import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useParams } from 'react-router-dom'
import * as api from '../../api'
import DropdownField, { Option } from '../../components/DropdownField'
import { Modal } from '@common/components'
import TextField from '../../components/TextField'
import { Content, ContentAndButtons, Form } from '../../components/Wizard'
import Buttons from '../../components/Wizard/Buttons'
import { Subheading } from '../../components/Wizard/Typography'
import { rem, size, space, style } from '../../core'
import * as selectors from '../../selectors'
import { Relationship } from '../../selectors/references'
import { RootState } from '../../store'
import * as slice from '../../store/references'

interface Props {
  candidateId: string
  open: boolean
  setOpen: (b: boolean) => void
}

const Container = style()
  .size({
    maxWidth: rem(40),
    maxHeight: rem(40),
    width: 'calc(100vw - 200px)',
    height: 'calc(100dvh - 200px)',
  })
  .element()

const YearContainer = style()
  .grid({ columns: [size.auto, size.auto] })
  .spacing({ column: space.s })
  .element()

export const inititalReference: api.references.CreateUpdatePayload = {
  candidate_id: '',
  email: '',
  full_name: '',
  phone_number: '',
  company_name: '',
  candidate_job_title: '',
  linkedin: '',
  from: 2019,
  to: 2024,
}

const Component = (props: Props) => {
  const { roleid, candidateid } = useParams<{
    roleid: string
    candidateid: string
  }>()
  const dispatch = useDispatch()

  const [candidateProfile, createdId, loading] = useSelector((state: RootState) => [
    selectors.candidateProfiles.findByCandidateId(state, props.candidateId),
    state.references.createdId,
    state.references.loading,
  ])

  const [relationship, setRelationship] = useState(Relationship.Manager)
  const [reference, setReference] = useState<api.references.CreateUpdatePayload>(inititalReference)

  useEffect(() => {
    setReference({ ...inititalReference, candidate_id: props.candidateId })
    dispatch(slice.setCreatedId(''))
  }, [dispatch, props.candidateId, props.open])

  if (createdId) {
    return <Redirect to={`/roles/${roleid}/candidates/${candidateid}/references/${createdId}`} />
  }

  const invalidEmail = !selectors.isEmailValid(reference.email)
  const errorLabelForYear = genErrorLabelForYear()

  return (
    <Modal
      title={`Add a reference for ${
        (candidateProfile as api.request.Entity<api.candidateProfiles.Fields>)?.fields.full_name || ''
      }`}
      open={props.open}
      onClose={() => props.setOpen(false)}
    >
      <Container>
        <ContentAndButtons>
          <Content standalone>
            <Subheading>
              Please fill out some reference contact information, and we will invite them via email/SMS on behalf of{' '}
              {(candidateProfile as api.request.Entity<api.candidateProfiles.Fields>)?.fields.full_name}
              <br />
              <b>The reference will be invited as an additional reference, not a required one.</b>
            </Subheading>
            <Form>
              <TextField
                error={!!reference.email && invalidEmail}
                label="Email address"
                value={reference.email}
                placeholder="Enter an email address"
                onChange={e => setReference({ ...reference, email: e.target.value })}
              />
              <TextField
                label="Full name"
                value={reference.full_name}
                placeholder="Enter a full name"
                onChange={e => setReference({ ...reference, full_name: e.target.value })}
              />
              <DropdownField<Relationship>
                label="Relationship"
                onChange={rel => setRelationship(rel)}
                value={relationship}
              >
                {Object.values(Relationship).map(rel => (
                  <Option key={rel} value={rel}>
                    {rel}
                  </Option>
                ))}
              </DropdownField>
              <TextField
                label="Company name"
                value={reference.company_name}
                placeholder="Enter company name"
                onChange={e => setReference({ ...reference, company_name: e.target.value })}
              />
              <TextField
                label="Professional Network"
                value={reference.linkedin}
                placeholder="Enter the reference's professional network (eg. LinkedIn, GitHub, AngelList)"
                onChange={e => setReference({ ...reference, linkedin: e.target.value })}
              />
              <TextField
                label="Phone Number"
                value={reference.phone_number}
                placeholder="Enter the reference's phone number"
                onChange={e => setReference({ ...reference, phone_number: e.target.value })}
              />
              <YearContainer>
                <TextField
                  label="Worked from"
                  value={`${reference.from || ''}`}
                  placeholder="2019"
                  onChange={e => setReference({ ...reference, from: Number(e.target.value) })}
                />
                <TextField
                  label="Worked to"
                  error={!!errorLabelForYear}
                  errorLabel={errorLabelForYear}
                  value={`${reference.to || ''}`}
                  placeholder="2024"
                  onChange={e => setReference({ ...reference, to: Number(e.target.value) })}
                />
              </YearContainer>
            </Form>
          </Content>
          <Buttons
            next={onClickNext}
            back={() => {}}
            disabled={!reference || invalidEmail || !!errorLabelForYear}
            label={'Add'}
            loading={Boolean(loading)}
            forwardOnly
          ></Buttons>
        </ContentAndButtons>
      </Container>
    </Modal>
  )

  function onClickNext() {
    dispatch(
      slice.create({
        ...reference,
        is_peer: relationship === Relationship.Peer,
        is_report: relationship === Relationship.Report,
        is_manager: relationship === Relationship.Manager,
        is_student_peer: relationship === Relationship.StudentPeer,
        is_client: relationship === Relationship.Client,
        is_any: relationship === Relationship.Any,
      }),
    )
  }

  function genErrorLabelForYear() {
    if (reference.from > reference.to) return 'Must be later than from'

    const now = new Date().getFullYear()
    if (reference.to > now) return `Must be at most ${now}`
  }
}

export default Component
Component.displayName = 'AddReferenceModal'
