import * as tracking from 'core/track'
import React from 'react'
import Button from '../../../components/Button/ButtonV2'
import { Icon } from '../../../components/Icons'
import Modal from '../../../components/Modal'
import TextField from '../../../components/TextField'
import ToggleField from '../../../components/ToggleField'
import { px2rem, size, space, style } from '../../../core'
import { text } from '../../../core/design-system/text'
import { Activation, Timeline } from './Sections'

const Container = style()
  .size({
    width: px2rem(1080),
    maxHeight: '80vh',
  })
  .scroll({ y: 'auto' })
  .element()

const Content = style()
  .flex({ direction: 'column' })
  .spacing({ inner: px2rem(32), gap: px2rem(24) })
  .element()

const ButtonContainer = style()
  .grid({
    columns: [px2rem(140), px2rem(140)],
    rows: size.auto,
    align: 'center',
    justifyContent: 'end',
  })
  .spacing({
    gap: px2rem(16),
  })
  .element()

const IconContainer = style().flex().spacing({ gap: space.xxs }).element()

const TimelineInputContainer = style().flex({ alignItems: 'center' }).spacing({ gap: space.xs }).element()
const TimelineInputText = text.bodyText().element()

interface SettingsModalProps {
  open: boolean
  setOpen: (b: boolean) => void
  data: QualityOfHireSettings
  submit: (fields: QualityOfHireSettings) => void
}

export interface QualityOfHireSettings {
  enabled: boolean
  timelineDays: number
}

export const SettingsModal: React.FC<SettingsModalProps> = props => {
  const [data, setData] = React.useState<QualityOfHireSettings>(props.data)

  return (
    <Modal title={'Quality of Hire settings'} open={props.open} setOpen={props.setOpen}>
      <Container>
        <Content>
          <Activation>
            <ToggleField
              label={'Enable Quality of Hire'}
              onChange={() => {
                tracking.qualityofhire.settingsModalToggleEnableClicked()
                setData({ ...data, enabled: !data.enabled })
              }}
              on={data.enabled}
            />
          </Activation>

          <Timeline>
            <TimelineInputContainer>
              <TimelineInputText>Send automated surveys after</TimelineInputText>
              <div style={{ width: '5em' }}>
                <TextField
                  value={`${data.timelineDays}`}
                  onChange={e => {
                    const value = Number(e.target.value)
                    if (isNaN(value) || value < 0) {
                      return
                    }
                    tracking.qualityofhire.settingsModalTimeIntervalChanged(value)
                    setData(data => ({
                      ...data,
                      timelineDays: value,
                    }))
                  }}
                />
              </div>
              <TimelineInputText>days.</TimelineInputText>
            </TimelineInputContainer>
          </Timeline>

          <ButtonContainer>
            <Button
              onClick={() => {
                props.setOpen(false)
              }}
              buttonType={'tertiary'}
            >
              Close
            </Button>
            <Button
              onClick={() => {
                props.submit(data)
                props.setOpen(false)
              }}
              buttonType={'confirm'}
            >
              <IconContainer>
                <Icon name={'check'} />
                Confirm
              </IconContainer>
            </Button>
          </ButtonContainer>
        </Content>
      </Container>
    </Modal>
  )
}
