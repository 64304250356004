import React, { useEffect, useState } from 'react'
import { BsStars } from 'react-icons/bs'
import classes from './FloatingPrompt.module.scss'

interface FloatingPromptProps extends React.ComponentProps<'input'> {
  value: string
  onPressEnter: (value: string) => void
}

export const FloatingPrompt = ({ className, onPressEnter, value, ...props }: FloatingPromptProps) => {
  const [input, setInput] = useState(value)
  useEffect(() => {
    setInput(value)
  }, [value])

  return (
    <div className={`${classes.main} ${className ? className : ''}`}>
      <span className={`${classes.iconRing} ${props.disabled ? classes.pulsate : ''}`}>
        <BsStars className={classes.icon} />
      </span>
      <form
        style={{ display: 'contents' }}
        onSubmit={e => {
          e.preventDefault()
          onPressEnter(input)
        }}
      >
        <input
          type="search"
          className={classes.promptInput}
          value={input}
          onChange={e => setInput(e.target.value)}
          {...props}
        />
      </form>
    </div>
  )
}
