import { Buttons, Content, ContentAndButtons } from 'components/Wizard'
import { Subheading } from 'components/Wizard/Typography'
import { px2rem, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as selectors from 'selectors'
import { RootState } from 'store'

const SubheadingBold = text.bodyEmphasis().fg(designSystemColors.typographySecondary).inline().element('p')

const Container = style()
  .size({
    maxWidth: px2rem(640),
    maxHeight: px2rem(215),
    width: `calc(100vw - ${px2rem(200)})`,
    height: px2rem(356),
  })
  .element()

interface Props {
  setOpen: (b: boolean) => void
}

const Component = (props: Props) => {
  const history = useHistory()

  const isOrgAdmin = useSelector((state: RootState) =>
    selectors.users.isOrgAdmin(state, selectors.users.current(state)?.id || ''),
  )

  return (
    <Container>
      <ContentAndButtons>
        <Content>
          <Subheading>
            HiPeople allows you to automate your reference check requests to candidate directly via your ATS.
            <br />
            In order to enable this, the{' '}
            <SubheadingBold>
              ATS needs first to be connected to HiPeople in the organization settings
            </SubheadingBold>{' '}
            (ask the HiPeople admin in your organization to enable it).
          </Subheading>
        </Content>
        {isOrgAdmin ? (
          <Buttons
            next={() => history.push('/settings/organization')}
            label={'To settings'}
            back={() => props.setOpen(false)}
          />
        ) : (
          <Buttons forwardOnly next={() => props.setOpen(false)} back={() => {}} label={'Back'} />
        )}
      </ContentAndButtons>
    </Container>
  )
}

export default Component
Component.displayName = 'NoIntegration'
