import ProductPage from 'App/ProductPages/ProductPage'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import Button from '../../components/Button/ButtonV2'
import HeroGrid from '../../components/ProductPageLayout/HeroGrid'
import * as tracking from '../../core/track'
import * as selectors from '../../selectors'
import HeroImgReferenceFeedback from './HeroImgReferenceFeedback.png'

interface ReferenceFeedbackPageProps {}

const ReferenceChecksPage: React.FC<ReferenceFeedbackPageProps> = () => {
  const userId = useSelector(selectors.users.current)?.id || ''
  const canCreateRole = useSelector((state: RootState) => selectors.users.canCreateRole(state, userId))

  const button = (
    <Button
      as="Link"
      to={
        canCreateRole
          ? {
              pathname: '/roles/new',
              state: { isQuickMode: true, from: 'references-page' },
            }
          : { pathname: '/reference-feedback' }
      }
      buttonType="accent"
      onClick={() => tracking.references.sidebarCreateReferenceCheckClicked()}
      disabled={!canCreateRole}
      testId="create-reference-check-button"
    >
      Create Reference Check
    </Button>
  )

  return (
    <ProductPage
      product={'reference_check'}
      title={'Reference Checks'}
      ctaButton={button}
      hero={
        <HeroGrid
          heading={'Collect references with ease, and at scale'}
          subheading={
            'Automate your reference check collection, and receive verified, benchmarked references at scale. Choose what you want to learn about a candidate, and who you want to learn it from - HiPeople takes care of the rest.'
          }
          image={{
            src: HeroImgReferenceFeedback,
            altText: 'User interface of HiPeople dashboard showing insights from candidate reference check',
          }}
        >
          {button}
        </HeroGrid>
      }
    />
  )
}

export default ReferenceChecksPage
