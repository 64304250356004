import { QuestionTable } from 'App/ExperienceFeedback/QuestionList'
import Bar from 'components/Benchmark/Bar'
import DiffToAverage from 'components/Benchmark/DiffToAverage'
import { Column } from 'components/ContentGrid'
import { px2rem, rem, size, style } from 'core'
import { text } from 'core/design-system/text'
import React from 'react'

export interface ExperienceSummaryScore {
  heading: string
  title: string
  emoji: string
  emojiBgColor: string
  overallScore: number | null
  trendScore: number | null
}
interface NumericQuestionSummaryProps {
  questions: ExperienceSummaryScore[]
}

const Title = text.secondaryBodyInteractive().element()

const Row = style()
  .grid({
    columns: [px2rem(14), size.auto, rem(13), px2rem(72), px2rem(72)],
  })
  .element()

const Trend = text.secondaryBodyText().spacing({ outer: size.auto }).element()

const ScoreContainer = text.bodyText().flex().element()

const FullAverage = style()
  .spacing({ outer: [size.none, size.auto], gap: px2rem(2) })
  .flex({ alignItems: 'center' })
  .element()

const Score = text.bodyEmphasis().element()

const QuestionTitle = text.secondaryBodyText().text({ ellipsis: true }).element()

const ScoreBarContainer = style()
  .spacing({ gap: rem(0.5) })
  .grid({ columns: [rem(3), size.auto] })
  .selectChild(0, style().size({ height: px2rem(16) }))
  .size({ height: px2rem(18) })
  .element()

const Empty = text
  .label()
  .spacing({ outer: [size.none, size.auto] })
  .element()

const BarContainer = style()
  .grid()
  .size({ width: px2rem(145) })
  .element()

const Emoji = style()
  .flex({ alignItems: 'center', justifyContent: 'center' })
  .with(({ bgColor }: { bgColor: string }) => style().color({ bg: bgColor }))
  .size({ width: px2rem(42), height: px2rem(42) })
  .spacing({ outerRight: px2rem(12) })
  .border({
    radius: px2rem(6),
  })
  .element()

const QuestionContent = style()
  .flex({
    alignItems: 'center',
  })
  .element()

const NumericQuestionSummary: React.FC<NumericQuestionSummaryProps> = ({ questions }) => {
  return (
    <QuestionTable>
      <Row>
        <Column head></Column>
        <Column head>
          <Title>Question</Title>
        </Column>
        <Column head>
          <Title>Average score</Title>
        </Column>
        <Column head center>
          <Title>Monthly</Title>
        </Column>
        <Column head center>
          <Title>All-time</Title>
        </Column>
      </Row>
      {questions.map((question, i) => (
        <Row key={`${question.heading}-${i}`}>
          <Column></Column>
          <Column>
            <QuestionContent>
              <Emoji bgColor={question.emojiBgColor}>{question.emoji}</Emoji>
              <QuestionTitle>{question.title}</QuestionTitle>
            </QuestionContent>
          </Column>
          <Column centerVert>
            <ScoreBarContainer>
              <ScoreContainer>
                {question.overallScore === null ? (
                  <Empty>—</Empty>
                ) : (
                  <FullAverage>
                    <Score>{question.overallScore.toFixed(1)} </Score> / 7
                  </FullAverage>
                )}
              </ScoreContainer>
              <BarContainer>
                <Bar overall={question.overallScore} average={question.trendScore} maxDisplayedScore={7} />
              </BarContainer>
            </ScoreBarContainer>
          </Column>
          <Column centerVert>
            <DiffToAverage average={question.trendScore} overall={question.overallScore} />
          </Column>
          <Column centerVert>
            <Trend>{question.trendScore !== null ? `(${question.trendScore.toFixed(1)})` : `–`}</Trend>
          </Column>
        </Row>
      ))}
    </QuestionTable>
  )
}

export default NumericQuestionSummary
