import React from 'react'
import * as RadixSelect from '@radix-ui/react-select'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa6'

import classes from './Select.module.scss'

interface SelectOption {
  value: string
  label: React.ReactNode
}

interface SelectProps {
  options: SelectOption[]
  value?: string
  onChange?: (value: string) => void
  placeholder?: string
  disabled?: boolean
  triggerClassName?: string
  contentClassName?: string
  testId?: string
}

export const Select: React.FC<SelectProps> = props => (
  <RadixSelect.Root value={props.value} onValueChange={props.onChange} disabled={props.disabled ?? false}>
    <RadixSelect.Trigger className={`${classes.trigger} ${props.triggerClassName ?? ''}`} data-testid={props.testId}>
      <RadixSelect.Value placeholder={props.placeholder ?? 'Select an option'} />
      <FaChevronDown className={classes.icon} />
    </RadixSelect.Trigger>

    <RadixSelect.Portal>
      <RadixSelect.Content position="popper" className={`${classes.content} ${props.contentClassName ?? ''}`}>
        <RadixSelect.ScrollUpButton className={classes.scrollButton} asChild>
          <FaChevronUp />
        </RadixSelect.ScrollUpButton>
        <RadixSelect.Viewport className={classes.viewport}>
          {props.options.map(option => (
            <RadixSelect.Item key={option.value} value={option.value} className={classes.item}>
              <RadixSelect.ItemText>{option.label}</RadixSelect.ItemText>
              <RadixSelect.ItemIndicator className={classes.itemIndicator} />
            </RadixSelect.Item>
          ))}
        </RadixSelect.Viewport>
        <RadixSelect.ScrollDownButton className={classes.scrollButton} asChild>
          <FaChevronDown />
        </RadixSelect.ScrollDownButton>
      </RadixSelect.Content>
    </RadixSelect.Portal>
  </RadixSelect.Root>
)
