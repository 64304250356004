import { Card } from '@common/components'
import React, { useEffect, useReducer, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { getUTMSearchParams } from 'utils/format'
import * as tracking from '../../core/track'
import { RootState } from 'store'
import { createOrganization } from 'store/organizations'
import classes from './Signup.module.scss'
import { GoogleSSO, signUpWithGoogle } from './SocialSSO/GoogleSSO'
import { StartFreeTrial, StartFreeTrialValues } from './StartFreeTrial'
import { TellUsMore, TellUsMoreValues } from 'App/SignUp/TellUsMore'

export const StepIndex = {
  StartFreeTrial: 0,
  TellUsMore: 1,
} as {
  StartFreeTrial: number
  TellUsMore: number
}

let hasTrackedWithUtm = false

type OnSubmitCallbackValues = StartFreeTrialValues & TellUsMoreValues

export type SignUpValues = {
  startFreeTrial: StartFreeTrialValues
  tellUsMore: TellUsMoreValues
  googleCode?: string
}

interface SignUpProps {
  initialValues?: SignUpValues
  initialStep?: number
}

type StepAction = { type: 'SET_STEP'; payload: number } | { type: 'NEXT_STEP' }

type StepState = {
  step: number
  locationState: Record<string, any>
}

/**
 * Hook to encapsulate the logic of the current step state in the signup flow.
 */
const useStep = (
  initialStep: number,
  location: ReturnType<typeof useLocation>,
  history: ReturnType<typeof useHistory>,
) => {
  const locationState = location.state as Record<string, string | number>

  const initialState: StepState = {
    step: initialStep,
    locationState,
  }

  const reducer = (state: StepState, action: StepAction): StepState => {
    switch (action.type) {
      case 'SET_STEP':
        history.replace({
          ...location,
          state: { ...state.locationState, step: action.payload },
        })
        return { ...state, step: action.payload }
      case 'NEXT_STEP':
        history.replace({
          ...location,
          state: { ...state.locationState, step: state.step + 1 },
        })
        return { ...state, step: state.step + 1 }
      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  return { state, dispatch }
}

export const SignUp: React.FC<SignUpProps> = props => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  const initialStep =
    props.initialStep ??
    (
      location.state as {
        step: number
      }
    )?.step ??
    StepIndex.StartFreeTrial
  const { state, dispatch: stepDispatch } = useStep(initialStep, location, history)

  const nextStep = () => stepDispatch({ type: 'NEXT_STEP' })

  const [freeTrialValues, setFreeTrialValues] = useState<StartFreeTrialValues>(
    props.initialValues?.startFreeTrial ?? {
      agreedToTermsAndConditions: true,
      agreedToNewsletter: true,
      emailAddress: '',
      password: '',
    },
  )

  const [tellUsMoreValues, setTellUsMoreValues] = useState<TellUsMoreValues>(
    props.initialValues?.tellUsMore ?? {
      fullName: '',
      companyName: '',
      jobTitle: '',
      phoneNumber: '',
    },
  )

  useEffect(() => {
    const utmParams = getUTMSearchParams(location.search)

    if (freeTrialValues.emailAddress && !hasTrackedWithUtm) {
      hasTrackedWithUtm = true
      tracking.identify({
        email: freeTrialValues.emailAddress,
        is_org_admin: true,
        is_super_user: false,
        organization_plan: 'self-trial',
        organization: tellUsMoreValues?.companyName,
        ...utmParams,
      })
    }
  }, [location.search, freeTrialValues.emailAddress, tellUsMoreValues?.companyName])

  const { loading, success } = useSelector((state: RootState) => state.createOrganization)

  if (success) {
    return <Redirect to="/" />
  }

  const submit = async () => {
    const values: OnSubmitCallbackValues = {
      ...freeTrialValues,
      ...tellUsMoreValues,
    }

    dispatch(
      createOrganization(
        values.agreedToNewsletter,
        values.agreedToTermsAndConditions,
        values.companyName,
        values.companySize || '',
        values.emailAddress,
        values.password,
        values.fullName,
        props.initialValues?.googleCode,
        {
          jobTitle: values.jobTitle,
          phoneNumber: values.phoneNumber,
          referredBy: values.referredBy,
          interestedIn: values.interestedIn,
          useCase: values.useCase,
        },
      ),
    )
  }

  return (
    <>
      <div className={classes.main}>
        {state.step === StepIndex.StartFreeTrial && (
          <Card big className={classes.cardWithImage}>
            <StartFreeTrial
              values={freeTrialValues}
              onChange={setFreeTrialValues}
              next={nextStep}
              onGoogleSignUp={signUpWithGoogle}
            />
            <div className={classes.sideImage} />
          </Card>
        )}

        {state.step === StepIndex.TellUsMore && (
          <Card big className={classes.card}>
            <TellUsMore
              values={tellUsMoreValues}
              onChange={setTellUsMoreValues}
              submit={submit}
              loading={loading}
              success={success}
            />
          </Card>
        )}
      </div>
    </>
  )
}

interface SignUpPageProps {
  interceptGoogle?: boolean
}

const SignUpPage: React.FC<SignUpPageProps> = props => {
  if (props.interceptGoogle) {
    return (
      <GoogleSSO>
        <SignUp />
      </GoogleSSO>
    )
  }

  return <SignUp />
}

export default SignUpPage
