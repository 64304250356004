import { get, put } from './methods'
import { APIResponse, Entity } from './request'

export const RESOURCE = 'otp'

export interface Fields {
  id: string
  type: 'shared-candidate' | 'self-shared-candidate'
  email: string
  candidate_id: string
}

interface LinkFields {
  token: string
}

export function load(id: string): Promise<APIResponse<Entity<Fields>>> {
  return get<Entity<Fields>>(`/otp/${id}`)
}

export function send(id: string): Promise<APIResponse<Entity<Fields>>> {
  return put<Entity<Fields>>(`/otp/${id}/send`, {})
}

export function verify(id: string, pin: string): Promise<APIResponse<Entity<LinkFields>>> {
  return put<Entity<LinkFields>>(`/otp/${id}/verify`, { pin })
}

export function empty(id: string): Entity<Fields> {
  return {
    id,
    resource: RESOURCE,
    fields: {
      id,
      type: 'shared-candidate',
      email: '',
      candidate_id: '',
    },
  }
}
