import { fr, px2rem, size, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React from 'react'
import { EmojiBadge } from '../EmojiBadge'

const Container = style()
  .color({ bg: designSystemColors.backgroundNeutralPrimary })
  .border({
    around: `solid 1px`,
    color: designSystemColors.borderDefault,
    radius: px2rem(6),
  })
  .block()
  .sans({ nodecoration: true })
  .cond(({ isFullWidth }) => isFullWidth, style().size({ width: '100%' }))
  .element('article')

const Header = style()
  .grid({ columns: [size.auto, fr(1)], align: 'center' })
  .spacing({ gap: px2rem(12), outer: px2rem(16) })
  .element('section')

const Title = text
  .bodyEmphasis()
  .cond(({ big }) => big, style().text({ size: px2rem(16) }))
  .color({ fg: designSystemColors.typographyPrimary })
  .element('h3')

const ContentContainer = style()
  .spacing({ inner: ['0', px2rem(16), px2rem(16), px2rem(16)] })
  .element('section')
export type EmojiColor = 'purple' | 'orange' | 'green' | 'blue' | 'cyan' | 'red'

export interface ContainerCardProps {
  title?: string
  emoji?: string
  emojiColor?: EmojiColor
  bigTitle?: boolean
  isFullWidth?: boolean
  children?: React.ReactNode
}

const ContainerCard: React.FC<ContainerCardProps> = props => {
  return (
    <Container isFullWidth={props.isFullWidth}>
      {props.emoji && props.emojiColor && props.emojiColor && (
        <Header>
          <EmojiBadge emoji={props.emoji} emojiColor={props.emojiColor}></EmojiBadge>
          <Title big={props.bigTitle}>{props.title}</Title>
        </Header>
      )}
      <ContentContainer>{props.children}</ContentContainer>
    </Container>
  )
}

export default ContainerCard
