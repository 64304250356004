import { RejectionReason } from 'api/reference'
import { style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import React from 'react'
import { FeedbackStyleType } from 'selectors/halo-score'
import { Relationship } from 'selectors/references'
import { EmptyAnswerSection } from './Chips'
import EmptyAnswer from './EmptyAnswer'
import RDSForcedTruth from './RDSForcedTruth'
import { ReferenceAnswer } from './ReferenceAnswerV2'

type ReferenceDetails = {
  id: string
  relationship: Relationship
  professionalNetwork?: {
    name: string
    url: string
  }
  name: string
  email: string
  companyName: string
  from: number
  to: number
  feedbackStyle?: FeedbackStyleType
  rejectionReason?: RejectionReason
  isReviewed: boolean
  isVerified: boolean
  completed: boolean
}

type ReferenceId = string

export type ForcedTruthAnswerType = {
  startCaption: string
  endCaption: string
  value: number
  skipped?: boolean
}[]

const ForcedTruthStyle = style()
  .select(
    '> * + *',
    style().border({
      top: 'solid 1px',
      color: designSystemColors.borderDefault,
    }),
  )
  .element()

interface ForcedTruthProps {
  references: ReferenceDetails[]
  answers: Record<ReferenceId, ForcedTruthAnswerType>
  candidateName: string
}

const ForcedTruth: React.FC<ForcedTruthProps> = ({ references, answers, candidateName }) => {
  if (references.length === 0) {
    return (
      <EmptyAnswerSection>
        <EmptyAnswer waitingReferences noSpacing />
      </EmptyAnswerSection>
    )
  }

  return (
    <ForcedTruthStyle>
      {references.map(reference => {
        const refAnswer = answers[reference.id]

        return (
          <ReferenceAnswer key={reference.id} reference={reference} candidateName={candidateName}>
            {refAnswer ? (
              <RDSForcedTruth answers={refAnswer} />
            ) : (
              <EmptyAnswer
                waitingResponse={!reference.completed}
                notApplicableForCandidate={reference.relationship === Relationship.Self && reference.completed}
                noSpacing
              />
            )}
          </ReferenceAnswer>
        )
      })}
    </ForcedTruthStyle>
  )
}

export default ForcedTruth
