import { px2rem, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React from 'react'
import { Icon } from '../Icons'

export const BADGE_THEMES: { [key in BadgeTheme]: { bg: string; fg: string } } = {
  orange: {
    bg: designSystemColors.informationOrangeBackground,
    fg: designSystemColors.informationOrangePrimary,
  },
  blue: {
    bg: designSystemColors.informationBlueBackground,
    fg: designSystemColors.informationBluePrimary,
  },
  green: {
    bg: designSystemColors.informationGreenBackground,
    fg: designSystemColors.informationGreenPrimary,
  },
  purple: {
    bg: designSystemColors.informationPurpleBackground,
    fg: designSystemColors.informationPurplePrimary,
  },
  red: {
    bg: designSystemColors.informationRedBackground,
    fg: designSystemColors.informationRedPrimary,
  },
  cyan: {
    bg: designSystemColors.informationCyanBackground,
    fg: designSystemColors.informationCyanPrimary,
  },
}

const BadgeStyle = text
  .label()
  .spacing({ inner: [px2rem(3), px2rem(4)] })
  .border({ radius: px2rem(3) })
  .cond(({ onClick }) => !!onClick, style().pointer())
  .text({ ellipsis: true, noWrap: true, align: 'left' })
  .size({ height: '100%', width: 'fit-content', maxWidth: '100%' })
  .nooverflow()
  .cond(
    ({ copy }) => !copy?.length,
    style()
      .size({ width: px2rem(20) })
      .inlineFlex({ alignItems: 'center', justifyContent: 'center' }),
  )
  .with((props: { theme: BadgeTheme }) => {
    const opts = BADGE_THEMES[props.theme]
    return opts ? style().color(opts) : style()
  })
  .element('span')

const IconStyle = style()
  .cond(({ copy }) => copy?.length, style().spacing({ innerRight: px2rem(4) }))
  .inline()
  .element()

export type BadgeTheme = 'orange' | 'blue' | 'green' | 'purple' | 'red' | 'cyan'

interface BadgeProps {
  copy: string
  theme: BadgeTheme
  icon?: string
  onClick?: () => void
}

export const Badge: React.FC<BadgeProps> = ({ copy, theme, icon, onClick }) => (
  <BadgeStyle onClick={onClick} theme={theme} copy={copy}>
    {icon && (
      <IconStyle copy={copy}>
        <Icon name={icon} />
      </IconStyle>
    )}
    {copy}
  </BadgeStyle>
)
