import request, { apiRequest, APIResponse } from './request'

/**
 * @deprecated use `apiGet` instead
 */
export function get<T, M = {}>(path: string): Promise<APIResponse<T, M>> {
  return request('GET', path, {})
}

/**
 * @deprecated use `apiPost` instead
 */
export function post<T, M = {}>(path: string, body: any, formData?: FormData): Promise<APIResponse<T, M>> {
  return request(
    'POST',
    path,
    formData
      ? { formData }
      : {
          body: JSON.stringify(body),
        },
  )
}

/**
 * @deprecated use `apiPut` instead
 */
export function put<T, M = {}>(path: string, body: any, formData?: FormData): Promise<APIResponse<T, M>> {
  return request(
    'PUT',
    path,
    formData
      ? { formData }
      : {
          body: JSON.stringify(body),
        },
  )
}

/**
 * @deprecated use `apiDel` instead
 */
export function del<T, M = {}>(path: string, body: any): Promise<APIResponse<T, M>> {
  return request('DELETE', path, {
    body: JSON.stringify(body),
  })
}

export function apiGet<T, M = {}>(path: string): Promise<APIResponse<T, M>> {
  return apiRequest('GET', path, {})
}

export function apiPost<T, M = {}>(path: string, body: any, formData?: FormData): Promise<APIResponse<T, M>> {
  return apiRequest(
    'POST',
    path,
    formData
      ? { formData }
      : {
          body: JSON.stringify(body),
        },
  )
}

export function apiPut<T, M = {}>(path: string, body: any, formData?: FormData): Promise<APIResponse<T, M>> {
  return apiRequest(
    'PUT',
    path,
    formData
      ? { formData }
      : {
          body: JSON.stringify(body),
        },
  )
}
