import { Button } from '@common/components'
import { useConfirm } from 'components/Confirm'
import { space, style } from 'core'
import { text } from 'core/design-system/text'
import React from 'react'

const Container = style()
  .flex({ alignItems: 'center' })
  .spacing({ gap: space.m, inner: [space.xs, space.m] })
  .element()

const ActionButton = style().size({ minWidth: space.xxl }).elementFromComponent(Button)

const BannerText = text.bodyInteractive().element('span')
const BannerTextSmall = text.smallBodyText().element('span')

interface Props {
  numSelected: number
  onDelete: () => void
}

export const CandidateBulkActions: React.FC<Props> = props => {
  const confirm = useConfirm()

  return (
    <Container>
      <BannerText>Candidate actions</BannerText>
      <BannerTextSmall>({props.numSelected} selected)</BannerTextSmall>
      <ActionButton
        buttonType="primary"
        onClick={async () => {
          if (
            !(await confirm({
              title: 'Confirm archive',
              message: `Are you sure you want to archive the ${props.numSelected} selected candidate(s)?`,
              confirmLabel: 'Archive',
              danger: true,
            }))
          )
            return

          props.onDelete()
        }}
      >
        Archive
      </ActionButton>
    </Container>
  )
}
