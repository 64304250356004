import Modal from 'components/Modal'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React, { useState } from 'react'
import { ChipKnowledgeBite, getKnowledgeBiteByKey } from '../../content'
import { colors, height, px2rem, space, style } from '../../core'
import * as tracking from '../../core/track'
import KnowledgeBite from './KnowledgeBite'

interface Props {
  selected: string[]
  input?: string
}

const Chips = style()
  .text({ height: height.xxl })
  .select('> a,span', style().spacing({ outerRight: space.xs, outerBottom: space.xs }))
  .spacing({ outerBottom: `-${space.xs}` })
  .element()

const ReferenceDescription = text
  .bodyText()
  .spacing({ outerTop: px2rem(12) })
  .element('p')

const Chip = text
  .bodyText()
  .inlineBlock()
  .spacing({ inner: [px2rem(8), px2rem(14)] })
  .color({ fg: colors.midGray })
  .round(px2rem(30))
  .border({ around: `solid 1px`, color: designSystemColors.borderDefault })
  .color({ bg: designSystemColors.backgroundNeutralSecondary })
  .cursor('default')
  .cond(
    ({ clickable }: { clickable: boolean }) => clickable,
    style().select(':hover', style().color({ bg: designSystemColors.backgroundNeutralSecondaryHover }).pointer()),
  )
  .element('span')

const KnowledgeBiteContainer = style()
  .spacing({ inner: space.m })
  .size({ maxWidth: px2rem(1024) })
  .element()

const Component = (props: Props) => {
  const [knowledgeBite, setKnowledgeBite] = useState<ChipKnowledgeBite>()

  return (
    <>
      <Chips>
        {props.selected.map(
          c =>
            c && (
              <Chip
                clickable={getKnowledgeBiteByKey(c)}
                onClick={() => getKnowledgeBiteByKey(c) && onClickChip(c)}
                key={c}
              >
                {c}
              </Chip>
            ),
        )}
      </Chips>
      {props.input ? <ReferenceDescription>{props.input}</ReferenceDescription> : null}
      <Modal open={!!knowledgeBite} setOpen={open => !open && setKnowledgeBite(undefined)}>
        {knowledgeBite && (
          <KnowledgeBiteContainer>
            <KnowledgeBite onClose={() => setKnowledgeBite(undefined)} knowledgeBite={knowledgeBite} />
          </KnowledgeBiteContainer>
        )}
      </Modal>
    </>
  )

  function onClickChip(chip: string) {
    const clickedKnowledgeBite = getKnowledgeBiteByKey(chip)
    tracking.references.chipViewed({ chipName: chip })
    setKnowledgeBite(knowledgeBite === clickedKnowledgeBite ? undefined : clickedKnowledgeBite)
  }
}

export default Component
Component.displayName = 'RDSChips'
