import React from 'react'
import { Link } from 'react-router-dom'

export const ScoreLink = (props: { score: string; tracking: () => void; link: string | null }) => {
  if (!props.link) {
    return <span style={{ textDecoration: 'none', cursor: 'default', color: 'inherit' }}>{props.score}</span>
  }

  return (
    <Link
      to={props.link}
      style={{ textDecoration: 'none', cursor: 'pointer', color: 'inherit' }}
      onClick={props.tracking}
    >
      {props.score}
    </Link>
  )
}
