import { Button, CompanySize, Logo, Text } from '@common/components'
import { px2rem, style } from 'core'
import React, { useState } from 'react'
import * as tracking from '../../../core/track'
import classes from '../Signup.module.scss'
import { PersonalInfo } from 'App/SignUp/TellUsMore/PersonalInfo'
import { CompanyInfo } from 'App/SignUp/TellUsMore/CompanyInfo'

export const interests = ['Assessments & tests', 'Reference checks', 'Both'] as const
export const useCases = ['Direct hiring / internal use', 'Staffing / recruiting agency / other'] as const
export const referrers = [
  'Google/Search',
  'LinkedIn',
  'Friend/Colleague',
  'Software review site',
  'Newsletter',
  'Other',
] as const

export type InterestedIn = (typeof interests)[number]
export type UseCase = (typeof useCases)[number]
export type ReferredBy = (typeof referrers)[number]

export type TellUsMoreValues = {
  fullName: string
  jobTitle: string
  phoneNumber: string
  companyName: string
  referredBy?: ReferredBy
  interestedIn?: InterestedIn
  useCase?: UseCase
  companySize?: CompanySize
}

const Buttons = style()
  .flex({ alignItems: 'center', justifyContent: 'center' })
  .spacing({ gap: px2rem(16) })
  .element()

interface TellUsMoreProps {
  values: TellUsMoreValues
  onChange: React.Dispatch<React.SetStateAction<TellUsMoreValues>>
  submit: () => any
  loading: boolean
  success: boolean
}

// TODO: move this to a context or redux
let hasTracked = false

export const TellUsMore: React.FC<TellUsMoreProps> = props => {
  const [page, setPage] = useState(0)

  const [personalInfoValid, setPersonalInfoValid] = useState(false)
  const [companyInfoValid, setCompanyInfoValid] = useState(false)

  const submit = () => {
    if (!hasTracked) {
      tracking.signUp.signUpProvidedCompanyName()
      hasTracked = true
    }
    props.submit()
  }

  return (
    <div className={classes.containerTellUsMore}>
      <div className={classes.center}>
        <Logo />
        <Text variant="highlight">Tell us more about {page === 0 ? 'yourself' : 'your company'}</Text>
      </div>

      <div className={classes.textFields}>
        {page === 0 && <PersonalInfo values={props.values} onChange={props.onChange} setValid={setPersonalInfoValid} />}
        {page === 1 && <CompanyInfo values={props.values} onChange={props.onChange} setValid={setCompanyInfoValid} />}
      </div>

      <Buttons>
        {page === 0 && (
          <Button
            variant="purple-dark"
            onClick={() => setPage(1)}
            disabled={props.loading || !personalInfoValid}
            rounded
            style={{ width: '100%' }}
          >
            Next
          </Button>
        )}

        {page === 1 && (
          <>
            <Button
              variant="primary"
              onClick={() => setPage(0)}
              disabled={props.loading}
              rounded
              style={{ width: '100%' }}
            >
              Back
            </Button>
            <Button
              variant="purple-dark"
              onClick={submit}
              disabled={props.loading || !companyInfoValid}
              rounded
              style={{ width: '100%' }}
            >
              {props.success ? 'Loading dashboard...' : 'Start for free'}
            </Button>
          </>
        )}
      </Buttons>
    </div>
  )
}
