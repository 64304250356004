import { designSystemColors } from 'core/design-system/colors'
import React from 'react'
import { useSelector } from 'react-redux'
import * as api from '../../api'
import FileView from '../../components/FileView'
import { px2rem, space, style } from '../../core'
import * as selectors from '../../selectors'
import { RootState } from '../../store'
import EmptyAnswer from './EmptyAnswer'
import ReferenceAnswer from './ReferenceAnswer'

interface Props {
  candidateId: string
  referenceId?: string
  item: api.assessmentversions.Item
}

const Container = style()
  .grid()
  .select(
    '> *',
    style().border({
      top: 'solid 1px',
      color: designSystemColors.borderDefault,
    }),
  )
  .element()

const EmptyAnswerContainer = style()
  .spacing({
    outer: space.none,
    inner: [px2rem(24), px2rem(16)],
  })
  .element()

const Component = (props: Props) => {
  const references = useSelector((state: RootState) =>
    props.referenceId
      ? [selectors.references.getById(state, props.referenceId)]
      : selectors.references.findByCandidateId(state, props.candidateId),
  )

  if (references.length === 0) {
    return (
      <EmptyAnswerContainer>
        <EmptyAnswer waitingReferences noSpacing />
      </EmptyAnswerContainer>
    )
  }

  return (
    <Container>
      {references.map(reference => (
        <Answer
          key={reference.id}
          item={props.item}
          reference={reference}
          candidateId={props.candidateId}
          hideProfile={props.referenceId !== undefined}
        />
      ))}
    </Container>
  )
}

export default Component
Component.displayName = 'SimpleAnswer'

interface AnswerProps {
  item: api.assessmentversions.Item
  candidateId: string
  reference: api.request.Entity<api.references.Fields>
  hideProfile: boolean
}

function Answer(props: AnswerProps) {
  const content = useSelector((state: RootState) =>
    selectors.formResponses.findAnswerByReferenceId(state, props.reference.id, props.item.content_id),
  )

  if (content && content.url) {
    return <FileView src={content?.url} candidateId={props.candidateId} />
  }

  const value = content ? content.url || content.input || content.value : undefined

  if (value) {
    return (
      <ReferenceAnswer contentid={props.item.content_id} reference={props.reference} hideProfile={props.hideProfile}>
        {value}
      </ReferenceAnswer>
    )
  }

  return (
    <ReferenceAnswer reference={props.reference} contentid={props.item.content_id}>
      <EmptyAnswer
        notApplicableForCandidate={props.reference.fields.is_self && props.reference.fields.form_response_id !== ''}
        skipped={props.reference.fields.form_response_id !== '' || content?.skipped}
        leftBlank={props.reference.fields.form_response_id !== '' && !content?.skipped}
        waitingResponse={props.reference.fields.form_response_id === ''}
        noSpacing
      />
    </ReferenceAnswer>
  )
}
