import React from 'react'
import classes from './Badge.module.scss'

interface BadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  icon?: React.ReactNode
  variant?: 'success' | 'error' | 'gray' | 'white' | 'warning'
}

export const Badge = ({ variant, icon, children, ...props }: BadgeProps) => {
  return (
    <div style={{ display: 'inline' }}>
      <div className={[classes.badge, classes[variant ?? 'gray']].join(' ')} {...props}>
        {icon}
        {children}
      </div>
    </div>
  )
}
