import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as api from '../../api'
import { Question, Step } from '../../api/openjobroles'
import { AppThunk } from '../../core/store'
import { Relationship, RequiredReference } from '../../selectors/references'
import { createCandidateForm } from '../../store/new-role'
import { add as notify } from '../../store/notifications'
import { addEntities } from '../resources'

interface State {
  loading: boolean
  steps: Step[]
  requiredReferences: RequiredReference[]
}

const name = 'update-reference'

const initialState: State = {
  loading: false,
  steps: [],
  requiredReferences: [],
}

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setSteps(state, action: PayloadAction<Step[]>) {
      state.steps = action.payload
    },
    deleteStep(state, action: PayloadAction<number>) {
      const copy = state.steps.slice()
      copy.splice(action.payload, 1)
      state.steps = copy
    },
    setStep(state, action: PayloadAction<{ index: number; question: Question }>) {
      state.steps[action.payload.index] = {
        question: action.payload.question,
      }
    },
    setItem(state, action: PayloadAction<{ index: number; id: string }>) {
      state.steps[action.payload.index] = {
        test_item_id: action.payload.id,
      }
    },
    setRelationship(state, action: PayloadAction<{ i: number; relationship: Relationship }>) {
      state.requiredReferences[action.payload.i].relationship = action.payload.relationship
    },
    setDescription(state, action: PayloadAction<{ i: number; description: string }>) {
      state.requiredReferences[action.payload.i].description = action.payload.description
    },
    addItem(state, action: PayloadAction<string>) {
      state.steps.push({
        test_item_id: action.payload,
      })
    },
    addRequiredReference(state, action: PayloadAction<RequiredReference>) {
      state.requiredReferences.push(action.payload)
    },
    removeRequiredReference(state, action: PayloadAction<number>) {
      const copy = state.requiredReferences.slice()
      copy.splice(action.payload, 1)
      state.requiredReferences = copy
    },
    setRequiredReferences(state, action: PayloadAction<RequiredReference[]>) {
      state.requiredReferences = action.payload
    },
    reset(state) {
      state.steps = []
      state.requiredReferences = []
    },
  },
})

export const {
  setIsLoading,

  setRelationship,
  setDescription,
  addRequiredReference,
  removeRequiredReference,
  setRequiredReferences,
} = slice.actions

export const update =
  (roleId: string, requiredReferences: RequiredReference[]): AppThunk =>
  async dispatch => {
    dispatch(setIsLoading(true))

    const [, formErrors] = await api.openjobroles.updateCandidateForm(roleId, createCandidateForm([]))

    if (formErrors) {
      formErrors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    const [response, errors] = await api.requiredReferences.updateForRole(
      roleId,
      requiredReferences.map(rr => ({
        id: rr.id,
        is_peer: rr.relationship === Relationship.Peer,
        is_manager: rr.relationship === Relationship.Manager,
        is_report: rr.relationship === Relationship.Report,
        is_student_peer: rr.relationship === Relationship.StudentPeer,
        is_client: rr.relationship === Relationship.Client,
        is_any: rr.relationship === Relationship.Any,
        relationship_description: rr.description,
      })),
    )

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(addEntities(response))
    }

    dispatch(setIsLoading(false))

    dispatch(
      notify({
        success: "You've updated the references for this job successfully! 🎉",
      }),
    )
  }

export const updateCandidate =
  (candidateId: string, requiredReferences: RequiredReference[]): AppThunk =>
  async dispatch => {
    dispatch(setIsLoading(true))

    const [response, errors] = await api.requiredReferences.updateForCandidate(
      candidateId,
      requiredReferences.map(rr => ({
        id: rr.id,
        is_peer: rr.relationship === Relationship.Peer,
        is_manager: rr.relationship === Relationship.Manager,
        is_report: rr.relationship === Relationship.Report,
        is_student_peer: rr.relationship === Relationship.StudentPeer,
        is_client: rr.relationship === Relationship.Client,
        is_any: rr.relationship === Relationship.Any,
        relationship_description: rr.description,
      })),
    )

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    if (response) {
      dispatch(addEntities(response))
    }

    dispatch(setIsLoading(false))

    dispatch(
      notify({
        success: "You've updated the references for this candidate successfully! 🎉",
      }),
    )
  }

export default slice.reducer
