import { Button, LockedByPlan, LockIcon } from '@common/components'
import Block, { Header } from 'components/Block'
import { Icon } from 'components/Icons'
import { Notice } from 'components/Notice.tsx'
import { px, px2rem, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import * as tracking from 'core/track'
import { lockedContentTracking } from 'core/track/locked-content'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as selectors from 'selectors'
import { RootState } from 'store'
import * as slice from 'store/backgroundcheckintegrations'
import providers, { BackgroundCheckProvider, InstallMethod } from 'store/backgroundcheckintegrations/providers'
import Dialog from '../../components/Confirm/Dialog'

const CheckrLink = text.bodyText().element('a')

const NoticeContainer = style()
  .spacing({ outerBottom: px2rem(12) })
  .element()

const DisconnectText = text.bodyEmphasis().element()

const DisconnectButton = style()
  .text({ underline: true, weight: '600' })
  .cursor('pointer')
  .noborders()
  .transparent()
  .color({ fg: designSystemColors.typographyPrimary })
  .element('button')

const BlockForm = style().inlineBlock().spacing({ row: '1.2rem' }).element()

const Component: React.FC = () => {
  const dispatch = useDispatch()

  const org = useSelector((state: RootState) => selectors.orgs.getById(state, selectors.orgs.currentId(state)))

  const isOrgPaidPlan = useSelector(selectors.orgs.isOrgPaidPlan)

  const existingBackgroundCheckIntegration = useSelector((state: RootState) => {
    const currentOrgId = selectors.orgs.currentId(state)
    return selectors.backgroundcheckintegrations.getByOrgId(state, currentOrgId)
  })

  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const onDeleteConfirmation = (isConfirmed: boolean) => {
    tracking.orgs.proceedCheckrDisconnection()
    setDeleteDialogOpen(false)
    if (!isConfirmed || !existingBackgroundCheckIntegration) {
      return
    }
    dispatch(slice.deleteOrgIntegration(org.id, existingBackgroundCheckIntegration.fields.id))
  }

  const checkrProvider = providers['checkr']
  const Installer = getInstaller(checkrProvider.installMethod) as React.FC<InstallerProps>

  return (
    <LockedByPlan
      tracking={lockedContentTracking}
      locked={!isOrgPaidPlan}
      lockIconDisabled={true}
      upgradeTo={'Scale'}
      modalData={{
        learnMoreLink: 'https://intercom.help/hipeople/en/articles/8915407-integrating-checkr',
        learnMoreAbout: 'Integrating Checkr',
      }}
    >
      <Block
        testId="checkr-integration"
        title={
          <>
            Checkr integration
            <LockIcon visible={!isOrgPaidPlan} />
          </>
        }
      >
        <Header>
          Checkr (
          <CheckrLink href="https://checkr.com" target="_blank" rel="noreferrer">
            https://checkr.com
          </CheckrLink>
          ) allows you to run background check on individual candidates via the HiPeople dashboard. To enable it you
          need to connect HiPeople to your Checkr account via the button below. Once active, you can click on a
          candidate, then on the dropdown menu on the top-right corner to request a background check request via Checkr.{' '}
          <br />
          <br />
          Checkr is currently available to US customers only!
        </Header>
        <BlockForm>
          {!existingBackgroundCheckIntegration && (
            <Installer key={checkrProvider.slug} organizationID={org.id} provider={checkrProvider}>
              <Button
                variant="purple-dark"
                onClick={() => tracking.orgs.initiateCheckrConnection()}
                style={{ display: 'flex', alignItems: 'center', gap: px(8) }}
              >
                <Icon name={'plus'} />
                Connect Checkr account
              </Button>
            </Installer>
          )}
          {existingBackgroundCheckIntegration && (
            <>
              {!existingBackgroundCheckIntegration.fields.is_ready && (
                <NoticeContainer>
                  <Notice
                    icon={'clock'}
                    noticeBackgroundColor={designSystemColors.informationOrangeBackground}
                    iconColor={designSystemColors.uiStatusWarningSecondary}
                    copy={
                      'Your account integration is pending until we receive a readiness confirmation from Checkr within 3 days max.'
                    }
                  />
                </NoticeContainer>
              )}
              <DisconnectButton
                onClick={() => {
                  setDeleteDialogOpen(true)
                  tracking.orgs.initiateCheckrDisconnection()
                }}
              >
                <DisconnectText>click here to disconnect account</DisconnectText>
              </DisconnectButton>
              <Dialog
                title={'About to delete an integration, continue?'}
                message={'Deleting integrations can be very destructive and it can break working flows.'}
                confirmLabel={'Yes, DELETE it'}
                danger={true}
                isOpen={isDeleteDialogOpen}
                setOpen={setDeleteDialogOpen}
                onConfirm={onDeleteConfirmation}
              />
            </>
          )}
        </BlockForm>
      </Block>
    </LockedByPlan>
  )
}

export default Component
Component.displayName = 'BackgroundCheckIntegrations'

type Installer = React.FC<InstallerProps>
type InstallerProps = {
  organizationID: string
  provider: BackgroundCheckProvider
  children: React.ReactNode
}

const OAuthInstaller: Installer = ({ organizationID, provider, children }) => {
  const dispatch = useDispatch()
  const url = useSelector((state: RootState) => state.backgroundcheckintegrations.url)

  useEffect(() => {
    if (url) {
      window.location.href = url
    }
  }, [url])

  return (
    <div
      onClick={() => {
        dispatch(slice.installOAuthProvider(organizationID, provider.slug))
      }}
    >
      {children}
    </div>
  )
}

const getInstaller = (installMethod: InstallMethod): Installer | undefined => {
  switch (installMethod) {
    case 'oauth':
      return OAuthInstaller
  }
}
