import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaLifeRing } from 'react-icons/fa'
import HiPeopleWatermark from '../../../assets/logos/logo+wordmark.svg'
import { Button } from '../../Button/Button'
import { Container } from '../../Container/Container'
import classes from './Footer.module.scss'
import { LanguagePicker } from '../../Candidate/LanguagePicker/LanguagePicker'
import { Locale } from '../../../models/locales'
import { Text } from '../../Text/Text'

interface FooterProps {
  buttons?: React.ReactNode
  actionButtons?: React.ReactNode
  /** if true, the navigation buttons will be hidden */
  hideNavigation?: boolean
  /** Link should not be clickable on full screen */
  linkDisabled?: boolean
  enableLanguagePicker?: boolean
  /** Languages available in the role if language picker is enabled */
  availableLanguages?: Locale[]
  tracking: {
    support: () => void
    clickOnHipeople: () => void
  }
  supportLink: string
}

export const Footer: React.FC<FooterProps> = props => {
  const { t, i18n } = useTranslation()

  const [selectedLanguage, setSelectedLanguage] = React.useState<Locale>()

  React.useEffect(() => {
    if (i18n.language && i18n.language !== selectedLanguage && props.enableLanguagePicker) {
      setSelectedLanguage(i18n.language as Locale)
    }
  }, [i18n.language])

  return (
    <div className={classes.wrapper}>
      {props.enableLanguagePicker && (
        <LanguagePicker
          languagesAvailable={props.availableLanguages || []}
          onSelectLanguage={language => {
            i18n.changeLanguage(language)
          }}
          defaultLocale={selectedLanguage}
        />
      )}
      <Container>
        <div className={classes.content}>
          <div className={classes.logoRow}>
            <Button
              variant="tertiary"
              className={classes.watermark}
              onClick={() => {
                if (!props.linkDisabled) {
                  props.tracking.clickOnHipeople()
                  window.open('https://hipeople.io/')
                }
              }}
              style={{ cursor: props.linkDisabled ? 'default' : 'pointer' }}
            >
              <Text variant="small-body-text">{t('footer.powered-by', 'powered by')}</Text>{' '}
              <img src={HiPeopleWatermark} alt="HiPeople" />
            </Button>
            <a
              href={props.supportLink}
              className={classes.support}
              target="_blank"
              rel="noreferrer"
              onClick={props.tracking.support}
            >
              <Button variant="light" className={classes.button}>
                <FaLifeRing /> <span>{t('footer.support', 'Support')}</span>
              </Button>
            </a>
          </div>
          {props.actionButtons && <div className={classes.actionButtons}>{props.actionButtons}</div>}
          {!props.hideNavigation && props.buttons && <div className={classes.buttons}>{props.buttons}</div>}
        </div>
      </Container>
    </div>
  )
}
