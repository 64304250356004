import { openjobroleusers } from '../api'
import { Entity } from '../api/request'
import * as selectors from '../selectors'
import { RootState } from '../store'
import lookup from './lookup'
import { findById as findRoleById } from './roles'

function getById(state: RootState, id: string): Entity<openjobroleusers.Fields> {
  return lookup(state, openjobroleusers.RESOURCE, id) || openjobroleusers.empty(id)
}

export function findByRoleId(state: RootState, roleId: string): Entity<openjobroleusers.Fields>[] {
  const role = findRoleById(state, roleId)
  if (!role || !role.relationships) return []

  const users: Entity<openjobroleusers.Fields>[] = []
  const userIds = new Set()

  for (const r of role.relationships) {
    if (r.resource !== openjobroleusers.RESOURCE) {
      continue
    }

    const user = getById(state, r.id)
    // Filter out non-unique user entries
    if (!user || userIds.has(user.fields.user_id)) {
      continue
    }

    users.push(user)
    userIds.add(user.fields.user_id)
  }

  return users
}

export function findByRoleIdAndUserId(
  state: RootState,
  roleId: string,
  userId: string,
): Entity<openjobroleusers.Fields> | undefined {
  const role = findRoleById(state, roleId)
  if (!role || !role.relationships) return

  return role.relationships
    .filter(r => r.resource === openjobroleusers.RESOURCE)
    .map(r => getById(state, r.id))
    .filter(r => !!r)
    .find(r => r.fields.user_id === userId)
}

export function canManageRole(state: RootState, roleId: string, userId: string): boolean {
  if (selectors.users.isOrgAdmin(state, userId)) {
    return true
  }

  if (selectors.users.isSuperUser(state, userId)) {
    return true
  }

  if (selectors.users.canProvideSupport(state, userId)) {
    return true
  }

  return findByRoleIdAndUserId(state, roleId, userId)?.fields.can_control || false
}
