import Profile from 'App/Reference/Profile'
import FeedbackStyleBadge from 'components/FeedbackStyleBadge'
import { Icon } from 'components/Icons'
import Modal from 'components/Modal'
import { RelationshipLabel } from 'components/RelationshipLabel'
import VerificationBadge from 'components/VerificationBadge'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import * as selectors from 'selectors'
import { RootState } from 'store'
import * as api from '../../api'
import { colors, height, px2rem, scale, space, style, weight } from '../../core'
import * as tracking from '../../core/track'
import { Relationship, relationshipOf } from '../../selectors/references'
import ReferenceFollowup from './ReferenceFollowup'

const Container = style()
  .flex()
  .sans({ size: scale.s, height: height.s })
  .cursor('default')
  .cond(({ onClick }: { onClick: boolean }) => onClick, style().pointer())
  .cond(
    ({ hasHighlightedBackground }: { hasHighlightedBackground: boolean }) => hasHighlightedBackground,
    style().color({ bg: designSystemColors.backgroundNeutralSecondary }),
  )
  .element()
const Sidebar = style()
  .bg({ color: colors.transparent })
  .border({ around: 'none' })
  .text({ align: 'left' })
  .pointer()
  .flex({
    grow: '0',
    shrink: '0',
    basis: px2rem(200),
    direction: 'column',
    alignItems: 'flex-start',
  })
  .size({ maxWidth: px2rem(200) })
  .spacing({ inner: px2rem(16) })
  .relative()
  .select('> * + *', style().spacing({ outerTop: space.xxxs }))
  .select(':hover > *:last-child', style().visible())
  .select('> *:last-child', style().invisible())
  .select(':hover', style().color({ bg: designSystemColors.backgroundNeutralPrimaryHover }))
  .element('button')
const Main = style()
  .relative()
  .spacing({ inner: px2rem(16), innerBottom: px2rem(24) })
  .flex({ grow: '1', shrink: '0', basis: '0', direction: 'column' })
  // TODO: enable centered positioning for content when the prop values provided to this component is fixed
  .select('> * + *', style().spacing({ outerTop: px2rem(12) }))
  .element()

const RelationshipContainer = style().inline().element()

const Name = text
  .bodyInteractive()
  .text({ ellipsis: true })
  .spacing({ outerBottom: px2rem(4) })
  .size({ width: '100%' })
  .element('p')

const Score = style()
  .sans({ weight: weight.normal, color: colors.darkGray })
  .spacing({ outerBottom: px2rem(8) })
  .element()

const DetailsSection = text
  .label()
  .spacing({
    outerTop: space.auto,
    outerBottom: space.xxxs,
    innerTop: space.m,
  })
  .color({ fg: designSystemColors.typographySecondary })
  .element('span')

const DetailsText = style().spacing({ outerLeft: space.xxxs }).element('span')

const ProfileContainer = style()
  .size({ maxWidth: px2rem(1024) })
  .spacing({ inner: space.s })
  .element()

const RelationshipVerification = style()
  .flex({ alignItems: 'center' })
  .spacing({ gap: px2rem(8) })
  .element()

interface Props {
  children: React.ReactNode
  reference: api.request.Entity<api.references.Fields>
  score?: string
  center?: boolean
  hideProfile?: boolean
  contentid: string
  onClick?: () => void
}

const Component = (props: Props) => {
  const [isDetailsShown, setDetailsShown] = useState(false)

  const relationship = relationshipOf(props.reference) || ''

  const filter = useSelector((state: RootState) => state.references.filter[props.reference.fields.candidate_id])

  const feedbackStyle = useSelector((state: RootState) =>
    selectors.haloScore.findFeedbackStyleByReferenceId(state, props.reference.id),
  )

  if (filter && filter !== props.reference.id) {
    return <></>
  }

  const isSelfReference = relationship === Relationship.Self

  return (
    <>
      <Container onClick={props.onClick} hasHighlightedBackground={isSelfReference}>
        {!props.hideProfile && (
          <Sidebar onClick={() => setDetailsShown(true)}>
            <Name>{props.reference.fields.full_name}</Name>
            <RelationshipVerification>
              <RelationshipContainer>
                <RelationshipLabel type={relationship as Relationship} />
              </RelationshipContainer>
              {!isSelfReference && (
                <>
                  <VerificationBadge
                    verified={props.reference.fields.is_verified}
                    reviewed={props.reference.fields.is_reviewed}
                  />

                  {feedbackStyle && <FeedbackStyleBadge style={feedbackStyle} />}
                </>
              )}
            </RelationshipVerification>
            <DetailsSection onClick={() => tracking.references.showDetails()}>
              <Icon name={'info-circle'} />
              <DetailsText>{'Details'}</DetailsText>
            </DetailsSection>
          </Sidebar>
        )}
        <Main center={props.center}>
          {props.score ? <Score>{props.score}</Score> : <></>}
          {props.children}
          <ReferenceFollowup referenceId={props.reference.id} contentId={props.contentid} />
        </Main>
      </Container>
      <Modal open={isDetailsShown} setOpen={setDetailsShown} title={props.reference.fields.full_name}>
        <ProfileContainer>
          <Profile reference={props.reference} />
        </ProfileContainer>
      </Modal>
    </>
  )
}

export default Component
Component.displayName = 'ReferenceAnswer'
