import { map } from './css'
import Rows from './rows'

export interface IOptions {
  flex?: boolean
  block?: boolean
  inlineBlock?: boolean
  inlineFlex?: boolean
  inline?: boolean
  hidden?: boolean
  invisible?: boolean
  height?: string
  width?: string
  minWidth?: string
  maxWidth?: string
  minHeight?: string
  maxHeight?: string
  overflow?: string
  display?: 'inline' | 'block' | 'none' | 'inline-block' | 'inline-flex' | 'flex'
  float?: 'left' | 'right'
}

export default function box(rows: Rows, options: IOptions) {
  let display = options.display

  if (options.flex) {
    display = 'flex'
  }

  if (options.inline) {
    display = 'inline'
  }

  if (options.block) {
    display = 'block'
  }

  if (options.inlineBlock) {
    display = 'inline-block'
  }

  if (options.inlineFlex) {
    display = 'inline-flex'
  }

  if (options.hidden) {
    display = 'none'
  }

  if (options.invisible) {
    rows.set('visibility', 'hidden')
  }

  if (options.invisible === false) {
    rows.set('visibility', 'visible')
  }

  if (display) {
    rows.set('display', display)
  }

  map(rows, options, {
    width: 'width',
    height: 'height',
    maxWidth: 'maxWidth',
    minWidth: 'minWidth',
    maxHeight: 'maxHeight',
    minHeight: 'minHeight',
    overflow: 'overflow',
    float: 'float',
  })
}
