import React from 'react'
import classes from './Text.module.scss'

interface TextProps extends React.HTMLAttributes<HTMLDivElement> {
  variant:
  | 'hero-text'
  | 'home-title'
  | 'score-highlight-primary'
  | 'score-highlight-secondary'
  | 'highlight'
  | 'timeline-step'
  | 'action-card-header'
  | 'home-card-header'
  | 'timeline-header'
  | 'card-header'
  | 'body-text'
  | 'body-interactive'
  | 'secondary-body-interactive'
  | 'body-emphasis'
  | 'small-body-text'
  | 'small-body'
  | 'small-body-secondary'
  | 'label'
  marginBottom?: boolean
  center?: boolean
  fullWidth?: boolean
}

export const Text = ({ variant, marginBottom, center, fullWidth, ...props }: TextProps) => {
  return (
    <div
      {...props}
      className={`
        ${classes.sans} ${classes[variant]} ${center ? classes.center : ''}
        ${marginBottom ? classes.marginBottom : ''}
        ${fullWidth ? classes.fullWidth : ''}
        ${props.className}
`}
    />
  )
}
