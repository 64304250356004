import { Product } from 'api/templates'
import { interpolateVariables } from 'core/text'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDebounce } from 'react-use'
import * as selectors from 'selectors'
import { RootState } from 'store'
import * as newRoleSlice from 'store/new-role'

export function useSearchTemplates({
  product,
  locale,
  debounceMs = 400,
}: {
  product: Product
  locale: string
  debounceMs?: number
}) {
  const dispatch = useDispatch()

  const orgId = useSelector((state: RootState) => selectors.orgs.currentId(state))
  const canManageContent = useSelector((state: RootState) =>
    selectors.users.canManageContent(state, selectors.users.id(state)),
  )
  const templates = useSelector((state: RootState) => state.newRole.presets)
  const isLoading = useSelector((state: RootState) => state.newRole.loading)

  const [query, setQuery] = useState('')

  const filteredTemplate = templates
    .filter(preset => !preset.fields.is_private && preset.fields.is_active)
    .filter(preset => canManageContent || preset.fields.copy['title'] !== 'Blank')
    .map(preset => ({
      id: preset.id,
      isHighlighted: preset.fields.is_highlighted,
      title: preset.fields.copy['title'] || preset.fields.copy['name'],
      icon: preset.fields.copy['icon'],
    }))

  useDebounce(() => dispatch(newRoleSlice.loadPresets(orgId, product, query, locale)), debounceMs, [
    query,
    product,
    locale,
  ])

  return { query, setQuery, templates: filteredTemplate, isLoading }
}

export function useGetTemplatePreview(templateId: string) {
  const org = useSelector((state: RootState) => selectors.orgs.getById(state, selectors.orgs.currentId(state)))

  const getPreview = useSelector(
    (state: RootState) => (presetId: string) => selectors.presets.findPresetPreviewById(state, presetId, org.id),
  )

  const template = getPreview(templateId).map(item => ({
    ...item,
    copy: interpolateVariables(item.copy, {
      'CANDIDATE.FIRST_NAME': 'Alice',
      'ORGANIZATION.NAME': org.fields.name,
    }),
  }))

  return { template }
}
