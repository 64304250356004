import { GoogleIcon } from 'components/Icons'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { ButtonContent } from '../../components/Button/ButtonV2'
import TextField from '../../components/TextField'
import { Subheading } from '../../components/Typography'
import { text } from '../../core/design-system/text'
import { setEmail } from '../../store/login'

import { Button, Text } from '@common/components'
import { Link } from 'react-router-dom'
import classes from '../SignUp/Signup.module.scss'

const ButtonRowSeparator = text.bodyText().flex({ justifyContent: 'center' }).element()

interface StepEmailProps {
  onGoogleSignIn: () => void
}

const Component: React.FC<StepEmailProps> = values => {
  const dispatch = useDispatch()

  const [value, setValue] = useState('')
  const buttonDisabled = !value.trim()

  return (
    <>
      <Subheading>
        <Button
          variant="secondary"
          onClick={() => values.onGoogleSignIn()}
          rounded
          style={{ width: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex', gap: '8px' }}
        >
          <ButtonContent>
            <GoogleIcon />
            <span>Log in with Google</span>
          </ButtonContent>
        </Button>
      </Subheading>
      <ButtonRowSeparator>OR</ButtonRowSeparator>
      <TextField
        autoFocus
        label="Work email"
        placeholder="Enter your work email"
        onChange={e => setValue(e.target.value)}
        onEnter={(!buttonDisabled && onSubmit) || undefined}
        value={value}
        testId={'step-email-input'}
      />
      <Button
        onClick={onSubmit}
        variant="purple-dark"
        disabled={buttonDisabled}
        data-testid={'step-email-button'}
        rounded
      >
        Continue
      </Button>
      <Text variant="body-text" className={classes.getStarted}>
        New to HiPeople?{' '}
        <Link to={'/sign-up'} className={classes.link}>
          Get started
        </Link>
      </Text>
    </>
  )

  function onSubmit() {
    dispatch(setEmail(value))
  }
}

export default Component
Component.displayName = 'StepEmail'
