import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import { colors, px2rem, scale, size, space, style, weight } from '../../core'

export interface Props {
  head?: boolean
  highlight?: boolean
  pointer?: boolean
  center?: boolean
  centerVert?: boolean
}

const LinkRow = style()
  .block({ width: size.fill, height: size.fill })
  .text({ color: colors.silver, nodecoration: true })

const Link = style().color({ fg: colors.black }).text({ nodecoration: true })

const Highlight = style().color({ fg: colors.black }).text({ weight: weight.normal }).pointer()

const Head = text
  .bodyText()
  .size({ height: px2rem(42) })
  .center({ vertical: true })
  .spacing({ outer: [space.auto, space.none] })
  .border({
    top: '1px solid',
    bottom: '1px solid',
    color: colors.lightMoonGray,
  })
  .color({
    bg: colors.lightMoonGray,
    fg: designSystemColors.typographySecondary,
  })

export default style()
  .spacing({ inner: [space.s, scale.xxxs] })
  .color({ fg: colors.midGray, bg: colors.white })
  .border({
    top: '',
    bottom: '1px solid',
    color: colors.lightestGray,
  })
  .select('> a', LinkRow)
  .select('a', Link)
  .cond((props: Props) => !!props.center, style().center())
  .cond((props: Props) => !!props.centerVert, style().center({ vertical: true, horizontal: false }))
  .cond((props: Props) => !!props.highlight, Highlight)
  .cond((props: Props) => !!props.head, Head)
  .cond((props: Props) => !!props.pointer, style().pointer())
  .element()
