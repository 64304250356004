import { css } from '@emotion/css'
import isPropValid from '@emotion/is-prop-valid'
import React, { createElement, forwardRef } from 'react'
import setBackground, { IOptions as BackgroundOptions } from './bg'
import setBorder, { IOptions as BorderOptions } from './border'
import box, { IOptions as BoxOptions } from './box'
import color, { IOptions as ColorOptions } from './color'
import { override } from './css'
import flex, { IOptions as FlexOptions } from './flex'
import grid, { IOptions as GridOptions } from './grid'
import setList, { IOptions as ListOptions } from './list'
import position, { IOptions as PositionOptions } from './position'
import Rows, { ICondFn } from './rows'
import setSpacing, { IOptions as SpacingOptions } from './spacing'
import setTransitions, { ITransition } from './transitions'
import setType, { IOptions as TypeOptions } from './type'

const LENGTH_OF_COMPONENT_ID = 6
function generateId() {
  const arr = new Uint8Array(LENGTH_OF_COMPONENT_ID / 2)
  if (window?.crypto !== undefined) {
    window.crypto.getRandomValues(arr)
  }
  return 'i' + Array.from(arr, dec => dec.toString(16).padStart(2, '0')).join('')
}

class Style {
  rows: Rows
  constructor() {
    this.rows = new Rows()
  }

  absolute(options?: PositionOptions): Style {
    position(
      this.rows,
      override(options || {}, {
        absolute: true,
      }) as PositionOptions,
    )
    return this
  }

  animation(animation: string) {
    this.set('animation', animation)
    this.set('WebkitAnimation', animation)
    return this
  }

  back(): Style {
    return this.depth({ back: true })
  }

  bg(options: BackgroundOptions): Style {
    setBackground(this.rows, options)
    return this
  }

  block(options?: BoxOptions): Style {
    box(
      this.rows,
      override(options || {}, {
        block: true,
      }),
    )
    return this
  }

  border(options: BorderOptions): Style {
    setBorder(this.rows, options)
    return this
  }

  box(options: BoxOptions): Style {
    box(this.rows, options)
    return this
  }

  blur(size: number): Style {
    this.rows.set('OFilter', `blur(${size}px)`)
    this.rows.set('msFilter', `blur(${size}px)`)
    this.rows.set('MozFilter', `blur(${size}px)`)
    this.rows.set('WebkitFilter', `blur(${size}px)`)
    return this
  }

  center(options?: { vertical?: boolean; horizontal?: boolean }): Style {
    this.rows.set('display', 'flex')

    const vertical = !options || options.vertical === true
    const horizontal = !options || options.horizontal === true

    if (vertical) this.rows.set('alignItems', 'center')
    if (horizontal) this.rows.set('justifyContent', 'center')

    return this
  }

  circle(): Style {
    return this.border({ radius: '100%' })
  }

  clone(): Style {
    const clone = new Style()
    clone.rows.raw = this.rows.raw.slice()
    return clone
  }

  color(options: ColorOptions): Style {
    color(this.rows, options)
    return this
  }

  cond(fn: ICondFn, style: Style): Style {
    this.rows.add({ condfn: fn, value: style.rows })
    return this
  }

  content(text: string): Style {
    this.rows.set('content', `"${text}"`)
    return this
  }

  cover(image: string): Style {
    this.bg({ image, cover: true })
    return this
  }

  css(props: { [name: string]: any }) {
    return css(this.rows.compile(props))
  }

  cursor(cursor: 'default' | 'pointer' | 'col-resize' | 'not-allowed' | 'wait'): Style {
    this.rows.set('cursor', cursor)
    return this
  }

  depth(options: { front?: boolean; back?: boolean; index?: number }): Style {
    if (options.front) {
      this.rows.set('zIndex', '9999999')
    }

    if (options.back) {
      this.rows.set('zIndex', '-1')
    }

    if (options.hasOwnProperty('index')) {
      this.rows.set('zIndex', String(options.index))
    }

    return this
  }

  easein(time: number, props?: string[]): Style {
    this.transitions('ease-in', time, props)
    return this
  }

  easeout(time: number, props?: string[]): Style {
    this.transitions('ease-out', time, props)
    return this
  }

  element(tag?: string) {
    const elementType = tag || 'div'
    const elementId = generateId()
    // eslint-disable-next-line react/display-name
    const FunctionalComponent = forwardRef((props: any, ref) => {
      if (props.fill === true || props.overflow === true) {
        // delete key fill from props to avoid warning
        const { fill, overflow, ...rest } = props
        props = rest
      }
      // Invalid html props are filtered out
      // https://emotion.sh/docs/styled#customizing-prop-forwarding
      const validProps = Object.fromEntries(Object.entries(props).filter(([key]) => isPropValid(key)))

      return createElement(
        elementType,
        {
          ...validProps,
          className: `${this.css(props)} ${elementId} ${props.className || ''}`,
          ref,
        },
        props.children,
      )
    }) as any as React.FC<any> & { className?: string; __style__: Style }

    FunctionalComponent.displayName = `styled.${elementType}`
    FunctionalComponent.__style__ = this
    FunctionalComponent.toString = () => `.${elementId}`

    return FunctionalComponent
  }

  elementFromComponent<TProps extends { className?: string; children?: React.ReactNode }>(
    Component: React.FunctionComponent<TProps> | React.ComponentClass<TProps>,
    options?: { filteredProps?: string[] },
  ) {
    type TNewProps = TProps & Record<string, any>
    const elementId = generateId()
    const filteredProps = options?.filteredProps
    // @ts-ignore
    // eslint-disable-next-line react/display-name
    const FunctionalComponent = forwardRef((props: TNewProps, ref) => {
      // Invalid html props are filtered out
      // https://emotion.sh/docs/styled#customizing-prop-forwarding
      const validProps = filteredProps
        ? (Object.fromEntries(Object.entries(props).filter(([key]) => !filteredProps.includes(key))) as TProps)
        : props

      return createElement(
        Component,
        {
          ...validProps,
          className: `${this.css(props)} ${elementId} ${props.className || ''}`,
          ref,
        },
        props.children,
      )
    }) as any as React.FC<TNewProps> & { className?: string; __style__: Style }

    FunctionalComponent.displayName = Component.displayName || 'styled'
    FunctionalComponent.__style__ = this
    FunctionalComponent.toString = () => `.${elementId}`

    return FunctionalComponent
  }

  fg(colorCode: string): Style {
    this.color({ fg: colorCode })
    return this
  }

  fixed(options?: PositionOptions): Style {
    position(
      this.rows,
      override(options || {}, {
        fixed: true,
      }) as PositionOptions,
    )
    return this
  }

  flex(options?: FlexOptions): Style {
    flex(this.rows, options || {})
    return this
  }

  inlineFlex(options?: FlexOptions): Style {
    flex(this.rows, options || {})
    this.rows.set('display', 'inline-flex')
    return this
  }

  front(): Style {
    return this.depth({ front: true })
  }

  grid(options?: GridOptions): Style {
    grid(this.rows, options || {})
    return this
  }

  hidden(options?: BoxOptions): Style {
    box(
      this.rows,
      override(options || {}, {
        hidden: true,
      }),
    )
    return this
  }

  inline(options?: BoxOptions): Style {
    box(
      this.rows,
      override(options || {}, {
        inline: true,
      }),
    )
    return this
  }

  inlineBlock(options?: BoxOptions): Style {
    box(
      this.rows,
      override(options || {}, {
        inlineBlock: true,
      }),
    )
    return this
  }

  invisible(options?: BoxOptions): Style {
    box(
      this.rows,
      override(options || {}, {
        invisible: true,
      }),
    )
    return this
  }

  list(options: ListOptions): Style {
    setList(this.rows, options)
    return this
  }

  mono(options?: TypeOptions): Style {
    setType(
      this.rows,
      override(options || {}, {
        family: ['Menlo', 'Inconsolata', 'Fira Mono', 'Droid Sans Mono', 'Consolas', 'monaco', 'monospace'],
      }),
    )

    return this
  }

  noborders(): Style {
    return this.border({ around: '0px' })
  }

  nooutline(): Style {
    this.rows.set('outline', 'none')
    return this
  }

  nospacing(): Style {
    this.spacing({ inner: '0', outer: '0' })
    return this
  }

  nooverflow(): Style {
    this.rows.set('overflow', 'hidden')
    return this
  }

  whitespace(s: string): Style {
    this.rows.set('whiteSpace', s)
    return this
  }

  opacity(n: number): Style {
    this.rows.set('opacity', String(n))
    return this
  }

  order(n: number): Style {
    this.rows.set('order', String(n))
    return this
  }

  pointer(): Style {
    this.rows.set('cursor', 'pointer')
    return this
  }

  rotate(degree: number): Style {
    this.rows.set('transform', `rotate(${degree}deg)`)
    return this
  }

  round(radius: string): Style {
    return this.border({ radius })
  }

  relative(options?: PositionOptions): Style {
    position(
      this.rows,
      override(options || {}, {
        relative: true,
      }) as PositionOptions,
    )
    return this
  }

  sans(options?: TypeOptions): Style {
    setType(
      this.rows,
      override(options || {}, {
        family: [
          'Work Sans',
          '-apple-system',
          'BlinkMacSystemTypography',
          'Segoe UI',
          'Roboto',
          'Oxygen',
          'Ubuntu',
          'Cantarell',
          'Fira Sans',
          'Droid Sans',
          'Helvetica Neue',
          'sans-serif',
        ],
      }),
    )

    return this
  }

  scale(...values: number[]): Style {
    this.rows.set('transform', `scale(${values.join(', ')})`)
    return this
  }

  scroll({ x, y }: { x?: 'scroll' | 'auto' | 'hidden'; y?: 'scroll' | 'auto' | 'hidden' }): Style {
    if (x) this.rows.set('overflowX', x)
    if (y) this.rows.set('overflowY', y)
    return this
  }

  select(selector: string, style: Style): Style {
    this.rows.set(selector, style.rows)
    return this
  }

  selectChild(n: number, style: Style): Style {
    this.rows.set(`> *:nth-child(${n})`, style.rows)
    return this
  }

  serif(options?: TypeOptions): Style {
    setType(
      this.rows,
      override(options || {}, {
        family: [
          'Recoleta',
          'Apple Garamond',
          'Baskerville',
          'Times New Roman',
          'Droid Serif',
          'Times',
          'Source Serif Pro',
          'serif',
        ],
      }),
    )

    return this
  }

  set(property: string, value: string): Style {
    this.rows.set(property, value)
    return this
  }

  shadow(opacity?: number): Style {
    if (typeof opacity === 'undefined') {
      opacity = 0.15
    }

    this.rows.set('boxShadow', `0 1px 3px 0 rgba(0,0,0,${opacity})`)

    return this
  }

  size(options: BoxOptions): Style {
    box(this.rows, options)
    return this
  }

  spacing(options: SpacingOptions): Style {
    setSpacing(this.rows, options)
    return this
  }

  sticky(options?: PositionOptions): Style {
    position(
      this.rows,
      override(options || {}, {
        sticky: true,
      }) as PositionOptions,
    )
    return this
  }

  stretch(options?: BoxOptions): Style {
    box(
      this.rows,
      override(options || {}, {
        width: '100%',
        height: '100%',
      }),
    )

    return this
  }

  transition(options: ITransition): Style {
    setTransitions(this.rows, [options])
    return this
  }

  transitions(timingFn: string, duration: number, props?: string[]): Style {
    if (!Array.isArray(props)) {
      return this.transition({ fn: timingFn, duration })
    }

    setTransitions(
      this.rows,
      props.map(prop => {
        return {
          fn: timingFn,
          duration,
          prop,
        }
      }),
    )
    return this
  }

  uppercase(options?: TypeOptions): Style {
    setType(this.rows, override(options || {}, { uppercase: true }))
    return this
  }

  visible(options?: BoxOptions): Style {
    box(
      this.rows,
      override(options || {}, {
        invisible: false,
      }),
    )
    return this
  }

  text(options: TypeOptions): Style {
    setType(this.rows, options)
    return this
  }

  transparent(): Style {
    this.rows.set('background', 'transparent')
    return this
  }

  with<T>(fn: (props: T) => Style): Style {
    this.rows.add({ value: (props: T) => fn(props).rows })
    return this
  }

  screen(options: { gte?: string; lte?: string }, style: Style): Style {
    const parts: string[] = []
    if (options.gte) {
      parts.push(`(min-width: ${options.gte})`)
    }
    if (options.lte) {
      parts.push(`(max-width: ${options.lte})`)
    }
    this.rows.set(`@media ${parts.join(' and ')}`, style.rows)
    return this
  }
}

declare const style: Style
type Element = ReturnType<typeof style.element>

export default (baseElement?: Element) => {
  if (!baseElement) {
    return new Style()
  }
  return baseElement.__style__.clone()
}
