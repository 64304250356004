import { useCandidate } from 'providers/candidates'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { findByCandidateId } from 'selectors/orgs'
import { RootState } from 'store'

export function useOrg({ candidateId }: { candidateId: string }) {
  // Trigger org data fetch via candidate
  const { isLoading } = useCandidate({ candidateId })

  const orgSelector = useCallback(
    (state: RootState) => findByCandidateId(state, isLoading ? '' : candidateId),
    [candidateId, isLoading],
  )
  const org = useSelector(orgSelector)

  return {
    isLoading: !org,
    org,
  }
}

export function useOrgPrivacyPolicyLink({ candidateId }: { candidateId: string }) {
  const { isLoading, org } = useOrg({ candidateId })

  const privacyPolicyLink = org?.fields.privacy_policy_link || import.meta.env.VITE_CANDIDATE_PRIVACY_POLICY_LINK || ''
  return {
    isLoading,
    privacyPolicyLink,
  }
}
