import { LockedByPlan } from '@common/components'
import { CandidateExperienceMode } from 'api/org-settings'
import { Badge } from 'components/Badge'
import Block, { Header } from 'components/Block'
import { RadioButton } from 'components/RadioButton'
import RadioGroup from 'components/RadioButton/RadioGroup'
import { colors, px2rem, size, style } from 'core'
import { designSystemColors as designColours } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import * as tracking from 'core/track'
import { lockedContentTracking } from 'core/track/locked-content'
import { useFeatureFlag } from 'providers/features'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as selectors from 'selectors'
import { RootState } from 'store'
import { requestProductUpgrade, save } from 'store/settings/org'

const Link = style().sans({ color: colors.gray }).element('a')

const RadioGroupStyle = style()
  .flex({ direction: 'column' })
  .select('> *', style().spacing({ inner: [px2rem(24), px2rem(24)] }))
  .select(
    '> :not(:last-child)',
    style().border({
      bottom: '1px solid',
      color: designColours.borderDefault,
    }),
  )
  .css({})

const BadgeStyle = style().inline().size({ width: size.auto }).element()
const UpgradeLink = text.bodyInteractive().pointer().element('a')
const Arrow = style().sans({ nodecoration: true }).element('span')

export const CandidateExperienceFeedbackToggle = () => {
  const dispatch = useDispatch()

  const orgId = useSelector((state: RootState) => selectors.orgs.currentId(state))
  const org = useSelector(selectors.orgs.current)
  const orgSettings = useSelector((state: RootState) => selectors.orgSettings.getByOrgId(state, orgId))

  const isOrgPaidPlan = useSelector(selectors.orgs.isOrgPaidPlan)

  const { isEnabled: isInviteViaLinkEnabled } = useFeatureFlag('cx-invite-via-link')

  const loading = useSelector((state: RootState) => state.orgSettings.loading)

  const paymentPending = !!org.fields.paid_products['candidate-experience']?.pending
  const payment = (org.fields.paid_products['candidate-experience']?.paid_at || 0) > 0

  return (
    <Block title="Candidate Experience Feedback" noSpacing testId="ats-automation">
      <Header>
        Enable collecting feedback from your candidates about their hiring process. By toggling the feature on, every
        future candidate will receive an invitation to complete the candidate experience feedback survey.{' '}
        <Link
          href="https://hipeople.notion.site/Candidate-Feedback-ec5744e7f2994500af6138e3155d1ef1"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more about the feature here
        </Link>
      </Header>
      <RadioGroup<CandidateExperienceMode>
        className={RadioGroupStyle}
        value={orgSettings?.fields.candidate_experience_mode || 'none'}
        onChange={(candidateExperienceMode: CandidateExperienceMode) => {
          dispatch(save(orgId || '', {}, { candidateExperienceMode }))
          tracking.orgs.saveSelectionCandidateExperience()
        }}
        disabled={loading}
      >
        <RadioButton value="none">Disabled</RadioButton>
        <RadioButton
          value="post-reference-check"
          description="We will send surveys to candidates after they've completed their reference check process with HiPeople."
        >
          Linked with Reference Check
        </RadioButton>
        {isInviteViaLinkEnabled ? (
          <RadioButton
            value="link"
            description="You will be able to invite manually your hired and rejected candidates at any stage of the process by grabbing a link directly on the Candidate Experience dashboard."
          >
            Invite candidate via link
          </RadioButton>
        ) : (
          <></>
        )}
        <LockedByPlan
          tracking={lockedContentTracking}
          key={'ats-hired-or-rejected'}
          locked={!isOrgPaidPlan}
          upgradeTo={'Scale'}
          modalData={{
            learnMoreLink: 'https://intercom.help/hipeople/en/articles/7826759-integrating-a-job-with-your-chosen-ats',
            learnMoreAbout: 'Integrating Your ATS',
          }}
        >
          <RadioButton
            value="ats-hired-or-rejected"
            description="We will send surveys to each candidate hired or rejected in your integrated ATS."
            header={(() => {
              if (paymentPending)
                return (
                  <BadgeStyle>
                    <Badge copy={'Your account is in review'} theme={'orange'} icon={'clock'} />
                  </BadgeStyle>
                )
              if (!payment)
                return (
                  <BadgeStyle>
                    <Badge copy={'Upgrade'} theme={'blue'} icon={'sparkles'} />
                  </BadgeStyle>
                )
            })()}
            footer={(() => {
              if (!payment && !paymentPending)
                return (
                  <Arrow>
                    <UpgradeLink onClick={() => dispatch(requestProductUpgrade(orgId, 'candidate-experience'))}>
                      {'Upgrade your plan now'}
                    </UpgradeLink>
                    {' >'}
                  </Arrow>
                )
            })()}
            tooltip={(() => {
              if (!payment)
                return {
                  copy: 'Premium feature. Upgrade to automate your surveys.',
                }
            })()}
            disabled={!payment}
          >
            ATS Automation
          </RadioButton>
        </LockedByPlan>
      </RadioGroup>
    </Block>
  )
}
