import { Fields, RESOURCE } from 'api/skills'
import { RootState } from 'store'
import lookup from './lookup'
import * as questions from './questions'

export function findById(state: RootState, id: string) {
  return lookup<Fields>(state, RESOURCE, id)
}

export function findByQuestionId(state: RootState, questionId: string) {
  const question = questions.findById(state, questionId)

  if (!question || !question.fields.skill_id) {
    return undefined
  }

  return findById(state, question.fields.skill_id)
}
