import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Block from '../../components/Block'
import Button from '../../components/Button/ButtonV1'
import Form from '../../components/Form'
import ProfileImage from '../../components/ProfileImage'
import TextField, { Label } from '../../components/TextField'
import { size, space, style } from '../../core'
import * as selectors from '../../selectors'
import { RootState } from '../../store'
import { OrgChanges, save, SettingsChanges } from '../../store/settings/org'
import ColorSelector from '@common/components/ColorSelector/ColorSelector'
import { LockedByPlan, LockIcon, Text } from '@common/components'
import { lockedContentTracking } from 'core/track/locked-content'
import * as styles from '@common/styles'

const Grid = style()
  .grid({ columns: [size.xxxl, size.auto] })
  .spacing({ columns: space.l })
  .element()

interface MyOrganizationProps {
  canProvideSupport: boolean
}

const MyOrganization: React.FC<MyOrganizationProps> = props => {
  const dispatch = useDispatch()

  const org = useSelector((state: RootState) => {
    return selectors.orgs.getById(state, selectors.orgs.currentId(state))
  })

  const orgSettings = useSelector((state: RootState) => {
    return selectors.orgSettings.getByOrgId(state, selectors.orgs.currentId(state))
  })

  const isOrgPaidPlan = useSelector(selectors.orgs.isOrgPaidPlan)

  const loading = useSelector((state: RootState) => {
    return state.orgSettings.loading
  })
  const [orgChanges, setOrgChanges] = React.useState<OrgChanges>({})
  const [settingsChanges, setSettingsChanges] = React.useState<SettingsChanges>({})

  const notchanged = Object.values(orgChanges).length + Object.values(settingsChanges).length === 0

  const savedMinReferenceCount = orgSettings === undefined ? '' : `${orgSettings.fields.min_reference_count}`

  const brandingColor = settingsChanges.brandingColorPrimary || orgSettings?.fields.branding_color_primary || '#4c49ee'

  return (
    <Block title="My Organization" testId="MyOrganization">
      <Grid>
        <ProfileImage
          storagePath={import.meta.env.VITE_S3_LOGO_IMAGES_FOLDER}
          src={(orgChanges.logoImage && URL.createObjectURL(orgChanges.logoImage)) || org.fields.logo_image_url}
          onChange={logoImage => {
            setOrgChanges(changes => ({ ...changes, logoImage }))
          }}
          name={org.fields.name}
        />
        <Form>
          <TextField
            label="Name"
            disabled={!props.canProvideSupport}
            value={org.fields.name || ''}
            change={orgChanges.name}
            onChange={e => {
              const name = e.target.value
              setOrgChanges(orgChanges => ({ ...orgChanges, name }))
            }}
          />
          <TextField
            label="Slug"
            value={org.fields.slug || ''}
            change={orgChanges.slug}
            disabled={!props.canProvideSupport}
            onChange={e => {
              const slug = e.target.value
              setOrgChanges(changes => ({ ...changes, slug }))
            }}
          />
          <TextField
            label="Minimum required references"
            value={savedMinReferenceCount}
            change={settingsChanges.minReferenceCount}
            onChange={e => {
              const minReferenceCount = e.target.value
              if (/\D/.test(minReferenceCount)) return
              setSettingsChanges(settingsChanges => ({
                ...settingsChanges,
                minReferenceCount,
              }))
            }}
          />
          <LockedByPlan
            tracking={lockedContentTracking}
            locked={!isOrgPaidPlan}
            upgradeTo={'Scale'}
            modalData={{
              learnMoreLink: 'https://intercom.help/hipeople/en/articles/9395101-setting-custom-colors',
              learnMoreAbout: 'Setting Custom Colors',
            }}
            lockIconDisabled={true}
          >
            <div>
              <Label>
                Primary branding color{' '}
                <LockIcon visible={!isOrgPaidPlan} style={{ verticalAlign: 'middle', marginLeft: '0' }} />
              </Label>
              <ColorSelector
                color={brandingColor}
                setColor={color => {
                  setSettingsChanges(settingsChanges => ({
                    ...settingsChanges,
                    brandingColorPrimary: color,
                  }))
                }}
              />
              <Text variant={'body-text'} style={{ marginTop: '1em' }}>
                The selected color will be applied to the look and feel of your assessments and reference checks,
                including buttons and input fields.
              </Text>
              <Text style={{ margin: '1em 0' }} variant={'body-emphasis'}>
                Preview
              </Text>
              <div
                style={{
                  padding: '1em 5em',
                  border: '1px solid #E4E4E4',
                  borderRadius: '8px',
                  display: 'inline-block',
                  pointerEvents: 'none',
                }}
              >
                <Button
                  style={{
                    background: brandingColor,
                    padding: '0 3em',
                    color: styles.textColorForBackground(brandingColor),
                  }}
                >
                  Button
                </Button>
              </div>
            </div>
          </LockedByPlan>
          <Button
            primary
            onClick={() => (org ? dispatch(save(org.id, orgChanges, settingsChanges)) : null)}
            disabled={loading || notchanged}
          >
            Save
          </Button>
        </Form>
      </Grid>
    </Block>
  )
}

export default MyOrganization
