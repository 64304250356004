import { colors, rem, scale, size, space, style } from 'core'
import { designSystemColors as DesignSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import * as React from 'react'
import { Icon } from '../../components/Icons'

const selected = style().color({
  bg: DesignSystemColors.typographyPrimaryAccent,
  fg: colors.white,
})

const Option = text
  .secondaryBodyInteractive()
  .spacing({ inner: space.xs })
  .color({ bg: DesignSystemColors.backgroundNeutralPrimary })
  .sans({ size: scale.s })
  .select(':hover', selected)
  .select(':hover *', selected)
  .cond(({ selected }) => selected, selected)
  .cond(({ selected }) => selected, style().select('*', selected))
  .pointer()
  .element()

const Content = style().flex({ alignItems: 'center' }).element()

const Label = style().text({ ellipsis: true }).element()

const IconColumn = style()
  .size({ width: rem(2) })
  .color({ fg: colors.lightSilver })
  .select(':hover', selected)
  .element()

const ImageColumn = style()
  .size({ width: size.s })
  .round('100%')
  .with((props: { src: string }) => style().bg({ image: props.src, cover: true }))
  .element()

export interface Props<T> {
  children: React.ReactNode
  value: T
  icon?: string
  emoji?: string
  imageUrl?: string
  selected?: boolean
  onSelect?: (value: T) => void
  emphasisOnSelect?: boolean
}

function Component<T>(props: Props<T>) {
  const icon = props.selected ? 'check' : props.icon

  return (
    <Option
      key={props.value}
      selected={props.selected || false}
      onClick={() => props.onSelect && props.onSelect(props.value)}
    >
      <Content>
        {props.imageUrl ? (
          <ImageColumn src={props.imageUrl}></ImageColumn>
        ) : props.emoji ? (
          <IconColumn>{props.emoji}</IconColumn>
        ) : icon ? (
          <IconColumn>
            <Icon name={icon} />
          </IconColumn>
        ) : null}
        <Label>{props.children}</Label>
      </Content>
    </Option>
  )
}

export default Component
Component.displayName = 'Option'
