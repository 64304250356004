import { SubText } from 'App/ProductPages/Shared'
import React from 'react'
import ContentContainer from '../../components/Container/ContentContainer'
import DashboardLayout from '../../components/DashboardLayout'
import ProductPageShell from '../../components/ProductPageLayout'
import HeroGrid from '../../components/ProductPageLayout/HeroGrid'
import HeroImgCandidateExperience from './HeroImgCandidateExperience.png'

interface Props {
  button: JSX.Element
}
const EmptyCandidateExperiencePage: React.FC<Props> = props => {
  return (
    <DashboardLayout>
      <ProductPageShell title={'Candidate Experience Analytics'}>
        <ContentContainer>
          <HeroGrid
            heading={'Make every candidate experience matter'}
            subheading={
              <SubText>
                HiPeople helps you to measure, analyze, and enhance your candidate experience along the recruitment
                process. <br />
                <br />
                ✔︎ Increase your quality of hire <br />
                ✔︎ Optimize your time to hire <br />
                ✔︎ Decrease your cost of hire
              </SubText>
            }
            image={{
              src: HeroImgCandidateExperience,
              altText: 'User interface of HiPeople dashboard showing insights from candidate experience',
            }}
          >
            {props.button}
          </HeroGrid>
        </ContentContainer>
      </ProductPageShell>
    </DashboardLayout>
  )
}

export default EmptyCandidateExperiencePage
