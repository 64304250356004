import Button from 'components/Button/ButtonV2'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import UserHint from './UserHint'

const Component = () => {
  const { ssoLoginURL } = useSelector((state: RootState) => state.login)

  return (
    <>
      <UserHint />
      <Button as={'a'} buttonType={'confirm'} href={ssoLoginURL}>
        Continue login
      </Button>
    </>
  )
}

export default Component
Component.displayName = 'StepSSO'
