import React from 'react'
import { style } from '../../core'
import * as selectors from '../../selectors'
import LineChart from './LineChart'
import RadarChart from './RadarChart'

interface Props {
  data: selectors.charts.Data
  options: selectors.charts.Options
}

const Container = style().center({ horizontal: true }).element()

const Component = (props: Props) => {
  return (
    <Container>
      {props.options.lineChart ? <LineChart data={props.data} options={props.options.lineChart} /> : null}
      {props.options.radarChart ? <RadarChart data={props.data} options={props.options.radarChart} /> : null}
    </Container>
  )
}

export default Component
Component.displayName = 'Chart'
