import { get, put } from './methods'
import { APIResponse, Entity, sortByUpdatedAt } from './request'

export const RESOURCE = 'assessments'
const ANSWER_FORMATS = {
  Text: 'multi_line_input',
  'Camera Record': 'camera_upload',
  'PDF Upload': 'pdf_upload',
  'Video Upload': 'video_upload',
  'File Upload': 'file_upload',
}

interface Fields {
  id: string
  name: string
  assessment_version_id: string
  image_url: string
  created_at: number
  updated_at: number
}

export async function load(orgid: string): Promise<APIResponse<Entity<Fields>[]>> {
  const [resp, errors] = await get<Entity<Fields>[]>(`organizations/${orgid}/assessments`)
  if (errors) {
    return [resp, errors]
  }

  if (resp) {
    const sorted = resp.result.sort(sortByUpdatedAt)
    return [{ ...resp, result: sorted }, errors]
  }

  return [resp, errors]
}

export interface CustomTestItem {
  title: string
  format: string
  required: boolean
}

export async function create({
  orgid,
  genericAssessmentId,
  name,
  customQuestions,
}: {
  orgid: string
  genericAssessmentId: string
  name: string
  customQuestions: CustomTestItem[]
}): Promise<APIResponse<Entity<Fields>>> {
  const testItems = customQuestions.map(item => {
    return {
      question: item.title,
      [ANSWER_FORMATS[item.format]]: true,
      required: !!item.required,
    }
  })

  return put<Entity<Fields>>(`/assessments`, {
    organization_id: orgid,
    generic_assessment_id: genericAssessmentId,
    name: name,
    test_items: testItems,
  })
}
