import * as api from 'api'
import { Card } from 'common/src/components/Card/Card'
import React, { useMemo } from 'react'
import { FaCheckCircle, FaQuestionCircle } from 'react-icons/fa'
import { FaCircleXmark } from 'react-icons/fa6'
import classes from './CheatingPrevention.module.scss'

import { Text, Tooltip } from '@common/components'
import { Entity } from 'api/request'
import { designSystemColors } from 'core/design-system/colors'

interface Props {
  questionnaireSubmission: Entity<api.questionnaireSubmissions.Fields> | undefined
}

export const CheatingPreventionCard = (props: Props) => {
  const { deviceName, isMobile } = useMemo(
    () =>
      props.questionnaireSubmission?.fields?.device
        ? getDevice(props.questionnaireSubmission.fields.device)
        : { deviceName: 'N/A', isMobile: false },
    [props.questionnaireSubmission?.fields.device],
  )
  if (
    props.questionnaireSubmission?.fields.times_left_full_screen === undefined ||
    props.questionnaireSubmission?.fields.time_mouse_left_window === undefined
  )
    return null
  return (
    <Card container className={classes.card} data-testid="cheating-prevention-card">
      <Text variant="body-emphasis" marginBottom>
        Cheating Prevention
      </Text>
      <div className={classes.content}>
        <div className={classes.row}>
          <Text variant="small-body-text">Mouse always inside the assessment screen</Text>
          <Text
            variant="small-body-text"
            style={{
              color: isMobile
                ? designSystemColors.typographySecondary
                : props.questionnaireSubmission.fields.time_mouse_left_window === 0
                ? designSystemColors.uiStatusSuccess
                : designSystemColors.uiStatusWarningSecondary,
            }}
          >
            {isMobile ? (
              <Tooltip text="N/A (Mobile device used)">
                <FaQuestionCircle size={16} />
              </Tooltip>
            ) : props.questionnaireSubmission.fields.time_mouse_left_window === 0 ? (
              <Tooltip text="Mouse always inside the assessment screen">
                <FaCheckCircle size={16} />
              </Tooltip>
            ) : (
              <Tooltip
                text={`Mouse left window ${props.questionnaireSubmission.fields.time_mouse_left_window.toLocaleString()} times`}
              >
                <FaCircleXmark size={16} />
              </Tooltip>
            )}
          </Text>
        </div>
        <div className={classes.row}>
          <Text variant="small-body-text">Full screen mode always active</Text>
          <Text
            variant="small-body-text"
            style={{
              color: isMobile
                ? designSystemColors.typographySecondary
                : props.questionnaireSubmission.fields.times_left_full_screen === 0
                ? designSystemColors.uiStatusSuccess
                : designSystemColors.uiStatusWarningSecondary,
            }}
          >
            {isMobile ? (
              <Tooltip text="N/A (Mobile device used)">
                <FaQuestionCircle size={16} />
              </Tooltip>
            ) : props.questionnaireSubmission.fields.times_left_full_screen === 0 ? (
              <Tooltip text="Full screen mode always active">
                <FaCheckCircle size={16} />
              </Tooltip>
            ) : (
              <Tooltip
                text={`Left full screen ${props.questionnaireSubmission.fields.times_left_full_screen.toLocaleString()} times`}
              >
                <FaCircleXmark size={16} />
              </Tooltip>
            )}
          </Text>
        </div>
        <div className={classes.row}>
          <Text variant="small-body-text">Device</Text>
          <Text variant="small-body-text">{deviceName ?? 'N/A'}</Text>
        </div>
        <div className={classes.row}>
          <Text variant="small-body-text">Location</Text>
          <Text variant="small-body-text">
            {props.questionnaireSubmission.fields.city && props.questionnaireSubmission.fields.country
              ? props.questionnaireSubmission.fields.city + ', ' + props.questionnaireSubmission.fields.country
              : 'N/A'}
          </Text>
        </div>
        <div className={classes.row}>
          <Text variant="small-body-text">IP Address</Text>
          <Text variant="small-body-text">{props.questionnaireSubmission.fields.ip ?? 'N/A'}</Text>
        </div>
      </div>
    </Card>
  )
}

function getDevice(agentString: string) {
  const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
  return regex.test(agentString) ? { deviceName: 'Mobile', isMobile: true } : { deviceName: 'Desktop', isMobile: false }
}
