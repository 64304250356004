import { ModuleEntity } from 'api/modules'
import { QuestionnaireAnswerEntity } from 'api/questionnaire-answers'
import {
  QuestionnaireSubmissionEntity,
  RESOURCE as QuestionnaireSubmissionResource,
} from 'api/questionnaire-submissions'
import { Fields, RESOURCE } from 'api/questionnaires'
import { QuestionEntity, RESOURCE as QuestionResource } from 'api/questions'
import { AnswersType } from 'App/Candidate/ByQuestion/QuestionnaireAnswerBlock'
import { sendLogMessage } from 'core/track/errors'
import { RootState } from 'store'
import { findById as findCandidateById } from './candidates'
import lookup from './lookup'
import { findById as findModuleById } from './modules'
import {
  findByCandidateId as findQuestionnaireAnswersByCandidateId,
  findByQuestionnaireSubmissionId as findQuestionnaireAnswersByQuestionnaireSubmissionId,
} from './questionnaire-answers'
import { findByQuestionnaireId as findQuestionnaireEntriesByQuestionnaireId } from './questionnaire-entries'
import { findById as findQuestionnaireSubmissionById } from './questionnaire-submissions'
import { findById as findQuestionById, findByModuleId, findByModuleId as findQuestionByModuleId } from './questions'
import { findByCandidateId as findReferencesByCandidateId } from './references'
import {
  findById as findResponseOptionById,
  findByQuestionId as findResponseOptionsByQuestionId,
} from './response-options'

export function findById(state: RootState, id: string) {
  return lookup<Fields>(state, RESOURCE, id)
}

export function findQuestionnaireByCandidateId(state: RootState, candidateId: string) {
  const candidate = findCandidateById(state, candidateId)
  if (!candidate) {
    return undefined
  }

  const questions = findQuestionsByQuestionnaireId(state, candidate.fields.questionnaire_id)
  if (!questions) return

  const answers = findQuestionnaireAnswersByCandidateId(state, candidateId)

  return { questions, answers }
}

function findQuestionsByQuestionnaireId(state: RootState, questionnaireId: string) {
  const modulesAndQuestions = findQuestionsAndModulesByQuestionnaireId(state, questionnaireId)
  if (!modulesAndQuestions) return

  const questionsEntities = modulesAndQuestions
    .map(moduleOrQuestion => {
      if (moduleOrQuestion.resource === QuestionResource) {
        return [moduleOrQuestion] as QuestionEntity[]
      }
      const module: ModuleEntity = moduleOrQuestion as ModuleEntity
      return findQuestionByModuleId(state, module.id)
    })
    .filter(entity => entity !== undefined)
    .flat() as QuestionEntity[]

  return questionsEntities.map(question => {
    const responseOptions = findResponseOptionsByQuestionId(state, question.id)?.map(option => option.fields)

    return {
      ...question.fields,
      responseOptions,
    }
  })
}

function findQuestionsAndModulesByQuestionnaireId(state: RootState, questionnaireId: string) {
  const questionnaireEntries = findQuestionnaireEntriesByQuestionnaireId(state, questionnaireId)
  if (!questionnaireEntries) {
    return undefined
  }

  return questionnaireEntries
    .map(entry => findModuleById(state, entry.fields.module_id) || findQuestionById(state, entry.fields.question_id))
    .filter(entity => entity !== undefined) as (ModuleEntity | QuestionEntity)[]
}

export function findSidebarGroupedQuestionsModulesByQuestionnaireId(
  state: RootState,
  questionnaireId: string,
): { title: string; id: string; emoji: string }[] | undefined {
  const modulesAndQuestions = findQuestionsAndModulesByQuestionnaireId(state, questionnaireId)
  if (!modulesAndQuestions) return

  const items: { title: string; id: string; emoji: string }[] = []
  for (const questionOrModule of modulesAndQuestions) {
    if (questionOrModule.resource === QuestionResource) {
      const question = questionOrModule as QuestionEntity

      items.push({
        id: question.id,
        title: question.fields.copy?.dashboard?.title || '',
        emoji: question.fields.copy?.dashboard?.emoji || '',
      })
      continue
    }

    const module = questionOrModule as ModuleEntity
    if (items.some(e => e.id === module.id) || module.fields.exclude_from_summary) continue

    items.push({
      id: module.id,
      title: module.fields.name,
      emoji: module.fields.copy.emoji || '🛠',
    })
  }

  return items
}

function getPrimaryQuestion(questions: QuestionEntity[]) {
  const primaryQuestions = questions.filter(question => !question.fields.parent_id)

  if (primaryQuestions.length > 1) {
    sendLogMessage(
      'There can be only one primary question for a module',
      { primaryQuestions: JSON.stringify(primaryQuestions) },
      { debug: true },
    )
  }

  return primaryQuestions[0]
}

function getAnswerType(questions: QuestionEntity[]) {
  const isForcedTruth = questions.every(question => question.fields.response_type === 'forced-truth')
  if (isForcedTruth) {
    return 'forced-truth'
  }

  return getPrimaryQuestion(questions).fields.response_type
}

function getFollowUpQuestions(primaryQuestionId: string, questions: QuestionEntity[]) {
  return questions.filter(question => question.fields.parent_id === primaryQuestionId)
}

export function findGroupedQuestionsByQuestionnaireId(state: RootState, candidateId: string, questionnaireId: string) {
  const modulesAndQuestions = findQuestionsAndModulesByQuestionnaireId(state, questionnaireId)
  if (!modulesAndQuestions) return

  const questionnaire = findById(state, questionnaireId)
  if (!questionnaire) return

  const references = findReferencesByCandidateId(state, candidateId)

  const questionnaireSubmissionIds = references.map(
    ref => ref.relationships?.find(r => r.resource === QuestionnaireSubmissionResource)?.id || '',
  )

  const questionnaireSubmissions = questionnaireSubmissionIds
    .map(id => findQuestionnaireSubmissionById(state, id))
    .filter(submission => (submission?.fields.submitted_at ?? 0) > 0) as QuestionnaireSubmissionEntity[]

  const questionnaireAnswersGroupedByQuestion: Record<string, QuestionnaireAnswerEntity[]> = {}

  for (const questionnaireSubmissionId of questionnaireSubmissionIds) {
    const answers = findQuestionnaireAnswersByQuestionnaireSubmissionId(state, questionnaireSubmissionId)
    for (const answer of answers) {
      questionnaireAnswersGroupedByQuestion[answer.fields.question_id] =
        questionnaireAnswersGroupedByQuestion[answer.fields.question_id] ?? []
      questionnaireAnswersGroupedByQuestion[answer.fields.question_id].push(answer)
    }
  }

  const items: {
    title: string
    id: string
    emoji: string
    answers: AnswersType
  }[] = []

  for (const questionOrModule of modulesAndQuestions) {
    if (questionOrModule.resource === QuestionResource) {
      const question = questionOrModule as QuestionEntity

      switch (question.fields.response_type) {
        case 'optionset':
        case 'chips': {
          const answers: AnswersType = {
            type: question.fields.response_type,
            answers: {},
          }

          for (const questionnaireSubmission of questionnaireSubmissions) {
            const refAnswers = findQuestionnaireAnswersByQuestionnaireSubmissionId(
              state,
              questionnaireSubmission.id,
            ).filter(answer => answer.fields.question_id === question.id)
            if (refAnswers.length === 0) {
              continue
            }
            if (refAnswers.length === 1 && refAnswers[0]?.fields.is_skipped) {
              answers.answers[questionnaireSubmission.fields.reference_id] = {
                skipped: true,
              }
              continue
            }

            const input = refAnswers.find(answer => !answer.fields.selected_response_option && answer.fields.text_input)
              ?.fields.text_input

            const alreadyAnswered = new Set()
            const selectedResponseOptions = refAnswers
              .filter(answer => answer.fields.selected_response_option)
              .map(answer => findResponseOptionById(state, answer.fields.selected_response_option))
              .filter(responseOption => {
                if (alreadyAnswered.has(responseOption?.id)) {
                  return false
                }
                alreadyAnswered.add(responseOption?.id)
                return true
              })
              .map(responseOption => responseOption?.fields.copy?.caption || '')

            answers.answers[questionnaireSubmission.fields.reference_id] = {
              selected: selectedResponseOptions,
              input,
            }
          }
          items.push({
            id: question.id,
            title: question.fields.copy?.dashboard?.title || '',
            emoji: question.fields.copy?.dashboard?.emoji || '',
            answers,
          })

          break
        }

        case 'text-input': {
          const answers: AnswersType = {
            type: question.fields.response_type,
            answers: {},
          }

          for (const questionnaireSubmission of questionnaireSubmissions) {
            const refAnswer = findQuestionnaireAnswersByQuestionnaireSubmissionId(
              state,
              questionnaireSubmission.id,
            ).find(answer => answer.fields.question_id === question.id)

            if (refAnswer?.fields.is_skipped) {
              answers.answers[questionnaireSubmission.fields.reference_id] = {
                skipped: true,
              }
              continue
            }

            answers.answers[questionnaireSubmission.fields.reference_id] = {
              input: refAnswer?.fields.text_input,
              mediaURL: refAnswer?.fields.media_url,
            }
          }
          items.push({
            id: question.id,
            title: question.fields.copy?.dashboard?.title || '',
            emoji: question.fields.copy?.dashboard?.emoji || '',
            answers,
          })

          break
        }
      }
    }

    const module = questionOrModule as ModuleEntity
    if (
      items.some(e => e.id === module.id) ||
      module.fields.slug === 'reference-intro' ||
      module.fields.slug === 'talent-pool' ||
      module.fields.slug === 'sales-pool'
    )
      continue

    const allQuestions = findByModuleId(state, module.id) || []
    const answerType = getAnswerType(allQuestions)

    switch (answerType) {
      case 'optionset':
      case 'chips': {
        const answers: AnswersType = {
          type: answerType,
          answers: {},
        }

        for (const questionnaireSubmission of questionnaireSubmissions) {
          const primaryQuestion = getPrimaryQuestion(allQuestions)
          const refAnswers = findQuestionnaireAnswersByQuestionnaireSubmissionId(
            state,
            questionnaireSubmission.id,
          ).filter(answer => answer.fields.question_id === primaryQuestion.id)

          if (refAnswers.length === 0) {
            continue
          }

          if (refAnswers.length === 1 && refAnswers[0]?.fields.is_skipped) {
            answers.answers[questionnaireSubmission.fields.reference_id] = {
              skipped: true,
            }
            continue
          }

          const input = refAnswers.find(answer => !answer.fields.selected_response_option && answer.fields.text_input)
            ?.fields.text_input

          const alreadyAnswered = new Set()
          const selectedResponseOptions = refAnswers
            .filter(answer => answer.fields.selected_response_option)
            .map(answer => findResponseOptionById(state, answer.fields.selected_response_option))
            .filter(responseOption => {
              if (alreadyAnswered.has(responseOption?.id)) {
                return false
              }
              alreadyAnswered.add(responseOption?.id)
              return true
            })
            .map(responseOption => responseOption?.fields.copy?.caption || '')

          const followUpQuestions = getFollowUpQuestions(primaryQuestion.id, allQuestions)
          const followUpQuestionAnswers: any[] = []

          followUpQuestions
            .filter(question => question.fields.response_type === 'text-input')
            .forEach(question => {
              const answer = findQuestionnaireAnswersByQuestionnaireSubmissionId(
                state,
                questionnaireSubmission.id,
              ).find(answer => answer.fields.question_id === question.id)

              if (!answer) {
                return
              }

              const followUpQuestionAnswer: any = {
                question: question.fields.copy.heading || '',
                description: question.fields.copy.dashboard?.description || '',
              }

              if (answer.fields.is_skipped) {
                followUpQuestionAnswer.skipped = true
              } else {
                followUpQuestionAnswer.input = answer.fields.text_input
                followUpQuestionAnswer.mediaURL = answer.fields.media_url
              }

              followUpQuestionAnswers.push(followUpQuestionAnswer)
            })

          answers.answers[questionnaireSubmission.fields.reference_id] = {
            selected: selectedResponseOptions,
            followUpQuestion: followUpQuestionAnswers,
            input,
          }
        }
        items.push({
          id: module.id,
          title: module.fields.name,
          emoji: module.fields.copy.emoji || '🛠',
          answers,
        })

        break
      }
      case 'text-input': {
        const answers: AnswersType = {
          type: answerType,
          answers: {},
        }

        for (const questionnaireSubmission of questionnaireSubmissions) {
          const primaryQuestion = getPrimaryQuestion(allQuestions)
          const refAnswer = findQuestionnaireAnswersByQuestionnaireSubmissionId(state, questionnaireSubmission.id).find(
            answer => answer.fields.question_id === primaryQuestion.id,
          )

          if (refAnswer?.fields.is_skipped) {
            answers.answers[questionnaireSubmission.fields.reference_id] = {
              skipped: true,
            }
            continue
          }

          answers.answers[questionnaireSubmission.fields.reference_id] = {
            input: refAnswer?.fields.text_input,
            mediaURL: refAnswer?.fields.media_url,
          }
        }
        items.push({
          id: module.id,
          title: module.fields.name,
          emoji: module.fields.copy.emoji || '🛠',
          answers,
        })

        break
      }
      case 'forced-truth': {
        const answers: AnswersType = {
          type: answerType,
          answers: {},
        }

        for (const questionnaireSubmission of questionnaireSubmissions) {
          const refAnswers = (allQuestions || []).map(question => {
            const answer = findQuestionnaireAnswersByQuestionnaireSubmissionId(state, questionnaireSubmission.id).find(
              answer => answer.fields.question_id === question.id,
            )

            if (!answer) {
              return {
                startCaption: question.fields.copy?.start_statement || '',
                endCaption: question.fields.copy?.end_statement || '',
                value: 0,
                skipped: true,
              }
            }

            const selectedResponseOption = findResponseOptionById(state, answer?.fields.selected_response_option)

            return {
              startCaption: question.fields.copy?.start_statement ?? '',
              endCaption: question.fields.copy?.end_statement ?? '',
              value: selectedResponseOption?.fields.value ?? 0,
              skipped: answer.fields.is_skipped ?? false,
            }
          })

          answers.answers[questionnaireSubmission.fields.reference_id] = refAnswers
        }
        items.push({
          id: module.id,
          title: module.fields.name,
          emoji: module.fields.copy.emoji || '🛠',
          answers,
        })

        break
      }
    }
  }

  return items
}
