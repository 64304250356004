import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as api from '../../api'
import { AppThunk } from '../../core/store'
import { add as notify } from '../../store/notifications'
import { addEntities } from '../resources'

interface CandidateReferenceFilter {
  [candidateId: string]: string | null
}

interface State {
  loading: boolean
  createdId: string
  inviteLink: string
  filter: CandidateReferenceFilter
}

const name = 'update-reference'

const initialState: State = {
  loading: false,
  createdId: '',
  inviteLink: '',
  filter: {},
}

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setCreatedId(state, action: PayloadAction<string>) {
      state.createdId = action.payload
    },
    setInviteLink(state, action: PayloadAction<string>) {
      state.inviteLink = action.payload
    },
    setFilter(state, action: PayloadAction<{ candidateId: string; referenceId: string }>) {
      const { candidateId, referenceId } = action.payload
      state.filter[candidateId] = referenceId
    },
  },
})

export const { setLoading, setInviteLink, setCreatedId, setFilter } = slice.actions

export default slice.reducer

export const updateVerification =
  (id: string, verify: boolean, rejectionReason?: string): AppThunk =>
  async dispatch => {
    const [response, errors] = await api.references.verify(id, verify, rejectionReason || '')

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }
    if (response) {
      dispatch(addEntities(response))
      dispatch(
        notify({
          success: `The selected reference has been set as ${!verify ? 'not' : ''} verified.`,
        }),
      )
    }
  }

export const remove =
  (id: string): AppThunk =>
  async dispatch => {
    const [response, errors] = await api.references.remove(id)

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    if (response) {
      dispatch(addEntities(response))
      dispatch(
        notify({
          success: 'The selected reference has been deleted.',
        }),
      )
    }
  }
export const create =
  (reference: api.references.CreatePayload): AppThunk =>
  async dispatch => {
    dispatch(setLoading(true))

    const [response, errors] = await api.references.create(reference)

    dispatch(setLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    if (response) {
      dispatch(addEntities(response))
      dispatch(setCreatedId(response.result.id))
      dispatch(
        notify({
          success: 'The selected reference has been invited!',
        }),
      )
    }
  }

export const update =
  (id: string, reference: api.references.UpdatePayload): AppThunk =>
  async dispatch => {
    dispatch(setLoading(true))

    const [response, errors] = await api.references.update(id, reference)

    dispatch(setLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    if (response) {
      dispatch(addEntities(response))
      dispatch(setCreatedId(response.result.id))
      dispatch(
        notify({
          success: 'The selected reference has been updated!',
        }),
      )
    }
  }

export const inviteLink =
  (id: string): AppThunk =>
  async dispatch => {
    const [response, errors] = await api.references.inviteLink(id)

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(setInviteLink(response.result.fields.link))
    }
  }

export const inviteLinkv2 =
  (id: string): AppThunk =>
  async dispatch => {
    const [response, errors] = await api.references.inviteLinkv2(id)

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(setInviteLink(response.result.fields.link))
    }
  }
