import ReferenceFilter from 'App/Candidate/ReferenceFilter'
import { useCandidate } from 'providers/candidates'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import ListSelector, { ListItem } from '../../components/ListSelector'
import { style } from '../../core'
import * as tracking from '../../core/track'
import * as selectors from '../../selectors'
import { RootState } from '../../store'
import * as content from '../../store/new-role/content'
import { Visible } from './ByQuestion'

interface Props {
  candidateId: string
  visible: Visible
}

const Container = style()
  .scroll({ y: 'auto' })
  .spacing({
    innerRight: `0.5rem`,
  })
  .element()

const ListSelectorContainer = style()
  .spacing({
    outerTop: '0.5rem',
  })
  .element()

const Component = (props: Props) => {
  const { candidate } = useCandidate({ candidateId: props.candidateId })

  const contentfulQuestions = useSelector((state: RootState) =>
    selectors.assessmentVersions
      .getGroupedItemsByCandidateId(state, props.candidateId)
      .filter(
        view =>
          view.dashboard_view?.parent_question_id === '' &&
          !view.dashboard_view?.profile_sidebar &&
          !view.dashboard_view?.reference_profile,
      ),
  )

  const rdsModulesQuestions = useSelector((state: RootState) =>
    selectors.questionnaires.findSidebarGroupedQuestionsModulesByQuestionnaireId(
      state,
      candidate?.fields.questionnaire_id || '',
    ),
  )

  let listItems: ListItem[] = []
  if (rdsModulesQuestions?.length) {
    listItems = rdsModulesQuestions.map(questionOrModule => ({
      title: questionOrModule.title,
      // FYI: emoji typecheck is a fix added as part of the following PR
      // https://github.com/hipeople/dashboard/pull/1284
      emoji: typeof questionOrModule.emoji === 'string' ? questionOrModule.emoji : '🛠',
      id: questionOrModule.id,
    }))
  } else {
    listItems = contentfulQuestions.map(question => ({
      title: question.dashboard_view.title,
      emoji: content.CONTENT_EMOJI_LOOKUP[question.content_id] || '🛠',
      id: question.content_id,
    }))
  }

  const location = useLocation()
  const activeItemIdInUrl = location.hash.substring(1) // strip the # in id

  const [highlighted, setHighlighted] = useState(activeItemIdInUrl)
  const isFirstRender = useRef<boolean>(true)
  useEffect(() => {
    const scrollBehavior = isFirstRender.current ? 'auto' : 'smooth'
    document.getElementById(activeItemIdInUrl)?.scrollIntoView({ behavior: scrollBehavior, block: 'start' })
    isFirstRender.current = false
  }, [activeItemIdInUrl])

  useEffect(() => {
    setHighlighted(
      getTopVisibleItem(
        props.visible,
        listItems.map(l => l.id),
      ),
    )
  }, [props.visible])

  if (!listItems.length) return <></>

  return (
    <Container>
      <ReferenceFilter candidateId={candidate?.id || ''} />
      <ListSelectorContainer>
        <ListSelector
          items={listItems}
          onClick={s => tracking.candidates.questionBySectionBlockNavigation(s, listItems)}
          active={highlighted || listItems?.[0].id}
        />
      </ListSelectorContainer>
    </Container>
  )
}

export default Component
Component.displayName = 'SectionBlockNavigator'

function getTopVisibleItem(itemStatus: Visible, questionList: string[]): string {
  const visibleItems: string[] = []
  for (const key in itemStatus) {
    if (itemStatus[key]) {
      visibleItems.push(key)
    }
  }

  for (const question of questionList) {
    if (visibleItems.includes(question)) return question
  }

  return questionList[0]
}
