import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const name = 'add-user-to-team'

interface State {
  loading: boolean
  teamUsers: string[]
  organizationMembers: string[]
}

const initialState: State = {
  loading: false,
  teamUsers: [],
  organizationMembers: [],
}

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setTeamUsers(state, action: PayloadAction<string[]>) {
      state.teamUsers = action.payload
    },
    setOrganizationUsers(state, action: PayloadAction<string[]>) {
      state.organizationMembers = action.payload
    },
  },
})

export default slice.reducer
