import React from 'react'
import { BsLightningCharge } from 'react-icons/bs'
import { FaRegCalendarCheck } from 'react-icons/fa6'
import { SlPresent } from 'react-icons/sl'
import { Link } from 'react-router-dom'
import { Button } from '../Button/Button'
import classes from './TopBar.module.scss'

interface TopBarProps {
  tracking: {
    links: {
      referLinkClicked: () => void
      bookDemoLinkClicked: () => void
      upgradeLinkClicked: () => void
    }
  }
}

export const TopBar = (props: TopBarProps) => {
  return (
    <div className={classes.topBar}>
      <div className={classes.buttons}>
        <a
          href="https://airtable.com/appXUAULRXVzaZdUf/shri6TATnxdm6W3lm"
          target={'_blank'}
          rel="noreferrer"
          onClick={props.tracking.links.referLinkClicked}
        >
          <Button variant={'tertiary'}>
            <SlPresent />
            Refer & earn
          </Button>
        </a>
        <a
          href="https://meetings.hubspot.com/jgillmann"
          target={'_blank'}
          rel="noreferrer"
          onClick={props.tracking.links.bookDemoLinkClicked}
        >
          <Button variant={'tertiary'}>
            <FaRegCalendarCheck />
            Book a demo
          </Button>
        </a>
        <Link to="/upgrade" onClick={props.tracking.links.upgradeLinkClicked}>
          <Button variant={'tertiary'}>
            <BsLightningCharge />
            Upgrade
          </Button>
        </Link>
      </div>
    </div>
  )
}
