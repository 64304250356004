import { Icon } from 'components/Icons'
import { px, px2rem, size, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React from 'react'
import { Step as RoleCreationStep } from '../RoleCreation'
import { Step as ReferenceStep } from '../Templates/reference-check/NewReferenceCheckPage'

const Spacer = style()
  .border({
    color: designSystemColors.borderDefault,
    top: `solid ${px2rem(1)}`,
    radius: '50%',
  })
  .size({ width: px2rem(30) })
  .element()

const TopNavigationStyle = style()
  .flex({ alignItems: 'center', justifyContent: 'center' })
  .spacing({ gap: px2rem(12), inner: px2rem(12) })
  .border({ radius: px2rem(16) })
  .color({ bg: designSystemColors.backgroundNeutralTertiary })
  .size({ height: size.fill })
  .element('nav')

function getStepStatus(index: number, selectedStepIndex: number): NavigationStepStatus {
  if (index < selectedStepIndex) {
    return 'completed'
  }
  if (index === selectedStepIndex) {
    return 'active'
  }
  return 'upcoming'
}

type NavigationStepType = ReferenceStep | RoleCreationStep

interface TopNavigationProps {
  selectedStepIndex: number
  steps: NavigationStepType[]
}

export const TopNavigation: React.FC<TopNavigationProps> = ({ steps, selectedStepIndex }) => {
  return (
    <TopNavigationStyle>
      {steps.map((step, i) => (
        <React.Fragment key={`navigation-step-${i}`}>
          <NavigationStep text={step} stepNumber={i} stepStatus={getStepStatus(i, selectedStepIndex)} />
          {i !== steps.length - 1 && <Spacer />}
        </React.Fragment>
      ))}
    </TopNavigationStyle>
  )
}

const TAB_ITEMS_BREAK_1 = 1030 // < only numbers
const TAB_ITEMS_BREAK_2 = 1525 // < only text

const StepNumber = text
  .smallBody()
  .border({ radius: '50%' })
  .size({ height: px2rem(24), width: px2rem(24) })
  .flex({ alignItems: 'center', justifyContent: 'center', shrink: '0' })
  .with(({ stepStatus }: { stepStatus: NavigationStepStatus }) => {
    switch (stepStatus) {
      case 'active': {
        return style().color({
          bg: designSystemColors.backgroundNeutralAccent,
          fg: designSystemColors.white,
        })
      }
      case 'completed': {
        return style().color({
          bg: designSystemColors.uiStatusSuccess,
          fg: designSystemColors.white,
        })
      }
      case 'upcoming': {
        return style()
          .border({
            color: designSystemColors.typographySecondary,
            around: `solid ${px2rem(1)}`,
          })
          .color({
            fg: designSystemColors.typographySecondary,
          })
      }
    }
  })
  .screen({ gte: px(TAB_ITEMS_BREAK_1), lte: px(TAB_ITEMS_BREAK_2) }, style().hidden())
  .element('span')

const StepText = text
  .bodyInteractive()
  .block()
  .color({ fg: designSystemColors.typographySecondary })
  .text({ ellipsis: true })
  .screen({ lte: px(TAB_ITEMS_BREAK_1 - 1) }, style().hidden())
  .element('span')
const NavigationStepStyle = style()
  .flex({ alignItems: 'center', justifyContent: 'center' })
  .spacing({ gap: px2rem(8) })
  .nooverflow()
  .element()

type NavigationStepStatus = 'completed' | 'active' | 'upcoming'
interface NavigationStepProps {
  text: React.ReactNode
  stepStatus: NavigationStepStatus
  stepNumber: number
}

const NavigationStep: React.FC<NavigationStepProps> = ({ text, stepNumber, stepStatus }) => {
  return (
    <NavigationStepStyle>
      <StepNumber stepStatus={stepStatus}>
        {stepStatus !== 'completed' ? stepNumber + 1 : <Icon name="check" />}
      </StepNumber>
      <StepText>{text}</StepText>
    </NavigationStepStyle>
  )
}
