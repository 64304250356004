import React from 'react'
import classes from './ScoreGroup.module.scss'
import { Score } from '../../../api/score'
import { ScoreGroupHeader } from './ScoreGroupHeader'
import { ModuleList } from './ModuleList'
import { ScoreCard } from './ScoreCard'
import { KnowledgeCardProps } from '../../KnowledgeCard/KnowledgeCard'

export interface ModuleSummaryBlockProps {
  description?: string
  title: string
  theme: string
  emoji?: string
  icon?: React.ReactNode
  lowBehaviourText?: string
  highBehaviourText?: string
  detailsChildren?: React.ReactNode
}

export interface Group {
  id: string
  name: string
  slug: string
  copy: ModuleSummaryBlockProps
}

type ModuleData = {
  description?: string
  customDescription?: React.ReactNode
  pairwise?: {
    startCaption: string
    endCaption: string
  }
  locale?: string
  isSharedResults?: boolean
  learnings?: KnowledgeCardProps['learning'][]
}

export type GetModuleDataFn = (score: Score) => ModuleData

export interface ScoreGroupProps {
  score: Score | undefined
  group: Group
  onClickResponses?: (moduleSlug: string, group: Group) => void
  candidateFirstName: string
  getModuleData?: GetModuleDataFn
  defaultCollapsed?: boolean
}

export const ScoreGroup = (props: ScoreGroupProps) => {
  if (!props.score) {
    return <div></div>
  }

  const headerScoreType = getHeaderGroupType(props.group)

  return (
    <ScoreCard data-testid="score-group">
      <ScoreGroupHeader group={props.group} score={props.score} scoreType={headerScoreType} />
      <ModuleList
        score={props.score}
        getModuleData={props.getModuleData}
        onClickResponses={props.onClickResponses}
        candidateFirstName={props.candidateFirstName}
        group={props.group}
        defaultCollapsed={props.defaultCollapsed}
      />
    </ScoreCard>
  )
}

export const GroupIcon = (props: { theme: string; emoji?: string; icon?: React.ReactNode }) => {
  return (
    <div className={classes.groupIconWrapper} style={{ color: props.theme }}>
      <div className={classes.groupIcon} style={{ backgroundColor: props.theme }} />
      {props.icon ? props.icon : props.emoji && <div className={classes.emoji}>{props.emoji}</div>}
    </div>
  )
}

export type ScoreVisualTypes = 'default' | 'pairwise' | 'disc'

const getHeaderGroupType = (group: Group) => {
  if (group.slug === 'personality') {
    return 'pairwise'
  }
  return 'default'
}

/**@sentriz @valk8 @jannwitt This function is a temporary solution until backend returns score design types
 * @param score
 * @returns score visual type
 */
export const getScoreVisualType = (
  score: Score,
  group?: Group,
): (typeof scoreTypeProperties)[keyof typeof scoreTypeProperties] => {
  if (
    score.slug?.startsWith('personality') ||
    score.slug?.startsWith('candidateexpectations') ||
    group?.slug === 'candidateexpectations' ||
    group?.slug === 'personality'
  ) {
    return scoreTypeProperties['pairwise']
  }
  if (score.slug?.startsWith('disc')) {
    return scoreTypeProperties['disc']
  }

  // if (group?.slug === 'softskills') {
  //   console.log('softskills')
  //   return scoreTypeProperties['default']
  // }

  return scoreTypeProperties['default']
}

export const scoreTypeProperties: Record<
  ScoreVisualTypes,
  { showScore: boolean; pairwiseOnHeader?: boolean; slug: string }
> = {
  pairwise: {
    slug: 'pairwise',
    showScore: false,
    pairwiseOnHeader: true,
  },
  disc: {
    slug: 'disc',
    showScore: true,
  },
  default: {
    slug: 'default',
    showScore: true,
  },
}
