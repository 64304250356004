import * as content from '../store/new-role/content'
import { AnswerFormat } from '../store/new-role/formats'

const OLD_AREAS_OF_IMPROVEMENT: AnswerFormat = {
  name: 'Areas of Improvement',
  question: 'What were {CANDIDATE.FIRST_NAME}’s most significant areas for improvement when you worked together?',
  desc: "Get a structured view of candidates' improvement areas.",
  testItemId: content.OLD_AREAS_OF_IMPROVEMENT.test_item_id,
  notSelectable: true,
  icon: 'user-plus',
  emoji: '🌱',
  isFromLibrary: true,
}

const OLD_OVERALL_PERFORMANCE: AnswerFormat = {
  name: 'Overall Performance',
  question: "How would you rate {CANDIDATE.FIRST_NAME}'s performance compared to colleagues in a similar position?",
  desc: 'Let references rate the performance of candidates.',
  testItemId: content.OLD_OVERALL_PERFORMANCE_QUESTION.test_item_id,
  notSelectable: true,
  emoji: '🏋️‍♀️',
  icon: 'globe-europe',
  isFromLibrary: true,
}

const CURRENT_EXPERIENCE: AnswerFormat = {
  name: 'Current Experience',
  desc: `How experienced was the candidate in this activity?`,
  contentId: content.CURRENT_EXPERIENCE_QUESTION.question.response_options[0],
  notSelectable: true,
  emoji: '🏹',
  placeholder:
    'e.g When you last worked with {CANDIDATE.FIRST_NAME}, how experienced was {CANDIDATE.FIRST_NAME} in the following activity: Enterprise Sales',
  icon: 'abacus',

  parameters: [
    {
      name: 'Current Experience',
      placeholder: 'Enter an activity. e.g Enterprise Sales',
      value: '',
    },
  ],
}

const PROFICIENCY: AnswerFormat = {
  name: 'Skill Slider',
  desc: 'How proficient is the candidate in certain skills?',
  contentId: content.PROFICIENCY_QUESTION.question.response_options[0],
  notSelectable: true,
  placeholder: 'e.g Please share some insights into {CANDIDATE.FIRST_NAME}’s ability to Manage Inbound Sales.',
  icon: 'star-half-alt',
  emoji: '👑',
  parameters: [
    {
      name: 'Skill',
      placeholder: 'Enter a skill. e.g Manage Inbound Sales',
      value: '',
    },
  ],
}
const OLD_LEADERSHIP_STYLE: AnswerFormat = {
  name: 'Leadership Style',
  desc: 'How does the candidate act as a leader?',
  testItemId: content.LEADERSHIP_STYLE_QUESTION.test_item_id,
  notSelectable: true,
  icon: 'user-chart',
  emoji: '👔',
  isFromLibrary: true,
}

const OLD_WORK_VALUES: AnswerFormat = {
  name: 'Work Environment',
  question:
    'In your opinion, which of these things are particularly important to motivate {CANDIDATE.FIRST_NAME} at work?',
  desc: 'Learn what factors motivate candidates the most.',
  testItemId: content.WORK_VALUES_QUESTION.test_item_id,
  icon: 'hand-holding-seedling',
  emoji: content.CONTENT_EMOJI_LOOKUP[content.WORK_VALUES_QUESTION.test_item_id],
  isFromLibrary: true,
  notSelectable: true,
}

const OLD_TEAMWORK_STYLE: AnswerFormat = {
  name: 'Teamwork Style',
  question: 'When working in a group, which of these behaviors describe {CANDIDATE.FIRST_NAME} best?',
  desc: 'Learn what jobs candidates have taken on previously.',
  testItemId: content.TEAMWORK_STYLE_QUESTION.test_item_id,
  icon: 'people-carry',
  emoji: content.CONTENT_EMOJI_LOOKUP[content.TEAMWORK_STYLE_QUESTION.test_item_id],
  isFromLibrary: true,
  notSelectable: true,
}

export default [
  OLD_LEADERSHIP_STYLE,
  PROFICIENCY,
  CURRENT_EXPERIENCE,
  OLD_OVERALL_PERFORMANCE,
  OLD_AREAS_OF_IMPROVEMENT,
  OLD_WORK_VALUES,
  OLD_TEAMWORK_STYLE,
]
