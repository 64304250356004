import { CandidateExperienceMode } from 'api/org-settings'
import { Icon } from 'components/Icons'
import * as tracking from 'core/track'
import React from 'react'
import { FilterOptions, Filters } from '../../../api/orgs'
import Button from '../../../components/Button/ButtonV2'
import DateRangeDropdown from '../../../components/DateRangeDropdown/DateRangeDropdown'
import { breakpoints, px2rem, size, space, style } from '../../../core'
import { useFeatureFlag } from '../../../providers/features'
import { DateRangePreset } from '../index'
import FilterDropdown, { FilterProps } from './FilterDropdown'

const Container = style()
  .size({ width: size.fill })
  .spacing({ gap: px2rem(16) })
  .screen(breakpoints.xs, style().grid({ columns: 'repeat(6, minmax(150px, 1fr))' }))
  .select('> * > *', style().size({ width: size.fill }))
  .element()

const Section = style().size({ width: size.fill }).flex({ alignItems: 'end', direction: 'column' }).element()

interface FiltersProps {
  filterOptions: FilterOptions
  filters: Filters
  filtered: boolean
  setFilters: React.Dispatch<React.SetStateAction<Filters>>
  dateRanges: DateRangePreset[]
  selectedDateRange: DateRangePreset
  setDateRange: React.Dispatch<React.SetStateAction<DateRangePreset>>
  reset: () => void
  cxMode: CandidateExperienceMode
  setUpgradeModalOpen: (v: boolean) => void
}

export const FiltersSection: React.FC<FiltersProps> = (props: FiltersProps) => {
  const { isEnabled: isPaidFiltersEnabled } = useFeatureFlag('cx-paid-filters')
  return (
    <Section>
      <Container>
        {isPaidFiltersEnabled && (
          <AdvancedFiltersWrapper cxMode={props.cxMode} setUpgradeModalOpen={props.setUpgradeModalOpen}>
            <FilterDropdown
              label={<SparklesFilterLabel cxMode={props.cxMode} label="Office" />}
              presets={props.filterOptions.location || []}
              selected={props.filters.location}
              setSelected={selected => {
                if (selected) {
                  tracking.candidateExperience.candidateExperienceOfficeSelected()
                }
                props.setFilters({ ...props.filters, location: selected })
              }}
            />
            <FilterDropdown
              label={<SparklesFilterLabel cxMode={props.cxMode} label="Department" />}
              presets={props.filterOptions.department || []}
              selected={props.filters.department}
              setSelected={selected => {
                if (selected) {
                  tracking.candidateExperience.candidateExperienceDepartmentSelected()
                }
                props.setFilters({ ...props.filters, department: selected })
              }}
            />
            <FilterDropdown
              label={<SparklesFilterLabel cxMode={props.cxMode} label="Recruiter" />}
              presets={props.filterOptions.recruiter || []}
              selected={props.filters.recruiter_email}
              setSelected={selected => {
                if (selected) {
                  tracking.candidateExperience.candidateExperienceRecruiterelected()
                }
                props.setFilters({
                  ...props.filters,
                  recruiter_email: selected,
                })
              }}
              allowInput={true}
            />
            <FilterDropdown
              label={<SparklesFilterLabel cxMode={props.cxMode} label="Stage" />}
              presets={props.filterOptions.last_stage || []}
              selected={props.filters.last_stage}
              setSelected={selected => {
                if (selected) {
                  tracking.candidateExperience.candidateExperienceHiringStageSelected(selected)
                }
                props.setFilters({ ...props.filters, last_stage: selected })
              }}
            />
          </AdvancedFiltersWrapper>
        )}
        <FilterDropdown
          label={'Status'}
          presets={props.filterOptions.hiring_status || []}
          selected={props.filters.hiring_status}
          setSelected={selected => {
            if (selected) {
              tracking.candidateExperience.candidateExperienceStatusSelected(selected)
            }
            props.setFilters({ ...props.filters, hiring_status: selected })
          }}
        />
        <DateRangeDropdown ranges={props.dateRanges} selected={props.selectedDateRange} setRange={props.setDateRange} />
      </Container>
      {props.filtered && (
        <Button
          buttonType={'minimal'}
          onClick={() => {
            tracking.candidateExperience.candidateExperienceFiltersCleared()
            props.reset()
          }}
        >
          {'Clear all filters'}
        </Button>
      )}
    </Section>
  )
}

const DisabledFilter = style().pointer().select('> *', style().set('pointerEvents', 'none')).element()

type AdvancedFiltersWrapperProps = {
  children: React.ReactElement<FilterProps>[]
  cxMode: CandidateExperienceMode
  setUpgradeModalOpen: (v: boolean) => void
}

const AdvancedFiltersWrapper: React.FC<AdvancedFiltersWrapperProps> = props => {
  if (props.cxMode === 'ats-hired-or-rejected') {
    return <>{props.children}</>
  }

  return (
    <>
      {props.children.map(c => (
        <DisabledFilter onClick={() => props.setUpgradeModalOpen(true)} key={c.props.label}>
          {c}
        </DisabledFilter>
      ))}
    </>
  )
}

const SparklesFilterLabelWrapper = style().inlineFlex({ alignItems: 'center' }).spacing({ gap: space.xxs }).element()

type SparklesFilterLabelProps = {
  label: string
  cxMode: CandidateExperienceMode
}

const SparklesFilterLabel: React.FC<SparklesFilterLabelProps> = props => {
  if (props.cxMode === 'ats-hired-or-rejected') {
    return <>{props.label}</>
  }

  return (
    <SparklesFilterLabelWrapper>
      <Icon name="sparkles" /> {}
      <span>{props.label}</span>
    </SparklesFilterLabelWrapper>
  )
}
