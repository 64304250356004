export const RESOURCE = 'greenhouse_profiles'

export interface Fields {
  id: string
  first_name: string
  last_name: string
  email: string
  resume_url: string
  phone_number: string
  greenhouse_profile_url: string
  created_at: number
  updated_at: number
}
