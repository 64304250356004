import React from 'react'
import { LetterIcon } from '../../../components/Icons'
import { colors, fr, size, space, style } from '../../../core'

const Container = style()
  .sans()
  .grid({ columns: [size.l, fr(1), size.auto], align: 'center' })
  .spacing({ column: space.xs, outer: space.s })
  .element()

const Email = style().sans({ ellipsis: true }).element()
const RevokeAccess = style().color({ fg: colors.gray }).pointer().element()

interface Props {
  email: string
  children: React.ReactNode
  action?: () => void
}

const Component = (props: Props) => {
  return (
    <Container>
      <LetterIcon name={props.email} />
      <Email>{props.children}</Email>
      {props.action ? <RevokeAccess onClick={props.action}>revoke access</RevokeAccess> : null}
    </Container>
  )
}

export default Component
Component.displayName = 'Name'
