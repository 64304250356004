import Empty from 'App/Candidate/WaitingOnCandidate'
import { Column } from 'components/ContentGrid'
import { px2rem, rem, size, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import { format } from 'date-fns'
import React from 'react'

const QuestionListStyle = style()
  .border({
    around: `1px solid`,
    color: designSystemColors.borderDefault,
    radius: px2rem(8),
  })
  .set('borderSpacing', '0px')
  .size({ width: size.fill })
  .nooverflow()
  .element('table')

const HeaderStyle = style()
  .grid({ columns: [rem(4), size.auto] })
  .spacing({ gap: px2rem(16) })
  .element()

const Title = text
  .secondaryBodyInteractive()
  .spacing({ inner: [size.s, px2rem(16)] })
  .element('th')

const QuestionRow = text
  .bodyText()
  .border({
    top: `1px solid`,
    color: designSystemColors.borderDefault,
  })
  .grid({ columns: [rem(4), size.auto] })
  .spacing({ inner: [px2rem(16), px2rem(16)], gap: px2rem(16) })
  .element('tr')

const QuestionCopy = style()
  .fg(designSystemColors.typographySecondary)
  .spacing({ outer: [size.auto, size.none] })
  .sans({ height: px2rem(18) })
  .element('td')

interface TextQuestionListProps {
  question: {
    date: Date
    answer: string
  }[]
  filtered: boolean
}

const TextQuestionList: React.FC<TextQuestionListProps> = ({ question, filtered }) => {
  if (!question.length)
    return (
      <Empty
        title={filtered ? `No summaries to display for the selected filters` : `No data to display`}
        status={
          filtered
            ? `You don't have any available responses for the selected filters.`
            : `For anonymity reasons, we only display answers once there are at least three submissions.`
        }
      />
    )

  return (
    <QuestionListStyle>
      <Column head>
        <HeaderStyle>
          <Title>Date</Title>
          <Title>Answer</Title>
        </HeaderStyle>
      </Column>
      {question.map(q => (
        <QuestionRow key={`${q.date}-${q.answer}`}>
          <QuestionCopy>{format(q.date, 'd/M/yy')}</QuestionCopy>
          <QuestionCopy>{q.answer}</QuestionCopy>
        </QuestionRow>
      ))}
    </QuestionListStyle>
  )
}

export default TextQuestionList
