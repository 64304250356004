import Button from 'components/Button/ButtonV2'
import { fr, px2rem, size, style } from 'core'
import { text } from 'core/design-system/text'
import React from 'react'
import * as tracking from '../../core/track'
import { ReferenceCheckDetailsModal, ReferenceFeedbackDetailsValues } from './ReferenceFeedbackDetailsModal'
import { Description, Title } from './Shared'
import { ModuleCard } from './SurveyModuleCard'

const TextElem = text
  .bodyText()
  .sans({ align: 'center' })
  .spacing({ outerBottom: px2rem(16), outerTop: px2rem(64) })
  .element('p')

const Container = style()
  .spacing({ outer: [px2rem(0), size.auto] })
  .size({ width: px2rem(1072) })
  .element()

export type RoleCreationModulesValues = {
  isReferenceFeedbackAdded: boolean
  isSelfAssessmentAdded: boolean
  isTalentExperienceAdded: boolean
  showOnboardingTalkToSales: boolean
  isOnboardingAdded: boolean
  referenceFeedbackDetails: ReferenceFeedbackDetailsValues
}

interface RoleCreationModulesProps {
  values: RoleCreationModulesValues
  onChange: React.Dispatch<React.SetStateAction<RoleCreationModulesValues>>
  back: () => any
  next: () => any
  minRequiredReferenceCount: number
  isSelfAssessmentSelectable: boolean
  isEditModeEnabled?: boolean
}

const CardContainer = style()
  .flex({ wrap: 'wrap' })
  .spacing({ gap: px2rem(16) })
  .center({ horizontal: true })
  .element()

const Buttons = style()
  .grid({ columns: [fr(1)] })
  .spacing({ gap: px2rem(16), outer: 'auto' })
  .size({ width: px2rem(256) })
  .element()

export const RoleCreationModules: React.FC<RoleCreationModulesProps> = ({
  next,
  values,
  onChange,
  minRequiredReferenceCount,
  isSelfAssessmentSelectable,
}) => {
  const [showReferenceFeedbackDetailsModal, setShowReferenceFeedbackDetailsModal] = React.useState(false)

  const isSubmitButtonDisabled = !values.isReferenceFeedbackAdded && !values.isSelfAssessmentAdded

  return (
    <Container>
      <Title data-testid="role-creation-modules-title">{'Select modules'}</Title>
      <Description>{'Select the product modules for this job.'}</Description>
      <CardContainer>
        <ModuleCard
          title="Assessment"
          text="Request candidate to complete an assessment to evaluate their skill competency and job fit."
          icon="user"
          bgColor="purple"
          isCardAdded={values.isSelfAssessmentAdded}
          toggleAddState={() => {
            onChange(values => ({
              ...values,
              isSelfAssessmentAdded: !values.isSelfAssessmentAdded,
            }))
            !values.isSelfAssessmentAdded
              ? tracking.roles.roleCreationAddAssessment()
              : tracking.roles.roleCreationRemoveAssessment()
          }}
          isComingSoon={!isSelfAssessmentSelectable}
          testId="assessment"
        />
        <ModuleCard
          title="Reference Check"
          text="Gather feedback about a candidate via former colleagues."
          icon="users"
          bgColor="green"
          hasDetails
          onClickDetailsButton={() => setShowReferenceFeedbackDetailsModal(true)}
          isCardAdded={values.isReferenceFeedbackAdded}
          toggleAddState={() => {
            values.isReferenceFeedbackAdded
              ? onChange(values => ({
                  ...values,
                  isReferenceFeedbackAdded: false,
                }))
              : setShowReferenceFeedbackDetailsModal(true)

            if (values.isReferenceFeedbackAdded) tracking.roles.roleCreationRemoveReferenceCheck()
          }}
          testId="reference-feedback"
        />
      </CardContainer>
      <TextElem>Add either reference check or assessment module to continue (you can change this later).</TextElem>
      <Buttons>
        <div />
        <Button
          buttonType="primary"
          onClick={() => {
            next()
          }}
          disabled={isSubmitButtonDisabled}
        >
          {'Next step'}
        </Button>
      </Buttons>

      <ReferenceCheckDetailsModal
        initialValues={values.referenceFeedbackDetails}
        open={showReferenceFeedbackDetailsModal}
        setOpen={setShowReferenceFeedbackDetailsModal}
        onSubmit={referenceFeedbackDetails => {
          onChange(values => ({
            ...values,
            referenceFeedbackDetails,
            isReferenceFeedbackAdded: true,
          }))
          setShowReferenceFeedbackDetailsModal(false)
          if (!values.isReferenceFeedbackAdded) tracking.roles.roleCreationAddReferenceCheck()
        }}
        minRequiredReferenceCount={minRequiredReferenceCount}
      />
    </Container>
  )
}
