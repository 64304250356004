import React from 'react'
import classes from './PieChart.module.scss'
import { Pie, ResponsiveContainer, PieChart as PieChartComponent, Cell, Legend } from 'recharts'
import variables from '../../../styles/colorVariables.module.scss'

type DataArray = {
  name: string
  value: number
}[]

interface PieChartProps {
  data: DataArray
}

const COLORS = [
  variables.typographyPrimaryAccent,
  variables.typographyPrimaryAccent + '80',
  variables.typographyPrimaryAccent + '40',
  variables.typographyPrimaryAccent + '20',
  // add more colors if needed, otherwise it loops
]

export const PieChart = (props: PieChartProps) => {
  if (!props.data || props.data.length === 0) {
    return null
  }
  return (
    <div className={classes.main}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChartComponent width={300} height={300}>
          <Pie
            data={props.data}
            cx="50%"
            cy="50%"
            outerRadius={70}
            dataKey="value"
            legendType="circle"
            startAngle={90}
            endAngle={-270}
          >
            {props.data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Legend verticalAlign="bottom" height={36} />
        </PieChartComponent>
      </ResponsiveContainer>
    </div>
  )
}
