import * as api from '../api'
import * as selectors from '../selectors'
import { RootState } from '../store'
import * as candidates from './candidates'
import lookup from './lookup'

function findById(state: RootState, id: string): api.request.Entity<api.comments.Fields> | undefined {
  return lookup(state, api.comments.RESOURCE, id)
}

export function findByCandidateId(state: RootState, candidateId: string): api.request.Entity<api.comments.Fields>[] {
  const candidate = candidates.findById(state, candidateId)
  if (!candidate || !candidate.relationships) return []

  const result: api.request.Entity<api.comments.Fields>[] = []

  for (const r of candidate.relationships) {
    if (r.resource !== api.comments.RESOURCE) continue

    const comment = findById(state, r.id)
    if (!comment) continue

    result.push(comment)
  }

  return result
}

export function findSuggestionsByRoleId(state: RootState, roleId: string): { id: string; display: string }[] {
  const currentUser = selectors.users.current(state)
  const roleUsers = selectors.openJobRoleUsers.findByRoleId(state, roleId)

  const suggestions: { id: string; display: string }[] = []
  for (const roleUser of roleUsers) {
    if (roleUser.fields.user_id === currentUser?.id) continue

    const profile = selectors.profiles.getByUserId(state, roleUser.fields.user_id)
    if (!profile) continue

    suggestions.push({
      id: profile.fields.user_id,
      display: profile.fields.full_name,
    })
  }

  return suggestions
}
