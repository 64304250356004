import React from 'react'
import styles from './Callout.module.scss'
import { FaCheckCircle, FaTimes, FaTimesCircle } from 'react-icons/fa'

export const Circle = (props: { color: string }) => {
  return (
    <svg className={styles.svgContainer} id="loading-circle" height="30" width="30">
      <circle
        data-testid={'loading-circle'}
        cx="15"
        cy="15"
        r="14"
        strokeWidth="2"
        strokeOpacity="0.35"
        fill="transparent"
        stroke={props.color}
      />
    </svg>
  )
}

type Props = {
  error?: string
  success?: string
  fadeOut?: boolean
  close: () => void
}

export const Callout = (props: Props) => {
  return (
    <div
      className={`${styles.callout} ${props.error ? styles.error : ''} ${props.fadeOut ? styles.fadeOut : ''}`}
      data-sentry-unmask
    >
      <div className={styles.iconContainer}>{props.error ? <FaTimesCircle /> : <FaCheckCircle />}</div>
      <div className={styles.message}>{props.error || props.success}</div>
      <button className={styles.exit} onClick={props.close}>
        <div className={styles.circleContainer}>
          <Circle color={'white'} />
        </div>
        <div className={styles.exitIconContainer}>
          <FaTimes />
        </div>
      </button>
    </div>
  )
}

export default Callout
