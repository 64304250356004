import { px2rem, style } from 'core'
import React from 'react'
import { designSystemColors } from '../../core/design-system/colors'
import { text } from '../../core/design-system/text'
import { TooltipContent } from './index'

const Container = style()
  .inlineBlock()
  .select(
    `${TooltipContent.Style}`,
    style().absolute({ top: 0, left: '50%' }).invisible().set('transform', 'translateX(-50%) translateY(-100%)'),
  )
  .select(`:hover ${TooltipContent.Style}`, style().visible())
  .front()
  .element()

const TooltipContentStyled = text
  .smallBody()
  .color({ fg: designSystemColors.backgroundNeutralPrimary })
  .block()
  .sans({ align: 'center' })
  .spacing({ inner: [px2rem(2), px2rem(4)] })
  .size({ width: px2rem(192) })
  .element('p')

interface ElementTooltipProps {
  tooltipCopy: string
  targetElement: React.ReactNode
  className?: string
}

const ElementTooltip: React.FC<ElementTooltipProps> = ({ tooltipCopy, targetElement }) => {
  return (
    <Container>
      <TooltipContent arrowPosition="bottom">
        <TooltipContentStyled>{tooltipCopy}</TooltipContentStyled>
      </TooltipContent>
      {targetElement}
    </Container>
  )
}

export default ElementTooltip
