import { get } from './methods'
import { APIResponse, Entity } from './request'

export const RESOURCE = 'candidate_reference_check_stages'

export interface Fields {
  id: string
  candidate_id: string
  reference_form_id: string
  reference_form_started_at: number
  candidate_form_id: string
  candidate_form_started_at: number
  created_at: number
  updated_at: number
  is_active: boolean
}

export function loadByCandidateId(id: string): Promise<APIResponse<Entity<Fields>>> {
  return get<Entity<Fields>>(`/candidates/${id}/reference_check_stage`)
}
