import DropdownField, { Option } from 'components/DropdownField'
import { space, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import * as tracking from 'core/track'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as selectors from 'selectors'
import { relationshipCopy, relationshipOf } from 'selectors/references'
import { RootState } from 'store'
import { setFilter } from 'store/references'

const Title = text.label().color({ fg: designSystemColors.typographySecondary }).element()

const Container = style().grid().spacing({ rows: space.xxs }).element()

interface Props {
  candidateId: string
}

const Component = (props: Props) => {
  const dispatch = useDispatch()
  const references = useSelector((state: RootState) =>
    selectors.references.findByCandidateId(state, props.candidateId || ''),
  )

  const filter = useSelector((state: RootState) => state.references.filter[props.candidateId])

  if (!references.length) return <></>

  const items = [
    <Option key="All" emoji={'🖼'} value={null}>
      Show All
    </Option>,
    ...references.map(reference => {
      const copy = relationshipCopy[relationshipOf(reference)]
      if (!copy) return null
      return (
        <Option key={reference.id} value={reference.id} emoji={copy.emoji || '🖼'}>
          <div className="data-hj-suppress">
            {reference.fields.full_name} ({relationshipOf(reference)})
          </div>
        </Option>
      )
    }),
  ]

  return (
    <Container>
      <Title>FILTER BY REFERENCE</Title>
      <DropdownField<string | null>
        value={filter || null}
        onChange={referenceId => {
          dispatch(
            setFilter({
              candidateId: props.candidateId,
              referenceId: referenceId || '',
            }),
          )

          tracking.candidates.filterDropdownApplied()
        }}
      >
        {items}
      </DropdownField>
    </Container>
  )
}

export default Component
Component.displayName = 'ReferenceFilter'
