import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { NotificationsUI } from '@common/components/Notifications'

const Notifications = () => {
  const notifications = useSelector((state: RootState) => state.notifications)

  return <NotificationsUI messages={notifications?.messages} />
}

export default Notifications
