// duplicate of common/src/styles/zIndex.scss for now
// this allows us to export the zIndex values as ts variables into the candidate and dashboard apps
export const zIndex = {
  background: '-1',
  base: '0',
  foreground: '1',
  sticky: '2',
  navigation: '3',
  modal: '20',
  popover: '30',
  dropdown: '40',
  tooltip: '50',
  toast: '110',
  loader: '120',
}
