import { QuestionTable } from 'App/ExperienceFeedback/QuestionList'
import Modal from 'components/Modal'
import { fr, px2rem, size, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React from 'react'
import PreviewTable, { Preview } from './PreviewTable'

const Title = text.cardHeader().element('h3')

const ModalHeader = style()
  .grid({ columns: [fr(1), size.auto, size.auto, size.auto], align: 'center' })
  .spacing({ gap: px2rem(8), inner: [px2rem(18), px2rem(24)] })
  .border({
    bottom: `solid ${px2rem(1)}`,
    color: designSystemColors.borderDefault,
  })
  .element()

const PreviewTemplateModalStyle = style()
  .size({ width: px2rem(800), maxHeight: px2rem(640) })
  .scroll({ x: 'auto' })
  .select(`& ${QuestionTable}`, style().border({ radius: px2rem(0) }))
  .element()

interface PreviewTemplateModalProps {
  close: () => any
  preview: Preview[]
}

export const PreviewTemplateModal: React.FC<PreviewTemplateModalProps> = ({ close, preview }) => {
  return (
    <Modal
      open={true}
      setOpen={close}
      renderHeader={({ closeButton }) => (
        <ModalHeader>
          <Title>{'Preview'}</Title>
          {closeButton}
        </ModalHeader>
      )}
    >
      <PreviewTemplateModalStyle>
        <PreviewTable questions={preview} />
      </PreviewTemplateModalStyle>
    </Modal>
  )
}
