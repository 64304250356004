import * as api from 'api'
import { listing } from 'api'
import { lookup } from 'core'
import { candidateBaseURL } from 'core/utils'
import { RootState } from 'store'
import { CandidateEntity, findById as findCandidateById } from './candidates'
import { apiGet, del, get, post, put } from './methods'
import { APIResponse, Entity, Errors } from './request'
import { RoleAnalytics } from '@common/components'

export const RESOURCE = 'open_job_roles'

export interface Fields {
  id: string
  team_id: string
  assessment_id: string
  candidate_form_id: string
  reference_form_id: string
  self_reference_form_id: string
  candidate_title_verification_enabled: boolean
  questionnaire_id: string
  organization_id: string
  name: string
  public_name: string
  description: string
  assessment_locale: string
  reference_check_locales: string[]
  time_window_days: number
  candidate_expiry_days: number
  ask_self_reference: boolean
  ask_self_assessment: boolean
  ask_reference_check: boolean
  branding_image_url: string
  branding_text: string
  disable_additional_references: boolean
  is_active: boolean
  is_paused: boolean
  is_test_role: boolean
  candidate_count: number
  candidates_to_review_count: number
  created_by: string
  created_at: number
  updated_at: number
  reference_check_preset_id: string
  self_assessment_preset_id: string
  ats_id?: string
  integration_id?: string
}

export function empty(id: string): Entity<Fields> {
  return {
    id,
    resource: RESOURCE,
    fields: {
      id,
      team_id: '',
      assessment_id: '',
      candidate_form_id: '',
      reference_form_id: '',
      candidate_title_verification_enabled: false,
      self_reference_form_id: '',
      questionnaire_id: '',
      organization_id: '',
      name: '',
      public_name: '',
      description: '',
      assessment_locale: 'en_US',
      reference_check_locales: ['en_US'],
      time_window_days: 0,
      candidate_expiry_days: 0,
      ask_self_reference: false,
      ask_self_assessment: false,
      ask_reference_check: false,
      branding_image_url: '',
      branding_text: '',
      disable_additional_references: false,
      is_active: true,
      is_paused: false,
      is_test_role: false,
      candidate_count: 0,
      candidates_to_review_count: 0,
      created_by: '',
      created_at: 0,
      updated_at: 0,
      self_assessment_preset_id: '',
      reference_check_preset_id: '',
    },
  }
}

export interface Candidate {
  email: string
  full_name: string
}

export interface Form {
  steps: Step[]
  dialog_submit_id: string
  dialog_submitted_id: string
  dialog_inactive_id: string
}

export interface Step {
  question?: Question
  dialog_id?: string
  test_item_id?: string
}
interface ResponseOptionPayload {
  slug: string
  copy: Record<string, string>
  value: number
}
export interface QuestionnairePayload {
  module?: {
    id?: string
  }
  question?: {
    skill_id?: string
    properties?: Record<string, any>
    copy: {
      heading?: string
      description?: string
      descriptionHeading?: string
      descriptionHeadingHint?: string
      dashboard?: {
        title?: string
        emoji?: string
        description?: string
      }
    }
    response_type: string
    response_options: ResponseOptionPayload[]
  }
}

export interface Question {
  heading: string
  response_options: string[]
  dashboard_view_title?: string
  subheading?: string
  validation_id?: string
  parameters?: Parameter[]
  highlight_recent_outstanding_option?: boolean
}

export interface Parameter {
  name: string
  placeholder: string
  value: string
}
export interface SelectedUser {
  id: string
  can_control?: boolean
}

interface UpdateRoleTitlesPayload {
  updates: {
    internal_name?: string
    public_name?: string
  }
  updated: string[]
}

interface RequiredReference {
  is_peer?: boolean
  is_manager?: boolean
  is_report?: boolean
  is_student_peer?: boolean
  is_client?: boolean
  is_self?: boolean
  is_any?: boolean
  relationship_description?: string
}

export function invite(id: string, candidates: Candidate, send_survey = false): Promise<APIResponse<CandidateEntity>> {
  return post<CandidateEntity>(`open_job_roles/${id}/invite/v2`, {
    candidate: candidates,
    send_survey: send_survey,
  })
}

export function selfInvite(id: string, candidates: Candidate): Promise<APIResponse<CandidateEntity>> {
  return post<CandidateEntity>(`open_job_roles/${id}/self-invite`, {
    candidate: candidates,
  })
}

export async function fetch(id: string): Promise<APIResponse<Entity<Fields>>> {
  return await get<Entity<Fields>>(`open_job_roles/${id}`)
}

export function start(id: string): Promise<APIResponse<Entity<Fields>>> {
  return put<Entity<Fields>>(`open_job_roles/${id}/start`, {})
}

export function pause(id: string): Promise<APIResponse<Entity<Fields>>> {
  return put<Entity<Fields>>(`open_job_roles/${id}/pause`, {})
}

export function delet(id: string): Promise<APIResponse<Entity<Fields>>> {
  return put<Entity<Fields>>(`open_job_roles/${id}/archive`, {})
}
export async function createReferencePreviewLink(id: string, slug: string): Promise<[string, Errors | undefined]> {
  const [response, errors] = await put<Entity<{ token: string }>>(`open_job_roles/${id}/preview_reference`, {})
  if (errors) {
    return ['', errors]
  }

  const token = encodeURI(response?.result.fields.token || '')
  return [`${candidateBaseURL(slug)}/preview/roles/${id}/reference?token=${token}`, undefined]
}

export async function createSelfReferencePreviewLink(id: string, slug: string): Promise<[string, Errors | undefined]> {
  const [response, errors] = await put<Entity<{ token: string }>>(`open_job_roles/${id}/preview_reference`, {})
  if (errors) {
    return ['', errors]
  }

  const token = encodeURI(response?.result.fields.token || '')
  return [`${candidateBaseURL(slug)}/preview/roles/${id}/self-reference?token=${token}`, undefined]
}

export function updateTimeWindowDays(
  id: string,
  timeWindowDays: number,
  candidateExpiryDays: number,
): Promise<APIResponse<Entity<Fields>>> {
  return put<Entity<Fields>>(`open_job_roles/${id}/time_window_days`, {
    time_window_days: timeWindowDays,
    candidate_expiry_days: candidateExpiryDays,
  })
}

export function updateCandidateForm(id: string, form: Form): Promise<APIResponse<Entity<Fields>>> {
  return put<Entity<Fields>>(`open_job_roles/${id}/candidate_form`, form)
}

export function updateRoleTitles(id: string, payload: UpdateRoleTitlesPayload): Promise<APIResponse<Entity<Fields>>> {
  return post<Entity<Fields>>(`open_job_roles/${id}`, payload)
}

type CreateUpdatePayload = {
  name: string
  publicName: string
  description: string
  assessmentLocale: string
  referenceCheckLocales: string[]
  users: SelectedUser[]
  candidateExpiryDays: number
  timeWindowDays: number
  questionnaire: QuestionnairePayload[] | null
  requiredReferences: RequiredReference[]
  includeSelfReference: boolean
  includeSelfAssessment: boolean
  includeReferenceCheck: boolean
  candidateTitleVerificationEnabled: boolean
  brandingText?: string
  brandingImageUrl?: string
}

type CreatePayload = CreateUpdatePayload
type UpdatePayload = CreateUpdatePayload

export function create(payload: CreatePayload): Promise<APIResponse<Entity<Fields>>> {
  return post<Entity<Fields>>('open_job_roles', {
    name: payload.name,
    public_name: payload.publicName,
    description: payload.description,
    assessment_locale: payload.assessmentLocale,
    reference_check_locales: payload.referenceCheckLocales,
    users: payload.users,
    candidate_expiry_days: payload.candidateExpiryDays,
    time_window_days: payload.timeWindowDays,
    questionnaire: payload.questionnaire,
    required_references: payload.requiredReferences,
    ask_self_reference: payload.includeSelfReference,
    ask_self_assessment: payload.includeSelfAssessment,
    ask_reference_check: payload.includeReferenceCheck,
    candidate_title_verification_enabled: payload.candidateTitleVerificationEnabled,
    branding_image_url: payload.brandingImageUrl,
    branding_text: payload.brandingText,
  })
}

export function update(id: string, payload: UpdatePayload): Promise<APIResponse<Entity<Fields>>> {
  return put<Entity<Fields>>(`open_job_roles/${id}`, {
    name: payload.name,
    public_name: payload.publicName,
    description: payload.description,
    assessment_locale: payload.assessmentLocale,
    reference_check_locales: payload.referenceCheckLocales,
    users: payload.users,
    candidate_expiry_days: payload.candidateExpiryDays,
    time_window_days: payload.timeWindowDays,
    questionnaire: payload.questionnaire,
    required_references: payload.requiredReferences,
    ask_self_reference: payload.includeSelfReference,
    ask_self_assessment: payload.includeSelfAssessment,
    ask_reference_check: payload.includeReferenceCheck,
    candidate_title_verification_enabled: payload.candidateTitleVerificationEnabled,
    branding_image_url: payload.brandingImageUrl,
    branding_text: payload.brandingText,
  })
}

export function findById(state: RootState, id: string): Entity<Fields> {
  return lookup(state, { openjobrole: id })
}

export function findByCandidateId(state: RootState, candidateId: string): Entity<Fields> | undefined {
  const candidate = findCandidateById(state, candidateId)
  if (candidate === undefined || !candidate.relationships) return
  return findById(state, candidate.fields.open_job_role_id)
}

export async function createTestRole(): Promise<APIResponse<Entity<Fields>>> {
  return put<Entity<Fields>>('test_open_job_role', {})
}

export type InterviewStage = {
  ats_job_interview_stage_id: string
  integrated_product: 'reference-check' | 'self-assessment'
}

export function createIntegration(
  id: string,
  integrationId: string,
  atsJobId: string,
  integratedProduct: InterviewStage[],
): Promise<APIResponse<Entity<Fields>>> {
  return post<Entity<Fields>>(
    `open_job_roles/${id}/integrations?include=open_job_roles,organizations,integration_connections`,
    {
      integration_id: integrationId,
      ats_job_id: atsJobId,
      interview_stages: integratedProduct,
    },
  )
}

export function deleteIntegration(id: string, connectionId: string): Promise<APIResponse<Entity<Fields>>> {
  return del<Entity<Fields>>(
    `open_job_roles/${id}/integrations/${connectionId}?include=open_job_roles,organizations,integration_connections`,
    {},
  )
}

export function addTemplateToRole(
  id: string,
  presetId: string,
  presetType: 'reference_check' | 'self_assessment',
): Promise<APIResponse<Entity<Fields>>> {
  return put<Entity<Fields>>(`open_job_roles/${id}/preset/${presetType}`, {
    preset_id: presetId,
  })
}

type BaseRoleQuery = {
  limit: number
  skip: number
  onlyDeleted: boolean
  starred?: boolean
  title: string
  sort?: listing.Options['sort']
  product?: 'reference_check' | 'assessment'
}

type UserRoleQuery = BaseRoleQuery & {
  type: 'user'
  userId: string
}

type OrgRoleQuery = BaseRoleQuery & {
  type: 'org'
  orgId: string
}

export type RoleQuery = UserRoleQuery | OrgRoleQuery

async function fetchUserRoles(query: UserRoleQuery) {
  const extra: listing.Filter[] = []
  switch (query.product) {
    case 'assessment':
      extra.push(listing.True('open_job_roles.ask_self_assessment_product'))
      break
    case 'reference_check':
      extra.push(listing.True('open_job_roles.ask_reference_check'))
      break
  }

  return api.listing.list<api.openjobroleusers.Fields>(`users/${query.userId}/open_job_roles`, {
    limit: query.limit,
    include: [
      api.openjobroles.RESOURCE,
      api.candidates.RESOURCE,
      api.users.RESOURCE,
      api.profiles.RESOURCE,
      api.integrationConnections.RESOURCE,
    ],
    filters: [
      query.onlyDeleted ? api.listing.False('open_job_roles.is_active') : api.listing.True('open_job_roles.is_active'),
      api.listing.Includes('open_job_roles.name', query.title),
      ...(query.starred ? [api.listing.True('is_starred')] : []),
      ...extra,
    ],
    sort: query.sort,
    skip: query.skip,
  })
}

async function fetchOrgRoles(query: OrgRoleQuery): Promise<APIResponse<Entity<Fields>[]>> {
  const extra: listing.Filter[] = []
  switch (query.product) {
    case 'assessment':
      extra.push(listing.True('ask_self_assessment_product'))
      break
    case 'reference_check':
      extra.push(listing.True('ask_reference_check'))
      break
  }
  return api.listing.list<api.openjobroles.Fields>(`organizations/${query.orgId}/open_job_roles`, {
    limit: query.limit,
    filters: [api.listing.True('is_active'), api.listing.Includes('name', query.title), ...extra],
    include: [api.candidates.RESOURCE, api.users.RESOURCE, api.profiles.RESOURCE, api.integrationConnections.RESOURCE],
    sort: query.sort?.length
      ? query.sort
      : [
          {
            field: 'updated_at',
            order: api.listing.SortOrder.Desc,
          },
        ],
    skip: query.skip,
  })
}

export async function fetchRoles(query: RoleQuery) {
  switch (query.type) {
    case 'user': {
      return fetchUserRoles(query)
    }
    case 'org': {
      return fetchOrgRoles(query)
    }
  }
}

export async function starRole({ isStarred, roleId, userId }: { roleId: string; userId: string; isStarred: boolean }) {
  return put<Entity<api.openjobroles.Fields>>(`open_job_roles/${roleId}/users/${userId}/star`, {
    is_starred: isStarred,
  })
}

export async function getRoleAnalytics(id: string) {
  return apiGet<RoleAnalytics, {}>(`/open_job_roles/${id}/analytics`)
}
