import { Text, ToggleSwitch } from '@common/components'
import React from 'react'

interface Props extends React.Attributes {
  on: boolean
  label: string
  onChange: (on: boolean) => void
  disabled?: boolean
  className?: string
  icon?: React.ReactNode
  testId?: string
}

const ToggleField = (props: Props) => {
  const [on, setOn] = React.useState<boolean>(props.on)

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
      // set the aria-label to the text of the label react node
      aria-label={props.label}
    >
      <div style={{ width: '44px' }}>
        <ToggleSwitch
          on={on}
          name={props.label}
          setOn={value => {
            setOn(value)
            props.onChange(value)
          }}
          disabled={props.disabled}
          data-testid={props.testId}
        />
      </div>
      {!!props.label && (
        <div style={{ width: '100%', textAlign: 'left', paddingLeft: 8 }}>
          <Text variant="body-text">
            {props.label}
            {props.icon}
          </Text>
        </div>
      )}
    </div>
  )
}

export default ToggleField
