import React from 'react'
import { Column as LargeColumn } from '../../../../components/ContentGrid'
import { px2rem, style } from '../../../../core'
import { designSystemColors } from '../../../../core/design-system/colors'
import { text } from '../../../../core/design-system/text'
import { PREVIEW_GRID_COLUMNS } from './Row'

const HeaderRow = style()
  .grid({
    columns: PREVIEW_GRID_COLUMNS,
  })
  .nooverflow()
  .border({
    around: '1px solid',
    color: designSystemColors.borderDefault,
    radius: px2rem(8),
  })
  .element()

const Text = text.smallBody().color({ fg: designSystemColors.typographySecondary }).element('p')

const Column = style(LargeColumn)
  .size({ height: px2rem(30) })
  .element()

export const Header: React.FC = () => {
  return (
    <HeaderRow>
      <Column head />
      <Column head />
      <Column head>
        <Text>Area</Text>
      </Column>
      <Column head>
        <Text>Name</Text>
      </Column>
      <Column head>
        <Text>Duration</Text>
      </Column>
      <Column head>
        <Text>Language</Text>
      </Column>
      <Column head>
        <Text>Actions</Text>
      </Column>
    </HeaderRow>
  )
}
