import React from 'react'
import { Text } from '../../Text/Text'
import classes from './ResourceCard.module.scss'

interface ResourceCardProps {
  title: string
  description: string
  icon?: React.ReactNode
  onClick?: () => void
}

export const ResourceCard = (props: ResourceCardProps) => {
  return (
    <div className={classes.ResourceCard} onClick={props.onClick}>
      <div className={classes.ResourceCardIcon}>{props.icon}</div>
      <div className={classes.ResourceCardText}>
        <Text variant="home-card-header">{props.title}</Text>
        <Text variant="body-text" className={classes.ResourceDescription}>
          {props.description}
        </Text>
      </div>
    </div>
  )
}
