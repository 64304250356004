import { useUser } from 'providers/users'
import token from 'core/token'
import React, { useEffect } from 'react'
import { space, style } from '../../core'
import SingleColumnLayout, { Heading, Subheading } from '../SingleColumnLayout'

const Container = style().spacing({ inner: space.s }).element()

const Component = () => {
  const user = useUser()
  useEffect(() => {
    console.error('AccessDenied component reached', {
      url: `${window.location}`,
      user,
      token: { read: token.read(), status: token.status() },
    })
  }, [`${window.location}`, user.id])

  return (
    <SingleColumnLayout>
      <Container>
        <Heading>Access denied</Heading>
        <Subheading>
          Sorry, you don't have access to this page. Please <a href="mailto:support@hipeople.io">contact support</a> if
          you need help.
        </Subheading>
      </Container>
    </SingleColumnLayout>
  )
}

export default Component
Component.displayName = 'AccessDenied'
