import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Block, { Header } from '../../components/Block'
import Button from '../../components/Button/ButtonV1'
import Form from '../../components/Form'
import TextField from '../../components/TextField'
import * as selectors from '../../selectors'
import { RootState } from '../../store'
import { updatePrivacyPolicyLink } from '../../store/settings/org'

const Component = () => {
  const dispatch = useDispatch()
  const [newLink, setNewLink] = useState<string | undefined>(undefined)

  const [org, loading]: [ReturnType<typeof selectors.orgs.getById>, boolean] = useSelector((state: RootState) => {
    return [selectors.orgs.getById(state, selectors.orgs.currentId(state)), state.orgSettings.loading]
  })

  const notchanged = newLink === undefined

  return (
    <Block title="Privacy Policy" testId="privacy-policy-link">
      <Header>
        Explain to your candidates and references how their data is stored and protected by providing them a link to a
        privacy policy. Optionally, you can specify your organization's custom privacy policy by changing the URL below.
      </Header>
      <Form>
        <TextField
          label="Link to your privacy policy"
          value={org.fields.privacy_policy_link || ''}
          change={newLink}
          onChange={e => setNewLink(e.target.value)}
        />
        <div></div>
        <Button
          primary
          onClick={() => (org && newLink ? dispatch(updatePrivacyPolicyLink(org.id, newLink)) : null)}
          disabled={loading || notchanged}
        >
          Save
        </Button>
      </Form>
    </Block>
  )
}

export default Component
Component.displayName = 'PrivacyPolicyLink'
