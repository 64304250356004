import { breakpoints, px2rem, space, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import * as tracking from 'core/track'
import React from 'react'
import ButtonV2, { ButtonContent } from '../../components/Button/ButtonV2'

interface HeaderProps {}

const HeaderStyle = text
  .bodyInteractive()
  .screen(breakpoints.mobile, style().hidden())
  .fg(designSystemColors.typographySecondary)
  .flex({ justifyContent: 'flex-end', alignItems: 'center' })
  .spacing({ inner: space.s, gap: px2rem(6) })
  .element()

const HELP_LINK = 'https://intercom.help/hipeople/'

interface HeaderProps {
  fakeMode?: boolean
}
export const Header: React.FC<HeaderProps> = props => {
  return (
    <HeaderStyle>
      <ButtonV2
        buttonType={'tertiary'}
        onClick={() => {
          if (!props.fakeMode) {
            tracking.candidatePortal.support()
          }
          window.open(HELP_LINK)
        }}
      >
        <ButtonContent icon={{ name: 'life-ring', ariaLabel: 'Support' }}>{'Support'}</ButtonContent>
      </ButtonV2>
    </HeaderStyle>
  )
}
