import { EmojiColor } from 'components/Container/ContainerCard'
import { QuestionResponseType } from './questions'

export const RESOURCE = 'inventories'

export interface Fields {
  id: string
  product: 'reference-check' | 'self-assessment' | 'onboarding' | 'candidate-experience'
  slug: string
  locale: string
  is_selectable: string
  copy: {
    title: string
    emoji?: string
    emojiColor?: EmojiColor
    description: string
    preview_duration?: string
    preview_example_question?: string
    preview_introduction?: string
    preview_response_type?: QuestionResponseType
    preview_result_description?: string
    preview_when_to_use?: string
  }
  properties: {
    overlapped_by?: string[]
  }
  created_at: number
  updated_at: number
  is_active: boolean
}
