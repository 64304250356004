import { AssessmentStatus } from 'api/candidates'
import { Entity } from './request'

export const RESOURCE = 'questionnaire_submissions'

export interface Fields {
  id: string
  name: string
  questionnaire_id: string
  reference_id: string
  user_id: string
  employee_id: string
  manager_id: string
  self_assessment_candidate_id: string
  due_at: number
  due_after: number
  submitted_at: number
  is_rejected: boolean
  client_signature: string
  times_left_full_screen: number
  full_screen_supported: boolean
  time_mouse_left_window: number
  time_exceeded_modules: string[]
  ip: string
  device: string
  country: string
  city: string
  created_at: number
  updated_at: number
  status: AssessmentStatus
}

export type QuestionnaireSubmissionEntity = Entity<Fields>
