import React from 'react'
import { ArticleDescription } from '../../content'
import { colors, scale, size, space, style, weight } from '../../core'
import * as tracking from '../../core/track'
import { Icon } from '../Icons'

const Container = style()
  .select('a', style().text({ color: colors.midGray, nodecoration: true }))
  .element()

const Article = style()
  .grid({ columns: [space.xxl, size.auto] })
  .border({ around: '1px solid', color: colors.lightMoonGray })
  .round(size.s)
  .nooverflow()
  .element('a')

const Content = style()
  .grid()
  .spacing({ inner: [space.s, space.s], rows: space.xs })
  .element()

const Title = style().sans({ size: scale.s, weight: weight.normal }).element()

const Summary = style().sans({ size: scale.s }).element()

const Image = style().size({ width: size.fill, height: size.fill }).set('objectFit', 'cover').element('img')

const External = style()
  .grid({ columns: [size.xs, size.auto] })
  .nooverflow()
  .text({ nodecoration: true })
  .sans({ size: scale.xs, color: colors.midGray })
  .spacing({ columns: size.xxs })
  .element()

const ExternalText = style()
  .nooverflow()
  .text({ nodecoration: true })
  .sans({ ellipsis: true, weight: weight.lighter })
  .element()

const ImageContainer = style().nooverflow().element()

interface Props {
  content: ArticleDescription
  chipName: string
}

const Component = ({ content, chipName }: Props) => {
  return (
    <Container>
      <Article
        onClick={() =>
          tracking.references.knowledgeBiteContentViewed({
            chipName: chipName,
            article: content.url,
          })
        }
        href={content.url}
        target="_blank"
      >
        <ImageContainer>
          <Image src={content.image} />
        </ImageContainer>
        <Content>
          <Title>{content.title}</Title>
          <Summary>{content.summary}</Summary>
          <External>
            <Icon name={'link'} />
            <ExternalText>{content.url}</ExternalText>
          </External>
        </Content>
      </Article>
    </Container>
  )
}

export default Component
Component.displayName = 'Article'
