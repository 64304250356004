import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { updateSettings } from 'api/org-settings'
import { create } from 'api/orgs'
import { AppThunk } from 'core/store'
import { add as notify } from 'store/notifications'
import { addEntities } from 'store/resources'
import { sessions } from '../../api'
import { newSession, restore } from '../sessions'
import { signUp } from 'core/track'

const name = 'create-organization'

interface State {
  loading: boolean
  success: boolean
}

const initialState: State = {
  loading: false,
  success: false,
}

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setSuccess(state, action: PayloadAction<boolean>) {
      state.success = action.payload
    },
  },
})

export const { setLoading, setSuccess } = slice.actions

export default slice.reducer

export const createOrganization =
  (
    marketingConsent: boolean,
    acceptedTermsAndConditions: boolean,
    companyName: string,
    companySize: string,
    workEmail?: string,
    password?: string,
    fullName?: string,
    googleCode?: string,
    additionalInfo?: {
      jobTitle: string
      phoneNumber: string
      referredBy?: string
      interestedIn?: string
      useCase?: string
    },
  ): AppThunk =>
  async dispatch => {
    dispatch(setLoading(true))

    try {
      const [response] = await create(
        marketingConsent,
        acceptedTermsAndConditions,
        companyName,
        companySize,
        workEmail,
        password,
        fullName,
        googleCode,
        additionalInfo,
      )

      if (response) {
        response.included?.filter(r => r.resource === sessions.RESOURCE).forEach(r => dispatch(newSession(r)))
        dispatch(restore())
        dispatch(setSuccess(true))
        dispatch(addEntities(response))
      }

      if (!signUp.hasGASignupEvent()) {
        // The UTM params are stashed into local storage and removed from the URL in Router.tsx
        const utmParamsString = localStorage.getItem('utm_params')
        const utmParams = utmParamsString ? (JSON.parse(utmParamsString) as Record<string, string>) : undefined
        signUp.successfullySignedUp(utmParams)
      }

      return true
    } catch ([, errors]) {
      const verboseErrorMessages = {
        'non-valid-work-email': 'Please enter a valid work email address',
        'company-already-registered':
          "This company has already been registered. Please contact your organization's administrator to get access.",
      }

      errors.forEach(e => {
        dispatch(notify({ error: verboseErrorMessages[e.message] || e.message }))
      })

      return false
    } finally {
      dispatch(setLoading(false))
    }
  }

export const updateGenieSettings =
  (orgId: string, enabledReferenceCheck: boolean, enabledAssessment: boolean): AppThunk =>
  async dispatch => {
    dispatch(setLoading(true))
    const [response, errors] = await updateSettings(orgId, {
      updates: {
        hire_genie_enabled_assessment: enabledAssessment,
        hire_genie_enabled_reference_check: enabledReferenceCheck,
      },
      updated: ['hire_genie_enabled_reference_check', 'hire_genie_enabled_assessment'],
    })
    dispatch(setLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(addEntities(response))
    }

    return true
  }
