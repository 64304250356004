import { Entity } from './request'

export const RESOURCE = 'questionnaire_entries'

export interface Fields {
  id: string
  questionnaire_id: string
  module_id: string
  question_id: string
  created_at: number
  updated_at: number
  is_active: boolean
}

export type QuestionnaireEntryEntity = Entity<Fields>
