export default async function resizeImage(file: File, maxWidth: number, maxHeight: number) {
  const image = await loadImageFromFile(file)
  const fileName = file.name

  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  if (!ctx) {
    throw new Error('Failed to get canvas context.')
  }
  const size = getDimensionsToFit(image.width, image.height, maxWidth, maxHeight)

  canvas.width = size.width
  canvas.height = size.height

  ctx.fillStyle = 'white'
  ctx.fillRect(0, 0, canvas.width, canvas.height)
  ctx.drawImage(canvas, 0, 0)

  ctx.imageSmoothingQuality = 'high'
  ctx.drawImage(image, 0, 0, size.width, size.height)

  return new Promise<File>((resolve, reject) =>
    ctx.canvas.toBlob(
      blob => {
        if (!blob) {
          reject()
          return
        }
        resolve(
          new File([blob], fileName, {
            type: 'image/jpeg',
            lastModified: Date.now(),
          }),
        )
      },
      'image/jpeg',
      0.98, // 98% quality
    ),
  )
}

function loadImageFromFile(file: File) {
  const fileExtension = (file.name || '').split('.').pop()
  return new Promise<CanvasImageSource & { width: number; height: number }>((resolve, reject) => {
    const reader = new FileReader()
    reader.onerror = () => reject(`Cannot read file: ${fileExtension}`)
    reader.onload = event => {
      const img = new Image()
      img.onload = () => resolve(img)
      img.onerror = () => reject(`Cannot load image: ${fileExtension}`)
      if (event.target) {
        img.src = event.target.result as string
      }
    }
    reader.readAsDataURL(file)
  })
}

function getDimensionsToFit(width: number, height: number, maxWidth: number, maxHeight: number) {
  const scale = Math.min(1, maxWidth / width, maxHeight / height)
  return {
    width: Math.floor(width * scale),
    height: Math.floor(height * scale),
  }
}
