enum Events {
  // User
  USER_LOGIN = 'User Login',
  TRIPLE_DOT_HELP = 'Triple Dot Help Button',

  FREE_TRIAL_USER_REQUESTED_NEW_VERIFICATION_EMAIL = 'Free trial user requested new verification email',
  CHOOSE_PLAN_BANNER_CLICKED = 'Choose a plan banner clicked',
  CHOOSE_PLAN_TRIAL_EXPIRED_CLICKED = 'Choose a plan (trial expired) clicked',
  TALK_TO_PRODUCT_ADVISOR_CLICKED = 'Talk to a Product Advisor clicked',
  CHOOSE_PLAN_PDF_EXPORT = 'Choose a plan PDF Export',

  // Role creation flow
  ROLE_CREATION_TEST_ROLE_CREATED = 'Test Role Created',
  ROLE_CREATION_STARTED = 'Create new role - Started',
  ROLE_CREATION_DETAILS = 'Create new role - Title',
  ROLE_CREATION_TEAM = 'Create new role - Add team members',
  ROLE_CREATION_TEAM_MEMBER_ACCESS_REMOVED = 'Create new role - Removed team member access',
  ROLE_CREATION_CLICK_TO_ADD_USER = 'Create a new role - Click to add user',
  ROLE_CREATION_ADD_ASSESSMENT = 'Create new role - Added assessment',
  ROLE_CREATION_REMOVE_ASSESSMENT = 'Create new role - Removed assessment',
  ROLE_CREATION_ADD_REFERENCE_CHECK = 'Create new role - Added reference check',
  ROLE_CREATION_EDIT_TEXT_SELECTED = 'Create new role - Edit text selected',
  ROLE_CREATION_TEXT_UPDATED = 'Create new role - Text updated',
  ROLE_CREATION_UPLOAD_IMAGE = 'Create new role - Upload image selected',
  ROLE_CREATION_REMOVE_IMAGE = 'Create new role - Remove image selected',

  ROLE_CREATION_REMOVE_REFERENCE_CHECK = 'Create new role - Removed reference check',
  ROLE_CREATION_DOCUMENTATION = 'Create new role - Documentation',
  ROLE_CREATION_EXIT = 'Create new role - Exit',

  // Roles
  ROLE_CREATED = 'Role Created',
  ROLE_CREATED_LIFETIME = 'Lifetime Created Roles',
  ROLE_PAUSED = 'Role Paused',
  ROLE_ARCHIVED = 'Role Archived',
  ROLE_START = 'Role Start',
  ROLE_VIEWED = 'Role Viewed',
  ROLE_VIEW_AS_REFERENCE = 'Role View As Reference',
  ROLE_VIEW_AS_SELF_REFERENCE = 'Role View Self-Assessment',
  ROLE_UPDATE_REFERENCES = 'Role Update References',
  ROLE_UPDATE_EXPIRY_TIME = 'Role Update Expiry Time',
  ROLE_UPDATE_TITLES = 'Role Update Titles',
  ROLE_UPDATE = 'Role Update',
  ROLE_MANAGE_USERS = 'Role Manage Users',
  ROLE_ANALYTICS_ENGAGEMENT_CLICKED = 'Role Analytics Engagement Clicked',
  ROLE_ANALYTICS_PERFORMANCE_CLICKED = 'Role Analytics Performance Clicked',

  // Invite candidate flow(s)
  ROLE_INVITE_CANDIDATES = 'Invite candidate - Start',
  ROLE_INVITE_VIA_LINK_SELECTED = 'Invite via link - Selected',
  ROLE_INVITE_VIA_LINK_CLIPBOARD = 'Invite via link - Copy to clipboard',
  ROLE_INVITE_VIA_EMAIL_SELECTED = 'Invite via email - Selected',
  ROLE_INVITE_VIA_EMAIL_SENT = 'Invite via email - Sent',
  ROLE_INVITE_YOURSELF = 'Invite yourself - Start',

  // Templates
  TEMPLATE_PREVIEW = 'Template Preview',
  TEMPLATE_EDIT = 'Template Edit',
  TEMPLATE_ADD = 'Template Add',
  TEMPLATE_CREATE_NEW = 'Template Create New',
  TEMPLATE_CREATE_NEW_FROM_SELECTION = 'Template Create New From Selection',
  TEMPLATE_ADD_TO_ROLE = 'Template Add Existing Template',
  TEMPLATE_USE_CUSTOM = 'Template Use Custom',
  TEMPLATE_ADD_TO_LIBRARY = 'Template Add To Library',
  TEMPLATE_CLICK_SEARCH_BAR = 'Template Click Search Bar',
  TEMPLATE_CONFIRM = 'Template Confirm',

  // Candidates
  CANDIDATE_VIEWED = 'Candidate Viewed',
  CANDIDATE_UPDATED_EMAIL = 'Candidate Updated email',
  CANDIDATE_SEND_REMINDER = 'Candidate Send reminder',
  CANDIDATE_MARK_COMPLETED = 'Candidate Mark completed',
  CANDIDATE_GET_MANAGEMENT_LINK = 'Candidate Get reference management link',
  CANDIDATE_SHARED_INVITATION_SEND = 'Candidate Share invite send',
  CANDIDATE_SHARE_MODAL_OPENED = 'Candidate Share modal opened',
  CANDIDATE_SHARE_MODAL_DONE_CLICKED = 'Candidate Share modal Done clicked',
  CANDIDATE_WORK_VERIFICATION_MODAL_OPENED = 'Candidate Work Verification modal opened',
  CANDIDATE_EXPORT_AS_PDF = 'Candidate Export as PDF',
  CANDIDATE_ADD_A_REFERENCE = 'Candidate Add a reference',
  CANDIDATE_VIEW_SUMMARY = 'Candidate View summary',
  CANDIDATE_VIEW_ONBOARDING = 'Candidate View onboarding',
  CANDIDATE_VIEW_SELF_ASSESSMENT = 'Candidate View self assessment',
  CANDIDATE_VIEW_RESPONSES_DETAILS = 'Candidate View response details',
  CANDIDATE_VIEW_MODULE_NAME_CLICK = 'Candidate View clicked on module name',
  CANDIDATE_LIST_BY_QUESTION = 'Candidate List by question',
  CANDIDATE_REFERENCE_FILTER_APPLIED = 'Candidate Filter by reference',
  CANDIDATE_QUESTION_BY_SECTION_BLOCK_NAVIGATOR = 'Candidate question by section block navigator',
  CANDIDATE_UPDATED_REFERENCE_REQUIREMENTS = 'Candidate Updated Reference Requirements',
  CANDIDATE_DELETE = 'Candidate Delete',
  CANDIDATE_COMMENT_LEFT = 'Candidate Comment left',
  CANDIDATE_QUESTION_BY_HEATMAP_LABEL = 'Candidate question by heatmap label',
  CANDIDATE_CHANGE_HIRE_STATUS = 'Candidate change hire status',
  CANDIDATE_CHANGE_EVALUATION_STATUS = 'Candidate change evaluation status',
  CANDIDATE_SUMMARY_BLOCK_DETAILS_VIEWED = 'Candidate summary block details viewed',
  CANDIDATE_ASSESSMENT_PDF_EXPORT_CLICKED = 'Candidate assessment PDF export clicked',
  CANDIDATE_REF_CHECK_PDF_EXPORT_CLICKED = 'Candidate reference check PDF export clicked',
  SHARED_CANDIDATE_VIEWED = 'Shared Candidate Viewed',
  CANDIDATE_TABLE_SCORE_CLICKED = 'Candidate table score clicked',

  CANDIDATE_SEARCH_TABLE_FILTERED_BY_NAME = 'Filtered candidate table by name',
  CANDIDATE_SEARCH_TABLE_FILTERED_BY_STAGE = 'Filtered candidate table by stage',
  CANDIDATE_SEARCH_TABLE_FILTERED_BY_REF_CHECK_STATUS = 'Filtered candidate table by reference check status',
  CANDIDATE_SEARCH_TABLE_FILTERED_BY_ASSESSMENT_STATUS = 'Filtered candidate table by assessment status',
  CANDIDATE_TABLE_COLUMN_SELECTOR_CLICKED = 'Candidate table column selector clicked',
  CANDIDATE_SEARCH_TABLE_FILTERED_BY_EVALUATION_STATUS = 'Filtered candidate table by evaluation status',

  // Onboarding
  CANDIDATE_ONBOARDING_FAQ_BLOCK_OPENED = 'Candidate onboarding FAQ block opened',
  CANDIDATE_ONBOARDING_FAQ_BLOCK_CLOSED = 'Candidate onboarding FAQ block closed',
  CANDIDATE_ONBOARDING_DOCUMENTATION_BUTTON_CLICKED = 'Candidate onboarding documentation button clicked',
  CANDIDATE_ONBOARDING_SUPPORT_BUTTON_CLICKED = 'Candidate onboarding support button clicked',
  CANDIDATE_ONBOARDING_MISSING_INFORMATION_CLICKED = 'Candidate onboarding support button clicked',
  CANDIDATE_ONBOARDING_CONFIRM_START_DATE_MANAGER = 'Candidate onboarding confirms start date and manager',
  CANDIDATE_ONBOARDING_SKIPS_START_DATE_MANAGER = 'Candidate onboarding skips start date and manager',
  CANDIDATE_ONBOARDING_EXITS_START_DATE_MANAGER = 'Candidate onboarding exits start date and manager',
  CANDIDATE_ONBOARDING_CONFIRM_EMPLOYEE_EMAIL = 'Candidate onboarding confirms employee email',
  CANDIDATE_ONBOARDING_SKIPS_EMPLOYEE_EMAIL = 'Candidate onboarding skips employee email',
  CANDIDATE_ONBOARDING_SUMMARY_USEFUL = 'Candidate onboarding summary useful',
  CANDIDATE_ONBOARDING_SUMMARY_NOT_USEFUL = 'Candidate onboarding summary not useful',

  // Candidate Experience
  CANDIDATE_EXPERIENCE_DROPDOWN_CLICKED = 'Candidate Experience dropdown button clicked',
  CANDIDATE_EXPERIENCE_SCORES_CLICKED = 'Candidate Experience scores Button clicked',
  CANDIDATE_EXPERIENCE_FEEDBACK_CLICKED = 'Candidate Experience scores Feedback clicked',
  CANDIDATE_EXPERIENCE_ENABLE_NOW_CLICKED = 'Sidebar Candidate Experience - Enable now',
  CANDIDATE_EXPERIENCE_DATE_RANGE_SELECTED = 'Candidate Experience filter date range selected',
  CANDIDATE_EXPERIENCE_STATUS_SELECTED = 'Candidate Experience filter status selected',
  CANDIDATE_EXPERIENCE_HIRING_STAGE_SELECTED = 'Candidate Experience filter hiring stage selected',
  CANDIDATE_EXPERIENCE_RECRUITER_SELECTED = 'Candidate Experience filter recruiter selected',
  CANDIDATE_EXPERIENCE_DEPARTMENT_SELECTED = 'Candidate Experience filter department selected',
  CANDIDATE_EXPERIENCE_OFFICE_SELECTED = 'Candidate Experience filter office selected',
  CANDIDATE_EXPERIENCE_FILTERS_CLEARED = 'Candidate Experience filters cleared',

  CANDIDATE_FEEDBACK_PREVIEW = 'Candidate Feedback - Preview opened',
  CANDIDATE_FEEDBACK_CANCEL_SEND = 'Candidate Feedback - Cancel send',
  CANDIDATE_FEEDBACK_SEND = 'Candidate Feedback - Send',
  CANDIDATE_FEEDBACK_SEND_MODAL_OPENED = 'Candidate Feedback - Modal opened',
  CANDIDATE_FEEDBACK_KNOWLEDGE_SOURCE_OPENED = 'Candidate Feedback - Candidate knowledge source opened',

  // References
  SIDEBAR_REFERENCE_CHECK_VIEWED = 'Sidebar - Reference Check ',
  SIDEBAR_CREATE_REFERENCE_CHECK_CLICKED = 'Sidebar Reference Check - Create Reference Check',
  CHIP_VIEWED = 'Chip Viewed',
  CONTENT_VIEWED = 'Content Viewed',
  REFERENCE_VIEWED = 'Reference Viewed',
  REFERENCE_UPDATE_EMAIL = 'Reference Update email',
  REFERENCE_SHOW_DETAILS = 'Reference show details',
  REFERENCE_SHOW_MORE_ANSWERS = 'References Show more answers',
  REFERENCE_DELETE = 'Reference Delete',

  // Quality of Hire
  SIDEBAR_QUALITY_OF_HIRE_VIEWED = 'Sidebar - Quality of Hire',
  QUALITY_OF_HIRE_SETTINGS_MODAL_OPENED = 'Quality of Hire - Settings modal opened',
  QUALITY_OF_HIRE_SETTINGS_MODAL_CLOSED = 'Quality of Hire - Settings modal closed',
  QUALITY_OF_HIRE_SETTINGS_MODAL_TOGGLE_ENABLE_CLICKED = 'Quality of Hire - Settings modal toggle enabled clicked',
  QUALITY_OF_HIRE_SETTINGS_MODAL_CONFIRM_CLICKED = 'Quality of Hire - Settings modal confirm clicked',
  QUALITY_OF_HIRE_SETTINGS_MODAL_TIME_INTERVAL_CHANGED = 'Quality of Hire - Time interval changed',

  QUALITY_OF_HIRE_EMPLOYEE_REMOVED = 'Quality of Hire - Employee removed',
  QUALITY_OF_HIRE_EMPLOYEE_CLICKED = 'Quality of Hire - Employee clicked',

  QUALITY_OF_HIRE_ENABLE_CTA_BUTTON_CLICKED = 'Quality of Hire - Enable CTA button clicked',
  QUALITY_OF_HIRE_ADD_NEW_HIRE_MODAL_OPENED = 'Quality of Hire - Add new hire modal opened',
  QUALITY_OF_HIRE_ADD_NEW_HIRE_MODAL_CLOSED = 'Quality of Hire - Add new hire modal closed',
  QUALITY_OF_HIRE_ADD_NEW_HIRE_MODAL_CONFIRM_CLICKED = 'Quality of Hire - Add new hire modal confirm clicked',

  // Orgs
  ORG_SIGN_OUT = 'Org Sign out',
  ORG_ORGANIZATION_SETTINGS = 'Org Organization Settings',
  ORG_MY_ACCOUNT = 'Org My Account',
  ORG_MANAGE_USERS = 'Org Manage Users',
  ORG_HELP = 'Org Help',
  ORG_SETTINGS_CANDIDATE_EXPERIENCE_SAVE_SELECTION = 'Org settings - Save selection candidate experience',

  // Assessment
  SIDEBAR_ASSESSMENT_VIEWED = 'Sidebar - Assessment ',
  SIDEBAR_CREATE_ASSESSMENT_CLICKED = 'Sidebar Assessment - Create Assessment',
  SELF_ASSESSMENT_CREATION_SOFT_SKILL_SELECTED = 'Self Assessment Soft Skill Selected',
  SELF_ASSESSMENT_CREATION_SOFT_SKILL_UNSELECTED = 'Self Assessment Soft Skill Unselected',
  SELF_ASSESSMENT_CREATION_CUSTOM_QUESTIONS_ADD_TEXT_QUESTION = 'Self Assessment Custom Questions Add text question',
  SELF_ASSESSMENT_CREATION_CUSTOM_QUESTIONS_ADD_SELECTION_QUESTION = 'Self Assessment Custom Questions Add selection question',
  SELF_ASSESSMENT_CREATION_CUSTOM_QUESTIONS_ADD_VIDEO_QUESTION = 'Self Assessment Custom Questions Add video question',
  SELF_ASSESSMENT_CREATION_CUSTOM_QUESTIONS_ALLOW_MULTI_SELECT = 'Self Assessment Custom Questions Allow multi select',
  SELF_ASSESSMENT_CREATION_TEST_PREVIEW = 'Self Assessment - Preview Test',
  UNLOCK_RESPONSES_CLICKED = 'Unlock Responses Clicked',

  // New content selection
  CONTENT_SELECTION_DOCUMENTATION_BUTTON_CLICKED = 'Content Selection - documentation button clicked',
  CONTENT_SELECTION_ADD_TESTS_BUTTON_CLICKED = 'Content Selection - add tests button clicked',
  CONTENT_SELECTION_ADD_TEXT_QUESTION_BUTTON_CLICKED = 'Content Selection - add text question button clicked',
  CONTENT_SELECTION_ADD_SELECTION_QUESTION_BUTTON_CLICKED = 'Content Selection - add selection question button clicked',
  CONTENT_SELECTION_ADD_VIDEO_QUESTION_BUTTON_CLICKED = 'Content Selection - add video question button clicked',
  CONTENT_SELECTION_EDIT_CUSTOM_QUESTION_BUTTON_CLICKED = 'Content Selection - edit custom question button clicked',
  CONTENT_SELECTION_REMOVE_BUTTON_CLICKED = 'Content Selection - remove button clicked',
  CONTENT_SELECTION_MODAL_CANCEL_CLICKED = 'Content Selection - modal cancel clicked',
  CONTENT_SELECTION_MODAL_VALIDATE_SELECTION_CLICKED = 'Content Selection - modal Use tests button clicked',
  CONTENT_SELECTION_MODAL_GROUP_FILTER_CLICKED = 'Content Selection - modal group filter clicked',
  CONTENT_SELECTION_MODAL_CLOSE_CLICKED = 'Content Selection - modal close clicked',
  CONTENT_SELECTION_OPEN_PREVIEW_BUTTON_CLICKED = 'Content Selection - open preview button clicked',
  CONTENT_SELECTION_LIBRARY_ITEM_TOGGLE_CLICKED = 'Content Selection - library item toggle clicked',
  CONTENT_SELECTION_AI_LIBRARY_ITEM_TOGGLE_CLICKED = 'Content Selection - AI suggested library item toggle clicked',
  CONTENT_SELECTION_CONFIRM_CLICKED = 'Content Selection - confirm clicked',

  // AI-tools
  AI_TOOLS_CONTENT_SELECTION_RELOAD_BUTTON_CLICKED = 'AI Tools - Content Selection Reload AI Suggestions Button Clicked',
  AI_TOOLS_CUSTOM_QUESTION_ADDED = 'AI Tools - Custom Question Added',

  // Candidate portal with event names mirroring original candidate-app event names.
  SELF_ASSESSMENT_SUMMARY_TAB_SELECTED = 'Self Assessment Summary Tab Selected',
  CANDIDATE_PORTAL_START = 'Candidate Invitation Start',
  CANDIDATE_PORTAL_LEGAL_CONSENT_DECLINE = 'Candidate Legal Consent - Declined',
  CANDIDATE_PORTAL_LEGAL_CONSENT_GIVE = 'Candidate Legal Consent - Given',
  CANDIDATE_PORTAL_ADD_PROFESSIONAL_NETWORK = 'Candidate Social Network - Continue',
  CANDIDATE_PORTAL_REFERENCE_ADD_OLD = 'Candidate Contact Form - Continue',
  CANDIDATE_PORTAL_SUBMITS = 'Candidate Submits',

  // Candidate portal new events
  CANDIDATE_PORTAL_ADD_ADDITIONAL_REFERENCE = 'Candidate Additional Reference Add',
  CANDIDATE_PORTAL_OPEN = 'Candidate Open',
  CANDIDATE_PORTAL_REFERENCE_VIEWED = 'Candidate Reference Page Viewed',
  CANDIDATE_PORTAL_REFERENCE_ADD = 'Candidate Reference Added',
  CANDIDATE_PORTAL_REFERENCE_UPDATED = 'Candidate Reference Updated',
  CANDIDATE_PORTAL_SUPPORT = 'Candidate Support',
  CANDIDATE_PORTAL_HIPEOPLE_CLICKED = 'Candidate HiPeople Clicked',

  // Talent Pool
  TALENT_POOL_VIEW = 'Talent Pool View',
  TALENT_POOL_EMAIL_CLICKED = 'Talent Pool Email clicked',
  TALENT_POOL_SIDEBAR_CREATE_REFERENCE_CHECK_CLICKED = 'Sidebar Talent Pool - Start with Reference Check',

  // Sales pool
  SALES_POOL_SIDEBAR_CLICKED = 'Sales pool sidebar clicked',
  SALES_POOL_REFERENCE_EMAIL_CLICKED = 'Sales pool reference email clicked',
  SALES_POOL_SIDEBAR_CREATE_REFERENCE_CHECK_CLICKED = 'Sales pool sidebar create reference check clicked',

  // Checkr events
  CHECKR_CONNECTION_INITIATED = 'Checkr connection initiated',
  CHECKR_DISCONNECTION_INITIATED = 'Checkr disconnect button clicked',
  CHECKR_DISCONNECTION_PROCEEDED = 'Proceeded with Checkr disconnection',
  CHECKR_BUTTON_CLICKED = 'Background Check with Checkr button clicked',
  CHECKR_TAB_VIEWED = 'Checkr reports tab viewed',

  // Sign-Up events
  SELF_SIGN_UP_PROVIDED_WORK_EMAIL = 'Provided work email during self sign up flow',
  SELF_SIGN_UP_PROVIDED_COMPANY_NAME = 'Provided company name during self sign up flow',
  SELF_SIGN_UP_VERIFIED_EMAIL = 'Verified email after self sign up flow',
  SELF_SIGN_UP_WITH_GOOGLE_AUTH = 'Used Google Sign-Up during self sign up flow',
  SUCCESSFULLY_SIGNED_UP = 'Successfully singed up',

  // Sidebar
  SIDEBAR_ROLES_BUTTON_CLICKED = 'Sidebar - Roles',
  SIDEBAR_CANDIDATES_BUTTON_CLICKED = 'Sidebar - Candidates',
  SIDEBAR_REPORTS_BUTTON_CLICKED = 'Sidebar - Reports',
  SIDEBAR_TALENT_NETWORK_BUTTON_CLICKED = 'Sidebar - Talent Network',
  SIDEBAR_HOME_BUTTON_CLICKED = 'Sidebar - Home',

  // Home
  HOME_ASSESSMENTS_CARD_CLICKED = 'Home - Assessments Card Quick Start Clicked',
  HOME_CANDIDATE_EXPERIENCE_CARD_CLICKED = 'Home - Candidate Experience Card Quick Start Clicked',
  HOME_REFERENCE_CHECKS_CARD_CLICKED = 'Home - Reference Checks Card Quick Start Clicked',
  HOME_QUALITY_OF_HIRE_CARD_CLICKED = 'Home - Quality of Hire Card Quick Start Clicked',
  HOME_JOB_ROW_CLICKED = 'Home - Job Row Clicked',
  HOME_INVITE_TEAM_CLICKED = 'Home - Invite Team Clicked',
  HOME_RESOURCE_CLICKED = 'Home - Resource Clicked',
  HOME_TASK_CLICKED = 'Home - Task Clicked',

  // Misc
  FILE_DOWNLOADED = 'File Downloaded',
  FILE_DOWNLOADED_LIFETIME = 'Lifetime Downloaded Files',

  VIDEO_PLAYED = 'Video Played',
  VIDEO_PLAYED_LIFETIME = 'Lifetime Videos Played',

  AUDIO_PLAYED = 'Audio Played',
  AUDIO_PLAYED_LIFETIME = 'Lifetime Audios Played',

  // AI ASSISTANT
  AI_DEFAULT_PROMPT_CLICKED = 'AI Assistant - Default Prompt Clicked',
  AI_CUSTOM_PROMPT_SENT = 'AI Assistant - Custom Prompt Sent',
  AI_REGENERATE_ANSWER_CLICKED = 'AI Assistant - Regenerate Answer Clicked',
  AI_READ_MORE_CLICKED = 'AI Assistant - Read More Clicked',

  // LINKS
  USED_LINK_FROM_SOURCE = 'Used Link from Source',
  REFER_LINK_CLICKED = 'Topbar - Refer & earn Link Clicked',
  BOOK_DEMO_LINK_CLICKED = 'Topbar - Book Demo Link Clicked',
  UPGRADE_LINK_CLICKED = 'Topbar - Upgrade Link Clicked',

  // LOCKED CONTENT
  LOCKED_MODAL_OPENED = 'Locked Content - Modal Opened',
  LOCKED_MODAL_CLOSED = 'Locked Content - Modal Closed',
  LOCKED_LEARN_MORE_CLICKED = 'Locked Content - Learn More Clicked',
  LOCKED_UPGRADE_CLICKED = 'Locked Content - Upgrade Clicked',

  // PRICING PAGE
  PRICING_PAGE_UPGRADE_BUTTON_CLICKED = 'Pricing Page - Upgrade Button Clicked',
  PRICING_PAGE_BILLING_PERIOD_TOGGLED = 'Pricing Page - Billing Period Toggled',
}

export default Events
