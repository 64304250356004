import React, { useRef, useState } from 'react'
import classes from './VideoPlayer.module.scss'

type VideoPlayerProps = React.VideoHTMLAttributes<HTMLVideoElement> & {
  src: string
  poster?: string
  controls?: boolean
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ src, poster, controls = true, ...props }) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [isPlaying, setIsPlaying] = useState(false)

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause()
        setIsPlaying(false)
      } else {
        videoRef.current.play()
        setIsPlaying(true)
      }
    }
  }

  const handleVideoEnd = () => {
    setIsPlaying(false)
  }

  return (
    <div className={classes.videoContainer}>
      <p className={classes.onlyShowInPrint}>Video response not available in print</p>
      <video
        ref={videoRef}
        src={src}
        poster={poster}
        controls={controls}
        className={classes.video}
        onEnded={handleVideoEnd}
        {...props}
      />
      {!controls && (
        <button className={classes.playPauseButton} onClick={handlePlayPause}>
          {isPlaying ? 'Pause' : 'Play'}
        </button>
      )}
    </div>
  )
}

export { VideoPlayer }
