import action from './action'
import events from './events'
import track from './track'

export const view = action(() => {
  track(events.TALENT_POOL_VIEW, {})
})

export const emailClicked = action(() => {
  track(events.TALENT_POOL_EMAIL_CLICKED, {})
})

export const sidebarCreateReferenceCheckClicked = action(() => {
  track(events.TALENT_POOL_SIDEBAR_CREATE_REFERENCE_CHECK_CLICKED, {})
})
