import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { orgs } from 'api'
import { AppThunk } from 'core/store'
import { add as notify } from 'store/notifications'
import { FilterOptions, Filters } from '../../api/orgs'

const name = 'candidate-experience'

export type QuestionAggregate = {
  question_id: string
  average_overall: number | null
  average_this_month: number | null
}

export type NPSByMonth = {
  year: number
  month: number
  day: number
  nps: number | null // raw
  nps_score: number | null // -100 -> 100
}

export type TextQuestions = Record<string, { response: string; date: number }[]>

interface State {
  numericQuestions: QuestionAggregate[]
  npsByMonth: NPSByMonth[]
  npsOverall: number // raw
  npsScoreOverall: number // -100 -> 100
  textQuestions: TextQuestions
  textResponsesBest: TextQuestions
  textResponsesImprovements: TextQuestions
  textResponsesNotCovered: TextQuestions
  surveysSent: number
  totalResponses: number
  neverOpened: number
  filters: FilterOptions
}

const initialState: State = {
  numericQuestions: [],
  npsByMonth: [],
  npsOverall: 0,
  npsScoreOverall: 0,
  textQuestions: {},
  textResponsesBest: {},
  textResponsesImprovements: {},
  textResponsesNotCovered: {},
  surveysSent: 0,
  totalResponses: 0,
  neverOpened: 0,
  filters: {},
}

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setNumericQuestions(state, action: PayloadAction<QuestionAggregate[]>) {
      state.numericQuestions = action.payload
    },
    setNPSByMonth(state, action: PayloadAction<NPSByMonth[]>) {
      state.npsByMonth = action.payload
    },
    setNPSOverall(state, action: PayloadAction<number>) {
      state.npsOverall = action.payload
    },
    setNPSScoreOverall(state, action: PayloadAction<number>) {
      state.npsScoreOverall = action.payload
    },
    setTextQuestions(
      state,
      action: PayloadAction<{
        questionId: string
        responses: { response: string; date: number }[]
      }>,
    ) {
      const { questionId, responses } = action.payload
      state.textQuestions = { ...state.textQuestions, [questionId]: responses }
    },
    setSurveysSent(state, action: PayloadAction<number>) {
      state.surveysSent = action.payload
    },
    setTotalResponses(state, action: PayloadAction<number>) {
      state.totalResponses = action.payload
    },
    setNeverOpened(state, action: PayloadAction<number>) {
      state.neverOpened = action.payload
    },
    setTextResponsesBest(
      state,
      action: PayloadAction<{
        questionId: string
        responses: { response: string; date: number }[]
      }>,
    ) {
      const { questionId, responses } = action.payload
      state.textResponsesBest = {
        ...state.textResponsesBest,
        [questionId]: responses,
      }
    },
    setTextResponsesImprovements(
      state,
      action: PayloadAction<{
        questionId: string
        responses: { response: string; date: number }[]
      }>,
    ) {
      const { questionId, responses } = action.payload
      state.textResponsesImprovements = {
        ...state.textResponsesImprovements,
        [questionId]: responses,
      }
    },
    setTextResponsesNotCovered(
      state,
      action: PayloadAction<{
        questionId: string
        responses: { response: string; date: number }[]
      }>,
    ) {
      const { questionId, responses } = action.payload
      state.textResponsesNotCovered = {
        ...state.textResponsesNotCovered,
        [questionId]: responses,
      }
    },
    setFilters(state, action: PayloadAction<FilterOptions>) {
      state.filters = action.payload
    },
  },
})

export const loadFilters =
  (orgId: string): AppThunk =>
  async dispatch => {
    const [response, errors] = await orgs.getCandidateExperienceFilters(orgId)
    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    if (response && response.meta) {
      dispatch(slice.actions.setFilters(response.meta))
    }
  }

export const loadSummaries =
  (orgId: string, filters: Filters, startDate?: Date, endDate?: Date): AppThunk =>
  async dispatch => {
    const [response, errors] = await orgs.getCandidateExperienceSummary(orgId, filters, startDate, endDate)
    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    if (response && response.meta) {
      dispatch(slice.actions.setNumericQuestions(response.meta.questions))
      dispatch(slice.actions.setNPSByMonth(response.meta.nps_by_month))
      dispatch(slice.actions.setNPSOverall(response.meta.nps_overall))
      dispatch(slice.actions.setNPSScoreOverall(response.meta.nps_score_overall))
      dispatch(slice.actions.setSurveysSent(response.meta.questionnaires_sent))
      dispatch(slice.actions.setTotalResponses(response.meta.questionnaires_submitted))
      const neverOpened =
        response.meta.questionnaires_sent -
        Math.max(response.meta.questionnaires_opened, response.meta.questionnaires_submitted)
      dispatch(slice.actions.setNeverOpened(neverOpened))
    }
  }

export const loadTextResponses =
  (
    orgId: string,
    questionId: string,
    filters: Filters,
    startDate: Date | undefined,
    endDate: Date | undefined,
  ): AppThunk =>
  async dispatch => {
    const [response, errors] = await orgs.getCandidateExperienceTextResponse(
      orgId,
      questionId,
      filters,
      startDate,
      endDate,
    )
    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    if (response) {
      const items = response?.result?.map(res => ({
        response: res.fields.text_input,
        date: res.fields.created_at,
      }))

      dispatch(
        slice.actions.setTextQuestions({
          questionId: questionId,
          responses: items,
        }),
      )
    }
  }

export const loadTextResponsesBest =
  (
    orgId: string,
    questionId: string,
    filters: Filters,
    startDate: Date | undefined,
    endDate: Date | undefined,
  ): AppThunk =>
  async dispatch => {
    const [response, errors] = await orgs.getCandidateExperienceTextResponseHighlights(
      orgId,
      questionId,
      filters,
      startDate,
      endDate,
    )
    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    if (response) {
      const items = response?.result?.map(res => ({
        response: res.fields.text_input,
        date: res.fields.created_at,
      }))

      dispatch(
        slice.actions.setTextResponsesBest({
          questionId: questionId,
          responses: items,
        }),
      )
    }
  }

export const loadTextResponsesImprovements =
  (
    orgId: string,
    questionId: string,
    filters: Filters,
    startDate: Date | undefined,
    endDate: Date | undefined,
  ): AppThunk =>
  async dispatch => {
    const [response, errors] = await orgs.getCandidateExperienceTextResponseHighlights(
      orgId,
      questionId,
      filters,
      startDate,
      endDate,
    )
    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    if (response) {
      const items = response?.result?.map(res => ({
        response: res.fields.text_input,
        date: res.fields.created_at,
      }))

      dispatch(
        slice.actions.setTextResponsesImprovements({
          questionId: questionId,
          responses: items,
        }),
      )
    }
  }

export const loadTextResponsesNotCovered =
  (
    orgId: string,
    questionId: string,
    filters: Filters,
    startDate: Date | undefined,
    endDate: Date | undefined,
  ): AppThunk =>
  async dispatch => {
    const [response, errors] = await orgs.getCandidateExperienceTextResponseHighlights(
      orgId,
      questionId,
      filters,
      startDate,
      endDate,
    )
    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    if (response) {
      const items = response?.result?.map(res => ({
        response: res.fields.text_input,
        date: res.fields.created_at,
      }))

      dispatch(
        slice.actions.setTextResponsesNotCovered({
          questionId: questionId,
          responses: items,
        }),
      )
    }
  }

export default slice.reducer
