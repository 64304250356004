import { px2rem, size, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React from 'react'

const NoResultsImg = style()
  .block()
  .size({ width: px2rem(146), height: px2rem(146) })
  .element('img')

const NoResultsDescription = text
  .cardHeader()
  .color({ fg: designSystemColors.typographyPrimary })
  .sans({ align: 'center' })
  .element('p')

const NoResultsStyle = style()
  .size({ height: `calc(${size.fill} - ${px2rem(36)})` })
  .border({
    around: '1px solid',
    color: designSystemColors.borderDefault,
    radius: [px2rem(8)],
  })
  .nooverflow()
  .color({ bg: designSystemColors.backgroundNeutralPrimary })
  .flex({ direction: 'column', alignItems: 'center', justifyContent: 'center' })
  .spacing({ gap: px2rem(18), outerBottom: px2rem(36) })
  .element()

interface NoResultsProps {
  type: ResultsNotFound
}

export type ResultsNotFound = 'no-candidates-found' | 'no-candidates-exist'

const NO_CANDIDATES_EXIST_IMAGE = '/assets/woman_on_laptop.png'
const NO_CANDIDATES_FOUND_IMAGE = '/assets/thinking.png'

export const NoResults: React.FC<NoResultsProps> = props => {
  switch (props.type) {
    case 'no-candidates-exist': {
      return (
        <NoResultsStyle>
          <NoResultsImg src={NO_CANDIDATES_EXIST_IMAGE} alt="No candidates exist" />
          <NoResultsDescription>{'Candidates added to your Jobs appear here'}</NoResultsDescription>
        </NoResultsStyle>
      )
    }
    case 'no-candidates-found': {
      return (
        <NoResultsStyle>
          <NoResultsImg src={NO_CANDIDATES_FOUND_IMAGE} alt="No candidates found" />
          <NoResultsDescription>{'No Candidates found'}</NoResultsDescription>
        </NoResultsStyle>
      )
    }
  }
}
