import action from './action'
import events from './events'
import track from './track'

export const modalOpened = action((learnMoreAbout: string) => {
  track(events.LOCKED_MODAL_OPENED, { learnMoreAbout })
})

export const modalClosed = action(() => {
  track(events.LOCKED_MODAL_CLOSED, {})
})

export const learnMoreClicked = action((learnMoreAbout: string) => {
  track(events.LOCKED_LEARN_MORE_CLICKED, {
    learnMoreAbout,
  })
})

export const upgradeClicked = action((learnMoreAbout: string) => {
  track(events.LOCKED_UPGRADE_CLICKED, { learnMoreAbout })
})

export const lockedContentTracking = {
  modalOpened,
  modalClosed,
  learnMoreClicked,
  upgradeClicked,
}
