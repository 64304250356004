import { del, post } from './methods'
import { APIResponse, Entity } from './request'

export const RESOURCE = 'comments'

export interface Fields {
  id: string
  user_id: string
  candidate_id: string
  text: string
  is_internal: boolean
  created_at: number
  updated_at: number
  deleted_at: number
}

export function add(candidateId: string, text: string, mentions: string[]): Promise<APIResponse<Entity<Fields>>> {
  return post<Entity<Fields>>(`candidates/${candidateId}/comments`, {
    text,
    // govalidate will try to validate an empty slice of users
    mentions: mentions.length ? mentions : undefined,
  })
}

export function remove(candidateId: string, commentId: string): Promise<APIResponse<Entity<Fields>>> {
  return del<Entity<Fields>>(`candidates/${candidateId}/comments/${commentId}`, {})
}
