import { del, get, post } from './methods'
import { APIResponse, Entity } from './request'

export const RESOURCE = 'integrations'

export interface Fields {
  id: string
  organization_id: string
  ats_slug: string
  created_at: number
  updated_at: number
}

interface LinkTokenFields {
  link_token: string
}

interface OAuthFields {
  url: string
}

interface RegisterFields {}

interface HelpFields {
  help: string
}

export interface Job {
  id: string
  remote_id: string
  code: string
  name: string
  location: string
}

export interface JobInterviewStage {
  id: string
  name: string
}

export function installOAuthATS(orgId: string, atsID: string): Promise<APIResponse<Entity<OAuthFields>>> {
  return post<Entity<OAuthFields>>(`organizations/${orgId}/integrations/install/oauth`, { ats_id: atsID })
}

export function installLinkTokenATS(orgId: string, atsID: string): Promise<APIResponse<Entity<LinkTokenFields>>> {
  return post<Entity<LinkTokenFields>>(`organizations/${orgId}/integrations/install/link_token`, { ats_id: atsID })
}

export function installLinkTokenATSAccount(
  orgId: string,
  publicToken: string,
): Promise<APIResponse<Entity<RegisterFields>>> {
  return post<Entity<RegisterFields>>(`organizations/${orgId}/integrations/install/link_token/account`, {
    public_token: publicToken,
  })
}

export function installAPIKeyATS(orgId: string, atsID: string, apiKey: string): Promise<APIResponse<Entity<{}>>> {
  return post<Entity<{}>>(`organizations/${orgId}/integrations/install/api_key`, { ats_id: atsID, api_key: apiKey })
}

export function installAPIKeyBasicAuthATS(
  orgId: string,
  atsID: string,
  apiKey: string,
  url: string,
  username: string,
  password: string,
): Promise<APIResponse<Entity<{}>>> {
  return post<Entity<{}>>(`organizations/${orgId}/integrations/install/api_key_basic`, {
    ats_id: atsID,
    api_key: apiKey,
    url: url,
    username: username,
    password: password,
  })
}

export function getATSJobs(integrationId: string, jobStatus: string): Promise<APIResponse<Entity<Job[]>>> {
  return get<Entity<Job[]>>(`integrations/${integrationId}/ats_jobs?job_status=${jobStatus}`)
}

export function getATSJobInterviewStages(
  integrationId: string,
  atsJobId: string,
): Promise<APIResponse<Entity<JobInterviewStage[]>>> {
  return get<Entity<JobInterviewStage[]>>(`integrations/${integrationId}/ats_jobs/${atsJobId}/job_interview_stages`)
}

export function deleteOrgIntegration(orgId: string, integrationId: string): Promise<APIResponse<Entity<Fields>>> {
  return del<Entity<any>>(`organizations/${orgId}/integrations/${integrationId}?include=organizations,integrations`, {})
}

export function getIntegrationHelp(integrationId: string): Promise<APIResponse<Entity<HelpFields>>> {
  return get<Entity<HelpFields>>(`integrations/${integrationId}/help`)
}
