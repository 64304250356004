import { fr, px2rem, size, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import React from 'react'
import Bar from './Bar'
import DiffToAverage from './DiffToAverage'
import OrgAverage from './OrgAverage'
import OverallScore from './OverallScore'

interface Props {
  overall: number | null
  average: number | null
  noAverage?: boolean
}

export const DECIMAL_PRECISION_BENCHMARKS = 1

const Container = style()
  .size({ width: size.fill, height: size.fill })
  .bg({ color: designSystemColors.backgroundNeutralSecondary })
  .flex({ direction: 'column', justifyContent: 'center' })
  .border({ radius: px2rem(2) })
  .element()

const NoAverageBenchmarkGrid = style()
  .grid({ columns: [fr(1.2), fr(4), fr(1)] })
  .spacing({ columns: px2rem(6) })
  .element()

const BenchmarkGrid = style()
  .grid({ columns: [fr(1), fr(2), fr(1), fr(1)] })
  .element()

const Benchmark: React.FC<Props> = ({ average, overall, noAverage }) => {
  if (noAverage) {
    return (
      <Container>
        <NoAverageBenchmarkGrid>
          <OverallScore overall={overall} />
          <Bar overall={overall} average={average} />
          <OrgAverage average={average} />
        </NoAverageBenchmarkGrid>
      </Container>
    )
  }

  return (
    <Container>
      <BenchmarkGrid>
        <OverallScore overall={overall} />
        <Bar overall={overall} average={average} />
        <DiffToAverage average={average} overall={overall} />
        <OrgAverage average={average} />
      </BenchmarkGrid>
    </Container>
  )
}

export default Benchmark
