import { Button, LockedByPlan, LockIcon } from '@common/components'
import { lockedContentTracking } from 'core/track/locked-content'
import React from 'react'
import { useSelector } from 'react-redux'
import Block, { Header } from '../../components/Block'
import { BrandIcon } from '../../components/Icons'
import { Subheading } from '../../components/Typography'
import { space, style } from '../../core'
import * as selectors from '../../selectors'

const Content = style()
  .spacing({ outerTop: space.m })
  .select('a', style().text({ nodecoration: true }))
  .element()

const ButtonContent = style().flex({ alignItems: 'center', justifyContent: 'center' }).element()

const Label = style().spacing({ outerLeft: space.xxs }).element('span')

const Component = () => {
  const isOrgPaidPlan = useSelector(selectors.orgs.isOrgPaidPlan)

  return (
    <LockedByPlan
      tracking={lockedContentTracking}
      locked={!isOrgPaidPlan}
      lockIconDisabled={true}
      upgradeTo={'Scale'}
      modalData={{
        learnMoreLink: 'https://intercom.help/hipeople/en/articles/7536982-connecting-slack-with-hipeople',
        learnMoreAbout: 'Integrating Slack',
      }}
    >
      <Block
        testId="slack-workspace"
        title={
          <>
            Slack Workspace
            <LockIcon visible={!isOrgPaidPlan} />
          </>
        }
      >
        <Header>
          <Subheading>
            Integrate HiPeople into your Slack workspace so that users can get updates about their jobs, candidates, and
            references without having to leave Slack. Following the installation process started by the button below,
            members of your workspace can then navigate to HiPeople in the app directory, and click "Connect your
            HiPeople account" to login and receive updates over Slack.
          </Subheading>
        </Header>
        <Content>
          <a href={`${import.meta.env.VITE_API_BASE}/api/slack/install`} target="_blank" rel="noopener noreferrer">
            <Button variant="purple-dark" style={{ width: '100%' }}>
              <ButtonContent>
                <BrandIcon name="slack" /> <Label>Add to Slack</Label>
              </ButtonContent>
            </Button>
          </a>
        </Content>
      </Block>
    </LockedByPlan>
  )
}

export default Component
Component.displayName = 'AddToSlack'
