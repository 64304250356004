const minPasswordLength = 8
const specialCharacters = Array.from('!@#$%^&*~+=-.,:;()[]/')

const hasNumber = (password: string) => /\d/.test(password)
const hasSpecialCharacter = (password: string) => specialCharacters.some(specialChar => password.includes(specialChar))

export const passwordError = (password: string) => {
  let error: string = ''
  if (password.length < minPasswordLength) {
    error += `Password must be at least ${minPasswordLength}  characters.\n`
  }
  if (!hasNumber(password)) {
    error += `Password must contain at least one number.\n`
  }

  if (!hasSpecialCharacter(password)) {
    error += `Password must contain at least one special character. Characters allowed are ${specialCharacters.join(
      ' ',
    )}`
  }
  return error
}

export const isPasswordValidWithConfirmation = (password: string, passwordConfirm: string) => {
  return passwordError(password) === '' && password === passwordConfirm
}
