import { RejectionReason } from 'api/reference'
import EmptyAnswer from 'App/Candidate/EmptyAnswer'
import ChipAnswer from 'App/Candidate/RDSChipsAnswer'
import Followup from 'App/Candidate/ReferenceFollowup/RDSFollowup'
import { style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import React from 'react'
import { FeedbackStyleType } from 'selectors/halo-score'
import { Relationship } from 'selectors/references'
import { EmptyAnswerSection } from './Chips'
import MultiChipContainer from './MultiChipContainer'
import { ReferenceAnswer } from './ReferenceAnswerV2'

const ChipsStyle = style()
  .select(
    '> * + *',
    style().border({
      top: 'solid 1px',
      color: designSystemColors.borderDefault,
    }),
  )
  .element()

type ReferenceDetails = {
  id: string
  relationship: Relationship
  professionalNetwork?: {
    name: string
    url: string
  }
  name: string
  email: string
  companyName: string
  from: number
  to: number
  feedbackStyle?: FeedbackStyleType
  rejectionReason?: RejectionReason
  isReviewed: boolean
  isVerified: boolean
  completed: boolean
}

type ReferenceId = string

type FollowUpQuestion = {
  question: string
  description: string
  skipped?: boolean
  input?: string
  mediaURL?: string
}

export type ChipAnswerType = {
  selected?: string[]
  input?: string
  skipped?: boolean
  followUpQuestion?: FollowUpQuestion[]
}

interface ChipAnswerProps {
  references: ReferenceDetails[]
  answers: Record<ReferenceId, ChipAnswerType>
  candidateName: string
  allChips?: { id: string; value: string; caption: string }[]
  outstandingOptionValue?: string
}

const Chips = ({ references, candidateName, answers, allChips, outstandingOptionValue }: ChipAnswerProps) => {
  if (references.length === 0) {
    return (
      <EmptyAnswerSection>
        <EmptyAnswer waitingReferences noSpacing />
      </EmptyAnswerSection>
    )
  }

  return (
    <ChipsStyle>
      {references.map(ref => {
        const answer = answers[ref.id]

        if (!answer || answer?.skipped || !answer?.selected?.length) {
          return (
            <ReferenceAnswer key={ref.id} reference={ref} candidateName={candidateName}>
              <EmptyAnswer
                leftBlank={!answer?.selected?.length}
                waitingResponse={!ref.completed}
                skipped={answer?.skipped}
                notApplicableForCandidate={ref.relationship === Relationship.Self && !answer && ref.completed}
              />
            </ReferenceAnswer>
          )
        }

        return (
          <React.Fragment key={ref.id}>
            {allChips && outstandingOptionValue && (
              <MultiChipContainer chips={allChips} outstandingOptionValue={outstandingOptionValue} />
            )}
            <ReferenceAnswer reference={ref} candidateName={candidateName}>
              <ChipAnswer input={answer?.input || ''} selected={answer.selected} />
              {(answer.followUpQuestion || []).map(q => (
                <Followup
                  key={q.description}
                  description={q.description}
                  question={q.question}
                  candidateFirstName={candidateName}
                  skipped={q.skipped}
                  input={q.input}
                  mediaURL={q.mediaURL}
                />
              ))}
            </ReferenceAnswer>
          </React.Fragment>
        )
      })}
    </ChipsStyle>
  )
}

export default Chips
