import { Column } from '../../../components/ContentGrid'
import { Menu } from './ManageUsers'
import { Skeleton } from '@common/components'
import { style } from '../../../core'

const TableCell = style()
  .box({
    width: '100%',
    minHeight: '100%',
  })
  .flex({
    alignItems: 'center',
  })
  .element()

export const LoadingRow = () => (
  <>
    <Column>
      <TableCell></TableCell>
    </Column>
    <Column>
      <TableCell>
        <Skeleton width={32} height="unset" style={{ borderRadius: '50%', aspectRatio: '1/1' }} />
      </TableCell>
    </Column>
    <Column>
      <TableCell>
        <Skeleton width={200} height={20} />
      </TableCell>
    </Column>
    <Column>
      <TableCell>
        <Skeleton width={180} height={20} />
      </TableCell>
    </Column>
    <Column>
      <TableCell>
        <Skeleton width="100%" height={32} />
      </TableCell>
    </Column>
    <Column>
      <TableCell>
        <Menu>
          <Skeleton width={28} height={24} />
        </Menu>
      </TableCell>
    </Column>
  </>
)
