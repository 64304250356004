import GuestLayout from 'components/GuestLayout'
import { Heading, Subheading } from 'components/Typography'
import React from 'react'

const Component = () => {
  return (
    <GuestLayout>
      <Heading center>Maintenance mode</Heading>
      <br />
      <Subheading>
        HiPeople is currently down for maintenance mode. This will take no longer than an hour. Thank you for your
        patience. ☺️
      </Subheading>
    </GuestLayout>
  )
}

export default Component
Component.displayName = 'Maintenance'
