import React from 'react'
import { px2rem, style } from '../../../core'
import { designSystemColors } from '../../../core/design-system/colors'
import { text } from '../../../core/design-system/text'

const SectionStyle = style()
  .border({
    around: 'solid 1px',
    color: designSystemColors.borderDefault,
    radius: px2rem(8),
  })
  .element()

const Title = text.bodyInteractive().element('p')
const TitleContainer = style()
  .spacing({ inner: px2rem(24) })
  .element()

const Description = text.bodyText().element('p')
const DescriptionContainer = style()
  .border({
    top: 'solid 1px',
    bottom: 'solid 1px',
    color: designSystemColors.borderDefault,
  })
  .spacing({ inner: px2rem(24) })
  .flex({ direction: 'column' })
  .spacing({ gap: px2rem(10) })
  .bg({ color: designSystemColors.backgroundNeutralSecondary })
  .element()

const InputContainer = style()
  .spacing({ inner: px2rem(24) })
  .element()

const Section: React.FC<{ title: string; children?: React.ReactNode }> = props => {
  return (
    <SectionStyle>
      <TitleContainer>
        <Title>{props.title}</Title>
      </TitleContainer>
      {props.children}
    </SectionStyle>
  )
}

export function Timeline(props) {
  return (
    <Section title={'Timeline'}>
      <DescriptionContainer>
        <Description>
          HiPeople will survey the new hire and their manager once, according to a defined timeline (e.g. 90 days after
          they start). Please indicate here the number of days after the new hire’s start date that the Quality of the
          Hire should be evaluated.
        </Description>
        <Description>Note that future timeline modifications will apply only to the next new hires added.</Description>
        <Description>
          <a
            href="https://hipeople.notion.site/Quality-of-Hire-Analytics-Survey-questions-80ccddad329d4e998d428626abd374da"
            target="_blank"
            rel="noopener noreferrer"
          >
            Click here to check the surveys
          </a>
        </Description>
      </DescriptionContainer>
      <InputContainer>{props.children}</InputContainer>
    </Section>
  )
}

export function Activation(props) {
  return (
    <Section title={'Activation'}>
      <DescriptionContainer>
        <Description>
          By enabling Quality of Hire, you´ll collect feedback from your hiring Manager and your New Hire to evaluate
          how well they perform within the company.
        </Description>
      </DescriptionContainer>
      <InputContainer>{props.children}</InputContainer>
    </Section>
  )
}
