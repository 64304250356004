import * as api from '../api'
import { Fields } from '../api/employees'
import { RootState } from '../store'
import * as candidates from './candidates'
import lookup from './lookup'

export function findById(state: RootState, id: string): api.request.Entity<api.employees.Fields> | undefined {
  return lookup<api.employees.Fields>(state, api.employees.RESOURCE, id)
}

export function findByCandidateId(
  state: RootState,
  candidateId: string,
): api.request.Entity<api.employees.Fields> | undefined {
  const candidate = candidates.findById(state, candidateId)
  if (!candidate) return

  return findById(state, candidate.fields.employee_id)
}

export function findEmployees(state: RootState): Fields[] {
  const employeeList: Fields[] = []
  for (const id of state.employees.employeeIds) {
    const employee = findById(state, id)
    if (!employee) continue

    employeeList.push(employee.fields)
  }

  return employeeList
}

export function findMissingById(state: RootState, id: string): string[] {
  const employee = findById(state, id)
  if (!employee) return []

  const missing: string[] = []

  if (!employee.fields.manager_id) missing.push('Manager')
  if (!employee.fields.work_email) missing.push('Work email')
  if (!employee.fields.start_at) missing.push('Start date')

  return missing
}
