import React from 'react'
import { colors, height, rem, size, space, style } from '../../core'
import { Icon } from '../Icons'

interface Props {
  children: React.ReactNode
  onClick: () => void
  icon?: string
}

const Container = style()
  .grid({ columns: [rem(1.5), size.auto], align: 'center' })
  .border({ around: '1px solid' })
  .color({
    bg: colors.nearWhite,
    fg: colors.gray,
    border: colors.lightMoonGray,
  })
  .round('5px')
  .spacing({ inner: space.xs })
  .select(':hover', style().color({ fg: colors.black, border: colors.moonGray }))
  .pointer()
  .sans({ height: height.m })
  .element()

const AddIcon = style().element()

const Label = style().sans().center({ vertical: true }).element()

const Component = (props: Props) => {
  return (
    <Container onClick={props.onClick}>
      <AddIcon>
        <Icon name={props.icon || 'plus'} />
      </AddIcon>
      <Label>{props.children}</Label>
    </Container>
  )
}

export default Component
Component.displayName = 'CreateButton'
