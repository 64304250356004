import { Entity } from './request'

export const RESOURCE = 'response_options'

export interface Fields {
  id: string
  slug: string
  question_id: string
  copy: Record<string, string>
  value: number
  created_at: number
  updated_at: number
  is_active: boolean
}

export type ResponseOptionEntity = Entity<Fields>
