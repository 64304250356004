import { del, get, post, put } from './methods'
import { APIResponse, Entity } from './request'
import { Fields as SessionsFields } from './sessions'

export const RESOURCE = 'users'

export interface Fields {
  email: string
  id: string
  is_org_admin: boolean
  is_super_user: boolean
  is_active: boolean
  organization_id: string
  created_at: number
  verified_at: number
}

export function empty(id: string): Entity<Fields> {
  return {
    id,
    resource: RESOURCE,
    fields: {
      id,
      email: '',
      is_org_admin: false,
      is_super_user: false,
      is_active: true,
      organization_id: '',
      created_at: 0,
      verified_at: 0,
    },
  }
}

export function updateEmail(id: string, email: string): Promise<APIResponse<Entity<Fields>>> {
  return post<Entity<Fields>>(`users/${id}/email`, {
    email,
  })
}

export function updatePassword(
  id: string,
  oldPassword: string,
  newPassword: string,
): Promise<APIResponse<Entity<Fields>>> {
  return put<Entity<Fields>>(`users/${id}/password`, {
    old_password: oldPassword,
    new_password: newPassword,
  })
}

export function sendNewPassword(id: string): Promise<APIResponse<Entity<Fields>>> {
  return post<Entity<Fields>>(`users/${id}/send_new_password`, {})
}

export function resendEmailVerification(id: string): Promise<APIResponse<Entity<{}>>> {
  return post<Entity<{}>>(`users/${id}/resend_email_verification`, {})
}

export function deleteUser(id: string): Promise<APIResponse<Entity<Fields>>> {
  return del<Entity<Fields>>(`users/${id}`, {})
}

export function setIsOrgAdmin(id: string, isOrgAdmin: boolean): Promise<APIResponse<Entity<Fields>>> {
  return post<Entity<Fields>>(`users/${id}/is_org_admin`, {
    is_org_admin: isOrgAdmin,
  })
}

export function setIsSuperUser(id: string, isSuperUser: boolean): Promise<APIResponse<Entity<Fields>>> {
  return post<Entity<Fields>>(`users/${id}/is_super_user`, {
    is_super_user: isSuperUser,
  })
}

export async function loadById(id: string): Promise<APIResponse<Entity<Fields>>> {
  return get<Entity<Fields>>(`users/${id}`)
}

export async function loadActiveByOrgId(orgid: string): Promise<APIResponse<Entity<Fields>[]>> {
  return get<Entity<Fields>[]>(
    `organizations/${orgid}/users?include=profiles,teams&limit=500&is_active[true]&sort=profiles.full_name`,
  )
}

export function requestPasswordReset(email: string): Promise<APIResponse<Entity<{}>>> {
  return post<Entity<{}>>(`users/reset_password/request`, {
    email: email,
  })
}

export function resetPassword(newPassword: string): Promise<APIResponse<Entity<SessionsFields>>> {
  return post<Entity<SessionsFields>>(`users/reset_password`, {
    new_password: newPassword,
  })
}
