import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import * as React from 'react'
import { useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { Modifier, usePopper } from 'react-popper'
import { useClickAway, useKeyPressEvent } from 'react-use'
import { colors, px2rem, rem, scale, size, space, style, weight } from '../../core'
import { zIndex } from '@common/styles/zIndex'
import { Icon } from '../Icons'
import Option, { Props as OptionProps } from './Option'

export { default as Option } from './Option'

const OPTION_REM_SIZE = 2.55

const Container = style()
  .cond(({ label }) => !!label, style().spacing({ innerTop: rem(1.65) }))
  .relative()
  .cond(
    ({ open }: { open: boolean }) => open,
    style().color({ bg: designSystemColors.backgroundNeutralSecondaryHover }),
  )
  .cond(({ minimal }: { minimal: boolean }) => minimal, style().spacing({ innerTop: '0' }).noborders().shadow(0))
  .element('article')

const Input = style()
  .grid({ columns: [size.auto, size.m], align: 'center' })
  .spacing({ inner: space.xs, gap: px2rem(4) })
  .border({ around: '1px solid', color: colors.lightMoonGray })
  .color({ bg: colors.white })
  .round('5px')
  .select('.fas', style().color({ fg: colors.darkSilver }))
  .cond(({ open }: { open: boolean }) => open, style().select('.fas', style().color({ fg: colors.midGray })))
  .cond(
    ({ emphasis }: { emphasis: boolean }) => emphasis,
    style().color({ bg: designSystemColors.backgroundNeutralTertiary }),
  )
  .select(':hover .fas', style().color({ fg: colors.midGray }))
  .cond(
    ({ minimal }: { minimal: boolean }) => minimal,
    style()
      .noborders()
      .color({ bg: colors.lightMoonGray })
      .spacing({ inner: [space.xxxs, space.xs] })
      .select(':hover', style().color({ bg: colors.white })),
  )
  .cond(
    ({ minimal, open }: { minimal: boolean; open: boolean }) => minimal && open,
    style().color({ bg: colors.white }).shadow(0),
  )
  .cond(
    ({ disabled }: { disabled: boolean }) => disabled,
    style()
      .border({ color: colors.lightestGray })
      .select(':hover', style().border({ color: colors.lightestGray }))
      .select('.fas', style().opacity(0.1)),
  )
  .cond(({ small }: { small: boolean }) => small, style().size({ height: '2rem' }).spacing({ inner: '0.5rem' }))
  .pointer()
  .element()

const Label = style()
  .fg(colors.darkGray)
  .absolute({ top: 0, left: 0 })
  .sans({ size: scale.s, weight: weight.normal })
  .easein(0.1)
  .element('label')

const Options = style()
  // hide dropdown when Input scrolls out of view
  .select('[data-popper-reference-hidden=true]', style().hidden())
  .select('[data-popper-escaped=true]', style().hidden())
  .element()

const OptionsWithoutStyling = style().element()

const List = style()
  .absolute({ top: space.xxxs })
  .border({
    around: 'solid 1px',
    color: designSystemColors.backgroundNeutralSecondaryHover,
  })
  .set('boxShadow', '0px 0px 20px rgba(0, 0, 0, 0.15)')
  .round(px2rem(6))
  .size({ width: size.fill, maxHeight: size.xxxxl })
  .scroll({ y: 'auto' })
  .with(({ limit }: { limit: number | undefined }) =>
    style().size({
      maxHeight: limit ? rem(limit * OPTION_REM_SIZE) : size.xxxxl,
    }),
  )
  .element()

const Selected = text
  .secondaryBodyInteractive()
  .sans({
    ellipsis: true,
  })
  .cond(({ disabled }: { disabled: boolean }) => disabled, style().opacity(0.6))
  .element()

const Placeholder = style()
  .sans({ size: scale.s, color: colors.midGray, height: scale.m })
  .color({ fg: colors.gray })
  .element()

const TextInput = style()
  .size({ width: size.fill, height: size.fill })
  .sans({ size: scale.s, color: colors.midGray, height: scale.m })
  .noborders()
  .nooutline()
  .element('input')

interface Props<T> {
  value: T
  label?: React.ReactNode
  placeholder?: string
  children?: (React.ReactElement<OptionProps<T>> | null)[]
  onChange: (value: T) => void
  limit?: number
  allowInput?: boolean
  textInput?: string
  onInput?: (input: string) => void
  minimal?: boolean
  disabled?: boolean
  missingSuggestion?: string
  onClickMissingSuggestion?: () => void
  disableRelativePositionTracking?: boolean
  small?: boolean
  testId?: string
}

export default function DropdownField<T>(props: Props<T>) {
  const outerDropdownRef = useRef(null)
  const [inputRef, setInputRef] = useState<HTMLElement | null>(null)
  const [dropdownRef, setDropdownRef] = useState<HTMLElement | null>(null)

  const modifiers = useMemo<Modifier<string, object>[]>(
    () => [
      {
        name: 'sameWidth',
        enabled: true,
        phase: 'beforeWrite',
        requires: ['computeStyles'],
        fn({ state }) {
          state.styles.popper.minWidth = `${state.rects.reference.width}px`
        },
        effect({ state }) {
          state.elements.popper.style.minWidth = `${(state.elements.reference as HTMLDivElement).offsetWidth}px`
        },
      },
    ],
    [],
  )

  const { styles, attributes } = usePopper(inputRef, dropdownRef, {
    placement: 'bottom-start',
    strategy: 'fixed',
    modifiers,
  })

  const [open, setOpen] = useState(false)

  const options = listOfOptions<T>(props)
  const selected = options.find(o => {
    return o && o.props.value === props.value
  })

  const showTextInput = (open && props.allowInput) || false
  const showSelected = !showTextInput && !!selected
  const showPlaceholder = !showTextInput && !showSelected

  useClickAway(outerDropdownRef, ev => {
    if (ev.target instanceof Node && !inputRef?.contains(ev.target)) {
      setOpen(false)
    }
  })

  useKeyPressEvent('Escape', () => {
    if (open) {
      setOpen(false)
    }
  })
  useKeyPressEvent('Enter', () => {
    if (open) {
      setOpen(false)
    }
  })

  const OptionsWrapper = props.disableRelativePositionTracking ? OptionsWithoutStyling : Options

  return (
    <>
      <div ref={setInputRef} data-testid={props.testId}>
        <Container label={props.label} minimal={!!props.minimal} disabled={!!props.disabled}>
          <Input
            small={!!props.small}
            onClick={() => {
              if (open) {
                setOpen(false)
                return
              }

              setOpen(!props.disabled)
            }}
            open={open}
            minimal={!!props.minimal}
            disabled={!!props.disabled}
            emphasis={selected?.props.emphasisOnSelect}
          >
            {showTextInput ? (
              <TextInput
                autoFocus
                value={props.textInput}
                onChange={e => props.onInput && props.onInput(e.target.value)}
              />
            ) : null}
            {showSelected && selected ? (
              <Selected disabled={!!props.disabled}>{selected.props.children}</Selected>
            ) : null}
            {showPlaceholder ? <Placeholder>{props.placeholder || 'Click to select'}</Placeholder> : null}
            <Icon name="chevron-down" />
          </Input>
          {props.label ? <Label {...props}>{props.label}</Label> : null}
        </Container>
      </div>
      {open
        ? createPortal(
            <OptionsWrapper>
              <div ref={outerDropdownRef}>
                <div
                  ref={setDropdownRef}
                  style={{ ...styles.popper, zIndex: zIndex.dropdown + 10 }}
                  {...attributes.popper}
                  data-testid="dropdown-options"
                >
                  <List limit={props.limit}>
                    {options.map(c => {
                      return React.cloneElement(c, {
                        selected: c.props.value === selected?.props.value,
                        onSelect: (value: T) => {
                          props.onChange(value)
                          setOpen(false)
                        },
                      })
                    })}
                    {props.missingSuggestion ? (
                      <Option<string>
                        value={props.missingSuggestion}
                        onSelect={() => props.onClickMissingSuggestion?.()}
                        icon={'search'}
                      >
                        {props.missingSuggestion}
                      </Option>
                    ) : null}
                  </List>
                </div>
              </div>
            </OptionsWrapper>,
            document.getElementById('root') || document.body,
          )
        : null}
    </>
  )
}

function listOfOptions<T>(props: Props<T>): React.ReactElement<OptionProps<T>>[] {
  const result: React.ReactElement<OptionProps<T>>[] = []

  if (!props.children) return result

  for (const el of props.children) {
    if (!el) continue
    result.push(el)
  }

  return result
}
