import { Row, Table } from 'common/src/components/Table/Table'
import { text } from 'core/design-system/text'
import React from 'react'

import { Dot } from '@common/components/Dot/Dot'
import { AssessmentStatus, HiringStatus, OnboardingStatus, ReferenceCheckStatus } from 'api/candidates'
import { StatusContainer, StatusText } from 'App/OpenJobRole/StatusTemplate'
import DropdownField, { Option } from 'components/DropdownField'
import { colors, size, style } from 'core'
import * as tracking from 'core/track'
import { useSelector } from 'react-redux'
import * as selectors from 'selectors'
import { colorForStatus } from 'selectors/candidates'
import { RootState } from 'store'
import { allHiringStatuses, statusToText } from 'utils/status'
import { Link } from '@common/components'

const NameDateContainer = style()
  .size({ height: size.fill })
  .flex({ alignItems: 'center' })
  .sans({ nodecoration: true })
  .elementFromComponent(Link)

const NameDate = text.bodyEmphasis().color({ fg: colors.black }).element()

const Name = style().element()

export type Manager = {
  id: string
  name: string
  email: string
  profileImg: string
}

export type Candidate = {
  id: string
  fullName: string
  updatedAt: Date
  referenceCheckStatus: ReferenceCheckStatus | null
  onboardingStatus: OnboardingStatus | null
  assessmentStatus: AssessmentStatus | null
  hiringStatus: HiringStatus
  missingInformation: boolean
  roleId: string
}

export type UpdateHiringFunc = (
  id: string,
  isHired: boolean,
  isRejected: boolean,
  startYear?: number,
  startMonth?: number,
  startDay?: number,
  managerId?: string,
  workEmail?: string,
  shouldOnboard?: boolean,
) => any

interface Props {
  loadMoreCandidates: () => any
  candidates: Candidate[]
  hasMoreCandidates: boolean
  onSort: (key: string, direction: string) => void
  updateHiring: UpdateHiringFunc
}

export const CandidatesTable: React.FC<Props> = props => {
  const headers = [
    { key: 'candidate_profiles.full_name', name: 'Name', sortable: true },
    { key: 'assessment_status', name: 'Assessment Status' },
    { key: 'reference_check_status', name: 'Reference Check Status' },
    { key: 'updated_at', name: 'Last activity', sortable: true },
    { key: 'stage', name: 'Stage' },
  ]

  return (
    <Table
      onSort={props.onSort}
      style={{ height: '90%' }}
      headers={headers}
      onReachBottom={() => {
        if (props.hasMoreCandidates) {
          props.loadMoreCandidates()
        }
      }}
      testId="candidates-table"
    >
      {props.candidates.map(candidate => (
        <CandidateRow updateHiring={props.updateHiring} key={`candidate-row-${candidate.id}`} candidate={candidate} />
      ))}
    </Table>
  )
}

interface RowProps {
  candidate: Candidate
  updateHiring: UpdateHiringFunc
}

const CandidateRow: React.FC<RowProps> = props => {
  const role = useSelector((state: RootState) => selectors.roles.findByCandidateId(state, props.candidate?.id || ''))

  const trackingProps = useSelector((state: RootState) =>
    tracking.candidates.extractPropsFromState(state, props.candidate?.id),
  )

  const assessmentStatus = props.candidate.assessmentStatus ? (
    <StatusContainer key={`assessment-status-${props.candidate.id}`}>
      <Dot color={colorForStatus(props.candidate.assessmentStatus)} />
      <StatusText>{statusToText(props.candidate.assessmentStatus)}</StatusText>
    </StatusContainer>
  ) : (
    '-'
  )

  const referenceCheckStatus = props.candidate.referenceCheckStatus ? (
    <StatusContainer key={`assessment-status-${props.candidate.id}`}>
      <Dot color={colorForStatus(props.candidate.referenceCheckStatus)} />
      <StatusText>{statusToText(props.candidate.referenceCheckStatus)}</StatusText>
    </StatusContainer>
  ) : (
    '-'
  )

  return (
    <Row
      id={'row-id-' + props.candidate.id + props.candidate.fullName}
      cells={[
        <NameDateContainer
          key={`candidate-name-${props.candidate.id}`}
          to={
            !role?.fields.ask_reference_check || props.candidate.assessmentStatus === 'completed'
              ? `/roles/${props.candidate.roleId}/candidates/${props.candidate.id}/assessment`
              : `/roles/${props.candidate.roleId}/candidates/${props.candidate.id}`
          }
          onClick={() => {
            if (trackingProps) {
              tracking.candidates.viewed(trackingProps)
            }
          }}
          className="data-hj-suppress"
        >
          <NameDate>
            <Name>{props.candidate.fullName}</Name>
          </NameDate>
        </NameDateContainer>,
        assessmentStatus,
        referenceCheckStatus,
        props.candidate.updatedAt.toLocaleDateString(),
        <DropdownField<HiringStatus>
          key={'123'}
          value={props.candidate.hiringStatus}
          onChange={status => {
            props.updateHiring(props.candidate.id, status === 'hired', status === 'rejected')
            tracking.candidates.changeHireStatus()
          }}
        >
          {allHiringStatuses.map(s => (
            <Option key={`hiring-status-${s}`} value={s} emphasisOnSelect={s === 'hired'}>
              {statusToText(s)}
            </Option>
          ))}
        </DropdownField>,
      ]}
    />
  )
}
