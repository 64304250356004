import * as api from '../api'
import { RootState } from '../store'
import {
  CURRENT_EXPERIENCE_QUESTION,
  OLD_OVERALL_PERFORMANCE_QUESTION,
  OVERALL_PERFORMANCE_QUESTION,
  PROFICIENCY_QUESTION,
} from '../store/new-role/content'
import { SKILL_LEVEL } from '../store/new-role/formats'
import * as candidates from './candidates'
import * as forms from './forms'
import lookup from './lookup'
import * as referenceCheckStages from './reference-check-stages'
import * as references from './references'
import * as roles from './roles'

function findById(state: RootState, id: string): api.request.Entity<api.assessmentversions.Fields> | undefined {
  return lookup(state, api.assessmentversions.RESOURCE, id)
}

function findByFormId(state: RootState, id: string): api.request.Entity<api.assessmentversions.Fields> | undefined {
  const form = forms.findById(state, id)
  if (!form) return

  return findById(state, form.fields.assessment_version_id)
}

export function findReferenceVersionByCandidateId(
  state: RootState,
  candidateId: string,
): api.request.Entity<api.assessmentversions.Fields> | undefined {
  const stage = referenceCheckStages.getByCandidateId(state, candidateId)
  if (stage?.fields.reference_form_id) {
    return findByFormId(state, stage.fields.reference_form_id)
  }

  const candidate = candidates.findById(state, candidateId)
  if (!candidate) return

  const role = roles.findById(state, candidate.fields.open_job_role_id)
  if (!role) return

  return findByFormId(state, role.fields.reference_form_id)
}
function findReferenceQuestion(
  state: RootState,
  candidateId: string,
  contentId: string,
): api.assessmentversions.Item | undefined {
  const version = findReferenceVersionByCandidateId(state, candidateId)
  if (!version) return

  for (const scale of version.fields.scales) {
    for (const item of scale.items) {
      if (item.content_id === contentId) {
        return item
      }
    }
  }
}

export function findDashboardView(
  state: RootState,
  candidateId: string,
  contentId: string,
): api.assessmentversions.DashboardView | undefined {
  const item = findReferenceQuestion(state, candidateId, contentId)
  return item?.dashboard_view
}
export function findReferenceOptionById(
  state: RootState,
  referenceId: string,
  optionId: string,
): api.assessmentversions.Option | undefined {
  const reference = references.findById(state, referenceId)
  if (!reference) return

  const version = findReferenceVersionByCandidateId(state, reference.fields.candidate_id)
  if (!version) return

  return findReferenceOptionByVersion(version, optionId)
}

export function findReferenceOptionByVersion(
  version: api.request.Entity<api.assessmentversions.Fields>,
  optionId: string,
): api.assessmentversions.Option | undefined {
  for (const item of version.fields.scales[0].items) {
    for (const option of item.response_options.options) {
      if (option.content_id === optionId) {
        return option
      }
    }
  }

  // SEE https://www.notion.so/hipeople/2020-12-12-Work-Style-Questions-Missing-Answers-3b5eee1fc964485b87a040749354c7af
  return patchBrokenWorkStyleOptionLookup(optionId)
}

// SEE https://www.notion.so/hipeople/2020-12-12-Work-Style-Questions-Missing-Answers-3b5eee1fc964485b87a040749354c7af
export function findFollowupQuestionsByReferenceId(
  state: RootState,
  referenceId: string,
  contentId: string,
): api.assessmentversions.Item[] | undefined {
  const reference = references.findById(state, referenceId)
  if (!reference) return

  const version = findReferenceVersionByCandidateId(state, reference.fields.candidate_id)
  if (!version) return

  return version.fields.scales[0].items.filter(
    item => item.dashboard_view && item.dashboard_view.parent_question_id === contentId,
  )
}

function getItemsByCandidateId(state: RootState, candidateId: string): api.assessmentversions.Item[] {
  return findReferenceVersionByCandidateId(state, candidateId)?.fields.scales[0].items || []
}

export function getGroupedItemsByCandidateId(state: RootState, candidateId: string): api.assessmentversions.Item[] {
  const seenGroups: string[] = []
  const groupedItems: api.assessmentversions.Item[] = []
  const items = findReferenceVersionByCandidateId(state, candidateId)?.fields?.scales[0]?.items
  if (!items) return []

  for (const item of items) {
    if (!item?.dashboard_view?.group) {
      groupedItems.push(item)
      continue
    }

    if (seenGroups.includes(item.dashboard_view.group)) {
      continue
    }

    seenGroups.push(item.dashboard_view.group)
    groupedItems.push(item)
  }

  return groupedItems
}

export function getItemsByGroupId(
  state: RootState,
  candidateId: string,
  groupId: string,
): api.assessmentversions.Item[] {
  const items = findReferenceVersionByCandidateId(state, candidateId)?.fields?.scales[0]?.items
  if (!items) return []

  return items.filter(item => item.dashboard_view?.group === groupId)
}

export function findOverallPerformanceQuestionsByCandidateId(state: RootState, candidateId: string): string[] {
  const referenceItems = getItemsByCandidateId(state, candidateId)
  const overallPerformanceQuestions: string[] = []

  for (const item of referenceItems) {
    if (item.content_id === OVERALL_PERFORMANCE_QUESTION.test_item_id) {
      overallPerformanceQuestions.push(item.content_id)
      continue
    }

    if (item.content_id === OLD_OVERALL_PERFORMANCE_QUESTION.test_item_id) {
      overallPerformanceQuestions.push(item.content_id)
      continue
    }
  }

  return overallPerformanceQuestions
}

export function findSkillsetQuestionsByCandidateId(state: RootState, candidateId: string): string[] {
  const referenceItems = getItemsByCandidateId(state, candidateId)
  const skillsetQuestions: string[] = []

  for (const item of referenceItems) {
    const responseOptions = item.response_options

    if (responseOptions.content_id === SKILL_LEVEL.contentId) {
      skillsetQuestions.push(item.content_id)
      continue
    }
    if (responseOptions.content_id === CURRENT_EXPERIENCE_QUESTION.question.response_options[0]) {
      skillsetQuestions.push(item.content_id)
      continue
    }
    if (responseOptions.options[0]?.content_id === PROFICIENCY_QUESTION.question.response_options[0]) {
      skillsetQuestions.push(item.content_id)
      continue
    }
  }

  return skillsetQuestions
}

export function findAskedQuestionsByCandidateId(
  state: RootState,
  candidateId: string,
  ...contentIds: string[]
): string[] {
  const asked: string[] = []

  for (const content of contentIds) {
    if (findReferenceQuestion(state, candidateId, content)) {
      asked.push(content)
    }
  }

  return asked
}

function patchBrokenWorkStyleOptionLookup(optionId: string): api.assessmentversions.Option | undefined {
  const lookup: { [id: string]: api.assessmentversions.Option } = {
    '10DTcG1FiNoHOkODLhgGxz': {
      content_id: '10DTcG1FiNoHOkODLhgGxz',
      type: 'ui_chip',
      caption: 'Focused',
      description: '',
      value: 0,
    },
    '3o7h7Qc0DBeZzKYEJY45JZ': {
      content_id: '3o7h7Qc0DBeZzKYEJY45JZ',
      type: 'ui_chip',
      caption: 'Proactive',
      description: '',
      value: 0,
    },
    '2f9LcjmJ3ceufzagTVWvIE': {
      content_id: '2f9LcjmJ3ceufzagTVWvIE',
      type: 'ui_chip',
      caption: 'Leading',
      description: '',
      value: 0,
    },
    '2x0K9NVFr9WzBDM4NOnauU': {
      content_id: '2x0K9NVFr9WzBDM4NOnauU',
      type: 'ui_chip',
      caption: 'Pleasant',
      description: '',
      value: 0,
    },
    '6DvWOxG8TcLbTny5WO1Zmd': {
      content_id: '6DvWOxG8TcLbTny5WO1Zmd',
      type: 'ui_chip',
      caption: 'Connected',
      description: '',
      value: 0,
    },
    '4Wjx77tk4scPFr1mIoXvrn': {
      content_id: '4Wjx77tk4scPFr1mIoXvrn',
      type: 'ui_chip',
      caption: 'Calm',
      description: '',
      value: 0,
    },
    '39g6jDBpZtKyNwySLdhoOI': {
      content_id: '39g6jDBpZtKyNwySLdhoOI',
      type: 'ui_chip',
      caption: 'Resilient',
      description: '',
      value: 0,
    },
    '66Fr66S8NN8c99Kk0d18gh': {
      content_id: '66Fr66S8NN8c99Kk0d18gh',
      type: 'ui_chip',
      caption: 'Flexible',
      description: '',
      value: 0,
    },
    '3a5SuX1D6UQhHiNiMGuhnn': {
      content_id: '3a5SuX1D6UQhHiNiMGuhnn',
      type: 'ui_chip',
      caption: 'Reliable',
      description: '',
      value: 0,
    },
    pJ8iTrWRjnxJDKvpNI9R4: {
      content_id: 'pJ8iTrWRjnxJDKvpNI9R4',
      type: 'ui_chip',
      caption: 'Detailed',
      description: '',
      value: 0,
    },
    '1e6WNdiIkGZBUiaKAlOYQM': {
      content_id: '1e6WNdiIkGZBUiaKAlOYQM',
      type: 'ui_chip',
      caption: 'Sincere',
      description: '',
      value: 0,
    },
    '5H2vNKPzMlTNgnVPfUW11p': {
      content_id: '5H2vNKPzMlTNgnVPfUW11p',
      type: 'ui_chip',
      caption: 'Independent',
      description: '',
      value: 0,
    },
    '1AUsHpb1HOcnEMh6HD9ZXQ': {
      content_id: '1AUsHpb1HOcnEMh6HD9ZXQ',
      type: 'ui_chip',
      caption: 'Innovative',
      description: '',
      value: 0,
    },
    '38PpvURsyDanRRtaUZDpxs': {
      content_id: '38PpvURsyDanRRtaUZDpxs',
      type: 'ui_chip',
      caption: 'Analytical',
      description: '',
      value: 0,
    },
    '3y4LcCFLDHldZaHM8X5Hdu': {
      content_id: '3y4LcCFLDHldZaHM8X5Hdu',
      type: 'ui_chip',
      caption: 'Dependable',
      description: '',
      value: 0,
    },
    '2MS1xOJDbeonYbuROLalzd': {
      content_id: '2MS1xOJDbeonYbuROLalzd',
      type: 'ui_chip',
      caption: 'Empathetic',
      description: '',
      value: 0,
    },
    '4vIuEgg9VhndjnqCi8bdlw': {
      content_id: '4vIuEgg9VhndjnqCi8bdlw',
      type: 'ui_chip',
      caption: 'Balanced',
      description: '',
      value: 0,
    },
    '6vxqTqfkS6qFJLOeDQtW0t': {
      content_id: '6vxqTqfkS6qFJLOeDQtW0t',
      type: 'ui_chip',
      caption: 'Respectful',
      description: '',
      value: 0,
    },
    o2vccn49knKUMS7SVWezt: {
      content_id: 'o2vccn49knKUMS7SVWezt',
      type: 'ui_chip',
      caption: 'Positive',
      description: '',
      value: 0,
    },
    '4jkz1Kd2aQePu9jqumOp4G': {
      content_id: '4jkz1Kd2aQePu9jqumOp4G',
      type: 'ui_chip',
      caption: 'Leading',
      description: '',
      value: 0,
    },
    '76V8I0msheeUKgiu2GDM7E': {
      content_id: '76V8I0msheeUKgiu2GDM7E',
      type: 'ui_chip',
      caption: 'Creative',
      description: '',
      value: 0,
    },
    phW0rtPlLr9iHeZEpa3Lm: {
      content_id: 'phW0rtPlLr9iHeZEpa3Lm',
      type: 'ui_chip',
      caption: 'Detailed',
      description: '',
      value: 0,
    },
    '5AnOY1gGIpbT1RMMqJiDiv': {
      content_id: '5AnOY1gGIpbT1RMMqJiDiv',
      type: 'ui_chip',
      caption: 'Calm',
      description: '',
      value: 0,
    },
    '1FXmqsIWBEMMjzf3YAsbBT': {
      content_id: '1FXmqsIWBEMMjzf3YAsbBT',
      type: 'ui_chip',
      caption: 'Clever',
      description: '',
      value: 0,
    },
  }

  return lookup[optionId]
}
