import React from 'react'
import { SubmissionType } from 'selectors/candidates'
import { Breadcrumb } from '../../components/DashboardLayout/Breadcrumbs'
import Shell from './SharedShell'

interface Props {
  children: React.ReactNode
  candidateId: string
  roleId: string
  breadcrumbs: Breadcrumb[]
  hideNameHeader?: boolean
  submissionType?: SubmissionType
}

const Component = (props: Props) => {
  return (
    <Shell
      candidateId={props.candidateId}
      roleId={props.roleId}
      breadcrumbs={props.breadcrumbs}
      hideNameHeader={props.hideNameHeader}
      submissionType={props.submissionType}
    >
      <div style={{ width: '100%' }}>{props.children}</div>
    </Shell>
  )
}

export default Component
Component.displayName = 'ProfileShell'
