import React from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import { colors, scale, space, style, weight } from '../../core'
import { Breadcrumb } from '../DashboardLayout/Breadcrumbs'
import { Icon } from '../Icons'

interface Props {
  content: Breadcrumb[]
}

const Container = style()
  .spacing({ inner: [space.s, space.s] })
  .border({ bottom: '1px solid', color: colors.lightestGray })
  .element()

const Column = style()
  .sans({ size: scale.s, weight: weight.normal })
  .spacing({ inner: [space.none, space.xs, space.none, space.none] })
  .inlineBlock()

const Child = Column.clone()
  .select('a', style().sans({ nodecoration: true, color: colors.midGray }))
  .element()

const Separator = Column.clone().color({ fg: colors.moonGray }).element()

const Component = (props: Props) => {
  return (
    <Container size={props.content.length}>
      {props.content.map((b, ind) => (
        <React.Fragment key={ind}>
          <Child url={b.url}>{b.url ? <Link to={b.url}>{b.label}</Link> : b.label}</Child>
          {ind < props.content.length - 1 ? (
            <Separator>
              <Icon name="caret-right" />
            </Separator>
          ) : null}
        </React.Fragment>
      ))}
    </Container>
  )
}

export default Component
Component.displayName = 'Breadcrumbs'
