import { Button, DragAndDrop, LockedByPlan, Modal, Text } from '@common/components'
import {
  LibraryItemConfig,
  TemplateContent,
  TemplateContentItem,
  PresetCustomQuestionResponseType,
} from 'api/templates'
import * as newRoleSlice from 'store/new-role'
import { getCustomQuestionThemeCopy } from '../../RoleCreation/PreviewTable'
import ContentSelectionModal from '../../RoleCreation/TemplateCreationAssessmentV2/ContentSelection/Modal'
import { AddCustomMultiSelectQuestionModal } from '../../RoleCreation/TemplateCreationSelfAssessment/AddCustomMultiSelectQuestionModal'
import { AddCustomTextQuestionModal } from '../../RoleCreation/TemplateCreationSelfAssessment/AddCustomTextQuestionModal'
import { AddCustomVideoQuestionModal } from '../../RoleCreation/TemplateCreationSelfAssessment/AddCustomVideoQuestionModal'

import { Icon } from 'components/Icons'
import { px2rem, size, space, style, vh } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import { lockedContentTracking } from 'core/track/locked-content'
import { formatDurationSeconds, isPresent } from 'core/utils'
import {
  CustomQuestionData,
  LibraryItemData,
  LibrarySelection,
  useLibrary,
  useLibraryFilter,
  useLibrarySelection,
} from '../../RoleCreation/TemplateCreationSelfAssessment/selection'

import * as tracking from 'core/track'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as selectors from 'selectors'
import { RootState } from 'store'

import { Header as PreviewHeader } from '../../RoleCreation/TemplateCreationAssessmentV2/Preview/Header'
import { Row as PreviewRow } from '../../RoleCreation/TemplateCreationAssessmentV2/Preview/Row'

import { AssessmentLocale } from '@common/models/locales'
import * as Org from 'api/orgs'
import { Entity } from 'api/request'
import TextField, { Label } from 'components/TextField'

import { TemplateEditorHeader } from '../TemplateEditorHeader'
import { useHistory } from 'react-router-dom'
import { HandleCreateTemplate } from './NewAssessmentPage'
import isEqual from 'lodash-es/isEqual'

interface NewAssessmentProps {
  org: Entity<Org.Fields>
  onConfirm: (params: HandleCreateTemplate) => Promise<void>
  isVideoQuestionEnabled: boolean
  templateId: string | null
  mode: 'new' | 'edit' | 'clone'
  role: {
    id: string
    locale: AssessmentLocale
    description: string
  } | null
}

export const NewAssessment: React.FC<NewAssessmentProps> = props => {
  const history = useHistory()
  const [isSelectionModalOpen, setIsSelectionModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const [limit, setLimit] = useState(100)
  const filter = useLibraryFilter()
  const { matchingLibraryItems, libraryItemFromId, groups } = useLibrary(filter, limit)

  const selectedPreset =
    useSelector((state: RootState) => (props.templateId ? selectors.presets.getById(state, props.templateId) : null)) ??
    null

  const selection = useSelectedPreset(props.org.id, props.templateId)

  const mappedGroups = useMemo(
    () =>
      groups.map(group => ({
        slug: group.fields.slug,
        theme: group.fields.copy.theme,
        name: group.fields.copy.title,
        icon: group.fields.copy.icon,
        library_item_count: group.fields.library_item_count,
      })),
    [groups],
  )

  const onScrollNext = useCallback(() => {
    if (matchingLibraryItems.length < limit) return // no more items to load
    setLimit(limit + 100)
  }, [limit, matchingLibraryItems.length])

  const [customQuestionModal, setCustomQuestionModal] = useState<{
    open: boolean
    type?: PresetCustomQuestionResponseType
    question?: CustomQuestionData
    index?: number
  }>({ open: false, type: 'video' })

  const [confirmModalOpen, setConfirmModalOpen] = useState(false)

  const [dragAndDropData, setDragAndDropData] = useState<(LibraryItemData | CustomQuestionWithId)[] | null>(null)

  const savedPreview = useSelector((state: RootState) =>
    props.templateId ? selectors.presets.findPresetPreviewById(state, props.templateId, props.org.id) : [],
  )

  const isOrgPaidPlan = useSelector(selectors.orgs.isOrgPaidPlan)

  const isNewAssessment = !props.templateId

  const submitCustomQuestion = (question: CustomQuestionData, index?: number) => {
    if (index !== undefined) {
      const newQuestions = [...selection.customQuestions]
      newQuestions[index] = question
      selection.setCustomQuestions(newQuestions)
    } else {
      selection.setCustomQuestions([...selection.customQuestions, question])
    }
  }

  const removeQuestion = (questionText: string) => {
    const newQuestions = [...selection.customQuestions]
    const index = newQuestions.findIndex(question => question.questionText === questionText)
    newQuestions.splice(index, 1)
    selection.setCustomQuestions(newQuestions)
  }

  // import redux data
  const unordered: (LibraryItemData | CustomQuestionWithId)[] = useMemo(() => {
    return [
      ...Array.from(selection.libraryItems.set)
        .map(id => libraryItemFromId(id))
        .filter(isPresent),
      ...selection.customQuestions.map((question, index) => ({
        ...question,
        id: index,
      })),
    ]
  }, [selection.libraryItems, selection.customQuestions])

  // sort the data based on the saved preview order
  const orderedByPreview = useMemo(() => {
    if (!savedPreview.length || !unordered.length) return []

    const savedPreviewIds = savedPreview?.map(item => item.module?.id ?? item.copy)

    const sortedArray: typeof unordered = []

    for (const savedPreviewId of savedPreviewIds) {
      const foundItem = unordered.find(item =>
        isCustomQuestion(item) ? savedPreviewId === item.questionText : savedPreviewId === item.id,
      )
      if (foundItem) sortedArray.push(foundItem)
    }

    return sortedArray
  }, [savedPreview, unordered])

  const isValid = (item: LibraryItemData) => {
    return (
      item && item.group_icon && item.group_title && item.group_theme && item.name && item.duration_seconds && item.id
    )
  }

  // set the drag and drop data to the preview data on mount
  useEffect(() => {
    if (dragAndDropData !== null) {
      if (dragAndDropData.length === 0 && orderedByPreview.length > 0) {
        for (const item of orderedByPreview) {
          if (!isCustomQuestion(item) && !isValid(item)) {
            return
          }
        }

        setDragAndDropData(orderedByPreview)
      }
      return
    }

    if (isNewAssessment) {
      setDragAndDropData([])
      return
    }

    setDragAndDropData(orderedByPreview)
  }, [isNewAssessment, orderedByPreview])

  // update the drag and drop data if the redux data changes
  useEffect(() => {
    if (dragAndDropData !== null && dragAndDropData.length > 0 && unordered?.length !== dragAndDropData?.length) {
      const addedItems = unordered.filter(newItem => !dragAndDropData.some(oldItem => isSame(oldItem, newItem)))
      const removedItems = dragAndDropData.filter(oldItem => !unordered.some(newItem => isSame(oldItem, newItem)))

      if (addedItems.length === 0 && removedItems.length === 0) return

      const newDragAndDropData = [...dragAndDropData]
      if (removedItems.length > 0) {
        removedItems.forEach(item => {
          const index = newDragAndDropData.findIndex(i => i === item)
          newDragAndDropData.splice(index, 1)
        })
      }
      if (addedItems.length > 0) {
        newDragAndDropData.push(...addedItems)
      }
      setDragAndDropData(newDragAndDropData)
    } else if (dragAndDropData !== null && dragAndDropData.length === unordered?.length) {
      // check if any of the items in unordered are not in dragAndDropData
      // unordered can partially change over time, when the data is fetched from the api and pieced together
      const newDragAndDropData = dragAndDropData.map(item => unordered.find(i => i.id === item.id) || item)

      // if the new array is different from the old one, update the state
      // we need this to avoid a rerender loop, because of the dragAndDrop side effect that is in the dependency array
      if (!isEqual(dragAndDropData, newDragAndDropData)) {
        setDragAndDropData(newDragAndDropData)
      }
    } else if (dragAndDropData !== null && dragAndDropData.length === 0 && unordered?.length > 0) {
      setDragAndDropData(unordered)
    }
  }, [unordered, dragAndDropData, isNewAssessment])

  const testsAdded = selection.libraryItems.set.size > 0 || selection.customQuestions?.length > 0

  const handleSubmitTemplate = async (templateTitle: string) => {
    if (!dragAndDropData) return

    setLoading(true)

    await props.onConfirm({
      mode: 'save-template',
      payload: generatePayload(dragAndDropData, selection),
      templateTitle,
    })

    setLoading(false)

    setConfirmModalOpen(false)

    history.push(`/templates?mode=template&product=self-assessment`)
  }

  const handleSubmitAssessment = async () => {
    if (!props.role || !dragAndDropData) return
    setLoading(true)

    if (props.mode === 'edit') {
      await props.onConfirm({
        payload: generatePayload(dragAndDropData, selection),
        mode: 'edit-existing-assessment',
        templateTitle: selectedPreset?.fields.copy.title || '',
      })
    } else {
      await props.onConfirm({
        payload: generatePayload(dragAndDropData, selection),
        mode: 'create-new-assessment',
      })
    }

    setLoading(false)

    history.push(`/roles/${props.role.id}`)
  }

  const handleClickConfirm = async () => {
    if (!dragAndDropData) return

    if (props.role && props.mode === 'edit') {
      // we are editing an existing template connected to a role
      // redirect to the role page when the user clicks confirm,
      // without showing the preview modal
      tracking.contentSelection.confirmClicked()
      setLoading(true)

      await props.onConfirm({
        payload: generatePayload(dragAndDropData, selection),
        mode: 'edit-template',
        templateTitle: selectedPreset?.fields.copy.title || '',
      })

      setLoading(false)
      history.push(`/roles/${props.role.id}`)
    } else if (props.mode === 'edit') {
      tracking.contentSelection.confirmClicked()
      setLoading(true)
      await props.onConfirm({
        payload: generatePayload(dragAndDropData, selection),
        mode: 'edit-template',
        templateTitle: selectedPreset?.fields.copy.title || '',
      })

      setLoading(false)
      history.push(`/templates?mode=template&product=self-assessment`)
    } else {
      tracking.contentSelection.confirmClicked()
      setConfirmModalOpen(true)
    }
  }

  const handleCloseConfirmModal = () => {
    setConfirmModalOpen(false)
  }

  const handleExit = () => {
    if (history.length > 1) {
      history.goBack()
    } else if (props.role) {
      history.push(`/templates?product=self-assessment&roleid=${props.role.id}&mode=single`)
    } else {
      history.push('/templates?mode=template')
    }
  }

  return (
    <>
      <AssessmentContentSelectionPageUIStyle>
        <TemplateEditorHeader showWarningModal onExit={handleExit} />
        <Content>
          <Title data-testid="create-assessment-page-title">
            {props.mode === 'edit' ? 'Edit ' : 'Create '}
            {props.role ? 'assessment ' : 'template'}
          </Title>
          <Description>{'Add tests from our test library and create custom questions.'}</Description>
          <MainContentSelectionArea>
            {testsAdded ? (
              <PreviewContainer>
                <PreviewHeader />
                {dragAndDropData && (
                  <DragAndDrop
                    data={dragAndDropData}
                    setData={setDragAndDropData}
                    dragHandleInside
                    render={(item, i, DragHandle) => {
                      if (isCustomQuestion(item)) {
                        const { theme, icon, ariaLabel } = getCustomQuestionThemeCopy(item.responseType)

                        return (
                          <PreviewRow
                            DragHandle={DragHandle}
                            key={i}
                            areaIcon={icon}
                            areaTitle={'Custom'}
                            areaTheme={theme}
                            areaAriaLabel={ariaLabel}
                            name={item.questionText}
                            locale=""
                            duration={''}
                            edit={() => {
                              tracking.contentSelection.editCustomQuestionButtonClicked()
                              const indexInSelection = selection.customQuestions.findIndex(
                                question => question.questionText === item.questionText,
                              )
                              setCustomQuestionModal({
                                open: true,
                                type: item.responseType,
                                question: item,
                                index: indexInSelection,
                              })
                            }}
                            remove={() => {
                              tracking.contentSelection.removeButtonClicked()
                              removeQuestion(item.questionText)
                            }}
                          />
                        )
                      } else {
                        if (!isValid(item)) {
                          return null
                        }
                        return (
                          <PreviewRow
                            DragHandle={DragHandle}
                            key={item.id}
                            remove={() => {
                              tracking.contentSelection.removeButtonClicked()
                              selection.libraryItems.remove(item.id)
                            }}
                            areaIcon={item.group_icon}
                            areaTitle={item.group_title}
                            areaTheme={item.group_theme}
                            areaAriaLabel=""
                            name={item.name}
                            locale={item.locale}
                            // mismatchingLocale={hasDifferentLocale(item, assessmentLocale) || false}
                            duration={item.duration_seconds ? formatDurationSeconds(item.duration_seconds) : ''}
                            edit={() => {
                              setIsSelectionModalOpen(true)
                            }}
                          />
                        )
                      }
                    }}
                  />
                )}
              </PreviewContainer>
            ) : (
              <></>
            )}
            <Button
              variant="purple"
              onClick={() => {
                tracking.contentSelection.addTestsButtonClicked()
                setIsSelectionModalOpen(true)
              }}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '0.5rem',
              }}
              data-testid="add-tests-button"
            >
              {testsAdded ? <Icon name="edit" ariaLabel="Edit tests" /> : <Icon name="plus" ariaLabel="Add tests" />}
              {testsAdded ? 'Edit' : 'Add'} tests
            </Button>

            <AddQuestionButtonsContainer>
              <Button
                variant="tertiary"
                onClick={() => {
                  tracking.contentSelection.addTextQuestionButtonClicked()
                  setCustomQuestionModal({ open: true, type: 'text-input' })
                }}
                style={{
                  width: '100%',
                }}
                data-testid="add-text-question-button"
              >
                <Icon name="text" ariaLabel="Add text question" style={{ paddingRight: '0.5rem' }} />
                Add text question
              </Button>
              <Button
                variant="tertiary"
                onClick={() => {
                  tracking.contentSelection.addSelectionQuestionButtonClicked()
                  setCustomQuestionModal({ open: true, type: 'optionset' })
                }}
                style={{
                  width: '100%',
                }}
                data-testid="add-selection-question-button"
              >
                <Icon name="list" ariaLabel="Add selection question" style={{ paddingRight: '0.5rem' }} />
                Add selection question
              </Button>
              {props.isVideoQuestionEnabled && (
                <LockedByPlan
                  tracking={lockedContentTracking}
                  locked={!isOrgPaidPlan}
                  upgradeTo={'Grow'}
                  modalData={{
                    learnMoreLink:
                      'https://intercom.help/hipeople/en/articles/7193404-add-video-questions-to-your-assessment',
                    learnMoreAbout: 'Video Custom Questions',
                  }}
                >
                  <Button
                    variant="tertiary"
                    onClick={() => {
                      tracking.contentSelection.addVideoQuestionButtonClicked()
                      setCustomQuestionModal({
                        open: true,
                        type: 'video',
                      })
                    }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                    data-testid="add-video-question-button"
                  >
                    <Icon name="video" ariaLabel="Add video question" style={{ paddingRight: '0.5rem' }} />

                    {'Add video question'}
                  </Button>
                </LockedByPlan>
              )}
            </AddQuestionButtonsContainer>

            <FooterContainer>
              <Button
                variant="purple-dark"
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={handleClickConfirm}
                disabled={!selection.valid || loading}
                data-testid="confirm-button"
              >
                Confirm
              </Button>
            </FooterContainer>
          </MainContentSelectionArea>
        </Content>

        {customQuestionModal.open && customQuestionModal.type === 'text-input' ? (
          <AddCustomTextQuestionModal
            close={() => {
              setCustomQuestionModal({ open: false })
            }}
            onSubmit={editedQuestion => {
              const question: CustomQuestionData = {
                responseType: 'text-input',
                questionText: editedQuestion.text,
                isRequired: editedQuestion.isRequired,
              }

              submitCustomQuestion(question, customQuestionModal.index)
              setCustomQuestionModal({ open: false })
            }}
            initialValues={{
              text: customQuestionModal.question?.questionText || '',
              isRequired: customQuestionModal.question?.isRequired || false,
            }}
          />
        ) : (
          ''
        )}

        {customQuestionModal.open &&
        (customQuestionModal.type === 'optionset' || customQuestionModal.type === 'chips') ? (
          <AddCustomMultiSelectQuestionModal
            close={() => {
              setCustomQuestionModal({ open: false })
            }}
            onSubmit={editedQuestion => {
              const question: CustomQuestionData = {
                responseType: editedQuestion.allowMultipleChoice ? 'chips' : 'optionset',
                questionText: editedQuestion.text,
                options: editedQuestion.options,
                isRequired: editedQuestion.isRequired,
              }

              submitCustomQuestion(question, customQuestionModal.index)
              setCustomQuestionModal({ open: false })
            }}
            initialValues={{
              text: customQuestionModal.question?.questionText || '',
              options: customQuestionModal.question?.options || [],
              allowMultipleChoice: customQuestionModal.question?.responseType === 'chips' || false,
              isRequired: customQuestionModal.question?.isRequired || false,
            }}
          />
        ) : (
          ''
        )}

        {customQuestionModal.open && customQuestionModal.type === 'video' ? (
          <AddCustomVideoQuestionModal
            close={() => {
              setCustomQuestionModal({ open: false })
            }}
            onSubmit={editedQuestion => {
              const question: CustomQuestionData = {
                responseType: 'video',
                questionText: editedQuestion.text,
                isRequired: editedQuestion.isRequired,
              }

              submitCustomQuestion(question, customQuestionModal.index)
              setCustomQuestionModal({ open: false })
            }}
            initialValues={{
              text: customQuestionModal.question?.questionText || '',
              isRequired: customQuestionModal.question?.isRequired || false,
            }}
          />
        ) : (
          ''
        )}

        {isSelectionModalOpen && (
          <ContentSelectionModal
            organizationSlug={props.org.fields.slug}
            open={isSelectionModalOpen}
            setOpen={setIsSelectionModalOpen}
            groups={mappedGroups}
            matchingLibraryItems={matchingLibraryItems}
            libraryItemFromId={libraryItemFromId}
            selection={selection}
            filter={filter}
            onScrollNext={onScrollNext}
            role={props.role}
          />
        )}
      </AssessmentContentSelectionPageUIStyle>
      {confirmModalOpen && (
        <SaveTemplateModal
          createTemplate={handleSubmitTemplate}
          createAssessment={handleSubmitAssessment}
          onClose={handleCloseConfirmModal}
          loading={loading}
          showContinueWithoutSaving={!!props.role}
          presetTitle={props.mode === 'clone' ? `${selectedPreset?.fields.copy.title} (Copy)` : null}
        />
      )}
    </>
  )
}

const SaveTemplateModal = (props: {
  onClose: () => void
  createTemplate: (templateName: string) => void
  createAssessment: () => void
  loading: boolean
  showContinueWithoutSaving: boolean
  presetTitle: string | null
}) => {
  const [templateName, setTemplateName] = useState(props.presetTitle ?? '')
  const MIN_TEMPLATE_NAME_LENGTH = 3

  return (
    <Modal onClose={props.onClose} open title="Add to library">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <p>By adding to the library, anybody in your organization will be able to reuse the template.</p>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2rem',
          }}
        >
          <div>
            <Label>Template name</Label>
            <TextField
              value={templateName}
              onChange={e => setTemplateName(e.target.value)}
              placeholder="e.g: Product Manager Survey"
            />
          </div>
          <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
            <Button
              variant="purple-dark"
              disabled={templateName.length < MIN_TEMPLATE_NAME_LENGTH || props.loading}
              style={{
                width: '100%',
              }}
              onClick={() => props.createTemplate(templateName)}
              data-testid="add-to-library-button"
            >
              {props.loading ? 'Saving...' : 'Add to library'}
            </Button>

            {props.showContinueWithoutSaving && (
              <>
                <div style={{ display: 'flex', gap: '1rem', paddingBlock: '1rem' }}>
                  <hr
                    style={{
                      flex: 1,
                    }}
                  />
                  <Text variant="label">or</Text>
                  <hr
                    style={{
                      flex: 1,
                    }}
                  />
                </div>
                <div>
                  <Label>Use as custom survey</Label>
                  <Text variant="body-text" style={{}}>
                    By using as a custom survey, you won't be able to reuse this for another job.
                  </Text>
                </div>

                <Button
                  variant={'secondary'}
                  disabled={props.loading}
                  style={{
                    width: '100%',
                  }}
                  onClick={() => props.createAssessment()}
                  data-testid="continue-without-saving-button"
                >
                  {props.loading ? 'Saving...' : 'Use as custom survey'}
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}

const isCustomQuestion = (item: Record<string, any>): item is CustomQuestionData => {
  return item !== undefined && 'responseType' in item && item.responseType !== undefined
}

type CustomQuestionWithId = CustomQuestionData & { id: number }

const isSame = (a: LibraryItemData | CustomQuestionWithId, b: LibraryItemData | CustomQuestionWithId) => {
  if (isCustomQuestion(a) && isCustomQuestion(b)) {
    return a.id === b.id
  } else if (isCustomQuestion(a) || isCustomQuestion(b)) {
    return false
  } else {
    return a.id === b.id
  }
}

const generatePayload = (dataArray: any[], selection: LibrarySelection): TemplateContentItem[] => {
  return dataArray
    .map(item => {
      if (!item) return null
      if (isLibraryItemData(item)) {
        return {
          library_item_id: item.id,
          library_item_config: selection.config[item.id],
        }
      } else {
        return {
          custom_question_response_type: item.responseType,
          custom_question_heading: item.questionText,
          custom_question_options: item.options,
          is_required: item.isRequired,
        }
      }
    })
    .filter(isPresent)
}

function isLibraryItemData(item: LibraryItemData | CustomQuestionWithId): item is LibraryItemData {
  return (
    item.id !== undefined &&
    (item as LibraryItemData).group_title !== undefined &&
    (item as LibraryItemData).name !== undefined &&
    (item as LibraryItemData).duration_seconds !== undefined
  )
}

const PreviewContainer = style().flex({ direction: 'column' }).spacing({ gap: space.m, outerBottom: space.m }).element()

const AssessmentContentSelectionPageUIStyle = style()
  .spacing({ inner: px2rem(32) })
  .size({ minHeight: vh(100) })
  .color({ bg: designSystemColors.backgroundNeutralSecondary })
  .element()

const Content = style()
  .size({ maxWidth: px2rem(900) })
  .spacing({ outer: [px2rem(64), size.auto] })
  .element()

const Title = text
  .highlight()
  .sans({ align: 'center' })
  .spacing({ outerBottom: px2rem(16) })
  .element('h1')

const Description = text.bodyText().sans({ align: 'center' }).element('p')

const MainContentSelectionArea = style()
  .spacing({ outerTop: px2rem(64), gap: px2rem(27) })
  .flex({ direction: 'column', alignItems: 'center' })
  .select('> *', style().size({ width: size.stretch }))
  .element()

const AddQuestionButtonsContainer = style()
  .grid({
    columns: '1fr 1fr 1fr',
  })
  .size({ width: '100%' })
  .spacing({
    outerTop: px2rem(16),
    gap: px2rem(16),
  })
  .color({ fg: designSystemColors.typographySecondary })
  .element()

const FooterContainer = style()
  .flex({ direction: 'column' })
  .center()
  .spacing({ outerTop: px2rem(64), gap: px2rem(24) })
  .element()

function useSelectedPreset(orgId: string, templateId: string | null) {
  const dispatch = useDispatch()
  const selection = useLibrarySelection()

  const selectedPreset = useSelector((state: RootState) =>
    templateId ? selectors.presets.getById(state, templateId) : null,
  )

  useEffect(() => {
    if (templateId && !selectedPreset) {
      dispatch(newRoleSlice.getPresetById(orgId, templateId))
    }
  }, [templateId, selectedPreset, dispatch])

  useEffect(() => {
    if (!selectedPreset?.fields.content?.length) return
    if (selection.libraryItems.set.size) return

    const libraryItems = getSelectionLibraryItems(selectedPreset.fields.content)
    const customQuestions = getSelectionCustomQuestions(selectedPreset.fields.content)
    const config = getConfig(selectedPreset.fields.content)

    selection.libraryItems.replace(libraryItems)
    selection.setCustomQuestions(customQuestions)
    selection.setConfig(config)
  }, [selectedPreset?.id])

  return selection
}

const getSelectionLibraryItems = (content: TemplateContent) => content.map(row => row.library_item_id).filter(isPresent)

const getSelectionCustomQuestions = (content: TemplateContent) =>
  content
    .map(row => {
      if (!row.custom_question_response_type || !row.custom_question_heading) return undefined
      return {
        options: row.custom_question_options,
        isRequired: row.is_required || false,
        questionText: row.custom_question_heading,
        responseType: row.custom_question_response_type,
      }
    })
    .filter(isPresent)

const getConfig = (content: TemplateContent) =>
  content.reduce((acc, row) => {
    if (row.library_item_id && row.library_item_config) {
      acc[row.library_item_id] = row.library_item_config
    }
    return acc
  }, {} as { [libraryItemId: string]: LibraryItemConfig })
