import React from 'react'
import { Score } from '../../../api/score'
import { Locale } from '../../../models/locales'
import { Badge } from '../../Badge/Badge'
import { Button } from '../../Button/Button'
import { DrawerCard } from '../../DrawerCard/DrawerCard'
import { LanguageIcon } from '../../Dropdown/LanguageDropdown/LanguageDropdown'
import { PairwiseScore } from '../../PairwiseScore'
import { ScoreInfo } from '../../ScoreInfo/ScoreInfo'
import { Text } from '../../Text/Text'
import { Time } from '../../Time/Time'
import { AnswerResultsBar } from '../AnswerResultsBar/AnswerResultsBar'
import { GaussianDistribution5 } from '../GaussianDistribution/GaussianDistribution5'
import { ScoreCard } from './ScoreCard'
import { GetModuleDataFn, getScoreVisualType, Group, ScoreGroupProps } from './ScoreGroup'
import classes from './ScoreGroup.module.scss'
import { Card } from '../../Card/Card'
import { KnowledgeCard } from '../../KnowledgeCard/KnowledgeCard'

interface ModuleScoreDrawerProps extends ScoreGroupProps {
  child: Score
  answerResultScoredPresent: boolean
  defaultCollapsed?: boolean
}

export const ModuleScoreDrawer = ({
  child,
  answerResultScoredPresent,
  defaultCollapsed = true,
  ...props
}: ModuleScoreDrawerProps) => {
  const scoreType = getScoreVisualType(child, props.group)
  const moduleData = props.getModuleData?.(child)
  const hasChildren = scoreType.slug !== 'disc' && props.score?.children && props.score.children.length >= 1
  return (
    // we remove the padding here to ensure the hover effect goes edge to edge
    <ScoreCard key={child.slug} style={{ padding: 0 }}>
      <DrawerCard
        withoutHeaderLayout
        showHandle
        defaultCollapsed={defaultCollapsed}
        header={
          <div
            key={child.slug}
            className={`${classes.fullWidthSubgridItem} ${scoreType.pairwiseOnHeader ? classes.pairwiseHeader : ''}`}
          >
            <Text variant="body-interactive" style={{ whiteSpace: 'nowrap' }}>
              {child.name}
            </Text>
            {scoreType.pairwiseOnHeader && child.benchmark !== undefined ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <div className={classes.center}>
                  <PairwiseScore
                    score={child.benchmark}
                    startCaption={moduleData?.pairwise?.startCaption}
                    endCaption={moduleData?.pairwise?.endCaption}
                    axisLabels={!moduleData?.isSharedResults}
                  />
                </div>
              </div>
            ) : scoreType.slug === 'disc' ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Badge variant="gray">
                  <Text variant="body-interactive">{child.qualitative_score}</Text>
                </Badge>
              </div>
            ) : (
              scoreType.showScore && (
                <>
                  <Text variant="body-interactive">{child.score.toFixed(0)}%</Text>
                  <Time
                    timeTakenMillis={child.time_taken_millis || 0}
                    timeAllowedMillis={child.time_allowed_millis || 0}
                    showIcon
                  />
                </>
              )
            )}
          </div>
        }
        className={classes.fullWidthSubgridItem}
        headerContentClassName={classes.headerSubgrid}
        headerClassName={classes.fullWidthSubgridItem}
      >
        {hasChildren && (
          <div className={classes.inventory}>
            {props.score?.children?.map(score => (
              <ScoreInventoryItem
                key={score.slug}
                group={props.group}
                score={score}
                moduleData={moduleData}
                getModuleData={props.getModuleData}
              />
            ))}
          </div>
        )}
        <div className={classes.contentBody}>
          {props.score && moduleData?.description && (
            <Text variant="body-text" className={classes.contentBodyText}>
              {moduleData.description}
            </Text>
          )}
          <div className={classes.contentBodyScores}>
            {scoreType.showScore && child.benchmark !== undefined && <GaussianDistribution5 n={child.benchmark} />}
            {answerResultScoredPresent &&
              child.num_correct !== undefined &&
              child.num_incorrect !== undefined &&
              child.num_skipped !== undefined && (
                <AnswerResultsBar
                  numCorrectQuestions={child.num_correct}
                  numIncorrectQuestions={child.num_incorrect}
                  numSkippedQuestions={child.num_skipped}
                  fullWidth={true}
                  fullHeight={true}
                  onClick={() => {
                    if (props.onClickResponses) {
                      props.onClickResponses(child.slug || '', props.group)
                    }
                  }}
                />
              )}
          </div>
          {moduleData?.customDescription && (
            <div className={classes.contentBodyScoreInfo}>
              <ScoreInfo
                benchmarkScore={child.benchmark !== undefined ? child.benchmark : getBenchmarkFromScore(child.score)}
                header={<Text variant="body-interactive">Expected behavior</Text>}
              >
                <Text variant="body-text" className={classes.contentBodyText}>
                  {moduleData.customDescription}
                </Text>
              </ScoreInfo>
            </div>
          )}
          {moduleData?.isSharedResults && moduleData?.learnings?.length && (
            <Card className={classes.contentBodyLearnings}>
              <Text variant="secondary-body-interactive" marginBottom style={{ width: '100%' }}>
                Do you want to improve your {child.name} skills? Check out these resources and level up your game.
              </Text>
              <div className={classes.contentBodyLearningsList}>
                {moduleData?.learnings.map((learning, idx) => (
                  <KnowledgeCard key={idx} learning={learning} />
                ))}
              </div>
            </Card>
          )}
          {!moduleData?.isSharedResults && (
            <div className={classes.contentBodyActionButtons}>
              <Button
                data-testid="responses-button"
                variant="tertiary"
                onClick={() => {
                  if (props.onClickResponses) {
                    props.onClickResponses(child.slug || '', props.group)
                  }
                }}
              >
                Responses
              </Button>

              {moduleData?.locale && (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <LanguageIcon locale={moduleData.locale as Locale} big />
                </div>
              )}
            </div>
          )}
        </div>
      </DrawerCard>
    </ScoreCard>
  )
}

export const getBenchmarkFromScore = (achievedScorePercentage: number): number => {
  if (achievedScorePercentage < 33) {
    return 0
  } else if (achievedScorePercentage <= 66) {
    return 2
  } else {
    return 4
  }
}

const ScoreInventoryItem: React.FC<{
  score: Score
  group: Group
  moduleData?: ReturnType<GetModuleDataFn>
  getModuleData?: GetModuleDataFn
}> = props => {
  const subModuleData = props.getModuleData?.(props.score)
  const behaviorDescriptionSubModule = subModuleData?.customDescription

  const benchmarkScore =
    props.score.benchmark !== undefined ? props.score.benchmark : getBenchmarkFromScore(props.score.score)

  return (
    <div key={props.score.slug} className={classes.moduleScoreRow}>
      <div className={classes.pairwiseRow}>
        <Text variant="body-interactive" style={{ whiteSpace: 'nowrap' }}>
          {props.score.name}
        </Text>
        <div className={classes.center}>
          <>
            <PairwiseScore
              score={benchmarkScore}
              startCaption={subModuleData?.pairwise?.startCaption}
              endCaption={subModuleData?.pairwise?.endCaption}
              axisLabels={!props.moduleData?.isSharedResults || !subModuleData?.isSharedResults}
            />
          </>
        </div>
      </div>
      {behaviorDescriptionSubModule && (
        <div className={classes.contentBodyScoreInfo}>
          <ScoreInfo benchmarkScore={benchmarkScore} header={<Text variant="body-interactive">Expected behavior</Text>}>
            <Text variant="body-text" className={classes.contentBodyText}>
              {behaviorDescriptionSubModule}
            </Text>
          </ScoreInfo>
        </div>
      )}
    </div>
  )
}
