import React, { useEffect, useState } from 'react'
import Modal from '../../components/Modal'
import ModalButtonsV2 from '../../components/Modal/ModalButtonsV2'
import TextField from '../../components/TextField'
import { px2rem, rem, style } from '../../core'

interface Props {
  text: string
  open: boolean
  setOpen: (open: boolean) => void
  onConfirm: (text: string) => void
}

const Container = style()
  .spacing({ inner: [px2rem(16), px2rem(32)] })
  .element()

const TextFieldContainer = style()
  .size({
    maxWidth: '100%',
    maxHeight: rem(40),
    width: px2rem(576),
  })
  .element()

const ButtonsContainer = style()
  .spacing({ outerTop: px2rem(16) })
  .element()

const Component = (props: Props) => {
  const [text, setText] = useState(props.text)
  const [disabled, setDisabled] = useState(false)
  const [textInvalid, setTextInvalid] = useState(true)

  useEffect(() => {
    setTextInvalid(text.trim().length === 0)
  }, [text])

  return (
    <Modal title={`Update text`} open={props.open} setOpen={props.setOpen}>
      <Container>
        <TextFieldContainer>
          <TextField
            label={'Text'}
            value={text}
            onChange={e => setText(e.target.value)}
            multiline={true}
            height={'15rem'}
            noresize
          />
        </TextFieldContainer>

        <ButtonsContainer>
          <ModalButtonsV2
            disabled={disabled}
            disabledSubmitButton={textInvalid}
            submitLabel={'Update text'}
            onCancel={() => {
              setDisabled(true)
              props.setOpen(false)
            }}
            onSubmit={() => {
              setDisabled(true)
              props.setOpen(false)
              props.onConfirm(text)
            }}
            confirmButtonType={'accent'}
          />
        </ButtonsContainer>
      </Container>
    </Modal>
  )
}

export default Component
Component.displayName = 'UpdateRoleTextModal'
