import React from 'react'
import classes from './Beacon.module.scss'

interface BeaconProps {
  children: React.ReactNode
  visible?: boolean
}

export const Beacon = ({ children, visible }: BeaconProps) => {
  return (
    <div className={classes.wrapper}>
      {children}
      {(visible === undefined || visible) && (
        <div className={classes.container}>
          <div className={classes.beacon} />
          <div className={classes.beaconElement} />
        </div>
      )}
    </div>
  )
}
