import { Icon } from 'components/Icons'
import Pairwise from 'components/Pairwise'
import { space, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React from 'react'
import { OceanScore } from 'selectors/onboarding-summaries'

const Container = style().element()

const Heading = text
  .label()
  .flex({ alignItems: 'center' })
  .spacing({ gap: space.xxs })
  .sans({ uppercase: true, color: designSystemColors.typographySecondary })
  .element()

const DocumentationIcon = text.bodyInteractive().color({ fg: designSystemColors.typographySecondary }).element('a')

const DOCUMENTATION_LINK =
  'https://www.notion.so/hipeople/Personality-Profile-Public-Documentation-de7f9db9edf94205be51abfb48dd1805'

const Pairwises = style().flex({ direction: 'column' }).spacing({ gap: space.xs, outerTop: space.s }).element()

interface PersonalityProfileProps {
  scores: OceanScore
}

const PersonalityProfile: React.FC<PersonalityProfileProps> = ({ scores }) => (
  <Container>
    <Heading>
      Personality profile
      <DocumentationIcon href={DOCUMENTATION_LINK} target="_blank" rel="noopener noreferrer">
        <Icon name="question-circle" />
      </DocumentationIcon>
    </Heading>
    <Pairwises>
      <Pairwise
        startCaption="Private"
        endCaption="Outgoing"
        score={scores.extraversion}
        iconTextColor="transparent"
        hideScoreTooltip={true}
      />
      <Pairwise
        startCaption="Forthright"
        endCaption="Polite"
        score={scores.agreeableness}
        iconTextColor="transparent"
        hideScoreTooltip={true}
      />
      <Pairwise
        startCaption="Flexible"
        endCaption="Organized"
        score={scores.conscientiousness}
        iconTextColor="transparent"
        hideScoreTooltip={true}
      />
      <Pairwise
        startCaption="Pragmatic"
        endCaption="Reflective"
        score={scores.openness}
        iconTextColor="transparent"
        hideScoreTooltip={true}
      />
      <Pairwise
        startCaption="Excitable"
        endCaption="Relaxed"
        score={scores.stability}
        iconTextColor="transparent"
        hideScoreTooltip={true}
      />
    </Pairwises>
  </Container>
)

export default PersonalityProfile
