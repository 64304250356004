import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as api from '../../api'
import { webhooks } from '../../api'
import { AppThunk } from '../../core/store'
import { add as notify } from '../notifications'
import { addEntities } from '../resources'

interface State {
  loading: boolean
  creating: boolean
  hasMore: boolean
  total: number
  content: string[]
  changes: Changes
  createdId: string
}

interface Changes {
  title?: string
  target_method?: string
  target_url?: string
  custom_payload?: string
  username?: string
  password?: string
  action?: string
  resource?: string
}

const name = webhooks.RESOURCE
const initialState: State = {
  loading: false,
  creating: false,
  hasMore: false,
  total: 0,
  content: [],
  changes: {},
  createdId: '',
}

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setContent(state, action: PayloadAction<string[]>) {
      state.content = action.payload
    },
    addContent(state, action: PayloadAction<string[]>) {
      state.content = state.content.concat(action.payload)
    },
    setCreating(state, action: PayloadAction<boolean>) {
      state.creating = action.payload
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setHasMore(state, action: PayloadAction<boolean>) {
      state.hasMore = action.payload
    },
    setTotal(state, action: PayloadAction<number>) {
      state.total = action.payload
    },
    setCustomPayload(state, action: PayloadAction<string>) {
      state.changes.custom_payload = action.payload
    },
    setMethod(state, action: PayloadAction<string>) {
      state.changes.target_method = action.payload
    },
    setTitle(state, action: PayloadAction<string>) {
      state.changes.title = action.payload
    },
    setUrl(state, action: PayloadAction<string>) {
      state.changes.target_url = action.payload
    },
    setUsername(state, action: PayloadAction<string>) {
      state.changes.username = action.payload
    },
    setPassword(state, action: PayloadAction<string>) {
      state.changes.password = action.payload
    },
    setAction(state, action: PayloadAction<string>) {
      state.changes.action = action.payload
    },
    setResource(state, action: PayloadAction<string>) {
      state.changes.resource = action.payload
    },
    setCreatedId(state, action: PayloadAction<string>) {
      state.createdId = action.payload
    },
    reset(state) {
      state.changes = {}
      state.createdId = ''
    },
  },
})

export const {
  setContent,
  addContent,
  setCreating,
  setLoading,
  setHasMore,
  setTotal,
  setCustomPayload,
  setMethod,
  setTitle,
  setUrl,
  setUsername,
  setPassword,
  setAction,
  setResource,
  setCreatedId,
  reset,
} = slice.actions

export default slice.reducer

export const create =
  (body: webhooks.CreateWebhookPayload): AppThunk =>
  async dispatch => {
    dispatch(setLoading(true))
    dispatch(setCreating(true))

    const [response, errors] = await webhooks.create(body)

    dispatch(setLoading(false))
    dispatch(setCreating(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    if (response) {
      dispatch(addContent([response.result.id]))
      dispatch(addEntities(response))
      dispatch(setCreatedId(response.result.id))
      dispatch(notify({ success: 'Successfully created your webhook!' }))
      dispatch(reset())
      return
    }
  }

export const load =
  (orgId: string, skip?: number): AppThunk =>
  async dispatch => {
    dispatch(setLoading(true))

    const [response, errors] = await api.listing.list<webhooks.Fields>(`organizations/${orgId}/webhooks`, {
      limit: 20,
      filters: [api.listing.True('is_active')],
      sort: [
        {
          field: 'updated_at',
          order: api.listing.SortOrder.Desc,
        },
      ],
      skip: skip || 0,
    })

    dispatch(setLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    if (response) {
      dispatch(addEntities(response))
      dispatch(setTotal(response.list?.total || 0))
      if (skip && skip > 0) {
        dispatch(addContent(response.result.map(row => row.fields.id)))
      } else {
        dispatch(setContent(response.result.map(row => row.fields.id)))
      }
      dispatch(setHasMore(response.list?.has_more || false))
    }
  }

export const update =
  (id: string, changes: Changes): AppThunk =>
  async dispatch => {
    dispatch(setLoading(true))

    const [response, errors] = await webhooks.update(id, {
      changed: Object.keys(changes),
      fields: changes,
    })

    dispatch(setLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    if (response) {
      dispatch(addEntities(response))
      dispatch(notify({ success: 'Successfully updated your webhook!' }))
      dispatch(reset())
      return
    }
  }

export const remove =
  (id: string): AppThunk =>
  async dispatch => {
    dispatch(setLoading(true))
    const [response, errors] = await webhooks.remove(id)
    dispatch(setLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    if (response) {
      dispatch(addEntities(response))
      dispatch(reset())
      dispatch(notify({ success: 'Successfully deleted your webhook!' }))
      return
    }
  }
