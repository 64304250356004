import { ModuleType } from 'api/modules'
import { Column } from 'components/ContentGrid'
import { px2rem, size, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React, { useMemo } from 'react'

import * as tracking from '../../../core/track'

import { Badge, Button, ToggleSwitch } from '@common/components'
import { WarningIcon } from 'App/RoleCreation/TemplateCreationAssessmentV2/ContentSelection/WarningIcon'
import { Icon } from 'components/Icons'

const Table = style()
  .border({
    around: `1px solid`,
    color: designSystemColors.borderDefault,
    radius: px2rem(8),
  })
  .set('borderSpacing', '0px')
  .size({ width: size.fill })
  .element('table')

const Row = style()
  .grid({
    columns: [px2rem(14), px2rem(270), size.auto, px2rem(124), px2rem(14)],
  })
  .element('tr')

const Title = text.secondaryBodyInteractive().element('th')
const Actions = style()
  .spacing({ gap: px2rem(14) })
  .elementFromComponent(Column)

const ModuleContainer = style()
  .transparent()
  .noborders()
  .nospacing()
  .sans({ align: 'left' })
  .select('> :first-child', style().size({ height: '100%' }))
  .element('div')

const ModuleInfo = style()
  .flex()
  .spacing({ gap: px2rem(16) })
  .element()

const ModuleName = text.cardHeader().element('p')
const ModuleEmoji = text.cardHeader().element('p')

const Copy = text.bodyText().element('p')

const onboardingModulesSlug = ['areas-of-improvement', 'culture-add', 'strengths', 'work-styles', 'working-with-others']

const bfasCrossoverModules = [
  'Patience',
  'Grit',
  'Relationship Building',
  'Reliability',
  'Attention to Detail',
  'Stress Tolerance',
  'Achievement Drive',
]

export type ModulesData = {
  slug: string
  emoji: string
  name: string
  description: string
  preview?: { question: string; options?: string[]; action?: string }
  type?: ModuleType
  facets?: string[]
}[]

interface ModuleSelectTableProps {
  modules: ModulesData
  toggleModuleSelection: (moduleId: string) => any
  selectedModuleIds: string[]
  isToggleDisabled?: boolean
  isBFASSelected?: boolean
  onModuleDetailsClick: (targetModuleIndex: number) => any
}
const ModuleSelectTable: React.FC<ModuleSelectTableProps> = ({
  modules,
  toggleModuleSelection,
  selectedModuleIds,
  isToggleDisabled,
  isBFASSelected,
  onModuleDetailsClick,
}) => {
  const selectedModuleIdSet = useMemo(() => new Set(selectedModuleIds), [selectedModuleIds])

  return (
    <Table>
      <Row>
        <Column head></Column>
        <Column head>
          <Title>Module name</Title>
        </Column>
        <Column head>
          <Title>Description</Title>
        </Column>
        <Column head>
          <Title>Include</Title>
        </Column>
        <Column head></Column>
      </Row>
      {modules.map((module, i) => (
        <Row key={`${module.slug}`}>
          <Column />
          <Column centerVert>
            <ModuleInfo>
              <ModuleEmoji>{module.emoji}</ModuleEmoji>
              <ModuleName>
                {module.name}
                {isBFASSelected && bfasCrossoverModules.includes(module.name) && (
                  <WarningIcon
                    message={
                      'Warning: you have already selected the BFAS (Big Five) Personality Assessment, where this soft skill is already covered.'
                    }
                  />
                )}
                {onboardingModulesSlug.includes(module.slug) && (
                  <Badge variant="success" style={{ marginTop: 5, cursor: 'pointer' }}>
                    Onboarding insights
                  </Badge>
                )}
              </ModuleName>
            </ModuleInfo>
          </Column>
          <ModuleContainer centerVert>
            <Column centerVert>
              <Copy>{module.description}</Copy>
            </Column>
          </ModuleContainer>
          <Actions centerVert>
            <Button variant="icon" onClick={() => onModuleDetailsClick(i)}>
              <Icon name="eye" ariaLabel="Preview Template" />
            </Button>

            <ToggleSwitch
              on={selectedModuleIdSet.has(module.slug)}
              aria-label={`Include ${module.name} module`}
              setOn={() => {
                toggleModuleSelection(module.slug)
                selectedModuleIdSet.has(module.slug)
                  ? tracking.selfAssessment.softSkillUnselected(module.name)
                  : tracking.selfAssessment.softSkillSelected(module.name)
              }}
              disabled={isToggleDisabled}
            />
          </Actions>
          <Column />
        </Row>
      ))}
    </Table>
  )
}

export default ModuleSelectTable
