import { MediaPlayer } from '@common/components'
import EmptyAnswer from 'App/Candidate/EmptyAnswer'
import { Icon } from 'components/Icons'
import { px2rem, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import { interpolateVariables } from 'core/text'
import * as tracking from 'core/track'
import Markdown from 'markdown-to-jsx'
import React from 'react'

interface Props {
  skipped?: boolean
  question: string
  description: string
  input?: string
  candidateFirstName: string
  mediaURL?: string
}

const Container = style()
  .spacing({ inner: px2rem(16) })
  .color({ bg: designSystemColors.backgroundNeutralSecondary })
  .border({ radius: px2rem(4) })
  .element()

const Title = text
  .bodyEmphasis()
  .spacing({ outerBottom: px2rem(12) })
  .element('h3')

const FollowupDescription = text
  .smallBody()
  .select(
    'a',
    style()
      .text({ color: designSystemColors.typographyPrimary, nodecoration: true })
      .border({ bottom: '1px solid', color: designSystemColors.borderDefault }),
  )
  .element('p')

const FollowupAnswer = text.bodyText().element('p')

const Header = style()
  .flex({ alignItems: 'center' })
  .spacing({ outerBottom: px2rem(12) })
  .select('> *:first-child', style().spacing({ outer: [px2rem(0), px2rem(12), px2rem(0), px2rem(6)] }))
  .element()

const RDSFollowup = (props: Props) => {
  const variables = { 'CANDIDATE.FIRST_NAME': props.candidateFirstName }

  return (
    <Container>
      {props.description && (
        <Header>
          <Icon name={'code-branch'} />
          <FollowupDescription>
            <Markdown>{interpolateVariables(props.description, variables)}</Markdown>
          </FollowupDescription>
        </Header>
      )}
      <Title>{interpolateVariables(props.question, variables)}</Title>
      {props.skipped ? (
        <EmptyAnswer skipped />
      ) : props.mediaURL ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <MediaPlayer
            mediaURL={props.mediaURL}
            onAudioPlayCallback={() => tracking.media.playAudio()}
            onVideoPlayCallback={() => tracking.media.playVideo({})}
          />
        </div>
      ) : (
        <FollowupAnswer>{props.input}</FollowupAnswer>
      )}
    </Container>
  )
}

export default RDSFollowup
