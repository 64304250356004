import Heatmap from 'components/Heatmap'
import { px2rem, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { useOverallPerformanceQuestions, useSkillQuestions } from 'providers/responses'
import React from 'react'
import { useParams } from 'react-router-dom'

const Container = style()
  .color({ bg: designSystemColors.backgroundNeutralPrimary })
  .spacing({
    inner: [px2rem(16), px2rem(26), px2rem(16), px2rem(16)],
  })
  .border({
    around: 'solid 1px',
    color: designSystemColors.borderDefault,
    radius: px2rem(6),
  })
  .element()

const Component = () => {
  const { candidateid: candidate_id } = useParams<{ candidateid: string }>()
  const candidateid = candidate_id || ''
  const { skills } = useSkillQuestions({ candidateId: candidateid })
  const { overallPerformances } = useOverallPerformanceQuestions({
    candidateId: candidateid,
  })

  if (!candidateid || (!Object.keys(skills).length && !overallPerformances)) {
    return null
  }

  return (
    <Container>
      <Heatmap candidateId={candidateid} />
    </Container>
  )
}

export default Component
Component.displayName = 'SkillsetBlock'
