import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface Message {
  error?: string
  success?: string
  timeoutSecs?: number
  createdAt?: number
}

interface State {
  messages: Message[]
}

const name = 'notifications'
const initialState: State = {
  messages: [],
}

const slice = createSlice({
  name,
  initialState,
  reducers: {
    add(state, action: PayloadAction<Message>) {
      state.messages.push({
        ...action.payload,
        createdAt: Date.now(),
      })
    },
  },
})

export const { add } = slice.actions

export default slice.reducer
