
      // Load gtag.js only in production
      if (import.meta.env.PROD) {
        const script = document.createElement('script')
        script.async = true
        script.src = 'https://www.googletagmanager.com/gtag/js?id=G-4ES0JBSE6H'
        document.head.appendChild(script)
      }

      window.dataLayer = window.dataLayer || []
      window.gtag = function () {
        window.dataLayer.push(arguments)
      }
      window.gtag('js', new Date())
      window.gtag('config', 'G-4ES0JBSE6H')
    