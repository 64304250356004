import { listing } from './index'
import { del, get, post } from './methods'
import { APIResponse, Entity } from './request'

export const RESOURCE = 'employees'

export interface Fields {
  id: string
  org_id: string
  work_email: string
  name: string
  manager_id: string
  department: string
  created_at: number
  updated_at: number
  is_active: boolean
  start_at: number
  scores: {
    employee_organization?: number | null
    employee_role?: number | null
    employee_team?: number | null
    manager_organization?: number | null
    manager_role?: number | null
    manager_team?: number | null
    managers_evaluation_score?: number | null
    new_hire_experience_score?: number | null
    overall_score?: number | null
  }
}

export interface CreateFields {
  name: string
  department: string
  manager_name: string
  work_email: string
  manager_email: string
  start_at: number
}

export function getById(orgId: string, employeeId: string, include?: string): Promise<APIResponse<Entity<Fields>>> {
  const includeStr = include ? '?include=' + include : ''
  return get<Entity<Fields>>(`organizations/${orgId}/employees/${employeeId}${includeStr}`)
}

export function list(orgId: string, options?: listing.Options): Promise<APIResponse<Entity<Fields>[]>> {
  return listing.list<Fields>(`organizations/${orgId}/employees`, options || {})
}

export function create(orgId: string, employee: CreateFields): Promise<APIResponse<Entity<Fields>>> {
  return post<Entity<Fields>>(`organizations/${orgId}/employees`, employee)
}

export function remove(orgId: string, employeeId: string): Promise<APIResponse<Entity<Fields>>> {
  return del<Entity<Fields>>(`organizations/${orgId}/employees/${employeeId}`, {})
}
