import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import * as tracking from '../../../core/track'
import { RootState } from 'store'
import { login, setPasswd } from 'store/login'
import { restore } from 'store/sessions'
import { SignUp, SignUpValues, StepIndex } from 'App/SignUp'
import { add as notify } from 'store/notifications'

const clientId = encodeURIComponent(`${import.meta.env.VITE_GOOGLE_AUTH_CLIENT_ID}`)
const signInRedirectUrl = encodeURIComponent(`${import.meta.env.VITE_GOOGLE_AUTH_REDIRECT_BASE_URL}/login/google`)
const signUpRedirectUrl = encodeURIComponent(`${import.meta.env.VITE_GOOGLE_AUTH_REDIRECT_BASE_URL}/sign-up/google`)
const scope = encodeURIComponent(
  'openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
)

const nonceStorageKey = 'google-auth-nonce'

const nonceStorage = sessionStorage

function getNonce() {
  const nonce = crypto.getRandomValues(new Uint8Array(128)).toString()
  nonceStorage.setItem(nonceStorageKey, nonce)
  return nonce
}

export const signUpWithGoogle = (utmParams: Record<string, string>) => {
  window.location.replace(
    `https://accounts.google.com/o/oauth2/auth?client_id=${clientId}&redirect_uri=${signUpRedirectUrl}&response_type=code&scope=${scope}&state=${new GoogleSSORegistrationState(
      getNonce(),
      'registration',
      true,
      true,
      utmParams,
    ).encode()}`,
  )
}

export const signInWithGoogle = () => {
  window.location.replace(
    `https://accounts.google.com/o/oauth2/auth?client_id=${clientId}&redirect_uri=${signInRedirectUrl}&response_type=code&scope=${scope}&state=${new GoogleSSORegistrationState(
      getNonce(),
      'login',
    ).encode()}`,
  )
}

export class GoogleSSORegistrationState {
  constructor(
    public nonce: string,
    public flow: 'registration' | 'login',
    public tncAccepted?: boolean,
    public newsletterAccepted?: boolean,
    public utmParams?: Record<string, string>,
  ) {}

  public encode(): string {
    return btoa(
      JSON.stringify({
        nonce: this.nonce,
        flow: this.flow,
        tncAccepted: this.tncAccepted,
        newsletterAccepted: this.newsletterAccepted,
        utmParams: this.utmParams,
      }),
    )
  }

  public static Decode(value: string): GoogleSSORegistrationState {
    const v = JSON.parse(atob(value))
    return new GoogleSSORegistrationState(v.nonce, v.flow, v.tncAccepted, v.newsletterAccepted, v.utmParams)
  }
}

export const GoogleSSO: React.FC<{ children?: React.ReactNode }> = props => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [pickedUpParams, setPickedUpParams] = useState(false)

  const params = new URL(window.location.toString()).searchParams
  const location = useLocation()

  const stateParam = params.get('state')
  const codeParam = params.get('code') || undefined

  const [timeoutExpired, setTimeoutExpired] = useState(false)
  const [flow, setFlow] = useState<'registration' | 'login'>()

  const [finishedRegistration, setFinishedRegistration] = useState(false)
  const [signupValues, setSignupValues] = useState<SignUpValues>()

  setTimeout(() => setTimeoutExpired(true), 1500)

  // Create an organization first if we're in the registration flow
  useEffect(() => {
    if (!pickedUpParams && stateParam && codeParam && stateParam.trim().length > 0 && codeParam.trim().length > 0) {
      // Set state to "done" so that we don't end up in a redirect loop in case of an error
      setPickedUpParams(true)

      const state = GoogleSSORegistrationState.Decode(stateParam || '')

      if (state.flow === 'registration') {
        if (state.newsletterAccepted !== undefined && state.tncAccepted !== undefined) {
          // Check if the authentication request came from this browser
          const expectedNonce = nonceStorage.getItem(nonceStorageKey)
          nonceStorage.removeItem(nonceStorageKey)

          if (state.nonce !== expectedNonce) {
            // The user needs to start over.
            dispatch(notify({ error: 'Something went wrong in Google Authentication flow' }))
            // Remove the auth params from the URL.
            history.push('/sign-up')

            return
          }

          setSignupValues({
            startFreeTrial: {
              emailAddress: '',
              password: '',
              agreedToTermsAndConditions: state.tncAccepted,
              agreedToNewsletter: state.newsletterAccepted,
            },
            tellUsMore: {
              fullName: '',
              jobTitle: '',
              phoneNumber: '',
              companyName: '',
            },
            googleCode: codeParam,
          })

          setFinishedRegistration(true)
        }
      } else if (state.flow === 'login') {
        dispatch(login(undefined, undefined, 'google', codeParam))
        dispatch(setPasswd(''))
      }

      setFlow(state.flow)
    }
  }, [stateParam, codeParam])

  // If org was created successful, there will be a JWT available. We're not "logged in" yet tough and need to
  // restore the full session.
  const jwt = useSelector((state: RootState) => state.sessions.jwt)
  useEffect(() => {
    if (jwt) {
      dispatch(restore())
    }
  }, [pickedUpParams, jwt])

  const isLoggedIn = useSelector((state: RootState) => state.sessions.isLoggedIn)
  useEffect(() => {
    const utmParamsString = localStorage.getItem('utm_params')
    const utmParams = JSON.parse(utmParamsString !== null ? utmParamsString : '{}')
    // If creating was successful, redirect to the main page so that the router can do its work.
    if (flow && isLoggedIn) {
      if (flow === 'registration') {
        tracking.signUp.signUpWithGoogleAuth(utmParams ?? {})
      } else if (flow === 'login') {
        tracking.users.login({ isGoogleSSO: true })
      }

      history.push('/', { googlesso: true, ...utmParams })
    }
  }, [isLoggedIn, flow, location])

  if (finishedRegistration) {
    return <SignUp initialStep={StepIndex.TellUsMore} initialValues={signupValues} />
  }

  return <>{timeoutExpired && props.children}</>
}

GoogleSSO.displayName = 'CompleteGoogleSignUp'
