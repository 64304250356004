import { gradientColor } from 'components/Heatmap/Score'
import { px2rem, size, space, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React, { useState } from 'react'
import { OverTimeScores } from './index'

interface CandidateNPSProps {
  scores: OverTimeScores
}

const MAX_HEIGHT = 104

const CandidateNPSStyle = style()
  .color({ bg: designSystemColors.backgroundNeutralPrimary })
  .border({
    around: `1px solid`,
    color: designSystemColors.borderDefault,
    radius: px2rem(8),
  })
  .nooverflow()
  .spacing({
    inner: px2rem(16),
    rows: px2rem(24),
  })
  .grid()
  .element()

const Title = text.cardHeader().element()

const BarContainer = style()
  .size({ height: px2rem(MAX_HEIGHT) })
  .nooverflow()
  .flex()
  .bg({ color: designSystemColors.backgroundNeutralSecondary })
  .border({ radius: px2rem(3) })
  .spacing({ gap: px2rem(1) })
  .element()

const Score = text
  .label()
  .absolute({ right: 0, left: 0, bottom: px2rem(4) })
  .sans({ align: 'center' })
  .element()

const Label = text
  .smallBodySecondary()
  .sans({ align: 'center' })
  .size({ width: size.fill })
  .cond(({ isHiddenUntilHovered, isActiveScore }) => isHiddenUntilHovered && !isActiveScore, style().invisible())
  .element()

const Bar = style()
  .spacing({ outerTop: space.auto })
  .size({ width: size.fill })
  .relative()
  .with((props: { score: number }) =>
    style()
      .size({ height: `${(props.score / 10) * 100}%` })
      .set('backgroundColor', gradientColor(props.score, 10)),
  )
  .cond(
    ({ isHiddenUntilHovered }) => isHiddenUntilHovered,
    style()
      .select(`${Score}`, style().invisible())
      .select(`:hover ${Score}`, style().visible())
      .select(`${Label}`, style().invisible()),
  )

  .element()

const Labels = style().flex().element()

const Chart = style()
  .grid()
  .spacing({ gap: px2rem(4) })
  .element()

const CandidateNPS: React.FC<CandidateNPSProps> = ({ scores }) => {
  const [activeScoreBar, setActiveScoreBar] = useState<number | null>(null)
  return (
    <CandidateNPSStyle>
      <Title>Experience score over time</Title>
      <Chart>
        <BarContainer>
          {scores.map((score, i) => (
            <Bar
              score={score.score || 0}
              key={`${score.label || '14.11'}-${i}`}
              isHiddenUntilHovered={score.score !== null && score.hiddenUntilHovered}
              onMouseEnter={() => setActiveScoreBar(i)}
              onMouseLeave={() => setActiveScoreBar(null)}
              isActiveScoreBar={activeScoreBar === i}
            >
              <Score>{score.score?.toFixed(1) || '-'}</Score>
            </Bar>
          ))}
        </BarContainer>
        <Labels>
          {scores.map((score, i) => (
            <Label
              key={`${score.label || '14.11'}-${i}`}
              isActiveScore={activeScoreBar === i}
              isHiddenUntilHovered={score.hiddenUntilHovered}
            >
              {score.label}
            </Label>
          ))}
        </Labels>
      </Chart>
    </CandidateNPSStyle>
  )
}

export default CandidateNPS
