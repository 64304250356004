import React from 'react'
import styles from './PageHeader.module.scss'
import clsx from 'clsx'

interface RootProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
}

const Root = React.forwardRef<HTMLDivElement, RootProps>(({ className, children, ...props }, ref) => {
  return (
    <div ref={ref} className={clsx(styles.root, className)} {...props}>
      {children}
    </div>
  )
})

Root.displayName = 'PageHeader.Root'

interface TitleProps extends React.HTMLAttributes<HTMLHeadingElement> {
  children: React.ReactNode
}

const Title = React.forwardRef<HTMLHeadingElement, TitleProps>(({ className, children, ...props }, ref) => {
  return (
    <h1 ref={ref} className={clsx(styles.title, className)} {...props}>
      {children}
    </h1>
  )
})

Title.displayName = 'PageHeader.Title'

interface StartItemsProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
}

const StartItems = React.forwardRef<HTMLDivElement, StartItemsProps>(({ className, children, ...props }, ref) => {
  return (
    <div ref={ref} className={clsx(styles.itemGroup, className)} {...props}>
      {children}
    </div>
  )
})

StartItems.displayName = 'PageHeader.StartItems'

interface EndItemsProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
}

const EndItems = React.forwardRef<HTMLDivElement, EndItemsProps>(({ className, children, ...props }, ref) => {
  return (
    <div ref={ref} className={clsx(styles.itemGroup, className)} {...props}>
      {children}
    </div>
  )
})

EndItems.displayName = 'PageHeader.EndItems'

/**
 * A compound component for creating flexible page headers.
 * The title is required, the start and end items are optional.
 *
 * @example
 * ```tsx
 * <PageHeader.Root>
 *   <PageHeader.StartItems>
 *     <PageHeader.Title>Page Title</PageHeader.Title>
 *        <div>asdf@asdf.com</div>
 *        <div>To review</div>
 *     </PageHeader.StartItems>
 *   <PageHeader.EndItems>
 *     <button>Share</button>
 *     <button>More</button>
 *   </PageHeader.EndItems>
 * </PageHeader.Root>
 * ```
 */
export const PageHeader = {
  Root,
  Title,
  StartItems,
  EndItems,
}
