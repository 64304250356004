import { DEFAULT_SKIN } from 'core/skin/skin'
import React from 'react'
import { Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'
import { px } from '../../../core'
import { PieChart as PieChartVisual } from '../../../selectors/reports'

const WIDTH = '100%'
const HEIGHT = 200
const PIE_MARGIN = { top: 10 }
const LEGEND_STYLE = { bottom: px(-15) }

interface Props {
  content: PieChartVisual
}

const Component = (props: Props) => {
  const palette = [
    DEFAULT_SKIN.darkRed,
    DEFAULT_SKIN.darkGreen,
    DEFAULT_SKIN.darkSilver,
    DEFAULT_SKIN.darkBlue,
    DEFAULT_SKIN.darkOrange,
    DEFAULT_SKIN.darkSky,
  ]

  const data = props.content.slices.map((slice, i) => ({
    ...slice,
    fill: palette[i % palette.length],
  }))

  return (
    <ResponsiveContainer width={WIDTH} height={HEIGHT}>
      <PieChart margin={PIE_MARGIN}>
        <Pie
          isAnimationActive={false}
          data={data}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          innerRadius={0}
          outerRadius={60}
          paddingAngle={1}
          minAngle={5}
          label
        />
        <Tooltip />
        <Legend wrapperStyle={LEGEND_STYLE} />
      </PieChart>
    </ResponsiveContainer>
  )
}

export default Component
Component.displayName = 'PieChart'
