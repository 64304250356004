/**
 * Replaces variable values in a template string
 * @param str
 * @param variables
 * @returns
 */
export function interpolateVariables(str: string | undefined, variables: Record<string, string | number | boolean>) {
  if (typeof str !== 'string') {
    return ''
  }
  let interpolatedStr = str
  for (const [key, value] of Object.entries(variables)) {
    interpolatedStr = interpolatedStr.replace(new RegExp(`{${key}}`, 'g'), value.toString())
  }
  return interpolatedStr
}
