import { TopBar as TopbbarComponent } from '@common/components'
import React from 'react'
import * as tracking from '../../core/track'

interface TopBarProps {
  /** @deprecated */
  title?: string
  /** @deprecated */
  menu?: string
}

/** @deprecated use common TopBar instead */
const TopBar: React.FC<TopBarProps> = ({ title, menu }) => {
  return <TopbbarComponent tracking={tracking} />
}

export default TopBar
