import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../../components/Modal'
import TextField from '../../components/TextField'
import { Content, ContentAndButtons, Form } from '../../components/Wizard'
import Buttons from '../../components/Wizard/Buttons'
import { Subheading } from '../../components/Wizard/Typography'
import { rem, style } from '../../core'
import * as selectors from '../../selectors'
import { RootState } from '../../store'
import * as slice from '../../store/openjobroles'

interface Props {
  open: boolean
  roleId: string
  setOpen: (b: boolean) => void
}

const Container = style()
  .size({
    maxWidth: rem(40),
    maxHeight: rem(25),
    width: 'calc(100vw - 200px)',
    height: rem(22.25),
  })
  .element()

const Component = (props: Props) => {
  const dispatch = useDispatch()

  const [initInternalTitle, initPublicTitle, loading]: [string, string, boolean] = useSelector((state: RootState) => {
    const role = selectors.roles.findById(state, props.roleId)
    return [role?.fields.name, role?.fields.public_name, state.openjobroles.isLoading]
  })

  const [internalTitle, setInternalTitle] = useState('')
  const [publicTitle, setPublicTitle] = useState('')
  const [copyInternalNameToPublic, setCopyPublicNameToInternal] = useState(initInternalTitle === initPublicTitle)

  const updated = initInternalTitle !== internalTitle || initPublicTitle !== publicTitle

  useEffect(() => {
    setCopyPublicNameToInternal(initInternalTitle === initPublicTitle)
  }, [props.roleId, props.open])

  useEffect(() => {
    if (initInternalTitle) {
      setInternalTitle(initInternalTitle)
    }
    if (initPublicTitle) {
      setPublicTitle(initPublicTitle)
    }
  }, [initInternalTitle, initPublicTitle])

  return (
    <Modal title="Update Job Titles" open={props.open} setOpen={props.setOpen}>
      <Container>
        <ContentAndButtons>
          <Content standalone>
            <Subheading>
              Every job has an internal title shown to you and your colleagues, and a public title shown to candidates
              and references.
            </Subheading>
            <Form>
              <TextField
                label="🔍 Internal job title"
                value={internalTitle}
                placeholder="Enter the job title for you and your colleagues. e.g Senior Product Manager"
                onChange={e => {
                  setInternalTitle(e.target.value)
                  setCopyPublicNameToInternal(false)
                }}
              />
              <TextField
                label="🌍 Public job title"
                value={publicTitle}
                placeholder="Enter the job title to be shown to candidates and references."
                onChange={e => {
                  setPublicTitle(e.target.value)
                  if (copyInternalNameToPublic) {
                    setInternalTitle(e.target.value)
                  }
                }}
              />
            </Form>
          </Content>
          <Buttons
            next={() => {
              updated ? dispatch(slice.updateTitles(props.roleId, internalTitle, publicTitle)) : props.setOpen(false)
            }}
            label={updated ? 'Update' : 'Done'}
            loading={loading}
            back={() => {}}
            disabled={!internalTitle || !publicTitle}
            forwardOnly
          ></Buttons>
        </ContentAndButtons>
      </Container>
    </Modal>
  )
}

export default Component
Component.displayName = 'TitlesModal'
