import { listing, questions } from 'api/index'
import { get } from './methods'
import { QuestionResponseType } from './questions'
import { APIResponse, Entity } from './request'

export const RESOURCE = 'modules'

// TODO: delete
type ProductModuleTypes = 'reference-check' | 'onboarding' | 'candidate-experience' | 'self-assessment'

// TODO: delete
enum AssessmentModules {
  'self-assessment',
  'hard-skills',
  'bfas',
  'hexaco',
  'disc',
  'mindset',
  'cognitive-abilities',
  'soft-skills',
  'mini-ipip',
  'culture-add',
  'candidate-centered',
  'knowledge-test',
}

// TODO: delete
type AssessmentModuleTypes = keyof typeof AssessmentModules

// TODO: delete
export type ModuleType = ProductModuleTypes | AssessmentModuleTypes

export interface Fields {
  id: string
  slug: string
  version: number
  locale: string
  name: string
  description: string
  group_id?: string
  inventory_id?: string
  copy: {
    title?: string
    emoji?: string
    is_scorable?: boolean
    description?: string
    score_card?: {
      low_caption: string
      low_explanation: string
      high_caption: string
      high_explanation: string
      low_behavior_example?: string
      high_behavior_example?: string
    }
    preview_duration?: string
    preview_example_question?: string
    preview_introduction?: string
    preview_response_type?: QuestionResponseType
    preview_result_description?: string
    preview_when_to_use?: string
    feedback?: {
      growth: string
      strength: string
      learnings: {
        image: string
        site: string
        title: string
        url: string
      }[]
    }
    interpretation?: {
      high: string
      above_average: string
      average: string
      below_average: string
      low: string
    }
  }
  is_selectable: boolean
  exclude_from_summary: boolean
  created_at: number
  updated_at: number
  is_active: boolean
  properties: {
    boundaries?: number[]
    overlapped_by?: string[]
    time_allowed_secs?: number
  }
  type: ModuleType
}

export type ModuleEntity = Entity<Fields>

function list(options?: listing.Options): Promise<APIResponse<Entity<Fields>[]>> {
  return listing.list<Fields>(`modules`, options || {})
}

// TODO: Delete me
function listByType(type: ModuleType): Promise<APIResponse<ModuleEntity[]>> {
  return list({
    filters: [listing.Eq('product', type)],
    limit: 100,
    include: [questions.RESOURCE],
    sort: [
      {
        field: 'slug',
        order: listing.SortOrder.Asc,
      },
    ],
  })
}

export function listReferenceCheckModules(): Promise<APIResponse<ModuleEntity[]>> {
  return listByType('reference-check')
}

export function bySlug(slug: string): Promise<APIResponse<ModuleEntity[]>> {
  return get(`modules?slug[eq]=${slug}&include=questions`)
}

export function byId(id: string): Promise<APIResponse<ModuleEntity>> {
  return get(`modules/${id}?include=questions,response_options`)
}
