import * as api from 'api'
import { formresponses } from 'api'
import { Entity } from 'api/request'
import { TestimonyType } from 'App/Candidate/Summary/TestimonialBlock'
import sbd from 'sbd'
import { en, removeStopwords } from 'stopword'
import { assessmentVersions, candidateProfiles, formResponses, percentiles, responseOptions } from '.'
import { RootState } from '../store'
import {
  AREAS_OF_IMPROVEMENT,
  CONTENT_ID_SLUG_LOOKUP,
  CULTURE_ADD_QUESTION,
  ENCOURAGED_REEVALUATION_WORKFLOW_QUESTION,
  EVERYONE_KNOWS_WHAT_TO_DO_QUESTION,
  GENERAL_IMPRESSION,
  GREAT_RESULTS_GREAT_VIBES_QUESTION,
  GROWTH_HINTS_QUESTION,
  HELP_OTHERS_ACCOMPLISH_WORK_QUESTION,
  KEY_ACCOMPLISHMENTS_QUESTION,
  LEADERSHIP_SKILL_QUESTION,
  LOOKS_AFTER_INTERESTS_THINKS_OTHERS_FIRST_QUESTION,
  NEED_PRIVACY_OTHER_PEOPLE_LAUGH_QUESTION,
  OLD_AREAS_OF_IMPROVEMENT,
  OLD_OVERALL_PERFORMANCE_QUESTION,
  OVERALL_PERFORMANCE_QUESTION,
  QUESTION_SLUGS,
  REASONS_FOR_LEAVING_QUESTION,
  RECOGNISED_IMPORTANT_CONTRIBUTIONS_QUESTION,
  RECOMMENDATION_OF_HIRE_QUESTION,
  SHOWED_CONSIDERATION_FEELINGS_QUESTION,
  SOUND_COMPELLING_VISION_QUESTION,
  STRENGTHS_QUESTION,
  TELLING_OTHERS_NO_REASSURING_OTHERS_QUESTION,
  WORKING_WITH_OTHERS_QUESTION,
  WORK_STYLE_QUESTION,
} from '../store/new-role/content'
import * as assessmentversions from './assessment-versions'
import * as candidates from './candidates'
import { findAnswerByReferenceId, findSelectedOptionsByReferenceId } from './form-responses'
import * as questionnaireAnswers from './questionnaire-answers'
import * as questions from './questions'
import * as references from './references'
import * as skills from './skills'

export function findRecommendationSummaryByCandidateId(
  state: RootState,
  candidateId: string,
): {
  strongRecommendation: number
  recommendation: number
  uncertainOrNoRecommendation: number
} {
  let strongRecommendation = 0
  let recommendation = 0
  let uncertainOrNoRecommendation = 0

  const candidate = candidates.findById(state, candidateId)
  if (!candidate) return { strongRecommendation, recommendation, uncertainOrNoRecommendation }

  if (candidate.fields.questionnaire_id) {
    return findRDSRecommendationSummaryByCandidateId(state, candidateId)
  }

  const submitted = references.findRespondedByCandidateIdExceptSelf(state, candidateId)

  for (const reference of submitted) {
    const answer = findAnswerByReferenceId(state, reference.id, RECOMMENDATION_OF_HIRE_QUESTION.test_item_id)

    const answerValue = answer?.value
    if (typeof answerValue !== 'number') {
      continue
    }

    if (answerValue > 8) {
      strongRecommendation += 1
    } else if (answerValue > 5) {
      recommendation += 1
    } else {
      uncertainOrNoRecommendation += 1
    }
  }

  return { strongRecommendation, recommendation, uncertainOrNoRecommendation }
}

function findRDSRecommendationSummaryByCandidateId(
  state: RootState,
  candidateId: string,
): {
  strongRecommendation: number
  recommendation: number
  uncertainOrNoRecommendation: number
} {
  const hiringRecommendationQuestions = questions.findByModuleSlug(state, candidateId, 'hiring-recommendations') || []
  const hiringRecommendationQuestion = hiringRecommendationQuestions.find(q => !q.fields.parent_id)

  let strongRecommendation = 0
  let recommendation = 0
  let uncertainOrNoRecommendation = 0

  if (!hiringRecommendationQuestion) {
    return {
      strongRecommendation,
      recommendation,
      uncertainOrNoRecommendation,
    }
  }

  const submitted = references.findRespondedByCandidateIdExceptSelf(state, candidateId)

  for (const reference of submitted) {
    const answers = questionnaireAnswers.findAnswerByReferenceId(state, reference.id, hiringRecommendationQuestion.id)

    const pickedAnswer = answers?.find(answer => answer.selected_response_option)
    if (!pickedAnswer) {
      continue
    }

    const responseOption = responseOptions.findById(state, pickedAnswer.selected_response_option)
    if (!responseOption) {
      continue
    }

    const answerValue = responseOption.fields.value

    if (answerValue > 8) {
      strongRecommendation += 1
    } else if (answerValue > 5) {
      recommendation += 1
    } else {
      uncertainOrNoRecommendation += 1
    }
  }

  return {
    strongRecommendation,
    recommendation,
    uncertainOrNoRecommendation,
  }
}

export function findGeneralImpressionsTestimoniesByCandidateId(
  state: RootState,
  candidateId: string,
): TestimonyType[] | undefined {
  const candidate = candidates.findById(state, candidateId)
  if (!candidate) return

  if (candidate.fields.questionnaire_id) {
    return findRDSTestimoniesByCandidateId(
      state,
      candidateId,
      WORK_STYLE_QUESTION.test_item_id,
      GENERAL_IMPRESSION.test_item_id,
    )
  }

  const profile = candidateProfiles.findByCandidateId(state, candidateId)
  if (!profile) return

  const submitted = references.findRespondedByCandidateIdExceptSelf(state, candidateId)

  const feedback: { feedback: string; referenceId: string }[] = []

  for (const reference of submitted) {
    const workStyle = findAnswerByReferenceId(state, reference.id, WORK_STYLE_QUESTION.test_item_id)

    if (workStyle?.input) {
      feedback.push({
        referenceId: reference.id,
        feedback: workStyle.input,
      })
    }

    const generalImpression = findAnswerByReferenceId(state, reference.id, GENERAL_IMPRESSION.test_item_id)

    if (generalImpression?.input) {
      feedback.push({
        referenceId: reference.id,
        feedback: generalImpression.input,
      })
    }
  }

  const acceptable = findAllAcceptableBlurbs(candidateProfiles.firstNameOf(profile), feedback)
  if (!acceptable || !acceptable.length) return

  return acceptable.map(blurb => ({
    name: references.findById(state, blurb.referenceId)?.fields.full_name || '',
    quote: blurb.blurb,
  }))
}

function findRDSTestimoniesByCandidateId(state: RootState, candidateId: string, ...contentIds: string[]) {
  const profile = candidateProfiles.findByCandidateId(state, candidateId)
  if (!profile) return

  const submitted = references.findRespondedByCandidateIdExceptSelf(state, candidateId)

  const feedback: { feedback: string; referenceId: string }[] = []

  for (const reference of submitted) {
    for (const content of contentIds) {
      const moduleSlug = CONTENT_ID_SLUG_LOOKUP[content]
      if (!moduleSlug) continue

      const questionsInModules = questions.findByModuleSlug(state, candidateId, moduleSlug)
      if (!questionsInModules) continue

      for (const question of questionsInModules) {
        if (question.fields.conditions) continue

        const answers = questionnaireAnswers.findAnswerByReferenceId(state, reference.id, question.id)

        if (!answers) continue

        for (const ans of answers) {
          feedback.push({
            referenceId: reference.id,
            feedback: ans.text_input,
          })
        }
      }
    }
  }

  const acceptable = findAllAcceptableBlurbs(candidateProfiles.firstNameOf(profile), feedback)
  if (!acceptable || !acceptable.length) return

  const uniqueAcceptable: {
    referenceId: string
    blurb: string
  }[] = []
  const uniqueResponses = new Set()

  for (const accepted of acceptable) {
    const key = `${accepted.referenceId}/${accepted.blurb}`
    if (uniqueResponses.has(key)) {
      continue
    }
    uniqueResponses.add(key)

    uniqueAcceptable.push({
      referenceId: accepted.referenceId,
      blurb: accepted.blurb,
    })
  }

  return uniqueAcceptable.map(blurb => ({
    name: references.findById(state, blurb.referenceId)?.fields.full_name || '',
    quote: blurb.blurb,
  }))
}

export function findKeyAcheivementsTestimoniesByCandidateId(
  state: RootState,
  candidateId: string,
): TestimonyType[] | undefined {
  const candidate = candidates.findById(state, candidateId)
  if (!candidate) return

  if (candidate.fields.questionnaire_id) {
    return findRDSTestimoniesByCandidateId(state, candidateId, KEY_ACCOMPLISHMENTS_QUESTION.test_item_id)
  }

  const profile = candidateProfiles.findByCandidateId(state, candidateId)
  if (!profile) return

  const submitted = references.findRespondedByCandidateIdExceptSelf(state, candidateId)

  const feedback: { feedback: string; referenceId: string }[] = []

  for (const reference of submitted) {
    const keyAcheivements = findAnswerByReferenceId(state, reference.id, KEY_ACCOMPLISHMENTS_QUESTION.test_item_id)

    if (keyAcheivements?.input) {
      feedback.push({
        referenceId: reference.id,
        feedback: keyAcheivements.input,
      })
    }
  }

  const acceptable = findAllAcceptableBlurbs(candidateProfiles.firstNameOf(profile), feedback)
  if (!acceptable || !acceptable.length) return

  return acceptable.map(blurb => ({
    name: references.findById(state, blurb.referenceId)?.fields.full_name || '',
    quote: blurb.blurb,
  }))
}

export function findGrowthHintsTestimoniesByCandidateId(
  state: RootState,
  candidateId: string,
): TestimonyType[] | undefined {
  const candidate = candidates.findById(state, candidateId)
  if (!candidate) return

  if (candidate.fields.questionnaire_id) {
    return findRDSTestimoniesByCandidateId(state, candidateId, GROWTH_HINTS_QUESTION.test_item_id)
  }

  const profile = candidateProfiles.findByCandidateId(state, candidateId)
  if (!profile) return

  const submitted = references.findRespondedByCandidateIdExceptSelf(state, candidateId)

  const feedback: { feedback: string; referenceId: string }[] = []

  for (const reference of submitted) {
    const growthHints = findAnswerByReferenceId(state, reference.id, GROWTH_HINTS_QUESTION.test_item_id)

    if (growthHints?.input) {
      feedback.push({
        referenceId: reference.id,
        feedback: growthHints.input,
      })
    }
  }

  const acceptable = findAllAcceptableBlurbs(candidateProfiles.firstNameOf(profile), feedback)
  if (!acceptable || !acceptable.length) return

  return acceptable.map(blurb => ({
    name: references.findById(state, blurb.referenceId)?.fields.full_name || '',
    quote: blurb.blurb,
  }))
}

export type CultureAddAttributes = 'innovative' | 'results-driven' | 'detail-oriented' | 'collaborative'

export function findCultureAddSummaryExceptSelf(state: RootState, candidateId: string): CultureAddAttributes | null {
  const refs = references.findRespondedByCandidateIdExceptSelf(state, candidateId)
  if (!refs || !refs.length) return null

  return findCultureAddSummary(state, candidateId, refs)
}

export function findCultureAddSummaryOnlySelf(state: RootState, candidateId: string): CultureAddAttributes | null {
  const refs = references.findRespondedByCandidateIdOnlySelf(state, candidateId)
  if (!refs || !refs.length) return null

  return findCultureAddSummary(state, candidateId, refs)
}

function findCultureAddSummary(
  state: RootState,
  candidateId: string,
  references: Entity<api.references.Fields>[],
): CultureAddAttributes | null {
  const candidate = candidates.findById(state, candidateId)
  if (!candidate) return null

  if (candidate.fields.questionnaire_id) {
    return calculateRDSResultsCultureScores(state, references)
  }
  const asked = assessmentversions.findAskedQuestionsByCandidateId(
    state,
    candidateId,
    GREAT_RESULTS_GREAT_VIBES_QUESTION.test_item_id,
    CULTURE_ADD_QUESTION.test_item_id,
  )

  if (!asked.length) return null

  const resultsVibesAnswers: number[] = []
  const cultureAddAnswers: number[] = []
  for (const reference of references) {
    const resultsVibes = findAnswerByReferenceId(state, reference.id, GREAT_RESULTS_GREAT_VIBES_QUESTION.test_item_id)

    const cultureAdd = findAnswerByReferenceId(state, reference.id, CULTURE_ADD_QUESTION.test_item_id)

    // if either are skipped, discard that specific reference
    if (
      resultsVibes?.skipped ||
      resultsVibes?.value === undefined ||
      cultureAdd?.skipped ||
      cultureAdd?.value === undefined
    ) {
      continue
    }

    resultsVibesAnswers.push(resultsVibes?.value || 0)
    cultureAddAnswers.push(cultureAdd?.value || 0)
  }

  if (!cultureAddAnswers.length || !resultsVibesAnswers.length) return null

  let sumResultsVibesAnswers = 0
  for (const answer of resultsVibesAnswers) {
    sumResultsVibesAnswers += answer
  }

  let sumCultureAddAnswers = 0
  for (const answer of cultureAddAnswers) {
    sumCultureAddAnswers += answer
  }

  return calculateCultureAddSummary(
    sumResultsVibesAnswers / resultsVibesAnswers.length,
    sumCultureAddAnswers / cultureAddAnswers.length,
  )
}

function calculateRDSResultsCultureScores(
  state: RootState,
  references: Entity<api.references.Fields>[],
): CultureAddAttributes | null {
  const resultsVibesAnswers: number[] = []
  const cultureAddAnswers: number[] = []
  for (const reference of references) {
    const resultsVibes = questionnaireAnswers.findSlugSelectedOptionsByReferenceId(
      state,
      reference.id,
      'results-vibes',
    )?.[0]

    const cultureAdd = questionnaireAnswers.findSlugSelectedOptionsByReferenceId(
      state,
      reference.id,
      'culture-add',
    )?.[0]

    // if either are skipped, discard that specific reference
    if (resultsVibes?.fields.value === undefined || cultureAdd?.fields.value === undefined) {
      continue
    }

    resultsVibesAnswers.push(resultsVibes.fields.value)
    cultureAddAnswers.push(cultureAdd.fields.value)
  }

  if (!cultureAddAnswers.length || !resultsVibesAnswers.length) return null

  let sumResultsVibesAnswers = 0
  for (const answer of resultsVibesAnswers) {
    sumResultsVibesAnswers += answer
  }

  let sumCultureAddAnswers = 0
  for (const answer of cultureAddAnswers) {
    sumCultureAddAnswers += answer
  }

  return calculateCultureAddSummary(
    sumResultsVibesAnswers / resultsVibesAnswers.length,
    sumCultureAddAnswers / cultureAddAnswers.length,
  )
}

function calculateCultureAddSummary(resultsVibesAverage: number, cultureAddAverage: number): CultureAddAttributes {
  // the midPoint denotes where in our scoring a reference would flip between
  // our four quadrants - Exploration, Teamwork, Results and Optimisation
  // our questions have values of 0-10, so you can imagine a four quadrant system
  // where 5 is the axis.
  const midPoint = 5
  if (cultureAddAverage >= midPoint && resultsVibesAverage >= midPoint) {
    return 'innovative'
  }

  if (cultureAddAverage < midPoint && resultsVibesAverage > midPoint) {
    return 'collaborative'
  }

  if (cultureAddAverage < midPoint && resultsVibesAverage < midPoint) {
    return 'detail-oriented'
  }

  return 'results-driven'
}

function calculateAgreeablenessExtraversionScores(
  state: RootState,
  candidateId: string,
  refs: Entity<api.references.Fields>[],
): { extraversion: number; agreeableness: number } | null {
  const candidate = candidates.findById(state, candidateId)
  if (!candidate) return null

  if (candidate.fields.questionnaire_id) {
    return calculateRDSAgreeablenessExtraversionScores(state, refs)
  }

  const asked = assessmentversions.findAskedQuestionsByCandidateId(
    state,
    candidateId,
    LOOKS_AFTER_INTERESTS_THINKS_OTHERS_FIRST_QUESTION.test_item_id,
    TELLING_OTHERS_NO_REASSURING_OTHERS_QUESTION.test_item_id,
  )

  if (!asked.length) return null

  const extraversionAverages: number[] = []
  const agreeablenessAverages: number[] = []
  for (const reference of refs) {
    const looksAfterInterest = findAnswerByReferenceId(
      state,
      reference.id,
      LOOKS_AFTER_INTERESTS_THINKS_OTHERS_FIRST_QUESTION.test_item_id,
    )

    const tellingOthersNo = findAnswerByReferenceId(
      state,
      reference.id,
      TELLING_OTHERS_NO_REASSURING_OTHERS_QUESTION.test_item_id,
    )

    // only factor in agreeableness for this reference if both scores are present
    if (
      !looksAfterInterest?.skipped &&
      looksAfterInterest?.value !== undefined &&
      !tellingOthersNo?.skipped &&
      tellingOthersNo?.value !== undefined
    ) {
      const agreeablenessScore = ((looksAfterInterest?.value || 0) + (tellingOthersNo?.value || 0)) / 2
      agreeablenessAverages.push(agreeablenessScore)
    }

    const needsPrivacy = findAnswerByReferenceId(
      state,
      reference.id,
      NEED_PRIVACY_OTHER_PEOPLE_LAUGH_QUESTION.test_item_id,
    )

    const workingWithOthers = findAnswerByReferenceId(state, reference.id, WORKING_WITH_OTHERS_QUESTION.test_item_id)

    // only factor in extraversion for this reference if both scores are present
    if (
      !needsPrivacy?.skipped &&
      needsPrivacy?.value !== undefined &&
      !workingWithOthers?.skipped &&
      workingWithOthers?.value !== undefined
    ) {
      const extraversionScore = ((needsPrivacy?.value || 0) + (workingWithOthers?.value || 0)) / 2
      extraversionAverages.push(extraversionScore)
    }
  }

  if (!agreeablenessAverages.length || !extraversionAverages.length) return null

  let sumAgreeablenessAnswers = 0
  for (const answer of agreeablenessAverages) {
    sumAgreeablenessAnswers += answer
  }

  let sumExtraversionAnswers = 0
  for (const answer of extraversionAverages) {
    sumExtraversionAnswers += answer
  }

  return {
    agreeableness: sumAgreeablenessAnswers / agreeablenessAverages.length,
    extraversion: sumExtraversionAnswers / extraversionAverages.length,
  }
}

function calculateRDSAgreeablenessExtraversionScores(
  state: RootState,
  refs: Entity<api.references.Fields>[],
): { extraversion: number; agreeableness: number } | null {
  const extraversionAverages: number[] = []
  const agreeablenessAverages: number[] = []
  for (const reference of refs) {
    const looksAfterInterest = questionnaireAnswers.findSlugSelectedOptionsByReferenceId(
      state,
      reference.id,
      'self-versus-others',
    )?.[0]

    const tellingOthersNo = questionnaireAnswers.findSlugSelectedOptionsByReferenceId(
      state,
      reference.id,
      'rejection-reassurance',
    )?.[0]

    // only factor in agreeableness for this reference if both scores are present
    if (looksAfterInterest?.fields?.value !== undefined && tellingOthersNo?.fields?.value !== undefined) {
      const agreeablenessScore = (looksAfterInterest.fields.value + tellingOthersNo.fields.value) / 2
      agreeablenessAverages.push(agreeablenessScore)
    }

    const needsPrivacy = questionnaireAnswers.findSlugSelectedOptionsByReferenceId(
      state,
      reference.id,
      'privacy-others-laughing',
    )?.[0]

    const workingWithOthers = questionnaireAnswers.findSlugSelectedOptionsByReferenceId(
      state,
      reference.id,
      'working-with-others',
    )?.[0]

    // only factor in extraversion for this reference if both scores are present
    if (needsPrivacy?.fields?.value !== undefined && workingWithOthers?.fields?.value !== undefined) {
      const extraversionScore = (needsPrivacy.fields.value + workingWithOthers.fields.value) / 2
      extraversionAverages.push(extraversionScore)
    }
  }

  if (!agreeablenessAverages.length || !extraversionAverages.length) return null

  let sumAgreeablenessAnswers = 0
  for (const answer of agreeablenessAverages) {
    sumAgreeablenessAnswers += answer
  }

  let sumExtraversionAnswers = 0
  for (const answer of extraversionAverages) {
    sumExtraversionAnswers += answer
  }

  return {
    agreeableness: sumAgreeablenessAnswers / agreeablenessAverages.length,
    extraversion: sumExtraversionAnswers / extraversionAverages.length,
  }
}

export type WorkingWithOthersChartAttribute =
  | 'engage'
  | 'lead'
  | 'direct'
  | 'balance'
  | 'control'
  | 'cooperate'
  | 'consider'
  | 'connect'

export function findWorkingWithOthersChartAttributeExceptSelf(
  state: RootState,
  candidateId: string,
): WorkingWithOthersChartAttribute | null {
  const refs = references.findRespondedByCandidateIdExceptSelf(state, candidateId)
  if (!refs || !refs.length) return null

  return findWorkingWithOthersChartAttribute(state, candidateId, refs)
}

export function findWorkingWithOthersChartAttributeOnlySelf(
  state: RootState,
  candidateId: string,
): WorkingWithOthersChartAttribute | null {
  const refs = references.findRespondedByCandidateIdOnlySelf(state, candidateId)
  if (!refs || !refs.length) return null

  return findWorkingWithOthersChartAttribute(state, candidateId, refs)
}

function findWorkingWithOthersChartAttribute(
  state: RootState,
  candidateId: string,
  references: Entity<api.references.Fields>[],
): WorkingWithOthersChartAttribute | null {
  const scores = calculateAgreeablenessExtraversionScores(state, candidateId, references)
  if (!scores) return null

  const EXTRAVERSION_STANDARD_DEVIATION = 1.267
  const AGREEABLENESS_STANDARD_DEVIATION = 1.219

  const EXTRAVERSION_POPULATION_MEAN = 4.967
  const AGREEABLENESS_POPULATION_MEAN = 5.93

  // Build z-score for candidate_score_agreeableness: z_score_agreeableness = (candidate_score_agreeableness - 4.967) / 1.267
  const zScoreExtraversion = (scores.extraversion - EXTRAVERSION_POPULATION_MEAN) / EXTRAVERSION_STANDARD_DEVIATION

  // Build z-score for candidate_score_extraversion: z_score_extraversion = (candidate_score_extraversion - 5.93) / 1.219
  const zScoreAgreeableness = (scores.agreeableness - AGREEABLENESS_POPULATION_MEAN) / AGREEABLENESS_STANDARD_DEVIATION

  // this reassignment is just an aid for me for plotting, we can remove
  // it if you like.
  let x = zScoreAgreeableness
  let y = zScoreExtraversion

  const MAX_Z_SCORE = 2
  const MIN_Z_SCORE = -2

  x = x > MAX_Z_SCORE ? MAX_Z_SCORE : x
  x = x < MIN_Z_SCORE ? MIN_Z_SCORE : x

  y = y > MAX_Z_SCORE ? MAX_Z_SCORE : y
  y = y < MIN_Z_SCORE ? MIN_Z_SCORE : y

  // ifelse(x > 0 & y > 0 & abs(x) > abs(y), "Engage",
  if (x > 0 && y > 0 && Math.abs(x) > Math.abs(y)) {
    return 'engage'
  }

  // ifelse(x > 0 & y > 0 & abs(x) < abs(y), "Lead"
  if (x > 0 && y > 0 && Math.abs(x) < Math.abs(y)) {
    return 'lead'
  }

  // ifelse(x < 0 & y > 0 & abs(x) > abs(y), "Balance",
  if (x < 0 && y > 0 && Math.abs(x) > Math.abs(y)) {
    return 'balance'
  }

  // ifelse(x < 0 & y > 0 & abs(x) < abs(y), "Direct",
  if (x < 0 && y > 0 && Math.abs(x) < Math.abs(y)) {
    return 'direct'
  }

  // ifelse(x < 0 & y < 0 & abs(x) < abs(y), "Cooperate",
  if (x < 0 && y < 0 && Math.abs(x) < Math.abs(y)) {
    return 'cooperate'
  }

  // ifelse(x < 0 & y < 0 & abs(x) > abs(y), "Control",
  if (x < 0 && y < 0 && Math.abs(x) > Math.abs(y)) {
    return 'control'
  }

  //ifelse(x > 0 & y < 0 & abs(x) > abs(y), "Connect",
  if (x > 0 && y < 0 && Math.abs(x) > Math.abs(y)) {
    return 'connect'
  }

  return 'consider'
}

function findAllAcceptableBlurbs(
  name: string,
  sources: { feedback: string; referenceId: string }[],
): { referenceId: string; blurb: string }[] | undefined {
  const MINIMUM_SCORE_THRESHOLD = 40
  const sentences: { referenceId: string; text: string; score: number }[] = []

  for (const source of sources) {
    for (const sentence of sbd.sentences(source.feedback)) {
      sentences.push({
        referenceId: source.referenceId,
        text: sentence.trim(),
        score: 0,
      })
    }
  }

  if (!sentences.length) return

  for (const sentence of sentences) {
    if (startsWithName(name, sentence.text)) {
      sentence.score += 25
    }

    if (appropriateLength(sentence.text)) {
      sentence.score += 25
    }

    if (containsName(name, sentence.text)) {
      sentence.score += 15
    }

    sentence.score += (1 - stopWordRatio(sentence.text)) * 20
    sentence.score += uniqueWordRatio(sentence.text) * 15
  }

  return sentences
    .sort((a, b) => b.score - a.score)
    .filter(sentence => sentence.score > MINIMUM_SCORE_THRESHOLD)
    .map(sentence => ({
      referenceId: sentence.referenceId,
      blurb: normalizeBlurb(sentence.text),
    }))
}

function normalizeBlurb(blurb: string): string {
  if (/[A-Za-z]$/.test(blurb)) {
    return `${blurb}.`
  }

  return blurb
}

function startsWithName(name: string, text: string): boolean {
  const parts = text.split(' ')
  return parts[0].toLowerCase() === name.toLowerCase()
}

function containsName(name: string, text: string): boolean {
  return text.toLowerCase().includes(name.toLowerCase())
}

function stopWordRatio(text: string): number {
  const parts = text.split(' ')
  const stopWordsCount = parts.length - removeStopwords(parts, en).length

  return stopWordsCount / parts.length
}

function uniqueWordRatio(text: string): number {
  const sentence = text.toLowerCase().split(' ')
  const uniqueWordCount = new Set(sentence).size

  return uniqueWordCount / sentence.length
}

function appropriateLength(text: string): boolean {
  const MIN_SENTANCE_CHAR_LENGTH = 40
  const MAX_SENTANCE_CHAR_LENGTH = 160
  const textLength = text.length

  return textLength > MIN_SENTANCE_CHAR_LENGTH && textLength < MAX_SENTANCE_CHAR_LENGTH
}
// We need a lot of information to populate the heatmap
// - org average for right most column
// - average is for the overall column
// - label is for left most column
// - responses object to populate each of the references individual scores
export type HeatmapInformation = {
  [skillId: string]: {
    orgAverage: number | null
    average: number | null
    label: string
    responses: {
      [referenceId: string]: {
        value: number
        caption?: string
      } | null
    }
  }
}

function findRDSSkillsetResponsesByCandidateId(state: RootState, candidateId: string): HeatmapInformation {
  const heatMapInfo: HeatmapInformation = {}

  const refs = references.findRespondedByCandidateIdExceptSelf(state, candidateId)

  if (refs.length === 0) {
    return heatMapInfo
  }

  const skillsetQuestions = questions.findSkillsetQuestionsByCandidateId(state, candidateId)

  if (!skillsetQuestions || skillsetQuestions.length === 0) {
    return heatMapInfo
  }

  for (const skillsetQuestion of skillsetQuestions) {
    const skill = skills.findByQuestionId(state, skillsetQuestion.id)

    heatMapInfo[skillsetQuestion.id] = {
      orgAverage: percentiles.findAverageOrgSkillScore(state, candidateId, skillsetQuestion.id) || null,
      label: skill?.fields.heading || skillsetQuestion.copy.heading || '',
      responses: {},
      average: null,
    }

    let sum = 0
    let answerCount = 0

    for (const ref of refs) {
      const selectedOptionByRef = questionnaireAnswers.findSelectedOptionsByReferenceId(
        state,
        ref.id,
        skillsetQuestion.id,
      )?.[0]

      if (!selectedOptionByRef || selectedOptionByRef.fields?.copy?.caption === 'Not Applicable') {
        continue
      }

      const value = selectedOptionByRef?.fields.value
      if (typeof value === 'number') {
        sum += value
        answerCount += 1
      }

      heatMapInfo[skillsetQuestion.id].responses[ref.id] = { value }
    }

    heatMapInfo[skillsetQuestion.id].average = answerCount > 0 ? sum / answerCount : 0
  }

  return heatMapInfo
}

export function findSkillsetResponsesByCandidateId(state: RootState, candidateId: string): HeatmapInformation {
  const submitted = references.findRespondedByCandidateIdExceptSelf(state, candidateId)
  if (!submitted) return {}

  const candidate = candidates.findById(state, candidateId)
  if (!candidate) return {}

  if (candidate.fields.questionnaire_id) {
    return findRDSSkillsetResponsesByCandidateId(state, candidateId)
  }

  const heatMapInfo: HeatmapInformation = {}

  // We have to get the skills from the assessmentVersion, because we want to
  // show not just the skills that references responded to, but all the different
  // skill questions asked
  const skills = assessmentversions.findSkillsetQuestionsByCandidateId(state, candidateId)
  if (!skills) return {}

  for (const contentId of skills) {
    heatMapInfo[contentId] = {
      orgAverage: percentiles.findAverageOrgSkillScore(state, candidateId, contentId) || null,
      label: assessmentversions.findDashboardView(state, candidateId, contentId)?.title || '',
      responses: {},
      average: null,
    }
  }

  const skillQuestionsAnswersGroupedByItemId: Record<string, formresponses.Row[]> = {}

  for (const reference of submitted) {
    const skillQuestionsAnswers = formResponses.findAllSkillAnswersByReferenceId(state, reference.id, candidateId)

    for (const answer of skillQuestionsAnswers || []) {
      if (answer.value) {
        heatMapInfo[answer.item_id].responses[reference.id] = {
          value: Math.round(answer.value),
        }
      }

      if (skillQuestionsAnswersGroupedByItemId[answer.item_id]) {
        skillQuestionsAnswersGroupedByItemId[answer.item_id].push(answer)
      } else {
        skillQuestionsAnswersGroupedByItemId[answer.item_id] = [answer]
      }
    }
  }

  Object.entries(skillQuestionsAnswersGroupedByItemId).forEach(([itemId, answers]) => {
    heatMapInfo[itemId].average = getAverageValue(answers)
  })

  return heatMapInfo
}

function getAverageValue(skillQuestionsAnswers: formresponses.Row[] | undefined) {
  const validAnswers = (skillQuestionsAnswers || [])
    .map(answer => answer.value)
    .filter(value => typeof value === 'number') as number[]
  const sumOfNormalizedValues = validAnswers.map(Math.round).reduce((sum: number, value) => sum + (value || 0), 0)
  return sumOfNormalizedValues / validAnswers.length
}

function findRDSOverallPerformanceResponsesByCandidateId(
  state: RootState,
  candidateId: string,
): HeatmapInformation | undefined {
  const overallPerformanceQuestion = questions.findBySlugCandidateId(state, candidateId, 'overall-performance')?.[0]
  if (!overallPerformanceQuestion) {
    return
  }

  const refs = references.findRespondedByCandidateIdExceptSelf(state, candidateId)
  if (refs.length === 0) {
    return
  }

  const heatMapInfo: HeatmapInformation = {
    [overallPerformanceQuestion.id]: {
      label: overallPerformanceQuestion.fields.copy.heading || '',
      orgAverage: percentiles.findAverageOrgSkillScore(state, candidateId, overallPerformanceQuestion.id) || null,
      average: null,
      responses: {},
    },
  }

  let sum = 0
  let answerCount = 0

  for (const ref of refs) {
    const selectedOptionByRef = questionnaireAnswers.findSlugSelectedOptionsByReferenceId(
      state,
      ref.id,
      'overall-performance',
    )?.[0]

    if (!selectedOptionByRef) {
      continue
    }

    const value = selectedOptionByRef?.fields.value
    if (typeof value === 'number') {
      sum += value
      answerCount += 1
    }

    heatMapInfo[overallPerformanceQuestion.id].responses[ref.id] = {
      value: value,
      caption: selectedOptionByRef?.fields.copy.caption || '',
    }
  }

  heatMapInfo[overallPerformanceQuestion.id].average = answerCount > 0 ? sum / answerCount : 0

  return heatMapInfo
}

export function findOverallPerformanceResponsesByCandidateId(
  state: RootState,
  candidateId: string,
): HeatmapInformation | undefined {
  const submitted = references.findRespondedByCandidateIdExceptSelf(state, candidateId)
  if (!submitted) return

  const candidate = candidates.findById(state, candidateId)
  if (!candidate) return

  if (candidate.fields.questionnaire_id) {
    return findRDSOverallPerformanceResponsesByCandidateId(state, candidateId)
  }

  const askedOverallPerformance = assessmentVersions.findAskedQuestionsByCandidateId(
    state,
    candidateId,
    OVERALL_PERFORMANCE_QUESTION.test_item_id,
    OLD_OVERALL_PERFORMANCE_QUESTION.test_item_id,
  )
  if (!askedOverallPerformance.length) return
  // this is to consider that we have both a new and old performance question
  // that both are still actively used.
  const askedOverallPerformanceContentId = askedOverallPerformance[0]

  const heatMapInfo: HeatmapInformation = {
    [askedOverallPerformanceContentId]: {
      label:
        assessmentversions.findDashboardView(state, candidateId, OVERALL_PERFORMANCE_QUESTION.test_item_id)?.title ||
        '',
      orgAverage: percentiles.findAverageOrgOverallPerformanceScore(state, candidateId) || null,
      average: null,
      responses: {},
    },
  }

  const overallPerformanceQuestions = assessmentversions.findOverallPerformanceQuestionsByCandidateId(
    state,
    candidateId,
  )
  if (!overallPerformanceQuestions) return

  const overallPerformanceAnswersGroupedByItemId: Record<string, formresponses.Row[]> = {}

  for (const reference of submitted) {
    const overallPerformanceAnswers = formResponses.findAllOverallPerformanceAnswersByReferenceId(
      state,
      reference.id,
      candidateId,
    )

    for (const answer of overallPerformanceAnswers || []) {
      if (answer.value) {
        heatMapInfo[askedOverallPerformanceContentId].responses[reference.id] = { value: Math.round(answer.value) }
      }

      if (overallPerformanceAnswersGroupedByItemId[answer.item_id]) {
        overallPerformanceAnswersGroupedByItemId[answer.item_id].push(answer)
      } else {
        overallPerformanceAnswersGroupedByItemId[answer.item_id] = [answer]
      }
    }
  }

  Object.entries(overallPerformanceAnswersGroupedByItemId).forEach(([itemId, answers]) => {
    heatMapInfo[itemId].average = getAverageValue(answers)
  })

  return heatMapInfo
}

function findLeadershipSummaryScoreByReferenceId(
  state: RootState,
  referenceId: string,
  contentIds: string[],
  requiredCount: number,
): number | null {
  const answers: number[] = []
  for (const contentId of contentIds) {
    const answer = formResponses.findAnswerByReferenceId(state, referenceId, contentId)
    if (answer && !answer.skipped) {
      answers.push(answer.value || 0)
    }
  }

  if (!answers.length || answers.length < requiredCount) {
    return null
  }

  let sum = 0
  for (const answer of answers) {
    sum += answer
  }

  return sum / answers.length
}

function findRDSLeadershipSummaryScoreByReferenceId(
  state: RootState,
  referenceId: string,
  slugs: QUESTION_SLUGS[],
  requiredCount: number,
): number | null {
  const answers: number[] = []
  for (const slug of slugs) {
    const answer = questionnaireAnswers.findSlugSelectedOptionsByReferenceId(state, referenceId, slug)?.[0]
    if (answer?.fields.value !== undefined) {
      answers.push(answer.fields.value)
    }
  }

  if (!answers.length || answers.length < requiredCount) {
    return null
  }

  let sum = 0
  for (const answer of answers) {
    sum += answer
  }

  return sum / answers.length
}

export function findEstablishFocusOverallByCandidateId(state: RootState, candidateId: string): number | null {
  const candidate = candidates.findById(state, candidateId)
  if (!candidate) return null

  const refs = references.findRespondedByCandidateIdExceptSelf(state, candidateId)
  if (!refs || !refs.length) return null

  const minimumAnswers = 0

  if (candidate.fields.questionnaire_id) {
    const establishFocusSlugs: QUESTION_SLUGS[] = ['leadership-skills', 'everyone-knows-what-todo']
    const scores = refs.map(ref =>
      findRDSLeadershipSummaryScoreByReferenceId(state, ref.id, establishFocusSlugs, minimumAnswers),
    )
    return getAverageScore(scores)
  }

  // Properties dictated by science team --
  const establishFocusQuestions = [
    LEADERSHIP_SKILL_QUESTION.test_item_id,
    EVERYONE_KNOWS_WHAT_TO_DO_QUESTION.test_item_id,
  ]

  const scores = refs.map(ref =>
    findLeadershipSummaryScoreByReferenceId(state, ref.id, establishFocusQuestions, minimumAnswers),
  )

  return getAverageScore(scores)
}

export function findRelationshipBuildingOverallByCandidateId(state: RootState, candidateId: string): number | null {
  const candidate = candidates.findById(state, candidateId)
  if (!candidate) return null

  // Properties dictated by science team --
  const minimumAnswers = 0

  const refs = references.findRespondedByCandidateIdExceptSelf(state, candidateId)
  if (!refs || !refs.length) return null

  if (candidate.fields.questionnaire_id) {
    const relationshipBuildingSlugs: QUESTION_SLUGS[] = [
      'recognises-contributions',
      'shows-consideration',
      'helps-others-work',
    ]
    const scores = refs.map(ref =>
      findRDSLeadershipSummaryScoreByReferenceId(state, ref.id, relationshipBuildingSlugs, minimumAnswers),
    )
    return getAverageScore(scores)
  }

  const relationshipBuildingQuestions = [
    RECOGNISED_IMPORTANT_CONTRIBUTIONS_QUESTION.test_item_id,
    SHOWED_CONSIDERATION_FEELINGS_QUESTION.test_item_id,
    HELP_OTHERS_ACCOMPLISH_WORK_QUESTION.test_item_id,
  ]

  const scores = refs.map(ref =>
    findLeadershipSummaryScoreByReferenceId(state, ref.id, relationshipBuildingQuestions, minimumAnswers),
  )

  return getAverageScore(scores)
}

export function findInspireOthersOverallByCandidateId(state: RootState, candidateId: string): number | null {
  const candidate = candidates.findById(state, candidateId)
  if (!candidate) return null

  // Properties dictated by science team --
  const minimumAnswers = 0

  const refs = references.findRespondedByCandidateIdExceptSelf(state, candidateId)
  if (!refs || !refs.length) return null

  if (candidate.fields.questionnaire_id) {
    const inspireOthersSlugs: QUESTION_SLUGS[] = ['compelling-vision', 'encouraged-reevaluation']
    const scores = refs.map(ref =>
      findRDSLeadershipSummaryScoreByReferenceId(state, ref.id, inspireOthersSlugs, minimumAnswers),
    )
    return getAverageScore(scores)
  }

  const inspireOthersQuestions = [
    SOUND_COMPELLING_VISION_QUESTION.test_item_id,
    ENCOURAGED_REEVALUATION_WORKFLOW_QUESTION.test_item_id,
  ]

  const scores = refs.map(ref =>
    findLeadershipSummaryScoreByReferenceId(state, ref.id, inspireOthersQuestions, minimumAnswers),
  )

  return getAverageScore(scores)
}

export function findLeadershipSkillsOverallScoreByCandidateId(state: RootState, candidateId: string): number | null {
  const candidate = candidates.findById(state, candidateId)
  if (!candidate) return null

  // Properties dictated by science team --
  const minimumAnswers = 3

  const refs = references.findRespondedByCandidateIdExceptSelf(state, candidateId)
  if (!refs || !refs.length) return null

  if (candidate.fields.questionnaire_id) {
    const overallSlugs: QUESTION_SLUGS[] = [
      'leadership-skills',
      'everyone-knows-what-todo',
      'recognises-contributions',
      'shows-consideration',
      'helps-others-work',
      'compelling-vision',
      'encouraged-reevaluation',
    ]
    const scores = refs.map(ref =>
      findRDSLeadershipSummaryScoreByReferenceId(state, ref.id, overallSlugs, minimumAnswers),
    )
    return getAverageScore(scores)
  }

  const leadershipSkillsQuestion = [
    LEADERSHIP_SKILL_QUESTION.test_item_id,
    EVERYONE_KNOWS_WHAT_TO_DO_QUESTION.test_item_id,
    RECOGNISED_IMPORTANT_CONTRIBUTIONS_QUESTION.test_item_id,
    SHOWED_CONSIDERATION_FEELINGS_QUESTION.test_item_id,
    HELP_OTHERS_ACCOMPLISH_WORK_QUESTION.test_item_id,
    SOUND_COMPELLING_VISION_QUESTION.test_item_id,
    ENCOURAGED_REEVALUATION_WORKFLOW_QUESTION.test_item_id,
  ]

  const scores = refs.map(ref =>
    findLeadershipSummaryScoreByReferenceId(state, ref.id, leadershipSkillsQuestion, minimumAnswers),
  )

  return getAverageScore(scores)
}

function getAverageScore(scores: (number | null)[]): number | null {
  let sumScore = 0
  let refCount = 0
  for (const score of scores) {
    if (score !== null) {
      sumScore += score
      refCount += 1
    }
  }

  if (!refCount) return null
  if (!sumScore) return 0

  return sumScore / refCount
}

function findSelectedAttributesOfTheContentByCandidateId(
  state: RootState,
  candidateId: string,
  ...contentIds: string[]
): {
  attributes: {
    name: string
    pickedBy: references.Reference[]
  }[]
  numberOfReferences: number
} {
  const attributeMap: Record<
    string,
    {
      name: string
      pickedBy: references.Reference[]
    }
  > = {}

  const candidate = candidates.findById(state, candidateId)
  if (candidate?.fields.questionnaire_id) {
    return findSelectedRDSAttributesOfTheContentByCandidateId(state, candidateId, ...contentIds)
  }

  const submitted = references.findRespondedByCandidateId(state, candidateId)

  for (const contentId of contentIds) {
    for (const reference of submitted) {
      const options = findSelectedOptionsByReferenceId(state, reference.id, contentId)

      for (const option of options) {
        if (!option) continue

        const attributeName = option.caption
        attributeMap[attributeName] = attributeMap[attributeName] || {
          name: attributeName,
          pickedBy: [],
        }

        attributeMap[attributeName].pickedBy.push({
          fullName: reference.fields.full_name,
          relationship: references.relationshipOf(reference),
        })
      }
    }
  }

  return {
    numberOfReferences: submitted.length,
    attributes: Object.values(attributeMap),
  }
}

function findSelectedRDSAttributesOfTheContentByCandidateId(
  state: RootState,
  candidateId: string,
  ...contentIds: string[]
): {
  attributes: {
    name: string
    pickedBy: references.Reference[]
  }[]
  numberOfReferences: number
} {
  const attributeMap: Record<
    string,
    {
      name: string
      pickedBy: references.Reference[]
    }
  > = {}

  const questionList: string[] = []
  for (const id of contentIds) {
    const moduleSlug = CONTENT_ID_SLUG_LOOKUP[id]
    if (!moduleSlug) continue

    const questionsInModules = questions.findByModuleSlug(state, candidateId, moduleSlug)
    if (!questionsInModules) continue

    questionsInModules.map(question => questionList.push(question.id))
  }

  const submitted = references.findRespondedByCandidateId(state, candidateId)

  for (const questionId of questionList) {
    for (const reference of submitted) {
      const options = questionnaireAnswers.findSelectedOptionsByReferenceId(state, reference.id, questionId)
      if (!options) continue

      const alreadySubmitted = new Set()
      for (const option of options) {
        if (!option) continue

        if (alreadySubmitted.has(option.id)) {
          continue
        }
        alreadySubmitted.add(option.id)

        const attributeName = option.fields.copy.caption
        if (!attributeName) continue

        attributeMap[attributeName] = attributeMap[attributeName] || {
          name: attributeName,
          pickedBy: [],
        }

        attributeMap[attributeName].pickedBy.push({
          fullName: reference.fields.full_name,
          relationship: references.relationshipOf(reference),
        })
      }
    }
  }

  return {
    numberOfReferences: submitted.length,
    attributes: Object.values(attributeMap),
  }
}

export function findWorkStyleByCandidateId(state: RootState, candidateId: string) {
  return findSelectedAttributesOfTheContentByCandidateId(state, candidateId, WORK_STYLE_QUESTION.test_item_id)
}

export function findReasonsOfLeavingByCandidateId(state: RootState, candidateId: string) {
  return findSelectedAttributesOfTheContentByCandidateId(state, candidateId, REASONS_FOR_LEAVING_QUESTION.test_item_id)
}

export function findStrengthAttributesByCandidateId(state: RootState, candidateId: string) {
  return findSelectedAttributesOfTheContentByCandidateId(state, candidateId, STRENGTHS_QUESTION.test_item_id)
}

export function findAreasOfImprovementAttributesByCandidateId(state: RootState, candidateId: string) {
  return findSelectedAttributesOfTheContentByCandidateId(
    state,
    candidateId,
    AREAS_OF_IMPROVEMENT.test_item_id,
    OLD_AREAS_OF_IMPROVEMENT.test_item_id,
  )
}
