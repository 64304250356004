import React, { useEffect, useState } from 'react'
import Callout from '../Callout/Callout'

interface Message {
  error?: string
  success?: string
  timeoutSecs?: number
  createdAt?: number
}

function getKey(msg: Message) {
  if (msg.createdAt === undefined) {
    return ''
  }
  return msg.createdAt.toString()
}

type NotificationStatus = 'displayed' | 'fading-out' | 'pending' | 'on-screen'

export interface Props {
  messages?: Message[]
}

export function NotificationsUI(props: Props) {
  const [notificationStatus, setNotificationStatus] = useState<Record<string, NotificationStatus>>({})

  // filter out warnings that are more than a second-old,
  // in case a page is changed and notifications is remounted
  const messageOnDisplay = (props.messages || []).find(msg => notificationStatus[getKey(msg)] !== 'displayed')

  useEffect(() => {
    if (!messageOnDisplay) {
      return
    }

    const messageKey = getKey(messageOnDisplay)
    setNotificationStatus(notificationStatus => ({
      ...notificationStatus,
      [messageKey]: 'on-screen',
    }))

    const setFadeOutTimeout = setTimeout(() => {
      setNotificationStatus(notificationStatus => ({
        ...notificationStatus,
        [messageKey]: 'fading-out',
      }))
    }, 2800)
    const clearFadeOutTimeout = setTimeout(() => {
      setNotificationStatus(notificationStatus => ({
        ...notificationStatus,
        [messageKey]: 'displayed',
      }))
    }, 3000)

    return () => {
      clearTimeout(setFadeOutTimeout)
      clearTimeout(clearFadeOutTimeout)
    }
  }, [messageOnDisplay])

  if (!messageOnDisplay) {
    return <></>
  }

  const messageKey = getKey(messageOnDisplay)

  return (
    <Callout
      key={messageKey}
      error={messageOnDisplay.error}
      success={messageOnDisplay.success}
      close={() => {
        setNotificationStatus(notificationStatus => ({
          ...notificationStatus,
          [messageKey]: 'fading-out',
        }))
        setTimeout(() => {
          setNotificationStatus(notificationStatus => ({
            ...notificationStatus,
            [messageKey]: 'displayed',
          }))
        }, 200)
      }}
      fadeOut={notificationStatus[messageKey] === 'fading-out'}
    />
  )
}
