import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { genericassessments } from '../../api'

interface State {
  loading: boolean
  content: { [keyword: string]: string[] }
  total: { [keyword: string]: number }
}

const name = genericassessments.RESOURCE
const initialState: State = {
  loading: false,
  content: {},
  total: {},
}

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setTotal(state, action: PayloadAction<{ keyword: string; total: number }>) {
      const { keyword, total } = action.payload
      state.total[keyword] = total
    },
    setContent(state, action: PayloadAction<{ keyword: string; results: string[] }>) {
      const { keyword, results } = action.payload
      state.content[keyword] = results
    },
    appendContent(state, action: PayloadAction<{ keyword: string; results: string[] }>) {
      const { keyword, results } = action.payload
      state.content[keyword] = state.content[keyword].concat(results)
    },
  },
})

export const { setContent, setTotal, setLoading } = slice.actions

export default slice.reducer
