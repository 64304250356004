import React, { CSSProperties } from 'react'
import PI, { CountryData, PhoneInputProps } from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const ReactPhoneInput: React.FC<PhoneInputProps> = (PI as any).default || PI

interface IProps {
  value: string
  enableSearch?: boolean
  defaultCountry?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void
  onEnter?: () => void
  onValidationChanged?: (isValid: boolean) => void
}

const searchStyle: CSSProperties = {
  lineHeight: '1.5rem',
  width: '15rem',
}

const inputStyle: CSSProperties = {
  height: '100%',
  lineHeight: '1.5rem',
  border: 'none',
  padding: '0.75rem 0.75rem 0.75rem 3rem',
  width: '100%',
}

const PhoneTextField: React.FC<IProps> = props => {
  return (
    <ReactPhoneInput
      inputStyle={inputStyle}
      searchStyle={searchStyle}
      buttonStyle={{ border: 'none', minWidth: '2rem' }}
      country={props.defaultCountry || 'us'}
      enableSearch={props.enableSearch}
      value={props.value}
      onChange={(
        value: string,
        data: CountryData | {},
        event: React.ChangeEvent<HTMLInputElement>,
        formattedValue: string,
      ) => {
        if (!props.onChange) {
          return
        }

        // Nullability guards
        value = value || ''
        formattedValue = formattedValue || ''
        const dialCode = (data as CountryData)?.dialCode || ''
        const countrySpecificFormat = (data as CountryData)?.format || ''

        // If this event was triggered because of a click event (yes, this component does this)
        // the event type will not be "change".
        if (event?.type === 'change') {
          // If the user input is empty, then the formatted string only contains the country dial
          // code (e.g., 49 for Germany).
          event.target.value = toE164Format(value.length === 0 || formattedValue === dialCode ? '' : formattedValue)

          if (props.onValidationChanged) {
            props.onValidationChanged(formattedValue.length === countrySpecificFormat.length)
          }

          props.onChange(event)
        }
      }}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
      onKeyDown={props.onKeyUp} // there is no onKeyDown on PhoneInput ... this is as good as it gets
      onEnterKeyPress={props.onEnter}
    />
  )
}

// Removes all characters from the string other than "+" and numbers
function toE164Format(phone: string) {
  return phone.replace(/[^\d\+]/g, '')
}
export default PhoneTextField
