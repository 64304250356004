import { useSelector } from 'react-redux'
import { findByCandidateId, findMissingById } from 'selectors/employees'
import { getById as findUserById } from 'selectors/users'
import { RootState } from 'store'

export function useEmployee(query: { candidateId: string }) {
  const { candidateId } = query
  const employee = useSelector((state: RootState) => findByCandidateId(state, candidateId))
  const manager = useSelector((state: RootState) => findUserById(state, employee?.fields.manager_id || ''))
  const missingFields = useSelector((state: RootState) => findMissingById(state, employee?.id || ''))
  return { employee, manager, missingFields }
}
