import { lookup as findMimeType } from 'mime-types'
import React from 'react'
import { useSelector } from 'react-redux'
import * as tracking from '../../core/track'
import { RootState } from '../../store'

interface IProps {
  src: string
  candidateId: string
}

const Component = (props: IProps) => {
  const trackingProps = useSelector((state: RootState) =>
    props.candidateId ? tracking.media.extractPropsFromState(state, props.candidateId) : null,
  )

  const mimeType: string = findMimeType(props.src) || ''
  if (mimeType.includes('video')) {
    return (
      <video
        onPlay={() => {
          if (trackingProps) {
            tracking.media.playVideo(trackingProps)
          }
        }}
        width="800"
        controls
      >
        <source src={props.src} />
        Your browser doesn't support displaying videos! Sorry!
      </video>
    )
  }

  if (mimeType.includes('image')) {
    return <img alt="user submit" width="800" src={props.src} />
  }
  return (
    <a
      href={props.src}
      onClick={() => {
        if (trackingProps) {
          tracking.media.downloadFile(trackingProps)
        }
      }}
      download
      rel="noopener noreferrer"
      target="_blank"
    >
      Download submission
    </a>
  )
}

export default Component
Component.displayName = 'FileView'
