import React, { useMemo } from 'react'

export const MediaPlayer = (props: {
  mediaURL: string
  onVideoPlayCallback?: () => void
  onAudioPlayCallback?: () => void
}) => {
  const Player = useMemo(() => {
    if (/\/audio/.test(props?.mediaURL ?? ''))
      return <audio src={props.mediaURL} controls onPlay={props.onAudioPlayCallback} />

    if (/\/video/.test(props?.mediaURL ?? ''))
      return <video src={props.mediaURL} controls style={{ maxHeight: '22rem' }} onPlay={props.onVideoPlayCallback} />
    return null
  }, [props.mediaURL])
  return (
    <div
      style={{
        width: 'fit-content',
        height: 'fit-content',
        borderRadius: '8px',
        overflow: 'hidden',
      }}
    >
      {Player}
    </div>
  )
}
