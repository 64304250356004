import React from 'react'
import { Redirect, RedirectProps, useParams } from 'react-router-dom'

type RedirectPropsWithoutTo = Omit<RedirectProps, 'to'>

interface RedirectWithParamsProps extends RedirectPropsWithoutTo {
  setUrl: (params: Record<string, string>) => string
}

export const RedirectWithParams: React.FC<RedirectWithParamsProps> = ({ setUrl, ...redirectProps }) => {
  const params = useParams<Record<string, string>>()
  return <Redirect to={setUrl(params)} {...redirectProps} />
}
