import { Icon } from 'components/Icons'
import { px2rem, size, space, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React from 'react'

const Container = style()
  .flex({ justifyContent: 'space-between' })
  .spacing({ inner: [space.none, space.xxxs] })
  .element()

const Label = text.label().spacing({ outerTop: space.auto }).fg(designSystemColors.typographySecondary).flex().element()

const IconContainer = style()
  .spacing({ outer: [size.auto, size.none, size.auto, px2rem(4)] })
  .sans({ nodecoration: true })
  .text({ color: designSystemColors.typographySecondary, nodecoration: true })
  .element('a')

const Title: React.FC = () => (
  <Container>
    <Label>OVERALL</Label>
    <Label>
      AVERAGE
      <IconContainer
        target="_blank"
        rel="noopener noreferrer"
        href={'https://intercom.help/hipeople/en/articles/7470584-analyse-a-candidate-s-reference-feedback'}
      >
        <Icon name="question-circle" />
      </IconContainer>
    </Label>
  </Container>
)

export default Title
