import { del, post, put } from './methods'
import { APIResponse, Entity } from './request'

export const RESOURCE = 'webhooks'
export const resources = ['candidates', 'references', 'open_job_roles']
export const actions = ['create', 'update', 'start', 'complete']
export const methods = ['PUT', 'POST', 'GET', 'PATCH', 'DELETE']

export interface Fields {
  id: string
  title: string
  organization_id: string
  resource: string
  action: string
  target_method: string
  target_url: string
  custom_payload: string
  username?: string
  password?: string
  is_active: boolean
  created_at: number
  updated_at: number
}

export interface CreateWebhookPayload {
  title: string
  organization_id: string
  resource: string
  action: string
  target_method: string
  target_url: string
}

export function create(body: CreateWebhookPayload): Promise<APIResponse<Entity<Fields>>> {
  return put<Entity<Fields>>('webhooks', body)
}

interface UpdateWebhookPayload {
  changed: string[]
  fields: {
    title?: string
    resource?: string
    action?: string
    target_method?: string
    target_url?: string
    payload?: string
  }
}

export function update(id: string, body: UpdateWebhookPayload): Promise<APIResponse<Entity<Fields>>> {
  return post<Entity<Fields>>(`webhooks/${id}`, body)
}

export function remove(id: string): Promise<APIResponse<Entity<Fields>>> {
  return del<Entity<Fields>>(`webhooks/${id}`, {})
}

export function empty(id: string): Entity<Fields> {
  return {
    id,
    resource: RESOURCE,
    fields: {
      id,
      title: '',
      organization_id: '',
      resource: '',
      action: '',
      target_method: '',
      target_url: '',
      custom_payload: '',
      is_active: true,
      created_at: 0,
      updated_at: 0,
    },
  }
}
