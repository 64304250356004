import { Button, LanguageMap } from '@common/components'
import { BadgeTheme, BADGE_THEMES } from 'components/Badge'
import { Icon } from 'components/Icons'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React from 'react'
import { Column } from '../../../../components/ContentGrid'
import { px, px2rem, size, style } from '../../../../core'

export const PREVIEW_GRID_COLUMNS = [px2rem(40), px2rem(16), px2rem(180), size.auto, px2rem(95), px2rem(85), px2rem(90)]

const RowStyle = style()
  .grid({
    columns: PREVIEW_GRID_COLUMNS,
  })
  .border({
    around: '1px solid',
    color: designSystemColors.borderDefault,
    radius: px2rem(8),
  })
  .nooverflow()
  .cond(({ isEmpty }: { isEmpty: boolean }) => isEmpty, style().grid({ columns: ['100%'] }))
  .cond(
    ({ warning }: { warning: boolean }) => warning,
    style().color({ border: designSystemColors.informationOrangePrimary }),
  )
  .element()

const Actions = style()
  .spacing({ gap: px2rem(6) })
  .elementFromComponent(Column)

const Name = text.cardHeader().element('p')
const Locale = text.bodyText().element('p')
const MismatchingLocale = text.bodyEmphasis().color({ fg: designSystemColors.informationOrangePrimary }).element('p')
const Duration = text.bodyText().element('p')

const Type = text
  .bodyInteractive()
  .size({ height: px2rem(22) })
  .sans({ size: px2rem(12), noWrap: true })
  .spacing({ gap: px(5), inner: px2rem(5) })
  .center()
  .border({ radius: px2rem(4) })
  .with((props: { bgColor: string; fgColor: string }) => style().color({ bg: props.bgColor, fg: props.fgColor }))
  .element()

type RowProps = {
  areaIcon: string
  areaTitle: string
  areaTheme: BadgeTheme
  name: string
  locale: string
  mismatchingLocale?: boolean
  areaAriaLabel: string
  duration: string
  edit?: () => void
  remove: () => void
  DragHandle?: React.ReactNode
}

export const Row: React.FC<RowProps> = props => {
  const { bg, fg } = BADGE_THEMES[props.areaTheme] || { bg: '', fg: '' }
  return (
    <RowStyle warning={props.mismatchingLocale} data-testid="content-selection-row">
      {props.DragHandle && (
        <Column centerVert center>
          {props.DragHandle}
        </Column>
      )}
      <Column />
      <Column centerVert>
        <Type bgColor={bg} fgColor={fg}>
          <Icon name={props.areaIcon} ariaLabel={props.areaAriaLabel}></Icon>
          {props.areaTitle}
        </Type>
      </Column>
      <Column centerVert>
        <Name>{props.name}</Name>
      </Column>
      <Column centerVert>
        <Duration>{props.duration}</Duration>
      </Column>
      <Column centerVert>
        {!!LanguageMap[props.locale] &&
          (props.mismatchingLocale || false ? (
            <MismatchingLocale>{LanguageMap[props.locale].name}</MismatchingLocale>
          ) : (
            <Locale>{LanguageMap[props.locale].name}</Locale>
          ))}
      </Column>
      <Actions centerVert>
        {props.edit ? (
          <Button variant="icon" onClick={props.edit} rounded size="sm">
            <Icon name="edit" ariaLabel="Preview Template" />
          </Button>
        ) : (
          ''
        )}
        <Button variant="icon" onClick={props.remove} rounded size="sm">
          <Icon name="times" ariaLabel="Preview Template" />
        </Button>
      </Actions>
    </RowStyle>
  )
}
