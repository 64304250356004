import * as api from 'api'
import { Fields, QuestionnaireSubmissionEntity, RESOURCE } from 'api/questionnaire-submissions'
import { Entity } from 'api/request'
import * as selectors from 'selectors'
import { RootState } from 'store'
import * as candidateProfiles from './candidate-profiles'
import * as candidates from './candidates'
import { findById as findEmployeeById } from './employees'
import lookup from './lookup'
import * as references from './references'
import { findByCandidateId as findReferenceByCandidateId } from './references'

export function findById(state: RootState, id: string) {
  return lookup<Fields>(state, RESOURCE, id)
}

export function findReferenceSubmissionsByCandidateId(state: RootState, candidateId: string) {
  const references = findReferenceByCandidateId(state, candidateId)

  const questionnaireSubmissions: Record<string, QuestionnaireSubmissionEntity | undefined> = {}

  for (const reference of references) {
    const questionnaireSubmission = reference.relationships?.find(r => r.resource === RESOURCE)
    if (questionnaireSubmission) {
      questionnaireSubmissions[reference.id] = findById(state, questionnaireSubmission.id)
    }
  }

  return questionnaireSubmissions
}

function findByCandidateId(state: RootState, candidateId: string) {
  const candidate = candidates.findById(state, candidateId)
  if (!candidate) return

  const questionnaireSubmissionRelationship = candidate.relationships?.filter(r => r.resource === RESOURCE)

  if (!questionnaireSubmissionRelationship) {
    return undefined
  }

  return questionnaireSubmissionRelationship.map(sub => findById(state, sub.id))
}

export function findSelfAssessmentByCandidateId(state: RootState, candidateId: string) {
  const submissions = findByCandidateId(state, candidateId)
  if (!submissions) return

  return submissions.find(item => item?.fields.self_assessment_candidate_id === candidateId)
}

export function findSelfAssessmentSubmissionsByCandidateId(state: RootState, candidateId: string) {
  const submissions = findByCandidateId(state, candidateId)
  if (!submissions) {
    return undefined
  }

  return submissions.filter(sub => sub?.fields.self_assessment_candidate_id === candidateId)
}

export function findByReferenceId(state: RootState, referenceId: string): Entity<Fields> | undefined {
  const reference = references.findById(state, referenceId)
  if (!reference) return

  const questionnaireSubmissionRelationship = reference.relationships?.find(r => r.resource === RESOURCE)

  if (!questionnaireSubmissionRelationship) {
    return undefined
  }

  return findById(state, questionnaireSubmissionRelationship.id)
}

export function findByEmployeeId(state: RootState, employeeId: string) {
  const employee = findEmployeeById(state, employeeId)
  if (!employee) {
    return undefined
  }

  const questionnaireSubmissionRelationships = employee.relationships?.filter(r => r.resource === RESOURCE)

  if (!questionnaireSubmissionRelationships) {
    return undefined
  }

  const questionnaireSubmissions: QuestionnaireSubmissionEntity[] = []

  for (const relationship of questionnaireSubmissionRelationships) {
    const questionnaireSubmission = findById(state, relationship.id)
    if (questionnaireSubmission) {
      questionnaireSubmissions.push(questionnaireSubmission)
    }

    const peerQuestionnaireSubmission = findPeeredById(state, questionnaireSubmission?.id || '')
    if (peerQuestionnaireSubmission) {
      questionnaireSubmissions.push(peerQuestionnaireSubmission)
    }
  }

  return questionnaireSubmissions
}

function findPeeredById(state: RootState, questionnaireSubmissionId: string) {
  const questionnaireSubmission = findById(state, questionnaireSubmissionId)
  if (!questionnaireSubmission) return

  const relationship = questionnaireSubmission?.relationships?.find(r => r.resource === RESOURCE)
  if (!relationship) return

  return findById(state, relationship.id)
}

export const sortByOverlapped = (a, b) => {
  // Sort by overlapped property (true before false)
  if (a.overlapped && !b.overlapped) {
    return -1
  } else if (!a.overlapped && b.overlapped) {
    return 1
  }

  // Sort alphabetically by caption
  return a.caption.localeCompare(b.caption)
}

export interface CultureFitResponse {
  id: string
  caption: string
  overlapped: boolean
}

export function findCultureFitCheckResponses(
  state: RootState,
  questionnaireSubmissionId: string,
):
  | {
      candidateProfile: Entity<api.candidateProfiles.Fields> | undefined
      recruiterSelectedValues: CultureFitResponse[] | undefined
      candidateSelectedValues: CultureFitResponse[] | undefined
    }
  | undefined {
  const submission = findById(state, questionnaireSubmissionId)
  if (!submission) return

  const questionnaire = selectors.questionnaires.findById(state, submission?.fields?.questionnaire_id || '')

  const answers = selectors.questionnaireAnswers.findByQuestionnaireSubmissionId(state, submission.id)

  const cultureFitQuestion = selectors.questions.findBySlug(state, 'culturefitcheck-whatmattersmost')
  if (!cultureFitQuestion) return

  const responseOptions = selectors.responseOptions.findByQuestionId(state, cultureFitQuestion.id)

  const cultureFitCheckModule = Object.entries(questionnaire?.fields.library_item_configs || {}).find(([key]) =>
    key.startsWith('modules:culturefitcheck'),
  )?.[1]

  const recruiterSelectedSlugs = cultureFitCheckModule?.questions?.[cultureFitQuestion.id] || []
  if (!recruiterSelectedSlugs.length) return

  const cultureFitAnswers = answers.filter(answer => answer.fields.question_id === cultureFitQuestion.id)

  const candidateResponses = cultureFitAnswers.map(answer =>
    selectors.responseOptions.findById(state, answer.fields.selected_response_option),
  )

  const recruiterOverlaps: Set<string> = new Set()

  const recruiterSelectedValues = (responseOptions || [])
    .filter(option => recruiterSelectedSlugs.includes(option.fields.slug))
    .map(resp => {
      recruiterOverlaps.add(resp.id)
      return {
        id: resp.id,
        caption: resp.fields.copy.caption || '',
        overlapped: false,
      }
    })

  const candidateOverlaps: Set<string> = new Set()

  const candidateSelectedValues = candidateResponses
    .map(resp => {
      candidateOverlaps.add(resp?.id || '')
      return {
        id: resp?.id || '',
        caption: resp?.fields.copy.caption || '',
        overlapped: recruiterOverlaps.has(resp?.id || ''),
      }
    })
    .sort(sortByOverlapped)

  const candidateProfile = candidateProfiles.findByCandidateId(state, submission.fields.self_assessment_candidate_id)

  const recruiterSelectedValuesSort = recruiterSelectedValues
    .map(resp => ({
      ...resp,
      overlapped: candidateOverlaps.has(resp.id),
    }))
    .sort(sortByOverlapped)

  return {
    candidateProfile,
    recruiterSelectedValues: recruiterSelectedValuesSort,
    candidateSelectedValues,
  }
}
