import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React from 'react'

import { Tooltip } from '@common/components'
import { Icon } from 'components/Icons'

const WarningIconStyle = text
  .smallBodySecondary()
  .color({ fg: designSystemColors.uiStatusWarningSecondary })
  .inlineBlock()
  .size({ width: '25px' })
  .text({ align: 'center' })
  .element('span')

interface Props {
  message: string
  tooltipDirection?: 'top' | 'bottom' | 'left' | 'right'
}

export const WarningIcon: React.FC<Props> = props => {
  return (
    <>
      <Tooltip text={props.message} direction={props.tooltipDirection ?? 'right'}>
        <WarningIconStyle data-tip={props.message} data-for="overlapping-content">
          <Icon name="exclamation-triangle" />
        </WarningIconStyle>
      </Tooltip>
    </>
  )
}
