import { useContext } from 'react'
import { Options } from './Dialog'
import { DialogContext } from './Provider'

export { default as Provider } from './Provider'

export const useConfirm = () => {
  const { open } = useContext(DialogContext)

  return (options: Options) => {
    return new Promise(resolve => {
      open && open(options, resolve)
    })
  }
}
