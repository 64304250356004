import { useGeneralImpression } from 'providers/candidate-summaries'
import { useSelectedCandidate } from 'providers/candidates'
import { useModule } from 'providers/modules'
import React from 'react'
import { useParams } from 'react-router-dom'
import { GENERAL_IMPRESSION } from 'store/new-role/content'
import { SummaryContainer } from './SummaryContainer'
import { TestimonialBlock, TestimonyType } from './TestimonialBlock'

interface GeneralImpressionsBlockUIProps {
  testimonies: TestimonyType[]
  detailsUrl?: string
}

const GeneralImpressionsBlockUI: React.FC<GeneralImpressionsBlockUIProps> = ({ testimonies, detailsUrl }) => {
  return (
    <SummaryContainer emoji={'🖼'} title={'General Impressions'} hintColor={'green'} to={detailsUrl}>
      <TestimonialBlock testimonies={testimonies} />
    </SummaryContainer>
  )
}

interface GeneralImpressionBlockProps {
  container?: React.FC
}

const GeneralImpressionBlock: React.FC<GeneralImpressionBlockProps> = ({ container }) => {
  const { candidate } = useSelectedCandidate()
  const { isLoading, testimonies } = useGeneralImpression({
    candidateId: candidate?.id,
  })

  const module = useModule({ slug: 'general-impression' })
  const targetId = candidate?.fields.questionnaire_id ? module?.id : GENERAL_IMPRESSION.test_item_id
  const { roleid, candidateid } = useParams<{
    roleid: string
    candidateid: string
  }>()
  const detailsUrl = `/roles/${roleid}/candidates/${candidateid}/responses#${targetId}`

  if (isLoading || !testimonies || !testimonies.length) {
    return null
  }

  const Container = container || React.Fragment

  return (
    <Container>
      <GeneralImpressionsBlockUI testimonies={testimonies} detailsUrl={detailsUrl} />
    </Container>
  )
}

export default GeneralImpressionBlock
