import { Column } from 'components/ContentGrid'
import { pct, px2rem, rem, size, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React from 'react'
import { Link } from 'react-router-dom'

interface QuestionListProps {
  question: {
    copy: string
    link: string
  }[]
}

const Title = text
  .secondaryBodyInteractive()
  .spacing({ inner: [size.s, px2rem(16)] })
  .element()

const QuestionRow = text
  .bodyText()
  .border({
    top: `1px solid`,
    color: designSystemColors.borderDefault,
  })
  .grid({ columns: [size.auto, rem(7)] })
  .spacing({ inner: [px2rem(8), px2rem(16)], gap: px2rem(12) })
  .element()

export const QuestionTable = style()
  .border({
    around: `1px solid`,
    color: designSystemColors.borderDefault,
    radius: px2rem(8),
  })
  .set('borderSpacing', '0px')
  .size({ width: size.fill })
  .nooverflow()
  .element()

const QuestionCopy = style()
  .fg(designSystemColors.typographySecondary)
  .spacing({ outer: [size.auto, size.none] })
  .sans({ height: px2rem(18) })
  .element()

const AddQuestion = text
  .bodyInteractive()
  .color({
    bg: designSystemColors.backgroundNeutralSecondary,
    fg: designSystemColors.typographySecondary,
  })
  .size({ width: pct(100), height: px2rem(36) })
  .border({
    around: `1px solid`,
    color: designSystemColors.borderDefault,
    radius: px2rem(6),
  })
  .sans({ nodecoration: true, align: 'center' })
  .pointer()
  .spacing({ inner: px2rem(8), outer: size.auto })
  .elementFromComponent(Link)

const QuestionList: React.FC<QuestionListProps> = ({ question }) => {
  return (
    <QuestionTable>
      <Column head>
        <Title>Question</Title>
      </Column>
      {question.map((q, i) => (
        <QuestionRow key={i}>
          <QuestionCopy>{q.copy}</QuestionCopy>
          <AddQuestion to={q.link}>See Answers</AddQuestion>
        </QuestionRow>
      ))}
    </QuestionTable>
  )
}

export default QuestionList
