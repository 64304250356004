import { Button, ButtonContent } from 'components/Button/ButtonV2'
import Modal from 'components/Modal'
import TextField, { BaseLabel } from 'components/TextField'
import { fr, pct, px2rem, size, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import * as tracking from 'core/track'
import React from 'react'
import { Icon } from '../../../components/Icons'
import ToggleField from '../../../components/ToggleField'

const Title = text.cardHeader().element('h3')

const ModalHeader = style()
  .grid({ columns: [fr(1), size.auto, size.auto], align: 'center' })
  .spacing({ gap: px2rem(8), inner: [px2rem(18), px2rem(32)] })
  .border({
    bottom: `solid ${px2rem(1)}`,
    color: designSystemColors.borderDefault,
  })
  .element()

const QuestionContent = style()
  .spacing({ inner: [px2rem(24), px2rem(32)] })
  .scroll({ y: 'auto' })
  .size({
    maxHeight: px2rem(576),
    maxWidth: px2rem(768),
  })
  .element()

const OptionsContent = style()
  .spacing({ inner: [px2rem(0), px2rem(32), px2rem(24), px2rem(32)] })
  .size({
    maxHeight: px2rem(576),
    maxWidth: px2rem(768),
  })
  .scroll({ y: 'auto' })
  .element()

const OptionContent = style()
  .grid({ columns: [px2rem(32), size.auto, px2rem(44)], align: 'center' })
  .spacing({ outerBottom: px2rem(16) })
  .element()

const Buttons = style()
  .flex({ justifyContent: 'flex-end' })
  .spacing({ inner: [px2rem(18), px2rem(32)], gap: px2rem(16) })
  .border({
    top: `solid 1px`,
    color: designSystemColors.borderDefault,
  })
  .select('> *', style().size({ minWidth: px2rem(140) }))
  .element()

const CustomQuestionModalStyle = style()
  .size({ minWidth: px2rem(600) })
  .element()

const Label = BaseLabel.clone()
  .block()
  .spacing({ outerBottom: px2rem(12) })
  .element('label')

const OptionRemovalButton = style()
  .flex({ alignItems: 'center', justifyContent: 'center' })
  .color({
    fg: designSystemColors.typographyPrimary,
    bg: designSystemColors.transparent,
  })
  .size({ width: px2rem(32), height: px2rem(32) })
  .border({
    radius: pct(50),
    around: `solid ${px2rem(1)} ${designSystemColors.transparent}`,
  })
  .pointer()
  .select(':hover', style().color({ bg: designSystemColors.backgroundNeutralSecondaryHover }))
  .element('button')

const OptionButtonsContainer = text
  .bodyText()
  .flex({
    alignItems: 'center',
    justifyContent: 'right',
  })
  .element()

const CounterContainer = text
  .bodyText()
  .flex({
    alignItems: 'center',
    justifyContent: 'left',
  })
  .element('p')

const AddOptionButtonContainer = style()
  .spacing({
    outer: [px2rem(16), px2rem(44), px2rem(32), px2rem(32)],
  })
  .element()

const ToggleContainer = style()
  .spacing({
    outer: [px2rem(16), px2rem(44), px2rem(16), px2rem(8)],
  })
  .element()

type CustomQuestionValues = {
  text: string
  isRequired: boolean
  options?: string[]
  allowMultipleChoice?: boolean
}

interface AddCustomMultiSelectQuestionModalProps {
  close: () => any
  onSubmit: (values: CustomQuestionValues) => any
  initialValues?: CustomQuestionValues
}

export const AddCustomMultiSelectQuestionModal: React.FC<AddCustomMultiSelectQuestionModalProps> = ({
  close,
  onSubmit,
  initialValues,
}) => {
  const [questionText, setQuestionText] = React.useState(initialValues?.text ?? '')
  const [options, setOptions] = React.useState(initialValues?.options ?? ['', '', ''])
  const [allowMultipleChoice, setAllowMultipleChoice] = React.useState(!!initialValues?.allowMultipleChoice)

  const [isRequired, setIsRequired] = React.useState(!!initialValues?.isRequired)

  const isInputInvalidValid = questionText.trim().length === 0 || options.filter(e => e.trim().length === 0).length > 0

  const createOptionRow = (text: string, idx: number, onTextChanged: (newValue: string) => void) => {
    return (
      <OptionContent>
        <CounterContainer>{idx + 1}. </CounterContainer>
        <TextField
          placeholder={'Option ' + (idx + 1)}
          value={text}
          onChange={ev => onTextChanged(ev.target.value)}
          testId={`add-selection-question-modal-option-${idx}`}
        />
        <OptionButtonsContainer>
          <OptionRemovalButton
            onClick={() => {
              const newOptions = [...options]
              newOptions.splice(idx, 1)
              setOptions(newOptions)
            }}
          >
            <Icon name={'times'} />
          </OptionRemovalButton>
        </OptionButtonsContainer>
      </OptionContent>
    )
  }

  return (
    <Modal
      open={true}
      setOpen={close}
      testId="add-selection-question-modal"
      renderHeader={({ closeButton }) => (
        <ModalHeader>
          <Title>{`${initialValues?.text ? 'Update' : 'Add'} selection question`}</Title>
          {closeButton}
        </ModalHeader>
      )}
    >
      <CustomQuestionModalStyle>
        <QuestionContent>
          <TextField
            label="Question"
            placeholder="e.g: Can you elaborate on the reasons for the termination?"
            value={questionText}
            onChange={ev => setQuestionText(ev.target.value)}
            testId="add-selection-question-modal-question-input"
          />
        </QuestionContent>
        <OptionsContent>
          <Label>Answers</Label>
          {options.map((optionText, idx) => {
            return createOptionRow(optionText, idx, newValue => {
              const newOptions = [...options]
              newOptions[idx] = newValue
              setOptions(newOptions)
            })
          })}
          <AddOptionButtonContainer>
            <Button
              data-testid="add-selection-question-modal-add-option-button"
              isFullWidth
              buttonType="tertiary"
              onClick={() => {
                setOptions([...options, ''])
              }}
            >
              {'Click to add answer option'}
            </Button>
          </AddOptionButtonContainer>
          <ToggleContainer>
            <ToggleField
              on={allowMultipleChoice}
              label={'Allow user to select multiple answers'}
              aria-label={`Allow user to select multiple answers`}
              onChange={() => {
                setAllowMultipleChoice(!allowMultipleChoice)
                tracking.selfAssessment.customQuestionsAllowMultiSelect()
              }}
            />
          </ToggleContainer>
          <ToggleContainer>
            <ToggleField label={'Required'} aria-label={`Required`} on={isRequired} onChange={setIsRequired} />
          </ToggleContainer>
        </OptionsContent>
        <Buttons>
          <Button buttonType="tertiary" onClick={close}>
            {'Cancel'}
          </Button>
          <Button
            buttonType="accent"
            onClick={() => {
              onSubmit({
                text: questionText,
                options,
                allowMultipleChoice,
                isRequired,
              })
              tracking.selfAssessment.customQuestionsAddSelectionQuestion()
            }}
            disabled={isInputInvalidValid || !options?.length || options.length < 2}
          >
            <ButtonContent
              icon={{
                name: 'plus',
                ariaLabel: initialValues?.text ? 'Update question' : 'Add question',
              }}
            >
              {initialValues?.text ? 'Update question' : 'Add question'}
            </ButtonContent>
          </Button>
        </Buttons>
      </CustomQuestionModalStyle>
    </Modal>
  )
}

AddCustomMultiSelectQuestionModal.displayName = 'AddCustomMultiSelectQuestionModal'
