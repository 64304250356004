import { orgSettings } from '../api'
import { Entity } from '../api/request'
import { RootState } from '../store'
import lookup from './lookup'
import { getById as getOrgById } from './orgs'

function getById(state: RootState, id: string): Entity<orgSettings.Fields> {
  return lookup(state, orgSettings.RESOURCE, id) || orgSettings.empty(id)
}

export function getByOrgId(state: RootState, orgId: string): Entity<orgSettings.Fields> | undefined {
  const org = getOrgById(state, orgId)
  if (!org || !org.relationships) return

  const rel = org.relationships.find(r => r.resource === orgSettings.RESOURCE)
  if (!rel) return

  return getById(state, rel.id)
}
