import React from 'react'
import style from '../../core/style'

interface Props {
  children?: JSX.Element | string
  height?: string
  size?: string
}

const Container = style().element()

export const Row = ({ children }: Props) => {
  return <Container>{children}</Container>
}
