import { Button, Tooltip } from '@common/components'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { save } from 'store/settings/org'
import { employees, listing } from '../../api'
import { ButtonContent } from '../../components/Button/ButtonV2'
import DashboardLayout from '../../components/DashboardLayout'
import { Icon } from '../../components/Icons'
import ProductPageShell from '../../components/ProductPageLayout'
import { space, style } from '../../core'

import { UpgradeButton } from 'App/ProductPages/Shared'
import { EmptyQualityOfHirePageUI } from 'App/QualityOfHire/EmptyPage'
import * as tracking from '../../core/track'
import { useFeatureFlag } from '../../providers/features'
import { useUser } from '../../providers/users'
import * as selectors from '../../selectors'
import { RootState } from '../../store'
import {
  create,
  defaultSortField,
  defaultSortOrder,
  loadEmployeeById,
  loadEmployees,
  remove,
  setSortDesc,
  setSortField,
  SortField,
} from '../../store/employees'
import { loadByProduct } from '../../store/reports'
import { NewHireModal } from './NewHire/Modal'
import { QualityOfHireSettings, SettingsModal } from './Settings/Modal'
import StatCards from './Statistics/Cards'
import { Table } from './Table'

const ButtonContainer = style().flex().spacing({ gap: space.xs }).element()

const ButtonTooltip = style().element()

export type Query = {
  offset?: number
}

interface QualityOfHirePageUIProps {
  employees?: employees.Fields[]
  fetchData: (query: Query) => void
  canManageOrg: boolean
  qohEnabled: boolean
  enableButton: JSX.Element
  qohTimelineDays: number
  addHire: (employeeFields: employees.CreateFields) => Promise<boolean>
  removeHire: (employeeId: string) => void
  hasMore: boolean
  updateSettings: (fields: QualityOfHireSettings) => void
  sortingField: SortField
  setSortingField: (field: SortField) => void
  desc: boolean
  setDesc: (order: listing.SortOrder) => void
  overallScore: number
  managerScore: number
  newHireScore: number
  isSettingsModalOpen: boolean
  setSettingsModalOpen: (isOpen: boolean) => void
}

function QualityOfHirePageUI(props: QualityOfHirePageUIProps) {
  const [isAddHireModalOpen, setAddHireModalOpen] = useState(false)

  const user = useSelector((state: RootState) => {
    return selectors.users.current(state)
  })
  const userHasVerifiedEmail = user.fields.verified_at > 0

  const settingsAndAddHireButtons = (
    <Tooltip text="Please verify your email address by clicking the link we sent you!" disabled={userHasVerifiedEmail}>
      <ButtonTooltip>
        <ButtonContainer>
          <Button
            variant="secondary"
            onClick={() => {
              tracking.qualityofhire.settingsModalSetOpen(true)
              props.setSettingsModalOpen(true)
            }}
          >
            <Icon name={'cog'} />
          </Button>
          <Button
            variant="accent"
            disabled={!userHasVerifiedEmail}
            onClick={() => {
              tracking.qualityofhire.addNewHireModalSetOpen(true)
              setAddHireModalOpen(true)
            }}
          >
            <ButtonContent icon={{ name: 'plus', ariaLabel: 'add new hire' }}>{'Add new hire'}</ButtonContent>
          </Button>
        </ButtonContainer>
      </ButtonTooltip>
    </Tooltip>
  )

  return (
    <ProductPageShell
      title={'Quality of Hire Analytics'}
      button={props.qohEnabled ? settingsAndAddHireButtons : props.enableButton}
      overflow
    >
      <SettingsModal
        open={props.isSettingsModalOpen}
        setOpen={(open: boolean) => {
          tracking.qualityofhire.settingsModalSetOpen(open)
          return props.setSettingsModalOpen(open)
        }}
        data={{
          enabled: props.qohEnabled,
          timelineDays: props.qohTimelineDays,
        }}
        submit={props.updateSettings}
      />
      {props.qohEnabled ? (
        <>
          <NewHireModal
            open={isAddHireModalOpen}
            setOpen={(open: boolean) => {
              tracking.qualityofhire.addNewHireModalSetOpen(open)
              setAddHireModalOpen(open)
            }}
            submit={props.addHire}
          />
          {props.employees?.length ? (
            <StatCards
              overallScore={props.overallScore}
              managerScore={props.managerScore}
              newHireScore={props.newHireScore}
            />
          ) : (
            ''
          )}
          <Table
            employees={props.employees || []}
            hasMore={props.hasMore}
            loadNext={props.fetchData}
            isAddHireModalOpen={isAddHireModalOpen}
            setAddHireModalOpen={(open: boolean) => {
              tracking.qualityofhire.addNewHireModalSetOpen(open)
              setAddHireModalOpen(open)
            }}
            removeHire={props.removeHire}
            sortingField={props.sortingField}
            setSortingField={props.setSortingField}
            desc={props.desc}
            setDesc={props.setDesc}
          />
        </>
      ) : (
        <EmptyQualityOfHirePageUI button={props.enableButton} />
      )}
    </ProductPageShell>
  )
}

interface QualityOfHirePageProps {}

const QualityOfHirePage: React.FC<QualityOfHirePageProps> = () => {
  const dispatch = useDispatch()
  const user = useUser()

  const canManageOrg = useSelector((state: RootState) => selectors.users.canManageOrganization(state, user.id))

  const org = useSelector((state: RootState) => selectors.orgs.findByUserId(state, user.id))

  const orgSettings = useSelector((state: RootState) => selectors.orgSettings.getByOrgId(state, org?.id || ''))

  const { isEnabled: isFeatureFlagEnabled } = useFeatureFlag('quality-of-hire')

  const isQualityOfHireEnabled = isFeatureFlagEnabled && orgSettings?.fields.quality_of_hire_enabled === true

  const hasMore = useSelector((state: RootState) => state.employees.hasMore)

  const employeeList: employees.Fields[] = useSelector((state: RootState) => {
    return selectors.employees.findEmployees(state)
  })

  const [sortField, sortOrder]: [RootState['employees']['sortedBy']['field'], boolean] = useSelector(
    (state: RootState) => {
      const sortField = state.employees.sortedBy.field || defaultSortField
      const sortOrder = state.employees.sortedBy.order || defaultSortOrder
      const desc = sortOrder === listing.SortOrder.Desc

      return [sortField, desc]
    },
  )

  const fetchData = useCallback(
    (query: Query) => {
      if (isQualityOfHireEnabled && org?.id) {
        dispatch(loadEmployees(org?.id || '', query.offset))
        dispatch(loadByProduct('quality_of_hire'))
      }
    },
    [org?.id, dispatch, sortField, sortOrder, isQualityOfHireEnabled],
  )

  useEffect(() => {
    if (isQualityOfHireEnabled && org?.id && employeeList.length) {
      employeeList.forEach(employee => {
        dispatch(loadEmployeeById(org?.id, employee.id, 'questionnaire_submissions'))
      })
    }
  }, [employeeList.length])

  useEffect(() => {
    if (isQualityOfHireEnabled && org?.id) {
      dispatch(loadEmployees(org?.id || ''))
      dispatch(loadByProduct('quality_of_hire'))
    }
  }, [org?.id, sortField, sortOrder, dispatch, isQualityOfHireEnabled])

  const [isSettingsModalOpen, setSettingsModalOpen] = useState(false)

  const enableButton = (
    <Button
      variant="accent"
      onClick={() => {
        tracking.qualityofhire.settingsModalSetOpen(true)
        tracking.qualityofhire.enableCtaButtonClicked()
        setSettingsModalOpen(true)
      }}
    >
      Enable Quality of Hire
    </Button>
  )

  const scores = useSelector((state: RootState) => {
    return state.reports.qohStats
  })

  if (org?.fields.plan !== 'normal') {
    return (
      <DashboardLayout>
        <ProductPageShell title={'Quality of Hire Analytics'} overflow>
          <EmptyQualityOfHirePageUI button={<UpgradeButton orgId={org?.id || ''} product={'quality-of-hire'} />} />
        </ProductPageShell>
      </DashboardLayout>
    )
  }

  return (
    <DashboardLayout>
      <QualityOfHirePageUI
        addHire={async (employeeFields: employees.CreateFields): Promise<boolean> => {
          tracking.qualityofhire.addNewHireModalConfirmClicked()
          if (org?.id) {
            return (await dispatch(create(org?.id, employeeFields))) as any as boolean
          }
          return false
        }}
        removeHire={(employeeId: string) => {
          tracking.qualityofhire.employeeRemoved()
          if (org?.id) {
            return dispatch(remove(org?.id, employeeId))
          }
        }}
        updateSettings={(fields: QualityOfHireSettings) => {
          tracking.qualityofhire.settingsModalConfirmClicked()
          if (org?.id) {
            return dispatch(
              save(
                org?.id,
                {},
                {
                  qualityOfHireEnabled: fields.enabled,
                  qualityOfHireSurveyInterval: fields.timelineDays,
                },
              ),
            )
          }
        }}
        hasMore={hasMore}
        canManageOrg={canManageOrg}
        qohEnabled={(isQualityOfHireEnabled && orgSettings?.fields.quality_of_hire_enabled) || false}
        qohTimelineDays={orgSettings?.fields.quality_of_hire_survey_interval || 90}
        employees={employeeList}
        fetchData={fetchData}
        sortingField={sortField}
        setSortingField={(field: SortField) => dispatch(setSortField(field))}
        desc={sortOrder}
        setDesc={(order: listing.SortOrder) => dispatch(setSortDesc(order))}
        overallScore={scores.overall_score}
        managerScore={scores.managers_evaluation_score}
        newHireScore={scores.new_hire_experience_score}
        isSettingsModalOpen={isSettingsModalOpen}
        setSettingsModalOpen={setSettingsModalOpen}
        enableButton={enableButton}
      />
    </DashboardLayout>
  )
}

export default QualityOfHirePage
