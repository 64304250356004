import { gradientColor } from 'components/Heatmap/Score'
import { pct, px2rem, size, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { IOptions as PositionOptions } from 'core/style/position'
import React from 'react'

const ScoreBar = style()
  .size({ width: size.fill, height: size.fill })
  .bg({ color: designSystemColors.backgroundNeutralTertiary })
  .relative()
  .border({ radius: px2rem(2) })
  .nooverflow()
  .element()

const Overlay = style()
  .absolute({ top: 0, right: 0, bottom: 0, left: 0 })
  .spacing({ outer: size.auto })
  .size({ height: px2rem(8), width: size.fill })
  .flex({ justifyContent: 'space-evenly' })
  .element()

const Segment = style()
  .set('mixBlendMode', 'multiply')
  .size({ height: size.fill, width: px2rem(2) })
  .border({ radius: px2rem(2) })
  .color({ bg: designSystemColors.backgroundNeutralTertiary })
  .element()

const Score = style()
  .size({ height: size.fill })
  .with(
    ({
      score = 0,
      maxDisplayedScore = 1,
      bipolar,
    }: {
      score: number
      maxDisplayedScore: number
      bipolar?: boolean
    }) => {
      let width = Math.abs(score) * (100 / maxDisplayedScore)
      let colour = gradientColor(Math.abs(score), maxDisplayedScore)
      let position: PositionOptions = {}
      if (bipolar) {
        position = score > 0 ? { left: pct(50) } : { right: pct(50) }
        colour = designSystemColors.backgroundSecondaryAccent
        width /= 2
      }
      return style()
        .absolute(position)
        .set('backgroundColor', colour)
        .size({ width: `${width}%` })
    },
  )
  .element()

interface ScoreBarProps {
  score: number | null
  maxDisplayedScore?: number
  segments?: number
  bipolar?: boolean
}

const Component: React.FC<ScoreBarProps> = ({ score, maxDisplayedScore = 5, segments = 10, bipolar = false }) => {
  return (
    <ScoreBar>
      <Score score={score} maxDisplayedScore={maxDisplayedScore} bipolar={bipolar} />
      <Overlay>
        {[...Array(segments - 1)].map((_, i) => (
          <Segment key={i} value={i} />
        ))}
      </Overlay>
    </ScoreBar>
  )
}

export default Component
Component.displayName = 'ScoreBar'
