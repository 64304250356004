import { px2rem, size, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import React from 'react'

interface Props {
  value: number | null
  showPreciseValue?: boolean
  maxDisplayedScore?: number
}

const Container = style()
  .size({ width: size.fill, height: size.fill })
  .center()
  .with((props: { count: number | null }) => style().set('backgroundColor', gradientColor(props.count)))
  .border({ radius: px2rem(2) })
  .fg(designSystemColors.typographyPrimary)
  .sans({ size: px2rem(12) })
  .element()

const Score: React.FC<Props> = ({ value, showPreciseValue }) => {
  const formattedScore = format(value, showPreciseValue)
  return <Container count={value}>{formattedScore || '-'}</Container>
}

export function gradientColor(value: number | null, maxDisplayedScore = 5): string {
  const colors = [
    '#fcd0a1',
    '#f8dfaf',
    '#f6e9bc',
    '#f4f0c8',
    '#f1f2ce',
    '#ddeab8',
    '#c3e0a4',
    '#a5d491',
    '#85c77f',
    '#73ba7c',
  ]

  if (!value) return '#F9F9F9'

  const gradMax = colors.length - 1
  const gradIndex = Math.floor((gradMax * value) / maxDisplayedScore)
  const gradIndexLim = Math.min(gradIndex, gradMax)

  return colors[gradIndexLim]
}

function format(score: number | null, showPreciseValue: boolean | undefined): string | null {
  if (typeof score !== 'number') {
    return null
  }

  if (showPreciseValue) {
    return score.toFixed(2)
  }

  return score.toFixed(0)
}

export default Score
