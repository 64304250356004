import { Fields, RESOURCE } from 'api/groups'
import { Entity } from 'api/request'
import { RootState } from 'store'
import lookup from './lookup'

export function findById(state: RootState, id: string): Entity<Fields> {
  return lookup<Fields>(state, RESOURCE, id) as Entity<Fields>
}

export function findBySlug(state: RootState, slug: string): Entity<Fields> | undefined {
  return Object.values(state.resources)
    .filter(r => r.resource === RESOURCE)
    .find(r => r.fields.slug === slug)
}

export function list(state: RootState): Entity<Fields>[] {
  return Object.values(state.resources).filter(r => r.resource === RESOURCE)
}
