import { px2rem, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React from 'react'

const EstimationContainer = style().flex().element()
const Estimation = text.bodyText().color({ fg: designSystemColors.typographySecondary }).element('p')
const EstimationDuration = text
  .bodyEmphasis()
  .color({ fg: designSystemColors.typographySecondary })
  .spacing({ innerLeft: px2rem(4) })
  .element('span')

interface TimeEstimationLabelProps {
  estimation: string
}

const TimeEstimationLabel: React.FC<TimeEstimationLabelProps> = ({ estimation }) => {
  return (
    <EstimationContainer>
      <Estimation>{`⏳ Survey length: `}</Estimation>
      <EstimationDuration>{estimation}</EstimationDuration>
    </EstimationContainer>
  )
}

export default TimeEstimationLabel
