import action from './action'
import events from './events'
import track from './track'

interface Props {
  title: string
}

export const faqBlockOpened = action((props: Props) => {
  track(events.CANDIDATE_ONBOARDING_FAQ_BLOCK_OPENED, {
    Title: props.title,
  })
})

export const faqBlockClosed = action((props: Props) => {
  track(events.CANDIDATE_ONBOARDING_FAQ_BLOCK_CLOSED, {
    Title: props.title,
  })
})

export const faqDocumentationClicked = action((props: Props) => {
  track(events.CANDIDATE_ONBOARDING_DOCUMENTATION_BUTTON_CLICKED, {
    Title: props.title,
  })
})

export const supportButtonClicked = action(() => track(events.CANDIDATE_ONBOARDING_SUPPORT_BUTTON_CLICKED, {}))

export const missingInformationButtonClicked = action(() =>
  track(events.CANDIDATE_ONBOARDING_MISSING_INFORMATION_CLICKED, {}),
)

export const confirmStartDateManager = action(() => track(events.CANDIDATE_ONBOARDING_CONFIRM_START_DATE_MANAGER, {}))

export const skipStartDateManager = action(() => track(events.CANDIDATE_ONBOARDING_SKIPS_START_DATE_MANAGER, {}))

export const exitStartDateManager = action(() => track(events.CANDIDATE_ONBOARDING_EXITS_START_DATE_MANAGER, {}))

export const confirmEmployeeEmail = action(() => track(events.CANDIDATE_ONBOARDING_CONFIRM_EMPLOYEE_EMAIL, {}))

export const skipEmployeeEmail = action(() => track(events.CANDIDATE_ONBOARDING_SKIPS_EMPLOYEE_EMAIL, {}))

export const summaryMarkedAsUseful = action((props: Props) =>
  track(events.CANDIDATE_ONBOARDING_SUMMARY_USEFUL, {
    Title: props.title,
  }),
)

export const summaryMarkedAsNotUseful = action((props: Props) =>
  track(events.CANDIDATE_ONBOARDING_SUMMARY_NOT_USEFUL, {
    Title: props.title,
  }),
)
