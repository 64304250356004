import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useConfirm } from '../../../components/Confirm'
import CreateButton from '../../../components/CreateButton'
import Modal from '../../../components/Modal'
import TextField from '../../../components/TextField'
import { Buttons, Content, ContentAndButtons } from '../../../components/Wizard'
import { Subheading } from '../../../components/Wizard/Typography'
import { colors, fr, rem, scale, size, space, style } from '../../../core'
import * as tracking from '../../../core/track'
import * as selectors from '../../../selectors'
import { RootState } from '../../../store'
import { share } from '../../../store/candidates'
import NameList from './NameList'

interface Props {
  candidateId: string
  open: boolean
  setOpen: (b: boolean) => void
}

const Container = style()
  .size({
    maxWidth: rem(40),
    width: 'calc(100vw - 200px)',
    height: rem(26),
  })
  .element()

const Disclaimer = style()
  .spacing({ outerTop: space.s })
  .sans({ size: scale.xs, align: 'center', color: colors.gray })
  .set('fontStyle', 'italic')
  .element()

const AddEmail = style()
  .select('> *', style().size({ height: size.fill }))
  .grid({ columns: [fr(1), size.auto] })
  .spacing({ column: space.s })
  .element()

const Component = (props: Props) => {
  const dispatch = useDispatch()
  const confirm = useConfirm()

  const [inviteEmail, setInviteEmail] = useState('')

  const [candidateProfile, openJobRole, org, user]: [
    ReturnType<typeof selectors.candidateProfiles.findByCandidateId>,
    ReturnType<typeof selectors.roles.findByCandidateId>,
    ReturnType<typeof selectors.orgs.getById>,
    ReturnType<typeof selectors.users.current>,
  ] = useSelector((state: RootState) => [
    selectors.candidateProfiles.findByCandidateId(state, props.candidateId),
    selectors.roles.findByCandidateId(state, props.candidateId),
    selectors.orgs.getById(state, selectors.orgs.currentId(state)),
    selectors.users.current(state),
  ])

  const possessiveFirstName = selectors.possessiveFirstName(candidateProfile?.fields?.full_name || '')

  const invalidEmail = !selectors.isEmailValid(inviteEmail)

  return (
    <Modal title={`Share ${possessiveFirstName} profile`} open={props.open} setOpen={props.setOpen}>
      <Container>
        <ContentAndButtons>
          <Content standalone>
            <Subheading>Share a password-protected, view-only candidate profile with others.</Subheading>
            <AddEmail>
              <TextField
                error={!!inviteEmail && invalidEmail}
                onChange={e => setInviteEmail(e.target.value)}
                value={inviteEmail}
                placeholder="Email address"
              />
              <CreateButton onClick={onClickShare}>Add</CreateButton>
            </AddEmail>
            <NameList candidateId={props.candidateId} />
            <Disclaimer>
              Users invited to the Role "{openJobRole?.fields.name}" also have access to this candidate profile
            </Disclaimer>
          </Content>
          <Buttons
            forwardOnly
            next={() => {
              tracking.candidates.shareModalDoneClicked()
              props.setOpen(false)
            }}
            back={() => {}}
            label={'Done'}
          />
        </ContentAndButtons>
      </Container>
    </Modal>
  )

  function emailIsInSameOrg(): boolean {
    const domain = inviteEmail.split('@')?.[1]
    if (!domain) return false

    if (org?.fields.email_domain && org?.fields.email_domain === domain) {
      return true
    }

    if (user?.fields.email && user.fields.email.endsWith(domain)) {
      return true
    }

    return false
  }

  async function onClickShare() {
    tracking.candidates.sharedInvitationSend()
    if (
      !emailIsInSameOrg() &&
      !(await confirm({
        title: 'Sharing outside your organization',
        message:
          'It looks like you are intending to share this page with someone outside of your organization. Please, note that it contains potentially sensitive information. Continue anyway?',
        confirmLabel: 'Yes, continue',
        danger: true,
      }))
    )
      return

    dispatch(share(props.candidateId, inviteEmail))
    setInviteEmail('')
  }
}

export default Component
Component.displayName = 'ShareCandidateLinkModal'
