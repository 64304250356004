import { EvaluationStatus } from 'api/candidates'
import CandidateOnboarding from 'App/Candidate/Onboarding'
import Responses from 'App/Candidate/Responses'
import ContentTabs from 'App/Candidate/SelfAssessment/ContentTabs'
import { BlockListByResults } from 'App/Candidate/Summary/Overview/BlockList'
import WaitingOnCandidate from 'App/Candidate/WaitingOnCandidate'
import { text } from 'core/design-system/text'
import * as tracking from 'core/track'
import { useEvaluationStatus } from 'providers/candidates/hire-status'
import { useFeatureFlag } from 'providers/features'
import { usePostHireOnboardingSummaries } from 'providers/onboarding-summaries'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useParams } from 'react-router-dom'
import { RootState } from 'store'
import { fetchItemScores, sendReferenceCheck } from 'store/candidates'
import { px2rem, size, space, style } from '../../../core'
import * as selectors from '../../../selectors'
import SharedProfileShell from '../../Shared/ProfileShell'
import NoInviteYet from '../NoInviteYet'
import ProfileShell from '../ProfileShell'
import { ReferenceList } from '../ReferenceCard'
import { SummaryBlocks } from './SummaryBlocks'

const SummaryContainer = style()
  .size({ width: size.fill })
  .spacing({ innerBottom: px2rem(32) })
  .element()

const DisclaimerText = text
  .bodyText()
  .text({ italic: true, align: 'center' })
  .spacing({ outerBottom: space.xxs })
  .element('p')

interface SummaryProps {
  isInSharedViewMode?: boolean
}

export const RefCheckSummary: React.FC<SummaryProps> = props => {
  const dispatch = useDispatch()
  const { candidateid: candidate_id } = useParams<{
    candidateid: string
  }>()
  const candidateid = candidate_id || ''

  const candidate = useSelector((state: RootState) => selectors.candidates.findById(state, candidateid))
  const profile = useSelector((state: RootState) => selectors.candidateProfiles.findByCandidateId(state, candidateid))
  const role = useSelector((state: RootState) => selectors.roles.findByCandidateId(state, candidateid))

  const emptyStatus = useSelector((state: RootState) => selectors.candidates.emptyStatus(state, candidateid))

  const rootUrl = props.isInSharedViewMode
    ? `/shared/candidates/${candidate?.id || ''}/reference-check`
    : `/roles/${role?.id || ''}/candidates/${candidate?.id || ''}/reference-check`

  const { isEnabled: onboardingEnabled } = useFeatureFlag('onboarding')

  const employeeId = useSelector(
    (state: RootState) => selectors.employees.findByCandidateId(state, candidateid || '')?.id,
  )

  const { employeeExperience, managerEvaluation } = usePostHireOnboardingSummaries({
    employeeId: employeeId || '',
  })

  const postHireData = employeeExperience && managerEvaluation

  const preHireData = useSelector((state: RootState) =>
    selectors.onboardingSummaries.findShowOnboardingSectionByCandidateId(state, candidateid || ''),
  )

  const shouldShowOnboarding = Boolean((preHireData || postHireData) && onboardingEnabled)

  const isOrgPaidPlan = useSelector(selectors.orgs.isOrgPaidPlan)

  useEffect(() => {
    if (!candidate) {
      dispatch(fetchItemScores(candidateid ? candidateid : ''))
    } else {
      dispatch(fetchItemScores(candidate.id))
    }
  }, [candidateid])

  const overviewBlocks = useSelector((state: RootState) =>
    selectors.candidates.overviewBlocks(state, candidateid, candidate?.fields.reference_check_status || 'in_progress'),
  )

  const { updateEvaluationStatus } = useEvaluationStatus()
  const [evaluationStatus, setEvaluationStatus] = useState<EvaluationStatus>(
    candidate?.fields.evaluation_status || 'to-review',
  )

  useEffect(() => {
    if (candidate?.fields.evaluation_status) {
      setEvaluationStatus(candidate?.fields.evaluation_status)
    }
  }, [candidate, candidate?.fields, candidate?.fields.evaluation_status])

  if (!candidateid) return null

  const breadcrumbs =
    role && profile
      ? [
          {
            url: props.isInSharedViewMode ? undefined : `/roles/${role ? role.id : ''}`,
            label: role.fields.name,
          },
          {
            url: props.isInSharedViewMode ? undefined : `/roles/${role ? role.id : ''}/candidates/${candidate?.id}`,
            label: profile.fields.full_name,
          },
        ]
      : []

  const button =
    emptyStatus?.type === 'not-started'
      ? {
          copy: `Send out Reference Check`,
          send: () => dispatch(sendReferenceCheck(candidateid || '')),
        }
      : undefined

  const emptyUI = (() => {
    if (emptyStatus && emptyStatus?.type === 'not-started') {
      return (
        <>
          <ReferenceList isInSharedViewMode={props.isInSharedViewMode} />
          <NoInviteYet messageCopy={emptyStatus.status} />
        </>
      )
    }

    if (emptyStatus?.type === 'no-invitation') {
      return (
        <>
          <ReferenceList isInSharedViewMode={props.isInSharedViewMode} />
          <WaitingOnCandidate {...emptyStatus} />
        </>
      )
    }
  })()

  const isStillInProgress =
    candidate?.fields.reference_check_status === 'in_progress' ||
    candidate?.fields.reference_check_status === 'not_started'

  const tabs = (
    <ContentTabs
      tabs={[
        {
          title: 'Overview',
          to: `${rootUrl}/overview`,
          enabled: true,
          page: (
            <SummaryContainer>
              {emptyStatus ? (
                emptyUI || <WaitingOnCandidate {...emptyStatus} />
              ) : (
                <>
                  <DisclaimerText>
                    Please note that this overview is generated automatically and is intended to serve only as a
                    reference point.
                  </DisclaimerText>
                  <BlockListByResults overview={overviewBlocks} isStillInProgress={isStillInProgress} />
                </>
              )}
            </SummaryContainer>
          ),
          tracking: () => {},
        },
        {
          title: 'Results',
          to: `${rootUrl}/reference-results`,
          enabled: true,
          page: (
            <SummaryContainer>
              {emptyUI || (
                <>
                  <ReferenceList isInSharedViewMode={props.isInSharedViewMode} />
                  {!emptyStatus && <SummaryBlocks />}
                </>
              )}
            </SummaryContainer>
          ),
          tracking: () => {},
        },
        {
          title: 'Responses',
          to: `${rootUrl}/responses`,
          enabled: true,
          page: (
            <SummaryContainer>
              {emptyUI || (
                <>
                  <ReferenceList isInSharedViewMode={props.isInSharedViewMode} />
                  {!emptyStatus && <Responses roleId={role?.id || ''} candidateId={candidate?.id || ''} />}
                </>
              )}
            </SummaryContainer>
          ),
          tracking: () => {},
        },
        {
          title: 'Onboarding',
          to: `${rootUrl}/onboarding`,
          enabled: shouldShowOnboarding,
          isLocked: !isOrgPaidPlan,
          locked: {
            modalData: {
              title: 'You have found a premium feature!',
              learnMoreAbout: 'Onboarding',
              learnMoreLink: 'https://intercom.help/hipeople/en/articles/8915380-onboarding-insights',
            },
            upgradeTo: 'Scale',
            tracking: tracking.lockedContent,
          },
          page: (
            <SummaryContainer>
              {!isOrgPaidPlan && <Redirect to={`${rootUrl}/overview`} />}
              {emptyUI || (
                <>
                  <CandidateOnboarding isInSharedViewMode={props.isInSharedViewMode ?? false} />
                </>
              )}
            </SummaryContainer>
          ),
          tracking: () => {},
        },
      ]}
    />
  )

  if (props.isInSharedViewMode) {
    return (
      <SharedProfileShell candidateId={candidateid || ''} roleId={role?.id || ''} breadcrumbs={breadcrumbs}>
        {tabs}
      </SharedProfileShell>
    )
  }

  return (
    <>
      <ProfileShell
        candidateId={candidateid || ''}
        roleId={role?.id || ''}
        breadcrumbs={breadcrumbs}
        button={button}
        evaluationStatus={evaluationStatus}
        onEvaluationStatusChanged={newStatus => {
          if (candidate) {
            setEvaluationStatus(newStatus)
            updateEvaluationStatus(candidate.id, newStatus)
            tracking.candidates.changeEvaluationStatus()
          }
        }}
      >
        {tabs}
      </ProfileShell>
    </>
  )
}

export default RefCheckSummary
