import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { RootState } from 'store'
import { create } from 'store/webhooks'
import { webhooks } from '../../../api'
import Block from '../../../components/Block'
import Button from '../../../components/Button/ButtonV1'
import Buttons from '../../../components/Buttons'
import DashboardLayout, { Content, Header } from '../../../components/DashboardLayout'
import Form from '../../../components/Form'
import * as selectors from '../../../selectors'
import WebhookForm from '../Webhooks/WebhookForm'

const Component = () => {
  const dispatch = useDispatch()
  const orgId = useSelector((state: RootState) => selectors.orgs.currentId(state))

  const [changes, createdId]: [RootState['webhooks']['changes'], RootState['webhooks']['createdId']] = useSelector(
    (state: RootState) => {
      return [state.webhooks.changes, state.webhooks.createdId]
    },
  )

  const webhook: webhooks.CreateWebhookPayload = {
    title: '',
    organization_id: orgId,
    resource: webhooks.resources[0],
    action: webhooks.actions[0],
    target_method: webhooks.methods[0],
    target_url: '',
  }

  if (createdId) return <Redirect to={'/settings/webhooks'} />

  return (
    <DashboardLayout>
      <Header
        breadcrumbs={[
          { label: 'Settings' },
          { url: `/settings/webhooks`, label: 'Webhooks' },
          { url: `/settings/webhooks/create`, label: 'Create Webhook' },
        ]}
      ></Header>
      <Content>
        <Block title="New Webhook">
          <Form>
            <WebhookForm createMode webhook={webhook} />
            <Buttons>
              <Button primary onClick={() => dispatch(create({ ...webhook, ...changes }))}>
                Save
              </Button>
            </Buttons>
          </Form>
        </Block>
      </Content>
    </DashboardLayout>
  )
}

export default Component
Component.displayName = 'CreateWebhooks'
