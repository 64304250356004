import { map } from './css'
import Rows from './rows'

export interface IOptions {
  absolute?: boolean
  relative?: boolean
  fixed?: boolean
  sticky?: boolean
  top?: string | number
  bottom?: string | number
  left?: string | number
  right?: string | number
}

export default function position(rows: Rows, options: IOptions) {
  if (options.absolute) {
    rows.set('position', 'absolute')
  } else if (options.relative) {
    rows.set('position', 'relative')
  } else if (options.fixed) {
    rows.set('position', 'fixed')
  } else if (options.sticky) {
    rows.set('position', 'sticky')
  }

  map(rows, options, {
    top: 'top',
    bottom: 'bottom',
    left: 'left',
    right: 'right',
  })
}
