import { Entity } from 'api/request'
import { RESOURCE, WorkVerification } from 'api/work-verifications'
import { RootState } from 'store'
import * as candidates from './candidates'
import lookup from './lookup'

export function findById(state: RootState, id: string) {
  return lookup<WorkVerification>(state, RESOURCE, id)
}

export function findByCandidateId(state: RootState, candidateId: string) {
  const candidate = candidates.findById(state, candidateId)
  if (!candidate || !candidate.relationships) return undefined

  const result: Entity<WorkVerification>[] = []

  for (const r of candidate.relationships) {
    if (r.resource !== RESOURCE) continue

    const ref = findById(state, r.id)
    if (!ref) continue

    result.push(ref)
  }

  return result
}
