export const RESOURCE = 'assessment_versions'

export interface Fields {
  id: string
  title: string
  scoring: AssessmentScoring
  scales: Scale[]
}

interface AssessmentScoring {
  benchmark: number
  subscore_weights: SubscoreWeight[]
  scale_weights: ScaleWeight[]
}

interface SubscoreWeight {
  subscore_id: string
  weight: number
}

interface ScaleWeight {
  scale_id: string
  weight: number
}

interface BenchmarkTag {
  content_id: string
  threshold: number
  tag: string
}

interface Scale {
  content_id: string
  title: string
  is_soft_skill: boolean
  is_hard_skill: boolean
  is_job_skill: boolean
  benchmark: number
  benchmark_tags: BenchmarkTag[] | null
  subscore: Subscore
  description: string
  items: Item[]
}

interface Subscore {
  content_id: string
  title: string
  description: string
  benchmark: number
}

export interface Item {
  content_id: string
  associated_content_id?: string
  is_full_name: boolean
  is_email: boolean
  is_work_sample: boolean
  is_visible_to_recruiter: boolean
  is_tech_skills_list: boolean
  is_start_date: boolean
  is_salary: boolean
  is_business_email: boolean
  response_options: Optionset
  dashboard_view: DashboardView
}

export interface DashboardView {
  content_id: string
  title: string
  description: string
  list_column: boolean
  profile_sidebar: boolean
  reference_profile: boolean
  display_as_tags: boolean
  display_as_pairwise_comparison: boolean
  start_statement: string
  end_statement: string
  group: string
  format_as_date: boolean
  format_as_currency: boolean
  column_width: boolean
  column_title: string
  parent_question_id: string
  highlight_recent_outstanding_option: boolean
  chart?: {
    line_chart: {
      render_slider_values_by_year: boolean
      render_options_by_year: boolean
    }
    radar_chart: {
      render_chip_labels: boolean
    }
  }
}

interface Optionset {
  content_id: string
  multiselect: boolean
  ranking: boolean
  options: Option[]
}

export interface Option {
  content_id: string
  type: string
  caption: string
  description: string
  value: number
}
