import { useEffect } from 'react'

export default function useKeypress(key: string, modifiers: string[], callback: (event: KeyboardEvent) => void) {
  useEffect(() => {
    function onKeyDown(event: KeyboardEvent) {
      const allModifiersPressed = modifiers.every(modifier => event.getModifierState(modifier))
      if (allModifiersPressed && event.key === key) {
        callback(event)
      }
    }
    window.addEventListener('keydown', onKeyDown)
    return () => window.removeEventListener('keydown', onKeyDown)
  }, [callback])
}
