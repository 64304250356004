import { Icon } from 'components/Icons'
import React from 'react'
import classes from './Status.module.scss'

export const PositiveStatus: React.FC = () => {
  return (
    <div className={`${classes.iconContainer} ${classes.positive}`}>
      <div className={`${classes.icon} ${classes.positiveIcon}`}>
        <Icon name={'check-circle'} variant={'solid'} />
      </div>
    </div>
  )
}

export const NegativeStatus: React.FC = () => {
  return (
    <div className={`${classes.iconContainer} ${classes.negative}`}>
      <div className={`${classes.icon} ${classes.negativeIcon}`}>
        <Icon name={'exclamation-triangle'} variant={'solid'} />
      </div>
    </div>
  )
}

export const UnselectedStatus: React.FC = () => {
  return (
    <div className={`${classes.iconContainer} ${classes.unselected}`}>
      <div className={`${classes.icon} ${classes.unselectedIcon}`}>
        <Icon name={'question-circle'} variant={'solid'} />
      </div>
    </div>
  )
}

export const IncompleteStatus: React.FC = () => {
  return (
    <div className={`${classes.iconContainer} ${classes.incomplete}`}>
      <div className={`${classes.icon} ${classes.incompleteIcon}`}>
        <Icon name={'clock'} variant={'solid'} />
      </div>
    </div>
  )
}
