import { Score, ScoreResponse } from '@common/api/score'
import { InterpretationIcon, ScoreGroup, SimpleTable, Text, Tooltip } from '@common/components'
import { Icon } from 'components/Icons'
import React from 'react'
import { useSelector } from 'react-redux'
import * as selectors from 'selectors'
import { RootState } from 'store'
import { Group } from './AssessmentPage/AssessmentPage'

interface HardSkillsDashboardProps {
  candidateFirstName: string
  hardSkillsScores: Score | undefined
  candidateId: string
  roleId: string
  isSharedResults?: boolean
  defaultCollapsed?: boolean
  onResponsesClick: (moduleSlug: string, group: Group) => void
}

const HardSkillsScoreGroup: React.FC<HardSkillsDashboardProps> = props => {
  const group = useSelector((state: RootState) => {
    const group_redux = selectors.groups.findBySlug(state, props.hardSkillsScores?.slug || '')
    return {
      id: group_redux?.id || '',
      name: props.hardSkillsScores?.name || group_redux?.fields?.copy?.title || '',
      slug: group_redux?.fields?.slug || '',
      copy: {
        title: group_redux?.fields?.copy?.title || '',
        icon: group_redux?.fields?.copy?.icon ? <Icon name={group_redux?.fields?.copy?.icon} /> : '🛠',
        emoji: group_redux?.fields?.copy?.emoji,
        theme: group_redux?.fields?.copy?.theme || '',
        description: group_redux?.fields?.copy?.short_title || '',
      },
    }
  })

  const getModule = useSelector((state: RootState) => (slug: string) => selectors.modules.findBySlug(state, slug))

  const getModuleData = (score: ScoreResponse['meta'][number]) => {
    const module = getModule(score.slug ?? '')

    return {
      description: group?.copy?.description || module?.fields?.copy?.description || '',
      locale: module?.fields.locale || '',
      customDescription: !props.isSharedResults && (
        <>
          <Text variant="body-text">{getHardSkillsScoreInfoDescription(score, props.candidateFirstName)}</Text>
          {score.extra?.wpm !== undefined &&
            score.extra?.accuracy !== undefined &&
            score.extra?.correct_words !== undefined &&
            score.extra?.incorrect_words !== undefined &&
            score.extra?.missing_characters !== undefined &&
            score.extra?.extra_characters !== undefined && (
              <SimpleTable
                data={[
                  {
                    label: 'WPM',
                    value: <Tooltip text="Correctly typed words per minute">{score.extra.wpm}</Tooltip>,
                  },
                  {
                    label: 'Accuracy',
                    value: (
                      <Tooltip text="Percentage of correctly pressed keys">
                        {score.extra?.accuracy !== undefined ? score.extra?.accuracy : '-'}
                      </Tooltip>
                    ),
                  },
                  {
                    label: 'Characters',
                    value: (
                      <Tooltip text="Correct, incorrect, missed, and extra characters">
                        {score.extra.correct_words} • {score.extra.incorrect_words} • {score.extra.missing_characters} •{' '}
                        {score.extra.extra_characters}
                      </Tooltip>
                    ),
                  },
                ]}
              />
            )}
        </>
      ),
      isSharedResults: props.isSharedResults,
      learnings: module?.fields.copy?.feedback?.learnings,
    }
  }

  return (
    <ScoreGroup
      score={props.hardSkillsScores}
      group={group}
      onClickResponses={props.onResponsesClick}
      candidateFirstName={props.candidateFirstName}
      getModuleData={getModuleData}
      defaultCollapsed={props.defaultCollapsed}
    />
  )
}

export default HardSkillsScoreGroup

function getHardSkillsScoreInfoDescription(score: Score, firstName: string): string {
  const interpretationStatus = getInterpretationStatus(score.score)
  const interpretation = getHardSkillsInterpretationData(interpretationStatus, firstName)
  return interpretation.copy.description
}

type HardSkillsInterpretationState = 'High' | 'Low' | 'Average'

type HardSkillsInterpretationData = {
  icon: InterpretationIcon
  copy: { title: string; description: string }
  status: HardSkillsInterpretationState
}

const HARD_SKILLS_INTERPRETATION = {
  high: 'The candidate scored more than 2/3 of the questions correctly. This result corresponds to an advanced level of the assessed skill.',
  low: 'The candidate scored less than 1/3 of the questions correctly. This result corresponds to a beginner’s level of the assessed skill.',
  average:
    'The candidate scored an average of correct responses. This result corresponds to an intermediate level of the assessed skill.',
}

function getHardSkillsInterpretationData(
  resultState: HardSkillsInterpretationState,
  candidateFirstName: string,
): HardSkillsInterpretationData {
  switch (resultState) {
    case 'High': {
      return {
        icon: 'tick',
        copy: {
          title: `${candidateFirstName} achieved high scores`,
          description: HARD_SKILLS_INTERPRETATION.high,
        },
        status: resultState,
      }
    }
    case 'Low': {
      return {
        icon: 'cross',
        copy: {
          title: `${candidateFirstName} achieved low scores`,
          description: HARD_SKILLS_INTERPRETATION.low,
        },
        status: resultState,
      }
    }
    case 'Average': {
      return {
        icon: 'tick-warning',
        copy: {
          title: `${candidateFirstName} achieved average scores`,
          description: HARD_SKILLS_INTERPRETATION.average,
        },
        status: resultState,
      }
    }
  }
}

function getInterpretationStatus(achievedScorePercentage: number): HardSkillsInterpretationState {
  if (achievedScorePercentage < 33) {
    return 'Low'
  } else if (achievedScorePercentage <= 66) {
    return 'Average'
  } else {
    return 'High'
  }
}
