import React from 'react'
import { size, style } from '../../core'

interface Props {
  children?: React.ReactNode
  width?: string
  height?: string
  size?: string
}

const Container = style()
  .with((props: Props) => style().size({ height: props.height || size.auto }))
  .element()

export function Column({ children, height }: Props) {
  return <Container height={height}>{children}</Container>
}
