import action from './action'
import events from './events'
import track from './track'

// SIDEBAR
export const sidebarAssessmentPageViewed = action(() => {
  track(events.SIDEBAR_ASSESSMENT_VIEWED, {})
})

export const sidebarCreateAssessmentClicked = action(() => {
  track(events.SIDEBAR_CREATE_ASSESSMENT_CLICKED, {})
})

// SUMMARY PAGE
export const summaryTabViewed = action((assessmentName: string) => {
  track(events.SELF_ASSESSMENT_SUMMARY_TAB_SELECTED, {
    AssessmentType: assessmentName,
  })
})

// SOFT SKILLS
export const softSkillSelected = action((name: string) => {
  track(events.SELF_ASSESSMENT_CREATION_SOFT_SKILL_SELECTED, {
    Item: name,
  })
})

export const softSkillUnselected = action((name: string) => {
  track(events.SELF_ASSESSMENT_CREATION_SOFT_SKILL_UNSELECTED, {
    Item: name,
  })
})

// CUSTOM QUESTIONS
export const customQuestionsAddTextQuestion = action(() => {
  track(events.SELF_ASSESSMENT_CREATION_CUSTOM_QUESTIONS_ADD_TEXT_QUESTION, {})
})

export const customQuestionsAddSelectionQuestion = action(() => {
  track(events.SELF_ASSESSMENT_CREATION_CUSTOM_QUESTIONS_ADD_SELECTION_QUESTION, {})
})

export const customQuestionsAddVideoQuestion = action(() => {
  track(events.SELF_ASSESSMENT_CREATION_CUSTOM_QUESTIONS_ADD_VIDEO_QUESTION, {})
})

export const customQuestionsAllowMultiSelect = action(() => {
  track(events.SELF_ASSESSMENT_CREATION_CUSTOM_QUESTIONS_ALLOW_MULTI_SELECT, {})
})

export const testPreview = action((name: string) => {
  track(events.SELF_ASSESSMENT_CREATION_TEST_PREVIEW, { module_name: name })
})

export const unlockResponsesClicked = action(() => {
  track(events.UNLOCK_RESPONSES_CLICKED, {})
})

// AI ASSISTANT

export const aiDefaultPromptClicked = action((defaultPrompt: string) => {
  track(events.AI_DEFAULT_PROMPT_CLICKED, {
    defaultPrompt,
  })
})

export const aiCustomPromptSent = action(() => {
  track(events.AI_CUSTOM_PROMPT_SENT, {})
})

export const aiRegenerateAnswerClicked = action(() => {
  track(events.AI_REGENERATE_ANSWER_CLICKED, {})
})

export const aiReadMoreClicked = action(() => {
  track(events.AI_READ_MORE_CLICKED, {})
})
