import { get, put } from './methods'
import { APIResponse, Entity } from './request'

export const RESOURCE = 'sessions'

export interface Fields {
  token: string
  intercom_hmac: string
}

export function login(
  email?: string,
  password?: string,
  type?: string,
  code?: string,
): Promise<APIResponse<Entity<Fields>>> {
  return put('sessions', { email, password, type, code })
}

export function impersonate(userId: string): Promise<APIResponse<Entity<Fields>>> {
  return put('sessions/impersonate', { user_id: userId })
}

export function restore(): Promise<APIResponse<Entity<Fields>>> {
  return get('sessions')
}
