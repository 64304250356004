import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/react'
import App from './App'
import 'common/src/styles/primereact/datatable.scss'
import 'common/src/styles/primereact/datepicker.scss'
import 'common/src/styles/primereact/multiselect.scss'
import 'core/polyfills'
import 'primereact/resources/primereact.min.css'
import { hotjar } from 'react-hotjar'
import './index.scss'
import { getEnvironmentName } from 'api/request'

if (import.meta.env.PROD) {
  hotjar.initialize(3652238, 6)
}

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  normalizeDepth: 7,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      unmask: ['[data-sentry-unmask]'],
    }),
    Sentry.captureConsoleIntegration({ levels: ['error'] }),
  ],
  tracesSampleRate: 0.5,
  beforeSend(event) {
    event.tags = {
      ...event.tags,
      environment: getEnvironmentName(),
      branch: import.meta.env.HEAD, // this comes from Netlify and allows us to track which feature branch is being used
    }
    return event
  },
})

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!
const root = createRoot(container)
root.render(
  <StrictMode>
    <App />
  </StrictMode>,
)
