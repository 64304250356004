import React from 'react'
import { GroupIcon, ScoreGroupProps, ScoreVisualTypes } from './ScoreGroup'
import { Text } from '../../Text/Text'
import classes from './ScoreGroup.module.scss'

interface ScoreGroupHeaderProps {
  group: ScoreGroupProps['group']
  score: ScoreGroupProps['score']
  scoreType: ScoreVisualTypes
}

export const ScoreGroupHeader = (props: ScoreGroupHeaderProps) => {
  if (!props.score) return null
  return (
    <div className={`${classes.header} ${classes.fullWidthSubgridItem}`} data-testid="score-group-header">
      <div className={classes.titleContainer}>
        <GroupIcon theme={props.group.copy.theme} icon={props.group.copy.icon} emoji={props.group.copy.emoji} />
        <Text variant="card-header" style={{ marginLeft: '0.5rem' }}>
          {props.group.copy.title ?? props.score.name}
        </Text>
      </div>

      <div className={`${classes.score}`}>
        {props.scoreType !== 'pairwise' && <Text variant="card-header">{props.score.score.toFixed(0)}%</Text>}
      </div>
    </div>
  )
}
