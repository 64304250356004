import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as api from '../../api'
import { AppThunk } from '../../core/store'
import { add as notify } from '../notifications'
import { addEntities } from '../resources'

interface State {
  loading: boolean
  sent: boolean
  token?: string
  error: boolean
}

const name = api.oneTimePassword.RESOURCE
const initialState: State = {
  loading: false,
  sent: false,
  token: undefined,
  error: false,
}

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setIsSent(state, action: PayloadAction<boolean>) {
      state.sent = action.payload
    },
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload
    },
    setIsError(state, action: PayloadAction<boolean>) {
      state.error = action.payload
    },
  },
})

export const { setIsLoading, setIsSent, setToken, setIsError } = slice.actions

export default slice.reducer

export const load =
  (id: string): AppThunk =>
  async dispatch => {
    dispatch(setIsLoading(true))

    const [response, errors] = await api.oneTimePassword.load(id)

    dispatch(setIsLoading(false))

    if (errors) {
      dispatch(setIsError(true))
      return false
    }

    if (response) {
      dispatch(addEntities(response))
    }
  }

export const send =
  (id: string): AppThunk =>
  async dispatch => {
    dispatch(setIsLoading(true))

    const [response, errors] = await api.oneTimePassword.send(id)

    dispatch(setIsLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(addEntities(response))
      dispatch(setIsSent(true))
      dispatch(notify({ success: 'PIN sent! Please check your email inbox' }))
    }
  }

export const verify =
  (id: string, pin: string): AppThunk =>
  async dispatch => {
    dispatch(setIsLoading(true))

    const [response, errors] = await api.oneTimePassword.verify(id, pin)

    dispatch(setIsLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(setToken(response.result.fields.token))
      dispatch(addEntities(response))
    }
  }
