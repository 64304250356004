import Button, { ButtonContent } from 'components/Button/ButtonV2'
import { Icon } from 'components/Icons'
import { fr, px2rem, size, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React, { useState } from 'react'
import { CustomSkillModal } from './CustomSkillModal'
import { SkillSelectionModal } from './SkillSelectionModal'

const Title = text
  .highlight()
  .sans({ align: 'center' })
  .spacing({ outerBottom: px2rem(16) })
  .element('h1')
const Description = text
  .bodyText()
  .sans({ align: 'center' })
  .spacing({ outerBottom: px2rem(112) })
  .element('p')

const SkillsTable = style().element()
const SkillsTableHeader = style()
  .grid({ columns: [px2rem(28), size.auto, px2rem(16)] })
  .spacing({ gap: px2rem(12), outerBottom: px2rem(10) })
  .element()
const SkillsTableHeaderLabel = text.bodyInteractive().block().element('span')
const SkillQuestionRow = style()
  .grid({
    columns: [px2rem(28), size.auto, px2rem(16)],
    align: 'center',
  })
  .spacing({ gap: px2rem(12), outerBottom: px2rem(18) })
  .element()
const SkillQuestionOrder = text.bodyText().block().element('span')
const SkillQuestionName = style().element()
const SkillQuestionNameCell = text
  .bodyInteractive()
  .color({ bg: designSystemColors.backgroundNeutralPrimary })
  .spacing({ inner: [px2rem(16), px2rem(14)] })
  .border({
    color: designSystemColors.borderDefault,
    around: `solid ${px2rem(1)}`,
    radius: px2rem(6),
  })
  .element('span')
const SkillQuestionActions = style().element()
const SkillQuestionDeleteButton = style()
  .noborders()
  .block()
  .bg({ color: designSystemColors.transparent })
  .pointer()
  .element('button')

const Buttons = style()
  .grid({ columns: [fr(1), fr(1)] })
  .spacing({ gap: px2rem(16), outerTop: px2rem(64) })
  .element()

const TemplateCreationSkillsStyle = style()
  .spacing({ outer: [px2rem(0), size.auto] })
  .size({ width: px2rem(512) })
  .element()

export type TemplatedCreationSkillsValues = {
  selectedSkillIds: string[]
}

interface TemplateCreationSkillsProps {
  skills: { id: string; name: string }[]
  values: TemplatedCreationSkillsValues
  onChange: React.Dispatch<React.SetStateAction<TemplatedCreationSkillsValues>>
  next: () => any
  back: () => any
  createSkill: (skillName: string, func: (string) => any) => Promise<any>
}

export const TemplateCreationSkills: React.FC<TemplateCreationSkillsProps> = ({
  next,
  back,
  skills,
  values,
  onChange,
  createSkill,
}) => {
  const selectedSkills = skills.filter(({ id }) => values.selectedSkillIds.includes(id))

  const [isSkillSelectionModalShown, setIsSkillSelectionModalShown] = useState(false)

  const [isCustomSkillsModalShown, setIsCustomSkillsModalShown] = useState(false)

  return (
    <>
      <TemplateCreationSkillsStyle>
        <Title>{'Add skill questions'}</Title>
        <Description>{'Do you want to assess a specific skill? Add them here.'}</Description>
        <SkillsTable>
          <SkillsTableHeader>
            <SkillQuestionOrder></SkillQuestionOrder>
            <SkillQuestionName>
              <SkillsTableHeaderLabel>{`🛠 Skills (${selectedSkills.length})`}</SkillsTableHeaderLabel>
            </SkillQuestionName>
            <SkillQuestionActions></SkillQuestionActions>
          </SkillsTableHeader>
          {selectedSkills.map(({ id, name }, i) => (
            <SkillQuestionRow key={id}>
              <SkillQuestionOrder>{`${i + 1}.`}</SkillQuestionOrder>
              <SkillQuestionNameCell>{name}</SkillQuestionNameCell>
              <SkillQuestionActions>
                <SkillQuestionDeleteButton
                  onClick={() =>
                    onChange(values => ({
                      ...values,
                      selectedSkillIds: values.selectedSkillIds.filter(skillId => skillId !== id),
                    }))
                  }
                >
                  <Icon name="times" ariaLabel="Delete User" />
                </SkillQuestionDeleteButton>
              </SkillQuestionActions>
            </SkillQuestionRow>
          ))}
        </SkillsTable>
        <Button
          style={{ marginBottom: '0.5rem' }}
          buttonType="secondary-accent-fgbg"
          onClick={() => {
            setIsSkillSelectionModalShown(true)
          }}
          isFullWidth
        >
          <ButtonContent icon={{ name: 'plus', ariaLabel: 'Add tests' }}>{'Add skill question'}</ButtonContent>
        </Button>
        <Button
          buttonType="tertiary"
          onClick={() => {
            setIsCustomSkillsModalShown(true)
          }}
          isFullWidth
        >
          <ButtonContent>{'Create custom skill question'}</ButtonContent>
        </Button>
        <Buttons>
          <Button buttonType="secondary" onClick={back}>
            {'Back'}
          </Button>
          <Button buttonType="primary" onClick={next}>
            {'Next step'}
          </Button>
        </Buttons>
      </TemplateCreationSkillsStyle>
      {isSkillSelectionModalShown && (
        <SkillSelectionModal
          close={() => setIsSkillSelectionModalShown(false)}
          onSubmit={({ selectedSkillsId }) => {
            onChange(values => {
              const newValues = { ...values }
              newValues.selectedSkillIds = Array.from(new Set([...newValues.selectedSkillIds, ...selectedSkillsId]))
              return newValues
            })
          }}
          newSkillCallback={() => {
            setIsSkillSelectionModalShown(false)
            setIsCustomSkillsModalShown(true)
          }}
          skills={skills.filter(({ id }) => !selectedSkills.find(skill => id === skill.id))}
        />
      )}
      {isCustomSkillsModalShown && (
        <CustomSkillModal
          close={() => setIsCustomSkillsModalShown(false)}
          onSubmit={async ({ text }) => {
            await createSkill(text, id => (values.selectedSkillIds = [...values.selectedSkillIds, id]))
          }}
        />
      )}
    </>
  )
}
