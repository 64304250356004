import { Card } from '@common/components'
import * as api from 'api'
import { inititalReference as emptyReference } from 'App/Candidate/AddReferenceModal'
import InfoCard from 'App/CandidateOnly/InfoCard'
import { Link, Text, TextHeader } from 'App/CandidateOnly/Styles'
import Button from 'components/Button/ButtonV2'
import DropdownField, { Option } from 'components/DropdownField'
import TextField from 'components/TextField'
import { breakpoints, fr, rem, space, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import * as tracking from 'core/track'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useMount } from 'react-use'
import * as selectors from 'selectors'
import { Relationship, relationshipCopy } from 'selectors/references'
import { RootState } from 'store'
import * as slice from 'store/references'

const Container = style()
  .size({ maxWidth: rem(36) })
  .spacing({ outerBottom: space.xl })
  .select('> * + *', style().spacing({ outerTop: space.m }))
  .element()

const Content = style()
  .select('> * + *:not(:first-child)', style().spacing({ outerTop: space.m }))
  .element()

const InputRowWithSingleCell = style()
  .grid({ columns: [fr(1)] })
  .spacing({ columns: space.s })
  .element()
const InputRow = style()
  .grid({ columns: [fr(1), fr(1)] })
  .cond(({ withSplitter }) => withSplitter, style().grid({ columns: [fr(1), '1rem', fr(1)], align: 'center' }))
  .screen(breakpoints.mobile, style().grid({ columns: [fr(1)] }))
  .spacing({ columns: space.s, rows: space.s })
  .element()
const SectionInputRow = style()
  .grid({ columns: [fr(1), fr(1)] })
  .cond(({ withSplitter }) => withSplitter, style().grid({ columns: [fr(1), '1rem', fr(1)], align: 'center' }))
  .spacing({ columns: space.s, rows: space.s })
  .screen(
    breakpoints.mobile,
    style()
      .grid({ columns: [fr(1)] })
      .cond(
        ({ withSplitter }) => withSplitter,
        style().screen(
          breakpoints.mobile,
          style()
            .color({
              bg: `${designSystemColors.backgroundNeutralSecondary} !important`,
            })
            .border({ radius: '0.5rem' })
            .spacing({ inner: '0.5rem', innerTop: '0.75rem' }),
        ),
      ),
  )
  .element()
const Splitter = style()
  .size({ height: '65%' })
  .bg({
    imageRaw: `linear-gradient(${designSystemColors.backgroundNeutralPrimaryInverted}, ${designSystemColors.backgroundNeutralPrimaryInverted})`,
    size: '1px 100%',
    repeat: 'no-repeat',
    position: 'center center',
  })
  .flex({ justifyContent: 'center', alignItems: 'center' })
  .spacing({ outerTop: '1.5rem' })
  .screen(breakpoints.mobile, style().bg({ size: '5rem 1px' }).spacing({ outerTop: '0 !important' }))
  .element()
const SplitterText = text
  .smallBodyText()
  .color({
    fg: designSystemColors.typographySecondary,
    bg: designSystemColors.backgroundNeutralPrimary,
  })
  .text({ weight: '500' })
  .spacing({ inner: '0.075rem' })
  .screen(
    breakpoints.mobile,
    style()
      .spacing({ innerLeft: '0.5rem', innerRight: '0.5rem' })
      .color({
        bg: `${designSystemColors.backgroundNeutralSecondary} !important`,
      }),
  )
  .bg({ color: 'white' })
  .element()
const YearRow = style()
  .grid({ columns: [fr(1), fr(1)] })
  .screen(breakpoints.mobile, style().grid({ columns: [fr(1)] }))
  .spacing({ columns: space.s, rows: space.s })
  .element()
const CompanyRow = style()
  .grid({ columns: [fr(1)] })
  .screen(
    breakpoints.mobile,
    style()
      .grid({ columns: [fr(1), fr(1)] })
      .select('> *:first-child', style().grid({ span: 2 })),
  )
  .spacing({ columns: space.s, rows: space.s })
  .element()
function useAllReferenceEmails(candidateid: string) {
  const references = useSelector((state: RootState) => selectors.references.findByCandidateId(state, candidateid))
  const allReferenceEmails = references.map(r => r.fields.email)
  return allReferenceEmails
}

function useAllReferencePhones(candidateid: string, excludeRefIds: string[]) {
  const references = useSelector((state: RootState) => selectors.references.findByCandidateId(state, candidateid))

  return references.filter(r => !excludeRefIds.includes(r.id)).map(r => r.fields.phone_number)
}

function sanitizeEmail(email: string) {
  return email
    .trim()
    .replace(/@googlemail\.com$/, '@gmail.com') // replace googlemail.com with gmail.com
    .replace(/\+\S+@gmail\.com$/, '@gmail.com') // remove + alias for gmail.com
}

function isUniqueEmail(email: string, emails: string[]) {
  const sanitizedEmail = sanitizeEmail(email)
  return emails.map(sanitizeEmail).every(email => email !== sanitizedEmail)
}

function sanitizePhone(phone: string) {
  return phone.trim().replace(/^00/, '+').replace('(', '').replace(')', '').replace(/\s/g, '')
}

function isUniquePhone(phone: string, phones: string[]) {
  const sanitizedPhone = sanitizePhone(phone)
  const idx = phones.map(sanitizePhone).indexOf(sanitizedPhone)
  return idx < 0
}

function isURLValid(url: string): boolean {
  if (url.length === 0 || !url.includes('.') || url.startsWith('.') || url.endsWith('.')) {
    return false
  }
  if (!(url.startsWith('http://') || url.startsWith('https://'))) {
    url = `http://${url}`
  }
  try {
    new URL(url)
    return true
  } catch {
    return false
  }
}

const AddReference: React.FC = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const { t } = useTranslation()
  const { candidateid, referenceid, requiredreferenceid } = useParams<{
    candidateid: string
    referenceid?: string
    requiredreferenceid?: string
  }>()

  const candidateProfile = useSelector((state: RootState) =>
    selectors.candidateProfiles.findByCandidateId(state, candidateid || ''),
  )

  const required = useSelector((state: RootState) =>
    selectors.requiredReferences.findById(state, requiredreferenceid || ''),
  )

  const orig = useSelector(
    (state: RootState) =>
      selectors.references.findByRequirementId(state, candidateid || '', requiredreferenceid || '') ||
      selectors.references.findById(state, referenceid || '') ||
      api.references.empty(''),
  )

  const createdId = useSelector((state: RootState) => state.references.createdId)
  const allReferenceEmails = useAllReferenceEmails(candidateid || '')
  const allReferencePhones = useAllReferencePhones(candidateid || '', orig ? [orig.id] : [])

  const role = useSelector((state: RootState) => selectors.roles.findByCandidateId(state, candidateid))
  const org = useSelector((state: RootState) => selectors.orgs.getById(state, role?.fields.organization_id || ''))
  const orgSettings = useSelector((state: RootState) => selectors.orgSettings.getByOrgId(state, org?.id || ''))

  const [relationship, setRelationship] = useState(Relationship.Manager)
  const [reference, setReference] = useState(emptyReference)
  const [isEmailDirty, setIsEmailDirty] = useState(false)
  const [isPhoneNumberDirty, setIsPhoneNumberDirty] = useState(false)

  const isInUpdateMode = !!orig.id
  const emailValid = selectors.isEmailValid(reference.email)
  const isEmailUnique = isUniqueEmail(reference.email, allReferenceEmails)
  const isPhoneUnique = isUniquePhone(
    reference.phone_number,
    candidateProfile && (candidateProfile?.fields.phone_number || '').trim().length > 0
      ? [candidateProfile.fields.phone_number, ...allReferencePhones]
      : allReferencePhones,
  )

  const urlValid = orgSettings?.fields.disabled_verification || isURLValid(reference.linkedin)
  const isPhoneNumberMandatory = !!orgSettings?.fields.reference_phone_number_mandatory
  const isEmailOptional = !!orgSettings?.fields.reference_allow_only_email_or_phone_number
  const isEmailMandatory = !isEmailOptional
  const isPhoneNumberValid = reference.phone_number.trim().length > 3

  const isEmailAndPhoneNumberValid = () => {
    const emailEmpty = (reference.email || '').length === 0
    const phoneNumberEmpty = (reference.phone_number || '').length === 0
    // If both are empty, obviously invalid
    if (phoneNumberEmpty && emailEmpty) {
      return false
    }

    // If the user entered something (email or phone), it must be valid
    if ((!emailEmpty && !emailValid) || (!phoneNumberEmpty && !isPhoneNumberValid)) {
      return false
    }

    // If phone number is mandatory, it must have been provided by the user
    if (isPhoneNumberMandatory && phoneNumberEmpty) {
      return false
    }

    // If either email or phone is NOT allowed, make sure email is not empty (old behaviour)
    if (isEmailMandatory) {
      return !emailEmpty // considered valid if email is not empty
    }

    // In this case either email or phone is allowed and we checked at
    // the beginning that either of these need to be provided
    return true
  }

  const errorLabelForEmail = genErrorLabelForEmail() || getErrorLabelForEmailToPhoneFallback()
  const errorLabelForPhone: string | undefined = genErrorLabelForPhone() || getErrorLabelForEmailToPhoneFallback()
  const errorLabelForYear = genErrorLabelForYear()
  const errorLabelForURL = genErrorLabelForURL()

  const valid =
    isEmailAndPhoneNumberValid() &&
    urlValid &&
    !!reference.full_name &&
    !!reference.company_name &&
    !!reference.to &&
    !!reference.from &&
    !errorLabelForYear &&
    (role?.fields.candidate_title_verification_enabled ? (reference.candidate_job_title ? true : false) : true)

  function onClickNext() {
    if (reference.email.trim().length > 0 && !isEmailUnique && !isInUpdateMode) {
      setIsEmailDirty(true)
      return
    }

    if (reference.phone_number && !isPhoneUnique) {
      setIsPhoneNumberDirty(true)
      return
    }

    if (!reference) {
      return
    }

    const fields = {
      ...reference,
      phone_number: reference.phone_number.replace(/\s/g, ''),
      full_name: reference.full_name.trim(),
      candidate_id: candidateid || '',
      requirement_id: required?.id || '',
      is_peer: relationship === Relationship.Peer,
      is_report: relationship === Relationship.Report,
      is_manager: relationship === Relationship.Manager,
      is_student_peer: relationship === Relationship.StudentPeer,
      is_client: relationship === Relationship.Client,
      is_any: relationship === Relationship.Any,
    }

    if (orig.id) {
      dispatch(slice.update(orig.id, fields))
      tracking.candidatePortal.referenceUpdate()
    } else {
      dispatch(slice.create(fields))
      tracking.candidatePortal.referenceAdd()
      tracking.candidatePortal.referenceAddOld()
    }
  }

  function genErrorLabelForYear() {
    if (reference.from > reference.to) return 'Must be later than from'

    const now = new Date().getFullYear()
    if (reference.to > now) return `Must be at most ${now}`
  }

  function getErrorLabelForEmailToPhoneFallback() {
    if (
      !isEmailMandatory &&
      !isPhoneNumberMandatory &&
      (isEmailDirty || isPhoneNumberDirty) &&
      (reference.email || '').trim().length === 0 &&
      (reference.phone_number || '').trim().length === 0
    ) {
      return 'Please provide an email address or phone number.'
    }
  }

  function genErrorLabelForEmail() {
    if (reference.email && isEmailDirty && !isEmailUnique) return 'Please provide a unique email for each reference.'
    if (orig.fields.email_failed_at)
      return 'The invite sent to this email address bounced (undeliverable). Please check and try again.'

    if (isEmailMandatory && isEmailDirty && (reference.email || '').trim().length === 0) {
      return 'Please provide a valid email'
    }
  }

  function genErrorLabelForPhone() {
    if (reference.phone_number && isPhoneNumberDirty && !isPhoneUnique) {
      return 'This phone number has already been used.'
    }

    if (isPhoneNumberMandatory && isPhoneNumberDirty && (reference.phone_number || '').trim().length === 0) {
      return 'Please provide a valid phone number'
    }
  }

  function genErrorLabelForURL() {
    if (reference.linkedin.length > 0 && !urlValid) {
      return 'A valid professional network URL is required.'
    }
  }

  useEffect(() => {
    setRelationship(selectors.references.relationshipOf(orig))
    setReference({
      candidate_id: candidateid || '',
      email: orig.fields.email || '',
      full_name: orig.fields.full_name || '',
      phone_number: orig.fields.phone_number || '',
      company_name: orig.fields.company_name || '',
      linkedin: orig.fields.linkedin || '',
      candidate_job_title: orig.fields.candidate_job_title || '',
      from: orig.fields.from || 2019,
      to: orig.fields.to || 2024,
      personal_message: orig.fields.personal_message || '',
    })
  }, [orig.id, orig.fields.candidate_id])

  useEffect(() => {
    if (!required) return
    setRelationship(selectors.references.relationshipOf(required))
  }, [required?.id])

  useMount(() => {
    tracking.candidatePortal.referenceView()
  })

  if (createdId) {
    history.push({
      pathname: `/candidates/${candidateid}/manage-references`,
      search: location.search,
    })
  }

  return (
    <Container>
      <TextHeader>{t('candidate-dashboard-intro.references.add-a-reference', 'Add a reference details')}</TextHeader>
      <Card big>
        <Content>
          {required ? (
            <Text>
              {t(
                'candidate-dashboard-intro.references.add-a-reference.description',
                `Please provide the details about a {relationship} with whom you’ve worked.`,
                { relationship: t(`candidate-dashboard-intro.reference.type-${relationship.trim()}`, relationship) },
              )}
            </Text>
          ) : (
            <Text>
              {t(
                'candidate-dashboard-intro.references.add-a-reference.description',
                `Please provide the details about a person with whom you’ve worked.`,
              )}
            </Text>
          )}
          {required?.fields.relationship_description && (
            <InfoCard heading="Notes from recruiter:">{required?.fields.relationship_description}</InfoCard>
          )}
          {required && (
            <InputRowWithSingleCell>
              <TextField
                label={t('candidate-dashboard-intro.references.add-a-reference.full-name', 'Full Name') + ' *'}
                value={reference.full_name}
                placeholder={t(
                  'candidate-dashboard-intro.references.add-a-reference.full-name-placeholder',
                  'Sue Brown',
                )}
                onChange={e => setReference({ ...reference, full_name: e.target.value })}
              />
            </InputRowWithSingleCell>
          )}
          {!required && (
            <InputRow>
              <TextField
                label={t('candidate-dashboard-intro.references.add-a-reference.full-name', 'Full Name')}
                value={reference.full_name}
                placeholder={t(
                  'candidate-dashboard-intro.references.add-a-reference.full-name-placeholder',
                  'Sue Brown',
                )}
                onChange={e => setReference({ ...reference, full_name: e.target.value })}
              />
              <DropdownField<Relationship>
                label={t('candidate-dashboard-intro.references.add-a-reference.reference-type', 'Reference type')}
                onChange={rel => setRelationship(rel)}
                value={relationship}
              >
                {Object.values(Relationship).map(rel => {
                  const copy = relationshipCopy[rel]
                  if (!copy) return null
                  return (
                    <Option key={rel} value={rel}>
                      {copy.emoji} {t(`candidate-dashboard-intro.reference.type-${rel}`, copy.label)}
                    </Option>
                  )
                })}
              </DropdownField>
            </InputRow>
          )}
          <SectionInputRow withSplitter={!isEmailMandatory && !isPhoneNumberMandatory}>
            <TextField
              type="email"
              label={`${t('candidate-dashboard-intro.references.add-a-reference.email', 'Email')} ${
                isEmailMandatory
                  ? '*'
                  : isPhoneNumberMandatory
                  ? t('candidate-dashabord-intro.references.optional-email', '(optional)')
                  : ''
              }`}
              error={!!errorLabelForEmail || (reference.email.length > 0 && (!reference.email || !emailValid))}
              errorLabel={errorLabelForEmail}
              value={reference.email}
              placeholder={t(
                'candidate-dashboard-intro.references.add-a-reference.email-placeholder',
                'Enter an email address',
              )}
              onChange={e => {
                setIsEmailDirty(true)
                setReference({ ...reference, email: e.target.value })
              }}
              disabled={!!orig.fields.form_response_id}
              subLabel={
                orig.fields.form_response_id
                  ? t(
                      'candidate-dashboard-intro.references.add-a-reference.email-disabled',
                      'Reference has responded so their email cannot be updated',
                    )
                  : undefined
              }
            />
            {!isEmailMandatory && !isPhoneNumberMandatory ? (
              <Splitter>
                <SplitterText>OR</SplitterText>
              </Splitter>
            ) : null}
            <TextField
              type="tel"
              label={`${t('candidate-dashboard-intro.references.add-a-reference.phone', 'Phone')} ${
                isPhoneNumberMandatory ? '*' : ''
              }`}
              error={!!errorLabelForPhone || (errorLabelForPhone || '').trim().length > 0}
              errorLabel={errorLabelForPhone}
              value={reference.phone_number}
              placeholder="+1 202 303 4004"
              phone={!!orgSettings?.fields.reference_phone_number_force_formatting}
              onChange={e => {
                setIsPhoneNumberDirty(true)
                setReference({ ...reference, phone_number: e.target.value })
              }}
            />
          </SectionInputRow>
          {!orgSettings?.fields.disabled_verification && (
            <TextField
              type="url"
              label={
                t('candidate-dashboard-intro.references.add-a-reference.professional-network', 'Professional network') +
                ' *'
              }
              error={reference.linkedin.length > 0 && !urlValid}
              errorLabel={errorLabelForURL}
              subLabel={t(
                'candidate-dashboard-intro.references.add-a-reference.professional-network-sub-label',
                "Enter link (e.g. Linkedin, AngelList, Xing, Github); enter 'no-network.com' if you do not have any professional profile",
              )}
              value={reference.linkedin}
              placeholder="linkedin.com/in/john.doe"
              onChange={e => setReference({ ...reference, linkedin: e.target.value })}
            />
          )}
          <CompanyRow>
            <TextField
              label={
                t('candidate-dashboard-intro.references.add-a-reference.worked-together-at', 'Worked together at') +
                ' *'
              }
              subLabel={t(
                'candidate-dashboard-intro.references.add-a-reference.worked-together-at-sub-label',
                'If you worked together at more than one company, please put in the most recent one',
              )}
              value={reference.company_name}
              placeholder={t(
                'candidate-dashboard-intro.references.add-a-reference.worked-together-at-placeholder',
                'Company name',
              )}
              onChange={e => setReference({ ...reference, company_name: e.target.value })}
            />
          </CompanyRow>
          {!!role?.fields.candidate_title_verification_enabled && (
            <TextField
              type="url"
              label={t('candidate-dashboard-intro.references.add-a-reference.your-job-title', 'Your job title') + ' *'}
              subLabel={t(
                'candidate-dashboard-intro.references.add-a-reference.your-job-title-sub-label',
                'Enter the job title you had when you worked together',
              )}
              placeholder={t(
                'candidate-dashboard-intro.references.add-a-reference.your-job-title-placeholder',
                'ie.: Senior Software Engineer',
              )}
              value={reference.candidate_job_title}
              onChange={e =>
                setReference({
                  ...reference,
                  candidate_job_title: e.target.value,
                })
              }
            />
          )}
          <YearRow>
            <TextField
              type="number"
              label={t('candidate-dashboard-intro.references.add-a-reference.from', 'From') + ' *'}
              value={`${reference.from || ''}`}
              placeholder="2019"
              onChange={e => setReference({ ...reference, from: Number(e.target.value) })}
            />
            <TextField
              type="number"
              label={t('candidate-dashboard-intro.references.add-a-reference.to', 'To') + ' *'}
              error={!!errorLabelForYear}
              errorLabel={errorLabelForYear}
              value={`${reference.to || ''}`}
              placeholder="2024"
              onChange={e => setReference({ ...reference, to: Number(e.target.value) })}
            />
          </YearRow>
          <TextField
            label={t(
              'candidate-dashboard-intro.references.add-a-reference.personal-message',
              'Add personalized message to invitation',
            )}
            placeholder={t(
              'candidate-dashboard-intro.references.add-a-reference.personal-message-placeholder',
              'I hope you are doing well! If you have a moment, I would really appreciate your support in providing me with a reference. Thank you!',
            )}
            multiline={true}
            value={reference.personal_message || ''}
            onChange={e =>
              setReference({
                ...reference,
                personal_message: (e.target.value || '').trim().length > 0 ? e.target.value : undefined,
              })
            }
          />
          <InputRow>
            <Link
              to={{
                pathname: `/candidates/${candidateid}/manage-references`,
                search: location.search,
              }}
            >
              <Button buttonType={'secondary'} isFullWidth>
                {t('candidate-dashboard-intro.references.add-a-reference.cancel', 'Cancel')}
              </Button>
            </Link>
            <Button buttonType={'accent'} onClick={onClickNext} disabled={!valid}>
              {isInUpdateMode
                ? t('candidate-dashboard-intro.references.add-a-reference.update', 'Update')
                : t('candidate-dashboard-intro.references.add-a-reference.send-invite', 'Send invite')}
            </Button>
          </InputRow>
        </Content>
      </Card>
    </Container>
  )
}

export default AddReference
