import { Icon } from 'components/Icons'
import { px2rem, size, space, style } from 'core'
import { text } from 'core/design-system/text'
import React from 'react'

const NoticeStyle = style()
  .with(({ bgColor }: { bgColor: string }) => style().color({ bg: bgColor }))
  .grid({ columns: [px2rem(16), size.auto] })
  .spacing({ inner: px2rem(12), columns: px2rem(8) })
  .border({ radius: px2rem(5) })
  .element()

const Copy = text.smallBodyText().spacing({ outer: space.auto }).nooverflow().element('p')

const IconContainer = style()
  .with(({ color }: { color: string }) => style().color({ fg: color }))
  .spacing({ outer: space.auto })
  .element()

interface NoticeProps {
  icon: string
  iconColor: string
  noticeBackgroundColor: string
  copy: string
}

export const Notice: React.FC<NoticeProps> = ({ icon, iconColor, noticeBackgroundColor, copy }) => {
  return (
    <NoticeStyle bgColor={noticeBackgroundColor}>
      <IconContainer color={iconColor}>
        <Icon name={icon} />
      </IconContainer>
      <Copy>{copy}</Copy>
    </NoticeStyle>
  )
}
