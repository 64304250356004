import { fr, px2rem, style } from 'core'
import React from 'react'
import { Button } from 'components/Button/ButtonV2'
import Modal from 'components/Modal'
import TextField from 'components/TextField'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'

const Container = style()
  .size({
    width: px2rem(612),
  })
  .element()

const Caption = text.bodyText().color({ fg: designSystemColors.typographySecondary }).element()

const Content = style()
  .flex({ direction: 'column' })
  .spacing({ inner: px2rem(32), gap: px2rem(24) })
  .element()

const LinkSection = style()
  .size({ width: '100%' })
  .grid({ align: 'end', columns: [fr(1), px2rem(184)] })
  .spacing({ gap: px2rem(16) })
  .element()

interface InviteViaLinkModalProps {
  open: boolean
  setOpen: (b: boolean) => void
  onClickCopyToClipboard: () => void
  urlText: string
}

const InviteViaLinkModal: React.FC<InviteViaLinkModalProps> = ({ open, setOpen, onClickCopyToClipboard, urlText }) => {
  return (
    <Modal title={`🔗 Invite candidates via link`} open={open} setOpen={setOpen} testId="invite-via-link-modal">
      <Container>
        <Content>
          <Caption>
            Clip and share this link with candidates for direct access to the assessment or reference check.
          </Caption>
          <LinkSection>
            <TextField
              label="Invite link"
              value={urlText || 'Loading...'}
              disabled
              isFullWidth
              testId="invite-via-link-url-text-field"
            />
            <Button buttonType="accent" isFullWidth onClick={onClickCopyToClipboard}>
              Copy to clipboard
            </Button>
          </LinkSection>
        </Content>
      </Container>
    </Modal>
  )
}

export default InviteViaLinkModal
