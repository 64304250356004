import Loading from 'components/Loading'
import token from 'core/token'
import { useEmailVerification } from 'providers/email-verification'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, RouteComponentProps } from 'react-router-dom'
import * as selectors from 'selectors'
import { RootState } from 'store'
import { remember as rememberLogin } from 'store/login'
import { restore as restoreSession } from 'store/sessions'

interface PrivateRouteProps {
  path: string
  children?: React.ReactNode
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ path, children }) => {
  return <Route path={path} render={props => <PrivateRouteChildren {...props} children={children} />} />
}

export default PrivateRoute

interface PrivateRouteChildrenProps extends RouteComponentProps {
  children: React.ReactNode
}

const PrivateRouteChildren: React.FC<PrivateRouteChildrenProps> = ({ location, children }) => {
  const dispatch = useDispatch()

  const isLoggedIn = useSelector((state: RootState) => state.sessions.isLoggedIn)
  const isRestoring = useSelector((state: RootState) => state.sessions.isRestoring)
  const userId = useSelector((state: RootState) => selectors.users.current(state)?.id || '')
  const email = useSelector((state: RootState) => selectors.users.current(state)?.fields.email || '')
  const fullName = useSelector(
    (state: RootState) => selectors.profiles.getByUserId(state, userId)?.fields.full_name || '',
  )

  useEmailVerification()

  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(restoreSession())
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (!(email && fullName)) {
      return
    }

    dispatch(rememberLogin(email, fullName))
  }, [email, fullName, userId])

  if (!token.status()) {
    const params = {
      pathname: '/login',
      state: { from: location },
    }

    return <Redirect to={params} />
  }

  // Restore session from the stored token if necessary
  if (isRestoring || !isLoggedIn) {
    return <Loading />
  }

  return <>{children}</>
}
