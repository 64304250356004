import React from 'react'
import { FaAngleDown, FaAngleUp } from 'react-icons/fa'
import classes from './DrawerCard.module.scss'

interface DrawerCardProps {
  header: React.ReactNode
  className?: string
  headerClassName?: string
  headerContentClassName?: string
  contentClassname?: string
  defaultCollapsed?: boolean
  showHandle?: boolean
  /** If true, the header will not be displayed in a flex layout with space between the header and the handle */
  withoutHeaderLayout?: boolean
  children: React.ReactNode
}

export const DrawerCard = (props: DrawerCardProps) => {
  const [collapsed, setCollapsed] = React.useState(props.defaultCollapsed || false)
  return (
    <div className={props.className ?? ''} data-testid="drawer-card">
      <div
        className={`${classes.header} ${props.showHandle ? classes.withHandle : ''} ${
          props.withoutHeaderLayout ? '' : classes.withHeaderLayout
        } ${props.headerClassName ?? ''}`}
        onClick={() => props.showHandle && setCollapsed(!collapsed)}
      >
        <div className={props.headerContentClassName ?? ''}>{props.header}</div>
        {props.showHandle && (
          <div className={`${classes.handle} hideInPrint`}>{collapsed ? <FaAngleDown /> : <FaAngleUp />}</div>
        )}
      </div>
      <div
        className={`
          ${classes.content} 
          ${classes.fullWidthSubgridItem} 
          ${!collapsed ? classes.open : ''} 
          ${props.contentClassname ?? ''}
        `}
      >
        <div className={`${classes.fullWidthSubgridItem} ${classes.contentBody}`}>{props.children}</div>
      </div>
    </div>
  )
}
