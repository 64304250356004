import { Button as MainButton } from '@common/components'
import * as React from 'react'
import { colors, px, rem, scale, space, style, weight } from '../../core'

interface Props {
  children: React.ReactNode
  onClick?: () => void
  primary?: boolean
  minimal?: boolean
  secondary?: boolean
  disabled?: boolean
  inline?: boolean
  danger?: boolean
  loading?: boolean
  testId?: string
  style?: React.CSSProperties
}

const Button = style()
  .size({ width: '100%', height: rem(2.3) })
  .nooutline()
  .color({ bg: colors.white, fg: colors.black })
  .spacing({ inner: [space.xs, space.s] })
  .sans({ weight: weight.normal, height: scale.s })
  .round(px(5))
  .cond((props: Props) => !!props.inline, style().size({ width: 'auto' }).inlineBlock())
  .center()
  .pointer()

export const DefaultButton = Button.clone()
  .noborders()
  .color({ fg: colors.midGray, bg: colors.lightestGray })
  .cond(
    (props: Props) => !!props.disabled,
    style().set('pointerEvents', 'none').color({ fg: colors.lightGray, bg: colors.lightestGray }),
  )
  .element('button')

export const MinimalButton = Button.clone()
  .size({ width: 'auto' })
  .inlineBlock()
  .nospacing()
  .noborders()
  .transparent()
  .element('button')

const Component = (props: Props) => {
  const disabled = props.loading ? props.loading : props.disabled
  if (props.primary)
    return (
      <MainButton
        variant="purple-dark"
        loading={props.loading}
        disabled={disabled}
        data-testid={props.testId}
        onClick={props.onClick}
        style={props.style}
      >
        {props.children}
      </MainButton>
    )
  if (props.secondary)
    return (
      <MainButton
        variant="secondary"
        loading={props.loading}
        disabled={disabled}
        data-testid={props.testId}
        onClick={props.onClick}
        style={props.style}
      >
        {props.children}
      </MainButton>
    )
  if (props.minimal)
    return (
      <MainButton
        variant="tertiary"
        loading={props.loading}
        disabled={disabled}
        data-testid={props.testId}
        onClick={props.onClick}
      >
        {props.children}
      </MainButton>
    )
  return (
    <MainButton
      variant="purple-dark"
      loading={props.loading}
      disabled={disabled}
      data-testid={props.testId}
      onClick={props.onClick}
      style={props.style}
    >
      {props.children}
    </MainButton>
  )
}

export default Component
Component.displayName = 'Button'
