import { getEnvironment } from 'api/request'
import { Intercom } from 'components/Intercom'
import Notifications from 'components/Notifications'
import React from 'react'
import { Provider as StoreProvider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { IntercomProvider } from 'react-use-intercom'
import { Provider as ConfirmProvider } from '../components/Confirm'
import { LinkSourceTracker } from '../components/LinkSourceTracker'
import store from '../core/store'
import token from '../core/token'
import Router from './Router'
import { Environments } from './Settings/Pages/ManageEnvironments'
import { DebugWindow } from '@common/components'
import { QueryClientProvider } from '../providers/QueryClient/QueryClientProvider'

// Apply Hotjar styles to see what elements are obfuscated
if (import.meta.env.VITE_HOTJAR_VISIBLE === 'true') {
  import('../hotjar.scss')
}

const App = () => {
  if (token.isTemporary()) {
    console.info('Activated temporary token.')
  }

  const environment = getEnvironment()
  switch (environment) {
    case Environments.Production:
      document.title = 'HiPeople'
      break
    case Environments.Feat:
      document.title = '[FEAT-STAGING] HiPeople'
      break
    case Environments.Local:
      document.title = '[LOCAL] HiPeople'
  }

  return (
    <QueryClientProvider>
      <IntercomProvider appId={import.meta.env.VITE_INTERCOM_APP_ID || ''}>
        <BrowserRouter>
          <StoreProvider store={store}>
            <LinkSourceTracker />
            <Intercom />
            <ConfirmProvider>
              <DebugWindow />
              <Router />
            </ConfirmProvider>
            <Notifications />
          </StoreProvider>
        </BrowserRouter>
      </IntercomProvider>
    </QueryClientProvider>
  )
}

export default App
