import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../../components/Modal'
import CopyLinkButton from '../../components/Modal/CopyLinkButton'
import TextField from '../../components/TextField'
import { Buttons, Content, ContentAndButtons, Form } from '../../components/Wizard'
import { Subheading } from '../../components/Wizard/Typography'
import { rem, style } from '../../core'
import * as selectors from '../../selectors'
import { RootState } from '../../store'
import * as slice from '../../store/candidates'

interface Props {
  candidateId: string
  open: boolean
  setOpen: (b: boolean) => void
}

const Container = style()
  .size({
    maxWidth: rem(40),
    width: 'calc(100vw - 200px)',
    height: rem(20),
  })
  .element()

const Component = (props: Props) => {
  const dispatch = useDispatch()

  const [candidateProfile, managementLink]: [ReturnType<typeof selectors.candidateProfiles.findByCandidateId>, string] =
    useSelector((state: RootState) => [
      selectors.candidateProfiles.findByCandidateId(state, props.candidateId),
      state.candidates.managementLink,
    ])

  useEffect(() => {
    dispatch(slice.setManagementLink(''))
    if (props.open) {
      dispatch(slice.managementLink(props.candidateId))
    }
  }, [props.open, props.candidateId, dispatch])

  return (
    <Modal
      title={`Get ${selectors.possessiveFirstName(candidateProfile?.fields?.full_name || '')} candidate portal link`}
      open={props.open}
      setOpen={props.setOpen}
    >
      <Container>
        <ContentAndButtons>
          <Content standalone>
            <Subheading>Below is the candidate portal link for {candidateProfile?.fields?.full_name}.</Subheading>
            <Form>
              <TextField label="Candidate's portal link" value={managementLink || 'Loading...'} disabled />
            </Form>
            <CopyLinkButton copyText={managementLink} />
          </Content>
          <Buttons forwardOnly next={() => props.setOpen(false)} back={() => {}} label={'Done'} />
        </ContentAndButtons>
      </Container>
    </Modal>
  )
}

export default Component
Component.displayName = 'ManagementLinkModal'
