import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit'
import resizeImage from 'core/image'
import { profiles, sessions, users } from '../../api'
import { AppThunk } from '../../core/store'
import { copyToClipboard } from '../../core/utils'
import { add as notify } from '../notifications'
import { addEntities } from '../resources'
import { handleResponse } from '../utils'

const name = 'account-settings'

export interface Changes {
  name?: string
  email?: string
  location?: string
  profileImage?: File
}

interface State {
  name: string
  email: string
  location: string
  profilePicUrl: string
  changes: Changes
  loading: boolean
}

const initialState: State = {
  name: '',
  email: '',
  location: '',
  profilePicUrl: '',
  changes: {},
  loading: false,
}

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
  },
})

export const { setLoading } = slice.actions

export default slice.reducer

export const save =
  (userId: string, profileId: string, changes: Changes): AppThunk =>
  async (dispatch: Dispatch) => {
    dispatch(setLoading(true))

    if (changes.name !== undefined) {
      if (!handleResponse<profiles.Fields>(dispatch, await profiles.updateName(profileId, changes.name.trim())))
        return dispatch(setLoading(false))
    }

    if (changes.location !== undefined) {
      if (!handleResponse<profiles.Fields>(dispatch, await profiles.updateLocation(profileId, changes.location.trim())))
        return dispatch(setLoading(false))
    }

    if (changes.profileImage !== undefined) {
      // limit the size of the uploaded profile images to 512x512
      let resizedImage = changes.profileImage
      try {
        resizedImage = await resizeImage(changes.profileImage, 512, 512)
      } catch (error) {
        // ignore errors if resizing fails
      }
      if (!handleResponse<profiles.Fields>(dispatch, await profiles.updateProfileImage(profileId, resizedImage)))
        return dispatch(setLoading(false))
    }

    if (changes.email !== undefined) {
      if (!handleResponse<users.Fields>(dispatch, await users.updateEmail(userId, changes.email)))
        return dispatch(setLoading(false))
    }

    dispatch(setLoading(false))

    dispatch(
      notify({
        success: 'Your profile has been updated successfully!',
      }),
    )
  }

export const loadUserById =
  (id: string): AppThunk =>
  async (dispatch: Dispatch) => {
    dispatch(setLoading(true))

    const [response, errors] = await users.loadById(id)

    dispatch(setLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(addEntities(response))
    }
  }

export const sendNewPassword =
  (id: string): AppThunk =>
  async (dispatch: Dispatch) => {
    dispatch(setLoading(true))

    const [response, errors] = await users.sendNewPassword(id)

    dispatch(setLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(addEntities(response))
    }

    dispatch(
      notify({
        success: 'A new password has been sent to the user successfully.',
      }),
    )
  }

export const resendEmailVerification =
  (id: string): AppThunk =>
  async (dispatch: Dispatch) => {
    dispatch(setLoading(true))

    const [, errors] = await users.resendEmailVerification(id)

    dispatch(setLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    dispatch(
      notify({
        success: 'Verification email sent successfully. Check your inbox!',
      }),
    )
  }

export const impersonate =
  (id: string): AppThunk =>
  async (dispatch: Dispatch) => {
    dispatch(setLoading(true))

    const [response, errors] = await sessions.impersonate(id)

    dispatch(setLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    copyToClipboard(`https://app.hipeople.io/?token=${response?.result.fields.token}`)

    dispatch(
      notify({
        success: 'Impersonation link has been copied to your clipboard. Open it in incognito window.',
      }),
    )
  }

export const deleteUser =
  (id: string): AppThunk =>
  async (dispatch: Dispatch) => {
    dispatch(setLoading(true))

    const [response, errors] = await users.deleteUser(id)

    dispatch(setLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(addEntities(response))
    }

    dispatch(
      notify({
        success: 'Selected user has been inactivated.',
      }),
    )
  }

export const setIsOrgAdmin =
  (id: string, isOrgAdmin: boolean): AppThunk =>
  async (dispatch: Dispatch) => {
    dispatch(setLoading(true))

    const [response, errors] = await users.setIsOrgAdmin(id, isOrgAdmin)

    dispatch(setLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(addEntities(response))

      dispatch(
        notify({
          success: isOrgAdmin
            ? 'Selected user has been set as organization admin.'
            : 'Organization admin status was removed from the selected user',
        }),
      )
    }
  }

export const setIsSuperUser =
  (id: string, isSuperUser: boolean): AppThunk =>
  async (dispatch: Dispatch) => {
    dispatch(setLoading(true))

    const [, errors] = await users.setIsSuperUser(id, isSuperUser)

    dispatch(setLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    dispatch(
      notify({
        success: isSuperUser
          ? 'Selected user has been set as super user.'
          : 'Superuser status was removed from the selected user',
      }),
    )
  }
