import * as api from '../api'
import { RootState } from '../store'
import * as candidates from './candidates'
import lookup from './lookup'

function findById(state: RootState, id: string): api.request.Entity<api.greenhouseProfiles.Fields> | undefined {
  return lookup<api.greenhouseProfiles.Fields>(state, api.greenhouseProfiles.RESOURCE, id)
}

export function findByCandidateId(
  state: RootState,
  candidateId: string,
): api.request.Entity<api.greenhouseProfiles.Fields> | undefined {
  const candidate = candidates.findById(state, candidateId)
  if (!candidate || !candidate.relationships) return

  const greenhouseProfile = candidate.relationships.find(r => r.resource === api.greenhouseProfiles.RESOURCE)

  if (!greenhouseProfile) return

  return findById(state, greenhouseProfile.id)
}
