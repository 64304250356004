import { fr, px2rem, size, style } from '../../core'
import { text } from '../../core/design-system/text'

export const Title = text
  .highlight()
  .sans({ align: 'center' })
  .spacing({ outerBottom: px2rem(16) })
  .element('h1')

export const Description = text
  .bodyText()
  .sans({ align: 'center' })
  .spacing({ outerBottom: px2rem(64) })
  .element('p')

export const TextFields = style().element()

export const Buttons = style()
  .grid({ columns: [fr(1), fr(1)] })
  .spacing({ gap: px2rem(16), outer: 'auto' })
  .size({ width: px2rem(512) })
  .spacing({ top: px2rem(16) })
  .element()

export const Container = style()
  .spacing({ outer: [px2rem(0), size.auto] })
  .size({ width: px2rem(512) })
  .element()
