import { px2rem, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import React from 'react'

type EmojiColor = 'purple' | 'orange' | 'green' | 'blue' | 'cyan' | 'red'

function getBgColor(emojiColor: EmojiColor): string {
  switch (emojiColor) {
    case 'purple': {
      return designSystemColors.informationPurpleBackground
    }
    case 'orange': {
      return designSystemColors.informationOrangeBackground
    }
    case 'green': {
      return designSystemColors.informationGreenBackground
    }
    case 'blue': {
      return designSystemColors.informationBlueBackground
    }
    case 'cyan': {
      return designSystemColors.informationCyanBackground
    }
    case 'red': {
      return designSystemColors.informationRedBackground
    }
  }
}

const Emoji = style()
  .flex({ alignItems: 'center', justifyContent: 'center' })
  .with(({ bgColor }: { bgColor: string }) => style().color({ bg: bgColor }))
  .size({ width: px2rem(42), height: px2rem(42) })
  .border({
    radius: px2rem(6),
  })
  .element()

interface EmojiBadgeProps {
  emojiColor: EmojiColor
  emoji?: string
}

export const EmojiBadge: React.FC<EmojiBadgeProps> = props => (
  <Emoji bgColor={getBgColor(props.emojiColor)}>{props.emoji}</Emoji>
)

EmojiBadge.displayName = 'Badge'
