import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const name = 'update-team'

interface Changes {
  name?: string
  location?: string
}

interface State {
  loading: boolean
  changes: { [id: string]: Changes }
}

const initialState: State = {
  loading: false,
  changes: {},
}

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setName(state, action: PayloadAction<{ id: string; name: string }>) {
      const { id, name } = action.payload
      state.changes[id] = {
        ...state.changes[id],
        name,
      }
    },
    setLocation(state, action: PayloadAction<{ id: string; location: string }>) {
      const { id, location } = action.payload
      state.changes[id] = {
        ...state.changes[id],
        location,
      }
    },
    resetChanges(state, action: PayloadAction<string>) {
      state.changes[action.payload] = {}
    },
  },
})

export const { setLoading, resetChanges } = slice.actions

export default slice.reducer
