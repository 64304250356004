import { px2rem, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React from 'react'
import { Relationship } from 'selectors/references'

const RelationshipLabelStyle = text
  .label()
  .spacing({ inner: [px2rem(3), px2rem(4)] })
  .border({ radius: px2rem(3) })
  .cond(
    ({ type }: { type: Relationship }) => type === Relationship.Manager,
    style().color({
      bg: designSystemColors.informationOrangeBackground,
      fg: designSystemColors.informationOrangePrimary,
    }),
  )
  .cond(
    ({ type }: { type: Relationship }) => type === Relationship.Report,
    style().color({
      bg: designSystemColors.informationBlueBackground,
      fg: designSystemColors.informationBluePrimary,
    }),
  )
  .cond(
    ({ type }: { type: Relationship }) => type === Relationship.Client,
    style().color({
      bg: designSystemColors.informationGreenBackground,
      fg: designSystemColors.informationGreenPrimary,
    }),
  )
  .cond(
    ({ type }: { type: Relationship }) => type === Relationship.StudentPeer,
    style().color({
      bg: designSystemColors.informationRedBackground,
      fg: designSystemColors.informationRedPrimary,
    }),
  )
  .cond(
    ({ type }: { type: Relationship }) => type === Relationship.Peer,
    style().color({
      bg: designSystemColors.informationPurpleBackground,
      fg: designSystemColors.informationPurplePrimary,
    }),
  )
  .cond(
    ({ type }: { type: Relationship }) => type === Relationship.Any,
    style().color({
      bg: designSystemColors.informationBlueBackground,
      fg: designSystemColors.informationBluePrimary,
    }),
  )
  .cond(
    ({ type }: { type: 'Candidate' }) => type === 'Candidate',
    style().color({
      bg: designSystemColors.informationRedBackground,
      fg: designSystemColors.informationRedPrimary,
    }),
  )
  .element('span')

interface RelationshipLabelProps {
  type: Relationship
}

export const RelationshipLabel: React.FC<RelationshipLabelProps> = ({ type }) => {
  return <RelationshipLabelStyle type={type}>{type}</RelationshipLabelStyle>
}

export default RelationshipLabel
