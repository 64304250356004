import React, { useEffect, useMemo } from 'react'
import { LockedByPlan, LockedByPlanProps } from '../Locked/Locked'
import { Text } from '../Text/Text'
import classes from './Tabs.module.scss'

export interface TabsProps {
  defaultActiveTabId?: string
  tabs: {
    /** id is used to identify the tab. ids should be unique */
    id: string
    label: string | React.ReactNode
    content?: React.ReactNode
    onClickTab?: () => void
    special?: boolean
    locked?: {
      modalData: LockedByPlanProps['modalData']
      tracking: LockedByPlanProps['tracking']
      upgradeTo: LockedByPlanProps['upgradeTo']
    }
  }[]
  noSpacing?: boolean
  variant?: 'light' | 'dark'
  type?: 'default' | 'bar'
}

export const Tabs: React.FC<TabsProps> = props => {
  const [activeTab, setActiveTab] = React.useState(props.defaultActiveTabId || props.tabs[0].id)
  const [barPosition, setBarPosition] = React.useState<{
    left: number
    width: number
  }>({
    left: 0,
    width: 0,
  })
  const Content = useMemo(() => {
    return props.tabs.find(tab => tab.id === activeTab)?.content || null
  }, [activeTab, props.tabs])

  const onClickTab = (tab: TabsProps['tabs'][0]) => (e: React.MouseEvent) => {
    setActiveTab(tab.id)
    tab.onClickTab && tab.onClickTab()

    if (props.type === 'bar') {
      // left position of tab clicked + half of tab width - half of bar width (50px)
      const tabElement = e.currentTarget as HTMLDivElement
      setBarPosition(getBarPosition(tabElement))
    }
  }

  useEffect(() => {
    if (props.defaultActiveTabId) {
      setActiveTab(props.defaultActiveTabId)
    }
  }, [props.defaultActiveTabId])

  // set initial bar position
  useEffect(() => {
    if (props.type === 'bar') {
      const tabElement = document.querySelector(`.${classes.tabList} .${classes.active}`) as HTMLDivElement
      if (tabElement) {
        setBarPosition(getBarPosition(tabElement))
      }
    }
  }, [props.type])

  return (
    <div className={`${classes.wrapper} ${props.noSpacing ? classes.noSpacing : ''}`}>
      <div className={`${classes.tabList}  ${classes[props.type || 'default']} ${classes[props.variant || 'light']}`}>
        {props.tabs.map(tab =>
          tab.locked ? (
            <LockedByPlan
              key={tab.id}
              locked={true}
              modalData={tab.locked.modalData}
              upgradeTo={tab.locked.upgradeTo}
              tracking={tab.locked.tracking}
            >
              <div
                className={`${tab.special ? classes.tabSpecial : classes.tabNormal} ${
                  activeTab === tab.id ? classes.active : ''
                }`}
                onClick={onClickTab(tab)}
              >
                <Text variant="body-interactive" className={tab.special ? classes.specialText : ''}>
                  {tab.label}
                </Text>
              </div>
            </LockedByPlan>
          ) : (
            <div
              key={tab.id}
              className={`${tab.special ? classes.tabSpecial : classes.tabNormal} ${
                activeTab === tab.id ? classes.active : ''
              }`}
              onClick={onClickTab(tab)}
            >
              <Text variant="body-interactive" className={tab.special ? classes.specialText : ''}>
                {tab.label}
              </Text>
            </div>
          ),
        )}
        {props.type === 'bar' && <div className={classes.barElement} style={barPosition} />}
      </div>
      <div className={classes.tabPanel}>{Content}</div>
    </div>
  )
}

const getBarPosition = (tabElement: HTMLDivElement) => {
  const tabElementWidth = tabElement.offsetWidth
  const barWidth = tabElementWidth * 0.8 // 80% of tab width
  const leftPosition = tabElement.offsetLeft + tabElementWidth / 2 - barWidth / 2 // center of tab - half of bar width
  return { left: leftPosition, width: barWidth }
}
