import { Logo } from '@common/components'
import * as api from 'api'
import { Fields } from 'api/candidate-profiles'
import { QuestionEntity } from 'api/questions'
import { Entity } from 'api/request'
import { getScore } from 'api/score'
import NoInviteYet from 'App/Candidate/NoInviteYet'
import SoftSkillsScoreGroup from 'App/Candidate/SelfAssessment/SoftSkillsDashboard'
import WaitingOnCandidate from 'App/Candidate/WaitingOnCandidate'
import { hasPresentKey, isPresent } from 'core/utils'
import groupBy from 'lodash-es/groupBy'
import { useCandidate } from 'providers/candidates'
import { useFeatureFlag } from 'providers/features'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import * as selectors from 'selectors'
import { BESSI_DOMAINS } from 'selectors/modules'
import { findByQuestionnaireId } from 'selectors/questionnaire-entries'
import { findByModuleIds } from 'selectors/questions'
import {
  AssessmentAnswersByDomain,
  findCustomSelfAssessmentAnswers,
  findSelfAssessmentAnswers,
} from 'selectors/self-assessment'
import { RootState } from 'store'
import { formatDateFromNano } from 'utils/format'
import * as tracking from '../../../../core/track'
import AssessmentSummariesDashboard from '../AssessmentSummariesDashboard/AssessmentSummariesDashboard'
import summariesClasses from '../AssessmentSummariesDashboard/AssessmentSummariesDashboard.module.scss'
import CandidateScoreCard from '../CandidateDashboard'
import CognitiveAbilitiesDashboard from '../CognitiveAbilitiesDashboard'
import { AssessmentTab } from '../ContentTabs'
import CustomQuestionsDashboard from '../CustomQuestionsDashboard'
import HardSkillsScoreGroup from '../HardSkillsDashboard'
import PersonalityScoreCard from '../PersonalityDashboard'
import classes from './AssessmentExportPDFPage.module.scss'

import { ScoreResponse } from '@common/api/score'

export const ExportContext = React.createContext<{
  isPdfExport: boolean
}>({
  isPdfExport: false,
})

const AssessmentExportPDFPage: React.FC = () => {
  const { candidateid } = useParams<{
    roleid: string
    candidateid: string
  }>()

  const { candidate, candidateProfile: profile } = useCandidate({
    candidateId: candidateid || '',
  })

  const selfAssessment = useSelector((state: RootState) =>
    selectors.questionnaireSubmissions.findSelfAssessmentByCandidateId(state, candidateid),
  )

  const questionnaire = useSelector(
    (state: RootState) =>
      selectors.questionnaires.findById(state, selfAssessment?.fields.questionnaire_id || '') ?? undefined,
  )

  const role = useSelector((state: RootState) => selectors.roles.findByCandidateId(state, candidateid))

  const questionnaireSubmission = useSelector((state: RootState) =>
    selectors.questionnaireSubmissions.findSelfAssessmentByCandidateId(state, candidateid),
  )

  const answers = useSelector((state: RootState) => findSelfAssessmentAnswers(state, candidateid))

  const customQuestionAnswers = useSelector((state: RootState) => findCustomSelfAssessmentAnswers(state, candidateid))

  const candidateFirstName = selectors.firstName(profile?.fields.full_name || 'The candidate')

  const { isEnabled: isAssessmentSummariesEnabled } = useFeatureFlag('assessment-summaries')

  const personalityGroup = useSelector((state: RootState) => {
    return selectors.groups.findBySlug(state, 'personality')
  })

  const personalityAnswers = useMemo(
    () => answers?.filter(field => field.module?.group_id === personalityGroup?.id),
    [answers, personalityGroup],
  )

  // TODO: 🚨 we shouldn't be checking module.type or comparing slugs anymore.
  // we should be looking at the module.group instead for these tabs
  const hardSkillsAnswers = answers?.filter(
    field => field.module?.type === 'hard-skills' || field.module?.type === 'knowledge-test',
  )

  // TODO: this should probably be moved to the findSelfAssessmentByCandidateId selector
  // where all content can be returned in terms of domain. Can be timed with upcoming domain/facet/etc changes
  const softSkillsAnswers = useMemo(
    () =>
      answers
        ?.filter(hasPresentKey('module'))
        .filter(field => field.module?.type === 'soft-skills' || field.module?.type === 'self-assessment') || [],
    [answers],
  )

  const assessmentAnswersByDomain: AssessmentAnswersByDomain = []

  if (softSkillsAnswers?.length) {
    const groups = groupBy(
      softSkillsAnswers,
      ans => BESSI_DOMAINS.find(domain => domain.facets.includes(ans.module.slug))?.name || 'Other',
    )

    for (const [domainName, modules] of Object.entries(groups)) {
      assessmentAnswersByDomain.push({
        domain: { name: domainName },
        answers: modules,
      })
    }
  }

  const cognitiveAbilitiesAnswers = answers?.filter(field => field.module?.type === 'cognitive-abilities') || []

  const cultureAddAnswersByDomain: AssessmentAnswersByDomain = useSelector((state: RootState) => {
    if (!answers) return []

    const cultureAddAnswers = answers?.filter(field => {
      const group_id = field?.module?.group_id
      if (!group_id) return false
      const group = selectors.groups.findById(state, group_id)
      return group && group.fields.slug === 'cultureadd'
    })

    if (!cultureAddAnswers?.length) return []

    const groups = groupBy(
      cultureAddAnswers,
      answer =>
        (answer?.module?.inventory_id &&
          selectors.inventories.findById(state, answer.module.inventory_id)?.fields?.copy?.title) ||
        answer?.module?.copy?.title ||
        'Other',
    )

    return Object.entries(groups).map(([domainName, modules]) => ({
      domain: { name: domainName },
      answers: modules,
    }))
  })

  const cultureFitResponses = useSelector((state: RootState) =>
    selectors.questionnaireSubmissions.findCultureFitCheckResponses(state, selfAssessment?.id || ''),
  )

  const candidateAnswers = useMemo(
    () => answers?.filter(field => field.module?.type === 'candidate-centered') || [],
    [answers],
  )

  const history = useHistory()

  // print on mount, and then go back after printing
  useEffect(() => {
    const timer = setTimeout(() => {
      window.print()
    }, 1000)
    function goBack() {
      history.goBack()
    }
    window.addEventListener('afterprint', goBack)
    return () => {
      clearTimeout(timer)
      window.removeEventListener('afterprint', goBack)
    }
  }, [])

  const questionnaireModuleIds = useSelector((state: RootState) => {
    if (!questionnaire) return []
    const result = findByQuestionnaireId(state, questionnaire?.id)
    return (result || []).map(e => e.fields.module_id)
  })
  const moduleQuestions: Map<string, QuestionEntity[]> = useSelector((state: RootState) =>
    findByModuleIds(state, questionnaireModuleIds),
  )

  const [score, setScore] = useState<ScoreResponse | null>(null)

  useEffect(() => {
    if (!questionnaireSubmission?.id) return
    getScore(questionnaireSubmission.id).then(res => {
      const scoreResponse = res[0] as unknown as ScoreResponse
      if (!scoreResponse) return
      setScore(scoreResponse)
    })
  }, [questionnaireSubmission?.id])

  const scoreGroupCards = useMemo<AssessmentTab[]>(() => {
    const hardSkillsScores = score?.meta?.find(m => m.slug === 'hardskills')
    const softSkillsScores = score?.meta?.find(m => m.slug === 'softskills')
    const cognitiveAbilitiesScores = score?.meta?.find(m => m.slug === 'cognitiveabilities')
    const cultureAddScores = score?.meta?.find(m => m.slug === 'cultureadd')
    const personalityScores = score?.meta?.find(m => m.slug === 'personality')
    const candidateExpectationsScores = score?.meta?.find(m => m.slug === 'candidateexpectations')

    return [
      {
        title: 'Overview',
        to: '',
        enabled: true,
        page: (
          <AssessmentSummariesDashboard
            scores={score?.meta || []}
            roleId={role?.fields.id || ''}
            candidateId={candidate?.id || ''}
            candidateFirstName={candidateFirstName}
            sortingOrder={['']}
            isSharedResults={false}
          />
        ),
        tracking: () => tracking.selfAssessment.summaryTabViewed('Overview'),
      },
      {
        title: 'Hard Skills',
        to: '',
        enabled: hardSkillsScores?.children ? hardSkillsScores?.children.length > 0 : false,
        page: (
          <HardSkillsScoreGroup
            key={'Hard Skills'}
            hardSkillsScores={hardSkillsScores}
            candidateFirstName={candidateFirstName}
            roleId={role?.fields.id || ''}
            candidateId={candidate?.id || ''}
            defaultCollapsed={false}
            onResponsesClick={() => {}}
          />
        ),
        tracking: () => tracking.selfAssessment.summaryTabViewed('Hard Skills'),
      },
      {
        title: 'Soft Skills',
        to: '',
        enabled: softSkillsScores?.children ? softSkillsScores?.children.length > 0 : false,
        page: (
          <SoftSkillsScoreGroup
            key={'Soft Skills'}
            softSkillsScores={softSkillsScores}
            selfAssessmentAnswersByDomain={assessmentAnswersByDomain}
            testGroupName={'Soft Skills'}
            cultureFitResponses={cultureFitResponses}
            roleId={role?.fields.id || ''}
            candidateId={candidate?.id || ''}
            candidateFirstName={candidateFirstName}
            defaultCollapsed={false}
            onResponsesClick={() => {}}
          />
        ),
        tracking: () => tracking.selfAssessment.summaryTabViewed('Soft Skills'),
      },
      {
        title: 'Cognitive Abilities',
        to: '',
        enabled: cognitiveAbilitiesScores?.children ? cognitiveAbilitiesScores?.children.length > 0 : false,
        page: (
          <CognitiveAbilitiesDashboard
            key={'Cognitive Abilities'}
            cognitiveAbilitiesScores={cognitiveAbilitiesScores}
            candidateFirstName={candidateFirstName}
            roleId={role?.fields.id || ''}
            candidateId={candidate?.id || ''}
            defaultCollapsed={false}
            onResponsesClick={() => {}}
          />
        ),
        tracking: () => tracking.selfAssessment.summaryTabViewed('Cognitive Abilities'),
      },
      {
        title: 'Culture',
        to: '',
        enabled: cultureAddScores?.children ? cultureAddScores?.children?.length > 0 : false,
        page: (
          <SoftSkillsScoreGroup
            key={'Culture'}
            softSkillsScores={cultureAddScores}
            cultureFitResponses={cultureFitResponses}
            selfAssessmentAnswersByDomain={cultureAddAnswersByDomain}
            testGroupName={'Culture'}
            roleId={role?.fields.id || ''}
            candidateId={candidate?.id || ''}
            candidateFirstName={candidateFirstName}
            defaultCollapsed={false}
            onResponsesClick={() => {}}
          />
        ),
        tracking: () => tracking.selfAssessment.summaryTabViewed('Culture'),
      },
      {
        title: 'Personality',
        to: '',
        enabled: personalityScores?.children ? personalityScores?.children.length > 0 : false,
        page: (
          <PersonalityScoreCard
            key={'Personality'}
            questionnaire={questionnaire}
            personalityScores={personalityScores}
            personalityAnswers={personalityAnswers || []}
            moduleQuestions={moduleQuestions}
            personalityGroupId={personalityGroup?.id || ''}
            isSharedResults={false}
            roleId={role?.id || ''}
            candidateId={candidate?.id || ''}
            candidateFirstName={candidateFirstName}
            defaultCollapsed={false}
            onResponsesClick={() => {}}
          />
        ),
        tracking: () => tracking.selfAssessment.summaryTabViewed('Personality'),
      },
      {
        title: 'Candidate Expectations',
        to: '',
        enabled: candidateExpectationsScores?.children ? candidateExpectationsScores?.children.length > 0 : false,
        page: (
          <CandidateScoreCard
            key="Candidate Expectations"
            candidateExpectationsAnswers={candidateAnswers}
            candidateExpectationsScores={candidateExpectationsScores}
            candidateFirstName={candidateFirstName}
            candidateId={candidate?.id || ''}
            roleId={role?.id || ''}
            defaultCollapsed={false}
          />
        ),
        tracking: () => tracking.selfAssessment.summaryTabViewed('Candidate'),
      },
      {
        title: 'Custom Questions',
        to: '',
        enabled: customQuestionAnswers ? customQuestionAnswers?.length > 0 : false,
        page: (
          <CustomQuestionsDashboard
            key={'Custom Questions'}
            customQuestionAnswers={customQuestionAnswers}
            onResponsesClick={() => {}}
          />
        ),
        tracking: () => tracking.selfAssessment.summaryTabViewed('Custom Questions'),
      },
    ].filter(tab => isPresent(tab) && tab.enabled)
  }, [
    assessmentAnswersByDomain,
    candidateAnswers,
    candidateFirstName,
    cognitiveAbilitiesAnswers,
    customQuestionAnswers,
    hardSkillsAnswers,
    isAssessmentSummariesEnabled,
    moduleQuestions,
    personalityAnswers,
    personalityGroup?.id,
    questionnaire,
    questionnaireSubmission?.fields,
    role?.fields.id,
    cultureAddAnswersByDomain,
    cultureFitResponses,
  ])

  const isSelfAssessmentCompleted = selfAssessment && selfAssessment.fields.submitted_at > 0
  const isSelfAssessmentInProgress = selfAssessment && selfAssessment.fields.submitted_at === 0

  const createPageElements = () => {
    if (!selfAssessment) {
      return (
        <NoInviteYet messageCopy={`Send the Assessment request to ${profile?.fields.full_name || 'this candidate'}`} />
      )
    } else if (isSelfAssessmentInProgress) {
      return (
        <WaitingOnCandidate
          title={`${profile?.fields.full_name || 'This candidate'} was successfully invited!`}
          status={`${
            profile?.fields.full_name || 'This candidate'
          } is about to complete the assessment, so you'll soon find out if they're the right fit for you.`}
        />
      )
    } else if (isSelfAssessmentCompleted) {
      return (
        <div className={`${summariesClasses.scoreGroupWrapper}`} style={{ gap: '1rem' }}>
          {scoreGroupCards.map((card, idx) => (
            <div
              key={card.title + 'tab'}
              className={`${summariesClasses.scoreGroupWrapper} ${summariesClasses.fullWidthSubgridItem} ${
                idx === 0 ? '' : 'dontBreakOnPrint'
              }`}
            >
              {/* first tab is never allowed to break, otherwise there may be an almost blank first page*/}
              {idx === 0 && (
                <div className={`${classes.tabTitle} ${summariesClasses.fullWidthSubgridItem}`}>{card.title}</div>
              )}
              {card.page}
            </div>
          ))}
        </div>
      )
    } else {
      // this should never happen
      return null
    }
  }

  if (!candidateid) return null

  return (
    <div className={classes.container}>
      <Logo variant="logo+wordmark" />
      <NameHeader profile={profile} role={role} selfAssessment={selfAssessment} />
      {createPageElements()}
    </div>
  )
}

export default AssessmentExportPDFPage

interface NameHeaderProps {
  profile: Entity<Fields> | undefined
  role: Entity<api.openjobroles.Fields> | undefined
  selfAssessment: Entity<api.questionnaireSubmissions.Fields> | undefined
}

const NameHeader = (props: NameHeaderProps) => {
  return (
    <div className={classes.nameHeaderContainer}>
      <div className={classes.nameHeader}>
        <div className={classes.nameHeaderName}>{props.profile?.fields.full_name}</div>
        <div className={classes.nameHeaderEmail}>
          <i className="fas fa-envelope" style={{ marginRight: 5 }}></i>
          {props.profile?.fields.email}
        </div>
      </div>
      <div className={classes.nameHeaderRole}>
        <div className={classes.nameHeaderRoleTitle}>{props.role?.fields.name}</div>
        <div className={classes.nameHeaderQuestionnaire}>
          Invited: {formatDateFromNano(props.selfAssessment?.fields.created_at)}
          <br />
          Completed: {formatDateFromNano(props.selfAssessment?.fields.submitted_at)}
        </div>
      </div>
    </div>
  )
}
