import { px2rem, style } from 'core'
import React from 'react'
import { designSystemColors } from '../../core/design-system/colors'

const Container = style()
  .center()
  .border({
    around: '1px solid',
    color: designSystemColors.borderDefault,
    radius: px2rem(8),
  })
  .color({ bg: designSystemColors.backgroundNeutralPrimary })
  .nooverflow()
  .element()

interface ContentContainerProps {
  children: React.ReactNode
}

const ContentContainer: React.FC<ContentContainerProps> = ({ children }) => {
  return <Container>{children}</Container>
}

export default ContentContainer
