import React from 'react'
import { useSelector } from 'react-redux'
import { HashLink as Link } from 'react-router-hash-link'
import { Icon } from '../../components/Icons'
import { colors, size, space, style } from '../../core'
import * as tracking from '../../core/track'
import * as selectors from '../../selectors'
import { RootState } from '../../store'

const Container = style()
  .spacing({
    outer: space.none,
    inner: [space.xs, space.m, space.l, space.m],
  })
  .border({
    radius: [size.none, size.none, size.xs, size.xs],
  })
  .bg({ color: colors.white })
  .select('a', style().block().stretch().sans({ nodecoration: true, color: colors.silver, align: 'center' }))
  .element()

const Separator = style()
  .sans({
    align: 'center',
  })
  .color({ fg: colors.moonGray })
  .element()

interface Props {
  candidateId: string
  referenceId: string
  itemId: string
}

const Component = (props: Props) => {
  const [candidateQaUrl, totalOtherAnswers] = useSelector((state: RootState) => [
    selectors.candidates.qaUrl(state, props.candidateId),
    selectors.references
      .findByCandidateId(state, props.candidateId)
      .filter(
        r =>
          r.id !== props.referenceId &&
          !!selectors.formResponses.findAnswerByReferenceId(state, r.fields.id, props.itemId),
      ).length,
  ])

  if (totalOtherAnswers === 0) return null

  return (
    <Container>
      <Link to={`${candidateQaUrl}#${props.itemId}`} onClick={() => tracking.references.showMoreAnswers()}>
        Show {totalOtherAnswers} more answer{Number(totalOtherAnswers) > 1 ? 's' : ''}
        <Separator>
          <Icon name="sort-down" />
        </Separator>
      </Link>
    </Container>
  )
}

export default Component
Component.displayName = 'ShowMore'
