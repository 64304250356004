import { space } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React from 'react'

const Label = text.bodyEmphasis().element()
const Text = text
  .bodyInteractive()
  .fg(designSystemColors.typographySecondary)
  .spacing({ outerTop: space.xxs })
  .element('p')

interface Props {
  heading: string
  children: React.ReactNode
}

const Component = (props: Props) => (
  <>
    <Label>{props.heading}</Label>
    <Text>{props.children}</Text>
  </>
)

export default Component
Component.displayName = 'InfoCard'
