import React from 'react'
import { Card } from '../../Card/Card'

export const LogoBanner = (props: { src: string }) => {
  return (
    <Card big style={{ height: 'fit-content', padding: '20px' }}>
      <img
        style={{
          maxHeight: '68px',
          maxWidth: '80vw',
          objectFit: 'contain',
          borderRadius: '3px',
        }}
        alt="logo"
        src={props.src}
      />
    </Card>
  )
}
