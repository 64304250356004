import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from '@common/components'
import TextField from '../../components/TextField'
import { RootState } from '../../store'
import { login, setPasswd } from '../../store/login'
import classes from '../SignUp/Signup.module.scss'
import ForgotPassword from './ForgotPassword'
import UserHint from './UserHint'

interface StepPasswordProps {
  onGoogleSignIn: () => void
}

const Component: React.FC<StepPasswordProps> = values => {
  const dispatch = useDispatch()

  const { email, passwd, loading } = useSelector((state: RootState) => state.login)

  return (
    <>
      <UserHint />
      <TextField
        autoFocus
        label="Password:"
        onChange={e => dispatch(setPasswd(e.target.value))}
        placeholder="Enter your password"
        value={passwd}
        onEnter={onSubmit}
        type="password"
        testId={'step-password-input'}
      />
      <ForgotPassword />
      <Button
        onClick={onSubmit}
        rounded
        variant="purple-dark"
        disabled={loading}
        data-testid={'step-password-button'}
        className={classes.marginTop}
      >
        Continue
      </Button>
    </>
  )

  function onSubmit() {
    dispatch(login(email, passwd))
    dispatch(setPasswd(''))
  }
}

export default Component
Component.displayName = 'StepPassword'
