import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React from 'react'
import { px2rem, size, space, style } from '../../core'

interface Props {
  title: string
  status: string
  cta?: React.ReactNode
}

const WaitingOnCandidateStyle = style()
  .spacing({ inner: [space.xl], gap: px2rem(12) })
  .size({ minHeight: px2rem(595) })
  .center()
  .element()

const WaitingImageContainer = style().element()

const WaitingImage = style()
  .size({ height: px2rem(117) })
  .element('img')

const WaitingHeader = text.cardHeader().element()

const WaitingSecondary = text
  .bodyInteractive()
  .spacing({ outerBottom: space.xs })
  .fg(designSystemColors.typographySecondary)
  .element()

const WaitingTextContainer = style()
  .grid()
  .spacing({ gap: px2rem(6) })
  .size({ width: '25%' })
  .element()

const InformationBlock = style()
  .bg({ color: designSystemColors.backgroundNeutralPrimary })
  .size({ width: size.fill })
  .border({
    around: '1px solid',
    radius: px2rem(4),
    color: designSystemColors.borderDefault,
  })
  .element()

const IMAGE = '/assets/woman_with_watch.png'

const WaitingOnCandidate: React.FC<Props> = ({ title, status, cta }) => {
  return (
    <InformationBlock>
      <WaitingOnCandidateStyle className="data-hj-suppress">
        <WaitingImageContainer>
          <WaitingImage src={IMAGE} />
        </WaitingImageContainer>
        <WaitingTextContainer>
          <WaitingHeader>{title}</WaitingHeader>
          <WaitingSecondary>{status}</WaitingSecondary>
          {cta || <></>}
        </WaitingTextContainer>
      </WaitingOnCandidateStyle>
    </InformationBlock>
  )
}

export default WaitingOnCandidate
