import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import * as tracking from '../../core/track'

function hasOnlyLettersAndNumbers(str) {
  return /^[0-9a-zA-Z]+$/.test(str)
}

export const LinkSourceTracker: React.FC = () => {
  const sourceParamKey = 'ref'
  const history = useHistory()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const [hasTracked, setHasTracked] = useState(false)

  const source = (params.get(sourceParamKey) || '').toString().trim()

  // Only allow letters and a length of less than 30 characters for sources.
  const validSource = source.length > 0 && hasOnlyLettersAndNumbers(source) && source.length < 30

  if (!hasTracked && validSource) {
    tracking.links.usedLinkFromSource(source, history.location.pathname)
    setHasTracked(true)

    const queryParams = new URLSearchParams(location.search)
    queryParams.delete(sourceParamKey)

    history.replace({ search: queryParams.toString() })
  }

  return <></>
}
