import { Icon } from 'components/Icons'
import Modal from 'components/Modal'
import { fr, px2rem, rem, size, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import { candidateBaseURL, copyToClipboard } from 'core/utils'
import { useCandidateProfile } from 'providers/candidates'
import { useEmployee } from 'providers/employees'
import { useOrg } from 'providers/orgs'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as selectors from 'selectors'
import { RootState } from 'store'
import { add as notify } from '../../store/notifications'

const Container = style()
  .size({
    maxWidth: rem(40),
    maxHeight: rem(30),
    width: 'calc(100vw - 200px)',
    height: 'calc(100dvh - 400px)',
  })
  .element()

interface PreviewOnboardingModalProps {
  candidateId: string
  open: boolean
  setOpen: (b: boolean) => void
}

const ScoresContainer = style().spacing({ inner: size.s }).element()

const ScoresTable = style()
  .block()
  .round(px2rem(4))
  .nooverflow()
  .border({
    around: `solid ${px2rem(1)}`,
    color: designSystemColors.borderDefault,
  })
  .element('table')

const ScoresTableHeader = style()
  .block()
  .color({ bg: designSystemColors.backgroundNeutralSecondaryHover })
  .element('thead')

const ScoresTableHeaderCell = text
  .smallBody()
  .sans({ align: 'left' })
  .color({ fg: designSystemColors.typographySecondary })
  .element('th')

const ScoresTableRow = style()
  .grid({ columns: [fr(3), fr(3), fr(1), fr(1)] })
  .spacing({ inner: [px2rem(8), px2rem(12)] })
  .select('&:nth-child(even)', style().color({ bg: designSystemColors.backgroundNeutralSecondary }))
  .element('tr')

const ScoresTableBody = style().block().element('tbody')

const ScoresTableBodyCell = text.label().element('td')

const IconContainer = style()
  .pointer()
  .bg({ color: designSystemColors.transparent })
  .noborders()
  .nospacing()
  .sans({ nodecoration: true })
  .element('button')

const PreviewOnboardingModal: React.FC<PreviewOnboardingModalProps> = ({ open, setOpen, candidateId }) => {
  const dispatch = useDispatch()
  const { org } = useOrg({ candidateId })
  const { employee } = useEmployee({ candidateId })
  const profile = useCandidateProfile({ candidateId })
  const manager = useSelector((state: RootState) =>
    selectors.profiles.getByUserId(state, employee?.fields.manager_id || ''),
  )
  const submissions = useSelector((state: RootState) =>
    selectors.questionnaireSubmissions.findByEmployeeId(state, employee?.id || ''),
  )

  const token = useSelector((state: RootState) => state.sessions.jwt)

  return (
    <Modal title={`Preview Onboarding Modals`} open={open} setOpen={setOpen}>
      <Container>
        <ScoresContainer>
          <ScoresTable>
            <ScoresTableHeader>
              <ScoresTableRow>
                <ScoresTableHeaderCell>Respondent</ScoresTableHeaderCell>
                <ScoresTableHeaderCell>Due Date</ScoresTableHeaderCell>
                <ScoresTableHeaderCell>Done</ScoresTableHeaderCell>
                <ScoresTableHeaderCell>Link</ScoresTableHeaderCell>
              </ScoresTableRow>
            </ScoresTableHeader>
            <ScoresTableBody>
              {(submissions || []).map(sub => (
                <ScoresTableRow key={sub.id}>
                  <ScoresTableBodyCell>
                    {sub.fields.employee_id ? profile?.fields.full_name || '' : manager?.fields.full_name || ''}
                  </ScoresTableBodyCell>
                  <ScoresTableBodyCell>{new Date(sub.fields.due_at / 1e6).toLocaleDateString()}</ScoresTableBodyCell>
                  <ScoresTableBodyCell>{sub.fields.submitted_at > 0 ? '✅' : '❌'}</ScoresTableBodyCell>
                  <ScoresTableBodyCell>
                    <IconContainer
                      onClick={() => {
                        copyToClipboard(
                          `${candidateBaseURL(org?.fields.slug || '')}/submissions/${sub.id}/preview?token=${token}`,
                        )
                        dispatch(
                          notify({
                            success: 'Preview link is copied to your clipboard',
                          }),
                        )
                      }}
                    >
                      <Icon variant={'regular'} name={'copy'} />
                    </IconContainer>
                  </ScoresTableBodyCell>
                </ScoresTableRow>
              ))}
            </ScoresTableBody>
          </ScoresTable>
        </ScoresContainer>
      </Container>
    </Modal>
  )
}

export default PreviewOnboardingModal
