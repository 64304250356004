import { map } from './css'
import Rows from './rows'

export interface IOptions {
  style: string
}

export default function list(rows: Rows, options: IOptions) {
  map(rows, options, {
    style: 'listStyle',
  })
}
