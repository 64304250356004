import React from 'react'
import classes from './LoadingSpinner.module.scss'

interface LoadingSpinnerProps {
  className?: string
  darkStyle?: boolean
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ className, darkStyle }) => {
  return <div className={`${classes.spinner} ${darkStyle ? classes.dark : ''} ${className}`} />
}
