import { Icon } from 'components/Icons'
import { px2rem, size, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React from 'react'

interface NoInviteYetProps {
  messageCopy: string
}

const Header = text
  .cardHeader()
  .center()
  .spacing({ outerBottom: px2rem(4) })
  .element()

const Secondary = text.bodyInteractive().center().fg(designSystemColors.typographySecondary).element()

const IconContainer = style()
  .center()
  .sans({ size: px2rem(43), color: designSystemColors.typographySecondary })
  .spacing({ outerBottom: px2rem(12) })
  .element()

const InformationBlock = style()
  .bg({ color: designSystemColors.backgroundNeutralPrimary })
  .size({ width: size.fill, minHeight: px2rem(595) })
  .flex({ justifyContent: 'center', alignItems: 'center' })
  .border({
    around: '1px solid',
    radius: px2rem(4),
    color: designSystemColors.borderDefault,
  })
  .element()

const Container = style().element()

const NoInviteYet: React.FC<NoInviteYetProps> = ({ messageCopy }) => {
  return (
    <InformationBlock>
      <Container>
        <IconContainer>
          <Icon name={'exclamation-triangle'} ariaLabel={'Warning'} />
        </IconContainer>
        <Header>Request not yet sent</Header>
        <Secondary>{messageCopy}</Secondary>
      </Container>
    </InformationBlock>
  )
}

export default NoInviteYet
