import { px2rem, style } from 'core'
import React from 'react'
import { designSystemColors } from '../../core/design-system/colors'
import { text } from '../../core/design-system/text'
import { Icon } from '../Icons'
import { ArrowPosition, TooltipContent } from './index'

const InfoIcon = text.smallBodySecondary().inlineBlock().element('span')

const Hint = style()
  .inlineBlock()
  .relative()
  .select(`:hover ${TooltipContent.Style}`, style().visible())
  .front()
  .cond(
    ({ arrowPosition }: { arrowPosition: ArrowPosition }) => arrowPosition === 'right',
    style().select(
      `${TooltipContent.Style}`,
      style()
        .absolute({ top: 0, left: '50%' })
        .invisible()
        .set('transform', 'translateX(calc(-100% - ' + px2rem(7) + ')) translateY(-40%)'),
    ),
  )
  .cond(
    ({ arrowPosition }: { arrowPosition: ArrowPosition }) => arrowPosition === 'bottom',
    style().select(
      `${TooltipContent.Style}`,
      style().absolute({ top: 0, left: '50%' }).invisible().set('transform', 'translateX(-50%) translateY(-100%)'),
    ),
  )
  .element()

const TooltipContentStyled = text
  .smallBody()
  .color({ fg: designSystemColors.backgroundNeutralPrimary })
  .block()
  .sans({ align: 'center' })
  .spacing({ inner: [px2rem(2), px2rem(4)] })
  .size({ width: px2rem(192) })
  .element('p')

interface IconTooltipProps {
  tooltipCopy: string
  icon: string
  className?: string
  arrowPosition?: ArrowPosition
}

const IconTooltip: React.FC<IconTooltipProps> = ({ tooltipCopy, icon, className, arrowPosition = 'bottom' }) => {
  return (
    <InfoIcon className={className}>
      <Hint arrowPosition={arrowPosition}>
        <TooltipContent arrowPosition={arrowPosition}>
          <TooltipContentStyled>{tooltipCopy}</TooltipContentStyled>
        </TooltipContent>
        <Icon name={icon} />
      </Hint>
    </InfoIcon>
  )
}

export default IconTooltip
