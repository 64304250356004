import { Icon, LetterIcon } from 'components/Icons'
import { designSystemColors } from 'core/design-system/colors'
import { useFeatureFlag } from 'providers/features'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { useMount } from 'react-use'
import { RootState } from 'store'
import { logoutSSO } from 'store/login'
import { logout } from 'store/sessions'
import * as tracking from '../../core/track'
import { DemoStep, useDemo } from '../../providers/demo'
import * as selectors from '../../selectors'
import { loadSidebarRole } from '../../store/roles'
import AssessmentsIcon from './AssessmentsIcon.svg?react'
import CandidateFeedbackIcon from './CandidateFeedbackIcon.svg?react'
import CandidatesIcon from './CandidatesIcon.svg?react'
import HomeIcon from './HomeIcon.svg?react'
import HorizontalSeperator from './HorizontalSeperator.svg?react'
import OrgMenu from './OrgMenu'
import ProfileMenu from './ProfileMenu'
import ReferenceChecksIcon from './ReferenceCheckIcon.svg?react'
import ReportsIcon from './ReportsIcon.svg?react'
import RolesIcon from './RolesIcon.svg?react'
import SalesPoolIcon from './SalesPoolIcon.svg?react'
import ShieldIcon from './ShieldIcon.svg?react'
import TalentPoolIcon from './TalentPoolIcon.svg?react'
import { Popover, PopoverTrigger, Tooltip } from '@common/components'
import styles from './styles.module.scss'

const MenuItemIcon = ({
  icon,
  ...props
}: {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  [key: string]: any
}) => {
  const ItemIcon = icon
  return <ItemIcon {...props} />
}

export type MenuItemType = {
  name: string
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string | undefined }>
  element: 'link' | 'separator'
  link: string
  isEnabled: boolean
  requireElevatedRights: boolean
  tracking?: () => void
  hasBeacon?: boolean
  isLocked?: boolean
  testId?: string
}

interface SidebarUIProps {
  menuItems: MenuItemType[]
  data: {
    org: { name: string; logoUrl: string }
    user: { id: string; name: string; profileImageUrl?: string }
  }
  canManageOrganization: boolean
  canProvideSupport: boolean
  onClickSignout: () => void
  helpTracking?: () => void
  profileSettingsTracking?: () => void
  orgSettingsTracking?: () => void
  manageUsersTracking?: () => void
  areBeaconsVisible?: boolean
}

const SidebarUI: React.FC<SidebarUIProps> = ({
  menuItems,
  data,
  canManageOrganization,
  canProvideSupport,
  onClickSignout,
  helpTracking,
  profileSettingsTracking,
  orgSettingsTracking,
  manageUsersTracking,
  areBeaconsVisible,
}) => {
  const [isOrgMenuOpen, setOrgMenuOpen] = useState(false)
  const [isProfileMenuOpen, setProfileMenuOpen] = useState(false)

  const { pathname } = useLocation()

  const enabledItems = menuItems.filter(item => {
    if (item.requireElevatedRights && !canManageOrganization) {
      return false
    }
    return item.isEnabled
  })

  const orgMenuButtonRef = useRef<HTMLElement>(null)
  const profileMenuButtonRef = useRef<HTMLElement>(null)

  return (
    <nav className={styles.container}>
      <Popover side="top-right">
        <PopoverTrigger
          className={styles.orgButton}
          data-menu-open={isOrgMenuOpen}
          aria-label="view organization menu"
          aria-expanded={isOrgMenuOpen}
          aria-haspopup={true}
          data-testid="sidebar-org-button"
        >
          <img
            src={data.org.logoUrl}
            alt={data.org.name}
            style={{
              width: '48px',
              aspectRatio: '1 / 1',
              borderRadius: '1.125rem',
              border: `solid 2px ${designSystemColors.borderDefault}`,
              backgroundColor: designSystemColors.backgroundNeutralPrimary,
              objectFit: 'contain',
            }}
          />
        </PopoverTrigger>

        <OrgMenu
          isMenuOpen={isOrgMenuOpen}
          setMenuOpen={setOrgMenuOpen}
          helpTracking={helpTracking}
          onClickSignout={onClickSignout}
          canManageOrganization={canManageOrganization}
          canProvideSupport={canProvideSupport}
          activatorRef={orgMenuButtonRef}
          orgSettingsTracking={orgSettingsTracking}
          manageUsersTracking={manageUsersTracking}
        />
      </Popover>

      <ul className={styles.menuBar} aria-label="pages menu">
        {enabledItems.map(item =>
          item.element === 'separator' ? (
            <HorizontalSeperator key={item.name} />
          ) : (
            <li key={item.name} className={styles.menuItemStyle} data-selected={pathname === item.link}>
              <Tooltip text={item.name} direction="right">
                <Link
                  aria-label={item.name}
                  to={item.link}
                  aria-current={pathname === item.link ? 'page' : false}
                  onClick={item.tracking}
                  data-testid={item.testId}
                >
                  <div className={styles.menuItemContent} data-selected={pathname === item.link}>
                    <div>
                      <MenuItemIcon
                        icon={item.icon}
                        style={
                          pathname === item.link
                            ? { '--outline-color': '#495EEE', '--fill-color': '#BBC2F0' }
                            : { '--outline-color': '#666666', '--fill-color': '#E4E4E4' }
                        }
                      />
                      {item.isLocked && (
                        <div className={styles.lock}>
                          <Icon name={'lock'} />
                        </div>
                      )}
                    </div>
                  </div>
                  <span className={styles.selectedItemOverlay} aria-hidden={true} />
                </Link>
              </Tooltip>
            </li>
          ),
        )}
      </ul>

      <Popover side="bottom-right">
        <PopoverTrigger
          className={styles.profileButton}
          data-menu-open={isProfileMenuOpen}
          aria-label="view profile menu"
          aria-expanded={isProfileMenuOpen}
          aria-haspopup={true}
          data-testid="sidebar-profile-button"
        >
          <div className={`${styles.profileImageContainer} data-hj-suppress`}>
            {data.user.profileImageUrl ? (
              <img className={styles.profileImage} src={data.user.profileImageUrl} alt={data.user.name} />
            ) : data.user.name ? (
              <LetterIcon name={data.user.name} />
            ) : null}
          </div>
        </PopoverTrigger>

        <ProfileMenu
          isMenuOpen={isProfileMenuOpen}
          setMenuOpen={setProfileMenuOpen}
          onClickSignout={onClickSignout}
          activatorRef={profileMenuButtonRef}
          userId={data.user?.id}
          profileSettingsTracking={profileSettingsTracking}
        />
      </Popover>
    </nav>
  )
}

interface SidebarMenuProps {}

const SidebarMenu: React.FC<SidebarMenuProps> = () => {
  const dispatch = useDispatch()

  const { isEnabled: isProductPagesEnabled } = useFeatureFlag('product-pages')

  const { isEnabled: isQualityOfHireEnabled } = useFeatureFlag('quality-of-hire')

  const { isEnabled: isCandidateExperienceEnabled } = useFeatureFlag('candidate-experience')

  const org = useSelector((state: RootState) => selectors.orgs.findByUserId(state, state.sessions.userId))

  const state = useSelector((state: RootState) => state)

  const orgSettings = useMemo(() => (org ? selectors.orgSettings.getByOrgId(state, org?.id) : null), [org, state])

  const user = useSelector((state: RootState) => selectors.users.current(state))

  const samlsso = useSelector((state: RootState) => selectors.samlsso.findByOrgId(state, org?.id || ''))

  const profile = useSelector((state: RootState) => selectors.profiles.getByUserId(state, state.sessions.userId))

  const canManageOrganization = useSelector((state: RootState) =>
    selectors.users.canManageOrganization(state, state.sessions.userId),
  )

  const canProvideSupport = useSelector((state: RootState) =>
    selectors.users.canProvideSupport(state, state.sessions.userId),
  )

  const userId = useSelector(selectors.users.current)?.id || ''
  const canCreateRole = useSelector((state: RootState) => selectors.users.canCreateRole(state, userId))

  const hasCreatedRole = useSelector((state: RootState) => state.roles.hasCreatedRole)
  const { step, isInDemo } = useDemo()

  const [areBeaconsVisible, setBeaconsVisible] = useState(false)

  const isOrgPaidPlan = useSelector(selectors.orgs.isOrgPaidPlan)

  useMount(() => {
    dispatch(loadSidebarRole(userId))
  })

  useEffect(() => {
    if (((isInDemo && step === DemoStep.NeedsRole) || !hasCreatedRole) && canCreateRole) {
      setBeaconsVisible(true)
    } else setBeaconsVisible(false)
  }, [isInDemo, step, hasCreatedRole, canCreateRole])

  const data = {
    org: {
      name: org?.fields.name || '',
      logoUrl: org?.fields.logo_image_url || 'https://avatars2.githubusercontent.com/u/60610251?s=200&v=4',
    },
    user: {
      id: user?.id || '',
      name: profile?.fields.full_name || '',
      profileImageUrl: profile?.fields.profile_picture_url,
    },
  }

  const menuItems: MenuItemType[] = useMemo(
    () => [
      {
        name: 'Home',
        icon: HomeIcon,
        element: 'link',
        link: '/',
        isEnabled: true,
        requireElevatedRights: false,
        tracking: () => tracking.home.homeButtonClicked(),
        hasBeacon: true,
        testId: 'sidebar-home',
      },
      {
        name: 'Separator1',
        icon: HorizontalSeperator,
        link: '',
        element: 'separator',
        isEnabled: !!isProductPagesEnabled,
        requireElevatedRights: false,
      },
      {
        name: 'Jobs',
        icon: RolesIcon,
        element: 'link',
        link: '/roles',
        isEnabled: isOrgPaidPlan,
        requireElevatedRights: false,
        tracking: () => tracking.roles.rolesButtonClicked(),
        hasBeacon: true,
        testId: 'sidebar-roles',
      },
      {
        name: 'Candidates',
        icon: CandidatesIcon,
        element: 'link',
        link: '/candidates',
        isEnabled: true,
        requireElevatedRights: false,
        tracking: () => tracking.candidates.candidatesButtonClicked(),
        testId: 'sidebar-candidates',
      },
      {
        name: 'Separator2',
        icon: HorizontalSeperator,
        link: '',
        element: 'separator',
        isEnabled: !!isProductPagesEnabled,
        requireElevatedRights: false,
      },
      {
        name: 'Assessments',
        icon: AssessmentsIcon,
        element: 'link',
        link: '/assessments',
        isEnabled: !!isProductPagesEnabled,
        requireElevatedRights: false,
        tracking: () => tracking.selfAssessment.sidebarAssessmentPageViewed(),
        testId: 'sidebar-assessments',
      },
      {
        name: 'Reference Checks',
        icon: ReferenceChecksIcon,
        element: 'link',
        link: '/reference-feedback',
        isEnabled: !!isProductPagesEnabled,
        requireElevatedRights: false,
        tracking: () => tracking.references.sidebarReferenceFeedbackPageViewed(),
        testId: 'sidebar-reference-checks',
      },
      {
        name: 'Quality of Hire',
        icon: ShieldIcon,
        element: 'link',
        link: '/quality-of-hire',
        isEnabled: isOrgPaidPlan && !!isQualityOfHireEnabled,
        requireElevatedRights: false,
        tracking: () => tracking.qualityofhire.sidebarQualityOfHirePageViewed(),
        isLocked: org?.fields.plan !== 'normal',
        testId: 'sidebar-quality-of-hire',
      },
      {
        name: 'Candidate Experience',
        icon: CandidateFeedbackIcon,
        element: 'link',
        link: '/candidateexperience/scores',
        isEnabled: isOrgPaidPlan && !!isCandidateExperienceEnabled,
        requireElevatedRights: true,
        tracking: () => tracking.candidateExperience.candidateExperienceDropdownClicked(),
        isLocked: org?.fields.plan !== 'normal',
        testId: 'sidebar-candidate-experience',
      },
      {
        name: 'Talent Pool',
        icon: TalentPoolIcon,
        element: 'link',
        link: '/talentpool',
        isEnabled: canCreateRole,
        requireElevatedRights: false,
        tracking: () => tracking.talentPool.view(),
        isLocked: org?.fields.plan !== 'normal',
        testId: 'sidebar-talent-pool',
      },
      {
        name: 'Sales Pool',
        icon: SalesPoolIcon,
        element: 'link',
        link: '/sales-pool',
        isEnabled: isOrgPaidPlan && canCreateRole,
        requireElevatedRights: false,
        tracking: () => tracking.salesPool.sidebarClicked(),
        isLocked: org?.fields.plan !== 'normal',
        testId: 'sidebar-sales-pool',
      },
      {
        name: 'Reports',
        icon: ReportsIcon,
        element: 'link',
        link: '/reports',
        isEnabled: isOrgPaidPlan,
        requireElevatedRights: true,
        tracking: () => tracking.orgs.reportsButtonClicked(),
        testId: 'sidebar-reports',
      },
    ],
    [
      canCreateRole,
      isCandidateExperienceEnabled,
      isProductPagesEnabled,
      isQualityOfHireEnabled,
      org,
      orgSettings?.fields?.sales_pool_enabled,
      orgSettings?.fields?.talent_pool_enabled,
    ],
  )

  function onClickSignout() {
    if (samlsso) {
      dispatch(logoutSSO(samlsso.id))
    }
    dispatch(logout())
    tracking.orgs.logout()
    tracking.reset()
  }

  return (
    <SidebarUI
      menuItems={menuItems}
      data={data}
      canManageOrganization={canManageOrganization || canProvideSupport}
      canProvideSupport={canProvideSupport}
      onClickSignout={() => onClickSignout()}
      helpTracking={() => tracking.orgs.help()}
      profileSettingsTracking={() => tracking.orgs.myAccount()}
      orgSettingsTracking={() => tracking.orgs.settings()}
      manageUsersTracking={() => tracking.orgs.manageUsers()}
      areBeaconsVisible={areBeaconsVisible}
    />
  )
}

export default SidebarMenu
