import React, { useEffect, useState } from 'react'
import { ATS as ATSType } from 'store/integrations/ats'
import Tabs, { Tab, TabList, TabPanel } from '../../components/Tabs'
import Wizard, { Steps, TabStep as WizardButton } from '../../components/Wizard'
import { rem, style } from '../../core'

const DEFAULT_HELP_LINK = 'https://intercom.help/hipeople/en/'

const Container = style()
  .size({
    maxWidth: rem(60),
    maxHeight: rem(36),
    width: 'calc(100vw - 200px)',
    height: 'calc(100dvh - 200px)',
  })
  .element()

export interface TabItem {
  tab: string
  desc: string
  component: any
}

interface Props {
  tabItems: TabItem[]
  closeModal: () => void
  setHelpLink: (link: string) => void
  helpLinks: { [id: string]: string }
  atsMap: { [key: string]: ATSType }
}

const Component = (props: Props) => {
  const [selectedTab, setSelectedTab] = useState(0)
  const [done, setDone] = useState({} as { [id: string]: boolean })

  useEffect(() => {
    props.setHelpLink(props.helpLinks[props.tabItems[selectedTab].tab] || DEFAULT_HELP_LINK)
  }, [selectedTab])

  return (
    <Container>
      <Wizard>
        <TabList>
          <Steps>
            {props.tabItems.map((item, i) => (
              <Tab key={i} item={item.tab}>
                <WizardButton
                  index={i + 1}
                  done={done[item.tab] || false}
                  active={selectedTab === i}
                  // onClick={() => setSelectedTab(ROLE_TAB)}
                >
                  {item.desc}
                </WizardButton>
              </Tab>
            ))}
          </Steps>
        </TabList>
        <Tabs selected={selectedTab}>
          {props.tabItems.map((item, i) => {
            const TabComponent = item.component
            return (
              <TabPanel key={i}>
                <TabComponent
                  back={back}
                  next={next}
                  setDone={(isDone: boolean) => setDone({ ...done, [item.tab]: isDone })}
                  atsMap={props.atsMap}
                  closeModal={close}
                />
              </TabPanel>
            )
          })}
        </Tabs>
      </Wizard>
    </Container>
  )

  function close() {
    props.closeModal()
  }

  function next() {
    setSelectedTab(normalizeTabIndex(selectedTab + 1))
  }

  function back() {
    setSelectedTab(normalizeTabIndex(selectedTab - 1))
  }

  function normalizeTabIndex(ind: number): number {
    return ind % props.tabItems.length
  }
}

export default Component
