import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { findByCandidateId, OneTimePasswordEntity } from 'selectors/one-time-password'
import { RootState } from 'store'

export function useOtp(): { otp?: OneTimePasswordEntity } {
  const { candidateid } = useParams<{ candidateid?: string }>()
  const oneTimePasswords = useSelector((state: RootState) => findByCandidateId(state, candidateid || ''))

  return { otp: oneTimePasswords[0] }
}
