import React from 'react'
import { useSelector } from 'react-redux'
import * as selectors from '../../../selectors'
import { RootState } from '../../../store'
import Followup from './Followup'

interface Props {
  referenceId: string
  contentId: string
}

const Component = (props: Props) => {
  const followupAnswer = useSelector((state: RootState) =>
    selectors.assessmentVersions.findFollowupQuestionsByReferenceId(state, props.referenceId, props.contentId),
  )

  if (!followupAnswer) return <></>

  return (
    <>
      {followupAnswer.map(followup => (
        <Followup key={followup.content_id} referenceId={props.referenceId} followup={followup} />
      ))}
    </>
  )
}

export default Component
Component.displayName = 'ReferenceFollowup'
