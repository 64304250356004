import { Tooltip } from '@common/components'
import { Icon } from 'components/Icons'
import { fr, px2rem, size, space, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React, { useState } from 'react'

export type Score = {
  id: string
  topic: string
  dimension: string
  dimensionLabel: string
  score: number
  emoji: string
}

interface TopicSummaryProps {
  title: string
  emoji: string
  praise: Score[]
  adjust: Score[]
  onItemClick: (id: string) => void
}

const Title = text
  .bodyEmphasis()
  .sans({ align: 'left' })
  .color({ fg: designSystemColors.typographyPrimary })
  .element('h3')

const Header = style()
  .grid({
    columns: [size.auto, fr(1), size.auto],
    align: 'center',
  })
  .spacing({ gap: px2rem(12) })
  .size({ width: '100%' })
  .element()

const Emoji = style()
  .flex({ alignItems: 'center', justifyContent: 'center' })
  .with(({ bgColor }: { bgColor: string }) => style().color({ bg: bgColor }))
  .size({ width: px2rem(42), height: px2rem(42) })
  .border({
    radius: px2rem(6),
  })
  .element()

const TopicSummaryStyle = style()
  .grid()
  .bg({ color: designSystemColors.backgroundNeutralPrimary })
  .size({ width: size.fill })
  .spacing({ inner: px2rem(16), rows: px2rem(16) })
  .border({
    around: `solid 1px`,
    color: designSystemColors.borderDefault,
    radius: px2rem(6),
  })
  .element()

const TableContainer = style()
  .grid({ columns: [fr(1), fr(1)] })
  .spacing({ gap: px2rem(16) })
  .element()

const Trait = text
  .bodyEmphasis()
  .flex()
  .spacing({ gap: px2rem(4) })
  .element()

const IconContainer = style()
  .color({ fg: designSystemColors.typographySecondary })
  .spacing({ outer: [size.auto, size.none] })
  .pointer()
  .element()

const TraitContainer = style().flex({ direction: 'column' }).spacing({ gap: space.xs }).element()

const Chips = text
  .smallBody()
  .flex({ alignItems: 'center' })
  .spacing({ gap: px2rem(4), outer: [space.auto, space.none] })
  .element()

const Chip = text
  .smallBody()
  .border({
    around: '1px solid',
    color: designSystemColors.borderDefault,
    radius: px2rem(10),
  })
  .spacing({ inner: px2rem(4) })
  .color({ bg: designSystemColors.backgroundNeutralPrimary })
  .cond(({ selected }) => selected, style().color({ bg: designSystemColors.backgroundNeutralTertiary }))
  .pointer()
  .element('button')

const TopicSummary: React.FC<TopicSummaryProps> = ({
  title,
  emoji,
  praise,
  adjust,
  onItemClick,
}: TopicSummaryProps) => {
  const dimensions = Array.from(new Set([...praise.map(d => d.dimensionLabel), ...adjust.map(d => d.dimensionLabel)]))
  const [dimensionsToSee, setDimensionsToSee] = useState<string[]>(dimensions)

  return (
    <TopicSummaryStyle>
      <Header>
        <Emoji bgColor={designSystemColors.informationPurpleBackground}>{emoji}</Emoji>
        <Title>{title}</Title>
        <Chips>
          Show:{' '}
          {dimensions.map(dimension => (
            <Chip
              selected={dimensionsToSee.includes(dimension)}
              onClick={() => {
                if (dimensionsToSee.includes(dimension)) {
                  const dim = dimensionsToSee.filter(i => i !== dimension)
                  setDimensionsToSee(dim)
                  return
                }

                setDimensionsToSee([...dimensionsToSee, dimension])
              }}
              key={dimension}
            >
              {dimension}
            </Chip>
          ))}
        </Chips>
      </Header>
      <TableContainer>
        <TraitContainer>
          <Trait>
            Praise
            <Tooltip text="Most managers underestimate the importance of recognizing their reports on work well done.">
              <IconContainer>
                <Icon name="question-circle" />
              </IconContainer>
            </Tooltip>
          </Trait>
          <ScoreTable onItemClick={onItemClick} scores={praise} dimensions={dimensionsToSee} />
        </TraitContainer>
        <TraitContainer>
          <Trait>
            Analyze
            <Tooltip text="A more in-depth analysis is worthwhile in order to uncover potential for improvement in onboarding.">
              <IconContainer>
                <Icon name="question-circle" />
              </IconContainer>
            </Tooltip>
          </Trait>
          <ScoreTable onItemClick={onItemClick} scores={adjust} dimensions={dimensionsToSee} />
        </TraitContainer>
      </TableContainer>
    </TopicSummaryStyle>
  )
}

interface ScoreTableProps {
  scores: Score[]
  dimensions: string[]
  onItemClick: (id: string) => void
}

const ScoreTableStyle = style()
  .border({
    around: `1px solid`,
    color: designSystemColors.borderDefault,
    radius: px2rem(4),
  })
  .size({ width: size.fill })
  .nooverflow()
  .set('borderSpacing', '0px')
  .element('table')

const Row = style()
  .grid({ columns: [fr(3), fr(3), fr(1)] })
  .spacing({ inner: px2rem(12) })
  .pointer()
  .select('&:nth-child(even)', style().bg({ color: designSystemColors.backgroundNeutralSecondary }))
  .size({ width: size.fill })
  .element('tr')

const HeaderRow = style()
  .grid({ columns: [fr(3), fr(3), fr(1)] })
  .spacing({ inner: [px2rem(8), px2rem(12)] })
  .sans({ align: 'left' })
  .size({ width: size.fill })
  .bg({ color: designSystemColors.backgroundNeutralSecondaryHover })
  .element('thead')

const Category = text
  .smallBodySecondary()
  .cond(({ right }) => right, style().sans({ align: 'right' }))
  .element('th')

const TableBody = style().element('tbody')

const TopicLabel = text.label().element('td')

const DimensionLabel = text.smallBody().element('td')

const ScoreLabel = text.label().sans({ ellipsis: true, align: 'right' }).element('td')

const MaxScore = text.smallBody().inline().element()

const RATING_SCALE = 7

const ScoreTable: React.FC<ScoreTableProps> = ({ scores, dimensions, onItemClick }: ScoreTableProps) => {
  return (
    <ScoreTableStyle>
      <HeaderRow>
        <Category>Topic</Category>
        <Category>Dimension</Category>
        <Category right>Average</Category>
      </HeaderRow>
      <TableBody>
        {scores
          .filter(i => dimensions.includes(i.dimensionLabel))
          .slice(0, 3)
          .map(score => (
            <Row key={score.topic} onClick={() => onItemClick(score.id)}>
              <TopicLabel>
                {score.emoji} {score.topic}
              </TopicLabel>
              <DimensionLabel>{score.dimensionLabel}</DimensionLabel>
              <ScoreLabel>
                {score.score}
                <MaxScore> / {RATING_SCALE}</MaxScore>
              </ScoreLabel>
            </Row>
          ))}
      </TableBody>
    </ScoreTableStyle>
  )
}

export default TopicSummary
