import { Embeds } from '@checkr/web-sdk'
import Modal, { Content } from 'components/Modal'
import { px2rem, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import React from 'react'
import ReactDOM from 'react-dom'
import { useDispatch } from 'react-redux'
import { updateCheckr } from '../../../store/candidates'

const EmbedContainer = style()
  .size({ width: px2rem(640) })
  .element()

const NewInvitationEmbedStyles = {
  '.btn': {
    'border-radius': px2rem(8),
  },
  '.btn-primary': {
    'background-color': designSystemColors.uiStatusSuccess,
  },
  '.btn-submit:disabled': {
    opacity: 0.35,
  },
  '.header': {
    display: 'none',
  },
}
interface RequestCheckModalProps {
  candidateId: string
  open: boolean
  setOpen: (open: boolean) => void
  sessionTokenPath: string
  bearerToken: string
  candidateEmail: string
}

interface invitationSuccessResponse {
  candidate_id: string
  created_at: string
  custom_external_status: {
    code: string
    message: string
  }
  external_background_check_id: string
  external_candidate_id: string
  external_job_application_id: string
  external_requester_id: string
  external_system: string
  id: string
  metadata: string
  object: string
  uri: string
  candidate_url: string
  report_url: string
  invitation: {
    id: string
    created_at: string
    expires_at: string
  }
  package: {
    id: string
    name: string
    slug: string
  }
  report: string
}

const NewInvitation = Embeds.NewInvitation.useReact(React, ReactDOM)
const RequestCheckModal: React.FC<RequestCheckModalProps> = ({
  open,
  setOpen,
  sessionTokenPath,
  candidateId,
  bearerToken,
  candidateEmail,
}) => {
  const dispatch = useDispatch()

  const handleOnInvitationSuccess = (response: invitationSuccessResponse) => {
    dispatch(updateCheckr(response.external_candidate_id, response.candidate_id, response.invitation?.id))
  }

  return (
    <Modal title={`Invite candidate to background check`} open={open} setOpen={setOpen}>
      <Content>
        <EmbedContainer>
          <NewInvitation
            sessionTokenPath={sessionTokenPath}
            sessionTokenRequestHeaders={() => {
              return { Authorization: `Bearer ${bearerToken}` }
            }}
            externalCandidateId={candidateId || ''}
            packagePlaceholder="HiPeople Standard Package"
            emailLabel="Candidate email"
            defaultEmail={candidateEmail}
            styles={NewInvitationEmbedStyles}
            onInvitationSuccess={handleOnInvitationSuccess}
          />
        </EmbedContainer>
      </Content>
    </Modal>
  )
}

export default RequestCheckModal
