import { MediaPlayer } from '@common/components'
import { RejectionReason } from 'api/reference'
import Followup from 'App/Candidate/ReferenceFollowup/RDSFollowup'
import { style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import React from 'react'
import { FeedbackStyleType } from 'selectors/halo-score'
import { Relationship } from 'selectors/references'
import { EmptyAnswerSection } from './Chips'
import EmptyAnswer from './EmptyAnswer'
import RDSTextAnswer from './RDSTextAnswer'
import { ReferenceAnswer } from './ReferenceAnswerV2'

type ReferenceDetails = {
  id: string
  relationship: Relationship
  professionalNetwork?: {
    name: string
    url: string
  }
  name: string
  email: string
  companyName: string
  from: number
  to: number
  feedbackStyle?: FeedbackStyleType
  rejectionReason?: RejectionReason
  isReviewed: boolean
  isVerified: boolean
  completed: boolean
}

type FollowUpQuestion = {
  question: string
  description: string
  skipped?: boolean
  input?: string
}

export type TextAnswerType = {
  skipped?: boolean
  input?: string
  mediaURL?: string
  followUpQuestion?: FollowUpQuestion[]
}

type ReferenceId = string

const TextAnswerStyle = style()
  .select(
    '> * + *',
    style().border({
      top: 'solid 1px',
      color: designSystemColors.borderDefault,
    }),
  )
  .element()

interface TextAnswerProps {
  references: ReferenceDetails[]
  answers: Record<ReferenceId, TextAnswerType>
  candidateName: string
}

const TextAnswer: React.FC<TextAnswerProps> = ({ references, answers, candidateName }) => {
  if (references.length === 0) {
    return (
      <EmptyAnswerSection>
        <EmptyAnswer waitingReferences noSpacing />
      </EmptyAnswerSection>
    )
  }
  return (
    <TextAnswerStyle>
      {references.map(reference => {
        const refAnswer = answers[reference.id]

        return (
          <ReferenceAnswer key={reference.id} reference={reference} candidateName={candidateName}>
            {refAnswer?.input || refAnswer?.mediaURL ? (
              <>
                {refAnswer?.input ? (
                  <RDSTextAnswer value={refAnswer.input} />
                ) : (
                  refAnswer?.mediaURL && <MediaPlayer mediaURL={refAnswer.mediaURL} />
                )}
                {(refAnswer.followUpQuestion || []).map(q => (
                  <Followup
                    key={q.description}
                    description={q.description}
                    question={q.question}
                    candidateFirstName={candidateName}
                    skipped={q.skipped}
                    input={q.input}
                  />
                ))}
              </>
            ) : (
              <EmptyAnswer
                waitingResponse={!reference.completed}
                notApplicableForCandidate={reference.relationship === Relationship.Self && reference.completed}
                skipped={refAnswer?.skipped}
                leftBlank={refAnswer?.input === ''}
                noSpacing
              />
            )}
          </ReferenceAnswer>
        )
      })}
    </TextAnswerStyle>
  )
}

export default TextAnswer
