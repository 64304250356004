import { useCandidate } from 'providers/candidates'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { findByCandidateId as findWorkVerificationsByCandidateId, findById } from 'selectors/work-verifications'
import { RootState } from 'store'
import {
  confirmWorkVerification,
  createWorkVerifications,
  fetchWorkVerification,
  updateWorkVerifications,
} from 'store/work-verifications'

export function useWorkVerifications(query?: { candidateId?: string }) {
  const { candidateId } = query || {}
  // Trigger data fetch for candidate to get work verifications
  const { isLoading } = useCandidate({ candidateId: candidateId || '' })
  const workVerificationsSelector = useCallback(
    (state: RootState) => findWorkVerificationsByCandidateId(state, candidateId || ''),
    [candidateId],
  )
  const workVerifications = useSelector(workVerificationsSelector)

  const dispatch = useDispatch()
  const createWorkVerificationsCallback = useCallback(
    async (...args: Parameters<typeof createWorkVerifications>) => dispatch(createWorkVerifications(...args)),
    [dispatch],
  ) as unknown as (...args: Parameters<typeof createWorkVerifications>) => Promise<boolean>
  const updateWorkVerificationsCallback = useCallback(
    async (...args: Parameters<typeof updateWorkVerifications>) => dispatch(updateWorkVerifications(...args)),
    [dispatch],
  ) as unknown as (...args: Parameters<typeof updateWorkVerifications>) => Promise<boolean>

  return {
    isLoading,
    workVerifications,
    createWorkVerifications: createWorkVerificationsCallback,
    updateWorkVerifications: updateWorkVerificationsCallback,
  }
}

export function useWorkVerification(query?: { workVerificationId?: string }) {
  const { workVerificationId } = query || {}
  const workVerificationsSelector = useCallback(
    (state: RootState) => findById(state, workVerificationId || ''),
    [workVerificationId],
  )
  const workVerification = useSelector(workVerificationsSelector)

  const dispatch = useDispatch()
  const confirmWorkVerificationCallback = useCallback(
    async (...args: Parameters<typeof confirmWorkVerification>) => dispatch(confirmWorkVerification(...args)),
    [dispatch],
  ) as unknown as (...args: Parameters<typeof confirmWorkVerification>) => Promise<boolean>

  const isWorkVerificationFetched = !!workVerification
  useEffect(() => {
    if (!isWorkVerificationFetched && workVerificationId) {
      dispatch(fetchWorkVerification(workVerificationId))
    }
  }, [workVerificationId, isWorkVerificationFetched, dispatch])

  const isLoading = workVerificationId && !isWorkVerificationFetched

  return {
    isLoading,
    workVerification,
    confirmWorkVerification: confirmWorkVerificationCallback,
  }
}
