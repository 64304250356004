import { TopNavigation } from 'App/RoleCreation/TopNavigation'
import { Button, ButtonContent } from 'components/Button/ButtonV2'
import Dialog from 'components/Confirm/Dialog'
import { fr, px2rem, style, vh } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import * as tracking from 'core/track'
import { useFeatureFlag } from 'providers/features'
import { useUser } from 'providers/users'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { Product } from 'api/templates'
import { ReferenceCheckDetailsPage } from 'App/RoleCreation/ReferenceCheckDetails'
import { useSearchParam } from 'react-use'
import { openJobRoleUsers, orgs, orgSettings, profiles, roles, users } from 'selectors'
import { getById } from 'selectors/orgs'
import { Relationship } from 'selectors/references'
import { RootState } from 'store'
import { createv2 as createRole, loadUsersByOrgId, RoleRequiredData, update as updateRole } from 'store/new-role'
import { fetch as fetchRole } from 'store/openjobroles'
import { defaultTextTemplate } from '../CandidateOnly/ManageReferences/PublicIntroScreen'
import { AccessEntryPermissionLevel, RoleCreationAccess, RoleCreationAccessValues } from './RoleCreationAccess'
import { RoleCreationDetails, RoleCreationDetailsValues } from './RoleCreationDetails'
import { RoleCreationModules, RoleCreationModulesValues } from './RoleCreationModules'
import { RoleCustomization, RoleCustomizationValues } from './RoleCustomization'
import { AssessmentLocale, ReferenceCheckLocale } from '@common/models/locales'

export enum Step {
  Details = 'Specify details',
  Access = 'Add team members',
  Modules = 'Select modules',
  Customization = 'Customize Page',
  CreateAssessment = 'Create assessment',
  CreateReferenceChecks = 'Create Reference Checks',
  ReferenceCheckDetails = 'References setup',
}

const allSteps: Step[] = [Step.Modules, Step.Details, Step.Access, Step.Customization]

const quickAssessmentSteps: Step[] = [Step.Details, Step.CreateAssessment]

const quickReferenceCheckSteps: Step[] = [Step.ReferenceCheckDetails, Step.Details, Step.CreateReferenceChecks]

const LeftSidebar = style().flex({ justifyContent: 'flex-start' }).element()
const RightSidebar = style().flex({ justifyContent: 'flex-end' }).element()
const Content = style().element()

const Header = style()
  .grid({ columns: [fr(1), `minmax(${px2rem(600)}, auto)`, fr(1)] })
  .spacing({ outerBottom: px2rem(64), columns: px2rem(16) })
  .element()

export const RoleCreationStyle = style()
  .spacing({ inner: px2rem(32) })
  .size({ minHeight: vh(100) })
  .color({ bg: designSystemColors.backgroundNeutralSecondary })
  .element()

type OnSubmitCallbackValues = RoleCreationDetailsValues & {
  accessRights: RoleCreationAccessValues
  isQuickMode: boolean
  quickModeProduct?: Product
} & RoleCreationModulesValues &
  RoleCustomizationValues

type RoleUser = { userId: string; name: string }

type RoleCreationValues = {
  roleDetails: RoleCreationDetailsValues
  roleAccess: RoleCreationAccessValues
  roleModules: RoleCreationModulesValues
  roleCustomization: RoleCustomizationValues
}

interface RoleCreationProps {
  onSubmit: (values: OnSubmitCallbackValues) => any
  currentUserId: string
  allUsers: RoleUser[]
  minRequiredReferenceCount: number
  exit: () => any
  onboardingEnabled: boolean
  candidateExperienceEnabled: boolean
  showOnboardingTalkToSales: boolean
  isSelfAssessmentSelectable: boolean
  initialValues?: RoleCreationValues
  isEditModeEnabled?: boolean
  orgLogoUrl: string
}

const RoleCreation: React.FC<RoleCreationProps> = props => {
  const { state } = useLocation<{ from: string; isQuickMode: boolean }>()
  const from = state?.from || ''
  const isQuickMode = state?.isQuickMode || false

  let quickModeProduct: Product | undefined = undefined
  if (from === 'references-page' && isQuickMode) quickModeProduct = 'reference-check'
  if (from === 'assessment-page' && isQuickMode) quickModeProduct = 'self-assessment'

  const steps = useMemo(() => {
    switch (quickModeProduct) {
      case 'reference-check':
        return quickReferenceCheckSteps
      case 'self-assessment':
        return quickAssessmentSteps
      default:
        return allSteps
    }
  }, [quickModeProduct])

  const [selectedStepIndex, setSelectedStepIndex] = useState(0)

  const nextStep = useCallback(() => setSelectedStepIndex(stepIndex => stepIndex + 1), [])
  const prevStep = useCallback(() => setSelectedStepIndex(stepIndex => stepIndex - 1), [])

  const [roleCreationDetails, setRoleCreationDetails] = useState<RoleCreationDetailsValues>(
    props.initialValues?.roleDetails ?? {
      internalRoleTitle: '',
      externalRoleTitle: '',
      description: '',
      assessmentLocale: 'en_US',
    },
  )

  const [roleCreationAccess, setRoleCreationAccess] = useState<RoleCreationAccessValues>(
    () =>
      props.initialValues?.roleAccess ??
      props.allUsers
        .filter(({ userId }) => userId === props.currentUserId)
        .map(({ userId, name }) => ({
          userId,
          name,
          permissionLevel: 'admin',
        })),
  )

  const [roleCreationModules, setRoleCreationModules] = useState<RoleCreationModulesValues>(() => {
    if (props.initialValues?.roleModules) {
      return props.initialValues?.roleModules
    }

    const requiredReferences = Array(props.minRequiredReferenceCount)
      .fill(null)
      .map((_, idx) =>
        idx < Math.floor(props.minRequiredReferenceCount / 2) ? Relationship.Manager : Relationship.Peer,
      )
      .map(type => ({ type: type }))

    return {
      isOnboardingAdded: props.onboardingEnabled,
      isReferenceFeedbackAdded:
        quickModeProduct === 'reference-check' || from === 'talent-pool' || from === 'sales-pool',
      isSelfAssessmentAdded: quickModeProduct === 'self-assessment',
      isTalentExperienceAdded: props.candidateExperienceEnabled,
      showOnboardingTalkToSales: props.showOnboardingTalkToSales,
      referenceFeedbackDetails: {
        includeSelfReference: false,
        requiredReferences: requiredReferences,
        includeJobTitleVerification: false,
        timeForCandidatesToProvideReferences: 5,
        timeForReferencesToSubmit: 5,
        referenceCheckLocales: ['en_US'],
      },
    }
  })

  const [isExitDialogShown, setIsExitDialogShown] = useState(false)

  const routeParams = useSearchParam('step') as Lowercase<keyof typeof Step> | undefined | null

  const [customizationValues, setCustomizationValues] = useState<RoleCustomizationValues>(() => {
    return (
      props.initialValues?.roleCustomization ?? {
        customCandidatePortalText: defaultTextTemplate,
      }
    )
  })

  const submit = () => {
    props.onSubmit({
      ...roleCreationDetails,
      accessRights: roleCreationAccess,
      ...roleCreationModules,
      ...customizationValues,
      isQuickMode: isQuickMode,
      quickModeProduct: quickModeProduct,
    })

    tracking.roles.create({
      name: roleCreationDetails.internalRoleTitle,
      references: roleCreationModules.referenceFeedbackDetails.requiredReferences.length,
    })
  }

  // select step if route param step is defined. ie.: ?step=details
  useEffect(() => {
    if (routeParams) {
      // get key from enum and find index of key in steps array
      const stepIndex = steps.findIndex(
        step =>
          Object.keys(Step)
            .find(key => Step[key] === step)
            ?.toLowerCase() === routeParams,
      )
      if (stepIndex !== -1) {
        setSelectedStepIndex(stepIndex)
      }
    }
  }, [routeParams, steps])

  return (
    <>
      <RoleCreationStyle>
        <Header>
          <LeftSidebar>
            <Button
              buttonType="tertiary"
              onClick={() => {
                setIsExitDialogShown(true)
                tracking.roles.roleCreationExit()
              }}
            >
              <ButtonContent icon={{ name: 'sign-out', ariaLabel: 'Exit' }}>{'Exit'}</ButtonContent>
            </Button>
          </LeftSidebar>
          <Content>
            <TopNavigation steps={steps} selectedStepIndex={selectedStepIndex} />
          </Content>
          <RightSidebar>
            <Button
              buttonType="tertiary"
              as={'a'}
              target="_blank"
              href={'https://intercom.help/hipeople/en/articles/7123673-create-your-first-reference-feedback-request'}
              onClick={() => tracking.roles.roleCreationDocumentation()}
            >
              <ButtonContent icon={{ name: 'book', ariaLabel: 'Documentation' }}>{'Documentation'}</ButtonContent>
            </Button>
          </RightSidebar>
        </Header>
        {steps[selectedStepIndex] === Step.ReferenceCheckDetails && (
          <ReferenceCheckDetailsPage
            initialValues={roleCreationModules.referenceFeedbackDetails}
            onSubmit={referenceFeedbackDetails => {
              setRoleCreationModules(values => ({
                ...values,
                referenceFeedbackDetails,
                isReferenceFeedbackAdded: true,
              }))
              if (!roleCreationModules.isReferenceFeedbackAdded) {
                tracking.roles.roleCreationAddReferenceCheck()
              }
              nextStep()
            }}
            minRequiredReferenceCount={props.minRequiredReferenceCount}
          />
        )}
        {steps[selectedStepIndex] === Step.Modules && (
          <RoleCreationModules
            values={roleCreationModules}
            minRequiredReferenceCount={props.minRequiredReferenceCount}
            onChange={setRoleCreationModules}
            next={nextStep}
            back={prevStep}
            isSelfAssessmentSelectable={props.isSelfAssessmentSelectable}
            isEditModeEnabled={props.isEditModeEnabled}
          />
        )}
        {steps[selectedStepIndex] === Step.Details && (
          <RoleCreationDetails
            values={roleCreationDetails}
            modules={roleCreationModules}
            onChange={setRoleCreationDetails}
            next={isQuickMode ? submit : nextStep}
            back={quickModeProduct === 'self-assessment' ? undefined : prevStep}
            isEditModeEnabled={props.isEditModeEnabled}
          />
        )}
        {steps[selectedStepIndex] === Step.Access && (
          <RoleCreationAccess
            currentUserId={props.currentUserId}
            allUsers={props.allUsers}
            values={roleCreationAccess}
            onChange={setRoleCreationAccess}
            next={nextStep}
            back={prevStep}
          />
        )}
        {steps[selectedStepIndex] === Step.Customization && (
          <RoleCustomization
            values={customizationValues}
            onChange={setCustomizationValues}
            submit={submit}
            back={prevStep}
            isEditModeEnabled={props.isEditModeEnabled}
            roleName={props.initialValues?.roleDetails.externalRoleTitle || ''}
            orgLogoUrl={props.orgLogoUrl}
          />
        )}
      </RoleCreationStyle>
      <Dialog
        title={props.isEditModeEnabled ? 'Exit editing the job?' : 'Exit Job creation?'}
        message={'By leaving now you will lose the progress. Continue?'}
        confirmLabel={props.isEditModeEnabled ? 'Exit and lose progress' : 'Exit and delete job'}
        cancelLabel={props.isEditModeEnabled ? 'Continue editing the job' : 'Continue Job creation'}
        danger={true}
        isOpen={isExitDialogShown}
        setOpen={setIsExitDialogShown}
        onConfirm={result => {
          if (!result) {
            setIsExitDialogShown(result)
            return
          }
          props.exit()
        }}
      />
    </>
  )
}

interface RoleCreationPageProps {}

const RoleCreationPage: React.FC<RoleCreationPageProps> = () => {
  const user = useUser()
  const currentUserId = user.id
  const orgId = user.fields.organization_id

  const org = useSelector((state: RootState) => orgs.getById(state, orgId || ''))

  const minRequiredReferenceCount = useSelector(
    (state: RootState) => orgSettings.getByOrgId(state, orgId)?.fields.min_reference_count ?? 0,
  )

  const isTalentExperienceEnabled = useSelector(
    (state: RootState) =>
      orgSettings.getByOrgId(state, orgId)?.fields.candidate_experience_mode === 'post-reference-check',
  )

  const orgPlan = useSelector((state: RootState) => getById(state, orgId)?.fields.plan)
  const { isEnabled: isOnboardingEnabled } = useFeatureFlag('post-hire-onboarding')
  const showOnboardingTalkToSales = !isOnboardingEnabled && orgPlan === 'self-trial'

  const selectableUserIds = useSelector((state: RootState) => state.newRole.selectableUserIds)

  const allUsers = useSelector((state: RootState) =>
    selectableUserIds
      .map(id => ({
        user: users.getById(state, id),
        profile: profiles.getByUserId(state, id),
      }))
      .map(({ user, profile }) => ({
        userId: user.id,
        name: profile?.fields.full_name || '',
      })),
  )

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(loadUsersByOrgId(orgId))
  }, [orgId])

  const history = useHistory()
  const exit = () => {
    // if the history stack is empty return to dashboard
    if (history.length === 0) {
      history.replace('/')
      return
    }
    // if history stack is not empty return the previous page
    history.goBack()
  }

  const { isEnabled: isSelfAssessmentEnabled } = useFeatureFlag('self-assessment')

  const { roleid } = useParams<{ roleid?: string }>()
  const isEditModeEnabled = !!roleid

  const existingRole = useSelector((state: RootState) => roles.findById(state, roleid || ''))
  const existingRoleAccessValues = useSelector((state: RootState) => {
    if (!roleid) {
      return []
    }
    return openJobRoleUsers.findByRoleId(state, roleid).map(user => ({
      userId: user.fields.user_id,
      name: profiles.getByUserId(state, user.fields.user_id)?.fields.full_name || '',
      permissionLevel: (user.fields.can_control ? 'admin' : 'viewer') as AccessEntryPermissionLevel,
    }))
  })
  const existingRequiredReferences = useSelector((state: RootState) =>
    roles.findRequiredReferencesByRoleId(state, roleid || '').map(({ id, relationship, description }) => ({
      id,
      type: relationship,
      alias: description,
    })),
  )
  const initialValues: RoleCreationValues | undefined = isEditModeEnabled
    ? {
        roleDetails: {
          externalRoleTitle: existingRole.fields.public_name,
          internalRoleTitle: existingRole.fields.name,
          description: existingRole.fields.description,
          assessmentLocale: existingRole.fields.assessment_locale as AssessmentLocale,
        },
        roleCustomization: {
          customCandidatePortalImageUrl: existingRole.fields.branding_image_url,
          customCandidatePortalText: existingRole.fields.branding_text,
        },
        roleAccess: existingRoleAccessValues,
        roleModules: {
          showOnboardingTalkToSales: showOnboardingTalkToSales,
          isOnboardingAdded: !!isOnboardingEnabled,
          isReferenceFeedbackAdded: existingRole.fields.ask_reference_check,
          isSelfAssessmentAdded: existingRole.fields.ask_self_assessment,
          isTalentExperienceAdded: !!isTalentExperienceEnabled,
          referenceFeedbackDetails: {
            includeSelfReference: existingRole.fields.ask_self_reference,
            includeJobTitleVerification: existingRole.fields.candidate_title_verification_enabled,
            requiredReferences: existingRequiredReferences,
            timeForCandidatesToProvideReferences: existingRole.fields.candidate_expiry_days,
            timeForReferencesToSubmit: existingRole.fields.time_window_days,
            referenceCheckLocales: existingRole.fields.reference_check_locales as ReferenceCheckLocale[],
          },
        },
      }
    : undefined

  useEffect(() => {
    roleid && dispatch(fetchRole(roleid))
  }, [roleid])

  const onSubmit: (values: OnSubmitCallbackValues) => any = async values => {
    const requiredReferences =
      values.referenceFeedbackDetails?.requiredReferences.map(({ id, alias, type }) => ({
        id,
        relationship: type as Relationship,
        description: alias || '',
      })) || []

    const users = values.accessRights.map(({ userId, permissionLevel }) => ({
      id: userId,
      can_control: permissionLevel === 'admin',
    }))

    const data: RoleRequiredData = {
      name: values.internalRoleTitle,
      publicName: values.externalRoleTitle,
      description: values.description,
      assessmentLocale: values.assessmentLocale,
      referenceCheckLocales: values.referenceFeedbackDetails?.referenceCheckLocales || ['en_US'],
      users: users,
      candidateExpiryDays: values.referenceFeedbackDetails?.timeForCandidatesToProvideReferences || 5,
      timeWindowDays: values.referenceFeedbackDetails?.timeForReferencesToSubmit || 5,
      questionnaire: null /* no questionnaire payload */,
      requiredReferences: values.isReferenceFeedbackAdded ? requiredReferences : [],
      includeSelfReference: !!values.referenceFeedbackDetails?.includeSelfReference,
      includeSelfAssessment: values.isSelfAssessmentAdded,
      includeReferenceCheck: values.isReferenceFeedbackAdded,
      candidateTitleVerificationEnabled: values.referenceFeedbackDetails.includeJobTitleVerification,
      brandingText: values.customCandidatePortalText,
      brandingImageUrl: values.customCandidatePortalImageUrl,
    }

    let roleId: string | null

    if (isEditModeEnabled && roleid) {
      roleId = (await dispatch(updateRole(roleid, data))) as any
    } else {
      roleId = (await dispatch(createRole(data))) as any
    }

    if (roleId) {
      if (values.quickModeProduct) {
        history.replace(`/roles/${roleId}/templates?product=${values.quickModeProduct}`)
      } else {
        history.replace(`/roles/${roleId}`)
      }
    }

    return roleId
  }

  // if users are not loaded yet, don't render anything
  if (allUsers.length === 0) {
    return null
  }

  // if the role hasn't been loaded in the edit mode, don't render anything
  if (isEditModeEnabled && existingRole.fields.created_at === 0) {
    return null
  }

  return (
    <RoleCreation
      currentUserId={currentUserId}
      minRequiredReferenceCount={Math.max(minRequiredReferenceCount, 1)}
      allUsers={allUsers}
      onSubmit={onSubmit}
      exit={exit}
      onboardingEnabled={isOnboardingEnabled || false}
      candidateExperienceEnabled={isTalentExperienceEnabled || false}
      showOnboardingTalkToSales={showOnboardingTalkToSales}
      isSelfAssessmentSelectable={isSelfAssessmentEnabled || false}
      initialValues={initialValues}
      isEditModeEnabled={isEditModeEnabled}
      orgLogoUrl={org?.fields.logo_image_url}
    />
  )
}

export default RoleCreationPage
