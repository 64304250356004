import { map } from './css'
import Rows from './rows'

export interface IOptions {
  columns?: string | string[]
  rows?: string | string[]
  align?: string
  justify?: string
  justifyContent?: string
  span?: number
}

export default function grid(rows: Rows, options: IOptions) {
  rows.set('display', 'grid')

  if (options.span) {
    rows.set('gridColumn', `span ${options.span}`)
  }

  map(rows, options, {
    columns: 'gridTemplateColumns',
    rows: 'gridTemplateRows',
    align: 'alignItems',
    justify: 'justifyItems',
    justifyContent: 'justifyContent',
  })
}
