import { px2rem, size, space, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import * as tracking from 'core/track'
import { useFeatureFlag } from 'providers/features'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation, useParams } from 'react-router-dom'
import * as selectors from 'selectors'
import { RootState } from 'store'
import CheckrLogo from './BackgroundCheck/CheckrLogo.svg?react'
import TabSeparator from './TabSeparator.svg?react'

interface TabSelectorProps {
  content?: boolean
}

export const Nav = style().size({ width: '100%' }).element('nav')

export const NavItem = style().block().element('li')

export const NavList = style()
  .size({ width: '100%' })
  .flex({ alignItems: 'right', justifyContent: 'right' })
  .nospacing()
  .select(`> ${NavItem} + ${NavItem}`, style().spacing({ outerLeft: px2rem(8) }))
  .element('ul')

export const NavLinkText = text.bodyInteractive().whitespace('nowrap').element('span')

export const NavLink = style()
  .block()
  .spacing({ inner: [px2rem(8), px2rem(12)] })
  .size({ height: size.fill })
  .text({ nodecoration: true })
  .round(px2rem(4))
  .sans()
  .cond(
    ({ isActive }: { isActive: boolean }) => isActive,
    style().bg({ color: designSystemColors.backgroundNeutralTertiary }),
  )
  .cond(
    ({ isActive }: { isActive: boolean }) => !isActive,
    style()
      .select(`> ${NavLinkText}`, style().color({ fg: designSystemColors.typographySecondary }))
      .select(':hover', style().color({ bg: designSystemColors.backgroundNeutralSecondary })),
  )
  .elementFromComponent(Link, {
    filteredProps: ['isActive'],
  })

const CheckrLink = style(NavLink)
  .flex({ alignItems: 'center' })
  .spacing({ inner: px2rem(3) })
  .select('> :first-child', style().size({ width: px2rem(60), height: px2rem(28) }))
  .elementFromComponent(Link, {
    filteredProps: ['isActive'],
  })

const SeparatorStyle = style()
  .flex({ alignItems: 'center' })
  .spacing({ outer: [space.auto, px2rem(16)] })
  .element()

const TabSelector: React.FC<TabSelectorProps> = () => {
  const { roleid, candidateid } = useParams<{
    roleid?: string
    candidateid?: string
  }>()
  const { pathname: pathName } = useLocation()

  const prefix = pathName.startsWith('/shared') ? '/shared' : `/roles/${roleid}`

  const summaryPath = `${prefix}/candidates/${candidateid}`
  const summaryPaths = [
    summaryPath,
    `${summaryPath}/overview`,
    `${summaryPath}/reference-results`,
    `${summaryPath}/responses`,
    `${summaryPath}/onboarding`,
  ]
  const selfAsssementPath = `${prefix}/candidates/${candidateid}/assessment`
  const referenceCheckPath = `${prefix}/candidates/${candidateid}/reference-check`
  const checkrPath = `${prefix}/candidates/${candidateid}/background-check`
  const checkrPaths = [checkrPath]

  const { isEnabled: selfAssessmentEnabled } = useFeatureFlag('self-assessment')
  const { isEnabled: isCheckrIntegrationEnabled } = useFeatureFlag('background-checks')

  const role = useSelector((state: RootState) => selectors.roles.findByCandidateId(state, candidateid || ''))

  const existingBackgroundCheckIntegration = useSelector((state: RootState) => {
    const currentOrgId = selectors.orgs.currentId(state)
    return selectors.backgroundcheckintegrations.getByOrgId(state, currentOrgId)
  })

  const candidateHasAnInvitation = useSelector((state: RootState) => {
    return selectors.candidates.findById(state, candidateid || '')?.fields?.checkr_invitation_id
  })

  return (
    <Nav>
      <NavList>
        {selfAssessmentEnabled && role?.fields.ask_self_assessment && role.fields.self_assessment_preset_id && (
          <NavItem>
            <NavLink
              isActive={pathName.startsWith(selfAsssementPath)}
              to={selfAsssementPath}
              onClick={() => tracking.candidates.viewSelfAssessment()}
            >
              <NavLinkText>{'Assessment'}</NavLinkText>
            </NavLink>
          </NavItem>
        )}
        {role?.fields.ask_reference_check &&
          (role.fields.reference_check_preset_id || role.fields.reference_form_id) && (
            <NavItem>
              <NavLink
                isActive={summaryPaths.includes(pathName) || pathName.startsWith(referenceCheckPath)}
                to={referenceCheckPath}
                onClick={() => tracking.candidates.viewSummary()}
              >
                <NavLinkText>{selfAssessmentEnabled ? 'Reference Check' : 'Summary'}</NavLinkText>
              </NavLink>
            </NavItem>
          )}
        {existingBackgroundCheckIntegration?.fields?.is_ready &&
          isCheckrIntegrationEnabled &&
          candidateHasAnInvitation && (
            <>
              <SeparatorStyle>
                <TabSeparator />
              </SeparatorStyle>
              <NavItem>
                <CheckrLink
                  isActive={checkrPaths.includes(pathName)}
                  to={checkrPath}
                  onClick={() => tracking.candidates.viewCheckrTab()}
                >
                  <CheckrLogo title="Checkr background check" />
                </CheckrLink>
              </NavItem>
            </>
          )}
      </NavList>
    </Nav>
  )
}

export default TabSelector
