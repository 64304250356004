import React from 'react'
import classes from './ScoreGroup.module.scss'

interface ScoreGroupWrapperProps {
  children: React.ReactNode
}

export const ScoreGroupWrapper: React.FC<ScoreGroupWrapperProps> = ({ children }) => {
  return <div className={classes.scoreGroupWrapper}>{children}</div>
}
