import { Block, BlockProps } from 'App/Candidate/Summary/Overview/Block'
import { ChipResult, NumberResult } from 'App/Candidate/Summary/Overview/Result'
import React from 'react'
import { useSelector } from 'react-redux'
import { plural, questions, responseOptions } from 'selectors'
import { ReferenceCheckOverviewBlocks } from 'selectors/candidates'
import { RootState } from 'store'
import classes from './BlockList.module.scss'

interface BlockListProps {
  blocks: BlockProps[]
}

const BlockList: React.FC<BlockListProps> = props => {
  return (
    <div className={classes.container}>
      {props.blocks.map((block, index) => (
        <Block {...block} key={index} />
      ))}
    </div>
  )
}

interface BlockListByResultsProps {
  overview: ReferenceCheckOverviewBlocks
  isStillInProgress: boolean
}

export const BlockListByResults: React.FC<BlockListByResultsProps> = props => {
  const performanceResponse = useSelector((state: RootState) => {
    const question = questions.findBySlug(state, 'overall-performance')
    const options = responseOptions.findByQuestionId(state, question?.id || '')
    const score = Math.floor(props.overview.performance.score)
    return options?.find(option => option.fields.value === score)?.fields.copy.caption || ''
  })

  const unselectedText = 'Metric not selected for reference check survey'
  const performanceText = `The candidate is rated '${performanceResponse}' based on an average score of ${
    Math.round(props.overview.performance.score * 100) / 100
  }`

  return (
    <BlockList
      blocks={[
        {
          title: 'Overall Performance',
          emoji: '📈',
          status: props.overview.performance.status,
          description:
            {
              positive: performanceText,
              incomplete: 'This metric cannot be displayed because not all references have submitted a response yet',
              negative: performanceText,
            }[props.overview.performance.status] || unselectedText,
          modalTitle: 'Performance',
          modalInfo:
            'We asked the reference giver to rate the candidate in the applicable areas from "room for improvement" to "outstanding". ',
          result: (
            <NumberResult
              notApplicable={props.isStillInProgress || props.overview.performance.status === 'unselected'}
              result={props.overview.performance.score.toFixed(2)}
              total={props.overview.performance.of}
            />
          ),
        },
        {
          title: 'Accepted Requests',
          emoji: '✉️',
          status: props.overview.acceptedRequests.status,
          description:
            {
              positive: 'All references provided the candidate with a reference',
              incomplete: 'So far, all references provided the candidate with a reference',
              negative: 'One or more references declined to provided the candidate with a reference',
            }[props.overview.acceptedRequests.status] || unselectedText,
          modalTitle: 'Accepted requests',
          modalInfo: 'We have asked the reference giver if they would be willing to refer the candidate.',
          result: (
            <NumberResult
              notApplicable={props.overview.acceptedRequests.status === 'unselected'}
              result={props.overview.acceptedRequests.positive.toFixed(0)}
              total={props.overview.totalRefs}
            />
          ),
        },
        {
          title: 'Hiring Recommendation',
          emoji: '🔎',
          status: props.overview.hiringRecommendation.status,
          description:
            {
              positive: 'All references recommend hiring the candidate',
              incomplete: 'So far, all references recommend hiring the candidate',
              negative: "One or more references wouldn't recommend or have doubts about hiring the candidate",
            }[props.overview.hiringRecommendation.status] || unselectedText,
          modalTitle: 'Hiring recommendation',
          modalInfo: 'We have asked the reference giver if they would recommend hiring the candidate.',
          result: (
            <NumberResult
              notApplicable={props.overview.hiringRecommendation.status === 'unselected'}
              result={props.overview.hiringRecommendation.positive.toFixed(0)}
              total={props.overview.totalRefs}
            />
          ),
        },
        {
          title: 'Termination',
          emoji: '🚧',
          status: props.overview.termination.status,
          description:
            {
              positive: "The candidate's contract has not been terminated in the past",
              incomplete: 'So far, all references received have not mentioned contract termination',
              negative: "The candidate's contract has been terminated at least once in the past",
            }[props.overview.termination.status] || unselectedText,
          modalTitle: 'Termination',
          modalInfo:
            'We have ask the reference “Why did the candidate leave the organization you have worked for together?”',
          result: (
            <ChipResult
              chips={
                props.overview.termination.status === 'unselected'
                  ? ['N/a']
                  : props.overview.termination.terminated
                  ? ['Contract terminated']
                  : ['0 termination warnings']
              }
            />
          ),
        },
        {
          title: 'Legitimacy',
          emoji: '👮‍♀️',
          status: props.overview.legitimacy.status,
          description:
            {
              positive: 'All references seem legitimate',
              incomplete: 'So far, all references seem legitimate',
              negative: 'One or more references may not be legitimate',
            }[props.overview.legitimacy.status] || unselectedText,
          modalTitle: 'Legitimacy',
          modalInfo:
            'We are basing our potential fraud detection on the user agent and the IP address of the reference giver.',
          result: (
            <ChipResult
              chips={
                props.overview.legitimacy.status === 'unselected'
                  ? ['N/a']
                  : [
                      `${props.overview.legitimacy.fraudWarnings} fraud ${plural(
                        'warning',
                        props.overview.legitimacy.fraudWarnings,
                      )}`,
                    ]
              }
            />
          ),
        },
        {
          title: 'Reasons for Leaving',
          emoji: '👋',
          status: props.overview.reasonsForLeaving.status,
          description:
            {
              positive: 'All reasons for leaving are unproblematic',
              incomplete: 'So far, all reasons for leaving are unproblematic',
              negative: 'One or more reasons for leaving could be problematic',
            }[props.overview.reasonsForLeaving.status] || unselectedText,
          modalTitle: 'Reasons for leaving',
          modalInfo:
            'We have ask the reference “Why did the candidate leave the organization you have worked for together?”',
          result: (
            <ChipResult
              chips={
                props.overview.reasonsForLeaving.status === 'unselected'
                  ? ['N/a']
                  : props.overview.reasonsForLeaving.reasons.length
                  ? props.overview.reasonsForLeaving.reasons
                  : ['0 leaving warnings']
              }
            />
          ),
        },
        {
          title: 'Candidate title verification',
          emoji: '🔒',
          status: props.overview.titleVerification.status,
          description:
            {
              positive: "All references said the candidate's title was the same when they worked together",
              incomplete: 'Not enough data',
              negative: "One or more references said the candidate's title was different when they worked together",
            }[props.overview.titleVerification.status] || unselectedText,
          modalTitle: '',
          modalInfo: '',
          result: (
            <NumberResult
              notApplicable={props.overview.titleVerification.status === 'unselected'}
              result={props.overview.titleVerification.matches.toFixed(0)}
              total={props.overview.totalRefs}
            />
          ),
        },
      ]}
    />
  )
}
