import variables from '../styles/colorVariables.module.scss'

/**
 * Format a number to a string with a fixed number of decimals
 * @param seconds i.e: 1234
 * @returns i.e: 20m 34s
 */
export function formatTimeSeconds(seconds: number): string {
  if (!seconds) {
    return ''
  }

  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const secs = Math.floor(seconds % 60)

  const hoursStr = hours ? `${hours}h ` : ''
  const minutesStr = minutes ? `${minutes}m ` : ''
  const secsStr = secs ? `${secs}s` : ''

  return `${hoursStr}${minutesStr}${secsStr}`
}

export function initialsFromName(name: string): string {
  // regexp to generally capture the first 2 letter in someone's name.
  // also supported is someone with a one word name, or a 3+ word name.
  // in those cases we get 1 or 2 letters back, respectively (the svg can
  // handle those cases too)
  const regexp = /^(\w).*?(?:\s(\w).*?)?$/
  const results = name.match(regexp)
  let result = ''

  if (!results || results.length < 2) {
    result = name.slice(0, 2).toUpperCase()
  } else {
    results.shift()
    result = results.join('').toUpperCase()
    if (result.length < 2) result = name.slice(0, 2).toUpperCase()
  }

  return result
}

const basePalette = [
  variables.informationBluePrimary,
  variables.informationYellowPrimary,
  variables.informationPurplePrimary,
  variables.informationOrangePrimary,
  variables.informationRedPrimary,
]

/** Returns a color based on the first letter of the string
 * @param letter: string ex. 'T'
 * @returns string ex. '#FFC107'
 * */
export function letterToColor(letter: string) {
  return basePalette[letter.charCodeAt(0) % basePalette.length]
}

/** Returns two colors based on the id
 * @param id: string ex. '4f8t5gc7oa3df'
 * @returns {firstColor: string, secondColor: string} ex. {firstColor: '#FFC107', secondColor: '#FF5722'}
 * */
export function idToColors(id: string): {
  firstColor: string
  secondColor: string
} {
  let firstCharCode = 0
  let secondCharCode = 0
  // make sure chars are different, so we don't get the same color twice
  for (let index = 0; index < id.length; index++) {
    const char = id[index]
    if (index === 0) {
      firstCharCode = char.charCodeAt(0)
      continue
    }
    if (char.charCodeAt(0) % basePalette.length !== firstCharCode % basePalette.length) {
      secondCharCode = char.charCodeAt(0)
      break
    }
  }
  const firstColor = basePalette[firstCharCode % basePalette.length]
  const secondColor = basePalette[secondCharCode % basePalette.length]
  return { firstColor, secondColor }
}

export function millisecondsToHuman(milliseconds: number): string {
  // Calculate hours, minutes, and seconds from the input milliseconds
  const hours = Math.floor(milliseconds / 3600000)
  const minutes = Math.floor((milliseconds % 3600000) / 60000)
  const seconds = Math.floor((milliseconds % 60000) / 1000)

  // Format the minutes and seconds as two-digits numbers
  const minutesStr = minutes.toString().padStart(2, '0')
  const secondsStr = seconds.toString().padStart(2, '0')

  // Return the formatted time
  if (hours > 0) {
    // Format the hours as two-digits number if needed
    const hoursStr = hours.toString().padStart(2, '0')
    return `${hoursStr}:${minutesStr}:${secondsStr}`
  } else {
    return `${minutesStr}:${secondsStr}`
  }
}
