import * as api from 'api'
import { RejectionReason, rejectionReasons } from 'api/reference'
import DashboardLayout, { Content, Header } from 'components/DashboardLayout'
import DropdownField, { Option } from 'components/DropdownField'
import { Button } from 'components/Popup'
import RelationshipLabel from 'components/RelationshipLabel'
import TextField from 'components/TextField'
import ToggleField from 'components/ToggleField'
import VerificationBadge from 'components/VerificationBadge'
import { breakpoints, colors, fr, px, rem, size, space, style } from 'core'
import { text } from 'core/design-system/text'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDebounce, useLocalStorage } from 'react-use'
import * as selectors from 'selectors'
import { RootState } from 'store'
import { load } from 'store/reference-management'
import { updateVerification } from 'store/references'
import { logout } from 'store/sessions'
import { Title, Total } from './ManageCandidates'
import NameAndNetwork from './NameAndNetwork'
import { useInView } from 'react-intersection-observer'

const SearchRow = style()
  .grid({ columns: [size.auto, rem(12), rem(8)], align: 'center' })
  .screen(breakpoints.mobile, style().grid({ columns: [fr(1)] }))
  .spacing({
    outerTop: space.l,
    inner: space.none,
    columns: space.s,
    rows: space.s,
  })
  .element()

const ContentContainer = text
  .bodyInteractive()
  .spacing({
    innerBottom: rem(10),
  })
  .element()

const SCROLLABLE_TARGET = 'manage-references-scroll-target'

const Component = () => {
  const dispatch = useDispatch()

  const totalReferences = useSelector((state: RootState) => state.referenceManagement.totalReferences)
  const hasMore = useSelector((state: RootState) => state.referenceManagement.hasMore)
  const references = useSelector((state: RootState) =>
    state.referenceManagement.references.map(id => selectors.references.findUnsanitizedById(state, id)),
  )
  const loading = useSelector((state: RootState) => state.referenceManagement.loading)

  const [query, setQuery] = useState('')
  const [onlyUnreviewed, setOnlyUnreviewed] = useLocalStorage<boolean>('custom:manage-references-unreviewed', false)

  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: '200px',
  })

  useDebounce(() => dispatch(load(query, onlyUnreviewed)), 400, [query, onlyUnreviewed])

  useEffect(() => {
    if (inView && hasMore) {
      onPageLoad()
    }
  }, [inView, hasMore])

  return (
    <DashboardLayout>
      <Header breadcrumbs={[{ label: 'Settings' }, { url: `/settings/references`, label: 'Manage references' }]}>
        <Button icon="sign-out" onClick={() => dispatch(logout())}>
          Sign out
        </Button>
      </Header>
      <Content id={SCROLLABLE_TARGET}>
        <Title>Manage References</Title>
        <SearchRow>
          <TextField
            value={query}
            placeholder="Search by ID, email, or name"
            onChange={e => setQuery(e.target.value)}
          />
          <ToggleField label="Only unreviewed?" on={onlyUnreviewed || false} onChange={setOnlyUnreviewed} />
          {!loading ? <Total> {totalReferences} references</Total> : null}
        </SearchRow>
        <ContentContainer>
          {references.map(reference => (
            <Reference key={reference.id} reference={reference} />
          ))}
          {hasMore && <div ref={ref} />}
        </ContentContainer>
      </Content>
    </DashboardLayout>
  )

  function onPageLoad() {
    dispatch(load(query, onlyUnreviewed, references.length))
  }
}

const ReferenceRow = style()
  .spacing({ inner: space.s, rows: space.s })
  .color({ fg: colors.midGray, bg: colors.white })
  .select('a', style().color({ fg: colors.black }).text({ nodecoration: true }))

const ReferenceHeadingRow = ReferenceRow.clone()
  .grid({
    columns: [rem(12), rem(20), size.auto, rem(8)],
    align: 'center',
  })
  .screen(breakpoints.mobile, style().grid({ columns: [fr(1)] }))
  .element()

const ReferenceSubRow = ReferenceRow.clone()
  .border({ top: '1px solid', color: colors.lightMoonGray })
  .grid({ columns: [rem(12), rem(20), size.auto], align: 'center' })
  .screen(breakpoints.mobile, style().grid({ columns: [fr(1)] }))
  .element()

const ReferenceContainer = style()
  .spacing({ outerTop: space.m })
  .color({ bg: colors.white })
  .round(px(8))
  .nooverflow()
  .sans()
  .element()

interface ReferenceProps {
  reference: api.request.Entity<api.references.Fields>
}

const Reference = ({ reference }: ReferenceProps) => {
  const dispatch = useDispatch()

  const referenceProfessionalNetwork = useSelector((state: RootState) =>
    selectors.references.getProfessionalNetworkForDisplay(state, reference),
  )
  const candidate = useSelector((state: RootState) =>
    selectors.candidates.findById(state, reference.fields.candidate_id),
  )
  const candidateProfile = useSelector((state: RootState) =>
    selectors.candidateProfiles.findByCandidateId(state, reference.fields.candidate_id),
  )
  const candidateProfessionalNetwork = useSelector((state: RootState) =>
    selectors.candidates.getProfessionalNetworkById(state, reference.fields.candidate_id),
  )
  const role = useSelector((state: RootState) =>
    selectors.roles.findByCandidateId(state, reference.fields.candidate_id),
  )
  const organization = useSelector((state: RootState) =>
    selectors.orgs.findByCandidateId(state, reference.fields.candidate_id),
  )
  const relationship = selectors.references.relationshipOf(reference)

  return (
    <ReferenceContainer>
      <ReferenceHeadingRow>
        <VerificationBadgeStyle>
          <VerificationBadge verified={reference.fields.is_verified} reviewed={reference.fields.is_reviewed} />{' '}
          {organization?.fields.name || 'Not found'}
        </VerificationBadgeStyle>
        <NameAndNetwork
          name={reference?.fields.full_name || ''}
          link={`/roles/${role?.fields.id}/candidates/${candidate?.fields.id}/references/${reference?.fields.id}`}
          network={referenceProfessionalNetwork}
        />
        <NameAndNetwork
          name={candidateProfile?.fields.full_name || ''}
          link={`/roles/${role?.fields.id}/candidates/${candidate?.fields.id}`}
          network={candidateProfessionalNetwork}
        />
        <RelationshipLabelStyle>
          <RelationshipLabel type={relationship} />
        </RelationshipLabelStyle>
      </ReferenceHeadingRow>

      <ReferenceSubRow>
        <Item>{reference?.fields.company_name}</Item>
        <Item>
          worked {reference?.fields.from} – {reference?.fields.to}
        </Item>
        <Item>
          <DropdownField<RejectionReason | true | undefined>
            value={findCurrentValue(reference?.fields)}
            onChange={verified => {
              if (verified === undefined || !reference) return

              if (verified !== true) {
                dispatch(updateVerification(reference.id, false, verified))
                return
              }

              dispatch(updateVerification(reference.id, true))
            }}
          >
            {prepOptions().map((option, i) => (
              <Option key={i} value={option.value}>
                {option.copy}
              </Option>
            ))}
          </DropdownField>
        </Item>
      </ReferenceSubRow>
    </ReferenceContainer>
  )
}

const Item = style().element()
const RelationshipLabelStyle = style().screen(breakpoints.mobile, style().hidden()).text({ align: 'right' }).element()
const VerificationBadgeStyle = style().flex({ alignItems: 'center' }).spacing({ gap: space.xs }).element()

type OptionValues = {
  value: RejectionReason | boolean | undefined
  copy: string
}

function prepOptions(): OptionValues[] {
  const options: OptionValues[] = []

  options.push({ value: true, copy: 'Verified' })
  options.push({ value: undefined, copy: 'Unreviewed' })

  for (const reason of rejectionReasons) {
    options.push({ value: reason, copy: reason })
  }

  return options
}

function findCurrentValue(reference: api.references.Fields | undefined): RejectionReason | true | undefined {
  if (!reference || !reference.is_reviewed) return

  if (reference.rejection_reason) {
    return reference.rejection_reason
  }

  if (reference.is_verified) {
    return true
  }

  return 'rejected_other'
}

export default Component
Component.displayName = 'ManageReferences'
