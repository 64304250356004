import { Button, GroupIcon, Modal, Text } from '@common/components'
import React from 'react'
import { GrFormClose } from 'react-icons/gr'

import * as api from 'api'
import { designSystemColors } from 'core/design-system/colors'
import { useSelector } from 'react-redux'
import * as orgsSelectors from 'selectors/orgs'
import { findSelfAssessmentByCandidateId } from 'selectors/questionnaire-submissions'
import { findSelfAssessmentAnswersByModuleSlug } from 'selectors/self-assessment'
import { RootState } from 'store'
import { getResponses } from '../get_responses'
import { SelfAssessmentQuestionBlock } from '../SelfAssessmentQuestionBlock'
import { Group } from './AssessmentPage'

interface ResponsesModalProps {
  modalState: {
    open: boolean
    moduleSlug: string | null
    group: Group | null
  }
  onClose: () => void
  role: api.request.Entity<api.openjobroles.Fields> | undefined
  candidateId: string
  candidateFirstName: string
}

export const ResponsesModal: React.FC<ResponsesModalProps> = props => {
  const orgId = useSelector((state: RootState) => orgsSelectors.currentId(state))
  const org = useSelector((state: RootState) => orgsSelectors.getById(state, orgId))

  const moduleAnswers = useSelector((state: RootState) =>
    props.modalState.moduleSlug
      ? findSelfAssessmentAnswersByModuleSlug(state, props.candidateId, props.modalState.moduleSlug)
      : undefined,
  )

  const variables: Record<string, string> = {
    'ORGANIZATION.NAME': org.fields.name,
    'ROLE.NAME': props.role?.fields.public_name || props.role?.fields.name || '',
  }

  const submission = useSelector((state: RootState) => findSelfAssessmentByCandidateId(state, props.candidateId))

  if (!moduleAnswers || !props.modalState.moduleSlug) {
    return null
  }

  const responses = getResponses([moduleAnswers], variables, submission)
  const moduleResponses = responses[props.modalState.moduleSlug]

  if (!moduleResponses || moduleResponses.length === 0 || !props.modalState.group) {
    return null
  }

  return (
    <Modal
      open={props.modalState.open}
      onClose={props.onClose || (() => {})}
      data-testid="responses-modal"
      style={{ maxHeight: '80dvh', maxWidth: 'clamp(300px, 80vw, 800px)', overflow: 'auto' }}
      header={
        <ModalHeader
          title={moduleAnswers.module?.copy.title || ''}
          subtitle={props.modalState.group.copy.title || ''}
          theme={props.modalState.group.copy.theme || ''}
          icon={props.modalState.group.copy.icon || ''}
          emoji={props.modalState.group.copy.emoji || ''}
          onClose={props.onClose || (() => {})}
        />
      }
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        {moduleResponses.map(response => (
          <SelfAssessmentQuestionBlock
            key={response.answer.question.id}
            response={response}
            questionnaireSubmissionId={response.questionnaireSubmissionId || ''}
            candidateName={props.candidateFirstName}
          />
        ))}
      </div>
    </Modal>
  )
}

interface ModalHeaderProps {
  title: string
  subtitle: string
  theme: string
  icon: React.ReactNode
  emoji: string
  onClose: () => void
}

const ModalHeader: React.FC<ModalHeaderProps> = props => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        gap: '0.5rem',
        padding: '1rem',
        borderBottom: `1px solid ${designSystemColors.borderDefault}`,
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.025)',
        position: 'sticky',
        top: 0,
        backgroundColor: 'white',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem',
        }}
      >
        <GroupIcon theme={props.theme} icon={props.icon} emoji={props.emoji} />
        <div>
          <Text variant="card-header">
            <span data-testid="responses-modal-title">{props.title}</span>
          </Text>
          <Text variant="body-text">{props.subtitle}</Text>
        </div>
      </div>
      <Button variant="icon" size="sm" onClick={props.onClose} data-testid="responses-modal-close-button">
        <GrFormClose size={24} />
      </Button>
    </div>
  )
}
