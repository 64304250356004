import React from 'react'

interface Props {
  hint: string
  children: React.ReactNode
}

const Component = (props: Props) => <div data-tour-hint={props.hint}>{props.children}</div>
export default Component
Component.displayName = 'TourHint'
