import { ActionCreator } from 'core/store'
import { add as notify } from 'store/notifications'
import { addEntities } from 'store/resources'
import { Fields, upload } from '../../api/images'
import { Entity } from '../../api/request'

export const uploadImage =
  (file: File): ActionCreator<Entity<Fields>> =>
  async dispatch => {
    const [response, errors] = await upload(file)

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return null
    }

    if (response) {
      dispatch(addEntities(response))

      return response.result as any
    }

    return null
  }
