import React from 'react'

import { createPortal } from 'react-dom'
import { GrFormClose } from 'react-icons/gr'
import useKeypress from '../../utils/keypress'
import classes from './Modal.module.scss'

interface ModalProps extends Omit<React.HTMLAttributes<HTMLDialogElement>, 'onClose'> {
  open: boolean
  onClose: () => void
  children?: React.ReactNode
  header?: React.ReactNode
  footer?: React.ReactNode
  title?: string
  style?: React.CSSProperties
}
/**
 * Modal component that renders a modal on top of the page, no matter where it is used.
 * Buttons can be added to the footer.
 * Esc key closes the modal.
 **/
export const Modal = (props: ModalProps) => {
  const closeModal = () => {
    if (props.open) {
      props.onClose?.()
    }
  }
  useKeypress('Escape', [], closeModal)

  if (!props.open) return null

  return (
    <>
      {createPortal(
        <div className={classes.modalContainer}>
          <Backdrop closeModal={closeModal} />
          <dialog
            {...props}
            className={`${props.footer ? classes.modal : classes.modalNoFooter}`}
            style={props.style}
            open
          >
            <div className={classes.contentWrapper}>
              {props.header ? (
                <>{props.header}</>
              ) : (
                <>
                  {props.title ? (
                    <div className={classes.header}>
                      <h2 className={classes.title}>{props.title}</h2>
                      <GrFormClose size={24} onClick={closeModal} className={classes.close} />
                    </div>
                  ) : (
                    <GrFormClose size={24} onClick={closeModal} className={classes.close} />
                  )}
                </>
              )}
              <div className={classes.content}>{props.children}</div>
              {props.footer ? <div className={classes.footer}>{props.footer}</div> : <div />}
            </div>
          </dialog>
        </div>,
        document.querySelector('#root') || document.body,
      )}
    </>
  )
}

const Backdrop = (props: { closeModal: () => void }) => {
  return (
    <div
      className={classes.backdrop}
      onClick={e => {
        e.stopPropagation()
        props.closeModal()
      }}
    />
  )
}
