import { designSystemColors } from 'core/design-system/colors'
import React, { useEffect, useRef, useState } from 'react'
import Measure, { BoundingRect } from 'react-measure'
import { Link } from 'react-router-dom'
import { useWindowSize } from 'react-use'
import { colors, px, rem, scale, size, space, style, weight } from '../../core'
import { zIndex } from '@common/styles/zIndex'
import { Icon } from '../Icons'
import Position from './Position'

export interface Params {
  parent?: React.MutableRefObject<HTMLDivElement | null>
  inheritSize?: boolean // when set false and parent is specified, it'll set the popup to the size of the parent rect.
}

interface Props extends Params {
  children: React.ReactNode
  open: boolean
  setOpen: (open: boolean) => void
}

const Popup = style()
  .relative()
  .front()
  .cond((params: Params) => !!params.parent, style().with(Position))
  .element()

const Content = style()
  .size({ width: size.stretch })
  .spacing({ inner: [space.xxs, space.none] })
  .border({ around: '1px solid', color: colors.lightMoonGray })
  .shadow(0.1)
  .color({ bg: colors.white })
  .round('6px')
  .front()
  .set('boxShadow', '0 1px 0 0 rgba(0,0,0,.05),0 0 20px 0 rgba(0,0,0,.15)')
  .element('section')

export const Separator = style()
  .size({ width: size.stretch, height: px(1) })
  .color({ bg: colors.lightestGray })
  .spacing({ outer: [space.xs, space.none] })
  .element()

const Component = (props: Props) => {
  const el = useRef(null)
  const windowSize = useWindowSize()
  const [bounds, setBounds] = useState<BoundingRect | undefined>(undefined)

  useEffect(() => {
    window.addEventListener('keyup', onKeyPress, false)

    return () => {
      window.removeEventListener('keyup', onKeyPress, false)
    }
  }, [])

  if (!props.open) return null

  return (
    <div ref={el} onClick={() => props.setOpen(false)}>
      <div
        onClick={e => {
          e.stopPropagation()
          props.setOpen(false)
        }}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: zIndex.navigation,
        }}
      />
      <Popup windowSize={windowSize} bounds={bounds} parent={props.parent} inheritSize={props.inheritSize}>
        <Measure
          bounds
          onResize={contentRect => {
            setBounds(contentRect?.bounds)
          }}
        >
          {({ measureRef }) => (
            <div ref={measureRef}>
              <Content>{props.children}</Content>
            </div>
          )}
        </Measure>
      </Popup>
    </div>
  )

  function onKeyPress(e) {
    switch (e.key) {
      // ESC
      case 'Escape':
        props.setOpen(false)
        break
    }
  }
}

export default Component
Component.displayName = 'Popup'

const ButtonGrid = style()
  .grid({ columns: [rem(1), size.auto] })
  .spacing({ columns: space.xs, inner: [space.xs, space.s] })
  .color({ fg: colors.black })
  .sans({ size: scale.s, nodecoration: true })

const ButtonContainer = style()
  .pointer()
  .select(':hover', style().color({ bg: colors.nearWhite }))
  .select('a', ButtonGrid)
  .select('strong', style().text({ weight: weight.normal }))
  .element()

const ButtonIcon = style()
  .sans({ align: 'center' })
  .color({ fg: colors.moonGray })
  .cond(
    ({ variant }: { variant: ButtonVariant }) => variant === 'secondary',
    style().color({ fg: designSystemColors.typographySecondary }),
  )
  .element()

const NoLink = ButtonGrid.element()
const ButtonLabel = style()
  .cond(
    ({ variant }: { variant: ButtonVariant }) => variant === 'secondary',
    style().color({ fg: designSystemColors.typographySecondary }),
  )
  .element()

type ButtonVariant = 'default' | 'secondary'

interface ButtonProps {
  children: React.ReactNode
  icon: string
  link?: string
  newTab?: boolean
  variant?: ButtonVariant
  onClick?: (ev: any) => void
}

export function Button(props: ButtonProps) {
  if (props.link) {
    return (
      <LinkButton
        onClick={props.onClick}
        icon={props.icon}
        link={props.link}
        newTab={props.newTab}
        variant={props.variant}
      >
        {props.children}
      </LinkButton>
    )
  }

  return (
    <ButtonContainer onClick={props.onClick}>
      <NoLink>
        <ButtonContent icon={props.icon} variant={props.variant}>
          {props.children}
        </ButtonContent>
      </NoLink>
    </ButtonContainer>
  )
}

function LinkButton(props: {
  icon: string
  children: React.ReactNode
  link: string
  newTab?: boolean
  onClick?: (ev: any) => void
  variant?: ButtonVariant
}) {
  return (
    <ButtonContainer onClick={ev => props.onClick && props.onClick(ev)}>
      {/^(http|mailto)/.test(props.link || '') ? (
        <a href={props.link} target="_blank" rel="noopener noreferrer">
          <ButtonContent {...props} />
        </a>
      ) : (
        <Link target={props.newTab ? '_blank' : '_self'} to={props.link}>
          <ButtonContent {...props} />
        </Link>
      )}
    </ButtonContainer>
  )
}

function ButtonContent(props: { icon: string; children: React.ReactNode; variant?: ButtonVariant }) {
  return (
    <>
      <ButtonIcon variant={props.variant}>
        <Icon name={props.icon} />
      </ButtonIcon>
      <ButtonLabel variant={props.variant}>{props.children}</ButtonLabel>
    </>
  )
}
