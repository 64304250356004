import { px2rem, space, style, weight } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import { Link as ReactLink } from 'react-router-dom'

export const Text = text.bodyText().spacing({ outerTop: space.s }).element('p')
export const PreformattedText = text.bodyText().spacing({ outerTop: space.s }).text({ preformatted: true }).element('p')
export const TextHint = text
  .smallBody()
  .text({ align: 'center' })
  .spacing({ outerTop: space.xxs })
  .fg(designSystemColors.typographySecondary)
  .element('p')
export const TextBold = text.bodyText().text({ weight: weight.normal }).element('span')
export const TextHeader = style()
  .sans({ weight: '600', size: px2rem(30), line: px2rem(40) })
  .color({ fg: designSystemColors.typographyPrimary })
  .text({ align: 'center' })
  .nospacing()
  .element()

const LinkBaseStyle = style()
  .text({ nodecoration: true })
  .block()
  .cond(({ disabled }: { disabled: boolean }) => disabled, style().set('pointerEvents', 'none'))

export const Link = LinkBaseStyle.clone().elementFromComponent(ReactLink)
export const A = LinkBaseStyle.clone().element('a')
