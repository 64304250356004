import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { assessments } from '../../api'

interface State {
  created: string | undefined
  loading: boolean
  creating: boolean
  content: string[]
}

const name = assessments.RESOURCE
const initialState: State = {
  created: undefined,
  loading: false,
  creating: false,
  content: [],
}

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setCreating(state, action: PayloadAction<boolean>) {
      state.creating = action.payload
    },
    setContent(state, action: PayloadAction<string[]>) {
      state.content = action.payload
    },
    setCreated(state, action: PayloadAction<string>) {
      state.created = action.payload
    },
  },
})

export const { setContent, setLoading, setCreating, setCreated } = slice.actions

export default slice.reducer
