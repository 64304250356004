import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { openjobroleusers, users } from '../../api'
import { SelectedUser } from '../../api/openjobroles'
import { AppThunk } from '../../core/store'
import { add as notify } from '../notifications'
import { addEntities } from '../resources'

interface State {
  selectableUserIds: string[]
  selectedUsersWithPerms: SelectedUser[]
  isLoading: boolean
}

const name = openjobroleusers.RESOURCE
const initialState: State = {
  selectableUserIds: [],
  selectedUsersWithPerms: [],
  isLoading: false,
}

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
    setSelectableUserIds(state, action: PayloadAction<string[]>) {
      state.selectableUserIds = action.payload
    },
  },
})

export const { setIsLoading, setSelectableUserIds } = slice.actions

export default slice.reducer

export const loadUsersByOrgId =
  (orgid: string): AppThunk =>
  async dispatch => {
    dispatch(setIsLoading(true))

    const [response, errors] = await users.loadActiveByOrgId(orgid)

    dispatch(setIsLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(addEntities(response))
      dispatch(setSelectableUserIds(response.result.map(row => row.fields.id)))
    }
  }

export const addUser =
  (roleid: string, user: SelectedUser): AppThunk =>
  async dispatch => {
    dispatch(setIsLoading(true))

    const [response, errors] = await openjobroleusers.addUserToRole(roleid, user.id, user.can_control || false)

    dispatch(setIsLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(addEntities(response))
    }
  }

export const deleteUser =
  (roleid: string, userid: string): AppThunk =>
  async dispatch => {
    dispatch(setIsLoading(true))

    const [response, errors] = await openjobroleusers.deleteUserFromRole(roleid, userid)

    dispatch(setIsLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(addEntities(response))
      dispatch(notify({ success: 'User has been removed from the job!' }))
    }
  }
