import { useAppDispatch } from 'core/store'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootState } from 'store'
import * as api from '../../api'
import InviteForm from '../../components/InviteForm'
import Modal from '../../components/Modal'
import { Content, ContentAndButtons } from '../../components/Wizard'
import Buttons from '../../components/Wizard/Buttons'
import { Subheading } from '../../components/Wizard/Typography'
import { rem, style } from '../../core'
import * as tracking from '../../core/track'
import * as selectors from '../../selectors'
import * as slice from '../../store/new-role'

interface Props {
  open: boolean
  roleId: string
  setOpen: (b: boolean) => void
  refresh: () => void
}

const Container = style()
  .size({
    maxWidth: rem(40),
    maxHeight: rem(18),
    width: 'calc(100vw - 200px)',
    height: 'calc(100dvh - 200px)',
  })
  .element()

const InviteModal = (props: Props) => {
  const history = useHistory()
  const dispatch = useAppDispatch()

  const [row, setRow] = useState<api.openjobroles.Candidate>({
    full_name: '',
    email: '',
  })

  const [loading, sentInvites]: [boolean, string[]] = useSelector((state: RootState) => [
    state.newRole.loading,
    state.newRole.sentInvites,
  ])

  const isDone = row.email && row.full_name
  const notInvitedYet = !sentInvites.includes(row.email)
  const invalidEmailPresent = !selectors.isEmailValid(row.email)
  const role = useSelector((state: RootState) => selectors.roles.findById(state, props.roleId))

  useEffect(() => {
    if (!props.open) {
      setRow({ full_name: '', email: '' })
    }
  }, [dispatch, props.open])

  return (
    <Modal title="Invite candidate" open={props.open} setOpen={props.setOpen}>
      <Container>
        <ContentAndButtons>
          <Content standalone>
            <Subheading>Enter the candidate's email and name to send an invite.</Subheading>
            <InviteForm row={row} setRow={setRow} />
          </Content>
          <Buttons
            next={() => (!notInvitedYet ? done() : sendInvites())}
            back={() => {}}
            label={!notInvitedYet ? 'Done' : `Invite`}
            disabled={!isDone || loading || invalidEmailPresent}
            loading={loading}
            forwardOnly
          ></Buttons>
        </ContentAndButtons>
      </Container>
    </Modal>
  )

  async function sendInvites() {
    if (props.roleId) {
      const response = await dispatch(slice.invitev2(props.roleId, row, { sendSurvey: true }))

      tracking.roles.inviteViaEmailSent({ name: role.fields.name })

      if (response && role.fields.ask_reference_check) {
        history.push(`/roles/${props.roleId}/candidates/${response.candidateId}`)
      }

      if (response && role.fields.ask_self_assessment) {
        history.push(`/roles/${props.roleId}/candidates/${response.candidateId}/assessment`)
      }
    }
  }

  function done() {
    props.setOpen(false)
    props.refresh()
  }
}

export default InviteModal
