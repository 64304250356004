import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { colors, px, space, style } from '../../../core'
import * as selectors from '../../../selectors'
import { RootState } from '../../../store'
import { unshare } from '../../../store/candidates'
import Name from './Name'

const Container = style()
  .spacing({ outerTop: space.s })
  .scroll({ y: 'auto' })
  .color({ bg: colors.nearWhite })
  .round(px(6))
  .element()

interface Props {
  candidateId: string
}

const Component = (props: Props) => {
  const dispatch = useDispatch()

  const currentUser = useSelector(selectors.users.current)
  const currentProfile = useSelector((state: RootState) => selectors.profiles.getByUserId(state, currentUser?.id || ''))
  const otps = useSelector((state: RootState) => selectors.oneTimePassword.findByCandidateId(state, props.candidateId))

  return (
    <Container>
      <Name email={currentUser?.fields.email || ''}>{currentProfile?.fields.full_name} (you)</Name>
      {otps.map(otp => (
        <Name
          key={otp.id}
          email={otp.fields.email}
          action={() => {
            dispatch(unshare(otp.fields.candidate_id, otp.fields.email))
          }}
        >
          {otp.fields.email}
        </Name>
      ))}
    </Container>
  )
}

export default Component
Component.displayName = 'NameList'
