import Modal from 'components/Modal'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { HashLink as Link } from 'react-router-hash-link'
import * as api from '../../api'
import Chart from '../../components/Chart'
import { ChipKnowledgeBite, getKnowledgeBiteByKey } from '../../content'
import { colors, height, px2rem, space, style } from '../../core'
import * as tracking from '../../core/track'
import * as selectors from '../../selectors'
import { RootState } from '../../store'
import EmptyAnswer from './EmptyAnswer'
import KnowledgeBite from './KnowledgeBite'
import ReferenceAnswer from './ReferenceAnswer'

interface Props {
  candidateId: string
  referenceId?: string
  item: api.assessmentversions.Item
}

const Container = style().element()

export const Chips = style().text({ height: height.xxl }).flex({ wrap: 'wrap' }).spacing({ gap: space.xs }).element()

const ReferenceDescription = text
  .bodyText()
  .spacing({ outerTop: px2rem(12) })
  .element('p')

export const Chip = text
  .bodyText()
  .inlineBlock()
  .spacing({ inner: [px2rem(8), px2rem(14)] })
  .color({ fg: colors.midGray })
  .round(px2rem(30))
  .border({ around: `solid 1px`, color: designSystemColors.borderDefault })
  .color({ bg: designSystemColors.backgroundNeutralSecondary })
  .cond(
    ({ onClick }: { onClick: boolean }) => onClick,
    style().select(':hover', style().color({ bg: designSystemColors.backgroundNeutralSecondaryHover })),
  )
  .cond(
    ({ highlight }: { highlight: boolean }) => highlight,
    style().color({
      bg: designSystemColors.backgroundNeutralAccent,
      fg: designSystemColors.white,
    }),
  )
  .cond(
    ({ correct }: { correct: boolean }) => correct,
    style()
      .color({
        bg: designSystemColors.informationGreenBackground,
        fg: designSystemColors.informationGreenPrimary,
      })
      .border({ color: designSystemColors.informationGreenPrimary }),
  )
  .cond(
    ({ correct, highlight }: { correct: boolean; highlight: boolean }) => correct && highlight,
    style()
      .color({
        bg: designSystemColors.informationGreenPrimary,
        fg: designSystemColors.informationGreenBackground,
      })
      .border({ color: designSystemColors.informationGreenBackground }),
  )
  .element('span')

const ChipLink = style(Chip).sans({ nodecoration: true }).elementFromComponent(Link)

const KnowledgeBiteContainer = style()
  .spacing({ inner: space.m })
  .size({ maxWidth: px2rem(1024) })
  .element()

const Answers = style()
  .select(
    '> *',
    style().border({
      top: 'solid 1px',
      color: designSystemColors.borderDefault,
    }),
  )
  .select('> :first-child', style().noborders())
  .element()

const InnerSection = style()
  .bg({ color: designSystemColors.backgroundNeutralSecondary })
  .spacing({
    outer: space.none,
    inner: [px2rem(24), px2rem(16)],
  })
  .border({ top: '1px solid', color: designSystemColors.borderDefault })
  .element()

export const EmptyAnswerSection = style()
  .spacing({
    outer: space.none,
    inner: [px2rem(24), px2rem(16)],
  })
  .element()

const Component = (props: Props) => {
  const references = useSelector((state: RootState) =>
    props.referenceId
      ? [selectors.references.getById(state, props.referenceId)]
      : selectors.references.findByCandidateId(state, props.candidateId),
  )

  const chart = useSelector((state: RootState) =>
    selectors.charts.chartForQuestion(state, props.candidateId, props.item.content_id),
  )

  const [knowledgeBite, setKnowledgeBite] = useState<ChipKnowledgeBite>()

  if (references.length === 0) {
    return (
      <EmptyAnswerSection>
        <EmptyAnswer waitingReferences noSpacing />
      </EmptyAnswerSection>
    )
  }

  return (
    <>
      <Container>
        {chart && props.referenceId === undefined ? (
          <InnerSection>
            <Chart data={chart.data} options={chart.options} />
          </InnerSection>
        ) : null}
        <Answers>
          {references.map(reference =>
            reference ? (
              <Answer
                key={reference.id}
                item={props.item}
                reference={reference}
                hideProfile={props.referenceId !== undefined}
                onClickChip={onClickChip}
                sectionUrl={`#${props.item.content_id}`}
              />
            ) : null,
          )}
        </Answers>
      </Container>
      <Modal open={!!knowledgeBite} setOpen={open => !open && setKnowledgeBite(undefined)}>
        {knowledgeBite && (
          <KnowledgeBiteContainer>
            <KnowledgeBite onClose={() => setKnowledgeBite(undefined)} knowledgeBite={knowledgeBite} />
          </KnowledgeBiteContainer>
        )}
      </Modal>
    </>
  )

  function onClickChip(chip: string) {
    const clickedKnowledgeBite = getKnowledgeBiteByKey(chip)
    tracking.references.chipViewed({ chipName: chip })
    setKnowledgeBite(knowledgeBite === clickedKnowledgeBite ? undefined : clickedKnowledgeBite)
  }
}

export default Component
Component.displayName = 'Chips'

interface AnswerProps {
  item: api.assessmentversions.Item
  reference: api.request.Entity<api.references.Fields>
  hideProfile: boolean
  sectionUrl: string
  onClickChip?: (k: string) => void
}

export function Answer(props: AnswerProps) {
  const [content, answer] = useSelector((state: RootState) => [
    selectors.formResponses.findSelectedOptionsByReferenceId(state, props.reference.id, props.item.content_id),
    selectors.formResponses.findAnswerByReferenceId(state, props.reference.id, props.item.content_id),
  ])

  if (Array.isArray(content) && content.length === 0) {
    const didReferenceSubmitAnswers = props.reference.fields.form_response_id !== ''
    return (
      <ReferenceAnswer
        reference={props.reference}
        contentid={props.item.content_id}
        hideProfile={props.hideProfile}
        center
      >
        <EmptyAnswer
          noSpacing
          notApplicableForCandidate={
            props.reference.fields.is_self && didReferenceSubmitAnswers && answer === undefined
          }
          skipped={answer === undefined || (answer as api.formresponses.Row).skipped}
          leftBlank={answer !== undefined && !(answer as api.formresponses.Row).skipped}
          waitingResponse={!didReferenceSubmitAnswers}
        />
      </ReferenceAnswer>
    )
  }

  return (
    <ReferenceAnswer
      reference={props.reference}
      contentid={props.item.content_id}
      hideProfile={props.hideProfile}
      center
    >
      <Chips>
        {Array.isArray(content) &&
          content.map((c, index) => {
            if (props.onClickChip && getKnowledgeBiteByKey(c?.caption || '')) {
              const onClick = props.onClickChip
              return (
                <ChipLink key={c?.caption || index} to={props.sectionUrl} onClick={() => onClick(c?.caption || '')}>
                  {c?.caption}
                </ChipLink>
              )
            }

            return <Chip key={c?.caption || index}>{c?.caption}</Chip>
          })}
      </Chips>
      {(answer as api.formresponses.Row)?.input ? (
        <ReferenceDescription>{(answer as api.formresponses.Row).input}</ReferenceDescription>
      ) : null}
    </ReferenceAnswer>
  )
}
