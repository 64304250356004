import action from './action'
import events from './events'
import track, { googleAnalyticsCustomEvent } from './track'

export const signUpProvidedWorkEmail = action(() => {
  track(events.SELF_SIGN_UP_PROVIDED_WORK_EMAIL, {})
})

export const signUpProvidedCompanyName = action(() => {
  track(events.SELF_SIGN_UP_PROVIDED_COMPANY_NAME, {})
})

export const signUpVerifiedEmail = action((verified: boolean, error?: string) => {
  track(events.SELF_SIGN_UP_VERIFIED_EMAIL, {
    verified: verified,
    error: error || '',
  })
})

export const signUpWithGoogleAuth = action((utmParams: Record<string, string>) => {
  track(events.SELF_SIGN_UP_WITH_GOOGLE_AUTH, {
    ...utmParams,
  })
})

export const successfullySignedUp = action((utmParams?: Record<string, string>) => {
  //mixpanel event
  track(events.SUCCESSFULLY_SIGNED_UP, {})

  //GA4 event
  googleAnalyticsCustomEvent('signup', 'plg', utmParams)
})

export function hasGASignupEvent() {
  return dataLayer.some(event => event.event === 'signup')
}
