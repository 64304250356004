import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import {
  findAreasOfImprovementAttributesByCandidateId,
  findCultureAddSummaryExceptSelf,
  findCultureAddSummaryOnlySelf,
  findEstablishFocusOverallByCandidateId,
  findGeneralImpressionsTestimoniesByCandidateId,
  findGrowthHintsTestimoniesByCandidateId,
  findInspireOthersOverallByCandidateId,
  findKeyAcheivementsTestimoniesByCandidateId,
  findLeadershipSkillsOverallScoreByCandidateId,
  findReasonsOfLeavingByCandidateId,
  findRecommendationSummaryByCandidateId,
  findRelationshipBuildingOverallByCandidateId,
  findStrengthAttributesByCandidateId,
  findWorkingWithOthersChartAttributeExceptSelf,
  findWorkingWithOthersChartAttributeOnlySelf,
  findWorkStyleByCandidateId,
} from 'selectors/candidate-summaries'
import { RootState } from 'store'

export function useLeadershipSummaries(query?: { candidateId?: string }) {
  const { candidateId } = query || {}
  const overallScoreSelector = useCallback(
    (state: RootState) => findLeadershipSkillsOverallScoreByCandidateId(state, candidateId || ''),
    [candidateId],
  )

  const establishFocusSummarySelector = useCallback(
    (state: RootState) => findEstablishFocusOverallByCandidateId(state, candidateId || ''),
    [candidateId],
  )

  const relationshipBuildingSummarySelector = useCallback(
    (state: RootState) => findRelationshipBuildingOverallByCandidateId(state, candidateId || ''),
    [candidateId],
  )

  const inspireOthersSummarySelector = useCallback(
    (state: RootState) => findInspireOthersOverallByCandidateId(state, candidateId || ''),
    [candidateId],
  )

  const overallLeadershipScore = useSelector(overallScoreSelector)
  const establishFocusScore = useSelector(establishFocusSummarySelector)
  const buildingRelationshipScore = useSelector(relationshipBuildingSummarySelector)
  const inspireOthersScore = useSelector(inspireOthersSummarySelector)

  return {
    establishFocusScore,
    buildingRelationshipScore,
    inspireOthersScore,
    overallLeadershipScore,
  }
}

export function useHiringRecommendationSummary(query: { candidateId?: string }) {
  const { candidateId } = query
  const data = useSelector((state: RootState) => findRecommendationSummaryByCandidateId(state, candidateId || ''))

  if (!candidateId) {
    return { isLoading: true }
  }

  return {
    isLoading: false,
    data,
  }
}

export function useGeneralImpression(query: { candidateId?: string }) {
  const { candidateId } = query
  const testimonies = useSelector((state: RootState) =>
    findGeneralImpressionsTestimoniesByCandidateId(state, candidateId || ''),
  )

  if (!candidateId) {
    return { isLoading: true }
  }

  return {
    isLoading: false,
    testimonies,
  }
}

export function useKeyAcheivements(query: { candidateId?: string }) {
  const { candidateId } = query
  const testimonies = useSelector((state: RootState) =>
    findKeyAcheivementsTestimoniesByCandidateId(state, candidateId || ''),
  )

  if (!candidateId) {
    return { isLoading: true }
  }

  return {
    isLoading: false,
    testimonies,
  }
}

export function useGrowthHints(query: { candidateId?: string }) {
  const { candidateId } = query
  const testimonies = useSelector((state: RootState) =>
    findGrowthHintsTestimoniesByCandidateId(state, candidateId || ''),
  )

  if (!candidateId) {
    return { isLoading: true }
  }

  return {
    isLoading: false,
    testimonies,
  }
}

export function useWorkStyle(query: { candidateId?: string }) {
  const { candidateId } = query
  const data = useSelector((state: RootState) => findWorkStyleByCandidateId(state, candidateId || ''))

  if (!candidateId) {
    return { isLoading: true }
  }

  return {
    isLoading: false,
    data,
  }
}

export function useReasonsForLeaving(query: { candidateId?: string }) {
  const { candidateId } = query
  const data = useSelector((state: RootState) => findReasonsOfLeavingByCandidateId(state, candidateId || ''))

  if (!candidateId) {
    return { isLoading: true }
  }

  return {
    isLoading: false,
    data,
  }
}

export function useCultureAdd(query: { candidateId?: string }) {
  const { candidateId } = query
  const summary = useSelector((state: RootState) => findCultureAddSummaryExceptSelf(state, candidateId || ''))

  const selfSummary = useSelector((state: RootState) => findCultureAddSummaryOnlySelf(state, candidateId || ''))

  if (!candidateId) {
    return { isLoading: true }
  }

  return {
    isLoading: false,
    summary,
    selfSummary,
  }
}

export function useStrengths(query: { candidateId?: string }) {
  const { candidateId } = query
  const data = useSelector((state: RootState) => findStrengthAttributesByCandidateId(state, candidateId || ''))

  if (!candidateId) {
    return { isLoading: true }
  }

  return {
    isLoading: false,
    data,
  }
}

export function useAreasOfImprovement(query: { candidateId?: string }) {
  const { candidateId } = query
  const data = useSelector((state: RootState) =>
    findAreasOfImprovementAttributesByCandidateId(state, candidateId || ''),
  )

  if (!candidateId) {
    return { isLoading: true }
  }

  return {
    isLoading: false,
    data,
  }
}

export function useWorkingWithOthers(query: { candidateId?: string }) {
  const { candidateId } = query
  const summary = useSelector((state: RootState) =>
    findWorkingWithOthersChartAttributeExceptSelf(state, candidateId || ''),
  )

  const selfSummary = useSelector((state: RootState) =>
    findWorkingWithOthersChartAttributeOnlySelf(state, candidateId || ''),
  )

  if (!candidateId) {
    return { isLoading: true }
  }

  return {
    isLoading: false,
    summary,
    selfSummary,
  }
}
