import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import * as selectors from 'selectors'
import { RootState } from 'store'
import { employees, listing } from '../../../api'
import { Button, ButtonContent } from '../../../components/Button/ButtonV2'
import { useConfirm } from '../../../components/Confirm'
import ContentGrid, { Column, SortedColumn } from '../../../components/ContentGrid'
import { FirstStep } from '../../../components/FirstStep'
import IconTooltip from '../../../components/Tooltip/IconTooltip'
import { size, space, style } from '../../../core'
import { SortField } from '../../../store/employees'
import { Query } from '../index'
import { CustomRow, Row } from './Row'
import { useInView } from 'react-intersection-observer'

const IconTooltipContainer = style()
  .spacing({ outer: ['0', space.xxs] })
  .element()

const ScrollContainer = style().relative().size({ maxHeight: '60vh' }).scroll({ y: 'auto' }).element()

const Container = style().size({ height: size.fill }).element()

const ButtonContainer = style().spacing({ outerTop: space.m }).element()

type Props = {
  employees: employees.Fields[]
  loadNext: (query: Query) => void
  hasMore: boolean
  setAddHireModalOpen: (open: boolean) => void
  isAddHireModalOpen: boolean
  removeHire: (employeeId: string) => void
  sortingField: SortField
  setSortingField: (field: SortField) => void
  desc: boolean
  setDesc: (order: listing.SortOrder) => void
}

const SCROLLABLE_TARGET = 'qoh-table'

export function Table(props: Props) {
  const confirm = useConfirm()
  const user = useSelector((state: RootState) => {
    return selectors.users.current(state)
  })
  const userHasVerifiedEmail = user.fields.verified_at > 0
  const button = (
    <Button
      buttonType="secondary-accent-fgbg"
      onClick={() => {
        props.setAddHireModalOpen(true)
      }}
      isFullWidth={true}
      disabled={!userHasVerifiedEmail}
    >
      <ButtonContent icon={{ name: 'plus', ariaLabel: 'add new hire' }}>{'Add new hire'}</ButtonContent>
    </Button>
  )

  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: '200px',
  })

  useEffect(() => {
    if (inView && props.hasMore) {
      props.loadNext({
        offset: props.employees.length,
      })
    }
  }, [inView, props.hasMore])

  return (
    <Container>
      <ContentGrid overflow>
        <CustomRow>
          <Column head></Column>
          <SortedColumn<SortField>
            center={false}
            field={SortField.Name}
            sortingField={props.sortingField}
            setSortingField={props.setSortingField}
            desc={props.desc}
            setDesc={props.setDesc}
          >
            Name
          </SortedColumn>
          <SortedColumn<SortField>
            center={false}
            field={SortField.StartingDate}
            sortingField={props.sortingField}
            setSortingField={props.setSortingField}
            desc={props.desc}
            setDesc={props.setDesc}
          >
            Start date
          </SortedColumn>
          <Column head>Department</Column>
          <SortedColumn<SortField>
            center
            field={SortField.OverallScore}
            sortingField={props.sortingField}
            setSortingField={props.setSortingField}
            desc={props.desc}
            setDesc={props.setDesc}
          >
            Overall score
            <IconTooltipContainer>
              <IconTooltip icon={'info-circle'} tooltipCopy={"Average of the 'Experience' and 'Evaluation' score"} />
            </IconTooltipContainer>
          </SortedColumn>
          <SortedColumn<SortField>
            center
            field={SortField.NewHireExperienceScore}
            sortingField={props.sortingField}
            setSortingField={props.setSortingField}
            desc={props.desc}
            setDesc={props.setDesc}
          >
            Experience
            <IconTooltipContainer>
              <IconTooltip icon={'info-circle'} tooltipCopy={'How the new hires rate their starting experience.'} />
            </IconTooltipContainer>
          </SortedColumn>
          <SortedColumn<SortField>
            center
            field={SortField.ManagersEvaluationScore}
            sortingField={props.sortingField}
            setSortingField={props.setSortingField}
            desc={props.desc}
            setDesc={props.setDesc}
          >
            Evaluation
            <IconTooltipContainer>
              <IconTooltip icon={'info-circle'} tooltipCopy={'How managers rate the performance of the new hires.'} />
            </IconTooltipContainer>
          </SortedColumn>
          <Column head center>
            Delete
          </Column>
        </CustomRow>
        <ScrollContainer id={SCROLLABLE_TARGET}>
          {props.employees.length ? (
            props.employees.map((employee, i) => (
              <Row
                key={'employee-' + i}
                onDelete={async () => {
                  if (
                    await confirm({
                      title: `Remove ${employee.name}?`,
                      message: `This action is permanent, the employee **${employee.name}** and their data will be deleted. Do you want to continue?`,
                      confirmLabel: 'Yes, remove hire',
                      danger: true,
                    })
                  ) {
                    props.removeHire(employee.id)
                  }
                }}
                employee={employee}
              />
            ))
          ) : (
            <FirstStep
              header={'Add your first new hire'}
              description={
                'Fill in the details of your new hire and their manager in order to send them our scientific questionnaire to evaluate the quality of the hire.'
              }
            >
              <br />
              {!props.employees.length && button}
            </FirstStep>
          )}
          {props.hasMore && <div ref={ref} />}
        </ScrollContainer>
      </ContentGrid>
      <ButtonContainer>{props.employees.length > 0 && button}</ButtonContainer>
    </Container>
  )
}
