import _ from 'lodash'
import React, { useCallback, useEffect } from 'react'
import { FaArrowDownShortWide, FaArrowRightArrowLeft, FaArrowUpWideShort } from 'react-icons/fa6'
import classes from './Table.module.scss'

type Data = {
  id: string | number
  /** Optional style for the row */
  style?: React.CSSProperties
  [key: string]: string | number | React.ReactNode | Record<string, unknown> | React.CSSProperties
}

export type TableHeader<T> = {
  name: string
  key: (keyof T & string) | string
  /** Column width. Example `"25px"` or `"60%"` */
  colWidth?: string
  minColWidth?: string
  sortable?: boolean
  align?: 'left' | 'center' | 'right'
  render?: (item: T) => React.ReactNode
}

interface TableProps<T> {
  headers: TableHeader<T>[]
  data?: T[]
  children?: React.ReactNode
  /** Called when the user scrolls to the bottom of the table */
  onReachBottom?: () => void
  /** Called when the user clicks on a sortable header. Use API call to sort. */
  onSort?: (key: string, direction: 'Asc' | 'Desc') => void
  noPadding?: boolean
  style?: React.CSSProperties
  testId?: string
}

/** Check if the key is in the item and if the value is a string */
function canEscapeKey<T extends object, K extends string>(
  item: { [key in K]: unknown },
  key: K,
): item is T & { [key in K]: unknown } {
  return key in item && typeof item[key] === 'string'
}

/**
 * Responsive general purpose visual table component.
 *
 * @example
 * const [headers, setHeaders] = useState([
 *  { name: 'Name', key: 'name' },
 * { name: 'Age', key: 'age' }
 * ])
 *
 * const [data, setData] = useState([
 * { id: 1, name: 'John', age: 20 },
 * { id: 2, name: 'Jane', age: 21 }
 * ])
 *
 * <Table headers={headers} data={data} />
 * @param props
 */
export const Table = <T extends Data>(props: TableProps<T>) => {
  const tableRef = React.useRef<HTMLDivElement>(null)
  const [sortBy, setSortBy] = React.useState<{
    key: keyof T & string
    direction: 'Asc' | 'Desc'
  } | null>(null)

  // Handle sorting
  const handleSort = useCallback((key: keyof T & string) => {
    setSortBy(prev =>
      prev && prev.key === key
        ? { key, direction: prev.direction === 'Asc' ? 'Desc' : 'Asc' }
        : { key, direction: 'Asc' },
    )
  }, [])

  React.useEffect(() => {
    if (props.onSort && sortBy) {
      props.onSort(sortBy.key, sortBy.direction)
    }
  }, [props.onSort, sortBy])

  let isAtBottom = false

  // execute onReachBottom when the user scrolls to 80% of the table
  useEffect(() => {
    const table = tableRef.current
    if (!table) return

    const reachBottom = _.throttle(() => {
      if (props.onReachBottom) {
        props.onReachBottom()
      }
    }, 500)

    const handleScroll = () => {
      if (props.onReachBottom && table.scrollTop + table.clientHeight >= table.scrollHeight * 0.9) {
        if (!isAtBottom) {
          isAtBottom = true
          reachBottom()
        }
      } else {
        isAtBottom = false
      }
    }

    table.addEventListener('scroll', handleScroll)

    return () => {
      table.removeEventListener('scroll', handleScroll)
    }
  }, [props.onReachBottom])

  return (
    <div className={classes.tableContainer} ref={tableRef} style={props.style}>
      <table className={classes.table} data-testid={props.testId}>
        <colgroup>
          {props.headers.map(header => (
            <col
              key={header.name}
              style={{
                minWidth: header.minColWidth,
                width: header.colWidth || 'auto',
              }}
            />
          ))}
        </colgroup>
        <thead className={classes.thead}>
          <tr className={classes.tr}>
            {props.headers.map(header => (
              <th
                key={header.name}
                className={[
                  classes.th,
                  props.onSort && header.sortable ? classes.sortable : '',
                  !props.noPadding && classes.padding,
                ].join(' ')}
              >
                <div
                  className={
                    header.align
                      ? [classes[header.align], classes.sortIndicatorContainer].join(' ')
                      : classes.sortIndicatorContainer
                  }
                  onClick={() => header.sortable && handleSort(header.key)}
                >
                  {header.name}
                  {props.onSort &&
                    header.sortable &&
                    (sortBy?.key === header.key ? (
                      sortBy.direction === 'Asc' ? (
                        <FaArrowDownShortWide size={14} className={classes.sortIndicatorIcon} />
                      ) : (
                        <FaArrowUpWideShort size={14} className={classes.sortIndicatorIcon} />
                      )
                    ) : (
                      <FaArrowRightArrowLeft size={14} style={{ transform: 'rotate(90deg)' }} />
                    ))}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={classes.tbody}>
          {props.children}
          {props.data?.map(item => (
            <tr key={item.id} className={classes.tr} style={item.style}>
              {props.headers.map(header => (
                <td key={header.name} className={[classes.td, !props.noPadding && classes.padding].join(' ')}>
                  <div className={header.align ? classes[header.align] : ''}>
                    {/* @ts-ignore */}
                    {header.render
                      ? header.render(item)
                      : canEscapeKey(item, header.key)
                      ? item[header.key]
                      : String(item[header.key])}
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

interface RowProps {
  id: string | number
  cells: (string | number | React.ReactNode)[]
  onClick?: () => void
}

/**
 * Table row component. Use this component to render a row in the table.
 *
 * @param props
 *
 * @example
 * <Table headers={[{ name: 'Name', key: 'name' }, { name: 'Age', key: 'age' }]}>
 *   <Row id={1} cells={['John', 20]} />
 *   <Row id={2} cells={['Jane', 21]} />
 * </Table>
 *
 * @example
 * <Table ...>
 *   {data.map((item) => (
 *     <Row key={item.id} id={item.id} cells={[item.name, item.age]} />
 *   ))}
 * </Table>
 **/
export const Row: React.FC<RowProps> = props => {
  return (
    <tr className={[classes.tr, props.onClick ? classes.clickable : ''].join(' ')} onClick={props.onClick}>
      {props.cells.map((cell, i) => (
        <td key={`cell-${i}-${props.id}`} className={[classes.td, classes.padding].join(' ')}>
          {cell}
        </td>
      ))}
    </tr>
  )
}
