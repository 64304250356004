import { useSelector } from 'react-redux'
import { canManageRole } from 'selectors/open-job-role-users'
import { canProvideSupport, current, id, isSuperUser } from 'selectors/users'
import { RootState } from 'store'

export function useIsSuperUser() {
  return useSelector((state: RootState) => isSuperUser(state, id(state)))
}

export function useCanProvideSupport() {
  return useSelector((state: RootState) => canProvideSupport(state, id(state)))
}

export function useUser() {
  return useSelector(current)
}

export function useIsAllowedToManagedRole(roleId: string) {
  return useSelector((state: RootState) => canManageRole(state, roleId, state.sessions.userId))
}
