import { interpolateVariables } from 'core/text'
import { RootState } from '../store'
import * as candidateProfiles from './candidate-profiles'
import * as orgs from './orgs'
import * as profiles from './profiles'
import * as references from './references'
import * as roles from './roles'
import * as users from './users'

function getVariablesByReferenceId(state: RootState, referenceId: string): { [key: string]: string | number } {
  const reference = references.findById(state, referenceId)
  if (!reference) return {}

  const candidateProfile = candidateProfiles.findByCandidateId(state, reference.fields.candidate_id)

  const currentUser = users.current(state)
  if (!currentUser) return {}

  const recruiterUser = users.getById(state, currentUser.id)
  const recruiterProfile = profiles.getByUserId(state, currentUser.id)

  const openJobRole = roles.findByCandidateId(state, reference.fields.candidate_id)

  const organization = orgs.getById(state, orgs.currentId(state))

  return {
    // candidate variables
    'CANDIDATE.FIRST_NAME': candidateProfile ? candidateProfiles.firstNameOf(candidateProfile) : '',
    'CANDIDATE.FULL_NAME': candidateProfile?.fields.full_name || '',
    'CANDIDATE.EMAIL': candidateProfile?.fields.email || '',
    'CANDIDATE.PHONE_NUMBER': candidateProfile?.fields.phone_number || '',

    // reference variables
    'REFERENCE.FIRST_NAME': references.firstNameOf(reference),
    'REFERENCE.FULL_NAME': reference.fields.full_name,
    'REFERENCE.EMAIL': reference.fields.email,
    'REFERENCE.COMPANY_NAME': reference.fields.company_name,
    'REFERENCE.RELATIONSHIP': references.relationshipOf(reference) || '',
    'REFERENCE.FROM': reference.fields.from,
    'REFERENCE.TO': reference.fields.to,
    'REFERENCE.PROFESSIONAL_NETWORK': reference.fields.linkedin,

    // recruiter variables
    'RECRUITER.FIRST_NAME': recruiterProfile ? profiles.firstNameOf(recruiterProfile) : '',
    'RECRUITER.FULL_NAME': recruiterProfile?.fields.full_name || '',
    'RECRUITER.EMAIL': recruiterUser?.fields.email || '',

    // job variables
    // uses the public name, so recruiters aren't concerned
    // their internal names are being made public.
    'ROLE.NAME': openJobRole?.fields.public_name || '',
    'ORGANIZATION.IMAGE_URL': organization.fields.logo_image_url || '',
    'ORGANIZATION.NAME': organization.fields.name || '',
    'ORGANIZATION.SLUG': organization.fields.slug || '',
  }
}

export function renderQuestionForReference(state: RootState, text: string, referenceId: string): string {
  return interpolateVariables(text, getVariablesByReferenceId(state, referenceId))
}
