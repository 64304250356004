import React from 'react'
import logoWordmark from '../../assets/logos/logo+wordmark.svg'
import logo from '../../assets/logos/logo.svg'
import wordmark from '../../assets/logos/wordmark.png'
import classes from './Logo.module.scss'

interface LogoProps extends React.HTMLAttributes<HTMLImageElement> {
  variant?: 'wordmark' | 'logo+wordmark' | 'logo-only'
}

export const Logo = ({ variant, className, ...rest }: LogoProps) => {
  return (
    <img
      className={[classes.logo, className].join(' ')}
      alt="Logo"
      {...rest}
      src={variant === 'wordmark' ? wordmark : variant === 'logo+wordmark' ? logoWordmark : logo}
    />
  )
}
