import Modal from 'components/Modal'
import { fr, px2rem, size, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React from 'react'
import { useSelector } from 'react-redux'
import { onboardingSummaries, questions } from 'selectors'
import { ONBOARDING_MODAL_DETAILS } from 'selectors/onboarding-summaries'
import { RootState } from 'store'

const TitleIcon = style()
  .inlineBlock()
  .spacing({ outerRight: px2rem(14) })
  .element('span')
const Title = text.cardHeader().element('h3')

const ModalHeader = style()
  .grid({ columns: [fr(1), size.auto, size.auto], align: 'center' })
  .spacing({ gap: px2rem(8), inner: [px2rem(18), px2rem(32)] })
  .border({
    bottom: `solid ${px2rem(1)}`,
    color: designSystemColors.borderDefault,
  })
  .element()

const DescriptionSection = style()
  .flex({ direction: 'column' })
  .spacing({ gap: px2rem(8) })
  .element()
const DescriptionTitle = text.bodyEmphasis().element('h4')
const Description = text.bodyText().element('p')

const FindOutMoreSection = style()
  .flex({ direction: 'column' })
  .spacing({ gap: px2rem(8) })
  .element()
const FindOutMoreTitle = text.bodyEmphasis().element('h4')

const Article = style()
  .border({
    around: `${px2rem(1)} solid`,
    color: designSystemColors.borderDefault,
    radius: px2rem(8),
  })
  .grid({ columns: [px2rem(88), size.auto] })
  .spacing({ inner: px2rem(4), gap: px2rem(8) })
  .sans({ nodecoration: true })
  .nooverflow()
  .element('a')
const ArticleContent = style()
  .flex({ direction: 'column', justifyContent: 'center' })
  .spacing({ gap: px2rem(8) })
  .element()
const ArticleTitle = text.bodyEmphasis().element('p')
const ArticleImage = style()
  .size({ width: size.fill, height: size.fill })
  .block()
  .round(px2rem(4))
  .set('objectFit', 'cover')
  .element('img')
const ArticleLink = text
  .smallBody()
  .nooverflow()
  .text({ nodecoration: true })
  .color({ fg: designSystemColors.typographySecondary })
  .element('p')
const ArticleLinkText = style().nooverflow().inlineBlock().text({ nodecoration: true }).element('span')

const ScoresSection = style()
  .flex({ direction: 'column' })
  .spacing({ gap: px2rem(8) })
  .element()
const ScoresTitle = text.bodyEmphasis().element('h4')
const ScoresTable = style()
  .block()
  .round(px2rem(4))
  .nooverflow()
  .border({
    around: `solid ${px2rem(1)}`,
    color: designSystemColors.borderDefault,
  })
  .element('table')
const ScoresTableHeader = style()
  .block()
  .color({ bg: designSystemColors.backgroundNeutralSecondaryHover })
  .element('thead')
const ScoresTableHeaderCell = text
  .smallBody()
  .sans({ align: 'left' })
  .color({ fg: designSystemColors.typographySecondary })
  .element('th')
const ScoresTableBody = style().block().element('tbody')
const ScoresTableBodyCell = text.label().set('fontVariantNumeric', 'tabular-nums').element('td')
const ScoresTableRow = style()
  .grid({ columns: [fr(2), fr(1), fr(1)] })
  .spacing({ inner: [px2rem(8), px2rem(12)] })
  .select('&:nth-child(even)', style().color({ bg: designSystemColors.backgroundNeutralSecondary }))
  .element('tr')
const RatingBase = text.smallBody().element('span')

const Column = style()
  .flex({ direction: 'column' })
  .spacing({ gap: px2rem(24) })
  .element()

const OnboardingAttributesDetailModalStyle = style()
  .grid({ columns: [fr(1), fr(1)] })
  .spacing({ gap: px2rem(20), inner: px2rem(32) })
  .size({ width: px2rem(640) })
  .element()

const NoScore = style().element()

const RATING_SCALE = 7
interface OnboardingAttributesDetailModalProps {
  close: () => void
  titleEmoji: string
  title: string
  description?: string
  scores: { manager?: number; report?: number; date: Date }[]
  article?: {
    title: string
    imageUrl: string
    imageAltText: string
    url: string
    urlText: string
  }
}

const OnboardingAttributesDetailModal: React.FC<OnboardingAttributesDetailModalProps> = ({
  close,
  title,
  titleEmoji,
  description,
  scores,
  article,
}) => {
  return (
    <Modal
      open={true}
      setOpen={close}
      renderHeader={({ closeButton, helpButton }) => (
        <ModalHeader>
          <Title>
            <TitleIcon>{titleEmoji}</TitleIcon>
            {title}
          </Title>
          {helpButton}
          {closeButton}
        </ModalHeader>
      )}
    >
      <OnboardingAttributesDetailModalStyle>
        <Column>
          {description && (
            <DescriptionSection>
              <DescriptionTitle>{'Description'}</DescriptionTitle>
              <Description>{description}</Description>
            </DescriptionSection>
          )}
          {article && (
            <FindOutMoreSection>
              <FindOutMoreTitle>{'Read more about the topic'}</FindOutMoreTitle>
              <Article href={article.url} target="_blank">
                <ArticleImage src={article.imageUrl} alt={article.imageAltText} />
                <ArticleContent>
                  <ArticleTitle>{article.title}</ArticleTitle>
                  <ArticleLink>
                    {'🔗  '}
                    <ArticleLinkText>{article.urlText}</ArticleLinkText>
                  </ArticleLink>
                </ArticleContent>
              </Article>
            </FindOutMoreSection>
          )}
        </Column>
        <Column>
          {scores.length > 0 && (
            <ScoresSection>
              <ScoresTitle>{'Scores overtime'}</ScoresTitle>
              <ScoresTable>
                <ScoresTableHeader>
                  <ScoresTableRow>
                    <ScoresTableHeaderCell>{'Survey date'}</ScoresTableHeaderCell>
                    <ScoresTableHeaderCell>{'Manager'}</ScoresTableHeaderCell>
                    <ScoresTableHeaderCell>{'Report'}</ScoresTableHeaderCell>
                  </ScoresTableRow>
                </ScoresTableHeader>
                <ScoresTableBody>
                  {scores.map((score, i) => (
                    <ScoresTableRow key={score.date + '-' + i}>
                      <ScoresTableBodyCell>{new Intl.DateTimeFormat().format(score.date)}</ScoresTableBodyCell>
                      <ScoresTableBodyCell>
                        {score.manager !== undefined ? (
                          <RatingBase>
                            {score.manager}
                            {` / ${RATING_SCALE}`}
                          </RatingBase>
                        ) : (
                          <NoScore>-</NoScore>
                        )}
                      </ScoresTableBodyCell>
                      <ScoresTableBodyCell>
                        {score.report !== undefined ? (
                          <RatingBase>
                            {score.report}
                            {` / ${RATING_SCALE}`}
                          </RatingBase>
                        ) : (
                          <NoScore>-</NoScore>
                        )}
                      </ScoresTableBodyCell>
                    </ScoresTableRow>
                  ))}
                </ScoresTableBody>
              </ScoresTable>
            </ScoresSection>
          )}
        </Column>
      </OnboardingAttributesDetailModalStyle>
    </Modal>
  )
}

interface ModalWrapperProps {
  employeeId: string
  questionId: string
  close: () => void
}

export const OnboardingAttributesDetailModalWrapper: React.FC<ModalWrapperProps> = ({
  questionId,
  close,
  employeeId,
}) => {
  const question = useSelector((state: RootState) => questions.findById(state, questionId))
  const selected = ONBOARDING_MODAL_DETAILS[question?.fields?.copy?.dashboard?.title || ''] || null
  const answers = useSelector((state: RootState) =>
    onboardingSummaries.findScoresByQuestionId(state, employeeId, questionId),
  )

  if (!selected) return <></>

  return (
    <OnboardingAttributesDetailModal
      titleEmoji={selected.emoji}
      title={selected.modalTitle}
      close={close}
      scores={
        answers?.map(item => ({
          manager: item.manager,
          report: item.employee,
          date: new Date(item.date / 1e6),
        })) || []
      }
      description={selected.description}
      article={{
        title: selected.title,
        imageUrl: selected.imageUrl,
        imageAltText: selected.imageAltText,
        url: selected.url,
        urlText: selected.urlText,
      }}
    />
  )
}

OnboardingAttributesDetailModalWrapper.displayName = 'OnboardingAttributesDetailModalWrapper'
export default OnboardingAttributesDetailModalWrapper
