import { px2rem, space, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React from 'react'

export type SelectedCandidatesCategory = 'mine' | 'org' | 'archived' | 'starred'

interface CandidatesCategorySelectorProps {
  selected: SelectedCandidatesCategory
  setSelected: (selected: SelectedCandidatesCategory) => void
  orgAdmin: boolean
}

const CandidatesCategorySelectorStyle = style()
  .flex()
  .spacing({ gap: px2rem(12) })
  .element()

const Category = style()
  .flex()
  .pointer()
  .round(px2rem(4))
  .size({ height: px2rem(32) })
  .spacing({ columns: px2rem(8), inner: [space.none, space.xxs] })
  .bg({ color: designSystemColors.backgroundNeutralSecondary })
  .cond(
    ({ isActive }: { isActive: boolean }) => isActive,
    style().bg({ color: designSystemColors.backgroundNeutralTertiary }),
  )
  .border({ around: '0' })
  .element('button')

const CategoryEmoji = text.bodyInteractive().spacing({ outer: space.auto }).center({ vertical: true }).element()

const CategoryTitle = text
  .bodyInteractive()
  .spacing({ outer: [space.auto, space.none] })
  .center({ vertical: true })
  .sans({ ellipsis: true })
  .element()

const CandidatesCategorySelector: React.FC<CandidatesCategorySelectorProps> = ({ selected, setSelected, orgAdmin }) => {
  return (
    <CandidatesCategorySelectorStyle>
      <Category isActive={selected === 'mine'} onClick={() => setSelected('mine')}>
        <CategoryEmoji>📂</CategoryEmoji>
        <CategoryTitle>My Candidates</CategoryTitle>
      </Category>
      {orgAdmin ? (
        <Category isActive={selected === 'org'} onClick={() => setSelected('org')}>
          <CategoryEmoji>🏢</CategoryEmoji>
          <CategoryTitle>All org Candidates</CategoryTitle>
        </Category>
      ) : (
        <></>
      )}
      <Category isActive={selected === 'archived'} onClick={() => setSelected('archived')}>
        <CategoryEmoji>🗑</CategoryEmoji>
        <CategoryTitle>Archived</CategoryTitle>
      </Category>
    </CandidatesCategorySelectorStyle>
  )
}

export default CandidatesCategorySelector
