import { listing } from 'api'
import * as domains from './domains'
import * as inventories from './inventories'
import { list, SortOrder } from './listing'
import { get as getEntity } from './methods'
import * as modules from './modules'
import { APIResponse, Entity } from './request'

export const RESOURCE = 'libraryitems'

export type LibraryItemEntityType = typeof modules.RESOURCE | typeof domains.RESOURCE | typeof inventories.RESOURCE

export type Fields = {
  id: string
  type: LibraryItemEntityType
  entity_id: string
  group_slug: string
  group_id: string
  name: string
  locale: string
  duration_seconds: number
  is_selectable: boolean
  overlapped_by?: string[]
}

export function get(
  filters: listing.Filter[],
  limit: number = 100,
  skip: number = 0,
  sortOrder: SortOrder = SortOrder.Asc,
  sortField: string = 'name',
): Promise<APIResponse<Entity<Fields>[]>> {
  return list(`library-items`, {
    include: ['modules', 'domains', 'inventories'],
    limit,
    skip,
    filters,
    sort: [
      {
        order: sortOrder,
        field: sortField,
      },
    ],
  })
}

interface TokenFields {
  token: string
}

export function getPreviewToken(itemID: string): Promise<APIResponse<Entity<TokenFields>>> {
  return getEntity<Entity<TokenFields>>(`library-items/` + itemID + `/preview`)
}
