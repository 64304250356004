import React from 'react'
import classes from './Banner.module.scss'

interface BannerProps extends React.HTMLAttributes<HTMLDivElement> {
  /** The content of the banner. Can be a string or a component */
  children: React.ReactNode | string
  variant?: 'success' | 'warning' | 'error' | 'ai' | 'gray' | 'light'
  /** Icon that can come from Font Awesome, any component or just a text string. */
  startIcon?: React.ReactNode | string
  endIcon?: React.ReactNode | string
}

/**
 * A banner component that can be used to display a message to the user. Non-clickable.
 * */
export const Banner = ({ children, variant, startIcon, endIcon, className, ...props }: BannerProps) => {
  return (
    <div {...props} className={[classes.banner, variant && classes[variant], className].join(' ')}>
      {startIcon && <div>{startIcon}</div>}
      {children}
      {endIcon && <div className={classes.endIcon}>{endIcon}</div>}
    </div>
  )
}
