import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import StepEmail from './StepEmail'
import StepPassword from './StepPassword'
import StepSSO from './StepSSO'

interface LoginFormProps {
  onGoogleSignIn: () => void
}

const Component: React.FC<LoginFormProps> = values => {
  const { ssoLoginURL, email } = useSelector((state: RootState) => state.login)

  if (ssoLoginURL) {
    return <StepSSO />
  }

  if (email) {
    return <StepPassword onGoogleSignIn={() => values.onGoogleSignIn()} />
  }

  return <StepEmail onGoogleSignIn={() => values.onGoogleSignIn()} />
}

export default Component
Component.displayName = 'LoginForm'
