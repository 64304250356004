import { RootState } from '../../store'
import * as responses from '../form-responses'
import { Chart, Row } from './chart'

export function sliderLineChart(state: RootState, candidateId: string, contentId: string): Chart {
  const chart: Chart = {
    data: [],
    options: {
      lineChart: {
        syncId: contentId,
        lines: ['value'],
        tooltipFormatter,
        x: {
          key: 'year',
        },
        y: {
          key: 'value',
          domain: [0, 10],
        },
      },
    },
  }

  const answersByYear = responses.groupAnswersByRelationshipYear(state, candidateId, contentId)

  for (const year in answersByYear) {
    chart.data.push({
      year,
      value: Math.round(
        answersByYear[year].map(a => a.value || 0).reduce((a, b) => a + b, 0) / answersByYear[year].length,
      ),
    })
  }

  chart.data = chart.data.sort(sortDataByYear)

  return chart

  function tooltipFormatter(value: string | number | Array<string | number>) {
    return [`${value} / 10`]
  }
}

function sortDataByYear(a: Row, b: Row): number {
  if (!a || !b || a.year === undefined || b.year === undefined) return 0

  if (a.year > b.year) {
    return 1
  }

  if (a.year < b.year) {
    return -1
  }

  return 0
}
