import { px2rem, size, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React from 'react'

const CandidateLabel = text
  .label()
  .color({
    fg: designSystemColors.informationRedPrimary,
    bg: designSystemColors.informationRedBackground,
  })
  .flex({ alignItems: 'center', justifyContent: 'center' })
  .size({ height: px2rem(20), width: px2rem(20) })
  .border({ radius: px2rem(10) })
  .element('span')
const QuadrantLabel = text
  .smallBody()
  .relative()
  .back()
  .flex({ alignItems: 'center' })
  .size({ height: px2rem(26) })
  .spacing({ inner: [px2rem(0), px2rem(4)] })
  .border({
    radius: px2rem(4),
    color: designSystemColors.borderDefault,
    around: `solid ${px2rem(1)}`,
  })
  .sans({ noWrap: true })
  .color({
    fg: designSystemColors.typographyPrimary,
    bg: designSystemColors.backgroundNeutralPrimary,
  })
  .pointer()
  .select(`${CandidateLabel}`, style().spacing({ outerLeft: px2rem(4) }))
  .element('p')

const TooltipTitle = text.label().color({ fg: designSystemColors.white }).element('h4')
const TooltipText = text.smallBody().color({ fg: designSystemColors.white }).element('p')
const Tooltip = style()
  .block()
  .relative()
  .size({ width: px2rem(120) })
  .spacing({ inner: px2rem(8) })
  .border({
    radius: px2rem(4),
  })
  .color({
    fg: designSystemColors.white,
    bg: designSystemColors.backgroundNeutralPrimaryInverted,
  })
  .select(`${TooltipTitle}`, style().spacing({ outerBottom: px2rem(6) }))
  .element()

const QuadrantSection = style()
  .select(
    `${Tooltip}`,
    style()
      .spacing({ outer: [px2rem(6), px2rem(0)] })
      .front(),
  )
  .flex({ direction: 'column' })
  .element()
const TopRightQuadrantSection = style(QuadrantSection).element()
const TopLeftQuadrantSection = style(QuadrantSection).element()
const BottomLeftQuadrantSection = style(QuadrantSection).element()
const BottomRightQuadrantSection = style(QuadrantSection).element()

const CartesianQuadrant = style()
  .relative()
  .select(
    `${TopRightQuadrantSection}`,
    style()
      .absolute({ top: px2rem(4), right: px2rem(4) })
      .flex({ alignItems: 'flex-end' }),
  )
  .select(
    `${TopLeftQuadrantSection}`,
    style()
      .absolute({ top: px2rem(4), left: px2rem(4) })
      .flex({ alignItems: 'flex-start' }),
  )
  .select(
    `${BottomLeftQuadrantSection}`,
    style()
      .absolute({ bottom: px2rem(4), left: px2rem(4) })
      .flex({ alignItems: 'flex-start' }),
  )
  .select(
    `${BottomRightQuadrantSection}`,
    style()
      .absolute({ bottom: px2rem(4), right: px2rem(4) })
      .flex({ alignItems: 'flex-end' }),
  )
  .element()
const FirstCartesianQuadrant = style(CartesianQuadrant).element()
const SecondCartesianQuadrant = style(CartesianQuadrant).element()
const ThirdCartesianQuadrant = style(CartesianQuadrant).element()
const FourthCartesianQuadrant = style(CartesianQuadrant).element()

const Overlay = style()
  .grid({
    columns: 'repeat(2, 50%)',
    rows: 'repeat(2, 50%)',
  })
  .set('gridTemplateAreas', '"second first" "third forth"')
  .select(`${FirstCartesianQuadrant}`, style().set('gridArea', 'first'))
  .select(`${SecondCartesianQuadrant}`, style().set('gridArea', 'second'))
  .select(`${ThirdCartesianQuadrant}`, style().set('gridArea', 'third'))
  .select(`${FourthCartesianQuadrant}`, style().set('gridArea', 'forth'))
  .front()
  .set('pointerEvents', 'none')
  .element()

const ChartLine = style()
  .set('transformOrigin', 'center')
  .with(({ rotationAngle }: { rotationAngle: number }) => style().set('transform', `rotate(${rotationAngle}deg)`))
  .element('line')
const ChartPolygon = style()
  .set('transformOrigin', 'center')
  .with(({ rotationAngle }: { rotationAngle: number }) => style().set('transform', `rotate(${rotationAngle}deg)`))
  .element('polygon')
const Chart = style()
  .nooverflow()
  .color({ bg: designSystemColors.backgroundNeutralSecondary })
  .border({ radius: px2rem(8) })
  .size({ width: size.fill, height: size.fill })
  .element('svg')
const CartesianChartBlockStyle = style()
  .relative()
  .select(`${Overlay}`, style().absolute({ top: 0, left: 0, right: 0, bottom: 0 }))
  .element()

type Point = {
  x: number
  y: number
}

type PolygonPoints = [Point, Point, Point]

const CHART_WIDTH = 212
const CHART_HEIGHT = 212

const ORIGIN: Point = {
  x: CHART_WIDTH / 2,
  y: CHART_HEIGHT / 2,
}

// Assume chart is SQUARE
const LENGTH_OF_DIAGONAL_LINE = CHART_WIDTH * Math.sqrt(2)

function getColorSetForPolygon(hintColor: HintColor): {
  fill: string
  stroke: string
} {
  switch (hintColor) {
    case 'purple': {
      return {
        fill: designSystemColors.informationPurpleBackground,
        stroke: designSystemColors.informationPurplePrimary,
      }
    }
    case 'orange': {
      return {
        fill: designSystemColors.informationOrangeBackground,
        stroke: designSystemColors.informationOrangePrimary,
      }
    }
  }
}

type HintColor = 'purple' | 'orange'
type AxisLabel = { text: string; hasCandidateLabel?: boolean }
type LabelTooltip = {
  title: string
  text: string
}
type AxisLabels = {
  firstQuadrantLabel1?: AxisLabel
  firstQuadrantTooltip1?: LabelTooltip
  firstQuadrantLabel2?: AxisLabel
  firstQuadrantTooltip2?: LabelTooltip
  secondQuadrantLabel1?: AxisLabel
  secondQuadrantTooltip1?: LabelTooltip
  secondQuadrantLabel2?: AxisLabel
  secondQuadrantTooltip2?: LabelTooltip
  thirdQuadrantLabel1?: AxisLabel
  thirdQuadrantTooltip1?: LabelTooltip
  thirdQuadrantLabel2?: AxisLabel
  thirdQuadrantTooltip2?: LabelTooltip
  fourthQuadrantLabel1?: AxisLabel
  fourthQuadrantTooltip1?: LabelTooltip
  fourthQuadrantLabel2?: AxisLabel
  fourthQuadrantTooltip2?: LabelTooltip
}
interface CartesianChartBlockProps extends AxisLabels {
  numberOfAreas: number
  selectedAreaIndex: number
  hintColor: HintColor
}

const transparentColor = designSystemColors.transparent

export const CartesianChartBlock: React.FC<CartesianChartBlockProps> = ({
  numberOfAreas,
  selectedAreaIndex,
  hintColor,
  firstQuadrantLabel1,
  firstQuadrantTooltip1,
  firstQuadrantLabel2,
  firstQuadrantTooltip2,
  secondQuadrantLabel1,
  secondQuadrantTooltip1,
  secondQuadrantLabel2,
  secondQuadrantTooltip2,
  thirdQuadrantLabel1,
  thirdQuadrantTooltip1,
  thirdQuadrantLabel2,
  thirdQuadrantTooltip2,
  fourthQuadrantLabel1,
  fourthQuadrantTooltip1,
  fourthQuadrantLabel2,
  fourthQuadrantTooltip2,
}) => {
  const [hoveredAreaIndex, setHoveredAreaIndex] = React.useState<number | null>(null)
  if (selectedAreaIndex >= numberOfAreas) {
    throw new Error(
      'Invalid selectedAreaIndex for CartesianChartBlock. selectedAreaIndex cannot be equal or more than numberOfAreas',
    )
  }
  if (numberOfAreas % 2 !== 0) {
    throw new Error('Invalid numberOfAreas for CartesianChartBlock. numberOfAreas cannot be an odd number')
  }

  const numberOfLines = numberOfAreas / 2
  const degreeBetweenLines = 360 / numberOfAreas
  const radianBetweenLines = (degreeBetweenLines * 2 * Math.PI) / 360
  const polygonPoints: PolygonPoints = [
    { x: ORIGIN.x, y: ORIGIN.y },
    { x: ORIGIN.x + CHART_WIDTH, y: ORIGIN.y },
    {
      x: ORIGIN.x + CHART_WIDTH * Math.cos(radianBetweenLines),
      y: ORIGIN.y - CHART_WIDTH * Math.sin(radianBetweenLines),
    },
  ]

  const colorSetForPolygon = getColorSetForPolygon(hintColor)

  return (
    <CartesianChartBlockStyle>
      <Chart
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${CHART_WIDTH} ${CHART_HEIGHT}`}
        preserveAspectRatio="none"
        onMouseLeave={() => setHoveredAreaIndex(null)}
      >
        {[...new Array(numberOfLines)].map((_, i) => (
          <ChartLine
            key={`line-${i}`}
            x1={-(LENGTH_OF_DIAGONAL_LINE - CHART_WIDTH) / 2}
            x2={CHART_WIDTH + (LENGTH_OF_DIAGONAL_LINE - CHART_WIDTH) / 2}
            y1={ORIGIN.y}
            y2={ORIGIN.y}
            stroke={designSystemColors.typographySecondary}
            strokeWidth=".5"
            rotationAngle={i * degreeBetweenLines}
          />
        ))}
        {[...new Array(numberOfAreas)].map((_, i) => (
          <ChartPolygon
            key={`polygon-${i}`}
            fill={selectedAreaIndex === i ? colorSetForPolygon.fill : transparentColor}
            stroke={selectedAreaIndex === i ? colorSetForPolygon.stroke : transparentColor}
            rotationAngle={-1 * i * degreeBetweenLines}
            points={polygonPoints.map(({ x, y }) => `${x} ${y}`).join(', ')}
            onMouseEnter={() => setHoveredAreaIndex(i)}
          />
        ))}
      </Chart>
      <Overlay>
        <FirstCartesianQuadrant>
          {firstQuadrantLabel1 && (
            <BottomRightQuadrantSection>
              {firstQuadrantTooltip1 && hoveredAreaIndex === 0 && (
                <Tooltip>
                  <TooltipTitle>{firstQuadrantTooltip1.title}</TooltipTitle>
                  <TooltipText>{firstQuadrantTooltip1.text}</TooltipText>
                </Tooltip>
              )}
              <QuadrantLabel>
                {firstQuadrantLabel1.text}
                {firstQuadrantLabel1.hasCandidateLabel && <CandidateLabel>{'C'}</CandidateLabel>}
              </QuadrantLabel>
            </BottomRightQuadrantSection>
          )}
          {firstQuadrantLabel2 && (
            <TopLeftQuadrantSection>
              <QuadrantLabel>
                {firstQuadrantLabel2.text}
                {firstQuadrantLabel2.hasCandidateLabel && <CandidateLabel>{'C'}</CandidateLabel>}
              </QuadrantLabel>
              {firstQuadrantTooltip2 && hoveredAreaIndex === (numberOfAreas === 8 ? 1 : 0) && (
                <Tooltip>
                  <TooltipTitle>{firstQuadrantTooltip2.title}</TooltipTitle>
                  <TooltipText>{firstQuadrantTooltip2.text}</TooltipText>
                </Tooltip>
              )}
            </TopLeftQuadrantSection>
          )}
        </FirstCartesianQuadrant>
        <SecondCartesianQuadrant>
          {secondQuadrantLabel1 && (
            <TopRightQuadrantSection>
              <QuadrantLabel>
                {secondQuadrantLabel1.text}
                {secondQuadrantLabel1.hasCandidateLabel && <CandidateLabel>{'C'}</CandidateLabel>}
              </QuadrantLabel>
              {secondQuadrantTooltip1 && hoveredAreaIndex === (numberOfAreas === 8 ? 2 : 1) && (
                <Tooltip>
                  <TooltipTitle>{secondQuadrantTooltip1.title}</TooltipTitle>
                  <TooltipText>{secondQuadrantTooltip1.text}</TooltipText>
                </Tooltip>
              )}
            </TopRightQuadrantSection>
          )}
          {secondQuadrantLabel2 && (
            <BottomLeftQuadrantSection>
              {secondQuadrantTooltip2 && hoveredAreaIndex === (numberOfAreas === 8 ? 3 : 1) && (
                <Tooltip>
                  <TooltipTitle>{secondQuadrantTooltip2.title}</TooltipTitle>
                  <TooltipText>{secondQuadrantTooltip2.text}</TooltipText>
                </Tooltip>
              )}
              <QuadrantLabel>
                {secondQuadrantLabel2.text}
                {secondQuadrantLabel2.hasCandidateLabel && <CandidateLabel>{'C'}</CandidateLabel>}
              </QuadrantLabel>
            </BottomLeftQuadrantSection>
          )}
        </SecondCartesianQuadrant>
        <ThirdCartesianQuadrant>
          {thirdQuadrantLabel1 && (
            <TopLeftQuadrantSection>
              <QuadrantLabel>
                {thirdQuadrantLabel1.text}
                {thirdQuadrantLabel1.hasCandidateLabel && <CandidateLabel>{'C'}</CandidateLabel>}
              </QuadrantLabel>
              {thirdQuadrantTooltip1 && hoveredAreaIndex === (numberOfAreas === 8 ? 4 : 2) && (
                <Tooltip>
                  <TooltipTitle>{thirdQuadrantTooltip1.title}</TooltipTitle>
                  <TooltipText>{thirdQuadrantTooltip1.text}</TooltipText>
                </Tooltip>
              )}
            </TopLeftQuadrantSection>
          )}
          {thirdQuadrantLabel2 && (
            <BottomRightQuadrantSection>
              {thirdQuadrantTooltip2 && hoveredAreaIndex === (numberOfAreas === 8 ? 5 : 2) && (
                <Tooltip>
                  <TooltipTitle>{thirdQuadrantTooltip2.title}</TooltipTitle>
                  <TooltipText>{thirdQuadrantTooltip2.text}</TooltipText>
                </Tooltip>
              )}
              <QuadrantLabel>
                {thirdQuadrantLabel2.text}
                {thirdQuadrantLabel2.hasCandidateLabel && <CandidateLabel>{'C'}</CandidateLabel>}
              </QuadrantLabel>
            </BottomRightQuadrantSection>
          )}
        </ThirdCartesianQuadrant>
        <FourthCartesianQuadrant>
          {fourthQuadrantLabel1 && (
            <BottomLeftQuadrantSection>
              {fourthQuadrantTooltip1 && hoveredAreaIndex === (numberOfAreas === 8 ? 6 : 3) && (
                <Tooltip>
                  <TooltipTitle>{fourthQuadrantTooltip1.title}</TooltipTitle>
                  <TooltipText>{fourthQuadrantTooltip1.text}</TooltipText>
                </Tooltip>
              )}
              <QuadrantLabel>
                {fourthQuadrantLabel1.text}
                {fourthQuadrantLabel1.hasCandidateLabel && <CandidateLabel>{'C'}</CandidateLabel>}
              </QuadrantLabel>
            </BottomLeftQuadrantSection>
          )}
          {fourthQuadrantLabel2 && (
            <TopRightQuadrantSection>
              <QuadrantLabel>
                {fourthQuadrantLabel2.text}
                {fourthQuadrantLabel2.hasCandidateLabel && <CandidateLabel>{'C'}</CandidateLabel>}
              </QuadrantLabel>
              {fourthQuadrantTooltip2 && hoveredAreaIndex === (numberOfAreas === 8 ? 7 : 3) && (
                <Tooltip>
                  <TooltipTitle>{fourthQuadrantTooltip2.title}</TooltipTitle>
                  <TooltipText>{fourthQuadrantTooltip2.text}</TooltipText>
                </Tooltip>
              )}
            </TopRightQuadrantSection>
          )}
        </FourthCartesianQuadrant>
      </Overlay>
    </CartesianChartBlockStyle>
  )
}
