import { PageHeader, TopBar } from '@common/components'
import { UpgradeButton } from 'App/ProductPages/Shared'
import Button from 'components/Button/ButtonV2'
import { Icon } from 'components/Icons'
import { copyToClipboard } from 'core/utils'
import { useUser } from 'providers/users'
import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useDebounce } from 'react-use'
import { RootState } from 'store'
import { load } from 'store/talent-pool'
import ContentGrid, { Caption, Column, Row } from '../../../components/ContentGrid'
import DashboardLayout, { Content } from '../../../components/DashboardLayout'
import TextField from '../../../components/TextField'
import { colors, rem, size, style } from '../../../core'
import * as tracking from '../../../core/track'
import * as selectors from '../../../selectors'
import { add as notify } from '../../../store/notifications'
import EmptyTalentPoolPage from '../../ProductPages/EmptyTalentPoolPage'
import { SearchRow, Total } from './ManageCandidates'
import NameAndNetwork from './NameAndNetwork'
import { TalentPoolLoadingRow } from './TalentPoolLoadingRow'

const UserRow = Row([rem(0.5), rem(2), size.auto, rem(10), rem(8), size.auto, rem(8), rem(4), rem(2)])

export const IconColumn = style()
  .flex({ alignItems: 'center', justifyContent: 'center' })
  .round('100%')
  .color({ fg: colors.moonGray })
  .element()

const Email = style().element()

const SCROLLABLE_TARGET = 'manage-talent-pool-scroll-target'

const ManageTalentPool = () => {
  const dispatch = useDispatch()

  const totalReferences = useSelector((state: RootState) => state.talentPool.totalReferences)
  const hasMore = useSelector((state: RootState) => state.talentPool.hasMore)
  const references = useSelector((state: RootState) =>
    state.talentPool.references.map(id => {
      const reference = selectors.references.findById(state, id)
      if (!reference) return {}
      return {
        reference,
        referenceProfessionalNetwork: selectors.references.getProfessionalNetworkForDisplay(state, reference),
        candidate: selectors.candidates.findById(state, reference.fields.candidate_id),
        candidateProfile: selectors.candidateProfiles.findByCandidateId(state, reference.fields.candidate_id),
        role: selectors.roles.findByCandidateId(state, reference.fields.candidate_id),
        verificationStatus: selectors.references.getVerificationStatus(state, id),
        relationship: selectors.references.relationshipOf(reference),
        organization: selectors.orgs.findByCandidateId(state, reference.fields.candidate_id),
      }
    }),
  )

  const loading = useSelector((state: RootState) => state.talentPool.loading)
  const org = useSelector((state: RootState) => selectors.orgs.current(state))

  const [query, setQuery] = useState('')

  useDebounce(() => dispatch(load(query, org.id)), 400, [query])

  const [queryWasChanged, setQueryWasChanged] = useState(false)
  useEffect(() => {
    if (query.length > 0) {
      setQueryWasChanged(true)
    }
  }, [query])

  const user = useUser()

  const canCreateRole = useSelector((state: RootState) => selectors.users.canCreateRole(state, user.id))

  const [loadingMore, setLoadingMore] = useState(false)

  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: '200px',
  })

  useEffect(() => {
    if (inView && hasMore && !loadingMore) {
      loadMoreReferences()
    }
  }, [inView, hasMore, loadingMore])

  if (org.fields.plan !== 'normal') {
    return <EmptyTalentPoolPage button={<UpgradeButton orgId={org.id} product={'talent-pool'} />} />
  }

  return (
    <>
      {!queryWasChanged && references.length === 0 && (
        <EmptyTalentPoolPage
          button={
            <Button
              as="Link"
              to={
                canCreateRole ? { pathname: '/roles/new', state: { from: 'talent-pool' } } : { pathname: '/talentpool' }
              }
              buttonType="accent"
              onClick={() => tracking.talentPool.sidebarCreateReferenceCheckClicked()}
            >
              Start with Reference Check
            </Button>
          }
        />
      )}
      {(queryWasChanged || references.length > 0) && (
        <DashboardLayout>
          <TopBar tracking={tracking} />
          <Content id={SCROLLABLE_TARGET}>
            <PageHeader.Root>
              <PageHeader.Title>Talent Pool</PageHeader.Title>
            </PageHeader.Root>
            <SearchRow>
              <TextField
                value={query}
                placeholder="Search by ID, email, or name"
                onChange={e => setQuery(e.target.value)}
              />
              {!loading ? <Total> {totalReferences} references</Total> : null}
            </SearchRow>
            <ContentGrid>
              <UserRow>
                <Column head></Column>
                <Column head></Column>
                <Column head>Name</Column>
                <Column head>Company</Column>
                <Column head>Worked From</Column>
                <Column head>Reference Of</Column>
                <Column head>Relationship</Column>
                <Column head>Contact</Column>
                <Column head></Column>
                {references.map((row, i) => (
                  <React.Fragment key={`row-${i}`}>
                    <Column />
                    <Column centerVert>
                      <IconColumn verified={row.reference?.fields.is_verified}>
                        <Icon name={row.verificationStatus?.icon || ''} />
                      </IconColumn>
                    </Column>
                    <Column centerVert highlight>
                      <NameAndNetwork
                        name={row.reference?.fields.full_name || ''}
                        link={`/roles/${row.role?.fields.id}/candidates/${row.candidate?.fields.id}/references/${row.reference?.fields.id}`}
                        network={row.referenceProfessionalNetwork}
                      />
                    </Column>
                    <Column centerVert className="data-hj-suppress">
                      {row.reference?.fields.company_name}
                    </Column>
                    <Column centerVert>
                      {row.reference?.fields.from} – {row.reference?.fields.to}
                    </Column>
                    <Column centerVert highlight>
                      <Link
                        to={`/roles/${row.candidate?.fields.open_job_role_id}/candidates/${row.candidate?.fields.id}`}
                        className="data-hj-suppress"
                      >
                        {row.candidateProfile?.fields.full_name}
                        <Caption>{row.role?.fields.name}</Caption>
                      </Link>
                    </Column>
                    <Column centerVert>{row.relationship}</Column>
                    <Column center highlight>
                      <Email
                        onClick={() => {
                          copy(row?.reference?.fields.full_name || '', row.reference?.fields.talent_pool_email || '')
                          tracking.talentPool.emailClicked()
                        }}
                      >
                        <IconColumn>
                          <Icon name={'envelope'} />
                        </IconColumn>
                      </Email>
                    </Column>
                    <Column />
                  </React.Fragment>
                ))}
                {references.length === 0 && !loading ? (
                  <>
                    <Column />
                    <Column>
                      <IconColumn>
                        <Icon name={'times-circle'} />
                      </IconColumn>
                    </Column>
                    <Column>Nothing found 🤕</Column>
                    <Column />
                    <Column />
                    <Column />
                    <Column />
                    <Column />
                    <Column />
                    <Column />
                  </>
                ) : null}
                {loadingMore && (
                  <>
                    <TalentPoolLoadingRow />
                    <TalentPoolLoadingRow />
                    <TalentPoolLoadingRow />
                  </>
                )}
                {hasMore && <div ref={ref} />}
              </UserRow>
            </ContentGrid>
          </Content>
        </DashboardLayout>
      )}
    </>
  )

  async function loadMoreReferences() {
    setLoadingMore(true)
    await dispatch(load(query, org.id, references.length))
    setLoadingMore(false)
  }

  function copy(referenceName: string, text: string) {
    copyToClipboard(text)
    dispatch(
      notify({
        success: `${selectors.possessiveFirstName(referenceName)} contact email has been copied to your clipboard!`,
      }),
    )
  }
}

export default ManageTalentPool
