import React from 'react'
import classes from './LoadingText.module.scss'

interface Props {
  text: string
  color?: string
}

export const LoadingText = (props: Props) => {
  return (
    <div className={classes.wrapper}>
      <span style={{ color: props.color }}>{props.text}</span>

      <div className={classes.dots}>
        <div className={classes.dot} style={{ backgroundColor: props.color }} />
        <div className={classes.dot} style={{ backgroundColor: props.color }} />
        <div className={classes.dot} style={{ backgroundColor: props.color }} />
      </div>
    </div>
  )
}
