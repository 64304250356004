import * as api from 'api'
import { renderOptions, SortOrder } from './listing'
import { get, post, put } from './methods'
import { ModuleType } from './modules'
import { QuestionResponseType } from './questions'
import { APIResponse, Entity } from './request'

export const RESOURCE = 'presets'

export type Product = 'reference-check' | 'self-assessment' | 'onboarding' | 'candidate-experience'

export const templateCustomQuestionResponseTypes = {
  'text-input': 'text-input',
  optionset: 'optionset',
  chips: 'chips',
  video: 'video',
} as const

export type PresetCustomQuestionResponseType = Extract<
  QuestionResponseType,
  keyof typeof templateCustomQuestionResponseTypes
>

export type LibraryItemConfig = {
  questions: { [questionSlug: string]: string[] }
}

export type TemplateContentItem = {
  module_slug?: string
  module_type?: ModuleType
  library_item_id?: string
  library_item_config?: LibraryItemConfig
  skill_id?: string
  question_slug?: string
  is_required?: boolean
  custom_question_response_type?: PresetCustomQuestionResponseType
  custom_question_heading?: string
  custom_question_options?: string[]
}

export type TemplateContent = TemplateContentItem[]

export type TemplatePayload = {
  content: TemplateContent
  copy: Record<string, string>
  is_private: boolean
  is_highlighted: boolean // if true, the template will show as the organization's template, if false it will be a hipeople template
  product: Product
  keywords?: string // keywords is what the template library uses to search for templates
}

export interface Fields {
  id: string
  organization_id: string
  slug: string
  product: Product
  copy: Record<string, string>
  content: TemplateContent
  is_private: boolean
  is_highlighted: boolean
  created_at: number
  updated_at: number
  is_active: boolean
}

export async function list(
  organizationId: string,
  product: Product,
  filterString: string,
  locale: string,
): Promise<APIResponse<Entity<Fields>[]>> {
  const filters: api.listing.Filter[] = [api.listing.Eq('product', product)]
  if (filterString) {
    filters.push(api.listing.Includes('keywords', filterString))
  }
  const options: api.listing.Options = {
    filters,
    limit: 500,
    include: ['skills', 'libraryitems', 'domains', 'inventories', 'modules'],
    sort: [
      { field: 'is_highlighted', order: SortOrder.Desc },
      { field: 'keywords', order: SortOrder.Asc },
    ],
  }
  return get<Entity<Fields>[]>(`organizations/${organizationId}/presets?locale=${locale}&${renderOptions(options)}`)
}

export async function getById(organizationId: string, templateId: string) {
  const options: api.listing.Options = {
    limit: 500,
    include: ['skills', 'libraryitems', 'domains', 'inventories', 'modules'],
    sort: [
      { field: 'is_highlighted', order: SortOrder.Desc },
      { field: 'keywords', order: SortOrder.Asc },
    ],
  }

  return get<Entity<Fields>[]>(`organizations/${organizationId}/presets?id[id]=${templateId}&${renderOptions(options)}`)
}

export async function create(organizationId: string, preset: TemplatePayload) {
  return post<Entity<Fields>>(`organizations/${organizationId}/presets`, preset)
}

/**
 * switching it to private will hide the template from the template library,
 * but will still be available for any role that's currently using it
 */
export async function setPrivate({
  organizationId,
  templateId,
  isPrivate,
}: {
  organizationId: string
  templateId: string
  isPrivate: boolean
}) {
  return put<Entity<Fields>>(`organizations/${organizationId}/presets/${templateId}`, {
    updates: {
      is_private: isPrivate,
    },
    updated: ['is_private'],
  })
}

export interface UpdateTemplatePayload<U extends Partial<TemplatePayload>> {
  updates: U
  updated: (keyof U)[] // this is a type guard to ensure that you provide the all the keys in the template payload
}

export async function updateTemplate<U extends Partial<TemplatePayload>>(
  organizationId: string,
  templateId: string,
  payload: UpdateTemplatePayload<U>,
): Promise<APIResponse<Entity<Fields>>> {
  return put<Entity<Fields>>(`organizations/${organizationId}/presets/${templateId}`, payload)
}
