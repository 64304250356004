import { ScoreResponse } from '@common/api/score'
import { ScoreGroup } from '@common/components'
import { Icon } from 'components/Icons'
import React from 'react'
import { useSelector } from 'react-redux'
import * as selectors from 'selectors'
import { AssessmentModuleAnswers } from 'selectors/self-assessment'
import { RootState } from 'store'
import { Group } from './AssessmentPage/AssessmentPage'

interface CandidateScoreCardProps {
  candidateFirstName: string
  candidateId: string
  roleId: string
  candidateExpectationsScores?: ScoreResponse['meta'][number] | undefined
  candidateExpectationsAnswers: AssessmentModuleAnswers[] | undefined
  defaultCollapsed?: boolean
  isSharedResults?: boolean
  onResponsesClick?: (moduleSlug: string, group: Group) => void
}

const CandidateScoreCard: React.FC<CandidateScoreCardProps> = props => {
  const group = useSelector((state: RootState) => {
    const group_redux = selectors.groups.findBySlug(state, props.candidateExpectationsScores?.slug || '')
    return {
      id: group_redux?.id || '',
      name: props.candidateExpectationsScores?.name || group_redux?.fields?.copy?.title || '',
      slug: group_redux?.fields?.slug || '',
      copy: {
        title: group_redux?.fields?.copy?.title || '',
        icon: group_redux?.fields?.copy?.icon ? <Icon name={group_redux?.fields?.copy?.icon} /> : '🛠',
        emoji: group_redux?.fields?.copy?.emoji,
        theme: group_redux?.fields?.copy?.theme || '',
        description: group_redux?.fields?.copy?.short_title || '',
      },
    }
  })

  const getModuleData = (score: ScoreResponse['meta'][number]) => {
    const module = props.candidateExpectationsAnswers?.find(m => m.module?.slug === score.slug)
    return {
      description: module?.module?.copy?.description,
      locale: module?.module?.locale || 'en_US',
      pairwise: {
        startCaption: module?.module?.copy?.score_card?.high_caption || '',
        endCaption: module?.module?.copy?.score_card?.low_caption || '',
      },
      isSharedResults: props.isSharedResults,
    }
  }
  return (
    <ScoreGroup
      score={props.candidateExpectationsScores}
      group={group}
      candidateFirstName={props.candidateFirstName}
      onClickResponses={props.onResponsesClick}
      getModuleData={getModuleData}
      defaultCollapsed={!props.isSharedResults}
    />
  )
}

export default CandidateScoreCard
