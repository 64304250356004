import Rows from './rows'

export interface ITransition {
  fn: string
  duration: number
  prop?: string
  delay?: string
}

export default function setTransitions(rows: Rows, transitions: ITransition[]) {
  setTransition(rows, transitions.map(define).join(', '))
}

function setTransition(rows: Rows, definition: string) {
  rows.set('WebkitTransition', definition)
  rows.set('MozTransition', definition)
  rows.set('OTransition', definition)
  rows.set('transition', definition)
}

function define(options: ITransition): string {
  return `${options.prop || ''} ${options.duration}s ${options.fn} ${options.delay || ''}`.trim()
}
