import { Locale } from '@common/models/locales'
import * as api from 'api'
import { listing } from 'api'
import * as libraryItems from 'api/library-items'
import { LibraryItemConfig, PresetCustomQuestionResponseType } from 'api/templates'
import { QuestionResponseType } from 'api/questions'
import { Entity } from 'api/request'
import { LibraryGroup } from 'App/RoleCreation/TemplateCreationAssessmentV2/ContentSelection/Modal'
import { BadgeTheme } from 'components/Badge'
import useQuery from 'hooks/useQuery'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDebounce, useMount, useSet } from 'react-use'
import * as selectors from 'selectors'
import { RootState } from 'store'
import { add as notify } from 'store/notifications'
import { addEntities } from 'store/resources'

export type CustomQuestionData = {
  responseType: PresetCustomQuestionResponseType
  questionText: string
  isRequired: boolean
  options?: string[]
}

export function useLibrary(filter: LibraryFilter, limit: number = 0) {
  const dispatch = useDispatch()

  const [groupsResponse, errors] = useQuery(api.groups.list)

  useEffect(() => {
    if (errors) {
      for (const e of errors) dispatch(notify({ error: e.message }))
    }
  }, [errors])

  const [matchingLibraryItems, setMatchingLibraryItems] = useState<string[]>([])
  const load = async () => {
    const filters: listing.Filter[] = []
    if (filter.query) filters.push(listing.Includes('keywords', filter.query))
    if (filter.group) filters.push(listing.Eq('group_slug', filter.group.slug))
    // Locale always defaults to 'en_US'
    filters.push(listing.Eq('locale', filter.locale || 'en_US'))

    const [resp, errors] = await libraryItems.get(filters, limit)
    if (errors) return

    if (!resp?.result) return
    dispatch(addEntities(resp))
    setMatchingLibraryItems(resp.result.map(r => r.id))
  }

  // load both when we first render
  useMount(async () => {
    await load()
  })
  // and also when the query or selected group changes
  useDebounce(load, 100, [filter.query, filter.group?.slug, filter.locale, limit])

  const libraryItemIDs = useSelector(selectors.libraryItems.allIDs)

  const libraryItemMap = useSelector((state: RootState) => {
    return libraryItemIDs.reduce((map, id) => {
      const expanded = expandLibraryItem(state, id)
      return expanded ? { ...map, [expanded.id]: expanded } : map
    }, {})
  })

  const libraryItemFromId = useMemo(
    () =>
      (id: string): LibraryItemData | undefined =>
        libraryItemMap[id],
    [libraryItemMap],
  )

  return {
    groups: groupsResponse?.result || [],
    matchingLibraryItems,
    libraryItemFromId,
  } as const
}

export type LibrarySelection = {
  libraryItems: {
    set: Set<string>
    has: (item: string) => boolean
    remove: (item: string) => void
    replace: (items: string[]) => void
    reset: () => void
  }

  customQuestions: CustomQuestionData[]
  setCustomQuestions: (r: CustomQuestionData[]) => void

  // store selected response options per library item. currently only used for configurable-chips questions like culturefitcheck
  config: { [libraryItemId: string]: LibraryItemConfig }
  setConfig: (r: { [libraryItemId: string]: LibraryItemConfig }) => void

  extend: (parent: LibrarySelection) => void
  replace: (parent: LibrarySelection) => void

  valid: boolean
  size: number
  key: () => string
}

export function useLibrarySelection(): LibrarySelection {
  const [libraryItemsSet, libraryItemsActions] = useSet(new Set<string>())
  const [customQuestions, setCustomQuestions] = useState<CustomQuestionData[]>([])

  const libraryItemsReplace = (items: string[]) => {
    libraryItemsActions.reset()
    for (const it of items) {
      libraryItemsActions.add(it)
    }
  }

  const [config, setConfig] = useState<{
    [libraryItemId: string]: LibraryItemConfig
  }>({})

  // extend our current selection by a parent selection of the same type
  const extend = (parent: LibrarySelection) => {
    for (const it of Array.from(parent.libraryItems.set)) {
      libraryItemsActions.add(it)
    }
    for (const [libraryItemId, options] of Object.entries(parent.config)) {
      setConfig({ ...config, [libraryItemId]: options })
    }
  }

  // replace our whole current selection by a parent selection of the same type
  const replace = (parent: LibrarySelection) => {
    libraryItemsActions.reset()
    setConfig({})

    extend(parent)
  }

  const key = () => {
    return `${Array.from(libraryItemsSet)}|${JSON.stringify(customQuestions)}|${JSON.stringify(config)}`
  }

  return {
    libraryItems: {
      ...libraryItemsActions,
      set: libraryItemsSet,
      replace: libraryItemsReplace,
    },
    customQuestions,
    setCustomQuestions,
    config,
    setConfig,
    valid: libraryItemsSet.size + customQuestions.length > 0,
    size: libraryItemsSet.size,
    extend,
    replace,
    key,
  }
}

export type LibraryFilter = ReturnType<typeof useLibraryFilter>

export function useLibraryFilter() {
  const [group, setGroup] = useState<LibraryGroup>()
  const [query, setQuery] = useState<string>()
  const [locale, setLocale] = useState<Locale | undefined>(undefined)

  return { group, setGroup, query, setQuery, locale, setLocale } as const
}

export type LibraryItemData = {
  id: string
  description: string
  entity_id: string
  is_selectable: boolean
  name: string
  locale: string
  duration_seconds: number
  type: libraryItems.LibraryItemEntityType
  group_title: string
  group_slug: string
  group_icon: string
  group_theme: BadgeTheme
  preview: {
    duration: string
    example_question: string
    introduction: string
    response_type?: QuestionResponseType
    result_description: string
    when_to_use: string
    emoji?: string
  }
  overlapped_by?: string[]
}

// expandLibraryItem takes a generic library item and tries to find more
// information on it based on its underlying module, inventory, domain, etc
export const expandLibraryItem = (state: RootState, id: string): LibraryItemData | undefined => {
  const item = selectors.libraryItems.findById(state, id)
  if (!item) return undefined

  const group = selectors.groups.findById(state, item.fields.group_id)

  type SharedUnderlyingFields = {
    copy: {
      title?: string
      description?: string
      preview_duration?: string
      preview_example_question?: string
      preview_introduction?: string
      preview_response_type?: QuestionResponseType
      preview_result_description?: string
      preview_when_to_use?: string
      emoji?: string
    }
    locale: string
    properties: {
      overlapped_by?: string[]
    }
  }
  const genItemData = (entity?: Entity<SharedUnderlyingFields>): LibraryItemData | undefined => {
    if (!entity) return undefined
    return {
      ...item.fields,
      name: entity.fields.copy.title || '',
      description: entity.fields.copy.description || '',
      locale: entity.fields.locale || 'en_US',

      preview: {
        duration: entity.fields.copy.preview_duration || '',
        example_question: entity.fields.copy.preview_example_question || '',
        introduction: entity.fields.copy.preview_introduction || '',
        response_type: entity.fields.copy.preview_response_type,

        result_description: entity.fields.copy.preview_result_description || '',
        when_to_use: entity.fields.copy.preview_when_to_use || '',
        emoji: entity.fields.copy.emoji || '',
      },
      overlapped_by: entity.fields.properties?.overlapped_by,

      group_title: group?.fields.copy.title || '',
      group_slug: group?.fields.slug || '',
      group_icon: group?.fields.copy.icon || '',
      group_theme: group?.fields.copy.theme || '',
    }
  }

  switch (item.fields.type) {
    case 'modules': {
      return genItemData(selectors.modules.findById(state, item.fields.entity_id))
    }
    case 'domains': {
      return genItemData(selectors.domains.findById(state, item.fields.entity_id))
    }
    case 'inventories': {
      return genItemData(selectors.inventories.findById(state, item.fields.entity_id))
    }
  }
}
