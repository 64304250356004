import { RootState } from '../../store'
import * as responses from '../form-responses'
import { totalReferences } from '../references'
import { Chart } from './chart'

export function renderChipLabels(state: RootState, candidateId: string, contentId: string): Chart {
  const chart: Chart = {
    data: [],
    options: {
      radarChart: {
        key: 'caption',
        radars: ['count'],
        domain: [0, totalReferences(state, candidateId)],
      },
    },
  }

  const result = responses.findPopularOptionsByCandidateIdExceptSelf(state, candidateId, contentId, 0)

  for (const row of result.popular) {
    chart.data.push({
      caption: row.caption,
      count: row.count,
    })
  }

  return chart
}
