import React from 'react'
import {
  DataTable,
  DataTableFilterEvent,
  DataTableFilterMeta,
  DataTablePageEvent,
  DataTableSelectionMultipleChangeEvent,
  DataTableSortEvent,
  DataTableValueArray,
} from 'primereact/datatable'

import './PrimeReactTable.scss'

interface LazyTableState {
  first: number
  rows: number
  page: number
  sortField?: string
  sortOrder?: 0 | 1 | -1 | null | undefined
  filters: DataTableFilterMeta
}

interface Props<T extends DataTableValueArray> {
  children: React.ReactNode
  setlazyState: (state: LazyTableState) => void
  lazyState: LazyTableState
  total: number
  loading: boolean
  data: T
  rows?: number
  header?: React.ReactNode | (() => React.ReactNode)
  selection?: T
  onSelectionChange?: (event: DataTableSelectionMultipleChangeEvent<T>) => void
  testId?: string
}

export default function PrimeReactTable<T extends DataTableValueArray>(props: Props<T>) {
  const onPage = (event: DataTablePageEvent) => {
    props.setlazyState({ ...props.lazyState, ...event })
  }

  const onSort = (event: DataTableSortEvent) => {
    let order: DataTableSortEvent['sortOrder']
    if (event.sortField !== props.lazyState.sortField) {
      // new sort field
      order = -1 // default to descending, prime react default is ascending
    } else if (props.lazyState.sortOrder === 1) {
      order = -1
    } else if (props.lazyState.sortOrder === -1) {
      order = 1
    } else {
      order = event.sortOrder
    }
    const newEvent = { ...event, sortOrder: order }
    props.setlazyState({ ...props.lazyState, ...newEvent })
  }

  const onFilter = (event: DataTableFilterEvent) => {
    props.setlazyState({ ...props.lazyState, ...event, first: 0 })
  }

  return (
    <DataTable
      style={{ width: '100%', flex: 1 }}
      columnResizeMode="expand"
      value={props.data}
      lazy
      dataKey="id"
      first={props.lazyState.first}
      filterDisplay={'menu'}
      rows={props.rows || 10}
      totalRecords={props.total}
      onPage={onPage}
      onSort={onSort}
      sortField={props.lazyState.sortField}
      sortOrder={props.lazyState.sortOrder}
      onFilter={onFilter}
      filters={props.lazyState.filters}
      paginator
      rowsPerPageOptions={[10, 25, 50, 100]}
      loading={props.loading}
      className={'hipeopleStyle'}
      header={props.header}
      showGridlines={true}
      selectionMode={'checkbox'}
      selectionPageOnly
      cellSelection={false}
      selection={props.selection || []}
      onSelectionChange={props.onSelectionChange}
      data-testid={props.testId}
    >
      {props.children}
    </DataTable>
  )
}
