import Markdown from 'markdown-to-jsx'
import React, { useEffect, useState } from 'react'
import { breakpoints, colors, height, rem, space, style, vw } from '../../core'
import Button from '../Button/ButtonV1'
import Modal, { Content } from '../Modal'
import Buttons from '../Modal/Buttons'
import TextField from '../TextField'
import { ContentAndButtons } from '../Wizard'

const Container = style()
  .size({
    maxWidth: rem(41),
  })
  .screen(breakpoints.mobile, style().size({ width: vw(100) }))
  .element()

const Message = style().sans({ color: colors.midGray, height: height.s }).element()

const Input = style().spacing({ outerTop: space.m }).element()

export interface Options {
  title: string
  message: string
  messageOnly?: boolean
  confirmLabel?: string
  cancelLabel?: string
  danger?: boolean
  requireInput?: string
  requireInputLabel?: string
  requireInputPlaceholder?: string
}

interface Props extends Options {
  isOpen: boolean
  setOpen: (_: boolean) => void
  onConfirm: (_: boolean) => void
}

const Component = (props: Props) => {
  const [confirmInput, setConfirmInput] = useState('')
  const [disabled, setDisabled] = useState(props.requireInput ? props.requireInput !== confirmInput : false)

  useEffect(() => {
    setDisabled(props.requireInput ? props.requireInput !== confirmInput : false)
  }, [confirmInput, props.requireInput])

  useEffect(() => {
    if (props.isOpen) {
      setConfirmInput('')
    }
  }, [props.isOpen])

  return (
    <Modal title={props.title} open={props.isOpen} setOpen={onClose}>
      <Container>
        <ContentAndButtons>
          <Content>
            <Message>
              <Markdown>{props.message}</Markdown>
            </Message>
            {props.requireInput ? (
              <Input>
                <TextField
                  placeholder={props.requireInputPlaceholder || ''}
                  label={props.requireInputLabel}
                  value={confirmInput}
                  onChange={onConfirmInputChange}
                />
              </Input>
            ) : null}
          </Content>
          {!props.messageOnly && (
            <Buttons reverseOrderOnMobile>
              <Button secondary onClick={onCancel}>
                {props.cancelLabel || 'Cancel'}
              </Button>
              <Button primary onClick={onConfirm} danger={props.danger} disabled={disabled}>
                {props.confirmLabel || 'Confirm'}
              </Button>
            </Buttons>
          )}
        </ContentAndButtons>
      </Container>
    </Modal>
  )

  function onClose() {
    props.onConfirm(false)
  }

  function onCancel() {
    props.onConfirm(false)
  }

  function onConfirm() {
    props.onConfirm(true)
  }

  function onConfirmInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    setConfirmInput(e.target.value)
  }
}

export default Component
Component.displayName = 'Dialog'
