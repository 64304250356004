import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, useParams } from 'react-router-dom'
import { RootState } from 'store'
import * as selectors from '../../../selectors'

export const CandidatePageRedirect = () => {
  const { candidateid } = useParams<{
    candidateid: string
  }>()

  const referenceStatus = useSelector((state: RootState) =>
    selectors.candidates.findStatusForReferenceCheck(state, candidateid),
  )
  const assessmentStatus = useSelector((state: RootState) =>
    selectors.candidates.findStatusForAssessment(state, candidateid),
  )

  if (assessmentStatus === 'completed') return <Redirect to={`/shared/candidates/${candidateid}/assessment`} />

  if (referenceStatus === 'completed') return <Redirect to={`/shared/candidates/${candidateid}/reference-check`} />

  return <Redirect to={`/shared/candidates/${candidateid}/assessment`} />
}
