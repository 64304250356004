import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { addEntities } from 'store/resources'
import { newSession } from 'store/sessions'
import * as api from '../../api'
import { AppThunk } from '../../core/store'
import { add as notify } from '../notifications'

const name = 'reset-password'

interface State {
  loading: boolean
  success: boolean
}

const initialState: State = {
  loading: false,
  success: false,
}

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setSuccess(state, action: PayloadAction<boolean>) {
      state.success = action.payload
    },
  },
})

export const { setLoading, setSuccess } = slice.actions

export default slice.reducer

export const resetPassword =
  (newPassword: string, newPasswordConfirm: string): AppThunk =>
  async dispatch => {
    if (newPassword !== newPasswordConfirm) {
      dispatch(notify({ error: 'Passwords do not match.' }))
      return false
    }
    dispatch(setLoading(true))

    const [response, errors] = await api.users.resetPassword(newPassword)
    dispatch(setLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(newSession(response.result))
      dispatch(setSuccess(true))
      dispatch(addEntities(response))
    }
  }
