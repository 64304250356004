import * as React from 'react'
import { Route as GenericRoute, Switch, useLocation } from 'react-router-dom'
import LimitedRoute from './LimitedRoute'
import PrivateRoute from './PrivateRoute'
import ScrollToTop from './ScrollToTop'

interface RouterProps {
  children: JSX.Element | JSX.Element[]
}

export const Router = ({ children }: RouterProps) => <Switch>{children}</Switch>

interface RouteProps {
  children: JSX.Element
  path: string
  auth?: boolean
  limited?: boolean
  exact?: boolean
}

export const Route = ({ children, auth, path, limited, exact }: RouteProps) => {
  const location = useLocation()
  const route = location.pathname

  if (!auth) {
    return (
      <GenericRoute exact={exact} path={path}>
        {children}
      </GenericRoute>
    )
  }

  if (limited) {
    return (
      <LimitedRoute exact={exact} path={path}>
        {children}
      </LimitedRoute>
    )
  }

  return (
    <GenericRoute>
      <ScrollToTop route={route} />
      <PrivateRoute path={path}>{children}</PrivateRoute>
    </GenericRoute>
  )
}
