import { colors, rem, scale, space, style, weight } from '../../core'

export const Heading = style()
  .sans({ size: rem(1.2), weight: weight.bold })
  .spacing({ outerTop: space.none, outerBottom: space.none })
  .element()

export const Subheading = style()
  .sans({
    size: scale.s,
    weight: weight.light,
    color: colors.gray,
    height: rem(1.3),
  })
  .spacing({ outerTop: space.xs, outerBottom: space.s })
  .element()
