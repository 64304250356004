import React from 'react'
import Modal from 'components/Modal'
import { Button } from '@common/components'
import { fr, px2rem, size, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import { Link } from 'react-router-dom'

const Title = text.cardHeader().element('h3')

const ModalHeader = style()
  .grid({ columns: [fr(1), size.auto, size.auto], align: 'center' })
  .spacing({ gap: px2rem(8), inner: [px2rem(18), px2rem(24)] })
  .border({
    bottom: `solid ${px2rem(1)}`,
    color: designSystemColors.borderDefault,
  })
  .element()

const Warning = text
  .bodyText()
  .color({ bg: designSystemColors.uiStatusWarningBackground })
  .spacing({ inner: [px2rem(12), px2rem(16)], outerBottom: px2rem(16) })
  .border({ radius: px2rem(8) })
  .element('p')

const Description = text.bodyText().element('p')

const Content = style()
  .spacing({ inner: [px2rem(24), px2rem(24)] })
  .element()

const Buttons = style()
  .flex({ justifyContent: 'flex-end' })
  .spacing({ inner: [px2rem(18), px2rem(24)], gap: px2rem(16) })
  .border({
    top: `solid 1px`,
    color: designSystemColors.borderDefault,
  })
  .element()

const EditTemplateModalStyle = style()
  .size({
    width: px2rem(512),
  })
  .element()

interface EditReferenceCheckTemplateModalProps {
  roleId: string
  templateId: string
  close: () => any
}

const EditReferenceCheckTemplateModal: React.FC<EditReferenceCheckTemplateModalProps> = ({
  close,
  roleId,
  templateId,
}) => {
  return (
    <Modal
      open={true}
      setOpen={close}
      renderHeader={({ closeButton }) => (
        <ModalHeader>
          <Title>Edit survey</Title>
          {closeButton}
        </ModalHeader>
      )}
    >
      <EditTemplateModalStyle>
        <Content>
          <Warning>⚠️️ Please note: by selecting a new template, you will lose your custom created survey.</Warning>
          <Description>Do you wish to edit the current survey or select a template from the library?</Description>
        </Content>
        <Buttons>
          <Link to={`/templates?roleid=${roleId}&product=reference-check&mode=single`}>
            <Button variant="tertiary">Select a different template</Button>
          </Link>
          <Link to={`/templates/reference-check?templateid=${templateId}&roleid=${roleId}&mode=edit`}>
            <Button variant="accent">Edit current survey</Button>
          </Link>
        </Buttons>
      </EditTemplateModalStyle>
    </Modal>
  )
}

EditReferenceCheckTemplateModal.displayName = 'EditTemplateModal'

interface EditAssessmentTemplateModalProps {
  roleId: string
  templateId: string
  close: () => any
}

const EditAssessmentTemplateModal: React.FC<EditAssessmentTemplateModalProps> = ({ close, roleId, templateId }) => {
  return (
    <Modal
      open={true}
      setOpen={close}
      renderHeader={({ closeButton }) => (
        <ModalHeader>
          <Title>Edit survey content</Title>
          {closeButton}
        </ModalHeader>
      )}
    >
      <EditTemplateModalStyle>
        <Content>
          <Warning>
            {
              '⚠️️ Please note: some of your candidates already completed the Assessment with the current survey content. If you change it now, the comparison betwen candidates might not be accurate anymore.'
            }
          </Warning>
          <Description>Do you wish to edit the current survey anyway?</Description>
        </Content>
        <Buttons>
          <Button variant="tertiary" onClick={close}>
            {'Cancel'}
          </Button>
          <Link to={`/templates/self-assessment?templateid=${templateId}&roleid=${roleId}&mode=edit`}>
            <Button variant="purple-dark">Edit current survey</Button>
          </Link>
        </Buttons>
      </EditTemplateModalStyle>
    </Modal>
  )
}

type EditTemplateModalType = 'reference-check' | 'self-assessment' | 'onboarding' | 'candidate-experience'
interface EditTemplateModalProps {
  type: EditTemplateModalType
  roleId: string
  templateId: string
  close: () => any
}

function getEditTemplateModalComponent(type: EditTemplateModalType) {
  switch (type) {
    case 'self-assessment':
      return EditAssessmentTemplateModal
    case 'reference-check':
      return EditReferenceCheckTemplateModal
    default:
      return EditReferenceCheckTemplateModal
  }
}

export const EditTemplateModal: React.FC<EditTemplateModalProps> = ({ type, roleId, templateId, close }) => {
  const ModalComponent = getEditTemplateModalComponent(type)
  return <ModalComponent roleId={roleId} templateId={templateId} close={close} />
}
