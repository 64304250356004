import { ListItem } from 'components/ListSelector'
import * as selectors from '../../selectors'
import { RootState } from '../../store'
import action from './action'
import events from './events'
import track from './track'

interface Props {
  roleName: string
  status: string
}

export const viewed = action((props: Props) => {
  track(events.CANDIDATE_VIEWED, {
    'Role Name': props.roleName,
    Status: props.status,
  })
})

export const updateEmail = action(() => {
  track(events.CANDIDATE_UPDATED_EMAIL, {})
})

export const sendReminder = action(() => {
  track(events.CANDIDATE_SEND_REMINDER, {})
})

export const markCompleted = action(() => {
  track(events.CANDIDATE_MARK_COMPLETED, {})
})

export const getManagementLink = action(() => {
  track(events.CANDIDATE_GET_MANAGEMENT_LINK, {})
})

export const shareModalOpened = action(() => {
  track(events.CANDIDATE_SHARE_MODAL_OPENED, {})
})

export const shareModalDoneClicked = action(() => {
  track(events.CANDIDATE_SHARE_MODAL_DONE_CLICKED, {})
})

export const workVerificationModalOpened = action(() => {
  track(events.CANDIDATE_WORK_VERIFICATION_MODAL_OPENED, {})
})

export const exportAsPDF = action(() => {
  track(events.CANDIDATE_EXPORT_AS_PDF, {})
})
export const previewCandidateFeedback = action(() => {
  track(events.CANDIDATE_FEEDBACK_PREVIEW, {})
})
export const cancelSendCandidateFeedback = action(() => {
  track(events.CANDIDATE_FEEDBACK_CANCEL_SEND, {})
})

export const sendCandidateFeedback = action(() => {
  track(events.CANDIDATE_FEEDBACK_SEND, {})
})

export const knowledgeSourceOpened = action(() => {
  track(events.CANDIDATE_FEEDBACK_KNOWLEDGE_SOURCE_OPENED, {})
})

export const sendCandidateFeedbackModalOpened = action(() => {
  track(events.CANDIDATE_FEEDBACK_SEND_MODAL_OPENED, {})
})

export const addReference = action(() => {
  track(events.CANDIDATE_ADD_A_REFERENCE, {})
})

export const deleted = action(() => {
  track(events.CANDIDATE_DELETE, {})
})

export const viewSummary = action(() => {
  track(events.CANDIDATE_VIEW_SUMMARY, {})
})

export const viewOnboarding = action(() => {
  track(events.CANDIDATE_VIEW_ONBOARDING, {})
})

export const viewSelfAssessment = action(() => {
  track(events.CANDIDATE_VIEW_SELF_ASSESSMENT, {})
})

export const listByQuestion = action(() => {
  track(events.CANDIDATE_LIST_BY_QUESTION, {})
})

export const filterDropdownApplied = action(() => {
  track(events.CANDIDATE_REFERENCE_FILTER_APPLIED, {})
})

export const questionBySectionBlockNavigation = action((testId: string, items: ListItem[]) => {
  const blockTitle: string = items.find(item => item.id === testId)?.title || 'uknkown'
  track(events.CANDIDATE_QUESTION_BY_SECTION_BLOCK_NAVIGATOR, {
    blockTitle: blockTitle,
  })
})

export const updateReferenceRequirements = action(() => {
  track(events.CANDIDATE_UPDATED_REFERENCE_REQUIREMENTS, {})
})

export const sharedInvitationSend = action(() => {
  track(events.CANDIDATE_SHARED_INVITATION_SEND, {})
})

export const commentLeft = action(() => {
  track(events.CANDIDATE_COMMENT_LEFT, {})
})

export const clicksHeatmapSkillLabel = action(() => {
  track(events.CANDIDATE_QUESTION_BY_HEATMAP_LABEL, {})
})

export const changeHireStatus = action(() => {
  track(events.CANDIDATE_CHANGE_HIRE_STATUS, {})
})

export const changeEvaluationStatus = action(() => {
  track(events.CANDIDATE_CHANGE_EVALUATION_STATUS, {})
})

export const candidateSummaryBlockDetailsViewed = action(({ blockTitle }: { blockTitle: string }) => {
  track(events.CANDIDATE_SUMMARY_BLOCK_DETAILS_VIEWED, {
    'Block Title': blockTitle,
  })
})

export const extractPropsFromState = action((state: RootState, candidateId: string): Props | null => {
  const role = selectors.roles.findByCandidateId(state, candidateId)
  if (!role) return null

  return {
    roleName: role.fields.name,
    status: selectors.candidates.getStep(state, candidateId),
  }
})

export const candidateCheckResponseDetails = action((surveyName: string) => {
  let surveyType: string = 'unknown'
  switch (surveyName) {
    case 'Assessment':
      surveyType = 'assessment'
      break
    case 'Reference check':
      surveyType = 'reference'
      break
    case 'Onboarding':
      surveyType = 'onboarding'
      break
  }
  track(events.CANDIDATE_VIEW_RESPONSES_DETAILS, { surveyType: surveyType })
})
export const viewModuleNameClicked = action((moduleSlug: string) => {
  track(events.CANDIDATE_VIEW_MODULE_NAME_CLICK, { moduleSlug: moduleSlug })
})
export const openCheckrInviteModal = action(() => {
  track(events.CHECKR_BUTTON_CLICKED, {})
})

export const viewCheckrTab = action(() => {
  track(events.CHECKR_TAB_VIEWED, {})
})

export const candidatesButtonClicked = action(() => track(events.SIDEBAR_CANDIDATES_BUTTON_CLICKED, {}))

export const assessmentPdfExportClicked = action(() => {
  track(events.CANDIDATE_ASSESSMENT_PDF_EXPORT_CLICKED, {})
})
export const refCheckPdfExportClicked = action(() => {
  track(events.CANDIDATE_REF_CHECK_PDF_EXPORT_CLICKED, {})
})

export const tableScoreClicked = action((type: string, title: string) => {
  track(events.CANDIDATE_TABLE_SCORE_CLICKED, { type: type, title: title })
})

export const candidateSearchTableFilteredByStage = action(selected => {
  track(events.CANDIDATE_SEARCH_TABLE_FILTERED_BY_STAGE, {
    selected,
  })
})

export const candidateSearchTableFilteredByEvaluationStatus = action(selected => {
  track(events.CANDIDATE_SEARCH_TABLE_FILTERED_BY_EVALUATION_STATUS, {
    selected,
  })
})

export const candidateSearchTableFilteredByRefCheckStatus = action(selected => {
  track(events.CANDIDATE_SEARCH_TABLE_FILTERED_BY_REF_CHECK_STATUS, {
    selected,
  })
})

export const candidateSearchTableFilteredByAssessmentStatus = action(selected => {
  track(events.CANDIDATE_SEARCH_TABLE_FILTERED_BY_ASSESSMENT_STATUS, {
    selected,
  })
})

export const candidateSearchTableFilteredByName = action(() => {
  track(events.CANDIDATE_SEARCH_TABLE_FILTERED_BY_NAME, {})
})

export const candidateTableColumnSelectorClicked = action(() => {
  track(events.CANDIDATE_TABLE_COLUMN_SELECTOR_CLICKED, {})
})
