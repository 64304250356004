import { presets } from 'api'
import { TemplateContent } from 'api/templates'
import { QuestionResponseType } from 'api/questions'
import { Entity } from 'api/request'
import { Preview } from 'App/RoleCreation/PreviewTable'
import * as selectors from 'selectors'
import { RootState } from 'store'
import { CONTENT_SLUGS } from 'store/new-role/content'
import { ModuleEntity } from '../api/modules'
import { expandLibraryItem } from '../App/RoleCreation/TemplateCreationSelfAssessment/selection'
import lookup from './lookup'

export function getById(state: RootState, id: string): Entity<presets.Fields> | undefined {
  return lookup(state, presets.RESOURCE, id)
}

export function findPresetPreviewById(state: RootState, id: string, orgId: string): Preview[] {
  const preset = getById(state, id)
  if (!preset) return []

  switch (preset.fields.product) {
    case 'reference-check': {
      return getReferenceCheckPreview(state, preset.fields.content, orgId)
    }
    default: {
      return getPreview(state, preset.fields.content)
    }
  }
}

export function getReferenceCheckPreview(state: RootState, presetContent: TemplateContent, orgId: string) {
  return getPreview(state, [...presetContent, { module_slug: 'reference-outro' }])
}

function getPreviewQuestions(state: RootState, module: ModuleEntity): Preview[] {
  const previewQuestions: Preview[] = []

  const questions = selectors.questions.findByModuleId(state, module.id)
  if (!questions) return []

  for (const question of questions) {
    // no followups for now
    if (question.fields.conditions) continue

    if (
      question.fields.response_type.toString() === 'timed-intro' ||
      question.fields.response_type.toString() === 'timed-outro' ||
      question.fields.response_type.toString() === 'intro' ||
      question.fields.response_type.toString() === 'outro'
    ) {
      continue
    }

    if (question.fields.response_type === 'forced-truth') {
      previewQuestions.push({
        copy: `${question.fields.copy['heading']}: ${question.fields.copy['start_statement']} vs. ${question.fields.copy['end_statement']}`,
        type: question.fields.response_type as QuestionResponseType,
        module: {
          id: module.id,
          slug: module.fields.slug as CONTENT_SLUGS,
          name: module.fields.name,
          emoji: module.fields.copy.emoji || '🛠',
        },
      })

      continue
    }

    let name = module.fields.copy.title || module.fields.name
    if (module.fields.slug === 'talent-pool') {
      name = 'Talent Pool'
    }

    previewQuestions.push({
      copy: question.fields.copy['heading'],
      type: question.fields.response_type as QuestionResponseType,
      module: {
        id: module.id,
        slug: module.fields.slug as CONTENT_SLUGS,
        name: name,
        emoji: module.fields.copy.emoji || '🛠',
      },
    })
  }

  return previewQuestions
}

function getPreview(state: RootState, presetContent: TemplateContent): Preview[] {
  const items: Preview[] = []

  if (!presetContent || !presetContent.length) return []

  for (const item of presetContent) {
    if (item.library_item_id) {
      const expanded = expandLibraryItem(state, item.library_item_id)
      items.push({
        copy: expanded?.name || '',
        type: expanded?.preview.response_type || 'slider',
        module: {
          id: expanded?.id || '',
          slug: expanded?.group_slug as CONTENT_SLUGS,
          name: expanded?.name || '',
          emoji: expanded?.preview.emoji || '',
        },
      })

      continue
    }
    if (item.module_type) {
      const modules = selectors.modules.latest(state, item.module_type)

      for (const module of modules) {
        if (!module) continue
        items.push(...getPreviewQuestions(state, module))
      }

      continue
    } else if (item.module_slug) {
      const module = selectors.modules.allLatest(state).find(r => r.fields.slug === item.module_slug)

      if (!module) continue

      items.push(...getPreviewQuestions(state, module))

      continue
    }

    if (item.question_slug) {
      const question = selectors.questions.findBySlug(state, item.question_slug)
      if (!question) continue

      if (question.fields.properties.skill_intro || question.fields.properties.skill_outro) continue

      const module = selectors.modules.findByQuestionId(state, question.id)
      if (!module) continue

      items.push({
        copy: question.fields.copy['heading'],
        type: question.fields.response_type as QuestionResponseType,
        module: {
          id: module.id,
          slug: module.fields.slug as CONTENT_SLUGS,
          name: module.fields.copy?.title || '',
          emoji: module.fields.copy?.emoji || '🛠',
        },
      })

      continue
    }

    if (item.skill_id) {
      const skill = selectors.skills.findById(state, item.skill_id)
      if (!skill) continue

      items.push({
        copy: `When you last worked with {CANDIDATE.FIRST_NAME}, how skilled was {CANDIDATE.FIRST_NAME} in the following activity: **${skill.fields.heading}**`,
        type: 'optionset',
        module: {
          id: 'skills',
          slug: 'skills',
          name: 'Skills',
          emoji: '🛠',
        },
      })
    }

    if (item.custom_question_response_type || item.custom_question_heading) {
      items.push({
        copy: item.custom_question_heading || '',
        type: item.custom_question_response_type || 'text-input',
      })
    }
  }

  return items
}
