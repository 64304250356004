import action from './action'
import events from './events'
import track, { ANONYMOUS_URL } from './track'

export const open = action(() => track(events.CANDIDATE_PORTAL_OPEN, ANONYMOUS_URL))

export const start = action(() => track(events.CANDIDATE_PORTAL_START, ANONYMOUS_URL))

export const legalConsentDecline = action(() => track(events.CANDIDATE_PORTAL_LEGAL_CONSENT_DECLINE, ANONYMOUS_URL))

export const legalConsentGive = action(() => track(events.CANDIDATE_PORTAL_LEGAL_CONSENT_GIVE, ANONYMOUS_URL))

export const professionalNetworkAdd = action(() =>
  track(events.CANDIDATE_PORTAL_ADD_PROFESSIONAL_NETWORK, ANONYMOUS_URL),
)

export const referenceView = action(() => track(events.CANDIDATE_PORTAL_REFERENCE_VIEWED, ANONYMOUS_URL))

export const referenceAddOld = action(() => track(events.CANDIDATE_PORTAL_REFERENCE_ADD_OLD, ANONYMOUS_URL))

export const referenceAdd = action(() => track(events.CANDIDATE_PORTAL_REFERENCE_ADD, ANONYMOUS_URL))

export const referenceUpdate = action(() => track(events.CANDIDATE_PORTAL_REFERENCE_UPDATED, ANONYMOUS_URL))

export const support = action(() => track(events.CANDIDATE_PORTAL_SUPPORT, ANONYMOUS_URL))

export const submit = action(() => track(events.CANDIDATE_PORTAL_SUBMITS, ANONYMOUS_URL))

export const addAddionalReference = action(() => track(events.CANDIDATE_PORTAL_ADD_ADDITIONAL_REFERENCE, ANONYMOUS_URL))

export function clickOnHipeople() {
  track(events.CANDIDATE_PORTAL_HIPEOPLE_CLICKED, ANONYMOUS_URL)
}
