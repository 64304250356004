import * as api from 'api'
import { Response } from 'App/Candidate/SelfAssessment/Responses'
import { interpolateVariables } from 'core/text'
import { hasPresentKey } from 'core/utils'
import {
  AssessmentModuleAnswers,
  getCognitiveAbilitiesQuestionScore,
  getKnowledgeTestQuestionScore,
  NumericalReasoningAnswer,
} from 'selectors/self-assessment'

function scaleScoringValue(score: number, scale: number, targetScale: number) {
  // +1 is added to start the index from 1
  return Math.round(((targetScale - 1) * score) / scale) + 1
}

export function getResponses(
  answersByModuleId: AssessmentModuleAnswers[],
  variables: Record<string, string | number | boolean>,
  submission?: api.request.Entity<api.questionnaireSubmissions.Fields>,
): { [key: string]: Response[] } {
  const responsesByModuleSlug: { [key: string]: Response[] } = {}

  const answersFiltered = answersByModuleId.filter(hasPresentKey('module'))

  for (const { module, questionAnswers } of answersFiltered) {
    const ANSWER_SCALE = 10
    const targetScale =
      module.type === 'hard-skills'
        ? 6
        : module.type === 'cognitive-abilities' || module.type === 'knowledge-test'
        ? 1
        : 7

    const questionAnswersFiltered = questionAnswers.filter(
      field => !(field.question.properties.skill_intro || field.question.properties.skill_outro),
    )

    for (const answerQuestion of questionAnswersFiltered) {
      const numericalAnswers = {
        expected: answerQuestion.question.properties.numerical_reasoning?.expected_answers,
        actual: answerQuestion.numerical_input,
        toProvide: answerQuestion.question.properties.numerical_reasoning?.to_provide,
      } as NumericalReasoningAnswer

      const getCAQuestionScore = () =>
        getCognitiveAbilitiesQuestionScore(module, answerQuestion.selected, answerQuestion.is_skipped, numericalAnswers)

      const score = (() => {
        if (!module) {
          return 0
        }
        if (module.type === 'cognitive-abilities') {
          return getCAQuestionScore()
        }
        if (module.type === 'knowledge-test') {
          return getKnowledgeTestQuestionScore(ANSWER_SCALE, answerQuestion.selected, answerQuestion.is_skipped)
        }
        return scaleScoringValue(answerQuestion.selected?.[0]?.value ?? 0, ANSWER_SCALE, targetScale)
      })()

      const answer: Response = {
        title: interpolateVariables(answerQuestion.question.copy.heading, variables),
        score: score,
        moduleName: module.copy.title || module.name || 'Custom Questions',
        moduleEmoji: module.copy.emoji || '🛠',
        moduleSlug: module.slug || 'custom-questions',
        hasBeenSubmitted:
          !answerQuestion.is_skipped ||
          ((module.slug === 'numerical-reasoning' || module.slug === 'cogabilitynumericalreasoningbasic') &&
            numericalAnswers?.actual.length > 0) ||
          answerQuestion.selected?.length > 0 ||
          (answerQuestion.text_input !== undefined && answerQuestion.text_input.length > 0),
        maxScore: module ? targetScale : 0,
        questionnaireSubmissionId: submission?.id,
        answer: answerQuestion,
      }

      if (!responsesByModuleSlug[answer.moduleSlug]) {
        responsesByModuleSlug[answer.moduleSlug] = [answer]
      } else {
        responsesByModuleSlug[answer.moduleSlug].push(answer)
      }
    }
  }

  return responsesByModuleSlug
}
