import React from 'react'
import { useSelector } from 'react-redux'
import { colors, rem, space, style, weight } from '../../core'
import * as selectors from '../../selectors'
import { RootState } from '../../store'

const Container = style()
  .grid({ columns: [rem(1.5), 'auto'], align: 'center' })
  .spacing({ columns: space.xxs })
  .element()

const Logo = style()
  .size({ width: rem(1.5), height: rem(1.5) })
  .round('3px')
  .with((props: { src: string }) =>
    style().bg({
      image: props.src,
      contain: true,
      center: true,
      norepeat: true,
      color: colors.white,
    }),
  )
  .element()

const Name = style()
  .sans({ size: rem(1), weight: weight.normal })
  .element()

interface Props {
  orgId: string
}

const OrgLogo = (props: Props) => {
  const org = useSelector((state: RootState) => selectors.orgs.getById(state, props.orgId))

  return (
    <Container>
      <Logo src={org?.fields.logo_image_url || 'https://avatars2.githubusercontent.com/u/60610251?s=200&v=4'} />
      <Name>{org?.fields.name}</Name>
    </Container>
  )
}

export default OrgLogo
