import { Tooltip } from '@common/components'
import React from 'react'
import classes from './VerificationBadge.module.scss'

interface Props {
  verified: boolean
  reviewed?: boolean
}

export const JobTitleBadge: React.FC<Props> = props => {
  const dataTip = props.verified
    ? 'Job title has been verified'
    : props.reviewed
    ? 'Job title could not be verified'
    : 'Job title pending verification'
  const Badge = () => {
    if (props.verified)
      return (
        <div className={classes.verified}>
          <Verified />
        </div>
      )
    if (props.reviewed)
      return (
        <div className={classes.notVerified}>
          <NotVerified />
        </div>
      )
    return (
      <div className={classes.unreviewed}>
        <Unreviewed />
      </div>
    )
  }

  return (
    <Tooltip text={dataTip} direction="right">
      <Badge />
    </Tooltip>
  )
}
const RelationshipBadge: React.FC<Props> = props => {
  const dataTip = props.verified
    ? 'Relationship has been verified'
    : props.reviewed
    ? 'Relationship could not be verified'
    : 'Pending verification'
  const Badge = () => {
    if (props.verified)
      return (
        <div className={classes.verifiedRelationship}>
          <Verified />
        </div>
      )
    if (props.reviewed)
      return (
        <div className={classes.notVerified}>
          <NotVerified />
        </div>
      )
    return (
      <div className={classes.unreviewed}>
        <Unreviewed />
      </div>
    )
  }

  return (
    <Tooltip text={dataTip} direction="right">
      <Badge />
    </Tooltip>
  )
}

export default RelationshipBadge

export const Verified: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13" width="14px">
    <path d="M13 6.5c0-.94-.6-1.75-1.47-2.08.38-.81.23-1.83-.43-2.52a2.27 2.27 0 00-2.52-.43A2.25 2.25 0 006.5 0c-.96 0-1.78.6-2.1 1.47a2.22 2.22 0 00-2.95 2.95 2.23 2.23 0 000 4.19c-.38.81-.23 1.83.45 2.49.66.68 1.68.83 2.5.45a2.23 2.23 0 004.18 0 2.22 2.22 0 002.95-2.94A2.26 2.26 0 0013 6.5zM9.32 5.38l-3.33 3.3c-.12.13-.3.1-.4 0L3.66 6.75c-.1-.1-.1-.28 0-.4l.66-.66c.12-.1.3-.1.4.02L5.8 6.78l2.46-2.44c.13-.13.3-.13.4 0l.67.66c.1.1.1.28 0 .38z" />
  </svg>
)

export const NotVerified: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13" width="14px">
    <path d="M13 6.5c0-.94-.6-1.75-1.47-2.08.38-.81.23-1.83-.43-2.52a2.27 2.27 0 00-2.52-.43A2.25 2.25 0 006.5 0c-.96 0-1.78.6-2.1 1.47a2.22 2.22 0 00-2.95 2.95 2.23 2.23 0 000 4.19c-.38.81-.23 1.83.45 2.49.66.68 1.68.83 2.5.45a2.23 2.23 0 004.18 0 2.22 2.22 0 002.95-2.94A2.26 2.26 0 0013 6.5z" />
    <path
      fill="#fff"
      d="M7.3 6.75l1.38-1.37a.4.4 0 000-.6l-.3-.3a.4.4 0 00-.6 0L6.4 5.85 5.03 4.48a.4.4 0 00-.6 0l-.3.3a.4.4 0 000 .6l1.36 1.37-1.37 1.38a.4.4 0 000 .6l.3.3a.4.4 0 00.6 0l1.39-1.36 1.36 1.36a.4.4 0 00.6 0l.3-.3a.4.4 0 000-.6z"
    />
  </svg>
)

export const Unreviewed: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13" width="14px">
    <path d="M10.87 4.7l-.79-.37-.4.85.88.33zm-.38-2.18l.63-.6-.01-.01-.02-.02zm-2.18-.37l-.8.3.33.88.84-.4zm-3.63 0l-.37.78.84.4.34-.88zM2.12 4.7l.31.81.87-.33-.4-.85zm0 3.63l.79.37.4-.84-.88-.34zm.4 2.16l.62-.6v-.01l-.02-.01zm2.16.4l.8-.31-.33-.87-.84.4zm3.63 0l.36-.79-.84-.38-.33.86zm2.56-2.56l-.31-.8-.87.33.4.84zM13 6.51c0-1.2-.78-2.22-1.83-2.62l-.62 1.62c.44.17.72.57.72 1zm-1.36-1.44c.49-1.03.29-2.3-.53-3.15l-1.25 1.2c.32.34.39.83.21 1.21zm-.56-3.18a2.83 2.83 0 00-3.15-.53l.74 1.57c.38-.18.88-.1 1.21.21zm-1.97-.05A2.82 2.82 0 006.51 0v1.73c.42 0 .82.28 1 .72zM6.51 0C5.29 0 4.27.77 3.87 1.84l1.62.61a1.1 1.1 0 011.02-.72zM5.05 1.36a2.8 2.8 0 00-3.14.55l1.22 1.22c.3-.3.8-.38 1.18-.2zm-3.14.55a2.8 2.8 0 00-.57 3.16l1.57-.74c-.18-.38-.11-.86.22-1.2zm-.1 1.98A2.8 2.8 0 000 6.51h1.73c0-.45.28-.84.7-1zM0 6.51c0 1.2.74 2.22 1.81 2.63l.62-1.62c-.42-.16-.7-.54-.7-1.01zm1.34 1.46a2.76 2.76 0 00.58 3.15l1.2-1.25c-.32-.3-.4-.78-.21-1.17zm.55 3.12a2.76 2.76 0 003.16.58L4.3 10.1c-.38.18-.86.1-1.17-.2zm1.98.1a2.8 2.8 0 002.64 1.82v-1.73c-.47 0-.86-.28-1.02-.7zm2.64 1.82a2.8 2.8 0 002.61-1.81l-1.62-.62c-.16.42-.55.7-1 .7zm1.44-1.33a2.8 2.8 0 003.16-.57L9.88 9.88c-.33.33-.8.4-1.2.22zm3.16-.57a2.8 2.8 0 00.54-3.14l-1.57.73c.18.39.1.87-.2 1.18zm.07-1.97A2.83 2.83 0 0013 6.51h-1.73c0 .45-.29.85-.72 1.01z" />
  </svg>
)
