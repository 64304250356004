import Token from './Token'

const URL_QUERY_PARAM_KEY = 'token'
const TOKEN_STORAGE_KEY = 'JWT'

export default class TemporaryToken implements Token {
  constructor() {
    sanityCheck()

    const search = new URLSearchParams(window.location.search)
    const urlToken = search.get(URL_QUERY_PARAM_KEY)

    if (urlToken) {
      this.write(urlToken)
    } else {
      console.error('Temp token not found in URL', {
        url: `${window.location}`,
        search: `${search}`,
      })
    }
  }

  read(): string {
    return sessionStorage.getItem(TOKEN_STORAGE_KEY) || ''
  }

  write(jwt: string) {
    sessionStorage.setItem(TOKEN_STORAGE_KEY, jwt)
  }

  clear() {
    sessionStorage.removeItem(TOKEN_STORAGE_KEY)
  }

  status(): boolean {
    return this.read().length > 0
  }
}

function sanityCheck() {
  const key = '_sanity_check_key'
  sessionStorage.setItem(key, '1')
  if (sessionStorage.getItem(key) !== '1') {
    console.error('sessionStorage not working')
  }
}
