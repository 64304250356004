import { designSystemColors } from 'core/design-system/colors'
import React from 'react'
import { useSelector } from 'react-redux'
import * as api from '../../api'
import { px2rem, space, style } from '../../core'
import * as selectors from '../../selectors'
import { RootState } from '../../store'
import { Answer, Chip, Chips } from './Chips'
import EmptyAnswer from './EmptyAnswer'

interface Props {
  candidateId: string
  referenceId?: string
  item: api.assessmentversions.Item
}
const InnerSection = style()
  .bg({ color: designSystemColors.backgroundNeutralSecondary })
  .spacing({
    outer: space.none,
    inner: [px2rem(24), px2rem(16)],
  })
  .element()
const EmptyAnswerSection = style()
  .spacing({
    outer: space.none,
    inner: [px2rem(24), px2rem(16)],
  })
  .element()

const Answers = style()
  .select(
    '> *',
    style().border({
      top: 'solid 1px',
      color: designSystemColors.borderDefault,
    }),
  )
  .element()

const Container = style().grid().element()

const Component = (props: Props) => {
  const references = useSelector((state: RootState) =>
    props.referenceId
      ? [selectors.references.getById(state, props.referenceId)]
      : selectors.references.findByCandidateId(state, props.candidateId),
  )

  const outstandingOptionValue = useSelector((state: RootState) =>
    selectors.formResponses.outstandingOptionValue(
      state,
      props.candidateId,
      props.item.content_id,
      props.item.response_options.options,
    ),
  )

  // it makes sense here to still show the answers, even if only the self assessment
  // is complete.
  const respondedReferences = useSelector((state: RootState) =>
    selectors.references.findRespondedByCandidateId(state, props.candidateId),
  )

  const filter = useSelector((state: RootState) => state.references.filter[props.candidateId])

  if (references.length === 0) {
    return (
      <EmptyAnswerSection>
        <EmptyAnswer waitingReferences noSpacing />
      </EmptyAnswerSection>
    )
  }

  if (respondedReferences.length === 0) {
    return (
      <EmptyAnswerSection>
        <EmptyAnswer waitingResponse noSpacing />
      </EmptyAnswerSection>
    )
  }

  // Aggregate header should only be shown when looking at all the reference
  // answers. Not when looking at one individual reference's answers.
  const showAllReferencesAnswers = props.referenceId === undefined && !filter

  return (
    <Container>
      {showAllReferencesAnswers ? (
        <InnerSection>
          <Chips>
            {props.item.response_options.options.map(option => (
              <Chip key={option.content_id} highlight={option.value === outstandingOptionValue}>
                {option.caption}
              </Chip>
            ))}
          </Chips>
        </InnerSection>
      ) : (
        <></>
      )}
      <Answers>
        {references.map(reference => (
          <Answer
            key={reference.id}
            item={props.item}
            reference={reference}
            hideProfile={props.referenceId !== undefined}
            sectionUrl={`#${props.item.content_id}`}
          />
        ))}
      </Answers>
    </Container>
  )
}

export default Component
Component.displayName = 'HighlightedOutStandingOption'
