import ProductPage from 'App/ProductPages/ProductPage'
import Button from 'components/Button/ButtonV2'
import HeroGrid from 'components/ProductPageLayout/HeroGrid'
import React from 'react'
import { useSelector } from 'react-redux'
import * as selectors from 'selectors'
import { RootState } from 'store'
import * as tracking from '../../core/track'
import HeroImgAssessments from './HeroImgAssessments.png'

interface AssessmentsPageProps {}

const AssessmentsPage: React.FC<AssessmentsPageProps> = () => {
  const userId = useSelector(selectors.users.current)?.id || ''
  const canCreateRole = useSelector((state: RootState) => selectors.users.canCreateRole(state, userId))

  const button = (
    <Button
      as="Link"
      to={
        canCreateRole
          ? {
              pathname: '/roles/new',
              state: { isQuickMode: true, from: 'assessment-page' },
            }
          : { pathname: '/assessments' }
      }
      buttonType="accent"
      onClick={() => tracking.selfAssessment.sidebarCreateAssessmentClicked()}
      disabled={!canCreateRole}
      testId="create-assessment-button"
    >
      Create Assessment
    </Button>
  )

  return (
    <ProductPage
      product={'assessment'}
      title={'Assessments'}
      ctaButton={button}
      hero={
        <HeroGrid
          heading={'Assess your candidates’ job-fit in no time'}
          subheading={
            "HiPeople Assessments turn the painstaking process of resume screening and candidate selection into a breeze. Assess your candidates' soft skills, personality, culture fit, cognitive abilities, and hard skills, and compare them with science-backed benchmarks."
          }
          image={{
            src: HeroImgAssessments,
            altText: 'User interface of HiPeople dashboard showing insights from candidate assessments',
          }}
        >
          {button}
        </HeroGrid>
      }
    />
  )
}

export default AssessmentsPage
