import { px, space, style } from 'core'
import { text } from 'core/design-system/text'
import React from 'react'
import { DECIMAL_PRECISION_BENCHMARKS } from '.'

const Average = text
  .secondaryBodyInteractive()
  .spacing({
    outer: space.auto,
    innerBottom: px(1),
  })
  .element()

const NA = style().sans({ italic: true }).element('span')

interface Props {
  average: number | null
}

const OrgAverage: React.FC<Props> = ({ average }) => {
  if (!average) {
    return (
      <Average>
        <NA>n.a.</NA>
      </Average>
    )
  }

  return <Average>({average.toFixed(DECIMAL_PRECISION_BENCHMARKS)})</Average>
}

export default OrgAverage
