import { px2rem, size, space, style } from 'core'
import { text } from 'core/design-system/text'
import React from 'react'
import { DECIMAL_PRECISION_BENCHMARKS } from '.'

const DifferenceContainer = style()
  .grid({ columns: [size.auto, size.auto] })
  .size({ height: px2rem(16) })
  .spacing({
    columns: px2rem(4),
    outer: space.auto,
  })
  .element()

const Arrow = style()
  .spacing({ outer: [space.auto, space.none] })
  .element('img')

const Number = text
  .label()
  .spacing({ outer: [space.auto, space.none] })
  .element()

interface Props {
  overall: number | null
  average: number | null
}
const DiffToAverage: React.FC<Props> = ({ overall, average }) => {
  if (!overall || !average)
    return (
      <DifferenceContainer>
        <Number>—</Number>
      </DifferenceContainer>
    )

  const diff =
    parseFloat(overall.toFixed(DECIMAL_PRECISION_BENCHMARKS)) -
    parseFloat(average.toFixed(DECIMAL_PRECISION_BENCHMARKS))
  const svg = averageSvgLookup(overall, average)
  const sign = diff > 0 ? '+' : ''

  return (
    <DifferenceContainer>
      <Arrow src={svg} />
      <Number>
        {sign}
        {diff.toFixed(DECIMAL_PRECISION_BENCHMARKS)}
      </Number>
    </DifferenceContainer>
  )
}

export default DiffToAverage

enum AverageSVG {
  ABOVE = '/assets/ArrowUp.svg',
  BELOW = '/assets/ArrowDown.svg',
  SAME = '/assets/ArrowTilde.svg',
  NONE = '',
}

function averageSvgLookup(overall: number, average: number | null): string {
  if (!average) return AverageSVG.NONE

  if (Math.abs(overall - average) < 0.2) {
    return AverageSVG.SAME
  }

  if (average < overall) {
    return AverageSVG.ABOVE
  }

  return AverageSVG.BELOW
}
