import { Icon } from 'components/Icons'
import { pct, px2rem, size, space, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import { useHiringRecommendationSummary } from 'providers/candidate-summaries'
import { useSelectedCandidate } from 'providers/candidates'
import { useModule } from 'providers/modules'
import React from 'react'
import { useParams } from 'react-router-dom'
import { RECOMMENDATION_OF_HIRE_QUESTION } from 'store/new-role/content'
import { SummaryContainer } from './SummaryContainer'

const HiringRecommendationContainer = style()
  .flex({ direction: 'column', justifyContent: 'space-between' })
  .spacing({ gap: px2rem(16) })
  .size({ height: pct(100) })
  .element()

const ScoreContainer = style()
  .color({ bg: designSystemColors.backgroundNeutralSecondary })
  .border({ radius: px2rem(8) })
  .size({ height: pct(100) })
  .center()
  .element()

const Score = style()
  .grid({ rows: [size.auto, px2rem(20)] })
  .spacing({ inner: [space.none, px2rem(4)] })
  .nooverflow()
  .element()

const HeroScoreLabel = text.scoreHighlightSecondary().spacing({ outer: space.auto }).element()

const GivenScore = text.scoreHighlightPrimary().inline().element()

const LabelText = text.label().fg(designSystemColors.typographySecondary).spacing({ outer: space.auto }).element()

interface HiringRecommendationProps {
  strongRecommendation: number
  recommendation: number
  uncertainOrNoRecommendation: number
  candidateName: string
  detailsUrl?: string
}

const HiringRecommendationBlockUI: React.FC<HiringRecommendationProps> = ({
  strongRecommendation,
  recommendation,
  uncertainOrNoRecommendation,
  candidateName,
  detailsUrl,
}) => {
  const numberOfReferences = strongRecommendation + recommendation + uncertainOrNoRecommendation
  const recommendationCount = recommendation + strongRecommendation

  if (numberOfReferences <= 0) {
    return null
  }

  const { icon, noticeBackgroundColor, iconColor, notice } = getRecommendationDetails(
    strongRecommendation,
    uncertainOrNoRecommendation,
    numberOfReferences,
  )

  return (
    <SummaryContainer emoji={'💬'} hintColor="blue" title={`Hiring Recommendation`} to={detailsUrl}>
      <HiringRecommendationContainer>
        <ScoreContainer>
          <Score>
            <HeroScoreLabel>
              <GivenScore>{recommendationCount}</GivenScore> / {numberOfReferences}
            </HeroScoreLabel>
            <LabelText>
              {strongRecommendation === numberOfReferences && (
                <>
                  <u>STRONGLY</u>{' '}
                </>
              )}
              {`RECOMMEND HIRING`}
            </LabelText>
          </Score>
        </ScoreContainer>
        <NoticeStyle bgColor={noticeBackgroundColor}>
          <IconContainer color={iconColor}>
            <Icon name={icon} />
          </IconContainer>
          <Copy className="data-hj-suppress">{notice({ candidateName })}</Copy>
        </NoticeStyle>
      </HiringRecommendationContainer>
    </SummaryContainer>
  )
}

type RecommendationType = 'strong' | 'positive' | 'negative'

const NoticeStyle = style()
  .with(({ bgColor }: { bgColor: string }) => style().color({ bg: bgColor }))
  .grid({ columns: [px2rem(16), size.auto] })
  .spacing({ inner: px2rem(12), columns: px2rem(8) })
  .border({ radius: px2rem(5) })
  .element()

const Copy = text.smallBodyText().spacing({ outer: space.auto }).nooverflow().element()

const IconContainer = style()
  .with(({ color }: { color: string }) => style().color({ fg: color }))
  .spacing({ outer: space.auto })
  .element()

type RecommendationDetails = {
  icon: string
  iconColor: string
  notice: ({ candidateName }) => string
  noticeBackgroundColor: string
}
const NOTICE_ICON_COPY_COLOR: Record<RecommendationType, RecommendationDetails> = {
  positive: {
    icon: 'check-circle',
    iconColor: designSystemColors.uiStatusSuccess,
    notice: ({ candidateName }) => `All references recommend or strongly recommend hiring ${candidateName}.`,
    noticeBackgroundColor: designSystemColors.informationGreenBackground,
  },
  negative: {
    icon: 'exclamation-circle',
    iconColor: designSystemColors.uiStatusError,
    notice: ({ candidateName }) =>
      `One or more references wouldn't recommend or have doubts about hiring ${candidateName}.`,
    noticeBackgroundColor: designSystemColors.informationRedBackground,
  },
  strong: {
    icon: 'check-circle',
    iconColor: designSystemColors.uiStatusSuccess,
    notice: ({ candidateName }) => `All references strongly recommend hiring ${candidateName}.`,
    noticeBackgroundColor: designSystemColors.informationGreenBackground,
  },
}

function getRecommendationDetails(
  strongRecommendation: number,
  uncertainOrNoRecommendation: number,
  numberOfReferences: number,
): RecommendationDetails {
  if (uncertainOrNoRecommendation) {
    return NOTICE_ICON_COPY_COLOR['negative']
  }

  if (strongRecommendation === numberOfReferences) {
    return NOTICE_ICON_COPY_COLOR['strong']
  }

  return NOTICE_ICON_COPY_COLOR['positive']
}

interface HiringRecommendationBlockProps {
  container?: React.FC
}

const HiringRecommendationBlock: React.FC<HiringRecommendationBlockProps> = ({ container }) => {
  const { candidate, candidateProfile } = useSelectedCandidate()
  const { isLoading, data } = useHiringRecommendationSummary({
    candidateId: candidate?.id,
  })

  const module = useModule({ slug: 'hiring-recommendations' })
  const targetId = candidate?.fields.questionnaire_id ? module?.id : RECOMMENDATION_OF_HIRE_QUESTION.test_item_id
  const { roleid, candidateid } = useParams<{
    roleid: string
    candidateid: string
  }>()
  const detailsUrl = `/roles/${roleid}/candidates/${candidateid}/responses#${targetId}`

  if (isLoading || !data || !candidateProfile) {
    return null
  }

  const { recommendation, strongRecommendation, uncertainOrNoRecommendation } = data

  const numberOfReferences = strongRecommendation + recommendation + uncertainOrNoRecommendation

  if (numberOfReferences <= 0) {
    return null
  }
  const Container = container || React.Fragment

  return (
    <Container>
      <HiringRecommendationBlockUI
        strongRecommendation={strongRecommendation}
        recommendation={recommendation}
        uncertainOrNoRecommendation={uncertainOrNoRecommendation}
        detailsUrl={detailsUrl}
        candidateName={candidateProfile.fields.full_name}
      />
    </Container>
  )
}

export default HiringRecommendationBlock
