import validate from 'all-good-emails'

interface ValidationRule<T> {
  errorMessage: string
  isValid: (t: T) => boolean
}

interface ValidationResult<T> {
  isValid: boolean
  getError: string | null
  value: T
}

export class Validator<T> {
  constructor(private rules: ValidationRule<T>[]) {}

  isValid(value: T): boolean {
    return !this.rules.find(v => !v.isValid(value))
  }

  getError(value: T): string | null {
    return this.rules.find(v => !v.isValid(value))?.errorMessage || null
  }

  validate(value: T): ValidationResult<T> {
    return {
      getError: this.getError(value),
      isValid: this.isValid(value),
      value: value,
    }
  }
}

export const Rules = {
  minStringLength: (minLength: number, customMessage?: string) => {
    return {
      errorMessage: customMessage || 'Value is too short',
      isValid: (v: string) => v.trim().length >= minLength,
    }
  },
  maxStringLength: (maxLength: number, customMessage?: string) => {
    return {
      errorMessage: customMessage || 'Value is too long',
      isValid: (v: string) => v.trim().length <= maxLength,
    }
  },
  validEmailAddress: (customMessage?: string) => {
    return {
      errorMessage: customMessage || 'Email address is not valid',
      isValid: (v: string) => validate(v),
    }
  },
  isSelected: (customMessage?: string) => {
    return {
      errorMessage: customMessage || 'This element must be selected',
      isValid: (selected: boolean) => selected,
    }
  },
  isPresent: (customMessage?: string) => {
    return {
      errorMessage: customMessage || 'This field must not be empty',
      isValid: (v: string) => !!v,
    }
  },
}
