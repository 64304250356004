import { JobInterviewStage } from 'api/integrations'
import DropdownField, { Option } from 'components/DropdownField'
import { Buttons, Content, ContentAndButtons, Form } from 'components/Wizard'
import { Heading, Subheading } from 'components/Wizard/Typography'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useMount } from 'react-use'
import * as selectors from 'selectors'
import { RootState } from 'store'
import * as slice from 'store/integrations'
import { ATS as ATSType, useATS } from 'store/integrations/ats'

const SubheadingBold = text.bodyEmphasis().fg(designSystemColors.typographySecondary).element('p')

interface Props {
  next: () => void
  back: () => void
  setDone: (d: boolean) => void
  roleId: string
  atsMap: { [key: string]: ATSType }
}

const Component = (props: Props) => {
  const dispatch = useDispatch()
  const { roleid } = useParams<{ roleid: string }>()

  const role = useSelector((state: RootState) => selectors.roles.findById(state, roleid || ''))

  const org = useSelector((state: RootState) => selectors.orgs.getById(state, selectors.orgs.currentId(state)))
  const integration = useSelector((state: RootState) => selectors.integrations.getByOrgId(state, org.id))
  const loading = useSelector((state: RootState) => state.integrations.loading)
  const atsJobInterviewStages = useSelector((state: RootState) => state.integrations.atsJobInterviewStages || [])
  const selectedATSJob = useSelector((state: RootState) => state.integrations.selectedATSJob)
  const selectedATSReferenceCheckJobInterviewStage = useSelector(
    (state: RootState) => state.integrations.selectedATSReferenceCheckJobInterviewStage,
  )
  const selectedATSSelfAssessmentJobInterviewStage = useSelector(
    (state: RootState) => state.integrations.selectedATSSelfAssessmentJobInterviewStage,
  )
  const combinedATSMap = useATS(org?.id)

  const [filter, setFilter] = useState('')

  useMount(() => {
    if (integration && selectedATSJob) {
      dispatch(slice.getATSJobInterviewStages(integration.id, selectedATSJob.id))
    }
    slice.setSelectedATSReferenceCheckJobInterviewStage(undefined)
    slice.setSelectedATSSelfAssessmentJobInterviewStage(undefined)
  })

  if (integration?.fields.ats_slug && combinedATSMap[integration?.fields.ats_slug]) {
    const ats = combinedATSMap[integration?.fields.ats_slug || '']
    // TalentLink is an edge case, and we need to let the user manage ATS automation
    // without providing an interview stage.
    const isTalentLink = ats.slug === 'cornerstone-talentlink'
    return (
      <ContentAndButtons>
        <Content>
          <Heading>Pipeline stage</Heading>
          <Subheading>
            Select the ATS’ Pipeline stage that will automatically trigger the various survey collection.
            <SubheadingBold>
              Every candidate moved to the selected Pipeline stages in the ATS will automatically receive a request for
              the defined step in HiPeople.
            </SubheadingBold>
          </Subheading>
          <Form>
            {role.fields.ask_self_assessment ? (
              <DropdownField<JobInterviewStage | undefined>
                label={`Select ${ats.name} Job Pipeline stage for 👤 Assessment ${isTalentLink ? '(optional)' : ''}`}
                value={selectedATSSelfAssessmentJobInterviewStage}
                onChange={stage => {
                  if (!stage) return
                  props.setDone(true)
                  setFilter('')
                  dispatch(slice.setSelectedATSSelfAssessmentJobInterviewStage(stage))
                }}
                allowInput={true}
                onInput={input => setFilter(input)}
                placeholder={'Select'}
                missingSuggestion={loading ? 'Loading...' : 'Not here? Please allow up to an hour while we are syncing'}
              >
                {atsJobInterviewStages
                  .filter(stage => selectors.match(stage.name, filter))
                  .map(s => (
                    <Option value={s} key={s.id}>
                      {s.name}
                    </Option>
                  ))}
              </DropdownField>
            ) : (
              <></>
            )}
            {role.fields.ask_reference_check ? (
              <DropdownField<JobInterviewStage | undefined>
                label={`Select ${ats.name} Job Pipeline stage for 👥 Reference Check ${
                  isTalentLink ? '(optional)' : ''
                }`}
                value={selectedATSReferenceCheckJobInterviewStage}
                onChange={stage => {
                  if (!stage) return
                  props.setDone(true)
                  setFilter('')
                  dispatch(slice.setSelectedATSReferenceCheckJobInterviewStage(stage))
                }}
                allowInput={true}
                onInput={input => setFilter(input)}
                placeholder={'Select'}
                missingSuggestion={loading ? 'Loading...' : 'Not here? Please allow up to an hour while we are syncing'}
              >
                {atsJobInterviewStages
                  .filter(stage => selectors.match(stage.name, filter))
                  .map(s => (
                    <Option value={s} key={s.id}>
                      {s.name}
                    </Option>
                  ))}
              </DropdownField>
            ) : (
              <></>
            )}
          </Form>
        </Content>
        <Buttons
          next={() => {
            props.setDone(true)
            props.next()
          }}
          back={props.back}
          disabled={
            !isTalentLink &&
            ((role.fields.ask_self_assessment && !selectedATSSelfAssessmentJobInterviewStage) ||
              (role.fields.ask_reference_check && !selectedATSReferenceCheckJobInterviewStage))
          }
        />
      </ContentAndButtons>
    )
  }
  return <></>
}

export default Component
