import React from 'react'
import { useSelector } from 'react-redux'
import * as selectors from '../../selectors'
import { RootState } from '../../store'

interface Props {
  children: React.ReactNode
  userId: string
}

const Component = (props: Props) => {
  const currentUserId = useSelector((state: RootState) => selectors.users.id(state))

  return props.userId === currentUserId ? <>{props.children}</> : null
}

export default Component
Component.displayName = 'IsOwnAccount'
