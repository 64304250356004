import { Button, Modal } from '@common/components'
import { ButtonContent, Button as ButtonOld } from 'components/Button/ButtonV2'
import * as tracking from 'core/track'
import React from 'react'

interface TemplateEditorHeaderProps {
  showWarningModal: boolean
  onExit: () => void
}
export const TemplateEditorHeader = (props: TemplateEditorHeaderProps) => {
  const [isExitDialogShown, setIsExitDialogShown] = React.useState(false)

  const handleClickExitBtn = () => {
    if (props.showWarningModal) {
      setIsExitDialogShown(true)
    } else {
      props.onExit()
    }
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <ButtonOld buttonType="tertiary" onClick={handleClickExitBtn}>
          <ButtonContent icon={{ name: 'sign-out', ariaLabel: 'Exit' }}>{'Exit'}</ButtonContent>
        </ButtonOld>
        <ButtonOld
          buttonType="tertiary"
          as={'a'}
          target="_blank"
          href={'https://intercom.help/hipeople/en/articles/7066451-create-your-first-assessment'}
          onClick={tracking.contentSelection.documentationButtonClicked}
        >
          <ButtonContent icon={{ name: 'book', ariaLabel: 'Documentation' }}>{'Documentation'}</ButtonContent>
        </ButtonOld>
        {isExitDialogShown && (
          <ExitWarningModal
            onClose={() => setIsExitDialogShown(false)}
            onConfirm={() => {
              setIsExitDialogShown(false)
              props.onExit()
            }}
          />
        )}
      </div>
    </>
  )
}

export const ExitWarningModal: React.FC<{
  onClose: () => void
  onConfirm: () => void
}> = ({ onClose, onConfirm }) => {
  return (
    <Modal onClose={onClose} open title="Exit Template Selection?">
      <div>
        <p>By leaving now you will lose your progress. Continue?</p>
      </div>
      <div
        style={{
          display: 'flex',
          gap: '1rem',
        }}
      >
        <Button variant="secondary" onClick={onClose}>
          Continue selection
        </Button>
        <Button variant="danger" onClick={onConfirm}>
          Exit and discard changes
        </Button>
      </div>
    </Modal>
  )
}
