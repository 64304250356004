import { del, put } from './methods'
import { APIResponse, Entity } from './request'

export const RESOURCE = 'slack_profiles'

export interface Fields {
  id: string
  user_id: string
  slack_user_id: string
  created_at: number
  updated_at: number
}

export async function addByUserId(
  userId: string,
  slackUserId: string,
  slackTeamId: string,
): Promise<APIResponse<Entity<Fields>[]>> {
  return put<Entity<Fields>[]>(`users/${userId}/slack_profile`, {
    slack_user_id: slackUserId,
    slack_team_id: slackTeamId,
  })
}

export async function deleteByUserId(userId: string): Promise<APIResponse<Entity<Fields>[]>> {
  return del<Entity<Fields>[]>(`users/${userId}/slack_profile`, {})
}
