import { Button, LockedByPlan, LockIcon } from '@common/components'
import Block, { Header } from 'components/Block'

import { useConfirm } from 'components/Confirm'
import Form from 'components/Form'
import ToggleField from 'components/ToggleField'
import { lockedContentTracking } from 'core/track/locked-content'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as selectors from 'selectors'
import { RootState } from 'store'
import { save } from 'store/settings/org'

const PoolSettings = () => {
  const dispatch = useDispatch()
  const confirm = useConfirm()

  const orgId = useSelector((state: RootState) => selectors.orgs.currentId(state))

  const isOrgPaidPlan = useSelector(selectors.orgs.isOrgPaidPlan)

  const orgSettings = useSelector((state: RootState) => selectors.orgSettings.getByOrgId(state, orgId))

  const loading = useSelector((state: RootState) => state.orgSettings.loading)

  const [enabled, setEnabled] = useState({
    talent: orgSettings?.fields.talent_pool_enabled || false,
    sales: orgSettings?.fields.sales_pool_enabled || false,
  })

  const notchanged =
    enabled.talent === orgSettings?.fields.talent_pool_enabled &&
    enabled.sales === orgSettings?.fields.sales_pool_enabled

  return (
    <LockedByPlan
      tracking={lockedContentTracking}
      locked={!isOrgPaidPlan}
      lockIconDisabled={true}
      upgradeTo={'Scale'}
      modalData={{
        learnMoreLink: 'https://intercom.help/hipeople/en/articles/8915421-advanced-organization-settings',
        learnMoreAbout: 'Advanced Organization Settings',
      }}
    >
      <Block
        testId="talent-and-sales-pool"
        title={
          <>
            Talent and Sales Pool
            <LockIcon visible={!isOrgPaidPlan} />
          </>
        }
      >
        <Header>Enable or disable Pool options for your organization. </Header>
        <Form>
          <ToggleField
            on={enabled.talent}
            onChange={value => setEnabled({ ...enabled, talent: value })}
            label="Enable Talent Pool"
          />
          <ToggleField
            on={enabled.sales}
            onChange={value => setEnabled({ ...enabled, sales: value })}
            label="Enable Sales Pool"
          />
          <div></div>
          <Button variant="purple-dark" onClick={onClickSave} disabled={loading || notchanged}>
            Save
          </Button>
        </Form>
      </Block>
    </LockedByPlan>
  )

  async function onClickSave() {
    if (
      await confirm({
        title: 'Save Pool Settings',
        message: 'Are you sure you want to save these settings?',
      })
    ) {
      dispatch(
        save(
          orgId || '',
          {},
          {
            talentPoolEnabled: enabled.talent,
            salesPoolEnabled: enabled.sales,
          },
        ),
      )
    }
  }
}

export default PoolSettings
