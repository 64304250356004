import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { updateEvaluationStatus, updateHireStatus } from 'store/candidates'

export function useHireStatus() {
  const dispatch = useDispatch()
  const updateHireStatusCallback = useCallback(
    async (...args: Parameters<typeof updateHireStatus>) => {
      await dispatch(updateHireStatus(...args))
    },
    [dispatch],
  )

  return { updateHireStatus: updateHireStatusCallback }
}

export function useEvaluationStatus() {
  const dispatch = useDispatch()
  const callback = useCallback(
    async (...args: Parameters<typeof updateEvaluationStatus>) => {
      await dispatch(updateEvaluationStatus(...args))
    },
    [dispatch],
  )

  return { updateEvaluationStatus: callback }
}
