import { lookup } from 'core'
import { candidateBaseURL } from 'core/utils'
import { RootState } from 'store'
import * as api from './index'
import { candidateProfiles, questionnaireSubmissions, referenceCheckSummaries } from './index'
import { del, get, put } from './methods'
import { APIResponse, Entity, Errors } from './request'

export const RESOURCE = 'candidates'

export interface Fields {
  id: string
  open_job_role_id: string
  candidate_profile_id: string
  employee_id: string
  checkr_candidate_id: string
  checkr_invitation_id: string
  candidate_response_id: string
  professional_network: string
  stage: string
  overall_score: number
  invited_at: number
  submitted_at: number
  email_read_at: number
  completed_at: number
  marked_as_completed_at: number
  expired_at: number
  is_active: boolean
  created_at: number
  updated_at: number
  client_signature: string
  is_hired: boolean
  is_rejected: boolean
  questionnaire_id: string
  self_assessment_questionnaire_id: string
  fountain_continue_url: string
  reference_check_status: ReferenceCheckStatus
  hiring_status: HiringStatus
  evaluation_status: EvaluationStatus
}

export type CandidateEntity = Entity<Fields>

export type HiringStatus = 'rejected' | 'in-progress' | 'hired'

export type OnboardingStatus = 'completed' | 'in progress' | 'overdue' | 'not started'

export type ReferenceCheckStatus = 'expired' | 'completed' | 'in_progress' | 'not_started' | 'pending'

export type AssessmentStatus = 'overdue' | 'in-progress' | 'completed'

export type EvaluationStatus = 'to-review' | 'reject' | 'shortlist' | 'unclear' | 'exceptional'

interface InviteLinkFields {
  link: string
}

interface SelfReferenceTokenFields {
  token: string
}

interface SelfAssessmentTokenFields {
  token: string
}

function fetch(id: string): Promise<APIResponse<Entity<Fields>>> {
  return get<Entity<Fields>>(`candidates/${id}`)
}
function remind(id: string): Promise<APIResponse<Entity<Fields>>> {
  return put<Entity<Fields>>(`candidates/${id}/remind`, {})
}

function remove(...ids: string[]): Promise<APIResponse<Entity<Fields>>> {
  return del<Entity<Fields>>('candidates', { ids })
}

function unremove(id: string): Promise<APIResponse<Entity<Fields>>> {
  return put<Entity<Fields>>(`candidates/${id}/undelete`, {})
}

function complete(id: string): Promise<APIResponse<Entity<Fields>>> {
  return put<Entity<Fields>>(`candidates/${id}/complete`, {})
}

export interface CandidateListingQuery {
  roleId: string
  sort: api.listing.Options['sort']
  filters?: api.listing.Options['filters']
  skip?: number
  limit?: number
  include?: api.listing.Options['include']
}

export function fetchRoleCandidates(query: CandidateListingQuery): Promise<APIResponse<Entity<Fields>[]>> {
  return api.listing.list<Fields>(`open_job_roles/${query.roleId}/candidates`, {
    include: query.include ?? [
      candidateProfiles.RESOURCE,
      questionnaireSubmissions.RESOURCE,
      referenceCheckSummaries.RESOURCE,
    ],
    filters: [api.listing.True('is_active'), ...(query?.filters || [])],
    sort: query.sort,
    skip: query.skip || 0,
    limit: query.limit || 10,
  })
}

function updateEmail(id: string, email: string): Promise<APIResponse<Entity<Fields>>> {
  return put<Entity<Fields>>(`candidates/${id}/email`, { email })
}

function updateCheckr(
  id: string,
  checkrCandidateId: string,
  checkrInvitationId: string,
): Promise<APIResponse<Entity<Fields>>> {
  return put<Entity<Fields>>(`candidates/${id}/checkr`, {
    checkr_candidate_id: checkrCandidateId,
    checkr_invitation_id: checkrInvitationId,
  })
}

function updateProfessionalNetwork(id: string, professionalNetwork: string): Promise<APIResponse<Entity<Fields>>> {
  return put<Entity<Fields>>(`candidates/${id}/professional_network`, {
    professional_network: professionalNetwork,
  })
}

function inviteLink(id: string): Promise<APIResponse<Entity<InviteLinkFields>>> {
  return put<Entity<InviteLinkFields>>(`candidates/${id}/invite_link`, {})
}

function managementLink(id: string): Promise<APIResponse<Entity<InviteLinkFields>>> {
  return put<Entity<InviteLinkFields>>(`candidates/${id}/management_link`, {})
}

function share(id: string, email: string): Promise<APIResponse<Entity<InviteLinkFields>>> {
  return put<Entity<InviteLinkFields>>(`candidates/${id}/share?include=otp`, {
    email,
  })
}

function unshare(id: string, email: string): Promise<APIResponse<Entity<InviteLinkFields>>> {
  return put<Entity<InviteLinkFields>>(`candidates/${id}/unshare?include=otp`, {
    email,
  })
}

function updateHiring(
  id: string,
  isHired: boolean,
  isRejected: boolean,
): Promise<APIResponse<Entity<InviteLinkFields>>> {
  return put<Entity<InviteLinkFields>>(`candidates/${id}/hiring_status`, {
    is_hired: isHired,
    is_rejected: isRejected,
  })
}

export function updateEvaluationStatus(
  id: string,
  status: EvaluationStatus,
): Promise<APIResponse<Entity<InviteLinkFields>>> {
  return put<Entity<InviteLinkFields>>(`candidates/${id}/evaluation_status`, {
    evaluation_status: status,
  })
}

export {
  fetch,
  remind,
  remove,
  unremove,
  complete,
  updateEmail,
  updateCheckr,
  updateProfessionalNetwork,
  inviteLink,
  managementLink,
  share,
  unshare,
  updateHiring,
}

export function getSelfReferenceToken(id: string): Promise<APIResponse<Entity<SelfReferenceTokenFields>>> {
  return get<Entity<SelfReferenceTokenFields>>(`candidates/${id}/self_reference_link`)
}

export function getSelfReferenceTokenv2(id: string): Promise<APIResponse<Entity<SelfReferenceTokenFields>>> {
  return get<Entity<SelfReferenceTokenFields>>(`candidates/${id}/self_reference_link/v2`)
}

export function getSelfAssessmentToken(id: string): Promise<APIResponse<Entity<SelfAssessmentTokenFields>>> {
  return get<Entity<SelfAssessmentTokenFields>>(`candidates/${id}/self_assessment_link`)
}

export function findById(state: RootState, id: string): Entity<Fields> {
  return lookup(state, { candidate: id })
}

export function inviteSelfAssessment(id: string): Promise<APIResponse<Entity<Fields>>> {
  return put<Entity<Fields>>(`candidates/${id}/send_survey/self_assessment`, {})
}

export function inviteReferenceCheck(id: string): Promise<APIResponse<Entity<Fields>>> {
  return put<Entity<Fields>>(`candidates/${id}/send_survey/reference_check`, {})
}

export async function createReferencePreviewLinkv2(
  candidateId: string,
  slug: string,
): Promise<[string, Errors | undefined]> {
  const [response, errors] = await put<Entity<{ token: string }>>(`candidates/${candidateId}/preview`, {})
  if (errors) {
    return ['', errors]
  }

  const token = encodeURI(response?.result.fields.token || '')
  return [`${candidateBaseURL(slug)}/preview/v2/candidates/${candidateId}/reference?token=${token}`, undefined]
}

export async function createSelfReferencePreviewLinkv2(
  candidateId: string,
  slug: string,
): Promise<[string, Errors | undefined]> {
  const [response, errors] = await put<Entity<{ token: string }>>(`candidates/${candidateId}/preview`, {})
  if (errors) {
    return ['', errors]
  }

  const token = encodeURI(response?.result.fields.token || '')
  return [`${candidateBaseURL(slug)}/preview/v2/candidates/${candidateId}/self-reference?token=${token}`, undefined]
}
