import { designSystemColors } from 'core/design-system/colors'
import React from 'react'
import { colors, px, rgba, scale, size, space, style } from '../../core'
import { LetterIcon } from '../Icons'

const Circle = style()
  .size({ minHeight: size.none, width: size.fill })
  .spacing({ innerBottom: size.fill, outer: space.none })

const Image = Circle.clone()
  .with(({ src }: { src: string }) =>
    style().bg({
      image: src,
      center: true,
      cover: true,
      norepeat: true,
      color: colors.white,
    }),
  )
  .round('100%')
  .block()
  .element('figure')

const Container = style()
  .relative()
  .cond(({ editing }) => editing, style().select(':hover figure', style().blur(1)))
  .border({
    around: '2px solid',
    color: designSystemColors.typographyPrimaryAccent,
  })
  .spacing({ inner: '1px' })
  .cond(({ noPadding }) => noPadding, style().spacing({ inner: '0' }))
  .round('100%')
  .sans({ line: scale.none })
  .cond((props: { nocircle: boolean }) => props.nocircle, style().noborders())
  .element()

const Overlay = style()
  .spacing({ outerTop: px(-1), outerLeft: px(-1) })
  .size({ width: 'calc(100%)', height: 'calc(100%)' })
  .border({ around: '1px solid', color: colors.white })
  .color({ fg: colors.white })
  .absolute()
  .center()
  .opacity(0)
  .round('100%')
  .select(
    ':hover',
    style()
      .opacity(1)
      .bg({ color: rgba(0, 0, 0, 0.3) }),
  )
  .pointer()
  .front()
  .element('div')

const FileInput = style().hidden().element('input')

interface Props {
  src?: string
  onChange?: (profileImage?: File) => void
  storagePath?: string
  nocircle?: boolean
  title?: string
  name: string
  alt?: string
  noPadding?: boolean
  disabled?: boolean
}

const PHOTO_CACHE: Record<string, string> = {}

function getPhotoURL(photoURL: string) {
  try {
    const url = new URL(photoURL)
    PHOTO_CACHE[url.pathname] = PHOTO_CACHE[url.pathname] || photoURL
    return PHOTO_CACHE[url.pathname]
  } catch {
    return photoURL
  }
}

const ProfileImage = (props: Props) => {
  const fileInputRef = React.useRef<HTMLInputElement>()

  const onFileChangeCallback: React.ChangeEventHandler<HTMLInputElement> = ev => {
    if (!props.onChange) {
      return
    }
    const file = ev.target.files?.[0]

    props.onChange(file ? file : undefined)
  }

  const onClickFilePicker = () => {
    if (!props.onChange || props.disabled) return

    if (!fileInputRef.current) {
      console.error('No file input exists')
      return
    }

    fileInputRef.current.click()
  }

  return (
    <div title={props.title} className="data-hj-suppress">
      <Container
        onClick={onClickFilePicker}
        disabled={props.disabled}
        editing={!!props.onChange}
        nocircle={props.nocircle || false}
        noPadding={props.noPadding}
      >
        <FileInput
          ref={fileInputRef}
          type="file"
          accept="image/jpeg, image/jpg, image/png"
          onChange={onFileChangeCallback}
        />
        {props.onChange ? <Overlay>Change</Overlay> : null}
        {props.src ? (
          <Image src={getPhotoURL(props.src)} alt={props.alt} />
        ) : props.name ? (
          <LetterIcon name={props.name} />
        ) : null}
      </Container>
    </div>
  )
}

export default ProfileImage
