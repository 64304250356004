import { AIContent } from '../AssessmentPage/AiAssistant'
import { FaXmark } from 'react-icons/fa6'
import { FaRegCopy } from 'react-icons/fa'
import React, { useEffect, useState } from 'react'
import { Button, Modal, Text } from '@common/components'
import { useAI } from '../AssessmentPage/AiAssistant'
import classes from './InsightModal.module.scss'
import { useCopyToClipboard } from 'react-use'
import { useDispatch } from 'react-redux'
import { add as notify } from '../../../../store/notifications'

interface InsightModalProps {
  open: boolean
  onClose: () => void
}

const InsightModal: React.FC<InsightModalProps> = props => {
  const { data, loading, responseLoading, loadPrompt } = useAI()
  const [isAnimationComplete, setIsAnimationComplete] = useState(false)

  const interviewGuide = data.find(d => d.type === 'interview_guide')?.responses.at(0) || ''

  useEffect(() => {
    if (props.open && !interviewGuide?.length) {
      loadPrompt('interview_guide')
    }

    setIsAnimationComplete(false)
  }, [props.open])

  const isButtonEnabled = isAnimationComplete && !loading && !responseLoading && interviewGuide.length > 0
  const isLoading = loading || responseLoading
  const showContent = !isLoading && interviewGuide.length > 0

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      style={{ maxWidth: '800px', width: '100%', borderRadius: '0.5rem', overflow: 'hidden', padding: 0 }}
      header={
        <div className={classes.insightModalHeader}>
          <Text variant="card-header">Interview Questions</Text>
          <Button
            variant="ghost"
            onClick={props.onClose}
            size="sm"
            data-testid="close-insight-modal"
            style={{
              padding: 0,
              aspectRatio: '1/1',
            }}
          >
            <FaXmark size={20} />
          </Button>
        </div>
      }
      footer={<ModalFooter text={interviewGuide} isEnabled={isButtonEnabled} />}
    >
      <div className={classes.insightModalContent}>
        {isLoading && <Text variant="body-text">Generating interview questions...</Text>}

        {showContent && (
          <AIContent
            title="Interview Questions"
            text={interviewGuide}
            regenerate={() => loadPrompt('interview_guide', true)}
            onAnimationComplete={() => setIsAnimationComplete(true)}
          />
        )}
      </div>
    </Modal>
  )
}

interface ModalFooterProps {
  text: string
  isEnabled: boolean
}

const ModalFooter: React.FC<ModalFooterProps> = ({ text, isEnabled }) => {
  const [copied, setCopied] = useState(false)
  const dispatch = useDispatch()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_copy, copyToClipboard] = useCopyToClipboard()

  const handleCopy = async () => {
    if (text === '') return

    try {
      copyToClipboard(text)
      setCopied(true)
      setTimeout(() => setCopied(false), 2000)
    } catch (err) {
      console.error('Failed to copy text:', err)
      dispatch(
        notify({
          error: 'Something went wrong while copying the text, please try again.',
        }),
      )
    }
  }

  return (
    <div className={classes.insightModalFooter}>
      <Button
        variant="accent"
        onClick={handleCopy}
        data-testid="copy-interview-questions"
        style={{ gap: '0.5rem', display: 'flex', alignItems: 'center' }}
        disabled={!isEnabled}
      >
        <FaRegCopy size={16} />
        {copied ? 'Copied!' : 'Copy'}
      </Button>
    </div>
  )
}

export { InsightModal }
