import React from 'react'
import { Route } from 'react-router-dom'
import AccessDenied from '../../components/AccessDenied'
import token from '../token'

interface Props {
  path: string
  children: JSX.Element
  exact?: boolean
}

export default function LimitedRoute(props: Props) {
  return <Route exact={props.exact} path={props.path} render={render} />

  function render() {
    if (!token.status() && !token.reload().status()) {
      return <AccessDenied />
    }

    return props.children
  }
}
