import React from 'react'
import { px2rem, style } from '../../core'
import { designSystemColors } from '../../core/design-system/colors'
import { Icon } from '../Icons'

const SkillModuleDeleteButton = style()
  .center()
  .size({ width: px2rem(34), height: px2rem(34) })
  .border({
    radius: '50%',
    around: '1px solid',
    color: designSystemColors.borderDefault,
  })
  .block()
  .color({
    fg: designSystemColors.typographySecondary,
    bg: designSystemColors.backgroundNeutralSecondary,
  })
  .pointer()
  .element('button')

interface Props {
  onClick: () => void
  iconCaption?: string
}

export const RemoveButton: React.FC<Props> = props => {
  return (
    <SkillModuleDeleteButton onClick={props.onClick}>
      <Icon name="times" ariaLabel={props.iconCaption || `remove`} />
    </SkillModuleDeleteButton>
  )
}
