import { get, post } from './methods'
import { APIResponse, Entity } from './request'

export const RESOURCE = 'skills'

export interface Fields {
  id: string
  slug: string
  heading: string
  created_at: number
  updated_at: number
  is_active: boolean
}

export async function all(): Promise<APIResponse<Entity<Fields>[]>> {
  return get(`skills?limit=500`)
}

export async function create(heading: string): Promise<APIResponse<Entity<Fields>>> {
  return post(`skills`, { heading })
}
