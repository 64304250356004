import * as api from '../api'
import { RootState } from '../store'
import * as candidates from './candidates'
import lookup from './lookup'
import * as roles from './roles'

export function findById(state: RootState, id: string): api.request.Entity<api.requiredReferences.Fields> | undefined {
  return lookup(state, api.requiredReferences.RESOURCE, id)
}

export function findByRoleId(
  state: RootState,
  openJobRoleId: string,
): api.request.Entity<api.requiredReferences.Fields>[] {
  const role = roles.findById(state, openJobRoleId)
  if (!role || !role.relationships) return []

  const result: api.request.Entity<api.requiredReferences.Fields>[] = []

  for (const r of role.relationships) {
    if (r.resource !== api.requiredReferences.RESOURCE) continue

    const requiredReference = findById(state, r.id)
    if (!requiredReference) continue

    result.push(requiredReference)
  }

  return result
}

export function findByCandidateId(
  state: RootState,
  candidateId: string,
): api.request.Entity<api.requiredReferences.Fields>[] {
  const candidate = candidates.findById(state, candidateId)
  if (!candidate || !candidate.relationships) return []

  const result: api.request.Entity<api.requiredReferences.Fields>[] = []

  for (const r of candidate.relationships) {
    if (r.resource !== api.requiredReferences.RESOURCE) continue

    const requiredReference = findById(state, r.id)
    if (!requiredReference) continue

    result.push(requiredReference)
  }

  return result
}

export function findByCandidateOrRole(
  state: RootState,
  candidateId: string,
  openJobRoleId: string,
): api.request.Entity<api.requiredReferences.Fields>[] {
  const byCandidate = findByCandidateId(state, candidateId)
  const byRole = findByRoleId(state, openJobRoleId)
  return byCandidate.length ? byCandidate : byRole
}
