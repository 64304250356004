import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { z, ZodObject, ZodRawShape } from 'zod'

/**
 * Custom hook to get query parameters from the URL with schema validation.
 *
 * @param schema Zod object schema to validate query parameters.
 * @returns An object that allows easy access to query parameters, including a `getParam` function to retrieve and validate specific query parameters.
 *
 * @example
 *
 * // define a zod schema to validate query parameters
 * const queryParamsSchema = z.object({
 *   mode: z.enum(['a', 'b']),
 *   id: z.string(),
 * })
 *
 * const { getParam } = useQueryParams(queryParamsSchema)
 *
 * // with not fallback
 * const id = getParam('id') // string | null
 * const mode = getParam('mode') // 'a' | 'b' | null
 * // with fallback
 * const mode = getParam('mode', 'a') // 'a' | 'b'
 */
export const useQueryParams = <T extends ZodRawShape>(schema: ZodObject<T>) => {
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)

  const getParam = useMemo(() => createGetParam(searchParams, schema), [search, schema])

  return { getParam }
}

/**
 * Factory function to create a function that retrieves and validates query parameters.
 *
 * The getParam function has two overloads. If a 2nd argument is provided, it will be used as the fallback value if the query parameter is not found or is invalid.
 * If no 2nd argument is provided, the function will return null if the query parameter is not found or is invalid.
 *
 */
function createGetParam<T extends ZodRawShape>(searchParams: URLSearchParams, schema: ZodObject<T>) {
  type SchemaType = z.infer<typeof schema>

  function getParam<K extends keyof SchemaType>(key: K): SchemaType[K] | null // if no fallback is provided, return null if the param is not found
  function getParam<K extends keyof SchemaType>(
    key: K,
    fallback: NonNullable<SchemaType[K]>,
  ): NonNullable<SchemaType[K]> // if a fallback is provided, return the fallback value if the param is not found

  function getParam<K extends keyof SchemaType>(
    key: K,
    fallback?: NonNullable<SchemaType[K]>,
  ): SchemaType[K] | NonNullable<SchemaType[K]> | null {
    const value = searchParams.get(String(key))
    const fieldSchema = schema.shape[key]
    const parsed = fieldSchema.safeParse(value)

    if (parsed.success) {
      return parsed.data
    }

    return fallback !== undefined ? fallback : null
  }

  return getParam
}
