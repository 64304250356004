import React from 'react'
import style from '../../core/style'
import Tab from './Tab'
import TabPanel from './TabPanel'
export { Tab }
export { TabPanel }

export const TabList = style().scroll({ y: 'auto' }).element()

interface Props {
  selected: number
  children: React.ReactElement[]
}

const Component = (props: Props) => {
  return (
    <>
      {(props.children || []).map((c, ind) => {
        return React.cloneElement(c, {
          selected: ind === props.selected,
        })
      })}
    </>
  )
}

export default Component
Component.displayName = 'Tabs'
