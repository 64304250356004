export const RESOURCE = 'reference_check_summaries'

export interface Fields {
  id: string
  candidate_id: string
  performance_references_total: number
  performance_score: number
  performance_status: ReferenceCheckSummaryAlertStatus
  accepted_requests_positive: number
  accepted_requests_negative: number
  accepted_request_status: ReferenceCheckSummaryAlertStatus
  hiring_recommendation_positive: number
  hiring_recommendation_negative: number
  hiring_recommendation_status: ReferenceCheckSummaryAlertStatus
  termination_terminated: boolean
  termination_status: ReferenceCheckSummaryAlertStatus
  fraud_warnings: number
  legitimacy_fraud_warnings: number
  legitimacy_status: ReferenceCheckSummaryAlertStatus
  reasons_for_leaving?: string[]
  reasons_for_leaving_status: ReferenceCheckSummaryAlertStatus
  title_verification_matches: number
  title_verification_status: ReferenceCheckSummaryAlertStatus
  num_total_references: number
  is_active: boolean
  created_at: number
  updated_at: number
}

export type ReferenceCheckSummaryAlertStatus = 'positive' | 'negative' | 'unselected' | 'incomplete'
