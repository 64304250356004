import React from 'react'
import * as api from '../../api'
import Comment from './Comment'
import Editor from './Editor'

interface Props {
  candidateId: string
  comments: api.request.Entity<api.comments.Fields>[]
}

const Component = (props: Props) => {
  return (
    <>
      {props.comments
        .filter(comment => !comment.fields.deleted_at)
        .map(comment => (
          <Comment key={comment.id} comment={comment} />
        ))}
      <Editor candidateId={props.candidateId} />
    </>
  )
}

export default Component
Component.displayName = 'Comments'
