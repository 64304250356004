import { MinimalButton } from 'components/Button/ButtonV1'
import { Icon } from 'components/Icons'
import { fr, px2rem, rem, scale, size, space, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import * as tracking from 'core/track'
import React from 'react'

const Emoji = style()
  .flex({ alignItems: 'center', justifyContent: 'center' })
  .with(({ bgColor }: { bgColor: string }) => style().color({ bg: bgColor }))
  .size({ width: px2rem(42), height: px2rem(42) })
  .border({
    radius: px2rem(6),
  })
  .element()

const Header = style()
  .grid({
    columns: [size.auto, fr(1), size.auto],
    align: 'center',
  })
  .color({ bg: designSystemColors.backgroundNeutralPrimary })
  .cursor('pointer')
  .spacing({ gap: px2rem(12), inner: px2rem(16) })
  .noborders()
  .size({ width: '100%' })
  .nooverflow()
  .element('button')

const ToggleArrow = style()
  .flex({ alignItems: 'center' })
  .select(`> :last-child`, style().spacing({ left: px2rem(8), right: px2rem(8) }))
  .element()

const Content = style()
  .color({ bg: designSystemColors.backgroundNeutralSecondary })
  .cursor('default')
  .size({ height: '100%' })
  .border({
    top: '1px solid',
    color: designSystemColors.backgroundNeutralTertiary,
  })
  .element()

const DocumentationLink = text
  .bodyEmphasis()
  .color({ fg: designSystemColors.typographySecondary })
  .sans({ nodecoration: true })
  .select(`> ${MinimalButton} > :first-child`, style().spacing({ right: px2rem(8) }))
  .select(
    `${MinimalButton}`,
    style()
      .color({ fg: designSystemColors.typographySecondary })
      .spacing({ inner: px2rem(8) }),
  )
  .select(`:hover ${MinimalButton}`, style().color({ bg: designSystemColors.backgroundNeutralSecondaryHover }))
  .element('a')

const Footer = style()
  .spacing({
    innerTop: px2rem(16),
    innerBottom: px2rem(16),
    innerLeft: px2rem(8),
    bottom: '0',
    left: px2rem(16),
    right: px2rem(16),
  })
  .border({
    top: '1px solid',
    color: designSystemColors.backgroundNeutralTertiary,
  })
  .flex({ alignItems: 'center', justifyContent: 'space-between' })
  .element()

const Title = text
  .bodyEmphasis()
  .sans({ align: 'left' })
  .color({ fg: designSystemColors.typographyPrimary })
  .element('h3')

const SummaryContainer = style()
  .color({ bg: designSystemColors.backgroundNeutralPrimary })
  .border({
    around: `solid 1px`,
    color: designSystemColors.borderDefault,
    radius: px2rem(6),
  })
  .block()
  .cursor('pointer')
  .sans({ nodecoration: true })
  .nospacing()
  .nooverflow()
  .element('article')

const ContentContainer = style()
  .spacing({ inner: px2rem(32) })
  .element()

const IntroCopy = text
  .bodyText()
  .spacing({ bottom: px2rem(16) })
  .element('p')

const InsightsContainer = style()
  .grid({ columns: [fr(1), fr(1)], align: 'start' })
  .element()
const InsightHeader = text.bodyEmphasis().color({ fg: designSystemColors.typographyPrimary }).element('h4')
const InsightItem = style()
  .flex()
  .spacing({ top: px2rem(8), bottom: px2rem(8) })
  .element()
const InsightEmoji = style()
  .spacing({ right: px2rem(8) })
  .element()
const InsightCopy = text
  .bodyText()
  .spacing({ right: px2rem(8) })
  .element('p')

const EncourageContainer = style().element('section')
const PayAttentionContainer = style().element('section')

const MissingDataContainer = style().flex({ alignItems: 'center' }).element()
const MissingDataEmoji = style()
  .spacing({ right: px2rem(16) })
  .element()
const MissingDataCopy = text.bodyText().element('p')
const MissingModules = text.bodyEmphasis().element('span')
const MissingDataLabel = style()
  .color({
    bg: designSystemColors.backgroundNeutralSecondary,
    fg: designSystemColors.typographySecondary,
  })
  .sans({ size: scale.xs, align: 'center', ellipsis: true })
  .round(size.m)
  .spacing({ inner: [space.xxs, space.xs] })
  .element()

const WasThisUseful = style()
  .grid({ columns: [size.auto, rem(2), rem(2)] })
  .spacing({ gap: px2rem(12) })
  .element()

const Text = text
  .bodyInteractive()
  .spacing({ outer: [size.auto, size.none] })
  .color({ fg: designSystemColors.typographySecondary })
  .element()

const IconContainer = style()
  .select('*', style().fg(designSystemColors.typographySecondary))
  .border({
    around: '1px solid',
    radius: '100%',
    color: designSystemColors.backgroundNeutralTertiary,
  })
  .size({ height: px2rem(32), width: px2rem(32) })
  .transparent()
  .center()
  .pointer()
  .element('button')

function getBgColor(emojiColor: EmojiColor): string {
  switch (emojiColor) {
    case 'purple': {
      return designSystemColors.informationPurpleBackground
    }
    case 'orange': {
      return designSystemColors.informationOrangeBackground
    }
    case 'green': {
      return designSystemColors.informationGreenBackground
    }
    case 'blue': {
      return designSystemColors.informationBlueBackground
    }
  }
}

type EmojiColor = 'purple' | 'orange' | 'green' | 'blue'

export interface CollapsibleSummaryProps {
  emoji: React.ReactNode
  emojiColor: EmojiColor
  title: string
  intro: string
  encourage: {
    emoji: string
    copy: string
  }[]
  payAttention: {
    emoji: string
    copy: string
  }[]
  missingModules: string[]
  documentationLink: string
  onUsefulButtonsClick: () => void
}

const CollapsibleSummary = ({
  emoji,
  emojiColor,
  title,
  intro,
  encourage,
  payAttention,
  missingModules,
  documentationLink,
  onUsefulButtonsClick,
}: CollapsibleSummaryProps) => {
  const [isExpanded, setIsExpanded] = React.useState(false)
  const isMissingData = encourage.length === 0 && payAttention.length === 0

  return (
    <SummaryContainer>
      <Header
        onClick={() => {
          setIsExpanded(isExpanded => !isExpanded)
          if (!isExpanded) {
            tracking.onboarding.faqBlockOpened({ title })
          } else {
            tracking.onboarding.faqBlockClosed({ title })
          }
        }}
        aria-label="Toggle collapsible content summary"
      >
        <Emoji bgColor={getBgColor(emojiColor)}>{emoji}</Emoji>
        <Title>{title}</Title>

        <ToggleArrow>
          {isMissingData && <MissingDataLabel>No data available</MissingDataLabel>}
          <Icon name={isExpanded ? 'chevron-up' : 'chevron-down'} />
        </ToggleArrow>
      </Header>

      {isExpanded && (
        <Content>
          <ContentContainer>
            <IntroCopy>{intro}</IntroCopy>
            {isMissingData ? (
              <MissingDataContainer>
                <MissingDataEmoji>{'⚠️'}</MissingDataEmoji>
                <MissingDataCopy>
                  No data collected about this topic during the Reference Feedback process (missing modules:
                  <MissingModules>{missingModules.join(', ')}</MissingModules>)
                </MissingDataCopy>
              </MissingDataContainer>
            ) : (
              <InsightsContainer>
                <EncourageContainer>
                  <InsightHeader>{'Encourage'}</InsightHeader>
                  {encourage.map(item => (
                    <InsightItem key={item}>
                      <InsightEmoji>{item.emoji}</InsightEmoji>
                      <InsightCopy>{item.copy}</InsightCopy>
                    </InsightItem>
                  ))}
                </EncourageContainer>
                <PayAttentionContainer>
                  <InsightHeader>{'Pay attention'}</InsightHeader>
                  {payAttention.map(item => (
                    <InsightItem key={item}>
                      <InsightEmoji>{item.emoji}</InsightEmoji>
                      <InsightCopy>{item.copy}</InsightCopy>
                    </InsightItem>
                  ))}
                </PayAttentionContainer>
              </InsightsContainer>
            )}
          </ContentContainer>
          {!isMissingData && (
            <Footer>
              <DocumentationLink
                href={documentationLink}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => tracking.onboarding.faqDocumentationClicked({ title })}
              >
                <MinimalButton>
                  <Icon name="life-ring"></Icon>
                  {'Documentation'}
                </MinimalButton>
              </DocumentationLink>
              <WasThisUseful>
                <Text>{'Was this useful?'}</Text>
                <IconContainer
                  onClick={() => {
                    tracking.onboarding.summaryMarkedAsUseful({ title })
                    onUsefulButtonsClick()
                  }}
                >
                  <Icon name={'thumbs-up'} variant={'regular'} />
                </IconContainer>
                <IconContainer
                  onClick={() => {
                    tracking.onboarding.summaryMarkedAsNotUseful({ title })
                    onUsefulButtonsClick()
                  }}
                >
                  <Icon name={'thumbs-down'} variant={'regular'} />
                </IconContainer>
              </WasThisUseful>
            </Footer>
          )}
        </Content>
      )}
    </SummaryContainer>
  )
}

export default CollapsibleSummary
