import { DrawerTextInput, MarkdownText, Text } from '@common/components'
import { FloatingPromptCard } from '@common/components/FloatingPrompt/FloatingPromptCard'
import { FloatingPrompt } from '@common/components/FloatingPrompt/FloatingPrompt'
import { apiGet, apiPost } from 'api/methods'
import { colors } from 'core'
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { BsStars } from 'react-icons/bs'
import { FaArrowsRotate } from 'react-icons/fa6'
import * as tracking from '../../../../core/track'
import classes from './AiAssistantPage.module.scss'
import { isPresent } from 'core/utils'

type AIAssistResponseType = {
  type:
    | 'candidate_development_areas'
    | 'candidate_strengths'
    | 'candidate_summary'
    | 'candidate_summary_long'
    | 'interview_questions'
    | 'onboarding_advice'
    | 'team_interaction'
    | 'custom_prompt'
    | 'candidate_report'
    | 'interview_guide'
    | 'resume_screening'
  title: string
  visualization: 'prompt_library' | string
  responses: string[]
}

type AIAssistResponseData = AIAssistResponseType[]

const AIContext = createContext({
  data: [] as AIAssistResponseData,
  loading: true,
  responseLoading: false,
  loadPrompt: (_prompt: (string & {}) | AIAssistResponseType['type'], _regenerate?: boolean) => {},
  lastPrompt: '',
  lastPromptAt: new Date(),
})

export const useAI = () => {
  return useContext(AIContext)
}

type AIProviderProps = {
  candidateId: string
  children: React.ReactNode
}

export const AIProvider = (props: AIProviderProps) => {
  const [data, setData] = useState<AIAssistResponseData>([])

  const [loading, setLoading] = useState(true)
  const [responseLoading, setResponseLoading] = useState(false)

  const [lastPromptAt, setLastPromptAt] = useState(new Date())
  const [lastPrompt, setLastPrompt] = useState('')

  const loadData = async () => {
    try {
      await apiPost<{}, AIAssistResponseData>(`/assistant/candidates/${props.candidateId}/initialize`, {
        candidate_id: props.candidateId,
      })

      const response = await apiGet<{}, AIAssistResponseData>(`/assistant/candidates/${props.candidateId}`)
      const meta = response[0]?.meta ?? []

      setData(meta)
      setLoading(false)
    } catch (e) {
      setLoading(true)
    }
  }

  const loadPrompt = async (prompt: string, regenerate = false) => {
    setLastPromptAt(new Date())

    if (!prompt) {
      return
    }

    setLastPrompt(prompt)
    const existing = data?.find(d => d.title === prompt || d.type === prompt)

    if (!regenerate && existing?.responses.length) {
      return
    }

    try {
      setResponseLoading(true)

      const response = await apiPost<{}, AIAssistResponseData>(`/assistant/candidates/${props.candidateId}`, {
        prompt: existing ? existing.type : prompt,
      })

      const meta = response[0]?.meta ?? []
      setData(meta)
    } catch (e) {
      // console.log(e)
    } finally {
      setResponseLoading(false)
    }
  }

  useEffect(() => {
    loadData()
  }, [])

  return (
    <AIContext.Provider value={{ data, loading, responseLoading, loadPrompt, lastPrompt, lastPromptAt }}>
      {props.children}
    </AIContext.Provider>
  )
}

export const AiAssistant = () => {
  const { data, loading, responseLoading, loadPrompt } = useAI()

  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState('')
  const [input, setInput] = useState('')

  const onClickItem = (item: AIAssistResponseType) => () => {
    tracking.selfAssessment.aiDefaultPromptClicked(item.title)
    loadPrompt(item.title)
    setSelected(item.title)
    setInput(item.title)
  }

  const options = useMemo(
    () =>
      data
        .map(item =>
          item.visualization === 'prompt_library' || item.visualization === undefined // at first, we need to populate the prompt library, but this should work for both cases. TODO: remove this once prompt library is populated
            ? {
                id: item.type,
                title: item.title,
                category: item.type === 'custom_prompt' ? 'Custom Prompts' : undefined,
                onClick: onClickItem(item),
                children: (
                  <AIContent
                    title={item.title}
                    text={item.responses[0]}
                    regenerate={(prompt: string) => {
                      loadPrompt(prompt, true)
                    }}
                  />
                ),
              }
            : null,
        )
        .filter(isPresent),
    [data],
  )

  return (
    <FloatingPromptCard
      open={open}
      onClose={() => {
        setOpen(false)
        setSelected('')
        setInput('')
      }}
      canBack={!!selected}
      onBack={() => {
        setSelected('')
        setInput('')
      }}
      prompt={
        <FloatingPrompt
          value={input}
          onClick={() => {
            setOpen(true)
          }}
          onPressEnter={input => {
            tracking.selfAssessment.aiCustomPromptSent()
            setSelected(input)
            loadPrompt(input)
          }}
          placeholder="What would you like to know about this candidate?"
          disabled={loading || responseLoading}
        />
      }
    >
      <DrawerTextInput
        loading={!data || loading}
        responseLoading={responseLoading}
        options={options}
        selected={selected}
        style={{
          overflowY: 'auto',
          scrollbarWidth: 'none', // not accessible, but looks nice
          height: '500px',
          padding: '0 20px',
        }}
      />
    </FloatingPromptCard>
  )
}

export const AIContent = ({
  title,
  text,
  regenerate,
  onAnimationComplete,
  speed,
}: {
  title: string
  text: string
  regenerate: (prompt: string) => void
  onAnimationComplete?: () => void
  speed?: number
}) => {
  const { displayResponse } = useTextAnimation({ text, onComplete: onAnimationComplete, speed })

  return (
    <div style={{ paddingBlockEnd: '1rem' }}>
      <MarkdownText text={displayResponse} className={classes.markdown} />
      <div className={classes.heading}>
        <Text variant="small-body-text" className={classes.iconText}>
          <BsStars fill={colors.lightYellow} />
          Generated by AI
        </Text>
        <Text
          variant="small-body-text"
          className={classes.actionText}
          onClick={() => {
            tracking.selfAssessment.aiRegenerateAnswerClicked()
            regenerate(title)
          }}
        >
          Regenerate <FaArrowsRotate fill={colors.gray} />
        </Text>
      </div>
    </div>
  )
}

const useTextAnimation = ({
  text,
  speed = 5,
  onComplete,
}: {
  text: string
  speed?: number
  onComplete?: () => void
}) => {
  const [displayResponse, setDisplayResponse] = useState('')
  const [finished, setFinished] = useState(false)

  useEffect(() => {
    if (!text || finished) return

    const stringResponse = text.replace(/---/g, '\n\n---\n\n')
    const chars = stringResponse.split('')
    let i = 0

    const intervalId = setInterval(() => {
      setDisplayResponse(prev => prev + chars[i])
      i++
      if (i === chars.length) {
        clearInterval(intervalId)
        setFinished(true)
        onComplete?.()
      }
    }, speed)

    setFinished(false)
    setDisplayResponse('')

    return () => clearInterval(intervalId)
  }, [text, speed, onComplete])

  return { displayResponse, finished }
}
