import { breakpoints, px2rem, style } from 'core'
import React from 'react'
import AreasOfImprovementBlock from './AreasOfImprovementBlock'
import AudioVideoHintsBlock from './AudioVideoHintBlock'
import CultureAddBlock from './CultureAddBlock'
import GeneralImpressionsBlock from './GeneralImpressionsBlock'
import GrowthHintsBlock from './GrowthHintsBlock'
import HiringRecommendationBlock from './HiringRecommendationBlock'
import KeyAchievementsBlock from './KeyAchievementsBlock'
import LeadershipSkillsBlock from './LeadershipBlockV2'
import ReasonsForLeavingBlock from './ReasonsForLeavingBlock'
import SkillsetBlock from './SkillsetBlock'
import StrengthsBlock from './StrengthsBlock'
import WorkingWithOthersBlock from './WorkingWithOthersBlock'
import WorkStyleBlock from './WorkStyleBlock'

const BlockContainer = style().set('gridColumn', 'span 1').element()
const WideBlockContainer = style().set('gridColumn', 'span 2').element()
const FullBlockContainer = style().set('gridColumn', '1 / -1').element()
const SummaryBlocksStyle = style()
  .grid({ columns: `repeat(2, minmax(0, 1fr))` })
  .screen(breakpoints.m, style().grid({ columns: `repeat(2, minmax(0, 1fr))` }))
  .screen(breakpoints.l, style().grid({ columns: `repeat(3, minmax(0, 1fr))` }))
  .screen(breakpoints.xl, style().grid({ columns: `repeat(4, minmax(0, 1fr))` }))
  .spacing({ gap: px2rem(16) })
  .element()

interface SummaryBlocksProps {}

export const SummaryBlocks: React.FC<SummaryBlocksProps> = () => {
  return (
    <SummaryBlocksStyle>
      <FullBlockContainer>
        <SkillsetBlock />
      </FullBlockContainer>
      <WorkStyleBlock container={WideBlockContainer} />
      <ReasonsForLeavingBlock container={WideBlockContainer} />
      <StrengthsBlock container={WideBlockContainer} />
      <AreasOfImprovementBlock container={WideBlockContainer} />
      <LeadershipSkillsBlock container={BlockContainer} />
      <GeneralImpressionsBlock container={BlockContainer} />
      <WorkingWithOthersBlock container={BlockContainer} />
      <KeyAchievementsBlock container={BlockContainer} />
      <GrowthHintsBlock container={BlockContainer} />
      <AudioVideoHintsBlock container={BlockContainer} />
      <CultureAddBlock container={BlockContainer} />
      <HiringRecommendationBlock container={BlockContainer} />
    </SummaryBlocksStyle>
  )
}
