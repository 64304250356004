export const Locales = [
  'en_US',
  'es_ES',
  'de_DE',
  'fr_FR',
  'fr_CA',
  'it_IT',
  'sv_SE',
  'nb_NO',
  'da_DK',
  'fi_FI',
  'nl_NL',
] as const

export type Locale = (typeof Locales)[number]

export const AvailableReferenceCheckLocales: Locale[] = [
  'en_US',
  'es_ES',
  'de_DE',
  'fr_FR',
  'sv_SE',
  'nb_NO',
  'da_DK',
  'fi_FI',
  'nl_NL',
]

export const AvailableAssessmentLocales: Locale[] = ['en_US', 'es_ES', 'de_DE', 'fr_FR']
export const AvailableAssessmentModulesLocales: Locale[] = ['en_US', 'es_ES', 'de_DE', 'fr_FR', 'fr_CA', 'it_IT']

export type AssessmentLocale = (typeof AvailableAssessmentLocales)[number]
export type ReferenceCheckLocale = (typeof AvailableReferenceCheckLocales)[number]
