import { EvaluationStatus } from 'api/candidates'
import { Step, SubmissionType } from 'selectors/candidates'
import EmptyShell, { Props } from './EmptyShell'
import NameHeader, { MinimalNameHeader, NameAndImageHeader, ResponseHeader } from './NameHeader'
import styles from './Shell.module.scss'

interface ShellProps extends Props {
  assessmentScore?: number
  responseHeader?: {
    date: string
  }
  title?: SubmissionType
  minimal?: boolean
  nameAndImage?: { imageSrc: string; imageAltText: string }
  currentProgress?: Step
  showScore?: boolean
  evaluationStatus?: EvaluationStatus
  onEvaluationStatusChanged?: (newStatus: EvaluationStatus) => void
}

// TODO: Give this component a meaningful name.
const Shell = (props: ShellProps) => {
  return (
    <EmptyShell {...props}>
      {props.responseHeader ? (
        <ResponseHeader
          candidateId={props.candidateId}
          title={props.title}
          date={props.responseHeader.date}
          score={props.assessmentScore}
        />
      ) : props.minimal ? (
        <MinimalNameHeader candidateId={props.candidateId} />
      ) : props.nameAndImage ? (
        <NameAndImageHeader
          candidateId={props.candidateId}
          imageSrc={props.nameAndImage.imageSrc}
          imageAltText={props.nameAndImage.imageAltText}
        />
      ) : (
        <NameHeader
          title={props.title}
          candidateId={props.candidateId}
          currentProgress={props.currentProgress}
          button={props.button}
          score={props.showScore ? props.assessmentScore : undefined}
          evaluationStatus={props.evaluationStatus}
          onEvaluationStatusChanged={props.onEvaluationStatusChanged}
        />
      )}
      <div className={styles.container}>{props.children}</div>
    </EmptyShell>
  )
}

export default Shell
