import { JobsTable } from 'App/ProductPages/JobsTable'
import { px2rem, size, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import React from 'react'
import { Role } from 'selectors/roles'

const ProductFilteredViewStyle = style()
  .color({ bg: designSystemColors.backgroundNeutralSecondary })
  .size({ height: size.fill })
  .flex({ direction: 'column' })
  .spacing({ gap: px2rem(54), innerBottom: px2rem(250) })
  .element()

const CandidateListContainer = style().flex({ direction: 'column' }).size({ height: size.fill }).element('section')

interface ProductFilteredViewProps {
  loadMoreRoles: () => any
  roles: Role[]
  hasMoreRole: boolean
  onSort: (key: string, direction: string) => void
}

export const ProductFilteredView: React.FC<ProductFilteredViewProps> = props => {
  return (
    <ProductFilteredViewStyle>
      <CandidateListContainer>
        <JobsTable
          onSort={props.onSort}
          loadMoreRoles={props.loadMoreRoles}
          hasMoreRole={props.hasMoreRole}
          roles={props.roles}
        />
      </CandidateListContainer>
    </ProductFilteredViewStyle>
  )
}
