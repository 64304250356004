import React from 'react'
import { useSelector } from 'react-redux'
import * as selectors from '../../selectors'
import { RootState } from '../../store'

interface Props {
  children: React.ReactNode
}

const Component = (props: Props) => {
  const canManageOrganization = useSelector((state: RootState) => {
    return selectors.users.canManageOrganization(state, selectors.users.id(state))
  })

  return canManageOrganization ? <>{props.children}</> : null
}

export default Component
Component.displayName = 'CanManageOrganization'
