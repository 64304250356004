import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Job, JobInterviewStage } from 'api/integrations'
import { InterviewStage } from 'api/openjobroles'
import { addEntities } from 'store/resources'
import * as api from '../../api'
import { AppThunk } from '../../core/store'
import { add as notify } from '../notifications'

interface State {
  loading: boolean
  linkToken: string
  url: string
  atsJobs: Job[]
  atsJobInterviewStages: JobInterviewStage[]
  selectedATSJob?: Job
  selectedATSReferenceCheckJobInterviewStage?: JobInterviewStage
  selectedATSSelfAssessmentJobInterviewStage?: JobInterviewStage
  created: boolean
}

const name = 'integrations'
const initialState: State = {
  loading: false,
  linkToken: '',
  url: '',
  atsJobs: [],
  atsJobInterviewStages: [],
  created: false,
}

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset(state) {
      state.linkToken = ''
      state.url = ''
      state.atsJobs = []
      state.atsJobInterviewStages = []
      state.selectedATSJob = undefined
      state.selectedATSReferenceCheckJobInterviewStage = undefined
      state.selectedATSSelfAssessmentJobInterviewStage = undefined
      state.created = false
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setLinkToken(state, action: PayloadAction<string>) {
      state.linkToken = action.payload
    },
    setURL(state, action: PayloadAction<string>) {
      state.url = action.payload
    },
    setATSJobs(state, action: PayloadAction<Job[]>) {
      state.atsJobs = action.payload
    },
    setATSJobInterviewStages(state, action: PayloadAction<JobInterviewStage[]>) {
      state.atsJobInterviewStages = action.payload
    },
    setSelectedATSJob(state, action: PayloadAction<Job>) {
      state.selectedATSJob = action.payload
    },
    setSelectedATSReferenceCheckJobInterviewStage(state, action: PayloadAction<JobInterviewStage | undefined>) {
      state.selectedATSReferenceCheckJobInterviewStage = action.payload
    },
    setSelectedATSSelfAssessmentJobInterviewStage(state, action: PayloadAction<JobInterviewStage | undefined>) {
      state.selectedATSSelfAssessmentJobInterviewStage = action.payload
    },
    setCreated(state, action: PayloadAction<boolean>) {
      state.created = action.payload
    },
  },
})

export const {
  reset,
  setLoading,
  setLinkToken,
  setURL,
  setATSJobs,
  setATSJobInterviewStages,
  setSelectedATSJob,
  setSelectedATSReferenceCheckJobInterviewStage,
  setSelectedATSSelfAssessmentJobInterviewStage,
  setCreated,
} = slice.actions

export const installOAuthATS =
  (orgId: string, atsId: string): AppThunk =>
  async dispatch => {
    dispatch(setLoading(true))

    const [response, errors] = await api.integrations.installOAuthATS(orgId, atsId)
    dispatch(setLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(setURL(response.result.fields.url))
    }
  }

export const installLinkTokenATS =
  (orgId: string, atsId: string): AppThunk =>
  async dispatch => {
    dispatch(setLoading(true))

    const [response, errors] = await api.integrations.installLinkTokenATS(orgId, atsId)
    dispatch(setLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(setLinkToken(response.result.fields.link_token))
      dispatch(addEntities(response))
    }
  }

export const installLinkTokenATSAccount =
  (orgId: string, publicToken: string): AppThunk =>
  async dispatch => {
    dispatch(setLoading(true))
    const [response, errors] = await api.integrations.installLinkTokenATSAccount(orgId, publicToken)
    dispatch(setLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(notify({ success: 'Successfully added the integration!' }))
      dispatch(addEntities(response))
    }
  }

export const installAPIKeyATS =
  (orgId: string, atsId: string, apiKey: string): AppThunk =>
  async dispatch => {
    dispatch(setLoading(true))

    const [response, errors] = await api.integrations.installAPIKeyATS(orgId, atsId, apiKey)
    dispatch(setLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(addEntities(response))
    }
  }

export const installAPIKeyBasicAuthATS =
  (orgId: string, atsId: string, apiKey: string, url: string, username: string, password: string): AppThunk =>
  async dispatch => {
    dispatch(setLoading(true))

    const [response, errors] = await api.integrations.installAPIKeyBasicAuthATS(
      orgId,
      atsId,
      apiKey,
      url,
      username,
      password,
    )
    dispatch(setLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(addEntities(response))
    }
  }

export const getATSJobs =
  (integrationId: string, jobStatus: string): AppThunk =>
  async dispatch => {
    dispatch(setLoading(true))
    const [response, errors] = await api.integrations.getATSJobs(integrationId, jobStatus)
    dispatch(setLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(setATSJobs(response.result.fields))
    }
  }

export const getATSJobInterviewStages =
  (integrationId: string, atsJobId: string): AppThunk =>
  async dispatch => {
    dispatch(setLoading(true))
    const [response, errors] = await api.integrations.getATSJobInterviewStages(integrationId, atsJobId)
    dispatch(setLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(setATSJobInterviewStages(response.result.fields))
    }
  }

export const createOpenJobRoleIntegration =
  (integrationId: string, openJobRoleId: string, atsJobId: string, interviewStages: InterviewStage[]): AppThunk =>
  async dispatch => {
    dispatch(setLoading(true))
    const [response, errors] = await api.openjobroles.createIntegration(
      openJobRoleId,
      integrationId,
      atsJobId,
      interviewStages,
    )
    dispatch(setLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(setCreated(true))
      dispatch(addEntities(response))
      dispatch(notify({ success: 'Automation with ATS enabled' }))
    }
  }

export const deleteOpenJobRoleIntegration =
  (connectionId: string, openJobRoleId: string): AppThunk =>
  async dispatch => {
    dispatch(setLoading(true))
    const [response, errors] = await api.openjobroles.deleteIntegration(openJobRoleId, connectionId)
    dispatch(setLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(addEntities(response))
      dispatch(notify({ success: 'Automation with ATS has been disabled' }))
    }
  }

export const deleteOrgIntegration =
  (orgId: string, integrationId: string): AppThunk =>
  async dispatch => {
    dispatch(setLoading(true))
    const [response, errors] = await api.integrations.deleteOrgIntegration(orgId, integrationId)
    dispatch(setLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
    }

    if (response) {
      dispatch(notify({ success: 'Successfully deleted the integration!' }))
      dispatch(addEntities(response))
    }
  }

export default slice.reducer
