import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as api from '../../api'
import { AppThunk } from '../../core/store'
import { add as notify } from '../notifications'
import { addEntities } from '../resources'

const name = 'user-management'

interface State {
  loading: boolean
  users: { [orgId: string]: string[] }
  totalUsers: number
  hasMore: boolean
  organization: string
}

const initialState: State = {
  loading: false,
  users: {},
  totalUsers: 0,
  hasMore: true,
  organization: '',
}

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setUsers(state, action: PayloadAction<{ orgId: string; users: string[] }>) {
      const { orgId, users } = action.payload
      state.users[orgId] = users
    },
    addUsers(state, action: PayloadAction<{ orgId: string; users: string[] }>) {
      const { orgId, users } = action.payload
      state.users[orgId] = state.users[orgId].concat(users)
    },
    setTotalUsers(state, action: PayloadAction<number>) {
      state.totalUsers = action.payload
    },
    setHasMore(state, action: PayloadAction<boolean>) {
      state.hasMore = action.payload
    },
    setOrganization(state, action: PayloadAction<string>) {
      state.organization = action.payload
    },
  },
})

export const { setUsers, addUsers, setLoading, setTotalUsers, setHasMore, setOrganization } = slice.actions

export default slice.reducer

export const loadUsersByOrgId =
  (orgId: string, skip?: number, sort?: api.listing.Options['sort']): AppThunk =>
  async dispatch => {
    dispatch(setLoading(true))

    const [response, errors] = await api.listing.list<api.users.Fields>(`organizations/${orgId}/users`, {
      limit: 20,
      include: [api.profiles.RESOURCE, api.userPermissions.RESOURCE],
      filters: [api.listing.True('is_active')],
      sort: sort?.length
        ? sort
        : [
            {
              field: 'profiles.full_name',
              order: api.listing.SortOrder.Asc,
            },
          ],
      skip: skip || 0,
    })

    dispatch(setLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(addEntities(response))
      if (skip && skip > 0) {
        dispatch(addUsers({ orgId, users: response.result.map(row => row.fields.id) }))
      } else {
        dispatch(setUsers({ orgId, users: response.result.map(row => row.fields.id) }))
      }

      dispatch(setTotalUsers(response.list?.total || 0))
      dispatch(setHasMore(response.list?.has_more || false))
    }
  }

export const updateUserPermissions =
  (userid: string, updates: api.userPermissions.UpdatePayloadPermissions): AppThunk =>
  async dispatch => {
    dispatch(setLoading(true))

    const updated = Object.keys(updates)

    const [response, errors] = await api.userPermissions.updateUserPermissions(userid, {
      updates,
      updated,
    })

    dispatch(setLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(addEntities(response))

      dispatch(
        notify({
          success: "The selected user's permissions have been updated!",
        }),
      )
    }
  }

export const updateUserOrgAdmin =
  (id: string, isOrgAdmin: boolean): AppThunk =>
  async dispatch => {
    dispatch(setLoading(true))

    const [response, errors] = await api.users.setIsOrgAdmin(id, isOrgAdmin)

    dispatch(setLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(addEntities(response))

      dispatch(
        notify({
          success: isOrgAdmin
            ? 'Selected user has been set as organization admin.'
            : 'Organization admin status was removed from the selected user',
        }),
      )
    }
  }
