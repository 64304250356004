import { get, post } from './methods'
import * as request from './request'

export enum SortOrder {
  Asc = 'Asc',
  Desc = 'Desc',
}

enum FilterOperator {
  Eq = 'eq',
  Neq = 'neq',
  RoundEq = 'round_eq',
  Gt = 'gt',
  Lt = 'lt',
  Gte = 'gte',
  Lte = 'lte',
  In = 'in',
  Includes = 'includes',
  NotIncludes = '!includes',
  StartsWith = 'starts_with',
  EndsWith = 'ends_with',
  SimilarTo = 'similar_to',
  DateIs = 'date_is',
  DateIsNot = '!date_is',
  DateBefore = 'date_before',
  DateAfter = 'date_after',
  True = 'true',
  False = 'false',
  Empty = '',
}

export interface Filter {
  field: string
  value: number | string | undefined
  op: FilterOperator
}

export const Empty = createFilter(FilterOperator.Empty)
export const Eq = createFilter(FilterOperator.Eq)
export const Neq = createFilter(FilterOperator.Neq)
export const RoundEq = createFilter(FilterOperator.RoundEq)
export const Gt = createFilter(FilterOperator.Gt)
export const Lt = createFilter(FilterOperator.Lt)
export const Gte = createFilter(FilterOperator.Gte)
export const Lte = createFilter(FilterOperator.Lte)
export const In = createFilter(FilterOperator.In)
export const Includes = createFilter(FilterOperator.Includes)
export const NotIncludes = createFilter(FilterOperator.NotIncludes)
export const StartsWith = createFilter(FilterOperator.StartsWith)
export const EndsWith = createFilter(FilterOperator.EndsWith)
export const True = createFilter(FilterOperator.True)
export const False = createFilter(FilterOperator.False)
export const DateIs = createFilter(FilterOperator.DateIs)
export const DateIsNot = createFilter(FilterOperator.DateIsNot)
export const DateBefore = createFilter(FilterOperator.DateBefore)
export const DateAfter = createFilter(FilterOperator.DateAfter)

export type SortOption = {
  order: SortOrder
  field: string
}

export interface Options {
  filters?: Filter[]
  include?: string[]
  limit?: number
  skip?: number
  sort?: SortOption[]
  customQueries?: object
  body?: object
}

/*
 * Send a listing request to the API.
 *
 * Example usage:
 * api.list("/organization/:id/users", { filters: api.listing.True('is_active') })
 *
 */
export async function list<T>(endpoint: string, options: Options): Promise<request.APIResponse<request.Entity<T>[]>> {
  const qs = renderOptions(options)
  const url = `${endpoint}?${qs}`

  const req = options.body ? post<request.Entity<T>[]>(url, options.body) : get<request.Entity<T>[]>(url)

  const [resp, errors] = await req
  if (errors) {
    return [resp, errors]
  }

  return [resp, errors]
}

export function renderOptions(options: Options): string {
  const result = new URLSearchParams()

  if (options.filters !== undefined) {
    renderFilters(result, options.filters)
  }

  if (options.include !== undefined) {
    result.set('include', options.include.join(','))
  }

  if (options.limit !== undefined) {
    result.set('limit', `${options.limit}`)
  }

  if (options.skip !== undefined) {
    result.set('skip', `${options.skip}`)
  }

  const sort = options.sort?.map(({ order, field }) => `${order === SortOrder.Desc ? '-' : ''}${field}`)
  if (sort?.length) {
    result.set('sort', sort.join(','))
  }

  if (options.customQueries !== undefined) {
    for (const customQueryKey in options.customQueries) {
      result.set(customQueryKey, options.customQueries[customQueryKey])
    }
  }

  return result.toString()
}

function renderFilters(result: URLSearchParams, filters: Filter[]) {
  for (const f of filters) {
    if (f.value === undefined) {
      result.set(`${f.field}[${f.op}]`, '')
      continue
    }

    if (f.op === FilterOperator.Empty) {
      result.set(f.field, `${f.value}`)
      continue
    }

    result.set(`${f.field}[${f.op}]`, `${f.value}`)
  }
}

type FilterFunc = (field: string, value?: number | string | undefined) => Filter

function createFilter(op: FilterOperator): FilterFunc {
  return (field: string, value?: number | string | undefined): Filter => {
    return {
      field,
      value,
      op,
    }
  }
}
