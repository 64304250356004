import { size, space, style } from 'core'
import { text } from 'core/design-system/text'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import * as selectors from 'selectors'
import { RootState } from 'store'
import { SummaryContainer } from './SummaryContainer'

const AVContainer = style()
  .size({ height: size.stretch })
  .flex({ alignItems: 'center', justifyContent: 'center', direction: 'column' })
  .spacing({ gap: space.s })
  .element()

const CopyText = text.bodyText().text({ align: 'center' }).element('p')
const ResponsesLink = text.bodyInteractive().elementFromComponent(Link)

interface AudioVideoHintsBlockUIProps {
  detailsUrl?: string
}

const AudioVideoHintsBlockUI: React.FC<AudioVideoHintsBlockUIProps> = ({ detailsUrl }) => {
  return (
    <SummaryContainer emoji={'🔉'} title={'Audio/video feedback'} hintColor={'blue'} to={detailsUrl}>
      <AVContainer>
        <CopyText>One or more references have answered questions about the candidate with audio or video</CopyText>
        <ResponsesLink to={detailsUrl || ''}>See responses</ResponsesLink>
      </AVContainer>
    </SummaryContainer>
  )
}

interface AudioVideoHintsBlockProps {
  container?: React.FC
}

const AudioVideoHintsBlock: React.FC<AudioVideoHintsBlockProps> = ({ container }) => {
  const { roleid, candidateid } = useParams<{
    roleid: string
    candidateid: string
  }>()

  const responses = useSelector((state: RootState) =>
    selectors.questionnaireAnswers.findByCandidateId(state, candidateid || ''),
  )

  const anyAVResponse = useMemo(
    () =>
      Object.values(responses)
        .flat()
        .find(r => !!r?.media_url),
    [responses?.length],
  )

  const question = useSelector((state: RootState) =>
    selectors.questions.findById(state, anyAVResponse?.question_id || ''),
  )

  const detailsUrl = question?.fields.module_id
    ? `/roles/${roleid}/candidates/${candidateid}/responses#${question.fields.module_id}`
    : `/roles/${roleid}/candidates/${candidateid}/responses`

  if (!anyAVResponse) {
    return null
  }

  const Container = container || React.Fragment

  return (
    <Container>
      <AudioVideoHintsBlockUI detailsUrl={detailsUrl} />
    </Container>
  )
}

export default AudioVideoHintsBlock
