import React, { useState } from 'react'
import { Button } from '../../Button/Button'
import { Card } from '../../Card/Card'
import { Chip } from '../../Chip/Chip'
import { Dropdown, DropdownItem } from '../../Dropdown/Dropdown'
import { Text } from '../../Text/Text'
import { FeatureList } from '../FeatureList/FeatureList'
import classes from './PricingCard.module.scss'

export type CompanySize = (typeof companySizes)[number]
export const companySizes = [
  '1-15',
  '16-30',
  '31-50',
  '51-100',
  '101-200',
  '201-300',
  '301-400',
  '401-500',
  '501-750',
  '751-1,000',
  '1,001+',
] as const

type Price = {
  monthly?: number
  yearly?: number
  save?: number
  yearly_link?: string
  monthly_link?: string
  custom?: true
}

export type Prices = { [key in CompanySize | 'For all sizes']?: Price }

interface PricingCardProps {
  plan: {
    name: 'Free' | 'Grow' | 'Scale' | 'Enterprise'
    highlighted?: boolean
    prices: Prices
    description: string
    features: {
      title: string
      list: string[]
    }
  }
  period: 'monthly' | 'yearly'
  currency: 'USD' | 'EUR'
  initialCompanySize: string
  tracking: (plan: string, company_size: string) => void
}

export const PricingCard = (props: PricingCardProps) => {
  const [selectedCompanySize, setSelectedCompanySize] = useState<CompanySize>('1-15')

  const companySizeDisabled = !!props.initialCompanySize

  const selectedPrice = props.plan.prices[selectedCompanySize] || {}

  let monthlyPrice = selectedPrice.monthly
  if (props.period === 'yearly' && selectedPrice.yearly) {
    monthlyPrice = Math.floor(selectedPrice.yearly / 12)
  }

  return (
    <Card big className={`${classes.card} ${props.plan.highlighted ? classes.highlighted : ''}`}>
      {selectedPrice.save !== undefined && props.period === 'yearly' && (
        <Chip className={classes.chip}>Save ${selectedPrice.save.toLocaleString()}</Chip>
      )}
      <Text variant="card-header" className={classes.title}>
        {props.plan.name}
      </Text>
      <div className={classes.priceWrapper}>
        <Text variant="hero-text" className={classes.price}>
          {monthlyPrice !== undefined ? (
            <>
              ${monthlyPrice?.toLocaleString()}
              <span className={classes.pricePerMonth}>/month</span>
            </>
          ) : (
            'Custom'
          )}
        </Text>
        {props.period === 'yearly' && selectedPrice.yearly ? (
          <Text variant="body-text" className={classes.pricePerYear}>
            billed at ${selectedPrice.yearly.toLocaleString()} per year
          </Text>
        ) : (
          ''
        )}
      </div>
      <Text variant="body-text">Company size</Text>

      {(() => {
        const items = Object.keys(props.plan.prices)
        return (
          <Dropdown
            items={items}
            disabled={items.length <= 1 || companySizeDisabled}
            onSelect={(item: DropdownItem) => setSelectedCompanySize(item as CompanySize)}
            selectedDefault={props.initialCompanySize}
            testId={`company-size-dropdown-${props.plan.name}`}
          />
        )
      })()}

      <Text variant="body-text" className={classes.description}>
        {props.plan.description}
      </Text>
      <Button
        variant={props.plan.highlighted ? 'purple-dark' : 'tertiary-accent'}
        className={classes.button}
        onClick={() => {
          props.tracking(props.plan.name, selectedCompanySize)
          const link = props.period === 'yearly' ? selectedPrice.yearly_link : selectedPrice.monthly_link
          window.open(link || selectedPrice.yearly_link, '_blank')
        }}
        rounded
      >
        {props.plan.name === 'Free'
          ? 'Start for free'
          : props.plan.name === 'Enterprise' || selectedPrice.custom
          ? 'Contact sales'
          : 'Upgrade'}
      </Button>
      <div className={classes.features}>
        <FeatureList list={props.plan.features.list} title={props.plan.features.title} />
      </div>
    </Card>
  )
}
