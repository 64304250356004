import { AssessmentStatus, HiringStatus, OnboardingStatus, ReferenceCheckStatus } from 'api/candidates'

const StatusText: Record<Status, string> = {
  completed: 'Completed',
  'in progress': 'In progress',
  overdue: 'Overdue',
  'not started': 'Not started',
  not_started: 'Not started',
  pending: 'Pending',
  expired: 'Expired',
  'in-progress': 'In progress',
  in_progress: 'In progress',
  rejected: 'Rejected',
  hired: 'Hired',
}

export type Status = OnboardingStatus | ReferenceCheckStatus | AssessmentStatus | HiringStatus

export function statusToText(status: Status) {
  return StatusText[status] || String(status)
}

export const allHiringStatuses: HiringStatus[] = ['hired', 'in-progress', 'rejected']

export const allRefCheckStatuses: ReferenceCheckStatus[] = [
  'completed',
  'in_progress',
  'not_started',
  'pending',
  'expired',
]

export const allAssessmentStatuses: AssessmentStatus[] = ['overdue', 'in-progress', 'completed']
