import DropdownField, { Option } from 'components/DropdownField'
import { rem, size, style } from 'core'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Block, { Header } from '../../components/Block'
import Button from '../../components/Button/ButtonV1'
import Form from '../../components/Form'
import TextField from '../../components/TextField'
import * as selectors from '../../selectors'
import { RootState } from '../../store'
import { simpleATSImport } from '../../store/settings/org'

const Row = style()
  .grid({ columns: [size.auto, rem(16)] })
  .spacing({ column: size.m })
  .element()

const Component = () => {
  const dispatch = useDispatch()
  const [url, setURL] = useState<string | undefined>(undefined)
  const [language, setLanguage] = useState<string | undefined>(undefined)

  const loading = useSelector((state: RootState) => state.orgSettings.loading)
  const org = useSelector((state: RootState) => selectors.orgs.getById(state, selectors.orgs.currentId(state)))

  const save = () => {
    if (!org || !url || !language) return
    const urlClean = url.replace(/\/*$/, '')
    dispatch(simpleATSImport(org.id, `${urlClean}/xml?language=${language}`))
  }

  return (
    <Block title="Import jobs from Personio" testId="PersonioImport">
      <Header>
        Provide a link to your public jobs page in Personio, and we will create HiPeople jobs to match them.
      </Header>
      <Form>
        <Row>
          <TextField
            label="Enter Jobs page URL"
            value={''}
            placeholder={'https://acme.jobs.personio.de'}
            change={url}
            onChange={e => setURL(e.target.value)}
          />
          <DropdownField<string | undefined>
            label="Language"
            placeholder="Please select a language"
            onChange={id => setLanguage(id)}
            value={language}
          >
            <Option value="de">German</Option>
            <Option value="en">English</Option>
            <Option value="fr">French</Option>
            <Option value="es">Spanish</Option>
            <Option value="nl">Dutch</Option>
            <Option value="it">Italian</Option>
            <Option value="pt">Portuguese</Option>
          </DropdownField>
        </Row>
        <div></div>
        <Button primary onClick={save} disabled={!url || !language} loading={loading}>
          Import
        </Button>
      </Form>
    </Block>
  )
}

export default Component
Component.displayName = 'SimpleATSImport'
