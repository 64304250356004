import { Table, TableHeader, ToggleSwitch } from '@common/components'
import { WarningIcon } from 'App/RoleCreation/TemplateCreationAssessmentV2/ContentSelection/WarningIcon'
import { LibraryItemData, LibrarySelection } from 'App/RoleCreation/TemplateCreationSelfAssessment/selection'
import { Badge } from 'components/Badge'
import { Icon } from 'components/Icons'
import { px2rem, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import { isPresent } from 'core/utils'
import React from 'react'
import { plural } from 'selectors'
import * as tracking from '../../../../core/track'
import classes from './ContentSelectionTable.module.scss'
import { slugify } from '@common/utils/slugify'

const TestNameText = text.bodyEmphasis().nooverflow().sans({ ellipsis: true }).spacing({ innerRight: '1rem' }).element()

const DescriptionText = ({ children, title }: { children: string; title?: string }) => {
  const descriptionRef = React.useRef<HTMLDivElement>(null)

  const [shouldHover, setShouldHover] = React.useState(false)

  React.useEffect(() => {
    if (descriptionRef.current) {
      const height = descriptionRef.current.scrollHeight
      const wrapperHeight = descriptionRef.current.parentElement?.clientHeight ?? height
      setShouldHover(height > wrapperHeight)
    }
  }, [descriptionRef])

  return (
    <div className={[classes.descriptionWrapper, shouldHover && classes.shouldHover].join(' ')}>
      <div className={classes.descriptionOverlay} children={children} />
      <div className={classes.description} title={title} ref={descriptionRef}>
        {children}
      </div>
    </div>
  )
}

const PreviewButton = style()
  .transparent()
  .noborders()
  .color({ fg: designSystemColors.typographySecondary })
  .size({ width: px2rem(32), height: px2rem(32) })
  .border({ radius: '50%' })
  .pointer()
  .center()
  .element('button')

/*
 getOverlapping returns an array of selected library items (selection)
  that overlap with the given library item (item).
*/
function getOverlapping(item: LibraryItemData, selection: LibrarySelection): string[] {
  if (item.overlapped_by) {
    // This is the map of a library item ID with trimmed locale to the full ID:
    // {"inventories:bfas" => "inventories:bfas:en_US"}.
    // We need to use it to map `overlapped_by` IDs which don't contain a locale,
    // returning the full ID with locale from this function at the same time, because of further logic.
    const trimmedLocaleMap = new Map(
      [...selection.libraryItems.set].map(li => [li.substring(0, li.lastIndexOf(':')), li]),
    )
    return item.overlapped_by.map(id => trimmedLocaleMap.get(id)).filter(id => id !== undefined) as string[]
  }

  return []
}

const WarningIconContainer = style().spacing({ outerLeft: '-38px', outerRight: '3px' }).element()

const ToggleSwitchWrapper = ({
  item,
  suggestions,
  selection,
  libraryItemFromId,
  setLibraryItemIDForPreview,
}: {
  item: LibraryItemData
  suggestions: string[]
  selection: LibrarySelection
  libraryItemFromId: (id: string) => LibraryItemData | undefined
  setLibraryItemIDForPreview: (id: string) => void
}) => {
  const overlapping = getOverlapping(item, selection)
    .map(id => libraryItemFromId(id))
    .filter(isPresent)

  const disabled = overlapping.length > 0

  const toggle = (
    <ToggleSwitch
      on={selection.libraryItems.has(item.id)}
      aria-label={`Include ${item.name} in assessment`}
      name={item.name}
      disabled={disabled}
      setOn={() => {
        // track ai module added
        suggestions.includes(item.id)
          ? tracking.contentSelection.aiLibraryItemToggleClicked(item.name)
          : tracking.contentSelection.libraryItemToggleClicked(item.name)

        // TODO do this when a module has questions that have config options instead of hardcoding module ids
        if (item.id.includes('modules:culturefitcheck')) {
          setLibraryItemIDForPreview(item.id)
          selection.libraryItems.has(item.id) && selection.libraryItems.remove(item.id)
        } else {
          // @ts-expect-error
          selection.libraryItems.toggle(item.id)
        }
      }}
    />
  )

  if (disabled) {
    const message = `Warning: this content cannot be selected because it is already included in the ${plural(
      'module',
      overlapping.length,
    )}: ${overlapping.map(item => item.name).join(', ')}.`

    return (
      <>
        <WarningIconContainer>
          <WarningIcon message={message} tooltipDirection={'left'} />
        </WarningIconContainer>
        {toggle}
      </>
    )
  }

  return toggle
}

interface ContentSelectionTableProps {
  selection: LibrarySelection
  matchingLibraryItems: string[]
  libraryItemFromId: (id: string) => LibraryItemData | undefined
  setLibraryItemIDForPreview: (id: string) => void
  onScrollNext: () => void
  suggestions: string[]
}

export const ContentSelectionTable: React.FC<ContentSelectionTableProps> = props => {
  const data = React.useMemo(
    () =>
      [
        // Add AI suggestions first
        ...props.suggestions.map(id => {
          const item = props.libraryItemFromId(id)
          if (!item) {
            return null
          }

          return {
            ...item,
            style: {
              backgroundColor: designSystemColors.informationYellowBackground,
            },
          }
        }),
        ...props.matchingLibraryItems.map(id => {
          const item = props.libraryItemFromId(id)
          if (!item || props.suggestions.includes(id)) {
            return null
          }
          return {
            ...item,
          }
        }),
      ].filter(isPresent),
    [props.matchingLibraryItems, props.suggestions],
  )

  const headers: TableHeader<LibraryItemData>[] = [
    {
      key: 'area',
      name: 'Area',
      colWidth: '60px',
      align: 'center',
      render: item => <Badge copy={''} icon={item.group_icon} theme={item.group_theme} />,
    },
    {
      key: 'testName',
      name: 'Test name',
      colWidth: '15%',
      render: item => <TestNameText title={item.name}>{item.name}</TestNameText>,
    },
    {
      key: 'description',
      name: 'Description',
      render: item => <DescriptionText title={item.description}>{item.description}</DescriptionText>,
    },
    {
      key: 'time',
      name: 'Time',
      colWidth: '80px',
      align: 'center',
      render: item => <span>{Math.floor(item.duration_seconds / 60)} min</span>,
    },
    {
      key: 'preview',
      name: 'Preview',
      align: 'center',
      colWidth: '80px',
      render: item => (
        <PreviewButton
          data-testid={`test-preview-toggle-button-${slugify(item.name)}`}
          onClick={() => props.setLibraryItemIDForPreview(item.id)}
        >
          <Icon name={'eye'} />
        </PreviewButton>
      ),
    },
    {
      key: 'add',
      name: 'Add',
      colWidth: '80px',
      align: 'center',
      render: item => (
        <ToggleSwitchWrapper
          item={item}
          suggestions={props.suggestions}
          selection={props.selection}
          libraryItemFromId={props.libraryItemFromId}
          setLibraryItemIDForPreview={props.setLibraryItemIDForPreview}
        />
      ),
    },
  ]

  return (
    <Table
      style={{ height: 'unset' }}
      headers={headers}
      data={data}
      onReachBottom={props.onScrollNext}
      noPadding
      testId="content-selection-table"
    />
  )
}
