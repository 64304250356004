import { px2rem, space, style } from 'core'
import React from 'react'
import { designSystemColors } from '../../core/design-system/colors'
import { text } from '../../core/design-system/text'
import ElementTooltip from '../Tooltip/ElementTooltip'

const OptionContainer = style().flex({ alignItems: 'center' }).element()

const Relative = style().relative().element()
const AbsoluteTooltip = style().absolute({ top: space.m, right: space.m }).elementFromComponent(ElementTooltip)

type WithTooltipProps = {
  text: string
  children: React.ReactNode
}

const WithTooltip: React.FC<WithTooltipProps> = props => {
  return (
    <Relative>
      <AbsoluteTooltip tooltipCopy={props.text} targetElement={props.children} />
    </Relative>
  )
}
const RadioButtonBackground = style()
  .border({
    around: '1px solid',
    color: designSystemColors.borderDefault,
    radius: '50%',
  })
  .bg({ color: designSystemColors.backgroundNeutralSecondary })
  .size({ width: px2rem(16), height: px2rem(16) })
  .element()

const RadioButtonInput = style()
  .set('appearance', 'none')
  .set('WebkitAppearance', 'none')
  .set('MozAppearance', 'none')
  .size({ width: px2rem(14), height: px2rem(14) })
  .nospacing()
  .border({
    radius: '50%',
  })
  .select(
    ':checked',
    style().bg({ color: designSystemColors.uiStatusSuccess }).border({
      around: '3px solid',
      color: designSystemColors.backgroundNeutralSecondary,
      radius: '50%',
    }),
  )
  .pointer()
  .cond(({ disabled }: { disabled: boolean }) => disabled, style().cursor('not-allowed'))
  .element('input')

const InfoContainer = style()
  .spacing({ innerLeft: px2rem(16), gap: px2rem(8) })
  .flex({ direction: 'column', justifyContent: 'center' })
  .size({ width: '100%' })
  .element()

const Label = text
  .bodyInteractive()
  .pointer()
  .cond(({ disabled }: { disabled: boolean }) => disabled, style().cursor('not-allowed').set('opacity', '0.5'))
  .size({ width: '100%' })
  .element('label')

const Description = text.bodyText().element()

export interface RadioButtonProps<T> {
  // handled by RadioGroup
  id?: string
  onChange?: () => void
  isChecked?: boolean

  // handled by user
  value: T
  children: React.ReactNode
  description?: string
  disabled?: boolean
  header?: React.ReactNode
  footer?: React.ReactNode
  tooltip?: { copy: string }
}

export function RadioButton<T>(props: RadioButtonProps<T>) {
  return (
    <OptionContainer>
      {props.tooltip ? (
        <WithTooltip text={props.tooltip.copy}>
          <RadioButtonBackground>
            <RadioButtonInput
              type="radio"
              name={props.id}
              id={props.id}
              checked={props.isChecked}
              value={props.value}
              onChange={props.onChange}
              disabled={props.disabled}
            />
          </RadioButtonBackground>
        </WithTooltip>
      ) : (
        <RadioButtonBackground>
          <RadioButtonInput
            type="radio"
            name={props.id}
            id={props.id}
            checked={props.isChecked}
            value={props.value}
            onChange={props.onChange}
            disabled={props.disabled}
          />
        </RadioButtonBackground>
      )}
      <InfoContainer>
        {props.header}
        <Label for={props.id} disabled={props.disabled}>
          {props.children}
          {props.description && <Description>{props.description}</Description>}
        </Label>
        {props.footer}
      </InfoContainer>
    </OptionContainer>
  )
}
