import { LanguageDropdown, LockedByPlan } from '@common/components'
import { RoleCreationModulesValues } from 'App/RoleCreation/RoleCreationModules'
import Button from 'components/Button/ButtonV2'
import TextField, { Label } from 'components/TextField'
import { px2rem } from 'core'
import { text } from 'core/design-system/text'
import { lockedContentTracking } from 'core/track/locked-content'
import React from 'react'
import { useSelector } from 'react-redux'
import * as selectors from 'selectors'
import * as tracking from '../../core/track'
import { Buttons, Container, Description, TextFields, Title } from './Shared'
import { AssessmentLocale, AvailableAssessmentLocales } from '@common/models/locales'

const ExternalRoleTitleDescription = text
  .bodyText()
  .spacing({ outerTop: px2rem(10), outerBottom: px2rem(40) })
  .element('p')

const AssessmentLocaleDescription = text
  .bodyText()
  .spacing({ outerTop: px2rem(10), outerBottom: px2rem(20) })
  .element('p')

const InternalRoleTitleDescription = text
  .bodyText()
  .spacing({ outerTop: px2rem(10), outerBottom: px2rem(40) })
  .element('p')

const FieldDescription = text
  .bodyText()
  .spacing({ outerTop: px2rem(20), outerBottom: px2rem(20) })
  .element('p')

export type RoleCreationDetailsValues = {
  internalRoleTitle: string
  externalRoleTitle: string
  description: string
  assessmentLocale: AssessmentLocale
}

interface RoleCreationDetailsProps {
  values: RoleCreationDetailsValues
  modules: RoleCreationModulesValues
  onChange: React.Dispatch<React.SetStateAction<RoleCreationDetailsValues>>
  back: (() => any) | undefined
  next: () => any
  isEditModeEnabled?: boolean
}

export const RoleCreationDetails: React.FC<RoleCreationDetailsProps> = ({
  values,
  modules,
  onChange,
  back,
  next,
  isEditModeEnabled,
}) => {
  const isNextButtonDisabled =
    values.externalRoleTitle.length < 3 || (values.internalRoleTitle.length < 3 && values.internalRoleTitle.length > 0)

  const isOrgPaidPlan = useSelector(selectors.orgs.isOrgPaidPlan)

  const onSelectAssessmentLocale = (lang: AssessmentLocale) => {
    values.assessmentLocale = lang
  }

  const isSelfAssessmentAdded = modules.isSelfAssessmentAdded
  const isRefCheckAdded = modules.isReferenceFeedbackAdded

  return (
    <Container>
      <Title data-testid="role-creation-details-title">Specify details</Title>
      <Description>
        {`Specify the details of the job you are ${isEditModeEnabled ? 'editing' : 'creating'}.`}
      </Description>
      <TextFields>
        <TextField
          label="🌎 Job title"
          placeholder="e.g. Senior Product Manager"
          value={values.externalRoleTitle}
          onChange={e => {
            if (
              values.internalRoleTitle === values.externalRoleTitle ||
              // or a user highlights external title and hits a letter key instead of backspace
              (values.externalRoleTitle.length === 1 && values.internalRoleTitle === '')
            ) {
              onChange(values => ({
                ...values,
                externalRoleTitle: e.target.value,
                internalRoleTitle: e.target.value,
              }))
            } else {
              onChange(values => ({
                ...values,
                externalRoleTitle: e.target.value,
              }))
            }
          }}
          testId="role-creation-external-role-title"
        />
        <ExternalRoleTitleDescription>
          {'This is what the candidates and references will see in the surveys.'}
        </ExternalRoleTitleDescription>
        <LockedByPlan
          tracking={lockedContentTracking}
          locked={!isOrgPaidPlan}
          lockIconStyle={{ marginRight: '1rem' }}
          upgradeTo={'Scale'}
          modalData={{
            learnMoreLink: 'https://intercom.help/hipeople/en/articles/7066614-create-a-new-job',
            learnMoreAbout: 'Internal Job Titles',
          }}
        >
          <TextField
            label="🔍 Internal Job title (optional)"
            placeholder="e.g. Senior Product Manager - Berlin"
            value={values.internalRoleTitle}
            onChange={e =>
              onChange(values => ({
                ...values,
                internalRoleTitle: e.target.value,
              }))
            }
            testId="role-creation-internal-role-title"
          />
        </LockedByPlan>
      </TextFields>
      <InternalRoleTitleDescription>
        {'Here you can give it a more descriptive name for internal use.'}
      </InternalRoleTitleDescription>
      <TextFields>
        <TextField
          multiline
          label="📜 Job description (optional)"
          value={values.description}
          onChange={e => onChange(values => ({ ...values, description: e.target.value }))}
          placeholder="e.g. Able to take on the responsibility of defining and driving the strategic direction of the company's product, collaborating with cross-functional teams, and ensuring alignment with customer needs and business objectives to deliver a successful and impactful product to the market."
        />
        <FieldDescription>
          This description will be analyzed by our AI to suggest the most effective assessment module and reference
          check questions.
        </FieldDescription>
      </TextFields>
      {isSelfAssessmentAdded && (
        <>
          <Label>🌐 Assessment language</Label>
          <LanguageDropdown
            locales={AvailableAssessmentLocales}
            defaultLocale={values.assessmentLocale}
            onSelectLocale={onSelectAssessmentLocale}
          />
          <AssessmentLocaleDescription>
            Selected language will be used throughout the assessment and in any communications with the candidate.
            {isRefCheckAdded && (
              <>
                <b>Note: this language selection does not affect reference checks.</b>
              </>
            )}
          </AssessmentLocaleDescription>
        </>
      )}
      <Buttons>
        {back ? (
          <Button buttonType="secondary" onClick={back} isFullWidth>
            {'Back'}
          </Button>
        ) : (
          <div />
        )}
        <Button
          buttonType="primary"
          onClick={() => {
            tracking.roles.roleCreationDetails()
            if (values.internalRoleTitle.length < 1) {
              onChange(values => ({
                ...values,
                internalRoleTitle: values.externalRoleTitle,
              }))
            }
            next()
          }}
          disabled={isNextButtonDisabled}
        >
          {'Next step'}
        </Button>
      </Buttons>
    </Container>
  )
}
