import { RejectionReason } from 'api/reference'
import { Profile } from 'App/Reference/ProfileV2'
import FeedbackStyleBadge from 'components/FeedbackStyleBadge'
import { Icon } from 'components/Icons'
import Modal from 'components/Modal'
import { RelationshipLabel } from 'components/RelationshipLabel'
import VerificationBadge from 'components/VerificationBadge'
import { size, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import * as tracking from 'core/track'
import React, { useState } from 'react'
import { FeedbackStyleType } from 'selectors/halo-score'
import { Relationship } from 'selectors/references'
import { colors, px2rem, space } from '../../core'

const RelationshipContainer = style().inline().element()

const Name = text
  .bodyInteractive()
  .text({ ellipsis: true })
  .spacing({ outerBottom: px2rem(4) })
  .size({ width: '100%' })
  .element('p')

const DetailsSection = text
  .label()
  .block()
  .spacing({
    outerTop: space.auto,
    outerBottom: space.xxxs,
    innerTop: space.m,
  })
  .color({ fg: designSystemColors.typographySecondary })
  .element('span')

const DetailsText = style().spacing({ outerLeft: space.xxxs }).element('span')

const ProfileContainer = style()
  .size({ maxWidth: px2rem(1024) })
  .spacing({ inner: space.s })
  .element()

const RelationshipVerification = style()
  .flex({ alignItems: 'center' })
  .spacing({ gap: px2rem(8) })
  .element()

export const ReferenceAnswerSidebarStyle = style()
  .bg({ color: colors.transparent })
  .border({ around: 'none' })
  .text({ align: 'left' })
  .pointer()
  .flex({
    direction: 'column',
    alignItems: 'flex-start',
  })
  .size({ width: size.fill })
  .spacing({ inner: px2rem(16) })
  .relative()
  .select('> * + *', style().spacing({ outerTop: space.xxxs }))
  .select(':hover > *:last-child', style().visible())
  .select('> *:last-child', style().invisible())
  .select(':hover', style().color({ bg: designSystemColors.backgroundNeutralPrimaryHover }))
  .element('button')

export type ReferenceDetails = {
  relationship: Relationship
  professionalNetwork?: {
    name: string
    url: string
  }
  name: string
  email: string
  companyName: string
  from: number
  to: number
  feedbackStyle?: FeedbackStyleType
  rejectionReason?: RejectionReason
  isReviewed: boolean
  isVerified: boolean
}
interface ReferenceAnswerSidebarProps {
  candidateName: string
  reference: ReferenceDetails
}

export const ReferenceAnswerSidebar: React.FC<ReferenceAnswerSidebarProps> = ({ reference, candidateName }) => {
  const {
    relationship,
    professionalNetwork,
    email,
    companyName,
    from,
    to,
    feedbackStyle,
    name,
    rejectionReason,
    isReviewed,
    isVerified,
  } = reference
  const [isDetailsShown, setDetailsShown] = useState(false)
  const isSelfReference = relationship === Relationship.Self
  return (
    <>
      <ReferenceAnswerSidebarStyle onClick={() => setDetailsShown(true)}>
        <Name className="data-hj-suppress">{name}</Name>
        <RelationshipVerification>
          <RelationshipContainer>
            <RelationshipLabel type={relationship} />
          </RelationshipContainer>
          {!isSelfReference && (
            <>
              <VerificationBadge verified={isVerified} reviewed={isReviewed} />

              {feedbackStyle && <FeedbackStyleBadge style={feedbackStyle} />}
            </>
          )}
        </RelationshipVerification>
        <DetailsSection onClick={() => tracking.references.showDetails()}>
          <Icon name={'info-circle'} />
          <DetailsText>{'Details'}</DetailsText>
        </DetailsSection>
      </ReferenceAnswerSidebarStyle>
      <Modal open={isDetailsShown} setOpen={setDetailsShown} title={name}>
        <ProfileContainer>
          <Profile
            relationship={relationship}
            professionalNetwork={professionalNetwork}
            email={email}
            companyName={companyName}
            from={from}
            to={to}
            feedbackStyle={feedbackStyle}
            name={name}
            candidateName={candidateName}
            rejectionReason={rejectionReason}
            isReviewed={isReviewed}
            isVerified={isVerified}
          />
        </ProfileContainer>
      </Modal>
    </>
  )
}
