import React from 'react'
import { useSelector } from 'react-redux'
import * as api from '../../api'
import { Icon } from '../../components/Icons'
import { colors, rem, size, space, style } from '../../core'
import * as selectors from '../../selectors'
import { RootState } from '../../store'
import TextField from '../TextField'
import { Form } from '../Wizard'

interface Row {
  full_name: string
  email: string
}

interface Props {
  row: Row
  setRow: (row: Row) => void
}

const FormatIcon = style()
  .text({ size: rem(1.2) })
  .color({ fg: colors.lightSilver })
  .round('5px')
  .element('header')

const Container = style().element()

const IconColumn = style()
  .cond(
    ({ disabled }: { disabled: boolean }) => !disabled,
    style().select(':hover header', style().hidden()).select(':hover nav', style().flex()),
  )
  .spacing({ outerTop: size.auto, outerBottom: size.xs })
  .center()
  .element()

const Candidate = style()
  .grid({ columns: [rem(1.7), size.auto, size.auto] })
  .spacing({ columns: space.s, rows: space.m })
  .element()

const Component = (props: Props) => {
  const sentInvites = useSelector((state: RootState) => state.newRole.sentInvites)

  const sentAlready = sentInvites.includes(props.row.email)
  const invalidEmail = !selectors.isEmailValid(props.row.email)

  return (
    <Container>
      <Form>
        <Candidate>
          <IconColumn withLabel disabled={sentAlready}>
            <FormatIcon>
              <Icon name={sentAlready ? 'check' : 'paper-plane'} />
            </FormatIcon>
          </IconColumn>
          <TextField
            error={!!props.row.email && invalidEmail}
            label="Email"
            value={props.row.email}
            placeholder="e.g sue@brown.org"
            disabled={sentAlready}
            onChange={e => onChange({ ...props.row, email: e.target.value })}
            testId={'candidate-email-textfield'}
          />
          <TextField
            label="Name"
            value={props.row.full_name}
            placeholder="e.g Sue Brown"
            disabled={sentAlready}
            onChange={e => onChange({ ...props.row, full_name: e.target.value })}
            testId={'candidate-name-textfield'}
          />
        </Candidate>
      </Form>
    </Container>
  )

  function onChange(candidate: api.openjobroles.Candidate) {
    props.setRow({ ...props.row, ...candidate })
  }
}

export default Component
Component.displayName = 'InviteForm'
