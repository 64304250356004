import { APIResponse, Entity, Errors, Success } from 'api/request'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { addEntities } from 'store/resources'

const useQuery = <T>(apiCall: () => Promise<APIResponse<Entity<T>[]>>): [Success<Entity<T>[]>?, Errors?] => {
  const dispatch = useDispatch()

  const [data, setData] = useState<Success<Entity<T>[]> | undefined>(undefined)
  const [errors, setErrors] = useState<Errors | undefined>(undefined)

  useEffect(() => {
    apiCall()
      .then(response => {
        const [resp, errors] = response
        if (errors) {
          throw errors
        }
        if (!resp?.result) return
        dispatch(addEntities(resp))
        setData(resp)
      })
      .catch(errors => {
        setErrors(errors)
      })
  }, [])

  return [data, errors]
}

export default useQuery
