import action from './action'
import events from './events'
import track from './track'

export const toggleBillingPeriodClicked = action(() => {
  track(events.PRICING_PAGE_BILLING_PERIOD_TOGGLED, {})
})

export const upgradeButtonClicked = action((product: string, plan: string, companySize: string) => {
  track(events.PRICING_PAGE_UPGRADE_BUTTON_CLICKED, {
    product,
    plan,
    companySize,
  })
})
