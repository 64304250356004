import { style } from 'core'
import React from 'react'
import { RadioButtonProps } from './index'

const RadioGroupContainer = style().element()

interface RadioGroupProps<T> {
  className?: string
  value: T
  onChange: (v: T) => void
  disabled?: boolean
  children: React.ReactElement<RadioButtonProps<T>>[]
}

function RadioGroup<T>(props: RadioGroupProps<T>) {
  return (
    <RadioGroupContainer className={props.className}>
      {props.children.map(c => {
        return React.cloneElement(c, {
          id: JSON.stringify(c.props.value),
          key: JSON.stringify(c.props.value),
          isChecked: c.props.value === props.value,
          onChange: () => props.onChange(c.props.value),
          disabled: c.props.disabled || props.disabled,
        })
      })}
    </RadioGroupContainer>
  )
}

export default RadioGroup
