import { Nav, NavItem, NavLink, NavLinkText, NavList } from 'App/Candidate/TabSelector'
import * as tracking from 'core/track'
import React from 'react'
import { useLocation } from 'react-router-dom'

interface TabSelectorProps {
  content?: boolean
}
const TabSelector: React.FC<TabSelectorProps> = () => {
  const { pathname } = useLocation()
  const scores = `/candidateexperience/scores`
  const feedback = `/candidateexperience/feedback`

  return (
    <Nav>
      <NavList>
        <NavItem>
          <NavLink
            isActive={pathname === scores}
            to={scores}
            onClick={() => tracking.candidateExperience.candidateExperienceScoresTabClicked()}
          >
            <NavLinkText>{'Scores'}</NavLinkText>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            isActive={pathname.startsWith(feedback)}
            to={feedback}
            onClick={() => tracking.candidateExperience.candidateExperienceFeedbackTabClicked()}
          >
            <NavLinkText>{'Feedback'}</NavLinkText>
          </NavLink>
        </NavItem>
      </NavList>
    </Nav>
  )
}

export default TabSelector
