import { RejectionReason } from 'api/reference'
import { Details, Label, Row, RowIcon } from 'components/Block'
import FeedbackStyleBadge from 'components/FeedbackStyleBadge'
import RelationshipLabel from 'components/RelationshipLabel'
import { NotVerified, Unreviewed, Verified } from 'components/VerificationBadge'
import { px2rem, space, style } from 'core'
import { designSystemColors as designColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React from 'react'
import { FeedbackStyleType } from 'selectors/halo-score'
import { Relationship } from 'selectors/references'

const VerifiedStatusContainer = text
  .smallBody()
  .color({ bg: designColors.backgroundNeutralSecondary })
  .flex({ alignItems: 'center' })
  .select('svg', style().spacing({ outerRight: space.xs }))
  .round(px2rem(6))
  .spacing({ inner: space.xs, outerTop: space.s })
  .element()

interface VerifiedStatusProps {
  isReviewed: boolean
  isVerified: boolean
  candidateName: string
  referenceName: string
  companyName: string
  rejectionReason?: RejectionReason
}

const VerifiedStatus: React.FC<VerifiedStatusProps> = ({
  isVerified,
  isReviewed,
  candidateName,
  referenceName,
  companyName,
  rejectionReason,
}) => {
  if (isVerified)
    return (
      <VerifiedStatusContainer>
        <Verified /> The relation between the candidate and the reference has been verified by HiPeople.
      </VerifiedStatusContainer>
    )

  if (isReviewed)
    return (
      <VerifiedStatusContainer>
        <NotVerified /> {rejectReasonSentence(candidateName, referenceName, companyName, rejectionReason)}
      </VerifiedStatusContainer>
    )

  return (
    <VerifiedStatusContainer>
      <Unreviewed /> Verification pending
    </VerifiedStatusContainer>
  )
}

const rejectReasonSentence = (
  candidateName: string,
  referenceName: string,
  companyName: string,
  reason?: RejectionReason,
): string => {
  switch (reason) {
    case 'candidate_professional_profile_missing':
      return `Unfortunately, this relationship could not be verified as no professional network profile of ${candidateName} was given.`
    case 'reference_professional_profile_missing':
      return `Unfortunately, this relationship could not be verified as no professional network profile of ${referenceName} was given.`
    case 'both_professional_profile_missing':
      return `Unfortunately, this relationship could not be verified as no professional network profile of ${candidateName} and ${referenceName} was given.`
    case 'candidate_shared_company_missing':
      return `Unfortunately, this relationship could not be verified as ${candidateName}'s employment at ${companyName} could not be confirmed.`
    case 'reference_shared_company_missing':
      return `Unfortunately, this relationship could not be verified as ${referenceName}'s employment at ${companyName} could not be confirmed.`
    case 'both_shared_company_missing':
      return `Unfortunately, this relationship could not be verified as neither ${candidateName}'s nor ${referenceName}'s employment at ${companyName} could  be confirmed.`
    case 'candidate_shared_time_deviating':
      return `Unfortunately, this relationship could not be verified as ${candidateName} didn't work there in the given time span according to their professional network profile.`
    case 'reference_shared_time_deviating':
      return `Unfortunately, this relationship could not be verified as ${referenceName} didn't work there in the given time span according to their professional network profile.`
    case 'both_shared_time_deviating':
      return `Unfortunately, this relationship could not be verified as neither ${candidateName} nor ${referenceName} worked there in the given time span according to their professional network profiles.`
    case 'reference_refused_verification':
      return `Unfortunately, this relationship could not be verified as ${referenceName} refused the verification process.`
    default:
      return 'Unfortunately, this relationship could not be verified.'
  }
}

const ProfileStyle = style().element()

interface ProfileProps {
  candidateName: string
  relationship: Relationship
  professionalNetwork?: {
    name: string
    url: string
  }
  name: string
  email: string
  companyName: string
  from: number
  to: number
  feedbackStyle?: FeedbackStyleType
  rejectionReason?: RejectionReason
  isReviewed: boolean
  isVerified: boolean
}

export const Profile: React.FC<ProfileProps> = ({
  relationship,
  professionalNetwork,
  email,
  companyName,
  from,
  to,
  feedbackStyle,
  name,
  candidateName,
  rejectionReason,
  isReviewed,
  isVerified,
}) => {
  const isSelfReferenceProfile = relationship === Relationship.Self

  const rows: {
    value: React.ReactNode
    label: string
    emoji?: string
    link?: string
  }[] = []

  rows.push({
    emoji: '👔',
    label: 'Reference',
    value: <RelationshipLabel type={relationship} />,
  })

  rows.push({
    emoji: '📧',
    label: 'Email',
    value: email,
  })

  professionalNetwork &&
    rows.push({
      label: `Professional Network`,
      emoji: '🔗',
      value: professionalNetwork?.name,
      link: professionalNetwork.url,
    })

  if (!isSelfReferenceProfile) {
    rows.push({
      label: `Company`,
      emoji: '🏢',
      value: companyName,
    })

    rows.push({
      label: `Worked with candidate`,
      emoji: '🤝',
      value: `${from} to ${to}`,
    })

    feedbackStyle &&
      rows.push({
        emoji: '💬',
        label: 'Feedback style',
        link: 'https://hipeople.notion.site/Feedback-Styles-Public-Documentation-d0a2460d701842a5846ac6d191de9e87',
        value: (
          <>
            {feedbackStyle} <FeedbackStyleBadge style={feedbackStyle} />
          </>
        ),
      })
  }
  return (
    <ProfileStyle className="data-hj-suppress">
      <Details>
        {rows.map((row, i) => (
          <Row key={`row-${i}`}>
            <RowIcon>{row.emoji}</RowIcon>
            <Label nooverflow>
              <strong>{row.label}:</strong>
              {row.link ? (
                <a href={row.link} target="_blank" rel="noopener noreferrer">
                  {row.value}
                </a>
              ) : (
                row.value
              )}
            </Label>
          </Row>
        ))}
      </Details>
      {!isSelfReferenceProfile && (
        <VerifiedStatus
          isReviewed={isReviewed}
          isVerified={isVerified}
          candidateName={candidateName}
          referenceName={name}
          rejectionReason={rejectionReason}
          companyName={companyName}
        />
      )}
    </ProfileStyle>
  )
}
