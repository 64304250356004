import React from 'react'
import ReactDOM from 'react-dom'
import { zIndex } from '../../styles/zIndex'
interface PortalProps extends React.ComponentProps<'div'> {
  label?: string
  portalId?: string
  children: React.ReactNode
}

export const Portal = ({ label, portalId, children, style }: PortalProps) => {
  const content = (
    <div data-portal-content="" aria-label={label} style={{ zIndex: zIndex.sticky, position: 'relative', ...style }}>
      {children}
    </div>
  )

  return ReactDOM.createPortal(content, document.body, portalId)
}
