import React from 'react'
import Article from '../../components/Article'
import { ChipKnowledgeBite, getCopyByTopic } from '../../content'
import { colors, rem, scale, size, space, style, weight } from '../../core'

interface Props {
  knowledgeBite: ChipKnowledgeBite
  onClose: () => void
}

const Title = style().spacing({ outerBottom: space.xxs }).sans({ size: scale.s, weight: weight.normal }).element()

const Container = style()
  .grid({ columns: [rem(1.5), size.auto] })
  .spacing({ rows: size.s })
  .element()

const Content = style().sans({ height: size.s }).element()

const ContentWithBackground = style()
  .spacing({ inner: size.xs })
  .color({ bg: colors.lightestGray })
  .round(size.xxs)
  .sans({ height: size.s })
  .element()

const Emoji = style().element()

const CloseButton = style()
  .color({ fg: colors.midGray, bg: colors.lightMoonGray })
  .spacing({
    inner: [space.xxs, space.m],
    outer: [space.s, space.auto, space.none, space.auto],
  })
  .sans({ size: scale.s })
  .round(size.s)
  .text({ align: 'center' })
  .size({ width: space.xxl })
  .select(':hover', style().set('boxShadow', '0 0 0 2px rgb(204, 204, 204)'))
  .pointer()
  .element()

const ContentGrid = style().grid().spacing({ rows: size.xs }).element()

const Component = ({ knowledgeBite, onClose }: Props) => {
  return (
    <>
      <Container>
        <Emoji>{knowledgeBite.emoji}</Emoji>
        <ContentGrid>
          <Title>{knowledgeBite.key}</Title>
          <Content>{knowledgeBite.teaser}</Content>
        </ContentGrid>
        {knowledgeBite.suggestions.map(suggestion => {
          return (
            <React.Fragment key={suggestion.topic}>
              <Emoji>{suggestion.icon}</Emoji>
              <ContentGrid>
                <Title>{getCopyByTopic(suggestion.topic)}</Title>
                {suggestion.content.map((c, i) => (
                  <ContentWithBackground key={`content-with-background-${i}`}>{c}</ContentWithBackground>
                ))}
              </ContentGrid>
            </React.Fragment>
          )
        })}
        {knowledgeBite.furtherReading ? (
          <>
            <Emoji>📼</Emoji>
            <ContentGrid>
              <Title>Explore further</Title>
              {knowledgeBite.furtherReading.map(article => (
                <Article key={article.title} content={article} chipName={knowledgeBite.key} />
              ))}
            </ContentGrid>
          </>
        ) : null}
      </Container>
      <CloseButton onClick={() => onClose()}>Close</CloseButton>
    </>
  )
}

export default Component
Component.displayName = 'KnowledgeBite'
