import React from 'react'
import ReactDOM from 'react-dom'
import classes from '../Dropdown.module.scss'
import { FaChevronDown, FaCircleXmark, FaRegSquare, FaSquareCheck } from 'react-icons/fa6'
import { Card } from '../../Card/Card'
import { DropdownItem, returnKey, returnLabel } from '../Dropdown'
import { Text } from '../../Text/Text'

export interface MultiSelectDropdownProps {
  items: DropdownItem[]
  selectedDefault?: DropdownItem[]
  onSelect: (selection: DropdownItem[]) => void
  placeholder?: string
  disabled?: boolean
  testId?: string
}

/**
 * Checks if one `DropdownItem` equals to another.
 * The comparison is based on the `value` property for objects, and direct string comparison for string items.
 *
 * @param one - `DropdownItem` to compare with "another".
 * @param another - `DropdownItem` to compare with "one".
 * @returns `true` if one equals to another.
 */
const equals = (one: DropdownItem, another: DropdownItem): boolean => {
  if (typeof one === 'string' && typeof another === 'string') {
    // Both items are strings, compare them directly
    return one === another
  } else if (typeof one !== 'string' && typeof another !== 'string') {
    // Both items are objects, compare their 'value' properties
    return one.value === another.value
  } else {
    // One item is a string and the other is an object, they cannot be equal
    return false
  }
}

/**
 * Checks if an array of `DropdownItem`s contains a specific `DropdownItem`.
 * A `DropdownItem` can either be a string or an object with a `value` string property and an optional `label` of `ReactNode`.
 * The comparison is based on the `value` property for objects, and direct string comparison for string items.
 * Key difference with Array.prototype.includes() is that strict comparison on objects is not used, as it doesn't yield a desired result.
 *
 * @param items - The array of `DropdownItem` to search within.
 * @param itemToFind - The `DropdownItem` to find in the array.
 * @returns `true` if the `itemToFind` is found in the `items` array, otherwise `false`.
 */
const includes = (items: DropdownItem[], itemToFind: DropdownItem): boolean =>
  items.some(item => equals(item, itemToFind))

export const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = props => {
  const [selected, setSelected] = React.useState<DropdownItem[]>(props.selectedDefault || [])

  const [open, setOpen] = React.useState(false)
  const dropdownRef = React.createRef<HTMLDivElement>()

  const [portalElement, setPortalElement] = React.useState<HTMLDivElement | null>(null)
  const [dropdownPosition, setDropdownPosition] = React.useState({ top: 0, right: 0 })
  const [dropdownMaxHeight, setDropdownMaxHeight] = React.useState('auto')

  const handleChange = (item: DropdownItem) => {
    const newSelected = includes(selected, item) ? selected.filter(i => !equals(i, item)) : [...selected, item]
    setSelected(newSelected)
    props.onSelect(newSelected)
  }

  const disabled = (props.disabled !== undefined && props.disabled) || props.items.length === 0

  const handleDropdownClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    setOpen(!open)
  }

  const handleScroll: React.WheelEventHandler<HTMLDivElement> = e => {
    e.stopPropagation()
    const amountUserScrolledWheel = e.deltaY
    if (amountUserScrolledWheel !== 0) {
      const element = dropdownRef.current
      if (element) {
        element.scrollLeft += amountUserScrolledWheel
      }
    }
  }

  const calculateDropdownMaxHeight = () => {
    if (dropdownRef.current) {
      const dropdownRect = dropdownRef.current.getBoundingClientRect()
      const triggerElementHeight = dropdownRect.height
      setDropdownMaxHeight(`${window.innerHeight - (dropdownRect.top + triggerElementHeight) - 40}px`)
    }
  }

  const calculateDropdownPosition = () => {
    if (dropdownRef.current) {
      const rect = dropdownRef.current.getBoundingClientRect()
      setDropdownPosition({ top: rect.top + rect.height, right: window.innerWidth - rect.right })
    }
  }

  // Create the portal element on mount and clean up on unmount
  React.useEffect(() => {
    const el = document.createElement('div')
    document.body.appendChild(el)
    setPortalElement(el)

    return () => {
      document.body.removeChild(el)
    }
  }, [])

  // We need to call onSelect to apply any filters at the component init time.
  React.useEffect(() => {
    props.onSelect(props.selectedDefault || [])
  }, [props.selectedDefault])

  // Calculate the dropdown position and max height when the dropdown is opened
  React.useEffect(() => {
    if (open) {
      calculateDropdownMaxHeight()
      calculateDropdownPosition()
    }
  }, [open])

  return (
    <div className={`${classes.dropdown} ${disabled ? classes.disabled : classes.enabled}`} data-testid={props.testId}>
      <div
        className={`${classes.dropdownSelect} ${disabled ? classes.disabled : ''}`}
        onClick={handleDropdownClick}
        ref={dropdownRef}
        onWheel={handleScroll}
        onScroll={e => e.stopPropagation()}
      >
        {selected.length === 0 ? (
          <div className={classes.placeholder}>{props.placeholder || 'Select'}</div>
        ) : (
          <div className={classes.selectedWrapper}>
            {selected.map(item => (
              <div key={returnKey(item)} className={classes.selectedOption}>
                <div>{returnLabel(item)}</div>
                <FaCircleXmark
                  onClick={e => {
                    e.stopPropagation()
                    handleChange(item)
                  }}
                  size={14}
                  className={classes.removeIcon}
                />
              </div>
            ))}
          </div>
        )}
        <div className={disabled ? classes.arrowWrapperDisabled : classes.arrowWrapper} onClick={handleDropdownClick}>
          <FaChevronDown className={classes.icon} />
        </div>
      </div>
      {open && <div className={classes.overlay} onClick={() => setOpen(false)} />}

      {portalElement &&
        open &&
        ReactDOM.createPortal(
          <Card
            className={`${classes.list} ${open ? classes.open : classes.closed}`}
            style={{
              top: dropdownPosition.top,
              right: dropdownPosition.right,
              maxHeight: dropdownMaxHeight,
              overflow: 'auto',
            }}
          >
            <ul>
              {props.items.map(item => (
                <li
                  key={returnKey(item)}
                  onClick={() => handleChange(item)}
                  className={includes(selected, item) ? classes.selected : ''}
                >
                  <div className={classes.checkbox}>
                    {includes(selected, item) ? <FaSquareCheck /> : <FaRegSquare />}
                  </div>
                  <Text variant="body-text">{returnLabel(item)}</Text>
                </li>
              ))}
            </ul>
          </Card>,
          portalElement,
        )}
    </div>
  )
}
