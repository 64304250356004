import RelationshipLabel from 'components/RelationshipLabel'
import { px2rem, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React from 'react'
import * as selectors from 'selectors'
import { Relationship as RelationshipType } from 'selectors/references'

interface Props {
  name: string
  relationship: RelationshipType
}

const Container = style()
  .spacing({ gap: px2rem(6) })
  .flex({ direction: 'column', justifyContent: 'center' })
  .sans({ ellipsis: true })
  .element()

const LabelContainer = style()
  .size({ maxWidth: px2rem(96) })
  .element()

const Name = text.smallBody().fg(designSystemColors.typographySecondary).sans({ ellipsis: true }).element()

const ReferenceLabel: React.FC<Props> = ({ name, relationship }) => {
  return (
    <Container>
      <LabelContainer>
        <RelationshipLabel type={relationship} />
      </LabelContainer>
      <Name className="data-hj-suppress">
        {name.split(' ').length === 1 ? name : `${name[0]}. ${selectors.lastName(name)}`}
      </Name>
    </Container>
  )
}

export default ReferenceLabel
