import React from 'react'
import { EmptyState } from '@common/components/EmptyState/EmptyState'

export type ResultsNotFound = 'no-roles-found' | 'no-roles-exist'

interface NoResultsProps {
  type: ResultsNotFound
}

const NO_ROLES_EXIST_IMAGE = '/assets/woman_on_laptop.png'
const NO_ROLES_FOUND_IMAGE = '/assets/thinking.png'

export const NoResults: React.FC<NoResultsProps> = ({ type }) => {
  switch (type) {
    case 'no-roles-exist': {
      return (
        <EmptyState
          centered
          rounded
          bordered
          text="Create your first Job to get started"
          photo={NO_ROLES_EXIST_IMAGE}
        />
      )
    }

    case 'no-roles-found': {
      return <EmptyState centered rounded bordered text="No Jobs found" photo={NO_ROLES_FOUND_IMAGE} />
    }
  }
}
