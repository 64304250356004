import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit'
import * as api from '../../api'
import { AppThunk } from '../../core/store'
import { add as notify } from '../notifications'
import { addEntities } from '../resources'
import { handleResponse } from '../utils'

const name = 'invite'

interface State {
  loading: boolean
  teams: string[]
}

const initialState: State = {
  loading: false,
  teams: [],
}

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setTeams(state, action: PayloadAction<string[]>) {
      state.teams = action.payload
    },
  },
})

export const { setLoading, setTeams } = slice.actions

export default slice.reducer

export const loadTeams =
  (userid: string): AppThunk =>
  async (dispatch: Dispatch) => {
    dispatch(setLoading(true))

    const [response, errors] = await api.teams.loadByUserId(userid)

    dispatch(setLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(addEntities(response))
      dispatch(setTeams(response.result.map(row => row.fields.team_id)))
    }
  }

export const sendInvitation =
  (
    teamid: string,
    name: string,
    email: string,
    isOrgAdmin: boolean,
    permissions?: api.userPermissions.UpdatePayloadPermissions,
  ): AppThunk =>
  async (dispatch: Dispatch) => {
    if (!teamid) {
      return dispatch(
        notify({
          error: 'Please select a team..',
        }),
      )
    }

    dispatch(setLoading(true))

    if (
      !handleResponse<api.users.Fields>(dispatch, await api.teams.invite(teamid, email, name, isOrgAdmin, permissions))
    )
      return dispatch(setLoading(false))

    dispatch(setLoading(false))

    dispatch(
      notify({
        success: `Invitation to join your team has been sent out to ${name} (${email})`,
      }),
    )
  }
