import React from 'react'
import { colors, size, space, style } from '../../core'

const Container = style()
  .size({ height: size.fill })
  .border({ right: '1px solid', color: colors.lightMoonGray })
  .color({ bg: colors.nearWhite })
  .element()

const Content = style()
  .spacing({ inner: [space.l, space.m], rows: space.s })
  .grid()
  .element()

interface Props {
  children: React.ReactNode
}

const Component = (props: Props) => {
  return (
    <Container>
      <Content>{props.children}</Content>
    </Container>
  )
}

export default Component
Component.displayName = 'Steps'
