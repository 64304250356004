import { isPresent } from 'core/utils'
import { DataTableFilterMetaData, DataTableOperatorFilterMetaData } from 'primereact/datatable'
import * as listing from '../api/listing'

export function tableFiltersToApiFilters(
  filters: Record<string, DataTableOperatorFilterMetaData | DataTableFilterMetaData>,
): listing.Filter[] {
  return Object.keys(filters)
    .map((key): listing.Filter | undefined => {
      const filter = filters[key]

      const matchMode =
        'matchMode' in filter
          ? (filter as DataTableFilterMetaData).matchMode
          : (filter as DataTableOperatorFilterMetaData).constraints[0].matchMode
      const value =
        'value' in filter
          ? (filter as DataTableFilterMetaData).value
          : (filter as DataTableOperatorFilterMetaData).constraints[0].value

      if (value === null) return undefined

      const filterFuncs = {
        startsWith: listing.StartsWith,
        contains: listing.Includes,
        notContains: listing.NotIncludes,
        endsWith: listing.EndsWith,
        equals: listing.Eq,
        notEquals: listing.Neq,
        in: listing.In,
        lt: listing.Lt,
        lte: listing.Lte,
        gt: listing.Gt,
        gte: listing.Gte,
        dateIs: listing.DateIs,
        dateIsNot: listing.DateIsNot,
        dateBefore: listing.DateBefore,
        dateAfter: listing.DateAfter,
        between: undefined, // Not supporting this yet
        none: undefined,
        round_eq: listing.RoundEq,
      }

      const comparator = filterFuncs[matchMode || 'none'] || filterFuncs.none

      if (['dateIs', 'dateIsNot', 'dateBefore', 'dateAfter'].includes(matchMode || '')) {
        return comparator(key, new Date(value).toISOString())
      }

      return comparator(key, value)
    })
    .filter(isPresent)
}
