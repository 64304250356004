import { Icon } from 'components/Icons'
import { px2rem, size, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React, { useEffect, useRef } from 'react'

const Quote = style()
  .sans({
    italic: true,
    weight: '400',
    size: px2rem(14.4),
    line: px2rem(21),
    numeric: 'tabular-nums',
    align: 'center',
  })
  .color({ fg: designSystemColors.typographyPrimary })
  .nospacing()
  // Experimental and non standard line-clamp has been used to limit the number of lines and show ellipsis
  .set('display', '-webkit-box')
  .set('WebkitLineClamp', '8' /* max number of lines */)
  .set('WebkitBoxOrient', 'vertical')
  .set('overflow', 'hidden')
  .element('p')
const Name = text
  .smallBody()
  .color({ fg: designSystemColors.typographySecondary })
  .sans({
    align: 'center',
    uppercase: true,
  })
  .element('p')
const Testimony = style()
  .grid({ rows: `minmax(0, min-content) auto` })
  .select(`${Name}`, style().spacing({ outerTop: px2rem(12) }))
  .element('li')
const HorizontalList = style()
  .flex({ wrap: 'nowrap' })
  .list({ style: 'none' })
  .nospacing()
  .border({ radius: px2rem(6) })
  .select(
    `${Testimony}`,
    style()
      .size({ width: size.fill })
      .set('flexShrink', '0')
      .color({ bg: designSystemColors.backgroundNeutralSecondary })
      .spacing({ inner: [px2rem(10), px2rem(14)] })
      .set('scrollSnapAlign', 'start')
      // Support for re-snapping after resize: https://drafts.csswg.org/css-scroll-snap-1/#re-snap
      .select(`${Testimony}::after`, style().block().set('scrollSnapAlign', 'start').set('content', '""')),
  )
  .size({ width: size.fill, height: size.fill })
  .nooverflow()
  .set('scrollSnapType', 'x mandatory')
  .relative()
  .element('ul')

const Dot = style()
  .spacing({ inner: px2rem(0) })
  .size({ width: px2rem(3), height: px2rem(3) })
  .border({ radius: px2rem(1.5) })
  .color({ bg: designSystemColors.typographyPrimary })
  .cond(({ isExpanded }: { isExpanded: boolean }) => isExpanded, style().size({ width: px2rem(28) }))
  .element()
const Dots = style()
  .flex({ alignItems: 'center', justifyContent: 'center' })
  .select(`${Dot}`, style().spacing({ outer: [px2rem(0), px2rem(3)] }))
  .element()

const ArrowButton = style()
  .flex({ alignItems: 'center', justifyContent: 'center' })
  .noborders()
  .nospacing()
  .select(':hover', style().color({ bg: designSystemColors.backgroundNeutralPrimaryHover }))
  .pointer()
  .size({ width: px2rem(24), height: px2rem(24) })
  .border({
    radius: px2rem(12),
    around: `solid ${px2rem(1)}`,
    color: designSystemColors.borderDefault,
  })
  .color({ bg: designSystemColors.backgroundNeutralPrimary })
  .set('boxShadow', '0px 1px 3px rgba(0, 0, 0, 0.1)')
  .element('button')
const LeftArrowButton = style(ArrowButton).element('button')
const RightArrowButton = style(ArrowButton).element('button')

const TestimonialBlockStyle = style()
  .relative()
  .flex({ direction: 'column' })
  .size({ height: size.fill })
  .select(`${Dots}`, style().spacing({ outerTop: px2rem(16) }))
  .select(
    `${LeftArrowButton}`,
    style()
      .absolute({ top: px2rem(80), left: px2rem(-12) })
      .invisible(),
  )
  .select(
    `${RightArrowButton}`,
    style()
      .absolute({ top: px2rem(80), right: px2rem(-12) })
      .invisible(),
  )
  .select(
    ':hover',
    style().select(`${LeftArrowButton}`, style().visible()).select(`${RightArrowButton}`, style().visible()),
  )
  .element()

export type TestimonyType = {
  quote: React.ReactNode
  name?: React.ReactNode
}
export interface TestimonialBlockProps {
  testimonies: TestimonyType[]
}

export const TestimonialBlock: React.FC<TestimonialBlockProps> = ({ testimonies }) => {
  const testimonyRefs = useRef<Record<number, HTMLParagraphElement>>({})
  const [selectedTestimonyIndex, setSelectedTestimonyIndex] = React.useState(0)

  const numberOfTestimonies = testimonies.length
  const onLeftArrowButtonClick: React.MouseEventHandler = ev => {
    ev.preventDefault()
    setSelectedTestimonyIndex(index => (numberOfTestimonies + index - 1) % numberOfTestimonies)
  }
  const onRightArrowButtonClick: React.MouseEventHandler = ev => {
    ev.preventDefault()
    setSelectedTestimonyIndex(index => (index + 1) % numberOfTestimonies)
  }

  const isFirstRenderRef = React.useRef(true)
  useEffect(() => {
    if (isFirstRenderRef.current) {
      isFirstRenderRef.current = false
      return
    }
    // Scroll to selected testimony when selectedTestimonyIndex changes
    testimonyRefs.current[selectedTestimonyIndex]?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    })
  }, [selectedTestimonyIndex])

  return (
    <TestimonialBlockStyle>
      <HorizontalList>
        {testimonies.map((testimony, i) => (
          <Testimony key={`testimony-${i}`} ref={ref => (testimonyRefs.current[i] = ref)} className="data-hj-suppress">
            <Quote>{`“${testimony.quote}”`}</Quote>
            {testimony.name && <Name>{testimony.name}</Name>}
          </Testimony>
        ))}
      </HorizontalList>
      {testimonies.length > 1 && (
        <>
          <LeftArrowButton title="Show previous" onClick={onLeftArrowButtonClick}>
            <Icon name="chevron-left" />
          </LeftArrowButton>
          <RightArrowButton title="Show next" onClick={onRightArrowButtonClick}>
            <Icon name="chevron-right" />
          </RightArrowButton>
        </>
      )}
      <Dots>
        {testimonies.map((_, i) => (
          <Dot key={`testimony-${i}`} isExpanded={selectedTestimonyIndex === i} />
        ))}
      </Dots>
    </TestimonialBlockStyle>
  )
}
