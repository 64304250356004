import { px2rem, size, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React from 'react'

const StatsCardStyle = style()
  .border({
    around: '1px solid',
    color: designSystemColors.borderDefault,
    radius: px2rem(8),
  })
  .color({ bg: designSystemColors.backgroundNeutralPrimary })
  .flex({ direction: 'column' })
  .spacing({
    gap: px2rem(12),
    inner: px2rem(16),
  })
  .element()

const Label = text.bodyInteractive().size({ height: size.fill }).element()
const Stat = style()
  .sans({ size: px2rem(40), line: px2rem(46) })
  .element()

interface StatsCardData {
  label: string
  stat: string | number
}

const StatsCard: React.FC<StatsCardData> = ({ label, stat }) => {
  return (
    <StatsCardStyle>
      <Label>{label}</Label>
      <Stat>{stat}</Stat>
    </StatsCardStyle>
  )
}

export default StatsCard
