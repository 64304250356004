import { SubText } from 'App/ProductPages/Shared'
import React from 'react'
import ContentContainer from '../../components/Container/ContentContainer'
import DashboardLayout from '../../components/DashboardLayout'
import ProductPageShell from '../../components/ProductPageLayout'
import HeroGrid from '../../components/ProductPageLayout/HeroGrid'
import HeroImgTalentPool from './HeroImgTalentPool.png'

interface TalentPoolPageProps {
  button: JSX.Element
}

const EmptyTalentPoolPage: React.FC<TalentPoolPageProps> = props => {
  return (
    <DashboardLayout>
      <ProductPageShell title={'Talent Pool'}>
        <ContentContainer>
          <HeroGrid
            heading={'Turn references into candidates'}
            subheading={
              <SubText>
                Enable references to opt-in your HiPeople talent pool and collect additional candidates for future
                vacancies. Expect 30% of references end up joining. <br />
                <br />
                Build a unique, active talent pipeline starting today.
              </SubText>
            }
            image={{
              src: HeroImgTalentPool,
              altText: 'User interface of HiPeople dashboard showing a talent pool',
            }}
          >
            {props.button}
          </HeroGrid>
        </ContentContainer>
      </ProductPageShell>
    </DashboardLayout>
  )
}

export default EmptyTalentPoolPage
