import React from 'react'
import Step from '../../App/GuidedTour/Step'
import { style } from '../../core'

const Container = style().grid().spacing({ inner: '8em' }).element()

type Props = {
  label?: string
  header: string
  description: string
  children?: React.ReactNode
}

export const FirstStep: React.FC<Props> = props => {
  return (
    <Container>
      <Step label={props.label || '1'} header={props.header} description={props.description} />
      {props.children}
    </Container>
  )
}
