import { map } from './css'
import Rows from './rows'

export interface IOptions {
  top?: string
  left?: string
  right?: string
  radius?: string | string[]
  bottom?: string
  around?: string
  color?: string
  bottomRadius?: string
  topRadius?: string
}

export default function border(rows: Rows, options: IOptions) {
  if (options.around) {
    rows.set('border', options.around)
  }

  if (options.bottomRadius) {
    rows.set('borderBottomLeftRadius', options.bottomRadius)
    rows.set('borderBottomRightRadius', options.bottomRadius)
  }

  if (options.topRadius) {
    rows.set('borderTopLeftRadius', options.topRadius)
    rows.set('borderTopRightRadius', options.topRadius)
  }

  map(rows, options, {
    top: 'borderTop',
    left: 'borderLeft',
    right: 'borderRight',
    bottom: 'borderBottom',
    color: 'borderColor',
    radius: 'borderRadius',
  })
}
