import { useFeatureFlag } from 'providers/features'
import React from 'react'
import { useSelector } from 'react-redux'
import { SubmissionType } from 'selectors/candidates'
import Block from '../../../components/Block'
import Comments from '../../../components/Comments'
import * as selectors from '../../../selectors'
import { RootState } from '../../../store'
import { Props } from '../EmptyShell'
import Shell from '../Shell'
import classes from './Index.module.scss'

const ProfileShell = (props: Props) => {
  const candidate = useSelector((state: RootState) => selectors.candidates.findById(state, props.candidateId))
  const profile = useSelector((state: RootState) =>
    selectors.candidateProfiles.findByCandidateId(state, props.candidateId),
  )
  const comments = useSelector((state: RootState) => selectors.comments.findByCandidateId(state, props.candidateId))
  const { isEnabled: isCandidateCommentsEnabled } = useFeatureFlag('candidate-comments')

  return (
    <Shell {...props} title={SubmissionType.ReferenceCheck}>
      <div className={classes.wrapper}>
        {props.children}
        {candidate && isCandidateCommentsEnabled && (
          <div className={classes.blockContainer}>
            <Block title={`Comments about ${selectors.firstName(profile?.fields.full_name || '')}`}>
              <Comments candidateId={candidate.id} comments={comments} />
            </Block>
          </div>
        )}
      </div>
    </Shell>
  )
}

export default ProfileShell
