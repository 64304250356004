import { style } from 'core'
import { isPasswordValidWithConfirmation, passwordError } from 'core/password/password'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { Button, Card, Logo, Text } from '@common/components'
import Buttons from '../../components/Buttons'
import Form from '../../components/Form'
import TextField from '../../components/TextField'
import WarningBanner from '../../components/WarningBanner'
import { RootState } from '../../store'
import { resetPassword } from '../../store/resetpassword/reset-password'
import classes from '../SignUp/Signup.module.scss'

const ButtonLink = style().text({ nodecoration: true }).elementFromComponent(Link)

const Component = () => {
  const dispatch = useDispatch()

  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')

  const { loading, success } = useSelector((state: RootState) => state.resetPassword)

  return (
    <div className={classes.main}>
      <Card big className={classes.card}>
        {success ? (
          <>
            <WarningBanner type="info">Your password has been created.</WarningBanner>
            <Buttons>
              <ButtonLink to="/?verified=true">
                <Button variant="purple-dark">Go to the dashboard</Button>
              </ButtonLink>
            </Buttons>
          </>
        ) : (
          <Form>
            <div className={classes.center}>
              <Logo />
              <Text variant="highlight" style={{ textAlign: 'center' }}>
                Create your password
              </Text>
            </div>
            <TextField
              autoFocus
              label="New password"
              placeholder="Enter your new password"
              value={password}
              type="password"
              onChange={e => setPassword(e.target.value)}
              error={password !== '' && passwordError(password) !== ''}
              errorLabel={password && passwordError(password)}
            />
            <TextField
              label="Confirm"
              placeholder="Confirm your new password"
              value={passwordConfirm}
              type="password"
              onChange={e => setPasswordConfirm(e.target.value)}
              error={passwordConfirm !== '' && password !== passwordConfirm}
              errorLabel={passwordConfirm && (password !== passwordConfirm ? 'Passwords do not match' : '')}
            />
            <Button
              onClick={() => dispatch(resetPassword(password, passwordConfirm))}
              variant="purple-dark"
              rounded
              disabled={loading || !isPasswordValidWithConfirmation(password, passwordConfirm)}
            >
              Save
            </Button>
          </Form>
        )}
      </Card>
    </div>
  )
}

export default Component
Component.displayName = 'CreatePassword'
