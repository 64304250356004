import { formatDistance } from 'date-fns'

export function copyToClipboard(text) {
  const dummy = document.createElement('textarea')
  // to avoid breaking orgain page when copying more words
  // cant copy when adding below this code
  dummy.style.position = 'absolute'
  dummy.style.top = '-9999px'
  document.documentElement.appendChild(dummy)
  //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
  dummy.value = text
  dummy.select()
  document.execCommand('copy')
  document.documentElement.removeChild(dummy)
}

function checkIfPreviewDeployment() {
  const hostname = window.location.hostname
  return hostname.includes('deploy-preview-') && hostname.endsWith('.netlify.app') // verify that it's a netlify preview
}

function getPreviewDeploymentURL() {
  const previewNumber = window.location.hostname.match(/deploy-preview-(\d+)/)?.[1]

  if (!previewNumber) {
    throw new Error('No preview number found')
  }

  return `https://deploy-preview-${previewNumber}--hipeople-candidateapp.netlify.app`
}

export function candidateBaseURL(slug: string) {
  const isPreviewDeployment = checkIfPreviewDeployment()

  if (isPreviewDeployment) {
    return getPreviewDeploymentURL()
  }

  const baseURL = import.meta.env.VITE_CANDIDATE_BASE || 'https://{}.hipeople.io'
  return baseURL.replace(/\/$/, '').replace('{}', slug)
}

export function arrayIndexOffset<T>(items: T[], curr: T, offset: number): T {
  const i = items.indexOf(curr) + offset
  const m = items.length
  // account for a negative remainder
  return items[((i % m) + m) % m]
}

export function hasPresentKey<K extends string | number | symbol>(k: K) {
  return function <T, V>(a: T & { [k in K]?: V | null }): a is T & { [k in K]: V } {
    return a[k] !== undefined && a[k] !== null
  }
}

export function isPresent<T>(t: T | undefined | null | void): t is T {
  return t !== undefined && t !== null
}

export function formatDurationSeconds(seconds: number): string {
  if (!seconds) {
    return '/'
  }
  return formatDistance(0, seconds * 1000, { includeSeconds: true })
}
