import Modal from 'components/Modal'
import { fr, px2rem, size, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React from 'react'

const TitleIcon = style()
  .inlineBlock()
  .spacing({ outerRight: px2rem(14) })
  .element('span')

const Title = text.cardHeader().element('h3')

const ModalHeader = style()
  .grid({ columns: [fr(1), size.auto, size.auto], align: 'center' })
  .spacing({ gap: px2rem(8), inner: [px2rem(12), px2rem(14)] })
  .border({
    bottom: `solid ${px2rem(1)}`,
    color: designSystemColors.borderDefault,
  })
  .element()

const SectionTitle = text.bodyEmphasis().element('h4')

const DescriptionSection = style()
  .flex({ direction: 'column' })
  .spacing({ gap: px2rem(8) })
  .element('section')

const Description = text.bodyText().element('p')

const PreviewSection = style()
  .flex({ direction: 'column' })
  .spacing({ gap: px2rem(8) })
  .size({ height: '100%' })
  .element('section')

const PreviewContentContainer = style()
  .flex({ direction: 'column' })
  .spacing({ gap: px2rem(8), inner: [px2rem(30), px2rem(22)] })
  .size({ height: '100%' })
  .color({ bg: designSystemColors.backgroundNeutralSecondary })
  .border({
    radius: px2rem(8),
    around: `solid 1px`,
    color: designSystemColors.borderDefault,
  })
  .element()

const Column = style()
  .flex({ direction: 'column' })
  .spacing({ gap: px2rem(24) })
  .element()

const PreviewModuleModalStyle = style()
  .cond(
    ({ hasChildren }: { hasChildren: boolean }) => hasChildren,
    style().grid({ columns: [px2rem(260), px2rem(540)] }),
  )
  .spacing({ gap: px2rem(20), inner: [px2rem(24), px2rem(32), px2rem(40)] })
  .size({ width: px2rem(890) })
  .element()

interface PreviewModuleModalProps {
  close: () => void
  titleEmoji: string
  title: string
  description?: string
  children?: React.ReactNode
}

export const PreviewModuleModal: React.FC<PreviewModuleModalProps> = ({
  close,
  title,
  titleEmoji,
  description,
  children,
}) => {
  return (
    <Modal
      open={true}
      setOpen={close}
      renderHeader={({ closeButton }) => (
        <ModalHeader>
          <Title>
            <TitleIcon>{titleEmoji}</TitleIcon>
            {title}
          </Title>
          {closeButton}
        </ModalHeader>
      )}
    >
      <PreviewModuleModalStyle hasChildren={children}>
        <Column>
          {description && (
            <DescriptionSection>
              <SectionTitle>{'Description'}</SectionTitle>
              <Description>{description}</Description>
            </DescriptionSection>
          )}
        </Column>
        {children && (
          <Column>
            <PreviewSection>
              <SectionTitle>{'Preview'}</SectionTitle>
              <PreviewContentContainer>{children}</PreviewContentContainer>
            </PreviewSection>
          </Column>
        )}
      </PreviewModuleModalStyle>
    </Modal>
  )
}

const PreviewContentStyle = style()
  .flex({ direction: 'column', alignItems: 'center', justifyContent: 'center' })
  .spacing({ gap: px2rem(24) })
  .sans({ align: 'center' })
  .element()

export const PreviewContent = ({ children }) => {
  return <PreviewContentStyle>{children}</PreviewContentStyle>
}

const PreviewChipsContainer = style()
  .flex({ alignItems: 'center', justifyContent: 'center', wrap: 'wrap' })
  .spacing({ gap: px2rem(12) })
  .sans({ align: 'center' })
  .element()

const PreviewChip = style()
  .color({ bg: designSystemColors.backgroundNeutralTertiary })
  .border({
    radius: px2rem(30),
  })
  .spacing({ inner: [px2rem(8), px2rem(14)] })
  .element()

interface PreviewChipsProps {
  chipData: string[] | undefined
}

export const PreviewChips: React.FC<PreviewChipsProps> = ({ chipData }) => {
  return (
    <PreviewChipsContainer>
      {chipData && chipData.map((chip, i) => <PreviewChip key={i}>{chip}</PreviewChip>)}
    </PreviewChipsContainer>
  )
}
