import { parseProfessionalNetwork, ProfessionalNetwork, questionnaireSubmissions, users } from '.'
import * as api from '../api'
import { RootState } from '../store'
import { VERIFICATION_QUESTION, VERIFICATION_QUESTION_ANONYMITY_OPTION } from '../store/new-role/content'
import * as candidates from './candidates'
import * as formresponses from './form-responses'
import lookup from './lookup'

export enum Relationship {
  Peer = 'Peer',
  Manager = 'Manager',
  Report = 'Direct Report',
  StudentPeer = 'Student Peer',
  Client = 'Client',
  Self = 'Candidate',
  Any = 'Any Relationship',
}

export interface Reference {
  fullName: string
  relationship: Relationship
}

export interface RequiredReference {
  id?: string
  relationship: Relationship
  description?: string
}

interface Status {
  caption: string
  icon: string
  short?: string
}

export function getById(state: RootState, id: string): api.request.Entity<api.references.Fields> {
  return sanitize(state, lookup<api.references.Fields>(state, api.references.RESOURCE, id) || api.references.empty(id))
}

export function findById(state: RootState, id: string): api.request.Entity<api.references.Fields> | undefined {
  return sanitize(state, lookup<api.references.Fields>(state, api.references.RESOURCE, id) || api.references.empty(id))
}

export function findUnsanitizedById(state: RootState, id: string): api.request.Entity<api.references.Fields> {
  return lookup<api.references.Fields>(state, api.references.RESOURCE, id) || api.references.empty(id)
}

export function findByCandidateId(state: RootState, candidateId: string): api.request.Entity<api.references.Fields>[] {
  const candidate = candidates.findById(state, candidateId)
  if (!candidate || !candidate.relationships) return []

  const result: api.request.Entity<api.references.Fields>[] = []

  for (const r of candidate.relationships) {
    if (r.resource !== api.references.RESOURCE) continue

    const ref = findById(state, r.id)
    if (!ref) continue

    result.push(sanitize(state, ref))
  }

  return result
}

export function findNotSelfByCandidateId(
  state: RootState,
  candidateId: string,
): api.request.Entity<api.references.Fields>[] {
  const references = findByCandidateId(state, candidateId)
  if (!references) return []

  return references.filter(reference => !reference.fields.is_self)
}

export function findSameOriginByCandidateId(
  state: RootState,
  candidateId: string,
): api.request.Entity<api.references.Fields>[] {
  const candidate = candidates.findById(state, candidateId)
  if (!candidate || !candidate.fields.client_signature) return []

  if (candidate.fields.questionnaire_id) {
    const referenceQuestionnaireSubmissions = questionnaireSubmissions.findReferenceSubmissionsByCandidateId(
      state,
      candidateId,
    )
    const referencesWithSameOrigin = Object.entries(referenceQuestionnaireSubmissions)
      .filter(([, submission]) => submission?.fields.client_signature === candidate.fields.client_signature)
      .map(([referenceId]) => findById(state, referenceId))
      .filter(reference => !reference?.fields.is_self) as api.request.Entity<api.references.Fields>[]
    return referencesWithSameOrigin
  }

  const references = findNotSelfByCandidateId(state, candidateId)

  return references.filter(r => r.fields.client_signature === candidate.fields.client_signature)
}

export function findByRequirementId(
  state: RootState,
  candidateId: string,
  requirementId: string,
): api.request.Entity<api.references.Fields> | undefined {
  const references = findByCandidateId(state, candidateId)
  return references.find(r => r.fields.requirement_id && r.fields.requirement_id === requirementId)
}

export function firstNameOf(reference: api.request.Entity<api.references.Fields>): string {
  return reference.fields.full_name.trim().split(' ')[0]
}

export function relationshipOf(
  item: api.request.Entity<{
    is_peer: boolean
    is_report: boolean
    is_manager: boolean
    is_student_peer: boolean
    is_client: boolean
    is_self: boolean
    is_any: boolean
  }>,
) {
  if (item.fields.is_peer) return Relationship.Peer
  if (item.fields.is_report) return Relationship.Report
  if (item.fields.is_manager) return Relationship.Manager
  if (item.fields.is_student_peer) return Relationship.StudentPeer
  if (item.fields.is_client) return Relationship.Client
  if (item.fields.is_self) return Relationship.Self
  if (item.fields.is_any) return Relationship.Any
  return Relationship.Manager
}

export function getProfessionalNetworkOf(
  reference: api.request.Entity<api.references.Fields>,
): (ProfessionalNetwork & { url: string }) | undefined {
  if (!reference.fields.linkedin) return

  return parseProfessionalNetwork(reference.fields.linkedin)
}

export function getProfessionalNetworkForDisplay(
  state: RootState,
  reference: api.request.Entity<api.references.Fields>,
): (ProfessionalNetwork & { url: string }) | undefined {
  const anonymous = findDidChooseAnonymous(state, reference)
  if (!anonymous) return getProfessionalNetworkOf(reference)

  const userId = users.current(state)?.id || ''
  if (!users.canProvideSupport(state, userId)) return

  return getProfessionalNetworkOf(reference)
}

export function totalReferences(state: RootState, candidateId: string): number {
  return findByCandidateId(state, candidateId).length
}

export function totalReferencesExceptSelf(state: RootState, candidateId: string): number {
  return findNotSelfByCandidateId(state, candidateId).length
}

export function findRespondedByCandidateId(state: RootState, candidateId: string) {
  const references = findByCandidateId(state, candidateId)

  return references.filter(r => {
    if (r.fields.form_response_id !== '') {
      return true
    }

    const submission = questionnaireSubmissions.findByReferenceId(state, r.id)
    if (submission) {
      return submission.fields.submitted_at > 0
    }

    return false
  })
}

export function findSelfReferenceByCandidateId(state: RootState, candidateId: string) {
  return findByCandidateId(state, candidateId).find(r => r.fields.is_self)
}

export function findRespondedByCandidateIdExceptSelf(state: RootState, candidateId: string) {
  return findRespondedByCandidateId(state, candidateId).filter(reference => !reference.fields.is_self)
}

export function findRespondedByCandidateIdOnlySelf(state: RootState, candidateId: string) {
  return findRespondedByCandidateId(state, candidateId).filter(reference => reference.fields.is_self)
}
export function totalRespondedReferencesExceptSelf(state: RootState, candidateId: string) {
  return findRespondedByCandidateId(state, candidateId).filter(reference => !reference.fields.is_self).length
}

export function requiredReferencesFromEntities(
  entities: api.request.Entity<api.requiredReferences.Fields>[],
): RequiredReference[] {
  const result: RequiredReference[] = []
  for (const entity of entities) {
    const relationship = relationshipOf(entity)
    if (!relationship) continue

    result.push({
      id: entity.id,
      relationship,
      description: entity.fields.relationship_description,
    })
  }

  return result
}
export function getVerificationStatus(state: RootState, id: string): Status {
  const reference = findById(state, id)
  if (!reference) return { icon: '', caption: '' }

  const verified = reference.fields.is_verified
  const reviewed = reference.fields.is_reviewed

  let caption = 'Verification pending'
  let icon = 'hourglass'

  if (verified) {
    caption = 'Verified relationship'
    icon = 'check-circle'
  } else if (reviewed && !verified) {
    caption = 'Not verified'
    icon = 'user-times'
  }

  return { caption, icon }
}
export type ReferenceStatusStep =
  | 'sending-invitation'
  | 'invitation-sent'
  | 'invitation-seen'
  | 'failed-invitation'
  | 'replied'

export type ReferenceStatus = {
  step: ReferenceStatusStep
  data: {
    repliedAt: number
    emailDeliveredAt: number
    emailReadAt: number
    emailFailedAt: number
  }
}

export function getReferenceStatus(state: RootState, id: string): ReferenceStatus | undefined {
  const reference = findById(state, id)
  if (!reference) return undefined

  const emailDeliveredAt = reference.fields.email_delivered_at
  const emailFailedAt = reference.fields.email_failed_at
  const emailReadAt = reference.fields.email_read_at
  const smsDeliveredAt = reference.fields.sms_delivered_at
  const smsFailedAt = reference.fields.sms_failed_at

  const response = formresponses.findById(state, reference.fields.form_response_id)
  const questionnaireSubmissionId = reference.relationships?.find(
    r => r.resource === api.questionnaireSubmissions.RESOURCE,
  )?.id
  const questionnaireSubmission = questionnaireSubmissions.findById(state, questionnaireSubmissionId || '')

  const data = {
    repliedAt: (response?.fields.created_at ?? questionnaireSubmission?.fields.submitted_at ?? 0) / 10e5,
    emailDeliveredAt: emailDeliveredAt / 10e5,
    emailReadAt: emailReadAt / 10e5,
    emailFailedAt: emailFailedAt / 10e5,
    smsDeliveredAt: smsDeliveredAt / 10e5,
    smsFailedAt: smsFailedAt / 10e5,
  }

  let step: ReferenceStatusStep = 'sending-invitation'

  if (response || data.repliedAt) {
    step = 'replied'
  } else if (emailReadAt) {
    step = 'invitation-seen'
  } else if (emailFailedAt) {
    step = 'failed-invitation'
  } else if (emailDeliveredAt) {
    step = 'invitation-sent'
  }

  return { step, data }
}

// As of 10.08.21, we don't provide the option to provide
// a reference anonymously, however, we keep this here to
// respect the (minor) anonymity of the references that opted
// for this option.
function findDidChooseAnonymous(state: RootState, reference: api.request.Entity<api.references.Fields>): boolean {
  const answer = formresponses.findAnswerByResponseId(
    state,
    reference.fields.form_response_id,
    VERIFICATION_QUESTION.test_item_id,
  )

  return answer?.selected?.includes(VERIFICATION_QUESTION_ANONYMITY_OPTION) || false
}

// FIXME: this should be done in the backend in the future.
function sanitize(
  state: RootState,
  reference: api.request.Entity<api.references.Fields>,
): api.request.Entity<api.references.Fields> {
  // FIXME: we need to return empty reference from `findById`
  if (!reference) return reference

  const didChooseAnonymity = findDidChooseAnonymous(state, reference)
  if (!didChooseAnonymity) {
    return reference
  }

  const sanitized = {
    ...reference,
    fields: {
      ...reference.fields,
    },
  }

  sanitized.fields.full_name = 'Anonymous'
  sanitized.fields.email = 'Anonymous'

  return sanitized
}
export const relationshipCopy = {
  [Relationship.Peer]: {
    emoji: '🤲',
    placeholder: 'e.g Peer (last job)',
  },
  [Relationship.Manager]: {
    emoji: '💼',
    placeholder: 'e.g Engineering Manager',
  },
  [Relationship.Report]: {
    emoji: '🌱',
    placeholder: 'e.g Reporting colleague (last job)',
  },
  [Relationship.StudentPeer]: {
    emoji: '🎓',
    placeholder: 'e.g Thesis collaborator',
  },
  [Relationship.Client]: {
    emoji: '👔',
    placeholder: 'e.g Client (3+ months)',
  },
  [Relationship.Any]: {
    emoji: '💭',
    placeholder: 'e.g. Any reference',
  },
} as const

export const relationshipCopyWithSelf: {
  [key in Relationship]: { emoji: string; placeholder: string }
} = {
  ...relationshipCopy,
  [Relationship.Self]: {
    emoji: '🧑',
    placeholder: '',
  },
}
