import * as api from '../api'
import { orgs } from '../api'
import { Entity } from '../api/request'
import { RootState } from '../store'
import lookup from './lookup'
import * as roles from './roles'
import * as users from './users'

export function currentId(state: RootState): string {
  const currentUser = users.getById(state, users.id(state))
  return currentUser ? currentUser.fields.organization_id : ''
}

export function current(state: RootState): Entity<orgs.Fields> {
  const id = currentId(state)
  return getById(state, id)
}

export function isOrgPaidPlan(state: RootState): boolean {
  const id = currentId(state)
  const plan = getById(state, id).fields.plan
  return plan === 'normal' || plan === 'sales-trial'
}

export function getById(state: RootState, id: string): Entity<orgs.Fields> {
  return lookup(state, orgs.RESOURCE, id) || orgs.empty(id)
}

export function findByUserId(state: RootState, userId: string): Entity<orgs.Fields> | undefined {
  if (!userId) return

  const user = users.getById(state, userId)
  if (!user) return
  return lookup(state, orgs.RESOURCE, user.fields.organization_id)
}

export function findByCandidateId(state: RootState, candidateId: string): Entity<orgs.Fields> | undefined {
  const role = roles.findByCandidateId(state, candidateId)
  if (!role) return

  return findByUserId(state, role.fields.created_by)
}

export function findByRoleId(state: RootState, roleId: string): Entity<orgs.Fields> | undefined {
  const role = roles.findById(state, roleId)
  if (!role) return

  const orgByUserId = findByUserId(state, role.fields.created_by)
  if (orgByUserId) {
    return orgByUserId
  }

  const orgRelationship = role.relationships?.find(r => r.resource === api.orgs.RESOURCE)

  return lookup(state, orgs.RESOURCE, orgRelationship?.id || '')
}

export function privacyPolicyLink(state: RootState, id: string) {
  const org = getById(state, id)
  return org.fields.privacy_policy_link
    ? org.fields.privacy_policy_link
    : import.meta.env.VITE_CANDIDATE_PRIVACY_POLICY_LINK || ''
}
