import { Skeleton, Text } from '@common/components'
import classNames from 'classnames'
import { Icon } from 'components/Icons'
import TextField, { Label } from 'components/TextField'
import { designSystemColors } from 'core/design-system/colors'
import * as tracking from 'core/track'
import React from 'react'
import { BgColor, getBgColor } from '../RoleCreation/SurveyModuleCard'
import styles from './SearchTemplates.module.scss'

type Template = {
  id: string
  title: string
  icon: string
}

interface SearchTemplateProps {
  onSelect: (id: string) => void
  templates: Template[]
  quickTemplates: Template[]
  selectedTemplateId: string | null
  filterString: string
  setFilterString: (filter: string) => void
  header?: React.ReactNode
  isLoading?: boolean
}

const SearchTemplates: React.FC<SearchTemplateProps> = ({
  templates,
  quickTemplates,
  onSelect,
  selectedTemplateId,
  filterString,
  setFilterString,
  header,
  isLoading,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {header}
        <TextField
          placeholder="e.g. Sales Development Representative"
          value={filterString}
          onChange={e => setFilterString(e.target.value)}
          onFocus={tracking.roles.clickTemplateSearchbar}
          data-testid="select-role-template-search-input"
        />
      </div>
      <div className={styles.content}>
        {!quickTemplates?.length && !templates?.length && !isLoading && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Label>No templates found</Label>
          </div>
        )}
        {!!quickTemplates?.length && (
          <div>
            <Label>Organization templates</Label>
            <div className={styles.templateList}>
              {isLoading ? (
                <>
                  {Array.from({ length: 9 }).map((_, i) => (
                    <LoadingTemplate key={i} />
                  ))}
                </>
              ) : (
                <>
                  {quickTemplates.map((item, i) => (
                    <TemplateWithIcon
                      key={item.id}
                      i={i}
                      item={item}
                      color={designSystemColors.backgroundNeutralAccent}
                      onSelect={onSelect}
                      selectedTemplateId={selectedTemplateId}
                    />
                  ))}
                </>
              )}
            </div>
          </div>
        )}
        {!!templates?.length && (
          <div>
            <Label>HiPeople templates</Label>
            <div className={styles.templateList}>
              {isLoading ? (
                <>
                  {Array.from({ length: 30 }).map((_, i) => (
                    <LoadingTemplate key={i} />
                  ))}
                </>
              ) : (
                <>
                  {templates.map((item, i) => (
                    <TemplateWithIcon
                      key={item.id}
                      i={i}
                      item={item}
                      color={getBgColor(cardColors[i % cardColors.length])}
                      onSelect={onSelect}
                      selectedTemplateId={selectedTemplateId}
                    />
                  ))}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const TemplateWithIcon: React.FC<{
  i: number
  item: Template
  selectedTemplateId: string | null
  color?: string
  onSelect: (id: string) => void
}> = props => {
  return (
    <div
      className={classNames(styles.template, {
        [styles.selected]: props.selectedTemplateId === props.item.id,
      })}
      onClick={() => props.onSelect(props.item.id)}
      data-testid="select-role-template-button"
    >
      <Icon
        className={styles.icon}
        name={props.item.icon || cardIcons[props.i % cardIcons.length]}
        style={{
          backgroundColor: props.color ?? getBgColor(cardColors[props.i % cardColors.length]),
        }}
      />
      <Text variant="body-text">{props.item.title}</Text>
    </div>
  )
}

const LoadingTemplate = () => {
  return (
    <div className={styles.template}>
      <Skeleton
        style={{
          width: '100%',
          height: '100%',
          opacity: 0.2,
          backgroundColor: getBgColor(cardColors[0]),
        }}
      />
      <div className={styles.templateTitle}>
        <Skeleton
          style={{
            width: '100%',
            opacity: 0.2,
          }}
        />
      </div>
    </div>
  )
}

const cardColors: BgColor[] = ['purple', 'orange', 'green', 'blue']
const cardIcons: string[] = [
  'user-friends',
  'headset',
  'handshake',
  'people-arrows',
  'megaphone',
  'user-hard-hat',
  'dollar-sign',
  'wrench',
  'users',
  'balance-scale',
  'palette',
  'database',
  'tasks',
  'gavel',
  'transporter-empty',
  'users-cog',
  'first-aid',
  'chess-pawn',
  'user-headset',
  'search-dollar',
  'business-time',
]

export { SearchTemplates }
