import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Modal from '../../components/Modal'
import UpdateRequiredReferences from '../../components/UpdateRequiredReferences'
import { Content, ContentAndButtons } from '../../components/Wizard'
import Buttons from '../../components/Wizard/Buttons'
import { rem, style } from '../../core'
import * as selectors from '../../selectors'
import { Relationship } from '../../selectors/references'
import { RootState } from '../../store'
import * as slice from '../../store/update-references'

interface Props {
  open: boolean
  roleId: string
  setOpen: (b: boolean) => void
}

const Container = style()
  .size({
    maxWidth: rem(40),
    maxHeight: rem(30),
    width: 'calc(100vw - 200px)',
    height: 'calc(100dvh - 200px)',
  })
  .element()

const Component = (props: Props) => {
  const dispatch = useDispatch()
  const { roleid } = useParams<{
    roleid: string
  }>()

  const loading = useSelector((state: RootState) => state.updateReferences.loading)
  const requiredReferences = useSelector((state: RootState) => state.updateReferences.requiredReferences)
  const initRequiredReferences = useSelector((state: RootState) =>
    selectors.roles.findRequiredReferencesByRoleId(state, roleid || ''),
  )

  useEffect(() => {
    dispatch(slice.setRequiredReferences(initRequiredReferences))
  }, [dispatch, initRequiredReferences.length, props.open])

  return (
    <Modal title="Update Required References" open={props.open} setOpen={props.setOpen}>
      <Container>
        <ContentAndButtons>
          <Content standalone>
            <UpdateRequiredReferences
              requiredReferences={requiredReferences}
              onChangeRelationship={(i: number, relationship: Relationship) =>
                dispatch(slice.setRelationship({ i, relationship }))
              }
              onChangeDescription={(i: number, description: string) =>
                dispatch(slice.setDescription({ i, description }))
              }
              onRemove={(i: number) => dispatch(slice.removeRequiredReference(i))}
              onAdd={() =>
                dispatch(
                  slice.addRequiredReference({
                    relationship: Relationship.Peer,
                  }),
                )
              }
            />
          </Content>
          <Buttons
            loading={loading}
            next={() => dispatch(slice.update(props.roleId, requiredReferences))}
            back={() => {}}
            label={'Save'}
            forwardOnly
          />
        </ContentAndButtons>
      </Container>
    </Modal>
  )
}

export default Component
Component.displayName = 'ReferencesModal'
