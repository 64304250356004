import { fr, px2rem, size, style } from 'core'
import { text } from 'core/design-system/text'
import React from 'react'
import PairwiseScore from './PairwiseScore'

const Container = style()
  .grid({ columns: [fr(1), px2rem(132), fr(1)] })
  .select('> *', style().spacing({ outer: [size.auto, size.none] }))
  .spacing({ columns: px2rem(32) })
  .element()

const LeftText = text.bodyInteractive().sans({ align: 'right' }).element()
const RightText = text.bodyInteractive().element()

interface Props {
  score: number | null
  color?: string
  iconTextColor?: string
  hideScoreTooltip?: boolean
  startCaption: string
  endCaption: string
}

const Component: React.FC<Props> = ({ score, startCaption, endCaption, color, iconTextColor, hideScoreTooltip }) => (
  <Container>
    <LeftText>{startCaption}</LeftText>
    <PairwiseScore benchmark={score} color={color} textColor={iconTextColor} hideTooltip={hideScoreTooltip} />
    <RightText>{endCaption}</RightText>
  </Container>
)

export default Component
Component.displayName = 'Pairwise'
