import { Text } from 'App/CandidateOnly/Styles'
import Button from 'components/Button/ButtonV1'
import TextField from 'components/TextField'
import { rem, space, style } from 'core'
import * as tracking from 'core/track'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import * as selectors from 'selectors'
import { RootState } from 'store'
import { updateProfessionalNetwork } from 'store/candidates'

const Container = style()
  .size({ maxWidth: rem(26) })
  .select('> * + *', style().spacing({ outerTop: space.m }))
  .element()

const Component = () => {
  const { candidateid } = useParams<{ candidateid: string }>()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  const candidate = useSelector((state: RootState) => selectors.candidates.findById(state, candidateid || ''))
  const loading = useSelector((state: RootState) => state.candidates.loading)

  const [professionalNetwork, setProfessionalNetwork] = useState('')

  return (
    <Container>
      <Text>
        {t(
          'candidate-dashboard-intro.professional-network.description',
          `To allow the verification of your shared professional experience with the provided references, please submit
        your professional network profile (this information will not be shared with anybody aside for the recruiting
        team).`,
        )}
      </Text>
      <TextField
        type="url"
        label={t('candidate-dashboard-intro.professional-network.label', '🔗 Your professional network')}
        subLabel={t(
          'candidate-dashboard-intro.professional-network.sub-label',
          "Enter link (e.g. Linkedin, AngelList, Xing, Github); enter 'no-network.com' if you do not have any professional profile",
        )}
        value={professionalNetwork}
        placeholder="linkedin.com/in/john.doe"
        onChange={e => setProfessionalNetwork(e.target.value)}
        data-testid="candidate-professional-network-input"
      />
      <Button
        primary
        disabled={!professionalNetwork}
        loading={loading}
        onClick={() => {
          if (candidate?.fields.professional_network) {
            history.push({
              pathname: `/candidates/${candidateid}`,
              search: location.search,
            })
            return
          }
          dispatch(updateProfessionalNetwork(candidateid || '', professionalNetwork))
          tracking.candidatePortal.professionalNetworkAdd()
        }}
      >
        {candidate?.fields.professional_network
          ? t('candidate-dashboard-intro.professional-network.continue', 'Continue 🙌')
          : t('candidate-dashboard-intro.professional-network.update', 'Update')}
      </Button>
    </Container>
  )
}

export default Component
Component.displayName = 'ProfessionalNetwork'
