import { Fields, QuestionnaireEntryEntity, RESOURCE } from 'api/questionnaire-entries'
import { RootState } from 'store'
import lookup from './lookup'
import { findById as findQuestionnaireById } from './questionnaires'

function findById(state: RootState, id: string) {
  return lookup<Fields>(state, RESOURCE, id)
}

export function findByQuestionnaireId(state: RootState, questionnaireId: string) {
  const questionnaire = findQuestionnaireById(state, questionnaireId)

  if (!questionnaire) {
    return undefined
  }

  const questionnaireEntries = questionnaire.relationships?.filter(r => r.resource === RESOURCE).map(r => r.id) || []

  return (
    questionnaireEntries
      .map(id => findById(state, id))
      .filter(entity => entity !== undefined) as QuestionnaireEntryEntity[]
  ).sort((a, b) => a.fields.created_at - b.fields.created_at)
}
