import isValidEmail from 'all-good-emails'
import { DefaultButton } from 'components/Button/ButtonV1'
import TextField from 'components/TextField'
import { breakpoints, px2rem, space, style, vh } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import { useCandidate } from 'providers/candidates'
import { useWorkVerifications } from 'providers/work-verifications'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Footer } from '../Footer'
import { Header } from '../Header'

interface WorkVerificationSubmitSuccessProps {
  candidateName: string
}

const WorkVerificationSubmitSuccessStyle = style()
  .size({ minHeight: vh(100) })
  .flex({ direction: 'column' })
  .element()
const WorkVerificationSubmitSuccessTitle = text
  .cardHeader()
  .screen(breakpoints.mobile, style().spacing({ outerTop: px2rem(0), outerBottom: px2rem(24) }))
  .spacing({ outerTop: px2rem(64), outerBottom: px2rem(32) })
  .sans({ align: 'center' })
  .element('h1')
const WorkVerificationSubmitSuccessText = text.bodyText().element('p')

const WorkVerificationSubmitSuccess: React.FC<WorkVerificationSubmitSuccessProps> = ({ candidateName }) => {
  return (
    <WorkVerificationSubmitSuccessStyle>
      <Header />
      <Content>
        <WorkVerificationSubmitSuccessTitle>{`Thank you, ${candidateName}!`}</WorkVerificationSubmitSuccessTitle>
        <WorkVerificationSubmitSuccessText>
          {'We will reach out to the contacts you provided us with. Thanks for your time!'}
        </WorkVerificationSubmitSuccessText>
      </Content>
      <Footer />
    </WorkVerificationSubmitSuccessStyle>
  )
}

interface WorkVerificationProps {}

const Title = text
  .cardHeader()
  .screen(breakpoints.mobile, style().spacing({ outerTop: px2rem(0), outerBottom: px2rem(24) }))
  .spacing({ outerTop: px2rem(64), outerBottom: px2rem(32) })
  .sans({ align: 'center' })
  .element('h1')
const Description = text.bodyText().element('p')
const Form = style()
  .screen(breakpoints.mobile, style().spacing({ outerTop: px2rem(32) }))
  .spacing({ outerTop: px2rem(48) })
  .element()
const FormField = style()
  .screen(breakpoints.mobile, style().spacing({ outerBottom: px2rem(24) }))
  .spacing({ outerBottom: px2rem(32) })
  .element()
const FormFieldTitle = text
  .bodyEmphasis()
  .spacing({ outerBottom: px2rem(8) })
  .element('h2')
const WorkVerificationStyle = style()
  .size({ minHeight: vh(100) })
  .flex({ direction: 'column' })
  .element()

const Disclaimer = text
  .bodyInteractive()
  .spacing({ outerBottom: px2rem(8) })
  .element('p')
const DisclaimerBold = text
  .bodyEmphasis()
  .spacing({ outerBottom: px2rem(16) })
  .element('p')

const Content = style()
  .flex({
    grow: '1',
    shrink: '1',
    direction: 'column',
  })
  .set('alignSelf', 'center')
  .size({ maxWidth: px2rem(576) })
  .spacing({ inner: px2rem(20) })
  .element()

const SubmitButton = style(DefaultButton)
  .bg({ color: designSystemColors.backgroundNeutralAccent })
  .fg(designSystemColors.white)
  .spacing({
    innerLeft: px2rem(80),
    innerRight: px2rem(80),
    outer: [space.none, space.auto],
  })
  .element('button')

const WorkVerification: React.FC<WorkVerificationProps> = () => {
  const { candidateid } = useParams<{ candidateid?: string }>()
  const { candidateProfile, isLoading: isCandidateLoading } = useCandidate({
    candidateId: candidateid || '',
  })
  const {
    updateWorkVerifications,
    workVerifications,
    isLoading: isWorkVerificationsLoading,
  } = useWorkVerifications({
    candidateId: candidateid || '',
  })

  const [workVerificationDetails, setWorkVerificationDetails] = useState<
    Record<string, { id: string; hr_email: string }>
  >({})

  const onSubmitCallback = async () => {
    if (!candidateid) {
      return
    }
    const updatePayload = Object.values(workVerificationDetails)
    await updateWorkVerifications(candidateid, updatePayload)
  }

  const areAllVerificationsDetailsComplete = workVerifications
    ? workVerifications.every(verification => isValidEmail(verification.fields.hr_email))
    : false

  if (areAllVerificationsDetailsComplete) {
    return <WorkVerificationSubmitSuccess candidateName={candidateProfile?.fields.full_name || ''} />
  }

  const isLoading = isCandidateLoading || isWorkVerificationsLoading
  if (isLoading || !candidateProfile) {
    return null
  }

  const areAllEmailsValid = Object.values(workVerificationDetails)
    .map(details => details.hr_email)
    .every(isValidEmail)

  const incompleteWorkVerifications = (workVerifications || []).filter(
    verification => !isValidEmail(verification.fields.hr_email),
  )

  const companyNames = incompleteWorkVerifications
    .map(workVerification => workVerification.fields.company_name)
    .join(', ')

  return (
    <WorkVerificationStyle>
      <Header />
      <Content>
        <Title>{'Add HR contacts for work verification'}</Title>
        <Description>{`Hey ${candidateProfile.fields.full_name}!`}</Description>
        <Description>
          {
            'To proceed with the work verification, please provide the contact of a HR person for each of the following companies you’ve worked with:'
          }
        </Description>
        <Form>
          {incompleteWorkVerifications.map(workVerification => {
            const companyName = workVerification.fields.company_name
            const id = workVerification.id
            const email = workVerificationDetails[companyName]?.hr_email || ''
            return (
              <FormField key={id}>
                <FormFieldTitle>{companyName}</FormFieldTitle>
                <TextField
                  label="HR contact (email)"
                  value={email}
                  error={!isValidEmail(email)}
                  placeholder={'name@company.com'}
                  onChange={ev => {
                    const newValue = ev.target.value
                    setWorkVerificationDetails(companyEmails => ({
                      ...companyEmails,
                      [companyName]: {
                        id,
                        hr_email: newValue,
                      },
                    }))
                  }}
                />
              </FormField>
            )
          })}
        </Form>
        <Disclaimer>{`By submitting, you agree on the following statement:`}</Disclaimer>
        <DisclaimerBold>
          {`Hereby I consent that HiPeople can reach out to contacts at ${companyNames} to verify time of employment and title of position held.`}
        </DisclaimerBold>
        <SubmitButton inline disabled={!areAllEmailsValid} onClick={onSubmitCallback}>
          {'Submit'}
        </SubmitButton>
      </Content>
      <Footer />
    </WorkVerificationStyle>
  )
}

export default WorkVerification
