import { Card } from '@common/components'
import { getSelfReferenceToken, getSelfReferenceTokenv2 } from 'api/candidates'
import { useSelfAssessmentURL } from 'App/CandidateOnly'
import InfoCard from 'App/CandidateOnly/InfoCard'
import { A, Link, TextBold, TextHeader } from 'App/CandidateOnly/Styles'
import { Icon } from 'components/Icons'
import { breakpoints, colors, fr, height, px, px2rem, rem, space, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import { candidateBaseURL } from 'core/utils'
import { useCandidate } from 'providers/candidates'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import * as selectors from 'selectors'
import { RootState } from 'store'

const Container = style()
  .size({ maxWidth: rem(36) })
  .spacing({ outerBottom: space.xl })
  .select('> * + *', style().spacing({ outerTop: space.m }))
  .element()

const Portal = () => {
  const location = useLocation()
  const { t } = useTranslation()

  const { candidateid } = useParams<{ candidateid: string }>()
  const { candidate, candidateProfile } = useCandidate({
    candidateId: candidateid,
  })
  const selfReference = useSelector((state: RootState) =>
    selectors.references.findSelfReferenceByCandidateId(state, candidateid || ''),
  )
  const refCheckDeadline = useSelector((state: RootState) => selectors.candidates.getDeadline(state, candidateid || ''))
  const org = useSelector((state: RootState) => selectors.orgs.findByCandidateId(state, candidateid || ''))

  const role = useSelector((state: RootState) => selectors.roles.findByCandidateId(state, candidate?.id || ''))

  const [selfReferenceToken, setSelfReferenceToken] = useState('')
  useEffect(() => {
    if (!candidate?.id || !role?.fields.ask_self_reference) return
    ;(candidate.fields.questionnaire_id
      ? getSelfReferenceTokenv2(candidateid || '')
      : getSelfReferenceToken(candidateid || '')
    ).then(([response, errors]) => {
      if (errors) return
      setSelfReferenceToken(response?.result.fields.token || '')
    })
  }, [candidate?.id, role?.id])

  const selfReferenceSubmission = useSelector((state: RootState) =>
    selectors.questionnaireSubmissions.findByReferenceId(state, selfReference?.id || ''),
  )

  const selfAssessment = useSelector(
    (state: RootState) =>
      selectors.questionnaireSubmissions.findSelfAssessmentSubmissionsByCandidateId(state, candidate?.id || '')?.[0],
  )

  const selfAssessmentURL = useSelfAssessmentURL(candidateid, role?.id || '', org)

  if (!refCheckDeadline) return <></>

  const referenceCheckInProgress = !!candidate?.fields.submitted_at
  const referenceCheckCompleted = !!candidate?.fields.completed_at
  const referenceCheckOverdue = !!candidate?.fields.expired_at
  const selfReferenceCompleted =
    !!selfReference?.fields.form_response_id || selfReferenceSubmission?.fields.submitted_at
  const finished = referenceCheckCompleted && selfReferenceCompleted

  const selfRefCheckDeadline = new Date((selfReferenceSubmission?.fields.due_at || 0) / 1e6)

  const base = candidateBaseURL(org?.fields.slug || '')
  const selfReferenceURL = candidate?.fields.questionnaire_id
    ? `${base}/references/${selfReference?.id}/submissions/${selfReferenceSubmission?.id}?token=${selfReferenceToken}`
    : `${base}/references/${selfReference?.id}?token=${selfReferenceToken}`

  const selfAssessmentCompleted = !!selfAssessment?.fields.submitted_at

  const selfAssessmentDeadline = new Date((selfAssessment?.fields.due_at || 0) / 1e6)

  return (
    <Container>
      <TextHeader data-testid="candidate-portal-header">{`${selectors.possessiveFirstName(
        candidateProfile?.fields.full_name || '',
      )} dashboard`}</TextHeader>
      {finished ? (
        <Card big style={{ paddingBottom: '20px', paddingTop: '20px' }}>
          <InfoCard heading={`✅ Done`}>Your application has been completed. Thanks! 👍</InfoCard>
        </Card>
      ) : (
        <Card big style={{ paddingBottom: '20px', paddingTop: '20px' }}>
          <InfoCard
            heading={t('candidate-dashboard-intro.portal.title', '👔 Your next steps with {companyName}', {
              companyName: org?.fields.name,
            })}
          >
            {t(
              'candidate-dashboard-intro.portal.text',
              'Welcome {candidateName}! Please complete the required steps below to continue your process with {companyName}',
              { candidateName: candidateProfile?.fields.full_name, companyName: org?.fields.name },
            )}
          </InfoCard>
        </Card>
      )}

      <Card big>
        <Box>
          {/* reference check */}
          {role?.fields.ask_reference_check && candidate?.fields.invited_at ? (
            <Link
              to={{
                pathname: `/candidates/${candidateid}/manage-references`,
                search: location.search,
              }}
              data-testid="candidate-portal-reference-check-link"
            >
              <MediaCard>
                <CardHeader colour="#DABAE6" completed={referenceCheckCompleted}>
                  <Icon name="users" />
                </CardHeader>
                <CardInfo>
                  <TextBold>{t('candidate-dashboard-intro.portal.reference-check', 'Reference check')}</TextBold>

                  {referenceCheckOverdue ? (
                    <SubText>{t('candidate-dashboard-intro.portal.overdue', '⚠️ overdue')}</SubText>
                  ) : referenceCheckCompleted ? (
                    <CompletedText>{t('candidate-dashboard-intro.portal.completed', '✅ completed')}</CompletedText>
                  ) : referenceCheckInProgress ? (
                    <SubText>{t('candidate-dashboard-intro.portal.waiting', '📝 waiting')}</SubText>
                  ) : (
                    <SubText>
                      {t('candidate-dashboard-intro.portal.due', '⚠️ due {{date}}', {
                        date: selectors.dateString(refCheckDeadline),
                      })}
                    </SubText>
                  )}
                </CardInfo>
              </MediaCard>
            </Link>
          ) : (
            <></>
          )}
          {/* self reference */}
          {role?.fields.ask_self_reference && candidate?.fields.invited_at ? (
            <A
              href={selfReferenceURL}
              disabled={selfReferenceCompleted}
              data-testid="candidate-portal-self-reference-link"
            >
              <MediaCard>
                <CardHeader colour="#FFC691" completed={selfReferenceCompleted}>
                  <Icon name="file-alt" />
                </CardHeader>
                <CardInfo>
                  <TextBold>Self-reference</TextBold>

                  {selfReferenceCompleted ? (
                    <CompletedText>{t('candidate-dashboard-intro.portal.completed', '✅ completed')}</CompletedText>
                  ) : (
                    <SubText>
                      {t('candidate-dashboard-intro.portal.due', '⚠️ due {date}', {
                        date: selectors.dateString(selfRefCheckDeadline),
                      })}
                    </SubText>
                  )}
                </CardInfo>
              </MediaCard>
            </A>
          ) : (
            <></>
          )}
          {/* self assessment */}
          {selfAssessment && (
            <A
              href={selfAssessmentURL}
              disabled={selfAssessmentCompleted}
              data-testid="candidate-portal-self-assessment-link"
            >
              <MediaCard>
                <CardHeader colour="#FFC691" completed={selfAssessmentCompleted}>
                  <Icon name="file-alt" />
                </CardHeader>
                <CardInfo>
                  <TextBold>{t('candidate-dashboard-intro.portal.assessment', 'Assessment')}</TextBold>

                  {selfAssessmentCompleted ? (
                    <CompletedText>{t('candidate-dashboard-intro.portal.completed', '✅ completed')}</CompletedText>
                  ) : (
                    <SubText>
                      {t('candidate-dashboard-intro.portal.due', '⚠️ due {date}', {
                        date: selectors.dateString(selfAssessmentDeadline),
                      })}
                    </SubText>
                  )}
                </CardInfo>
              </MediaCard>
            </A>
          )}
        </Box>
      </Card>
    </Container>
  )
}

export default Portal

const SubText = text
  .bodyInteractive()
  .color({ fg: designSystemColors.typographySecondary })
  .spacing({ outerTop: space.xxxs })
  .element()

const CompletedText = style(SubText).color({ fg: designSystemColors.uiStatusSuccess }).element()

const Box = style()
  .size({ width: '100%' })
  .grid({ columns: [fr(1), fr(1)] })
  .screen(breakpoints.mobile, style().grid({ columns: [fr(1)] }))
  .spacing({ gap: space.m })
  .element()

const MediaCard = style()
  .size({ width: '1fr' })
  .nooverflow()
  .border({
    radius: px(8),
    around: 'solid 1px',
    color: designSystemColors.borderDefault,
  })
  .element()

const CardHeader = style()
  .size({ height: px2rem(120) })
  .flex({ alignItems: 'center', justifyContent: 'center' })
  .with(({ colour, completed }: { colour: string; completed: boolean }) =>
    style().color({
      bg: completed ? designSystemColors.backgroundNeutralTertiary : colour,
    }),
  )
  .text({ size: px2rem(40), color: colors.white })
  .element()

const CardInfo = style().sans({ height: height.s }).spacing({ inner: space.xs }).element()
