import { AiSuggestionsIcon, Banner } from '@common/components'
import Button from 'components/Button/ButtonV2'
import { fr, px2rem, size, style } from 'core'
import { text } from 'core/design-system/text'
import React, { useCallback, useState } from 'react'
import CustomQuestionList from './CustomQuestionList'
import { CustomQuestionModal } from './CustomQuestionModal'
import ModuleSelectTable, { ModulesData } from './ModuleSelectTable'
import { PreviewChips, PreviewContent, PreviewModuleModal } from './PreviewModuleModal'

export type { ModulesData } from './ModuleSelectTable'

const Title = text
  .highlight()
  .sans({ align: 'center' })
  .spacing({ outerBottom: px2rem(16) })
  .element('h1')
const Description = text.bodyText().sans({ align: 'center' }).element('p')

const Content = style()
  .size({ width: px2rem(800) })
  .spacing({ outer: [px2rem(64), size.auto] })
  .element()
const AddCustomQuestionButton = style()
  .flex({ justifyContent: 'center' })
  .noborders()
  .bg({ transparent: true })
  .pointer()
  .element('button')
const CustomQuestionListContainer = style()
  .flex({ direction: 'column' })
  .spacing({ gap: px2rem(16), outerTop: px2rem(32) })
  .element()

const Buttons = style()
  .grid({ columns: [fr(1), fr(1)] })
  .spacing({ gap: px2rem(16), outer: [px2rem(0), size.auto] })
  .size({ width: px2rem(512) })
  .element()

const QuestionTitle = text.bodyEmphasis().element('p')

const Text = text.bodyText().element('p')

const TemplateCreationSelectionStyle = style().element()

export type TemplatedCreationSelectionValues = {
  selectedModuleIds: string[]
  customQuestions: {
    text: string
  }[]
}
interface TemplateCreationSelectionProps {
  next: () => any
  getVariableCopy: (text: string) => string
  modules: ModulesData
  values: TemplatedCreationSelectionValues
  onChange: React.Dispatch<React.SetStateAction<TemplatedCreationSelectionValues>>
  canManageContent: boolean
  roleId: string | null
}

export const TemplateCreationSelection: React.FC<TemplateCreationSelectionProps> = ({
  next,
  getVariableCopy,
  modules,
  values,
  onChange,
  canManageContent,
  roleId,
}) => {
  const toggleModuleSelection = useCallback(
    (moduleId: string) => {
      onChange(values => {
        let selectedModuleIds = values.selectedModuleIds
        if (selectedModuleIds.some(id => id === moduleId)) {
          selectedModuleIds = selectedModuleIds.filter(id => id !== moduleId)
        } else {
          selectedModuleIds = [...selectedModuleIds, moduleId]
        }
        return { ...values, selectedModuleIds }
      })
    },
    [onChange],
  )

  const [selectedCustomQuestionIndex, setSelectedCustomQuestionIndex] = React.useState<number | null>(null)

  const [isCustomQuestionModalShown, setIsCustomQuestionModalShown] = React.useState(false)

  const [targetedModuleIndex, setTargetedModuleIndex] = useState<number | null>(null)

  const isNextButtonDisabled = values.selectedModuleIds.length + values.customQuestions.length === 0

  function onModuleDetailsClick(targetedModuleIndex: number) {
    setTargetedModuleIndex(targetedModuleIndex)
  }

  return (
    <>
      <TemplateCreationSelectionStyle>
        <Title>{'Select content'}</Title>
        <Description>{'Select the areas to assess the candidate on.'}</Description>
        <Content>
          <ModuleSelectTable
            modules={modules}
            selectedModuleIds={values.selectedModuleIds}
            toggleModuleSelection={toggleModuleSelection}
            isToggleDisabled={!canManageContent}
            onModuleDetailsClick={onModuleDetailsClick}
          />
          <CustomQuestionListContainer>
            {values.customQuestions.length > 0 && (
              <CustomQuestionList
                customQuestions={values.customQuestions}
                removeCustomQuestion={removedIndex => {
                  onChange(values => {
                    const customQuestions = values.customQuestions.filter((_q, i) => i !== removedIndex)
                    return { ...values, customQuestions }
                  })
                }}
                editCustomQuestion={id => setSelectedCustomQuestionIndex(id)}
              />
            )}
            <AddCustomQuestionButton
              onClick={() => setIsCustomQuestionModalShown(true)}
              ariaLabel="Add a custom free-text question"
            >
              <Banner
                variant={roleId ? 'ai' : 'light'}
                startIcon={roleId ? <AiSuggestionsIcon /> : null}
              >{`Don't see what you're looking for? Click here to add a custom free-text question`}</Banner>
            </AddCustomQuestionButton>
          </CustomQuestionListContainer>
        </Content>
        <Buttons>
          <div /> {/* Dummy Content to take place of prev button */}
          <Button buttonType="primary" onClick={next} disabled={isNextButtonDisabled}>
            {'Next step'}
          </Button>
        </Buttons>
        {targetedModuleIndex !== null && (
          <>
            <PreviewModuleModal
              close={() => setTargetedModuleIndex(null)}
              title={modules[targetedModuleIndex].name}
              titleEmoji={modules[targetedModuleIndex].emoji}
              description={modules[targetedModuleIndex].description}
            >
              {modules[targetedModuleIndex].preview && (
                <PreviewContent>
                  <QuestionTitle>{getVariableCopy(modules[targetedModuleIndex].preview?.question || '')}</QuestionTitle>
                  {modules[targetedModuleIndex].preview?.options && (
                    <PreviewChips chipData={modules[targetedModuleIndex].preview?.options} />
                  )}
                  {modules[targetedModuleIndex].preview?.action && (
                    <Text>{modules[targetedModuleIndex].preview?.action}</Text>
                  )}
                </PreviewContent>
              )}
            </PreviewModuleModal>
          </>
        )}
      </TemplateCreationSelectionStyle>
      {/* Handle the case for editing a question */}
      {selectedCustomQuestionIndex !== null && (
        <CustomQuestionModal
          close={() => setSelectedCustomQuestionIndex(null)}
          roleId={roleId}
          onSubmit={questions => {
            onChange(values => {
              return {
                ...values,
                customQuestions: questions,
              }
            })
            setSelectedCustomQuestionIndex(null)
          }}
          initialValues={values.customQuestions}
        />
      )}
      {/* Handle the case for adding a new question */}
      {isCustomQuestionModalShown && (
        <CustomQuestionModal
          close={() => setIsCustomQuestionModalShown(false)}
          roleId={roleId}
          onSubmit={questions => {
            onChange(values => {
              return {
                ...values,
                customQuestions: questions,
              }
            })
            setIsCustomQuestionModalShown(false)
          }}
          initialValues={values.customQuestions}
        />
      )}
    </>
  )
}
