const colorValues = {
  white: '#FFFFFF',
  grey98: '#F9F9F9',
  grey93: '#EEEEEE',
  grey89: '#E4E4E4',
  grey80: '#CCCCCC',
  grey40: '#666666',
  grey31: '#505050',
  grey20: '#333333',
  grey10: '#191919',
  yellow50: '#FFF5D2',
  yellow70: '#FFAE4F',
  orange10: '#FFF1DD',
  orange70: '#AB5A02',
  purple08: '#EAEBF7',
  purple10: '#F5EBFC',
  purple70: '#9520D6',
  blue10: '#EAF2FD',
  blue30: '#4AB2F7',
  blue70: '#055FE2',
  cyanPri: '#078EC8',
  cyanBack: '#DDF7FF',
  indigo20: '#C0C6EE',
  indigo60: '#4C49EE',
  green10: '#E9F9EF',
  green70: '#007F70',
  green90: '#008440',
  red10: '#FFE5EC',
  red60: '#D6342C',
  red70: '#BD3052',
  peach: '#FFEFDB',
  transparent: 'transparent',
}

type ColorTheme = {
  white: string
  agnosticGrey: string
  backgroundNeutralAccent: string
  backgroundNeutralPrimary: string
  backgroundNeutralPrimaryHover: string
  backgroundNeutralPrimaryInverted: string
  backgroundNeutralSecondary: string
  backgroundNeutralSecondaryHover: string
  backgroundNeutralTertiary: string
  backgroundPrimaryAccent: string
  backgroundSecondaryAccent: string
  borderDefault: string
  informationBlueBackground: string
  informationBluePrimary: string
  informationCyanBackground: string
  informationCyanPrimary: string
  informationGreenBackground: string
  informationGreenPrimary: string
  informationOrangeBackground: string
  informationOrangePrimary: string
  informationPurpleBackground: string
  informationPurplePrimary: string
  informationRedBackground: string
  informationRedPrimary: string
  typographyInvertedPrimary: string
  typographyPrimary: string
  typographySecondary: string
  typographyPrimaryAccent: string
  uiVerified: string
  uiStatusSuccess: string
  uiStatusError: string
  uiStatusWarningBackground: string
  uiStatusWarningSecondary: string
  transparent: string
  trialHeader: string
  informationYellowBackground: string
}
export const DefaultTheme: ColorTheme = {
  white: colorValues.white,
  agnosticGrey: colorValues.grey80,
  backgroundNeutralAccent: colorValues.grey31,
  backgroundNeutralPrimary: colorValues.white,
  backgroundNeutralPrimaryHover: colorValues.grey98,
  backgroundNeutralPrimaryInverted: colorValues.grey20,
  backgroundNeutralSecondary: colorValues.grey98,
  backgroundNeutralSecondaryHover: colorValues.grey93,
  backgroundNeutralTertiary: colorValues.grey89,
  backgroundPrimaryAccent: colorValues.purple08,
  backgroundSecondaryAccent: colorValues.indigo20,
  borderDefault: colorValues.grey89,
  informationBlueBackground: colorValues.blue10,
  informationBluePrimary: colorValues.blue70,
  informationCyanBackground: colorValues.cyanBack,
  informationCyanPrimary: colorValues.cyanPri,
  informationGreenBackground: colorValues.green10,
  informationGreenPrimary: colorValues.green70,
  informationOrangeBackground: colorValues.orange10,
  informationOrangePrimary: colorValues.orange70,
  informationPurpleBackground: colorValues.purple10,
  informationPurplePrimary: colorValues.purple70,
  informationRedBackground: colorValues.red10,
  informationRedPrimary: colorValues.red70,
  typographyInvertedPrimary: 'var(--brand-primary-text)',
  typographyPrimary: colorValues.grey20,
  typographyPrimaryAccent: 'var(--brand-primary)',
  typographySecondary: colorValues.grey40,
  uiVerified: colorValues.blue30,
  uiStatusSuccess: colorValues.green90,
  uiStatusError: colorValues.red60,
  uiStatusWarningBackground: colorValues.yellow50,
  uiStatusWarningSecondary: colorValues.yellow70,
  transparent: colorValues.transparent,
  trialHeader: colorValues.peach,
  informationYellowBackground: '#fffbec',
}

export const designSystemColors = DefaultTheme
