import { size, space, style } from 'core'
import { useCandidateProfile } from 'providers/candidates'
import { useReferenceQuestionnaireSubmissions } from 'providers/questionnaire'
import { useQuestionnaireAnswers } from 'providers/questionnaire/questionnaire-answers'
import { useReferenceFeedbackStyles, useReferences } from 'providers/references'
import React, { Dispatch, SetStateAction } from 'react'
import { getProfessionalNetworkOf, Relationship, relationshipOf } from 'selectors/references'
import { Visible } from '.'
import QuestionnaireAnswerBlock, { AnswersType, ReferenceDetails } from './QuestionnaireAnswerBlock'

const QuestionnaireAnswerBlockListUIStyle = style()
  .grid()
  .spacing({ rows: space.l })
  .size({ width: size.fill })
  .element()

interface QuestionnaireAnswerBlockListUIProps {
  updateVisible: Dispatch<SetStateAction<Visible>>
  candidateName: string
  references: ReferenceDetails[]
  questionnaireAnswerBlocks: {
    id: string
    title: string
    emoji?: string
    answers: AnswersType
  }[]
}

const QuestionnaireAnswerBlockListUI: React.FC<QuestionnaireAnswerBlockListUIProps> = ({
  candidateName,
  references,
  updateVisible,
  questionnaireAnswerBlocks,
}) => {
  return (
    <QuestionnaireAnswerBlockListUIStyle>
      {questionnaireAnswerBlocks.map(({ id, title, emoji, answers }) => (
        <QuestionnaireAnswerBlock
          key={id}
          id={id}
          title={title}
          emoji={emoji}
          candidateName={candidateName}
          references={references}
          answers={answers}
          onBlockVisible={isBlockVisible => updateVisible(visible => ({ ...visible, [id]: isBlockVisible }))}
        />
      ))}
    </QuestionnaireAnswerBlockListUIStyle>
  )
}

const RELATIONSHIP_PRIORITY_INDEX: Record<Relationship, number> = {
  Manager: 1,
  Peer: 2,
  'Direct Report': 3,
  Client: 4,
  Candidate: 6,
  'Student Peer': 6,
  'Any Relationship': 7,
}

function sortReferenceDetails(ref1: ReferenceDetails, ref2: ReferenceDetails) {
  const relationship1Priority = RELATIONSHIP_PRIORITY_INDEX[ref1.relationship]
  const relationship2Priority = RELATIONSHIP_PRIORITY_INDEX[ref2.relationship]

  return relationship1Priority - relationship2Priority
}

interface QuestionnaireAnswerBlockListProps {
  candidateId: string
  highlightedReferenceId?: string
  updateVisible: Dispatch<SetStateAction<Visible>>
}

const QuestionnaireAnswerBlockList: React.FC<QuestionnaireAnswerBlockListProps> = ({
  candidateId,
  highlightedReferenceId,
  updateVisible,
}) => {
  const candidateProfile = useCandidateProfile({ candidateId })
  const { references } = useReferences({ candidateId })
  const { questionnaireSubmissions } = useReferenceQuestionnaireSubmissions({
    candidateId,
  })

  const { feedbackStyles } = useReferenceFeedbackStyles({
    referenceIds: references.map(i => i.id),
  })

  const { groupedQuestions } = useQuestionnaireAnswers({ candidateId })

  if (!candidateProfile) {
    return null
  }

  const referenceDetails = references
    .filter(ref => {
      if (highlightedReferenceId) {
        return ref.id === highlightedReferenceId
      }
      return true
    })
    .map(ref => {
      const professionalNetwork = getProfessionalNetworkOf(ref)
      const style = feedbackStyles?.[ref.id]
      return {
        id: ref.id,
        relationship: relationshipOf(ref),
        professionalNetwork: ref.fields.linkedin
          ? {
              name: professionalNetwork?.name || '',
              url: professionalNetwork?.url || '',
            }
          : undefined,
        name: ref.fields.full_name,
        email: ref.fields.email,
        companyName: ref.fields.company_name,
        from: ref.fields.from,
        to: ref.fields.to,
        isVerified: ref.fields.is_verified,
        isReviewed: ref.fields.is_reviewed,
        rejectionReason: ref.fields.rejection_reason as any,
        completed: (questionnaireSubmissions?.[ref.id]?.fields.submitted_at ?? 0) > 0,
        feedbackStyle: style || undefined,
      }
    })
    .sort(sortReferenceDetails)

  const candidateName = candidateProfile.fields.full_name

  return (
    <QuestionnaireAnswerBlockListUI
      candidateName={candidateName}
      references={referenceDetails}
      updateVisible={updateVisible}
      questionnaireAnswerBlocks={groupedQuestions}
    />
  )
}

export default QuestionnaireAnswerBlockList
