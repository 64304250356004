export const RESOURCE = 'integration_connections'

export interface Fields {
  id: string
  integrated_product: 'reference-check' | 'self-assessment'
  integration_id: string
  open_job_role_id: string
  ats_job_id: string
  ats_job_interview_stage_id: string
  created_at: number
  updated_at: number
}
