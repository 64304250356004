import React from 'react'
import { Text } from '../../Text/Text'
import { ToggleSwitch } from '../../ToggleSwitch/ToggleSwitch'
import classes from './TogglePeriod.module.scss'

interface Props {
  on: boolean
  setOn: (on: boolean) => void
  height?: string
}

export const TogglePeriod = (props: Props) => {
  return (
    <div
      className={classes.container}
      style={{
        height: props.height || '7rem',
      }}
    >
      <div>
        <Text variant={'body-text'} className={classes.text}>
          <span>Get 2 months free</span>
          <br />
          <div className={!props.on ? classes.selected : ''}>Annual plans</div>
        </Text>
        <ToggleSwitch on={props.on} setOn={props.setOn} style={{ verticalAlign: 'middle' }} />
        <Text variant={'body-text'} className={classes.text}>
          <div className={props.on ? classes.selected : ''}>Monthly plans</div>
        </Text>
      </div>
    </div>
  )
}
