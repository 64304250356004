import { LockedByPlanProps, Tabs } from '@common/components'
import React from 'react'
import { BsStars } from 'react-icons/bs'
import { useHistory, useLocation } from 'react-router-dom'
import { isPresent } from '../../../core/utils'

export type AssessmentTab = AssessmentTabSimple | AssessmentTabWithLocked

type AssessmentTabSimple = {
  title: string
  to: string
  enabled: boolean
  page: React.ReactNode
  tracking: () => void
}

type AssessmentTabWithLocked = AssessmentTabSimple & {
  isLocked: boolean
  locked: {
    modalData: LockedByPlanProps['modalData']
    tracking: LockedByPlanProps['tracking']
    upgradeTo: LockedByPlanProps['upgradeTo']
  }
}

const isAssessmentTabWithLocked = (tab: AssessmentTab): tab is AssessmentTabWithLocked => {
  return (
    (tab as AssessmentTabWithLocked).isLocked !== undefined && (tab as AssessmentTabWithLocked).locked !== undefined
  )
}

interface AssessmentTabsProps {
  tabs: (AssessmentTab | AssessmentTabWithLocked)[]
}

const ContentTabs: React.FC<AssessmentTabsProps> = props => {
  const { pathname } = useLocation()
  const history = useHistory()

  const defaultActiveTabId = props.tabs.find(tab => tab?.to === pathname)?.to || props.tabs[0].to

  const onClickTab = (tab: AssessmentTab) => {
    tab.tracking()
    // set pathname to tab.to
    history.push(tab.to)
  }

  return (
    <Tabs
      defaultActiveTabId={defaultActiveTabId}
      tabs={props.tabs
        .map(tab =>
          tab.enabled
            ? {
                id: tab.to,
                label:
                  tab.title === 'AI Insights' ? (
                    <>
                      <BsStars /> {tab.title}
                    </>
                  ) : (
                    tab.title
                  ),
                content: tab.page,
                onClickTab: () =>
                  (isAssessmentTabWithLocked(tab) && !tab.isLocked) || !isAssessmentTabWithLocked(tab)
                    ? onClickTab(tab)
                    : () => {},
                special: tab.title === 'AI Insights',
                locked:
                  isAssessmentTabWithLocked(tab) && tab.isLocked
                    ? {
                        modalData: tab.locked?.modalData,
                        tracking: tab.locked?.tracking as LockedByPlanProps['tracking'],
                        upgradeTo: tab.locked?.upgradeTo,
                      }
                    : undefined,
              }
            : null,
        )
        .filter(isPresent)}
    />
  )
}

export default ContentTabs
