import React from 'react'
import { space, style } from '../../core'

const Container = style()
  .grid()
  .spacing({ outer: [space.m, space.none], rows: space.m })
  .element()

interface Props {
  children: React.ReactNode
}

const Component = (props: Props) => {
  return <Container>{props.children}</Container>
}

export default Component
Component.displayName = 'Form'
