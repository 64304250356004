import { useSelector } from 'react-redux'
import { findById, findBySlug } from 'selectors/modules'
import { RootState } from 'store'
import { CONTENT_SLUGS } from 'store/new-role/content'

export function useModule({ id, slug }: { id?: string; slug?: CONTENT_SLUGS }) {
  const module = useSelector((state: RootState) => {
    if (id) {
      return findById(state, id)
    }
    // FIXME: we should rarely need this. always check by module ID from the questionnaire to get the correct module version
    if (slug) {
      return findBySlug(state, slug || '')
    }
    return undefined
  })

  return module
}
