import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as api from 'api'
import { skills } from 'api'
import { AppThunk } from 'core/store'
import { add as notify } from 'store/notifications'
import { addEntities } from 'store/resources'

interface State {
  skills: api.request.Entity<api.skills.Fields>[]
}

const name = 'skills'
const initialState: State = {
  skills: [],
}

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setSkills(state, action: PayloadAction<api.request.Entity<api.skills.Fields>[]>) {
      state.skills = action.payload
    },
    addSkill(state, action: PayloadAction<api.request.Entity<api.skills.Fields>>) {
      state.skills = [...state.skills, action.payload]
    },
  },
})

export const { setSkills, addSkill } = slice.actions

export const load = (): AppThunk => async dispatch => {
  const [response, errors] = await skills.all()
  if (errors) {
    errors.forEach(e => {
      dispatch(notify({ error: e.message }))
    })
    return
  }

  if (response) {
    dispatch(addEntities(response))
    dispatch(setSkills(response.result))
  }
}

export const create =
  (heading: string, skillIdCallback: (string) => any): AppThunk =>
  async dispatch => {
    const [response, errors] = await skills.create(heading)
    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    if (response) {
      dispatch(addEntities(response))
      dispatch(addSkill(response.result))
      skillIdCallback(response.result.id)
    }
  }

export default slice.reducer
