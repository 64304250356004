import { Card } from '@common/components'
import { Link, PreformattedText } from 'App/CandidateOnly/Styles'
import Button from 'components/Button/ButtonV2'
import { px2rem, space, style } from 'core'
import * as tracking from 'core/track'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import * as selectors from 'selectors'
import { RootState } from 'store'
import { defaultTextTemplate } from './PublicIntroScreen'

const Container = style()
  .size({ width: '100%', maxWidth: px2rem(590) })
  .select('> * + *', style().spacing({ outerTop: space.m }))
  .element()

const ButtonsContainer = style()
  .size({ width: '100%' })
  .spacing({ outerTop: px2rem(50) })
  .flex({
    alignItems: 'center',
    justifyContent: 'center',
  })
  .element()

function interpolateString(str: string | undefined, variables: Record<string, string>) {
  if (typeof str !== 'string') {
    return ''
  }
  let interpolatedStr = str
  for (const [key, value] of Object.entries(variables)) {
    interpolatedStr = interpolatedStr?.replaceAll(`{${key}}`, value) as string
  }
  return interpolatedStr
}

function prepareIntroText(t, placeholders, roleBranding, defaultText): string {
  let toInterpolateText = roleBranding
  if (roleBranding === defaultText) {
    toInterpolateText = t('candidate-dashboard-intro.default-text', defaultText)
  }

  return interpolateString(toInterpolateText, placeholders)
}

const IntroScreen = () => {
  const { t } = useTranslation()

  const { candidateid } = useParams<{ candidateid: string }>()
  const location = useLocation()

  const role = useSelector((state: RootState) => selectors.roles.findByCandidateId(state, candidateid || ''))
  const org = useSelector((state: RootState) => selectors.orgs.findByCandidateId(state, candidateid || ''))

  const placeholders: Record<string, string> = {
    ROLE_TITLE: role?.fields.public_name || '',
    COMPANY_NAME: org?.fields.name || '',
  }

  return (
    <Container>
      <Card big>
        <PreformattedText>
          {prepareIntroText(t, placeholders, role?.fields.branding_text, defaultTextTemplate)}
        </PreformattedText>
        <ButtonsContainer>
          <Link
            to={{
              pathname: `/candidates/${candidateid}/legal`,
              search: location.search,
            }}
            onClick={() => tracking.candidatePortal.start()}
          >
            <Button buttonType={'primary'} style={{ minWidth: px2rem(236) }}>
              {t('candidate-dashboard-intro.button.continue', 'Continue')}
            </Button>
          </Link>
        </ButtonsContainer>
      </Card>
    </Container>
  )
}

export default IntroScreen
