import { get, put } from './methods'
import { APIResponse, Entity } from './request'

export const RESOURCE = 'references'

export interface Fields {
  id: string
  candidate_id: string
  form_response_id: string
  email: string
  full_name: string
  phone_number: string
  company_name: string
  linkedin: string
  from: number
  to: number
  is_peer: boolean
  is_manager: boolean
  is_report: boolean
  is_student_peer: boolean
  is_client: boolean
  is_self: boolean
  is_any: boolean
  personal_message: string
  candidate_job_title: string
  reference_candidate_job_title: string
  is_job_title_confirmed: boolean
  requirement_id: string
  is_verified: boolean
  is_reviewed: boolean
  rejection_reason: RejectionReason | ''
  is_active: boolean
  email_delivered_at: number
  email_failed_at: number
  email_read_at: number
  sms_delivered_at: number
  sms_failed_at: number
  talent_pool_email: string
  sales_pool_email: string
  created_at: number
  updated_at: number
  client_signature: string
}

export const rejectionReasons = [
  'candidate_professional_profile_missing',
  'reference_professional_profile_missing',
  'both_professional_profile_missing',
  'candidate_shared_company_missing',
  'reference_shared_company_missing',
  'both_shared_company_missing',
  'candidate_shared_time_deviating',
  'reference_shared_time_deviating',
  'both_shared_time_deviating',
  'reference_refused_verification',
  'rejected_other',
] as const

export type RejectionReason = (typeof rejectionReasons)[number]

interface InviteLinkFields {
  link: string
}

export function updateEmail(id: string, email: string): Promise<APIResponse<Entity<Fields>>> {
  return put<Entity<Fields>>(`/references/${id}/email`, {
    email,
  })
}

export function load(id: string): Promise<APIResponse<Entity<Fields>>> {
  return get<Entity<Fields>>(`/references/${id}/`)
}
export function verify(id: string, verify: boolean, rejectionReason: string): Promise<APIResponse<Entity<Fields>>> {
  return put<Entity<Fields>>(`/references/${id}/verification_status`, {
    is_verified: verify,
    rejection_reason: rejectionReason,
  })
}

export function remove(id: string): Promise<APIResponse<Entity<Fields>>> {
  return put<Entity<Fields>>(`/references/${id}/delete`, {})
}
export interface CreateUpdatePayload {
  candidate_id: string
  email: string
  full_name: string
  phone_number: string
  company_name: string
  linkedin: string
  candidate_job_title: string
  from: number
  to: number
  personal_message?: string
  is_peer?: boolean
  is_manager?: boolean
  is_report?: boolean
  is_student_peer?: boolean
  is_client?: boolean
  is_any?: boolean
}

export interface CreatePayload extends CreateUpdatePayload {
  requirement_id?: string
}

export function create(reference: CreatePayload): Promise<APIResponse<Entity<Fields>>> {
  return put<Entity<Fields>>(`/references`, reference)
}

export interface UpdatePayload extends CreateUpdatePayload {}

export function update(id: string, reference: UpdatePayload): Promise<APIResponse<Entity<Fields>>> {
  return put<Entity<Fields>>(`/references/${id}`, reference)
}

export function inviteLink(id: string): Promise<APIResponse<Entity<InviteLinkFields>>> {
  return put<Entity<InviteLinkFields>>(`/references/${id}/invite_link`, {})
}

export function inviteLinkv2(id: string): Promise<APIResponse<Entity<InviteLinkFields>>> {
  return put<Entity<InviteLinkFields>>(`/references/${id}/invite_link/v2`, {})
}

export function empty(id: string): Entity<Fields> {
  return {
    id,
    resource: RESOURCE,
    fields: {
      id,
      candidate_id: '',
      candidate_job_title: '',
      reference_candidate_job_title: '',
      is_job_title_confirmed: false,
      form_response_id: '',
      email: '',
      full_name: '',
      phone_number: '',
      company_name: '',
      linkedin: '',
      from: 0,
      to: 0,
      personal_message: '',
      is_peer: false,
      is_manager: false,
      is_report: false,
      is_any: false,
      is_student_peer: false,
      is_client: false,
      is_self: false,
      requirement_id: '',
      is_verified: false,
      is_reviewed: false,
      rejection_reason: '',
      is_active: true,
      email_delivered_at: 0,
      email_failed_at: 0,
      email_read_at: 0,
      sms_delivered_at: 0,
      sms_failed_at: 0,
      talent_pool_email: '',
      sales_pool_email: '',
      created_at: 0,
      updated_at: 0,
      client_signature: '',
    },
  }
}
