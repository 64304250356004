import validate from 'all-good-emails'
import DropdownField, { Option } from 'components/DropdownField'
import { LetterIcon } from 'components/Icons'
import Modal from 'components/Modal'
import TextField from 'components/TextField'
import { Buttons, ContentAndButtons } from 'components/Wizard'
import { rem, space, style } from 'core'
import { text } from 'core/design-system/text'
import * as tracking from 'core/track'
import { isValid, parse } from 'date-fns'
import React, { useState } from 'react'
import * as selectors from 'selectors'
import DateInput from '../DateInput'

const Container = style()
  .size({
    maxWidth: rem(40),
    width: 'calc(100vw - 200px)',
    height: rem(20),
  })
  .element()

const Content = style()
  .spacing({ inner: [space.m, space.xl] })
  .element()

const DateInputLabel = text
  .bodyInteractive()
  .spacing({ bottom: [space.xs] })
  .element('p')

const UserInfoContainer = style()
  .grid({ columns: ['48px', 'auto'] })
  .spacing({ outerLeft: rem(1.1) })
  .element()

const ProfileImg = style().size({ width: '34px', height: '34px' }).border({ radius: '50%' }).element('img')

const LetterContainer = style().size({ width: '34px', height: '34px' }).border({ radius: '50%' }).element()

const UserTextContainer = style().element()
const UserNameText = text.bodyInteractive().element('p')
const UserEmailText = text.secondaryBodyInteractive().element('p')

const BoldText = text.bodyEmphasis().element('span')
const EmployeeEmailCopy = text
  .bodyText()
  .spacing({ bottom: [space.m] })
  .element('p')

function isDateValid(day?: number, month?: number, year?: number) {
  const parsedDate = parse(`${day}.${month}.${year}`, 'dd.MM.yyyy', new Date())

  if (day && month && year) return isValid(parsedDate)
}

interface Props {
  initDay?: number
  initMonth?: number
  initYear?: number
  initManagerUserId?: string
  initEmployeeEmail?: string
  isOpen: boolean
  setOpen: (open: boolean) => void
  onSubmitCallback: (managerUserId: string, employeeEmail: string, day?: number, month?: number, year?: number) => void
  userProfiles: {
    id: string
    name: string
    email: string
    profileImg: string
  }[]
}

const ConfirmHireStatusModal = ({
  initDay,
  initMonth,
  initYear,
  initManagerUserId,
  initEmployeeEmail,
  isOpen,
  setOpen,
  userProfiles,
  onSubmitCallback,
}: Props) => {
  const [day, setDay] = useState<number | undefined>(initDay)
  const [month, setMonth] = useState<number | undefined>(initMonth)
  const [year, setYear] = useState<number | undefined>(initYear)
  const [employeeEmail, setEmployeeEmail] = useState(initEmployeeEmail || '')
  const [dateInputStage, setDateInputStage] = useState(true)
  const [selectedUserId, setSelectedUserId] = useState(initManagerUserId || '')
  const [filter, setFilter] = useState('')

  return (
    <Modal
      title="Confirm hire status"
      open={isOpen}
      setOpen={() => {
        if (dateInputStage) {
          tracking.onboarding.exitStartDateManager()
        } else {
          tracking.onboarding.exitStartDateManager()
        }
        setOpen(false)
      }}
    >
      <Container>
        <ContentAndButtons>
          <Content>
            {dateInputStage ? (
              <>
                <DateInputLabel>Starting date</DateInputLabel>
                <DateInput day={day} setDay={setDay} month={month} setMonth={setMonth} year={year} setYear={setYear} />
                <DropdownField<string>
                  aria-label="Manager email"
                  label="Manager email"
                  allowInput={true}
                  placeholder="Start typing to search"
                  limit={5}
                  missingSuggestion="Can't find the person? Ask your admin!"
                  value={selectedUserId}
                  onChange={userId => setSelectedUserId(userId)}
                  onInput={f => setFilter(f)}
                  disableRelativePositionTracking={true}
                >
                  {userProfiles
                    .filter(p => selectors.match(p.name, filter))
                    .map(p => (
                      <Option key={p.id} value={p.id}>
                        <UserInfoContainer>
                          {p.profileImg ? (
                            <ProfileImg src={p.profileImg}></ProfileImg>
                          ) : (
                            <LetterContainer>
                              <LetterIcon name={p.name} />
                            </LetterContainer>
                          )}
                          <UserTextContainer>
                            <UserNameText>{p.name}</UserNameText>
                            <UserEmailText>{p.email}</UserEmailText>
                          </UserTextContainer>
                        </UserInfoContainer>
                      </Option>
                    ))}
                </DropdownField>
              </>
            ) : (
              <>
                <EmployeeEmailCopy>
                  Please provide the employee <BoldText>work email</BoldText>. HiPeople will send any future
                  communications to the provided email address. Press "skip" if not available yet.
                </EmployeeEmailCopy>
                <TextField
                  aria-label="Employee work email"
                  label="Employee work email"
                  type="email"
                  value={employeeEmail}
                  placeholder="example@gmail.com"
                  error={employeeEmail !== '' && !validate(employeeEmail)}
                  errorLabel={employeeEmail !== '' && !validate(employeeEmail) ? 'Please enter a valid email.' : ''}
                  onChange={e => {
                    setEmployeeEmail(e.target.value)
                  }}
                />
              </>
            )}
          </Content>
          <Buttons
            next={() => {
              if (dateInputStage) {
                setDateInputStage(false)
                tracking.onboarding.confirmStartDateManager()
                return
              }
              tracking.onboarding.confirmEmployeeEmail()
              onSubmitCallback(selectedUserId, employeeEmail, day, month, year)
              setOpen(false)
            }}
            disabled={
              dateInputStage ? !(isDateValid(day, month, year) && selectedUserId !== '') : !validate(employeeEmail)
            }
            back={() => {
              if (dateInputStage) {
                tracking.onboarding.skipStartDateManager()
                setOpen(false)
                return
              }
              tracking.onboarding.skipEmployeeEmail()
              onSubmitCallback(selectedUserId, employeeEmail, day, month, year)
              setOpen(false)
            }}
            label={dateInputStage ? 'Next' : 'Submit'}
            secondaryLabel="Skip"
            aria-label={dateInputStage ? 'Next' : 'Submit'}
          />
        </ContentAndButtons>
      </Container>
    </Modal>
  )
}

export default ConfirmHireStatusModal
