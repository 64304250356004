import React, { createContext, useContext, forwardRef } from 'react'
import clsx from 'clsx'
import styles from './Input.module.scss'

interface InputContextType {
  size?: 'sm' | 'md' | 'lg'
  disabled?: boolean
  error?: boolean
}

const InputContext = createContext<InputContextType>({})

interface InputRootProps extends React.HTMLAttributes<HTMLDivElement> {
  size?: 'sm' | 'md' | 'lg'
  disabled?: boolean
  error?: boolean
  children: React.ReactNode
}

const InputRoot = forwardRef<HTMLDivElement, InputRootProps>(
  ({ size = 'md', disabled = false, error = false, className, children, ...props }, ref) => {
    return (
      <InputContext.Provider value={{ size, disabled, error }}>
        <div ref={ref} className={clsx(styles.root, error && styles.error, className)} {...props}>
          {children}
        </div>
      </InputContext.Provider>
    )
  },
)

InputRoot.displayName = 'InputRoot'

interface InputFieldProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  fullWidth?: boolean
}

const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  ({ className, disabled: fieldDisabled, fullWidth = false, ...props }, ref) => {
    const { size, disabled: contextDisabled, error } = useContext(InputContext)
    const isDisabled = fieldDisabled ?? contextDisabled

    return (
      <input
        ref={ref}
        className={clsx(
          styles.field,
          size && styles[size],
          error && styles.error,
          isDisabled && styles.disabled,
          fullWidth && styles.fullWidth,
          className,
        )}
        disabled={isDisabled}
        {...props}
      />
    )
  },
)

InputField.displayName = 'InputField'

interface InputLabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
  children: React.ReactNode
}

const InputLabel = forwardRef<HTMLLabelElement, InputLabelProps>(({ className, children, ...props }, ref) => {
  const { size, error } = useContext(InputContext)

  return (
    <label ref={ref} className={clsx(styles.label, size && styles[size], error && styles.error, className)} {...props}>
      {children}
    </label>
  )
})

InputLabel.displayName = 'InputLabel'

interface InputHelperTextProps extends React.HTMLAttributes<HTMLParagraphElement> {
  children: React.ReactNode
  variant?: 'default' | 'info' | 'error'
}

const InputHelperText = forwardRef<HTMLParagraphElement, InputHelperTextProps>(
  ({ className, children, variant = 'default', ...props }, ref) => {
    const { error } = useContext(InputContext)
    const finalVariant = error ? 'error' : variant

    return (
      <p
        ref={ref}
        className={clsx(
          styles.helperText,
          styles[`variant${finalVariant.charAt(0).toUpperCase()}${finalVariant.slice(1)}`],
          className,
        )}
        {...props}
      >
        {children}
      </p>
    )
  },
)

InputHelperText.displayName = 'InputHelperText'

/**
 * A compound component that creates a flexible input field with optional label and helper text.
 * The input component shares context for size, disabled state, and error state among its parts.
 *
 * @example
 * ```tsx
 * <Input.Root size="md" error={!!error}>
 *   <Input.Label>Email address</Input.Label>
 *   <Input.Field
 *     type="email"
 *     placeholder="Enter your email"
 *     value={value}
 *     onChange={handleChange}
 *   />
 *   <Input.HelperText variant="error">
 *     {error ? error.message : ''}
 *   </Input.HelperText>
 * </Input.Root>
 * ```
 */
const Input = {
  Root: InputRoot,
  Field: InputField,
  Label: InputLabel,
  HelperText: InputHelperText,
}

export { Input }
