import React from 'react'
import { colors, scale, style } from '../../core'

const Container = style()
  .absolute({ left: '0', top: '0' })
  .color({ fg: colors.lightGray })
  .sans({ size: scale.s })
  .center()
  .stretch()
  .element()

const Component = () => <Container>Loading</Container>
export default Component
Component.displayName = 'Loading'
