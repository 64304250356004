import mixpanel from 'mixpanel-browser'

if (import.meta.env.VITE_MIXPANEL_TOKEN) {
  mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN ?? '', {
    ip: false,
    save_referrer: false,
  })
}

export default mixpanel
