import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { webhooks } from '../../../api'
import DropdownField, { Option } from '../../../components/DropdownField'
import Form from '../../../components/Form'
import Grid, { Column } from '../../../components/Grid'
import TextField from '../../../components/TextField'
import { rem, size } from '../../../core'
import { RootState } from '../../../store'
import {
  setAction,
  setCustomPayload,
  setMethod,
  setPassword,
  setResource,
  setTitle,
  setUrl,
  setUsername,
} from '../../../store/webhooks'

interface Props {
  createMode?: boolean
  updateMode?: boolean
  webhook?: webhooks.Fields | webhooks.CreateWebhookPayload
}

const Component = (props: Props) => {
  const dispatch = useDispatch()

  const changes = useSelector((state: RootState) => state.webhooks.changes)
  const webhook = { ...props.webhook, ...changes }

  return (
    <Form>
      <TextField
        type="text"
        label="Title"
        value={webhook.title || ''}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => dispatch(setTitle(e.target.value))}
      />
      <TextField
        type="url"
        label="URL"
        value={webhook.target_url || ''}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => dispatch(setUrl(e.target.value))}
      />
      <Grid fill auto columnSpacing={size.s}>
        <Column>
          <DropdownField
            label="Method"
            value={webhook?.target_method || webhooks.methods[0]}
            onChange={(value: string) => dispatch(setMethod(value))}
          >
            {dropDownOptions(webhooks.methods || [])}
          </DropdownField>
        </Column>
        <Column>
          <DropdownField
            label="Action"
            value={webhook.action || webhooks.actions[0]}
            disabled={props.updateMode}
            onChange={(value: string) => dispatch(setAction(value))}
          >
            {dropDownOptions(webhooks.actions || [])}
          </DropdownField>
        </Column>
        <Column>
          <DropdownField
            label="Resource"
            value={webhook.resource || webhooks.resources[0]}
            disabled={props.updateMode}
            onChange={(value: string) => dispatch(setResource(value))}
          >
            {dropDownOptions(webhooks.resources || [])}
          </DropdownField>
        </Column>
      </Grid>
      {props.updateMode ? (
        <>
          <Grid fill auto columnSpacing={size.s}>
            <Column>
              <TextField
                type="text"
                label="Username"
                value={webhook.username || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => dispatch(setUsername(e.target.value))}
              />
            </Column>
            <Column>
              <TextField
                type="password"
                label="Password"
                value={webhook.password || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => dispatch(setPassword(e.target.value))}
              />
            </Column>
          </Grid>
          <TextField
            type="text"
            label="Payload"
            height={rem(20)}
            value={webhook.custom_payload || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => dispatch(setCustomPayload(e.target.value))}
            multiline
          />
        </>
      ) : (
        <></>
      )}
    </Form>
  )
}

export default Component
Component.displayName = 'WebhookForm'

function getResourceLabel(resourceName: string): string {
  switch (resourceName) {
    case 'candidates':
      return 'Candidates'
    case 'references':
      return 'References'
    case 'open_job_roles':
      return 'Jobs'
    default:
      return resourceName
  }
}

function dropDownOptions(options: string[]) {
  const renderedOptions = options.map(option => {
    if (!option) return <></>

    return (
      <Option key={option} value={option}>
        {getResourceLabel(option)}
      </Option>
    )
  })
  return renderedOptions
}
