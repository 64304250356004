import React from 'react'
import { colors, height, px, rgba, scale, size, space, style, weight } from '../../core'

type WarningType = 'info' | 'error'

interface Props {
  children: React.ReactNode
  type?: WarningType
  action?: () => void
  actionEnabled?: boolean
  actionText?: string
}

const Message = style().sans({ line: 'inherit' }).element()

const Warning = style()
  .sans({ size: scale.s, height: height.s })
  .border({ radius: size.xxs })
  .spacing({ inner: space.m })
  .color({ bg: colors.darkSilver, fg: colors.white })
  .cond(({ type }: { type: WarningType }) => type === 'info', style().color({ bg: colors.sand, fg: colors.darkGray }))
  .cond(({ type }: { type: WarningType }) => type === 'error', style().color({ bg: colors.errorBgColor }))
  .element()

const Button = style()
  .color({ fg: 'inherit', bg: rgba(0, 0, 0, 0.12) })
  .inlineBlock()
  .noborders()
  .nooutline()
  .round(px(5))
  .sans({ weight: weight.normal })
  .spacing({ outerTop: space.xs, inner: [space.xs, space.s] })
  .pointer()
  .element('button')

const Component = (props: Props) => {
  return (
    <Warning type={props.type}>
      <Message>{props.children}</Message>
      {props.actionEnabled ? <Button onClick={props.action}>{props.actionText}</Button> : <></>}
    </Warning>
  )
}

export default Component
Component.displayName = 'WarningBanner'
