import { RejectionReason } from 'api/reference'
import ForcedTruth, { ForcedTruthAnswerType } from 'App/Candidate//ForcedTruthV2'
import TextAnswer, { TextAnswerType } from 'App/Candidate//TextAnswer'
import Chips, { ChipAnswerType } from 'App/Candidate/ChipsV2'
import Block from 'components/Block'
import { px2rem, style } from 'core'
import { interpolateVariables } from 'core/text'
import React, { useCallback, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { FeedbackStyleType } from 'selectors/halo-score'
import { Relationship } from 'selectors/references'

export type ReferenceDetails = {
  id: string
  relationship: Relationship
  professionalNetwork?: {
    name: string
    url: string
  }
  name: string
  email: string
  companyName: string
  from: number
  to: number
  feedbackStyle?: FeedbackStyleType
  rejectionReason?: RejectionReason
  isReviewed: boolean
  isVerified: boolean
  completed: boolean
}

type ReferenceId = string

export type AnswersType =
  | {
      type: 'chips' | 'optionset'
      answers: Record<ReferenceId, ChipAnswerType>
    }
  | {
      type: 'text-input'
      answers: Record<ReferenceId, TextAnswerType>
    }
  | {
      type: 'forced-truth'
      answers: Record<ReferenceId, ForcedTruthAnswerType>
    }
  | {
      type: 'likert-scale'
      answers: Record<ReferenceId, ForcedTruthAnswerType>
    }

interface QuestionnaireAnswerProps {
  candidateName: string
  references: ReferenceDetails[]
  answers: AnswersType
}

const QuestionnaireAnswer: React.FC<QuestionnaireAnswerProps> = ({ candidateName, references, answers }) => {
  switch (answers.type) {
    case 'chips':
    case 'optionset': {
      return <Chips candidateName={candidateName} references={references} answers={answers.answers} />
    }
    case 'text-input': {
      return <TextAnswer candidateName={candidateName} references={references} answers={answers.answers} />
    }
    case 'forced-truth': {
      return <ForcedTruth candidateName={candidateName} references={references} answers={answers.answers} />
    }
  }
  return null
}

const Emoji = style()
  .spacing({ outerRight: px2rem(8) })
  .element('span')

const QuestionnaireAnswerBlockStyle = style().element()

interface QuestionnaireAnswerBlockProps {
  onBlockVisible: (isVisible: boolean) => any
  id: string
  title: string
  emoji?: string
  candidateName: string
  references: ReferenceDetails[]
  answers: AnswersType
}

const QuestionnaireAnswerBlock: React.FC<QuestionnaireAnswerBlockProps> = ({
  onBlockVisible,
  id,
  title,
  emoji,
  candidateName,
  references,
  answers,
}) => {
  const { ref, inView } = useInView({ threshold: 0.1 })
  useEffect(() => {
    onBlockVisible(inView)
  }, [inView])

  const getCopy = useCallback(
    (string: string) =>
      interpolateVariables(string, {
        'CANDIDATE.FIRST_NAME': candidateName,
      }),
    [candidateName],
  )

  return (
    <QuestionnaireAnswerBlockStyle ref={ref}>
      <Block
        id={id}
        title={
          <>
            {/*  FYI: emoji typecheck is a fix added as part of the following PR:
            https://github.com/hipeople/dashboard/pull/1284 */}
            <Emoji>{typeof emoji === 'string' ? emoji : '🛠'}</Emoji>
            <span>{getCopy(title)}</span>
          </>
        }
        noSpacing
      >
        <QuestionnaireAnswer candidateName={candidateName} references={references} answers={answers} />
      </Block>
    </QuestionnaireAnswerBlockStyle>
  )
}

export default QuestionnaireAnswerBlock
