import action from './action'
import events from './events'
import track from './track'

export const usedLinkFromSource = action((source: string, path: string) => {
  track(events.USED_LINK_FROM_SOURCE, {
    source,
    path,
  })
})

export const referLinkClicked = action(() => {
  track(events.REFER_LINK_CLICKED, {})
})

export const bookDemoLinkClicked = action(() => {
  track(events.BOOK_DEMO_LINK_CLICKED, {})
})

export const upgradeLinkClicked = action(() => {
  track(events.UPGRADE_LINK_CLICKED, {})
})
