import React from 'react'
import style from '../../core/style'

const Container = style().element()

interface Props {
  selected?: boolean
  children: React.ReactNode
}

const Component = (props: Props) => {
  if (!props.selected) return null
  return <Container>{props.children}</Container>
}

export default Component
Component.displayName = 'TabPanel'
