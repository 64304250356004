import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit'
import { orgSettings } from '../../api'
import { AppThunk } from '../../core/store'
import { add as notify } from '../notifications'
import { handleResponse } from '../utils'

const name = 'org-settings'

interface Changes {
  dataRetentionDays?: number
  deletedDataRetentionDays?: number
}

interface State {
  changes: Changes
  loading: boolean
}

const initialState: State = {
  changes: {},
  loading: false,
}

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setDataRetentionDays(state, action: PayloadAction<number>) {
      state.changes.dataRetentionDays = action.payload
    },
    setDeletedDataRetentionDays(state, action: PayloadAction<number>) {
      state.changes.deletedDataRetentionDays = action.payload
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    reset(state) {
      state.changes = {}
    },
  },
})

export const { setDataRetentionDays, setDeletedDataRetentionDays, setLoading } = slice.actions

export default slice.reducer

export const save =
  (id: string, changes: Changes): AppThunk =>
  async (dispatch: Dispatch) => {
    dispatch(setLoading(true))

    const changesPayload: orgSettings.UpdateSettingsPayload = {
      updated: [],
      updates: {},
    }

    if (changes.dataRetentionDays !== undefined) {
      changesPayload.updates.data_retention_days = changes.dataRetentionDays
      changesPayload.updated.push('data_retention_days')
    }

    if (changes.deletedDataRetentionDays !== undefined) {
      changesPayload.updates.deleted_data_retention_days = changes.deletedDataRetentionDays
      changesPayload.updated.push('deleted_data_retention_days')
    }

    if (
      changesPayload.updated.length > 0 &&
      !handleResponse<orgSettings.Fields>(dispatch, await orgSettings.updateSettings(id, changesPayload))
    )
      return dispatch(setLoading(false))

    dispatch(setLoading(false))

    dispatch(
      notify({
        success: 'The GDPR settings have been updated successfully!',
      }),
    )
  }
