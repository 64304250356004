import { PageHeader, TopBar } from '@common/components'
import { useFeatureFlag } from 'providers/features'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Block from '../../components/Block'
import DashboardLayout, { Content as DashboardContent } from '../../components/DashboardLayout'
import { breakpoints, size, space, style } from '../../core'
import * as tracking from '../../core/track'
import * as selectors from '../../selectors'
import { RootState } from '../../store'
import { load } from '../../store/reports'
import Content from './Content'

const Grid = style()
  .grid({ columns: 'repeat(1, minmax(0, 1fr))' })
  .screen(breakpoints.l, style().grid({ columns: 'repeat(2, minmax(0, 1fr))' }))
  .screen(breakpoints.xl, style().grid({ columns: 'repeat(4, minmax(0, 1fr))' }))
  .spacing({ column: space.s, row: space.s, outerTop: space.l })
  .select('> section > *', style().size({ height: size.fill, width: size.fill }))
  .element()

const Component = () => {
  return (
    <DashboardLayout>
      <TopBar tracking={tracking} />
      <DashboardContent>
        <PageHeader.Root>
          <PageHeader.Title>Reports</PageHeader.Title>
        </PageHeader.Root>
        <Reports />
      </DashboardContent>
    </DashboardLayout>
  )
}

export default Component
Component.displayName = 'Reports'

const ContentContainer = style().spacing({ inner: space.xxs }).element()

const SorryMessage = style().sans().element()

function Reports() {
  const dispatch = useDispatch()

  const { isEnabled: isSelfReferenceEnabled } = useFeatureFlag('self-reference')

  // TODO: Possible Bug Here
  const updatedAt = useSelector((state: RootState) => state.reports.updatedAt)
  const visuals = useSelector((state: RootState) => selectors.reports.all(state, !!isSelfReferenceEnabled))
  const canManageOrganization = useSelector((state: RootState) =>
    selectors.users.canManageOrganization(state, selectors.users.id(state)),
  )

  useEffect(() => {
    if (updatedAt > 0) return

    dispatch(load())
  }, [dispatch, updatedAt])

  if (!canManageOrganization) {
    return <SorryMessage>Sorry, you do not have permission to view reports</SorryMessage>
  }

  return (
    <Grid>
      {visuals.map((v, i) => (
        <Block key={`block-${i}`} title={v.title}>
          <ContentContainer>
            <Content content={v} />
          </ContentContainer>
        </Block>
      ))}
    </Grid>
  )
}
