import { px2rem, style } from 'core'
import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { useSelector } from 'react-redux'
import * as content from 'store/new-role/content'
import * as api from '../../api'
import Block from '../../components/Block'
import * as selectors from '../../selectors'
import { RootState } from '../../store'
import { SLIDER_WITH_DESC } from '../../store/new-role/response-types'
import { Visible } from './ByQuestion'
import Chips from './Chips'
import ForcedTruth from './ForcedTruth'
import HighlightedOutstandingOption from './HighlightedOutStandingOption'
import ShowMore from './ShowMore'
import SimpleAnswer from './SimpleAnswer'
import SliderWithDesc from './SliderWithDesc'

interface Props {
  candidateId: string
  referenceId?: string
  item: api.assessmentversions.Item
  visible?: Visible
  updateVisible?: Dispatch<SetStateAction<Visible>>
}

const Emoji = style()
  .spacing({ outerRight: px2rem(8) })
  .element('span')

const Component = (props: Props) => {
  const [candidateUrl, reference]: [
    ReturnType<typeof selectors.candidates.url> | undefined,
    ReturnType<typeof selectors.references.getById> | null,
  ] = useSelector((state: RootState) => [
    selectors.candidates.url(state, props.candidateId),
    props.referenceId ? selectors.references.getById(state, props.referenceId) : null,
  ])

  const emoji = (props.item.content_id && content.CONTENT_EMOJI_LOOKUP[props.item.content_id]) || '🛠'
  const title = props.item.dashboard_view.title

  const breadcrumbs = !reference
    ? undefined
    : [
        {
          url: `${candidateUrl}#${props.item.content_id}`,
          label: props.item.dashboard_view.title,
        },
        {
          url: ``,
          label: `${selectors.possessiveFirstName(selectors.references.firstNameOf(reference))} answer`,
        },
      ]

  const { ref, inView } = useInView({ threshold: 0.1 })

  useEffect(() => {
    if (!props.updateVisible || !props.visible) return

    props.updateVisible({
      ...props.visible,
      ...{ [props.item.content_id]: inView },
    })
  }, [inView])

  return (
    <span ref={ref}>
      <Block
        id={`${props.item.content_id}`}
        title={
          emoji ? (
            <>
              <Emoji>{emoji}</Emoji>
              <span>{title}</span>
            </>
          ) : (
            title
          )
        }
        breadcrumbs={breadcrumbs}
      >
        {renderAnswer(props)}
        {props.referenceId ? (
          <ShowMore candidateId={props.candidateId} referenceId={props.referenceId} itemId={props.item.content_id} />
        ) : null}
      </Block>
    </span>
  )
}

export default Component
Component.displayName = 'Item'

function renderAnswer(props: Props) {
  if (props.item.dashboard_view.display_as_tags) {
    return <Chips candidateId={props.candidateId} referenceId={props.referenceId} item={props.item} />
  }

  if (props.item.dashboard_view.display_as_pairwise_comparison) {
    return <ForcedTruth candidateId={props.candidateId} referenceId={props.referenceId} item={props.item} />
  }

  const responseType = props.item.response_options.options[0].type
  if (responseType === SLIDER_WITH_DESC) {
    return <SliderWithDesc candidateId={props.candidateId} referenceId={props.referenceId} item={props.item} />
  }

  if (props.item.dashboard_view.highlight_recent_outstanding_option) {
    return (
      <HighlightedOutstandingOption candidateId={props.candidateId} referenceId={props.referenceId} item={props.item} />
    )
  }

  return <SimpleAnswer candidateId={props.candidateId} referenceId={props.referenceId} item={props.item} />
}
