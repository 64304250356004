import * as React from 'react'
import classes from './DebugWindow.module.scss'
import { Button } from '../Button/Button'
import { Text } from '../Text/Text'

export const DebugWindow = () => {
  const currentHead = import.meta.env.HEAD || 'unknown'

  const showDebugWindow = window.location.search.includes('hi_debug=true')
  const isProductionSite = import.meta.env.BASE_URL === 'https://app.hipeople.io'

  const handleSetDeploymentCookie = (branch: 'main' | 'staging') => {
    // https://docs.netlify.com/site-deploys/split-testing/
    // Netlify determines the served branch based on the cookie, in a value between 0 and 1
    // 0 = main, 1 = staging
    const cookieValue = branch === 'main' ? '0' : '1'

    document.cookie = `nf_ab=${cookieValue}; path=/`
    window.location.reload()
  }

  if (!showDebugWindow) {
    return null
  }

  return (
    <div className={classes.debugWindow}>
      <div>
        <strong>Info</strong>
      </div>
      <div
        style={{
          display: 'grid',
        }}
      >
        <Text variant="label">Release:</Text>
        <Text variant="body-text">{currentHead}</Text>
      </div>
      {/* the switcher should only be available on production sites, since that's where blue green deployments are used */}
      {isProductionSite && (
        <div>
          <strong>Switch release</strong>
          <div className={classes.buttonsWrapper}>
            <Button
              variant={currentHead === 'main' ? 'accent' : 'secondary'}
              onClick={() => handleSetDeploymentCookie('main')}
            >
              Prod
            </Button>
            <Button
              variant={currentHead !== 'main' ? 'accent' : 'secondary'}
              onClick={() => handleSetDeploymentCookie('staging')}
            >
              Staging
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
