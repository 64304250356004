import { Icon } from 'components/Icons'
import { designSystemColors as designColors } from 'core/design-system/colors'
import React from 'react'
import { colors, px, rem, style } from '../../core'

const Input = style()
  .inlineBlock()
  .size({ height: rem(1), minWidth: rem(1) })
  .color({ bg: designColors.backgroundNeutralTertiary })
  .text({ size: rem(0.65), align: 'center', height: rem(1.125) })
  .cond(
    ({ checked }: { checked: boolean }) => checked,
    style().color({
      bg: colors.purpleDark,
      fg: designColors.backgroundNeutralPrimary,
    }),
  )
  .round(px(2))
  .pointer()
  .element('span')

interface Props {
  on: boolean
  onChange: (on: boolean) => void
}

const Component = (props: Props) => (
  <Input checked={props.on} onClick={() => props.onChange(!props.on)}>
    {props.on && <Icon name="check" />}
  </Input>
)

export default Component
Component.displayName = 'Checkbox'
