import { modules } from 'api'
import { AssessmentAnswerQuestion } from 'selectors/self-assessment'

export function getAssessmentTime(
  module: modules.Fields,
  questionAnswers: AssessmentAnswerQuestion[],
  timeExceeded?: boolean,
): { timeTakenMillis: number; timeAllowedMillis: number } {
  const timeAllowed = (module.properties?.time_allowed_secs ?? 0) * 1000
  const timeTaken = timeExceeded
    ? timeAllowed
    : questionAnswers.reduce((sum, question) => {
        return sum + question.time_taken_millis
      }, 0)

  return {
    timeTakenMillis: timeAllowed ? Math.min(timeAllowed, timeTaken) : timeTaken,
    timeAllowedMillis: timeAllowed,
  }
}
