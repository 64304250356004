import EditCandidateModal, { EditableCandidate } from 'App/Settings/EditCandidateModal'
import { Icon } from 'components/Icons'
import Popup, { Button } from 'components/Popup'
import { useCanProvideSupport, useIsSuperUser, useUser } from 'providers/users'
import React, { useState, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useDebounce } from 'react-use'
import { Circle, Label } from '../../../components/CandidateStatus'
import ContentGrid, { Caption, Column, Row } from '../../../components/ContentGrid'
import DashboardLayout, { Content, Header } from '../../../components/DashboardLayout'
import TextField from '../../../components/TextField'
import { colors, rem, scale, size, space, style, weight } from '../../../core'
import * as selectors from '../../../selectors'
import { RootState } from '../../../store'
import { load, loadByOrg } from '../../../store/candidate-management'
import { logout } from '../../../store/sessions'

const UserRowStyle = Row([rem(2), rem(10), size.auto, rem(8), rem(8), rem(10), rem(2)])

const MenuButton = style()
  .pointer()
  .noborders()
  .border({ radius: '50%' })
  .color({ bg: colors.transparent })
  .spacing({ inner: size.xs })
  .element('button')

const PopupContainer = style()
  .size({ width: size.xxxl })
  .absolute({ top: rem(4), right: size.none })
  .element()

export const SearchRow = style()
  .grid({ columns: [size.auto, rem(10)], align: 'center' })
  .spacing({
    inner: space.none,
  })
  .element()

export const Title = style()
  .color({ bg: colors.transparent })
  .sans({ size: scale.m, weight: weight.normal })
  .spacing({
    outerTop: space.xs,
  })
  .element()

export const Total = style()
  .sans({
    size: scale.s,
    color: colors.gray,
    align: 'right',
  })
  .element()

const SCROLLABLE_TARGET = 'manage-users-scroll-target'

const candidateRowSelector = (state: RootState) =>
  state.candidateManagement.candidates.map(id => {
    const role = selectors.roles.findByCandidateId(state, id)
    const orgID = role ? role.fields.organization_id : ''
    return {
      candidate: selectors.candidates.findById(state, id),
      profile: selectors.candidateProfiles.findByCandidateId(state, id),
      role: role,
      total: selectors.references.totalReferencesExceptSelf(state, id),
      responded: selectors.references.totalRespondedReferencesExceptSelf(state, id),
      organization: selectors.orgs.getById(state, orgID),
    }
  })
// @ts-ignore ValuesOf is not a type
type CandidateRow = ValuesOf<ReturnType<typeof candidateRowSelector>>

type UserRowProps = {
  row: CandidateRow
  setSelectedCandidate: (selectedCandidate: EditableCandidate | undefined) => void
  isMenuVisible: boolean
}

const UserRow: React.FC<UserRowProps> = ({ row, setSelectedCandidate, isMenuVisible }) => {
  const [isMenuPopupOpen, setIsMenuPopupOpen] = useState(false)

  return (
    <UserRowStyle>
      <Column center>
        <Circle candidate={row.candidate} />
      </Column>
      <Column centerVert>{row.organization?.fields.name || 'Not found'}</Column>
      <Column centerVert highlight>
        <Link to={`/roles/${row.candidate?.fields.open_job_role_id}/candidates/${row.candidate?.fields.id}`}>
          {row.profile?.fields.full_name}
          <Caption>{row.role?.fields.name}</Caption>
        </Link>
      </Column>
      <Column center>{row.total}</Column>
      <Column center>{row.responded}</Column>
      <Column center>
        <Label candidate={row.candidate} />
      </Column>
      {isMenuVisible ? (
        <Column center>
          <MenuButton onClick={() => setIsMenuPopupOpen(isOpen => !isOpen)}>
            <Icon name="ellipsis-v" />
          </MenuButton>
          <PopupContainer>
            <Popup open={isMenuPopupOpen} setOpen={setIsMenuPopupOpen}>
              <Button
                icon="edit"
                onClick={() =>
                  setSelectedCandidate({
                    id: row.profile?.fields.id || '',
                    fullName: row.profile?.fields.full_name || '',
                    email: row.profile?.fields.email || '',
                  })
                }
              >
                {'Update Candidate'}
              </Button>
            </Popup>
          </PopupContainer>
        </Column>
      ) : (
        <Column center />
      )}
    </UserRowStyle>
  )
}

const Component = () => {
  const dispatch = useDispatch()

  const totalCandidates = useSelector((state: RootState) => state.candidateManagement.totalCandidates)
  const hasMore = useSelector((state: RootState) => state.candidateManagement.hasMore)
  const candidates = useSelector(candidateRowSelector)
  const loading = useSelector((state: RootState) => state.candidateManagement.loading)

  const canProvideSupport = useCanProvideSupport()
  const isSuperUser = useIsSuperUser()
  const user = useUser()

  const [query, setQuery] = useState('')

  const loadUsers = (skip?: number) =>
    canProvideSupport ? load(query, skip) : loadByOrg(query, user.fields.organization_id, skip)

  const [selectedCandidate, setSelectedCandidate] = useState<EditableCandidate | undefined>(undefined)

  const [loadingMore, setLoadingMore] = useState(false)

  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: '200px',
  })

  useEffect(() => {
    if (inView && hasMore && !loadingMore) {
      loadMoreCandidates()
    }
  }, [inView, hasMore, loadingMore])

  useDebounce(() => dispatch(loadUsers()), 400, [query])

  return (
    <>
      <DashboardLayout>
        <Header breadcrumbs={[{ label: 'Settings' }, { url: `/settings/candidates`, label: 'Manage candidates' }]}>
          <Button icon="sign-out" onClick={() => dispatch(logout())}>
            Sign out
          </Button>
        </Header>
        <Content id={SCROLLABLE_TARGET}>
          <Title>Manage Candidates</Title>
          <SearchRow>
            <TextField
              value={query}
              placeholder="Search by ID, email, or name"
              onChange={e => setQuery(e.target.value)}
            />
            {!loading ? <Total> {totalCandidates} candidates</Total> : null}
          </SearchRow>
          <ContentGrid>
            <UserRowStyle>
              <Column head></Column>
              <Column head>Organization</Column>
              <Column head>Name</Column>
              <Column head center>
                Requested
              </Column>
              <Column head center>
                Responded
              </Column>
              <Column head center>
                Status
              </Column>
              <Column head></Column>
            </UserRowStyle>

            {candidates.map((row, i) => (
              <UserRow
                key={`user-${i}`}
                row={row}
                setSelectedCandidate={setSelectedCandidate}
                isMenuVisible={isSuperUser}
              />
            ))}
            {hasMore && <div ref={ref} />}
          </ContentGrid>
        </Content>
      </DashboardLayout>
      {selectedCandidate && (
        <EditCandidateModal
          open={true}
          setOpen={(open: boolean) => !open && setSelectedCandidate(undefined)}
          candidate={selectedCandidate}
        />
      )}
    </>
  )

  async function loadMoreCandidates() {
    setLoadingMore(true)
    await dispatch(loadUsers(candidates.length))
    setLoadingMore(false)
  }
}

export default Component
Component.displayName = 'ManageCandidates'
