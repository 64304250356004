import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { request, sessions, users } from '../../api'
import { AppThunk } from '../../core/store'
import token from '../../core/token'
import { forget } from '../login'
import { addEntities } from '../resources'

interface State {
  isLoggedIn: boolean
  isRestoring: boolean
  jwt: string
  intercomHMAC: string
  userId: string
  errors: request.Error[] | undefined
}

const name = 'sessions'
const initialState: State = {
  isRestoring: false,
  isLoggedIn: false,
  jwt: token.read(),
  intercomHMAC: '',
  userId: '',
  errors: undefined,
}

const slice = createSlice({
  name,
  initialState,
  reducers: {
    newSession(state, action: PayloadAction<request.Entity<sessions.Fields>>) {
      const relations = action.payload.relationships
      const fields = action.payload.fields as sessions.Fields
      state.jwt = fields.token
      state.intercomHMAC = fields.intercom_hmac
      state.isLoggedIn = true

      token.write(state.jwt)

      if (relations) {
        const user = relations.find(r => r.resource === users.RESOURCE)
        state.userId = (user && user.id) || ''
      }
    },

    updateSession(state, action: PayloadAction<request.Entity<sessions.Fields>>) {
      const relations = action.payload.relationships
      const fields = action.payload.fields as sessions.Fields
      state.jwt = fields.token
      state.intercomHMAC = fields.intercom_hmac
      state.isLoggedIn = true

      if (relations) {
        const user = relations.find(r => r.resource === users.RESOURCE)
        state.userId = (user && user.id) || ''
      }
    },

    setRestoring(state, action: PayloadAction<boolean>) {
      state.isRestoring = action.payload
    },

    reset(state: State) {
      state.isLoggedIn = false
      state.isRestoring = false
      state.jwt = ''
      token.clear()
    },

    setErrors(state: State, action: PayloadAction<request.Errors>) {
      state.errors = action.payload
    },
  },
})

export const { newSession, updateSession, setRestoring, reset, setErrors } = slice.actions

export default slice.reducer

export const restore = (): AppThunk => async dispatch => {
  dispatch(setRestoring(true))

  const [response, errors] = await sessions.restore()

  if (errors) {
    dispatch(setErrors(errors))
    dispatch(reset())
    return
  }

  if (response) {
    dispatch(updateSession(response.result))
    dispatch(addEntities(response))
  }

  dispatch(setRestoring(false))
}

export const logout = (): AppThunk => async dispatch => {
  dispatch(reset())
  dispatch(forget())
}
