import React from 'react'
import { rem, size, style } from '../../core'
import Buttons from './Buttons'
import Content from './Content'
import ContentAndButtons from './ContentAndButtons'
import Form from './Form'
import Steps from './Steps'
import TabStep from './TabStep'
export { Steps }
export { TabStep }
export { Content }
export { ContentAndButtons }
export { Form }
export { Buttons }

const Container = style()
  .size({ height: size.stretch })
  .grid({ columns: [rem(13), size.auto] })
  .element()

interface Props {
  children: React.ReactNode
}

const Component = (props: Props) => {
  return <Container>{props.children}</Container>
}

export default Component
Component.displayName = 'Wizard'
