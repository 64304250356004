import React from 'react'

interface Props {
  onFileSelected: (file: File) => void
  children?: React.ReactNode
}

const Component: React.FC<Props> = props => {
  return (
    <label className="custom-file-upload">
      <input
        style={{ display: 'none' }}
        type="file"
        name="file"
        onChange={e => {
          const files = e.target?.files
          if (files && files.length > 0) {
            props.onFileSelected(files[0])
          }
        }}
      />
      {props.children}
    </label>
  )
}

export default Component
Component.displayName = 'FileUpload'
