import React, { Fragment } from 'react'
import { Icon } from 'components/Icons'
import * as tracking from 'core/track'
import { TemplateProps } from '..'
import { Product } from 'api/templates'
import classes from './ModuleList.module.scss'
import { Button } from '@common/components'

interface ModuleListProps {
  isReferenceCheckActivated: boolean
  isSelfAssessmentActivated: boolean
  isOnboardingActivated: boolean
  referenceCheckTemplate?: TemplateProps
  selfAssessmentTemplate?: TemplateProps
  onboardingTemplate?: TemplateProps
  candidateExperienceTemplate?: TemplateProps
  onClickPreviewTemplate: (templateId: string) => void
  onClickEditTemplate: (templateId: string, type: Product) => void
  onClickSelectTemplate: (templateType: Product) => void
  onClickSelectExistingTemplate: (templateId: string) => void
  onClickExistingReferenceCheckTemplate: (templateId: string) => void
  hasCandidates: boolean
}

export const ModuleList: React.FC<ModuleListProps> = ({
  isReferenceCheckActivated,
  isSelfAssessmentActivated,
  isOnboardingActivated,
  referenceCheckTemplate,
  selfAssessmentTemplate,
  onClickEditTemplate,
  onClickSelectTemplate,
  onClickSelectExistingTemplate,
  onClickExistingReferenceCheckTemplate,
  hasCandidates,
}) => {
  const displayOnboardingCard = false // why is this here?

  return (
    <div className={classes.container}>
      {isSelfAssessmentActivated && (
        <Fragment>
          {selfAssessmentTemplate ? (
            <Button
              variant="tertiary"
              className={classes.module}
              disabled={!selfAssessmentTemplate.canBeEdited}
              onClick={() => {
                hasCandidates
                  ? onClickEditTemplate(selfAssessmentTemplate.id, 'self-assessment')
                  : onClickSelectExistingTemplate(selfAssessmentTemplate.id)
                tracking.roles.editModule({ module: 'self-assessment' })
              }}
            >
              <div className={classes.moduleHeader}>
                <h4 className={classes.moduleTitle}>
                  <span>👤</span>
                  Assessment
                </h4>
              </div>

              <Icon name="edit" ariaLabel="Edit Template" />
            </Button>
          ) : (
            <Button
              variant="tertiary"
              className={classes.selectTemplateButton}
              data-testid={'select-assessment-template'}
              onClick={() => {
                onClickSelectTemplate('self-assessment')
                tracking.roles.addTemplateToModule({
                  module: 'self-assessment',
                })
              }}
            >
              <span>👤</span>
              <span>Select Assessment template</span>
              <Icon name="plus" />
            </Button>
          )}
        </Fragment>
      )}
      {isReferenceCheckActivated && (
        <Fragment>
          {referenceCheckTemplate ? (
            <>
              <Button
                variant="tertiary"
                className={classes.module}
                onClick={() => {
                  if (referenceCheckTemplate.canBePreviewed) {
                    hasCandidates
                      ? onClickEditTemplate(referenceCheckTemplate.id, 'reference-check')
                      : onClickExistingReferenceCheckTemplate(referenceCheckTemplate.id)
                  } else {
                    // if template can't be previewed, assume legacy role
                    onClickSelectTemplate('reference-check')
                  }

                  tracking.roles.editModule({ module: 'reference-check' })
                }}
              >
                <div className={classes.moduleHeader}>
                  <h4 className={classes.moduleTitle}>
                    <span>👥</span>
                    Reference Check
                  </h4>
                </div>

                <Icon name="edit" ariaLabel="Edit Template" />
              </Button>
            </>
          ) : (
            <Button
              variant="tertiary"
              className={classes.selectTemplateButton}
              data-testid="select-reference-check-template"
              onClick={() => {
                onClickSelectTemplate('reference-check')
                tracking.roles.addTemplateToModule({
                  module: 'reference-check',
                })
              }}
            >
              <span>👥</span>
              <span>Select Reference Check template</span>
              <Icon name="plus" />
            </Button>
          )}
        </Fragment>
      )}
      {isOnboardingActivated && displayOnboardingCard && (
        <Button
          variant="tertiary"
          className={classes.module}
          onClick={() => {
            onClickEditTemplate('', 'onboarding')
            tracking.roles.editModule({ module: 'onboarding' })
          }}
        >
          <div className={classes.moduleHeader}>
            <h4 className={classes.moduleTitle}>
              <span>🌱</span>
              Onboarding Feedback
            </h4>
          </div>
        </Button>
      )}
    </div>
  )
}
