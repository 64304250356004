import React, { useState } from 'react'
import DropdownField, { Option } from '../../../components/DropdownField'
import { match } from '../../../selectors'

export interface FilterProps {
  label: React.ReactNode
  placeholder?: string
  selected: string
  setSelected: (string) => void
  presets: string[]
  disabled?: boolean
  allowInput?: boolean
}

const FilterDropdown: React.FC<FilterProps> = ({
  label,
  placeholder,
  selected,
  setSelected,
  presets,
  disabled,
  allowInput,
}) => {
  const filterPresets = ['all', ...presets.filter(v => v !== 'in-progress')]

  const [searchTerm, setSearchTerm] = useState('')

  return (
    <DropdownField<string>
      label={label}
      placeholder={placeholder || 'All'}
      onChange={selectedOption => {
        setSelected(selectedOption)
        setSearchTerm('')
      }}
      value={selected}
      disabled={disabled}
      allowInput={allowInput || false}
      textInput={searchTerm}
      onInput={f => setSearchTerm(f)}
    >
      {filterPresets
        .filter(label => match(label, searchTerm))
        .map(preset => (
          <Option key={preset} value={preset}>
            {preset.charAt(0).toUpperCase() + preset.slice(1)}
          </Option>
        ))}
    </DropdownField>
  )
}

export default FilterDropdown
