import { Embeds } from '@checkr/web-sdk'
import ContainerCard from 'components/Container/ContainerCard'
import { px2rem, size, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import React from 'react'
import ReactDOM from 'react-dom'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import * as selectors from 'selectors'
import { RootState } from 'store'
import { getSessionTokenPath } from '../../../api/backgroundcheckintegrations'
import token from '../../../core/token'
import Shell from '../Shell'
import CheckrLogo from './CheckrLogo.svg'

interface BackgroundCheckProps {}

const Container = style().flex({ direction: 'column' }).size({ width: size.fill }).element()

const ReportsOverview = Embeds.ReportsOverview.useReact(React, ReactDOM)

const ReportsOverviewEmbedStyles = {
  '.bgc-item': {
    'border-radius': '5px',
    'border-color': designSystemColors.borderDefault,
  },
  '.bgc-package-name': {
    color: designSystemColors.typographyPrimary,
    'font-size': px2rem(14),
  },
  '.bgc-time-description': {
    'font-size': px2rem(14),
  },
  '.bgc-status-container': {
    'background-color': designSystemColors.informationBlueBackground,
  },
  '.bgc-status-text': {
    color: designSystemColors.informationBluePrimary,
    'font-size': px2rem(12),
  },
  '.bgc-dashboard-link': {
    'background-color': designSystemColors.uiStatusSuccess,
    padding: '5px 8px 5px 8px',
    'font-size': px2rem(14),
    'font-weight': '',
    color: designSystemColors.white,
    'text-decoration': 'none',
    'border-radius': '5px',
  },
  '.bgc-dashboard-link:hover': {
    opacity: '.9',
    cursor: 'pointer',
  },
}

const BackgroundCheck: React.FC<BackgroundCheckProps> = () => {
  const { roleid: roleId, candidateid: candidateId } = useParams<{
    roleid: string
    candidateid: string
  }>()
  const candidate = useSelector((state: RootState) => selectors.candidates.findById(state, candidateId))
  const profile = useSelector((state: RootState) => selectors.candidateProfiles.findByCandidateId(state, candidateId))
  const role = useSelector((state: RootState) => selectors.roles.findByCandidateId(state, candidateId))
  const breadcrumbs =
    role && profile
      ? [
          { url: `/roles/${role ? role.id : ''}`, label: role.fields.name },
          {
            url: `/roles/${role ? role.id : ''}/candidates/${candidate?.id}`,
            label: profile.fields.full_name,
          },
        ]
      : []

  const currentOrgId = useSelector((state: RootState) => {
    return selectors.orgs.currentId(state)
  })

  const sessionTokenUrl = getSessionTokenPath(currentOrgId, 'checkr')
  const bearerToken = token.read()

  return (
    <Shell
      candidateId={candidateId || ''}
      roleId={roleId || ''}
      breadcrumbs={breadcrumbs}
      nameAndImage={{ imageSrc: CheckrLogo, imageAltText: 'Checkr Logo' }}
    >
      <Container>
        <ContainerCard title={'Background check'} emoji={'📑'} emojiColor={'purple'}>
          <ReportsOverview
            externalCandidateId={candidateId || ''}
            sessionTokenPath={sessionTokenUrl}
            sessionTokenRequestHeaders={() => {
              return { Authorization: `Bearer ${bearerToken}` }
            }}
            styles={ReportsOverviewEmbedStyles}
          />
        </ContainerCard>
      </Container>
    </Shell>
  )
}

export default BackgroundCheck
