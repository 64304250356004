import { useFeatureFlag } from 'providers/features'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useIntercom } from 'react-use-intercom'
import * as selectors from 'selectors'
import { RootState } from 'store'

interface IntercomProps {}

export const Intercom: React.FC<IntercomProps> = () => {
  const { isEnabled: intercomEnabled } = useFeatureFlag('intercom')
  const { boot: bootIntercom } = useIntercom()
  const userId = useSelector((state: RootState) => selectors.users.current(state)?.id || '')
  const userIntercomHMAC = useSelector((state: RootState) => state.sessions.intercomHMAC)
  const email = useSelector((state: RootState) => selectors.users.current(state)?.fields.email || '')
  const organization = useSelector(selectors.orgs.current)
  const fullName = useSelector(
    (state: RootState) => selectors.profiles.getByUserId(state, userId)?.fields.full_name || '',
  )
  const canCreateRole = useSelector((state: RootState) => selectors.users.canCreateRole(state, userId))
  const canManageContent = useSelector((state: RootState) => selectors.users.canManageContent(state, userId))
  const canManageOrganization = useSelector((state: RootState) => selectors.users.canManageOrganization(state, userId))

  useEffect(() => {
    if (!(email && fullName && userIntercomHMAC) || !intercomEnabled) {
      return
    }

    bootIntercom({
      userId,
      email,
      name: fullName,
      userHash: userIntercomHMAC,
      customAttributes: {
        'Can Create Role': canCreateRole,
        'Can Manage Content': canManageContent,
        'Can Manage Organization': canManageOrganization,
        Plan: organization.fields.plan,
      },
    })
  }, [email, fullName, userIntercomHMAC, intercomEnabled, userId])

  return null
}
