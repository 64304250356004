import { Entity } from 'api/request'
import * as templatesApi from 'api/templates'
import { ActionCreator } from 'core/store'
import { add as notify } from 'store/notifications'
import { addEntities } from 'store/resources'

export const createTemplate =
  (organizationId: string, template: templatesApi.TemplatePayload): ActionCreator<string | null> =>
  async dispatch => {
    const [response, errors] = await templatesApi.create(organizationId, template)

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return null
    }

    if (response) {
      dispatch(addEntities(response))

      return response.result.id
    }

    return null
  }

export const setPrivate =
  (
    params: { organizationId: string; templateId: string; isPrivate: boolean },
    { sendToast } = { sendToast: true },
  ): ActionCreator<string | null> =>
  async dispatch => {
    try {
      const [response, errors] = await templatesApi.setPrivate(params)

      if (errors) {
        errors.forEach(e => {
          dispatch(notify({ error: e.message }))
        })
        return null
      }

      if (response) {
        dispatch(addEntities(response))

        if (sendToast) {
          dispatch(
            notify({
              success: 'Template deleted',
            }),
          )
        }

        return response.result.id
      }

      return null
    } catch (e) {
      dispatch(notify({ error: e.message }))
      return null
    }
  }

export const updateTemplate =
  (
    organizationId: string,
    templateId: string,
    payload: templatesApi.UpdateTemplatePayload<templatesApi.TemplatePayload>,
  ): ActionCreator<string | null> =>
  async dispatch => {
    try {
      const [response, errors] = await templatesApi.updateTemplate(organizationId, templateId, payload)

      if (errors) {
        errors.forEach(e => {
          dispatch(notify({ error: e.message }))
        })
        return null
      }

      if (!response) {
        return null
      }

      dispatch(addEntities(response))

      return response.result.id
    } catch (e) {
      dispatch(notify({ error: e.message }))
      return null
    }
  }

export const getTemplate =
  (organizationId: string, templateId: string): ActionCreator<Entity<templatesApi.Fields> | null> =>
  async dispatch => {
    try {
      const [response, errors] = await templatesApi.getById(organizationId, templateId)

      if (errors) {
        errors.forEach(e => {
          dispatch(notify({ error: e.message }))
        })
        return null
      }

      if (response && response.result.length > 0) {
        return response.result[0]
      }

      return null
    } catch (e) {
      dispatch(notify({ error: e.message }))
      return null
    }
  }
