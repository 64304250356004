import { colors, height, space, style, weight } from '../../core'

export const Section = style()
  .bg({ color: colors.nearWhite })
  .spacing({
    outer: [],
    inner: [space.l, space.m],
  })

export const Subheading = style()
  .select('strong', style().text({ weight: weight.bold }))
  .text({ height: height.m })
  .element()

export default Section.clone()
  .border({ bottom: '1px solid', color: colors.lightestGray })
  .sans({ height: height.s })
  .element('header')
