import { Button } from 'components/Button/ButtonV2'
import { subDays } from 'date-fns'
import React from 'react'
import { useSelector } from 'react-redux'

import { Tooltip } from '@common/components'
import { employees } from '../../../api'
import { Badge, BadgeTheme } from '../../../components/Badge'
import { RemoveButton } from '../../../components/Button/RemoveButton'
import { Column, Row as DefaultRow } from '../../../components/ContentGrid'
import { Icon } from '../../../components/Icons'
import StatusRing from '../../../components/StatusRing'
import { space, style } from '../../../core'
import { text } from '../../../core/design-system/text'
import * as tracking from '../../../core/track'
import * as selectors from '../../../selectors'
import { milliseconds } from '../../../selectors'
import { RootState } from '../../../store'

export const CustomRow = DefaultRow(['2em', '20%', '11%', '11%', '15%', '15%', '15%', 'auto'])

const Name = text.cardHeader().element('p')

const NotApplicable = style().flex({ direction: 'row', alignItems: 'center' }).spacing({ gap: space.xxs }).element()

// toColor selects a semi-random BadgeTheme color based on the string's hash value
// it is used to try to differentiate the departments by color
function toColor(str: string): BadgeTheme {
  const colors: Array<BadgeTheme> = ['orange', 'blue', 'green', 'purple', 'red', 'cyan']

  let hash = 0
  if (str.length === 0) return colors[0]
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
    hash = hash & hash
  }
  hash = ((hash % colors.length) + colors.length) % colors.length
  return colors[hash]
}

interface Props {
  onDelete: () => void
  employee: employees.Fields
}

export const Row: React.FC<Props> = ({ employee, ...props }) => {
  const submissions = useSelector((state: RootState) =>
    selectors.questionnaireSubmissions.findByEmployeeId(state, employee.id),
  )

  const employeeSubmission = submissions?.find(sub => sub.fields.manager_id === '')

  const sevenDaysBeforeNow = subDays(new Date(), 7)

  // Important: due_at is the date at which the questionnaire was sent out - not the date it was meant to be completed by
  const employeeOverdue =
    employeeSubmission?.fields.due_at &&
    employeeSubmission.fields.submitted_at === 0 &&
    milliseconds(employeeSubmission?.fields.due_at) < sevenDaysBeforeNow.getTime()

  const employeeNotSent =
    employeeSubmission?.fields.due_at && milliseconds(employeeSubmission?.fields.due_at) > Date.now()

  const managerSubmission = submissions?.find(sub => sub.fields.employee_id === '')

  const managerOverdue =
    managerSubmission?.fields.due_at &&
    managerSubmission.fields.submitted_at === 0 &&
    milliseconds(managerSubmission?.fields.due_at) < sevenDaysBeforeNow.getTime()

  const managerNotSent = managerSubmission?.fields.due_at && milliseconds(managerSubmission?.fields.due_at) > Date.now()

  const overallStatus =
    managerNotSent && employeeNotSent ? 'not started' : managerOverdue || employeeOverdue ? 'overdue' : 'in progress'

  const managerStatus = managerNotSent ? 'not started' : managerOverdue ? 'overdue' : 'in progress'

  const employeeStatus = employeeNotSent ? 'not started' : employeeOverdue ? 'overdue' : 'in progress'

  const date = new Date(milliseconds(employee.start_at))

  return (
    <CustomRow>
      <Column></Column>
      <Column centerVert>
        <Name>
          <Button
            as="Link"
            to={`/quality-of-hire/${employee.id}`}
            buttonType="minimal"
            onClick={() => tracking.qualityofhire.employeeClicked(employee.id, employee.name)}
            className="data-hj-suppress"
          >
            {employee.name}
          </Button>
        </Name>
      </Column>
      <Column centerVert>{date.toLocaleDateString()}</Column>
      <Column centerVert>
        <Badge copy={employee.department} theme={toColor(employee.department)} />
      </Column>
      <Column center>
        {employee.scores && employee.scores.overall_score ? (
          employee.scores.overall_score.toFixed() + ' %'
        ) : employee.scores &&
          !employee.scores.overall_score &&
          (employee.scores.managers_evaluation_score === null || employee.scores.new_hire_experience_score === null) ? (
          <Tooltip text="Too many questions were skipped in the surveys to calculate a score">
            <NotApplicable>
              N/A
              <Icon name={'question-circle'} />
            </NotApplicable>
          </Tooltip>
        ) : (
          <StatusRing status={overallStatus} copy={'Overall ' + overallStatus} />
        )}
      </Column>
      <Column center>
        {employee.scores && employee.scores.new_hire_experience_score ? (
          employee.scores.new_hire_experience_score.toFixed() + ' %'
        ) : employee.scores && employee.scores.new_hire_experience_score === null ? (
          <Tooltip text="Employee skipped too many questions to calculate a score">
            <NotApplicable>
              N/A
              <Icon name={'question-circle'} />
            </NotApplicable>
          </Tooltip>
        ) : (
          <StatusRing status={employeeStatus} copy={'New hire experience ' + employeeStatus} />
        )}
      </Column>
      <Column center>
        {employee.scores && employee.scores.managers_evaluation_score ? (
          employee.scores.managers_evaluation_score.toFixed() + ' %'
        ) : employee.scores && employee.scores.managers_evaluation_score === null ? (
          <Tooltip text="Manager skipped too many questions to calculate a score">
            <NotApplicable>
              N/A
              <Icon name={'question-circle'} />
            </NotApplicable>
          </Tooltip>
        ) : (
          <StatusRing status={managerStatus} copy={'Manager evaluation ' + managerStatus} />
        )}
      </Column>
      <Column center>
        <RemoveButton onClick={props.onDelete} iconCaption={'remove ' + employee.name} />
      </Column>
    </CustomRow>
  )
}
