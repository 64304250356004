import React from 'react'
import classes from './NotificationIcon.module.scss'
import { Tooltip } from '../Tooltip/Tooltip'
import { FaBell } from 'react-icons/fa'

interface NotificationIconProps {
  notifications: React.ReactNode[]
  hide: boolean
}

export const NotificationIcon = (props: NotificationIconProps) => {
  return (
    <Tooltip
      text={
        <div className={classes.notifications}>
          {props.notifications.map((n, index) => (
            <div key={index} className={classes.notification}>
              {n}
            </div>
          ))}
        </div>
      }
      direction="top"
      disabled={props.hide}
    >
      <div className={classes.container}>
        <div className={classes.icon}>
          <FaBell />
        </div>
        <div className={classes.numberOfNotifications}>{props.notifications.length}</div>
      </div>
    </Tooltip>
  )
}
