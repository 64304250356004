import * as api from '../api'
import { Entity } from '../api/request'
import { RootState } from '../store'
import lookup from './lookup'
import { getById as getUserById } from './users'

export enum AccessLevel {
  FullAccessRecruiter = 'Recruiter Full Access',
  Recruiter = 'Recruiter',
  User = 'User',
  Administrator = 'Administrator',
}

function getById(state: RootState, id: string): Entity<api.userPermissions.Fields> {
  return lookup(state, api.userPermissions.RESOURCE, id) || api.userPermissions.empty(id)
}

export function getByUserId(state: RootState, userId: string): Entity<api.userPermissions.Fields> | undefined {
  const user = getUserById(state, userId)
  if (!user || !user.relationships) return

  const rel = user.relationships.find(r => r.resource === api.userPermissions.RESOURCE)
  if (!rel) {
    return
  }

  return getById(state, rel.id)
}

export function getAccessLevelByUserId(state: RootState, userId: string): AccessLevel {
  const user = getUserById(state, userId)
  if (user.fields.is_org_admin) return AccessLevel.Administrator

  const userPermission = getByUserId(state, userId)
  if (!userPermission) return AccessLevel.User

  return getAccessLevelForPermissions(userPermission)
}

function getAccessLevelForPermissions(permissions: Entity<api.userPermissions.Permissions>): AccessLevel {
  if (permissions.fields.can_create_role && permissions.fields.can_manage_content) {
    return AccessLevel.FullAccessRecruiter
  }

  if (permissions.fields.can_create_role && !permissions.fields.can_manage_content) {
    return AccessLevel.Recruiter
  }

  return AccessLevel.User
}

export function getUserPermissionsForAccessLevel(
  accessLevel: AccessLevel,
): api.userPermissions.UpdatePayloadPermissions {
  switch (accessLevel) {
    case AccessLevel.FullAccessRecruiter:
      return { can_create_role: true, can_manage_content: true }
    case AccessLevel.Recruiter:
      return { can_create_role: true, can_manage_content: false }
    default:
      return { can_create_role: false, can_manage_content: false }
  }
}
