import React from 'react'
import classes from './ActionCard.module.scss'

import { Card } from '../../Card/Card'
import { Text } from '../../Text/Text'
import { ArrowIcon } from '../ArrowIcon/ArrowIcon'

interface ActionCardProps {
  title: string
  description?: string
  onClick?: () => void
  variant?: 'primary' | 'secondary'
}

export const ActionCard = (props: ActionCardProps) => {
  return (
    <Card className={`${classes.HomeCard} ${classes[props.variant ?? 'primary']}`} onClick={props.onClick}>
      <div className={classes.innerWrapper}>
        <div className={`${classes.textColumn} ${props.variant === 'secondary' ? classes.secondaryTextColumn : ''}`}>
          <Text
            variant={props.variant === 'secondary' ? 'card-header' : 'action-card-header'}
            marginBottom={!!props.description}
            style={{ textDecoration: 'none' }}
            className={classes.text}
          >
            {props.title}
          </Text>
          {props.description && (
            <Text variant="body-text" style={{ textDecoration: 'none' }} className={classes.text}>
              {props.description}
            </Text>
          )}
        </div>
        <ArrowIcon />
      </div>
    </Card>
  )
}
