import { useRect } from '@reach/rect'
import { useEffect, useRef, useState } from 'react'

export type PopoverSide =
  | 'top'
  | 'bottom'
  | 'left'
  | 'right'
  | 'bottom-left'
  | 'bottom-right'
  | 'top-left'
  | 'top-right'

export const usePopover = (dir: PopoverSide) => {
  const [active, setActive] = useState(false)
  const [styles, setStyles] = useState<React.CSSProperties>()
  const [direction, setDirection] = useState<PopoverSide>(dir)

  const triggerRef = useRef<HTMLButtonElement | null>(null)
  const ref = useRef<HTMLDivElement | null>(null)

  const rect = useRect(ref)
  const triggerRect = useRect(triggerRef, {
    onChange(triggerRect) {
      const height = 5 + (rect?.height ?? 0)
      const width = 5 + (rect?.width ?? 0)

      /**
       * lock popover width to prevent
       * skrinking on horizontal collision
       */
      if ((dir.includes('left') || dir.includes('right')) && ref.current) {
        ref.current.style.minWidth = `${width - 5}px`
      }

      const collisionDirection = {
        left: triggerRect.left < width ? 'right' : dir,
        top: triggerRect.top < height ? 'bottom' : dir,
        right: window.innerWidth - triggerRect.right < width ? 'left' : dir,
        bottom: window.innerHeight - triggerRect.bottom < height ? 'top' : dir,
        'top-left': triggerRect.left < width ? 'top-right' : dir,
        'top-right': window.innerWidth - triggerRect.right < width ? 'top-left' : dir,
        'bottom-left': triggerRect.left < width ? 'bottom-right' : dir,
        'bottom-right': window.innerWidth - triggerRect.right < width ? 'bottom-left' : dir,
      }

      setDirection(collisionDirection[dir])
    },
  })

  useEffect(() => {
    const clickawayHandler = (e: any) => {
      const isWithinPopoverArea = ref.current?.contains(e.target) || triggerRef?.current?.contains(e.target)
      active && !isWithinPopoverArea && setActive(false)
    }

    active && window.addEventListener('mousedown', clickawayHandler)

    return () => {
      window.removeEventListener('mousedown', clickawayHandler)
    }
  }, [active])

  useEffect(() => {
    setDirection(dir)
  }, [dir])

  useEffect(() => {
    function px(value: number) {
      return `${value}px`
    }

    if (triggerRect && rect) {
      const trigger = triggerRect

      const xTop = px(trigger.top + trigger.height / 2 - rect.height / 2)
      const xLeft = direction.includes('right') ? px(trigger.right + 5) : px(trigger.left - rect.width - 5)

      const yLeft = px(trigger.left + trigger.width / 2 - rect.width / 2)
      const yTop = direction === 'top' ? px(trigger.top - rect.height - 5) : px(trigger.top + trigger.height + 5)

      if (!direction.includes('-')) {
        setStyles({
          top: direction === 'top' || direction === 'bottom' ? yTop : xTop,
          left: direction === 'top' || direction === 'bottom' ? yLeft : xLeft,
        })
      } else {
        setStyles({
          left: xLeft,
          top: direction.includes('top') ? px(trigger.top) : px(trigger.top - rect.height + trigger.height),
        })
      }
    }
  }, [rect, triggerRect, direction])

  return {
    ref,
    triggerRef,
    direction,
    styles,
    active,
    setActive,
  }
}
