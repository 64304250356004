import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../../components/Modal'
import TextField from '../../components/TextField'
import { Content, ContentAndButtons, Form } from '../../components/Wizard'
import Buttons from '../../components/Wizard/Buttons'
import { Subheading } from '../../components/Wizard/Typography'
import { rem, style } from '../../core'
import * as selectors from '../../selectors'
import { RootState } from '../../store'
import * as slice from '../../store/openjobroles'

interface Props {
  open: boolean
  roleId: string
  setOpen: (b: boolean) => void
}

const Container = style()
  .size({
    maxWidth: rem(40),
    maxHeight: rem(30),
    width: 'calc(100vw - 200px)',
    height: rem(25),
  })
  .element()

const Component = (props: Props) => {
  const dispatch = useDispatch()

  const initTimeWindowDays = useSelector((state: RootState) => {
    const role = selectors.roles.findById(state, props.roleId)
    return role?.fields.time_window_days
  })

  const initCandidateExpiryDays = useSelector((state: RootState) => {
    const role = selectors.roles.findById(state, props.roleId)
    return role?.fields.candidate_expiry_days || role?.fields.time_window_days
  })

  const [timeWindowDays, setTimeWindowDays] = useState(5)
  const [candidateExpiryDays, setCandidateExpiryDays] = useState(5)

  useEffect(() => {
    if (initTimeWindowDays) {
      setTimeWindowDays(initTimeWindowDays)
      setCandidateExpiryDays(initCandidateExpiryDays)
    }
  }, [initTimeWindowDays])

  return (
    <Modal title="Update Job Expiry Time" open={props.open} setOpen={props.setOpen}>
      <Container>
        <ContentAndButtons>
          <Content standalone>
            <Subheading>
              Every job has a time limit for candidates to provide the details of their references, and for references
              to complete their check. Select the numer of days you wish to give your references to respond.
            </Subheading>
            <Form>
              <TextField
                label="⏳ Workdays for candidates to provide reference details"
                value={`${candidateExpiryDays || ''}`}
                placeholder="Enter a number between 1-30"
                onChange={e => {
                  const input = e.target.value
                  setCandidateExpiryDays(Number(input))
                }}
              />
              <TextField
                label="⌛️ Workdays for references to complete check"
                value={`${timeWindowDays || ''}`}
                placeholder="Enter a number between 1-30"
                onChange={e => {
                  const input = e.target.value
                  setTimeWindowDays(Number(input))
                }}
              />
            </Form>
          </Content>
          <Buttons
            next={() => {
              dispatch(slice.updateTimeWindowDays(props.roleId, timeWindowDays, candidateExpiryDays))
              props.setOpen(false)
            }}
            back={() => {}}
            disabled={!timeWindowDays}
            label={'Update'}
            forwardOnly
          ></Buttons>
        </ContentAndButtons>
      </Container>
    </Modal>
  )
}

export default Component
Component.displayName = 'ExpiryTimeModal'
