import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit'
import { users } from '../../api'
import { AppThunk } from '../../core/store'
import { add as notify } from '../notifications'
import { handleResponse } from '../utils'

const name = 'password-settings'

interface Changes {
  oldPassword?: string
  newPassword?: string
  newPasswordConfirm?: string
}

interface State {
  changes: Changes
  loading: boolean
}

const initialState: State = {
  changes: {},
  loading: false,
}

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setOldPassword(state, action: PayloadAction<string>) {
      state.changes.oldPassword = action.payload
    },
    setNewPassword(state, action: PayloadAction<string>) {
      state.changes.newPassword = action.payload
    },
    setNewPasswordConfirm(state, action: PayloadAction<string>) {
      state.changes.newPasswordConfirm = action.payload
    },

    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },

    reset(state) {
      state.changes = {}
    },
  },
})

export const { setNewPassword, setOldPassword, setNewPasswordConfirm, setLoading } = slice.actions

export default slice.reducer

export const save =
  (userId: string, changes: Changes): AppThunk =>
  async (dispatch: Dispatch) => {
    dispatch(setLoading(true))

    if (changes.newPassword !== changes.newPasswordConfirm) {
      dispatch(setNewPasswordConfirm(''))
      dispatch(
        notify({
          error: 'Please confirm your password.',
        }),
      )
      dispatch(setLoading(false))
      return
    }

    if (
      !handleResponse<users.Fields>(
        dispatch,
        await users.updatePassword(userId, changes.oldPassword || '', changes.newPassword || ''),
      )
    )
      return dispatch(setLoading(false))

    dispatch(setLoading(false))

    dispatch(
      notify({
        success: 'Your password has been updated successfully!',
      }),
    )
  }
