import { useConfirm } from 'components/Confirm'
import DropdownField, { Option } from 'components/DropdownField'
import GuestLayout from 'components/GuestLayout'
import { size, style } from 'core'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { add as notify } from 'store/notifications'
import { API_BASE_KEY } from '../../../api/request'
import Block, { Header as BlockHeader } from '../../../components/Block'
import { Title, TitleRow } from '../../../components/DashboardLayout'

const SettingsContainers = style().grid().spacing({ outerTop: size.s, rows: size.m }).element()

export const Environments = {
  Production: 'https://api.hipeople.io',
  Feat: 'https://api.feat.hipeople.io',
  Local: 'http://api.hipeople.localhost',
} as const

// @ts-ignore ValuesOf is not a type
type EnvironmentsType = ValuesOf<typeof Environments>

const Component = () => {
  const confirm = useConfirm()
  const dispatch = useDispatch()
  const history = useHistory()

  const [environment, setEnvironment] = useState<EnvironmentsType>(
    (localStorage.getItem(API_BASE_KEY) as EnvironmentsType) ||
      import.meta.env.VITE_API_BASE ||
      Environments.Production,
  )

  return (
    <GuestLayout>
      <TitleRow>
        <Title>{`Manage Environments`}</Title>
      </TitleRow>
      <SettingsContainers>
        <Block title={`Environments`}>
          <BlockHeader>
            {`Alter the dashboard to connect to different our different API environments. Production is what our customers are currently seeing, Feature Staging is what is currently in our Feature Staging environment and Local is the API running on your computer.`}
          </BlockHeader>
          <DropdownField<EnvironmentsType> value={environment || ''} onChange={value => updateEnv(value)}>
            <Option value={Environments.Production}>{`Production`}</Option>
            <Option value={Environments.Feat}>{`Feature Staging`}</Option>
            <Option value={Environments.Local}>{`Local`}</Option>
          </DropdownField>
        </Block>
      </SettingsContainers>
    </GuestLayout>
  )

  async function updateEnv(env: EnvironmentsType) {
    if (
      await confirm({
        title: `About to change API environment?`,
        message: `**${env}** will be the new base environment. **After changing this you will be redirected to the login page**, hitting the desired environment.`,
        confirmLabel: 'Yes, change environment',
        danger: true,
      })
    ) {
      setEnvironment(env)
      try {
        localStorage.setItem(API_BASE_KEY, env)
        history.push(`/`)
      } catch {
        dispatch(
          notify({
            error: `There was a problem changing your environment, this is probably due to you having too much in your localStorage.`,
          }),
        )
      }
    }
  }
}

export default Component
Component.displayName = 'ManageEnvironments'
