import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit'
import { CandidateExperienceMode } from 'api/org-settings'
import { PaidProductName } from 'api/orgs'
import resizeImage from 'core/image'
import { orgs, orgSettings } from '../../api'
import { AppThunk } from '../../core/store'
import { add as notify } from '../notifications'
import { addEntities } from '../resources'
import { handleResponse } from '../utils'

const name = 'org-settings'

export interface OrgChanges {
  name?: string
  slug?: string
  logoImage?: File
}

export interface SettingsChanges {
  minReferenceCount?: string
  pdfExportEnabled?: boolean
  candidateExperienceMode?: CandidateExperienceMode
  qualityOfHireEnabled?: boolean
  qualityOfHireSurveyInterval?: number
  talentPoolEnabled?: boolean
  salesPoolEnabled?: boolean
  brandingColorPrimary?: string
}

interface State {
  name: string
  slug: string
  logoImageUrl: string
  loading: boolean
}

const initialState: State = {
  name: '',
  slug: '',
  logoImageUrl: '',
  loading: false,
}

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
  },
})

export const { setLoading } = slice.actions

export default slice.reducer

export const save =
  (id: string, orgChanges: OrgChanges, settingsChanges: SettingsChanges): AppThunk =>
  async (dispatch: Dispatch) => {
    dispatch(setLoading(true))

    const settingsChangesPayload: orgSettings.UpdateSettingsPayload = {
      updated: [],
      updates: {},
    }

    let toastMessage = 'The organization settings has been updated successfully!'

    if (settingsChanges.qualityOfHireEnabled !== undefined) {
      settingsChangesPayload.updates.quality_of_hire_enabled = settingsChanges.qualityOfHireEnabled
      settingsChangesPayload.updated.push('quality_of_hire_enabled')
    }

    if (settingsChanges.qualityOfHireSurveyInterval !== undefined) {
      settingsChangesPayload.updates.quality_of_hire_survey_interval = settingsChanges.qualityOfHireSurveyInterval
      settingsChangesPayload.updated.push('quality_of_hire_survey_interval')
      toastMessage = 'Your timeline has been updated and will be applied to all new hires you add from now on.'
    }

    if (settingsChanges.minReferenceCount !== undefined) {
      settingsChangesPayload.updates.min_reference_count = Number(settingsChanges.minReferenceCount)
      settingsChangesPayload.updated.push('min_reference_count')
    }

    if (settingsChanges.pdfExportEnabled !== undefined) {
      settingsChangesPayload.updates.all_recruiters_candidate_pdf_enabled = settingsChanges.pdfExportEnabled
      settingsChangesPayload.updated.push('all_recruiters_candidate_pdf_enabled')
    }

    if (settingsChanges.candidateExperienceMode !== undefined) {
      settingsChangesPayload.updates.candidate_experience_mode = settingsChanges.candidateExperienceMode
      settingsChangesPayload.updated.push('candidate_experience_mode')
    }

    if (settingsChanges.talentPoolEnabled !== undefined) {
      settingsChangesPayload.updates.talent_pool_enabled = settingsChanges.talentPoolEnabled
      settingsChangesPayload.updated.push('talent_pool_enabled')
    }

    if (settingsChanges.salesPoolEnabled !== undefined) {
      settingsChangesPayload.updates.sales_pool_enabled = settingsChanges.salesPoolEnabled
      settingsChangesPayload.updated.push('sales_pool_enabled')
    }

    if (settingsChanges.brandingColorPrimary !== undefined) {
      settingsChangesPayload.updates.branding_color_primary = settingsChanges.brandingColorPrimary
      settingsChangesPayload.updated.push('branding_color_primary')
    }

    if (
      settingsChangesPayload.updated.length > 0 &&
      !handleResponse<orgSettings.Fields>(dispatch, await orgSettings.updateSettings(id, settingsChangesPayload))
    ) {
      return dispatch(setLoading(false))
    }

    if (orgChanges.name !== undefined) {
      if (!handleResponse<orgs.Fields>(dispatch, await orgs.updateName(id, orgChanges.name.trim()))) {
        return dispatch(setLoading(false))
      }
    }

    if (orgChanges.slug !== undefined) {
      if (!handleResponse<orgs.Fields>(dispatch, await orgs.updateSlug(id, orgChanges.slug.trim()))) {
        return dispatch(setLoading(false))
      }
    }

    if (orgChanges.logoImage !== undefined) {
      // limit the size of the uploaded profile images to 512x512
      let resizedImage = orgChanges.logoImage
      try {
        resizedImage = await resizeImage(orgChanges.logoImage, 512, 512)
      } catch (error) {
        // ignore errors if resizing fails
      }
      if (!handleResponse<orgs.Fields>(dispatch, await orgs.updateImage(id, resizedImage))) {
        return dispatch(setLoading(false))
      }
    }

    dispatch(setLoading(false))

    dispatch(
      notify({
        success: toastMessage,
      }),
    )
  }

export const requestQuote =
  (id: string): AppThunk =>
  async (dispatch: Dispatch) => {
    const [response, errors] = await orgs.requestAQuote(id)

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(addEntities(response))

      dispatch(
        notify({
          success: `Thank you for your request! The HiPeople team will get back to you shortly`,
        }),
      )
    }
  }

export const requestProductUpgrade =
  (id: string, productName: PaidProductName): AppThunk =>
  async (dispatch: Dispatch) => {
    const [response, errors] = await orgs.requestProductUpgrade(id, productName)

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(addEntities(response))

      dispatch(
        notify({
          success: `Thank you for your request! The HiPeople team will get back to you shortly`,
        }),
      )
    }
  }
export const updatePrivacyPolicyLink =
  (id: string, privacyPolicyLink: string): AppThunk =>
  async (dispatch: Dispatch) => {
    dispatch(setLoading(true))

    if (!handleResponse<orgs.Fields>(dispatch, await orgs.updatePrivacyPolicyLink(id, privacyPolicyLink)))
      return dispatch(setLoading(false))

    dispatch(setLoading(false))

    dispatch(
      notify({
        success: `The privacy policy link has been updated!`,
      }),
    )
  }

export const simpleATSImport =
  (id: string, url: string): AppThunk =>
  async (dispatch: Dispatch) => {
    dispatch(setLoading(true))

    if (!handleResponse<orgs.Fields>(dispatch, await orgs.simpleATSImport(id, url))) return dispatch(setLoading(false))

    dispatch(setLoading(false))

    dispatch(
      notify({
        success: `Roles have been imported!`,
      }),
    )
  }
