import { PopoverContent } from '@common/components'
import { Button, Separator } from 'components/PopupV2'
import React, { MutableRefObject } from 'react'

interface OrgMenuProps {
  isMenuOpen: boolean
  setMenuOpen: (open: boolean) => void
  onClickSignout: () => void
  helpTracking?: () => void
  orgSettingsTracking?: () => void
  manageUsersTracking?: () => void
  canManageOrganization: boolean
  canProvideSupport: boolean
  activatorRef?: MutableRefObject<null | HTMLElement>
}

const OrgMenu: React.FC<OrgMenuProps> = props => {
  let orgMenuItems = [
    {
      element: 'button',
      label: 'Organization settings',
      icon: 'cog',
      link: '/settings/organization',
      ariaLabel: 'organization settings',
      requireElevatedRights: true,
      onClick: props.orgSettingsTracking,
    },
    {
      element: 'button',
      label: 'Plans & Billing',
      icon: 'credit-card',
      link: '/upgrade',
      ariaLabel: 'plans and billing',
      requireElevatedRights: true,
      onClick: props.orgSettingsTracking,
    },
    {
      element: 'button',
      label: 'Template library',
      icon: 'book-open',
      link: '/templates?mode=template',
      ariaLabel: 'template library',
      requireElevatedRights: true,
      onClick: props.orgSettingsTracking,
    },
    {
      element: 'button',
      label: 'Users',
      icon: 'users',
      link: '/settings/users',
      ariaLabel: 'manage users',
      requireElevatedRights: true,
      onClick: props.manageUsersTracking,
      internalOnly: false,
    },
    {
      element: 'button',
      label: 'Support candidates',
      icon: 'poll-people',
      link: '/settings/candidates',
      ariaLabel: 'candidate list',
      requireElevatedRights: true,
      onClick: props.orgSettingsTracking,
      internalOnly: true,
    },
    {
      element: 'button',
      label: 'Support references',
      icon: 'poll-people',
      link: '/settings/references',
      ariaLabel: 'reference list',
      requireElevatedRights: true,
      onClick: props.orgSettingsTracking,
      internalOnly: true,
    },
    { element: 'separator', requireElevatedRights: true },
    {
      element: 'button',
      label: 'Help',
      icon: 'life-ring',
      link: 'https://intercom.help/hipeople/en/',
      ariaLabel: 'help',
      onClick: props.helpTracking,
      internalOnly: false,
    },
    {
      element: 'button',
      label: 'Log out',
      icon: 'sign-out',
      ariaLabel: 'Log out',
      onClick: props.onClickSignout,
      internalOnly: false,
    },
  ]

  if (!props.canProvideSupport) {
    orgMenuItems = orgMenuItems.filter(item => !item.internalOnly)
  }

  if (!props.canManageOrganization) {
    orgMenuItems = orgMenuItems.filter(item => !item.requireElevatedRights)
  }

  return (
    <PopoverContent>
      <div style={{ padding: '0.5rem 0' }}>
        {orgMenuItems.map((item, i) =>
          item.element === 'separator' ? (
            <Separator key={`separator-${i}`} />
          ) : (
            <li key={item.label} style={{ listStyle: 'none' }}>
              <Button icon={item.icon || 'cog'} link={item.link} aria-label={item.ariaLabel} onClick={item.onClick}>
                {item.label}
              </Button>
            </li>
          ),
        )}
      </div>
    </PopoverContent>
  )
}

export default OrgMenu
