import { LockedModal, TopBar } from '@common/components'
import { Text } from '@common/components/Text/Text'
import { RolePage, TemplateProps } from 'App/Role'
import { lookup, style } from 'core'
import { interpolateVariables } from 'core/text'
import { lockedContentTracking } from 'core/track/locked-content'
import { copyToClipboard } from 'core/utils'
import { differenceInDays } from 'date-fns'
import { useFeatureFlag } from 'providers/features'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useMount } from 'react-use'
import { useIntercom } from 'react-use-intercom'
import { RootState } from 'store'
import * as modulesSlice from 'store/modules'
import { starRole } from 'store/roles'
import * as skillsSlice from 'store/skills'
import { candidates as candidatesApi, listing, openjobroles } from '../../api'
import DashboardLayout from '../../components/DashboardLayout'
import * as tracking from '../../core/track'
import * as selectors from '../../selectors'
import { setCreated as setCreatedAssessment } from '../../store/assessments'
import { add as notify } from '../../store/notifications'
import {
  defaultSortField,
  defaultSortOrder,
  fetch,
  loadCandidates,
  setCreated as setCreatedOpenJobRole,
  SortField,
} from '../../store/openjobroles'
import InviteViaLinkModal from '../Role/InviteCandidate/InviteViaLinkModal'
import ATSAutomationModal from './ATSAutomationModal'
import ExpiryTimeModal from './ExpiryTimeModal'
import InviteModal from './InviteModal'
import ReferencesModal from './ReferencesModal'
import TitlesModal from './TitlesModal'
import UsersPermissionsModal from './UserPermissionsModal'

const RolePageContainer = style().scroll({ y: 'auto', x: 'hidden' }).element('main')

const OpenJobRole = () => {
  const dispatch = useDispatch()
  const { update } = useIntercom()
  const { roleid } = useParams<{ roleid: string }>()

  const [isInviteViaEmailModalOpen, setIsInviteViaEmailModalOpen] = useState(false)
  const [isInviteViaLinkModalOpen, setIsInviteViaLinkModalOpen] = useState(false)
  const [isManageUserPermissionsModalOpen, setIsManageUserPermissionsModalOpen] = useState(false)

  const [isUpdateExpiryModalOpen, setIsUpdateExpiryModalOpen] = useState(false)

  const [isUpdateReferencesModalOpen, setIsUpdateReferencesModalOpen] = useState(false)

  const [isUpdateTitlesModalOpen, setIsUpdateTitlesModalOpen] = useState(false)

  const [isATSAutomationModalOpen, setIsATSAutomationModalOpen] = useState(false)

  const [sortField, sortOrder]: [SortField, boolean] = useSelector((state: RootState) => {
    const id = roleid ? roleid : ''
    const sortField = !state.openjobroles.sortedBy[id] ? defaultSortField : state.openjobroles.sortedBy[id].field
    const sortOrder = !state.openjobroles.sortedBy[id] ? defaultSortOrder : state.openjobroles.sortedBy[id].order
    const desc = sortOrder === listing.SortOrder.Desc

    return [sortField, desc]
  })

  const state = useSelector((state: RootState) => {
    if (!roleid) return null

    const role = lookup<openjobroles.Fields>(state, { openjobrole: roleid })
    if (!role) {
      return null
    }

    const candidateIds = state.openjobroles.candidates[roleid]
    if (!candidateIds) {
      return null
    }

    const total = state.openjobroles.total[roleid]

    const candidates = candidateIds.map(id => lookup<candidatesApi.Fields>(state, { candidate: id }))

    const hasMoreCandidates = candidates.length !== (total?.all || 0)

    return { role, candidates, hasMoreCandidates }
  })

  const { role, candidates } = state || {}

  const trackingProps = useSelector((state: RootState) => tracking.roles.extractPropsFromState(state, roleid || ''))

  // Send a tracking event every time user views a role
  useEffect(() => {
    if (role && trackingProps) {
      tracking.roles.view(trackingProps)
    }
  }, [role?.id])

  useMount(() => {
    dispatch(skillsSlice.load())
    dispatch(modulesSlice.loadReferenceCheckModules())
  })

  const orgId = useSelector((state: RootState) => selectors.orgs.currentId(state))

  const getPreview = useSelector(
    (state: RootState) => (presetId: string) => selectors.presets.findPresetPreviewById(state, presetId, orgId),
  )

  const org = useSelector((state: RootState) => selectors.orgs.getById(state, orgId))

  const isOrgPaidPlan = useSelector(selectors.orgs.isOrgPaidPlan)

  const createdAt = new Date((role?.fields.created_at || 0) / 1_000_000)
  const moreThanSevenDaysSinceCreatedAt = differenceInDays(new Date(), createdAt) > 7

  const justCreated = useSelector((state: RootState) => state.openjobroles.created.length > 0)

  const userId = useSelector((state: RootState) => state.sessions.userId)
  const isStarred = useSelector(
    (state: RootState) => selectors.openJobRoleUsers.findByRoleIdAndUserId(state, roleid, userId)?.fields.is_starred,
  )

  if (justCreated) {
    dispatch(setCreatedAssessment(''))
    dispatch(setCreatedOpenJobRole(''))
  }

  useEffect(() => {
    if (roleid) {
      dispatch(loadCandidates(roleid as string, sortField, sortOrder))

      if (!role) {
        dispatch(fetch(roleid))
      }
    }
  }, [roleid, sortField, sortOrder, dispatch])

  const SCROLLABLE_TARGET = 'candidates-scroll-target'

  const firstCandidate = candidates?.[0]
  useEffect(() => {
    if (roleid && firstCandidate?.id) {
      update({
        customAttributes: {
          'Role Id': roleid,
          'Candidate Id': firstCandidate.id,
        },
      })
    }
  }, [firstCandidate?.id, roleid])

  const { isEnabled: isMergeDevIntegrationEnabled } = useFeatureFlag('merge-dev-integration')

  const { isEnabled: isPostHireOnboardingEnabled } = useFeatureFlag('post-hire-onboarding')

  const referenceCheckPreset = useSelector((state: RootState) =>
    selectors.roles.findReferenceCheckPresetByRoleId(state, role?.id || ''),
  )

  const selfAssessmentPreset = useSelector((state: RootState) =>
    selectors.roles.findSelfAssessmentPresetByRoleId(state, role?.id || ''),
  )
  function getReferenceCheckPreset(): TemplateProps | undefined {
    if (!role?.fields.reference_form_id && !referenceCheckPreset) {
      return undefined
    }

    if (referenceCheckPreset) {
      return {
        id: referenceCheckPreset?.id || '',
        templateName: referenceCheckPreset?.fields.copy?.title || role?.fields.name || '',
        canBeEdited: true,
        canBePreviewed: true,
      }
    }

    return {
      id: '',
      templateName: `Custom: ${role?.fields.name}`,
      canBePreviewed: false,
      canBeEdited: true,
    }
  }

  function getSelfAssessmentPreset(): TemplateProps | undefined {
    if (!selfAssessmentPreset) {
      return undefined
    }

    return {
      id: selfAssessmentPreset?.id || '',
      templateName: selfAssessmentPreset?.fields.copy?.title || role?.fields.name || '',
      canBeEdited: true,
      canBePreviewed: true,
    }
  }

  // INVITE VIA LINK
  const { isEnabled: isInviteByLinkEnabled } = useFeatureFlag('invite-by-link')

  const [publicInviteLinkText, setPublicInviteLinkText] = useState('')

  function getPublicInviteLink(roleId: string) {
    // We use the window.location.origin instead of import.meta.env.VITE_BASE because we want to get the correct preview deployment url here.
    // Netlify does not support injecting the live preview url into the environment variables during build time.
    setPublicInviteLinkText(`${window.location.origin}/roles/${roleId}/public-intro`)
  }

  function handleCopyToClipboard(textToCopy: string) {
    copyToClipboard(textToCopy)
    onCopyToClipboardSuccess()
  }

  function onCopyToClipboardSuccess() {
    dispatch(notify({ success: 'Invite link copied to your clipboard' }))
  }

  const history = useHistory()

  return (
    <DashboardLayout>
      <TopBar tracking={tracking} />
      <RolePageContainer id={SCROLLABLE_TARGET}>
        <RolePage
          roleName={role?.fields.name || ''}
          isReferenceCheckActivated={role?.fields.ask_reference_check || !!role?.fields.reference_form_id || false}
          isSelfAssessmentActivated={!!role?.fields.ask_self_assessment}
          isOnboardingActivated={isPostHireOnboardingEnabled || false}
          referenceCheckTemplate={getReferenceCheckPreset()}
          selfAssessmentTemplate={getSelfAssessmentPreset()}
          onClickInviteCandidateByEmail={() => {
            setIsInviteViaEmailModalOpen(true)
            tracking.roles.inviteViaEmailSelected({
              name: role?.fields.name || '',
            })
          }}
          onClickInviteCandidateByLink={() => {
            setIsInviteViaLinkModalOpen(true)
            getPublicInviteLink(roleid)
            tracking.roles.inviteViaLinkSelected({
              name: role?.fields.name || '',
            })
          }}
          isInviteByLinkEnabled={isInviteByLinkEnabled}
          getPreview={(presetId: string) => {
            return getPreview(presetId).map(item => ({
              ...item,
              copy: interpolateVariables(item.copy, {
                'CANDIDATE.FIRST_NAME': 'Alice',
                'ORGANIZATION.NAME': org.fields.name,
                'ROLE.NAME': role?.fields.public_name || role?.fields.name || '',
                'REFERENCE.FIRST_NAME': 'Bob',
              }),
            }))
          }}
          hasLegacyReferenceForm={!!role?.fields.reference_form_id}
          isStarred={isStarred}
          toggleStarred={() => dispatch(starRole({ roleId: roleid, userId, isStarred: !isStarred }))}
          setIsInviteViaEmailModalOpen={setIsInviteViaEmailModalOpen}
          setIsManageUserPermissionsModalOpen={setIsManageUserPermissionsModalOpen}
          setIsUpdateExpiryModalOpen={setIsUpdateExpiryModalOpen}
          setIsUpdateReferencesModalOpen={setIsUpdateReferencesModalOpen}
          setIsUpdateTitlesModalOpen={setIsUpdateTitlesModalOpen}
          setIsATSAutomationModalOpen={setIsATSAutomationModalOpen}
        />
      </RolePageContainer>
      <LockedModal
        open={role !== undefined && !isOrgPaidPlan && moreThanSevenDaysSinceCreatedAt}
        setOpen={history.goBack}
        closeButtonText={'Go back'}
        tracking={lockedContentTracking}
        modalData={{
          title: 'Free access lapsed',
          content: (
            <Text variant="body-text">
              Upgrade your plan to get access to historic assessments and reference checks.
            </Text>
          ),
          learnMoreAbout: 'Locked roles',
        }}
      />
      <InviteModal
        roleId={roleid}
        open={isInviteViaEmailModalOpen}
        setOpen={(open: boolean) => {
          setIsInviteViaEmailModalOpen(open)

          if (!open) {
            dispatch(loadCandidates(roleid as string, sortField, sortOrder))
          }
        }}
        refresh={() => dispatch(loadCandidates(roleid as string, sortField, sortOrder))}
      />
      <InviteViaLinkModal
        open={isInviteViaLinkModalOpen}
        setOpen={open => setIsInviteViaLinkModalOpen(open)}
        onClickCopyToClipboard={() => {
          handleCopyToClipboard(publicInviteLinkText)
          tracking.roles.inviteViaLinkCopyToClipboard({
            name: role?.fields.name || '',
          })
        }}
        urlText={publicInviteLinkText}
      />
      <UsersPermissionsModal
        roleId={roleid || ''}
        open={isManageUserPermissionsModalOpen}
        setOpen={setIsManageUserPermissionsModalOpen}
      />
      <ReferencesModal
        roleId={roleid || ''}
        open={isUpdateReferencesModalOpen}
        setOpen={setIsUpdateReferencesModalOpen}
      />
      <ExpiryTimeModal roleId={roleid || ''} open={isUpdateExpiryModalOpen} setOpen={setIsUpdateExpiryModalOpen} />
      <TitlesModal roleId={roleid || ''} open={isUpdateTitlesModalOpen} setOpen={setIsUpdateTitlesModalOpen} />
      {isMergeDevIntegrationEnabled && (
        <ATSAutomationModal
          roleId={roleid || ''}
          open={isATSAutomationModalOpen}
          setOpen={setIsATSAutomationModalOpen}
        />
      )}
    </DashboardLayout>
  )
}

export default OpenJobRole
