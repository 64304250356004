import { useConfirm } from 'components/Confirm'
import TextField from 'components/TextField'
import { Buttons, Content, ContentAndButtons } from 'components/Wizard'
import { Subheading } from 'components/Wizard/Typography'
import { fr, px2rem, space, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMount } from 'react-use'
import * as selectors from 'selectors'
import { RootState } from 'store'
import * as slice from 'store/integrations'
import { ATS as ATSType } from 'store/integrations/ats'

const Container = style()
  .size({
    width: px2rem(640),
    height: px2rem(350),
  })
  .element()

const SelectedContainer = style()
  .grid({ columns: [fr(1), fr(1)] })
  .spacing({ columns: space.s, rows: space.s })
  .element()

const SubheadingBold = text.bodyEmphasis().fg(designSystemColors.typographySecondary).inline().element('p')

const Labels = style()
  .grid({ columns: [fr(1), fr(1)] })
  .spacing({ columns: space.s, outerBottom: px2rem(10) })
  .element()

const Label = text.bodyInteractive().element()

interface Props {
  roleId: string
  setOpen: (b: boolean) => void
  atsMap: { [key: string]: ATSType }
}

const Component = (props: Props) => {
  const dispatch = useDispatch()
  const confirm = useConfirm()

  const org = useSelector((state: RootState) => selectors.orgs.getById(state, selectors.orgs.currentId(state)))
  const role = useSelector((state: RootState) => selectors.roles.findById(state, props.roleId))
  const integration = useSelector((state: RootState) => selectors.integrations.getByOrgId(state, org.id))
  const connections = useSelector((state: RootState) =>
    selectors.integrationConnections.getByRoleId(state, props.roleId),
  )

  const atsJobInterviewStages = useSelector((state: RootState) => state.integrations.atsJobInterviewStages || [])

  useMount(() => {
    if (integration && connections && connections[0].fields.ats_job_id) {
      dispatch(slice.getATSJobInterviewStages(integration.id, connections[0].fields.ats_job_id))
    }
  })

  let ats: ATSType

  async function onClickDisconnect() {
    if (
      !connections ||
      !(await confirm({
        title: 'Confirm disconnection',
        message: `Please confirm if you wish to disconnect the ${ats?.name} automation for “${role.fields.name}”.`,
        confirmLabel: 'Disconnect',
        danger: true,
      }))
    )
      return

    for (const connection of connections) {
      dispatch(slice.deleteOpenJobRoleIntegration(connection.id, role.id))
    }

    props.setOpen(false)
  }

  if (integration?.fields.ats_slug && props.atsMap[integration?.fields.ats_slug]) {
    ats = props.atsMap[integration?.fields.ats_slug]

    const refCheckStage = (connections || []).find(i => i.fields.integrated_product === 'reference-check')

    const selfAssessmentStage = (connections || []).find(i => i.fields.integrated_product === 'self-assessment')

    return (
      <Container>
        <ContentAndButtons>
          <Content>
            <Subheading>
              <SubheadingBold>“{role.fields.name}”</SubheadingBold> is already connected to a job in{' '}
              <SubheadingBold>{ats.name}</SubheadingBold>.
            </Subheading>
            <Labels>
              <Label>HiPeople module</Label>
              <Label>ATS Pipeline stage</Label>
            </Labels>
            <SelectedContainer>
              {selfAssessmentStage ? (
                <>
                  <TextField label="" value={`👤 Assessment`} disabled />
                  <TextField
                    label=""
                    value={`“${
                      atsJobInterviewStages.find(
                        item => item.id === selfAssessmentStage.fields.ats_job_interview_stage_id,
                      )?.name || ''
                    }”`}
                    disabled
                  />
                </>
              ) : (
                <></>
              )}
              {refCheckStage ? (
                <>
                  <TextField label="" value={`👥 Reference Check`} disabled />
                  <TextField
                    label=""
                    value={`“${
                      atsJobInterviewStages.find(item => item.id === refCheckStage.fields.ats_job_interview_stage_id)
                        ?.name || ''
                    }”`}
                    disabled
                  />
                </>
              ) : (
                <></>
              )}
            </SelectedContainer>
          </Content>
          <Buttons next={onClickDisconnect} back={() => props.setOpen(false)} label={'Disconnect'} />
        </ContentAndButtons>
      </Container>
    )
  }

  return <></>
}

export default Component
