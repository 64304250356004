import { Badge, Tooltip } from '@common/components'
import * as api from 'api'
import { RejectionReason } from 'api/reference'
import { Details, Label, Row, RowIcon } from 'components/Block'
import FeedbackStyleBadge from 'components/FeedbackStyleBadge'
import RelationshipLabel from 'components/RelationshipLabel'
import { NotVerified, Unreviewed, Verified } from 'components/VerificationBadge'
import { px2rem, space, style } from 'core'
import { designSystemColors as designColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React from 'react'
import { useSelector } from 'react-redux'
import * as selectors from 'selectors'
import { RootState } from 'store'

interface Props {
  reference: api.request.Entity<api.references.Fields>
}

const Component: React.FC<Props> = props => {
  const professionalNetwork = useSelector((state: RootState) =>
    selectors.references.getProfessionalNetworkForDisplay(state, props.reference),
  )
  const candidateProfile = useSelector((state: RootState) =>
    selectors.candidateProfiles.findByCandidateId(state, props.reference.fields.candidate_id),
  )

  const feedbackStyle = useSelector((state: RootState) =>
    selectors.haloScore.findFeedbackStyleByReferenceId(state, props.reference.id),
  )

  const relationship = selectors.references.relationshipOf(props.reference)
  const isSelfReferenceProfile = props.reference.fields.is_self

  const rows: {
    value: React.ReactNode
    label: string
    emoji?: string
    status?: 'success' | 'error' | 'warning'
    tooltip?: string
    link?: string
  }[] = []

  rows.push({
    emoji: '👔',
    label: 'Reference',
    value: <RelationshipLabel type={relationship} />,
  })

  rows.push({
    emoji: '📧',
    label: 'Email',
    value: props.reference.fields.email,
  })

  professionalNetwork &&
    rows.push({
      label: `Professional Network`,
      emoji: '🔗',
      value: professionalNetwork?.name,
      link: professionalNetwork.url,
    })

  if (!isSelfReferenceProfile) {
    rows.push({
      label: `Company`,
      emoji: '🏢',
      value: props.reference.fields.company_name,
    })

    rows.push({
      label: `Worked with candidate`,
      emoji: '🤝',
      value: `${props.reference.fields.from} to ${props.reference.fields.to}`,
    })

    feedbackStyle &&
      rows.push({
        emoji: '💬',
        label: 'Feedback style',
        link: 'https://hipeople.notion.site/Feedback-Styles-Public-Documentation-d0a2460d701842a5846ac6d191de9e87',
        value: (
          <>
            {feedbackStyle} <FeedbackStyleBadge style={feedbackStyle} />
          </>
        ),
      })

    props.reference.fields.phone_number &&
      rows.push({
        emoji: '☎️',
        label: 'Phone number',
        value: `${props.reference.fields.phone_number}`,
      })

    props.reference.fields.candidate_job_title &&
      rows.push({
        emoji: '👤',
        label: 'Candidate job title',
        status: props.reference.fields.is_job_title_confirmed
          ? 'success'
          : props.reference.fields.reference_candidate_job_title
          ? 'error'
          : 'warning',
        tooltip: props.reference.fields.is_job_title_confirmed
          ? 'Reference has confirmed the job title'
          : props.reference.fields.reference_candidate_job_title
          ? `Job title given by reference: ${props.reference.fields.reference_candidate_job_title}`
          : 'Reference still needs to confirm the job title',
        value: `${props.reference.fields.candidate_job_title}`,
      })
  }

  return (
    <>
      <Details className="data-hj-suppress">
        {rows.map((row, i) => (
          <Row key={`row-${i}`}>
            <RowIcon>{row.emoji}</RowIcon>
            <Label>
              <strong>{row.label}:</strong>
              {row.link ? (
                <a href={row.link} target="_blank" rel="noopener noreferrer">
                  {row.value}
                </a>
              ) : row.tooltip && row.status ? (
                <Tooltip text={row.tooltip} direction="top">
                  <Badge variant={row.status}>{row.value}</Badge>
                </Tooltip>
              ) : (
                row.value
              )}
            </Label>
          </Row>
        ))}
      </Details>
      {!isSelfReferenceProfile && candidateProfile && (
        <VerifiedStatus candidateProfile={candidateProfile} reference={props.reference} />
      )}
    </>
  )
}

export default Component
Component.displayName = 'Profile'

const VerifiedStatusContainer = text
  .smallBody()
  .color({ bg: designColors.backgroundNeutralSecondary })
  .flex({ alignItems: 'center' })
  .select('svg', style().spacing({ outerRight: space.xs }))
  .round(px2rem(6))
  .spacing({ inner: space.xs, outerTop: space.s })
  .element()

interface BadgeProps {
  candidateProfile: api.request.Entity<api.candidateProfiles.Fields>
  reference: api.request.Entity<api.references.Fields>
}

const VerifiedStatus: React.FC<BadgeProps> = props => {
  if (props.reference.fields.is_verified)
    return (
      <VerifiedStatusContainer>
        <Verified /> The relation between the candidate and the reference has been verified by HiPeople.
      </VerifiedStatusContainer>
    )

  if (props.reference.fields.is_reviewed)
    return (
      <VerifiedStatusContainer>
        <NotVerified />{' '}
        {rejectReasonSentence(
          props.candidateProfile.fields.full_name,
          props.reference.fields.full_name,
          props.reference.fields.company_name,
          props.reference.fields.rejection_reason,
        )}
      </VerifiedStatusContainer>
    )

  return (
    <VerifiedStatusContainer>
      <Unreviewed /> Verification pending
    </VerifiedStatusContainer>
  )
}

const rejectReasonSentence = (
  candidateName: string,
  referenceName: string,
  companyName: string,
  reason: RejectionReason | '',
): string => {
  switch (reason) {
    case 'candidate_professional_profile_missing':
      return `Unfortunately, this relationship could not be verified as no professional network profile of ${candidateName} was given.`
    case 'reference_professional_profile_missing':
      return `Unfortunately, this relationship could not be verified as no professional network profile of ${referenceName} was given.`
    case 'both_professional_profile_missing':
      return `Unfortunately, this relationship could not be verified as no professional network profile of ${candidateName} and ${referenceName} was given.`
    case 'candidate_shared_company_missing':
      return `Unfortunately, this relationship could not be verified as ${candidateName}'s employment at ${companyName} could not be confirmed.`
    case 'reference_shared_company_missing':
      return `Unfortunately, this relationship could not be verified as ${referenceName}'s employment at ${companyName} could not be confirmed.`
    case 'both_shared_company_missing':
      return `Unfortunately, this relationship could not be verified as neither ${candidateName}'s nor ${referenceName}'s employment at ${companyName} could  be confirmed.`
    case 'candidate_shared_time_deviating':
      return `Unfortunately, this relationship could not be verified as ${candidateName} didn't work there in the given time span according to their professional network profile.`
    case 'reference_shared_time_deviating':
      return `Unfortunately, this relationship could not be verified as ${referenceName} didn't work there in the given time span according to their professional network profile.`
    case 'both_shared_time_deviating':
      return `Unfortunately, this relationship could not be verified as neither ${candidateName} nor ${referenceName} worked there in the given time span according to their professional network profiles.`
    case 'reference_refused_verification':
      return `Unfortunately, this relationship could not be verified as ${referenceName} refused the verification process.`
    default:
      return 'Unfortunately, this relationship could not be verified.'
  }
}
