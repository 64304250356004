import { listing } from 'api'
import { Fields as QuestionnaireAnswerFields } from 'api/questionnaire-answers'
import { list, renderOptions } from './listing'
import { apiPost, get, post, put } from './methods'
import { APIResponse, Entity } from './request'

export const RESOURCE = 'organizations'

export type PaidProductName = 'candidate-experience' | 'quality-of-hire' | 'talent-pool' | 'sales-pool'

export type PaidProducts = {
  [key in PaidProductName]?: { pending: boolean; paid_at: number }
}

export interface Fields {
  id: string
  name: string
  slug: string
  plan: 'self-trial' | 'self-trial-expired' | 'sales-trial' | 'normal'
  company_size: string
  paid_products: PaidProducts
  email_domain?: string
  logo_image_url: string
  privacy_policy_link: string
  candidate_quota_left?: number
}

export function empty(id: string): Entity<Fields> {
  return {
    id,
    resource: RESOURCE,
    fields: {
      id,
      name: '',
      slug: '',
      plan: 'normal',
      company_size: '',
      paid_products: {},
      email_domain: '',
      logo_image_url: '',
      privacy_policy_link: '',
    },
  }
}

export type Filters = {
  hiring_status: string
  department: string
  hiring_manager: string
  last_stage: string
  location: string
  // TODO: Rename this field in the API /filters endpoint (recruiter -> recruiter_email)
  recruiter_email: string
}

export type FilterOptions = {
  recruiter?: string[]
  department?: string[]
  location?: string[]
  hiring_manager?: string[]
  last_stage?: string[]
  hiring_status?: string[]
}

export async function load(skip: number): Promise<APIResponse<Entity<Fields>[]>> {
  return get<Entity<Fields>[]>(`organizations?limit=500&skip=` + skip)
}
export async function isEmailDomainFree(email: string): Promise<APIResponse<Entity<Fields>>> {
  return get<Entity<Fields>>(`organizations/check_email_domain?email=${email}`)
}

export function updateName(id: string, name: string): Promise<APIResponse<Entity<Fields>>> {
  return put<Entity<Fields>>(`organizations/${id}/name`, {
    name,
  })
}

export function updateSlug(id: string, slug: string): Promise<APIResponse<Entity<Fields>>> {
  return put<Entity<Fields>>(`organizations/${id}/slug`, {
    slug,
  })
}

export function updateImage(id: string, file: File): Promise<APIResponse<Entity<Fields>>> {
  const formData = new FormData()
  formData.append('file', file)

  return put<Entity<Fields>>(`organizations/${id}/image`, {}, formData)
}

export function updatePrivacyPolicyLink(id: string, privacy_policy_link: string): Promise<APIResponse<Entity<Fields>>> {
  return put<Entity<Fields>>(`organizations/${id}/privacy_policy_link`, {
    privacy_policy_link,
  })
}

export function requestAQuote(id: string): Promise<APIResponse<Entity<Fields>>> {
  return put<Entity<Fields>>(`organizations/${id}/request_a_quote`, {})
}

export async function requestProductUpgrade(
  id: string,
  productName: PaidProductName,
): Promise<APIResponse<Entity<Fields>>> {
  return put<Entity<Fields>>(`organizations/${id}/request_product_upgrade`, {
    product_name: productName,
  })
}

export function simpleATSImport(id: string, url: string): Promise<APIResponse<Entity<Fields>>> {
  return put<Entity<Fields>>(`organizations/${id}/open_job_roles/simple_import`, { url })
}

interface AggregateFields {
  questions: {
    question_id: string
    average_overall: number
    average_this_month: number
  }[]
  nps_by_month: {
    year: number
    month: number
    day: number
    nps: number
    nps_score: number
  }[]
  nps_overall: number
  nps_score_overall: number
  questionnaires_sent: number
  questionnaires_submitted: number
  questionnaires_opened: number
}

function genListingFilters(filters: Filters, startDate?: Date, endDate?: Date) {
  const listingFilters: listing.Filter[] = []
  for (const [field, value] of Object.entries(filters || {})) {
    // TODO: we shouldn't use the string "all" to indicate the absense of filter, just incase
    // a user has an team named "all", etc.
    // Maybe the Filter values could be optional.
    if (value === 'all') continue
    listingFilters.push(listing.Eq(field, value))
  }
  if (startDate && endDate) {
    listingFilters.push(listing.Empty('date_start', startDate.valueOf() * 10e5))
    listingFilters.push(listing.Empty('date_end', endDate.valueOf() * 10e5))
  }
  return listingFilters
}

export function getCandidateExperienceSummary(
  id: string,
  filters: Filters,
  startDate?: Date,
  endDate?: Date,
): Promise<APIResponse<undefined, AggregateFields>> {
  const options = {
    filters: genListingFilters(filters, startDate, endDate),
  }
  const query = renderOptions(options)
  return get(`organizations/${id}/candidateexperiencesubmissions?${query}`)
}

export function getCandidateExperienceTextResponse(
  id: string,
  questionId: string,
  filters: Filters,
  startDate?: Date,
  endDate?: Date,
): Promise<APIResponse<Entity<QuestionnaireAnswerFields>[]>> {
  return list(`organizations/${id}/candidateexperiencesubmissions/${questionId}`, {
    filters: [listing.Neq('text_input', ''), ...genListingFilters(filters, startDate, endDate)],
    limit: 500,
  })
}

export function getCandidateExperienceTextResponseHighlights(
  id: string,
  questionId: string,
  filters: Filters,
  startDate?: Date,
  endDate?: Date,
): Promise<APIResponse<Entity<QuestionnaireAnswerFields>[]>> {
  return list<QuestionnaireAnswerFields>(`organizations/${id}/candidateexperiencesubmissions/${questionId}`, {
    filters: [listing.Neq('text_input', ''), ...genListingFilters(filters, startDate, endDate)],
    limit: 3,
  })
}

export function getCandidateExperienceFilters(id: string): Promise<APIResponse<{}, FilterOptions>> {
  return get(`organizations/${id}/candidateexperiencesubmissions/filters`)
}

export function create(
  marketingConsent: boolean,
  acceptedTermsAndConditions: boolean,
  companyName: string,
  companySize: string,
  workEmail?: string,
  password?: string,
  fullName?: string,
  googleCode?: string,
  additionalInfo?: {
    jobTitle: string
    phoneNumber: string
    referredBy?: string
    interestedIn?: string
    useCase?: string
  },
): Promise<APIResponse<Entity<QuestionnaireAnswerFields>[]>> {
  return apiPost(`organizations`, {
    organization_name: companyName,
    company_size: companySize,
    user: {
      name: fullName,
      email: workEmail,
      password: password,
    },
    agree_tcs: acceptedTermsAndConditions,
    agree_updates: marketingConsent,
    google_code: googleCode,

    send_to_hubspot: !!additionalInfo,
    job_title: additionalInfo?.jobTitle,
    phone_number: additionalInfo?.phoneNumber,
    referred_by: additionalInfo?.referredBy,
    interested_in: additionalInfo?.interestedIn,
    use_case: additionalInfo?.useCase,
  })
}

export type CandidateExperienceHiringStatus = 'hired' | 'rejected'

interface CandidateExperienceLinkFields {
  link: string
}
export function createCandidateExperienceSubmissionLink(
  id: string,
  hiringStatus: CandidateExperienceHiringStatus,
  email: string,
): Promise<APIResponse<Entity<CandidateExperienceLinkFields>>> {
  return post(`organizations/${id}/candidateexperiencesubmissions`, {
    hiring_status: hiringStatus,
    email,
  })
}
