import React, { useEffect } from 'react'

interface Props {
  route: string
}

const Component = (props: Props) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [props.route])

  return <></>
}

export default Component
Component.displayName = 'ScrollToTop'
