import { breakpoints, fr, px2rem, style } from 'core'
import React from 'react'
import { designSystemColors } from '../../core/design-system/colors'
import { text } from '../../core/design-system/text'

const HeroGridStyle = style()
  .color({ bg: designSystemColors.backgroundNeutralPrimary })
  .grid({ columns: [fr(1)] })
  .spacing({ gap: px2rem(70), inner: px2rem(86), rows: px2rem(32) })
  .screen(breakpoints.l, style().grid({ columns: [fr(1), fr(1)] }))
  .element()

const LeftContent = style()
  .flex({
    direction: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  })
  .size({ minWidth: '25ch' })
  .spacing({ gap: px2rem(20) })
  .element()
const RightContent = style()
  .flex({
    direction: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  })
  .element()

const Heading = text.cardHeader().element()
const SubHeading = text.bodyText().element()

const Image = style()
  .cond(
    ({ noBorder }: { noBorder: boolean }) => !noBorder,
    style().border({
      around: '1px solid',
      color: designSystemColors.borderDefault,
      radius: px2rem(8),
    }),
  )
  .screen(breakpoints.m, style().size({ width: '100%', minWidth: px2rem(360) }))
  .size({ width: '100%' })
  .element('img')

interface HeroGridProps {
  heading: string
  subheading: React.ReactNode
  image?: { src: string; altText: string; noBorder?: boolean }
  children?: React.ReactNode
}

const HeroGrid: React.FC<HeroGridProps> = props => {
  return (
    <HeroGridStyle>
      <LeftContent>
        <Heading>{props.heading}</Heading>
        <SubHeading>{props.subheading}</SubHeading>
        {props.children}
      </LeftContent>
      <RightContent>
        {props.image && <Image src={props.image.src} alt={props.image.altText} noBorder={props.image.noBorder} />}
      </RightContent>
    </HeroGridStyle>
  )
}

export default HeroGrid
