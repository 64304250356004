import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as api from '../../api'
import { AppThunk } from '../../core/store'
import { add as notify } from '../notifications'
import { addEntities } from '../resources'

const name = 'org-management'

interface State {
  loading: boolean
  orgs: string[]
  users: { [orgid: string]: string[] }
}

const initialState: State = {
  loading: false,
  orgs: [],
  users: {},
}

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setOrgs(state, action: PayloadAction<string[]>) {
      state.orgs = action.payload
    },
    addOrgs(state, action: PayloadAction<string[]>) {
      state.orgs = state.orgs.concat(action.payload)
    },
  },
})

export const { setLoading, addOrgs } = slice.actions

export default slice.reducer

export const loadOrgs = (): AppThunk => async dispatch => {
  for (let i = 0; ; ) {
    dispatch(setLoading(true))

    const [response, errors] = await api.orgs.load(i)

    dispatch(setLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(addEntities(response))
      dispatch(addOrgs(response.result.map(row => row.id)))
      i += response?.result.length
    }

    if (!response?.list?.has_more) {
      return true
    }
  }
}
