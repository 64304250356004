import React from 'react'
import classes from './ToggleSwitch.module.scss'

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  on: boolean
  setOn: (on: boolean) => void
  disabled?: boolean
  name?: string
}

export const ToggleSwitch = ({ on, setOn, disabled, name, ...props }: Props) => {
  return (
    <button
      className={classes.button + ' toggle-btn'}
      onClick={() => !disabled && setOn(!on)}
      aria-label={name}
      aria-checked={on}
      role="switch"
      name={name}
      disabled={disabled}
      {...props}
    >
      <div className={`${classes.track} ${on ? classes.on : ' '}`}>
        <div className={classes.handle} />
      </div>
    </button>
  )
}
