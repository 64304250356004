import {
  ReferenceCheckDetails,
  ReferenceFeedbackDetailsModalProps,
} from 'App/RoleCreation/ReferenceFeedbackDetailsModal'
import { Container, Description, Title } from 'App/RoleCreation/Shared'
import React from 'react'

export const ReferenceCheckDetailsPage: React.FC<ReferenceFeedbackDetailsModalProps> = props => {
  return (
    <Container>
      <Title data-testid="role-creation-access-title">{'Reference Check Details'}</Title>
      <Description>{'Add the references you want to collect for this job'}</Description>
      <ReferenceCheckDetails
        back={props.back}
        initialValues={props.initialValues}
        onSubmit={props.onSubmit}
        minRequiredReferenceCount={props.minRequiredReferenceCount}
        quickFlowButtons={true}
      />
    </Container>
  )
}
