import QuestionList from 'App/ExperienceFeedback/QuestionList'
import Shell from 'App/ExperienceFeedback/Shell'
import TabSelector from 'App/ExperienceFeedback/TabSelector'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { useMount } from 'react-use'
import * as selectors from 'selectors'
import { RootState } from 'store'
import * as modulesSlice from 'store/modules'

interface QuestionsProps {}

const Questions: React.FC<QuestionsProps> = () => {
  const { url } = useRouteMatch()
  const dispatch = useDispatch()

  const questions = useSelector((state: RootState) => selectors.candidateExperience.findTextQuestionScores(state))

  useMount(() => {
    if (questions.length) return
    dispatch(modulesSlice.loadBySlug('candidate-experience'))
  })

  return (
    <Shell>
      <TabSelector content />
      <QuestionList
        question={questions.map(question => ({
          link: `${url}/${question.id}`,
          copy: question.copy,
        }))}
      />
    </Shell>
  )
}

export default Questions
