import { px2rem, space, style } from 'core'
import { text } from 'core/design-system/text'
import React from 'react'
import { DECIMAL_PRECISION_BENCHMARKS } from '.'

const Container = style()
  .grid({ columns: [space.auto, space.auto] })
  .size({ height: px2rem(16) })
  .spacing({
    columns: px2rem(4),
    outer: space.auto,
  })
  .element()

const Overall = text
  .bodyEmphasis()
  .spacing({ outerTop: space.auto })
  .sans({ height: space.auto })
  .spacing({ outer: space.auto })
  .element()

const OutOf = text.secondaryBodyInteractive().spacing({ outerTop: space.auto }).element()

interface Props {
  overall: number | null
  outOf?: number
}

const OverallScore: React.FC<Props> = ({ overall, outOf }) => {
  if (!overall) return <Overall>—</Overall>

  return (
    <Container>
      <Overall>{overall.toFixed(DECIMAL_PRECISION_BENCHMARKS)}</Overall>
      <OutOf>/ {outOf || 5}</OutOf>
    </Container>
  )
}

export default OverallScore
