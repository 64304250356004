import { ExperienceSummaryScore } from 'App/ExperienceFeedback/NumericQuestionSummary'
import { interpolateVariables } from 'core/text'
import {
  differenceInCalendarDays,
  endOfQuarter,
  format,
  isSameDay,
  startOfQuarter,
  startOfWeek,
  subQuarters,
} from 'date-fns'
import * as selectors from 'selectors'
import { RootState } from 'store'
import { DateRangeOption, OverTimeScores } from '../App/ExperienceFeedback'

export function findNumericQuestionScores(state: RootState): ExperienceSummaryScore[] {
  const orgId = selectors.orgs.currentId(state)
  const org = selectors.orgs.getById(state, orgId)

  const scores = state.candidateExperience.numericQuestions
  if (!scores) return []

  return scores.map(score => ({
    heading: interpolateVariables(selectors.questions.findById(state, score.question_id)?.fields.copy.heading || '', {
      'ORGANIZATION.NAME': org.fields.name,
    }),
    emoji: selectors.questions.findById(state, score.question_id)?.fields.copy?.dashboard?.emoji,
    emojiBgColor: selectors.questions.findById(state, score.question_id)?.fields.copy?.dashboard?.emojiBgColor,
    title: selectors.questions.findById(state, score.question_id)?.fields.copy?.dashboard?.title,
    overallScore: score.average_this_month ? candidateExperienceScale(score.average_this_month) : null,
    trendScore: score.average_overall ? candidateExperienceScale(score.average_overall) : null,
  }))
}

export function findTextQuestionScores(state: RootState): { copy: string; id: string; date: number }[] {
  const orgId = selectors.orgs.currentId(state)
  const org = selectors.orgs.getById(state, orgId)

  const questions = selectors.questions.findAllQuestionsBySlug(state, 'candidate-experience')
  if (!questions) return []

  return questions
    .filter(q => q && q?.fields.response_type === 'text-input')
    .map(q => ({
      copy: interpolateVariables(q?.fields.copy.heading || '', {
        'ORGANIZATION.NAME': org.fields.name,
      }),
      id: q?.id || '',
      date: q?.fields.updated_at || 0,
    }))
}

export function findScoresByMonth(state: RootState, scoresToDisplay?: number): OverTimeScores {
  const response = [...(state.candidateExperience.npsByMonth || [])].reverse()
  // defaults to show past 12 months including current month e.g Dec > Nov
  const AMOUNT_OF_SCORES_TO_DISPLAY = scoresToDisplay || 12

  const now = new Date()
  const start = new Date(now.getFullYear(), now.getMonth(), 1) // 1st Jan

  const items: { label: string; score: number | null }[] = []
  for (let i = 0; i < AMOUNT_OF_SCORES_TO_DISPLAY; i++) {
    const date = new Date(start.getTime())
    date.setMonth(start.getMonth() - i)

    const resp = response?.find(
      item =>
        // plus 1 because javascript indexes months at 0, but our api indexes at 1
        item.month === date.getMonth() + 1 && item.year === date.getFullYear(),
    )

    items.push({
      label: date.toLocaleDateString('default', { month: 'short' }),
      score: resp?.nps ?? null,
    })
  }

  return items.reverse()
}

export function findScoresByDay(
  state: RootState,
  startDate: Date,
  endDate: Date,
  dateRangeOption: DateRangeOption,
): OverTimeScores {
  const response = [...(state.candidateExperience.npsByMonth || [])].reverse()

  const items: OverTimeScores = []
  // loop iterates through every day between start date and end date
  for (
    let currentDate = new Date(startDate);
    currentDate <= new Date(endDate);
    currentDate.setDate(currentDate.getDate() + 1)
  ) {
    const [currentYear, currentMonth, currentDay] = [
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      currentDate.getDate(),
    ]
    // check if there is a response at the current date
    const hasResponseAtCurrentDate = response.some(
      item => currentYear === item.year && currentMonth === item.month && currentDay === item.day,
    )
    // get value for score at the current date
    const scoreAtCurrentDate = hasResponseAtCurrentDate
      ? response.find(item => currentYear === item.year && currentMonth === item.month && currentDay === item.day)
      : null

    items.push({
      label: getDateLabel(dateRangeOption || 'All time', currentDate),
      score: scoreAtCurrentDate?.nps || null,
      hiddenUntilHovered:
        (dateRangeOption === 'Last 30 days' ||
          (dateRangeOption === 'This month' && differenceInCalendarDays(endDate, startDate) > 21)) &&
        !isSameDay(startOfWeek(currentDate, { weekStartsOn: 1 }), currentDate),
    })
  }
  return items
}

export function findScoresByQuarter(
  state: RootState,
  startDate: Date,
  endDate: Date,
  dateRangeOption: DateRangeOption,
): OverTimeScores {
  const response = [...(state.candidateExperience.npsByMonth || [])].reverse()

  const items: OverTimeScores = []
  // loop iterates through every month between start date and end date
  for (
    let currentDate = new Date(startDate);
    currentDate <= new Date(endDate);
    currentDate.setMonth(currentDate.getMonth() + 1)
  ) {
    // check if there is a response at the current date
    const hasResponseAtCurrentDate = response.some(item => currentDate.getMonth() + 1 === item.month)
    // get value for score at the current month
    const scoreAtCurrentDate = hasResponseAtCurrentDate
      ? response.find(item => currentDate.getMonth() + 1 === item.month)
      : null

    items.push({
      label: getDateLabel(dateRangeOption || 'All time', currentDate),
      score: scoreAtCurrentDate?.nps || null,
    })
  }
  return items
}

// gets the label for the bars in the score over time card
function getDateLabel(dateRangeOption: DateRangeOption, currentDate: Date): string {
  switch (dateRangeOption) {
    case 'This week':
      return format(currentDate, 'd.M')
    case 'Last 7 days':
      return format(currentDate, 'd.M')
    case 'This month':
      return isSameDay(startOfWeek(currentDate, { weekStartsOn: 1 }), currentDate)
        ? format(currentDate, 'd.M')
        : format(currentDate, 'd')
    case 'Last 30 days':
      return isSameDay(startOfWeek(currentDate, { weekStartsOn: 1 }), currentDate)
        ? format(currentDate, 'd.M')
        : format(currentDate, 'd')
    case 'This quarter':
      return currentDate.toLocaleDateString('default', { month: 'short' })
    case 'Last quarter':
      return currentDate.toLocaleDateString('default', { month: 'short' })
    default:
      return format(currentDate, 'd.M')
  }
}

export function getLastQuarterDateRange(currentDate: Date) {
  const currentDateMinusAQuarter = subQuarters(currentDate, 1)
  const startOfLastQuarter = startOfQuarter(currentDateMinusAQuarter)
  const endOfLastQuarter = endOfQuarter(currentDateMinusAQuarter)

  return {
    startDate: startOfLastQuarter,
    endDate: endOfLastQuarter,
  }
}

function candidateExperienceScale(value: number): number {
  const ORIGINAL_MIN = 0
  const ORIGINAL_MAX = 10
  const NEW_MIN = 0
  const NEW_MAX = 7

  return selectors.scale(value, ORIGINAL_MIN, ORIGINAL_MAX, NEW_MIN, NEW_MAX)
}
