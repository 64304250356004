import { Fields, RESOURCE } from 'api/library-items'
import { RootState } from 'store'
import lookup from './lookup'

export function findById(state: RootState, id: string) {
  return lookup<Fields>(state, RESOURCE, id)
}

export function allIDs(state: RootState) {
  return Object.values(state.resources)
    .filter(r => r.resource === RESOURCE)
    .map(r => r.id)
}
