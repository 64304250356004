import { designSystemColors } from 'core/design-system/colors'
import React, { ReactNode } from 'react'
import { colors, px2rem, scale, size, space, style, weight } from '../../core'
import { Breadcrumb } from '../DashboardLayout/Breadcrumbs'
import Breadcrumbs from './Breadcrumbs'
import Details, { Label, Row, RowIcon } from './Details'
import Header from './Header'

export { Header }

export { Details, Label, Row, RowIcon }

export const Blocks = style()
  .flex({ direction: 'column' })
  .spacing({ gap: px2rem(16) })
  .element()

const Container = style()
  .sans({ size: scale.s })
  .color({ bg: colors.white, fg: colors.midGray })
  .shadow(0.1)
  .round(size.xs)
  .element()

const Content = style()
  .cond(({ spacing }: { spacing: boolean }) => spacing, style().spacing({ inner: px2rem(24) }))
  .element()

const Title = style()
  .sans({ size: scale.s, weight: weight.normal })
  .spacing({ inner: [space.s, space.s] })
  .border({ bottom: '1px solid', color: designSystemColors.borderDefault })
  .element()

interface Props {
  id?: string
  title?: string | React.ReactNode
  subTitle?: ReactNode
  breadcrumbs?: Breadcrumb[]
  children: React.ReactNode
  noSpacing?: boolean
  testId?: string
}

const Component = (props: Props) => {
  // render the Header, if we find it, outside the padded container
  const [header, children] = pluck(
    React.Children.toArray(props.children),
    e => React.isValidElement(e) && `${e.type}` === `${Header}`,
  )

  return (
    <section id={props.id || ''} data-testid={props.testId}>
      <Container>
        {props.title && !props.breadcrumbs ? (
          <Title className="data-hj-suppress">
            {props.title}&nbsp; &nbsp; {props.subTitle}
          </Title>
        ) : null}
        {props.breadcrumbs ? <Breadcrumbs content={props.breadcrumbs} /> : null}
        {header}
        <Content spacing={`${!props.noSpacing}`}>{children}</Content>
      </Container>
    </section>
  )
}

export default Component
Component.displayName = 'Block'

const pluck = <T,>(arr: Array<T>, check: (v: T) => boolean): [T, Array<T>] => {
  const index = arr.findIndex(check)
  const item = arr[index]
  if (index > -1) {
    arr.splice(index, 1)
  }
  return [item, arr]
}
