import { PopoverContent } from '@common/components'
import { Button, Separator } from 'components/PopupV2'
import React, { MutableRefObject } from 'react'

interface ProfileMenuProps {
  isMenuOpen: boolean
  setMenuOpen: (open: boolean) => void
  onClickSignout: () => void
  profileSettingsTracking?: () => void
  activatorRef?: MutableRefObject<null | HTMLElement>
  userId: string
}

const ProfileMenu: React.FC<ProfileMenuProps> = ({
  onClickSignout,
  profileSettingsTracking,

  userId,
}) => {
  const profileMenuItems = [
    {
      element: 'button',
      label: 'Your profile',
      icon: 'cog',
      link: `/settings/accounts/${userId}`,
      ariaLabel: 'your profile settings',
      onClick: profileSettingsTracking,
    },
    {
      element: 'button',
      label: 'Log out',
      icon: 'sign-out',
      ariaLabel: 'Log out',
      onClick: onClickSignout,
    },
  ]

  return (
    <PopoverContent>
      <div style={{ padding: '0.5rem 0' }}>
        {profileMenuItems.map(item =>
          item.element === 'seperator' ? (
            <Separator key={item.label} />
          ) : (
            <li key={item.label} style={{ listStyle: 'none' }}>
              <Button icon={item.icon || 'cog'} link={item.link} aria-label={item.ariaLabel} onClick={item.onClick}>
                {item.label}
              </Button>
            </li>
          ),
        )}
      </div>
    </PopoverContent>
  )
}

export default ProfileMenu
