import { map } from './css'
import Rows from './rows'

export interface IOptions {
  column?: string | string[]
  columns?: string | string[]
  inner?: string | string[]
  innerTop?: string
  innerRight?: string
  innerBottom?: string
  innerLeft?: string
  outer?: string | string[]
  outerTop?: string
  outerRight?: string
  outerBottom?: string
  outerLeft?: string
  left?: string | string[]
  right?: string | string[]
  top?: string | string[]
  bottom?: string | string[]
  row?: string | string[]
  rows?: string | string[]
  around?: string | string[]
  gap?: string
}

export default function spacing(rows: Rows, options: IOptions) {
  map(rows, options, {
    column: 'gridColumnGap',
    columns: 'gridColumnGap',
    inner: 'padding',
    innerBottom: 'paddingBottom',
    innerLeft: 'paddingLeft',
    innerRight: 'paddingRight',
    innerTop: 'paddingTop',
    outer: 'margin',
    around: 'margin',
    outerBottom: 'marginBottom',
    outerLeft: 'marginLeft',
    outerRight: 'marginRight',
    outerTop: 'marginTop',
    top: 'marginTop',
    right: 'marginRight',
    bottom: 'marginBottom',
    left: 'marginLeft',
    row: 'gridRowGap',
    rows: 'gridRowGap',
    gap: 'gap',
  })
}
