import * as api from '../api'
import { RootState } from '../store'
import lookup from './lookup'
import { getById as getOrgById } from './orgs'

function findById(state: RootState, id: string): api.request.Entity<api.samlsso.Fields> | undefined {
  return lookup(state, api.samlsso.RESOURCE, id)
}

export function findByOrgId(state: RootState, orgId: string): api.request.Entity<api.samlsso.Fields> | undefined {
  const org = getOrgById(state, orgId)

  const samlRel = (org.relationships || []).find(r => r.resource === api.samlsso.RESOURCE)

  if (!samlRel) return

  return findById(state, samlRel.id)
}
