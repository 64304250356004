import Button, { ButtonContent } from 'components/Button/ButtonV2'
import { Icon } from 'components/Icons'
import { px2rem, rem, size, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React, { useState } from 'react'

export function getBgColor(bgColor: BgColor) {
  switch (bgColor) {
    case 'purple': {
      return '#DFC2E9'
    }
    case 'orange': {
      return '#FFCD9C'
    }
    case 'green': {
      return '#96E2B6'
    }
    case 'blue': {
      return '#D0DDF4'
    }
  }
}

export type BgColor = 'purple' | 'orange' | 'green' | 'blue'

const CardContainer = style()
  .size({
    width: px2rem(256),
    height: px2rem(320),
  })
  .grid({ rows: [px2rem(96), size.auto, size.auto] })
  .color({ bg: designSystemColors.backgroundNeutralPrimary })
  .border({
    around: '1px solid',
    radius: px2rem(8),
    color: designSystemColors.borderDefault,
  })
  .element('article')

const CardHeader = style()
  .center()
  .sans({ size: rem(3) })
  .color({ fg: designSystemColors.white })
  .with(({ bgColor }: { bgColor: BgColor }) => style().color({ bg: bgColor }))
  .border({
    radius: [px2rem(8), px2rem(8), px2rem(0), px2rem(0)],
  })
  .element()

const CardContent = style()
  .flex({ direction: 'column' })
  .size({ minHeight: px2rem(112) })
  .spacing({ inner: px2rem(16), gap: px2rem(12) })
  .element()
const CardTitle = text.cardHeader().element('h3')
const CardText = text.smallBody().element('p')

const CardFooter = style()
  .flex({
    alignItems: 'stretch',
    justifyContent: 'flex-end',
    direction: 'column',
  })
  .spacing({
    inner: [px2rem(0), px2rem(16), px2rem(16)],
  })
  .element()

const ButtonContainer = style()
  .flex()
  .spacing({ gap: px2rem(12) })
  .element()

const FooterMessageStyle = style()
  .sans({ align: 'center' })
  .spacing({ outer: px2rem(8) })
  .element()

const FooterMessage = text.smallBody().color({ fg: designSystemColors.typographySecondary }).element('p')

const FooterMessageSuccessLink = text
  .smallBody()
  .text({ underline: true, nodecoration: true })
  .color({ fg: designSystemColors.uiStatusSuccess })
  .spacing({ outerLeft: px2rem(4) })
  .element('a')

interface ModuleCardProps {
  title: string
  text: string
  icon: string
  bgColor: BgColor
  isEnabledByAdmin?: boolean
  isCardAdded: boolean
  toggleAddState: () => void
  hasDetails?: boolean
  onClickDetailsButton?: () => void
  isComingSoon?: boolean
  testId?: string
  showTalkToSales?: boolean
}

export const ModuleCard: React.FC<ModuleCardProps> = ({
  title,
  text,
  icon,
  bgColor,
  isEnabledByAdmin,
  hasDetails,
  onClickDetailsButton,
  isCardAdded,
  toggleAddState,
  isComingSoon,
  testId,
  showTalkToSales,
}) => {
  const [isHovering, setIsHovering] = useState(false)

  return (
    <CardContainer>
      <CardHeader bgColor={getBgColor(bgColor)}>
        <Icon name={icon} />
      </CardHeader>
      <CardContent>
        <CardTitle>{title}</CardTitle>
        <CardText>{text}</CardText>
      </CardContent>
      <CardFooter>
        {showTalkToSales ? (
          <FooterMessageStyle>
            <FooterMessage>
              <Icon name={'lock'} />
              <FooterMessageSuccessLink
                href={'https://www.hipeople.io/book-a-demo'}
                target={'_blank'}
              >{`Talk to sales`}</FooterMessageSuccessLink>
            </FooterMessage>
          </FooterMessageStyle>
        ) : (
          isEnabledByAdmin && (
            <FooterMessageStyle>
              <FooterMessage>
                <Icon name={'lock'} />
                {` ${isCardAdded ? 'Enabled' : 'Disabled'} by org admin`}
              </FooterMessage>
            </FooterMessageStyle>
          )
        )}
        <ButtonContainer>
          {isEnabledByAdmin || showTalkToSales ? (
            <>
              <Button buttonType="primary" isFullWidth disabled>
                <ButtonContent
                  icon={{
                    name: isCardAdded ? 'check' : 'times',
                    ariaLabel: isCardAdded ? 'Added to job' : 'Not added to job',
                  }}
                >
                  {isCardAdded ? 'Added to job' : 'Not added to job'}
                </ButtonContent>
              </Button>
            </>
          ) : isCardAdded ? (
            <>
              {isHovering ? (
                <Button
                  buttonType={'destroy'}
                  isFullWidth
                  onClick={() => toggleAddState()}
                  onMouseEnter={() => setIsHovering(true)}
                  onMouseLeave={() => setIsHovering(false)}
                >
                  <ButtonContent icon={{ name: 'trash', ariaLabel: 'remove from job' }}>{'Remove?'}</ButtonContent>
                </Button>
              ) : (
                <Button
                  buttonType={'primary'}
                  isFullWidth
                  onClick={() => toggleAddState()}
                  onMouseEnter={() => setIsHovering(true)}
                  onMouseLeave={() => setIsHovering(false)}
                >
                  <ButtonContent icon={{ name: 'check', ariaLabel: 'added to job' }}>{'Added to job'}</ButtonContent>
                </Button>
              )}
              {hasDetails && (
                <Button buttonType={'tertiary'} onClick={onClickDetailsButton}>
                  <ButtonContent icon={{ name: 'cog', ariaLabel: 'open details' }} />
                </Button>
              )}
            </>
          ) : isComingSoon ? (
            <>
              <Button buttonType="primary" isFullWidth disabled>
                Coming soon
              </Button>
            </>
          ) : (
            <>
              <Button
                buttonType="tertiary"
                isFullWidth
                onClick={() => toggleAddState()}
                testId={`module-card-${testId}-add-to-role-button`}
              >
                {'Add to job'}
              </Button>
            </>
          )}
        </ButtonContainer>
      </CardFooter>
    </CardContainer>
  )
}
