import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Block, { Blocks } from '../../../components/Block'
import Button from '../../../components/Button/ButtonV1'
import { useConfirm } from '../../../components/Confirm'
import DashboardLayout, { Content, Header } from '../../../components/DashboardLayout'
import DropdownField, { Option } from '../../../components/DropdownField'
import { Button as PopupButton } from '../../../components/Popup'
import ToggleField from '../../../components/ToggleField'
import WarningBanner from '../../../components/WarningBanner'
import { space, style } from '../../../core'
import * as selectors from '../../../selectors'
import { RootState } from '../../../store'
import { logout } from '../../../store/sessions'
import {
  deleteUser,
  impersonate,
  loadUserById,
  sendNewPassword,
  setIsOrgAdmin,
  setIsSuperUser,
} from '../../../store/settings/account'
import { updateUserPermissions } from '../../../store/settings/user-management'
import CanManageOrganization from '../../AccessLevel/CanManageOrganization'
import IsOwnAccount from '../../AccessLevel/IsOwnAccount'
import IsSuperUser from '../../AccessLevel/IsSuperUser'
import Account from '../Account'
import Password from '../Password'

const WarningContainer = style().spacing({ outerBottom: space.s }).element()
const Actions = style().grid().spacing({ rows: space.s }).element()

const NEW_PASSWORD_ACTION = 'new-password'
const IMPERSONATE_ACTION = 'impersonate'
const DELETE_ACTION = 'delete'
const MAKE_ORG_ADMIN_ACTION = 'make-org-admin'
const MAKE_SUPER_USER_ACTION = 'make-super_user'
const TAKE_ORG_ADMIN_ACTION = 'take-org-admin'
const TAKE_SUPER_USER_ACTION = 'take-super_user'

const Component = () => {
  const dispatch = useDispatch()
  const confirm = useConfirm()

  const { userid } = useParams<{ userid: string }>()
  const [action, setAction] = useState('')

  const [user, userPermissions, profile]: [
    ReturnType<typeof selectors.users.getById>,
    ReturnType<typeof selectors.userPermissions.getByUserId>,
    ReturnType<typeof selectors.profiles.getByUserId>,
  ] = useSelector((state: RootState) => {
    const user = selectors.users.getById(state, userid || '')
    const profile = selectors.profiles.getByUserId(state, userid || '')
    return [user, selectors.userPermissions.getByUserId(state, userid || ''), profile]
  })

  const breadcrumbs = [{ label: 'Settings' }, { url: `/settings/accounts/${userid}`, label: 'Account and Profile' }]

  useEffect(() => {
    if (!userid) return
    dispatch(loadUserById(userid))
  }, [userid])

  return (
    <DashboardLayout>
      <Header breadcrumbs={breadcrumbs}>
        <CanManageOrganization>
          {user?.fields.is_active ? (
            <PopupButton icon="user-minus" onClick={() => dispatch(onClickDelete)}>
              Delete
            </PopupButton>
          ) : null}
        </CanManageOrganization>
        <PopupButton icon="sign-out" onClick={() => dispatch(logout())}>
          Sign out
        </PopupButton>
      </Header>
      <Content>
        {user && !user.fields.is_active ? (
          <WarningContainer>
            <WarningBanner>
              This user has been deleted. You can still access their details, however, they will not be able to login in
              future.
            </WarningBanner>
          </WarningContainer>
        ) : null}
        <Blocks>
          <Account userid={userid} />
          <IsOwnAccount userId={userid || ''}>
            <Password />
          </IsOwnAccount>
          <IsSuperUser>
            <Block title="Back-Office">
              <ToggleField
                label="Can manage candidates from other orgs in order to provide
                  support"
                on={userPermissions?.fields.can_provide_support || false}
                onChange={() =>
                  dispatch(
                    updateUserPermissions(userid || '', {
                      can_provide_support: !userPermissions?.fields.can_provide_support,
                    }),
                  )
                }
              />
            </Block>
          </IsSuperUser>
          <IsSuperUser>
            <Block title="Administrative Actions">
              <Actions>
                <DropdownField placeholder="Choose an action" value={action} onChange={action => setAction(action)}>
                  <Option icon="key" value={NEW_PASSWORD_ACTION}>
                    Send New Password
                  </Option>
                  <Option icon="mask" value={IMPERSONATE_ACTION}>
                    Impersonate
                  </Option>
                  <Option icon="trash" value={DELETE_ACTION}>
                    Delete
                  </Option>
                  <Option icon="user-crown" value={MAKE_ORG_ADMIN_ACTION}>
                    Make Organization Admin
                  </Option>
                  <Option icon="star" value={MAKE_SUPER_USER_ACTION}>
                    Make Super User
                  </Option>
                </DropdownField>
                <Button primary onClick={performAction} disabled={action.length === 0}>
                  Continue
                </Button>
              </Actions>
            </Block>
          </IsSuperUser>
        </Blocks>
      </Content>
    </DashboardLayout>
  )

  async function onClickDelete() {
    if (!userid) return

    if (
      await confirm({
        title: `About to delete a user, continue?`,
        message: `**${profile?.fields.full_name || ''}** will be deleted and won't be able to access HiPeople.`,
        confirmLabel: 'Yes, delete user',
        requireInput: profile?.fields.full_name,
        requireInputLabel: 'Enter the full name of the user to confirm:',
        requireInputPlaceholder: 'Verify the full name of the user to delete',
        danger: true,
      })
    ) {
      dispatch(deleteUser(userid))
    }
  }

  function performAction() {
    if (!userid) return

    switch (action) {
      case NEW_PASSWORD_ACTION:
        dispatch(sendNewPassword(userid))
        break
      case IMPERSONATE_ACTION:
        dispatch(impersonate(userid))
        break
      case DELETE_ACTION:
        dispatch(deleteUser(userid))
        break
      case MAKE_ORG_ADMIN_ACTION:
        dispatch(setIsOrgAdmin(userid, true))
        break
      case TAKE_ORG_ADMIN_ACTION:
        dispatch(setIsOrgAdmin(userid, false))
        break
      case MAKE_SUPER_USER_ACTION:
        dispatch(setIsSuperUser(userid, true))
        break
      case TAKE_SUPER_USER_ACTION:
        dispatch(setIsSuperUser(userid, false))
        break
    }
  }
}

export default Component
Component.displayName = 'EditAccount'
