import { designSystemColors } from 'core/design-system/colors'
import React from 'react'
import { colors, pct, rem, scale, size, style, weight } from '../../core'
import { Icon } from '../Icons'

const Container = style()
  .grid({ columns: [rem(2), size.auto] })
  .element()

const IconColumn = style()
  .size({ width: rem(1.5), height: rem(1.5) })
  .center()
  .round(pct(100))
  .sans({ size: rem(0.75), weight: weight.normal })
  .border({ around: '1px solid' })
  .color({ fg: colors.lightGray, border: colors.lightGray })
  .cond(
    ({ active }: { active: boolean }) => active,
    style().text({ weight: weight.normal }).color({
      bg: colors.white,
      fg: colors.red,
      border: colors.red,
    }),
  )
  .cond(
    ({ done }: { done: boolean }) => done,
    style().color({
      bg: designSystemColors.typographyPrimaryAccent,
      fg: colors.white,
      border: designSystemColors.typographyPrimaryAccent,
    }),
  )
  .element()

const Content = style()
  .sans({ color: colors.gray, size: scale.s })
  .center({ vertical: true })
  .cond(
    ({ active }: { active: boolean }) => active,
    style().text({
      color: colors.black,
      weight: weight.normal,
    }),
  )
  .cursor('default')
  .element()

interface Props {
  children: React.ReactNode
  index: number
  done?: boolean
  active?: boolean
  onClick?: () => void
}

const TabStep = (props: Props) => {
  return (
    <Container onClick={props.onClick}>
      <IconColumn done={props.done} active={props.active}>
        {props.done ? <Icon name="check" /> : props.index}
      </IconColumn>
      <Content active={props.active}>{props.children}</Content>
    </Container>
  )
}

export default TabStep
