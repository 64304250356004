import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Filter } from 'api/listing'
import {
  candidateProfiles,
  candidates,
  listing,
  openjobroles,
  orgs,
  questionnaireSubmissions,
  references,
  users,
} from '../../api'
import { AppThunk } from '../../core/store'
import { add as notify } from '../notifications'
import { addEntities } from '../resources'

interface State {
  loading: boolean
  candidates: string[]
  totalCandidates: number
  hasMore: boolean
}

const name = candidates.RESOURCE
const initialState: State = {
  loading: false,
  candidates: [],
  totalCandidates: 0,
  hasMore: false,
}

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setCandidates(state, action: PayloadAction<string[]>) {
      state.candidates = action.payload
    },
    addCandidates(state, action: PayloadAction<string[]>) {
      state.candidates = Array.from(new Set(state.candidates.concat(action.payload)))
    },
    setTotalCandidates(state, action: PayloadAction<number>) {
      state.totalCandidates = action.payload
    },
    setHasMore(state, action: PayloadAction<boolean>) {
      state.hasMore = action.payload
    },
  },
})

export const { setIsLoading, setCandidates, addCandidates, setTotalCandidates, setHasMore } = slice.actions

export default slice.reducer

export const load =
  (query: string, skip?: number, filters?: Filter[]): AppThunk =>
  async dispatch => {
    dispatch(setIsLoading(true))

    const [response, errors] = await listing.list<candidates.Fields>(`candidates`, {
      limit: 20,
      filters: filters || [listing.True('is_active')],
      include: [candidateProfiles.RESOURCE, openjobroles.RESOURCE, references.RESOURCE, orgs.RESOURCE, users.RESOURCE],
      sort: [
        {
          field: 'created_at',
          order: listing.SortOrder.Desc,
        },
      ],
      skip: skip || 0,
      body: query ? { query } : undefined,
    })

    dispatch(setIsLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(addEntities(response))
      if (skip && skip > 0) {
        dispatch(addCandidates(response.result.map(row => row.fields.id)))
      } else {
        dispatch(setCandidates(response.result.map(row => row.fields.id)))
      }

      dispatch(setTotalCandidates(response.list?.total || 0))
      dispatch(setHasMore(response.list?.has_more || false))
    }
  }

export const loadByOrg =
  (query: string, orgId: string, skip?: number, sort?: listing.Options['sort']): AppThunk =>
  async dispatch => {
    dispatch(setIsLoading(true))

    const [response, errors] = await listing.list<candidates.Fields>(`/organizations/${orgId}/candidates`, {
      limit: 20,
      filters: [listing.True('is_active')],
      include: [
        candidateProfiles.RESOURCE,
        openjobroles.RESOURCE,
        references.RESOURCE,
        orgs.RESOURCE,
        users.RESOURCE,
        questionnaireSubmissions.RESOURCE,
      ],
      sort: sort?.length
        ? sort
        : [
            {
              field: 'created_at',
              order: listing.SortOrder.Desc,
            },
          ],
      skip: skip || 0,
      body: query ? { query } : undefined,
    })

    dispatch(setIsLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(addEntities(response))
      if (skip && skip > 0) {
        dispatch(addCandidates(response.result.map(row => row.fields.id)))
      } else {
        dispatch(setCandidates(response.result.map(row => row.fields.id)))
      }

      dispatch(setTotalCandidates(response.list?.total || 0))
      dispatch(setHasMore(response.list?.has_more || false))
    }
  }

export const loadUserCandidates =
  (
    query: string,
    userId: string,
    skip?: number,
    sort?: listing.Options['sort'],
    filters?: listing.Filter[],
  ): AppThunk =>
  async dispatch => {
    dispatch(setIsLoading(true))

    const [response, errors] = await listing.list<candidates.Fields>(`/users/${userId}/candidates`, {
      limit: 20,
      filters: filters ? filters : [listing.True('is_active')],
      include: [
        candidateProfiles.RESOURCE,
        openjobroles.RESOURCE,
        references.RESOURCE,
        orgs.RESOURCE,
        users.RESOURCE,
        questionnaireSubmissions.RESOURCE,
      ],
      sort: sort?.length
        ? sort
        : [
            {
              field: 'created_at',
              order: listing.SortOrder.Desc,
            },
          ],
      skip: skip || 0,
      body: query ? { query } : undefined,
    })

    dispatch(setIsLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(addEntities(response))
      if (skip && skip > 0) {
        dispatch(addCandidates(response.result.map(row => row.fields.id)))
      } else {
        dispatch(setCandidates(response.result.map(row => row.fields.id)))
      }

      dispatch(setTotalCandidates(response.list?.total || 0))
      dispatch(setHasMore(response.list?.has_more || false))
    }
  }

export const updateCandidateProfile =
  (...args: Parameters<typeof candidateProfiles.updateCandidateProfile>): AppThunk =>
  async dispatch => {
    dispatch(setIsLoading(true))
    try {
      const [response, errors] = await candidateProfiles.updateCandidateProfile(...args)

      if (errors) {
        errors.forEach(e => {
          dispatch(notify({ error: e.message }))
        })
        return false
      }

      if (response) {
        dispatch(addEntities(response))
        dispatch(
          notify({
            success: 'Candidate has been updated successfully 🎉',
          }),
        )
      }
    } catch (error) {
      // DO NOTHING
    }
    dispatch(setIsLoading(false))
  }
