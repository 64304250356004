import React from 'react'
import { Right } from '../../components/DashboardLayout'
import Layout from '../../components/Layout'

interface Props {
  children: JSX.Element | (JSX.Element | null | string)[] | string
}

const Component = ({ children }: Props) => {
  return (
    <Layout fill>
      <Right>{children}</Right>
    </Layout>
  )
}

export default Component
Component.displayName = 'Layout'
