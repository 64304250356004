import { Props as TrackingProps } from 'core/track/users'
import { hotjar } from 'react-hotjar'
import token from '../token'
import EventNames from './events'
import GroupKeys, { addAccountGroup } from './groups'
import mixpanel from './mp'

type PropertyValue = string | number | boolean

export default (eventName: EventNames, eventProps: { [name: string]: PropertyValue | PropertyValue[] }) => {
  const props = {
    ...eventProps,
    impersonating: token.impersonating(),
    temporarySession: token.isTemporary(),
  }

  if (!isEnabled()) return console.info('[track]', eventName, props)

  mixpanel.track(eventName, props)
}

export function incr(eventName: EventNames) {
  if (!isEnabled()) return console.info('[incr]', eventName)

  mixpanel.people.increment(eventName)
}

export function log(eventName: EventNames) {
  const first = {
    [`First ${eventName}`]: new Date().toISOString(),
  }

  const last = {
    [`Last ${eventName}`]: new Date().toISOString(),
  }

  if (!isEnabled()) return console.info('[log]', first, last)

  mixpanel.people.set_once(first)
  mixpanel.people.set(last)
  mixpanel.identify()
}

export function identify(values: TrackingProps) {
  if (!isEnabled()) return

  // Mixpanle identify
  mixpanel.identify(values.email)
  mixpanel.people.set_once({
    $email: values.email,
    ...values,
  })

  // Hotjar identify
  if (hotjar.initialized() && values.userId) {
    hotjar.identify(values.userId, { plan: values.organization_plan })
  }

  // Google Analytycs userId
  if (values.userId) {
    gtag('set', { user_id: values.userId })
  }

  if (values.organization) {
    addAccountGroup(values.organization)
  }
}

export function group<T>(key: GroupKeys, name: string, values: T) {
  if (!isEnabled()) return

  mixpanel.set_group(key, name)
  mixpanel.get_group(key, name).set(values as any)
}

export function reset() {
  if (!isEnabled()) return

  mixpanel.reset()
}

function isEnabled() {
  return import.meta.env.PROD && import.meta.env.VITE_MIXPANEL_TOKEN
}

export const ANONYMOUS_URL = {
  $current_url: 'ANONYMOUS_URL',
}

export function pageView() {
  if (!isEnabled()) return
}

export function googleAnalyticsCustomEvent(action: string, category: string, utmParams?: Record<string, string>) {
  gtag('event', action, {
    event_category: category,
    ...utmParams,
  })
}
