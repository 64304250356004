import { request } from '../api'
import store from '../core/store'
import { add as notify } from './notifications'
import { addEntities } from './resources'

export function handleResponse<T>(
  dispatch: typeof store.dispatch,
  response: request.APIResponse<request.Entity<T>>,
): boolean {
  const [result, errors] = response

  if (errors) {
    errors.forEach(e => {
      dispatch(notify({ error: e.message }))
    })
    return false
  }

  if (result) {
    dispatch(addEntities(result))
  }

  return true
}
