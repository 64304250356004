import React from 'react'
import { fr, size, space, style } from '../../core'
export { Column } from './Column'
export { Row } from './Row'

export const DEFAULT_SIZE = fr(1)

interface Props {
  children: JSX.Element | JSX.Element[] | string
  auto?: boolean
  columns?: string[]
  rows?: string[]
  fill?: boolean
  height?: string
  vertical?: boolean
  rowSpacing?: string
  columnSpacing?: string
  centerVertically?: boolean
}

const Container = style()
  .with((props: Props) =>
    style()
      .grid({
        columns: (props.columns || []).join(' '),
        rows: (props.rows || []).join(' '),
        align: props.centerVertically ? 'center' : 'stretch',
      })
      .spacing({
        columns: props.columnSpacing || space.none,
        rows: props.rowSpacing || space.none,
      })
      .size({ height: props.height || size.auto }),
  )
  .cond((props: Props) => props.fill || false, style().stretch())
  .element()

export const Component = (props: Props) => {
  let columns = props.columns || []
  let rows = props.rows || []

  if (props.auto && !props.vertical) {
    columns = (props.children as JSX.Element[]).map(c => c.props.width || c.props.size || DEFAULT_SIZE)
  }

  if (props.auto && props.vertical) {
    rows = (props.children as JSX.Element[]).map(c => c.props.height || c.props.size || DEFAULT_SIZE)
  }

  return (
    <Container {...props} columns={columns} rows={rows}>
      {props.children}
    </Container>
  )
}

export default Component
Component.displayName = 'Grid'
