import { text } from 'core/design-system/text'
import React from 'react'
import { space, style } from '../../core'

interface Props {
  waitingResponse?: boolean
  waitingReferences?: boolean
  skipped?: boolean
  leftBlank?: boolean
  noQualitative?: boolean
  noSpacing?: boolean
  notApplicableForCandidate?: boolean
}

const Container = style().element()

const MessageBlock = text.bodyText().set('fontStyle', 'italic').element('p')
const MessageBlockWithSpacing = style(MessageBlock)
  .spacing({ inner: [space.s, space.none] })
  .element('p')

const Component = (props: Props) => {
  return (
    <Container>
      <Content {...props} />
    </Container>
  )
}

export default Component
Component.displayName = 'EmptyAnswer'

function Content(props: Props) {
  const Message = props.noSpacing ? MessageBlock : MessageBlockWithSpacing

  if (props.notApplicableForCandidate) {
    return <Message>{'Question not applicable to the candidate as self-assessment'}</Message>
  }

  if (props.waitingReferences) {
    return <Message>Waiting for references to be invited.</Message>
  }

  if (props.noQualitative) {
    return <Message>No qualitative feedback provided.</Message>
  }

  if (props.waitingResponse) {
    return <Message>Waiting reference to submit feedback.</Message>
  }

  if (props.skipped) {
    return <Message>Reference skipped this question.</Message>
  }

  if (props.leftBlank) {
    return <Message>Reference left this question blank.</Message>
  }

  return <Message>No answer given.</Message>
}
