import ScoreBar from 'components/Scores/ScoreBar'
import { ch, px2rem, size, style, weight } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React from 'react'

const ScoreContainer = style()
  .flex({ alignItems: 'baseline', alignSelf: 'end' })
  .spacing({ gap: px2rem(12) })
  .element()

const OverallScore = style()
  .sans({ size: px2rem(53), align: 'center' })
  .element()

const SmallGrey = style()
  .sans({ size: px2rem(30), weight: weight.normal })
  .set('fontVariantNumeric', 'tabular-nums')
  .color({ fg: designSystemColors.typographySecondary })
  .element()

const Score = style().grid({ align: 'center' }).spacing({ row: size.xs }).element()

const ScoreBarContainer = style()
  .size({ height: px2rem(16) })
  .element()

const BarLabels = text.secondaryBodyText().flex({ justifyContent: 'space-between' }).element()
const BarLabel = text
  .secondaryBodyText()
  .size({ width: ch(4) })
  .with(({ align }: { align: 'left' | 'center' | 'right' }) => style().text({ align }))
  .element()

interface LargeScoreCardProps {
  score: number | null
  smallGreyText?: string
  maxScore?: number
  scorePrecision?: number
  scoreBarSegments?: number
  bipolar?: boolean
  labels?: boolean
  scorebar?: boolean
  children?: React.ReactNode
}

const LargeScoreCard: React.FC<LargeScoreCardProps> = ({
  score,
  smallGreyText,
  maxScore = 100,
  scorePrecision = 0,
  scoreBarSegments = 10,
  bipolar,
  labels,
  scorebar = true,
  children,
}) => {
  return (
    <Score>
      {children}
      <ScoreContainer>
        <OverallScore>{score ? score.toFixed(scorePrecision) : 'Na'}</OverallScore>
        {smallGreyText && <SmallGrey>{smallGreyText}</SmallGrey>}
      </ScoreContainer>
      {scorebar && (
        <ScoreBarContainer>
          <ScoreBar score={score || 0} maxDisplayedScore={maxScore} segments={scoreBarSegments} bipolar={bipolar} />
          {bipolar && labels && (
            <BarLabels>
              <BarLabel align="left">-{maxScore}</BarLabel>
              <BarLabel align="center">0</BarLabel>
              <BarLabel align="right">{maxScore}</BarLabel>
            </BarLabels>
          )}
        </ScoreBarContainer>
      )}
    </Score>
  )
}

export default LargeScoreCard
LargeScoreCard.displayName = 'LargeScore'
