import React from 'react'
import { breakpoints, scale, size, space, style } from '../../core'

const Container = style().element()

const Content = style()
  .size({ width: size.fill, height: size.fill })
  .spacing({ inner: [space.m, space.m] })
  .sans({ size: scale.s })
  .screen(breakpoints.mobile, style().spacing({ inner: [space.xs, space.l, space.xs, space.s] }))
  .element()

interface Props {
  children: React.ReactNode
}

const Component = (props: Props) => {
  return (
    <Container>
      <Content>{props.children}</Content>
    </Container>
  )
}

export default Component
Component.displayName = 'Content'
