import { px2rem, size, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React from 'react'
import LargeScoreCard from './LargeScoreCard'

const CandidateNPSStyle = style()
  .color({ bg: designSystemColors.backgroundNeutralPrimary })
  .border({
    around: `1px solid`,
    color: designSystemColors.borderDefault,
    radius: px2rem(8),
  })
  .spacing({
    inner: [px2rem(16), px2rem(16), px2rem(32), px2rem(16)],
    rows: px2rem(24),
  })
  .size({ height: size.fill })
  .grid()
  .element()

const Title = text.cardHeader().element()

interface CandidateNPSProps {
  score: number
}

const CandidateNPS: React.FC<CandidateNPSProps> = ({ score }) => {
  return (
    <CandidateNPSStyle>
      <Title>Experience score</Title>
      <LargeScoreCard score={score} smallGreyText={'/10'} maxScore={10} />
    </CandidateNPSStyle>
  )
}

export default CandidateNPS

interface CandidateNPSScoreProps {
  score: number
}

export const CandidateNPSScore: React.FC<CandidateNPSScoreProps> = ({ score }) => {
  return (
    <CandidateNPSStyle>
      <Title>Experience score (distribution)</Title>
      <LargeScoreCard score={score} maxScore={100} bipolar labels />
    </CandidateNPSStyle>
  )
}
