import { post } from './methods'
import { APIResponse, Entity } from './request'

export interface Fields {
  source_link: string
  presigned_link: string
}

export async function upload(file: File): Promise<APIResponse<Entity<Fields>[]>> {
  const formData = new FormData()
  formData.append('file', file)

  return post<Entity<Fields>[]>(`/images`, undefined, formData)
}
