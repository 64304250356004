import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FeedbackStyleType, findFeedbackStyleByReferenceId } from 'selectors/halo-score'
import {
  findByCandidateId,
  findNotSelfByCandidateId,
  findSameOriginByCandidateId,
  getReferenceStatus,
  ReferenceStatus,
} from 'selectors/references'
import { RootState } from 'store'
import { remove } from 'store/references'

export function useReferenceFeedbackStyles({ referenceIds }: { referenceIds: string[] }) {
  const feedbackStyles = useSelector((state: RootState) => {
    const feedbackStyles: Record<string, FeedbackStyleType | null> = {}
    for (const refId of referenceIds) {
      const status = findFeedbackStyleByReferenceId(state, refId)
      if (status) {
        feedbackStyles[refId] = status
      }
    }
    return feedbackStyles
  })
  return { feedbackStyles }
}

export function useReferences(query: { candidateId: string }) {
  const { candidateId } = query
  const selector = useCallback((state: RootState) => findByCandidateId(state, candidateId), [candidateId])
  const references = useSelector(selector)
  const dispatch = useDispatch()
  const deleteReference = useCallback((referenceId: string) => {
    dispatch(remove(referenceId))
  }, [])

  return { references, deleteReference }
}

export function useReferencesExceptSelf(query: { candidateId: string; includeStatus?: boolean }) {
  const { candidateId } = query
  const selector = useCallback((state: RootState) => findNotSelfByCandidateId(state, candidateId), [candidateId])
  const references = useSelector(selector)
  const dispatch = useDispatch()
  const deleteReference = useCallback((referenceId: string) => {
    dispatch(remove(referenceId))
  }, [])

  return { references, deleteReference }
}

export function useReferenceStatuses({ referenceIds }: { referenceIds: string[] }) {
  const referenceStatuses = useSelector((state: RootState) => {
    const referenceStatuses: Record<string, ReferenceStatus> = {}
    for (const refId of referenceIds) {
      const status = getReferenceStatus(state, refId)
      if (status) {
        referenceStatuses[refId] = status
      }
    }
    return referenceStatuses
  })
  return { referenceStatuses }
}

export function useSameOriginReferences({ candidateId }: { candidateId: string }) {
  const sameOriginReferences = useSelector((state: RootState) => findSameOriginByCandidateId(state, candidateId))
  return { sameOriginReferences }
}
