import * as api from 'api'
import { Entity } from 'api/request'
import ContainerCard from 'components/Container/ContainerCard'
import { size, space, style } from 'core'
import React from 'react'
import { CultureFitResponse } from 'selectors/questionnaire-submissions'
import MultiChipContainer from '../MultiChipContainer'
import { DescriptionText } from './ScoreExplanation'

export type CultureFitCheckResponses = {
  candidateProfile: Entity<api.candidateProfiles.Fields> | undefined
  recruiterSelectedValues: CultureFitResponse[] | undefined
  candidateSelectedValues: CultureFitResponse[] | undefined
}

const SideBySideContainers = style()
  .grid({ columns: [size.auto, size.auto] })
  .spacing({ gap: space.m })
  .element()

const SubDescriptionText = style()
  .spacing({ outer: [size.m, space.auto] })
  .elementFromComponent(DescriptionText)

export const CultureFitCheckValueComparison: React.FC<CultureFitCheckResponses> = props => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <SubDescriptionText>
        We have submitted to your candidate a list of values identical to yours. Here is what you have selected, and
        what your candidate has selected on their side. Identical answers are highlighted.
      </SubDescriptionText>
      <SideBySideContainers>
        <ContainerCard title="Your Cultural Values" emoji="👋" emojiColor="green">
          <MultiChipContainer
            chips={
              props.recruiterSelectedValues
                ? props.recruiterSelectedValues.map(r => ({
                    ...r,
                    value: '',
                  }))
                : []
            }
          ></MultiChipContainer>
        </ContainerCard>
        <ContainerCard
          title={`${props.candidateProfile?.fields?.full_name}'s Cultural Values`}
          emoji="👋"
          emojiColor="purple"
        >
          <MultiChipContainer
            chips={
              props.candidateSelectedValues
                ? props.candidateSelectedValues.map(r => ({
                    ...r,
                    value: '',
                  }))
                : []
            }
          ></MultiChipContainer>
        </ContainerCard>
      </SideBySideContainers>
    </div>
  )
}
