export function getHostname(url: string) {
  try {
    return new URL(url).hostname.replace(/^www\./, '')
  } catch {
    return ''
  }
}

export function normalizeUrl(input: string) {
  if (input.trim().length === 0) return ''

  if (!/^\w+:\/\//.test(input)) {
    return `http://${input}`
  }

  return input
}
