import action from './action'
import events from './events'
import track from './track'

export const homeJobRowClicked = action((job_id: string, job_name: string) =>
  track(events.HOME_JOB_ROW_CLICKED, {
    job_id,
    job_name,
  }),
)

export const inviteTeamClicked = action(() => track(events.HOME_INVITE_TEAM_CLICKED, {}))

export const resourceClicked = action((resource: string) => track(events.HOME_RESOURCE_CLICKED, { resource }))

export const taskClicked = action((task: string) => track(events.HOME_TASK_CLICKED, { task }))

export const homeButtonClicked = action(() => track(events.SIDEBAR_HOME_BUTTON_CLICKED, {}))

export const homeAssessmentsCardClicked = action(() => track(events.HOME_ASSESSMENTS_CARD_CLICKED, {}))

export const homeCandidateExperienceCardClicked = action(() => track(events.HOME_CANDIDATE_EXPERIENCE_CARD_CLICKED, {}))

export const homeReferenceChecksCardClicked = action(() => track(events.HOME_REFERENCE_CHECKS_CARD_CLICKED, {}))

export const homeQualityOfHireCardClicked = action(() => track(events.HOME_QUALITY_OF_HIRE_CARD_CLICKED, {}))
