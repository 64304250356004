import { workVerifications } from 'api'
import { AppThunk } from 'core/store'
import { add as notify } from 'store/notifications'
import { addEntities } from 'store/resources'

export const createWorkVerifications =
  (...args: Parameters<typeof workVerifications.createWorkVerifications>): AppThunk =>
  async dispatch => {
    const [response, errors] = await workVerifications.createWorkVerifications(...args)

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(addEntities(response))
      dispatch(
        notify({
          success: 'Work verification request has been submitted 🎉',
        }),
      )
    }
    return true
  }

export const updateWorkVerifications =
  (...args: Parameters<typeof workVerifications.updateWorkVerifications>): AppThunk =>
  async dispatch => {
    const [response, errors] = await workVerifications.updateWorkVerifications(...args)

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(addEntities(response))
    }
    return true
  }

export const confirmWorkVerification =
  (...args: Parameters<typeof workVerifications.confirmWorkVerification>): AppThunk =>
  async dispatch => {
    const [response, errors] = await workVerifications.confirmWorkVerification(...args)

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(addEntities(response))
    }
    return true
  }

export const fetchWorkVerification =
  (...args: Parameters<typeof workVerifications.fetchWorkVerification>): AppThunk =>
  async dispatch => {
    const [response, errors] = await workVerifications.fetchWorkVerification(...args)

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(addEntities(response))
    }
    return true
  }
