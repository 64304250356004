import { Parameter } from 'api/openjobroles'
import oldContent from 'content/old-content'
import * as content from './content'

export interface AnswerFormat {
  name: string
  desc?: string
  contentId?: string
  testItemId?: string
  question?: string
  placeholder?: string
  defaultTitle?: string
  icon: string
  emoji?: string
  notSelectable?: boolean
  isFromLibrary?: boolean
  parameters?: Parameter[]
}

const WORK_STYLE: AnswerFormat = {
  name: 'Work Style',
  question: 'Which of these characteristics best describe {CANDIDATE.FIRST_NAME} as a team member?',
  desc: 'Learn what makes candidates unique as individuals.',
  testItemId: content.WORK_STYLE_QUESTION.test_item_id,
  icon: 'person-booth',
  emoji: content.CONTENT_EMOJI_LOOKUP[content.WORK_STYLE_QUESTION.test_item_id],
  isFromLibrary: true,
}

const SELF_WORK_STYLE: AnswerFormat = {
  name: 'Work Styles',
  question: 'Which of these characteristics best describe you as a team member?',
  desc: 'Collect ideas on how you can help candidates grow.',
  testItemId: content.SELF_WORK_STYLE_QUESTION.test_item_id,
  icon: 'tree-palm',
  emoji: content.CONTENT_EMOJI_LOOKUP[content.SELF_WORK_STYLE_QUESTION.test_item_id],
  isFromLibrary: true,
  notSelectable: true,
}

// Unused, but still referenced just for content ID
const COLLABORATION_FREQUENCY: AnswerFormat = {
  name: 'Collaboration Frequency',
  desc: 'How often did you work together?',
  testItemId: content.COLLABORATION_FREQUENCY_QUESTION.test_item_id,
  icon: 'wave-sine',
  emoji: content.CONTENT_EMOJI_LOOKUP[content.COLLABORATION_FREQUENCY_QUESTION.test_item_id],
  isFromLibrary: true,
  notSelectable: true,
}

// Unused, but still referenced in reference profile
const COMPANY_SIZE: AnswerFormat = {
  name: 'Company Size',
  desc: 'What was the company size?',
  testItemId: content.COMPANY_SIZE_QUESTION.test_item_id,
  icon: 'people-arrows',
  emoji: content.CONTENT_EMOJI_LOOKUP[content.COMPANY_SIZE_QUESTION.test_item_id],
  isFromLibrary: true,
  notSelectable: true,
}

// Unused, but still referenced in reference profile
const COMPANY_STAGE: AnswerFormat = {
  name: 'Company Stage',
  desc: 'Was the company young or mature?',
  testItemId: content.COMPANY_STAGE_QUESTION.test_item_id,
  icon: 'building',
  emoji: content.CONTENT_EMOJI_LOOKUP[content.COMPANY_STAGE_QUESTION.test_item_id],
  isFromLibrary: true,
  notSelectable: true,
}

// Unused, but still referenced in reference profile
const TEAM_SIZE: AnswerFormat = {
  name: 'Team Size',
  desc: 'What was the team size?',
  testItemId: content.TEAM_SIZE_QUESTION.test_item_id,
  icon: 'user-friends',
  emoji: content.CONTENT_EMOJI_LOOKUP[content.TEAM_SIZE_QUESTION.test_item_id],
  isFromLibrary: true,
  notSelectable: true,
}

export const SKILL_LEVEL: AnswerFormat = {
  name: 'Skill',
  desc: 'How skilled is the candidate in a certain activity',
  contentId: content.SKILL_LEVEL_QUESTION.question.response_options[0],
  placeholder: `e.g When you last worked with {CANDIDATE.FIRST_NAME}, how skilled was {CANDIDATE.FIRST_NAME} in the following activity: Enterprise Sales`,
  icon: 'star-half-alt',
  emoji: content.CONTENT_EMOJI_LOOKUP[content.SKILL_LEVEL_QUESTION.question.response_options[0]],
  parameters: [
    {
      name: 'Skill',
      placeholder: 'Enter a skill. e.g Manage Inbound Sales',
      value: '',
    },
  ],
}

const OVERALL_PERFORMANCE: AnswerFormat = {
  name: 'Overall Performance',
  question: "How would you rate {CANDIDATE.FIRST_NAME}'s performance compared to colleagues in a similar position?",
  desc: 'Let references rate the performance of candidates.',
  testItemId: content.OVERALL_PERFORMANCE_QUESTION.test_item_id,
  emoji: content.CONTENT_EMOJI_LOOKUP[content.OVERALL_PERFORMANCE_QUESTION.test_item_id],
  icon: 'globe-europe',
  isFromLibrary: true,
}

const AREAS_OF_IMPROVEMENT: AnswerFormat = {
  name: 'Areas of Improvement',
  question: 'What were {CANDIDATE.FIRST_NAME}’s most significant areas for improvement when you worked together?',
  desc: "Get a structured view of candidates' improvement areas.",
  testItemId: content.AREAS_OF_IMPROVEMENT.test_item_id,
  icon: 'user-plus',
  emoji: content.CONTENT_EMOJI_LOOKUP[content.AREAS_OF_IMPROVEMENT.test_item_id],
  isFromLibrary: true,
}

const SELF_AREAS_OF_IMPROVEMENT: AnswerFormat = {
  name: 'Areas of Improvements',
  question: 'What are the areas you believe you need to improve in order to grow professionally?',
  testItemId: content.SELF_AREAS_OF_IMPROVEMENT_QUESTION.test_item_id,
  icon: 'user-plus',
  emoji: content.CONTENT_EMOJI_LOOKUP[content.SELF_AREAS_OF_IMPROVEMENT_QUESTION.test_item_id],
  isFromLibrary: true,
  notSelectable: true,
}

const GENERAL_IMPRESSION: AnswerFormat = {
  name: 'General Impression',
  question: 'What distinguished {CANDIDATE.FIRST_NAME} as a team member?',
  desc: 'Have references describe candidates in their own words.',
  testItemId: content.GENERAL_IMPRESSION.test_item_id,
  icon: 'book-user',
  emoji: content.CONTENT_EMOJI_LOOKUP[content.GENERAL_IMPRESSION.test_item_id],
  isFromLibrary: true,
}

const SELF_GENERAL_IMPRESSION: AnswerFormat = {
  name: 'General Impression',
  question: 'Which personal characteristics do you think make you stand out?',
  testItemId: content.SELF_GENERAL_IMPRESSION_QUESTION.test_item_id,
  icon: 'book-user',
  emoji: content.CONTENT_EMOJI_LOOKUP[content.SELF_GENERAL_IMPRESSION_QUESTION.test_item_id],
  isFromLibrary: true,
  notSelectable: true,
}
const RELATIONSHIP_DESCRIPTION: AnswerFormat = {
  name: 'Relationship Overview',
  question: 'Can you briefly describe the professional relationship you have with {CANDIDATE.FIRST_NAME}?',
  desc: 'See how references and candidates know each other.',
  testItemId: content.RELATIONSHIP_DESCRIPTION_QUESTION.test_item_id,
  icon: 'people-arrows',
  emoji: content.CONTENT_EMOJI_LOOKUP[content.RELATIONSHIP_DESCRIPTION_QUESTION.test_item_id],
  isFromLibrary: true,
}

const REASONS_FOR_LEAVING: AnswerFormat = {
  name: 'Reasons for Leaving',
  question: 'Why did {CANDIDATE.FIRST_NAME} leave the organization you have worked for together?',
  desc: 'Learn why candidates left their prior organizations.',
  testItemId: content.REASONS_FOR_LEAVING_QUESTION.test_item_id,
  icon: 'sign-out-alt',
  emoji: content.CONTENT_EMOJI_LOOKUP[content.REASONS_FOR_LEAVING_QUESTION.test_item_id],
  isFromLibrary: true,
}

const KEY_ACCOMPLISHMENTS: AnswerFormat = {
  name: 'Key Achievements',
  question: "What were {CANDIDATE.FIRST_NAME}'s most remarkable achievements during your time together?",
  desc: "Let references outline the candidates' major successes.",
  testItemId: content.KEY_ACCOMPLISHMENTS_QUESTION.test_item_id,
  icon: 'key',
  emoji: content.CONTENT_EMOJI_LOOKUP[content.KEY_ACCOMPLISHMENTS_QUESTION.test_item_id],
  isFromLibrary: true,
}

const SELF_KEY_ACCOMPLISHMENTS: AnswerFormat = {
  name: 'Key Achievements',
  question: 'In your professional career, what has been your greatest achievement?',
  testItemId: content.SELF_KEY_ACCOMPLISHMENTS_QUESTION.test_item_id,
  icon: 'key',
  emoji: content.CONTENT_EMOJI_LOOKUP[content.SELF_KEY_ACCOMPLISHMENTS_QUESTION.test_item_id],
  isFromLibrary: true,
  notSelectable: true,
}

const STRENGTHS: AnswerFormat = {
  name: 'Strengths',
  question: "What would you consider to be {CANDIDATE.FIRST_NAME}'s most noticeable strengths?",
  desc: "Get a structured view of candidates' strengths.",
  testItemId: content.STRENGTHS_QUESTION.test_item_id,
  icon: 'dumbbell',
  emoji: content.CONTENT_EMOJI_LOOKUP[content.STRENGTHS_QUESTION.test_item_id],
  isFromLibrary: true,
}

const SELF_STRENGTHS: AnswerFormat = {
  name: 'Strengths',
  question: 'What are your greatest strengths?',
  testItemId: content.SELF_STRENGTHS_QUESTION.test_item_id,
  icon: 'dumbbell',
  emoji: content.CONTENT_EMOJI_LOOKUP[content.SELF_STRENGTHS_QUESTION.test_item_id],
  isFromLibrary: true,
  notSelectable: true,
}

const RECOMMENDATION_OF_HIRE: AnswerFormat = {
  name: 'Hiring Recommendations',
  question: 'All in all, would you recommend {ORGANIZATION.NAME} to hire {CANDIDATE.FIRST_NAME} as {ROLE.NAME}? ',
  desc: 'See if past co-workers recommend hiring candidates.',
  testItemId: content.RECOMMENDATION_OF_HIRE_QUESTION.test_item_id,
  icon: 'lightbulb',
  emoji: content.CONTENT_EMOJI_LOOKUP[content.RECOMMENDATION_OF_HIRE_QUESTION.test_item_id],
  isFromLibrary: true,
}

const GROWTH_HINTS: AnswerFormat = {
  name: 'Growth Hints',
  question:
    "Recognizing that anyone can develop, how can {ORGANIZATION.NAME} best support {CANDIDATE.FIRST_NAME}'s development?",
  desc: 'Collect ideas on how you can help candidates grow.',
  testItemId: content.GROWTH_HINTS_QUESTION.test_item_id,
  icon: 'tree-palm',
  emoji: content.CONTENT_EMOJI_LOOKUP[content.GROWTH_HINTS_QUESTION.test_item_id],
  isFromLibrary: true,
}

const SELF_GROWTH_HINTS: AnswerFormat = {
  name: 'Growth Hints',
  question:
    'In order to help you develop professionally, what kind of support would you wish to receive from {ORGANIZATION.NAME}?',
  testItemId: content.SELF_GROWTH_HINTS_QUESTION.test_item_id,
  icon: 'tree-palm',
  emoji: content.CONTENT_EMOJI_LOOKUP[content.SELF_GROWTH_HINTS_QUESTION.test_item_id],
  isFromLibrary: true,
  notSelectable: true,
}

const WORKING_WITH_OTHERS: AnswerFormat = {
  name: 'Working with Others',
  question: 'How did {CANDIDATE.FIRST_NAME} work with others? (Reference will rate 4 statements)',
  desc: 'Assess how candidates behave when working with others.',
  testItemId: content.WORKING_WITH_OTHERS_QUESTION.test_item_id,
  icon: 'tree-palm',
  emoji: content.CONTENT_EMOJI_LOOKUP[content.WORKING_WITH_OTHERS_QUESTION.test_item_id],
  isFromLibrary: true,
}

const SELF_WORKING_WITH_OTHERS: AnswerFormat = {
  name: 'Working with Others',
  question: 'How did you work with others? (Candidate will rate 4 statements)',
  desc: 'Assess how candidates behave when working with others.',
  testItemId: content.SELF_WORKING_WITH_OTHERS_QUESTION.test_item_id,
  icon: 'tree-palm',
  emoji: content.CONTENT_EMOJI_LOOKUP[content.SELF_WORKING_WITH_OTHERS_QUESTION.test_item_id],
  isFromLibrary: true,
  notSelectable: true,
}

const CULTURE_ADD: AnswerFormat = {
  name: 'Culture Add',
  question: 'What personal values did {CANDIDATE.FIRST_NAME} add to the team? (Reference will rate 2 statements)',
  desc: 'Identify the personal values that candidates will add to the team.',
  testItemId: content.CULTURE_ADD_QUESTION.test_item_id,
  icon: 'tree-palm',
  emoji: content.CONTENT_EMOJI_LOOKUP[content.CULTURE_ADD_QUESTION.test_item_id],
  isFromLibrary: true,
}

const SELF_CULTURE_ADD: AnswerFormat = {
  name: 'Culture Add',
  question: 'What personal values are important to you? (Candidate will rate 2 statements)',
  desc: 'Identify the personal values that candidates will add to the team.',
  testItemId: content.SELF_CULTURE_ADD_QUESTION.test_item_id,
  icon: 'tree-palm',
  emoji: content.CONTENT_EMOJI_LOOKUP[content.SELF_CULTURE_ADD_QUESTION.test_item_id],
  isFromLibrary: true,
  notSelectable: true,
}

const LEADERSHIP_SKILLS: AnswerFormat = {
  name: 'Leadership Skills',
  question: 'What key leadership qualities does {CANDIDATE.FIRST_NAME} possess? (Reference will rate 7 statements)',
  desc: 'Examine candidates in terms of key leadership qualities',
  testItemId: content.LEADERSHIP_SKILL_QUESTION.test_item_id,
  icon: 'tree-palm',
  emoji: content.CONTENT_EMOJI_LOOKUP[content.LEADERSHIP_SKILL_QUESTION.test_item_id],
  isFromLibrary: true,
}
export const allAnswerFormats = [
  RELATIONSHIP_DESCRIPTION,
  REASONS_FOR_LEAVING,
  KEY_ACCOMPLISHMENTS,
  SELF_KEY_ACCOMPLISHMENTS,
  OVERALL_PERFORMANCE,
  STRENGTHS,
  SELF_STRENGTHS,
  AREAS_OF_IMPROVEMENT,
  SELF_AREAS_OF_IMPROVEMENT,
  GROWTH_HINTS,
  SELF_GROWTH_HINTS,
  GENERAL_IMPRESSION,
  SELF_GENERAL_IMPRESSION,
  WORK_STYLE,
  SELF_WORK_STYLE,
  RECOMMENDATION_OF_HIRE,
  WORKING_WITH_OTHERS,
  SELF_WORKING_WITH_OTHERS,
  CULTURE_ADD,
  SELF_CULTURE_ADD,
  LEADERSHIP_SKILLS,

  SKILL_LEVEL,

  // Not selectable but still iterated over in profiles
  TEAM_SIZE,
  COMPANY_SIZE,
  COMPANY_STAGE,
  COLLABORATION_FREQUENCY,

  ...oldContent,
]
