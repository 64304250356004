import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getByUserId } from 'selectors/profiles'
import { RootState } from 'store'
import { loadManagers } from 'store/onboarding'

export function useManagers(query: { orgId: string }) {
  const { orgId } = query
  const dispatch = useDispatch()

  const managers = useSelector((state: RootState) =>
    state.onboarding.managers.map(m => {
      const profile = getByUserId(state, m.id)
      return {
        id: m.id,
        name: profile?.fields.full_name || '',
        email: m.fields.email,
        profileImg: profile?.fields.profile_picture_url || '',
      }
    }),
  )
  const isFetched = managers.length > 0

  useEffect(() => {
    if (!orgId || isFetched) return
    dispatch(loadManagers(orgId))
  }, [orgId, isFetched])

  return {
    managers,
  }
}
