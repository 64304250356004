import { Chip } from 'App/Candidate/Chips'
import { AchievedScore, RawScoreContainer } from 'App/Candidate/SelfAssessment/RawScoreDisplay'
import React from 'react'
import classes from './Result.module.scss'

interface NumberResultProps {
  result?: string
  total: number
  notApplicable?: boolean
}
export const NumberResult: React.FC<NumberResultProps> = props => {
  return (
    <div>
      {props.notApplicable ? (
        <Chip>N/a</Chip>
      ) : (
        <RawScoreContainer>
          <AchievedScore>{props.result}</AchievedScore> / {props.total}
        </RawScoreContainer>
      )}
    </div>
  )
}

interface ChipResultProps {
  chips?: string[]
}
export const ChipResult: React.FC<ChipResultProps> = props => {
  return (
    <div className={classes.chips}>
      {props.chips ? props.chips.map(chip => <Chip key={chip}>{chip}</Chip>) : <Chip>N/a</Chip>}
    </div>
  )
}
