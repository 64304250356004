import { LockedByPlan, LockIcon } from '@common/components'
import { useMergeLink } from '@mergeapi/react-merge-link'
import { getIntegrationHelp } from 'api/integrations'
import Block from 'components/Block'
import Dialog from 'components/Confirm/Dialog'
import { Icon } from 'components/Icons'
import Modal from 'components/Modal'
import TextField from 'components/TextField'
import { Content, ContentAndButtons, Form } from 'components/Wizard'
import Buttons from 'components/Wizard/Buttons'
import { Subheading } from 'components/Wizard/Typography'
import { height, px, rem, size, space, style, weight } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import { lockedContentTracking } from 'core/track/locked-content'
import { useFeatureFlag } from 'providers/features'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as selectors from 'selectors'
import { RootState } from 'store'
import * as slice from 'store/integrations'
import ats, { ATS as ATSType, InstallMethod, useATS } from 'store/integrations/ats'

const ATSGrid = style()
  .size({ width: '100%' })
  .grid({ columns: `1fr 1fr 1fr` })
  .spacing({ rows: space.m, columns: space.m })
  .element()

const ATS = style()
  .block()
  .flex({ alignItems: 'center' })
  .spacing({ gap: space.s, inner: space.xs })
  .sans({ height: height.l, weight: weight.bold, nodecoration: true })
  .border({ around: '1px solid', color: designSystemColors.borderDefault })
  .round(px(6))
  .pointer()
  .relative()
  .nooverflow()
  .select('section', style().hidden())
  .cond(props => props.isExisting, style().cursor('default').select(':hover section', style().flex()))
  .cond(props => !props.isExisting, style().select(':hover', style().color({ bg: designSystemColors.borderDefault })))
  .element('a')

const ATSOverlay = style()
  .absolute({ top: 0, left: 0, right: 0, bottom: 0 })
  .spacing({ inner: space.xs, gap: space.xs })
  .flex({ alignItems: 'center', justifyContent: 'flex-end' })
  .color({ bg: 'rgba(0, 0, 0, 0.5)' })
  .depth({ front: true })
  .element('section')
const ATSButton = style()
  .flex({ alignItems: 'center', justifyContent: 'center' })
  .pointer()
  .round('50%')
  .size({ width: size.l, height: size.l })
  .border({ around: 'none' })
  .color({ bg: designSystemColors.backgroundNeutralPrimary })
  .pointer()
  .element('button')
const ATSName = text.bodyInteractive().element('p')
const ATSLogo = style().size({ height: size.xl }).element('img')

const ATSHelpContainer = style()
  .size({
    maxWidth: rem(35),
    width: 'calc(100vw - 200px)',
    height: rem(24),
  })
  .element()

const Component: React.FC = () => {
  const dispatch = useDispatch()

  const org = useSelector((state: RootState) => selectors.orgs.getById(state, selectors.orgs.currentId(state)))

  const isOrgPaidPlan = useSelector(selectors.orgs.isOrgPaidPlan)

  const { isEnabled: isGreenhouse2 } = useFeatureFlag('greenhouse-2.0')
  if (isGreenhouse2) {
    ats['greenhouse'].installMethod = 'greenhouse-api-key'
  }

  // We can have multiple integrations installed, but for the sake of not confusing the users,
  // we only show the first integration we fetch from the database for a given organization.
  const existingIntegration = useSelector((state: RootState) => {
    const currentOrgId = selectors.orgs.currentId(state)
    return selectors.integrations.getByOrgId(state, currentOrgId)
  })

  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [isHelpDialogOpen, setHelpDialogOpen] = useState(false)

  const [atsHelp, setATSHelp] = useState('')

  const combinedATSMap = useATS(org?.id)

  useEffect(() => {
    if (!existingIntegration) return
    ;(async () => {
      const [response] = await getIntegrationHelp(existingIntegration.id)
      setATSHelp(response?.result.fields.help || '')
    })()
  }, [existingIntegration])

  if (existingIntegration?.fields.ats_slug && combinedATSMap[existingIntegration?.fields.ats_slug]) {
    const { square_image, name } = combinedATSMap[existingIntegration.fields.ats_slug]

    const onDeleteConfirmation = (isConfirmed: boolean) => {
      setDeleteDialogOpen(false)
      if (!isConfirmed) {
        return
      }
      dispatch(slice.deleteOrgIntegration(org.id, existingIntegration.fields.id))
    }
    return (
      <>
        <Block title="Integrations" testId="integrations">
          <ATSGrid>
            <ATS isExisting>
              <ATSOverlay>
                {atsHelp && (
                  <ATSButton title="Help" onClick={() => setHelpDialogOpen(true)}>
                    <Icon name="question" />
                  </ATSButton>
                )}
                <ATSButton title="Delete Integration" onClick={() => setDeleteDialogOpen(true)}>
                  <Icon name="trash" />
                </ATSButton>
              </ATSOverlay>
              <ATSLogo src={square_image} alt={`${name} logo`} />
              <ATSName>{name}</ATSName>
            </ATS>
          </ATSGrid>
        </Block>
        <Dialog
          title={'About to delete an integration, continue?'}
          message={'Deleting integrations can be very destructive and it can break many working flows.'}
          confirmLabel={'Yes, DELETE it'}
          danger={true}
          isOpen={isDeleteDialogOpen}
          setOpen={setDeleteDialogOpen}
          onConfirm={onDeleteConfirmation}
        />
        {!!atsHelp && isHelpDialogOpen && (
          <Modal open setOpen={setHelpDialogOpen} title={`Next steps for ${name}`}>
            <ATSHelpContainer>
              <ContentAndButtons>
                <Content standalone>
                  <Subheading>
                    {atsHelp.split('\n').map((line, i) => (
                      <p key={i}>{line}</p>
                    ))}
                  </Subheading>
                </Content>
                <Buttons next={() => setHelpDialogOpen(false)} back={() => {}} label={'Done'} forwardOnly />
              </ContentAndButtons>
            </ATSHelpContainer>
          </Modal>
        )}
      </>
    )
  }

  return (
    <LockedByPlan
      tracking={lockedContentTracking}
      locked={!isOrgPaidPlan}
      lockIconDisabled={true}
      upgradeTo={'Scale'}
      modalData={{
        learnMoreLink: 'https://intercom.help/hipeople/en/articles/7826759-integrating-a-job-with-your-chosen-ats',
        learnMoreAbout: 'Integrating Your ATS',
      }}
    >
      <Block
        testId="integrations"
        title={
          <>
            Integrations
            <LockIcon visible={!isOrgPaidPlan} />
          </>
        }
      >
        <ATSGrid>
          {Object.values(combinedATSMap).map(a => {
            const Installer = getInstaller(a.installMethod)
            if (!Installer) return <></>

            return (
              <Installer key={a.slug} organizationID={org.id} ats={a} onComplete={() => setHelpDialogOpen(true)}>
                <ATS>
                  <ATSLogo src={a.square_image} alt={`${a.name} logo`} />
                  <ATSName>{a.name}</ATSName>
                </ATS>
              </Installer>
            )
          })}
        </ATSGrid>
      </Block>
    </LockedByPlan>
  )
}

export default Component
Component.displayName = 'Integrations'

type InstallerType = React.FC<InstallerProps>
type InstallerProps = {
  organizationID: string
  ats: ATSType
  children: React.ReactNode
  onComplete?: () => void
}

const LinkTokenInstaller = ({
  organizationID,
  ats,
  children,
}: {
  ats: ATSType
  organizationID: string
  children: React.ReactNode
  onComplete?: () => void
}) => {
  const dispatch = useDispatch()
  const linkToken = useSelector((state: RootState) => state.integrations.linkToken)
  const onSuccess = useCallback(
    publicToken => {
      dispatch(slice.setLinkToken(''))
      dispatch(slice.installLinkTokenATSAccount(organizationID, publicToken))
    },
    [dispatch, organizationID],
  )

  const { open, isReady } = useMergeLink({ linkToken, onSuccess })

  useEffect(() => {
    if (linkToken && isReady) {
      open({ linkToken, onSuccess })
    }
  }, [linkToken, isReady, open])

  return (
    <div
      onClick={() => {
        dispatch(slice.installLinkTokenATS(organizationID, ats.slug))
      }}
    >
      {children}
    </div>
  )
}

const OAuthInstaller: InstallerType = ({ organizationID, ats, children }) => {
  const dispatch = useDispatch()
  const url = useSelector((state: RootState) => state.integrations.url)

  useEffect(() => {
    if (url) {
      window.location.href = url
    }
  }, [url])

  return (
    <div
      onClick={() => {
        dispatch(slice.installOAuthATS(organizationID, ats.slug))
      }}
    >
      {children}
    </div>
  )
}

const APIKeyInstallerContainer = style()
  .size({
    maxWidth: rem(35),
    width: 'calc(100vw - 200px)',
    height: rem(28),
  })
  .element()

const GreenhouseAPIKeyInstaller: InstallerType = ({ organizationID, ats, children, onComplete }) => {
  const dispatch = useDispatch()

  const [apiKey, setAPIKey] = useState('')
  const [apiKeyModalOpen, setAPIKeyModalOpen] = useState(false)

  if (!apiKeyModalOpen) return <div onClick={() => setAPIKeyModalOpen(true)}>{children}</div>

  return (
    <>
      <Modal open setOpen={setAPIKeyModalOpen} title={`Set ${ats.name} API key`}>
        <APIKeyInstallerContainer>
          <ContentAndButtons>
            <Content standalone>
              <Subheading>
                Your Greenhouse Harvest API key can be found in:
                <br />
                {' > '}
                <b>Settings</b>
                {' > '}
                <b>Dev Center</b>
                {' > '}
                <b>API Credential Management</b>
                {' > '}
                <b>Create New API Key</b>
                {' > '}
                <b>Harvest</b>
              </Subheading>
              <Form>
                <TextField
                  label="🔑 API Key"
                  value={apiKey}
                  placeholder={`Enter your ${ats.name} API key`}
                  onChange={e => {
                    setAPIKey(e.target.value)
                  }}
                />
              </Form>
              <Subheading>
                Please make sure you select the following permissions checkboxes in Greenhouse after the API key has
                been generated:
                <ol>
                  <li>
                    <b>Candidates</b>
                  </li>
                  <li>
                    <b>Activity Feed</b>
                  </li>
                  <li>
                    <b>Jobs</b>
                  </li>
                  <li>
                    <b>Job Stages</b>
                  </li>
                  <li>
                    <b>Rejection Reasons</b>
                  </li>
                </ol>
                When you're finished, click <b>Set</b> to complete the process.
              </Subheading>
            </Content>
            <Buttons
              next={() => {
                dispatch(slice.installAPIKeyATS(organizationID, ats.slug, apiKey))
                setAPIKeyModalOpen(false)
                onComplete?.()
              }}
              back={() => {}}
              label={'Set'}
              disabled={!apiKey}
              forwardOnly
            ></Buttons>
          </ContentAndButtons>
        </APIKeyInstallerContainer>
      </Modal>
      {children}
    </>
  )
}

const TalentLinkAPIKeyAndBasicAuthInstaller: InstallerType = ({ organizationID, ats, children, onComplete }) => {
  const dispatch = useDispatch()

  const [apiKey, setAPIKey] = useState('')
  const [url, setUrl] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [modalOpen, setModalOpen] = useState(false)

  if (!modalOpen) return <div onClick={() => setModalOpen(true)}>{children}</div>

  return (
    <>
      <Modal open setOpen={setModalOpen} title={`Set ${ats.name} API key and credentials`}>
        <APIKeyInstallerContainer>
          <ContentAndButtons>
            <Content standalone>
              <Form>
                <TextField
                  label="API Key"
                  value={apiKey}
                  placeholder={`Enter your ${ats.name} API key`}
                  onChange={e => {
                    setAPIKey(e.target.value)
                  }}
                />
                <TextField
                  label="URL"
                  type="url"
                  value={url}
                  placeholder={`Enter your ${ats.name} URL`}
                  onChange={e => {
                    setUrl(e.target.value)
                  }}
                />
                <TextField
                  label="Username"
                  value={username}
                  placeholder={`Enter your username, e.g.: CustomerName:username:BO`}
                  onChange={e => {
                    setUsername(e.target.value)
                  }}
                />
                <TextField
                  label="Password"
                  value={password}
                  placeholder={`Enter your password`}
                  onChange={e => {
                    setPassword(e.target.value)
                  }}
                />
              </Form>
              <Subheading>
                When you're finished, click <b>Set</b> to complete the process.
              </Subheading>
            </Content>
            <Buttons
              next={() => {
                dispatch(slice.installAPIKeyBasicAuthATS(organizationID, ats.slug, apiKey, url, username, password))
                setModalOpen(false)
                onComplete?.()
              }}
              back={() => {}}
              label={'Set'}
              disabled={!apiKey || !username || !password || !url}
              forwardOnly
            ></Buttons>
          </ContentAndButtons>
        </APIKeyInstallerContainer>
      </Modal>
      {children}
    </>
  )
}

const DocsInstaller: InstallerType = ({ ats, children }) => {
  return (
    <Link href={ats.docs_link} target="_blank" rel="noopener noreferrer">
      {children}
    </Link>
  )
}

const Link = style().text({ nodecoration: true }).element('a')

const getInstaller = (installMethod?: InstallMethod): InstallerType => {
  switch (installMethod) {
    case 'oauth':
      return OAuthInstaller
    case 'link-token':
      return LinkTokenInstaller
    case 'greenhouse-api-key':
      return GreenhouseAPIKeyInstaller
    case 'docs':
      return DocsInstaller
    case 'api-key-basic':
      return TalentLinkAPIKeyAndBasicAuthInstaller
    default:
      return LinkTokenInstaller
  }
}
