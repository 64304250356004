import { ScoreResponse } from '@common/api/score'
import { Badge, Banner, Card, RoleAnalytics, Text, Time } from '@common/components'
import * as api from 'api'
import { Entity } from 'api/request'
import { BadgeCandidate } from 'App/Candidate/SelfAssessment/AssessmentSummariesDashboard/BadgeCandidate'
import { CheatingPreventionCard } from 'App/Candidate/SelfAssessment/AssessmentSummariesDashboard/CheatingPreventionCard'
import { px2rem, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import { isPresent } from 'core/utils'
import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import * as selectors from 'selectors'
import { findByQuestionnaireId } from 'selectors/questionnaire-entries'
import { findByModuleIds } from 'selectors/questions'
import {
  findSelfAssessmentAnswers,
  getAllAssessmentModuleScores,
  getModuleGroupScores,
  getOverallAssessmentScore,
  ModuleGroupScoreData,
  ModuleScoreData,
} from 'selectors/self-assessment'
import { RootState } from 'store'
import { AIInsight, AIInsights } from './AIInsights'
import classes from './AssessmentSummariesDashboard.module.scss'
import { InsightModal } from './InsightModal'
import { EmptyState } from '@common/components/EmptyState/EmptyState'
import { ScoreGroupWrapper } from '@common/components/Scoring/ScoreGroup/ScoreGroupWrapper'

type BadgeTheme = 'orange' | 'blue' | 'green' | 'purple' | 'red' | 'cyan'

export const BadgeStyle = text
  .label()
  .text({ size: '1rem' })
  .size({ width: '2.625rem', height: '2.625rem' })
  .border({ radius: '0.375rem' })
  .spacing({ inner: [px2rem(3), px2rem(4)], outerRight: '0.75rem' })
  .cond(({ onClick }) => !!onClick, style().pointer())
  .cond(({ copy }) => !copy?.length, style().inlineFlex({ alignItems: 'center', justifyContent: 'center' }))
  .cond(
    ({ theme }: { theme: BadgeTheme }) => theme === 'orange',
    style().color({
      bg: designSystemColors.informationOrangeBackground,
      fg: designSystemColors.informationOrangePrimary,
    }),
  )
  .cond(
    ({ theme }: { theme: BadgeTheme }) => theme === 'blue',
    style().color({
      bg: designSystemColors.informationBlueBackground,
      fg: designSystemColors.informationBluePrimary,
    }),
  )
  .cond(
    ({ theme }: { theme: BadgeTheme }) => theme === 'green',
    style().color({
      bg: designSystemColors.informationGreenBackground,
      fg: designSystemColors.informationGreenPrimary,
    }),
  )
  .cond(
    ({ theme }: { theme: BadgeTheme }) => theme === 'purple',
    style().color({
      bg: designSystemColors.informationPurpleBackground,
      fg: designSystemColors.informationPurplePrimary,
    }),
  )
  .cond(
    ({ theme }: { theme: BadgeTheme }) => theme === 'red',
    style().color({
      bg: designSystemColors.informationRedBackground,
      fg: designSystemColors.informationRedPrimary,
    }),
  )
  .cond(
    ({ theme }: { theme: BadgeTheme }) => theme === 'cyan',
    style().color({
      bg: designSystemColors.informationCyanBackground,
      fg: designSystemColors.informationCyanPrimary,
    }),
  )
  .element('span')

interface AssessmentSummariesDashboardUIProps {
  scores: ScoreResponse['meta']
  roleId: string
  candidateId: string
  candidateFirstName: string
  overallScore?: number
  overallTimeTaken: number
  moduleGroupScores: ModuleGroupScoreData[]
  moduleScoreData: ModuleScoreData[]
  relatedGroups: Entity<api.groups.Fields>[]
  questionnaireSubmission: Entity<api.questionnaireSubmissions.Fields> | undefined
  sortingOrder: string[]
  isSharedResults?: boolean
  scorePages?: React.ReactNode[]
}

const getStrengthsAndGrowth = (
  moduleScores: ModuleScoreData[],
  getModuleFromSlug: (slug: string) => Entity<api.modules.Fields> | undefined,
): {
  strengths: Entity<api.modules.Fields>[]
  growth: Entity<api.modules.Fields>[]
} => {
  const sortedModuleScores = moduleScores
    .sort((a, b) => Number(b.moduleScore) - Number(a.moduleScore))
    .map(moduleScore => {
      const mod = getModuleFromSlug(moduleScore.moduleSlug)
      return mod
    })
    // this filter is needed because we just need to consider HardSkills and Cognitive abilities
    .filter(module => module?.fields.group_id === 'mnue9mwbb5' || module?.fields.group_id === 'vas3q5zrra')
    .filter(isPresent)
    .filter(moduleScore => {
      if (!moduleScore || !moduleScore.fields.copy.feedback) {
        return false
      }
      return true
    })

  const strengths: Entity<api.modules.Fields>[] = []
  const growth: Entity<api.modules.Fields>[] = []

  // Algorithm to determine strengths and growth. Can be found on https://www.notion.so/hipeople/Algorithm-for-this-here-6a4c7b68a0d343a1ab452b452fc08305?pvs=4
  if (sortedModuleScores.length < 3) {
    return { strengths, growth }
  } else if (sortedModuleScores.length >= 3 && sortedModuleScores.length < 5) {
    strengths.push(sortedModuleScores[0])
    growth.push(sortedModuleScores.slice(-1)[0])
  } else if (sortedModuleScores.length >= 5 && sortedModuleScores.length < 7) {
    strengths.push(...sortedModuleScores.slice(0, 2))
    growth.push(...sortedModuleScores.slice(-2))
  } else if (sortedModuleScores.length >= 7) {
    strengths.push(...sortedModuleScores.slice(0, 3))
    growth.push(...sortedModuleScores.slice(-3).reverse())
  }

  return { strengths, growth }
}

/**
 * Component used in the 'Overview' tab. Also used in the shared results page.
 */
const AssessmentSummariesDashboardUI: React.FC<AssessmentSummariesDashboardUIProps> = props => {
  const overallScore = props.scores?.find(score => score.type === 'questionnaire_submissions')
  const [openInsightModal, setOpenInsightModal] = useState(false)

  const getModuleFromSlug = useSelector(
    (state: RootState) => (slug: string) => selectors.modules.findBySlug(state, slug),
  )

  const { strengths, growth: growthList } = useMemo(
    () => getStrengthsAndGrowth(props.moduleScoreData, getModuleFromSlug),
    [props.moduleScoreData, getModuleFromSlug],
  )

  const [data, setData] = useState<RoleAnalytics | null>(null)

  useEffect(() => {
    if (!props.roleId) return
    api.openjobroles.getRoleAnalytics(props.roleId).then(response => {
      const roleAnalytics = response[0] as RoleAnalytics | undefined
      setData(roleAnalytics ?? null)
    })
  }, [props.roleId])

  const avgOverallScore = data?.performance_stats.average_overall_score.toFixed(0)
  const highestOverallScore = data?.performance_stats.highest_overall_score.toFixed(0)
  const candidateIntop1Percent =
    overallScore && data?.performance_stats.top_1_percent_overall_score
      ? overallScore?.score >= data?.performance_stats.top_1_percent_overall_score
      : undefined
  const candidateIntop10Percent =
    overallScore && data?.performance_stats.top_10_percent_overall_score
      ? overallScore?.score >= data?.performance_stats.top_10_percent_overall_score
      : undefined

  const org = useSelector((state: RootState) => selectors.orgs.current(state))
  const orgSettings = useSelector((state: RootState) => selectors.orgSettings.getByOrgId(state, org.id))

  return (
    <div data-single-column={props.isSharedResults} className={classes.wrapper}>
      <div className={classes.sidebar}>
        {
          <Card container className={classes.overallCard} data-testid="overall-score-card">
            {!props.isSharedResults && (candidateIntop1Percent || candidateIntop10Percent) ? (
              <BadgeCandidate type={candidateIntop1Percent ? 'top-1' : 'top-10'} />
            ) : null}
            <div className={classes.scoreWrapper}>
              <div className={classes.scoreContainer}>
                <div>
                  <Text variant="hero-text" data-testid="overall-score" className={classes.scoreMain}>
                    {overallScore?.score.toFixed(0) ?? '--'}
                  </Text>
                  {overallScore && <span className={classes.percentMain}>%</span>}
                  <Text variant="small-body-text">{'Overall Score'}</Text>
                </div>
                <Time dark showIcon timeTakenMillis={overallScore?.time_taken_millis || 0} />
              </div>

              <div className={classes.scoreContainer}>
                <div>
                  <Text variant="timeline-header" data-testid="average-overall-score" className={classes.score}>
                    {avgOverallScore ?? '--'}
                  </Text>
                  {avgOverallScore && <span className={classes.percent}>%</span>}
                  <Text variant="small-body-text">{'Average Overall Score'}</Text>
                </div>

                <div>
                  <Text variant="timeline-header" data-testid="highest-overall-score" className={classes.score}>
                    {highestOverallScore ?? '--'}
                  </Text>
                  {highestOverallScore && <span className={classes.percent}>%</span>}
                  <Text variant="small-body-text">{'Highest Overall Score'}</Text>
                </div>
              </div>
            </div>
          </Card>
        }

        {!props.isSharedResults && (
          <>
            <AIInsights>
              <AIInsight
                name="Interview Questions"
                onClick={() => {
                  setOpenInsightModal(true)
                }}
              >
                Generate personalized interview guides with AI
              </AIInsight>
            </AIInsights>
            {!orgSettings?.fields.interviewer_mode && (
              <CheatingPreventionCard questionnaireSubmission={props.questionnaireSubmission} />
            )}
          </>
        )}
      </div>

      {props.scorePages && props?.scorePages.length > 0 ? (
        <ScoreGroupWrapper>
          <Fragment>
            {props.isSharedResults && (
              <div className={classes.sharedViewInfoContainer}>
                <Card container style={{ borderLeft: '5px solid blue', gridColumn: '1 / -1' }}>
                  <Text variant="body-emphasis" marginBottom>
                    Read first
                  </Text>
                  <Text variant="body-text" marginBottom style={{ color: designSystemColors.typographySecondary }}>
                    Your assessment results are just one piece of information. They don't determine the company's next
                    actions. Please don't assume anything based on your score alone. Receiving results doesn't guarantee
                    immediate contact from the company. Thank you for understanding.
                  </Text>

                  <Banner
                    variant="gray"
                    style={{
                      width: '100%',
                      display: 'grid',
                      gridTemplateColumns: 'auto 6fr',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      textAlign: 'left',
                      gap: 20,
                    }}
                  >
                    <div>
                      <Text variant="highlight" style={{ fontWeight: 'normal', display: 'inline' }}>
                        {overallScore?.score.toFixed(0) ?? '-'}
                      </Text>
                      <Text
                        variant="timeline-header"
                        style={{
                          fontWeight: 'normal',
                          display: 'inline',
                          color: designSystemColors.typographySecondary,
                        }}
                      >
                        %
                      </Text>
                    </div>
                    <Text
                      variant="body-text"
                      style={{
                        textAlign: 'left',
                      }}
                    >
                      A score between 50% and 90% is usually seen as good. However, the exact range for a "good" score
                      can vary based on how other candidates perform in the same assessment. Contact the hiring company
                      for more information.
                    </Text>
                  </Banner>
                </Card>
                <Card container style={{ gridColumn: '1 / -1' }}>
                  <Text variant="body-emphasis" marginBottom>
                    Your strengths
                  </Text>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                    {strengths.length === 0 ? (
                      <Banner
                        variant="gray"
                        key="strenghts-empty"
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          textAlign: 'left',
                          width: '100%',
                          gap: 16,
                        }}
                      >
                        <Text variant="body-text">Not enough scored tests taken.</Text>
                      </Banner>
                    ) : (
                      strengths.map(strength => (
                        <Banner
                          variant="gray"
                          key={strength.id}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            textAlign: 'left',
                            width: '100%',
                            gap: 16,
                          }}
                        >
                          <Badge variant="success">{strength.fields.copy.title}</Badge>
                          <Text variant="body-text">{strength.fields.copy.feedback?.strength}</Text>
                        </Banner>
                      ))
                    )}
                  </div>
                </Card>
                <Card container style={{ gridColumn: '1 / -1' }}>
                  <Text variant="body-emphasis" marginBottom>
                    Your potential for growth
                  </Text>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                    {growthList.length === 0 ? (
                      <Banner
                        variant="gray"
                        key="strenghts-empty"
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          textAlign: 'left',
                          width: '100%',
                          gap: 16,
                        }}
                      >
                        <Text variant="body-text">Not enough scored tests taken.</Text>
                      </Banner>
                    ) : (
                      growthList.map(growth => (
                        <Banner
                          variant="gray"
                          key={growth.id}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            textAlign: 'left',
                            width: '100%',
                            gap: 16,
                          }}
                        >
                          <Badge variant="white">{growth.fields.copy.title}</Badge>
                          <Text variant="body-text">{growth.fields.copy.feedback?.growth}</Text>
                        </Banner>
                      ))
                    )}
                  </div>
                </Card>
              </div>
            )}
          </Fragment>
          <Fragment>{props.scorePages}</Fragment>
        </ScoreGroupWrapper>
      ) : (
        <EmptyState
          rounded
          bordered
          centered
          photo="/assets/woman_on_laptop.png"
          text="Candidate hasn't completed the assessment"
        />
      )}
      <InsightModal open={openInsightModal} onClose={() => setOpenInsightModal(false)} />
    </div>
  )
}

interface AssessmentSummariesDashboardProps {
  scores: ScoreResponse['meta']
  roleId: string
  candidateId: string
  candidateFirstName: string
  sortingOrder: string[]
  isSharedResults?: boolean
  scorePages?: React.ReactNode[]
}

const AssessmentSummariesDashboard: React.FC<AssessmentSummariesDashboardProps> = props => {
  const selfAssessmentAnswers = useSelector((state: RootState) => findSelfAssessmentAnswers(state, props.candidateId))

  const questionnaireSubmission = useSelector((state: RootState) =>
    selectors.questionnaireSubmissions.findSelfAssessmentByCandidateId(state, props.candidateId),
  )

  const questionnaire = useSelector((state: RootState) =>
    questionnaireSubmission
      ? selectors.questionnaires.findById(state, questionnaireSubmission.fields.questionnaire_id)
      : undefined,
  )

  const questionnaireModuleIds = useSelector((state: RootState) =>
    questionnaire ? (findByQuestionnaireId(state, questionnaire.id) || []).map(e => e.fields.module_id) : [],
  )
  const moduleQuestions = useSelector((state: RootState) => findByModuleIds(state, questionnaireModuleIds))

  const moduleScoreData = useMemo(() => {
    if (!questionnaire || !selfAssessmentAnswers || !moduleQuestions) {
      return []
    }
    return getAllAssessmentModuleScores(questionnaire, selfAssessmentAnswers || [], moduleQuestions)
  }, [questionnaire, selfAssessmentAnswers, moduleQuestions])

  const groups = useSelector(selectors.groups.list)

  const moduleTypeScores = useMemo(() => getModuleGroupScores(moduleScoreData, groups), [moduleScoreData, groups])
  const overallScore = getOverallAssessmentScore(moduleTypeScores)

  const overallTimeTaken = moduleTypeScores.reduce((sum, module) => {
    return sum + module.timeTakenMillis
  }, 0)

  const relatedGroups = moduleTypeScores
    .map(gs => gs.groupId)
    .map(groupId => groups.find(g => g.id === groupId))
    .filter(isPresent)

  return (
    <>
      <AssessmentSummariesDashboardUI
        candidateId={props.candidateId}
        roleId={props.roleId}
        overallScore={overallScore}
        overallTimeTaken={overallTimeTaken}
        candidateFirstName={props.candidateFirstName}
        moduleGroupScores={moduleTypeScores}
        scores={props.scores}
        moduleScoreData={moduleScoreData}
        relatedGroups={relatedGroups}
        questionnaireSubmission={questionnaireSubmission}
        sortingOrder={props.sortingOrder}
        isSharedResults={props.isSharedResults}
        scorePages={props.scorePages}
      />
    </>
  )
}

export default AssessmentSummariesDashboard
