import { Button, Card } from '@common/components'
import { Link, TextHeader, TextHint } from 'App/CandidateOnly/Styles'
import { rem, space, style } from 'core'
import * as tracking from 'core/track'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import * as selectors from 'selectors'
import { RootState } from 'store'
import * as slice from 'store/references'
import CandidateStatus from './CandidateStatus'
import Reference, { Header } from './Reference'
import { useTranslation } from 'react-i18next'

const Container = style()
  .size({ maxWidth: rem(40) })
  .spacing({ outerBottom: space.xl })
  .select('> * + *', style().spacing({ outerTop: space.m }))
  .element()

const References = style().grid().spacing({ rows: space.xs }).element()

const ManageReferences = () => {
  const { t, i18n } = useTranslation()
  const location = useLocation()
  const dispatch = useDispatch()
  const { candidateid } = useParams<{ candidateid: string }>()

  const candidate = useSelector((state: RootState) => selectors.candidates.findById(state, candidateid || ''))
  const candidateProfile = useSelector((state: RootState) =>
    selectors.candidateProfiles.findByCandidateId(state, candidateid || ''),
  )
  const requiredReferences = useSelector((state: RootState) =>
    selectors.requiredReferences.findByCandidateOrRole(
      state,
      candidate?.id || '',
      candidate?.fields.open_job_role_id || '',
    ),
  )
  const optionalReferences = useSelector((state: RootState) =>
    selectors.references
      .findByCandidateId(state, candidateid || '')
      .filter(r => !r.fields.requirement_id && !r.fields.is_self),
  )

  const openJobRole = useSelector((state: RootState) => selectors.roles.findByCandidateId(state, candidateid || ''))

  const isAdditionalReferencesDisabled = !!openJobRole?.fields.disable_additional_references

  const canAddOptional = candidate?.fields.submitted_at || candidate?.fields.completed_at

  useEffect(() => {
    dispatch(slice.setCreatedId(''))
  }, [location.key])

  useEffect(() => {
    if (i18n.language !== openJobRole?.fields.reference_check_locales?.[0]) {
      i18n.changeLanguage(openJobRole?.fields.reference_check_locales?.[0])
    }
  }, [])

  return (
    <Container>
      <TextHeader>
        {t('candidate-dashboard-intro.references.title', '{name} reference check', {
          name: candidateProfile?.fields.full_name || '',
        })}
      </TextHeader>
      <Card big style={{ paddingTop: space.m, paddingBottom: space.m }}>
        <CandidateStatus />
      </Card>

      <Card big>
        <References>
          <Header />
          {requiredReferences.map((rr, i) => (
            <Link
              disabled={rr.fields.is_self}
              key={rr.id}
              to={{
                pathname: `/candidates/${candidateid}/manage-references/add/required/${rr.id}`,
                search: location.search,
              }}
            >
              <Reference index={i} candidateId={candidateid || ''} requirement={rr} />
            </Link>
          ))}
          {optionalReferences.map((r, i) => (
            <Link
              key={r.id}
              to={{
                pathname: `/candidates/${candidateid}/manage-references/add/optional/${r.id}`,
                search: location.search,
              }}
            >
              <Reference
                key={r.id}
                index={i + requiredReferences.length}
                candidateId={candidateid || ''}
                reference={r}
              />
            </Link>
          ))}
        </References>
        {!isAdditionalReferencesDisabled && (
          <Link
            disabled={!canAddOptional}
            to={{
              pathname: `/candidates/${candidateid}/manage-references/add/optional`,
              search: location.search,
            }}
            onClick={() => tracking.candidatePortal.addAddionalReference()}
            style={{ textAlign: 'center', margin: space.m }}
          >
            <Button variant={'purple'} disabled={!canAddOptional}>
              {t('candidate-dashboard-intro.references.add-optional', 'Add optional reference')}
            </Button>
          </Link>
        )}
        {(!isAdditionalReferencesDisabled || !canAddOptional) && (
          <TextHint>
            {t(
              'candidate-dashboard-intro.references.add-optional-hint',
              'You can add optional references once the required ones have been invited.',
            )}
          </TextHint>
        )}
      </Card>
    </Container>
  )
}

export default ManageReferences
