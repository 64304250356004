import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import {
  findEmployeeExperienceSummaryByEmployeeId,
  findFosterNewHireIntegrationSummaryByCandidateId,
  findManagerEvaluationSummaryByEmployeeId,
  findMaximiseKeyHireEngagementSummaryByCandidateId,
  findNurtureTrustSummaryByCandidateId,
  findOceanProfileScores,
  findOnboardingBuddySummaryByCandidateId,
  findOptimiseFirstTasksSummaryByCandidateId,
  findTopicsToAnalyzeByEmployeeId,
  findTopicsToPraiseByEmployeeId,
} from 'selectors/onboarding-summaries'
import { RootState } from 'store'

export function usePreHireOnboardingSummaries(query: { candidateId?: string }) {
  const { candidateId } = query
  const onboardingBuddySelector = useCallback(
    (state: RootState) => findOnboardingBuddySummaryByCandidateId(state, candidateId || ''),
    [candidateId],
  )

  const optimiseFirstTaskSummarySelector = useCallback(
    (state: RootState) => findOptimiseFirstTasksSummaryByCandidateId(state, candidateId || ''),
    [candidateId],
  )

  const maximiseHireEngagmentSummarySelector = useCallback(
    (state: RootState) => findMaximiseKeyHireEngagementSummaryByCandidateId(state, candidateId || ''),
    [candidateId],
  )

  const fosterNewHireSummarySelector = useCallback(
    (state: RootState) => findFosterNewHireIntegrationSummaryByCandidateId(state, candidateId || ''),
    [candidateId],
  )

  const nurtureTrustSummarySelector = useCallback(
    (state: RootState) => findNurtureTrustSummaryByCandidateId(state, candidateId || ''),
    [candidateId],
  )

  const oceanProfileScoreSelector = useCallback(
    (state: RootState) => findOceanProfileScores(state, candidateId || ''),
    [candidateId],
  )
  const onboardingBuddySummary = useSelector(onboardingBuddySelector)
  const optimiseFirstTaskSummary = useSelector(optimiseFirstTaskSummarySelector)
  const maximiseHireEngagementSummary = useSelector(maximiseHireEngagmentSummarySelector)
  const fosterNewHireSummary = useSelector(fosterNewHireSummarySelector)
  const nurtureTrustSummary = useSelector(nurtureTrustSummarySelector)
  const oceanProfileScores = useSelector(oceanProfileScoreSelector)

  return {
    onboardingBuddySummary,
    optimiseFirstTaskSummary,
    maximiseHireEngagementSummary,
    fosterNewHireSummary,
    nurtureTrustSummary,
    oceanProfileScores,
  }
}

export function usePostHireOnboardingSummaries(query: { employeeId?: string }) {
  const { employeeId } = query

  const findPraiseTopicSelector = useCallback(
    (state: RootState) => findTopicsToPraiseByEmployeeId(state, employeeId || ''),
    [employeeId],
  )

  const findAnalyzeTopicSelector = useCallback(
    (state: RootState) => findTopicsToAnalyzeByEmployeeId(state, employeeId || ''),
    [employeeId],
  )

  const findEmployeeExperienceSelector = useCallback(
    (state: RootState) => findEmployeeExperienceSummaryByEmployeeId(state, employeeId || ''),
    [employeeId],
  )

  const findManagerEvaluationSelector = useCallback(
    (state: RootState) => findManagerEvaluationSummaryByEmployeeId(state, employeeId || ''),
    [employeeId],
  )

  const praiseTopics = useSelector(findPraiseTopicSelector)
  const analyzeTopics = useSelector(findAnalyzeTopicSelector)
  const employeeExperience = useSelector(findEmployeeExperienceSelector)
  const managerEvaluation = useSelector(findManagerEvaluationSelector)

  return { praiseTopics, analyzeTopics, employeeExperience, managerEvaluation }
}
