import { TemplateContent, TemplateContentItem } from 'api/templates'
import { TopNavigation } from 'App/RoleCreation/TopNavigation'
import { Button, ButtonContent } from 'components/Button/ButtonV2'
import { fr, px2rem, style, vh } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { interpolateVariables } from 'core/text'
import { useQueryParams } from 'hooks/useQueryParams'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useMount } from 'react-use'
import * as selectors from 'selectors'
import * as modulesSelectors from 'selectors/modules'
import * as orgsSelectors from 'selectors/orgs'
import * as presetsSelectors from 'selectors/presets'
import { getById } from 'selectors/presets'
import { RootState } from 'store'
import * as modulesSlice from 'store/modules'
import * as newRoleSlice from 'store/new-role'
import { addReferenceCheckQuestionnaireToRole } from 'store/new-role'
import * as rolesSlice from 'store/openjobroles'
import * as templatesSlice from 'store/templates'
import * as skillsSlice from 'store/skills'
import { create as createSkill } from 'store/skills'
import { z } from 'zod'
import { Preview } from '../../RoleCreation/PreviewTable'
import { SaveTemplateAction, SaveTemplateModal } from '../../RoleCreation/TemplateCreation/SaveTemplateModal'
import TemplateCreationPreview from '../../RoleCreation/TemplateCreation/TemplateCreationPreview'
import {
  ModulesData,
  TemplateCreationSelection,
  TemplatedCreationSelectionValues,
} from '../../RoleCreation/TemplateCreation/TemplateCreationSelection'
import {
  TemplateCreationSkills,
  TemplatedCreationSkillsValues,
} from '../../RoleCreation/TemplateCreation/TemplateCreationSkills'
import TimeEstimationLabel from '../../RoleCreation/TemplateCreation/TimeEstimationLabel'
import { ExitWarningModal } from '../TemplateEditorHeader'

export enum Step {
  Selection = 'Select content',
  Skills = 'Add skill questions',
  Preview = 'Preview',
  Confirmation = 'Confirmation',
}

const steps: Step[] = [Step.Selection, Step.Skills, Step.Preview, Step.Confirmation]

const LeftSidebar = style().flex({ justifyContent: 'flex-start' }).element()
const RightSidebar = style().flex({ justifyContent: 'flex-end' }).element()
const Content = style().element()

const Header = style()
  .grid({ columns: [fr(1), `minmax(${px2rem(600)}, auto)`, fr(1)] })
  .spacing({ outerBottom: px2rem(64) })
  .element()

const TimeEstimationContainer = style()
  .set('position', 'fixed')
  .set('bottom', '0')
  .spacing({ outer: [px2rem(42), px2rem(12)] })
  .element()

const TemplateCreationStyle = style()
  .spacing({ inner: px2rem(32) })
  .size({ minHeight: vh(100) })
  .color({ bg: designSystemColors.backgroundNeutralSecondary })
  .element()

type CreateTemplateFn = (template: {
  selectedModuleIds: string[]
  selectedSkillIds: string[]
  customQuestions: {
    text: string
  }[]
  templateName?: string
  isPrivate: boolean
}) => Promise<any>

type EditTemplateFn = (template: {
  selectedModuleIds: string[]
  selectedSkillIds: string[]
  customQuestions: {
    text: string
  }[]
  templateName?: string
  isPrivate: boolean
}) => Promise<void>

type TemplateCreationInitialValues = {
  selectedModuleIds: string[]
  selectedSkillIds: string[]
  customQuestions: {
    text: string
  }[]
}

const INPUT_TYPE_QUESTION_DURATION_ESTIMATION_IN_MINUTES = 2
const CHIPS_TYPE_QUESTION_DURATION_ESTIMATION_IN_MINUTES = 1
const FORCED_CHOICE_TYPE_QUESTION_DURATION_ESTIMATION_IN_MINUTES = 0.2
const OPTIONSET_TYPE_QUESTION_DURATION_ESTIMATION_IN_MINUTES = 0.2
const LIKERTSCALE_TYPE_QUESTION_DURATION_ESTIMATION_IN_MINUTES = 0.2

function getQuestionDuration(type: string): number {
  switch (type) {
    case 'chips':
      return CHIPS_TYPE_QUESTION_DURATION_ESTIMATION_IN_MINUTES
    case 'forced-truth':
      return FORCED_CHOICE_TYPE_QUESTION_DURATION_ESTIMATION_IN_MINUTES
    case 'text-input':
      return INPUT_TYPE_QUESTION_DURATION_ESTIMATION_IN_MINUTES
    case 'optionset':
      return OPTIONSET_TYPE_QUESTION_DURATION_ESTIMATION_IN_MINUTES
    case 'likert-scale':
      return LIKERTSCALE_TYPE_QUESTION_DURATION_ESTIMATION_IN_MINUTES
    default:
      return 0
  }
}

function getDurationEstimation(preview: Preview[]): number {
  return preview.reduce((acc, question) => acc + getQuestionDuration(question.type), 0)
}

const CRITICAL_QUESTIONNAIRE_DURATION_LIMIT_IN_MIN = 30
const OPTIMAL_QUESTIONNAIRE_DURATION_LOWER_LIMIT_IN_MIN = 10

function getEstimationLabel(durationEstimation: number) {
  if (durationEstimation < OPTIMAL_QUESTIONNAIRE_DURATION_LOWER_LIMIT_IN_MIN) {
    return 'Short'
  }
  if (durationEstimation < CRITICAL_QUESTIONNAIRE_DURATION_LIMIT_IN_MIN) {
    return 'Optimal'
  }
  return '⚠️ Long'
}

interface NewReferenceCheckPageProps {
  createTemplate: CreateTemplateFn
  editTemplate: EditTemplateFn
  presetTitle: string | null
  skills: { id: string; name: string }[]
  modules: ModulesData
  getPreview: (presetContent: TemplateContent) => Preview[]
  getVariableCopy: (text: string) => string
  createSkill: (skillName: string, idCallback: (string) => any) => Promise<any>
  exit: () => any
  mode: 'new' | 'edit'
  initialValues?: TemplateCreationInitialValues
  canManageContent: boolean
  isTalentPoolEnabled: boolean
  isSalesPoolEnabled: boolean
  roleId: string | null
}

const NewReferenceCheckPage: React.FC<NewReferenceCheckPageProps> = ({
  createTemplate,
  editTemplate,
  presetTitle,
  skills,
  modules,
  mode,
  getPreview,
  getVariableCopy,
  createSkill,
  exit,
  initialValues,
  canManageContent,
  isTalentPoolEnabled,
  isSalesPoolEnabled,
  roleId,
}) => {
  const [selectedStepIndex, setSelectedStepIndex] = useState(0)
  const [loading, setLoading] = useState(false)

  const nextStep = useCallback(() => setSelectedStepIndex(stepIndex => stepIndex + 1), [])
  const prevStep = useCallback(() => setSelectedStepIndex(stepIndex => stepIndex - 1), [])
  const [isExitDialogShown, setIsExitDialogShown] = useState(false)

  const [skillValues, setSkillValues] = useState<TemplatedCreationSkillsValues>(() => ({
    selectedSkillIds: initialValues?.selectedSkillIds || [],
  }))

  const [selectionValues, setSelectionValues] = useState<TemplatedCreationSelectionValues>(() => ({
    selectedModuleIds: initialValues?.selectedModuleIds || [],
    customQuestions: initialValues?.customQuestions || [],
  }))

  const presetContent = renderContentSelection(
    selectionValues.selectedModuleIds,
    selectionValues.customQuestions,
    skillValues.selectedSkillIds,
    isTalentPoolEnabled,
    isSalesPoolEnabled,
  )

  const estimationDuration = getDurationEstimation(getPreview(presetContent))

  const submit = async (action: SaveTemplateAction) => {
    switch (action.type) {
      case 'create-template': {
        setLoading(true)
        await createTemplate({
          isPrivate: false,
          templateName: action.templateName,
          selectedModuleIds: selectionValues.selectedModuleIds,
          customQuestions: selectionValues.customQuestions,
          selectedSkillIds: skillValues.selectedSkillIds,
        })
        setLoading(false)
        return
      }
      case 'use-template-as-custom': {
        setLoading(true)
        await createTemplate({
          isPrivate: true,
          templateName: '',
          selectedModuleIds: selectionValues.selectedModuleIds,
          customQuestions: selectionValues.customQuestions,
          selectedSkillIds: skillValues.selectedSkillIds,
        })
        setLoading(false)
        return
      }
      case 'update-template': {
        setLoading(true)
        await editTemplate({
          templateName: action.templateName,
          selectedModuleIds: selectionValues.selectedModuleIds,
          selectedSkillIds: skillValues.selectedSkillIds,
          customQuestions: selectionValues.customQuestions,
          isPrivate: false,
        })
        setLoading(false)
        return
      }
    }
  }

  return (
    <>
      <TemplateCreationStyle>
        <Header>
          <LeftSidebar>
            <Button buttonType="tertiary" onClick={() => setIsExitDialogShown(true)}>
              <ButtonContent icon={{ name: 'sign-out', ariaLabel: 'Exit' }}>{'Exit'}</ButtonContent>
            </Button>
          </LeftSidebar>
          <Content>
            <TopNavigation steps={steps} selectedStepIndex={selectedStepIndex} />
          </Content>
          <RightSidebar>
            <Button
              buttonType="tertiary"
              as={'a'}
              target="_blank"
              href={'https://intercom.help/hipeople/en/articles/7123673-create-your-first-reference-feedback-request'}
            >
              <ButtonContent icon={{ name: 'book', ariaLabel: 'Documentation' }}>{'Documentation'}</ButtonContent>
            </Button>
          </RightSidebar>
        </Header>
        {steps[selectedStepIndex] === Step.Selection && (
          <TemplateCreationSelection
            next={nextStep}
            roleId={roleId}
            modules={modules}
            values={selectionValues}
            onChange={setSelectionValues}
            getVariableCopy={getVariableCopy}
            canManageContent={canManageContent}
          />
        )}
        {steps[selectedStepIndex] === Step.Skills && (
          <TemplateCreationSkills
            back={prevStep}
            next={nextStep}
            values={skillValues}
            onChange={setSkillValues}
            skills={skills}
            createSkill={createSkill}
          />
        )}
        {steps[selectedStepIndex] === Step.Preview && (
          <TemplateCreationPreview
            back={prevStep}
            submit={() => {
              if (roleId && mode === 'edit') {
                return submit({
                  type: 'use-template-as-custom',
                })
              } else {
                return nextStep()
              }
            }}
            isSubmitOnProgress={loading}
            questions={getPreview(presetContent).map(item => ({
              ...item,
              copy: getVariableCopy(item.copy),
            }))}
          />
        )}
        {steps[selectedStepIndex] === Step.Confirmation && (
          <SaveTemplateModal
            presetTitle={presetTitle}
            close={prevStep}
            onSubmit={submit}
            mode={mode}
            showUseAsCustom={!!roleId}
          />
        )}
        <TimeEstimationContainer>
          <TimeEstimationLabel estimation={getEstimationLabel(estimationDuration)} />
        </TimeEstimationContainer>
      </TemplateCreationStyle>

      {isExitDialogShown && (
        <ExitWarningModal
          onClose={() => setIsExitDialogShown(false)}
          onConfirm={() => {
            setIsExitDialogShown(false)
            exit()
          }}
        />
      )}
    </>
  )
}

function renderContentSelection(
  selectedModuleIds: string[],
  customQuestions: { text: string }[],
  selectedSkillIds: string[],
  isTalentPoolEnabled: boolean | undefined,
  isSalesPoolEnabled: boolean | undefined,
): TemplateContent {
  const talentPoolSlug = 'talent-pool'
  const talentPoolModule = { module_slug: talentPoolSlug }

  const salesPoolSlug = 'sales-pool'
  const salesPoolModule = { module_slug: salesPoolSlug }

  const content: TemplateContent = [
    ...selectedModuleIds
      .reduce((acc: string[], module_slug) => {
        if (module_slug !== talentPoolSlug && module_slug !== salesPoolSlug) {
          acc.push(module_slug)
        }
        return acc
      }, [])
      .map(
        (module_slug): TemplateContentItem => ({
          module_slug,
        }),
      ),
    ...customQuestions.map(
      ({ text }): TemplateContentItem => ({
        custom_question_heading: text,
        custom_question_response_type: 'text-input',
      }),
    ),
    ...selectedSkillIds.map(
      (skill_id): TemplateContentItem => ({
        skill_id,
      }),
    ),
  ]

  if (isTalentPoolEnabled) {
    content.push(talentPoolModule)
  }

  if (isSalesPoolEnabled) {
    content.push(salesPoolModule)
  }

  return content
}

const MODULE_DESCRIPTIONS: Record<string, string> = {
  'working-with-others': 'Assess how candidates behave when working with others.',
  'work-styles': "Review candidates' unique characteristics from the viewpoint of co-workers.",
  strengths: 'Identify the areas in which the candidate excels according to others.',
  'reasons-for-leaving': 'Discover why candidates left their previous employers.',
  'overall-performance': 'Learn how candidates have performed compared to others in similar jobs.',
  'leadership-skills': "See how previous co-workers assess the candidates' ability to lead others.",
  'key-achievements': 'Understand the key responsibilities a candidate has carried out in their previous positions.',
  'hiring-recommendations': 'See if past co-workers recommend hiring candidates.',
  'growth-hints': 'Collect ideas on how you can help candidates grow.',
  'general-impression': 'Get a sense of the impression the candidate has left with previous co-workers.',
  'culture-add': 'Identify the personal values that candidates will add to the team.',
  'areas-of-improvement': 'Discover the areas where the candidate can still improve.',
  'reference-context': 'Discover the professional relationship between candidate and reference',
}

const MODULE_PREVIEWS = {
  'working-with-others': {
    question: 'When around others, {CANDIDATE.FIRST_NAME} is someone who...',
    action: '(Reference will be asked to provide input)',
  },
  'work-styles': {
    question: 'Which of these characteristics best describe {CANDIDATE.FIRST_NAME} as a team member?',
    options: [
      'Positive',
      'Leading',
      'Empathetic',
      'Respectful',
      'Detailed',
      'Dependable',
      'Clever',
      'Creative',
      'Balanced',
      'Calm',
    ],
    action: 'Please select all options that apply best.',
  },
  strengths: {
    question: 'What would you consider to be {CANDIDATE.FIRST_NAME}’s most noticeable strengths?',
    options: [
      'Acting with confidence',
      'Proactivity',
      'Relationship building',
      'Fostering others',
      'Openness to opinions',
      'Technical expertise',
      'Solving tricky issues',
      'Fast learning',
      'Offering ideas',
      'Planning and prioritizing',
      'Attention to details',
      'Fast delivery',
      'Coping with pressure',
      'Displaying vulnerability',
    ],
    action: 'Please mark all strengths that apply best.',
  },
  'reasons-for-leaving': {
    question: 'Why did {CANDIDATE.FIRST_NAME} leave the organization you have worked together?',
    options: [
      'Issues with supervisor',
      'Issues with coworker',
      'Was terminated',
      'Lack of opportunity to grow',
      'Wanted more work variety',
      'Wanted less stress at work',
      'Wanted better benefits / pay',
      'Poor organizational outlook',
      'More time for private matters',
      'Issue with work location',
      'Contract ended',
      'Other reasons',
    ],
    action: 'Please choose all reasons that apply.',
  },
  'overall-performance': {
    question: 'How would you rate {CANDIDATE.FIRST_NAME}’s performance compared to colleagues in a similar position?',
    options: ['Below average', 'Average', 'Above average', 'One of the best'],
  },
  'leadership-skills': {
    question:
      'In leading others, {CANDIDATE.FIRST_NAME}: Set clear and understandable criteria for performance evaluation.',
    options: ['Rarely', 'Sometimes', 'Often', 'Usually', 'Always'],
  },
  'key-achievements': {
    question: 'What were {CANDIDATE.FIRST_NAME}’s most remarkable achievements during your time together?',
    action: '(Reference will be asked to provide input)',
  },
  'hiring-recommendations': {
    question: 'All in all, would you recommend {ORGANIZATION.NAME} to hire {CANDIDATE.FIRST_NAME} as {ROLE.NAME}?',
    options: ['Strong Yes', 'Yes', 'Undecided', 'No'],
  },
  'growth-hints': {
    question:
      'Recognizing that anyone can develop, how can {ORGANIZATION.NAME} best support {CANDIDATE.FIRST_NAME}’s development?',
    action: '(Reference will be asked to provide input)',
  },
  'general-impression': {
    question: 'What distinguished {CANDIDATE.FIRST_NAME} as a team member?',
    action: ' (Reference will be asked to provide input)',
  },
  'culture-add': {
    question: 'What is more important to {CANDIDATE.FIRST_NAME}?',
    action: ' (Reference will be asked to provide input)',
  },
  'areas-of-improvement': {
    question: 'In which of these areas could {CANDIDATE.FIRST_NAME} improve to grow professionally?',
    options: [
      'Acting with confidence',
      'Proactivity',
      'Relationship building',
      'Fostering others',
      'Openness to opinions',
      'Technical expertise',
      'Solving tricky issues',
      'Fast learning',
      'Offering ideas',
      'Planning and prioritizing',
      'Attention to details',
      'Fast delivery',
      'Coping with pressure',
      'Displaying vulnerability',
    ],
    action: 'Please mark all areas of improvement that apply best.',
  },
  'reference-context': {
    question: 'Can you briefly describe the professional relationship you have with {CANDIDATE.FIRST_NAME}?',
    action: '(Reference will be asked to provide input)',
  },
}

interface TemplateCreationPageProps {}

const queryParamSchema = z.object({
  templateid: z.string(),
  mode: z.enum(['new', 'edit']),
  roleid: z.string(),
})

const ReferenceCheckPage: React.FC<TemplateCreationPageProps> = () => {
  const { getParam } = useQueryParams(queryParamSchema)
  const templateId = getParam('templateid')
  const mode = getParam('mode', 'new')
  const roleId = getParam('roleid')

  const orgId = useSelector((state: RootState) => orgsSelectors.currentId(state))

  const role = useSelector((state: RootState) => (roleId ? selectors.roles.findById(state, roleId) : null))

  const org = useSelector((state: RootState) => selectors.orgs.getById(state, orgId))

  const canManageContent = useSelector((state: RootState) =>
    selectors.users.canManageContent(state, selectors.users.id(state)),
  )

  const dispatch = useDispatch()
  useMount(() => {
    dispatch(newRoleSlice.loadPresets(orgId, 'reference-check', '', ''))
    dispatch(skillsSlice.load())
    dispatch(modulesSlice.loadReferenceCheckModules())
    if (roleId) dispatch(rolesSlice.fetch(roleId))
  })

  const selectedPreset = useSelector(
    (state: RootState) =>
      templateId && (state.newRole.presets.find(preset => preset.id === templateId) || getById(state, templateId)),
  )

  const allModules = useSelector((state: RootState) => modulesSelectors.latest(state, 'reference-check'))
  const allSkills = useSelector((state: RootState) => state.skills.skills)

  const modules: ModulesData = Object.entries(allModules)
    .filter(
      ([, module]) =>
        module.fields.is_selectable && module.fields.is_active && module.fields.type === 'reference-check',
    )
    .map(([, module]) => ({
      slug: module.fields.slug,
      emoji: module.fields.copy.emoji || '🛠',
      name: module.fields.name,
      description: MODULE_DESCRIPTIONS[module.fields.slug] || '',
      preview: MODULE_PREVIEWS[module.fields.slug] || '',
    }))
    .sort((module1, module2) => module1.name.localeCompare(module2.name))

  const skills = allSkills.map(skill => ({
    id: skill.id,
    name: skill.fields.heading,
  }))

  const getPreview = useSelector(
    (state: RootState) => (presetContent: TemplateContent) =>
      presetsSelectors.getReferenceCheckPreview(state, presetContent, orgId),
  )

  function getCopy(text: string): string {
    const variables: Record<string, string> = {
      'CANDIDATE.FIRST_NAME': 'Alice',
      'ORGANIZATION.NAME': org.fields.name,
      'ROLE.NAME': role?.fields.public_name || role?.fields.name || '',
      'REFERENCE.FIRST_NAME': 'Bob',
    }
    return interpolateVariables(text, variables)
  }

  const history = useHistory()
  const exit = () => {
    if (history.length > 1) {
      history.goBack()
    } else if (roleId) {
      history.push(`/templates?product=reference-check&roleid=${roleId}&mode=single`)
    } else {
      history.push('/templates?mode=template&product=reference-check')
    }
  }

  const { talent_pool_enabled: isTalentPoolEnabled, sales_pool_enabled: isSalesPoolEnabled } = useSelector(
    (state: RootState) =>
      (selectors.orgSettings.getByOrgId(state, orgId) ?? {})?.fields ?? {
        talent_pool_enabled: false,
        sales_pool_enabled: false,
      },
  )

  const handleCreateTemplate: CreateTemplateFn = async template => {
    if (!orgId) {
      return
    }

    const content = renderContentSelection(
      template.selectedModuleIds,
      template.customQuestions,
      template.selectedSkillIds,
      isTalentPoolEnabled,
      isSalesPoolEnabled,
    )

    const presetId = await dispatch(
      templatesSlice.createTemplate(orgId, {
        is_private: template.isPrivate,
        is_highlighted: true,
        copy: { title: template.templateName || '' },
        content: content,
        product: 'reference-check',
        keywords: template.templateName ?? '',
      }),
    )

    if (typeof presetId !== 'string') {
      throw new Error(`Preset could not be created.`)
    }

    if (roleId) {
      await dispatch(addReferenceCheckQuestionnaireToRole(roleId, presetId))

      history.replace(`/roles/${roleId}`)
    } else {
      history.replace(`/templates?mode=template&product=reference-check`)
    }
  }

  const handleEditTemplate: EditTemplateFn = async template => {
    if (!orgId || !templateId) {
      return
    }

    const content = renderContentSelection(
      template.selectedModuleIds,
      template.customQuestions,
      template.selectedSkillIds,
      isTalentPoolEnabled,
      isSalesPoolEnabled,
    )

    const presetId = await dispatch(
      templatesSlice.updateTemplate(orgId, templateId, {
        updates: {
          copy: { title: template.templateName || '' },
          content: content,
          product: 'reference-check',
          is_private: template.isPrivate,
          is_highlighted: true,
        },
        updated: ['is_private', 'copy', 'content'],
      }),
    )

    if (typeof presetId !== 'string') {
      throw new Error(`Preset could not be created.`)
    }

    if (roleId) {
      await dispatch(addReferenceCheckQuestionnaireToRole(roleId, presetId))

      history.replace(`/roles/${roleId}`)
    }

    history.replace(`/templates?mode=template&product=reference-check`)
  }

  if (templateId && !selectedPreset) {
    // Still loading presets
    return null
  }

  let initialValues: TemplateCreationInitialValues | undefined
  if (selectedPreset) {
    initialValues = {
      selectedModuleIds: selectedPreset.fields.content
        .filter(({ module_slug }) => module_slug)
        .map(({ module_slug }) => module_slug as string),

      selectedSkillIds: selectedPreset.fields.content
        .filter(({ skill_id }) => skill_id)
        .map(({ skill_id }) => skill_id as string),

      customQuestions: selectedPreset.fields.content
        .filter(({ custom_question_heading }) => custom_question_heading)
        .map(({ custom_question_heading }) => ({
          text: custom_question_heading as string,
        })),
    }
  }

  return (
    <NewReferenceCheckPage
      mode={mode}
      presetTitle={selectedPreset ? selectedPreset.fields.copy.title : null}
      modules={modules}
      roleId={roleId}
      skills={skills}
      createTemplate={handleCreateTemplate}
      editTemplate={handleEditTemplate}
      getPreview={getPreview}
      getVariableCopy={getCopy}
      canManageContent={canManageContent}
      createSkill={async (skillName, idCallback) => {
        await dispatch(createSkill(skillName, idCallback))
      }}
      exit={exit}
      initialValues={initialValues}
      isTalentPoolEnabled={isTalentPoolEnabled || false}
      isSalesPoolEnabled={isSalesPoolEnabled || false}
    />
  )
}

export default ReferenceCheckPage
