import React from 'react'
import classes from './ColorSelector.module.scss'

type ColorSelectorProps = {
  color?: string
  setColor: (color: string) => void
}

const ColorSelector = (props: ColorSelectorProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setColor(e.target.value)
  }

  return (
    <div className={classes.colorSelector}>
      <input type="color" value={props.color} onChange={handleChange} />
      <input disabled type="text" value={props.color || '#000000'} onChange={handleChange} />
    </div>
  )
}

export default ColorSelector
