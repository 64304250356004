// TODO: move to core/utils, merge with existing time formatting functions there

/**
 * Format time from nanoseconds to local time
 * @param timeNano time in nanoseconds
 * @returns formatted time. ie: 12:00 01/01/2025 or 12:00 if today
 */
export const formatTimeNano = (timeNano: number | undefined | null) => {
  // handle invalid time
  if (timeNano === 0 || timeNano === undefined || timeNano === null) return ''
  const timeMilli = timeNano / 1e6

  // try to format time
  try {
    const occurance = new Date(timeMilli)
    const hourMinute = occurance.toLocaleTimeString(navigator.language, {
      hour: '2-digit',
      minute: '2-digit',
    })
    const today = new Date()
    if (occurance.toDateString() === today.toDateString()) {
      // is today, only show hh:mm
      return `${hourMinute}`
    }
    // is not today, show hh:mm dd/mm/yyyy
    return `${hourMinute} ${occurance.toLocaleDateString()}`
  } catch (e) {
    return ''
  }
}

/**
 * Format time from nanoseconds to local time
 * @param timeNano time in nanoseconds
 * @returns formatted date without time. ie: 01/01/2025
 */
export const formatDateFromNano = (timeNano: number | undefined | null) => {
  if (timeNano === 0 || timeNano === undefined || timeNano === null) return ''
  const timeMilli = timeNano / 1e6

  try {
    const occurance = new Date(timeMilli)
    const today = new Date()
    if (occurance.toDateString() === today.toDateString()) {
      return `Today`
    }

    return occurance.toLocaleDateString()
  } catch (e) {
    return ''
  }
}

export function getMonthNames(monthFormat: 'long' | 'numeric' | '2-digit' | 'short' | 'narrow', localeName = 'en-US') {
  const format = new Intl.DateTimeFormat(localeName, { month: monthFormat }).format
  const currentYear = new Date().getFullYear()
  return Array(12)
    .fill(0)
    .map((_, i) => format(new Date(Date.UTC(currentYear, i))))
}

/** Function to transform a UTM search query into an object. Example: ?utm_a=1&utm_b=2&test=3 would return {utm_a:1,utm_b:2} */
export function getUTMSearchParams(search: string) {
  const params = new URLSearchParams(search)
  // get only keys that start with utm_ and return them as an object
  const utmParams = Object.fromEntries(Array.from(params.entries()).filter(([key]) => key.startsWith('utm_')))
  return utmParams
}

/**
 * Solution Engineering for Douglas - https://linear.app/hipeople/issue/HPPL-534/rename-test-categories-for-them
 * Returns the alternative title for the given title and orgId.
 * @param title - The title to be replaced if the orgId corresponds to Douglas
 * @param orgId - The ID of the organization
 * @returns The alternative title if the orgId corresponds to Douglas, otherwise the original title
 */
export function getAlternativeTitle(title: string, orgId: string): string {
  if (orgId !== 'dkkkuo3lq6w9') {
    return title
  }

  switch (title) {
    case 'Soft Skills':
      return 'Douglas Fit'
    default:
      return title
  }
}

export function removeUTMSearchParams(search: string) {
  return search
    .replace(/(\?|&)?utm_[^&]+/g, '') // Remove all UTM params from the query
    .replace(/^&/, '?') // Fix leading '&' to '?'
    .replace(/\?$/, '') // Remove '?' if it's left at the end with no params
}
