import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Redirect, useParams } from 'react-router-dom'
import Button from '../../../components/Button/ButtonV1'
import TextField from '../../../components/TextField'
import { FocusHeading as Heading, Subheading } from '../../../components/Typography'
import { style } from '../../../core'
import token from '../../../core/token'
import * as selectors from '../../../selectors'
import { RootState } from '../../../store'
import { verify } from '../../../store/one-time-password'
import OTPLayout from './OTPLayout'

const NewPinLink = style().text({ nodecoration: true }).element('a')

const StepVerify = () => {
  const PIN_LENGTH = 6
  const dispatch = useDispatch()
  const [pin, setPin] = useState('')
  const { otpid } = useParams<{ otpid: string }>()

  const candidateToken = useSelector((state: RootState) => state.oneTimePassword.token)
  const otp = useSelector((state: RootState) => selectors.oneTimePassword.getById(state, otpid || ''))

  const candidateId = otp.fields.candidate_id

  if (candidateToken) {
    token.writeTemporary(candidateToken)

    switch (otp.fields.type) {
      case 'shared-candidate':
        return <Redirect to={`/shared/candidates/${candidateId}`} />
      case 'self-shared-candidate':
        return <Redirect to={`/shared/candidates/${candidateId}/results`} />
    }
  }

  return (
    <OTPLayout>
      <Heading center>Restricted Content</Heading>
      <Subheading center>Please enter your one-time PIN provided in the email to access this information</Subheading>
      <TextField disabled value={otp?.fields.email || 'Loading...'} />
      <TextField value={pin} placeholder="Your 6 digit PIN" onChange={e => setPin(e.target.value)} />
      <Button
        onClick={() => {
          dispatch(verify(otpid || '', pin))
        }}
        primary
        disabled={pin.length !== PIN_LENGTH}
      >
        Enter
      </Button>
      <Link to={`/shared/otp/${otpid}`} component={NewPinLink}>
        <Subheading right>Request a new PIN here</Subheading>
      </Link>
    </OTPLayout>
  )
}

export default StepVerify
