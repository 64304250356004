export type Scores = {
  [item_id: string]: {
    stats: {
      polarity: number
      popMean: number
      popSd: number
    }
    forcedChoice?: boolean
    relevantAnswers: string[]
  }
}

export const AGREEABLENESS_SCORES: Scores = {
  '7HtDYbqxdvWQNgtE1v6vsb': {
    stats: { polarity: 1, popMean: 1.2020641, popSd: 0.7300111 },
    relevantAnswers: ['2MS1xOJDbeonYbuROLalzd', '6vxqTqfkS6qFJLOeDQtW0t'],
  },
  '5tXtehaq0WyIFJcddfCqy1': {
    stats: { polarity: 1, popMean: 6.6156463, popSd: 2.0463949 },
    forcedChoice: true,
    relevantAnswers: [],
  },
  '282RnCULYBzSstrnB109xw': {
    stats: { polarity: 1, popMean: 5.2893519, popSd: 2.0585011 },
    forcedChoice: true,
    relevantAnswers: [],
  },
  jvMQzXAWY0KP1OTGKFLIP: {
    stats: { polarity: -1, popMean: 0.6877778, popSd: 0.718427 },
    relevantAnswers: [
      '5zcxMDLVqtfyQkZB4WwN47',
      '2xYb1EmBCw3faJDqAVtk6e',
      '5hDxoFDp38jNPHqT6ux9uH',
      '44MG1NpSwZSYhith5uQhMZ',
    ],
  },
  '2p6vrwO6WLutIOJaELdooB': {
    stats: { polarity: 1, popMean: 1.5702411, popSd: 1.0711933 },
    relevantAnswers: [
      '5zcxMDLVqtfyQkZB4WwN47',
      '2xYb1EmBCw3faJDqAVtk6e',
      '5hDxoFDp38jNPHqT6ux9uH',
      '44MG1NpSwZSYhith5uQhMZ',
    ],
  },
  '3bisgKTV18oLJaRmpZ1Ds5': {
    stats: { polarity: -1, popMean: 0.7732143, popSd: 1.1929179 },
    relevantAnswers: [
      '6XT2I1ZoFm75UVX8MsCi2c',
      '78D2L2ytk5EJ3S5aFw2GNC',
      '5x7g8AsULWOQ1FnvmcGbs8',
      '3bXWK882Ok1hFlYPJPSJTg',
      '2TC1mcM7ecZMyfnZkJ5CQ6',
      '1fzjBt9ih65LLy1CWQ50kM',
    ],
  },
  '28lDj4fBrNDoRZKgTKjGWK': {
    stats: { polarity: 1, popMean: 1.2295539, popSd: 0.9723319 },
    relevantAnswers: ['3jBKygy1IeafpT4xahxJyd', '0NhlNu8fmkAOAn4TXv5GU', '35LQGWKnXahkWSUI0bkjAA'],
  },
  '28U0Ukwogn7kbqYJ9WC0DW': {
    stats: { polarity: 1, popMean: 4.9340528, popSd: 2.1255296 },
    forcedChoice: true,
    relevantAnswers: [],
  },
  '4gmD1nhca5riUyPUOVXfiN': {
    stats: { polarity: 1, popMean: 1.7105014, popSd: 0.9368449 },
    relevantAnswers: ['1ygJxQmTSpDFXUZwALJsWN', '1TVO410A9vNCWwpLYUfEDi', 'QPw1D1MqAxWgP76wTWtP5'],
  },
}

export const EXTRAVERSION_SCORES: Scores = {
  '7HtDYbqxdvWQNgtE1v6vsb': {
    stats: { polarity: 1, popMean: 1.2468767, popSd: 0.7001744 },
    relevantAnswers: ['o2vccn49knKUMS7SVWezt', '4jkz1Kd2aQePu9jqumOp4G'],
  },
  '3IX1Djjns4CxNfxP9E0Ga7': {
    stats: { polarity: 1, popMean: 5.1810748, popSd: 2.0343748 },
    forcedChoice: true,
    relevantAnswers: [],
  },
  '6jhuO1RmUzo0v6Nqydahc1': {
    stats: { polarity: 1, popMean: 4.8392857, popSd: 2.2429427 },
    forcedChoice: true,
    relevantAnswers: [],
  },
  jvMQzXAWY0KP1OTGKFLIP: {
    stats: { polarity: -1, popMean: 0.4077778, popSd: 0.6654714 },
    relevantAnswers: [
      '1nZPRgvb7hZSoh9uyyoQ90',
      '5rIkhfI0t7GT73ViiccZ02',
      '5zcxMDLVqtfyQkZB4WwN47',
      '2XP08NMD8SKlh8Y3fRia2O',
    ],
  },
  '2p6vrwO6WLutIOJaELdooB': {
    stats: { polarity: 1, popMean: 2.4318371, popSd: 1.1653917 },
    relevantAnswers: [
      '1nZPRgvb7hZSoh9uyyoQ90',
      '5rIkhfI0t7GT73ViiccZ02',
      '5zcxMDLVqtfyQkZB4WwN47',
      '2XP08NMD8SKlh8Y3fRia2O',
    ],
  },
  '3bisgKTV18oLJaRmpZ1Ds5': {
    stats: { polarity: -1, popMean: 0.3285714, popSd: 0.7813085 },
    relevantAnswers: [
      '6XT2I1ZoFm75UVX8MsCi2c',
      '78D2L2ytk5EJ3S5aFw2GNC',
      '3HHyEvN9N4tl2NAvVkizhs',
      '4nAziLnGfdrbFh1jjER8VL',
    ],
  },
  '28lDj4fBrNDoRZKgTKjGWK': {
    stats: { polarity: 1, popMean: 2.0068154, popSd: 0.8594883 },
    relevantAnswers: ['1sTfXi3ayfdYlCBXpPNWEw', '3bWmYirfiJZuL0f5W9Hc9R', '24lFbI6ZYdxZ9PHq9YevCI'],
  },
  '3VyCr6gbtMVPw145rCJbv6': {
    stats: { polarity: 1, popMean: 5.9303653, popSd: 2.3748425 },
    forcedChoice: true,
    relevantAnswers: [],
  },
  '4gmD1nhca5riUyPUOVXfiN': {
    stats: { polarity: 1, popMean: 1.4320404, popSd: 0.7982838 },
    relevantAnswers: ['28hUDae4tU5KI0aaO5RBq2', '6ctDKNReCfamMS51PAlnEK', '6Q0fbP339x7t17dMNTnIHq'],
  },
}

export const CONSCIENTIOUSNESS_SCORES: Scores = {
  '7HtDYbqxdvWQNgtE1v6vsb': {
    stats: { polarity: 1, popMean: 1.1176125, popSd: 0.77932 },
    relevantAnswers: ['phW0rtPlLr9iHeZEpa3Lm', '3y4LcCFLDHldZaHM8X5Hdu'],
  },
  jvMQzXAWY0KP1OTGKFLIP: {
    stats: { polarity: -1, popMean: 0.3257365, popSd: 0.5868026 },
    relevantAnswers: ['4pT4TbmCkkqRq0n2qzJZnL', '6fbwadi1xWNmzWaOZm7OTX', '2N6WSygHgtmWNZKLZyi7Ev'],
  },
  '2p6vrwO6WLutIOJaELdooB': {
    stats: { polarity: 1, popMean: 1.5064245, popSd: 0.9987041 },
    relevantAnswers: ['4pT4TbmCkkqRq0n2qzJZnL', '6fbwadi1xWNmzWaOZm7OTX', '2N6WSygHgtmWNZKLZyi7Ev'],
  },
  '3bisgKTV18oLJaRmpZ1Ds5': {
    stats: { polarity: -1, popMean: 0.4742268, popSd: 0.8898222 },
    relevantAnswers: [
      '5i1So6cMMndu07wW5L96gq',
      '1ORFb9drahN8CzY1fcd9CY',
      '7JSVCEfWWNoFRNr24vCwdF',
      '7f2gjHEeXFqcfJRmLWHXTV',
      '2JGVZKLzgaDphFOJFSySsl',
    ],
  },
  '28lDj4fBrNDoRZKgTKjGWK': {
    stats: { polarity: 1, popMean: 1.1860934, popSd: 0.8739249 },
    relevantAnswers: ['1sTfXi3ayfdYlCBXpPNWEw', '4cPFRAGandFl01pqnQOV0Z', 'NCEZPh2rCO7bClOCXEL9W'],
  },
  '3VyCr6gbtMVPw145rCJbv6': {
    stats: { polarity: -1, popMean: 5.9584296, popSd: 2.3703667 },
    forcedChoice: true,
    relevantAnswers: [],
  },
}

export const OPENNESS_SCORES: Scores = {
  '7HtDYbqxdvWQNgtE1v6vsb': {
    stats: { polarity: 1, popMean: 1.0532196, popSd: 0.7691415 },
    relevantAnswers: ['1FXmqsIWBEMMjzf3YAsbBT', '76V8I0msheeUKgiu2GDM7E'],
  },
  jvMQzXAWY0KP1OTGKFLIP: {
    stats: { polarity: -1, popMean: 0.5047248, popSd: 0.7567677 },
    relevantAnswers: [
      '5hDxoFDp38jNPHqT6ux9uH',
      '3bNkVnicRMUzzFnx1LaLmk',
      '5Mqs7AzfSARafMIAOJJC2P',
      '6VcQ2N6IUyJjDhLwA5D5Q3',
      '2XP08NMD8SKlh8Y3fRia2O',
    ],
  },
  '2p6vrwO6WLutIOJaELdooB': {
    stats: { polarity: 1, popMean: 2.8348857, popSd: 1.3694194 },
    relevantAnswers: [
      '5hDxoFDp38jNPHqT6ux9uH',
      '2XP08NMD8SKlh8Y3fRia2O',
      '6VcQ2N6IUyJjDhLwA5D5Q3',
      '5Mqs7AzfSARafMIAOJJC2P',
      '3bNkVnicRMUzzFnx1LaLmk',
    ],
  },
  '3bisgKTV18oLJaRmpZ1Ds5': {
    stats: { polarity: -1, popMean: 0.8144424, popSd: 0.9280284 },
    relevantAnswers: [
      'TGMsGc8drLogKCAN1Y3eJ',
      '5x7g8AsULWOQ1FnvmcGbs8',
      '5t4cDEy87GSAUsK6j7ngjj',
      '3bXWK882Ok1hFlYPJPSJTg',
      '7ihqJOznn8Mbplgdw1jqOr',
    ],
  },
  '28lDj4fBrNDoRZKgTKjGWK': {
    stats: { polarity: 1, popMean: 1.2613369, popSd: 0.6681804 },
    relevantAnswers: ['7HoXyu3dsVIE2aPUNiPyvI', '24lFbI6ZYdxZ9PHq9YevCI'],
  },
  '3VyCr6gbtMVPw145rCJbv6': {
    stats: { polarity: 1, popMean: 5.9584296, popSd: 2.3703667 },
    forcedChoice: true,
    relevantAnswers: [],
  },
}

export const STABILITY_SCORES: Scores = {
  '7HtDYbqxdvWQNgtE1v6vsb': {
    stats: { polarity: 1, popMean: 0.9024004, popSd: 0.8016948 },
    relevantAnswers: ['4vIuEgg9VhndjnqCi8bdlw', '5AnOY1gGIpbT1RMMqJiDiv'],
  },
  jvMQzXAWY0KP1OTGKFLIP: {
    stats: { polarity: -1, popMean: 0.4625048, popSd: 0.591594 },
    relevantAnswers: ['7br1A9x1ViQzjZPBK91BSq', '44MG1NpSwZSYhith5uQhMZ', '1nZPRgvb7hZSoh9uyyoQ90'],
  },
  '2p6vrwO6WLutIOJaELdooB': {
    stats: { polarity: 1, popMean: 1.1380952, popSd: 0.847185 },
    relevantAnswers: ['7br1A9x1ViQzjZPBK91BSq', '44MG1NpSwZSYhith5uQhMZ', '1nZPRgvb7hZSoh9uyyoQ90'],
  },
  '3bisgKTV18oLJaRmpZ1Ds5': {
    stats: { polarity: -1, popMean: 0.6267348, popSd: 0.8636001 },
    relevantAnswers: [
      '2TC1mcM7ecZMyfnZkJ5CQ6',
      '1fzjBt9ih65LLy1CWQ50kM',
      '7ihqJOznn8Mbplgdw1jqOr',
      '3bXWK882Ok1hFlYPJPSJTg',
    ],
  },
}

export const RDS_AGREEABLENESS_SCORES: Scores = {
  'work-style': {
    stats: { polarity: 1, popMean: 1.2020641, popSd: 0.7300111 },
    relevantAnswers: ['work-style-empathetic', 'work-style-respectful'],
  },
  'self-versus-others': {
    stats: { polarity: 1, popMean: 6.6156463, popSd: 2.0463949 },
    forcedChoice: true,
    relevantAnswers: [],
  },
  'rejection-reassurance': {
    stats: { polarity: 1, popMean: 5.2893519, popSd: 2.0585011 },
    forcedChoice: true,
    relevantAnswers: [],
  },
  'areas-of-improvement': {
    stats: { polarity: -1, popMean: 0.6877778, popSd: 0.718427 },
    relevantAnswers: [
      'areas-of-improvement-relationship-building',
      'areas-of-improvement-fostering-others',
      'areas-of-improvement-openness-to-opinions',
      'areas-of-improvement-displaying-vulnerability',
    ],
  },
  strengths: {
    stats: { polarity: 1, popMean: 1.5702411, popSd: 1.0711933 },
    relevantAnswers: [
      'strengths-relationship-building',
      '2xYb1EmBCw3faJDqAVtk6e',
      'strengths-open-to-opinions',
      'strengths-displaying-vulnerability',
    ],
  },
  'old-areas-of-improvement': {
    stats: { polarity: -1, popMean: 0.7732143, popSd: 1.1929179 },
    relevantAnswers: [
      'old-areas-of-improvement-working-with-others',
      'old-areas-of-improvement-communicating-with-others',
      'old-areas-of-improvement-openness-for-feedback',
      'old-areas-of-improvement-flexibility',
      'old-areas-of-improvement-handling-conflict',
      'old-areas-of-improvement-dealing-with-mistakes',
    ],
  },
  'teamwork-styles': {
    stats: { polarity: 1, popMean: 1.2295539, popSd: 0.9723319 },
    relevantAnswers: [
      'teamwork-styles-maintains-group-harmony',
      'teamwork-styles-listens-and-agrees',
      'teamwork-styles-jumps-in-as-supporter',
    ],
  },
  'results-vibes': {
    stats: { polarity: 1, popMean: 4.9340528, popSd: 2.1255296 },
    forcedChoice: true,
    relevantAnswers: [],
  },
  'work-values': {
    stats: { polarity: 1, popMean: 1.7105014, popSd: 0.9368449 },
    relevantAnswers: ['work-values-collaboration', 'work-values-participation', 'work-values-supportiveness'],
  },
}

export const RDS_EXTRAVERSION_SCORES: Scores = {
  'work-style': {
    stats: { polarity: 1, popMean: 1.2468767, popSd: 0.7001744 },
    relevantAnswers: ['work-style-positive', 'work-style-leading'],
  },
  'working-with-others': {
    stats: { polarity: 1, popMean: 5.1810748, popSd: 2.0343748 },
    forcedChoice: true,
    relevantAnswers: [],
  },
  'privacy-others-laughing': {
    stats: { polarity: 1, popMean: 4.8392857, popSd: 2.2429427 },
    forcedChoice: true,
    relevantAnswers: [],
  },
  'areas-of-improvement': {
    stats: { polarity: -1, popMean: 0.4077778, popSd: 0.6654714 },
    relevantAnswers: [
      'areas-of-improvement-acting-with-confidence',
      'areas-of-improvement-proactivity',
      'areas-of-improvement-relationship-building',
      'areas-of-improvement-offering-ideas',
    ],
  },
  strengths: {
    stats: { polarity: 1, popMean: 2.4318371, popSd: 1.1653917 },
    relevantAnswers: [
      'strengths-acting-with-confidence',
      'strengths-proactivity',
      'strengths-relationship-building',
      'strengths-offering-ideas',
    ],
  },
  'old-areas-of-improvement': {
    stats: { polarity: -1, popMean: 0.3285714, popSd: 0.7813085 },
    relevantAnswers: [
      'old-areas-of-improvement-working-with-others',
      'old-areas-of-improvement-communicating-with-others',
      'old-areas-of-improvement-showing-initiative',
      'old-areas-of-improvement-taking-ownership',
    ],
  },
  'teamwork-styles': {
    stats: { polarity: 1, popMean: 2.0068154, popSd: 0.8594883 },
    relevantAnswers: [
      'teamwork-styles-directs-and-organizes',
      'teamwork-styles-motivates-to-take-action',
      'teamwork-styles-participates-in-idea-generation',
    ],
  },
  'culture-add': {
    stats: { polarity: 1, popMean: 5.9303653, popSd: 2.3748425 },
    forcedChoice: true,
    relevantAnswers: [],
  },
  'work-values': {
    stats: { polarity: 1, popMean: 1.4320404, popSd: 0.7982838 },
    relevantAnswers: ['work-values-competition', 'work-values-growth', 'work-values-competence'],
  },
}

export const RDS_CONSCIENTIOUSNESS_SCORES: Scores = {
  'work-style': {
    stats: { polarity: 1, popMean: 1.1176125, popSd: 0.77932 },
    relevantAnswers: ['work-style-detailed', 'work-style-dependable'],
  },
  'areas-of-improvement': {
    stats: { polarity: -1, popMean: 0.3257365, popSd: 0.5868026 },
    relevantAnswers: [
      'areas-of-improvement-planning-and-prioritizing',
      'areas-of-improvement-attention-to-detail',
      'areas-of-improvement-quick-delivery',
    ],
  },
  strengths: {
    stats: { polarity: 1, popMean: 1.5064245, popSd: 0.9987041 },
    relevantAnswers: [
      'strengths-planning-and-prioritizing',
      'strengths-attention-to-detail',
      'strengths-quick-delivery',
    ],
  },
  'old-areas-of-improvement': {
    stats: { polarity: -1, popMean: 0.4742268, popSd: 0.8898222 },
    relevantAnswers: [
      'old-areas-of-improvement-quality-of-work',
      'old-areas-of-improvement-quantity-of-work',
      'old-areas-of-improvement-planning',
      'old-areas-of-improvement-efficiency',
      'old-areas-of-improvement-prioritization',
    ],
  },
  'teamwork-styles': {
    stats: { polarity: 1, popMean: 1.1860934, popSd: 0.8739249 },
    relevantAnswers: [
      'teamwork-styles-directs-and-organizes',
      'teamwork-styles-coordinates-activities',
      'teamwork-styles-performs-routine-activities',
    ],
  },
  'culture-add': {
    stats: { polarity: -1, popMean: 5.9584296, popSd: 2.3703667 },
    forcedChoice: true,
    relevantAnswers: [],
  },
}

export const RDS_OPENNESS_SCORES: Scores = {
  'work-style': {
    stats: { polarity: 1, popMean: 1.0532196, popSd: 0.7691415 },
    relevantAnswers: ['work-style-clever', 'work-style-creative'],
  },
  'areas-of-improvement': {
    stats: { polarity: -1, popMean: 0.5047248, popSd: 0.7567677 },
    relevantAnswers: [
      'areas-of-improvement-openness-to-opinions',
      'areas-of-improvement-technical-expertise',
      'areas-of-improvement-solving-tricky-issues',
      'areas-of-improvement-fast-learning',
      'areas-of-improvement-offering-ideas',
    ],
  },
  strengths: {
    stats: { polarity: 1, popMean: 2.8348857, popSd: 1.3694194 },
    relevantAnswers: [
      'strengths-open-to-opinions',
      'strengths-offering-ideas',
      'strengths-fast-learning',
      'strengths-solving-tricky-issues',
      'strengths-technical-expertise',
    ],
  },
  'old-areas-of-improvement': {
    stats: { polarity: -1, popMean: 0.8144424, popSd: 0.9280284 },
    relevantAnswers: [
      'old-areas-of-improvement-flexibility',
      'old-areas-of-improvement-openness-for-feedback',
      'old-areas-of-improvement-domain-expertise',
      'old-areas-of-improvement-coming-up-with-ideas',
      'old-areas-of-improvement-handling-uncertainty',
    ],
  },
  'teamwork-styles': {
    stats: { polarity: 1, popMean: 1.2613369, popSd: 0.6681804 },
    relevantAnswers: ['teamwork-styles-takes-a-critical-view', 'teamwork-styles-participates-in-idea-generation'],
  },
  'culture-add': {
    stats: { polarity: 1, popMean: 5.9584296, popSd: 2.3703667 },
    forcedChoice: true,
    relevantAnswers: [],
  },
}

export const RDS_STABILITY_SCORES: Scores = {
  'work-style': {
    stats: { polarity: 1, popMean: 0.9024004, popSd: 0.8016948 },
    relevantAnswers: ['work-style-balanced', 'work-style-calm'],
  },
  'areas-of-improvement': {
    stats: { polarity: -1, popMean: 0.4625048, popSd: 0.591594 },
    relevantAnswers: [
      'areas-of-improvement-coping-with-pressure',
      'areas-of-improvement-displaying-vulnerability',
      'areas-of-improvement-acting-with-confidence',
    ],
  },
  strengths: {
    stats: { polarity: 1, popMean: 1.1380952, popSd: 0.847185 },
    relevantAnswers: [
      'strengths-coping-with-pressure',
      'strengths-displaying-vulnerability',
      'strengths-acting-with-confidence',
    ],
  },
  'old-areas-of-improvement': {
    stats: { polarity: -1, popMean: 0.6267348, popSd: 0.8636001 },
    relevantAnswers: [
      'old-areas-of-improvement-handling-conflict',
      'old-areas-of-improvement-dealing-with-mistakes',
      'old-areas-of-improvement-handling-uncertainty',
      'old-areas-of-improvement-flexibility',
    ],
  },
}
