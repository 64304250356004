import { Footer, LogoBanner } from '@common/components'
import React, { useEffect } from 'react'
import * as tracking from '../../core/track/candidate-portal'
import classes from './CandidateShell.module.scss'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import * as selectors from 'selectors'
import { useLocation } from 'react-router-dom'
import { ReferenceCheckLocale } from '@common/models/locales'

interface Props {
  orgLogoUrl?: string
  brandingUrl?: string
  fakeMode?: boolean
  roleId?: string
  children?: React.ReactNode
}

export const CandidateShell: React.FC<Props> = props => {
  const { i18n } = useTranslation()
  const location = useLocation()

  const role = useSelector((state: RootState) => selectors.roles.findById(state, props.roleId || ''))

  const isManageReferences = location && location.pathname.includes('manage-references')

  useEffect(() => {
    // This is needed here, because in case the candidate is coming from a public link, this is the first page that sees
    // so the i18n is not yet initialized
    // Also, consideration here, for mixed roles (assessment and ref check) we are always giving priority to assessment
    // language, since assessment is always the first step in a process.
    if (role?.fields.assessment_id && role?.fields.assessment_locale) {
      i18n.changeLanguage(role.fields.assessment_locale)
    } else if (role?.fields.reference_check_locales && role?.fields.reference_check_locales.length > 0) {
      i18n.changeLanguage(role?.fields.reference_check_locales[0])
    }
  }, [])

  return (
    <div className={classes.container}>
      <div className={classes.main}>
        <div className={classes.content}>
          {props.orgLogoUrl && (
            <div className={classes.contentItem}>
              <LogoBanner src={props.orgLogoUrl} />
            </div>
          )}
          <div className={classes.contentItem}>{props.children}</div>
        </div>
        <Footer
          enableLanguagePicker={isManageReferences}
          availableLanguages={role?.fields.reference_check_locales as ReferenceCheckLocale[]}
          tracking={{
            clickOnHipeople: () => {
              tracking.clickOnHipeople()
            },
            support: () => {
              tracking.support()
            },
          }}
          supportLink={'https://intercom.help/hipeople/en/collections/9116793-candidates'}
        />
      </div>
      {props.brandingUrl && <img className={classes.brandingImage} src={props.brandingUrl} alt={'Branding'} />}
    </div>
  )
}
