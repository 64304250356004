export const RESOURCE = 'form_responses'

export interface Fields {
  id: string
  content_id: string
  assessment_version_id: string
  content: Row[]
  created_at: number
  updated_at: number
}

export interface Row {
  item_id: string
  skipped?: boolean
  input?: string
  value?: number
  selected?: string[]
  url?: string
}
