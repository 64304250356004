import { height, px2rem, scale, size, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import React from 'react'
import { Relationship } from 'selectors/references'
import { ReferenceAnswerSidebar, ReferenceAnswerSidebarStyle, ReferenceDetails } from './ReferenceAnswerSidebar'

const Sidebar = style()
  .flex({
    grow: '0',
    shrink: '0',
    basis: px2rem(200),
    direction: 'column',
    alignItems: 'flex-start',
  })
  .size({ maxWidth: px2rem(200) })
  .select(`> ${ReferenceAnswerSidebarStyle}`, style().size({ height: size.fill }))
  .element()

const Main = style()
  .relative()
  .spacing({ inner: px2rem(16), innerBottom: px2rem(24) })
  .flex({ grow: '1', shrink: '0', basis: '0', direction: 'column' })
  .select('> * + *', style().spacing({ outerTop: px2rem(12) }))
  .element()

const ReferenceAnswerStyle = style()
  .flex()
  .sans({ size: scale.s, height: height.s })
  .cursor('default')
  .cond(({ onClick }: { onClick: boolean }) => onClick, style().pointer())
  .cond(
    ({ hasHighlightedBackground }: { hasHighlightedBackground: boolean }) => hasHighlightedBackground,
    style().color({ bg: designSystemColors.backgroundNeutralSecondary }),
  )
  .element()

interface ReferenceAnswerProps {
  reference: ReferenceDetails
  candidateName: string
  children?: React.ReactNode
}

export const ReferenceAnswer: React.FC<ReferenceAnswerProps> = ({ children, reference, candidateName }) => {
  const isSelfReference = reference.relationship === Relationship.Self
  return (
    <ReferenceAnswerStyle hasHighlightedBackground={isSelfReference}>
      <Sidebar>
        <ReferenceAnswerSidebar reference={reference} candidateName={candidateName} />
      </Sidebar>
      <Main>{children}</Main>
    </ReferenceAnswerStyle>
  )
}
