import { Link, Text, TextBold } from 'App/CandidateOnly/Styles'
import Button from 'components/Button/ButtonV2'
import { px2rem, space, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import * as tracking from 'core/track'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import * as selectors from 'selectors'
import { RootState } from 'store'

const Container = style()
  .size({ maxWidth: px2rem(590), width: '100%' })
  .select('> * + *', style().spacing({ outerTop: space.m }))
  .element()

const InputRow = style()
  .grid({ columns: 'repeat(auto-fit, minmax(250px, 1fr))' })
  .spacing({ columns: space.s, outerTop: px2rem(60) })
  .element()

const PolicyLink = style().text({ underline: true }).fg(designSystemColors.typographyPrimary).element('a')

const Component = () => {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const { candidateid } = useParams<{ candidateid: string }>()

  const searchParams = new URLSearchParams(location.search)
  const fromPublic = searchParams.get('from-public')

  const org = useSelector((state: RootState) => selectors.orgs.findByCandidateId(state, candidateid || ''))
  const role = useSelector((state: RootState) => selectors.roles.findByCandidateId(state, candidateid || ''))
  const privacyPolicyLink = useSelector((state: RootState) => selectors.orgs.privacyPolicyLink(state, org?.id || ''))

  useEffect(() => {
    if (role?.fields.assessment_locale) {
      i18n.changeLanguage(role?.fields.assessment_locale)
    }
  }, [])

  return (
    <Container>
      <Text>
        {t('candidate-dashboard-intro.privacy-policy.title', 'By continuing, you agree on the following statement:')}
      </Text>
      <Text>
        <TextBold>
          {t(
            'candidate-dashboard-intro.privacy-policy.text',
            'Hereby I consent that references to my person are collected at the sources proposed by me as well as to the corresponding processing of my personal data regarding the evaluation of my competencies. Information about my rights in connection with this consent can be found in ',
          )}
          <PolicyLink href={privacyPolicyLink} target="_blank" rel="noopener noreferrer">
            {t('candidate-dashboard-intro.privacy-policy.link', 'this privacy policy')}
          </PolicyLink>
          .
        </TextBold>
      </Text>
      <InputRow>
        {!fromPublic && (
          <Button
            buttonType={'secondary'}
            isFullWidth
            onClick={() => {
              tracking.candidatePortal.legalConsentDecline()
              history.goBack()
            }}
          >
            {t('candidate-dashboard-intro.privacy-policy.decline', 'Go back')}
          </Button>
        )}
        <Link
          to={{
            pathname: `/candidates/${candidateid}/network`,
            search: location.search,
          }}
          onClick={() => tracking.candidatePortal.legalConsentGive()}
        >
          <Button buttonType={'confirm'} isFullWidth>
            {t('candidate-dashboard-intro.privacy-policy.agree', 'I agree')}
          </Button>
        </Link>
      </InputRow>
    </Container>
  )
}

export default Component
Component.displayName = 'LawyerConfirmation'
