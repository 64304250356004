function rgba(r: number, g: number, b: number, alpha: number = 1): string {
  return alpha ? `rgb(${r}, ${g}, ${b}, ${alpha})` : `rgb(${r}, ${g}, ${b})`
}
export default class Skin {
  name = 'default'

  white = rgba(255, 255, 255)
  black = rgba(25, 25, 25)
  sand = rgba(241, 242, 237)
  red = rgba(228, 81, 73)
  alertRed = rgba(235, 110, 110)
  darkRed = rgba(235, 110, 110)
  pink = rgba(223, 194, 233)
  lavender = rgba(208, 221, 244)
  orange = rgba(255, 205, 156)
  darkOrange = rgba(255, 128, 66)
  sky = rgba(194, 237, 240)
  darkSky = rgba(94, 137, 140)
  lightGreen = rgba(150, 226, 182)
  lightestGreen = rgba(188, 236, 208)
  green = rgba(121, 218, 162)
  darkGreen = rgba(20, 132, 87)
  brightGreen = rgba(63, 179, 117)
  lightBlue = rgba(199, 232, 234)
  blue = rgba(127, 163, 245)
  darkBlue = rgba(78, 78, 155)
  dusk = rgba(85, 176, 201, 1)
  lightYellow = rgba(230, 150, 0, 0.5)
  yellow = rgba(230, 150, 0)
  yolk = rgba(251, 231, 177)
  nearWhite = rgba(249, 249, 249)
  lightestGray = rgba(242, 242, 242)
  lighterGray = rgba(224, 224, 224)
  lightGray = rgba(148, 148, 148)
  lightMoonGray = rgba(228, 228, 228)
  moonGray = rgba(204, 204, 204)
  lightSilver = rgba(170, 170, 170)
  silver = rgba(157, 157, 157)
  darkSilver = rgba(123, 123, 123)
  gray = rgba(102, 102, 102)
  midGray = rgba(80, 80, 80)
  darkGray = rgba(51, 51, 51)
  transparent = 'transparent'

  primaryBg = this.brightGreen
  primaryFg = this.white
  secondaryBg = this.lightGreen
  secondaryFg = this.white
  tertiaryFg = this.gray
  tertiaryBg = this.lighterGray
  bg = this.white
  fg = this.black
  sidebarBg = this.sand
  sidebarFg = this.black
  verified = rgba(74, 178, 247)
  barChartFillColor = this.pink
  barChartBgColor = this.lightMoonGray
  errorBgColor = this.alertRed
  dangerBgColor = this.red
  dangerDisabledBgColor = rgba(228, 81, 73, 0.5)

  purple = 'var(--brand-primary)'
  purpleDark = '#4c49ee'
}

export const DEFAULT_SKIN = new Skin()
