import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as api from 'api'
import { AppThunk } from 'core/store'
import { addEntities } from 'store/resources'
import { add as notify } from '../notifications'

interface State {
  managers: api.request.Entity<api.users.Fields>[]
}

const initialState: State = {
  managers: [],
}

const slice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setManagers(state, action: PayloadAction<api.request.Entity<api.users.Fields>[]>) {
      state.managers = action.payload
    },
  },
})

export const { setManagers } = slice.actions

export default slice.reducer

export const loadManagers =
  (orgId: string): AppThunk =>
  async dispatch => {
    const [response, errors] = await api.users.loadActiveByOrgId(orgId)
    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    if (response) {
      dispatch(addEntities(response))
      dispatch(setManagers(response.result))
    }
  }
