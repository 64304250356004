import HeroScore from 'components/Scores/HeroScore'
import ScoreBar from 'components/Scores/ScoreBar'
import { px2rem, size, space, style } from 'core'
import { text } from 'core/design-system/text'
import { useLeadershipSummaries } from 'providers/candidate-summaries'
import { useSelectedCandidate } from 'providers/candidates'
import { useModule } from 'providers/modules'
import React from 'react'
import { useParams } from 'react-router-dom'
import { LEADERSHIP_SKILL_QUESTION } from 'store/new-role/content'
import { SummaryContainer } from './SummaryContainer'

const LeadershipSkillsStyle = style()
  .flex({ direction: 'column' })
  .spacing({ gap: px2rem(16) })
  .element()

const LabelContainerStyle = style()
  .flex({ direction: 'column' })
  .spacing({ gap: px2rem(8) })
  .element()

interface LeadershipSkillsBlockUIProps {
  overallLeadershipScore: number
  establishFocusScore: number
  buildingRelationshipScore: number
  inspireOthersScore: number
  detailsUrl?: string
}

const LeadershipSkillsBlockUI: React.FC<LeadershipSkillsBlockUIProps> = ({
  establishFocusScore,
  buildingRelationshipScore,
  inspireOthersScore,
  overallLeadershipScore,
  detailsUrl,
}) => {
  return (
    <SummaryContainer title={`Leadership Skills`} emoji={`⭐️`} hintColor={'orange'} to={detailsUrl}>
      <LeadershipSkillsStyle>
        {overallLeadershipScore !== null && <HeroScore score={Math.round(overallLeadershipScore * 10)} max={100} />}
        <LabelContainerStyle>
          <LabelScore label={'Establish focus'} score={establishFocusScore} />
          <LabelScore label={'Relationship building'} score={buildingRelationshipScore} />
          <LabelScore label={'Inspire others'} score={inspireOthersScore} />
        </LabelContainerStyle>
      </LeadershipSkillsStyle>
    </SummaryContainer>
  )
}

LeadershipSkillsBlockUI.displayName = 'LeadershipSkillsBlock'

const LabelScoreContainer = style()
  .grid({ rows: [size.s, px2rem(20)] })
  .spacing({ rows: px2rem(2), outer: [space.auto, space.none] })
  .element()

const Label = text.label().element()

interface LabelScoreProps {
  score: number | null
  label: string
}

const LabelScore: React.FC<LabelScoreProps> = ({ score, label }) => {
  if (score === null) return <></>

  return (
    <LabelScoreContainer>
      <Label>{label}</Label>
      {/* score is divided by two, as we currently store all our scores
        out of 10, but the bar's max is 5 */}
      <ScoreBar score={score / 2} />
    </LabelScoreContainer>
  )
}

interface LeadershipSkillsBlockProps {
  container?: React.FC
}

const LeadershipSkillsBlock: React.FC<LeadershipSkillsBlockProps> = ({ container }) => {
  const { candidate } = useSelectedCandidate()
  const Container = container || React.Fragment

  const { establishFocusScore, buildingRelationshipScore, inspireOthersScore, overallLeadershipScore } =
    useLeadershipSummaries({
      candidateId: candidate?.id,
    })

  const module = useModule({ slug: 'leadership-skills' })
  const targetId = candidate?.fields.questionnaire_id ? module?.id : LEADERSHIP_SKILL_QUESTION.test_item_id
  const { roleid, candidateid } = useParams<{
    roleid: string
    candidateid: string
  }>()
  const detailsUrl = `/roles/${roleid}/candidates/${candidateid}/responses#${targetId}`

  if (!establishFocusScore || !buildingRelationshipScore || !inspireOthersScore || !overallLeadershipScore) {
    return <></>
  }
  return (
    <Container>
      <LeadershipSkillsBlockUI
        establishFocusScore={establishFocusScore}
        buildingRelationshipScore={buildingRelationshipScore}
        inspireOthersScore={inspireOthersScore}
        overallLeadershipScore={overallLeadershipScore}
        detailsUrl={detailsUrl}
      />
    </Container>
  )
}

export default LeadershipSkillsBlock
