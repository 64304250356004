import React from 'react'
import classes from './Button.module.scss'

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant
  children: React.ReactNode
  size?: 'sm' | 'md' | 'lg' | 'auto'
  rounded?: boolean
  loading?: boolean
  bordered?: boolean
}

export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'tertiary-accent'
  | 'accent'
  | 'success'
  | 'danger'
  | 'warning'
  | 'purple'
  | 'purple-dark'
  | 'light'
  | 'ghost'
  | 'icon'

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, variant, loading, size, className, rounded, bordered, ...rest }, ref) => {
    return (
      <button
        ref={ref}
        className={[
          classes.button,
          classes[variant ?? 'primary'],
          size ? classes[size] : '',
          rounded ? classes.rounded : '',
          className,
        ].join(' ')}
        {...rest}
      >
        {loading ? 'Loading...' : children}
      </button>
    )
  },
)

Button.displayName = 'Button'
