import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'

export const RawScoreContainer = text
  .bodyText()
  .color({
    bg: designSystemColors.backgroundNeutralSecondary,
    fg: designSystemColors.typographySecondary,
  })
  .border({
    radius: '0.3rem',
  })
  .text({
    size: '1.15rem',
    weight: '400',
    align: 'center',
  })
  .spacing({
    inner: '0.5rem 1.275rem 0.5rem 1.275rem',
  })
  .size({
    height: 'fit-content',
    width: '136px',
    minWidth: '8.5rem',
  })
  .element('div')

export const AchievedScore = text
  .bodyText()
  .text({
    size: '30px',
    weight: '500',
  })
  .color({
    fg: designSystemColors.typographyPrimary,
  })
  .element('span')
