import { TopBar } from '@common/components'
import DashboardLayout, { Content } from 'components/DashboardLayout'
import React from 'react'
import * as tracking from '../../core/track'

interface ShellProps {
  children: JSX.Element | JSX.Element[]
}

const Shell: React.FC<ShellProps> = ({ children }) => {
  return (
    <DashboardLayout>
      <TopBar tracking={tracking} />
      <Content>{children}</Content>
    </DashboardLayout>
  )
}

export default Shell
