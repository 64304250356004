import { put } from './methods'
import { APIResponse, Entity } from './request'

export const RESOURCE = 'profiles'

export interface Fields {
  id: string
  user_id: string
  full_name: string
  location: string
  profile_picture_url: string
}

export function empty(id: string): Entity<Fields> {
  return {
    id,
    resource: RESOURCE,
    fields: {
      id,
      user_id: '',
      full_name: '',
      location: '',
      profile_picture_url: '',
    },
  }
}

export function updateName(id: string, name: string): Promise<APIResponse<Entity<Fields>>> {
  return put<Entity<Fields>>(`profiles/${id}/name`, {
    name,
  })
}

export function updateLocation(id: string, location: string): Promise<APIResponse<Entity<Fields>>> {
  return put<Entity<Fields>>(`profiles/${id}/location`, {
    location,
  })
}

export function updateProfileImage(id: string, file: File): Promise<APIResponse<Entity<Fields>>> {
  const formData = new FormData()
  formData.append('file', file)

  return put<Entity<Fields>>(`profiles/${id}/image`, {}, formData)
}
