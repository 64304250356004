import React from 'react'
import { Link } from 'react-router-dom'
import { Caption } from '../../../components/ContentGrid'
import { BrandIcon, Icon } from '../../../components/Icons'
import { colors, size, space, style } from '../../../core'
import { ProfessionalNetwork } from '../../../selectors'

const Container = style().element()

const ExternalIcon = style().sans({ size: size.xs }).element()

const Network = style().color({ fg: colors.lightGray }).flex({ alignItems: 'center' }).element()

const Label = style().spacing({ outerLeft: space.xxs }).element('span')

interface Props {
  name: string
  link: string
  network?: ProfessionalNetwork & { url: string }
}

const Component = (props: Props) => (
  <Container className="data-hj-suppress">
    <Link to={props.link}>{props.name}</Link>
    {props.network ? (
      <Caption>
        <a href={props.network.url} target="_blank" rel="noopener noreferrer">
          <Network>
            <ExternalIcon>
              {props.network.icon ? (
                <Icon name={props.network.icon || ''} />
              ) : (
                <BrandIcon name={props.network.brandIcon || ''} />
              )}
            </ExternalIcon>
            <Label>{props.network.name || 'Professional network'}</Label>
          </Network>
        </a>
      </Caption>
    ) : null}
  </Container>
)

export default Component
Component.displayName = 'NameAndNetwork'
