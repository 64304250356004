import * as api from '../api'
import { Entity } from '../api/request'
import { RootState } from '../store'
import lookup from './lookup'

export function getById(state: RootState, id: string): Entity<api.webhooks.Fields> | undefined {
  return lookup(state, api.webhooks.RESOURCE, id)
}

export function getSanitizedById(state: RootState, id: string): Entity<api.webhooks.Fields> | undefined {
  return sanitize(lookup(state, api.webhooks.RESOURCE, id) || api.webhooks.empty(id))
}

function sanitize(webhook: api.request.Entity<api.webhooks.Fields>): api.request.Entity<api.webhooks.Fields> {
  const sanitized = {
    ...webhook,
    fields: {
      ...webhook?.fields,
    },
  }

  switch (sanitized.fields.resource) {
    case 'candidates':
      sanitized.fields.resource = 'Candidates'
      break
    case 'references':
      sanitized.fields.resource = 'References'
      break
    case 'open_job_roles':
      sanitized.fields.resource = 'Roles'
      break
  }

  return sanitized
}
