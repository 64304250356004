import * as selectors from 'selectors'
import { RootState } from 'store'
import * as content from 'store/new-role/content'

export const FeedbackStyle = {
  Critical: 'Critical',
  Balanced: 'Balanced',
  Praising: 'Praising',
} as const

export type FeedbackStyleType = keyof typeof FeedbackStyle

export function findFeedbackStyleByReferenceId(state: RootState, referenceId: string): FeedbackStyleType | null {
  const score = findHaloScoreByReferenceId(state, referenceId)
  if (score === null) return null

  if (score >= 0.93) return FeedbackStyle.Praising
  if (score <= -1) return FeedbackStyle.Critical

  return FeedbackStyle.Balanced
}

type Statistics = {
  [key: string]: { mean: number; sd: number }
}

// A score requires at least 3 items to be deemed reliable
const Z_SCORE_MINIMUM = 3

function findHaloScoreByReferenceId(state: RootState, referenceId: string): number | null {
  const reference = selectors.references.getById(state, referenceId)
  if (!reference) return null

  if (!isCandidateRoleApplicable(state, reference.fields.candidate_id)) {
    return null
  }

  const candidate = selectors.candidates.findById(state, reference.fields.candidate_id)
  if (!candidate) return null

  if (candidate.fields.questionnaire_id) return findRDSHaloScoreByReferenceId(state, referenceId)

  const essentialsCounts = findEssentialsCountsByContentId(state, referenceId, reference.fields.candidate_id)
  const skillCounts = findSkillCountsByContentId(state, referenceId, reference.fields.candidate_id)

  const zScores = calculateZScoreEssentials({
    ...essentialsCounts,
    ...skillCounts,
  })
  const zScoresGenerated = Object.keys(zScores).length
  if (zScoresGenerated <= Z_SCORE_MINIMUM) {
    return null
  }

  let scoreSum = 0
  for (const id of Object.keys(zScores)) {
    scoreSum += zScores[id]
  }

  return scoreSum / zScoresGenerated
}

function findRDSHaloScoreByReferenceId(state: RootState, referenceId: string): number | null {
  const reference = selectors.references.findById(state, referenceId)
  if (!reference) return null

  const essentialsCounts = findRDSEssentialsCountsByContentId(state, referenceId, reference.fields.candidate_id)

  const skillCounts = findRDSSkillCountsByContentId(state, referenceId, reference.fields.candidate_id)

  const zScores = calculateRDSZScoreEssentials({
    ...essentialsCounts,
    ...skillCounts,
  })
  const zScoresGenerated = Object.keys(zScores).length
  if (zScoresGenerated <= Z_SCORE_MINIMUM) {
    return null
  }

  let scoreSum = 0
  for (const id of Object.keys(zScores)) {
    scoreSum += zScores[id]
  }

  return scoreSum / zScoresGenerated
}

// roles created before the 12th of November are ignored as before
// this point we had a cap on the number of Chips that were selectable
// which would negatively skew the scores
const CUT_OFF_TIMESTAMP = new Date('2020-11-12').getTime() * 1000

function isCandidateRoleApplicable(state: RootState, candidateId: string): boolean {
  const role = selectors.roles.findByCandidateId(state, candidateId)
  if (!role) return false

  return role?.fields.created_at > CUT_OFF_TIMESTAMP
}

// the counts for essentials questions can be based on two things
// 1. the value of the selected value - an example of this is the
// overall performance question, where we take the value of the
// selected option.
// 2. the number of chips selected - because the chips questions are
// positvely spun, the more items selected is an indicator of the
// more positive the response (eg. Work styles)
function findEssentialsCountsByContentId(
  state: RootState,
  referenceId: string,
  candidateId: string,
): { [key: string]: number | null } {
  const counts: { [key: string]: number | null } = {}

  // We need to get all the questions that are relevant to the bias detection from the
  // assessment version, as for some reasons questions that are left blank do not always
  // appear in the form responses.
  const relevantAskedQuestions = selectors.assessmentVersions.findAskedQuestionsByCandidateId(
    state,
    candidateId,
    ...BIAS_DETECTION_QUESTIONS,
  )

  for (const question of relevantAskedQuestions) {
    const response = selectors.formResponses.findAnswerByReferenceId(state, referenceId, question)

    // check value first
    if (response?.value) {
      counts[question] = response.value
      continue
    }

    if (response?.selected?.length) {
      counts[question] = response.selected.length
      continue
    }

    // If nothing is selected, and there's no value - assume it's skipped.
    // If Areas of Improvement is skipped, still factor this in because
    // this is a positive indicator of the candidate. We're checking both
    // skipped or if no value is selected because there are cases where
    // the reference giver will not explicitly skip the question - but
    // simply won't give an answer.
    if (NEGATIVE_QUESTIONS.includes(question)) {
      counts[question] = 0
    } else {
      counts[question] = null
    }
  }

  return counts
}

function findRDSEssentialsCountsByContentId(
  state: RootState,
  referenceId: string,
  candidateId: string,
): { [key: string]: number | null } {
  const counts: { [key: string]: number | null } = {}

  const questionnaire = selectors.questionnaires.findQuestionnaireByCandidateId(state, candidateId)
  if (!questionnaire) return {}

  const { questions, answers } = questionnaire

  const referenceAnswers = answers[referenceId]
  if (!referenceAnswers) return counts

  const relevantQuestions: typeof questions = []
  for (const question of questions) {
    if (question.parent_id) continue

    const module = selectors.modules.findByQuestionId(state, question.id)
    if (!module) continue

    if (RDS_BIAS_DETECTION_MODULES.includes(module?.fields.slug)) {
      relevantQuestions.push(question)
    }
  }

  for (const question of relevantQuestions) {
    // I included this check as before we could just infer that if a value was
    // present that value was a part of the calculation because values were only
    // assigned to response options where it made sense, however no every single
    // response option has a value of 0, so there's no way of filtering out
    // irrelevant response options based on the response option alone.
    if (!['optionset', 'chips'].includes(question.response_type)) continue

    const module = selectors.modules.findByQuestionId(state, question.id)
    if (!module) continue

    const answers = referenceAnswers.filter(ans => ans.question_id === question.id)

    if (question.response_type === 'optionset') {
      const responseOption = selectors.responseOptions.findById(state, answers?.[0]?.selected_response_option)
      if (!responseOption) continue

      counts[module.fields.slug] = responseOption?.fields.value
      continue
    }

    if (answers.length > 0) {
      counts[module.fields.slug] = answers.length
      continue
    }

    if (NEGATIVE_QUESTIONS.includes(module.fields.slug)) {
      counts[module.fields.slug] = 0
    } else {
      counts[module.fields.slug] = null
    }
  }

  return counts
}

// the "counts" for skills are different, instead of being based on the number
// of essentials, they're based on the value - ie. a score of 8/10 is equal to eight
// of 10 essentials selected - it's also different because we don't care about
// skipped skill questions - they don't factor into our bias score at all
function findSkillCountsByContentId(
  state: RootState,
  referenceId: string,
  candidateId: string,
): { [key: string]: number | null } {
  const questions = selectors.formResponses.findAllSkillAnswersByReferenceId(state, referenceId, candidateId)
  if (!questions) return {}

  const counts: { [key: string]: number | null } = {}
  for (const question of questions) {
    if (question.value !== undefined) {
      counts[question.item_id] = question.value
    }
  }

  return counts
}

// see comment above findSkillCountsByContentId
function findRDSSkillCountsByContentId(
  state: RootState,
  referenceId: string,
  candidateId: string,
): { [key: string]: number | null } {
  const questionnaire = selectors.questionnaires.findQuestionnaireByCandidateId(state, candidateId)
  if (!questionnaire) return {}

  const { questions } = questionnaire
  const questionAnswers = questionnaire.answers[referenceId]
  if (!questionAnswers) return {}

  const counts: { [key: string]: number | null } = {}
  for (const question of questions.filter(q => q.skill_id)) {
    const answer = questionAnswers.find(ans => ans.question_id === question.id && ans.selected_response_option)
    if (!answer) continue

    const responseOption = selectors.responseOptions.findById(state, answer.selected_response_option)
    if (!responseOption) continue

    if (responseOption.fields.value) {
      counts[question.id] = responseOption.fields.value
    }
  }

  return counts
}

// Basically the "halo-score" is the average of the
// z-score for each specific question. We just compare
// how far away each score is to the mean, and compare
// that to the standard deviation. For essentials
function calculateZScoreEssentials(counts: { [key: string]: number | null }): {
  [key: string]: number
} {
  const zScore: { [key: string]: number } = {}
  for (const id in counts) {
    const count = counts[id]
    if (count === null) continue

    // Basically if the item_id isn't in POPULATION_ANSWER_COUNT_STATISTICS, we'll assume it's a skill question
    const stat = POPULATION_ANSWER_COUNT_STATISTICS[id] || POPULATION_ANSWER_COUNT_STATISTICS[SKILL_KEY]
    zScore[id] = (count - stat.mean) / stat.sd

    // Areas of improvement gets special treatment, which inverses it
    if (NEGATIVE_QUESTIONS.includes(id)) {
      zScore[id] = zScore[id] * -1
    }
  }

  return zScore
}

// Basically the "halo-score" is the average of the
// z-score for each specific question. We just compare
// how far away each score is to the mean, and compare
// that to the standard deviation. For essentials
function calculateRDSZScoreEssentials(counts: { [key: string]: number | null }): {
  [key: string]: number
} {
  const zScore: { [key: string]: number } = {}
  for (const id in counts) {
    const count = counts[id]
    if (count === null) continue

    // Basically if the item_id isn't in POPULATION_ANSWER_COUNT_STATISTICS, we'll assume it's a skill question
    const stat = RDS_POPULATION_ANSWER_COUNT_STATISTICS[id] || RDS_POPULATION_ANSWER_COUNT_STATISTICS[SKILL_KEY]
    zScore[id] = (count - stat.mean) / stat.sd

    // Areas of improvement gets special treatment, which inverses it
    if (NEGATIVE_QUESTIONS.includes(id)) {
      zScore[id] = zScore[id] * -1
    }
  }

  return zScore
}

const SKILL_KEY = 'skill'

const POPULATION_ANSWER_COUNT_STATISTICS: Statistics = {
  [content.TEAMWORK_STYLE_QUESTION.test_item_id]: {
    mean: 4.334082,
    sd: 1.808063,
  },
  [content.STRENGTHS_QUESTION.test_item_id]: {
    mean: 7.102703,
    sd: 2.8258769,
  },
  [content.OLD_AREAS_OF_IMPROVEMENT.test_item_id]: {
    mean: 2.071167,
    sd: 2.6342808,
  },
  [content.AREAS_OF_IMPROVEMENT.test_item_id]: {
    mean: 1.270992,
    sd: 1.3531498,
  },
  [content.WORK_VALUES_QUESTION.test_item_id]: {
    mean: 4.668573,
    sd: 1.6068949,
  },
  [content.OLD_OVERALL_PERFORMANCE_QUESTION.test_item_id]: {
    mean: 4.643639,
    sd: 0.570686,
  },
  [content.WORK_STYLE_QUESTION.test_item_id]: {
    mean: 5.75204,
    sd: 2.2374093,
  },
  [content.RECOMMENDATION_OF_HIRE_QUESTION.test_item_id]: {
    mean: 9.852827,
    sd: 0.7400383,
  },
  [content.OVERALL_PERFORMANCE_QUESTION.test_item_id]: {
    mean: 4.697608,
    sd: 0.4935622,
  },
  // Each skill question has a different ID, so to track all the skill
  // median data we just have a dumb 'skill' key
  [SKILL_KEY]: {
    mean: 4.356703,
    sd: 0.6924335,
  },
}

const BIAS_DETECTION_QUESTIONS = Object.keys(POPULATION_ANSWER_COUNT_STATISTICS)

const RDS_POPULATION_ANSWER_COUNT_STATISTICS: Statistics = {
  'teamwork-styles': {
    mean: 4.334082,
    sd: 1.808063,
  },
  strengths: {
    mean: 7.102703,
    sd: 2.8258769,
  },
  'areas-of-improvement': {
    mean: 1.270992,
    sd: 1.3531498,
  },
  'work-styles': {
    mean: 5.75204,
    sd: 2.2374093,
  },
  'hiring-recommendations': {
    mean: 9.852827,
    sd: 0.7400383,
  },
  'overall-performance': {
    mean: 4.697608,
    sd: 0.4935622,
  },
  // Each skill question has a different ID, so to track all the skill
  // median data we just have a dumb 'skill' key
  [SKILL_KEY]: {
    mean: 4.356703,
    sd: 0.6924335,
  },
}

const RDS_BIAS_DETECTION_MODULES = Object.keys(RDS_POPULATION_ANSWER_COUNT_STATISTICS)

const NEGATIVE_QUESTIONS: string[] = [
  content.OLD_AREAS_OF_IMPROVEMENT.test_item_id,
  content.AREAS_OF_IMPROVEMENT.test_item_id,
  'areas-of-improvement',
]
