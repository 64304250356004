import Shell from 'App/Candidate/Shell'
import { isPresent } from 'core/utils'
import React, { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Step, SubmissionType } from 'selectors/candidates'
import { sendSelfAssessment } from 'store/candidates'
import * as tracking from '../../../../core/track'

import { Responses } from 'App/Candidate/SelfAssessment/Responses'
import SoftSkillsScoreGroup from 'App/Candidate/SelfAssessment/SoftSkillsDashboard'
import { getNavigationPath } from 'selectors/self-assessment'
import ProfileShell from '../../../Shared/ProfileShell'
import AssessmentSummariesDashboard from '../AssessmentSummariesDashboard/AssessmentSummariesDashboard'
import CandidateScoreCard from '../CandidateDashboard'
import CognitiveAbilitiesScoreGroup from '../CognitiveAbilitiesDashboard'

import ScoreGroupTempCustomQuestions from '../CustomQuestionsDashboard'
import HardSkillsScoreGroup from '../HardSkillsDashboard'
import PersonalityScoreCard from '../PersonalityDashboard'
import { AiAssistant, AIProvider } from './AiAssistant'
import classes from './AssessmentPage.module.scss'
import { ResponsesModal } from './ResponsesModal'
import { useAssessmentData } from './hooks/useAssessmentData'
import { useEvaluationStatus } from 'providers/candidates/hire-status'
import { EvaluationStatus } from 'api/candidates'

interface AssessmentPageProps {
  sharedViewMode?: boolean | 'candidate'
  responses?: boolean
}

const AssessmentPage: React.FC<AssessmentPageProps> = props => {
  const dispatch = useDispatch()

  const { roleid, candidateid } = useParams<{
    roleid: string
    candidateid: string
  }>()

  const { assessment, context, answers } = useAssessmentData(candidateid)
  const { updateEvaluationStatus } = useEvaluationStatus()
  const [evaluationStatus, setEvaluationStatus] = useState<EvaluationStatus>(
    context.candidate?.fields.evaluation_status || 'to-review',
  )

  const [responsesModalOpen, setResponsesModalOpen] = useState<{
    open: boolean
    moduleSlug: string | null
    group: Group | null
  }>({
    open: false,
    moduleSlug: null,
    group: null,
  })

  const scoreGroupCards = useMemo(() => {
    const hardSkillsScores = assessment.score?.meta?.find(m => m.slug === 'hardskills')
    const softSkillsScores = assessment.score?.meta?.find(m => m.slug === 'softskills')
    const cognitiveAbilitiesScores = assessment.score?.meta?.find(m => m.slug === 'cognitiveabilities')
    const cultureAddScores = assessment.score?.meta?.find(m => m.slug === 'cultureadd')
    const personalityScores = assessment.score?.meta?.find(m => m.slug === 'personality')
    const candidateExpectationsScores = assessment.score?.meta?.find(m => m.slug === 'candidateexpectations')

    return [
      {
        title: 'Hard Skills',
        enabled: hardSkillsScores?.children ? hardSkillsScores?.children.length > 0 : false,
        page: (
          <HardSkillsScoreGroup
            key={'Hard Skills'}
            hardSkillsScores={hardSkillsScores}
            candidateFirstName={context.candidateFirstName}
            roleId={context.candidateRole?.fields.id || ''}
            candidateId={context.candidate?.id || ''}
            isSharedResults={props.sharedViewMode === 'candidate'}
            defaultCollapsed={props.sharedViewMode !== 'candidate'}
            onResponsesClick={(moduleSlug, group) => setResponsesModalOpen({ open: true, moduleSlug, group })}
          />
        ),
      },
      {
        title: 'Soft Skills',
        enabled: softSkillsScores?.children ? softSkillsScores?.children.length > 0 : false,
        page: (
          <SoftSkillsScoreGroup
            key={'Soft Skills'}
            selfAssessmentAnswersByDomain={answers.assessmentAnswersByDomain}
            testGroupName={'Soft Skills'}
            softSkillsScores={softSkillsScores}
            cultureFitResponses={answers.cultureFit}
            roleId={context.candidateRole?.fields.id || ''}
            candidateId={context.candidate?.id || ''}
            candidateFirstName={context.candidateFirstName}
            isSharedResults={props.sharedViewMode === 'candidate'}
            defaultCollapsed={props.sharedViewMode !== 'candidate'}
            onResponsesClick={(moduleSlug, group) => setResponsesModalOpen({ open: true, moduleSlug, group })}
          />
        ),
      },
      {
        title: 'Cognitive Abilities',
        enabled: cognitiveAbilitiesScores?.children ? cognitiveAbilitiesScores?.children.length > 0 : false,
        page: (
          <CognitiveAbilitiesScoreGroup
            key={'Cognitive Abilities'}
            cognitiveAbilitiesScores={cognitiveAbilitiesScores}
            candidateFirstName={context.candidateFirstName}
            isSharedResults={props.sharedViewMode === 'candidate'}
            roleId={context.candidateRole?.fields.id || ''}
            candidateId={context.candidate?.id || ''}
            defaultCollapsed={props.sharedViewMode !== 'candidate'}
            onResponsesClick={(moduleSlug, group) => setResponsesModalOpen({ open: true, moduleSlug, group })}
          />
        ),
      },
      {
        title: 'Culture',
        enabled: cultureAddScores?.children ? cultureAddScores?.children?.length > 0 : false,
        page: (
          <SoftSkillsScoreGroup
            key={'Culture'}
            softSkillsScores={cultureAddScores}
            cultureFitResponses={answers.cultureFit}
            selfAssessmentAnswersByDomain={answers.cultureAddAnswersByDomain}
            testGroupName={'Culture'}
            roleId={context.candidateRole?.fields.id || ''}
            candidateId={context.candidate?.id || ''}
            candidateFirstName={context.candidateFirstName}
            isSharedResults={props.sharedViewMode === 'candidate'}
            defaultCollapsed={props.sharedViewMode !== 'candidate'}
            onResponsesClick={(moduleSlug, group) => setResponsesModalOpen({ open: true, moduleSlug, group })}
          />
        ),
      },
      {
        title: 'Personality',
        enabled: personalityScores?.children ? personalityScores?.children.length > 0 : false,
        page: (
          <PersonalityScoreCard
            key={'Personality'}
            questionnaire={assessment.questionnaire}
            personalityScores={personalityScores}
            personalityAnswers={answers.personality || []}
            moduleQuestions={assessment.moduleQuestions}
            personalityGroupId={answers.personalityGroup?.id || ''}
            isSharedResults={props.sharedViewMode === 'candidate'}
            candidateFirstName={context.candidateFirstName}
            roleId={context.candidateRole?.id || ''}
            candidateId={context.candidate?.id || ''}
            defaultCollapsed={props.sharedViewMode !== 'candidate'}
            onResponsesClick={(moduleSlug, group) => setResponsesModalOpen({ open: true, moduleSlug, group })}
          />
        ),
      },
      {
        title: 'Candidate Expectations',
        enabled: candidateExpectationsScores?.children ? candidateExpectationsScores?.children.length > 0 : false,
        page: (
          <CandidateScoreCard
            key="Candidate Expectations"
            candidateExpectationsAnswers={answers.candidateAnswers}
            candidateExpectationsScores={candidateExpectationsScores}
            candidateFirstName={context.candidateFirstName}
            candidateId={context.candidate?.id || ''}
            roleId={context.candidateRole?.id || ''}
            isSharedResults={props.sharedViewMode === 'candidate'}
            defaultCollapsed={props.sharedViewMode !== 'candidate'}
            onResponsesClick={(moduleSlug, group) => setResponsesModalOpen({ open: true, moduleSlug, group })}
          />
        ),
      },
      {
        title: 'Custom Questions',
        enabled:
          props.sharedViewMode === 'candidate'
            ? false
            : answers.customQuestion
            ? answers.customQuestion?.length > 0
            : false,
        page: (
          <ScoreGroupTempCustomQuestions
            key={'Custom Questions'}
            customQuestionAnswers={answers.customQuestion}
            onResponsesClick={(moduleSlug, group) => setResponsesModalOpen({ open: true, moduleSlug, group })}
          />
        ),
      },
    ].filter(page => isPresent(page) && page.enabled)
  }, [
    assessment.score?.meta,
    answers.customQuestion,
    props.sharedViewMode,
    context.candidateRole,
    context.candidate,
    assessment.questionnaire,
    answers.personalityGroup,
    answers.cultureFit,
    answers.candidateAnswers,
    assessment.moduleQuestions,
    answers.assessmentAnswersByDomain,
    answers.cognitiveAbilities,
    answers.cultureAddAnswersByDomain,
    answers.personality,
  ])

  const breadcrumbs =
    context.candidateRole && context.candidateProfile
      ? [
          {
            url: props.sharedViewMode ? undefined : `/roles/${context.candidateRole ? context.candidateRole.id : ''}`,
            label: context.candidateRole.fields.name,
          },
          {
            url: props.sharedViewMode
              ? undefined
              : getNavigationPath(context.candidateRole, context.candidate?.id || ''),
            label: context.candidateProfile.fields.full_name,
          },
        ]
      : []

  if (!candidateid) return null

  const isSelfAssessmentCompleted = assessment.selfAssessment && assessment.selfAssessment.fields.submitted_at > 0
  const isSelfAssessmentInProgress = assessment.selfAssessment && assessment.selfAssessment.fields.submitted_at === 0

  const currentProgress = isSelfAssessmentCompleted
    ? Step.Completed
    : isSelfAssessmentInProgress
    ? Step.InProgress
    : Step.Pending

  const button =
    currentProgress === Step.Pending
      ? {
          copy: 'Send out Assessment',
          send: () => dispatch(sendSelfAssessment(candidateid)),
        }
      : undefined

  const scorePages = scoreGroupCards.map(scorePage => scorePage.page)
  const sortingOrder = scoreGroupCards.map(scorePage => scorePage.title)

  // @here first
  if (props.sharedViewMode) {
    return (
      <ProfileShell
        candidateId={candidateid || ''}
        roleId={roleid || ''}
        breadcrumbs={breadcrumbs}
        submissionType={SubmissionType.Assessment}
      >
        <div className={classes.container}>
          <AssessmentSummariesDashboard
            scores={assessment.score?.meta || []}
            roleId={context.candidateRole?.fields.id || ''}
            candidateId={context.candidate?.id || ''}
            candidateFirstName={context.candidateFirstName}
            sortingOrder={sortingOrder}
            isSharedResults={props.sharedViewMode === 'candidate'}
            scorePages={scorePages}
          />
        </div>
      </ProfileShell>
    )
  }

  const assessmentScore = assessment.score?.meta?.find(m => m.type === 'questionnaire_submissions')?.score || 0

  if (props.responses) {
    return (
      <ProfileShell
        candidateId={candidateid || ''}
        roleId={roleid || ''}
        breadcrumbs={breadcrumbs}
        submissionType={SubmissionType.Assessment}
      >
        <Responses candidateId={candidateid || ''} role={context.candidateRole} />
      </ProfileShell>
    )
  }

  return (
    <>
      <ResponsesModal
        modalState={responsesModalOpen}
        onClose={() => setResponsesModalOpen({ open: false, moduleSlug: null, group: null })}
        candidateId={candidateid || ''}
        role={context.candidateRole}
        candidateFirstName={context.candidateFirstName}
      />
      <AIProvider candidateId={candidateid}>
        <Shell
          title={SubmissionType.Assessment}
          candidateId={candidateid || ''}
          roleId={roleid || ''}
          assessmentScore={assessmentScore}
          breadcrumbs={breadcrumbs}
          currentProgress={currentProgress}
          button={button}
          showScore={true}
          evaluationStatus={evaluationStatus}
          onEvaluationStatusChanged={newStatus => {
            if (context.candidate) {
              setEvaluationStatus(newStatus)
              updateEvaluationStatus(context.candidate.id, newStatus)
              tracking.candidates.changeEvaluationStatus()
            }
          }}
        >
          <AssessmentSummariesDashboard
            scores={assessment.score?.meta || []}
            roleId={context.candidateRole?.fields.id || ''}
            candidateId={context.candidate?.id || ''}
            candidateFirstName={context.candidateFirstName}
            sortingOrder={sortingOrder}
            isSharedResults={false}
            scorePages={scorePages}
          />
          <AiAssistant />
        </Shell>
      </AIProvider>
    </>
  )
}

export default AssessmentPage

export type Group = {
  copy: {
    title: string
    theme: string
    icon?: React.ReactNode
    emoji?: string
  }
}
