import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { findByCandidateId } from 'selectors/candidate-profiles'
import { findById } from 'selectors/candidates'
import { RootState } from 'store'
import { fetch as fetchCandidate } from 'store/candidates'

export function useCandidate(query?: { candidateId?: string }) {
  const { candidateId } = query || {}
  const candidateSelector = useCallback((state: RootState) => findById(state, candidateId || ''), [candidateId])
  const candidate = useSelector(candidateSelector)
  const candidateProfile = useCandidateProfile(query)

  const dispatch = useDispatch()
  const isCandidateFetched = !!candidate
  useEffect(() => {
    if (!isCandidateFetched && candidateId) {
      dispatch(fetchCandidate(candidateId))
    }
  }, [candidateId, isCandidateFetched, dispatch])

  return {
    isLoading: candidateId && !isCandidateFetched,
    candidate,
    candidateProfile,
  }
}

export function useSelectedCandidate() {
  const { candidateid } = useParams<{ candidateid?: string }>()
  return useCandidate({ candidateId: candidateid || '' })
}

export function useCandidateProfile(query?: { candidateId?: string }) {
  const { candidateId } = query || {}
  const candidateProfileSelector = useCallback(
    (state: RootState) => findByCandidateId(state, candidateId || ''),
    [candidateId],
  )
  const candidateProfile = useSelector(candidateProfileSelector)
  return candidateProfile
}
