import { ScoreResponse } from '@common/api/score'
import { ScoreExplanation, ScoreGroup } from '@common/components'
import { Icon } from 'components/Icons'
import { hasPresentKey } from 'core/utils'
import React, { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import * as selectors from 'selectors'
import { AssessmentAnswersByDomain } from 'selectors/self-assessment'
import { RootState } from 'store'
import { getAlternativeTitle } from 'utils/format'
import { Group } from './AssessmentPage/AssessmentPage'
import { CultureFitCheckResponses, CultureFitCheckValueComparison } from './CultureFitCheck'

interface SoftSkillsScoreGroupProps {
  selfAssessmentAnswersByDomain: AssessmentAnswersByDomain
  cultureFitResponses?: CultureFitCheckResponses
  testGroupName?: string
  softSkillsScores?: ScoreResponse['meta'][number]
  candidateId: string
  roleId: string
  candidateFirstName: string
  defaultCollapsed?: boolean
  isSharedResults?: boolean
  onResponsesClick: (moduleSlug: string, group: Group) => void
}

const SoftSkillsScoreGroup: React.FC<SoftSkillsScoreGroupProps> = props => {
  const org = useSelector((state: RootState) => selectors.orgs.current(state))
  const domains = getDomains(props.selfAssessmentAnswersByDomain, props.cultureFitResponses)

  const group = useSelector((state: RootState) => {
    const group_redux = selectors.groups.findBySlug(state, props.softSkillsScores?.slug || '')
    return {
      id: group_redux?.id || '',
      name: props.softSkillsScores?.name || group_redux?.fields?.copy?.title || '',
      slug: group_redux?.fields?.slug || '',
      copy: {
        icon: group_redux?.fields?.copy?.icon ? <Icon name={group_redux?.fields?.copy?.icon} /> : '🛠',
        emoji: group_redux?.fields?.copy?.emoji,
        theme: group_redux?.fields?.copy?.theme || '',
        description: group_redux?.fields?.copy?.short_title || '',
        title: getAlternativeTitle(group_redux?.fields?.copy?.title || '', org.id),
      },
    }
  })

  const getModuleData = (score: ScoreResponse['meta'][number]) => {
    const module = domains?.flatMap(d => d.modules).find(module => module.slug === score.slug)

    return {
      description: module?.description || module?.copy?.description,
      locale: 'en_US',
      customDescription: !props.isSharedResults && module?.detailsChildren && (
        <ScoreExplanation lowBehaviourText={module?.lowBehaviourText} highBehaviourText={module?.highBehaviourText}>
          {module?.detailsChildren}
        </ScoreExplanation>
      ),
      isSharedResults: props.isSharedResults,
    }
  }

  return (
    <ScoreGroup
      score={props.softSkillsScores}
      group={group}
      onClickResponses={props.onResponsesClick}
      candidateFirstName={props.candidateFirstName}
      getModuleData={getModuleData}
      defaultCollapsed={props.defaultCollapsed}
    />
  )
}

export default SoftSkillsScoreGroup

interface ModuleSummaryBlockPartialProps {
  copy: {
    description?: string
  }
  slug: string
  description: string
  detailsChildren?: React.ReactNode
  lowBehaviourText?: string
  highBehaviourText?: string
}

function getDomains(
  selfAssessmentAnswersByDomain: AssessmentAnswersByDomain,
  cultureFitResponses: CultureFitCheckResponses | undefined,
): {
  domain: { name: string }
  modules: ModuleSummaryBlockPartialProps[]
}[] {
  if (!selfAssessmentAnswersByDomain) {
    return []
  }

  return selfAssessmentAnswersByDomain.map(({ domain, answers }) => {
    const modules = answers.filter(hasPresentKey('module')).map(({ module }): ModuleSummaryBlockPartialProps => {
      let detailsChildren: ReactNode | undefined = undefined

      // Don't show a benchmark and show special details for culture fit check
      if (module.slug === 'culturefitcheck') {
        detailsChildren = (
          <CultureFitCheckValueComparison
            candidateProfile={cultureFitResponses?.candidateProfile}
            recruiterSelectedValues={cultureFitResponses?.recruiterSelectedValues || []}
            candidateSelectedValues={cultureFitResponses?.candidateSelectedValues || []}
          />
        )
      }

      return {
        ...module,
        slug: module.slug,
        detailsChildren,
        lowBehaviourText: module.copy?.score_card?.low_behavior_example,
        highBehaviourText: module.copy?.score_card?.high_behavior_example,
      }
    })

    return {
      domain,
      modules: modules,
    }
  })
}
