import { RoleQuery } from 'api/openjobroles'
import DashboardLayout from 'components/DashboardLayout'
import TextField from 'components/TextField'
import { fr, pct, px2rem, size, style, vh } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import * as tracking from 'core/track'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useDebounce } from 'react-use'
import * as selectors from 'selectors'
import { plural } from 'selectors'
import { getRoles, Role } from 'selectors/roles'
import { RootState } from 'store'
import { createTestRole, loadRoles } from 'store/roles'

import { Beacon, Button, PageHeader, Text, TopBar } from '@common/components'
import { listing } from 'api'
import { SortOption } from 'api/listing'
import { JobsTable } from 'App/ProductPages/JobsTable'
import { DemoStep, useDemo } from 'providers/demo'
import { NoResults, ResultsNotFound } from './NoResults'
import RoleCategorySelector, { SelectedRoleCategory } from './RoleCategorySelector'

const SidebarContainer = style().element()
const Main = style()
  .grid({ rows: [size.auto, `minmax(0, ${fr(1)})`], columns: [size.fill] })
  .size({ height: vh(100), width: pct(100) })
  .nooverflow()
  .element('article')

const PageContainer = style()
  .grid({ rows: [size.auto, `minmax(0, ${fr(1)})`], columns: [size.fill] })
  .size({ width: size.fill })
  .spacing({
    outer: [px2rem(0), size.auto],
    inner: [px2rem(48), px2rem(80)],
  })
  .element()

const PageContent = style()
  .flex({ direction: 'column' })
  .spacing({ gap: px2rem(24) })
  .element()

const RolesPageUIStyle = style()
  .size({ minHeight: vh(100) })
  .color({ bg: designSystemColors.backgroundNeutralSecondary })
  .element()

type FetchRolesQuery = {
  offset?: number
  limit?: number
  title?: string
  category: SelectedRoleCategory
  sort?: RoleQuery['sort']
}

interface RolesPageUIProps {
  fetchRoles: (query: FetchRolesQuery) => any
  loadMoreRoles: () => any
  totalRoleCount: number
  roles: Role[]
  hasMoreRole: boolean
  allowedToSeeOrganizationRoles: boolean
  allowedToCreateRole: boolean
  noResults?: ResultsNotFound
  createTestRole: () => void
  isInDemo: boolean
  demoStep: DemoStep
}

const RoleCategorySearchParamKey = 'role-category'
const TextSearchParamKey = 'role-title'

const RolesPageUI: React.FC<RolesPageUIProps> = props => {
  const location = useLocation()
  const history = useHistory()

  const searchParams = new URLSearchParams(location.search)

  const selectedRoleCategory = (searchParams.get(RoleCategorySearchParamKey) || 'mine') as SelectedRoleCategory
  const setRoleCategory = (roleCategory: SelectedRoleCategory) => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set(RoleCategorySearchParamKey, roleCategory)
    // reset search text
    searchParams.delete(TextSearchParamKey)
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    })
  }

  const textSearchValue = searchParams.get(TextSearchParamKey) || ''
  const onSearchTextInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set(TextSearchParamKey, ev.target.value)
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    })
  }

  const [sort, setSort] = useState<Required<listing.Options>['sort']>([
    {
      field: 'updated_at',
      order: listing.SortOrder.Desc,
    },
  ])

  useDebounce(
    () =>
      props.fetchRoles({
        title: textSearchValue,
        category: selectedRoleCategory,
        sort: sort,
      }),
    250,
    [textSearchValue, selectedRoleCategory, sort],
  )

  const onSort = useCallback((key: string, direction: string) => {
    setSort([
      {
        field: key,
        order: direction as listing.SortOrder,
      },
    ])
  }, [])

  return (
    <RolesPageUIStyle>
      <SidebarContainer></SidebarContainer>
      <Main>
        <TopBar tracking={tracking} />
        <PageContainer>
          <PageHeader.Root>
            <PageHeader.Title data-testid="roles-page-title">Jobs</PageHeader.Title>
            <PageHeader.EndItems>
              <Text variant="body-text">{`${props.totalRoleCount} ${plural('Job', props.totalRoleCount)}`}</Text>
              <TextField
                value={textSearchValue}
                placeholder="Search"
                icon="search"
                onChange={onSearchTextInputChange}
              />
              {props.allowedToCreateRole && (
                <Button
                  variant="secondary"
                  onClick={() => {
                    tracking.roles.testRoleCreation('Jobs Page')
                    props.createTestRole()
                  }}
                >
                  Create test job
                </Button>
              )}
              {props.allowedToCreateRole && (
                <Beacon visible={(props.isInDemo && props.demoStep === DemoStep.NeedsRole) || !props.roles.length}>
                  <Button
                    variant="accent"
                    data-testid="roles-page-create-new-role-button"
                    onClick={() => {
                      tracking.roles.startRoleCreation()
                      history.push('/roles/new')
                    }}
                  >
                    Create new job
                  </Button>
                </Beacon>
              )}
            </PageHeader.EndItems>
          </PageHeader.Root>
          <PageContent>
            <RoleCategorySelector
              orgAdmin={props.allowedToSeeOrganizationRoles}
              selected={selectedRoleCategory}
              setSelected={setRoleCategory}
            />

            {props.noResults ? (
              <NoResults type="no-roles-exist" />
            ) : (
              <JobsTable
                loadMoreRoles={props.loadMoreRoles}
                roles={props.roles}
                hasMoreRole={props.hasMoreRole}
                onSort={onSort}
              />
            )}
          </PageContent>
        </PageContainer>
      </Main>
    </RolesPageUIStyle>
  )
}

function getNoResultsReason(query: FetchRolesQuery | null, allowedToCreateRole: boolean): ResultsNotFound | undefined {
  if (!query) {
    return undefined
  }
  if (!allowedToCreateRole) {
    return 'no-roles-found'
  }
  if (query.title || query.category === 'deleted') {
    return 'no-roles-found'
  }
  return 'no-roles-exist'
}

function withSortFieldReplacement(sort: SortOption, from: string, to: string): SortOption {
  if (!sort || sort.field !== from) {
    return sort
  }

  return {
    ...sort,
    field: to,
  }
}

export function getRolesQuery(query: FetchRolesQuery | null, userId: string, orgId: string): RoleQuery | null {
  if (!query || !userId || !orgId) {
    return null
  }

  switch (query.category) {
    case 'deleted': {
      return {
        type: 'user',
        userId: userId,
        title: query.title || '',
        limit: query.limit || 35,
        onlyDeleted: true,
        skip: query.offset || 0,
        sort: query.sort?.map(s => withSortFieldReplacement(s, 'name', 'open_job_roles.name')),
      }
    }
    case 'mine': {
      return {
        type: 'user',
        userId: userId,
        title: query.title || '',
        limit: query.limit || 35,
        onlyDeleted: false,
        skip: query.offset || 0,
        sort: query.sort?.map(s => withSortFieldReplacement(s, 'name', 'open_job_roles.name')),
      }
    }
    case 'org': {
      return {
        type: 'org',
        orgId: orgId,
        title: query.title || '',
        limit: query.limit || 35,
        onlyDeleted: true,
        skip: query.offset || 0,
        sort: query.sort,
      }
    }
    case 'starred': {
      return {
        type: 'user',
        userId: userId,
        title: query.title || '',
        limit: query.limit || 35,
        onlyDeleted: false,
        starred: true,
        skip: query.offset || 0,
        sort: query.sort?.map(s => withSortFieldReplacement(s, 'name', 'open_job_roles.name')),
      }
    }
  }
}

interface RolesPageProps {}

const RolesPage: React.FC<RolesPageProps> = () => {
  const dispatch = useDispatch()
  const userId = useSelector(selectors.users.current)?.id || ''
  const orgId = useSelector(selectors.orgs.currentId)

  const canCreateRole = useSelector((state: RootState) => selectors.users.canCreateRole(state, userId))
  const canProvideSupportOrOrgAdmin = useSelector((state: RootState) =>
    selectors.users.canProvideSupportOrOrgAdmin(state, userId),
  )

  const [query, setQuery] = React.useState<FetchRolesQuery | null>(null)

  useEffect(() => {
    const loadRolesQuery = getRolesQuery(query, userId, orgId)
    if (!loadRolesQuery) {
      return
    }

    dispatch(loadRoles(loadRolesQuery))
  }, [query, userId, orgId])

  const { hasMore, total, roles, cursor } = useSelector(getRoles) || {}

  const { step, isInDemo } = useDemo()

  return (
    <DashboardLayout>
      <RolesPageUI
        fetchRoles={setQuery}
        loadMoreRoles={() => {
          const loadRolesQuery = getRolesQuery(query, userId, orgId)
          if (!loadRolesQuery) {
            return
          }
          dispatch(loadRoles({ ...loadRolesQuery, skip: cursor || 0 }))
        }}
        totalRoleCount={total || 0}
        roles={roles || []}
        hasMoreRole={hasMore || false}
        allowedToSeeOrganizationRoles={canProvideSupportOrOrgAdmin}
        allowedToCreateRole={canCreateRole}
        noResults={roles?.length === 0 ? getNoResultsReason(query, canCreateRole) : undefined}
        createTestRole={async () => {
          await dispatch(createTestRole())
          const loadRolesQuery = getRolesQuery(query, userId, orgId)
          if (!loadRolesQuery) {
            return
          }

          dispatch(loadRoles(loadRolesQuery))
        }}
        isInDemo={isInDemo}
        demoStep={step}
      />
    </DashboardLayout>
  )
}

export default RolesPage
