import { SummaryContainer } from 'App/Candidate/Summary/SummaryContainer'
import { useWorkStyle } from 'providers/candidate-summaries'
import { useSelectedCandidate } from 'providers/candidates'
import { useModule } from 'providers/modules'
import React from 'react'
import { useParams } from 'react-router-dom'
import { WORK_STYLE_QUESTION } from 'store/new-role/content'
import {
  AttributeBreakdownBlock,
  AttributeBreakdownBlockProps,
  sortBreakdowns,
  sortReferences,
} from './AttributeBreakdownBlock'

interface WorkStyleBlockUIProps {
  attributes: AttributeBreakdownBlockProps['attributes']
  numberOfReferences: AttributeBreakdownBlockProps['numberOfReferences']
  detailsUrl?: string
}

const WorkStyleBlockUI: React.FC<WorkStyleBlockUIProps> = ({ attributes, numberOfReferences, detailsUrl }) => {
  return (
    <SummaryContainer emoji={'👔'} title={'Work Style'} hintColor={'purple'} to={detailsUrl}>
      <AttributeBreakdownBlock
        title={'Most mentioned traits'}
        attributes={attributes}
        numberOfReferences={numberOfReferences}
      />
    </SummaryContainer>
  )
}

interface WorkStyleBlockProps {
  container?: React.FC
}

const MAX_NUMBER_OF_ATTRIBUTES = 5

const WorkStyleBlock: React.FC<WorkStyleBlockProps> = ({ container }) => {
  const Container = container || React.Fragment

  const { candidate } = useSelectedCandidate()
  const { isLoading, data } = useWorkStyle({ candidateId: candidate?.id })

  const module = useModule({ slug: 'work-styles' })
  const targetId = candidate?.fields.questionnaire_id ? module?.id : WORK_STYLE_QUESTION.test_item_id
  const { roleid, candidateid } = useParams<{
    roleid: string
    candidateid: string
  }>()
  const detailsUrl = `/roles/${roleid}/candidates/${candidateid}/responses#${targetId}`

  if (isLoading || !data || data.attributes.length <= 0 || data.numberOfReferences <= 0) {
    return null
  }

  const { attributes, numberOfReferences } = data

  const sortedAttributes = attributes
    // reverse sorting by the most picked attribute
    .sort((attribute1, attribute2) => sortBreakdowns(attribute1.pickedBy, attribute2.pickedBy))
    // limiting the number of attributes shown
    .slice(0, MAX_NUMBER_OF_ATTRIBUTES)
    // grouping relationship types together
    .map(attribute => ({
      ...attribute,
      pickedBy: attribute.pickedBy.sort(sortReferences),
    }))

  return (
    <Container>
      <WorkStyleBlockUI attributes={sortedAttributes} numberOfReferences={numberOfReferences} detailsUrl={detailsUrl} />
    </Container>
  )
}

export default WorkStyleBlock
