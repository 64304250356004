import React from 'react'
import { breakpoints, colors, pct, rem, size, space, style } from '../../core'
import RoundedRect from './RoundedRect'

export { Heading, Subheading } from './Typography'

interface Props {
  children: React.ReactNode
}

const Container = style()
  .spacing({ inner: [space.xs, space.none] })
  .grid({ columns: [size.auto, rem(30), size.auto] })
  .size({ width: size.fill })
  .screen(
    breakpoints.mobile,
    style()
      .grid({ columns: [size.auto, '90%', size.auto] })
      .spacing({ inner: ['5%', space.none] }),
  )
  .element()

const Center = style().center({ vertical: true }).screen(breakpoints.mobile, style().block()).element()

const Column = RoundedRect.clone()
  .bg({ color: colors.lightestGray })
  .size({ width: pct(100) })
  .screen(breakpoints.mobile, style().bg({ color: colors.lightMoonGray }))
  .element()

const Component = (props: Props) => {
  return (
    <Container>
      <div></div>
      <Center>
        <Column>{props.children}</Column>
      </Center>
      <div></div>
    </Container>
  )
}

export default Component
Component.displayName = 'SingleColumnLayout'
