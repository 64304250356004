import { text } from 'core/design-system/text'
import React from 'react'

const RDSTextAnswerStyle = text.bodyText().element('p')

interface RDSTextAnswerProps {
  value: string
}

const RDSTextAnswer: React.FC<RDSTextAnswerProps> = ({ value }) => {
  return <RDSTextAnswerStyle>{value}</RDSTextAnswerStyle>
}

export default RDSTextAnswer
