import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as api from '../../api'
import { candidates, openjobroles, request } from '../../api'
import { AppThunk } from '../../core/store'
import { add as notify } from '../notifications'
import { addEntities } from '../resources'

export interface State {
  created: string
  loadedAt: number
  isLoading: boolean
  fetching: object
  fetchingCandidates: object
  renaming: object
  candidates: object
  total: { [id: string]: Total }
  sortedBy: { [id: string]: SortCriteria }
}

export enum SortField {
  CandidateFullName = 'candidate_profiles.full_name',
  AssessmentCompletedAt = 'questionnaire_submissions.submitted_at',
  ReferenceCheckCompletedAt = 'completed_at',
  OverallScore = 'overall_score',
  UpdatedAt = 'updated_at',
  HireStatus = 'completion_status',
  HireStage = 'hiring_status',
  AssessmentStatus = 'assessment_completion_status',
}

export const defaultSortField = SortField.CandidateFullName
export const defaultSortOrder = api.listing.SortOrder.Asc

interface SortCriteria {
  order: api.listing.SortOrder
  field: SortField
}

export interface Total {
  all: number
  approved: number
  rejected: number
  inreview: number
}

const name = openjobroles.RESOURCE
const initialState: State = {
  created: '',
  loadedAt: 0,
  isLoading: false,
  fetching: {},
  renaming: {},
  candidates: {},
  fetchingCandidates: {},
  total: {},
  sortedBy: {},
}

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
      if (action.payload === false) {
        state.loadedAt = Date.now()
      }
    },
    setCreated(state, action: PayloadAction<string>) {
      state.created = action.payload
    },
    setIsFetching(state, action: PayloadAction<{ id: string; fetching: boolean }>) {
      const { id, fetching } = action.payload
      state.fetching[id] = fetching
    },
    setIsRenaming(state, action: PayloadAction<{ id: string; renaming: boolean }>) {
      const { id, renaming } = action.payload
      state.renaming[id] = renaming
    },
    setIsFetchingCandidates(state, action: PayloadAction<{ id: string; fetching: boolean }>) {
      const { id, fetching } = action.payload
      state.fetchingCandidates[id] = fetching
    },
    setListOfCandidates(
      state,
      action: PayloadAction<{
        id: string
        candidates: request.Entity<candidates.Fields>[]
      }>,
    ) {
      const { id, candidates } = action.payload
      state.candidates[id] = candidates.map(c => c.id)
    },
    setTotal(
      state,
      action: PayloadAction<{
        id: string
        all: number
        inreview: number
        approved: number
        rejected: number
      }>,
    ) {
      state.total[action.payload.id] = { ...action.payload }
    },
    addListOfCandidates(
      state,
      action: PayloadAction<{
        id: string
        candidates: request.Entity<candidates.Fields>[]
      }>,
    ) {
      const { id, candidates } = action.payload
      state.candidates[id] = Array.from(new Set(state.candidates[id].concat(candidates.map(c => c.id))))
    },
    setSortField(state, action: PayloadAction<{ field: SortField; id: string }>) {
      const { field, id } = action.payload

      if (state.sortedBy[id]) {
        state.sortedBy[id].field = field
        return
      }

      state.sortedBy[id] = {
        field: field,
        order: api.listing.SortOrder.Desc,
      }
    },
    setSortDesc(state, action: PayloadAction<{ order: api.listing.SortOrder; id: string }>) {
      const { order, id } = action.payload
      if (state.sortedBy[id]) {
        state.sortedBy[id].order = order
        return
      }

      state.sortedBy[id] = {
        field: SortField.CandidateFullName,
        order: order,
      }
    },
  },
})

export const {
  setCreated,
  setIsLoading,
  setIsFetching,
  setIsFetchingCandidates,
  setListOfCandidates,
  setTotal,
  addListOfCandidates,
} = slice.actions

export default slice.reducer

export const fetch =
  (id: string): AppThunk =>
  async dispatch => {
    dispatch(setIsFetching({ id, fetching: true }))

    const [response, errors] = await openjobroles.fetch(id)
    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    dispatch(setIsFetching({ id, fetching: false }))

    if (response) {
      dispatch(addEntities(response))
    }
  }

export const loadCandidates =
  (id: string, sortField: string, desc: boolean, skip?: number): AppThunk =>
  async dispatch => {
    dispatch(setIsFetchingCandidates({ id, fetching: true }))

    const [response, errors] = await api.listing.list<candidates.Fields>(`open_job_roles/${id}/candidates`, {
      include: [
        api.openjobroles.RESOURCE,
        api.candidateProfiles.RESOURCE,
        api.forms.RESOURCE,
        api.references.RESOURCE,
        api.greenhouseProfiles.RESOURCE,
        api.employees.RESOURCE,
        api.questionnaireSubmissions.RESOURCE,
        api.questionnaireAnswers.RESOURCE,
        api.responseOptions.RESOURCE,
        api.questionnaires.RESOURCE,
        api.questionnaireEntries.RESOURCE,
        api.questions.RESOURCE,
      ],
      filters: [api.listing.True('is_active')],
      sort: [
        {
          field: sortField,
          order: desc ? api.listing.SortOrder.Desc : api.listing.SortOrder.Asc,
        },
      ],
      skip: skip || 0,
      limit: 20,
    })

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
    }

    dispatch(setIsFetchingCandidates({ id, fetching: false }))

    if (response) {
      // If `skip` is a number higher than 0, we're pulling more candidates for the same role
      // Otherwise, we're pulling the first candidates for the given role.
      if (skip && skip > 0) {
        dispatch(addListOfCandidates({ id, candidates: response.result }))
      } else {
        dispatch(setListOfCandidates({ id, candidates: response.result }))
      }

      dispatch(
        // @ts-ignore
        setTotal({
          id,
          all: response.list?.total || 0,
        }),
      )

      dispatch(addEntities(response))
    }
  }

export const start =
  (id: string): AppThunk =>
  async dispatch => {
    const [response, errors] = await openjobroles.start(id)
    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    if (response) {
      dispatch(addEntities(response))
      dispatch(
        notify({
          success: 'The selected open job role has been started successfully.',
        }),
      )
    }
  }

export const pause =
  (id: string): AppThunk =>
  async dispatch => {
    const [response, errors] = await openjobroles.pause(id)
    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    if (response) {
      dispatch(addEntities(response))
      dispatch(
        notify({
          success: 'The selected open job role has been paused successfully.',
        }),
      )
    }
  }

export const del =
  (roleId: string): AppThunk =>
  async dispatch => {
    const [response, errors] = await openjobroles.delet(roleId)
    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    if (response) {
      dispatch(addEntities(response))
      dispatch(
        notify({
          success: 'The selected open job role has been deleted successfully.',
        }),
      )
    }
  }
export const updateTimeWindowDays =
  (roleId: string, timeWindowDays: number, candidateExpiryDays: number): AppThunk =>
  async dispatch => {
    const [response, errors] = await openjobroles.updateTimeWindowDays(roleId, timeWindowDays, candidateExpiryDays)

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    if (response) {
      dispatch(addEntities(response))
      dispatch(
        notify({
          success: 'Updated the job expiry time successfully!',
        }),
      )
    }
  }

export const updateTitles =
  (roleId: string, internalName: string, publicName: string): AppThunk =>
  async dispatch => {
    dispatch(setIsLoading(true))

    const [response, errors] = await openjobroles.updateRoleTitles(roleId, {
      updates: {
        internal_name: internalName,
        public_name: publicName,
      },
      updated: ['internal_name', 'public_name'],
    })

    dispatch(setIsLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    if (response) {
      dispatch(addEntities(response))
      dispatch(
        notify({
          success: 'Updated the job titles successfully!',
        }),
      )
    }
  }
