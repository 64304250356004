import TabSelector from 'App/Candidate/TabSelector'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SubmissionType } from 'selectors/candidates'
import { Content } from '../../components/DashboardLayout'
import { Breadcrumb } from '../../components/DashboardLayout/Breadcrumbs'
import * as tracking from '../../core/track'
import * as selectors from '../../selectors'
import { RootState } from '../../store'
import { fetch } from '../../store/candidates'
import NameHeader from '../Candidate/NameHeader'
import styles from '../Candidate/Shell.module.scss'

import Header from './Header'
import Layout from './Layout'

interface Props {
  children: React.ReactNode
  candidateId: string
  roleId: string
  breadcrumbs: Breadcrumb[]
  hideNameHeader?: boolean
  submissionType?: SubmissionType
}

const SharedShell = (props: Props) => {
  const dispatch = useDispatch()

  // identify if we are on the shared results for candidates. ie.: /shared/candidates/:id/results
  const isViewForCandidate: boolean =
    window?.location?.pathname?.includes('/shared/candidates') && window?.location?.pathname?.includes('/results')

  const candidateId = props.candidateId

  const [candidate, org] = useSelector((state: RootState) => {
    const role = selectors.roles.findByCandidateId(state, candidateId)
    return [selectors.candidates.findById(state, candidateId), selectors.orgs.findByRoleId(state, role?.id || '')]
  })

  useEffect(() => {
    if (!candidate) {
      dispatch(fetch(candidateId))
    }
    tracking.shared.viewCandidate()
  }, [candidateId])

  return (
    <Layout>
      <Header orgId={org?.id || ''} breadcrumbs={props.breadcrumbs} />
      <Content translate="no" className="bigOnPrint">
        {!isViewForCandidate && <TabSelector content />}

        <div className={styles.sharedContainer}>
          {!props.hideNameHeader && (
            <NameHeader title={props.submissionType} candidateId={candidateId} isSharedResults={isViewForCandidate} />
          )}
          {props.children}
        </div>
      </Content>
    </Layout>
  )
}

export default SharedShell
