import { TopBar } from '@common/components'
import PoolSettings from 'App/Settings/PoolSettings'
import { Blocks } from 'components/Block'
import Breadcrumbs from 'components/DashboardLayout/Breadcrumbs'
import { useFeatureFlag } from 'providers/features'
import React from 'react'
import { useSelector } from 'react-redux'
import DashboardLayout, { Content } from '../../components/DashboardLayout'
import * as selectors from '../../selectors'
import { RootState } from '../../store'
import AddToSlack from './AddToSlack'
import AiSettings from './AiSettings'
import BackgroundCheckIntegrations from './BackgroundCheckIntegrations'
import { CandidateExperienceFeedbackToggle } from './CandidateExperienceFeedbackToggle'
import GDPR from './GDPR'
import Integrations from './Integrations'
import MyOrganization from './MyOrganization'
import PDFToggle from './PDFToggle'
import PersonioImport from './PersonioImport'
import PrivacyPolicyLink from './PrivacyPolicyLink'

import * as tracking from '../../core/track'

const Organization = () => {
  const canManageOrg = useSelector((state: RootState) => {
    const user = selectors.users.current(state)
    return selectors.users.canManageOrganization(state, user?.id)
  })

  const canProvideSupport = useSelector((state: RootState) => {
    const user = selectors.users.current(state)
    return selectors.users.canProvideSupport(state, user?.id)
  })

  const { isEnabled: isBackgroundCheckIntegrationEnabled } = useFeatureFlag('background-checks')
  const { isEnabled: isMergeDevIntegrationEnabled } = useFeatureFlag('merge-dev-integration')
  const { isEnabled: isCandidateExperienceEnabled } = useFeatureFlag('candidate-experience')
  const { isEnabled: isPersonioImportEnabled } = useFeatureFlag('personio-import')

  return (
    <DashboardLayout>
      <TopBar tracking={tracking} />
      <Content>
        <Blocks>
          <Breadcrumbs content={[{ label: 'Settings' }, { url: `/settings/organization`, label: 'Organization' }]} />
          {canManageOrg && <MyOrganization canProvideSupport={canProvideSupport} />}
          {canManageOrg && <PrivacyPolicyLink />}
          {canManageOrg && <GDPR />}
          {isMergeDevIntegrationEnabled && canManageOrg && <Integrations />}
          {isCandidateExperienceEnabled && canManageOrg && <CandidateExperienceFeedbackToggle />}
          {isBackgroundCheckIntegrationEnabled && canManageOrg && <BackgroundCheckIntegrations />}
          {canManageOrg && <AddToSlack />}
          {canManageOrg && <PDFToggle />}
          {canManageOrg && <PoolSettings />}
          {canManageOrg && <AiSettings />}
          {isPersonioImportEnabled && canManageOrg && <PersonioImport />}
        </Blocks>
      </Content>
    </DashboardLayout>
  )
}

export default Organization
