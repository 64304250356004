import React from 'react'
import classes from './BadgeCandidate.module.scss'
import { Text } from '@common/components'
import { FaCrown } from 'react-icons/fa'

interface BadgeCandidateProps {
  type: 'top-1' | 'top-10'
}

export const BadgeCandidate = (props: BadgeCandidateProps) => {
  return (
    <div className={classes.badge}>
      <FaCrown size={16} />
      <Text variant="small-body" color="white">
        {props.type === 'top-1' ? 'Top 1%' : 'Top 10%'}
      </Text>
    </div>
  )
}
