import { MarkdownText } from '@common/components'
import { px2rem, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React, { ReactNode } from 'react'

const ScoreExplanationContainer = style()
  .grid({
    columns: '1fr 1fr',
  })
  .spacing({
    gap: '2rem',
  })
  .element()

const ScoreExplanationTitle = text
  .bodyEmphasis()
  .spacing({ bottom: px2rem(6) })
  .element()

const ScoreExplanationColumn = text
  .bodyText()
  .spacing({ top: px2rem(26) })
  .element()

export const DescriptionText = text.bodyText().sans({ color: designSystemColors.typographySecondary }).element()

interface ScoreTableInfoBlockProps {
  lowBehaviourText?: string
  highBehaviourText?: string
  description?: string
  children?: ReactNode
}

export const ScoreExplanation = (props: ScoreTableInfoBlockProps) => {
  const showBehaviourText =
    (props.highBehaviourText || '').trim().length > 0 && (props.lowBehaviourText || '').trim().length > 0
  return (
    <>
      {props.description && <DescriptionText>{props.description}</DescriptionText>}
      {showBehaviourText && (
        <ScoreExplanationContainer>
          {props.highBehaviourText && (
            <ScoreExplanationColumn>
              <ScoreExplanationTitle>High score behavior:</ScoreExplanationTitle>
              <MarkdownText text={props.highBehaviourText || ''} />
            </ScoreExplanationColumn>
          )}
          {props.lowBehaviourText && (
            <ScoreExplanationColumn>
              <ScoreExplanationTitle>Low score behavior:</ScoreExplanationTitle>
              <MarkdownText text={props.lowBehaviourText || ''} />
            </ScoreExplanationColumn>
          )}
        </ScoreExplanationContainer>
      )}
      {props.children}
    </>
  )
}
