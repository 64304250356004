import { Button, Card, Logo, Text } from '@common/components'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Form from '../../components/Form'
import TextField from '../../components/TextField'
import { rem, scale, style, weight } from '../../core'
import { RootState } from '../../store'
import { requestPasswordReset } from '../../store/resetpassword/request-password-reset'
import classes from '../SignUp/Signup.module.scss'
const successCommon = style().sans({
  size: scale.m,
  align: 'center',
  height: scale.m,
})

const SuccessHeading = successCommon.clone().sans({ weight: weight.bold }).element('p')

const SuccessDescription = successCommon
  .clone()
  .sans({ weight: weight.lighter, line: rem(2) })
  .element('p')

const Component = () => {
  const dispatch = useDispatch()

  const [email, setEmail] = useState('')

  const { loading, success } = useSelector((state: RootState) => state.requestPasswordReset)

  return (
    <div className={classes.main}>
      <Card big className={classes.card}>
        {success ? (
          <>
            <SuccessHeading>Sent!</SuccessHeading>
            <SuccessDescription>
              You'll receive an email to reset your password. Please check your inbox.
            </SuccessDescription>
          </>
        ) : (
          <Form>
            <div className={classes.center}>
              <Logo />
              <Text variant="highlight" style={{ textAlign: 'center' }}>
                Forgot your password
              </Text>
            </div>
            <TextField
              autoFocus
              label="Work email"
              placeholder="Enter your work email"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <Button
              onClick={() => dispatch(requestPasswordReset(email))}
              disabled={loading}
              rounded
              variant="purple-dark"
            >
              Reset password
            </Button>
            <Text variant="body-text" style={{ textAlign: 'center' }}>
              <Link to="/login" className={classes.link} style={{ textAlign: 'center' }}>
                Return to login
              </Link>
            </Text>
          </Form>
        )}
      </Card>
    </div>
  )
}

export default Component
Component.displayName = 'RequestPasswordReset'
