import React from 'react'
import style from '../../core/style'

const Container = style().element()

interface Props {
  item: string
  children: React.ReactNode
}

const Component = (props: Props) => {
  return <Container>{props.children}</Container>
}

export default Component
Component.displayName = 'Tab'
