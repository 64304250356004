import { PageHeader } from '@common/components'
import { Button as ButtonV2 } from 'components/Button/ButtonV2'
import { designSystemColors } from 'core/design-system/colors'
import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import * as slice from 'store/settings/user-management'
import * as api from '../../../api'
import ContentGrid, { Column, Row } from '../../../components/ContentGrid'
import DashboardLayout, { Content, Header } from '../../../components/DashboardLayout'
import DropdownField, { Option } from '../../../components/DropdownField'
import { Icon } from '../../../components/Icons'
import Popup, { Button as PopupButton } from '../../../components/Popup'
import ProfileImage from '../../../components/ProfileImage'
import { colors, px2rem, rem, scale, size, style } from '../../../core'
import * as selectors from '../../../selectors'
import { AccessLevel, getUserPermissionsForAccessLevel } from '../../../selectors/user-permissions'
import { RootState } from '../../../store'
import { logout } from '../../../store/sessions'
import { deleteUser } from '../../../store/settings/account'
import { loadTeams, sendInvitation } from '../../../store/settings/invite'
import { loadUsersByOrgId, updateUserOrgAdmin, updateUserPermissions } from '../../../store/settings/user-management'
import { AddUserModal, AddUserValues } from '../AddUserModal'
import OrgViewSelector from '../OrgViewSelector'
import { LoadingRow } from './LoadingRow'

const UserName = style().text({ weight: '600' }).element()

const UserEmail = style().element()

const UserImage = style()
  .size({ width: rem(2) })
  .element()

const UserRow = Row([rem(1), rem(3), size.auto, size.auto, size.auto, px2rem(44 + 17 * 2)])

export const Menu = style().flex({ alignItems: 'center', justifyContent: 'center' }).box({ width: size.fill }).element()

const MenuButton = style()
  .pointer()
  .flex({ alignItems: 'center', justifyContent: 'center' })
  .noborders()
  .spacing({ inner: px2rem(0) })
  .size({ width: rem(1.75), height: px2rem(24) })
  .color({ bg: colors.transparent })
  .select(':hover', style().color({ bg: designSystemColors.backgroundNeutralPrimaryHover }))
  .element('button')

const TableCell = style()
  .box({
    width: '100%',
    minHeight: '100%',
  })
  .flex({
    alignItems: 'center',
  })
  .element()

const PopupContainer = style()
  .absolute({ right: size.none })
  .box({ width: px2rem(160) })
  .spacing({
    top: rem(4.5),
  })
  .element()

const DropDownContainer = style()
  .size({ maxWidth: rem(20) })
  .element()

const Total = style()
  .sans({
    size: scale.s,
    color: colors.gray,
    align: 'right',
  })
  .element()

const SCROLLABLE_TARGET = 'manage-users-scroll-target'

const Component = () => {
  const dispatch = useDispatch()

  // Load user and teams
  const [user, teams, loading]: [
    ReturnType<typeof selectors.users.current>,
    ReturnType<typeof selectors.teams.getById>[],
    RootState['invite']['loading'],
  ] = useSelector((state: RootState) => {
    const user = selectors.users.current(state)
    const teams = state.invite.teams.map(id => selectors.teams.getById(state, id))
    return [user, teams, state.invite.loading]
  })

  useEffect(() => {
    if (user) {
      dispatch(loadTeams(user.id))
    }
  }, [user?.id])

  // Load organization users and their permissions
  const [selectedUserId, setSelectedUserId] = React.useState<string>()
  const orgId = useSelector((state: RootState) => state.userPermissions.organization || selectors.orgs.currentId(state))
  const totalUsers = useSelector((state: RootState) => state.userPermissions.totalUsers)
  const hasMore = useSelector((state: RootState) => state.userPermissions.hasMore)
  const allUserProfilesWithLevels = useSelector((state: RootState) =>
    (state.userPermissions.users[orgId] || []).map(userId => ({
      user: selectors.users.getById(state, userId),
      profile: selectors.profiles.getByUserId(state, userId),
      accessLevel: selectors.userPermissions.getAccessLevelByUserId(state, userId),
    })),
  )
  const activeUserProfilesWithLevels = allUserProfilesWithLevels.filter(p => p.user.fields.is_active)
  const superUser = useSelector((state: RootState) => selectors.users.isSuperUser(state, selectors.users.id(state)))
  useEffect(() => {
    if (orgId) {
      dispatch(loadUsersByOrgId(orgId, 0))
    }
  }, [dispatch, orgId])

  // "Add User" Modal state and actions
  const [addUserModalOpened, setAddUserModalOpened] = useState(false)
  const openModal = () => {
    setAddUserModalOpened(true)
  }

  const onAddNewUser = async (values: AddUserValues) => {
    setAddUserModalOpened(false)
    const isOrgAdmin = values.accessLevel === AccessLevel.Administrator
    const permissions = getUserPermissionsForAccessLevel(values.accessLevel)
    await dispatch(sendInvitation(teams[0].id, values.fullName, values.emailAddress, isOrgAdmin, permissions))
    await dispatch(slice.setUsers({ orgId, users: [] }))
    await dispatch(loadUsersByOrgId(orgId, 0))
  }

  // Table actions
  const onDelete = (userId: string) => dispatch(deleteUser(userId))

  // Add new state for loading more users
  const [loadingMore, setLoadingMore] = useState(false)

  // Add intersection observer
  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: '200px',
  })

  // Add effect to trigger loading when bottom is reached
  useEffect(() => {
    if (inView && hasMore && !loadingMore) {
      loadMoreUsers()
    }
  }, [inView, hasMore, loadingMore])

  return (
    <DashboardLayout>
      <Header breadcrumbs={[{ label: 'Settings' }, { url: `/settings/users`, label: 'Users' }]}>
        {superUser ? <OrgViewSelector content /> : null}
        <PopupButton icon="sign-out" onClick={() => dispatch(logout())}>
          Sign out
        </PopupButton>
      </Header>
      <Content id={SCROLLABLE_TARGET}>
        <PageHeader.Root>
          <PageHeader.Title>Users</PageHeader.Title>
          <PageHeader.EndItems>
            <Total>{totalUsers} users</Total>
            <ButtonV2 buttonType="primary" onClick={openModal} disabled={loading}>
              Invite user
            </ButtonV2>
          </PageHeader.EndItems>
        </PageHeader.Root>
        <ContentGrid>
          <UserRow>
            <Column head></Column>
            <Column head>Name</Column>
            <Column head></Column>
            <Column head>Email</Column>
            <Column head>Permissions</Column>
            <Column head>Actions</Column>
            {activeUserProfilesWithLevels.map(row => (
              <>
                <Column></Column>
                <Column>
                  <TableCell>
                    <UserImage>
                      <ProfileImage
                        nocircle
                        src={row.profile?.fields.profile_picture_url}
                        name={row.profile?.fields.full_name || ''}
                      />
                    </UserImage>
                  </TableCell>
                </Column>
                <Column>
                  <TableCell>
                    <Link to={`/settings/accounts/${row.user.id}`} className="data-hj-suppress">
                      <UserName>{row.profile?.fields.full_name}</UserName>
                    </Link>
                  </TableCell>
                </Column>
                <Column>
                  <TableCell>
                    <Link to={`/settings/accounts/${row.user.id}`} className="data-hj-suppress">
                      <UserEmail>{row.user?.fields.email}</UserEmail>
                    </Link>
                  </TableCell>
                </Column>
                <Column>
                  <DropDownContainer>
                    <DropdownField<AccessLevel>
                      value={row.accessLevel}
                      onChange={accessLevel => {
                        onAccessLevelChange(row.user, accessLevel)
                      }}
                    >
                      <Option value={AccessLevel.Administrator} icon="hand-holding-medical">
                        Administrator
                      </Option>
                      <Option value={AccessLevel.FullAccessRecruiter} icon="user-cog">
                        Recruiter (Full Access)
                      </Option>
                      <Option value={AccessLevel.Recruiter} icon="hands-helping">
                        Recruiter
                      </Option>
                      <Option value={AccessLevel.User} icon="user">
                        Hiring Manager
                      </Option>
                    </DropdownField>
                  </DropDownContainer>
                </Column>
                <Column>
                  <TableCell>
                    <Menu>
                      <MenuButton
                        onClick={ev => {
                          ev.stopPropagation()
                          setSelectedUserId(row.user.id)
                        }}
                      >
                        <Icon name="ellipsis-h" />
                      </MenuButton>
                      <PopupContainer>
                        <Popup
                          open={selectedUserId === row.user.id}
                          setOpen={(open: boolean) => setSelectedUserId(open ? row.user.id : undefined)}
                        >
                          <PopupButton
                            variant="secondary"
                            icon="times"
                            onClick={ev => {
                              ev.stopPropagation()
                              onDelete(row.user.id)
                            }}
                          >
                            {'Remove'}
                          </PopupButton>
                        </Popup>
                      </PopupContainer>
                    </Menu>
                  </TableCell>
                </Column>
              </>
            ))}
            {loadingMore && (
              <>
                <LoadingRow />
                <LoadingRow />
                <LoadingRow />
                <LoadingRow />
                <LoadingRow />
              </>
            )}
            {hasMore && <div ref={ref} />}
          </UserRow>
        </ContentGrid>
      </Content>
      <AddUserModal onSubmit={onAddNewUser} open={addUserModalOpened} setOpen={setAddUserModalOpened} />
    </DashboardLayout>
  )

  function onAccessLevelChange(user: api.request.Entity<api.users.Fields>, accessLevel: AccessLevel) {
    if (accessLevel === AccessLevel.Administrator) {
      dispatch(updateUserOrgAdmin(user.id, true))
      return
    }

    dispatch(updateUserOrgAdmin(user.id, false))
    dispatch(updateUserPermissions(user.id, getUserPermissionsForAccessLevel(accessLevel)))
  }

  async function loadMoreUsers() {
    setLoadingMore(true)
    await dispatch(loadUsersByOrgId(orgId, allUserProfilesWithLevels.length))
    setLoadingMore(false)
  }
}

export default Component
Component.displayName = 'ManageUsers'
