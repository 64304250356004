import React from 'react'
import { pct, px2rem, style } from '../../core'
import { designSystemColors } from '../../core/design-system/colors'
import { Icon } from '../Icons'

const Button = style()
  .flex({ alignItems: 'center', justifyContent: 'center' })
  .color({
    fg: designSystemColors.typographySecondary,
    bg: designSystemColors.transparent,
  })
  .size({ width: px2rem(32), height: px2rem(32) })
  .spacing({ outerRight: px2rem(4) })
  .border({
    radius: pct(50),
    around: `solid ${px2rem(1)} ${designSystemColors.borderDefault}`,
  })
  .box({
    float: 'left',
  })
  .pointer()
  .select(':hover', style().color({ bg: designSystemColors.backgroundNeutralSecondaryHover }))
  .element('button')

interface Props {
  onClick: () => void
  icon: string
}

export const ActionButton = (props: Props) => {
  return (
    <Button onClick={props.onClick}>
      <Icon name={props.icon} />
    </Button>
  )
}
