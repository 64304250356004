import { Button, ButtonContent } from 'components/Button/ButtonV2'
import Modal from 'components/Modal'
import TextField from 'components/TextField'
import { fr, px2rem, size, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import * as tracking from 'core/track'
import React from 'react'
import ToggleField from '../../../components/ToggleField'

const Title = text.cardHeader().element('h3')

const ModalHeader = style()
  .grid({ columns: [fr(1), size.auto, size.auto], align: 'center' })
  .spacing({ gap: px2rem(8), inner: [px2rem(18), px2rem(32)] })
  .border({
    bottom: `solid ${px2rem(1)}`,
    color: designSystemColors.borderDefault,
  })
  .element()

const Content = style()
  .spacing({ inner: [px2rem(24), px2rem(32)] })
  .scroll({ y: 'auto' })
  .size({
    maxHeight: px2rem(576),
    maxWidth: px2rem(768),
  })
  .element()

const Buttons = style()
  .flex({ justifyContent: 'flex-end' })
  .spacing({ inner: [px2rem(18), px2rem(32)], gap: px2rem(16) })
  .border({
    top: `solid 1px`,
    color: designSystemColors.borderDefault,
  })
  .select('> *', style().size({ minWidth: px2rem(140) }))
  .element()

const ExplainationText = text
  .bodyInteractive()
  .spacing({ outerTop: px2rem(16) })
  .element('p')

const CustomQuestionModalStyle = style()
  .size({ minWidth: px2rem(600) })
  .element()

const ToggleContainer = style()
  .spacing({
    outer: [px2rem(32), px2rem(44), px2rem(16), px2rem(0)],
  })
  .element()

type CustomQuestionValues = {
  text: string
  isRequired: boolean
}

interface CustomQuestionModalProps {
  close: () => any
  onSubmit: (values: CustomQuestionValues) => any
  initialValues?: CustomQuestionValues
}

export const AddCustomVideoQuestionModal: React.FC<CustomQuestionModalProps> = ({ close, onSubmit, initialValues }) => {
  const [text, setText] = React.useState(initialValues?.text ?? '')
  const [isRequired, setIsRequired] = React.useState(initialValues?.isRequired ?? false)

  const isConfirmButtonDisabled = text.trim().length < 3

  return (
    <Modal
      open={true}
      setOpen={close}
      renderHeader={({ closeButton }) => (
        <ModalHeader>
          <Title>{`${initialValues?.text ? 'Update' : 'Add'} video question`}</Title>
          {closeButton}
        </ModalHeader>
      )}
    >
      <CustomQuestionModalStyle>
        <Content>
          <TextField
            label="Question text"
            placeholder="e.g: What do you value the most in a company?"
            value={text}
            multiline
            onChange={ev => setText(ev.target.value)}
            testId="add-video-question-modal-question-input"
          />
          <ToggleContainer>
            <ToggleField label={'Required'} aria-label={`Required`} on={isRequired} onChange={setIsRequired} />
          </ToggleContainer>
          <ExplainationText>The user will be asked to record a video to answer the question.</ExplainationText>
        </Content>
        <Buttons>
          <Button buttonType="tertiary" onClick={close}>
            {'Cancel'}
          </Button>
          <Button
            buttonType="accent"
            onClick={() => {
              onSubmit({ text, isRequired })
              tracking.selfAssessment.customQuestionsAddVideoQuestion()
            }}
            disabled={isConfirmButtonDisabled}
          >
            <ButtonContent
              icon={{
                name: 'plus',
                ariaLabel: initialValues?.text ? 'Update question' : 'Add question',
              }}
            >
              {initialValues?.text ? 'Update question' : 'Add question'}
            </ButtonContent>
          </Button>
        </Buttons>
      </CustomQuestionModalStyle>
    </Modal>
  )
}
