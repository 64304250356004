import * as api from '../api'
import { RootState } from '../store'
import * as candidates from './candidates'
import lookup from './lookup'

export function findById(state: RootState, id: string): api.request.Entity<api.candidateProfiles.Fields> {
  return (
    lookup<api.candidateProfiles.Fields>(state, api.candidateProfiles.RESOURCE, id) || api.candidateProfiles.empty(id)
  )
}

export function findByCandidateId(
  state: RootState,
  candidateId: string,
): api.request.Entity<api.candidateProfiles.Fields> | undefined {
  const candidate = candidates.findById(state, candidateId)
  if (!candidate) return
  return findById(state, candidate.fields.candidate_profile_id)
}

export function firstNameOf(candidate: api.request.Entity<api.candidateProfiles.Fields>): string {
  return candidate.fields.full_name.trim().split(' ')[0]
}
