import { CompanySize, companySizes } from '@common/components/Pricing/PricingCard/PricingCard'
import DropdownField, { Option } from 'components/DropdownField'
import TextField from 'components/TextField'
import React, { useEffect, useState } from 'react'
import { Rules, Validator } from 'core/validation/validator'
import { InterestedIn, interests, TellUsMoreValues, UseCase, useCases } from 'App/SignUp/TellUsMore'

interface Props {
  values: TellUsMoreValues
  onChange: React.Dispatch<React.SetStateAction<TellUsMoreValues>>
  setValid: (valid: boolean) => void
}

const companyNameValidator = new Validator([Rules.minStringLength(3), Rules.maxStringLength(128)])
const companySizeValidator = new Validator([Rules.isPresent()])
const interestedInValidator = new Validator([Rules.isPresent()])
const useCaseValidator = new Validator([Rules.isPresent()])

export const CompanyInfo: React.FC<Props> = props => {
  const [companyNameDirty, setCompanyNameDirty] = useState(false)

  const valid = {
    companyName: companyNameValidator.validate(props.values.companyName),
    companySize: companySizeValidator.validate(props.values.companySize || ''),
    interestedIn: interestedInValidator.validate(props.values.interestedIn || ''),
    useCase: useCaseValidator.validate(props.values.useCase || ''),
  }

  useEffect(() => {
    props.setValid(Object.values(valid).every(v => v.isValid))
  }, [valid])

  return (
    <>
      <TextField
        aria-label="Company name"
        label="Company name"
        placeholder="Supercompany"
        value={props.values.companyName}
        onChange={e => {
          setCompanyNameDirty(true)
          props.onChange(values => ({
            ...values,
            companyName: e.target.value,
          }))
        }}
        error={companyNameDirty && !valid.companyName.isValid}
        errorLabel={companyNameDirty && !valid.companyName.isValid ? valid.companyName.getError : ''}
      />
      <DropdownField<CompanySize | undefined>
        aria-label="Company size"
        label="Company size"
        placeholder="Please select your company size"
        value={props.values.companySize}
        onChange={e => {
          props.onChange(values => ({
            ...values,
            companySize: e,
          }))
        }}
      >
        {companySizes.map(s => (
          <Option key={s} value={s}>
            {s}
          </Option>
        ))}
      </DropdownField>
      <DropdownField<InterestedIn | undefined>
        aria-label="What are you most interested in?"
        label="What are you most interested in?"
        placeholder="Please select your interest"
        value={props.values.interestedIn}
        onChange={e => {
          props.onChange(values => ({
            ...values,
            interestedIn: e,
          }))
        }}
      >
        {interests.map(s => (
          <Option key={s} value={s}>
            {s}
          </Option>
        ))}
      </DropdownField>
      <DropdownField<UseCase | undefined>
        aria-label="What is your primary use case for HiPeople?"
        label="What is your primary use case for HiPeople?"
        placeholder="Please select your use case"
        value={props.values.useCase}
        onChange={e => {
          props.onChange(values => ({
            ...values,
            useCase: e,
          }))
        }}
      >
        {useCases.map(s => (
          <Option key={s} value={s}>
            {s}
          </Option>
        ))}
      </DropdownField>
    </>
  )
}
