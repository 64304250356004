import React from 'react'
import classes from './AnswerResultsBar.module.scss'
import { Tooltip } from '../../Tooltip/Tooltip'

export type ResultType = 'Correct' | 'Incorrect' | 'Skipped'

export interface AnswerResultsBarProps {
  numCorrectQuestions: number
  numIncorrectQuestions: number
  numSkippedQuestions: number
  fullWidth?: boolean
  fullHeight?: boolean
  onClick?: () => void
}

export const AnswerResultsBar: React.FC<AnswerResultsBarProps> = props => {
  const totalQuestions = props.numCorrectQuestions + props.numIncorrectQuestions + props.numSkippedQuestions

  const correctColSpanRaw = (props.numCorrectQuestions / totalQuestions) * 100
  const incorrectColSpanRaw = (props.numIncorrectQuestions / totalQuestions) * 100
  const skippedColSpanRaw = (props.numSkippedQuestions / totalQuestions) * 100

  const isCorrectSmallest = correctColSpanRaw > incorrectColSpanRaw && correctColSpanRaw > skippedColSpanRaw
  const isIncorrectSmallest = incorrectColSpanRaw > correctColSpanRaw && incorrectColSpanRaw > skippedColSpanRaw
  const isSkippedSmallest = skippedColSpanRaw > incorrectColSpanRaw && skippedColSpanRaw > correctColSpanRaw

  const correctColSpan = isCorrectSmallest ? Math.ceil(correctColSpanRaw) : Math.floor(correctColSpanRaw)
  const incorrectColSpan = isIncorrectSmallest ? Math.ceil(incorrectColSpanRaw) : Math.floor(incorrectColSpanRaw)
  const skippedColSpan = isSkippedSmallest ? Math.ceil(skippedColSpanRaw) : Math.floor(skippedColSpanRaw)

  return (
    <div
      onClick={props.onClick}
      className={`${classes.scores} ${props.fullWidth ? classes.fullWidth : ''} ${
        props.fullHeight ? classes.fullHeight : ''
      }`}
    >
      {Math.round(props.numCorrectQuestions) > 0 && (
        <Tooltip
          text="Correct answers"
          direction="bottom"
          style={{ gridColumn: `span ${correctColSpan}`, width: '100%' }}
        >
          <div className={`${classes.answerScore} ${classes.correct}`}>{props.numCorrectQuestions}</div>
        </Tooltip>
      )}

      {Math.round(props.numIncorrectQuestions) > 0 && (
        <Tooltip
          text="Incorrect answers"
          direction="bottom"
          style={{ gridColumn: `span ${incorrectColSpan}`, width: '100%' }}
        >
          <div className={`${classes.answerScore} ${classes.incorrect}`}>{props.numIncorrectQuestions}</div>
        </Tooltip>
      )}

      {Math.round(props.numSkippedQuestions) > 0 && (
        <Tooltip text="Not answered" direction="bottom" style={{ gridColumn: `span ${skippedColSpan}`, width: '100%' }}>
          <div className={`${classes.answerScore} ${classes.skipped}`}>{props.numSkippedQuestions}</div>
        </Tooltip>
      )}
    </div>
  )
}
