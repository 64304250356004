import React from 'react'
import { Text } from '../../'
import styles from './JobRow.module.scss'
import Line from './Line.svg?react'

export const EmptyJobRow: React.FC = () => {
  return (
    <div className={styles.jobRow}>
      <div className={styles.emptyCircle}></div>
      <div className={styles.emptyLine}></div>
      <div className={styles.emptyLine}></div>
      <div className={styles.emptyLine}></div>
      <div className={styles.emptyLine}></div>
    </div>
  )
}

export const EmptyTable: React.FC = () => {
  return (
    <>
      <EmptyJobRow />
      <EmptyJobRow />
      <EmptyJobRow />
      <div className={styles.emptyOverlay}></div>
      <div className={styles.emptyMessage}>
        <div>
          <Text variant={'timeline-step'} className={styles.emptyMessageHeader}>
            No candidate to review
          </Text>
          <Text variant={'body-text'}>To invite candidates, go ahead and create an assessment or reference check.</Text>
        </div>
        <div>
          <Line />
        </div>
      </div>
    </>
  )
}
