import React, { Fragment, useState } from 'react'
import classes from './GaussianDistribution.module.scss'
import { Tooltip } from '../../Tooltip/Tooltip'
import { InterpretationData } from './types'

export interface GaussianDistribution5Props {
  n: number
  backdrop?: boolean
  interpretations?: InterpretationData
}

export const GaussianDistribution5: React.FC<GaussianDistribution5Props> = ({
  n,
  interpretations,
  backdrop = true,
}) => {
  const [hovered, setHovered] = useState<number | undefined>()

  const getFill = (index: typeof n) => {
    return n === index ? '#755EFF' : '#dce1e2'
  }

  const getScoreCopy = (n: number): string => {
    const copy = ['Low', 'Below average', 'Average', 'Above average', 'High'][n]
    const notInterpretable = interpretations?.status === 'Not-Interpretable'
    return notInterpretable ? interpretations?.copy.description ?? copy : copy
  }

  return (
    <div className={classes.main} data-backdrop={backdrop}>
      <svg
        fill="none"
        viewBox="0 0 272 45"
        xmlns="http://www.w3.org/2000/svg"
        className={classes.graph}
        onMouseOver={() => setHovered(n)}
        onMouseLeave={() => setHovered(undefined)}
      >
        <Fragment>
          <mask
            id="mask2_2311_19"
            style={{ maskType: 'alpha' }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="50"
            height="45"
          >
            <Tooltip direction="bottom" text={getScoreCopy(n)} open={hovered === 0}>
              <rect width="50" height="45" fill={getFill(0)} />
            </Tooltip>
          </mask>
          <g mask="url(#mask2_2311_19)">
            <path
              d="M68.9249 17.6875C86.6065 8.70458 103.74 0 129 0C154.681 0 172.86 8.997 191.327 18.1366C209.492 27.1267 227.936 36.2548 254.073 37.0936C256.238 37.1631 258 38.9121 258 41.0781V41.0781C258 43.2441 256.244 45 254.078 45H3.92188C1.75589 45 0 43.2441 0 41.0781V41.0781C0 38.9121 1.76353 37.1624 3.92866 37.101C32.295 36.2968 50.8948 26.8474 68.9249 17.6875Z"
              fill={getFill(0)}
            />
          </g>
        </Fragment>

        <Fragment>
          <mask
            id="mask3_2311_19"
            style={{ maskType: 'alpha' }}
            maskUnits="userSpaceOnUse"
            x="56"
            y="0"
            width="50"
            height="45"
          >
            <Tooltip direction="bottom" text={getScoreCopy(n)} open={hovered === 1}>
              <rect x="56" width="50" height="45" fill={getFill(1)} />
            </Tooltip>
          </mask>
          <g mask="url(#mask3_2311_19)">
            <path
              d="M72.9249 17.6875C90.6065 8.70458 107.74 0 133 0C158.681 0 176.86 8.997 195.327 18.1366C213.492 27.1267 231.936 36.2548 258.073 37.0936C260.238 37.1631 262 38.9121 262 41.0781V41.0781C262 43.2441 260.244 45 258.078 45H7.92188C5.75589 45 4 43.2441 4 41.0781V41.0781C4 38.9121 5.76353 37.1624 7.92866 37.101C36.295 36.2968 54.8948 26.8474 72.9249 17.6875Z"
              fill={getFill(1)}
            />
          </g>
        </Fragment>

        <Fragment>
          <mask
            id="mask4_2311_19"
            style={{ maskType: 'alpha' }}
            maskUnits="userSpaceOnUse"
            x="111"
            y="0"
            width="50"
            height="45"
          >
            <Tooltip direction="bottom" text={getScoreCopy(n)} open={hovered === 2}>
              <rect x="111" width="50" height="45" fill={getFill(2)} />
            </Tooltip>
          </mask>
          <g mask="url(#mask4_2311_19)">
            <path
              d="M75.9249 17.6875C93.6065 8.70458 110.74 0 136 0C161.681 0 179.86 8.997 198.327 18.1366C216.492 27.1267 234.936 36.2548 261.073 37.0936C263.238 37.1631 265 38.9121 265 41.0781V41.0781C265 43.2441 263.244 45 261.078 45H10.9219C8.75589 45 7 43.2441 7 41.0781V41.0781C7 38.9121 8.76353 37.1624 10.9287 37.101C39.295 36.2968 57.8948 26.8474 75.9249 17.6875Z"
              fill={getFill(2)}
            />
          </g>
        </Fragment>

        <Fragment>
          <mask
            id="mask0_2311_19"
            style={{ maskType: 'alpha' }}
            maskUnits="userSpaceOnUse"
            x="166"
            y="0"
            width="50"
            height="45"
          >
            <Tooltip direction="bottom" text={getScoreCopy(n)} open={hovered === 3}>
              <rect x="166" width="50" height="45" fill={getFill(3)} />
            </Tooltip>
          </mask>

          <g mask="url(#mask0_2311_19)">
            <path
              d="M78.9249 17.6875C96.6065 8.70458 113.74 0 139 0C164.681 0 182.86 8.997 201.327 18.1366C219.492 27.1267 237.936 36.2548 264.073 37.0936C266.238 37.1631 268 38.9121 268 41.0781V41.0781C268 43.2441 266.244 45 264.078 45H13.9219C11.7559 45 10 43.2441 10 41.0781V41.0781C10 38.9121 11.7635 37.1624 13.9287 37.101C42.295 36.2968 60.8948 26.8474 78.9249 17.6875Z"
              fill={getFill(3)}
              style={{ cursor: 'pointer' }}
            />
          </g>
        </Fragment>

        <Fragment>
          <mask
            id="mask1_2311_19"
            style={{ maskType: 'alpha' }}
            maskUnits="userSpaceOnUse"
            x="222"
            y="0"
            width="50"
            height="45"
          >
            <Tooltip direction="bottom" text={getScoreCopy(n)} open={hovered === 4}>
              <rect x="222" width="50" height="45" fill={getFill(4)} />
            </Tooltip>
          </mask>
          <g mask="url(#mask1_2311_19)">
            <path
              d="M82.9249 17.6875C100.606 8.70458 117.74 0 143 0C168.681 0 186.86 8.997 205.327 18.1366C223.492 27.1267 241.936 36.2548 268.073 37.0936C270.238 37.1631 272 38.9121 272 41.0781V41.0781C272 43.2441 270.244 45 268.078 45H17.9219C15.7559 45 14 43.2441 14 41.0781V41.0781C14 38.9121 15.7635 37.1624 17.9287 37.101C46.295 36.2968 64.8948 26.8474 82.9249 17.6875Z"
              fill={getFill(4)}
            />
          </g>
        </Fragment>
      </svg>
    </div>
  )
}
