import action from './action'
import events from './events'
import track from './track'

export const logout = action(() => {
  track(events.ORG_SIGN_OUT, {})
})

export const settings = action(() => {
  track(events.ORG_ORGANIZATION_SETTINGS, {})
})

export const myAccount = action(() => {
  track(events.ORG_MY_ACCOUNT, {})
})

export const manageUsers = action(() => {
  track(events.ORG_MANAGE_USERS, {})
})

export const help = action(() => {
  track(events.ORG_HELP, {})
})

export const initiateCheckrConnection = action(() => {
  track(events.CHECKR_CONNECTION_INITIATED, {})
})

export const initiateCheckrDisconnection = action(() => {
  track(events.CHECKR_DISCONNECTION_INITIATED, {})
})

export const proceedCheckrDisconnection = action(() => {
  track(events.CHECKR_DISCONNECTION_PROCEEDED, {})
})

export const reportsButtonClicked = action(() => track(events.SIDEBAR_REPORTS_BUTTON_CLICKED, {}))

export const saveSelectionCandidateExperience = action(() => {
  track(events.ORG_SETTINGS_CANDIDATE_EXPERIENCE_SAVE_SELECTION, {})
})
