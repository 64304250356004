import Rows from './rows'

type Index = { [key: string]: string }

export function map(rows: Rows, values: object, cssKeys: Index) {
  for (const key in values) {
    if (cssKeys[key]) {
      rows.set(cssKeys[key], (values as Index)[key])
    }
  }
}

export function override(a: object, b: object): object {
  const result = {} as Index

  for (const key in a) {
    result[key] = (a as Index)[key]
  }

  for (const key in b) {
    result[key] = (b as Index)[key]
  }

  return result
}
