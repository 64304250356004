import { SubText } from 'App/ProductPages/Shared'
import ButtonV2 from 'components/Button/ButtonV2'
import ContentContainer from 'components/Container/ContentContainer'
import HeroGrid from 'components/ProductPageLayout/HeroGrid'
import * as tracking from 'core/track'
import React from 'react'
import Img from './EmptyResponsesScreenshot.png'

export function EmptyResponses() {
  return (
    <ContentContainer>
      <HeroGrid
        heading={'See beyond the score!'}
        subheading={
          <SubText>
            Dive into every candidate's response and compare it to the correct answer. Empower your decisions with
            unmatched clarity. Understand your candidate’s strengths, gaps, and potential. Upgrade to embrace the future
            of insightful hiring. <br />
          </SubText>
        }
        image={{
          src: Img,
          altText: 'User interface of HiPeople dashboard showing Quality of Hire preview',
          noBorder: true,
        }}
      >
        <ButtonV2 as={'a'} buttonType="accent" to={'/upgrade'} onClick={tracking.selfAssessment.unlockResponsesClicked}>
          Upgrade responses
        </ButtonV2>
      </HeroGrid>
    </ContentContainer>
  )
}
