import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as api from 'api'
import { modules } from 'api'
import { ModuleType } from 'api/modules'
import { AppThunk } from 'core/store'
import { add as notify } from 'store/notifications'
import { addEntities } from 'store/resources'

interface State {
  modules: Record<ModuleType, api.request.Entity<api.modules.Fields>[]>
  totals: Record<ModuleType, number>
}

const name = 'modules'
const initialState: State = {
  modules: {
    'candidate-experience': [],
    onboarding: [],
    'self-assessment': [],
    'soft-skills': [],
    'reference-check': [],
    'hard-skills': [],
    'mini-ipip': [],
    bfas: [],
    hexaco: [],
    disc: [],
    mindset: [],
    'cognitive-abilities': [],
    'culture-add': [],
    'candidate-centered': [],
    'knowledge-test': [],
  },
  totals: {
    'candidate-experience': 0,
    onboarding: 0,
    'self-assessment': 0,
    'soft-skills': 0,
    'reference-check': 0,
    'hard-skills': 0,
    'mini-ipip': 0,
    bfas: 0,
    hexaco: 0,
    disc: 0,
    mindset: 0,
    'cognitive-abilities': 0,
    'culture-add': 0,
    'candidate-centered': 0,
    'knowledge-test': 0,
  },
}

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setModules(state, action: PayloadAction<[ModuleType, api.request.Entity<api.modules.Fields>[]]>) {
      const [moduleType, modules] = action.payload
      state.modules[moduleType] = modules
    },
    appendModules(state, action: PayloadAction<[ModuleType, api.request.Entity<api.modules.Fields>[]]>) {
      const [moduleType, modules] = action.payload
      if (state.modules[moduleType]) {
        state.modules[moduleType] = [...state.modules[moduleType], ...modules]
      }
    },
    setTotals(state, action: PayloadAction<[ModuleType, number]>) {
      const [moduleType, total] = action.payload
      state.totals[moduleType] = total
    },
  },
})

export const { setModules, setTotals } = slice.actions

export const loadReferenceCheckModules = (): AppThunk => async dispatch => {
  const [response, errors] = await modules.listReferenceCheckModules()
  if (errors) {
    errors.forEach(e => {
      dispatch(notify({ error: e.message }))
    })
    return
  }

  if (response) {
    dispatch(addEntities(response))
    dispatch(setModules(['reference-check', response.result]))
    dispatch(setTotals(['reference-check', response.list?.total || 0]))
  }
}

export const loadBySlug =
  (slug: string): AppThunk =>
  async dispatch => {
    const [response, errors] = await modules.bySlug(slug)
    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    if (response) {
      dispatch(addEntities(response))
    }
  }

export default slice.reducer
