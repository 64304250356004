import React from 'react'
import { colors, rem, size, style } from '../../core'

const Container = style()
  .bg({ color: colors.nearWhite })
  .size({ height: size.fill })
  .flex({ alignItems: 'center', justifyContent: 'center' })
  .element()

const Content = style()
  .size({ width: rem(24) })
  .element()

interface Props {
  children: JSX.Element | (JSX.Element | null | string)[] | string
}

const Component = ({ children }: Props) => {
  return (
    <Container>
      <Content>{children}</Content>
    </Container>
  )
}

export default Component
Component.displayName = 'GuestLayout'
