import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React from 'react'

import { ScoreResponse } from '@common/api/score'
import { ScoreGroup } from '@common/components'
import { Icon } from 'components/Icons'
import { useSelector } from 'react-redux'
import * as selectors from 'selectors'
import { RootState } from 'store'
import { Group } from './AssessmentPage/AssessmentPage'

export const Title = text.bodyEmphasis().color({ fg: designSystemColors.typographyPrimary }).element('h3')

interface CognitiveAbilitiesScoreGroupProps {
  candidateFirstName: string
  candidateId: string
  roleId: string
  isSharedResults?: boolean
  defaultCollapsed?: boolean
  cognitiveAbilitiesScores: ScoreResponse['meta'][number] | undefined
  onResponsesClick: (moduleSlug: string, group: Group) => void
}

const CognitiveAbilitiesScoreGroup: React.FC<CognitiveAbilitiesScoreGroupProps> = props => {
  const group = useSelector((state: RootState) => {
    const group_redux = selectors.groups.findBySlug(state, props.cognitiveAbilitiesScores?.slug || '')
    return {
      id: group_redux?.id || '',
      name: props.cognitiveAbilitiesScores?.name || group_redux?.fields?.copy?.title || '',
      slug: group_redux?.fields?.slug || '',
      copy: {
        title: group_redux?.fields?.copy?.title || '',
        icon: group_redux?.fields?.copy?.icon ? <Icon name={group_redux?.fields?.copy?.icon} /> : '🛠',
        emoji: group_redux?.fields?.copy?.emoji,
        theme: group_redux?.fields?.copy?.theme || '',
        description: group_redux?.fields?.copy?.short_title || '',
      },
    }
  })

  const getModuleData = (score: ScoreResponse['meta'][number]) => ({
    isSharedResults: props.isSharedResults,
  })

  return (
    <ScoreGroup
      score={props.cognitiveAbilitiesScores}
      group={group}
      onClickResponses={props.onResponsesClick}
      candidateFirstName={props.candidateFirstName}
      defaultCollapsed={props.defaultCollapsed}
      getModuleData={getModuleData}
    />
  )
}

export default CognitiveAbilitiesScoreGroup
