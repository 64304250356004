import { users } from '../api'
import { Entity } from '../api/request'
import { RootState } from '../store'
import lookup from './lookup'
import { getByUserId as getUserPermissionsByUserId } from './user-permissions'

export function id(state: RootState): string {
  return state.sessions.userId
}

export function current(state: RootState): Entity<users.Fields> {
  return getById(state, id(state)) || users.empty(id(state))
}

export function getById(state: RootState, id: string): Entity<users.Fields> {
  return lookup(state, users.RESOURCE, id) || users.empty(id)
}

export function isOrgAdmin(state: RootState, id: string): boolean {
  const user = getById(state, id)
  return user?.fields.is_org_admin || false
}

export function isSuperUser(state: RootState, id: string): boolean {
  const user = getById(state, id)
  return user?.fields.is_super_user || false
}

export function canManageOrganization(state: RootState, id: string): boolean {
  return isOrgAdmin(state, id) || isSuperUser(state, id)
}

export function canCreateRole(state: RootState, id: string): boolean {
  if (isOrgAdmin(state, id) || isSuperUser(state, id)) {
    return true
  }

  const userPermissions = getUserPermissionsByUserId(state, id)
  return userPermissions?.fields.can_create_role || false
}
export function canManageContent(state: RootState, id: string): boolean {
  if (isOrgAdmin(state, id) || isSuperUser(state, id)) {
    return true
  }

  const userPermissions = getUserPermissionsByUserId(state, id)
  return userPermissions?.fields.can_manage_content || false
}

export function canProvideSupport(state: RootState, id: string): boolean {
  if (isSuperUser(state, id)) {
    return true
  }

  const userPermissions = getUserPermissionsByUserId(state, id)
  return userPermissions?.fields.can_provide_support || false
}

export function canProvideSupportOrOrgAdmin(state: RootState, id: string): boolean {
  return canProvideSupport(state, id) || isOrgAdmin(state, id)
}
