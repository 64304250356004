import { animated, useSpring } from '@react-spring/web'
import React, { memo, useEffect, useMemo, useState } from 'react'
import styles from './CountdownTimer.module.scss'

type Props = {
  endTimestamp: number
  warningPulse?: boolean
  pulseThreshold?: number
  onComplete?: () => void
}
/**
 * Timer Component that renders a countdown timer. A future timestamp is used for easier memoization
 *
 */
const Timer = (props: Props) => {
  const { endTimestamp, onComplete, warningPulse = true, pulseThreshold = 60 } = props

  const timeLeft = useCountdownTimer({ endTimestamp, onComplete })

  // we need to memoize this, so that it's only calculated on the initial render
  const totalDurationSecs = useMemo(() => endTimestamp - Math.floor(Date.now() / 1000), [endTimestamp])

  const progressPercentage = ((totalDurationSecs - timeLeft) / totalDurationSecs) * 100

  const timerClassName = `${styles.CountdownTimer} ${warningPulse && timeLeft <= pulseThreshold ? styles.pulse : ''}`

  return (
    <div className={timerClassName}>
      <MemoizedCircleProgress progressPercentage={progressPercentage} />
      <span className={styles.timeLeft}>{formatTime(timeLeft)}</span>
    </div>
  )
}

const CircleProgress = (props: { progressPercentage: number }) => {
  const radius = 12
  const strokeWidth = 3
  const circumference = 2 * Math.PI * radius
  const strokeDashoffset = (props.progressPercentage / 100) * circumference // Reverse the progress
  const circleCenter = radius + strokeWidth / 2

  const spring = useSpring({
    strokeDashoffset,
    config: { duration: 1000 }, // Animates over 1 second smoothly, should be set to the interval of the timer
  })

  return (
    <svg height={radius * 2 + strokeWidth} width={radius * 2 + strokeWidth}>
      <circle cx={circleCenter} cy={circleCenter} r={radius} fill="none" stroke="#eee" strokeWidth={strokeWidth} />
      <animated.circle
        cx={circleCenter}
        cy={circleCenter}
        r={radius}
        fill="none"
        stroke="#333333"
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset={spring.strokeDashoffset}
        strokeLinecap="round"
        transform={`rotate(-90 ${circleCenter} ${circleCenter})`}
      />

      <line
        x1={circleCenter}
        y1={circleCenter}
        x2={circleCenter}
        y2={circleCenter - radius / 2}
        stroke="#333333"
        strokeWidth="2"
        strokeLinecap="round"
      />

      <line
        x1={circleCenter}
        y1={circleCenter + 1}
        x2={circleCenter + radius / 1.8}
        y2={circleCenter + 1}
        stroke="#333333"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}

const formatTime = (seconds: number) => {
  const mins = Math.floor(seconds / 60)
  const secs = seconds % 60
  return `${String(mins).padStart(2, '0')}:${String(secs.toFixed(0)).padStart(2, '0')}`
}

const MemoizedCircleProgress = memo(CircleProgress)
const CountdownTimer = memo(Timer)

export { CountdownTimer }

const useCountdownTimer = ({
  endTimestamp,
  onComplete,
  interval = 1000,
}: {
  endTimestamp: number
  onComplete?: () => void
  interval?: number
}) => {
  const [state, setState] = useState({
    timerValue: Math.max(0, endTimestamp - Math.floor(Date.now() / 1000)),
    prevTime: Date.now(),
  })

  useEffect(() => {
    const timer = setInterval(() => {
      const now = Date.now()
      setState(() => {
        const timeLeft = Math.max(0, endTimestamp - Math.floor(now / 1000))
        if (timeLeft <= 0) {
          clearInterval(timer)
          onComplete?.()
        }
        return { timerValue: timeLeft, prevTime: now }
      })
    }, interval)

    return () => {
      clearInterval(timer)
    }
  }, [endTimestamp, onComplete, interval])

  return state.timerValue
}
