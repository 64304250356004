import { PaidProductName } from 'api/orgs'
import Button from 'components/Button/ButtonV2'
import { text } from 'core/design-system/text'
import { useAppDispatch } from 'core/store'
import React from 'react'
import { requestProductUpgrade, requestQuote } from 'store/settings/org'

export const SubText = text.bodyText().element()

export const UpgradeButton: React.FC<{
  orgId: string
  product: PaidProductName
}> = props => {
  const dispatch = useAppDispatch()

  return (
    <Button
      as={'a'}
      buttonType="accent"
      href={'https://www.hipeople.io/book-a-demo'}
      target={'_blank'}
      onClick={() => {
        dispatch(requestProductUpgrade(props.orgId, props.product))
        dispatch(requestQuote(props.orgId))
      }}
    >
      Upgrade to unlock this feature
    </Button>
  )
}
