import * as Sentry from '@sentry/react'
import token from '../core/token'

export type APIResponse<T, M = {}> = [Success<T, M>?, Errors?]

export interface Success<T, M = {}> {
  result: T
  included: Entity<any>[]
  list?: List
  meta?: M
  path: string
}

export interface RelationshipKey {
  resource: string
  id: string
}

export interface Entity<T> {
  id: string
  resource: string
  fields: T
  relationships?: RelationshipKey[]
}

export interface Error {
  code: number
  field?: string
  message: string
}

interface List {
  total: number
  skip: number
  limit: number
  has_more: boolean
}

export enum ContentStatus {}

export type Errors = Error[]

function sendFormData(path: string, options: any): ReturnType<typeof fetch> {
  return new Promise((resolve, reject) => {
    const req = new XMLHttpRequest()
    req.addEventListener('load', () =>
      resolve({
        json: async function () {
          return JSON.parse(req.response)
        },
      } as any as ReturnType<typeof fetch>),
    )
    req.addEventListener('error', err => reject(err))
    req.open(options.method, path)
    Object.entries(options.headers || {}).forEach(([key, value]) => {
      // do not set content type manually for form request
      if (key !== 'Content-Type') {
        req.setRequestHeader(key, value as string)
      }
    })
    req.send(options.formData)
  })
}

/**
 * Request handler for the API
 * @deprecated use `apiRequest` instead
 * @param method Handles the request method (GET, POST, PUT, DELETE)
 * @param path Handles the request path ie. /candidate/...
 * @param options Request options
 */
export default function request<T, M>(method: string, path: string, options: any): Promise<APIResponse<T, M>> {
  const jwt = token.read()

  const headers = {
    'Content-Type': 'application/json',
  }

  if (jwt) {
    headers['Authorization'] = 'Bearer ' + jwt
  }

  const fetchFn = options.formData ? sendFormData : fetch

  return new Promise(resolve => {
    fetchFn(getApiUrl(path), {
      headers: headers,
      method,
      ...options,
    })
      .then(raw => raw.json())
      .then(parsed => {
        if (parsed.errors) {
          if (parsed.code >= 500) {
            Sentry.withScope(scope => {
              scope.setTag('method', method)
              scope.setTag('path', path)
              scope.setTag('code', parsed.code)
              scope.setTag('request_id', parsed.request_id)
              scope.setExtra('headers', JSON.stringify(headers))
              scope.setExtra('options', JSON.stringify(options))
              scope.setExtra('response', JSON.stringify(parsed))
              scope.setExtra('error', JSON.stringify(parsed.errors))

              Sentry.captureException(new Error(parsed.errors.map(e => e?.message).join(', ')))
            })
          }

          return resolve([undefined, parsed.errors])
        }

        return resolve([normalizeResult(parsed)])
      })
      .catch(err => {
        resolve([undefined, normalizeError(err)])

        Sentry.withScope(scope => {
          scope.setTag('method', method)
          scope.setTag('path', path)
          scope.setExtra('headers', JSON.stringify(headers))
          scope.setExtra('options', JSON.stringify(options))
          scope.setExtra('error', JSON.stringify(err))

          if (err.message) {
            scope.setExtra('error_message', err.message)
          }

          Sentry.captureException(err)
        })
      })
  })
}

/**
 * Request handler for the API
 * @param method Handles the request method (GET, POST, PUT, DELETE)
 * @param path Handles the request path ie. /candidate/...
 * @param options Request options
 */
export function apiRequest<T, M>(method: string, path: string, options: any): Promise<APIResponse<T, M>> {
  const jwt = token.read()

  const headers = {
    'Content-Type': 'application/json',
  }

  if (jwt) {
    headers['Authorization'] = 'Bearer ' + jwt
  }

  const fetchFn = options.formData ? sendFormData : fetch

  return new Promise((resolve, reject) => {
    fetchFn(getApiUrl(path), {
      headers: headers,
      method,
      ...options,
    })
      .then(raw => raw.json())
      .then(parsed => {
        if (parsed.errors) {
          if (parsed.code >= 500) {
            Sentry.withScope(scope => {
              scope.setTag('method', method)
              scope.setTag('path', path)
              scope.setTag('code', parsed.code)
              scope.setTag('request_id', parsed.request_id)
              scope.setExtra('headers', JSON.stringify(headers))
              scope.setExtra('options', JSON.stringify(options))
              scope.setExtra('response', JSON.stringify(parsed))
              scope.setExtra('error', JSON.stringify(parsed.errors))

              Sentry.captureException(new Error(parsed.errors.map(e => e?.message).join(', ')))
            })
          }

          return reject([undefined, parsed.errors])
        }

        return resolve([normalizeResult(parsed)])
      })
      .catch(err => {
        reject([undefined, normalizeError(err)])

        Sentry.withScope(scope => {
          scope.setTag('method', method)
          scope.setTag('path', path)
          scope.setExtra('headers', JSON.stringify(headers))
          scope.setExtra('options', JSON.stringify(options))
          scope.setExtra('error', JSON.stringify(err))

          if (err.message) {
            scope.setExtra('error_message', err.message)
          }

          Sentry.captureException(err)
        })
      })
  })
}

export function getApiUrl(path: string): string {
  return addHostname(normalizePath(path))
}

function normalizePath(path: string): string {
  if (path.startsWith('/api/')) return path
  if (path.startsWith('/')) return '/api' + path

  return '/api/' + path
}

function normalizeError(err: any): Errors {
  if (err instanceof SyntaxError) {
    return [
      {
        code: 0,
        field: undefined,
        message: 'Unable to connect. Please check your connection.',
      },
    ]
  }

  if (err instanceof Error) {
    return [
      {
        code: 0,
        field: undefined,
        message: err.message,
      },
    ]
  }

  return err
}

function normalizeResult(parsed: any) {
  if (!parsed.result) {
    return parsed
  }

  const keys = Object.keys(parsed.result)
  if (keys.length === 1 && keys[0] === 'resource') {
    return { result: [] }
  }

  return parsed
}

export const API_BASE_KEY = 'custom:api-base'

function addHostname(path: string): string {
  const environment = getEnvironment()
  if (!environment || environment.length === 0) {
    return path
  }

  // clean up trailing /
  const hostname = environment.replace(/\/$/, '')

  return `${hostname}${path}`
}

export function getEnvironment(): string | undefined {
  // Try to find the environment stored in localStorage, if
  // that does not exist, fall back to the API_BASE.
  return localStorage.getItem(API_BASE_KEY) || import.meta.env.VITE_API_BASE
}

export function getEnvironmentName(): 'production' | 'staging' | 'local' | null {
  const envUrl = getEnvironment()

  if (envUrl?.includes('api.hipeople.io')) {
    return 'production'
  } else if (envUrl?.includes('api.feat.hipeople.io')) {
    return 'staging'
  } else if (envUrl?.includes('api.hipeople.localhost')) {
    return 'local'
  } else {
    return null
  }
}

export function sortByUpdatedAt(a: Entity<{ updated_at: number }>, b: Entity<{ updated_at: number }>): number {
  if (a.fields.updated_at > b.fields.updated_at) return -1
  if (a.fields.updated_at < b.fields.updated_at) return 1
  return 0
}
