import React from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { px2rem, space, style } from '../../core'
import { designSystemColors } from '../../core/design-system/colors'
import { text } from '../../core/design-system/text'

export interface ListItem {
  title: string
  emoji: string
  id: string
}

interface Props {
  items: ListItem[]
  onClick: (id: string) => void
  active?: string
}

const Container = style().element()

const Link = style().text({ nodecoration: true }).elementFromComponent(ReactRouterLink)

const Item = style()
  .grid({ columns: [px2rem(24), space.auto] })
  .pointer()
  .round('4px 0 0 4px')
  .size({ height: px2rem(32) })
  .spacing({ columns: space.xxs, inner: [space.none, space.xxs] })
  .cond(
    ({ isActive }: { isActive: boolean }) => isActive,
    style().bg({ color: designSystemColors.backgroundNeutralTertiary }),
  )
  .element()

const ItemEmoji = style().spacing({ outer: space.auto }).element()

const ItemTitle = text
  .bodyInteractive()
  .spacing({ outer: [space.auto, space.none] })
  .sans({ ellipsis: true })
  .element()

const ListSelector = (props: Props) => {
  return (
    <Container>
      {props.items.map(q => (
        <Link key={q.id} smooth="true" to={`#${q.id}`} onClick={() => props.onClick(q.id)}>
          <Item isActive={q.id === props.active}>
            <ItemEmoji>{q.emoji}</ItemEmoji>
            <ItemTitle>{q.title}</ItemTitle>
          </Item>
        </Link>
      ))}
    </Container>
  )
}

export default ListSelector
