import { get, post, put } from './methods'
import { APIResponse, Entity } from './request'

export const RESOURCE = 'work_verifications'

export interface WorkVerification {
  id: string
  candidate_id: string
  company_name: string
  hr_email: string
  hr_email_delivered_at: number
  hr_email_failed_at: number
  hr_email_read_at: number
  role_name: string
  from_year: number
  from_month: number
  to_year: number
  to_month: number
  submitted_at: number
  verified: false
  created_at: number
  updated_at: number
  is_active: boolean
}

interface Fields {
  work_verifications: WorkVerification[]
}

export function createWorkVerifications(
  candidateId: string,
  companies: string[],
): Promise<APIResponse<Entity<Fields>>> {
  return post(`/candidates/${candidateId}/work_verifications`, {
    work_verifications: companies.map(company => ({
      company_name: company,
    })),
  })
}

type WorkVerificationUpdatePayload = {
  id: string
  hr_email: string
}[]

export function updateWorkVerifications(
  candidateId: string,
  updatePayload: WorkVerificationUpdatePayload,
): Promise<APIResponse<Entity<Fields>>> {
  return put(`/candidates/${candidateId}/work_verifications`, {
    work_verifications: updatePayload,
  })
}

type WorkVerificationConfirmationPayload = {
  role_name?: string
  from_year?: number
  from_month?: number
  to_year?: number
  to_month?: number
  verified: boolean
}

export function confirmWorkVerification(
  candidateId: string,
  workVerificationId: string,
  updatePayload: WorkVerificationConfirmationPayload,
): Promise<APIResponse<Entity<Fields>>> {
  return put(`/candidates/${candidateId}/work_verifications/${workVerificationId}`, updatePayload)
}

export function fetchWorkVerification(id: string) {
  return get<Entity<Fields>>(`workverifications/${id}`)
}
