import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { candidateProfiles, candidates, listing, references } from '../../api'
import { AppThunk } from '../../core/store'
import { add as notify } from '../notifications'
import { addEntities } from '../resources'

interface State {
  loading: boolean
  references: string[]
  totalReferences: number
  hasMore: boolean
}

const name = 'talent-pool'
const initialState: State = {
  loading: false,
  references: [],
  totalReferences: 0,
  hasMore: false,
}

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setReferences(state, action: PayloadAction<string[]>) {
      state.references = action.payload
    },
    addReferences(state, action: PayloadAction<string[]>) {
      state.references = state.references.concat(action.payload)
    },
    setTotalReferences(state, action: PayloadAction<number>) {
      state.totalReferences = action.payload
    },
    setHasMore(state, action: PayloadAction<boolean>) {
      state.hasMore = action.payload
    },
  },
})

export const { setIsLoading, setReferences, addReferences, setTotalReferences, setHasMore } = slice.actions

export default slice.reducer

export const load =
  (query: string, orgId: string, skip?: number): AppThunk =>
  async dispatch => {
    dispatch(setIsLoading(true))

    const filters = [listing.True('is_active')]
    const [response, errors] = await listing.list<references.Fields>(`organizations/${orgId}/talent_pool`, {
      limit: 20,
      filters,
      include: [candidates.RESOURCE, candidateProfiles.RESOURCE],
      sort: [
        {
          field: 'created_at',
          order: listing.SortOrder.Desc,
        },
      ],
      skip: skip || 0,
      customQueries: {
        search_query: query,
      },
    })

    dispatch(setIsLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(addEntities(response))
      if (skip && skip > 0) {
        dispatch(addReferences(response.result.map(row => row.fields.id)))
      } else {
        dispatch(setReferences(response.result.map(row => row.fields.id)))
      }

      dispatch(setTotalReferences(response.list?.total || 0))
      dispatch(setHasMore(response.list?.has_more || false))
    }
  }
