import { colors, px, rem, space, style, weight } from '../../core'

export const Heading = style()
  .sans({
    size: rem(1.2),
    weight: weight.normal,
    color: colors.black,
    letterSpacing: px(-0.5),
  })
  .spacing({ outer: [space.none, space.none, space.s, space.none] })
  .element()

export const Subheading = style()
  .sans({
    size: rem(1.2),
    weight: weight.lighter,
    color: colors.gray,
    letterSpacing: px(-0.5),
  })
  .spacing({ outer: [space.s, space.none, space.xxs, space.none] })
  .element()
