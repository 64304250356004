import { px2rem, size, space, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React from 'react'

interface HeroScoreProps {
  score: number
  max: number
}

const HeroScoreContainer = style()
  .grid({ rows: [px2rem(20), size.auto] })
  .spacing({ inner: px2rem(8) })
  .color({ bg: designSystemColors.backgroundNeutralSecondary })
  .border({ radius: px2rem(8) })
  .element()

const HeroScoreStyle = text.scoreHighlightSecondary().spacing({ outer: space.auto }).element()

const Score = text.scoreHighlightPrimary().inline().element()

const Title = text.label().fg(designSystemColors.typographySecondary).spacing({ outer: space.auto }).element()

const Component: React.FC<HeroScoreProps> = ({ score, max }) => {
  return (
    <HeroScoreContainer>
      <Title>OVERALL</Title>
      <HeroScoreStyle>
        <Score>{score}</Score> / {max}
      </HeroScoreStyle>
    </HeroScoreContainer>
  )
}

export default Component
Component.displayName = 'HeroScore'
