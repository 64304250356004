import ScoreBar from 'components/Scores/ScoreBar'
import { px2rem, size, space, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import React from 'react'

const Container = style().relative().element()

const PointerLine = style()
  .absolute()
  .size({ width: size.fill, height: px2rem(2) })
  .flex()
  .spacing({ outerTop: `-${px2rem(4)}` })
  .element()

interface Props {
  overall: number | null
  average: number | null
  maxDisplayedScore?: number
}

const Bar: React.FC<Props> = ({ overall, average, maxDisplayedScore = 5 }) => {
  return (
    <Container>
      {average && overall ? (
        <PointerLine>
          <LineAndCircle overall={overall} average={average} max={maxDisplayedScore} />
        </PointerLine>
      ) : (
        <div></div>
      )}
      <ScoreBar score={overall} maxDisplayedScore={maxDisplayedScore} />
    </Container>
  )
}

export default Bar

interface LineCircleProps {
  overall: number
  average: number
  max: number
}

const LineAndCircle: React.FC<LineCircleProps> = ({ overall, average, max }) => {
  const diff = overall - average
  if (Math.abs(diff) <= 0.2) {
    return (
      <CircleContainer>
        <Offset offset={average} max={max} />
        <Circle />
      </CircleContainer>
    )
  }

  if (diff < 0) {
    return (
      <CircleContainer>
        <Offset offset={Math.min(overall, average)} max={max} />
        <LeadingLine score={Math.abs(diff)} negative max={max} />
        <Circle />
      </CircleContainer>
    )
  }

  return (
    <CircleContainer>
      <Offset offset={Math.min(overall, average)} max={max} />
      <Circle />
      <LeadingLine score={Math.abs(diff)} max={max} />
    </CircleContainer>
  )
}

const CircleContainer = style().size({ width: size.fill }).flex().element()

const Offset = style()
  .with(({ offset, max }: { offset: number; max: number }) =>
    style().spacing({ outerLeft: `${offset * (100 / max)}%` }),
  )
  .element()

const Circle = style()
  .size({ width: px2rem(2), height: size.fill })
  .spacing({ outer: [space.none, px2rem(1)] })
  .bg({ color: designSystemColors.typographySecondary })
  .border({ radius: px2rem(2) })
  .element()

const LeadingLine = style()
  .size({ width: px2rem(2), height: size.fill })
  .with(({ score, max }: { score: number; max: number }) =>
    style().size({ width: `calc(${score * (100 / max)}% - ${px2rem(4)})` }),
  )
  .bg({ color: designSystemColors.informationGreenPrimary })
  .cond(
    ({ negative }: { negative: boolean }) => negative,
    style().bg({ color: designSystemColors.informationRedPrimary }),
  )
  .border({ radius: size.xxs })
  .element()
