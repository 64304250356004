import CollapsibleSummary, { CollapsibleSummaryProps } from 'components/CollapsibleSummary'
import ConfirmHireStatusModal from 'components/ConfirmHireStatusModal'
import TopicSummary from 'components/TopicSummary'
import { fr, px2rem, scale, space, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import * as tracking from 'core/track'
import { useCandidate } from 'providers/candidates'
import { useHireStatus } from 'providers/candidates/hire-status'
import { usePostHireOnboardingSummaries, usePreHireOnboardingSummaries } from 'providers/onboarding-summaries'
import { useManagers } from 'providers/onboarding/managers'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import * as selectors from 'selectors'
import { milliseconds } from 'selectors'
import { RootState } from 'store'
import { fetchItemScores } from 'store/candidates'
import { add as notify } from 'store/notifications'
import { OnboardingHeader } from '../NameHeader'
import MissingInfoBanner from './MissingInfoBanner'
import OnboardingAttributesDetailModal from './OnboardingAttributesDetailModal'
import { OverallEvaluationSummary } from './OverallEvaluationSummary'
import PersonalityProfile from './PersonalityProfile'

function getCollapsibleSummaryList(
  summaries: ReturnType<typeof usePreHireOnboardingSummaries>,
  copyVariables: {
    firstName: string
    possesiveFirstName: string
  },
  onUsefulButtonsClick: () => void,
) {
  const { firstName, possesiveFirstName } = copyVariables
  const collapsibleSummaries: CollapsibleSummaryProps[] = []

  if (summaries.onboardingBuddySummary) {
    collapsibleSummaries.push({
      emoji: '🧑‍🤝‍🧑',
      emojiColor: 'purple',
      title: `Pick the right onboarding buddy`,
      intro: `There is no doubt that onboarding buddies are beneficial to any onboarding process. Optimize this positive effect by using ${possesiveFirstName} profile to ensure a good match with the buddy.`,
      encourage: summaries.onboardingBuddySummary.encourage,
      payAttention: summaries.onboardingBuddySummary.warning,
      documentationLink:
        'https://www.notion.so/hipeople/Picking-the-right-Onboarding-Buddy-PUBLIC-DOCUMENTATION-c17bf93f3e45483c94099941575ac99a',
      missingModules: [],
      onUsefulButtonsClick: onUsefulButtonsClick,
    })
  }

  if (summaries.optimiseFirstTaskSummary) {
    collapsibleSummaries.push({
      emoji: '🌱',
      emojiColor: 'purple',
      title: `Optimize ${possesiveFirstName} first tasks`,
      intro: `New hires need the chance to showcase their strengths to build confidence quickly. Use ${possesiveFirstName} profile to personalize their first tasks and boost performance.`,
      encourage: summaries.optimiseFirstTaskSummary.encourage,
      payAttention: summaries.optimiseFirstTaskSummary.warning,
      documentationLink:
        'https://www.notion.so/hipeople/Optimize-new-hire-first-tasks-Public-Documentation-bd3044f598884945bf4054b01833c18c',
      missingModules: [],
      onUsefulButtonsClick: onUsefulButtonsClick,
    })
  }

  if (summaries.maximiseHireEngagementSummary) {
    collapsibleSummaries.push({
      emoji: '🌟',
      emojiColor: 'purple',
      title: `Maximize ${possesiveFirstName} engagement`,
      intro: `Reduce turnover risk and enable long-term engagement by knowing what really matters to ${firstName}.`,
      encourage: summaries.maximiseHireEngagementSummary.encourage,
      payAttention: summaries.maximiseHireEngagementSummary.warning,
      documentationLink:
        'https://www.notion.so/hipeople/Maximize-new-hire-engagement-PUBLIC-DOCUMENTATION-568614eb75044ec0ba50efc4075df8b4',
      missingModules: [],
      onUsefulButtonsClick: onUsefulButtonsClick,
    })
  }

  if (summaries.fosterNewHireSummary) {
    collapsibleSummaries.push({
      emoji: '👌',
      emojiColor: 'purple',
      title: `Foster ${possesiveFirstName} integration`,
      intro: `Becoming a member of the team is an essential part of any successful onboarding experience. Use previous learnings to help integrate ${firstName} from day one.`,
      encourage: summaries.fosterNewHireSummary.encourage,
      payAttention: summaries.fosterNewHireSummary.warning,
      documentationLink:
        'https://www.notion.so/hipeople/Foster-New-Hire-integration-PUBLIC-DOCUMENTATION-92f20c29b3484d6db143288e883b8afe',
      missingModules: [],
      onUsefulButtonsClick: onUsefulButtonsClick,
    })
  }

  if (summaries.nurtureTrustSummary) {
    collapsibleSummaries.push({
      emoji: '🤝',
      emojiColor: 'purple',
      title: `Nurture ${possesiveFirstName} trust`,
      intro: `Did you know that the first day at a new job is the most critical event for developing trust? Discover which cues of trustworthiness are most likely to work for ${firstName} using science-backed insights.`,
      encourage: summaries.nurtureTrustSummary.encourage,
      payAttention: summaries.nurtureTrustSummary.warning,
      documentationLink:
        'https://www.notion.so/hipeople/Nurture-new-hire-trust-Public-Documentation-42f09db2ed0c4e7a879d1f3f268347f0',
      missingModules: [],
      onUsefulButtonsClick: onUsefulButtonsClick,
    })
  }

  return collapsibleSummaries
}

const PreHireContentContainer = style()
  .grid({ columns: [px2rem(360), fr(1)] })
  .spacing({ gap: px2rem(16), top: px2rem(16) })
  .color({
    fg: designSystemColors.typographyPrimary,
  })
  .element()

const SidebarContainer = style()
  .flex({ direction: 'column' })
  .spacing({ gap: space.xs })
  .sans({ size: scale.s })
  .element()

const SidebarHeader = text.cardHeader().element('h2')
const SidebarCopy = text.bodyText().color({ fg: designSystemColors.typographySecondary }).element('p')

const SummaryContainer = style()
  .spacing({ innerBottom: px2rem(32) })
  .element()

const SupportMessage = text
  .bodyText()
  .flex({ justifyContent: 'center', alignItems: 'flex-start' })
  .spacing({ top: px2rem(16), bottom: px2rem(16) })
  .color({ fg: designSystemColors.typographySecondary })
  .element('p')

const SupportLink = text
  .bodyInteractive()
  .color({ fg: designSystemColors.typographySecondary })
  .spacing({ left: px2rem(4) })
  .element('a')

const CollapsibleSummaryList = style()
  .flex({ direction: 'column' })
  .spacing({ gap: px2rem(14) })
  .element()

const ContentContainer = style()
  .grid()
  .spacing({ rows: px2rem(32) })
  .element()

const PostHireContentContainer = style()
  .grid()
  .spacing({ rows: px2rem(12) })
  .element()

const OverallScoresContainer = style()
  .grid({ columns: [fr(1), fr(1)] })
  .spacing({ columns: px2rem(16) })
  .element()

interface OnboardingPageContentProps {
  candidateId: string
  employeeId: string
  onClickUpdateMissing?: () => void
}

const OnboardingPageContent: React.FC<OnboardingPageContentProps> = ({
  candidateId,
  onClickUpdateMissing,
  employeeId,
}) => {
  const { candidateProfile } = useCandidate({ candidateId })
  const summaries = usePreHireOnboardingSummaries({ candidateId })
  const { analyzeTopics, praiseTopics, employeeExperience, managerEvaluation } = usePostHireOnboardingSummaries({
    employeeId,
  })

  const [selectedQuestionId, setSelectedQuestionId] = useState<null | string>(null)

  const dispatch = useDispatch()
  useEffect(() => {
    if (candidateId) {
      dispatch(fetchItemScores(candidateId))
    }
  }, [candidateId])

  const possesiveFirstName = selectors.possessiveFirstName(candidateProfile?.fields.full_name || '')
  const firstName = selectors.firstName(candidateProfile?.fields.full_name || '')
  const SUPPORT_LINK = 'mailto: science@hipeople.io'

  const collapsibleSummaryList = getCollapsibleSummaryList(
    summaries,
    {
      firstName,
      possesiveFirstName,
    },
    () => dispatch(notify({ success: 'Thank you for the feedback! 🙌' })),
  )

  return (
    <>
      <OnboardingHeader candidateId={candidateId} />
      <MissingInfoBanner onClickUpdateMissing={onClickUpdateMissing} candidateId={candidateId} />
      <ContentContainer>
        {managerEvaluation && employeeExperience && (
          <PostHireContentContainer>
            <OverallScoresContainer>
              <OverallEvaluationSummary
                title={`${firstName}'s Experience`}
                emoji={`👋`}
                emojiColor={'purple'}
                overallScore={employeeExperience?.overall}
                roleScore={employeeExperience?.role}
                teamScore={employeeExperience?.team}
                organizationScore={employeeExperience?.organization}
              />
              <OverallEvaluationSummary
                title={'Manager Evaluation'}
                emoji={`📊`}
                emojiColor={'purple'}
                overallScore={managerEvaluation?.overall}
                roleScore={managerEvaluation?.role}
                teamScore={managerEvaluation?.team}
                organizationScore={managerEvaluation?.organization}
              />
            </OverallScoresContainer>
            {praiseTopics && analyzeTopics && (praiseTopics.length || analyzeTopics.length) && (
              <TopicSummary
                title={`Feedback Topics`}
                emoji={'📌'}
                praise={praiseTopics}
                adjust={analyzeTopics}
                onItemClick={(id: string) => setSelectedQuestionId(id)}
              />
            )}
          </PostHireContentContainer>
        )}
        {collapsibleSummaryList.length > 0 && (
          <PreHireContentContainer>
            <SidebarContainer>
              <SidebarHeader className="data-hj-suppress">{`Based on ${possesiveFirstName} Profile`}</SidebarHeader>
              <SidebarCopy className="data-hj-suppress">{`Use the science-backed tips on this page to make the most out of your onboarding efforts! Tips are based on ${possesiveFirstName} personality profile. The profile summarizes all feedback we received about ${firstName}.`}</SidebarCopy>
              {summaries.oceanProfileScores && <PersonalityProfile scores={summaries.oceanProfileScores} />}
            </SidebarContainer>
            <SummaryContainer>
              <CollapsibleSummaryList>
                {collapsibleSummaryList.map(summary => (
                  <div key={summary.title} className="data-hj-suppress">
                    <CollapsibleSummary {...summary} />
                  </div>
                ))}
              </CollapsibleSummaryList>
              <SupportMessage>
                {'Need support on a particular topic? '}
                <SupportLink onClick={() => tracking.onboarding.supportButtonClicked()} href={SUPPORT_LINK}>
                  Let us know here
                </SupportLink>
              </SupportMessage>
            </SummaryContainer>
          </PreHireContentContainer>
        )}
      </ContentContainer>
      {selectedQuestionId && (
        <OnboardingAttributesDetailModal
          employeeId={employeeId}
          close={() => setSelectedQuestionId(null)}
          questionId={selectedQuestionId}
        />
      )}
    </>
  )
}

interface OnboardingPageProps {
  isInSharedViewMode: boolean
}

const OnboardingPage: React.FC<OnboardingPageProps> = values => {
  const { candidateid: candidate_id } = useParams<{ candidateid: string }>()
  const candidateid = candidate_id || ''

  const { candidate } = useCandidate({
    candidateId: candidateid || '',
  })

  const employee = useSelector((state: RootState) =>
    selectors.employees.findById(state, candidate?.fields.employee_id || ''),
  )
  const org = useSelector((state: RootState) => selectors.orgs.getById(state, selectors.orgs.currentId(state)))

  const { managers } = useManagers({ orgId: org.id })
  const { updateHireStatus } = useHireStatus()

  const [modalOpen, setModalOpen] = useState(false)

  if (!candidateid) return null

  const createComponents = () => {
    return (
      <OnboardingPageContent
        onClickUpdateMissing={() => {
          tracking.onboarding.missingInformationButtonClicked()
          setModalOpen(true)
        }}
        candidateId={candidateid}
        employeeId={candidate?.fields.employee_id || ''}
      />
    )
  }

  if (values.isInSharedViewMode) {
    return <>{createComponents()}</>
  }

  const startDate = employee?.fields.start_at && new Date(milliseconds(employee?.fields.start_at))

  return (
    <>
      {createComponents()}
      {employee && modalOpen && (
        <ConfirmHireStatusModal
          initDay={startDate && startDate.getDay()}
          initMonth={startDate && startDate.getMonth()}
          initYear={startDate && startDate.getFullYear()}
          initManagerUserId={employee.fields.manager_id}
          initEmployeeEmail={employee.fields.work_email}
          isOpen={true}
          setOpen={setModalOpen}
          userProfiles={managers}
          onSubmitCallback={() => updateHireStatus(candidateid, true, false)}
        />
      )}
    </>
  )
}

export default OnboardingPage
