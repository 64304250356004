import action from './action'
import events from './events'
import track from './track'

export const sidebarQualityOfHirePageViewed = action(() => {
  track(events.SIDEBAR_QUALITY_OF_HIRE_VIEWED, {})
})

export const settingsModalSetOpen = action((open: boolean) => {
  if (open) {
    track(events.QUALITY_OF_HIRE_SETTINGS_MODAL_OPENED, {})
  } else {
    track(events.QUALITY_OF_HIRE_SETTINGS_MODAL_CLOSED, {})
  }
})

export const settingsModalToggleEnableClicked = action(() => {
  track(events.QUALITY_OF_HIRE_SETTINGS_MODAL_TOGGLE_ENABLE_CLICKED, {})
})

export const settingsModalConfirmClicked = action(() => {
  track(events.QUALITY_OF_HIRE_SETTINGS_MODAL_CONFIRM_CLICKED, {})
})

export const settingsModalTimeIntervalChanged = action((value: number) => {
  track(events.QUALITY_OF_HIRE_SETTINGS_MODAL_TIME_INTERVAL_CHANGED, { value })
})

export const employeeRemoved = action(() => {
  track(events.QUALITY_OF_HIRE_EMPLOYEE_REMOVED, {})
})

export const employeeClicked = action((id: string, name: string) => {
  track(events.QUALITY_OF_HIRE_EMPLOYEE_CLICKED, { id, name })
})

export const enableCtaButtonClicked = action(() => {
  track(events.QUALITY_OF_HIRE_ENABLE_CTA_BUTTON_CLICKED, {})
})

export const addNewHireModalSetOpen = action((open: boolean) => {
  if (open) {
    track(events.QUALITY_OF_HIRE_ADD_NEW_HIRE_MODAL_OPENED, {})
  } else {
    track(events.QUALITY_OF_HIRE_ADD_NEW_HIRE_MODAL_CLOSED, {})
  }
})

export const addNewHireModalConfirmClicked = action(() => {
  track(events.QUALITY_OF_HIRE_ADD_NEW_HIRE_MODAL_CONFIRM_CLICKED, {})
})
