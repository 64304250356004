import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addByUserId, deleteByUserId } from '../../api/slack-profiles'
import * as selectors from '../../selectors'
import { RootState } from '../../store'
import { add as notify } from '../../store/notifications'
import { addEntities } from '../../store/resources'

const Component = () => {
  const dispatch = useDispatch()

  const user = useSelector((state: RootState) => selectors.users.current(state))

  const search = new URLSearchParams(window.location.search)
  const paramRedirect = search.get('redirect')
  const paramSlackUserId = search.get('slack_user_id')
  const paramSlackTeamId = search.get('slack_team_id')
  const paramDisconnect = search.get('disconnect')

  useEffect(() => {
    if (!user?.id) return

    if (paramDisconnect) {
      deleteUserSlackProfile()
    } else {
      addUserSlackProfile()
    }

    window.location.href = paramRedirect || ''
  }, [user?.id])

  return <></>

  async function addUserSlackProfile() {
    if (!user || !paramSlackUserId || !paramSlackTeamId) return

    const [response, errors] = await addByUserId(user.id, paramSlackUserId, paramSlackTeamId)

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
    }
    if (response) {
      dispatch(addEntities(response))
    }
  }

  async function deleteUserSlackProfile() {
    if (!user) return

    const [response, errors] = await deleteByUserId(user.id)
    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
    }
    if (response) {
      dispatch(addEntities(response))
    }
  }
}

export default Component
Component.displayName = 'SlackPortal'
