import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { employees, listing } from '../../api'
import { AppThunk } from '../../core/store'
import { add as notify } from '../notifications'
import { addEntities } from '../resources'

interface State {
  loading: boolean
  employeeIds: string[]
  totalEmployees: number
  fetching: { [key: string]: boolean }
  fetchedAt: { [key: string]: number }
  hasMore: boolean
  sortedBy: SortCriteria
}

export enum SortField {
  Name = 'name',
  StartingDate = 'start_at',
  OverallScore = 'overall_score',
  NewHireExperienceScore = 'new_hire_experience_score',
  ManagersEvaluationScore = 'managers_evaluation_score',
  UpdatedAt = 'updated_at',
}

export const defaultSortField = SortField.UpdatedAt
export const defaultSortOrder = listing.SortOrder.Desc

interface SortCriteria {
  order: listing.SortOrder
  field: SortField
}

const name = employees.RESOURCE
const initialState: State = {
  loading: false,
  employeeIds: [],
  totalEmployees: 0,
  fetching: {},
  fetchedAt: {},
  hasMore: false,
  sortedBy: { order: defaultSortOrder, field: defaultSortField },
}

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setIsFetching(state, action: PayloadAction<{ orgId: string; fetching: boolean }>) {
      const { orgId, fetching } = action.payload
      state.fetching[orgId] = fetching

      if (!fetching) {
        state.fetchedAt[orgId] = Date.now()
      }
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setHasMore(state, action: PayloadAction<boolean>) {
      state.hasMore = action.payload
    },
    setEmployeeIds(state, action: PayloadAction<string[]>) {
      state.employeeIds = action.payload
    },
    addEmployeeIds(state, action: PayloadAction<string[]>) {
      state.employeeIds = state.employeeIds.concat(action.payload)
    },
    setTotalEmployees(state, action: PayloadAction<number>) {
      state.totalEmployees = action.payload
    },

    setSortField(state, action: PayloadAction<SortField>) {
      const field = action.payload

      if (state.sortedBy) {
        state.sortedBy.field = field
        return
      }

      state.sortedBy = {
        field: field,
        order: listing.SortOrder.Desc,
      }
    },
    setSortDesc(state, action: PayloadAction<listing.SortOrder>) {
      const order = action.payload
      if (state.sortedBy) {
        state.sortedBy.order = order
        return
      }

      state.sortedBy = {
        field: SortField.UpdatedAt,
        order: order,
      }
    },
  },
})

export const {
  setIsFetching,
  setEmployeeIds,
  addEmployeeIds,
  setTotalEmployees,

  setHasMore,
  setSortField,
  setSortDesc,
} = slice.actions

export default slice.reducer

export const loadEmployees =
  (orgId: string, skip?: number): AppThunk =>
  async (dispatch, getStore) => {
    // Prevent over fetching
    const store: { [employees.RESOURCE]: State } = getStore()
    const isAlreadyFetching = store.employees.fetching[orgId]
    const sortedBy = store.employees.sortedBy
    if (isAlreadyFetching) {
      return
    }

    dispatch(setIsFetching({ orgId, fetching: true }))

    const isSortedByScore = [
      SortField.OverallScore,
      SortField.NewHireExperienceScore,
      SortField.ManagersEvaluationScore,
    ].includes(sortedBy.field)

    const sort: SortCriteria = isSortedByScore ? { order: defaultSortOrder, field: defaultSortField } : sortedBy

    const [response, errors] = await employees.list(orgId, {
      limit: 20,
      filters: [listing.True('is_active')],
      sort: [sort],
      skip: skip || 0,
    })
    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    dispatch(setIsFetching({ orgId, fetching: false }))

    if (response) {
      if (isSortedByScore) {
        response.result.sort((a, b) => a.fields.scores[sortedBy.field] || 0 - b.fields.scores[sortedBy.field] || 0)

        if (sortedBy.order === listing.SortOrder.Asc) {
          response.result.reverse()
        }
      }

      dispatch(addEntities(response))
      if (skip && skip > 0) {
        dispatch(addEmployeeIds(response.result.map(row => row.fields.id)))
      } else {
        dispatch(setEmployeeIds(response.result.map(row => row.fields.id)))
      }

      dispatch(setTotalEmployees(response.list?.total || 0))
      dispatch(setHasMore(response.list?.has_more || false))
    }
  }

export const loadEmployeeById =
  (orgId: string, employeeId: string, include?: string): AppThunk =>
  async dispatch => {
    const [response, errors] = await employees.getById(orgId, employeeId, include)
    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    if (response) {
      dispatch(addEntities(response))
    }
  }

export const remove =
  (orgid: string, employeeId: string): AppThunk =>
  async (dispatch, getStore) => {
    const [response, errors] = await employees.remove(orgid, employeeId)

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    const store: { [employees.RESOURCE]: State } = getStore()

    if (response) {
      dispatch(addEntities(response))
      dispatch(setTotalEmployees(store.employees.totalEmployees - 1))
      dispatch(setEmployeeIds(store.employees.employeeIds.filter(id => id !== employeeId)))
      dispatch(
        notify({
          success: 'The selected hire has been archived.',
        }),
      )
    }
  }

export const create =
  (orgId: string, employeeFields: employees.CreateFields): AppThunk =>
  async (dispatch, getStore) => {
    // Prevent over fetching
    const store: { [employees.RESOURCE]: State } = getStore()

    const [response, errors] = await employees.create(orgId, employeeFields)

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(setTotalEmployees(store.employees.totalEmployees + 1))
      dispatch(addEmployeeIds([response.result.id]))

      dispatch(addEntities(response))
      dispatch(
        notify({
          success: 'New hire created successfully.',
        }),
      )
      return true
    }
  }
