// Wrap tracking actions with try/catch, so they won't interrupt the runtime
// in case of an any error
export default <T extends Array<any>, U>(fn: (...args: T) => U) => {
  return (...args: T) => {
    try {
      return fn(...args)
    } catch (err) {
      console.error('[track]', err)
    }
  }
}
