import React from 'react'
import { Text } from '../Text/Text'
import classes from './Chip.module.scss'

interface ChipProps extends React.HTMLAttributes<HTMLDivElement> {}

export const Chip = ({ className, ...props }: ChipProps) => {
  return (
    <div className={`${classes.main} ${className ? className : ''}`} {...props}>
      <Text variant="body-text" className={classes.text}>
        {props.children}
      </Text>
    </div>
  )
}
