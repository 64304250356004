import { SortOrder } from 'api/listing'
import React from 'react'
import { colors, space, style } from '../../core'
import Column, { Props as ColumnProps } from './Column'

interface Props<T> extends ColumnProps {
  children: React.ReactNode
  field: T
  sortingField: T
  setSortingField: (field: T) => void
  desc: boolean
  setDesc: (order: SortOrder) => void
}

const Container = style().flex().center({ vertical: true }).size({ height: '100%' }).element()

const SortingIcon = style().fg(colors.darkSilver).spacing({ innerLeft: space.xxs }).element('span')

function Component<T>(props: Props<T>) {
  return (
    <Column center={props.center} head onClick={onClick} pointer>
      <Container>
        {props.children}
        {props.field === props.sortingField ? (
          <SortingIcon>
            <i className={`fa fa-${!props.desc ? 'chevron-down' : 'chevron-up'}`} />
          </SortingIcon>
        ) : null}
      </Container>
    </Column>
  )

  function onClick() {
    if (props.field === props.sortingField) {
      const newOrder = props.desc ? SortOrder.Asc : SortOrder.Desc
      props.setDesc(newOrder)
      return
    }

    props.setSortingField(props.field)
  }
}

export default Component
Component.displayName = 'SortedColumn'
