import { Button, Modal, Text, Tooltip } from '@common/components'
import { Product } from 'api/templates'
import * as templatesApi from 'api/templates'

import { SearchTemplates } from './SearchTemplates'
import { Icon } from 'components/Icons'

import PreviewTable from 'App/RoleCreation/PreviewTable'
import { TemplateEditorHeader } from 'App/Templates/TemplateEditorHeader'
import { useGetTemplatePreview, useSearchTemplates } from 'hooks/useSearchTemplates'
import React, { useState } from 'react'
import { FaXmark } from 'react-icons/fa6'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useMount } from 'react-use'
import * as modulesSlice from 'store/modules'
import * as skillsSlice from 'store/skills'
import * as selectors from 'selectors'
import * as templatesSlice from 'store/templates'

import { RootState } from 'store'
import { useQueryParams } from 'hooks/useQueryParams'
import { z } from 'zod'
import { zIndex } from '@common/styles/zIndex'
import { addReferenceCheckQuestionnaireToRole, addSelfAssessmentPresetToRole } from 'store/new-role'
import { Entity } from 'api/request'

const TemplatesPage = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  const { getParam } = useQueryParams(queryParamSchema)

  /* 
  there are two modes of the template library page:

  single:
   - used when viewing a associated to a role, e.g "Select Assessment template". 
   - hides the tab switcher
   - shows the select button in the preview modal

  template:
   - used when creating a new template from scratch, e.g clicking Template Library.
   - shows the tab switcher
   - hides the select button in the preview modal
  */
  const mode = getParam('mode', 'single')
  const product = getParam('product', 'self-assessment')
  const roleId = getParam('roleid')

  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(null)
  const [selectedTab, setSelectedTab] = useState<Product>(product)

  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false)
  const [isDeleteWarningModalOpen, setIsDeleteWarningModalOpen] = useState(false)

  const selectedRole = useSelector((state: RootState) => (roleId ? selectors.roles.findById(state, roleId) : null))
  const orgId = useSelector((state: RootState) => selectors.orgs.currentId(state))

  const { query, setQuery, templates, isLoading } = useSearchTemplates({
    product,
    locale: selectedRole?.fields.assessment_locale ?? 'en_US',
  })

  useMount(() => {
    dispatch(modulesSlice.loadReferenceCheckModules())
    dispatch(skillsSlice.load())
  })

  const handleSelectTab = (tab: Product) => {
    setSelectedTab(tab)
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('product', tab)
    history.replace({ search: searchParams.toString() })
  }

  const handleSelectTemplate = (templateId: string) => {
    setSelectedTemplateId(templateId)
    setIsPreviewModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsPreviewModalOpen(false)
    setSelectedTemplateId(null)
  }

  const handleClickDelete = () => {
    setIsPreviewModalOpen(false)
    setIsDeleteWarningModalOpen(true)
  }

  const handleDeleteTemplate = async () => {
    if (!selectedTemplateId) return
    await dispatch(
      templatesSlice.setPrivate({ organizationId: orgId, templateId: selectedTemplateId, isPrivate: true }),
    )
    setIsDeleteWarningModalOpen(false)
    setSelectedTemplateId(null)
  }

  const handleCloseDeleteWarningModal = () => {
    setIsDeleteWarningModalOpen(false)
  }

  const handleExit = () => {
    if (roleId) {
      history.push(`/roles/${roleId}`)
    } else {
      history.push(`/`)
    }
  }

  const canManageContent = useSelector((state: RootState) =>
    selectors.users.canManageContent(state, selectors.users.id(state)),
  )
  const disableEdit =
    !templates.find(template => template.id === selectedTemplateId)?.isHighlighted || !canManageContent

  return (
    <>
      <div
        style={{
          padding: '2rem',
        }}
      >
        <TemplateEditorHeader showWarningModal={mode === 'single'} onExit={handleExit} />
        <section
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '1rem',
            height: '100%',
          }}
        >
          <Text
            variant="hero-text"
            style={{
              textAlign: 'center',
              fontWeight: 600,
            }}
            data-testid="template-library-title"
          >
            Template library
          </Text>
          <SearchTemplates
            onSelect={handleSelectTemplate}
            templates={templates.filter(template => !template.isHighlighted)}
            quickTemplates={templates.filter(template => template.isHighlighted)}
            selectedTemplateId={selectedTemplateId}
            filterString={query}
            setFilterString={setQuery}
            isLoading={isLoading}
            header={
              <>
                {mode === 'template' && (
                  <div>
                    <Button
                      variant={selectedTab === 'self-assessment' ? 'light' : 'secondary'}
                      onClick={() => handleSelectTab('self-assessment')}
                      data-testid="select-assessment-template-tab"
                    >
                      Assessments
                    </Button>
                    <Button
                      variant={selectedTab === 'reference-check' ? 'light' : 'secondary'}
                      onClick={() => handleSelectTab('reference-check')}
                      data-testid="select-reference-check-template-tab"
                    >
                      Reference Checks
                    </Button>
                  </div>
                )}
              </>
            }
          />
          <ActionButtons mode={mode} product={selectedTab} roleId={roleId} />
        </section>
        {isPreviewModalOpen && selectedTemplateId && (
          <PreviewTemplateModal
            orgId={orgId}
            product={product}
            templateId={selectedTemplateId}
            onClose={handleCloseModal}
            onClickDelete={handleClickDelete}
            title={getTemplateTitle(templates, selectedTemplateId)}
            showSelectButton={mode === 'single'}
            roleId={roleId}
            disableEdit={disableEdit}
          />
        )}

        {isDeleteWarningModalOpen && selectedTemplateId && (
          <DeleteWarningModal
            templateName={getTemplateTitle(templates, selectedTemplateId)}
            onClose={handleCloseDeleteWarningModal}
            onClickDelete={handleDeleteTemplate}
          />
        )}
      </div>
    </>
  )
}

export { TemplatesPage }

function getTemplateTitle(templates: { title: string; id: string }[], selectedTemplateId: string) {
  return templates.find(item => item.id === selectedTemplateId)?.title || ''
}

const ActionButtons: React.FC<{
  product: Product
  mode: PageMode
  roleId: string | null
}> = ({ mode, roleId, product }) => {
  const history = useHistory()

  const handleClickNewTemplate = () => {
    const queryParam = new URLSearchParams()
    queryParam.set('mode', 'new')
    if (roleId) queryParam.set('roleid', roleId)

    history.push(`/templates/${product}?${queryParam.toString()}`)
  }

  const handleClickNewFromSelection = () => {
    const queryParam = new URLSearchParams()
    queryParam.set('mode', 'new')
    if (roleId) queryParam.set('roleid', roleId)

    history.push(`/templates/${product}?${queryParam.toString()}`)
  }

  if (mode === 'template') {
    return (
      <div
        style={{
          paddingBlock: '1rem',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          style={{
            display: 'flex',
            gap: '1rem',
            alignItems: 'center',
            paddingBlock: '1rem',
          }}
          variant="secondary"
          onClick={handleClickNewTemplate}
        >
          <Icon name="plus" />
          Create new {product === 'self-assessment' ? 'Assessment' : 'Reference Check'} template
        </Button>
      </div>
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        paddingBlock: '1rem',
      }}
    >
      <Button
        style={{
          display: 'flex',
          gap: '1rem',
          alignItems: 'center',
          paddingBlock: '1rem',
        }}
        variant="secondary"
        onClick={handleClickNewFromSelection}
      >
        <Icon name="plus" />
        Select from test library
      </Button>
    </div>
  )
}

const PreviewTemplateModal: React.FC<{
  onClose: () => void
  onClickDelete: () => void
  templateId: string
  roleId: string | null
  orgId: string
  product: Product
  title: string
  showSelectButton: boolean
  disableEdit: boolean
}> = props => {
  const { template } = useGetTemplatePreview(props.templateId)

  const history = useHistory()
  const dispatch = useDispatch()
  const org = useSelector((state: RootState) => selectors.orgs.getById(state, selectors.orgs.currentId(state)))
  const orgSettings = useSelector((state: RootState) => selectors.orgSettings.getByOrgId(state, org.id))
  const showEditingOptions = !orgSettings?.fields.interviewer_mode

  const handleClickEdit = () => {
    const queryParam = new URLSearchParams()
    queryParam.set('mode', 'edit')
    queryParam.set('templateid', props.templateId)
    if (props.roleId) queryParam.set('roleid', props.roleId)

    history.push(`/templates/${props.product}?${queryParam.toString()}`)
  }

  const handleClickClone = () => {
    const queryParam = new URLSearchParams()
    queryParam.set('mode', 'clone')
    queryParam.set('templateid', props.templateId)
    if (props.roleId) queryParam.set('roleid', props.roleId)

    history.push(`/templates/${props.product}?${queryParam.toString()}`)
  }

  async function handleAssignTemplateToRole() {
    if (!props.roleId) {
      throw new Error('Role ID or Template ID is not defined')
    }

    const template = (await dispatch(
      templatesSlice.getTemplate(props.orgId, props.templateId),
    )) as unknown as Entity<templatesApi.Fields> | null // hack to get the type...

    const content = template?.fields.content

    if (!content) {
      throw new Error('Template content is not defined')
    }

    const newTemplateId = await dispatch(
      templatesSlice.createTemplate(props.orgId, {
        is_private: true,
        is_highlighted: true,
        copy: { title: '' },
        content: content,
        product: props.product,
      }),
    )

    // this is necessary because of the typing issues with the react-thunk
    if (!newTemplateId || typeof newTemplateId !== 'string') {
      throw new Error('Template ID is not defined')
    }

    if (props.product === 'self-assessment') {
      await dispatch(addSelfAssessmentPresetToRole(props.roleId, newTemplateId))
    } else if (props.product === 'reference-check') {
      await dispatch(addReferenceCheckQuestionnaireToRole(props.roleId, newTemplateId))
    }

    history.push(`/roles/${props.roleId}`)
  }

  return (
    <Modal
      open
      onClose={props.onClose}
      style={{ width: '100%', maxWidth: '800px' }}
      header={
        <div
          style={{
            display: 'flex',
            padding: '1rem',
            paddingBlockEnd: '0',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <Text variant="card-header">{props.title}</Text>
          </div>
          <div style={{ display: 'flex', gap: '0.5rem' }}>
            {showEditingOptions && (
              <>
                <Tooltip
                  text={props.disableEdit ? 'You cannot edit this template' : 'Edit template'}
                  direction="bottom"
                  style={{
                    padding: 0,
                  }}
                >
                  <Button
                    variant="icon"
                    onClick={handleClickEdit}
                    size="sm"
                    name="edit"
                    disabled={props.disableEdit}
                    style={{
                      padding: 0,
                    }}
                    data-testid="edit-template-button"
                  >
                    <Icon name="edit" ariaLabel="Edit template" />
                  </Button>
                </Tooltip>
                <Tooltip
                  text="Clone template"
                  direction="bottom"
                  style={{
                    padding: 0,
                  }}
                >
                  <Button variant="icon" onClick={handleClickClone} size="sm" data-testid="clone-template-button">
                    <Icon name="clone" />
                  </Button>
                </Tooltip>
                <Tooltip
                  text={props.disableEdit ? 'You cannot delete this template' : 'Delete template'}
                  direction="bottom"
                  data-testid="delete-template-button"
                  style={{
                    padding: 0,
                    zIndex: zIndex.tooltip,
                  }}
                >
                  <Button variant="icon" onClick={props.onClickDelete} size="sm" disabled={props.disableEdit}>
                    <Icon name="trash" />
                  </Button>
                </Tooltip>
              </>
            )}
            <Button
              variant="ghost"
              onClick={props.onClose}
              size="sm"
              data-testid="close-preview-modal"
              style={{
                padding: 0,
                aspectRatio: '1/1',
              }}
            >
              <FaXmark size={20} />
            </Button>
          </div>
        </div>
      }
    >
      <div
        style={{
          overflow: 'auto',
          maxHeight: '80dvh',
        }}
      >
        <PreviewTable questions={template} />
      </div>
      {props.showSelectButton && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingBlockStart: '1rem',
          }}
        >
          <Button
            variant="accent"
            onClick={handleAssignTemplateToRole}
            data-testid="select-template-button"
            style={{
              width: '100%',
            }}
          >
            Select
          </Button>
        </div>
      )}
    </Modal>
  )
}

const DeleteWarningModal: React.FC<{
  templateName: string
  onClose: () => void
  onClickDelete: () => void
}> = props => (
  <Modal open onClose={props.onClose} title="Delete template">
    <div
      style={{
        maxWidth: '500px',
      }}
    >
      <p>
        Deleting this <strong>{props.templateName}</strong> template will <strong>not</strong> affect the jobs currently
        using it, but will remove the it from the template library. Are you sure you want to proceed?
      </p>

      <div
        style={{
          display: 'flex',
          gap: '1rem',
        }}
      >
        <Button
          variant="secondary"
          onClick={props.onClose}
          style={{
            flex: 1,
          }}
        >
          Cancel
        </Button>
        <Button
          variant="danger"
          onClick={props.onClickDelete}
          style={{
            flex: 1,
          }}
        >
          Delete
        </Button>
      </div>
    </div>
  </Modal>
)

type PageMode = 'single' | 'template'

const queryParamSchema = z.object({
  mode: z.enum(['single', 'template']),
  roleid: z.string(),
  product: z.enum(['reference-check', 'self-assessment']),
})
