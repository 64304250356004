import { initialize, LDClient } from 'launchdarkly-js-client-sdk'
import { useOtp } from 'providers/otp'
import { useUser } from 'providers/users'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as selectors from '../../selectors'
import { RootState } from '../../store'

let client: LDClient | undefined
let clientStatus: 'initializing' | 'initialized' | 'uninitialized' = 'uninitialized'

type FeatureFlagKey =
  | 'edit-reference-flow-for-specific-candidate'
  | 'percentiles-overall-performance'
  | 'percentiles-skill-items'
  | 'talent-pool'
  | 'sales-pool'
  | 'candidate-comments'
  | 'intercom'
  | 'shareable-candidate'
  | 'tab-selector'
  | 'section-block-navigator'
  | 'merge-dev-integration'
  | 'work-verification'
  | 'fraud-detection-warning'
  | 'duration-estimation'
  | 'self-reference'
  | 'post-hire-onboarding'
  | 'maintenance-mode'
  | 'rds-content'
  | 'onboarding'
  | 'candidate-experience'
  | 'role-creation-2.0'
  | 'self-assessment'
  | 'greenhouse-2.0'
  | 'personio-import'
  | 'background-checks'
  | 'bfas'
  | 'cognitive-abilities'
  | 'bessi'
  | 'invite-by-link'
  | 'mindset'
  | 'mini-ipip'
  | 'hexaco'
  | 'product-pages'
  | 'culture-add'
  | 'candidate-centered'
  | 'video-question'
  | 'knowledge-tests'
  | 'response-rate-card'
  | 'assessment-selection'
  | 'cx-paid-filters'
  | 'cx-invite-via-link'
  | 'quality-of-hire'
  | 'assessment-summaries'
  | 'new-assessment-content-selection'
  | 'library-item-external-preview'
  | 'genie'
  | 'new-homepage'

const isProduction = import.meta.env.PROD

const DEFAULT_VALUE_OVERRIDE_TABLE: Partial<Record<FeatureFlagKey, boolean>> = {
  'maintenance-mode': false,
}

function getDefaultValue(feature: FeatureFlagKey, isProduction: boolean) {
  if (isProduction) {
    // For production, the default value will be always false
    return false
  }
  // For development the default value is true, unless it is overridden for a feature key
  // by the lookup table defined above
  return DEFAULT_VALUE_OVERRIDE_TABLE[feature] ?? true
}

export function useFeatureFlag(feature: FeatureFlagKey): {
  isLoading: boolean
  isEnabled?: boolean
} {
  const [, triggerUpdate] = useState(false)

  const user = useUser()
  const userId = user.id
  const userEmail = user.fields.email

  const { otp } = useOtp()
  const otpId = otp?.id
  const otpEmail = otp?.fields.email

  // Use OTP data as fallback in case one of the shared view is on display
  const clientKey = userId || otpId
  const clientEmail = userEmail || otpEmail

  const org = useSelector((state: RootState) => selectors.orgs.getById(state, selectors.orgs.currentId(state)))

  useEffect(() => {
    if (!clientKey || !clientEmail) {
      return
    }

    switch (clientStatus) {
      case 'initialized': {
        break
      }
      case 'initializing': {
        client?.on('ready', () => {
          // When the client is ready, trigger a dummy update so that
          // the components that use this hook can get the feature flag value
          triggerUpdate(state => !state)
        })
        break
      }
      case 'uninitialized': {
        clientStatus = 'initializing'
        if (import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID) {
          client = initialize(import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID ?? '', {
            kind: 'user',
            key: clientKey,
            email: clientEmail,
            plan: org.fields.plan,
          })
          client.on('ready', () => {
            clientStatus = 'initialized'
            // When the client is ready, trigger a dummy update so that
            // the components that use this hook can get the feature flag value
            triggerUpdate(state => !state)
          })
        }
        break
      }
    }
  }, [clientKey, clientEmail])

  const defaultValue = getDefaultValue(feature, isProduction)

  return {
    isLoading: clientStatus !== 'initialized' && isProduction,
    isEnabled: client?.variation(feature, defaultValue) ?? defaultValue,
  }
}
