import { Fields, RESOURCE } from 'api/questionnaire-submission-scores'
import { QuestionnaireSubmissionEntity } from 'api/questionnaire-submissions'
import { Entity } from 'api/request'
import { RootState } from 'store'
import lookup from './lookup'

function findById(state: RootState, id: string) {
  return lookup<Fields>(state, RESOURCE, id)
}

export function findByQuestionnaireSubmission(
  state: RootState,
  submission: QuestionnaireSubmissionEntity,
): Entity<Fields> | undefined {
  const subScore = submission.relationships?.find(r => r.resource === RESOURCE)

  return subScore ? findById(state, subScore.id) : undefined
}
