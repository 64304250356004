import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React, { MutableRefObject, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { colors, px, rem, scale, size, space, style, weight } from '../../core'
import { Icon } from '../Icons'

interface Props {
  children: React.ReactNode
  open: boolean
  setOpen: (open: boolean) => void
  listRef: MutableRefObject<null | HTMLElement>
  activatorRef?: MutableRefObject<null | HTMLElement>
}

const Popup = style()
  .front()
  .flex({
    justifyContent: 'center',
    direction: 'column',
    alignItems: 'flex-start',
  })
  .size({ width: '100%' })
  .spacing({ inner: [space.xxs, space.none], outer: '0' })
  .border({ around: '1px solid', color: designSystemColors.borderDefault })
  .shadow(0.1)
  .color({ bg: designSystemColors.backgroundNeutralPrimary })
  .round('6px')
  .set('boxShadow', '0 1px 0 0 rgba(0,0,0,.05),0 0 20px 0 rgba(0,0,0,.15)')
  .set('listStyleType', 'none')
  .element('ul')

export const Separator = style()
  .size({ width: size.stretch, height: px(1) })
  .color({ bg: designSystemColors.borderDefault })
  .spacing({ outer: [space.xxs, space.none] })
  .element()

const Component = (props: Props) => {
  const clickAwayHandler = e => {
    const isWithinMenuArea =
      props.listRef.current?.contains(e.target) || props.activatorRef?.current?.contains(e.target)

    if (props.open && !isWithinMenuArea) {
      props.setOpen(false)
    }
  }

  const keyPressHandler = e => {
    switch (e.key) {
      // ESC
      case 'Escape':
        props.setOpen(false)
        props.activatorRef?.current?.focus()
        break

      // RIGHT
      case 'ArrowRight':
        if (!props.open) {
          props.setOpen(true)
        } else return
        break

      // LEFT
      case 'ArrowLeft':
        if (props.open) {
          props.setOpen(false)
          props.activatorRef?.current?.focus()
        } else return
        break
    }
  }

  useEffect(() => {
    // close menu when clicked outside menu area
    if (props.open) {
      document.addEventListener('mousedown', clickAwayHandler)
    } else {
      document.removeEventListener('mousedown', clickAwayHandler)
    }

    // focus on first focusable element when menu opens
    ;(props.listRef.current?.querySelectorAll('a, button')[0] as HTMLElement)?.focus()

    // add keyboard interaction to menu and menu activator button
    props.listRef?.current?.addEventListener('keyup', keyPressHandler, false)
    props.activatorRef?.current?.addEventListener('keyup', keyPressHandler, false)

    return () => {
      props.activatorRef?.current?.removeEventListener('keyup', keyPressHandler, false)
      props.listRef?.current?.removeEventListener('keyup', keyPressHandler, false)
    }
  }, [props.open])

  if (!props.open) return null

  return <Popup ref={props.listRef}>{props.children}</Popup>
}

export default Component
Component.displayName = 'PopUp'

const ButtonGrid = style()
  .grid({ columns: [rem(1), size.auto] })
  .spacing({ columns: space.xs, inner: [space.xxs, space.s] })
  .color({ fg: colors.black })
  .sans({ size: scale.s, nodecoration: true })

const ButtonContainer = style()
  .pointer()
  .size({ width: '100%' })
  .select(':hover', style().color({ bg: designSystemColors.backgroundNeutralPrimaryHover }))
  .select('a', ButtonGrid)
  .select('strong', style().text({ weight: weight.normal }))
  .element()

const ButtonIcon = text.bodyText().color({ fg: designSystemColors.typographySecondary }).element('span')

const ButtonIconStyle = style()
  .sans({ align: 'center' })
  .cond(
    ({ variant }: { variant: ButtonVariant }) => variant === 'secondary',
    style().color({ fg: designSystemColors.typographySecondary }),
  )
  .element()

const NoLink = ButtonGrid.transparent().noborders().size({ width: '100%' }).pointer().element('button')
const ButtonLabelText = text.bodyText().element('p')
const ButtonLabelStyle = style()
  .sans({ align: 'left' })
  .cond(
    ({ variant }: { variant: ButtonVariant }) => variant === 'secondary',
    style().color({ fg: designSystemColors.typographySecondary }),
  )
  .element()

type ButtonVariant = 'default' | 'secondary'

interface ButtonProps {
  children: React.ReactNode
  icon: string
  link?: string
  newTab?: boolean
  variant?: ButtonVariant
  onClick?: (ev: any) => void
}

export function Button(props: ButtonProps) {
  if (props.link) {
    return (
      <LinkButton
        onClick={props.onClick}
        icon={props.icon}
        link={props.link}
        newTab={props.newTab}
        variant={props.variant}
      >
        {props.children}
      </LinkButton>
    )
  }

  return (
    <ButtonContainer onClick={props.onClick}>
      <NoLink>
        <ButtonContent icon={props.icon} variant={props.variant}>
          {props.children}
        </ButtonContent>
      </NoLink>
    </ButtonContainer>
  )
}

function LinkButton(props: {
  icon: string
  children: React.ReactNode
  link: string
  newTab?: boolean
  onClick?: (ev: any) => void
  variant?: ButtonVariant
}) {
  return (
    <ButtonContainer onClick={ev => props.onClick && props.onClick(ev)}>
      {/^(http|mailto)/.test(props.link || '') ? (
        <a href={props.link} target="_blank" rel="noopener noreferrer">
          <ButtonContent {...props} />
        </a>
      ) : (
        <Link target={props.newTab ? '_blank' : '_self'} to={props.link}>
          <ButtonContent {...props} />
        </Link>
      )}
    </ButtonContainer>
  )
}

function ButtonContent(props: { icon: string; children: React.ReactNode; variant?: ButtonVariant }) {
  return (
    <>
      <ButtonIconStyle>
        <ButtonIcon variant={props.variant}>
          <Icon name={props.icon} />
        </ButtonIcon>
      </ButtonIconStyle>
      <ButtonLabelStyle variant={props.variant}>
        <ButtonLabelText>{props.children}</ButtonLabelText>
      </ButtonLabelStyle>
    </>
  )
}
