import action from './action'
import { group } from './track'

enum GroupKeys {
  ACCOUNT = 'account',
}

interface AccountGroup {
  $name: string
}

export const addAccountGroup = action((account: string) => {
  group<AccountGroup>(GroupKeys.ACCOUNT, account, { $name: account })
})

export default GroupKeys
