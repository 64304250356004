import * as api from '../api'
import { RootState } from '../store'
import lookup from './lookup'
import * as orgs from './orgs'

function getById(state: RootState, id: string): api.request.Entity<api.integrations.Fields> | undefined {
  return lookup(state, api.integrations.RESOURCE, id)
}

export function getByOrgId(state: RootState, orgId: string): api.request.Entity<api.integrations.Fields> | undefined {
  const org = orgs.getById(state, orgId)
  if (!org || !org.relationships) return

  const rel = org.relationships.find(r => r.resource === api.integrations.RESOURCE)
  if (!rel) return

  return getById(state, rel.id)
}
