import { DefaultButton } from 'components/Button/ButtonV1'
import DropdownField, { Option } from 'components/DropdownField'
import TextField from 'components/TextField'
import { breakpoints, fr, px2rem, size, space, style, vh } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import { useCandidateProfile } from 'providers/candidates'
import { useWorkVerification } from 'providers/work-verifications'
import React, { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getMonthNames } from 'utils/format'
import { AlternativeFooter, Footer } from '../Footer'
import { Header } from '../Header'

interface HRWorkVerificationSubmitSuccessProps {
  candidateName: string
}

const HRWorkVerificationSubmitSuccessStyle = style()
  .size({ minHeight: vh(100) })
  .flex({ direction: 'column' })
  .element()
const HRWorkVerificationSubmitSuccessTitle = text
  .cardHeader()
  .screen(breakpoints.mobile, style().spacing({ outerTop: px2rem(0), outerBottom: px2rem(24) }))
  .spacing({ outerTop: px2rem(64), outerBottom: px2rem(32) })
  .sans({ align: 'center' })
  .element('h1')
const HRWorkVerificationSubmitSuccessText = text.bodyText().element('p')

const HRWorkVerificationSubmitSuccess: React.FC<HRWorkVerificationSubmitSuccessProps> = ({ candidateName }) => {
  return (
    <HRWorkVerificationSubmitSuccessStyle>
      <Header />
      <Content>
        <HRWorkVerificationSubmitSuccessTitle>{`Thank you!`}</HRWorkVerificationSubmitSuccessTitle>
        <HRWorkVerificationSubmitSuccessText>
          {`Thanks for providing information about ${candidateName}.`}
        </HRWorkVerificationSubmitSuccessText>
      </Content>
      <AlternativeFooter />
    </HRWorkVerificationSubmitSuccessStyle>
  )
}

interface HRWorkVerificationFormProps {}

const Title = text
  .cardHeader()
  .screen(breakpoints.mobile, style().spacing({ outerTop: px2rem(0), outerBottom: px2rem(24) }))
  .spacing({ outerTop: px2rem(64), outerBottom: px2rem(32) })
  .sans({ align: 'center' })
  .element('h1')
const Description = text.bodyText().element('p')
const Form = style()
  .screen(breakpoints.mobile, style().spacing({ outerTop: px2rem(32) }))
  .spacing({ outerTop: px2rem(48) })
  .element()
const FormField = style()
  .screen(breakpoints.mobile, style().spacing({ outerBottom: px2rem(24) }))
  .spacing({ outer: [px2rem(32), size.none] })
  .element()
const RadioInputDescription = text
  .bodyEmphasis()
  .block()
  .spacing({ outerBottom: px2rem(10) })
  .element('p')
const RadioGroup = style()
  .spacing({ outerBottom: px2rem(20) })
  .element('form')

const RadioInput = style()
  .set('WebkitAppearance', 'none')
  .set('MozAppearance', 'none')
  .set('appearance', 'none')
  .size({ width: px2rem(16), height: px2rem(16) })
  .bg({ color: designSystemColors.backgroundNeutralSecondary })
  .nospacing()
  .border({
    around: '1px solid',
    color: designSystemColors.typographySecondary,
    radius: '50%',
  })
  .select(
    ':checked',
    style().border({
      around: '6px solid',
      color: designSystemColors.uiStatusSuccess,
      radius: '50%',
    }),
  )
  .pointer()
  .element('input')
const RadioInputContainer = style()
  .flex({ alignItems: 'center' })
  .spacing({ outerBottom: px2rem(8) })
  .element()
const RadioInputLabel = text
  .bodyInteractive()
  .spacing({ outerLeft: px2rem(8) })
  .element('label')

const DateLabel = text
  .bodyEmphasis()
  .spacing({ outerBottom: px2rem(8) })
  .element('p')
const DateSection = style().element()
const TwoColumn = style()
  .grid({ columns: [fr(1), fr(1)] })
  .spacing({ gap: px2rem(6) })
  .element()
const TwoColumnBiggerGap = style(TwoColumn)
  .spacing({ gap: px2rem(24) })
  .screen(breakpoints.mobile, style().grid({ columns: [fr(1)] }))
  .element()
const Content = style()
  .flex({
    grow: '1',
    shrink: '1',
    direction: 'column',
  })
  .set('alignSelf', 'center')
  .size({ maxWidth: px2rem(576) })
  .spacing({ inner: px2rem(20) })
  .element()
const SubmitButton = style(DefaultButton)
  .bg({ color: designSystemColors.uiStatusSuccess })
  .fg(designSystemColors.white)
  .spacing({
    innerLeft: px2rem(80),
    innerRight: px2rem(80),
    outer: [px2rem(80), space.auto, space.none],
  })
  .select(':disabled', style().bg({ color: designSystemColors.backgroundNeutralTertiary }))
  .screen(
    breakpoints.mobile,
    style().spacing({
      innerLeft: px2rem(48),
      innerRight: px2rem(48),
      outer: [px2rem(24), space.auto, space.none],
    }),
  )
  .element('button')
const HRWorkVerificationFormStyle = style()
  .size({ minHeight: vh(100) })
  .flex({ direction: 'column' })
  .element()

type FormFields = {
  role?: string
  fromYear?: number
  fromMonth?: number
  toYear?: number
  toMonth?: number
  verified: boolean
}

function createRange(from: number, to: number, isInclusive: boolean = true) {
  const offset = isInclusive ? 1 : 0
  return Array(to - from + offset)
    .fill(0)
    .map((_, i) => i + from)
}

function areAllFormFieldsValid(formFields: FormFields) {
  if (formFields.verified === false) {
    return true
  }
  if (formFields.fromMonth === undefined) {
    return false
  }
  if (formFields.fromYear === undefined) {
    return false
  }
  if (formFields.toMonth === undefined) {
    return false
  }
  if (formFields.toYear === undefined) {
    return false
  }
  if ((formFields.role || '').trim() === '') {
    return false
  }
  return true
}

const HRWorkVerificationForm: React.FC<HRWorkVerificationFormProps> = () => {
  const { candidateid, workverificationid } = useParams<{
    candidateid?: string
    workverificationid?: string
  }>()

  const { confirmWorkVerification, isLoading, workVerification } = useWorkVerification({
    workVerificationId: workverificationid,
  })
  const candidateProfile = useCandidateProfile({ candidateId: candidateid })

  const [formValues, setFormValues] = useState<FormFields>({ verified: true })
  const setFormValue = useCallback(
    (key: keyof FormFields, value: any) => setFormValues(formValues => ({ ...formValues, [key]: value })),
    [],
  )

  const months = useMemo<[string, number][]>(
    () => getMonthNames('long').map((monthLiteral, index) => [monthLiteral, index + 1]),
    [],
  )
  const years = useMemo(() => createRange(1950, new Date().getFullYear()).reverse(), [])

  const [isSubmitted, setIsSubmitted] = React.useState(false)
  const onSubmitCallback = async () => {
    if (!candidateid || !workverificationid) {
      return
    }
    const isSubmitted = await confirmWorkVerification(candidateid, workverificationid, {
      from_month: formValues.fromMonth,
      from_year: formValues.fromYear,
      role_name: formValues.role,
      to_month: formValues.toMonth,
      to_year: formValues.toYear,
      verified: formValues.verified,
    })

    if (isSubmitted) {
      setIsSubmitted(true)
    }
  }

  if (isLoading) {
    return null
  }

  if (isSubmitted) {
    return <HRWorkVerificationSubmitSuccess candidateName={candidateProfile?.fields.full_name || ''} />
  }

  const allFormFieldsValid = areAllFormFieldsValid(formValues)
  const isSubmitButtonDisabled = !allFormFieldsValid

  return (
    <HRWorkVerificationFormStyle>
      <Header />
      <Content>
        <Title>{'Add HR contacts for work verification'}</Title>
        <Description>{'Good morning!'}</Description>
        <Description>
          {`Thanks for taking the time to verifying ${candidateProfile?.fields.full_name}’s work experience at ${workVerification?.fields.company_name}. Please provide us with the following information:`}
        </Description>
        <Form>
          <RadioGroup onChange={ev => setFormValues({ verified: (ev.target as any).value === 'true' })}>
            <RadioInputDescription>{`I can confirm that ${candidateProfile?.fields.full_name}`}</RadioInputDescription>
            <RadioInputContainer>
              <RadioInput type="radio" id="verified_no" name="verified" value="false" checked={!formValues.verified} />
              <RadioInputLabel htmlFor="verified_no">
                {`has not worked for ${workVerification?.fields.company_name}`}
              </RadioInputLabel>
            </RadioInputContainer>
            <RadioInputContainer>
              <RadioInput type="radio" id="verified_yes" name="verified" value="true" checked={formValues.verified} />
              <RadioInputLabel htmlFor="verified_yes">
                {`is currently or has worked at ${workVerification?.fields.company_name}`}
              </RadioInputLabel>
            </RadioInputContainer>
          </RadioGroup>
          <TwoColumnBiggerGap>
            <DateSection>
              <DateLabel>{'From'}</DateLabel>
              <TwoColumn>
                <DropdownField
                  placeholder={'Month'}
                  value={formValues.fromMonth}
                  onChange={val => setFormValue('fromMonth', val)}
                  disabled={!formValues.verified}
                >
                  {months.map(([monthLiteral, monthNumber]) => (
                    <Option key={monthNumber} value={monthNumber}>
                      {monthLiteral}
                    </Option>
                  ))}
                </DropdownField>
                <DropdownField
                  placeholder={'Year'}
                  value={formValues.fromYear}
                  onChange={val => setFormValue('fromYear', val)}
                  disabled={!formValues.verified}
                >
                  {years.map(year => (
                    <Option key={year} value={year}>
                      {year}
                    </Option>
                  ))}
                </DropdownField>
              </TwoColumn>
            </DateSection>
            <DateSection>
              <DateLabel>{'To'}</DateLabel>
              <TwoColumn>
                <DropdownField
                  placeholder={'Month'}
                  value={formValues.toMonth}
                  onChange={val => setFormValue('toMonth', val)}
                  disabled={!formValues.verified}
                >
                  {months.map(([monthLiteral, monthNumber]) => (
                    <Option key={monthNumber} value={monthNumber}>
                      {monthLiteral}
                    </Option>
                  ))}
                </DropdownField>
                <DropdownField
                  placeholder={'Year'}
                  value={formValues.toYear}
                  onChange={val => setFormValue('toYear', val)}
                  disabled={!formValues.verified}
                >
                  {years.map(year => (
                    <Option key={year} value={year}>
                      {year}
                    </Option>
                  ))}
                </DropdownField>
              </TwoColumn>
            </DateSection>
          </TwoColumnBiggerGap>
          <FormField>
            <TextField
              label="Job"
              subLabel="If the employee had more than one job, please specify the last one"
              value={formValues.role || ''}
              placeholder={'E.g. Engineering Manager'}
              onChange={ev => setFormValue('role', ev.target.value)}
              disabled={!formValues.verified}
            />
          </FormField>
        </Form>
        <SubmitButton inline onClick={onSubmitCallback} disabled={isSubmitButtonDisabled}>
          {'Submit answer'}
        </SubmitButton>
      </Content>
      <Footer />
    </HRWorkVerificationFormStyle>
  )
}

export default HRWorkVerificationForm
