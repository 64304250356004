import validEmail from 'all-good-emails'
import { getHostname, normalizeUrl } from 'core/url'
import * as assessmentVersions from './assessment-versions'
import * as backgroundcheckintegrations from './backgroundcheckintegrations'
import * as candidateExperience from './candidate-experience'
import * as candidateProfiles from './candidate-profiles'
import * as candidates from './candidates'
import * as charts from './charts'
import * as comments from './comments'
import * as domains from './domains'
import * as employees from './employees'
import * as formResponses from './form-responses'
import * as greenhouseProfiles from './greenhouse-profiles'
import * as groupScores from './group-scores'
import * as groups from './groups'
import * as haloScore from './halo-score'
import * as integrationConnections from './integration-connections'
import * as integrations from './integrations'
import * as inventories from './inventories'
import * as libraryItems from './library-items'
import * as managers from './managers'
import * as moduleScores from './module-scores'
import * as modules from './modules'
import * as notificationLogs from './notification-logs'
import * as onboardingSummaries from './onboarding-summaries'
import * as oneTimePassword from './one-time-password'
import * as openJobRoleUsers from './open-job-role-users'
import * as orgSettings from './org-settings'
import * as orgs from './orgs'
import * as percentiles from './percentiles'
import * as presets from './presets'
import * as profiles from './profiles'
import * as questionnaireAnswers from './questionnaire-answers'
import * as questionnaireSubmissionScores from './questionnaire-submission-scores'
import * as questionnaireSubmissions from './questionnaire-submissions'
import * as questionnaires from './questionnaires'
import * as questions from './questions'
import * as referenceCheckStages from './reference-check-stages'
import * as referenceCheckSummaries from './reference-check-summaries'
import * as references from './references'
import * as reports from './reports'
import * as requiredReferences from './required-references'
import * as responseOptions from './response-options'
import * as roles from './roles'
import * as samlsso from './saml-sso'
import * as skills from './skills'
import * as teams from './teams'
import * as userPermissions from './user-permissions'
import * as users from './users'
import * as variables from './variables'
import * as webhooks from './webhooks'

export { assessmentVersions, candidateExperience, candidateProfiles, candidates, employees }

export {
  backgroundcheckintegrations,
  charts,
  comments,
  domains,
  formResponses,
  greenhouseProfiles,
  groups,
  haloScore,
  integrationConnections,
  integrations,
  inventories,
  modules,
  onboardingSummaries,
  oneTimePassword,
  openJobRoleUsers,
  orgs,
  orgSettings,
  percentiles,
  presets,
  profiles,
  questionnaireAnswers,
}

export {
  questionnaires,
  questionnaireSubmissions,
  questions,
  referenceCheckStages,
  references,
  reports,
  requiredReferences,
  responseOptions,
  roles,
  samlsso,
}

export {
  groupScores,
  libraryItems,
  managers,
  moduleScores,
  notificationLogs,
  questionnaireSubmissionScores,
  referenceCheckSummaries,
  skills,
  teams,
  userPermissions,
  users,
  variables,
  webhooks,
}

export function firstName(name: string): string {
  return name.trim().split(' ')[0]
}

export function lastName(name: string): string {
  const nameList = name.trim().split(' ')
  return nameList[nameList.length - 1]
}

export function possessiveFirstName(name: string): string {
  const first = firstName(name)
  return first.endsWith('s') ? `${first}'` : `${first}'s`
}

export interface ProfessionalNetwork {
  name: string
  icon?: string
  brandIcon?: string
}

const PROFESSIONAL_NETWORK_DEFAULT: ProfessionalNetwork = {
  name: 'Professional Network',
  icon: 'external-link',
}

const PROFESSIONAL_NETWORKS: {
  [domain: string]: ProfessionalNetwork
} = {
  'angel.co': { brandIcon: 'angellist', name: 'AngelList' },
  'github.com': { brandIcon: 'github', name: 'Github' },
  'linkedin.com': { brandIcon: 'linkedin', name: 'LinkedIn' },
  'xing.com': { brandIcon: 'xing', name: 'XING' },
}

export function parseProfessionalNetwork(professionalNetworkUrl: string): ProfessionalNetwork & { url: string } {
  const url = normalizeUrl(professionalNetworkUrl)
  const hostname = getHostname(url)

  // Try only keep 2nd+ level domains. eg fr.linkedin.com -> linkedin.com
  const host2LD = hostname.replace(/.*?([^.]+\.[^\.]+)$/, '$1')

  const network = PROFESSIONAL_NETWORKS[host2LD] || PROFESSIONAL_NETWORK_DEFAULT
  return { ...network, url }
}

export function milliseconds(nanosecs: number): number {
  return Math.floor(nanosecs / 1.0e6)
}

export { millisecondsToHuman } from '@common/utils'

function ordinals(d: number) {
  if (d > 3 && d < 21) return 'th'
  switch (d % 10) {
    case 1:
      return 'st'
    case 2:
      return 'nd'
    case 3:
      return 'rd'
    default:
      return 'th'
  }
}

export function dateString(date: Date) {
  const n = date.getDate()
  const ordinal = ordinals(date.getDate())
  const month = date.toLocaleString('en-US', { month: 'long' })
  return `${n}${ordinal} ${month}`
}

// Currently only used for pluralizing "day". Switch to a library more than
// the current simple need is desired.
export function plural(word: string, quantity: number): string {
  if (quantity === 1) {
    return word
  }

  return word + 's'
}

export function match(rawText: string, rawFilter: string): boolean {
  // normalize
  const combining = /[\u0300-\u036F]/g
  const text = rawText.toLowerCase().trim().normalize('NFKD').replace(combining, '')
  const filter = rawFilter.toLowerCase().trim()

  if (filter.length === 0) {
    return true
  }

  if (filter.length <= 3) {
    return text.startsWith(filter)
  }

  return text.includes(filter)
}

export function isEmailValid(email: string): boolean {
  return validEmail(email)
}

export function scale(n: number, omin: number, omax: number, nmin: number, nmax: number): number {
  return ((nmax - nmin) * (n - omin)) / (omax - omin) + nmin
}

export function roundScale(n: number, omin: number, omax: number, nmin: number, nmax: number): number {
  return Math.round(scale(n, omin, omax, nmin, nmax))
}
