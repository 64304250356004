import { LockedByPlan, LockIcon } from '@common/components'
import Block, { Header } from 'components/Block'
import Form from 'components/Form'
import ToggleField from 'components/ToggleField'
import { style } from 'core'
import { lockedContentTracking } from 'core/track/locked-content'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as selectors from 'selectors'
import { RootState } from 'store'
import { updateGenieSettings } from 'store/organizations'

const TitleHeader = style().inlineFlex({ alignItems: 'center' }).element('span')

const AiSettings = () => {
  const dispatch = useDispatch()

  const orgId = useSelector((state: RootState) => selectors.orgs.currentId(state))

  const isOrgPaidPlan = useSelector(selectors.orgs.isOrgPaidPlan)

  const orgSettings = useSelector((state: RootState) => selectors.orgSettings.getByOrgId(state, orgId))
  if (!orgSettings) return <></>

  return (
    <LockedByPlan
      tracking={lockedContentTracking}
      locked={!isOrgPaidPlan}
      lockIconDisabled={true}
      upgradeTo={'Scale'}
      modalData={{
        learnMoreLink: 'https://intercom.help/hipeople/en/articles/8915421-advanced-organization-settings',
        learnMoreAbout: 'Advanced Organization Settings',
      }}
    >
      <Block
        testId="ai-suggestions"
        title={
          <TitleHeader>
            AI Suggestions
            <LockIcon visible={!isOrgPaidPlan} />
          </TitleHeader>
        }
      >
        <Header>
          Our AI tools assist you in the hiring process by providing suggestions to enhance the speed and quality of
          your reference checks and assessments.
        </Header>
        <Form>
          <ToggleField
            on={orgSettings.fields.hire_genie_enabled_reference_check}
            onChange={value => {
              dispatch(updateGenieSettings(orgId, value, orgSettings.fields.hire_genie_enabled_assessment))
            }}
            label="AI Suggestions for Reference Check"
          />
          <ToggleField
            on={orgSettings.fields.hire_genie_enabled_assessment}
            onChange={value => {
              dispatch(updateGenieSettings(orgId, orgSettings.fields.hire_genie_enabled_reference_check, value))
            }}
            label="AI Suggestions for Assessment"
          />
        </Form>
      </Block>
    </LockedByPlan>
  )
}

export default AiSettings
