import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import Markdown from 'markdown-to-jsx'
import React from 'react'
import { useSelector } from 'react-redux'
import { Item } from '../../../api/assessment-versions'
import { Icon } from '../../../components/Icons'
import { px2rem, style } from '../../../core'
import * as selectors from '../../../selectors'
import { RootState } from '../../../store'
import EmptyAnswer from '../EmptyAnswer'

interface Props {
  referenceId: string
  followup: Item
}

const Container = style()
  .spacing({ inner: px2rem(16) })
  .color({ bg: designSystemColors.backgroundNeutralSecondary })
  .border({ radius: px2rem(4) })
  .element()

const Title = text
  .bodyEmphasis()
  .spacing({ outerBottom: px2rem(12) })
  .element('h3')

const FollowupDescription = text
  .smallBody()
  .select(
    'a',
    style()
      .text({ color: designSystemColors.typographyPrimary, nodecoration: true })
      .border({ bottom: '1px solid', color: designSystemColors.borderDefault }),
  )
  .element('p')

const FollowupAnswer = text.bodyText().element('p')

const Header = style()
  .flex({ alignItems: 'center' })
  .spacing({ outerBottom: px2rem(12) })
  .select('> *:first-child', style().spacing({ outer: [px2rem(0), px2rem(12), px2rem(0), px2rem(6)] }))
  .element()

const Followup = (props: Props) => {
  const content = useSelector((state: RootState) =>
    selectors.formResponses.findAnswerByReferenceId(state, props.referenceId, props.followup.content_id),
  )

  const [description, title] = useSelector((state: RootState) => [
    selectors.variables.renderQuestionForReference(state, props.followup.dashboard_view.description, props.referenceId),
    selectors.variables.renderQuestionForReference(state, props.followup.dashboard_view.title, props.referenceId),
  ])

  if (!content) return <></>

  return (
    <Container>
      {description && (
        <Header>
          <Icon name={'code-branch'} />
          <FollowupDescription>
            <Markdown>{description}</Markdown>
          </FollowupDescription>
        </Header>
      )}
      <Title>{title}</Title>
      {content.input ? <FollowupAnswer>{content.input}</FollowupAnswer> : <EmptyAnswer skipped />}
    </Container>
  )
}

export default Followup
