import { FilterMatchMode, FilterOperator } from 'primereact/api'
import { DataTableFilterMeta } from 'primereact/datatable'

export const defaultFilters: DataTableFilterMeta = {
  'candidate_profiles.full_name': {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  completed_at: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
  },
  'questionnaire_submissions.submitted_at': {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
  },
  reference_check_status: {
    value: null,
    matchMode: FilterMatchMode.EQUALS,
  },
  assessment_completion_status: {
    value: null,
    matchMode: FilterMatchMode.EQUALS,
  },
  hiring_status: {
    value: null,
    matchMode: FilterMatchMode.EQUALS,
  },
  evaluation_status: {
    value: null,
    matchMode: FilterMatchMode.EQUALS,
  },
  'questionnaire_submission_scores.score': {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO }],
  },
}

export const ScoreMatchModeOptions = [
  {
    label: 'Greater Than or Equal To',
    value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
  },
  {
    label: 'Greater than',
    value: FilterMatchMode.GREATER_THAN,
  },
  {
    label: 'Less Than or Equal To',
    value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
  },
  {
    label: 'Less than',
    value: FilterMatchMode.LESS_THAN,
  },
  {
    label: 'Equals',
    value: 'round_eq',
  },
]
