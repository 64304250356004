import * as api from '../api'
import { RootState } from '../store'
import lookup from './lookup'
import * as roles from './roles'

function getById(state: RootState, id: string): api.request.Entity<api.integrationConnections.Fields> | undefined {
  return lookup(state, api.integrationConnections.RESOURCE, id)
}

export function getByRoleId(
  state: RootState,
  roleId: string,
): api.request.Entity<api.integrationConnections.Fields>[] | undefined {
  const role = roles.findById(state, roleId)
  if (!role || !role.relationships) return

  const relationships = role.relationships.filter(r => r.resource === api.integrationConnections.RESOURCE)

  return relationships
    .map(relationship => getById(state, relationship.id))
    .filter(relationship => relationship !== undefined) as api.request.Entity<api.integrationConnections.Fields>[]
}
