import React from 'react'
import { colors, rem, scale, size, space, style, weight } from '../../core'

const Container = style().relative().element()

const Bar = style()
  .size({ width: '100%', height: rem(1) })
  .color({ bg: colors.lightMoonGray })
  .cond(({ fixedColor }: Props) => !!fixedColor, style().color({ bg: colors.barChartBgColor }))
  .relative()
  .element()

const Fill = style()
  .absolute({ top: 0, left: 0 })
  .color({ bg: colors.green })
  .size({ height: size.fill })
  .with(({ score }: Props) => style().size({ width: `${score}%` }))
  .cond(({ score }: Props) => score < 70, style().color({ bg: colors.yellow }))
  .cond(({ score }: Props) => score < 40, style().color({ bg: colors.red }))
  .cond(({ fixedColor }: Props) => !!fixedColor, style().color({ bg: colors.barChartFillColor }))
  .element()

const Header = style()
  .sans({ size: scale.s, color: colors.midGray })
  .grid({ columns: [size.auto, rem(8)] })
  .spacing({ outerBottom: space.xs })
  .element()

const Label = style().sans({ weight: weight.normal }).element()

const Value = style().sans({ weight: weight.normal, align: 'right' }).element()

interface Props {
  score: number
  label: string
  prefix?: string
  suffix?: string
  max?: number
  fixedColor?: boolean
}

const Component = ({ score, max, label, prefix, suffix, fixedColor }: Props) => {
  let pct = score

  if (max) {
    pct = Math.floor((score * 100) / max)
  }

  return (
    <Container>
      <Header>
        <Label>{label}</Label>
        <Value>
          {prefix || ''}
          {score}
          {suffix || ''}
        </Value>
      </Header>
      <Bar score={pct} fixedColor={fixedColor}>
        <Fill score={pct} fixedColor={fixedColor} />
      </Bar>
    </Container>
  )
}

export default Component
Component.displayName = 'ScoreBar'
