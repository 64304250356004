import { RootState } from 'store'
import * as selectors from '../../selectors'
import action from './action'
import events from './events'
import track, { incr, log } from './track'

interface NewRoleProps {
  name: string
  references: number
}

export const startRoleCreation = action(() => track(events.ROLE_CREATION_STARTED, {}))

export const testRoleCreation = action((page: string) =>
  track(events.ROLE_CREATION_TEST_ROLE_CREATED, {
    page,
  }),
)

export const roleCreationDetails = action(() => track(events.ROLE_CREATION_DETAILS, {}))

export const roleCreationTeam = action(() => track(events.ROLE_CREATION_TEAM, {}))

export const roleCreationTeamMemberAccessRemoved = action(() =>
  track(events.ROLE_CREATION_TEAM_MEMBER_ACCESS_REMOVED, {}),
)

export const roleCreationClickToAddUser = action(() => track(events.ROLE_CREATION_CLICK_TO_ADD_USER, {}))

export const roleCreationAddAssessment = action(() => track(events.ROLE_CREATION_ADD_ASSESSMENT, {}))

export const roleCreationRemoveAssessment = action(() => track(events.ROLE_CREATION_REMOVE_ASSESSMENT, {}))

export const roleCreationAddReferenceCheck = action(() => track(events.ROLE_CREATION_ADD_REFERENCE_CHECK, {}))

export const roleCreationTextUpdated = action(() => track(events.ROLE_CREATION_TEXT_UPDATED, {}))

export const roleCreationEditTextSelected = action(() => track(events.ROLE_CREATION_EDIT_TEXT_SELECTED, {}))

export const roleCreationEditImageRemoved = action(() => track(events.ROLE_CREATION_REMOVE_IMAGE, {}))
export const roleCreationUploadImage = action(() => track(events.ROLE_CREATION_UPLOAD_IMAGE, {}))

export const roleCreationRemoveReferenceCheck = action(() => track(events.ROLE_CREATION_REMOVE_REFERENCE_CHECK, {}))

export const roleCreationDocumentation = action(() => track(events.ROLE_CREATION_DOCUMENTATION, {}))

export const roleCreationExit = action(() => track(events.ROLE_CREATION_EXIT, {}))

export const create = action((props: NewRoleProps) => {
  track(events.ROLE_CREATED, {
    'Role name': props.name,
    'Reference count': props.references,
  })
  log(events.ROLE_CREATED)

  incr(events.ROLE_CREATED_LIFETIME)
})

interface Props {
  name: string
}

export const start = action((props: Props) => {
  track(events.ROLE_START, {
    'Role Name': props.name,
  })
})

export const archive = action((props: Props) => {
  track(events.ROLE_ARCHIVED, {
    'Role Name': props.name,
  })
})

export const pause = action((props: Props) => {
  track(events.ROLE_PAUSED, {
    'Role Name': props.name,
  })
})

export const view = action((props: Props) => {
  track(events.ROLE_VIEWED, {
    'Role Name': props.name,
  })
})

export const rolesButtonClicked = action(() => track(events.SIDEBAR_ROLES_BUTTON_CLICKED, {}))

export const viewAsReference = action((props: Props) => {
  track(events.ROLE_VIEW_AS_REFERENCE, {
    'Role Name': props.name,
  })
})

export const viewAsSelfReference = action((props: Props) => {
  track(events.ROLE_VIEW_AS_SELF_REFERENCE, {
    'Role Name': props.name,
  })
})

export const updateReferences = action((props: Props) => {
  track(events.ROLE_UPDATE_REFERENCES, {
    'Role Name': props.name,
  })
})

export const updateExpiryTime = action((props: Props) => {
  track(events.ROLE_UPDATE_EXPIRY_TIME, {
    'Role Name': props.name,
  })
})

export const updateTitles = action((props: Props) => {
  track(events.ROLE_UPDATE_TITLES, {
    'Role Name': props.name,
  })
})

export const updateRole = action((props: Props) => {
  track(events.ROLE_UPDATE, {
    'Role Name': props.name,
  })
})

export const manageUsers = action((props: Props) => {
  track(events.ROLE_MANAGE_USERS, {
    'Role Name': props.name,
  })
})

export const inviteCandidates = action((props: Props) => {
  track(events.ROLE_INVITE_CANDIDATES, {
    'Role Name': props.name,
  })
})

export const inviteYourself = action((props: Props) => {
  track(events.ROLE_INVITE_YOURSELF, {
    'Role Name': props.name,
  })
})

export const inviteViaLinkSelected = action((props: Props) => {
  track(events.ROLE_INVITE_VIA_LINK_SELECTED, {
    'Role Name': props.name,
  })
})

export const inviteViaLinkCopyToClipboard = action((props: Props) => {
  track(events.ROLE_INVITE_VIA_LINK_CLIPBOARD, {
    'Role Name': props.name,
  })
})

export const inviteViaEmailSelected = action((props: Props) => {
  track(events.ROLE_INVITE_VIA_EMAIL_SELECTED, {
    'Role Name': props.name,
  })
})

export const inviteViaEmailSent = action((props: Props) => {
  track(events.ROLE_INVITE_VIA_EMAIL_SENT, {
    'Role Name': props.name,
  })
})

interface PreviewEditProps {
  module: 'reference-check' | 'self-assessment' | 'onboarding' | 'candidate-experience'
}

export const previewModule = action((props: PreviewEditProps) => {
  track(events.TEMPLATE_PREVIEW, {
    Module: props.module,
  })
})

export const editModule = action((props: PreviewEditProps) => {
  track(events.TEMPLATE_EDIT, {
    Module: props.module,
  })
})

export const addTemplateToModule = action((props: PreviewEditProps) => {
  track(events.TEMPLATE_ADD, {
    Module: props.module,
  })
})

export const createNewTemplate = action(() => {
  track(events.TEMPLATE_CREATE_NEW, {})
})

export const createNewTemplateFromSelection = action(() => {
  track(events.TEMPLATE_CREATE_NEW_FROM_SELECTION, {})
})

export const addTemplateToRole = action((id: string) => {
  track(events.TEMPLATE_ADD_TO_ROLE, { id })
})

export const saveTemplateAsCustom = action(() => {
  track(events.TEMPLATE_USE_CUSTOM, {})
})

export const addTemplateToLibrary = action(() => {
  track(events.TEMPLATE_ADD_TO_LIBRARY, {})
})

export const templateConfirm = action(() => {
  track(events.TEMPLATE_CONFIRM, {})
})

export const clickTemplateSearchbar = action(() => {
  track(events.TEMPLATE_CLICK_SEARCH_BAR, {})
})

export function extractPropsFromState(state: RootState, id: string): Props | null {
  const role = selectors.roles.findById(state, id)
  if (!role) return null

  return {
    name: role.fields.name,
  }
}

export function analyticsEngagementClicked() {
  track(events.ROLE_ANALYTICS_ENGAGEMENT_CLICKED, {})
}

export function analyticsPerformanceClicked() {
  track(events.ROLE_ANALYTICS_PERFORMANCE_CLICKED, {})
}
