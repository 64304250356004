import * as api from 'api'
import Pairwise from 'components/Pairwise'
import { style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import React from 'react'
import { useSelector } from 'react-redux'
import * as selectors from 'selectors'
import { RootState } from 'store'
import { SELF_REFERENCE_QUESTION_LOOKUP } from 'store/new-role/content'
import { EmptyAnswerSection } from './Chips'
import EmptyAnswer from './EmptyAnswer'
import ReferenceAnswer from './ReferenceAnswer'

interface Props {
  candidateId: string
  referenceId?: string
  item: api.assessmentversions.Item
}

const Container = style()
  .select(
    '> *',
    style().border({
      top: 'solid 1px',
      color: designSystemColors.borderDefault,
    }),
  )
  .select('> :first-child', style().noborders())
  .element()

const Component = ({ candidateId, referenceId, item }: Props) => {
  const references = useSelector((state: RootState) =>
    referenceId
      ? [selectors.references.getById(state, referenceId)]
      : selectors.references.findByCandidateId(state, candidateId),
  )

  const groupItems = useSelector((state: RootState) =>
    selectors.assessmentVersions.getItemsByGroupId(state, candidateId, item.dashboard_view.group),
  )

  if (references.length === 0) {
    return (
      <EmptyAnswerSection>
        <EmptyAnswer waitingReferences noSpacing />
      </EmptyAnswerSection>
    )
  }

  return (
    <Container>
      {references.map(reference => (
        <ReferenceAnswer key={reference.id} contentid={item.content_id} reference={reference}>
          <Answers reference={reference} groupItems={groupItems} />
        </ReferenceAnswer>
      ))}
    </Container>
  )
}

interface AnswersProps {
  reference: api.request.Entity<api.references.Fields>
  groupItems: api.assessmentversions.Item[]
}

const Answers = ({ groupItems, reference }: AnswersProps) => {
  if (!reference.fields.form_response_id) {
    return <EmptyAnswer waitingResponse={true} noSpacing />
  }

  // if it's a self reference and no item in the group
  // has an applicable self reference equivalent, display
  // as not applicable for candidate
  const NOT_APPLICABLE =
    reference.fields.is_self && !groupItems.some(item => SELF_REFERENCE_QUESTION_LOOKUP[item.content_id])
  if (NOT_APPLICABLE) {
    return <EmptyAnswer notApplicableForCandidate={true} noSpacing />
  }

  return (
    <>
      {groupItems.map(item => (
        <Answer key={item.content_id} item={item} referenceId={reference.id} />
      ))}
    </>
  )
}

interface AnswerProps {
  item: api.assessmentversions.Item
  referenceId: string
}

const Answer = ({ item, referenceId }: AnswerProps) => {
  const answer = useSelector((state: RootState) =>
    selectors.formResponses.findAnswerByReferenceId(state, referenceId, item.content_id),
  )

  return (
    <Pairwise
      key={item.content_id}
      startCaption={item.dashboard_view.start_statement}
      endCaption={item.dashboard_view.end_statement}
      // check answer exists (could be not in case of a reference rejecting request)
      // then do workaround for answers with value 0 being marshalled as undefined
      score={!answer || answer?.skipped ? null : answer?.value || 0}
    />
  )
}

export default Component
Component.displayName = 'Pairwise'
