import * as api from '../../api'
import { AppThunk } from '../../core/store'
import { add as notify } from '../notifications'
import { addEntities } from '../resources'

export const add =
  (candidateId: string, text: string, mentions: string[]): AppThunk =>
  async dispatch => {
    const [response, errors] = await api.comments.add(candidateId, text, mentions)

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    if (response) {
      dispatch(addEntities(response))
    }
  }

export const remove =
  (candidateId: string, commentId: string): AppThunk =>
  async dispatch => {
    const [response, errors] = await api.comments.remove(candidateId, commentId)

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    if (response) {
      dispatch(addEntities(response))
      dispatch(notify({ success: 'You have deleted your comment successfully!' }))
    }
  }
