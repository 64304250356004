import { DEFAULT_SKIN } from './skin'

export default {
  primaryBg: DEFAULT_SKIN.primaryBg,
  primaryFg: DEFAULT_SKIN.primaryFg,
  secondaryBg: DEFAULT_SKIN.secondaryBg,
  secondaryFg: DEFAULT_SKIN.secondaryFg,
  tertiaryFg: DEFAULT_SKIN.tertiaryFg,
  tertiaryBg: DEFAULT_SKIN.tertiaryBg,
  bg: DEFAULT_SKIN.bg,
  fg: DEFAULT_SKIN.fg,
  sidebarBg: DEFAULT_SKIN.sidebarBg,
  sidebarFg: DEFAULT_SKIN.sidebarFg,
  verified: DEFAULT_SKIN.verified,
  barChartFillColor: DEFAULT_SKIN.barChartFillColor,
  barChartBgColor: DEFAULT_SKIN.barChartBgColor,
  dangerBgColor: DEFAULT_SKIN.dangerBgColor,
  dangerDisabledBgColor: DEFAULT_SKIN.dangerDisabledBgColor,
  errorBgColor: DEFAULT_SKIN.errorBgColor,
  black: DEFAULT_SKIN.black,
  white: DEFAULT_SKIN.white,
  nearWhite: DEFAULT_SKIN.nearWhite,
  lightestGray: DEFAULT_SKIN.lightestGray,
  sand: DEFAULT_SKIN.sand,
  red: DEFAULT_SKIN.red,
  darkRed: DEFAULT_SKIN.darkRed,
  alertRed: DEFAULT_SKIN.alertRed,
  lightGreen: DEFAULT_SKIN.lightGreen,
  green: DEFAULT_SKIN.green,
  darkGreen: DEFAULT_SKIN.darkGreen,
  lightYellow: DEFAULT_SKIN.lightYellow,
  yellow: DEFAULT_SKIN.yellow,
  yolk: DEFAULT_SKIN.yolk,
  pink: DEFAULT_SKIN.pink,
  lavender: DEFAULT_SKIN.lavender,
  orange: DEFAULT_SKIN.orange,
  darkOrange: DEFAULT_SKIN.darkOrange,
  sky: DEFAULT_SKIN.sky,
  darkSky: DEFAULT_SKIN.darkSky,
  brightGreen: DEFAULT_SKIN.brightGreen,
  lightestGreen: DEFAULT_SKIN.lightestGreen,
  lightBlue: DEFAULT_SKIN.lightBlue,
  blue: DEFAULT_SKIN.blue,
  darkBlue: DEFAULT_SKIN.darkBlue,
  lightGray: DEFAULT_SKIN.lightGray,
  midGray: DEFAULT_SKIN.midGray,
  moonGray: DEFAULT_SKIN.moonGray,
  lightMoonGray: DEFAULT_SKIN.lightMoonGray,
  lightSilver: DEFAULT_SKIN.lightSilver,
  silver: DEFAULT_SKIN.silver,
  darkSilver: DEFAULT_SKIN.darkSilver,
  gray: DEFAULT_SKIN.gray,
  darkGray: DEFAULT_SKIN.darkGray,
  transparent: DEFAULT_SKIN.transparent,
  purple: DEFAULT_SKIN.purple,
  purpleDark: DEFAULT_SKIN.purpleDark,
}
