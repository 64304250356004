import { listing } from 'api'
import { SelectedCandidatesCategory } from 'App/CandidatesPage/CandidatesCategorySelector'
import SidebarMenu from 'components/Sidebar'
import TextField from 'components/TextField'
import { fr, pct, px2rem, size, space, style, vh } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import { useManagers } from 'providers/onboarding/managers'
import { useUser } from 'providers/users'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useDebounce } from 'react-use'
import * as selectors from 'selectors'
import { RootState } from 'store'
import { loadByOrg, loadUserCandidates } from 'store/candidate-management'

import { TopBar } from '@common/components'
import { SortOrder } from 'api/listing'
import { CandidatesTable } from 'App/CandidatesPage/CandidatesTable'
import { NoResults, ResultsNotFound } from 'App/CandidatesPage/NoResults'
import { updateHireStatus } from 'store/candidates'
import * as tracking from '../../core/track'
import CandidateCategorySelector from './CandidatesCategorySelector'
import { Candidate, Manager, UpdateHiringFunc } from './CandidatesTable'
import { PageHeader } from '@common/components'

const SidebarContainer = style().element()
const Main = style()
  .grid({ rows: [size.auto, `minmax(0, ${fr(1)})`], columns: [size.fill] })
  .size({ height: vh(100), width: pct(100) })
  .nooverflow()
  .element('article')
const PageContainer = style()
  .grid({ rows: [size.auto, `minmax(0, ${fr(1)})`], columns: [size.fill] })
  .size({ width: size.fill })
  .spacing({
    outer: [px2rem(0), size.auto],
    inner: [px2rem(48), px2rem(80)],
  })
  .element()

const PageContent = style()
  .flex({ direction: 'column' })
  .spacing({ gap: px2rem(24) })
  .element()
const PageSidebar = style()
  .size({ width: px2rem(240), minWidth: px2rem(240) })
  .flex({ direction: 'column' })
  .spacing({ gap: px2rem(36) })
  .element('aside')

const Count = text
  .bodyText()
  .sans({ color: designSystemColors.typographySecondary })
  .spacing({ outer: [space.auto, space.none] })
  .element()

const CandidatesPageUIStyle = style()
  .flex()
  .size({ minHeight: vh(100) })
  .color({ bg: designSystemColors.backgroundNeutralSecondary })
  .element()
interface CandidatesPageUIProps {
  loadCandidates: (query: {
    offset?: number
    limit?: number
    title?: string
    category: SelectedCandidatesCategory
  }) => any
  candidates: Candidate[]
  onSort: (key: string, direction: string) => void
  count: number
  hasMoreCandidates: boolean
  allowedToSeeOrganizationCandidates: boolean
  managers: Manager[]
  updateHiring: UpdateHiringFunc
  noResults: ResultsNotFound | undefined
  children: JSX.Element

  candidateCategory: SelectedCandidatesCategory
  setCandidateCategory: (category: SelectedCandidatesCategory) => void

  textSearchValue: string
  onSearchTextInputChange: (ev: React.ChangeEvent<HTMLInputElement>) => void
}

const CandidateCategorySearchParamKey = 'candidate-category'
const TextSearchParamKey = 'candidate-title'

const CandidatesPageUI: React.FC<CandidatesPageUIProps> = props => {
  return (
    <CandidatesPageUIStyle>
      <SidebarContainer>{props.children}</SidebarContainer>
      <Main>
        <TopBar tracking={tracking} />
        <PageContainer>
          <PageHeader.Root>
            <PageHeader.Title data-testid="candidates-page-title">Candidates</PageHeader.Title>
            <PageHeader.EndItems>
              <Count data-testid="candidates-count">{props.count} Candidates</Count>
              <TextField
                value={props.textSearchValue}
                placeholder="Search"
                icon="search"
                onChange={props.onSearchTextInputChange}
              />
            </PageHeader.EndItems>
          </PageHeader.Root>
          <PageContent>
            <PageSidebar>
              <CandidateCategorySelector
                orgAdmin={props.allowedToSeeOrganizationCandidates}
                selected={props.candidateCategory}
                setSelected={props.setCandidateCategory}
              />
            </PageSidebar>
            {props.noResults ? (
              <NoResults type={props.noResults} />
            ) : (
              <CandidatesTable
                updateHiring={props.updateHiring}
                candidates={props.candidates}
                onSort={props.onSort}
                loadMoreCandidates={() =>
                  props.loadCandidates({
                    offset: props.candidates.length,
                    title: props.textSearchValue,
                    category: props.candidateCategory,
                  })
                }
                hasMoreCandidates={props.hasMoreCandidates}
              />
            )}
          </PageContent>
        </PageContainer>
      </Main>
    </CandidatesPageUIStyle>
  )
}

interface CandidatesPageProps {}

type CandidateSearchQuery = {
  offset?: number
  limit?: number
  title?: string
  sort?: listing.Options['sort']
  category: SelectedCandidatesCategory
}

const CandidatesPage: React.FC<CandidatesPageProps> = () => {
  const dispatch = useDispatch()
  const user = useUser()
  const location = useLocation()
  const history = useHistory()

  const canManageOrg = useSelector((state: RootState) => selectors.users.canManageOrganization(state, user.id))
  const candidates: Candidate[] = useSelector((state: RootState) =>
    selectors.candidates.findCandidatesForCandidateList(state, true),
  )

  const count = useSelector((state: RootState) => state.candidateManagement.totalCandidates)

  const org = useSelector((state: RootState) => selectors.orgs.findByUserId(state, user.id))

  const searchParams = new URLSearchParams(location.search)

  const selectedCandidateCategory = (searchParams.get(CandidateCategorySearchParamKey) ||
    'mine') as SelectedCandidatesCategory

  const textSearchValue = searchParams.get(TextSearchParamKey) || ''

  const { managers } = useManagers({ orgId: org?.id || '' })

  const hasMore = useSelector((state: RootState) => state.candidateManagement.hasMore)

  const [sort, setSort] = React.useState<listing.Options['sort']>([
    {
      field: 'updated_at',
      order: listing.SortOrder.Desc,
    },
  ])

  const onSort = useCallback(
    (key: string, direction: string) => {
      setSort([
        {
          field: key,
          order: direction as SortOrder,
        },
      ])
    },
    [dispatch],
  )

  const loadCandidates = useCallback(
    (query: CandidateSearchQuery) => {
      if (!user?.id) return

      if (query.category === 'org') {
        return dispatch(loadByOrg(query.title || '', user.fields.organization_id, query.offset, query.sort))
      }

      if (query.category === 'archived') {
        return dispatch(
          loadUserCandidates(query.title || '', user.id, query.offset, query.sort, [listing.False('is_active')]),
        )
      }

      return dispatch(loadUserCandidates(query.title || '', user.id, query.offset, query.sort))
    },
    [user.id, dispatch, org?.id],
  )

  const setCandidateCategory = (candidateCategory: SelectedCandidatesCategory) => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set(CandidateCategorySearchParamKey, candidateCategory)
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    })
  }

  const onSearchTextInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set(TextSearchParamKey, ev.target.value)
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    })
  }

  useDebounce(
    () =>
      loadCandidates({
        title: textSearchValue,
        category: selectedCandidateCategory,
        sort: sort,
      }),
    250,
    [textSearchValue, selectedCandidateCategory, sort],
  )

  return (
    <CandidatesPageUI
      managers={managers}
      loadCandidates={loadCandidates}
      candidates={candidates}
      onSort={onSort}
      hasMoreCandidates={hasMore}
      allowedToSeeOrganizationCandidates={canManageOrg}
      count={count}
      updateHiring={(id, isHired, isRejected) => dispatch(updateHireStatus(id, isHired, isRejected))}
      noResults={
        candidates?.length === 0
          ? getNoResultsReason(textSearchValue, selectedCandidateCategory === 'archived')
          : undefined
      }
      candidateCategory={selectedCandidateCategory}
      setCandidateCategory={setCandidateCategory}
      textSearchValue={textSearchValue}
      onSearchTextInputChange={onSearchTextInputChange}
    >
      <SidebarMenu />
    </CandidatesPageUI>
  )
}

export default CandidatesPage

function getNoResultsReason(title: string, archived: boolean): ResultsNotFound | undefined {
  if (archived || title) {
    return 'no-candidates-found'
  }

  return 'no-candidates-exist'
}
