import Button from 'components/Button/ButtonV1'
import { Icon } from 'components/Icons'
import Modal from 'components/Modal'
import TextField from 'components/TextField'
import { colors as colorTokens, px2rem, rem, size, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import { useReferencesExceptSelf } from 'providers/references'
import { useWorkVerifications } from 'providers/work-verifications'
import React, { useState } from 'react'

const Content = style()
  .spacing({ inner: [px2rem(24), px2rem(32)] })
  .scroll({ y: 'auto' })
  .size({
    maxHeight: px2rem(256),
  })
  .element()

const Buttons = style()
  .flex({ justifyContent: 'flex-end' })
  .spacing({ inner: px2rem(20) })
  .select('> * + *', style().spacing({ outerLeft: px2rem(20) }))
  .border({
    top: `solid 1px`,
    color: designSystemColors.borderDefault,
  })
  .element()

const Container = style()
  .flex({ direction: 'column' })
  .size({
    maxWidth: px2rem(640),
    width: `calc(100vw - ${px2rem(200)})`,
  })
  .select(`> ${Buttons}`, style().spacing({ outerTop: size.auto }))
  .element()

const IntroductionText = text.bodyText().element('p')
const IntroductionTextList = style(IntroductionText)
  .spacing({ inner: px2rem(16) })
  .element('ul')
const IntroductionTextListItem = style(IntroductionText).element('li')

interface IntroductionProps {
  next: () => void
  close: () => void
}

const Introduction: React.FC<IntroductionProps> = ({ next, close }) => {
  return (
    <>
      <Content>
        <IntroductionText>
          {
            'You can verify a candidate’s work experience via HR verification. HiPeople will request the candidate to provide a contact of an HR person of a company, then verify the candidate’s:'
          }
        </IntroductionText>
        <IntroductionTextList>
          <IntroductionTextListItem>{'Employment'}</IntroductionTextListItem>
          <IntroductionTextListItem>{'Job(s) in the company'}</IntroductionTextListItem>
          <IntroductionTextListItem>{'Duration of the employment'}</IntroductionTextListItem>
        </IntroductionTextList>
      </Content>
      <Buttons>
        <Button minimal inline onClick={close}>
          {'Cancel'}
        </Button>
        <Button primary inline onClick={next}>
          {'Specify Companies'}
        </Button>
      </Buttons>
    </>
  )
}

const WorkVerificationRequestFormDescription = text.bodyText().element('p')
const CompanyChipRemoveButton = style()
  .noborders()
  .block()
  .bg({ color: colorTokens.transparent })
  .pointer()
  .spacing({ outerLeft: px2rem(12) })
  .element('button')
const CompanyChip = text
  .bodyText()
  .border({
    color: designSystemColors.borderDefault,
    around: 'solid 1px',
    radius: px2rem(48),
  })
  .bg({ color: designSystemColors.backgroundNeutralSecondary })
  .flex()
  .spacing({ inner: [px2rem(8), px2rem(12)], outer: px2rem(8) })
  .element('li')
const CompanyChips = style()
  .flex({ wrap: 'wrap' })
  .spacing({ outer: px2rem(-8), outerTop: px2rem(4), inner: size.none })
  .element('ul')
const CompanyInputForm = style()
  .flex({ alignItems: 'flex-end' })
  .spacing({ outerTop: px2rem(24) })
  .element()

interface WorkVerificationRequestFormProps {
  candidateId: string
  close: () => void
}

const WorkVerificationRequestForm: React.FC<WorkVerificationRequestFormProps> = ({ candidateId, close }) => {
  const { references } = useReferencesExceptSelf({ candidateId })
  const { createWorkVerifications } = useWorkVerifications()

  const [companies, setCompanies] = useState<string[]>(() => {
    const companies = references.map(r => r.fields.company_name)
    // filtering out duplicates
    return Array.from(new Set(companies))
  })
  const [newCompany, setNewCompany] = useState('')
  const isNewCompanyAlreadyInTheList = new Set(companies).has(newCompany.trim())
  const isAddButtonDisabled = isNewCompanyAlreadyInTheList || newCompany.length === 0
  const isSendRequestButtonDisabled = companies.length === 0
  const onSubmit = async () => {
    const isSuccess = await createWorkVerifications(candidateId, companies)
    if (isSuccess) {
      close()
    }
  }

  return (
    <>
      <Content>
        <WorkVerificationRequestFormDescription>
          {'Companies from which HiPeople will request work verification:'}
        </WorkVerificationRequestFormDescription>
        <CompanyChips>
          {companies.map(company => (
            <CompanyChip key={company}>
              <span
                onClick={ev => {
                  ev.stopPropagation()
                }}
              >
                {company}
              </span>
              <CompanyChipRemoveButton
                title={'remove'}
                onClick={() => {
                  setCompanies(companies => companies.filter(c => c !== company))
                }}
              >
                <Icon name="times" />
              </CompanyChipRemoveButton>
            </CompanyChip>
          ))}
        </CompanyChips>
        <CompanyInputForm>
          <TextField
            label={'Add company:'}
            placeholder={'Company name'}
            value={newCompany}
            onChange={ev => setNewCompany(ev.target.value)}
            onEnter={() => {
              if (isAddButtonDisabled) {
                return
              }
              setCompanies(companies => [...companies, newCompany.trim()])
              setNewCompany('')
            }}
          />
          <Button
            style={{
              height: rem(3.1),
              marginLeft: rem(1.6),
            }}
            onClick={() => {
              setCompanies(companies => [...companies, newCompany.trim()])
              setNewCompany('')
            }}
            disabled={isAddButtonDisabled}
          >
            {'Add'}
          </Button>
        </CompanyInputForm>
      </Content>
      <Buttons>
        <Button minimal inline onClick={close}>
          {'Cancel'}
        </Button>
        <Button primary inline onClick={onSubmit} disabled={isSendRequestButtonDisabled}>
          {'Send Request'}
        </Button>
      </Buttons>
    </>
  )
}

type PageId = 'introduction' | 'work-verification-request-form'

interface WorkVerificationRequestModalProps {
  candidateId: string
  open: boolean
  setOpen: (b: boolean) => void
}

const WorkVerificationRequestModal: React.FC<WorkVerificationRequestModalProps> = ({ open, setOpen, candidateId }) => {
  const [selectedPageId, setSelectedPageId] = useState<PageId>('introduction')
  return (
    <Modal title={`Request Work Verification`} open={open} setOpen={setOpen}>
      <Container>
        {selectedPageId === 'introduction' && (
          <Introduction next={() => setSelectedPageId('work-verification-request-form')} close={() => setOpen(false)} />
        )}
        {selectedPageId === 'work-verification-request-form' && (
          <WorkVerificationRequestForm candidateId={candidateId} close={() => setOpen(false)} />
        )}
      </Container>
    </Modal>
  )
}

export default WorkVerificationRequestModal
