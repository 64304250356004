import { text } from 'core/design-system/text'
import { EmojiColor } from '../../../components/Container/ContainerCard'
import { px2rem, style } from '../../../core'

export const ScoreExplanationContainer = style()
  .grid({
    columns: '1fr 1fr',
  })
  .spacing({
    gap: '2rem',
  })
  .element()

export const ScoreExplanationTitle = text
  .bodyEmphasis()
  .spacing({ bottom: px2rem(6) })
  .element()

export interface PersonalityModuleScoringProps {
  inventoryId?: string
  title: string
  emoji: string
  emojiColor: EmojiColor
  score: number | null // 0-10
  scoreLabel?: string
  description?: string
  startCaption: string
  endCaption: string
  locale: string
  lowBehaviourExample?: string
  highBehaviourExample?: string
}
