import { Button } from '@common/components'
import * as api from 'api'
import { getPreviewToken } from 'api/library-items'
import { QuestionEntity, QuestionResponseType } from 'api/questions'
import { ResponseOptionEntity } from 'api/response-options'
import { LibrarySelection } from 'App/RoleCreation/TemplateCreationSelfAssessment/selection'
import { ActionButton } from 'components/ActionButton'
import { Badge, BadgeTheme } from 'components/Badge'
import { Icon } from 'components/Icons'
import ToggleField from 'components/ToggleField'
import { px2rem, size, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import * as tracking from 'core/track'
import { candidateBaseURL, formatDurationSeconds } from 'core/utils'
import { xor } from 'lodash-es'
import { useFeatureFlag } from 'providers/features'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as selectors from 'selectors'
import { RootState } from 'store'
import { add as notify } from 'store/notifications'
import { addEntities } from 'store/resources'

const PreviewSidebarStyle = style()
  .size({ width: px2rem(450), height: size.fullScreen })
  .bg({ color: 'white' })
  .element()

const Header = style()
  .border({
    bottom: '1px solid',
    color: designSystemColors.borderDefault,
  })
  .flex({ alignItems: 'center' })
  .spacing({ innerRight: size.m })
  .element()

const HeaderText = text
  .cardHeader()
  .flex({ grow: '1' })
  .spacing({ around: px2rem(24) })
  .element('p')

const Title = text.highlight().spacing({ outerTop: size.xxs, outerBottom: size.m }).element('p')

const Container = style()
  .flex({ direction: 'column', alignItems: 'flex-start' })
  .spacing({ inner: size.m })
  .size({ maxHeight: `calc(100% - (3 * ${px2rem(24)}))` })
  .scroll({ y: 'auto' })
  .element()

const Text = text.bodyText().element()

const InfoBlock = style()
  .border({
    around: `1px solid`,
    color: designSystemColors.borderDefault,
    radius: px2rem(6),
  })
  .flex()
  .spacing({ gap: size.xxs })
  .spacing({ inner: size.s, outerRight: px2rem(20) })
  .element()

const InfoBlocks = style()
  .flex()
  .spacing({ top: px2rem(24) })
  .element()

const Subtitle = text
  .label()
  .sans({ uppercase: true })
  .spacing({ top: px2rem(30), bottom: px2rem(10) })
  .element('p')

const Box = style()
  .border({
    around: '1px solid',
    color: designSystemColors.borderDefault,
    radius: px2rem(6),
  })
  .flex({ direction: 'column' })
  .spacing({ inner: px2rem(16), gap: px2rem(8) })
  .element()

const IconContainer = style().color({ fg: designSystemColors.backgroundNeutralPrimaryInverted }).element()

interface PreviewSidebarProps {
  organizationSlug?: string
  libraryItemId?: string
  title: string
  badge: {
    copy: string
    theme: BadgeTheme
    icon: string
  }
  testDescription: string
  durationSeconds: number
  testType: string
  testTypeIcon: string
  exampleQuestion: string
  responseType?: QuestionResponseType
  whenToUse: string
  result: string
  selection: LibrarySelection
  close: () => void
  onConfiguredAction?: (id: string, isConfigured: boolean) => void
}

const PreviewSidebar: React.FC<PreviewSidebarProps> = (props: PreviewSidebarProps) => {
  const { isEnabled: isLibraryItemExternalPreviewEnabled } = useFeatureFlag('library-item-external-preview')

  const libraryItemConfig = useLibraryItemConfig(props.libraryItemId || '')

  useEffect(() => {
    libraryItemConfig.forEach(({ question }) => {
      const selectableOptions =
        props.libraryItemId && (props.selection.config[props.libraryItemId]?.questions?.[question.fields.slug] || [])

      if (selectableOptions?.length === question.fields.properties.maxselect) {
        props.onConfiguredAction?.(props.libraryItemId ?? '', true)
      }
    })
  }, [])

  return (
    <PreviewSidebarStyle data-testid="question-preview-sidebar" onClick={ev => ev.stopPropagation()}>
      <Header>
        <HeaderText>Test Preview</HeaderText>
        <ActionButton onClick={props.close} icon={'times'} />
      </Header>
      <Container>
        <Badge copy={props.badge.copy} theme={props.badge.theme} icon={props.badge.icon} />
        <Title>{props.title}</Title>
        <Text>{props.testDescription}</Text>
        <InfoBlocks>
          {props.durationSeconds ? (
            <InfoBlock>
              <IconContainer>
                <Icon name={'clock'} />
              </IconContainer>
              <Text>{formatDurationSeconds(props.durationSeconds)}</Text>
            </InfoBlock>
          ) : (
            ''
          )}
          {props.testType && (
            <InfoBlock>
              <Icon name={props.testTypeIcon} />
              <Text>{props.testType}</Text>
            </InfoBlock>
          )}
        </InfoBlocks>
        <div>
          {libraryItemConfig.map(({ question, responseOptions: ro }) => {
            const config = structuredClone(props.selection.config[props.libraryItemId ?? '']) || { questions: {} }

            return (
              <React.Fragment key={question.id}>
                <Subtitle>{question.fields.copy['heading']}</Subtitle>
                <Box data-testid="question-preview-sidebar-suboptions">
                  {question.fields.properties.maxselect ? (
                    <span>Please select {question.fields.properties.maxselect} options</span>
                  ) : (
                    ''
                  )}
                  {ro.map(opt => {
                    const isSelected = config.questions?.[question.fields.slug]?.includes(opt.fields.slug)
                    const selectedOptionsCount = (config.questions?.[question.fields.slug] || []).length

                    const disabled =
                      !isSelected && question && selectedOptionsCount >= (question.fields.properties.maxselect || 0)

                    const onChange = () => {
                      if (!config.questions) {
                        config.questions = {
                          [question.fields.slug]: [],
                        }
                      }

                      config.questions[question.fields.slug] = xor(config.questions[question.fields.slug], [
                        opt.fields.slug,
                      ])

                      if (props.libraryItemId) {
                        props.selection.setConfig({
                          ...props.selection.config,
                          [props.libraryItemId]: config,
                        })
                      }

                      if (props.libraryItemId && props.onConfiguredAction) {
                        const selectedOptionsCount = (config.questions?.[question.fields.slug] || []).length
                        const maxOptionsSelected = selectedOptionsCount === question.fields.properties.maxselect

                        maxOptionsSelected
                          ? props.onConfiguredAction(props.libraryItemId, true)
                          : props.onConfiguredAction(props.libraryItemId, false)
                      }
                    }

                    return (
                      <ToggleField
                        key={opt.id}
                        label={opt.fields.copy['caption']}
                        disabled={disabled}
                        on={isSelected}
                        onChange={onChange}
                        testId="question-preview-sidebar-suboption-switch"
                      />
                    )
                  })}
                </Box>
              </React.Fragment>
            )
          })}
          {isLibraryItemExternalPreviewEnabled && props.libraryItemId && props.organizationSlug && (
            <>
              <Button
                variant="purple"
                style={{
                  marginTop: '1rem',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '0.5rem',
                }}
                onClick={async () => {
                  tracking.selfAssessment.testPreview(props.title)

                  if (props.organizationSlug && props.libraryItemId) {
                    const [response, errors] = await getPreviewToken(props.libraryItemId)
                    if (errors) {
                      errors.forEach(e => {
                        notify({ error: e.message })
                      })
                    }

                    window.open(
                      `${candidateBaseURL(props.organizationSlug)}/preview/library-item/${
                        props.libraryItemId
                      }/reference?token=${response?.result.fields.token}`,
                    )
                  }
                }}
              >
                <Icon name={'eye'} />
                Preview example questions
              </Button>
            </>
          )}
          {
            // TODO: try to make sure every library item has this data
            props.whenToUse ? (
              <>
                <Subtitle>When to use</Subtitle>
                <Text>{props.whenToUse}</Text>
              </>
            ) : (
              ''
            )
          }

          {
            // TODO: try to make sure every library item has this data
            props.result ? (
              <>
                <Subtitle>Result</Subtitle>
                <Text>{props.result}</Text>
              </>
            ) : (
              ''
            )
          }
        </div>
      </Container>
    </PreviewSidebarStyle>
  )
}

export default PreviewSidebar

// right now, as far as library item configs go, we only support module containing configurable-chips questions. so look for that
const useLibraryItemConfig = (libraryItemId: string) => {
  const dispatch = useDispatch()

  const libraryItem = useSelector((state: RootState) => selectors.libraryItems.findById(state, libraryItemId))

  const questions =
    useSelector((state: RootState) => selectors.questions.findByModuleId(state, libraryItem?.fields.entity_id || '')) ||
    []

  useEffect(() => {
    if (questions.length) return
    ;(async () => {
      const [result, errors] = await api.modules.byId(libraryItem?.fields.entity_id || '')

      if (result) dispatch(addEntities(result))
      if (errors && errors.length > 0) {
        dispatch(
          notify({
            error: errors[0].message,
          }),
        )
      }
    })()
  }, [questions.length])

  const selectableQuestions = questions.filter(q => q.fields.response_type === 'configurable-chips')

  const optionsByQuestion = useSelector((state: RootState) => {
    const grouped: {
      question: QuestionEntity
      responseOptions: ResponseOptionEntity[]
    }[] = []
    for (const question of selectableQuestions)
      grouped.push({
        question,
        responseOptions: selectors.responseOptions.findByQuestionId(state, question.id) ?? [],
      })
    return grouped
  })

  return optionsByQuestion
}
