import { Card, Text } from '@common/components'
import { BsStars } from 'react-icons/bs'
import classes from './AIInsights.module.scss'
import React from 'react'

export const AIInsights: React.FC<{ children: React.ReactNode }> = props => (
  <Card className="hideInPrint">
    <Text variant={'body-emphasis'}>AI Insights</Text>
    <div className={classes.insights}>{props.children}</div>
  </Card>
)

export const AIInsight: React.FC<{ name: string; onClick?: () => void; children: React.ReactNode }> = props => (
  <div className={classes.insight} onClick={props.onClick}>
    <Text variant={'body-interactive'} className={classes.insightHeading}>
      <BsStars color="#4C49EE " /> {props.name}
    </Text>
    <Text variant={'small-body-text'} className={classes.insightBody}>
      {props.children}
    </Text>
  </div>
)
