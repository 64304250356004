import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Block from '../../components/Block'
import Button from '../../components/Button/ButtonV1'
import Form from '../../components/Form'
import ProfileImage from '../../components/ProfileImage'
import TextField from '../../components/TextField'
import { size, space, style } from '../../core'
import * as selectors from '../../selectors'
import { RootState } from '../../store'
import { Changes, save } from '../../store/settings/account'

const Grid = style()
  .grid({ columns: [size.xxxl, size.auto] })
  .spacing({ columns: space.l })
  .element()

interface Props {
  userid?: string
}

const Component = (props: Props) => {
  const dispatch = useDispatch()

  const user = useSelector((state: RootState) => {
    const user = selectors.users.getById(state, props.userid || state.sessions.userId)

    return user?.fields
  })
  const profile = useSelector((state: RootState) => {
    const profile = selectors.profiles.getByUserId(state, props.userid || state.sessions.userId)

    return profile?.fields
  })
  const loading = useSelector((state: RootState) => {
    return state.accountSettings.loading
  })

  const [changes, setChanges] = React.useState<Changes>({})

  const notchanged = Object.values(changes).length === 0
  const invalidEmail = !selectors.isEmailValid(changes.email || user.email)

  return (
    <Block title="Account and Profile" testId="account-page-header">
      <Grid>
        <ProfileImage
          src={(changes.profileImage && URL.createObjectURL(changes.profileImage)) || profile?.profile_picture_url}
          onChange={(profileImage?: File) => setChanges(changes => ({ ...changes, profileImage }))}
          name={profile?.full_name || ''}
        />
        <Form>
          <TextField
            label="Name"
            value={profile?.full_name || ''}
            change={changes.name}
            onChange={e => {
              const name = e.target.value
              setChanges(changes => ({ ...changes, name }))
            }}
            testId="account-name-input"
          />
          <TextField
            error={!!changes.email && invalidEmail}
            label="Email"
            value={user?.email || ''}
            disabled
            testId="account-email-input"
          />
          <TextField
            label="Location"
            value={profile?.location || ''}
            change={changes.location}
            onChange={e => {
              const location = e.target.value
              setChanges(changes => ({ ...changes, location }))
            }}
            testId="account-location-input"
          />
          <div></div>
          <Button
            primary
            onClick={() => (user && profile ? dispatch(save(user.id, profile.id, changes)) : null)}
            disabled={loading || notchanged || invalidEmail}
            testId="account-save-button"
          >
            Save
          </Button>
        </Form>
      </Grid>
    </Block>
  )
}

export default Component
Component.displayName = 'Account'
