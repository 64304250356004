import React, { createContext, useCallback, useState } from 'react'
import { breakpoints, rgba, size, space, style, vw } from '../../core'
import { zIndex } from '@common/styles/zIndex'

const Container = style()
  .fixed({ top: space.none, left: space.none })
  .bg({ color: rgba(0, 0, 0, 0.3) })
  .size({ width: vw(100), height: size.fullScreen })
  .center()
  .hidden()
  .cond(({ open }: Params) => open, style().flex())
  .depth({ index: Number(zIndex.modal) })
  .screen(breakpoints.mobile, style().bg({ color: rgba(0, 0, 0, 0.5) }))
  .element()

interface Params {
  open: boolean
}

interface Props extends Params {
  children: React.ReactNode
  setOpen: (open: boolean) => void
}

const ModalContext = createContext<(isModalAllowedToClose: boolean) => void>(() => {})

export const ModalContainer = (props: Props) => {
  const [isAllowedToClose, setAllowedToClose] = useState(true)
  const { setOpen } = props
  const onOutsideClick = useCallback(() => {
    if (isAllowedToClose) {
      setOpen(false)
    }
  }, [isAllowedToClose, setOpen])
  if (!props.open) return null

  return (
    <ModalContext.Provider value={setAllowedToClose}>
      <Container open={props.open} onClick={onOutsideClick}>
        {props.children}
      </Container>
    </ModalContext.Provider>
  )
}
