import React from 'react'
import classes from './AnalyticsTabs.module.scss'
import { Tabs } from '../Tabs/Tabs'
import { Text } from '../../components/Text/Text'

export type RoleAnalytics = {
  performance_stats: {
    average_overall_score: number
    top_10_percent_overall_score: number | null
    top_1_percent_overall_score: number | null
    highest_overall_score: number
    percentile_buckets: {
      count?: number
      bar_height?: number
    }[]
  }
  engagement_stats: {
    candidates_invited: number
    candidates_submitted: number
    completion_rate_percent: number
  }
}

interface AnalyticsTabsProps {
  data: RoleAnalytics
  tracking: {
    performanceClick: () => void
    engagementClick: () => void
  }
}

export const AnalyticsTabs = (props: AnalyticsTabsProps) => {
  return (
    <div className={classes.main}>
      <Tabs
        tabs={[
          {
            id: 'engagement',
            label: 'Engagement',
            content: (
              <div className={classes.content}>
                <div className={classes.contentItem}>
                  <Text variant="small-body">Candidates Invited</Text>
                  <Text variant="action-card-header">
                    {props.data.engagement_stats.candidates_invited.toLocaleString()}
                  </Text>
                </div>
                <div className={classes.contentItem}>
                  <Text variant="small-body">Assessment Submissions</Text>
                  <Text variant="action-card-header">
                    {props.data.engagement_stats.candidates_submitted.toLocaleString()}
                  </Text>
                </div>
                <div className={classes.contentItem}>
                  <Text variant="small-body">Completion Rate</Text>
                  <Text variant="action-card-header">
                    {props.data.engagement_stats.completion_rate_percent.toFixed(0)}%
                  </Text>
                </div>
              </div>
            ),
            onClickTab: props.tracking.engagementClick,
          },
          {
            id: 'performance',
            label: 'Performance',
            content: (
              <div className={classes.content}>
                <div className={classes.contentItem}>
                  <Text variant="small-body">Average Overall Score</Text>
                  <Text variant="action-card-header">
                    {props.data.performance_stats.average_overall_score.toFixed(0)}%
                  </Text>
                </div>
                <div className={classes.contentItem}>
                  <Text variant="small-body">Top 10% Overall Score</Text>
                  <Text variant="action-card-header">
                    {props.data.performance_stats.top_10_percent_overall_score
                      ? props.data.performance_stats.top_10_percent_overall_score.toFixed(0) + '%'
                      : '--'}
                  </Text>
                </div>
                <div className={classes.contentItem}>
                  <Text variant="small-body">Highest Overall Score</Text>
                  <Text variant="action-card-header">
                    {props.data.performance_stats.highest_overall_score.toFixed(0)}%
                  </Text>
                </div>
              </div>
            ),
            onClickTab: props.tracking.performanceClick,
          },
        ]}
        noSpacing
        variant="dark"
        type="bar"
      />
    </div>
  )
}
