import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useLocalStorage } from 'react-use'
import * as tracking from '../../core/track'

export function useEmailVerification() {
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  type Status = {
    verified: boolean
    error?: string
  }

  const [status, setStatus] = useLocalStorage<Status | null>('email-verification-status')

  const hasAnyStatus = !!params.get('verified')

  useEffect(() => {
    if (!hasAnyStatus || status === null) return

    const verified = params.get('verified') === 'true'
    const error = params.get('error') || ''

    setStatus({ verified, error })

    if (verified) {
      tracking.signUp.signUpVerifiedEmail(verified, error)
    }
  }, [hasAnyStatus])

  const dismiss = () => {
    setStatus(null)
  }

  return { status, dismiss }
}
