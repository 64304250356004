import { PopoverMenu, TopBar } from '@common/components'
import { getSessionTokenPath } from 'api/backgroundcheckintegrations'
import { createReferencePreviewLinkv2, createSelfReferencePreviewLinkv2, EvaluationStatus } from 'api/candidates'
import { createReferencePreviewLink, createSelfReferencePreviewLink } from 'api/openjobroles'
import TabSelector from 'App/Candidate/TabSelector'
import ButtonV2 from 'components/Button/ButtonV2'
import { useConfirm } from 'components/Confirm'
import { Breadcrumb, Breadcrumbs } from 'components/DashboardLayout/Breadcrumbs'

import { Icon } from 'components/Icons'
import { Button, Separator } from 'components/Popup'
import { style } from 'core'
import token from 'core/token'
import { useFeatureFlag } from 'providers/features'
import { useOrg } from 'providers/orgs'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { complete, fetch, remind, remove, unremove } from 'store/candidates'
import { add as notify } from 'store/notifications'
import { loadCandidates } from 'store/openjobroles'
import DashboardLayout, { Content } from '../../components/DashboardLayout'
import TourHint from '../../components/TourHint'
import WarningBanner from '../../components/WarningBanner'
import * as tracking from '../../core/track'
import * as selectors from '../../selectors'
import { CanManageOrganization, CanManageRole, IsSuperUser } from '../AccessLevel'
import AddReferenceModal from './AddReferenceModal'
import RequestCheckModal from './BackgroundCheck/RequestCheckModal'
import classes from './EmptyShell.module.scss'
import InviteLinkModal from './InviteLinkModal'
import ManagementLinkModal from './ManagementLinkModal'
import PreviewOnboardingModal from './PreviewOnboardingModal'
import ReferencesModal from './ReferencesModal'
import ShareCandidateLinkModal from './ShareCandidateLinkModal'
import UpdateEmailModal from './UpdateEmailModal'
import WarningHeader from './WarningHeader'
import WorkVerificationRequestModal from './WorkVerificationRequestModal'
import { Button as CommonButton } from '@common/components'

const QuickActionsStyle = style().flex({ alignItems: 'center' }).spacing({ gap: '0.6rem  ' }).element()

export interface Props {
  children: React.ReactNode
  candidateId: string
  roleId: string
  breadcrumbs: Breadcrumb[]
  button?: {
    send: () => void
    copy: string
  }
  evaluationStatus?: EvaluationStatus
  onEvaluationStatusChanged?: (newStatus: EvaluationStatus) => void
}

// TODO: This component is too big. Break it down into smaller components. Give meaningful name.
const EmptyShell = (props: Props) => {
  const dispatch = useDispatch()
  const confirm = useConfirm()

  const candidateId = props.candidateId
  const roleId = props.roleId

  const candidate = useSelector((state: RootState) => selectors.candidates.findById(state, candidateId))

  const isMarkedComplete = useSelector((state: RootState) => selectors.candidates.isMarkedComplete(state, candidateId))
  const profile = useSelector((state: RootState) => selectors.candidateProfiles.findByCandidateId(state, candidateId))
  const role = useSelector((state: RootState) => selectors.roles.findByCandidateId(state, candidateId))

  const requestedReferences = useSelector((state: RootState) =>
    selectors.references.totalRespondedReferencesExceptSelf(state, candidateId),
  )
  const greenhouseProfile = useSelector((state: RootState) =>
    selectors.greenhouseProfiles.findByCandidateId(state, candidateId),
  )
  const candidates = useSelector((state: RootState) => state.openjobroles.candidates)
  const canManageRole = useSelector((state: RootState) =>
    selectors.openJobRoleUsers.canManageRole(state, roleId || '', state.sessions.userId),
  )
  const referenceFormInProgress = useSelector((state: RootState) =>
    selectors.referenceCheckStages.getReferenceFormInProgress(state, candidateId),
  )

  const { org } = useOrg({ candidateId })
  const { isEnabled: isCandidateSharingEnabled } = useFeatureFlag('shareable-candidate')

  const currentOrgId = useSelector((state: RootState) => {
    return selectors.orgs.currentId(state)
  })

  useEffect(() => {
    if (!candidate) {
      dispatch(fetch(candidateId ? candidateId : ''))
    } else {
      dispatch(fetch(candidate.id))
    }
  }, [candidateId])

  useEffect(() => {
    if (roleId && !candidates[roleId]) {
      dispatch(loadCandidates(roleId, '', false))
    }
  }, [roleId])

  const [isUpdateEmailModalOpen, setIsUpdateEmailModalOpen] = useState(false)
  const [isAddReferenceModalOpen, setIsAddReferenceModalOpen] = useState(false)
  const [isInviteLinkModalOpen, setIsInviteLinkModalOpen] = useState(false)
  const [isShareCandidateLinkModalOpen, setIsShareCandidateLinkModalOpen] = useState(false)
  const [isManagementLinkModalOpen, setIsManagementLinkModalOpen] = useState(false)
  const [isPreviewOnboardingModalOpen, setIsPreviewOnboardingModalOpen] = useState(false)
  const [isUpdateReferencesModalOpen, setIsUpdateReferencesModalOpen] = useState(false)
  const [isWorkVerificationRequestModalOpen, setIsWorkVerificationRequestModalOpen] = useState(false)
  const [isBackgroundCheckModalOpen, setIsBackgroundCheckModalOpen] = useState(false)

  const { isEnabled: isWorkVerificationEnabled } = useFeatureFlag('work-verification')

  const trackingProps = useSelector((state: RootState) => tracking.roles.extractPropsFromState(state, role?.id || ''))

  // Checkr related data
  const { isEnabled: isCheckrIntegrationEnabled } = useFeatureFlag('background-checks')
  const existingBackgroundCheckIntegration = useSelector((state: RootState) => {
    const currentOrgId = selectors.orgs.currentId(state)
    return selectors.backgroundcheckintegrations.getByOrgId(state, currentOrgId)
  })
  const sessionTokenUrl = getSessionTokenPath(currentOrgId, 'checkr')
  const bearerToken = token.read()
  const candidateEmail = profile?.fields.email

  const shareCandidateQuick = (
    <CanManageRole roleId={role?.id || ''}>
      <ButtonV2
        style={{ height: '2rem' }}
        onClick={() => {
          setIsShareCandidateLinkModalOpen(true)
          tracking.candidates.shareModalOpened()
        }}
        buttonType={'tertiary'}
      >
        <Icon style={{ paddingRight: '0.5em' }} name={'share'} />
        Share
      </ButtonV2>
    </CanManageRole>
  )

  return (
    <DashboardLayout>
      <TopBar tracking={tracking} />

      <Content translate="no">
        <WarningHeader>
          {candidate && !candidate?.fields?.is_active && (
            <WarningBanner
              action={() => dispatch(unremove(props.candidateId))}
              actionEnabled={canManageRole}
              actionText="Undo"
            >
              {`This candidate has been archived. You can still access their
                data, however they will not be able to submit in future.`}
            </WarningBanner>
          )}
          {candidate && referenceFormInProgress && (
            <WarningBanner type="info">
              {`⏳ The reference questions for this candidate are being updated!
                This should take a few moments.`}
            </WarningBanner>
          )}
        </WarningHeader>
        <div className={classes.tabsWrapper}>
          <Breadcrumbs content={props.breadcrumbs} />

          <TabSelector content />

          <QuickActionsStyle>{shareCandidateQuick}</QuickActionsStyle>
          <PopoverMenu
            trigger={
              <CommonButton variant="ghost" data-testid="menu-dashboard-header-button">
                <Icon name="ellipsis-h" />
              </CommonButton>
            }
            menuContent={
              <div>
                <CanManageRole roleId={role?.id || ''}>
                  <Button
                    icon="envelope"
                    onClick={() => {
                      setIsUpdateEmailModalOpen(true)
                      tracking.candidates.updateEmail()
                    }}
                  >
                    {`Update email`}
                  </Button>
                </CanManageRole>
                {candidate && requestedReferences === 0 && canManageRole && role && role.fields.ask_reference_check && (
                  <Button
                    icon="bell"
                    onClick={() => {
                      dispatch(remind(candidate.id))
                      tracking.candidates.sendReminder()
                    }}
                  >
                    {`Send reminder`}
                  </Button>
                )}
                {candidate && canManageRole && !isMarkedComplete && (
                  <Button
                    icon="user-check"
                    onClick={() => {
                      dispatch(complete(candidate.id))
                      tracking.candidates.markCompleted()
                    }}
                  >
                    {`Mark completed`}
                  </Button>
                )}
                {candidate?.fields?.is_active ? (
                  <CanManageRole roleId={role?.id || ''}>
                    <Button icon="user-minus" onClick={onClickArchive}>
                      {`Archive`}
                    </Button>
                  </CanManageRole>
                ) : null}
                <Separator />
                {candidate && role && role.fields.ask_reference_check ? (
                  <CanManageRole roleId={role?.id || ''}>
                    <Button
                      icon="pencil"
                      onClick={() => {
                        setIsUpdateReferencesModalOpen(true)
                        tracking.candidates.updateReferenceRequirements()
                      }}
                    >
                      {`Update required references`}
                    </Button>
                  </CanManageRole>
                ) : null}
                {candidate?.fields?.is_active && role && role.fields.ask_reference_check ? (
                  <CanManageRole roleId={role?.id || ''}>
                    <Button
                      icon="tasks"
                      onClick={() => {
                        setIsAddReferenceModalOpen(true)
                        tracking.candidates.addReference()
                      }}
                    >
                      {`Invite a reference`}
                    </Button>
                  </CanManageRole>
                ) : null}
                {isWorkVerificationEnabled && candidate?.fields?.is_active && (
                  <CanManageRole roleId={role?.id || ''}>
                    <Button
                      icon="envelope-open"
                      onClick={() => {
                        setIsWorkVerificationRequestModalOpen(true)
                        tracking.candidates.workVerificationModalOpened()
                      }}
                    >
                      {'Request Work Verification'}
                    </Button>
                  </CanManageRole>
                )}

                {isCheckrIntegrationEnabled && existingBackgroundCheckIntegration?.fields?.is_ready && candidate && (
                  <CanManageRole roleId={role?.id || ''}>
                    <Button
                      icon="file"
                      onClick={() => {
                        setIsBackgroundCheckModalOpen(true)
                        tracking.candidates.openCheckrInviteModal()
                      }}
                    >
                      {`Background check with Checkr`}
                    </Button>
                  </CanManageRole>
                )}
                <Separator />
                {candidate && (
                  <CanManageOrganization>
                    <Button
                      icon="link"
                      onClick={() => {
                        setIsManagementLinkModalOpen(true)
                        tracking.candidates.getManagementLink()
                      }}
                    >
                      {`Get candidate portal link`}
                    </Button>
                  </CanManageOrganization>
                )}
                {candidate?.fields.is_hired && (
                  <IsSuperUser>
                    <Button
                      icon="link"
                      onClick={() => {
                        setIsPreviewOnboardingModalOpen(true)
                      }}
                    >
                      {`Preview onboarding`}
                    </Button>
                  </IsSuperUser>
                )}
                {/* 
          candidate exists AND role exists AND role is not archived AND there's an org slug
        */}
                {candidate && role && role.fields.is_active && role.fields.ask_reference_check && org?.fields.slug ? (
                  <Button icon="poll-people" onClick={viewAsReference}>
                    View as reference
                  </Button>
                ) : null}
                {/* 
          role exists and is not archived AND
          org slug exists AND
          (
            role has self reference form id OR 
            candidate has questionnaire id and ask_self_reference is true
          )
        */}
                {role &&
                role.fields.is_active &&
                org?.fields.slug &&
                ((role.fields.ask_self_reference && candidate?.fields.questionnaire_id) ||
                  role.fields.self_reference_form_id) ? (
                  <Button icon="poll-people" onClick={viewAsSelfReference}>
                    View self-reference
                  </Button>
                ) : null}
                {candidate && isCandidateSharingEnabled && (
                  <CanManageRole roleId={role?.id || ''}>
                    <Separator />
                    <TourHint hint="share-candidate-overview">
                      <Button
                        icon="reply"
                        onClick={() => {
                          setIsShareCandidateLinkModalOpen(true)
                          tracking.candidates.shareModalOpened()
                        }}
                      >
                        {`Share ${selectors.possessiveFirstName(profile?.fields.full_name || '')} overview`}
                      </Button>
                    </TourHint>
                  </CanManageRole>
                )}
                {greenhouseProfile && greenhouseProfile.fields.greenhouse_profile_url ? (
                  <>
                    <Separator />
                    <Button icon="external-link" link={greenhouseProfile.fields.greenhouse_profile_url}>
                      {`Open in Greenhouse`}
                    </Button>
                  </>
                ) : null}
                {greenhouseProfile && greenhouseProfile.fields.resume_url.trim().length > 0 ? (
                  <Button icon="file-user" link={greenhouseProfile.fields.resume_url}>
                    {`Download resume`}
                  </Button>
                ) : null}
                <Separator />
                <Button
                  icon="life-ring"
                  link="https://intercom.help/hipeople/en/"
                  onClick={() => tracking.users.help()}
                >
                  Help
                </Button>
              </div>
            }
          />
        </div>
        {props.children}
      </Content>
      <UpdateEmailModal
        candidateId={candidate?.id || ''}
        open={isUpdateEmailModalOpen}
        setOpen={setIsUpdateEmailModalOpen}
      />
      <AddReferenceModal
        candidateId={candidate?.id || ''}
        open={isAddReferenceModalOpen}
        setOpen={setIsAddReferenceModalOpen}
      />
      <InviteLinkModal
        candidateId={candidate?.id || ''}
        open={isInviteLinkModalOpen}
        setOpen={setIsInviteLinkModalOpen}
      />
      <ManagementLinkModal
        candidateId={candidate?.id || ''}
        open={isManagementLinkModalOpen}
        setOpen={setIsManagementLinkModalOpen}
      />
      <ReferencesModal
        candidateId={candidate?.id || ''}
        open={isUpdateReferencesModalOpen}
        setOpen={setIsUpdateReferencesModalOpen}
      />
      <ShareCandidateLinkModal
        candidateId={candidate?.id || ''}
        open={isShareCandidateLinkModalOpen}
        setOpen={setIsShareCandidateLinkModalOpen}
      />
      {isWorkVerificationRequestModalOpen && (
        <WorkVerificationRequestModal
          candidateId={candidate?.id || ''}
          open={isWorkVerificationRequestModalOpen}
          setOpen={setIsWorkVerificationRequestModalOpen}
        />
      )}
      {isPreviewOnboardingModalOpen && (
        <PreviewOnboardingModal
          candidateId={candidate?.id || ''}
          open={isPreviewOnboardingModalOpen}
          setOpen={setIsPreviewOnboardingModalOpen}
        />
      )}
      {isBackgroundCheckModalOpen && (
        <RequestCheckModal
          candidateId={candidate?.id || ''}
          sessionTokenPath={sessionTokenUrl || ''}
          bearerToken={bearerToken}
          candidateEmail={candidateEmail || ''}
          open={isBackgroundCheckModalOpen}
          setOpen={setIsBackgroundCheckModalOpen}
        />
      )}
    </DashboardLayout>
  )

  async function viewAsReference() {
    const newTab = window.open()

    const [url, errors] = candidate?.fields.questionnaire_id
      ? await createReferencePreviewLinkv2(candidateId, org?.fields.slug || '')
      : await createReferencePreviewLink(role?.id || '', org?.fields.slug || '')

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    if (newTab) {
      newTab.location.href = url
    }

    if (trackingProps) {
      tracking.roles.viewAsReference(trackingProps)
    }
  }

  async function viewAsSelfReference() {
    const newTab = window.open()

    const [url, errors] = candidate?.fields.questionnaire_id
      ? await createSelfReferencePreviewLinkv2(candidateId, org?.fields.slug || '')
      : await createSelfReferencePreviewLink(role?.id || '', org?.fields.slug || '')

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    if (newTab) {
      newTab.location.href = url
    }

    if (trackingProps) {
      tracking.roles.viewAsSelfReference(trackingProps)
    }
  }

  async function onClickArchive() {
    if (
      await confirm({
        title: `About to archive a candidate, continue?`,
        message: `**${profile?.fields.full_name || ''}** will be archived and won't be able to continue the process.`,
        confirmLabel: 'Yes, archive candidate',
        requireInput: profile?.fields.full_name,
        requireInputLabel: 'Enter the full name of the candidate to confirm:',
        requireInputPlaceholder: 'Verify the full name of the candidate to archive',
        danger: true,
      })
    ) {
      dispatch(remove(candidate?.id || ''))
      tracking.candidates.deleted()
    }
  }
}

export default EmptyShell
