import * as api from 'api'
import { Icon } from 'components/Icons'
import { px, style, weight } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import * as selectors from 'selectors'
import { RootState } from 'store'

interface TimeProps {
  stamp: number
}

const TimeText = text.bodyText().fg(designSystemColors.typographySecondary).element()

const Time = (props: TimeProps) => {
  const dateString = selectors.dateString(new Date(selectors.milliseconds(props.stamp)))
  return <TimeText>{dateString}</TimeText>
}

const Missing = text
  .bodyText()
  .inlineBlock()
  .color({ bg: designSystemColors.uiStatusWarningBackground })
  .spacing({ inner: [px(2), px(6)] })
  .round(px(3))
  .element('span')

const Status = text.bodyText().fg(designSystemColors.typographySecondary).text({ weight: weight.bold }).element()
const StatusSuccess = style(Status).fg(designSystemColors.uiStatusSuccess).element()
const StatusError = style(Status).fg(designSystemColors.uiStatusError).element()

interface Props {
  reference?: api.request.Entity<api.references.Fields>
}

const ColumnStatus = (props: Props) => {
  const { t } = useTranslation()
  const formResponse = useSelector((state: RootState) =>
    selectors.formResponses.findByReferenceId(state, props.reference?.id || ''),
  )

  const submission = useSelector((state: RootState) =>
    selectors.questionnaireSubmissions.findByReferenceId(state, props.reference?.id || ''),
  )

  if (!props.reference)
    return <Missing>{t('candidate-dashboard-intro.references.status-missing', '⚠️ &nbsp;Add details')}</Missing>

  if ((props.reference.fields.form_response_id && formResponse) || submission?.fields.submitted_at)
    return (
      <StatusSuccess>
        <Icon name="check-circle" /> {t('candidate-dashboard-intro.references.status-completed', 'Completed')}
        <Time stamp={formResponse?.fields.created_at || submission?.fields.submitted_at || 0} />
      </StatusSuccess>
    )

  if (props.reference.fields.email_read_at)
    return (
      <Status>
        <Icon name="envelope-open" /> {t('candidate-dashboard-intro.references.status-opened', 'Invite opened')}
        <Time stamp={props.reference.fields.email_read_at} />
      </Status>
    )

  if (props.reference.fields.email_failed_at)
    return (
      <StatusError>
        <Icon name="envelope" /> {t('candidate-dashboard-intro.references.status-bounced', 'Invite bounced')}
        <Time stamp={props.reference.fields.email_failed_at} />
      </StatusError>
    )

  if (props.reference.fields.email_delivered_at || props.reference.fields.sms_delivered_at)
    return (
      <Status>
        <Icon name="paper-plane" /> {t('candidate-dashboard-intro.references.status-sent', 'Invite sent')}
        <Time stamp={props.reference.fields.email_delivered_at || props.reference.fields.sms_delivered_at} />
      </Status>
    )

  return (
    <Status>
      <Icon name="paper-plane" /> {t('candidate-dashboard-intro.references.status-sending', 'Invite sending')}
    </Status>
  )
}

export default ColumnStatus
