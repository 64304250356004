import React, { useState } from 'react'
import { Mention, MentionItem, MentionsInput } from 'react-mentions'
import { useDispatch, useSelector } from 'react-redux'
import { Option } from '../../components/DropdownField'
import * as tracking from '../../core/track'
import * as selectors from '../../selectors'
import { RootState } from '../../store'
import { add } from '../../store/comments'
import { Label, Row } from '../Block'
import Button from '../Button/ButtonV1'
import ProfileImage from '../ProfileImage'
import classes from './Index.module.scss'
import { TextFieldBase } from '../TextField'

interface Props {
  candidateId: string
}

const Component = (props: Props) => {
  const dispatch = useDispatch()

  const [profileSuggestions, user]: [
    ReturnType<typeof selectors.comments.findSuggestionsByRoleId>,
    ReturnType<typeof selectors.profiles.getByUserId>,
  ] = useSelector((state: RootState) => {
    const role = selectors.roles.findByCandidateId(state, props.candidateId)
    return [
      selectors.comments.findSuggestionsByRoleId(state, role?.id || ''),
      selectors.profiles.getByUserId(state, selectors.users.current(state)?.id || ''),
    ]
  })

  const [text, setText] = useState('')
  const [mentions, setMentions] = useState<MentionItem[]>([])

  return (
    <Row comment>
      <ProfileImage nocircle src={user?.fields.profile_picture_url} name={user?.fields.full_name || ''} />
      <div>
        <Label>
          <strong className="data-hj-suppress">{user?.fields.full_name || ''}</strong>
        </Label>
        <div className={classes.container}>
          <MentionsInput
            placeholder={'Leave a comment here'}
            allowSuggestionsAboveCursor
            value={text}
            className={`${TextFieldBase.css({})} ${classes.textfield}`}
            onChange={(event, newValue, newPlainTextValue, mentions) => {
              setText(newValue)
              setMentions(mentions)
            }}
            style={{ suggestions: { top: '10px' } }}
          >
            <Mention
              displayTransform={(id: string, display: string) => `@${display}`}
              trigger="@"
              // @ts-ignore
              data={profileSuggestions}
              className={''}
              renderSuggestion={(suggestion, search, name, index, focused) => (
                <Option icon="user" value={suggestion.id} selected={focused}>
                  {suggestion.display}
                </Option>
              )}
            />
          </MentionsInput>
          <Button onClick={onSubmit}>Send</Button>
        </div>
      </div>
    </Row>
  )

  function onSubmit() {
    dispatch(
      add(
        props.candidateId,
        text,
        mentions.map(m => m.id),
      ),
    )
    setText('')
    tracking.candidates.commentLeft()
  }
}

export default Component
Component.displayName = 'Editor'
