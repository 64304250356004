import { Banner, Chip, LoadingSpinner, PricingCard, Tabs, Text, TopBar } from '@common/components'
import { TogglePeriod } from '@common/components/Pricing/TogglePeriod/TogglePeriod'
import * as tracking from 'core/track'
import * as selectors from 'selectors'
import React from 'react'
import DashboardLayout from '../../components/DashboardLayout'
import {
  AllInOneGrowPrices,
  AllInOneScalePrices,
  AssessmentGrowPrices,
  AssessmentScalePrices,
  EnterprisePrices,
  RefCheckGrowPrices,
  RefCheckScalePrices,
} from './pricing-data'
import { useSelector } from 'react-redux'

type Currency = 'USD' | 'EUR'
type Period = 'monthly' | 'yearly'

const topInfoHeight = '7rem'

const UpgradePage: React.FC = () => {
  const [currency] = React.useState<Currency>('USD')
  const [isMonthly, setIsMonthly] = React.useState(false)
  const period: Period = isMonthly ? 'monthly' : 'yearly'

  // suspend dashboard until org is loading so company size dropdowns read the right default value
  const organization = useSelector(selectors.orgs.current)
  if (!organization.fields.slug)
    return (
      <DashboardLayout>
        <TopBar tracking={tracking} />
        <LoadingSpinner />
      </DashboardLayout>
    )

  // TODO: add currency switcher
  return (
    <DashboardLayout>
      <TopBar tracking={tracking} />
      <div style={{ padding: '0 8rem' }}>
        <Tabs
          defaultActiveTabId={'all-in-one'}
          tabs={[
            {
              id: 'all-in-one',
              label: <>All-In-One</>,
              content: (
                <>
                  <div
                    style={{
                      height: topInfoHeight,
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'center',
                    }}
                  >
                    <Banner variant="light" style={{ position: 'relative', padding: '1.7rem' }}>
                      <Chip
                        style={{
                          position: 'absolute',
                          top: '-1.2rem',
                          left: 'calc(50% - 4rem)',
                        }}
                      >
                        SAVE 15%
                      </Chip>
                      <Text variant="home-card-header">Assessment and Reference Check in one Plan</Text>
                    </Banner>
                  </div>
                  <AllInOneTab
                    currency={currency}
                    period={period}
                    initialCompanySize={organization.fields.company_size}
                    tracking={(plan, companySize) =>
                      tracking.pricing.upgradeButtonClicked('All-In-One', plan, companySize)
                    }
                  />
                </>
              ),
            },
            {
              id: 'assessment',
              label: 'Assessment',
              content: (
                <>
                  <TogglePeriod
                    on={isMonthly}
                    setOn={on => {
                      setIsMonthly(on)
                      tracking.pricing.toggleBillingPeriodClicked()
                    }}
                    height={topInfoHeight}
                  />
                  <AssessmentTab
                    currency={currency}
                    period={period}
                    initialCompanySize={organization.fields.company_size}
                    tracking={(plan, companySize) =>
                      tracking.pricing.upgradeButtonClicked('Assessment', plan, companySize)
                    }
                  />
                </>
              ),
            },
            {
              id: 'refCheck',
              label: 'Reference Check',
              content: (
                <>
                  <div style={{ height: topInfoHeight }} />
                  <RefCheckTab
                    currency={currency}
                    period={period}
                    initialCompanySize={organization.fields.company_size}
                    tracking={(plan, companySize) =>
                      tracking.pricing.upgradeButtonClicked('Reference Check', plan, companySize)
                    }
                  />
                </>
              ),
            },
          ]}
        />
      </div>
    </DashboardLayout>
  )
}

type PricingTabProps = {
  currency: Currency
  period: 'monthly' | 'yearly'
  initialCompanySize: string
  tracking: (plan: string, companySize: string) => void
}

const AssessmentTab: React.FC<PricingTabProps> = props => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
        gap: '1rem',
      }}
    >
      <PricingCard
        plan={{
          name: 'Grow',
          prices: AssessmentGrowPrices[props.currency],
          description: 'For teams getting started with skills-based hiring.',
          features: {
            title: 'Everything from Free, plus',
            list: [
              'Unlimited assessments',
              'Unlimited users',
              'See correct responses',
              'ChatGPT secure',
              'Custom questions',
              'PDF exports',
            ],
          },
        }}
        {...props}
      />
      <PricingCard
        plan={{
          name: 'Scale',
          highlighted: true,
          prices: AssessmentScalePrices[props.currency],
          description: 'Lift assessments to a new level with integrations, more tests, custom branding.',
          features: {
            title: 'Everything from Grow, plus',
            list: ['ATS integration', 'Coding tests', 'Video questions', 'Custom branding', 'Custom questions'],
          },
        }}
        {...props}
      />
      <PricingCard
        plan={{
          name: 'Enterprise',
          prices: EnterprisePrices,
          description: 'For big organizations that need state-of-the-art enterprise functionalities',
          features: {
            title: 'Everything from Scale, plus',
            list: ['Analytics', 'API access', 'Dedicated CSM', 'Premium support', 'SLAs', 'SOC2 Type II report'],
          },
        }}
        {...props}
      />
    </div>
  )
}

const RefCheckTab: React.FC<PricingTabProps> = props => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
        gap: '1rem',
      }}
    >
      <PricingCard
        plan={{
          name: 'Grow',
          prices: RefCheckGrowPrices[props.currency],
          description: 'For teams taking first steps in automating recruiting.',
          features: {
            title: 'Everything from Free, plus',
            list: [
              'Unlimited candidates',
              'Unlimited references',
              'Fraud detection system',
              'PDF exports',
              'Survey branding',
            ],
          },
        }}
        {...props}
      />
      <PricingCard
        plan={{
          name: 'Scale',
          highlighted: true,
          prices: RefCheckScalePrices[props.currency],
          description: 'Lift your reference checking to the next level.',
          features: {
            title: 'Everything from Grow, plus',
            list: [
              'ATS integration',
              'Talent pool access',
              'Title verification',
              '360° references',
              'Onboarding insights',
            ],
          },
        }}
        {...props}
      />
      <PricingCard
        plan={{
          name: 'Enterprise',
          prices: EnterprisePrices,
          description: 'For businesses in need of state-of-the-art enterprise functionalities.',
          features: {
            title: 'Everything from Scale, plus',
            list: ['Analytics', 'API access', 'Dedicated CSM', 'Premium support', 'SLAs', 'SOC2 Type II report'],
          },
        }}
        {...props}
      />
    </div>
  )
}

const AllInOneTab: React.FC<PricingTabProps> = props => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
        gap: '1rem',
      }}
    >
      <PricingCard
        plan={{
          name: 'Grow',
          prices: AllInOneGrowPrices[props.currency],
          description: 'For teams getting started with skills-based hiring.',
          features: {
            title: 'Everything from Free, plus',
            list: [
              'Unlimited assessments',
              'Unlimited users',
              'See correct responses',
              'ChatGPT secure',
              'Custom questions',
              'PDF exports',
            ],
          },
        }}
        {...props}
      />
      <PricingCard
        plan={{
          name: 'Scale',
          highlighted: true,
          prices: AllInOneScalePrices[props.currency],
          description: 'Lift assessments to a new level with integrations, more tests, custom branding.',
          features: {
            title: 'Everything from Grow, plus',
            list: ['ATS integration', 'Coding tests', 'Video questions', 'Custom branding', 'Custom questions'],
          },
        }}
        {...props}
      />
      <PricingCard
        plan={{
          name: 'Enterprise',
          prices: EnterprisePrices,
          description: 'For big organizations that need state-of-the-art enterprise functionalities',
          features: {
            title: 'Everything from Scale, plus',
            list: ['Analytics', 'API access', 'Dedicated CSM', 'Premium support', 'SLAs', 'SOC2 Type II report'],
          },
        }}
        {...props}
      />
    </div>
  )
}

export default UpgradePage
