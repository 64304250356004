import { SummaryContainer } from 'App/Candidate/Summary/SummaryContainer'
import { useStrengths } from 'providers/candidate-summaries'
import { useSelectedCandidate } from 'providers/candidates'
import { useModule } from 'providers/modules'
import React from 'react'
import { useParams } from 'react-router-dom'
import { STRENGTHS_QUESTION } from 'store/new-role/content'
import {
  AttributeBreakdownBlock,
  AttributeBreakdownBlockProps,
  sortBreakdowns,
  sortReferences,
} from './AttributeBreakdownBlock'

interface StrengthsBlockUIProps {
  attributes: AttributeBreakdownBlockProps['attributes']
  numberOfReferences: AttributeBreakdownBlockProps['numberOfReferences']
  detailsUrl?: string
}

const StrengthsBlockUI: React.FC<StrengthsBlockUIProps> = ({ attributes, numberOfReferences, detailsUrl }) => {
  return (
    <SummaryContainer emoji={'💪'} title={'Strengths'} hintColor={'blue'} to={detailsUrl}>
      <AttributeBreakdownBlock
        title={'Most mentioned areas'}
        attributes={attributes}
        numberOfReferences={numberOfReferences}
      />
    </SummaryContainer>
  )
}

StrengthsBlockUI.displayName = 'StrengthsBlock'

interface StrengthsBlockProps {
  container?: React.FC
}

const MAX_NUMBER_OF_ATTRIBUTES = 5

const StrengthsBlock: React.FC<StrengthsBlockProps> = ({ container }) => {
  const Container = container || React.Fragment

  const { candidate } = useSelectedCandidate()
  const { isLoading, data } = useStrengths({ candidateId: candidate?.id })

  const module = useModule({ slug: 'strengths' })
  const targetId = candidate?.fields.questionnaire_id ? module?.id : STRENGTHS_QUESTION.test_item_id
  const { roleid, candidateid } = useParams<{
    roleid: string
    candidateid: string
  }>()
  const detailsUrl = `/roles/${roleid}/candidates/${candidateid}/responses#${targetId}`

  if (isLoading || !data || data.attributes.length <= 0 || data.numberOfReferences <= 0) {
    return null
  }

  const { attributes, numberOfReferences } = data

  const sortedAttributes = attributes
    // reverse sorting by the most picked attribute
    .sort((attribute1, attribute2) => sortBreakdowns(attribute1.pickedBy, attribute2.pickedBy))
    // limiting the number of attributes shown
    .slice(0, MAX_NUMBER_OF_ATTRIBUTES)
    // grouping relationship types together
    .map(attribute => ({
      ...attribute,
      pickedBy: attribute.pickedBy.sort(sortReferences),
    }))

  return (
    <Container>
      <StrengthsBlockUI attributes={sortedAttributes} numberOfReferences={numberOfReferences} detailsUrl={detailsUrl} />
    </Container>
  )
}

export default StrengthsBlock
