import { Tooltip } from '@common/components'
import Benchmark from 'components/Benchmark'
import BenchmarkTitle from 'components/Benchmark/Title'
import { px2rem, space, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import { useSelectedCandidate } from 'providers/candidates'
import { useReferencesExceptSelf } from 'providers/references'
import { useOverallPerformanceQuestions, useSkillQuestions } from 'providers/responses'
import React from 'react'
import { HeatmapInformation } from 'selectors/candidate-summaries'
import { relationshipOf } from 'selectors/references'
import ReferenceLabel from './ReferenceLabel'
import Score from './Score'
import SkillLabel from './SkillLabel'

interface Props {
  candidateId: string
}

// this represents each individual line the heatmap, it requires an interesting
// selector, to specifc the margins for the last two columns which have 12px
// padding where the others have 2px
const HeatmapRow = style()
  .grid({ columns: `${px2rem(164)} 1fr minmax(230px, 1fr)` })
  .selectChild(3, style().spacing({ outerLeft: px2rem(10) }))
  .spacing({ columns: px2rem(2) })
  .size({ height: px2rem(36) })
  .element()

const HeatmapScores = style()
  .with((props: { count: number }) =>
    style().grid({
      columns: `repeat(${props.count}, 1fr)}`,
    }),
  )
  .spacing({ columns: px2rem(2) })
  .element()

const Container = style().grid().spacing({ rows: space.s }).element()

const Skills = style()
  .grid()
  .spacing({ rows: px2rem(2) })
  .element()

const Emoji = style()
  .flex({ alignItems: 'center', justifyContent: 'center' })
  .with(({ bgColor }: { bgColor: string }) => style().color({ bg: bgColor }))
  .size({ width: px2rem(42), height: px2rem(42) })
  .border({
    radius: px2rem(6),
  })
  .element()

const TitleContainer = style()
  .flex()
  .spacing({ gap: px2rem(12) })
  .element()

const Title = text
  .bodyEmphasis()
  .color({ fg: designSystemColors.typographyPrimary })
  .spacing({ outer: [space.auto, space.none] })
  .element('h3')

const Heatmap: React.FC<Props> = ({ candidateId }) => {
  const { references } = useReferencesExceptSelf({ candidateId })
  const { isLoading } = useSelectedCandidate()
  const { skills } = useSkillQuestions({ candidateId })
  const { overallPerformances } = useOverallPerformanceQuestions({
    candidateId,
  })

  const noAverage = noAverageAvailable({ ...overallPerformances, ...skills })

  if (isLoading) return <></>

  return (
    <Container>
      <HeatmapRow>
        <TitleContainer>
          <Emoji bgColor={designSystemColors.informationPurpleBackground}>🛠</Emoji>
          <Title>Skills</Title>
        </TitleContainer>
        <HeatmapScores count={references.length}>
          {references.map(ref => (
            <ReferenceLabel key={ref.id} name={ref.fields.full_name} relationship={relationshipOf(ref)} />
          ))}
        </HeatmapScores>
        <BenchmarkTitle />
      </HeatmapRow>
      {overallPerformances &&
        Object.keys(overallPerformances).map(contentId => (
          <Skills key={contentId}>
            <HeatmapRow>
              <SkillLabel label={'Overall Performance'} contentId={contentId} />
              <HeatmapScores count={references.length}>
                {references.map(ref => {
                  return (
                    <Tooltip
                      key={ref.id}
                      text={
                        <div style={{ whiteSpace: 'nowrap' }}>
                          {overallPerformances[contentId]?.responses[ref.id]?.caption}
                          <br />
                          <a
                            href={
                              'https://intercom.help/hipeople/en/articles/8825544-reference-check-overall-performance-rating'
                            }
                            target={'_blank'}
                            style={{ color: 'white' }}
                            rel="noreferrer"
                          >
                            What does this mean?
                          </a>
                        </div>
                      }
                    >
                      <Score value={overallPerformances[contentId]?.responses[ref.id]?.value || null} />
                    </Tooltip>
                  )
                })}
              </HeatmapScores>
              <Benchmark
                noAverage={noAverage}
                average={overallPerformances[contentId]?.orgAverage}
                overall={overallPerformances[contentId]?.average}
              />
            </HeatmapRow>
          </Skills>
        ))}
      <Skills>
        {Object.keys(skills).map(id => (
          <HeatmapRow key={id}>
            <SkillLabel label={skills[id].label} contentId={id} />
            <HeatmapScores count={references.length}>
              {references.map(ref => {
                return <Score key={ref.id} value={skills[id]?.responses[ref.id]?.value || null} />
              })}
            </HeatmapScores>
            <Benchmark noAverage={noAverage} average={skills[id]?.orgAverage} overall={skills[id]?.average} />
          </HeatmapRow>
        ))}
      </Skills>
    </Container>
  )
}

export default Heatmap

function noAverageAvailable(info: HeatmapInformation): boolean {
  for (const item of Object.keys(info)) {
    if (info[item].orgAverage) {
      return false
    }
  }

  return true
}
