import React from 'react'
import { breakpoints, size, space, style } from '../../core'

const Container = style().relative().size({ height: size.stretch }).element()

const Content = style()
  .absolute({ top: space.none, left: space.none })
  .size({ width: size.fill, height: size.fill })
  .scroll({ y: 'auto' })
  .spacing({ inner: [space.m, space.m] })
  .cond(
    (params: Params) => !!params.standalone,
    style()
      .spacing({ inner: [space.xs, space.l] })
      .screen(breakpoints.mobile, style().spacing({ inner: [space.xs, space.l, space.xs, space.s] })),
  )
  .element()

interface Params {
  standalone?: boolean
}

interface Props extends Params {
  children: React.ReactNode
}

const Component = (props: Props) => {
  return (
    <Container>
      <Content {...props}>{props.children}</Content>
    </Container>
  )
}

export default Component
Component.displayName = 'Content'
