import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as api from '../../api'
import { ReportType } from '../../api/reports'
import { AppThunk } from '../../core/store'
import { add as notify } from '../notifications'

const name = 'reports'

interface State {
  report: api.reports.Fields
  assessmentStats: api.reports.Fields
  referenceStats: api.reports.Fields
  qohStats: api.reports.Fields
  updatedAt: number
}

const initialState: State = {
  report: {
    total_candidates: 0,
    total_references: 0,
    total_verified_references: 0,
    total_unverified_references: 0,
    total_completed_references: 0,
    total_completed_candidates: 0,
    total_marked_as_completed_candidates: 0,
    total_expired_candidates: 0,
    total_roles: 0,
    total_references_peers: 0,
    total_references_managers: 0,
    total_references_reports: 0,
    total_references_student_peers: 0,
    total_references_selfs: 0,
    total_references_anys: 0,
    total_references_clients: 0,
    average_time_candidate_complete_hours: 0,
    managers_evaluation_score: 0,
    new_hire_experience_score: 0,
    overall_score: 0,
  },
  assessmentStats: {
    total_candidates: 0,
    total_references: 0,
    total_verified_references: 0,
    total_unverified_references: 0,
    total_completed_references: 0,
    total_completed_candidates: 0,
    total_marked_as_completed_candidates: 0,
    total_expired_candidates: 0,
    total_roles: 0,
    total_references_peers: 0,
    total_references_managers: 0,
    total_references_reports: 0,
    total_references_student_peers: 0,
    total_references_anys: 0,
    total_references_selfs: 0,
    total_references_clients: 0,
    average_time_candidate_complete_hours: 0,
    managers_evaluation_score: 0,
    new_hire_experience_score: 0,
    overall_score: 0,
  },
  referenceStats: {
    total_candidates: 0,
    total_references: 0,
    total_verified_references: 0,
    total_unverified_references: 0,
    total_completed_references: 0,
    total_completed_candidates: 0,
    total_marked_as_completed_candidates: 0,
    total_expired_candidates: 0,
    total_roles: 0,
    total_references_peers: 0,
    total_references_managers: 0,
    total_references_reports: 0,
    total_references_student_peers: 0,
    total_references_selfs: 0,
    total_references_anys: 0,
    total_references_clients: 0,
    average_time_candidate_complete_hours: 0,
    managers_evaluation_score: 0,
    new_hire_experience_score: 0,
    overall_score: 0,
  },
  updatedAt: 0,
  qohStats: {
    total_candidates: 0,
    total_references: 0,
    total_verified_references: 0,
    total_unverified_references: 0,
    total_completed_references: 0,
    total_completed_candidates: 0,
    total_marked_as_completed_candidates: 0,
    total_expired_candidates: 0,
    total_roles: 0,
    total_references_peers: 0,
    total_references_managers: 0,
    total_references_reports: 0,
    total_references_anys: 0,
    total_references_student_peers: 0,
    total_references_selfs: 0,
    total_references_clients: 0,
    average_time_candidate_complete_hours: 0,
    managers_evaluation_score: 0,
    new_hire_experience_score: 0,
    overall_score: 0,
  },
}

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setReport(state, action: PayloadAction<api.reports.Fields>) {
      state.report = action.payload
      state.updatedAt = Date.now()
    },
    setAssessmentStats(state, action: PayloadAction<api.reports.Fields>) {
      state.assessmentStats = action.payload
    },
    setReferenceStats(state, action: PayloadAction<api.reports.Fields>) {
      state.referenceStats = action.payload
    },
    setQOHStats(state, action: PayloadAction<api.reports.Fields>) {
      state.qohStats = action.payload
    },
  },
})

export const { setReport, setAssessmentStats, setReferenceStats, setQOHStats } = slice.actions

export default slice.reducer

export const load = (): AppThunk => async dispatch => {
  const [response, errors] = await api.reports.load()

  if (errors) {
    errors.forEach(e => {
      dispatch(notify({ error: e.message }))
    })
    return
  }

  if (response?.meta) {
    dispatch(setReport(response.meta))
  }
}

export const loadByProduct =
  (reportType: ReportType): AppThunk =>
  async dispatch => {
    const [response, errors] = await api.reports.loadByProduct(reportType)

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    if (response?.meta) {
      if (reportType === 'assessment') {
        dispatch(setAssessmentStats(response.meta))
      }
      if (reportType === 'reference_check') {
        dispatch(setReferenceStats(response.meta))
      }
      if (reportType === 'quality_of_hire') {
        dispatch(setQOHStats(response.meta))
      }
    }
  }
