import { Tooltip } from '@common/components'
import { px, px2rem, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React from 'react'
import { FeedbackStyle, FeedbackStyleType } from 'selectors/halo-score'

const Chip = text
  .label()
  .inlineBlock()
  .size({ width: px2rem(26) })
  .round(px(12))
  .text({ align: 'center' })
  .color({ bg: '#E5E5E5', fg: '#6F6F6F' })
  .set('cursor', 'default')
  .element('span')
const ChipGreen = style(Chip).color({ bg: '#CDE4D8', fg: designSystemColors.uiStatusSuccess }).element('span')

interface Props {
  style: FeedbackStyleType
}

const Component: React.FC<Props> = ({ style }) => {
  let chipTooltip = ''
  let chipContent = ''

  if (style === FeedbackStyle.Critical) {
    chipContent = '--'
    chipTooltip = 'Feedback style: Critical'
  } else if (style === FeedbackStyle.Balanced) {
    chipContent = '±'
    chipTooltip = 'Feedback style: Balanced'
  } else if (style === FeedbackStyle.Praising) {
    chipContent = '++'
    chipTooltip = 'Feedback style: Praising'
  }

  const ChipComponent =
    style === FeedbackStyle.Praising ? (
      <ChipGreen data-tip={chipTooltip}>{chipContent}</ChipGreen>
    ) : (
      <Chip data-tip={chipTooltip}>{chipContent}</Chip>
    )

  return (
    <>
      <Tooltip text={chipTooltip} direction="right">
        {ChipComponent}
      </Tooltip>
    </>
  )
}

export default Component
Component.displayName = 'FeedbackStyleBadge'
