import React from 'react'
import { scale, style, weight } from '../../../core'
import { BigNumber } from '../../../selectors/reports'

const Container = style().element()

const Number = style()
  .sans({
    size: scale.l,
    weight: weight.bold,
  })
  .element()

const Label = style()
  .sans({
    size: scale.s,
    weight: weight.normal,
  })
  .element()

interface Props {
  content: BigNumber
}

const Component = (props: Props) => {
  return (
    <Container>
      <Number>{props.content.value}</Number>
      {props.content.label ? <Label>{props.content.label}</Label> : null}
    </Container>
  )
}

export default Component
Component.displayName = 'BigNumber'
