import React from 'react'
import * as RadixTooltip from '@radix-ui/react-tooltip'
import { Text } from '../Text/Text'
import './Tooltip.scss'

interface TooltipProps {
  children: React.ReactNode
  direction?: 'top' | 'bottom' | 'left' | 'right'
  text: React.ReactNode
  disabled?: boolean
  className?: string
  style?: React.CSSProperties
  open?: boolean
}

/**
 * Tooltip component using Radix Primitives
 * example:
 * <Tooltip text="Tooltip text" direction="top">
 *   <div>Hover me</div>
 * </Tooltip>
 */
export const Tooltip = ({ direction: dir = 'top', ...props }: TooltipProps) => {
  if (
    props.text === undefined ||
    props.text === '' ||
    props.disabled ||
    props.children === undefined ||
    props.children === null
  ) {
    return <>{props.children}</>
  }

  return (
    <RadixTooltip.Provider>
      <RadixTooltip.Root delayDuration={0} open={props.open}>
        <RadixTooltip.Trigger asChild>
          {props.style || props.className ? (
            <span style={props.style} className={props.className}>
              {props.children}
            </span>
          ) : (
            <span>{props.children}</span>
          )}
        </RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <RadixTooltip.Content side={dir} className={['tooltipContent', dir].join(' ')} sideOffset={5}>
            <Text variant="small-body" className="tooltipText">
              {props.text}
            </Text>
            <RadixTooltip.Arrow className="tooltipArrow" />
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  )
}
