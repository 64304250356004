import { get } from './methods'
import { APIResponse, Entity } from './request'

export const RESOURCE = 'generic_assessments'

interface Fields {
  id: string
  title: string
  description: string
  assessment_version_id: string
  created_at: number
  updated_at: number
}

export async function load({
  keyword,
  skip,
}: {
  keyword: string
  skip?: number
}): Promise<APIResponse<Entity<Fields>[]>> {
  const qs = [
    'include=assessment_versions',
    keyword.length > 0 && keyword.length < 4 ? `title[starts_with]=${keyword}` : '',
    keyword.length > 3 ? `title[includes]=${keyword}` : '',
    skip !== undefined ? `skip=${skip}` : '',
  ].filter(r => r.length)

  const [resp, errors] = await get<Entity<Fields>[]>(`genericassessments?&` + qs.join('&'))

  if (errors) {
    return [resp, errors]
  }

  if (resp) {
    return [resp, errors]
  }

  return [resp, errors]
}
