import { UpdateProfileDetails } from 'api/candidate-profiles'
import Modal from 'components/Modal'
import TextField from 'components/TextField'
import { Content, ContentAndButtons, Form } from 'components/Wizard'
import Buttons from 'components/Wizard/Buttons'
import { rem, style } from 'core'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateCandidateProfile } from 'store/candidate-management'

export type EditableCandidate = {
  id: string
  fullName: string
  email: string
}

interface Props {
  open: boolean
  setOpen: (b: boolean) => void
  candidate: EditableCandidate
}

const Container = style()
  .size({
    maxWidth: rem(40),
    maxHeight: rem(25),
    width: 'calc(100vw - 200px)',
    height: rem(20),
  })
  .element()

const EditCandidateModal: React.FC<Props> = ({ open, setOpen, candidate }) => {
  const dispatch = useDispatch()
  const [updates, setUpdates] = useState<UpdateProfileDetails>({})

  const name = updates.full_name === undefined ? candidate.fullName : updates.full_name
  const email = updates.email === undefined ? candidate.email : updates.email

  return (
    <Modal title={"Update candidate's details"} open={open} setOpen={setOpen}>
      <Container>
        <ContentAndButtons>
          <Content standalone>
            <Form>
              <TextField
                label="Full Name:"
                value={name}
                placeholder="Enter a full name"
                onChange={ev => {
                  const newFullName = ev.target.value
                  setUpdates(updates => ({
                    ...updates,
                    full_name: newFullName,
                  }))
                }}
              />
              <TextField
                label="Email:"
                value={email}
                placeholder="Enter an email"
                onChange={ev => {
                  const newEmail = ev.target.value
                  setUpdates(updates => ({
                    ...updates,
                    email: newEmail,
                  }))
                }}
              />
            </Form>
          </Content>
          <Buttons
            next={async () => {
              try {
                await dispatch(
                  updateCandidateProfile(candidate.id, {
                    updates,
                    updated: Object.keys(updates),
                  }),
                )
                setOpen(false)
              } catch (error) {
                // TODO: handle error
              }
            }}
            back={() => {}}
            disabled={name.length === 0 || email.length === 0}
            label={'Update'}
            forwardOnly
          ></Buttons>
        </ContentAndButtons>
      </Container>
    </Modal>
  )
}

export default EditCandidateModal
