import * as selectors from '../../selectors'
import { RootState } from '../../store'
import action from './action'
import events from './events'
import track from './track'

interface LoginProps {
  isSAMLSSO?: boolean
  isGoogleSSO?: boolean
}

export const login = action((props: LoginProps) => {
  track(events.USER_LOGIN, {
    'Is SAMLSSO': !!props.isSAMLSSO,
    'Is GoogleSSO': !!props.isGoogleSSO,
  })
})

export const help = action(() => {
  track(events.TRIPLE_DOT_HELP, {})
})

export interface Props {
  userId?: string
  email: string
  organization?: string
  organization_plan: string

  is_org_admin: boolean
  is_super_user: boolean

  can_create_role?: boolean
  can_manage_content?: boolean
  can_provide_support?: boolean
}

export function extractPropsFromState(state: RootState): Props | null {
  const user = selectors.users.getById(state, state.sessions.userId)
  if (!user) return null

  const org = selectors.orgs.findByUserId(state, state.sessions.userId)
  if (!org) return null

  const userPermissions = selectors.userPermissions.getByUserId(state, state.sessions.userId)

  return {
    userId: user.id,
    email: user.fields.email,
    organization: org.fields.name,
    organization_plan: org.fields.plan,

    is_org_admin: user.fields.is_org_admin,
    is_super_user: user.fields.is_super_user,

    can_create_role: userPermissions?.fields.can_create_role,
    can_manage_content: userPermissions?.fields.can_manage_content,
    can_provide_support: userPermissions?.fields.can_provide_support,
  }
}

export const requestNewVerificationEmail = action((id, email: string) => {
  track(events.FREE_TRIAL_USER_REQUESTED_NEW_VERIFICATION_EMAIL, {
    Id: id,
    Email: email,
  })
})

export const choosePlanToContinue = action((id, email: string) => {
  track(events.CHOOSE_PLAN_BANNER_CLICKED, {
    Id: id,
    Email: email,
  })
})

export const choosePlanTrialExpiredClicked = action((id, email: string) => {
  track(events.CHOOSE_PLAN_TRIAL_EXPIRED_CLICKED, {
    Id: id,
    Email: email,
  })
})

export const talkToProductAdvisorClicked = action((id, email: string) => {
  track(events.TALK_TO_PRODUCT_ADVISOR_CLICKED, {
    Id: id,
    Email: email,
  })
})

export const choosePlanPDFExport = action((id, email: string) => {
  track(events.CHOOSE_PLAN_PDF_EXPORT, {
    Id: id,
    Email: email,
  })
})
