import DropdownField, { Option } from 'components/DropdownField'
import { Icon } from 'components/Icons'
import TextField from 'components/TextField'
import ToggleField from 'components/ToggleField'
import { TooltipContent } from 'components/Tooltip'
import { fr, px2rem, size, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import { lockedContentTracking } from 'core/track/locked-content'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as selectors from 'selectors'
import { Relationship } from 'selectors/references'
import { Button, LockedByPlan, LockIcon, Modal, MultiSelectLanguageDropdown } from '@common/components'
import { AvailableReferenceCheckLocales, ReferenceCheckLocale } from '@common/models/locales'
import classes from './ReferenceFeedbackDetailsModal.module.scss'
const TimingSectionTitle = text
  .bodyInteractive()
  .spacing({ outerBottom: px2rem(10) })
  .element('h3')
const TimingSectionBlock = style()
  .grid({ columns: [px2rem(72), size.auto], align: 'center' })
  .spacing({ gap: px2rem(16) })
  .element()
const TimingSectionLabel = text.bodyText().element('span')
const TimingSection = style().element()
const TimingSections = style()
  .grid({ columns: [fr(1), fr(1)], align: 'center' })
  .spacing({ gap: px2rem(24), outerBottom: px2rem(48) })
  .element()

const RequiredReferencesTitle = text
  .bodyEmphasis()
  .spacing({ outerBottom: px2rem(12) })
  .element('h3')
const RequiredReferencesDescription = text
  .bodyText()
  .spacing({ outerBottom: px2rem(24) })
  .element('p')

const ReferenceCheckLocalesTitle = text
  .bodyEmphasis()
  .spacing({ outerTop: px2rem(12), outerBottom: px2rem(12) })
  .element('h3')
const ReferenceCheckLocalesDescription = text
  .bodyText()
  .spacing({ outerBottom: px2rem(24) })
  .element('p')

const RequiredReferencesEntryTable = style().element()
const RequiredReferencesEntryTableHeader = style()
  .grid({ columns: [px2rem(28), fr(1), fr(1), px2rem(16)] })
  .spacing({ gap: px2rem(12), outerBottom: px2rem(10) })
  .element()

const Hint = style()
  .inlineBlock()
  .relative()
  .select(
    `${TooltipContent.Style}`,
    style().absolute({ top: 0, left: '50%' }).invisible().set('transform', 'translateX(-50%) translateY(-100%)'),
  )
  .select(`:hover ${TooltipContent.Style}`, style().visible())
  .element()
const TooltipContentStyled = text
  .smallBody()
  .color({ fg: designSystemColors.backgroundNeutralPrimary })
  .block()
  .sans({ align: 'center' })
  .spacing({ inner: [px2rem(2), px2rem(4)] })
  .size({ width: px2rem(192) })
  .element('p')
const RequiredReferencesEntryTableHeaderLabel = text.bodyInteractive().block().element('span')
const RequiredReferencesEntryRow = style()
  .grid({
    columns: [px2rem(28), fr(1), fr(1), px2rem(16)],
    align: 'center',
  })
  .spacing({ gap: px2rem(12), outerBottom: px2rem(18) })
  .element()
const RequiredReferencesEntryOrder = text.bodyText().block().element('span')
const RequiredReferencesEntryName = style().element()
const RequiredReferencesEntryPermission = style().element()
const RequiredReferencesEntryActions = style().element()
const RequiredReferencesEntryDeleteButton = style()
  .noborders()
  .block()
  .bg({ color: designSystemColors.transparent })
  .pointer()
  .element('button')

const SelfReferenceToggleFieldContainer = style()
  .spacing({ outerTop: px2rem(32) })
  .element()

const referenceTypes: { label: string; value: RequiredReferenceType }[] = [
  { label: '💼 Manager', value: Relationship.Manager },
  { label: '🤲 Peer', value: Relationship.Peer },
  { label: '🌱 Direct Report', value: Relationship.Report },
  { label: '🎓 Student Peer', value: Relationship.StudentPeer },
  { label: '👔 Client', value: Relationship.Client },
  { label: '💭 Any Relationship', value: Relationship.Any },
]

const timeRangeInDays = Array(10)
  .fill(0)
  .map((_, i) => ({ value: i + 1 }))

type RequiredReferenceType = Relationship

type RequiredReferenceEntry = {
  id?: string
  type?: RequiredReferenceType
  alias?: string
}

export type ReferenceFeedbackDetailsValues = {
  requiredReferences: RequiredReferenceEntry[]
  timeForCandidatesToProvideReferences: number
  timeForReferencesToSubmit: number
  includeSelfReference: boolean
  includeJobTitleVerification: boolean
  referenceCheckLocales: ReferenceCheckLocale[]
}

type OnReferenceFeedbackDetailsSubmit = (values: ReferenceFeedbackDetailsValues) => any

export interface ReferenceFeedbackDetailsModalProps {
  back?: () => void
  initialValues?: Partial<ReferenceFeedbackDetailsValues>
  onSubmit: OnReferenceFeedbackDetailsSubmit
  minRequiredReferenceCount: number
  quickFlowButtons?: boolean
  noFooter?: boolean
  setFooter?: (footer: React.ReactNode) => void
}

export const ReferenceCheckDetails: React.FC<ReferenceFeedbackDetailsModalProps> = props => {
  const [timeForCandidatesToProvideReferences, setTimeForCandidatesToProvideReferences] = useState(
    props.initialValues?.timeForCandidatesToProvideReferences ?? 5 /* days */,
  )

  const [timeForReferencesToSubmit, setTimeForReferencesToSubmit] = useState(
    props.initialValues?.timeForReferencesToSubmit ?? 5 /* days */,
  )

  const isOrgPaidPlan = useSelector(selectors.orgs.isOrgPaidPlan)

  const [requiredReferences, setRequiredReferences] = useState<RequiredReferenceEntry[]>(() => {
    if (props.initialValues?.requiredReferences?.length) {
      return props.initialValues?.requiredReferences
    }
    return [{ type: Relationship.Manager }, { type: Relationship.Peer }, { type: Relationship.Peer }]
  })

  const [includeSelfReference, setIncludeSelfReference] = useState(props.initialValues?.includeSelfReference ?? false)

  const [referenceCheckLocales, setReferenceCheckLocales] = useState<ReferenceCheckLocale[]>(
    props.initialValues?.referenceCheckLocales ?? ['en_US'],
  )
  const [includeJobTitleVerification, setIncludeJobTitleVerification] = useState(
    props.initialValues?.includeJobTitleVerification ?? false,
  )

  const isConfirmButtonDisabled = requiredReferences.some(({ type }) => !type)

  const [placeholders, setPlaceholder] = useState<string[]>([])

  function generateRandomPlaceholder() {
    const values: string[] = [
      'Current manager',
      'VP Product @ Google',
      'Staff nurse @ John Hopkins',
      'Someone from your current team',
      'Last direct report',
    ]

    const randomIndex: number = Math.floor(Math.random() * values.length)
    setPlaceholder(prevState => [...prevState, values[randomIndex]])
  }

  function removePlaceholder(i) {
    setPlaceholder(placeholders.filter((_r, itemIndex) => itemIndex !== i))
  }

  useEffect(() => {
    requiredReferences.forEach(() => {
      if (requiredReferences.length > placeholders.length) {
        generateRandomPlaceholder()
      }
    })
  }, [])

  useEffect(() => {
    if (props.setFooter) {
      props.setFooter(
        <ModalFooter
          {...props}
          requiredReferences={requiredReferences}
          timeForCandidatesToProvideReferences={timeForCandidatesToProvideReferences}
          timeForReferencesToSubmit={timeForReferencesToSubmit}
          includeSelfReference={includeSelfReference}
          includeJobTitleVerification={includeJobTitleVerification}
          referenceCheckLocales={referenceCheckLocales}
          isConfirmButtonDisabled={isConfirmButtonDisabled}
        />,
      )
    }
  }, [
    requiredReferences,
    timeForCandidatesToProvideReferences,
    timeForReferencesToSubmit,
    includeSelfReference,
    includeJobTitleVerification,
    referenceCheckLocales,
    isConfirmButtonDisabled,
  ])

  return (
    <>
      <TimingSections>
        <TimingSection>
          <TimingSectionTitle>{'Time for candidates to provide references'}</TimingSectionTitle>
          <TimingSectionBlock>
            <DropdownField
              value={timeForCandidatesToProvideReferences}
              onChange={setTimeForCandidatesToProvideReferences}
              testId="reference-check-time-for-candidates-to-provide-references-dropdown"
            >
              {timeRangeInDays.map(({ value }) => (
                <Option key={`time-range-${value}`} value={value}>
                  {value}
                </Option>
              ))}
            </DropdownField>
            <TimingSectionLabel>
              {'Business days '}
              <Hint>
                <TooltipContent arrowPosition="bottom">
                  <TooltipContentStyled>
                    {'Candidates will see this time frame displayed in regular calendar days.'}
                  </TooltipContentStyled>
                </TooltipContent>
                <Icon name="question-circle" />
              </Hint>
            </TimingSectionLabel>
          </TimingSectionBlock>
        </TimingSection>
        <TimingSection>
          <TimingSectionTitle>{'Time for references to submit feedback'}</TimingSectionTitle>
          <TimingSectionBlock>
            <DropdownField
              value={timeForReferencesToSubmit}
              onChange={setTimeForReferencesToSubmit}
              testId="reference-check-time-for-references-to-submit-dropdown"
            >
              {timeRangeInDays.map(({ value }) => (
                <Option key={`time-range-${value}`} value={value}>
                  {value}
                </Option>
              ))}
            </DropdownField>
            <TimingSectionLabel>
              {'Business days '}
              <Hint>
                <TooltipContent arrowPosition="bottom">
                  <TooltipContentStyled>
                    {'References will see this time frame displayed in regular calendar days.'}
                  </TooltipContentStyled>
                </TooltipContent>
                <Icon name="question-circle" />
              </Hint>
            </TimingSectionLabel>
          </TimingSectionBlock>
        </TimingSection>
      </TimingSections>
      <RequiredReferencesTitle>{'Required references'}</RequiredReferencesTitle>
      <RequiredReferencesDescription>
        {
          'Specify the number and type of references to collect. You can use the “Target reference” field to further specify the reference you want the candidate to submit.'
        }
      </RequiredReferencesDescription>
      <RequiredReferencesEntryTable>
        <RequiredReferencesEntryTableHeader>
          <RequiredReferencesEntryOrder></RequiredReferencesEntryOrder>
          <RequiredReferencesEntryName>
            <RequiredReferencesEntryTableHeaderLabel>{'Reference type'}</RequiredReferencesEntryTableHeaderLabel>
          </RequiredReferencesEntryName>
          <RequiredReferencesEntryPermission>
            <RequiredReferencesEntryTableHeaderLabel>
              {'Target reference (optional) '}
              <Hint>
                <TooltipContent arrowPosition="bottom">
                  <TooltipContentStyled>
                    {'Specify a role or title, such as "Former Team Lead", for highly targeted reference checks.'}
                  </TooltipContentStyled>
                </TooltipContent>
                <Icon name="question-circle" />
              </Hint>
            </RequiredReferencesEntryTableHeaderLabel>
          </RequiredReferencesEntryPermission>
          <RequiredReferencesEntryActions></RequiredReferencesEntryActions>
        </RequiredReferencesEntryTableHeader>
        {requiredReferences.map(({ id, type, alias }, i) => (
          <RequiredReferencesEntryRow key={`required-reference-${i}`}>
            <RequiredReferencesEntryOrder>{`${i + 1}.`}</RequiredReferencesEntryOrder>
            <RequiredReferencesEntryName>
              <DropdownField
                label=""
                placeholder="Select reference type"
                value={type}
                onChange={selectedType => {
                  setRequiredReferences(requiredReferences => {
                    const newRequiredReferences = [...requiredReferences]
                    newRequiredReferences[i] = { id, type: selectedType, alias }
                    return newRequiredReferences
                  })
                }}
                testId="reference-check-required-references-type-dropdown"
              >
                {referenceTypes.map(({ label, value }) => (
                  <Option key={label} value={value}>
                    {label}
                  </Option>
                ))}
              </DropdownField>
            </RequiredReferencesEntryName>
            <RequiredReferencesEntryPermission>
              <LockedByPlan
                tracking={lockedContentTracking}
                lockIconStyle={{ marginRight: '1rem' }}
                locked={!isOrgPaidPlan}
                upgradeTo={'Grow'}
                modalData={{
                  learnMoreLink: 'https://intercom.help/hipeople/en/articles/7193595-collect-specific-references',
                  learnMoreAbout: 'Setting Target References',
                }}
              >
                <TextField
                  placeholder={placeholders[i] || 'VP Product @ Google'}
                  value={alias || ''}
                  onChange={event => {
                    setRequiredReferences(requiredReferences => {
                      const newRequiredReferences = [...requiredReferences]
                      newRequiredReferences[i] = {
                        id,
                        type,
                        alias: event.target.value,
                      }
                      return newRequiredReferences
                    })
                  }}
                />
              </LockedByPlan>
            </RequiredReferencesEntryPermission>
            <RequiredReferencesEntryActions>
              {i >= props.minRequiredReferenceCount && (
                <RequiredReferencesEntryDeleteButton
                  onClick={() => {
                    removePlaceholder(i)
                    setRequiredReferences(requiredReferences =>
                      requiredReferences.filter((_r, itemIndex) => itemIndex !== i),
                    )
                  }}
                >
                  <Icon name="times" ariaLabel="Delete required reference" />
                </RequiredReferencesEntryDeleteButton>
              )}
            </RequiredReferencesEntryActions>
          </RequiredReferencesEntryRow>
        ))}
      </RequiredReferencesEntryTable>
      <Button
        variant="tertiary"
        onClick={() => {
          generateRandomPlaceholder()
          setRequiredReferences(requiredReferences => {
            return [...requiredReferences, {}]
          })
        }}
      >
        {'Click to add required reference'}
      </Button>
      <>
        <ReferenceCheckLocalesTitle>Reference check languages</ReferenceCheckLocalesTitle>
        <ReferenceCheckLocalesDescription>
          Selected languages will be available to choose from and be used by candidates throughout the reference check.
        </ReferenceCheckLocalesDescription>
        <MultiSelectLanguageDropdown
          locales={AvailableReferenceCheckLocales}
          defaultLocales={props.initialValues?.referenceCheckLocales ?? ['en_US']}
          onSelectLocales={setReferenceCheckLocales}
          placeholder="Select languages"
          testId="reference-check-reference-check-locales-dropdown"
        />
      </>
      <SelfReferenceToggleFieldContainer>
        <LockedByPlan
          tracking={lockedContentTracking}
          locked={!isOrgPaidPlan}
          upgradeTo={'Scale'}
          lockIconDisabled={true}
          modalData={{
            learnMoreLink: 'https://intercom.help/hipeople/en/articles/8915374-360-self-references',
            learnMoreAbout: '360 Self References',
          }}
        >
          <ToggleField
            label={'Ask the candidate to fill the questionnaire as self reference'}
            on={includeSelfReference}
            onChange={setIncludeSelfReference}
            icon={<LockIcon visible={!isOrgPaidPlan} />}
            testId="reference-check-include-self-reference-toggle"
          />
        </LockedByPlan>
      </SelfReferenceToggleFieldContainer>
      <SelfReferenceToggleFieldContainer>
        <LockedByPlan
          tracking={lockedContentTracking}
          locked={!isOrgPaidPlan}
          upgradeTo={'Scale'}
          lockIconDisabled={true}
          modalData={{
            learnMoreLink: 'https://intercom.help/hipeople/en/articles/8711779-reference-check-job-title-verification',
            learnMoreAbout: 'Job Title Verification',
          }}
        >
          <ToggleField
            label={'Activate candidate job title verification'}
            on={includeJobTitleVerification}
            onChange={setIncludeJobTitleVerification}
            icon={<LockIcon visible={!isOrgPaidPlan} />}
            testId="reference-check-include-job-title-verification-toggle"
          />
        </LockedByPlan>
      </SelfReferenceToggleFieldContainer>
      {!props.noFooter && (
        <ModalFooter
          {...props}
          requiredReferences={requiredReferences}
          timeForCandidatesToProvideReferences={timeForCandidatesToProvideReferences}
          timeForReferencesToSubmit={timeForReferencesToSubmit}
          includeSelfReference={includeSelfReference}
          includeJobTitleVerification={includeJobTitleVerification}
          referenceCheckLocales={referenceCheckLocales}
          isConfirmButtonDisabled={isConfirmButtonDisabled}
        />
      )}
    </>
  )
}

export const ReferenceCheckDetailsModal: React.FC<
  ReferenceFeedbackDetailsModalProps & { open: boolean; setOpen: (open: boolean) => void }
> = props => {
  const [footer, setFooter] = useState<React.ReactNode>(null)
  return (
    <Modal
      title={`Reference Check Details`}
      open={props.open}
      onClose={() => props.setOpen(false)}
      footer={footer}
      data-testid="reference-check-details-modal"
    >
      <ReferenceCheckDetails
        {...props}
        noFooter
        setFooter={footer => setFooter(footer)}
        back={() => props.setOpen(false)}
      />
    </Modal>
  )
}

interface ModalFooterProps extends ReferenceFeedbackDetailsModalProps {
  requiredReferences: RequiredReferenceEntry[]
  timeForCandidatesToProvideReferences: number
  timeForReferencesToSubmit: number
  includeSelfReference: boolean
  includeJobTitleVerification: boolean
  referenceCheckLocales: ReferenceCheckLocale[]
  isConfirmButtonDisabled: boolean
}
const ModalFooter = ({
  requiredReferences,
  timeForCandidatesToProvideReferences,
  timeForReferencesToSubmit,
  includeSelfReference,
  includeJobTitleVerification,
  referenceCheckLocales,
  isConfirmButtonDisabled,
  ...props
}: ModalFooterProps) => {
  return (
    <div className={classes.footer}>
      {props.quickFlowButtons ? (
        <Button
          variant="primary"
          onClick={() => {
            props.onSubmit({
              requiredReferences,
              timeForCandidatesToProvideReferences,
              timeForReferencesToSubmit,
              includeSelfReference,
              includeJobTitleVerification,
              referenceCheckLocales,
            })
          }}
          disabled={isConfirmButtonDisabled}
        >
          {'Next step'}
        </Button>
      ) : (
        <>
          <Button variant="tertiary" onClick={props.back}>
            {'Cancel'}
          </Button>
          <Button
            variant="purple-dark"
            onClick={() => {
              props.onSubmit({
                requiredReferences,
                timeForCandidatesToProvideReferences,
                timeForReferencesToSubmit,
                includeSelfReference,
                includeJobTitleVerification,
                referenceCheckLocales,
              })
            }}
            disabled={isConfirmButtonDisabled}
          >
            {'Confirm'}
          </Button>
        </>
      )}
    </div>
  )
}
