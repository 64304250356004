import { Column } from '../../../components/ContentGrid'
import { Skeleton } from '@common/components'
import { style } from '../../../core'
import { IconColumn } from './ManageTalentPool'

const TableCell = style()
  .box({
    width: '100%',
    minHeight: '100%',
  })
  .flex({
    alignItems: 'center',
  })
  .element()

export const TalentPoolLoadingRow = () => (
  <>
    <Column />
    <Column centerVert>
      <IconColumn>
        <Skeleton width={16} height={16} style={{ borderRadius: '50%' }} />
      </IconColumn>
    </Column>
    <Column centerVert>
      <TableCell>
        <Skeleton width={180} height={20} />
      </TableCell>
    </Column>
    <Column centerVert>
      <TableCell>
        <Skeleton width={150} height={20} />
      </TableCell>
    </Column>
    <Column centerVert>
      <TableCell>
        <Skeleton width={120} height={20} />
      </TableCell>
    </Column>
    <Column centerVert>
      <TableCell>
        <Skeleton width={160} height={20} />
      </TableCell>
    </Column>
    <Column centerVert>
      <TableCell>
        <Skeleton width={100} height={20} />
      </TableCell>
    </Column>
    <Column center>
      <TableCell>
        <Skeleton width={24} height={24} style={{ borderRadius: '50%' }} />
      </TableCell>
    </Column>
    <Column />
  </>
)
