import { QuestionnaireSubmissionEntity } from 'api/questionnaire-submissions'
import { Score } from 'components/TopicSummary'
import {
  AGREEABLENESS_SCORES,
  CONSCIENTIOUSNESS_SCORES,
  EXTRAVERSION_SCORES,
  OPENNESS_SCORES,
  RDS_AGREEABLENESS_SCORES,
  RDS_CONSCIENTIOUSNESS_SCORES,
  RDS_EXTRAVERSION_SCORES,
  RDS_OPENNESS_SCORES,
  RDS_STABILITY_SCORES,
  Scores,
  STABILITY_SCORES,
} from 'content/onboarding-stats'
import { RootState } from 'store'
import * as api from '../api'
import * as selectors from '../selectors'
import * as candidateProfiles from './candidate-profiles'
import * as candidates from './candidates'
import * as formResponses from './form-responses'
import * as questionnaireAnswers from './questionnaire-answers'
import { findByQuestionnaireSubmissionId } from './questionnaire-answers'
import { findByEmployeeId } from './questionnaire-submissions'
import { findById as findQuestionById } from './questions'
import * as references from './references'
import { findById as findResponseOptionById } from './response-options'

export type OceanScore = {
  agreeableness: number | null
  extraversion: number | null
  openness: number | null
  stability: number | null
  conscientiousness: number | null
}

type Point = {
  emoji: string
  copy: string
}

type Summary = { encourage: Point[]; warning: Point[] }

function findScoresByReferenceId(state: RootState, referenceId: string): OceanScore | null {
  const reference = references.findById(state, referenceId)
  if (!reference) return null

  const candidate = candidates.findById(state, reference.fields.candidate_id)
  if (!candidate) return null

  if (candidate.fields.questionnaire_id) return findRDSScoresByReferenceId(state, referenceId)

  const answers = formResponses.findAllAnswersByReferenceId(state, referenceId)
  if (!answers) return null

  const agreeableness = calculateCategoryScoreForReference(AGREEABLENESS_SCORES, answers)
  const extraversion = calculateCategoryScoreForReference(EXTRAVERSION_SCORES, answers)
  const openness = calculateCategoryScoreForReference(OPENNESS_SCORES, answers)
  const stability = calculateCategoryScoreForReference(STABILITY_SCORES, answers)
  const conscientiousness = calculateCategoryScoreForReference(CONSCIENTIOUSNESS_SCORES, answers)

  return {
    agreeableness,
    extraversion,
    openness,
    stability,
    conscientiousness,
  }
}

function findRDSScoresByReferenceId(state: RootState, referenceId: string): OceanScore | null {
  const reference = references.findById(state, referenceId)
  if (!reference) return null

  const candidate = candidates.findById(state, reference.fields.candidate_id)
  if (!candidate) return null

  const submission = selectors.questionnaireSubmissions.findByReferenceId(state, referenceId)
  if (!submission) return null

  const answers = selectors.questionnaireAnswers.findByQuestionnaireSubmissionId(state, submission.id)
  if (!answers) return null

  const agreeableness = calculateRDSCategoryScoreForReference(state, RDS_AGREEABLENESS_SCORES, answers)
  const extraversion = calculateRDSCategoryScoreForReference(state, RDS_EXTRAVERSION_SCORES, answers)
  const openness = calculateRDSCategoryScoreForReference(state, RDS_OPENNESS_SCORES, answers)
  const stability = calculateRDSCategoryScoreForReference(state, RDS_STABILITY_SCORES, answers)
  const conscientiousness = calculateRDSCategoryScoreForReference(state, RDS_CONSCIENTIOUSNESS_SCORES, answers)

  return {
    agreeableness,
    extraversion,
    openness,
    stability,
    conscientiousness,
  }
}

// This function is basically the "brain" for computing scores. it takes a
// given references score and a specific category to calculate it for.
// - it discards skipped questions
// - checks if the question is forced choice (if so use value of answer when generating the score)
// - if not, it counts the number of relevant selected options (based on stats from science team),
//   and then uses that as it's value
// - if a given reference has less than 3 relevant scores for a given category, the reference
//   is not used for that category
function calculateCategoryScoreForReference(scoreStats: Scores, answers: api.formresponses.Row[]): number | null {
  const calculatedScores: number[] = []
  for (const answer of answers) {
    const scores = scoreStats[answer.item_id]
    if (!scores || answer.skipped) continue

    if (scores.forcedChoice) {
      calculatedScores.push(
        calculateZScore(answer.value || 0, scores.stats.polarity, scores.stats.popSd, scores.stats.popMean),
      )
      continue
    }

    const relevant: string[] = []
    for (const selected of answer.selected || []) {
      if (scores.relevantAnswers.includes(selected)) {
        relevant.push(selected)
      }
    }

    calculatedScores.push(
      calculateZScore(relevant.length, scores.stats.polarity, scores.stats.popSd, scores.stats.popMean),
    )
  }

  if (calculatedScores.length < 3) {
    return null
  }

  let count = 0
  for (const score of calculatedScores) {
    count += score
  }

  return count / calculatedScores.length
}

function calculateRDSCategoryScoreForReference(
  state: RootState,
  scoreStats: Scores,
  answers: api.questionnaireAnswers.QuestionnaireAnswerEntity[],
): number | null {
  const calculatedScores: number[] = []

  const grouped: {
    [id: string]: api.questionnaireAnswers.QuestionnaireAnswerEntity[]
  } = {}
  for (const answer of answers) {
    if (grouped[answer.fields.question_id]) {
      grouped[answer.fields.question_id].push(answer)
      continue
    }

    grouped[answer.fields.question_id] = [answer]
  }

  for (const key of Object.keys(grouped)) {
    const answers = grouped[key]
    if (!answers?.[0] || answers[0].fields.is_skipped || !answers[0].fields.selected_response_option) continue

    const question = selectors.questions.findById(state, key)
    if (!question) continue

    const scores = scoreStats[question.fields.slug]
    if (!scores) continue

    if (scores.forcedChoice) {
      const responseOption = selectors.responseOptions.findById(state, answers?.[0].fields.selected_response_option)

      calculatedScores.push(
        calculateZScore(
          responseOption?.fields.value || 0,
          scores.stats.polarity,
          scores.stats.popSd,
          scores.stats.popMean,
        ),
      )
      continue
    }

    const relevant: string[] = []
    for (const answer of answers || []) {
      const responseOption = selectors.responseOptions.findById(state, answer.fields.selected_response_option)
      if (!responseOption) continue

      if (scores.relevantAnswers.includes(responseOption.fields.slug)) {
        relevant.push(responseOption.fields.slug)
      }
    }

    calculatedScores.push(
      calculateZScore(relevant.length, scores.stats.polarity, scores.stats.popSd, scores.stats.popMean),
    )
  }

  if (calculatedScores.length < 3) {
    return null
  }

  let count = 0
  for (const score of calculatedScores) {
    count += score
  }

  return count / calculatedScores.length
}

// this is the equation to calculate the score - it's used in multiple places
// so makes sense to abstract the logic out
function calculateZScore(score: number, polarity: number, sd: number, mean: number): number {
  return ((score - mean) / sd) * polarity
}

// when a score is requested for a given candidate we find all of the submitted references
// and then we generate a references scores by each category, with null where the ref didn't
// have enough answers.
// eg. scores = [ {"ref_id_1": { extraversion: 0.5, stableness: 0.2}, "ref_id_2": { extraversion: null, stableness: 0.2 } }]
// then for the candidate we generate the categories by getting the average of all of their reference's scores for that category
// eg. candidate = {
//    extraversion = (extraversion of ref 1 + extraversion of ref 2) / 2
//    openness = (openness of ref 1 + openness of ref 2) / 2
// }
function findScoresByCandidateId(state: RootState, candidateId: string): OceanScore | null {
  const submitted = references.findRespondedByCandidateIdExceptSelf(state, candidateId)
  if (!submitted) return null

  const refScores: OceanScore[] = []
  for (const ref of submitted) {
    const scores = findScoresByReferenceId(state, ref.id)
    if (!scores) continue

    refScores.push(scores)
  }

  return {
    agreeableness: calculateAverageScore(refScores.map(score => score.agreeableness)),
    extraversion: calculateAverageScore(refScores.map(score => score.extraversion)),
    openness: calculateAverageScore(refScores.map(score => score.openness)),
    stability: calculateAverageScore(refScores.map(score => score.stability)),
    conscientiousness: calculateAverageScore(refScores.map(score => score.conscientiousness)),
  }
}

// if there's less than two scores for a given trait we deem it as not having
// enough information, and so it's just returned as a null.
function calculateAverageScore(scores: (number | null)[]): number | null {
  const filtered = scores.filter(score => score !== null) as number[]
  if (filtered.length < 2) return null

  let total = 0
  for (const score of filtered) {
    total += score
  }

  return total / filtered.length
}

// From Science
// Input:
// - Rank of Extraversion, Agreeableness, Conscientiousness and Openness scores
// - If more than 2 scores are missing do not show summary
// - Rank the traits, and then based on the highest trait return two statements
//   of encouragement and warning.
// - link to notion doc: https://www.notion.so/hipeople/Pick-the-right-onboarding-buddy-59579342d595480aafe8f97d10df2a85
export function findOnboardingBuddySummaryByCandidateId(state: RootState, candidateId: string): Summary | null {
  const scores = findScoresByCandidateId(state, candidateId)
  if (!scores) return null

  const relevant = {
    agreeableness: scores.agreeableness,
    extraversion: scores.extraversion,
    conscientiousness: scores.conscientiousness,
    openness: scores.openness,
  }

  const filteredList = Object.entries(relevant).filter(item => item[1] !== null) as [keyof OceanScore, number][]
  if (filteredList.length <= 1) return null

  const [key] = filteredList.sort(([, a], [, b]) => b - a)[0]

  if (key === 'conscientiousness') {
    return {
      encourage: [
        { emoji: '🤝 ', copy: 'A dependable person.' },
        { emoji: '👌', copy: 'Someone who communicates accurately.' },
      ],
      warning: [
        { emoji: '🔍', copy: 'Avoid a disorganized person.' },
        { emoji: '🙅', copy: 'Do not pick someone who is unpunctual.' },
      ],
    }
  }

  if (key === 'openness') {
    return {
      encourage: [
        { emoji: '💭 ', copy: 'A reflective person.' },
        { emoji: '💡', copy: 'Someone who enjoys discussing ideas.' },
      ],
      warning: [
        { emoji: '🔍', copy: 'Avoid a very pragmatic person.' },
        { emoji: '🙅', copy: 'Do not pick someone who is not flexible.' },
      ],
    }
  }

  if (key === 'agreeableness') {
    return {
      encourage: [
        { emoji: '🤝 ', copy: 'A polite person.' },
        { emoji: '💁', copy: 'Someone who is good at listening.' },
      ],
      warning: [
        { emoji: '🔍', copy: 'Avoid an outspoken person.' },
        {
          emoji: '🙅',
          copy: 'Do not pick someone who can come across as aggressive.',
        },
      ],
    }
  }

  return {
    encourage: [
      { emoji: '😎 ', copy: 'A confident person.' },
      { emoji: '🎙', copy: 'Someone who likes to chat.' },
    ],
    warning: [
      { emoji: '🔍', copy: 'Avoid a quiet person.' },
      { emoji: '🙅', copy: 'Do not pick someone who has a low energy level.' },
    ],
  }
}

// From Science
// Input:
// - Rank of Extraversion, Agreeableness, Conscientiousness and Openness scores
// - If more than 2 scores are missing do not show summary
// - Rank the traits, and then based on the highest trait return two statements
//   of encouragement and warning.
// - This is the same as the onboarding buddy summary above
// - https://www.notion.so/hipeople/Optimize-New-Hire-s-first-tasks-6f726af6a37f47fbab0439891d48618b
export function findOptimiseFirstTasksSummaryByCandidateId(state: RootState, candidateId: string): Summary | null {
  const scores = findScoresByCandidateId(state, candidateId)
  if (!scores) return null

  const relevant = {
    agreeableness: scores.agreeableness,
    extraversion: scores.extraversion,
    conscientiousness: scores.conscientiousness,
    openness: scores.openness,
  }

  const filteredList = Object.entries(relevant).filter(item => item[1] !== null) as [keyof OceanScore, number][]
  if (filteredList.length <= 1) return null

  const [key] = filteredList.sort(([, a], [, b]) => b - a)[0]

  if (key === 'conscientiousness') {
    return {
      encourage: [
        { emoji: '✅', copy: 'Quick-win tasks.' },
        { emoji: '🔍', copy: 'Problems that require attention to detail.' },
      ],
      warning: [
        { emoji: '🥱', copy: 'Steer around tasks that require waiting.' },
        { emoji: '🧐', copy: 'Avoid problems that are not clearly defined.' },
      ],
    }
  }

  if (key === 'openness') {
    return {
      encourage: [
        { emoji: '✅', copy: 'Independent tasks.' },
        { emoji: '💡', copy: 'Problems that require original thinking.' },
      ],
      warning: [
        {
          emoji: '🧐',
          copy: 'Steer around tasks that require following exact instructions.',
        },
        { emoji: '🥱', copy: 'Avoid very simple problems.' },
      ],
    }
  }

  if (key === 'agreeableness') {
    return {
      encourage: [
        { emoji: '🤝 ', copy: 'Collaborative tasks.' },
        {
          emoji: '🧑‍🤝‍🧑',
          copy: 'Problems that require a combination of different points of view.',
        },
      ],
      warning: [
        {
          emoji: '🧐',
          copy: 'Reduce tasks that require competitive thinking.',
        },
        {
          emoji: '🙅',
          copy: 'Avoid problems that require standing up to others.',
        },
      ],
    }
  }

  return {
    encourage: [
      { emoji: '🤝 ', copy: 'Collaborative tasks.' },
      {
        emoji: '🎙',
        copy: 'Problems that require presentation and persuasion skills.',
      },
    ],
    warning: [
      {
        emoji: '🙅',
        copy: 'Steer around tasks that require high carefulness.',
      },
      {
        emoji: '🧐',
        copy: `Avoid problems that require following others' decisions.`,
      },
    ],
  }
}

// Input:
// - Take Extraversion, Agreeableness, Conscientiousness and Openness scores
// - Build input scores where:
// 				agency = AVERAGE(extraversion, openness)
// 				benevolence = AVERAGE(conscientiousness, agreeableness)
// - If 1 value is missing for the average just take the other score.
// - If both are not available do not display summary.
// - Pick summary based on quadrant of score
// - https://www.notion.so/hipeople/Maximize-New-Hire-engagement-02f3f565c2484386b3a0ee4fdcfa24ad
// if (agency >= 0 & benevolence >= 0)
//  then " **Encourage** ⛵️ Let {CANDIDATE.FIRST_NAME} explore new topics.
// if (agency > 0 & benevolence < 0)
//  then **Encourage** 👏 Over-communicate recognition for results.
// if (agency < 0 & benevolence < 0)
//  then **Encourage** 👌 Let {CANDIDATE.FIRST_NAME} optimize existing processess.
// if (agency < 0 & benevolence > 0)
//   then **Encourage** 🤝 Check in on how {CANDIDATE.FIRST_NAME} perceives the atmosphere in the team.
export function findMaximiseKeyHireEngagementSummaryByCandidateId(
  state: RootState,
  candidateId: string,
): Summary | null {
  const scores = findScoresByCandidateId(state, candidateId)
  if (!scores) return null

  const profile = candidateProfiles.findByCandidateId(state, candidateId)
  if (!profile) return null

  const agencyScores = [scores.extraversion, scores.openness].filter(score => score !== null) as number[]
  const benevolenceScores = [scores.conscientiousness, scores.agreeableness].filter(score => score !== null) as number[]
  if (!agencyScores.length || !benevolenceScores.length) return null

  let agency = 0
  for (const score of agencyScores) {
    agency += score
  }
  agency = agency / agencyScores.length

  let benevolence = 0
  for (const score of benevolenceScores) {
    benevolence += score
  }
  benevolence = benevolence / benevolenceScores.length

  const name = candidateProfiles.firstNameOf(profile)

  if (agency >= 0 && benevolence >= 0) {
    return {
      encourage: [
        { emoji: '⛵️', copy: `Let ${name} explore new topics.` },
        { emoji: '🎢', copy: 'Provide variety in tasks and work settings.' },
      ],
      warning: [
        { emoji: '🥱', copy: 'Avoid establishing many fixed routines.' },
        {
          emoji: '🧐',
          copy: `Committing to plans at the cost of flexibility demotivates ${name}.`,
        },
      ],
    }
  }

  if (agency >= 0 && benevolence <= 0) {
    return {
      encourage: [
        { emoji: '👏', copy: 'Over-communicate recognition for results.' },
        { emoji: '🏎', copy: 'Provide quick responses and decisions.' },
      ],
      warning: [
        { emoji: '🧐', copy: 'Avoid sharing semi-finished thoughts.' },
        {
          emoji: '🥱',
          copy: `Collaborative decision-making demotivates ${name}.`,
        },
      ],
    }
  }

  if (agency <= 0 && benevolence <= 0) {
    return {
      encourage: [
        { emoji: '👌', copy: `Let ${name} optimize existing processes.` },
        { emoji: '⏱', copy: 'Provide predictability and routines.' },
      ],
      warning: [
        { emoji: '🧐', copy: 'Avoid imprecise task specifications.' },
        { emoji: '🥱', copy: `Many changes of plans demotivate ${name}.` },
      ],
    }
  }

  return {
    encourage: [
      {
        emoji: '🤝',
        copy: `Check in on how ${name} perceives the atmosphere in the team.`,
      },
      {
        emoji: '💡',
        copy: 'Provide transparency in your arguments and decisions.',
      },
    ],
    warning: [
      { emoji: '🧐', copy: `Avoid pushing ${name} to make quick decisions.` },
      { emoji: '😩', copy: `A strong focus on results demotivates ${name}.` },
    ],
  }
}

// From Science
// Input:
// - Just compare extraversion and agreeableness scores on a quadrant
// - Link https://www.notion.so/hipeople/Foster-New-Hire-integration-15bd42afff7a4dc1be84ffd544d0c0bf
// if (extraversion >= 0 & agreeableness >= 0)
//  then **Encourage** 👋 Plan as many first-week connections with new colleagues as possible.
// if (extraversion > 0 & agreeableness < 0)
// 	then **Encourage* 🙋 Give {CANDIDATE.FIRST_NAME} a chance to discuss topics openly early on.
// if (extraversion < 0 & agreeableness < 0)
//  then " **Encourage** 😌 Introduce {CANDIDATE.FIRST_NAME} to others slowly, focusing on key stakeholders first.
// if (extraversion < 0 & agreeableness > 0)
//  then **Encourage**  🤝 Plan early long and personal encounters with new colleagues.
export function findFosterNewHireIntegrationSummaryByCandidateId(
  state: RootState,
  candidateId: string,
): Summary | null {
  const scores = findScoresByCandidateId(state, candidateId)
  if (!scores) return null

  const profile = candidateProfiles.findByCandidateId(state, candidateId)
  if (!profile) return null

  if (scores.agreeableness === null || scores.extraversion === null) return null

  const name = candidateProfiles.firstNameOf(profile)

  if (scores.extraversion >= 0 && scores.agreeableness >= 0) {
    return {
      encourage: [
        {
          emoji: '👋',
          copy: `Plan as many first-week connections with new colleagues as possible.`,
        },
        {
          emoji: '👍',
          copy: `Show interest in ${selectors.possessiveFirstName(name)} contributions, especially in group settings.`,
        },
      ],
      warning: [
        {
          emoji: '📢',
          copy: `Let ${name} know if they draw too much attention in meetings.`,
        },
        {
          emoji: '⚖️',
          copy: `Show ${name} where to take charge and where to follow.`,
        },
      ],
    }
  }

  if (scores.extraversion >= 0 && scores.agreeableness <= 0) {
    return {
      encourage: [
        {
          emoji: '🙋',
          copy: `Give ${name} a chance to discuss topics openly early on.`,
        },
        {
          emoji: '💡',
          copy: `Encourage ${name} to ask "why" questions in 1:1's.`,
        },
      ],
      warning: [
        {
          emoji: '🤐',
          copy: `Show ${name} where they should hold back opinions to not offend others.`,
        },
        {
          emoji: '🔍',
          copy: `Monitor if team members find ${name} self-centered, mediate early.`,
        },
      ],
    }
  }

  if (scores.extraversion <= 0 && scores.agreeableness <= 0) {
    return {
      encourage: [
        {
          emoji: '😌',
          copy: `Introduce ${name} to others slowly, focusing on key stakeholders first.`,
        },
        {
          emoji: '💁',
          copy: 'Prioritize 1:1 interactions over group settings.',
        },
      ],
      warning: [
        {
          emoji: '🙋',
          copy: `Ask for ${selectors.possessiveFirstName(
            name,
          )} opinions via text or in 1:1's if they don't voice them enough.`,
        },
        {
          emoji: '🧑‍🤝‍🧑',
          copy: `Look for signs that ${name} builds relationships with others and provide early support.`,
        },
      ],
    }
  }

  return {
    encourage: [
      {
        emoji: '🤝',
        copy: `Plan early long and personal encounters with new colleagues.`,
      },
      {
        emoji: '🧑‍🤝‍🧑',
        copy: `Pair ${name} with a colleague for the first day(s).`,
      },
    ],
    warning: [
      {
        emoji: '🙋',
        copy: `Show ${name} where you expect them to challenge opinions.`,
      },
      {
        emoji: '🙅',
        copy: `Look for signs where ${name} struggles to say no to others.`,
      },
    ],
  }
}

// Input: agreeableness score
// Basically just check if the agreeableness score is positive or not
// Link to science def: https://www.notion.so/hipeople/Nurture-New-Hire-trust-dd36e512128140d1822ef388f765edb3
// if (agreeableness < 0)
// 	then **Encourage** 🏆 Create a first-day assignment that highlights the skills of your team members.
// if (agreeableness >= 0)
// 	then **Encourage** ⭐️ Design a first-day assignment that highlights the importance of team spirit.
export function findNurtureTrustSummaryByCandidateId(state: RootState, candidateId: string): Summary | null {
  const scores = findScoresByCandidateId(state, candidateId)
  if (!scores) return null

  const profile = candidateProfiles.findByCandidateId(state, candidateId)
  if (!profile) return null

  if (scores.agreeableness === null) return null

  const name = candidateProfiles.firstNameOf(profile)

  if (scores.agreeableness <= 0) {
    return {
      encourage: [
        {
          emoji: '🏆',
          copy: `Create a first-day assignment that highlights the skills of your team members.`,
        },
        {
          emoji: '👏',
          copy: `Let ${name} handle some tasks independently and communicate your trust in their abilities.`,
        },
      ],
      warning: [
        {
          emoji: '🔍',
          copy: `Avoid openly monitoring ${selectors.possessiveFirstName(name)} work progress.`,
        },
        {
          emoji: '🙅',
          copy: `Lack of competence is a deal-breaker when building trust with ${name}.`,
        },
      ],
    }
  }

  return {
    encourage: [
      {
        emoji: '⭐️',
        copy: `Design a first-day assignment that highlights the importance of team spirit.`,
      },
      {
        emoji: '🎊',
        copy: `Create many opportunities for ${name} to have positive interactions with others.`,
      },
    ],
    warning: [
      {
        emoji: '🔍',
        copy: `Avoid giving ${name} many assignments to do on their own.`,
      },
      {
        emoji: '🙅',
        copy: `Lack of friendliness is a deal-breaker when building trust with ${name}.`,
      },
    ],
  }
}

// find the ocean profile scores for the summary, take the scores,
// and then convert them to our pairwise friendly scores, that's
// literally it.
// notion doc: https://www.notion.so/hipeople/Personality-Profile-Summary-View-bf465b2bfa264b6b8c85ecccb20c8e32
export function findOceanProfileScores(state: RootState, candidateId: string): OceanScore | null {
  const scores = findScoresByCandidateId(state, candidateId)
  if (!scores) return null

  return {
    agreeableness: findPairwiseScoreFromBenchmark(scores.agreeableness, 'agreeableness'),
    extraversion: findPairwiseScoreFromBenchmark(scores.extraversion, 'extraversion'),
    openness: findPairwiseScoreFromBenchmark(scores.openness, 'openness'),
    stability: findPairwiseScoreFromBenchmark(scores.stability, 'stability'),
    conscientiousness: findPairwiseScoreFromBenchmark(scores.conscientiousness, 'conscientiousness'),
  }
}

// Benchmarks from Personality Summary summary doc
// Link https://www.notion.so/hipeople/Personality-Profile-Summary-View-bf465b2bfa264b6b8c85ecccb20c8e32
// Based on where they fall the in the potential segments, generate our pairwise low to high score
// for easy component consumption.
function findPairwiseScoreFromBenchmark(score: number | null, key: keyof OceanScore): number | null {
  const { low, midLow, midHigh, high } = Benchmarks[key]

  if (score === null) return null

  if (score < low) return 0
  if (score >= low && score < midLow) return 3
  if (score >= midLow && score < midHigh) return 5
  if (score >= midHigh && score < high) return 7
  return 10
}

const Benchmarks: {
  [key in keyof OceanScore]: {
    low: number
    midLow: number
    midHigh: number
    high: number
  }
} = {
  extraversion: {
    low: -0.407096985860319,
    midLow: -0.112792084573727,
    midHigh: 0.132604757246239,
    high: 0.361480060178773,
  },
  agreeableness: {
    low: -0.407096985860319,
    midLow: -0.112792084573727,
    midHigh: 0.132604757246239,
    high: 0.361480060178773,
  },
  openness: {
    low: -0.407096985860319,
    midLow: -0.112792084573727,
    midHigh: 0.132604757246239,
    high: 0.361480060178773,
  },
  stability: {
    low: -0.407096985860319,
    midLow: -0.112792084573727,
    midHigh: 0.132604757246239,
    high: 0.361480060178773,
  },
  conscientiousness: {
    low: -0.407096985860319,
    midLow: -0.112792084573727,
    midHigh: 0.132604757246239,
    high: 0.361480060178773,
  },
}
/**
 * Find the onboarding section for a given candidate
 * @returns {boolean} true if the onboarding section should be shown
 */
export function findShowOnboardingSectionByCandidateId(state: RootState, id: string): boolean {
  const candidate = candidates.findById(state, id)
  if (!candidate) return false

  const responded = references.findRespondedByCandidateIdExceptSelf(state, id)
  if (!responded || responded.length < 2) return false

  const scores = findScoresByCandidateId(state, id)
  if (!scores) return false

  return Object.values(scores).filter(score => score !== null).length > 1
}

function findMostRecentEmployeeManagerSubmissionByEmployeeId(
  state: RootState,
  employeeId: string,
):
  | {
      employeeSubmission?: QuestionnaireSubmissionEntity
      managerSubmission?: QuestionnaireSubmissionEntity
    }
  | undefined {
  const submissions = findByEmployeeId(state, employeeId)
  if (!submissions) {
    return
  }

  const orderedSubmission = submissions
    .filter(sub => sub.fields.submitted_at)
    .sort((a, b) => b.fields.due_at - a.fields.due_at)

  const mostRecentManager = orderedSubmission.find(item => item.fields.manager_id)

  const mostRecentEmployee = orderedSubmission.find(item => item.fields.employee_id)

  return {
    employeeSubmission: mostRecentEmployee,
    managerSubmission: mostRecentManager,
  }
}

type Dimension = 'role' | 'organization' | 'team'

type Topic = {
  dimension: Dimension
  dimensionLabel: string
  emoji: string
  topic: string
  employeeScore: number
  managerScore: number
  id: string
}

function findFeedbackTopicsByEmployeeId(
  state: RootState,
  employeeId: string,
): {
  topics: Topic[]
  dimensionAverages: { role: number; organization: number; team: number }
} | null {
  const submissions = findMostRecentEmployeeManagerSubmissionByEmployeeId(state, employeeId)
  if (!submissions) return null

  const { employeeSubmission, managerSubmission } = submissions

  const employeeAnswers = findByQuestionnaireSubmissionId(state, employeeSubmission?.id || '') || []
  const managerAnswers = findByQuestionnaireSubmissionId(state, managerSubmission?.id || '') || []

  const topics: Topic[] = []
  for (const employeeAnswer of employeeAnswers) {
    if (!employeeAnswer) continue

    const question = findQuestionById(state, employeeAnswer.fields.question_id)
    if (!question) continue

    if (question.fields?.properties.ui?.dashboard?.hide) {
      continue
    }

    const dimension = question.fields?.properties.meta?.onboarding?.dimension as Dimension
    const dimensionLabel = question.fields?.copy?.dashboard?.dimension as string
    const topic = question.fields?.copy?.dashboard?.title as string
    const emoji = question.fields?.copy?.dashboard?.emoji as string

    const managerAnswer = managerAnswers.find(a => a?.fields.question_id === question.id)
    if (!managerAnswer) continue

    const managerScore = findResponseOptionById(state, managerAnswer.fields.selected_response_option)?.fields.value
    if (managerScore === undefined) continue

    const employeeScore = findResponseOptionById(state, employeeAnswer.fields.selected_response_option)?.fields.value
    if (employeeScore === undefined) continue

    topics.push({
      employeeScore,
      managerScore,
      topic,
      emoji,
      dimension,
      dimensionLabel,
      id: question.id,
    })
  }

  let orgAverage = 0
  const orgTopics = topics.filter(t => t.dimension === 'organization')
  for (const topic of orgTopics) {
    orgAverage += topic.employeeScore + topic.managerScore
  }

  let roleAverage = 0
  const roleTopics = topics.filter(t => t.dimension === 'role')
  for (const topic of roleTopics) {
    roleAverage += topic.employeeScore + topic.managerScore
  }

  let teamAverage = 0
  const teamTopics = topics.filter(t => t.dimension === 'team')
  for (const topic of teamTopics) {
    teamAverage += topic.employeeScore + topic.managerScore
  }

  return {
    topics,
    dimensionAverages: {
      organization: orgAverage / orgTopics.length,
      role: roleAverage / roleTopics.length,
      team: teamAverage / teamTopics.length,
    },
  }
}

function onboardingScale(value: number): number {
  return selectors.roundScale(value, 0, 10, 1, 7)
}

export function findTopicsToPraiseByEmployeeId(state: RootState, employeeId: string): Score[] | null {
  const feedbackTopics = findFeedbackTopicsByEmployeeId(state, employeeId)
  if (!feedbackTopics) {
    return null
  }

  const { topics, dimensionAverages } = feedbackTopics
  if (!topics.length) {
    return null
  }

  return topics
    .filter(a => (a.employeeScore + a.managerScore) / 2 > 6)
    .map(a => ({
      topic: a.topic,
      emoji: a.emoji,
      dimensionLabel: a.dimensionLabel,
      dimension: a.dimension,
      score: (onboardingScale(a.employeeScore) + onboardingScale(a.managerScore)) / 2,
      id: a.id,
    }))
    .sort((a, b) => {
      // first sort by score
      // then dimension average score
      // then alphabetically
      // ^ unneccessarily complicated imo but them's the brakes
      const score = b.score - a.score
      if (score) return score

      const dim = dimensionAverages[b.dimension] - dimensionAverages[a.dimension]
      if (dim) return dim

      return a.topic.localeCompare(b.topic)
    })
}

export function findTopicsToAnalyzeByEmployeeId(state: RootState, employeeId: string): Score[] | null {
  const feedbackTopics = findFeedbackTopicsByEmployeeId(state, employeeId)
  if (!feedbackTopics) return null

  const { topics, dimensionAverages } = feedbackTopics
  if (!topics.length) return null

  return topics
    .filter(a => (a.employeeScore + a.managerScore) / 2 > 6)
    .map(a => ({
      topic: a.topic,
      emoji: a.emoji,
      dimensionLabel: a.dimensionLabel,
      dimension: a.dimension,
      score: (onboardingScale(a.employeeScore) + onboardingScale(a.managerScore)) / 2,
      id: a.id,
    }))
    .sort((a, b) => {
      const score = a.score - b.score
      if (score) return score

      const dim = dimensionAverages[a.dimension] - dimensionAverages[b.dimension]
      if (dim) return dim

      return a.topic.localeCompare(b.topic)
    })
}

type ReturnScores = { date: number; manager?: number; employee?: number }[]

export function findScoresByQuestionId(state: RootState, employeeId: string, questionId: string): ReturnScores | null {
  const submissions = findByEmployeeId(state, employeeId)?.filter(sub => sub.fields.submitted_at)
  if (!submissions) return null

  const results: {
    [time: number]: { dueAt: number; manager?: number; employee?: number }
  } = {}
  for (const sub of submissions) {
    const answers = findByQuestionnaireSubmissionId(state, sub.id)
    if (!answers) continue

    const answer = answers.find(i => i?.fields.question_id === questionId)
    if (!answer || !answer.fields.selected_response_option) continue

    const option = findResponseOptionById(state, answer.fields.selected_response_option)
    if (!option) continue

    if (sub.fields.employee_id) {
      results[sub.fields.due_at] = {
        ...results[sub.fields.due_at],
        dueAt: sub.fields.due_at,
        employee: onboardingScale(option.fields.value),
      }
      continue
    }

    if (sub.fields.user_id) {
      results[sub.fields.due_at] = {
        ...results[sub.fields.due_at],
        dueAt: sub.fields.due_at,
        manager: onboardingScale(option.fields.value),
      }

      continue
    }
  }

  return Object.values(results)
    .sort((a, b) => b.dueAt - a.dueAt)
    .map(item => ({
      date: item.dueAt,
      manager: item.manager,
      employee: item.employee,
    }))
}

export function findEmployeeExperienceSummaryByEmployeeId(state: RootState, employeeId: string) {
  const submissions = findMostRecentEmployeeManagerSubmissionByEmployeeId(state, employeeId)
  if (!submissions) return

  const { employeeSubmission } = submissions
  const averages = questionnaireAnswers.findDimensionScoresBySubmissionId(state, employeeSubmission?.id || '')
  if (!averages) return

  return {
    overall: overallAverages(averages.overall),
    role: overallAverages(averages.role),
    team: overallAverages(averages.team),
    organization: overallAverages(averages.organization),
  }
}

export function findManagerEvaluationSummaryByEmployeeId(state: RootState, employeeId: string) {
  const submissions = findMostRecentEmployeeManagerSubmissionByEmployeeId(state, employeeId)
  if (!submissions) return

  const { managerSubmission } = submissions
  const averages = questionnaireAnswers.findDimensionScoresBySubmissionId(state, managerSubmission?.id || '')
  if (!averages) return

  return {
    overall: overallAverages(averages.overall),
    role: overallAverages(averages.role),
    team: overallAverages(averages.team),
    organization: overallAverages(averages.organization),
  }
}

function overallAverages(values: number[]): number | undefined {
  if (values.length < 3) return

  let sum = 0
  for (const value of values) {
    sum += value
  }

  return Math.round((sum / values.length) * 10)
}

type ModalDetails = {
  modalTitle: string
  title: string
  url: string
  urlText: string
  emoji: string
  imageUrl: string
  imageAltText: string
  description: string
}

export const ONBOARDING_MODAL_DETAILS: {
  [key: string]: ModalDetails
} = {
  'Ensuring Accuracy': {
    modalTitle: 'Ensuring Accuracy',
    title: 'The Pros and Cons of Perfectionism, According to Research',
    url: 'https://hbr.org/2018/12/the-pros-and-cons-of-perfectionism-according-to-research',
    urlText: 'Harvard Business Review',
    imageUrl: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    imageAltText: 'Harvard Business Review logo',
    emoji: '🔍',
    description:
      'Learning how to deliver error-free work is an essential aspect of onboarding — Mastering the tasks and understanding what details matter will lead to excellent quality.',
  },
  'Prioritizing Work': {
    modalTitle: 'Prioritizing Work',
    title: 'How to Manage Someone Who Thinks Everything Is Urgent',
    url: 'https://hbr.org/2017/08/how-to-manage-someone-who-thinks-everything-is-urgent',
    urlText: 'Harvard Business Review',
    imageUrl: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    imageAltText: 'Harvard Business Review logo',
    emoji: '✅',
    description:
      'Learning to prioritize tasks and responsibilities is an integral part of onboarding — A clear understanding of the urgency of tasks helps achieve excellent prioritization results.',
  },
  'Meeting Expectations': {
    modalTitle: 'Meeting Expectations',
    title: '5 Strategies for Getting More Work Done in Less Time',
    url: 'https://hbr.org/2019/01/5-strategies-for-getting-more-work-done-in-less-time?utm_campaign=hbr&utm_medium=social&utm_source=facebook',
    urlText: 'Harvard Business Review',
    imageUrl: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    imageAltText: 'Harvard Business Review logo',
    emoji: '🎯',
    description:
      'It is imperative to meet the expectations of others to become a productive team member - precise alignment on goals leads to excellence in meeting expectations.',
  },
  'Managing Time': {
    modalTitle: 'Managing Time',
    title: 'Time Management Is About More Than Life Hacks',
    url: 'https://hbr.org/2020/01/time-management-is-about-more-than-life-hacks',
    urlText: 'Harvard Business Review',
    imageUrl: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    imageAltText: 'Harvard Business Review logo',
    emoji: '⏱',
    description:
      'Determining the right amount of time to complete a task is critical to becoming a reliable team member — Creating clear ideas about the scope of work leads to excellent time management.',
  },
  'Working Independently': {
    modalTitle: 'Working Independently',
    title: 'How to Make Employees Feel Like They Own Their Work',
    url: 'https://hbr.org/2015/12/how-to-make-employees-feel-like-they-own-their-work',
    urlText: 'Harvard Business Review',
    imageUrl: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    imageAltText: 'Harvard Business Review logo',
    emoji: '🤹',
    description:
      'Learning when and how to do things independently is an integral part of any onboarding process — When newcomers understand where their responsibilities begin and end, a high degree of autonomy develops.',
  },
  'Learning from Feedback': {
    modalTitle: 'Learning from Feedback',
    title: "The Gourmet Chef's Guide to Giving Feedback",
    url: 'https://hr.mit.edu/learning-topics/comm/articles/gourmet',
    urlText: 'MIT Human Resources',
    imageUrl: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/mit-logo.png',
    imageAltText: 'MIT Human Resources logo',
    emoji: '🌱',
    description:
      'Learning from feedback is a key driver of adjustment during onboarding — When newcomers feel comfortable enough to display vulnerability, excellent learning is possible.',
  },
  'Working with Others': {
    modalTitle: 'Working with Others',
    title: '30 Simple Habits To Help You Work Well With Others',
    url: 'https://www.forbes.com/sites/joshsteimle/2015/02/18/30-simple-habits-to-help-you-work-well-with-others/?sh=133fdf4e64b5',
    urlText: 'Forbes',
    imageUrl: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/forbes-logo.jpg',
    imageAltText: 'Forbes logo',
    emoji: '🤝',
    description:
      "Finding their role within a new team is extremely important for newcomers — When newcomers know how to best contribute to the team's success, great teamwork happens.",
  },
  'Communicating Effectively': {
    modalTitle: 'Communicating Effectively',
    title: 'Five Ways to Sharpen Your Communication Skills',
    url: 'https://hbr.org/2009/04/five-things-leaders-can-do-to',
    urlText: 'Harvard Business Review',
    imageUrl: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    imageAltText: 'Harvard Business Review logo',
    emoji: '🎙',
    description:
      'Learning how to use communication to achieve desired results is an essential aspect of newcomer success — When newcomers understand when, where and with whom to communicate, excellent communication occurs.',
  },
  'Developing Skills': {
    modalTitle: 'Developing Skills',
    title: 'How to Build Expertise in a New Field',
    url: 'https://hbr.org/2015/04/how-to-build-expertise-in-a-new-field',
    urlText: 'Harvard Business Review',
    imageUrl: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    imageAltText: 'Harvard Business Review logo',
    emoji: '📚',
    description:
      'Developing new skills and knowledge is critical to success as a new hire — Clear learning opportunities facilitate effective learning.',
  },
  'Learning Unwritten Rules': {
    modalTitle: 'Learning Unwritten Rules',
    title: 'Write Down Your Team’s Unwritten Rules',
    url: 'https://hbr.org/2020/10/write-down-your-teams-unwritten-rules',
    urlText: 'Harvard Business Review',
    imageUrl: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    imageAltText: 'Harvard Business Review logo',
    emoji: '🧳',
    description:
      'Becoming familiar with the unwritten rules of the new workplace (e.g., adequate language) enables newcomers to behave appropriately — Informal activities and guidance from long-time employees facilitate excellent cultural awareness.',
  },
  'Navigating Formalities': {
    modalTitle: 'Navigating Formalities',
    title: 'Navigating an Office Without Formal Processes',
    url: 'https://hbr.org/2014/06/navigating-an-office-without-formal-processes',
    urlText: 'Harvard Business Review',
    imageUrl: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    imageAltText: 'Harvard Business Review logo',
    emoji: '👔',
    description:
      'Learning how to navigate the formal structure of a company is a critical part of the onboarding process — Formal introductions and guidance from other employees help newcomers become effective in handling formalities.',
  },
  'Understanding the Vision': {
    modalTitle: 'Understanding the Vision',
    title: 'People Remember What You Say When You Paint a Picture',
    url: 'https://hbr.org/2015/06/employees-perform-better-when-they-can-literally-see-what-youre-saying',
    urlText: 'Harvard Business Review',
    imageUrl: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    imageAltText: 'Harvard Business Review logo',
    emoji: '⭐️',
    description:
      "Experiencing the company's vision during onboarding is fundamental to building long-term commitment — Authentic experiences with the company's values result in a high level of identification with the vision.",
  },
  'Showing Initiative': {
    modalTitle: 'Showing Initiative',
    title: 'Research Shows a Simple Way to Increase Your Engagement at Work',
    url: 'https://hbr.org/2018/04/research-shows-a-simple-way-to-increase-your-engagement-at-work',
    urlText: 'Harvard Business Review',
    imageUrl: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    imageAltText: 'Harvard Business Review logo',
    emoji: '🏃',
    description:
      "For newcomers, figuring out when their initiative will have the greatest impact is paramount — awareness of one's own contribution to team results goes hand-in-hand with outstanding initiative.",
  },
  'Overcoming Challenges': {
    modalTitle: 'Overcoming Challenges',
    title: "Why We Don't Let Coworkers Help Us, Even When We Need It",
    url: 'https://hbr.org/2018/03/why-we-dont-let-coworkers-help-us-even-when-we-need-it',
    urlText: 'Harvard Business Review',
    imageUrl: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    imageAltText: 'Harvard Business Review logo',
    emoji: '🌦',
    description:
      'Overcoming challenges is an essential part of any onboarding process — Effective coping and a supportive environment lead to good handling of difficulties.',
  },
  'Knowing the Past': {
    modalTitle: 'Knowing the Past',
    title: "Your Company's History as a Leadership Tool",
    url: 'https://hbr.org/2012/12/your-companys-history-as-a-leadership-tool',
    urlText: 'Harvard Business Review',
    imageUrl: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    imageAltText: 'Harvard Business Review logo',
    emoji: '📜',
    description:
      'For newcomers, historical knowledge of the new company is critical to understanding why things work the way they do - meaningful encounters with past stories and documentary material create an excellent background understanding.',
  },
} as const
