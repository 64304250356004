import * as api from '../api'
import { RootState } from '../store'
import * as candidates from './candidates'
import lookup from './lookup'
import * as presets from './presets'
import * as profiles from './profiles'
import * as references from './references'
import * as requiredreferences from './required-references'

export function findById(state: RootState, id: string): api.request.Entity<api.openjobroles.Fields> {
  return lookup<api.openjobroles.Fields>(state, api.openjobroles.RESOURCE, id) || api.openjobroles.empty(id)
}

export function findByCandidateId(
  state: RootState,
  candidateId: string,
): api.request.Entity<api.openjobroles.Fields> | undefined {
  const candidate = candidates.findById(state, candidateId)
  if (!candidate) return
  return findById(state, candidate.fields.open_job_role_id)
}

export function findRequiredReferencesByRoleId(state: RootState, roleId: string) {
  const roleRequiredReferences = requiredreferences.findByRoleId(state, roleId)
  return references.requiredReferencesFromEntities(roleRequiredReferences)
}

export function findReferenceCheckPresetByRoleId(state: RootState, roleId: string) {
  const role = findById(state, roleId)
  if (!role || !role.fields.created_at) {
    return undefined
  }
  if (!role.fields.reference_check_preset_id) {
    return null
  }
  const preset = presets.getById(state, role.fields.reference_check_preset_id)
  if (!preset || !preset.fields.created_at) {
    return null
  }
  return preset
}

export function findSelfAssessmentPresetByRoleId(state: RootState, roleId: string) {
  const role = findById(state, roleId)
  if (!role || !role.fields.created_at) {
    return undefined
  }
  if (!role.fields.self_assessment_preset_id) {
    return null
  }
  const preset = presets.getById(state, role.fields.self_assessment_preset_id)
  if (!preset || !preset.fields.created_at) {
    return null
  }
  return preset
}

export interface Role extends api.request.Entity<api.openjobroles.Fields> {
  id: string
  title: string
  updated_at: Date
  created_at: Date
  candidate_count: number
  created_by: {
    id: string
    full_name: string
    avatar_img: string
  }
  has_preset: boolean
  ask_reference_check: boolean
  ask_self_assessment: boolean
}

export function getRole(state: RootState, roleId: string): Role | undefined {
  const role = api.openjobroles.findById(state, roleId)
  if (!role) return

  const candidateCount = role.fields.candidate_count
  const userProfile = profiles.getByUserId(state, role.fields.created_by)

  return {
    candidate_count: candidateCount,
    created_by: {
      id: role.fields.created_by,
      full_name: userProfile ? userProfile.fields.full_name : 'Unknown',
      avatar_img: userProfile ? userProfile.fields.profile_picture_url : '',
    },
    created_at: new Date(role.fields.created_at / 1e6),
    title: role.fields.name,
    ask_reference_check: role.fields.ask_reference_check,
    ask_self_assessment: role.fields.ask_self_assessment,
    updated_at: new Date(role.fields.updated_at / 1e6),
    has_preset: !!role.fields.reference_check_preset_id || !!role.fields.self_assessment_preset_id,
    ...role,
  }
}

export function getRoles(
  state: RootState,
  offset?: number,
): {
  cursor: any
  total: any
  roles: Role[]
  query: any
  hasMore: any
} | null {
  const rolesCache = state.roles.roles
  if (!rolesCache) {
    return null
  }

  const { hasMore, roleIds, total, query, cursor } = rolesCache

  const roles: Role[] = []

  for (const roleId of roleIds) {
    const role = api.openjobroles.findById(state, roleId)

    if (!role) {
      continue
    }

    const candidateCount = role.fields.candidate_count
    const userProfile = profiles.getByUserId(state, role.fields.created_by)

    roles.push({
      candidate_count: candidateCount,
      created_by: {
        id: role.fields.created_by,
        full_name: userProfile ? userProfile.fields.full_name : 'Unknown',
        avatar_img: userProfile ? userProfile.fields.profile_picture_url : '',
      },
      created_at: new Date(role.fields.created_at / 1e6),
      title: role.fields.name,
      ask_reference_check: role.fields.ask_reference_check,
      ask_self_assessment: role.fields.ask_self_assessment,
      updated_at: new Date(role.fields.updated_at / 1e6),
      has_preset: !!role.fields.reference_check_preset_id || !!role.fields.self_assessment_preset_id,
      ...role,
    })
  }

  return {
    roles: roles.slice(offset || 0, offset ? offset + 10 : undefined),
    hasMore,
    total,
    query,
    cursor,
  }
}
