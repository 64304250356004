import { get } from './methods'
import { APIResponse } from './request'

export interface Fields {
  total_candidates: number
  total_references: number
  total_verified_references: number
  total_unverified_references: number
  total_completed_references: number
  total_completed_candidates: number
  total_marked_as_completed_candidates: number
  total_expired_candidates: number
  total_roles: number
  total_references_peers: number
  total_references_managers: number
  total_references_reports: number
  total_references_student_peers: number
  total_references_clients: number
  total_references_selfs: number
  total_references_anys: number
  average_time_candidate_complete_hours: number
  managers_evaluation_score: number
  new_hire_experience_score: number
  overall_score: number
}

export async function load(): Promise<APIResponse<undefined, Fields>> {
  return get<undefined, Fields>('reports?type=reference_check')
}

export type ReportType = 'assessment' | 'reference_check' | 'quality_of_hire'

export async function loadByProduct(reportType: ReportType): Promise<APIResponse<undefined, Fields>> {
  return get<undefined, Fields>(`reports?type=${reportType}`)
}
