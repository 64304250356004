import { Button } from 'components/Button/ButtonV2'
import Modal from 'components/Modal'
import TextField from 'components/TextField'
import { fr, px2rem, size, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import * as tracking from 'core/track'
import React from 'react'

const Title = text.cardHeader().element('h3')

const ModalHeader = style()
  .grid({ columns: [fr(1), size.auto], align: 'center' })
  .spacing({ gap: px2rem(8), inner: [px2rem(18), px2rem(32)] })
  .border({
    bottom: `solid ${px2rem(1)}`,
    color: designSystemColors.borderDefault,
  })
  .element()

const Subtitle = text.bodyEmphasis().element('h2')
const Description = text
  .bodyText()
  .spacing({ outerTop: px2rem(8), outerBottom: px2rem(20) })
  .element('p')

const SpacerContainer = style()
  .grid({ columns: [fr(1), size.auto, fr(1)], align: 'center' })
  .spacing({ gap: px2rem(20), outer: [px2rem(36), px2rem(0), px2rem(20)] })
  .element()
const Spacer = style()
  .size({ width: size.fill, height: px2rem(1) })
  .border({
    bottom: `solid ${px2rem(1)} ${designSystemColors.typographySecondary}`,
  })
  .element()
const SpacerLabel = text.bodyText().element('span')

const Spacing = style()
  .spacing({ outerTop: px2rem(24) })
  .element()

const Content = style()
  .spacing({ inner: [px2rem(40), px2rem(32)] })
  .scroll({ y: 'auto' })
  .element()

const SaveTemplateModalStyle = style()
  .size({ maxWidth: px2rem(540) })
  .element()

export type SaveTemplateAction =
  | {
      type: 'create-template'
      templateName: string
    }
  | {
      type: 'use-template-as-custom'
    }
  | {
      type: 'update-template'
      templateName: string
    }

type OnSaveTemplateAction = (values: SaveTemplateAction) => Promise<any>

interface SaveTemplateModalProps {
  close: () => void
  onSubmit: OnSaveTemplateAction
  presetTitle: string | null
  mode: 'new' | 'edit'
  showUseAsCustom?: boolean
}

export const SaveTemplateModal: React.FC<SaveTemplateModalProps> = ({
  close,
  onSubmit,
  presetTitle,
  mode,
  showUseAsCustom = true,
}) => {
  const [templateName, setTemplateName] = React.useState(presetTitle || '')

  const isAddToLibraryButtonDisabled = templateName.trim().length < 3

  const [actionInProgress, setActionInProgress] = React.useState<
    'create-template' | 'use-template-as-custom' | 'update-template' | null
  >(null)

  const onSubmitCallback: OnSaveTemplateAction = async values => {
    setActionInProgress(values.type)
    try {
      await onSubmit(values)
      close()
    } catch {
      // DO NOTHING
    }
    setActionInProgress(null)
  }

  return (
    <Modal
      open={true}
      setOpen={close}
      helpLink={'https://intercom.help/hipeople/en/articles/7123673-create-your-first-reference-feedback-request'}
      renderHeader={({ closeButton }) => (
        <ModalHeader>
          {mode === 'new' ? <Title>Add to library?</Title> : <Title>Update template</Title>}
          {closeButton}
        </ModalHeader>
      )}
    >
      <SaveTemplateModalStyle>
        <Content>
          {mode === 'new' ? (
            <>
              <Subtitle>{'Add to your org template library'}</Subtitle>
              <Description>
                By using adding to the library, anybody in your organization will be able to reuse the template.
              </Description>
            </>
          ) : (
            <>
              <Description>
                Update the template name and description. Anybody in your organization will be able to reuse the
                template.
              </Description>
            </>
          )}
          <TextField
            label="Template name"
            placeholder="e.g: Product Manager Survey"
            value={templateName}
            onChange={ev => setTemplateName(ev.target.value)}
            data-testid="reference-check-template-name-input"
          />
          <Spacing />
          <Button
            buttonType="primary"
            isFullWidth={true}
            data-testid="add-to-library-button"
            onClick={() => {
              tracking.roles.addTemplateToLibrary()

              mode === 'new'
                ? onSubmitCallback({ type: 'create-template', templateName })
                : onSubmitCallback({ type: 'update-template', templateName })
            }}
            disabled={isAddToLibraryButtonDisabled || !!actionInProgress}
            isLoading={actionInProgress === 'create-template'}
          >
            {mode === 'new' ? 'Add to library' : 'Update template'}
          </Button>
          {mode === 'new' && showUseAsCustom && (
            <>
              <SpacerContainer>
                <Spacer />
                <SpacerLabel>{'or'}</SpacerLabel>
                <Spacer />
              </SpacerContainer>
              <Subtitle>Use as custom survey</Subtitle>
              <Description>By using as a custom survey, you won't be able to reuse this for another job.</Description>
              <Button
                buttonType="secondary"
                isFullWidth={true}
                onClick={() => {
                  tracking.roles.saveTemplateAsCustom()
                  onSubmitCallback({ type: 'use-template-as-custom' })
                }}
                disabled={!!actionInProgress}
                isLoading={actionInProgress === 'use-template-as-custom'}
                data-testid="use-as-custom-survey-button"
              >
                Use as custom survey
              </Button>
            </>
          )}
        </Content>
      </SaveTemplateModalStyle>
    </Modal>
  )
}
