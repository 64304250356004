import Button, { ButtonType } from 'components/Button/ButtonV2'
import { px2rem, size, style } from 'core'
import React from 'react'

const ButtonContainer = style()
  .grid({
    columns: [px2rem(140), px2rem(140)],
    rows: size.auto,
    align: 'center',
    justifyContent: 'end',
  })
  .spacing({
    gap: px2rem(16),
  })
  .element()

interface ModalButtonsProps {
  cancelLabel?: string
  submitLabel?: React.ReactNode
  disabled?: boolean
  disabledSubmitButton?: boolean
  confirmButtonType?: ButtonType
  onCancel: () => void
  onSubmit: () => void
}

const ModalButtons: React.FC<ModalButtonsProps> = props => {
  return (
    <ButtonContainer>
      <Button onClick={props.onCancel} buttonType={'tertiary'} disabled={props.disabled}>
        {props.cancelLabel || 'Cancel'}
      </Button>
      <Button
        onClick={props.onSubmit}
        buttonType={props.confirmButtonType || 'confirm'}
        disabled={props.disabled || props.disabledSubmitButton}
      >
        <>{props.submitLabel || 'Continue'}</>
      </Button>
    </ButtonContainer>
  )
}

export default ModalButtons
