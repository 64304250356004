import React from 'react'
import { Text } from '../../Text/Text'
import classes from './FeatureList.module.scss'
import Icon from './icon.svg?react'

interface PricingCardProps {
  title: string
  list: string[]
}

export const FeatureList = (props: PricingCardProps) => {
  return (
    <div>
      <Text variant={'card-header'} className={classes.title}>
        {props.title}
      </Text>
      {props.list.map((feature, index) => (
        <Text key={index} variant={'body-interactive'} className={classes.text}>
          <Icon className={classes.icon} />
          {feature}
        </Text>
      ))}
    </div>
  )
}
