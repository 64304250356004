import action from 'core/track/action'
import events from 'core/track/events'
import track from 'core/track/track'

export const candidateExperienceDropdownClicked = action(() => {
  track(events.CANDIDATE_EXPERIENCE_DROPDOWN_CLICKED, {})
})

export const candidateExperienceScoresTabClicked = action(() => {
  track(events.CANDIDATE_EXPERIENCE_SCORES_CLICKED, {})
})

export const candidateExperienceFeedbackTabClicked = action(() => {
  track(events.CANDIDATE_EXPERIENCE_FEEDBACK_CLICKED, {})
})

export const sidebarCandidateExperienceEnableNowClicked = action(() => {
  track(events.CANDIDATE_EXPERIENCE_ENABLE_NOW_CLICKED, {})
})

export const candidateExperienceDateRangeSelected = action((range: string) => {
  track(events.CANDIDATE_EXPERIENCE_DATE_RANGE_SELECTED, {
    'Range name': range,
  })
})

export const candidateExperienceFiltersCleared = action(() => {
  track(events.CANDIDATE_EXPERIENCE_FILTERS_CLEARED, {})
})

export const candidateExperienceOfficeSelected = action(() => {
  track(events.CANDIDATE_EXPERIENCE_OFFICE_SELECTED, {})
})

export const candidateExperienceDepartmentSelected = action(() => {
  track(events.CANDIDATE_EXPERIENCE_DEPARTMENT_SELECTED, {})
})

export const candidateExperienceRecruiterelected = action(() => {
  track(events.CANDIDATE_EXPERIENCE_RECRUITER_SELECTED, {})
})

export const candidateExperienceStatusSelected = action((status: string) => {
  track(events.CANDIDATE_EXPERIENCE_STATUS_SELECTED, {
    Status: status,
  })
})

export const candidateExperienceHiringStageSelected = action((stage: string) => {
  track(events.CANDIDATE_EXPERIENCE_HIRING_STAGE_SELECTED, {
    'Hiring Stage': stage,
  })
})
