import { Entity } from './request'

export const RESOURCE = 'questions'

export type QuestionResponseType =
  | 'chips'
  | 'configurable-chips'
  | 'forced-choice'
  | 'forced-truth'
  | 'likert-scale'
  | 'one-off'
  | 'optionset'
  | 'slider'
  | 'text-input'
  | 'video'
  | 'stars'
  | 'yes-no'
  | 'card'
  | 'numerical-reasoning'
  | 'timed-intro'
  | 'timed-outro'
  | 'intro'
  | 'outro'
  | 'typing'

type Scoring = {
  pop_sd: number
  pop_max: number
  pop_min: number
  pop_mean: number
  pop_size: number
  polarity: number
}

export interface Fields {
  id: string
  module_id: string
  slug: string
  page_id: number
  parent_id: string
  contentful_id: string
  skill_id: string
  is_self: boolean
  is_required: boolean
  response_type: QuestionResponseType
  copy: Record<string, any>
  properties: {
    skill_intro?: boolean
    skill_outro?: boolean
    skill_experience?: boolean
    email?: boolean
    numeric?: boolean
    nonnumeric?: boolean
    ui?: {
      title: string
      emoji: string
      dashboard?: {
        hide?: boolean
      }
    }
    meta?: {
      onboarding?: {
        dimension: string
      }
    }
    scoring?: Scoring & { module: Scoring }
    mean?: number
    numerical_reasoning?: {
      expected_answers: number[]
      seq: number[]
      to_provide: number
    }
    maxselect?: number
  }
  conditions: any[]
  created_at: number
  updated_at: number
  is_active: boolean
  is_custom: boolean
}

export type QuestionEntity = Entity<Fields>
