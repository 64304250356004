import { MarkdownText } from '@common/components'
import MultiChipContainer from 'App/Candidate/MultiChipContainer'
import { Response } from 'App/Candidate/SelfAssessment/Responses'
import ScoreBar from 'components/Scores/ScoreBar'
import { fr, px2rem, size, space, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import Markdown from 'markdown-to-jsx'
import React from 'react'
import { useSelector } from 'react-redux'
import * as selectors from 'selectors'
import { sortByOverlapped } from 'selectors/questionnaire-submissions'
import { RootState } from 'store'

const SummaryContainer = style()
  .color({ bg: designSystemColors.backgroundNeutralPrimary })
  .border({
    around: `solid 1px`,
    color: designSystemColors.borderDefault,
    radius: px2rem(6),
  })
  .block()
  .sans({ nodecoration: true })
  .element()

const Emoji = style()
  .flex({ alignItems: 'center', justifyContent: 'center' })
  .size({ width: px2rem(20), height: px2rem(20) })
  .element()

const Header = style()
  .grid({ columns: [size.auto, fr(1), size.auto], align: 'center' })
  .spacing({
    gap: px2rem(4),
    inner: [px2rem(8), px2rem(24), px2rem(8), px2rem(12)],
  })
  .element()

const Title = text.cardHeader().color({ fg: designSystemColors.typographyPrimary }).element('h3')

const ModuleName = text.smallBodyText().element('p')

const ContentContainer = style()
  .spacing({ gap: px2rem(16) })
  .element()

const SingleResponseContainer = style()
  .grid({ columns: [px2rem(150), fr(1)], rows: [size.auto, size.auto] })
  .border({
    top: '1px solid',
    color: designSystemColors.backgroundNeutralTertiary,
  })
  .spacing({ inner: px2rem(12) })
  .element('section')

const RespondentDetailsContainer = style()
  .flex({ direction: 'column', alignItems: 'flex-start' })
  .spacing({ gap: px2rem(4) })
  .element()
const RespondentName = text.bodyInteractive().element('p')
const RespondentTypeCandidate = text
  .label()
  .border({ radius: px2rem(5) })
  .color({
    fg: designSystemColors.informationPurplePrimary,
    bg: designSystemColors.informationPurpleBackground,
  })
  .spacing({
    inner: [px2rem(2), px2rem(4)],
  })
  .element('span')

interface SelfAssessmentQuestionBlockProps {
  response: Response
  candidateName: string
  questionnaireSubmissionId: string
}

export const SelfAssessmentQuestionBlock: React.FC<SelfAssessmentQuestionBlockProps> = props => {
  const answer = props.response.answer

  const responseOptions = useSelector((state: RootState) => {
    return selectors.responseOptions.findByQuestionId(state, answer.question.id)
  })

  if (props.response.moduleSlug === 'culturefitcheck') {
    return <CultureFitCheckResponse {...props} />
  }

  let title = props.response.title
  if (answer.question.response_type === 'numerical-reasoning') {
    title += ` (${answer.question.properties.numerical_reasoning?.seq.join(', ')})`
  }

  return (
    <SummaryContainer>
      <Header>
        <Emoji>{props.response.moduleEmoji}</Emoji>
        <Title>
          <MarkdownText text={title} />
        </Title>
        <ModuleName>{props.response.moduleName}</ModuleName>
      </Header>
      <ContentContainer>
        <SingleResponseContainer>
          <RespondentDetailsContainer>
            <RespondentName>{props.candidateName}</RespondentName>
            <RespondentTypeCandidate>{'Candidate'}</RespondentTypeCandidate>
          </RespondentDetailsContainer>
          {answer.question.response_type === 'optionset' ? (
            <MultiChipContainer
              chips={(responseOptions || [])
                .map(resp => ({
                  id: resp.id,
                  value: resp.id,
                  score: resp.fields.value,
                  caption: resp.fields.copy.caption,
                  overlapped: answer.selected.some(ans => ans.id === resp.id),
                }))
                .sort(sortByOverlapped)}
              text_input={answer.text_input}
            />
          ) : answer.question.response_type === 'card' && props.response.moduleSlug === 'problem-solving' ? (
            // FIXME: This is not nice, we are wrapping the image in markdown strings to render it. Perhaps a custom component should be made for this case.
            // Or, the problem-solving module should be updated to use optionset instead of card, like problem solving basic.
            <MultiChipContainer
              chips={(responseOptions || [])
                .map(resp => ({
                  id: resp.id,
                  value: resp.id,
                  score: resp.fields.value,
                  caption:
                    resp.fields.copy.image !== '' && !resp.fields.copy.caption
                      ? '![](' + resp.fields.copy.image + ')'
                      : resp.fields.copy.caption,
                  overlapped: answer.selected.some(ans => ans.id === resp.id),
                }))
                .sort(sortByOverlapped)}
            />
          ) : answer.question.response_type === 'text-input' ? (
            <LabelStyle>
              <MarkdownText text={answer.text_input} />
            </LabelStyle>
          ) : answer.question.response_type === 'numerical-reasoning' ? (
            <div>
              <MultiChipContainer
                chips={(answer.numerical_input || []).map((input, i) => {
                  const expected = (answer.question.properties.numerical_reasoning?.expected_answers || [] || [])[i]

                  return {
                    id: answer.question.id + i,
                    value: answer.question.id + i,
                    caption: `${input}`,
                    score: input === expected ? 1 : 0,
                    overlapped: true,
                  }
                })}
              />
              The correct answer is: {answer.question.properties.numerical_reasoning?.expected_answers?.join(', ')}
            </div>
          ) : (
            <LabelScore
              submitted={props.response.hasBeenSubmitted}
              score={props.response.score}
              max={props.response.maxScore}
              textInput={answer.text_input || ''}
            />
          )}
        </SingleResponseContainer>
      </ContentContainer>
    </SummaryContainer>
  )
}

const CultureFitCheckResponse: React.FC<SelfAssessmentQuestionBlockProps> = props => {
  const result = useSelector((state: RootState) => {
    return selectors.questionnaireSubmissions.findCultureFitCheckResponses(state, props.questionnaireSubmissionId)
  })

  const { recruiterSelectedValues, candidateSelectedValues } = result || {
    recruiterSelectedValues: [],
    candidateSelectedValues: [],
  }

  return (
    <SummaryContainer id={props.response.moduleSlug}>
      <Header>
        <Emoji>{props.response.moduleEmoji}</Emoji>
        <Title>
          <Markdown>{props.response.moduleName}</Markdown>
        </Title>
        <ModuleName>{props.response.moduleName}</ModuleName>
      </Header>
      <ContentContainer>
        <SingleResponseContainer>
          <RespondentDetailsContainer>
            <RespondentName>Your Cultural Values Selection</RespondentName>
          </RespondentDetailsContainer>
          <MultiChipContainer
            chips={
              recruiterSelectedValues
                ? recruiterSelectedValues.map(resp => ({
                    id: resp.id,
                    value: resp.id,
                    response: resp,
                    caption: resp.caption,
                    overlapped: resp.overlapped,
                  }))
                : []
            }
          />
        </SingleResponseContainer>
        <SingleResponseContainer>
          <RespondentDetailsContainer>
            <RespondentName>{props.candidateName} Cultural Values selection</RespondentName>
          </RespondentDetailsContainer>
          <MultiChipContainer
            chips={
              candidateSelectedValues
                ? candidateSelectedValues.map(resp => ({
                    id: resp.id,
                    value: resp.id,
                    response: resp,
                    caption: resp.caption,
                    overlapped: resp.overlapped,
                  }))
                : []
            }
          />
        </SingleResponseContainer>
      </ContentContainer>
    </SummaryContainer>
  )
}

const LabelScoreContainer = style()
  .grid({ columns: [px2rem(48), size.auto] })
  .spacing({
    rows: px2rem(4),
    inner: [px2rem(8), px2rem(16)],
    outer: [space.auto, space.none],
  })
  .element()

const Max = text
  .smallBody()
  .color({ fg: designSystemColors.typographySecondary })
  .spacing({ outerLeft: px2rem(4) })
  .element()

const LabelStyle = style().flex({ alignItems: 'center' }).element()
const Score = text.bodyEmphasis().element('span')

const SkippedText = text.bodyText().text({ italic: true }).element('span')
const InputText = text.bodyText().element('span')

interface LabelScoreProps {
  submitted?: boolean
  score: number | null
  max: number
  textInput: string
}

const LabelScore: React.FC<LabelScoreProps> = props => {
  if (!props.submitted)
    return (
      <LabelStyle>
        <SkippedText>No answer submitted yet</SkippedText>
      </LabelStyle>
    )

  if (props.textInput)
    return (
      <LabelStyle>
        <InputText>{props.textInput}</InputText>
      </LabelStyle>
    )

  if (props.score === null)
    return (
      <LabelStyle>
        <SkippedText>This question has been skipped</SkippedText>
      </LabelStyle>
    )

  if (props.submitted && props.textInput !== '')
    return (
      <LabelStyle>
        <SkippedText>{props.textInput}</SkippedText>
      </LabelStyle>
    )

  return (
    <LabelScoreContainer>
      <LabelStyle>
        <Score>{props.score}</Score>
        <Max>{`/ ${props.max}`}</Max>
      </LabelStyle>
      <ScoreBar score={props.score} maxDisplayedScore={props.max} />
    </LabelScoreContainer>
  )
}
