import React from 'react'
import { Link as ReactRouterLink, LinkProps } from 'react-router-dom'
import classes from './Link.module.scss'

/* Thin Wrapper around the react-router-dom Link component to make it easier to replace it in the future */
interface Props extends LinkProps {
  hoverEffect?: boolean
}
const Link = ({ hoverEffect, ...props }: Props) => {
  return <ReactRouterLink {...props} className={[classes.Link, hoverEffect && classes.HoverLink].join(' ')} />
}

export { Link }
