import { DefaultButton } from 'components/Button/ButtonV1'
import { breakpoints, px2rem, size, space, style, weight } from 'core'
import { designSystemColors as designColors, designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import { useOrgPrivacyPolicyLink } from 'providers/orgs'
import React from 'react'
import { useParams } from 'react-router-dom'

const FooterStyle = style()
  .spacing({ inner: space.s })
  .screen(breakpoints.mobile, style().hidden())
  .flex({ justifyContent: 'space-between' })
  .element()

const FooterDataPrivacy = text
  .bodyText()
  .text({ weight: weight.bold, nodecoration: true })
  .fg(designColors.typographySecondary)
  .element('a')
const FooterPoweredBy = text
  .bodyText()
  .text({ nodecoration: true, ellipsis: true })
  .fg(designColors.typographySecondary)
  .flex({ alignItems: 'center' })
  .element('a')
const FooterPoweredByLogo = style()
  .size({ width: size.auto, height: px2rem(20) })
  .spacing({ outerLeft: space.xs })
  .element('img')

const FooterLogo = style()
  .size({ width: size.auto, height: px2rem(24) })
  .spacing({ outerLeft: space.xs })
  .element('img')
const AlternativeFooterText = text
  .bodyEmphasis()
  .size({ maxWidth: px2rem(320) })
  .spacing({ outer: [px2rem(16), space.none] })
  .sans({ align: 'center' })
  .element('p')
const LearnMoreButtonText = text.bodyInteractive().element('span')
const LearnMoreButton = style(DefaultButton)
  .bg({ color: designSystemColors.backgroundNeutralAccent })
  .fg(designSystemColors.white)
  .spacing({
    inner: px2rem(12),
    outer: [space.none, space.auto],
  })
  .text({ nodecoration: true })
  .element('a')

const AlternativeFooterTopSection = style().flex({ direction: 'column', alignItems: 'center' }).element()
const AlternativeFooterBottomSection = style().flex({ justifyContent: 'space-between' }).element()
const AlternativeFooterStyle = style(FooterStyle)
  .set('background', 'linear-gradient(96.41deg, #FDDFFF -2.81%, #FFDFC2 119.51%)')
  .flex({ direction: 'column' })
  .element()

interface AlternativeFooterProps {}

export const AlternativeFooter: React.FC<AlternativeFooterProps> = () => {
  const { candidateid } = useParams<{ candidateid?: string }>()
  const { privacyPolicyLink } = useOrgPrivacyPolicyLink({
    candidateId: candidateid || '',
  })
  return (
    <AlternativeFooterStyle>
      <AlternativeFooterTopSection>
        <FooterLogo src="/assets/hipeople.svg" />
        <AlternativeFooterText>
          <LearnMoreButtonText>
            {'Hire better, faster. With in-depth, automated candidate references'}
          </LearnMoreButtonText>
        </AlternativeFooterText>
        <LearnMoreButton inline href="https://hipeople.io" target="_blank" rel="noopener noreferrer">
          {'Learn More'}
        </LearnMoreButton>
      </AlternativeFooterTopSection>
      <AlternativeFooterBottomSection>
        <FooterDataPrivacy href={privacyPolicyLink} target="_blank" rel="noopener noreferrer">
          Data Privacy Notice
        </FooterDataPrivacy>
        <FooterPoweredBy href="https://hipeople.io" target="_blank" rel="noopener noreferrer">
          <span>powered by</span>
          <FooterPoweredByLogo src="/assets/hipeople.svg" />
        </FooterPoweredBy>
      </AlternativeFooterBottomSection>
    </AlternativeFooterStyle>
  )
}

interface FooterProps {
  fakeMode?: boolean
}

export const Footer: React.FC<FooterProps> = props => {
  const { candidateid } = useParams<{ candidateid?: string }>()
  const { privacyPolicyLink: orgPrivacyPolicyLink } = useOrgPrivacyPolicyLink({
    candidateId: candidateid || '',
  })

  const privacyPolicyLink = orgPrivacyPolicyLink || import.meta.env.VITE_CANDIDATE_PRIVACY_POLICY_LINK || ''

  return (
    <FooterStyle>
      <FooterDataPrivacy
        href={!props.fakeMode ? privacyPolicyLink : undefined}
        target="_blank"
        rel="noopener noreferrer"
      >
        Data Privacy Notice
      </FooterDataPrivacy>
      <FooterPoweredBy
        href={!props.fakeMode ? 'https://hipeople.io' : undefined}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span>powered by</span>
        <FooterPoweredByLogo src="/assets/hipeople.svg" />
      </FooterPoweredBy>
    </FooterStyle>
  )
}
