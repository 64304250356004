import { useCandidate } from 'providers/candidates'
import { useSelector } from 'react-redux'
import { findGroupedQuestionsByQuestionnaireId } from 'selectors/questionnaires'
import { RootState } from 'store'

export function useQuestionnaireAnswers(query: { candidateId: string }) {
  const { candidateId } = query
  const { candidate } = useCandidate({ candidateId })

  const questionnaireId = candidate?.fields.questionnaire_id || ''
  const rdsModulesQuestions = useSelector((state: RootState) =>
    findGroupedQuestionsByQuestionnaireId(state, candidateId, questionnaireId),
  )

  const groupedQuestions = rdsModulesQuestions || []
  return {
    groupedQuestions,
  }
}
