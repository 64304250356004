import React from 'react'
import { LoadingText } from '../LoadingText/LoadingText'
import { Text } from '../Text/Text'
import classes from './LoadingBar.module.scss'

interface LoadingBarProps {
  /** Messages to display while loading */
  loadingMessages?: string[]
  /** Estimated time in seconds for the loading to finish */
  approximateTimeEstimate?: number
}

export const LoadingBar = (props: LoadingBarProps) => {
  const loadingMessages = props.loadingMessages ?? ['Gathering resources', 'Finishing up']

  // timeEstimatePerInterval is the time it takes to go through all the messages. Defaults to 6 seconds
  const timeEstimatePerInterval = ((props.approximateTimeEstimate ?? 6) / loadingMessages.length) * 1000

  const [messageIndex, setMessageIndex] = React.useState(0)

  const [progress, setProgress] = React.useState(0)

  // every time messageIndex changes, update progress, but keep slowly progressing in between
  // this is to make the progress bar look smooth
  React.useEffect(() => {
    setProgress(messageIndex === loadingMessages.length - 1 ? 0.85 : messageIndex / loadingMessages.length)
    const interval = setInterval(() => {
      setProgress(progress => {
        if (messageIndex === loadingMessages.length - 1 && progress < 100) {
          return progress + Math.random() * 0.01
        }
        return progress + 0.01
      })
    }, 300)
    return () => clearInterval(interval)
  }, [messageIndex])

  // change message every time interval
  React.useEffect(() => {
    setMessageIndex(0)
    const interval = setInterval(() => {
      setMessageIndex(messageIndex => {
        if (messageIndex === loadingMessages.length - 1) {
          return messageIndex
        }
        return messageIndex + 1
      })
    }, timeEstimatePerInterval)
    return () => clearInterval(interval)
  }, [timeEstimatePerInterval])

  return (
    <div className={`${classes.main}`}>
      <Text variant="home-card-header" className={classes.text}>
        {loadingMessages[messageIndex]} <LoadingText text="" />
      </Text>
      <div className={classes.bar}>
        <div
          className={classes.progress}
          style={{
            width: `${progress * 100}%`,
          }}
        ></div>
      </div>
    </div>
  )
}
