import {
  AiSuggestionsIcon,
  Banner,
  Button,
  LanguageDropdown,
  LoadingSpinner,
  LoadingText,
  Text,
  Tooltip,
} from '@common/components'
import { AssessmentLocale, AvailableAssessmentModulesLocales } from '@common/models/locales'
import { apiGet } from 'api/methods'
import { QuestionResponseType } from 'api/questions'
import {
  LibraryFilter,
  LibraryItemData,
  LibrarySelection,
  useLibrarySelection,
} from 'App/RoleCreation/TemplateCreationSelfAssessment/selection'
import { BadgeTheme } from 'components/Badge'
import { ModalBox } from 'components/Modal'
import { ModalContainer } from 'components/Modal/Container'
import { fr, px2rem, rem, size, style } from 'core'
import { text } from 'core/design-system/text'
import debounce from 'lodash-es/debounce'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import * as selectors from 'selectors'
import { plural } from 'selectors'
import { RootState } from 'store'
import TextField from '../../../../components/TextField'
import * as tracking from '../../../../core/track'
import { ContentSelectionTable } from './ContentSelectionTable'
import FilterBadgeButton from './FilterBadgeButton'
import PreviewSidebar from './PreviewSidebar'
import { getAlternativeTitle } from 'utils/format'

const LeftSidebarWidth = '12.5rem'

const ModalHeader = style()
  .grid({
    columns: [LeftSidebarWidth, fr(1), rem(7), rem(15), size.auto],
    align: 'center',
  })
  .spacing({ gap: px2rem(16), inner: [px2rem(16), px2rem(24)] })
  .element()

const Title = text.cardHeader().element('h3')

const ContentContainer = style()
  .grid({ columns: [LeftSidebarWidth, fr(1)] })
  .spacing({
    gap: px2rem(16),
    inner: [px2rem(0), px2rem(24)],
  })
  .nooverflow()
  .element()

const Filters = style()
  .flex({ direction: 'column' })
  .scroll({ y: 'auto', x: 'hidden' })
  .spacing({ gap: px2rem(8) })
  .element()

const Buttons = style()
  .flex({ justifyContent: 'flex-end' })
  .spacing({ inner: [px2rem(12), px2rem(24)], gap: px2rem(16) })
  .select('> *', style().size({ minWidth: px2rem(140) }))
  .element()

const LoadingSpinnerStyle = style().center().element()

interface Props {
  organizationSlug: string
  matchingLibraryItems: string[]
  libraryItemFromId: (id: string) => LibraryItemData | undefined
  selection: LibrarySelection
  open: boolean
  setOpen: (open: boolean) => void
  groups: LibraryGroup[]
  filter: LibraryFilter
  onScrollNext: () => void
  role: {
    id: string
    locale: AssessmentLocale
    description: string
  } | null
}

const ContentSelectionModal: React.FC<Props> = (props: Props) => {
  const [nameSuggestions, setNameSuggestions] = useState<string[]>([])
  const [groupCounts, setGroupCounts] = useState<GroupCounts[]>([])
  const [libraryItemIDForSidebar, setLibraryItemIDForPreview] = useState<string>()

  const mountedRef = useRef<boolean>(false)

  const sidebarLibraryItem = libraryItemIDForSidebar ? props.libraryItemFromId(libraryItemIDForSidebar) : undefined

  const sidebarQuestionTypeCopy = sidebarLibraryItem?.preview.response_type
    ? questionTypeCopy(sidebarLibraryItem.preview.response_type)
    : undefined

  const trackSearchQuery = useCallback(
    debounce((value: string) => {
      tracking.contentSelection.libraryItemsSearched(value)
    }, 400),
    [],
  )

  const orgId = useSelector((state: RootState) => selectors.orgs.currentId(state))
  const orgSettings = useSelector((state: RootState) => selectors.orgSettings.getByOrgId(state, orgId))
  const subSelection = useLibrarySelection()

  const parentSelectionKey = props.selection.key()

  useEffect(() => {
    subSelection.extend(props.selection)
  }, [parentSelectionKey])

  // sort alphabetically
  const groups = props.groups.sort((a, b) => a.name.localeCompare(b.name))

  const onConfiguredAction = (id: string, isConfigured: boolean) => {
    const items = subSelection.libraryItems
    const action = isConfigured ? ('add' as const) : ('remove' as const)

    items[action](id)
  }

  const setOpen = useCallback(
    (open: boolean) => {
      if (!open) {
        tracking.contentSelection.modalCloseClicked()
        props.filter.setQuery('')
        props.filter.setGroup(undefined)
      }
      props.setOpen(open)
    },
    [props],
  )

  const onModalCanceled = useCallback(() => {
    tracking.contentSelection.modalCancelClicked()
    props.setOpen(false)

    props.filter.setQuery('')
    props.filter.setGroup(undefined)
  }, [props])

  const onAddTests = () => {
    tracking.contentSelection.modalValidateSelectionClicked()
    if (!subSelection.valid) return
    props.selection.replace(subSelection)
    props.setOpen(false)
  }

  const [aiSuggestionsLoading, setAiSuggestionsLoading] = useState(false)
  const [suggestionsIds, setSuggestionsIds] = useState<string[]>([])

  const getModulesIdsFromNames = useCallback((modulesNames: string[], groupSlug?: string, filter?: string) => {
    const query = new URLSearchParams({
      'name[in]': `${filter ? filter : modulesNames.join(',')}`,
      limit: '10',
      is_selectable: 'true',
      ...(groupSlug
        ? {
            'group_slug[eq]': `${groupSlug}`,
          }
        : {}),
    })

    apiGet(`/library-items?${query.toString()}`).then(res => {
      const libraryItemsIds = (res[0]?.result as Array<LibraryItemData>).map(item => item.id)
      setSuggestionsIds(libraryItemsIds)
    })
  }, [])

  const getSuggestions = useCallback(() => {
    if (!props.role?.id) return
    tracking.contentSelection.aiContentSelectionReloadClicked()
    setNameSuggestions([])
    setAiSuggestionsLoading(true)
    apiGet(`/genie/assessment/open_job_role/${props.role.id}/suggested_library_items`)
      .then(res => {
        const apiSuggestions = (res?.[0]?.meta as string[]) ?? []
        if (apiSuggestions.length > 0 && mountedRef.current) {
          setNameSuggestions(apiSuggestions)
        }
      })
      .finally(() => {
        setAiSuggestionsLoading(false)
      })
  }, [props.role?.id])

  // get suggestions on mount
  useEffect(() => {
    mountedRef.current = true
    if (props.role?.description && props.open && orgSettings?.fields?.hire_genie_enabled_assessment) {
      getSuggestions()
    }
    return () => {
      mountedRef.current = false
    }
  }, [props.open, orgSettings?.fields?.hire_genie_enabled_assessment, props.role?.description])

  // get modules ids for suggestions on filter change
  useEffect(() => {
    if (nameSuggestions.length > 0) {
      getModulesIdsFromNames(nameSuggestions, props.filter.group?.slug, props.filter.query)
    }
  }, [props.filter, nameSuggestions])

  const getGroupCounts = useCallback(
    (locale: string) => {
      // Resetting the counts before the query.
      // We wait for it to be non-empty before rending the component.
      setGroupCounts([])
      const query = new URLSearchParams({ locale: locale })
      apiGet(`/groups-count?${query.toString()}`).then(res => {
        const counts = res[0] as unknown as GroupCounts[]
        setGroupCounts(counts)
      })
    },
    [props.filter.locale],
  )

  // Get group counts on component mount.
  useEffect(() => {
    // If the filter isn't set -- using the assessment locale.
    getGroupCounts(props.filter.locale || props.role?.locale || 'en_US')
  }, [props.filter.locale])

  return (
    <ModalContainer open={props.open} setOpen={setOpen}>
      <ModalBox
        style={{
          display: 'grid',
          gridTemplateRows: 'auto 1fr auto',
          height: '90vh',
        }}
        open={props.open}
        setOpen={setOpen}
        isFullWidth
        wrapperStyle={{ display: 'contents' }}
        renderHeader={({ closeButton }) => (
          <ModalHeader>
            <Title data-testid="add-selection-question-modal-title">Test library</Title>
            <TextField
              isFullWidth
              testId="add-selection-question-modal-search-input"
              placeholder="Search"
              icon="search"
              value={props.filter.query || ''}
              height={'100%'}
              onChange={ev => {
                trackSearchQuery(ev.target.value)
                props.filter.setGroup(undefined)
                props.filter.setQuery(ev.target.value)
              }}
            />
            <Text variant="body-emphasis" style={{ textAlign: 'right' }}>
              Test Language
            </Text>
            <LanguageDropdown
              locales={AvailableAssessmentModulesLocales}
              defaultLocale={props.role?.locale || props.filter.locale || 'en_US'}
              onSelectLocale={props.filter.setLocale}
            />
            {closeButton}
          </ModalHeader>
        )}
      >
        {!groupCounts || groupCounts.length === 0 ? (
          <LoadingSpinnerStyle>
            {' '}
            <LoadingSpinner />
          </LoadingSpinnerStyle>
        ) : (
          <ContentContainer>
            <Filters>
              {groups.map(g => {
                const selected = props.filter.group?.slug === g.slug
                const count = groupCounts.find(group => group.group_name === g.name)?.count

                return (
                  <FilterBadgeButton
                    onClick={() => {
                      tracking.contentSelection.modalGroupFilterClicked(g.name)
                      props.filter.setQuery('')
                      props.filter.setGroup(selected ? undefined : g)
                    }}
                    selected={selected}
                    label={`${count} tests`}
                    key={g.slug}
                    badge={{
                      theme: g.theme,
                      copy: getAlternativeTitle(g.name, orgId),
                      icon: g.icon,
                    }}
                  />
                )
              })}
            </Filters>
            <ContentSelectionTable
              matchingLibraryItems={props.matchingLibraryItems}
              libraryItemFromId={props.libraryItemFromId}
              selection={subSelection}
              suggestions={suggestionsIds}
              onScrollNext={props.onScrollNext}
              setLibraryItemIDForPreview={(id: string) => {
                tracking.contentSelection.openPreviewButtonClicked()
                setLibraryItemIDForPreview(libraryItemIDForSidebar === id ? '' : id)
              }}
            />
          </ContentContainer>
        )}

        <Buttons>
          {props.role && (
            <Banner
              startIcon={<AiSuggestionsIcon />}
              variant="ai"
              style={{
                width: '100%',
                display: 'grid',
                gridTemplateColumns: '30px auto 30px',
                textAlign: 'left',
              }}
              endIcon={
                orgSettings?.fields.hire_genie_enabled_assessment && (
                  <div style={{ display: 'flex', gap: '1rem' }}>
                    {!aiSuggestionsLoading && (
                      <i className="fas fa-sync-alt" style={{ cursor: 'pointer' }} onClick={getSuggestions}></i>
                    )}
                    {nameSuggestions && !aiSuggestionsLoading && (
                      <>
                        <Tooltip
                          text={
                            <>
                              {nameSuggestions.length} suggestions
                              {nameSuggestions.length > 0 && (
                                <ul>
                                  {nameSuggestions.map(s => (
                                    <li key={s}>{s}</li>
                                  ))}
                                </ul>
                              )}
                            </>
                          }
                        >
                          <i className="fas fa-info-circle" data-tip="" data-for="genie-suggestions"></i>
                        </Tooltip>
                      </>
                    )}
                  </div>
                )
              }
            >
              {aiSuggestionsLoading ? (
                <LoadingText text="AI is finding suggestions" />
              ) : !orgSettings?.fields?.hire_genie_enabled_assessment ? (
                <span>
                  To get AI suggestions, enable it in the{' '}
                  <a href="/settings/organization">
                    <u>organization settings</u>
                  </a>
                  .
                </span>
              ) : !props.role.description ? (
                <div>
                  To get AI suggestions, add a{' '}
                  <a href={`/roles/${props.role.id}/edit?step=details`}>
                    <u>job description</u>
                  </a>
                  .
                </div>
              ) : nameSuggestions.length === 0 ? (
                <div>No suggestions found. Please try regenerating the suggestions.</div>
              ) : (
                <div>
                  AI suggestions based on the{' '}
                  <a href={`/roles/${props.role.id}/edit?step=details`}>
                    <u>job description</u>
                  </a>
                  .
                </div>
              )}
            </Banner>
          )}
          <Button variant="tertiary" onClick={onModalCanceled}>
            Cancel
          </Button>
          <Button
            variant="purple-dark"
            onClick={onAddTests}
            disabled={subSelection.key() === props.selection.key() || !subSelection.valid}
            data-testid="test-library-modal-confirm-button"
          >
            {subSelection.size > 0 ? `Use ${subSelection.size} ${plural('test', subSelection.size)}` : `Use tests`}
          </Button>
        </Buttons>
      </ModalBox>
      {sidebarLibraryItem && (
        <PreviewSidebar
          libraryItemId={sidebarLibraryItem.id}
          organizationSlug={props.organizationSlug}
          title={sidebarLibraryItem.name}
          badge={{
            copy: sidebarLibraryItem.group_title,
            theme: sidebarLibraryItem.group_theme,
            icon: sidebarLibraryItem.group_icon,
          }}
          testDescription={sidebarLibraryItem.preview?.introduction || sidebarLibraryItem.description}
          durationSeconds={sidebarLibraryItem.duration_seconds}
          testType={sidebarQuestionTypeCopy?.name || ''}
          testTypeIcon={sidebarQuestionTypeCopy?.icon || ''}
          exampleQuestion={sidebarLibraryItem.preview.example_question}
          responseType={sidebarLibraryItem.preview.response_type}
          whenToUse={sidebarLibraryItem.preview.when_to_use}
          result={sidebarLibraryItem.preview.result_description}
          selection={subSelection}
          onConfiguredAction={onConfiguredAction}
          close={() => {
            setLibraryItemIDForPreview('')
          }}
        />
      )}
    </ModalContainer>
  )
}

export type LibraryGroup = {
  slug: string
  theme: BadgeTheme
  name: string
  icon: string
  library_item_count: number
}

type GroupCounts = {
  group_name: string
  count: number
}

export default ContentSelectionModal

// TODO: can these be merged with UI elements from form creation?
const questionTypeCopy = (questionType: QuestionResponseType): { name: string; icon: string } | undefined => {
  switch (questionType) {
    case 'chips':
      return { name: 'All that apply', icon: 'ballot' }
    case 'forced-choice':
    case 'forced-truth':
      return { name: 'Pairwise scale', icon: 'tachometer-alt-fast' }
    case 'likert-scale':
      return { name: 'Likert scale', icon: 'signal' }
    case 'optionset':
      return { name: 'Multiple choice', icon: 'list-ul' }
    case 'text-input':
      return { name: 'Free text', icon: 'text' }
    case 'video':
      return { name: 'Video upload', icon: 'video' }
    case 'typing':
      return { name: 'Typing speed', icon: 'type' }
  }
}
