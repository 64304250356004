import React, { ElementRef, useRef } from 'react'
import { useClickAway } from 'react-use'
import classes from './FloatingPromptCard.module.scss'
import { GrFormClose, GrFormPreviousLink } from 'react-icons/gr'
import { animated, useTransition } from '@react-spring/web'
import { Portal } from '../Portal/Portal'

interface FloatingPromptCardProps {
  open: boolean
  onClose: () => void
  canBack: boolean
  onBack: () => void
  prompt: React.ReactElement
  children: React.ReactNode
}

export const FloatingPromptCard = ({ open, onClose, canBack, onBack, ...props }: FloatingPromptCardProps) => {
  const fadeOverlay = useTransition(open, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 200 },
  })
  const expandFromPrompt = useTransition(open, {
    from: { opacity: 0, transform: 'scaleX(0.7) scaleY(0.8)', transformOrigin: '50% 90%' },
    enter: { opacity: 1, transform: 'scaleX(1) scaleY(1)', transformOrigin: '50% 90%' },
    leave: { opacity: 0, transform: 'scaleX(0.7) scaleY(0.8)', transformOrigin: '50% 90%' },
    config: { tension: 1300, friction: 55 },
  })

  const promptRef = useRef<ElementRef<'div'>>(null)
  const contentRef = useRef<ElementRef<'div'>>(null)
  useClickAway(contentRef, e => {
    // close click outside modal, except for prompt
    if (!promptRef.current?.contains(e.target as Element)) {
      onClose()
    }
  })

  const FadeOverlay: React.FC<{ children: React.ReactElement }> = props => {
    return fadeOverlay((styles, open) => {
      if (open)
        return (
          <animated.div className={classes.backgroundOverlay} style={styles}>
            {props.children}
          </animated.div>
        )
      return props.children
    })
  }

  return (
    <Portal label="floating-prompt">
      <FadeOverlay>
        <div className={classes.main}>
          {expandFromPrompt(
            (styles, open) =>
              open && (
                <animated.div
                  ref={contentRef}
                  className={`${classes.content} ${open && classes.shadow}`}
                  style={styles}
                >
                  <div className={classes.topRow}>
                    {canBack ? <GrFormPreviousLink size={24} onClick={() => onBack()} /> : <span></span>}
                    <GrFormClose size={24} onClick={() => onClose()} />
                  </div>
                  <div className={classes.contentRow}>{props.children}</div>
                  <div className={classes.bottomRow} />
                </animated.div>
              ),
          )}
          <div ref={promptRef}>
            {React.cloneElement(props.prompt, {
              className: `${classes.prompt} ${!open && classes.shadow}`,
            })}
          </div>
        </div>
      </FadeOverlay>
    </Portal>
  )
}
