import { Fields, QuestionnaireAnswerEntity, RESOURCE } from 'api/questionnaire-answers'
import { ResponseOptionEntity } from 'api/response-options'
import { questions } from 'selectors'
import { RootState } from 'store'
import { QUESTION_SLUGS } from 'store/new-role/content'
import lookup from './lookup'
import {
  findById as findQuestionnaireSubmissionById,
  findReferenceSubmissionsByCandidateId as findQuestionnaireSubmissionsByCandidateId,
} from './questionnaire-submissions'
import { findById as findQuestionById } from './questions'
import * as references from './references'
import * as responseOptions from './response-options'

function findById(state: RootState, id: string) {
  return lookup<Fields>(state, RESOURCE, id)
}

export function findByCandidateId(state: RootState, candidateId: string) {
  const questionnaireSubmissions = findQuestionnaireSubmissionsByCandidateId(state, candidateId)

  const answers: Record<string, Fields[] | undefined> = {}

  for (const [referenceId, questionnaireSubmission] of Object.entries(questionnaireSubmissions)) {
    if (!questionnaireSubmission) {
      continue
    }

    const answerIds = questionnaireSubmission.relationships?.filter(r => r.resource === RESOURCE).map(r => r.id)
    if (!answerIds) {
      continue
    }

    answers[referenceId] = answerIds
      .map(id => findById(state, id)?.fields)
      .filter(entity => entity !== undefined) as Fields[]
  }

  return answers
}

function findByReferenceId(state: RootState, referenceId: string) {
  const reference = references.findById(state, referenceId)
  if (!reference) return

  const answers = findByCandidateId(state, reference.fields.candidate_id)
  return answers[referenceId]
}

export function findAnswerByReferenceId(state: RootState, referenceId: string, questionId: string) {
  const answers = findByReferenceId(state, referenceId)
  if (!answers) return

  // I'm not entirely sure what the best way to deal with one selector
  // finding a response and the matching self-assessment response is.
  // currently we just have a big lookup.

  // deal with happy path, question found right away
  return answers.filter(ans => ans.question_id === questionId)
}

export function findByQuestionnaireSubmissionId(
  state: RootState,
  questionnaireSubmissionId: string,
): QuestionnaireAnswerEntity[] {
  const questionnaireSubmission = findQuestionnaireSubmissionById(state, questionnaireSubmissionId)
  if (!questionnaireSubmission) return []

  const answerIds = questionnaireSubmission.relationships?.filter(r => r.resource === RESOURCE).map(r => r.id)
  if (!answerIds) return []

  return answerIds.map(id => findById(state, id)).filter(entity => entity !== undefined) as QuestionnaireAnswerEntity[]
}

export function findSelectedOptionsByReferenceId(state: RootState, referenceId: string, questionId: string) {
  const answers = findAnswerByReferenceId(state, referenceId, questionId)
  if (!answers || !answers.length) return undefined

  return answers
    .map(ans => responseOptions.findById(state, ans.selected_response_option))
    .filter(ans => ans !== undefined) as ResponseOptionEntity[]
}

function findSlugAnswersByReferenceId(state: RootState, referenceId: string, slug: QUESTION_SLUGS) {
  const allAnswers = findByReferenceId(state, referenceId)
  if (!allAnswers) return

  const answers: Fields[] = []
  for (const answer of allAnswers) {
    const question = findQuestionById(state, answer.question_id)
    if (question?.fields.slug === slug) {
      answers.push(answer)
    }
  }

  return answers.filter(ans => ans !== undefined) as Fields[]
}

export function findSlugSelectedOptionsByReferenceId(state: RootState, referenceId: string, slug: QUESTION_SLUGS) {
  const answers = findSlugAnswersByReferenceId(state, referenceId, slug)
  if (!answers) return

  return answers
    .map(ans => responseOptions.findById(state, ans.selected_response_option))
    .filter(ans => ans !== undefined) as ResponseOptionEntity[]
}

export function findDimensionScoresBySubmissionId(state: RootState, submissionId: string) {
  const answers = findByQuestionnaireSubmissionId(state, submissionId)
  if (!answers || !answers.length) return

  const counts: {
    overall: number[]
    role: number[]
    team: number[]
    organization: number[]
  } = { overall: [], role: [], team: [], organization: [] }

  for (const answer of answers) {
    if (!answer) continue

    const value = responseOptions.findById(state, answer.fields.selected_response_option)?.fields.value
    if (value === undefined) continue

    const question = questions.findById(state, answer.fields.question_id)
    if (!question) continue

    const dimension = question.fields.properties.meta?.onboarding?.dimension as string
    if (!dimension) continue

    counts[dimension].push(value)
    counts.overall.push(value)
  }

  return counts
}
