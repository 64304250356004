import * as api from 'api'
import { breakpoints, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Relationship } from 'selectors/references'

const Container = style()
  .nooverflow()
  .whitespace('nowrap')
  .text({ ellipsis: true })
  .screen(breakpoints.mobile, style().hidden())
  .element()
const Name = text.bodyInteractive().fg(designSystemColors.typographySecondary).element('span')

interface Props {
  reference?: api.request.Entity<api.references.Fields>
  emoji: string
  relationship: Relationship
}

const Component = (props: Props) => {
  const { t } = useTranslation()
  return (
    <Container>
      {props.emoji}{' '}
      <Name>
        {t('candidate-dashboard-intro.reference.type-' + props.relationship.trim(), props.relationship.trim())}
      </Name>
    </Container>
  )
}

export default Component
Component.displayName = 'ColumnType'
