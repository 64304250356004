import { fr, px2rem, style } from 'core'
import React from 'react'
import TextResponsesCard from './TextResponsesCard'

const TextResponseCardsContainer = style()
  .grid({ columns: [fr(1), fr(1), fr(1)] })
  .spacing({ gap: px2rem(16) })
  .element()

type CardItems = { quote: string }[]

interface ResponseHighlightCardsProps {
  highlightCardsData: {
    best: CardItems
    improvements: CardItems
    notCovered: CardItems
    noResponsesYet: CardItems
  }
}

const DETAILS_URL = '/candidateexperience/feedback'

const ResponseHighlightCards: React.FC<ResponseHighlightCardsProps> = ({ highlightCardsData }) => {
  return (
    <TextResponseCardsContainer>
      <TextResponsesCard
        testimonies={highlightCardsData.best || highlightCardsData.noResponsesYet}
        title={'Best things about the hiring process'}
        emoji={'🏅'}
        emojiColor={'blue'}
        detailsUrl={DETAILS_URL}
      />
      <TextResponsesCard
        testimonies={highlightCardsData.improvements || highlightCardsData.noResponsesYet}
        title={'Improvements for the hiring process'}
        emoji={'🛠'}
        emojiColor={'green'}
        detailsUrl={DETAILS_URL}
      />
      <TextResponsesCard
        testimonies={highlightCardsData.notCovered || highlightCardsData.noResponsesYet}
        title={'Anything that was not covered?'}
        emoji={'🙅'}
        emojiColor={'purple'}
        detailsUrl={DETAILS_URL}
      />
    </TextResponseCardsContainer>
  )
}

export default ResponseHighlightCards
