import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal, { Content } from '../../components/Modal'
import TextField from '../../components/TextField'
import { ContentAndButtons, Form } from '../../components/Wizard'
import Buttons from '../../components/Wizard/Buttons'
import { Subheading } from '../../components/Wizard/Typography'
import { breakpoints, rem, style, vw } from '../../core'
import * as selectors from '../../selectors'
import { RootState } from '../../store'
import * as slice from '../../store/references'

interface Props {
  referenceId: string
  open: boolean
  setOpen: (b: boolean) => void
}

const Container = style()
  .size({
    maxHeight: rem(25),
    width: rem(40),
    height: rem(22),
  })
  .screen(breakpoints.mobile, style().size({ width: vw(100) }))
  .element()

const Component = (props: Props) => {
  const dispatch = useDispatch()

  const [reference, loading]: [ReturnType<typeof selectors.references.findById>, boolean] = useSelector(
    (state: RootState) => [selectors.references.findById(state, props.referenceId), state.references.loading],
  )

  const [referenceEmail, setReferenceEmail] = useState('')
  const [referencePhoneNumber, setReferencePhoneNumber] = useState('')

  const updated = reference?.fields.email !== referenceEmail || reference?.fields.phone_number !== referencePhoneNumber

  useEffect(() => {
    if (reference?.fields.email) {
      setReferenceEmail(reference.fields.email)
    }
    if (reference?.fields.phone_number) {
      setReferencePhoneNumber(reference.fields.phone_number)
    }
  }, [reference])

  const firstName = reference ? selectors.references.firstNameOf(reference) : ''
  const invalid = (() => {
    if (referenceEmail) return !selectors.isEmailValid(referenceEmail)
    return !referencePhoneNumber
  })()

  return (
    <Modal title={`Update ${selectors.possessiveFirstName(firstName)} email`} open={props.open} setOpen={props.setOpen}>
      <Container>
        <ContentAndButtons>
          <Content>
            <Subheading>This will resend the invitation to the new email.</Subheading>
            <Form>
              <TextField
                label="✉️  New email address"
                error={!!referenceEmail && !selectors.isEmailValid(referenceEmail)}
                value={referenceEmail}
                placeholder="Enter a valid email address"
                onChange={e => setReferenceEmail(e.target.value)}
              />
              <TextField
                label="📞 New phone number (with international code)"
                value={referencePhoneNumber}
                placeholder="Enter a valid phone number"
                onChange={e => setReferencePhoneNumber(e.target.value)}
              />
            </Form>
          </Content>
          <Buttons
            next={() =>
              reference && updated
                ? dispatch(
                    slice.update(props.referenceId, {
                      ...reference.fields,
                      email: referenceEmail,
                      phone_number: referencePhoneNumber,
                    }),
                  )
                : props.setOpen(false)
            }
            back={() => {}}
            label={updated ? 'Update' : 'Done'}
            disabled={invalid}
            loading={loading}
            forwardOnly
          ></Buttons>
        </ContentAndButtons>
      </Container>
    </Modal>
  )
}

export default Component
Component.displayName = 'UpdateEmailModal'
