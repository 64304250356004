import { EvaluationStatus } from 'api/candidates'

const EvaluationStatusText: Record<EvaluationStatus, string> = {
  'to-review': 'To review',
  reject: 'Reject',
  unclear: 'Unclear',
  shortlist: 'Shortlist',
  exceptional: 'Exceptional',
}

export function evaluationStatusToText(status: EvaluationStatus) {
  return EvaluationStatusText[status] || String(status)
}

export const allEvaluationStatuses: EvaluationStatus[] = ['to-review', 'reject', 'shortlist', 'unclear', 'exceptional']
