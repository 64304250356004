import action from './action'
import events from './events'
import track from './track'

export const sidebarClicked = action(() => {
  track(events.SALES_POOL_SIDEBAR_CLICKED, {})
})

export const referenceEmailClicked = action(() => {
  track(events.SALES_POOL_REFERENCE_EMAIL_CLICKED, {})
})

export const sidebarCreateReferenceCheckClicked = action(() => {
  track(events.SALES_POOL_SIDEBAR_CREATE_REFERENCE_CHECK_CLICKED, {})
})
