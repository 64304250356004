import { px2rem, style } from 'core'
import React from 'react'
import { Link } from 'react-router-dom'
import ContainerCard, { ContainerCardProps } from '../../components/Container/ContainerCard'
import { TestimonialBlock, TestimonialBlockProps } from '../Candidate/Summary/TestimonialBlock'

const TextResponsesCardStyle = style().block().sans({ nodecoration: true }).elementFromComponent(Link)

const Content = style()
  .size({ height: px2rem(232), minHeight: px2rem(232) })
  .element()

interface TextResponsesCardProps extends ContainerCardProps, TestimonialBlockProps {
  detailsUrl?: string
}

const TextResponsesCard: React.FC<TextResponsesCardProps> = ({ ...props }) => {
  return (
    <TextResponsesCardStyle to={props.detailsUrl || ''}>
      <ContainerCard title={props.title} emoji={props.emoji} emojiColor={props.emojiColor}>
        <Content>
          <TestimonialBlock testimonies={props.testimonies} />
        </Content>
      </ContainerCard>
    </TextResponsesCardStyle>
  )
}

export default TextResponsesCard
