import * as Sentry from '@sentry/react'

export function sendLogMessage(message: string, extra: any, tags?: Record<string, any>) {
  Sentry.withScope(scope => {
    scope.setExtra('extra', extra)
    if (tags) {
      scope.setTags(tags)
    }
    Sentry.captureMessage(message)
  })
}
