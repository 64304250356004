import { Column } from 'components/ContentGrid'
import { Icon } from 'components/Icons'
import { px2rem, size, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React from 'react'

const Table = style()
  .border({
    around: `1px solid`,
    color: designSystemColors.borderDefault,
    radius: px2rem(8),
  })
  .set('borderSpacing', '0px')
  .size({ width: size.fill })
  .nooverflow()
  .element('table')

const Row = style()
  .grid({
    columns: [px2rem(14), size.auto, px2rem(60), px2rem(8)],
  })
  .element('tr')

const Title = text.secondaryBodyInteractive().element('th')

const QuestionInfo = style()
  .flex()
  .spacing({ gap: px2rem(8) })
  .element()
const QuestionNumber = text.bodyText().element('p')
const QuestionText = text.bodyEmphasis().element('span')

const ActionIconsContainer = style().flex().element()

const DeleteQuestionButton = style()
  .color({ fg: designSystemColors.typographySecondary })
  .noborders()
  .block()
  .bg({ color: designSystemColors.transparent })
  .pointer()
  .element('button')

const EditQuestionButton = style()
  .color({ fg: designSystemColors.typographySecondary })
  .noborders()
  .block()
  .bg({ color: designSystemColors.transparent })
  .pointer()
  .element('button')

interface CustomQuestionListProps {
  customQuestions: {
    text: string
  }[]
  removeCustomQuestion: (index: number) => void
  editCustomQuestion: (index: number) => void
}
const CustomQuestionList: React.FC<CustomQuestionListProps> = ({
  customQuestions,
  removeCustomQuestion,
  editCustomQuestion,
}) => {
  return (
    <Table>
      <Row>
        <Column head />
        <Column head>
          <Title>Custom question</Title>
        </Column>
        <Column head />
        <Column head />
      </Row>
      {customQuestions.map((question, i) => (
        <Row key={`${question.text}-${i}`}>
          <Column />
          <Column centerVert>
            <QuestionInfo>
              <QuestionNumber>{`${i + 1}.`}</QuestionNumber>
              <QuestionText>{question.text}</QuestionText>
            </QuestionInfo>
          </Column>
          <Column centerVert>
            <ActionIconsContainer>
              <EditQuestionButton onClick={() => editCustomQuestion(i)}>
                <Icon name="edit" ariaLabel="edit question" />
              </EditQuestionButton>
              <DeleteQuestionButton onClick={() => removeCustomQuestion(i)}>
                <Icon name="times" ariaLabel="remove question" />
              </DeleteQuestionButton>
            </ActionIconsContainer>
          </Column>
          <Column />
        </Row>
      ))}
    </Table>
  )
}

export default CustomQuestionList
