import * as tracking from 'core/track'
import React, { Dispatch, SetStateAction } from 'react'
import { DateRangeOption, DateRangePreset } from '../../App/ExperienceFeedback'
import DropdownField, { Option } from '../DropdownField'

interface DateRangeDropdownProps {
  selected: DateRangePreset
  setRange: Dispatch<SetStateAction<DateRangePreset>>
  ranges: DateRangePreset[]
}

const DateRangeDropdown: React.FC<DateRangeDropdownProps> = ({ selected, setRange, ranges }) => {
  return (
    <DropdownField<DateRangeOption>
      label={'Date'}
      placeholder={'Select range'}
      onChange={dateOption => {
        const range = ranges.find(range => range.name === dateOption)
        if (range) {
          setRange(range)
          tracking.candidateExperience.candidateExperienceDateRangeSelected(range.name)
        }
      }}
      value={selected.name}
    >
      {ranges.map(range => (
        <Option key={range.name} value={range.name}>
          {range.name}
        </Option>
      ))}
    </DropdownField>
  )
}

export default DateRangeDropdown
