import { Button } from '@common/components'
import { px2rem, style } from 'core'
import { text } from 'core/design-system/text'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Fields } from '../../api/images'
import { Entity } from '../../api/request'
import FileUpload from '../../components/FileUpload'
import { designSystemColors } from '../../core/design-system/colors'
import * as tracking from '../../core/track'
import { uploadImage } from '../../store/images'
import { PublicIntroScreen } from '../CandidateOnly/ManageReferences/PublicIntroScreen'
import UpdateRoleTextModal from '../CandidateOnly/UpdateRoleTextModal'
import { Buttons, Container, Title } from './Shared'
import { RootState } from 'store'
import * as selectors from 'selectors'

const InnerContainer = style()
  .spacing({ outerBottom: px2rem(32) })
  .flex({
    direction: 'column',
    alignItems: 'center',
  })
  .element()

const Description = text
  .bodyText()
  .sans({ align: 'center' })
  .spacing({ outerBottom: px2rem(32) })
  .element('p')

const Heading = style()
  .text({
    align: 'center',
  })
  .spacing({
    outerBottom: px2rem(25),
  })
  .element()

const HeadingLink = text.bodyText().text({ underline: true, nodecoration: true, weight: '500' }).pointer().element('a')

const HeadingText = text.bodyText().text({ weight: '500' }).element('a')

const previewHeightInPx = 900

const IntroScreenContainer = style()
  .size({
    width: '1700px',
    height: `${previewHeightInPx}px`,
  })
  .border({
    radius: '1rem',
    around: `1px solid ${designSystemColors.borderDefault}`,
  })
  .spacing({
    outer: `calc(-${previewHeightInPx}px / 4) 0 calc(-${previewHeightInPx}px / 4) 0`,
  })
  .nooverflow()
  .scale(0.5)
  .element()

export type RoleCustomizationValues = {
  customCandidatePortalText?: string
  customCandidatePortalImageUrl?: string
}

interface RoleCreationDetailsProps {
  values: RoleCustomizationValues
  onChange: React.Dispatch<React.SetStateAction<RoleCustomizationValues>>
  submit: () => any
  back: () => any
  isEditModeEnabled?: boolean
  orgLogoUrl: string
  roleName: string
}

export const RoleCustomization: React.FC<RoleCreationDetailsProps> = props => {
  const dispatch = useDispatch()

  const orgId = useSelector((state: RootState) => selectors.orgs.currentId(state))

  const [lastUploadedImageData, setLastUploadedImageData] = useState<Entity<Fields> | undefined>()
  const brandingImageUrl =
    lastUploadedImageData && lastUploadedImageData.fields.source_link === props.values.customCandidatePortalImageUrl
      ? lastUploadedImageData.fields.presigned_link
      : props.values.customCandidatePortalImageUrl

  const doUploadImage = async (file: File) => {
    const uploadedImageData = (await dispatch(uploadImage(file))) as any as Entity<Fields>
    setLastUploadedImageData(uploadedImageData)
    props.onChange({
      ...props.values,
      customCandidatePortalImageUrl: uploadedImageData.fields.source_link,
    })
  }

  const [fileToUpload, setFileToUpload] = useState<File | undefined>()
  useEffect(() => {
    if (fileToUpload) {
      doUploadImage(fileToUpload)
    }
  }, [fileToUpload])

  const [updateTextModalOpen, setUpdateTextModalOpen] = useState(false)
  return (
    <Container>
      <Title data-testid="role-creation-customization">{'Customize candidate portal page'}</Title>
      <Description>{`This is what the candidates will see upon invitation.`}</Description>
      <InnerContainer>
        <Heading>
          <HeadingLink
            onClick={() => {
              tracking.roles.roleCreationEditTextSelected()
              setUpdateTextModalOpen(true)
            }}
          >
            Edit text
          </HeadingLink>
          <HeadingText> or </HeadingText>
          <FileUpload
            onFileSelected={f => {
              tracking.roles.roleCreationUploadImage()
              setFileToUpload(f)
            }}
          >
            <HeadingLink>upload a custom image</HeadingLink>
          </FileUpload>
          {props.values.customCandidatePortalImageUrl && <HeadingText> or </HeadingText>}
          {props.values.customCandidatePortalImageUrl && (
            <HeadingLink
              onClick={() => {
                tracking.roles.roleCreationEditImageRemoved()
                props.onChange({
                  ...props.values,
                  customCandidatePortalImageUrl: '',
                })
              }}
            >
              remove image
            </HeadingLink>
          )}
        </Heading>
        <IntroScreenContainer>
          <PublicIntroScreen
            orgId={orgId || ''}
            roleName={props.roleName}
            orgLogoUrl={props.orgLogoUrl}
            brandingText={props.values.customCandidatePortalText || ''}
            brandingImageUrl={brandingImageUrl}
          />
        </IntroScreenContainer>
      </InnerContainer>
      <Buttons>
        <Button variant="secondary" onClick={props.back}>
          {'Back'}
        </Button>
        <Button variant="purple-dark" onClick={() => props.submit()}>
          {props.isEditModeEnabled ? 'Save job' : 'Create job'}
        </Button>
      </Buttons>
      {updateTextModalOpen && (
        <UpdateRoleTextModal
          text={props.values.customCandidatePortalText || ''}
          open={updateTextModalOpen}
          setOpen={setUpdateTextModalOpen}
          onConfirm={newText => {
            tracking.roles.roleCreationTextUpdated()
            props.onChange({
              ...props.values,
              customCandidatePortalText: newText,
            })
          }}
        ></UpdateRoleTextModal>
      )}
    </Container>
  )
}
