import { useSelector } from 'react-redux'
import { findReferenceSubmissionsByCandidateId } from 'selectors/questionnaire-submissions'
import { RootState } from 'store'

export function useReferenceQuestionnaireSubmissions(query: { candidateId: string }) {
  const { candidateId } = query

  const questionnaireSubmissions = useSelector((state: RootState) =>
    findReferenceSubmissionsByCandidateId(state, candidateId),
  )

  return {
    questionnaireSubmissions,
  }
}
