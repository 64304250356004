import { Banner, LockedModal, LockIcon, NotificationIcon, Row, Table, Text, Tooltip } from '@common/components'
import { Icon } from 'components/Icons'
import ProfileImage from 'components/ProfileImage'
import * as tracking from 'core/track'
import { lockedContentTracking } from 'core/track/locked-content'
import { differenceInDays } from 'date-fns'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import * as selectors from 'selectors'
import { Role } from 'selectors/roles'
import styles from './JobsTable.module.scss'
import { ATSTooltipContent } from './ATSTooltipContent'

interface Props {
  loadMoreRoles: () => any
  roles: Role[]
  hasMoreRole: boolean
  onSort: (key: string, direction: string) => void
}

export const JobsTable: React.FC<Props> = props => {
  const headers = [
    { key: 'name', name: 'Name', sortable: true },
    { key: 'candidates', name: 'Candidates' },
    { key: 'updated_at', name: 'Last activity', sortable: true },
    { key: 'created_at', name: 'Created at', sortable: true },
    { key: 'created_by', name: 'Created by' },
  ]

  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false)
  const isOrgPaidPlan = useSelector(selectors.orgs.isOrgPaidPlan)

  const getNotifications = (role: Role) => {
    const notifications: React.ReactNode[] = []
    if (!role.fields.ats_id) return notifications

    const isAssessmentRole = role.fields.ask_self_assessment
    const isReferenceCheckRole = role.fields.ask_reference_check
    const hasAssessment = role.fields.self_assessment_preset_id
    const hasReferenceCheck = role.fields.reference_check_preset_id

    if (!isAssessmentRole && !isReferenceCheckRole) {
      notifications.push(
        <Text key="notification-1" variant="body-text" style={{ color: 'white' }}>
          Role still needs to be set up. Click{' '}
          <Link to={`/roles/${role.id}/edit`} style={{ color: 'white' }}>
            here
          </Link>{' '}
          to get started
        </Text>,
      )
    }
    if (!hasAssessment && !hasReferenceCheck) {
      notifications.push(
        <Text key="notification-2" variant="body-text" style={{ color: 'white' }}>
          You need to{' '}
          <Link
            to={`/templates?product=${isAssessmentRole ? 'self-assessment' : 'reference-check'}&roleid=${
              role.id
            }&mode=${isAssessmentRole && isReferenceCheckRole ? 'template' : 'single'}`}
            style={{ color: 'white' }}
          >
            set up an assessment and/or reference check
          </Link>{' '}
          for this role
        </Text>,
      )
    }

    return notifications
  }

  return (
    <>
      <Table
        onSort={props.onSort}
        headers={headers}
        onReachBottom={() => {
          if (props.hasMoreRole) {
            props.loadMoreRoles()
          }
        }}
        testId="jobs-table"
      >
        {props.roles.map(role => {
          const isCreatedMoreThan7DaysAgo = differenceInDays(new Date(), role.created_at) > 7
          const isLocked = !isOrgPaidPlan && isCreatedMoreThan7DaysAgo
          const notifications = getNotifications(role)
          const isAtsJob = role.fields.ats_id

          return (
            <Row
              key={'row-key-' + role.id}
              id={'row-id-' + role.id + role.title}
              cells={[
                <div className={styles.nameWrapper} key={`${role.title}-${role.id}-title`}>
                  <div className={styles.title}>
                    {isLocked ? (
                      <Tooltip
                        direction={'right'}
                        text={`Free access lapsed`}
                        style={{
                          verticalAlign: 'text-top',
                          display: 'flex',
                          alignItems: 'center',
                          width: 'fit-content',
                        }}
                      >
                        <Text
                          variant="body-emphasis"
                          className={styles.jobTitle}
                          onClick={() => setUpgradeModalOpen(true)}
                        >
                          {role.title}
                        </Text>
                        <LockIcon />
                      </Tooltip>
                    ) : role.fields.ats_id && notifications.length > 0 ? (
                      <Text variant="body-emphasis" className={styles.jobTitle}>
                        {role.title}
                      </Text>
                    ) : (
                      <Link
                        to={`/roles/${role.id}`}
                        className={styles.jobLink}
                        onClick={() => tracking.roles.view({ name: role.title })}
                      >
                        <Text variant="body-emphasis" className={styles.jobTitle}>
                          {role.title}
                        </Text>
                      </Link>
                    )}
                    {isAtsJob && (
                      <Tooltip
                        text={<ATSTooltipContent role={role} />}
                        disabled={!role.fields.ats_id}
                        style={{ verticalAlign: 'text-top' }}
                      >
                        <Banner
                          variant="success"
                          style={{ padding: '0.25rem', whiteSpace: 'nowrap', cursor: 'help', marginLeft: '0.5rem' }}
                        >
                          <Text variant="small-body">
                            <Icon
                              name="circle"
                              ariaLabel="ATS Linked"
                              style={{
                                transform: 'scale(0.5)',
                              }}
                            />
                            ATS Imported
                          </Text>
                        </Banner>
                      </Tooltip>
                    )}
                  </div>
                  {notifications.length > 0 && <NotificationIcon notifications={notifications} hide={false} />}
                </div>,
                role.candidate_count,
                role.updated_at.toLocaleDateString(),
                role.created_at.toLocaleDateString(),
                <div key={'createdby-' + role.id} className={`${styles.createdBy} data-hj-suppress`}>
                  <div className={styles.createdByImageContainer}>
                    <ProfileImage
                      src={role.created_by.avatar_img}
                      name={role.created_by.full_name}
                      nocircle={true}
                      noPadding={true}
                    />
                  </div>
                  <Text variant="small-body">{role.created_by.full_name}</Text>
                </div>,
              ]}
            />
          )
        })}
      </Table>
      <LockedModal
        open={upgradeModalOpen}
        setOpen={setUpgradeModalOpen}
        tracking={lockedContentTracking}
        modalData={{
          title: 'Free access lapsed',
          content: (
            <Text variant="body-text">
              Upgrade your plan to get access to historic assessments and reference checks.
            </Text>
          ),
          learnMoreAbout: 'Locked roles',
        }}
      />
    </>
  )
}
