import { put } from './methods'
import { APIResponse, Entity } from './request'

export const RESOURCE = 'user_permissions'

export interface Permissions {
  can_create_role: boolean
  can_manage_content: boolean
  can_provide_support: boolean
}

export interface Fields extends Permissions {
  id: string
  user_id: string
  created_at: number
  updated_at: number
  is_active: boolean
}

export function empty(id: string): Entity<Fields> {
  return {
    id,
    resource: RESOURCE,
    fields: {
      id,
      user_id: '',
      can_create_role: false,
      can_manage_content: false,
      can_provide_support: false,
      created_at: 0,
      updated_at: 0,
      is_active: false,
    },
  }
}

interface UpdatePayload {
  updates: UpdatePayloadPermissions
  updated: string[]
}

export interface UpdatePayloadPermissions {
  can_create_role?: boolean
  can_manage_content?: boolean
  can_provide_support?: boolean
}

export function updateUserPermissions(userId: string, body: UpdatePayload): Promise<APIResponse<Entity<Fields>>> {
  return put<Entity<Fields>>(`users/${userId}/permissions`, body)
}
