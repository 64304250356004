import { combineReducers } from '@reduxjs/toolkit'
import salesPool from 'store/sales-pool'
import assessments from './assessments'
import backgroundcheckintegrations from './backgroundcheckintegrations'
import candidateExperience from './candidate-experience'
import candidateManagement from './candidate-management'
import candidates from './candidates'
import employees from './employees'
import genericassessments from './genericassessments'
import integrations from './integrations'
import login from './login'
import modules from './modules'
import newRole from './new-role'
import notifications from './notifications'
import onboarding from './onboarding'
import oneTimePassword from './one-time-password'
import openjobroles from './openjobroles'
import createOrganization from './organizations'
import referenceManagement from './reference-management'
import references from './references'
import reports from './reports'
import requestPasswordReset from './resetpassword/request-password-reset'
import resetPassword from './resetpassword/reset-password'
import resources from './resources'
import roleUserManagement from './role-user-management'
import roles from './roles'
import sessions from './sessions'
import accountSettings from './settings/account'
import addUserToTeam from './settings/add-user-to-team'
import createTeam from './settings/create-team'
import gdprSettings from './settings/gdpr'
import invite from './settings/invite'
import orgSettings from './settings/org'
import orgManagement from './settings/org-management'
import passwordSettings from './settings/password'
import updateTeam from './settings/update-team'
import userPermissions from './settings/user-management'
import skills from './skills'
import talentPool from './talent-pool'
import updateReferences from './update-references'
import webhooks from './webhooks'

const rootState = combineReducers({
  assessments,
  genericassessments,
  sessions,
  resources,
  login,
  openjobroles,
  roleUserManagement,
  notifications,
  candidates,
  accountSettings,
  passwordSettings,
  orgManagement,
  gdprSettings,
  invite,
  addUserToTeam,
  userPermissions,
  createTeam,
  updateTeam,
  webhooks,
  requestPasswordReset,
  resetPassword,
  createOrganization,
  orgSettings,
  newRole,
  updateReferences,
  references,
  reports,
  integrations,
  backgroundcheckintegrations,
  candidateManagement,
  referenceManagement,
  talentPool,
  salesPool,
  oneTimePassword,
  skills,
  modules,
  onboarding,
  candidateExperience,
  roles,
  employees,
})

export type RootState = ReturnType<typeof rootState>

export default rootState
