import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import {
  findOverallPerformanceResponsesByCandidateId,
  findSkillsetResponsesByCandidateId,
} from 'selectors/candidate-summaries'
import { RootState } from 'store'

export function useSkillQuestions(query: { candidateId: string }) {
  const { candidateId } = query
  const selector = useCallback(
    (state: RootState) => findSkillsetResponsesByCandidateId(state, candidateId),
    [candidateId],
  )
  const skills = useSelector(selector)

  return { skills }
}

export function useOverallPerformanceQuestions(query: { candidateId: string }) {
  const { candidateId } = query
  const selector = useCallback(
    (state: RootState) => findOverallPerformanceResponsesByCandidateId(state, candidateId),
    [candidateId],
  )
  const overallPerformances = useSelector(selector)

  return { overallPerformances }
}
