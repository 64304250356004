import { openjobroles } from '../../api'
import { RootState } from '../../store'
import action from './action'
import events from './events'
import track, { incr, log } from './track'

interface Props {
  roleName?: string
}

export const playVideo = action((props: Props) => {
  track(events.VIDEO_PLAYED, {
    'Role name': props.roleName || '',
  })

  log(events.VIDEO_PLAYED)
  incr(events.VIDEO_PLAYED_LIFETIME)
})
export const playAudio = action(() => {
  track(events.AUDIO_PLAYED, {})

  log(events.AUDIO_PLAYED)
  incr(events.AUDIO_PLAYED_LIFETIME)
})

export const downloadFile = action((props: Props) => {
  track(events.FILE_DOWNLOADED, {
    'Role name': props.roleName || '',
  })

  log(events.FILE_DOWNLOADED)
  incr(events.FILE_DOWNLOADED_LIFETIME)
})

export function extractPropsFromState(state: RootState, candidateId: string): Props | null {
  const role = openjobroles.findByCandidateId(state, candidateId)
  if (!role) return null

  return {
    roleName: role.fields.name,
  }
}
