import React from 'react'
import classes from './Container.module.scss'

interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
}

/**
 * A container component that centers its children
 */
export const Container = (props: ContainerProps) => {
  return (
    <div {...props} className={classes.container}>
      {props.children}
    </div>
  )
}
