import React from 'react'
import { PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, ResponsiveContainer } from 'recharts'
import * as selectors from '../../selectors'
import colors from './colors'

const WIDTH = '90%'
const HEIGHT = 200
const MARGIN = {
  top: 5,
  right: 40,
  left: 0,
  bottom: 5,
}

interface Props {
  data: selectors.charts.Data
  options: selectors.charts.chart.RadarChartOptions
}

const Component = (props: Props) => {
  return (
    <ResponsiveContainer width={WIDTH} height={HEIGHT}>
      <RadarChart outerRadius={HEIGHT / 2.5} data={props.data} margin={MARGIN}>
        <PolarGrid />
        <PolarAngleAxis dataKey={props.options.key} />
        <PolarRadiusAxis angle={30} domain={props.options.domain} />
        {props.options.radars.map((key, ind) => (
          <Radar key={key} dataKey={key} stroke={colors[ind]} fill={colors[ind]} fillOpacity={0.6} />
        ))}
      </RadarChart>
    </ResponsiveContainer>
  )
}

export default Component
Component.displayName = 'RadarChart'
