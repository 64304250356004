import { Button } from '@common/components'
import { isEmailDomainFree } from 'api/orgs'
import { px2rem, space, style } from 'core'
import { Rules, Validator } from 'core/validation/validator'
import React, { useState } from 'react'
import TextField from '../../components/TextField'
import * as tracking from '../../core/track'
import { StartFreeTrialValues } from '../SignUp/StartFreeTrial'

import { Link } from 'react-router-dom'

const FormContainer = style()
  .grid()
  .spacing({ outer: [space.m, space.none], rows: space.m })
  .size({ width: '100%' })
  .element()

const Buttons = style()
  .flex({ alignItems: 'center', justifyContent: 'center' })
  .spacing({ gap: px2rem(16) })
  .element()

interface SignupFormProps {
  values: StartFreeTrialValues
  onChange: React.Dispatch<React.SetStateAction<StartFreeTrialValues>>
  next: () => any
}

const emailValidator = new Validator([Rules.validEmailAddress()])

export const SignupForm: React.FC<SignupFormProps> = ({ values, onChange, next }) => {
  const [hasTracked, setHasTracked] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const errorMessages = {
    'non-valid-work-email': (
      <>
        A valid work email is required - read why{' '}
        <a
          href="https://intercom.help/hipeople/en/articles/8679937-why-we-require-a-valid-work-email"
          target="_blank"
          rel="noreferrer"
          style={{ color: 'inherit' }}
        >
          here
        </a>
        .
      </>
    ),
    'domain-already-registered': (
      <>
        Your company is already registered with us. Do you want to login? Click{' '}
        <Link
          to="login"
          style={{
            color: 'inherit',
          }}
        >
          here
        </Link>
        .
      </>
    ),
    'company-already-registered': (
      <>
        Your company is already registered with us. Do you want to login? Click{' '}
        <Link
          to="login"
          style={{
            color: 'inherit',
          }}
        >
          here
        </Link>
        .
      </>
    ),
  }

  const onNextButtonClicked = async () => {
    const validation = emailValidator.validate(values.emailAddress)
    if (!validation.isValid) {
      setError(validation.getError)
      return
    }

    const [, errors] = await isEmailDomainFree(values.emailAddress)
    if (errors) {
      setError(errorMessages[errors[0].message] ?? errors[0].message)
      return
    }
    // Since we can use the browser buttons to go back to this component,
    // remember that we already tracked this for the current page.
    if (!hasTracked) {
      tracking.signUp.signUpProvidedWorkEmail()
      setHasTracked(true)
    }
    next()
  }

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(values => ({
      ...values,
      emailAddress: e.target.value,
    }))
    setError(null)
  }

  return (
    <FormContainer>
      <TextField
        autoFocus
        aria-label="Work email"
        label="Work email"
        placeholder="Enter your work email"
        value={values.emailAddress}
        onChange={onChangeEmail}
        error={!!error}
        errorLabel={error}
        testId="signup-work-email"
      />
      <Buttons>
        <Button variant="purple-dark" rounded onClick={onNextButtonClicked} style={{ width: '100%' }}>
          {'Continue with email'}
        </Button>
      </Buttons>
    </FormContainer>
  )
}
