import DropdownField, { Option } from 'components/DropdownField'
import { Icon } from 'components/Icons'
import TextField, { BaseLabel } from 'components/TextField'
import { Subheading } from 'components/Wizard/Typography'
import { colors, fr, rem, size, space, style } from 'core'
import React from 'react'
import { useSelector } from 'react-redux'
import * as selectors from 'selectors'
import { Relationship, relationshipCopy, RequiredReference } from 'selectors/references'
import { RootState } from 'store'
import CreateButton from '../CreateButton'

const Footer = style()
  .grid({ columns: [size.auto, rem(11), size.auto] })
  .element()

const Empty = style().element()

const FormatIcon = style()
  .text({ size: rem(1.2) })
  .color({ fg: colors.lightSilver })
  .round('5px')
  .element('header')

const Container = style()
  .grid({ columns: [size.auto, fr(1), fr(1), rem(1)], align: 'center' })
  .spacing({
    columns: space.s,
    rows: space.xs,
    outerTop: space.m,
    outerBottom: space.m,
  })
  .element()

const AliasContainer = style().size({ height: size.fill }).element()
const CloseIcon = style().color({ fg: colors.gray }).pointer().element()
const Label = BaseLabel.element()

interface Props {
  requiredReferences: RequiredReference[]
  onChangeRelationship: (i: number, relationship: Relationship) => {}
  onChangeDescription: (i: number, description: string) => {}
  onAdd: () => {}
  onRemove: (i: number) => {}
}

const Component = (props: Props) => {
  const minReferenceCount = useSelector((state: RootState) => {
    const isOrgAdmin = selectors.users.isOrgAdmin(state, selectors.users.id(state))
    const orgSettings = selectors.orgSettings.getByOrgId(state, selectors.orgs.currentId(state))
    if (!orgSettings || isOrgAdmin) return 1
    return orgSettings.fields.min_reference_count
  })

  return (
    <>
      <Subheading>
        Specify the number and type of references to collect. You can use the “Alias” field to further specify the
        reference type to the candidate.
      </Subheading>
      <Container>
        <Empty />
        <Label>Reference type</Label>
        <Label>Alias (optional)</Label>
        <Empty />
        {props.requiredReferences.map((rr, i) => {
          const copy = relationshipCopy[rr.relationship]
          if (!copy) return null
          return (
            <>
              <FormatIcon>{copy.emoji}</FormatIcon>
              <DropdownField
                value={rr.relationship}
                onChange={relationship => props.onChangeRelationship(i, relationship)}
              >
                {Object.entries(relationshipCopy).map(([opt, copy], i) => (
                  <Option emoji={copy?.emoji} value={opt} key={i}>
                    {opt}
                  </Option>
                ))}
              </DropdownField>
              <AliasContainer>
                <TextField
                  placeholder={copy.placeholder}
                  value={rr.description || ''}
                  onChange={e => props.onChangeDescription(i, e.target.value)}
                />
              </AliasContainer>
              {i >= minReferenceCount ? (
                <CloseIcon onClick={() => props.onRemove(i)}>
                  <Icon name="times" />
                </CloseIcon>
              ) : (
                <Empty />
              )}
            </>
          )
        })}
      </Container>

      <Footer>
        <Empty />
        <CreateButton onClick={props.onAdd}>Add a reference</CreateButton>
        <Empty />
      </Footer>
    </>
  )
}

export default Component
Component.displayName = 'UpdateRequiredReferences'
