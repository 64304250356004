import React from 'react'
import classes from './SimpleTable.module.scss'

interface SimpleTableProps {
  data: Array<{ label: string; value: React.ReactNode | undefined }>
}

/**
 * Simple table component
 * example:
 * ```
 * <SimpleTable data={[{label: 'WPM', value: '400'}, {label: 'Accuracy', value: '90%'}]} />
 * ```
 */
export const SimpleTable = (props: SimpleTableProps) => {
  return (
    <table className={classes.table}>
      <tbody>
        <tr>
          {props.data.map(item => (
            <th key={item.label}>{item.label}</th>
          ))}
        </tr>

        <tr>
          {props.data.map(item => (
            <td key={item.label}>{item.value ?? '-'}</td>
          ))}
        </tr>
      </tbody>
    </table>
  )
}
