import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Form, QuestionnairePayload, Step } from 'api/openjobroles'
import { AppThunk } from 'core/store'
import { Relationship, RequiredReference } from 'selectors/references'
import { sendReferenceCheck, sendSelfAssessment } from 'store/candidates'
import * as api from '../../api'
import { add as notify } from '../notifications'
import { addEntities } from '../resources'
import * as content from './content'

export { content }

interface State {
  presets: api.request.Entity<api.presets.Fields>[]
  selectableUserIds: string[]
  loading: boolean
  sentInvites: string[]
}

const name = 'new-role'

const initialState: State = {
  presets: [],
  loading: false,
  sentInvites: [],
  selectableUserIds: [],
}

export const slice = createSlice({
  name,
  initialState,
  reducers: {
    setPresets(state, action: PayloadAction<api.request.Entity<api.presets.Fields>[]>) {
      state.presets = action.payload
    },
    setUsers(state, action: PayloadAction<string[]>) {
      state.selectableUserIds = action.payload
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    addSentInvites(state, action: PayloadAction<string[]>) {
      state.sentInvites = state.sentInvites.concat(action.payload)
    },
  },
})

export const { setPresets, setLoading, setUsers, addSentInvites } = slice.actions
export default slice.reducer

export interface RoleRequiredData {
  name: string
  publicName: string
  description: string
  assessmentLocale: string
  referenceCheckLocales: string[]
  users: api.openjobroles.SelectedUser[]
  candidateExpiryDays: number
  timeWindowDays: number
  questionnaire: QuestionnairePayload[] | null
  requiredReferences: RequiredReference[]
  includeSelfReference: boolean
  includeSelfAssessment: boolean
  includeReferenceCheck: boolean
  candidateTitleVerificationEnabled: boolean
  brandingText?: string
  brandingImageUrl?: string
}

export const createv2 =
  (data: RoleRequiredData): ((dispatch) => Promise<boolean | string>) =>
  async dispatch => {
    dispatch(setLoading(true))
    const [response, errors] = await api.openjobroles.create({
      ...data,
      users: data.users.map(user => ({
        id: user.id,
        can_control: user.can_control,
      })),
      requiredReferences: data.requiredReferences.map(rr => ({
        is_peer: rr.relationship === Relationship.Peer,
        is_manager: rr.relationship === Relationship.Manager,
        is_report: rr.relationship === Relationship.Report,
        is_student_peer: rr.relationship === Relationship.StudentPeer,
        is_client: rr.relationship === Relationship.Client,
        is_any: rr.relationship === Relationship.Any,
        relationship_description: rr.description,
      })),
    })

    dispatch(setLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(addEntities(response))

      dispatch(
        notify({
          success: "We've created the job, configure your selected modules to begin inviting candidates!",
        }),
      )
    }

    return response?.result.id || ''
  }

export const inviteYourself =
  (id: string, candidate: api.openjobroles.Candidate, role: api.openjobroles.Fields): AppThunk =>
  async dispatch => {
    dispatch(setLoading(true))
    const [response, errors] = await api.openjobroles.invite(id, candidate)
    dispatch(setLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    dispatch(addSentInvites([candidate.email]))

    if (response) {
      dispatch(addEntities(response))

      dispatch(
        notify({
          success: 'Candidate has been added to HiPeople successfully! 🚀',
        }),
      )

      if (response && role.ask_self_assessment) {
        dispatch(sendSelfAssessment(response.result.id))
      }

      if (response && role.ask_reference_check) {
        dispatch(sendReferenceCheck(response.result.id))
      }
    }
  }

export const invitev2 =
  (
    id: string,
    candidate: api.openjobroles.Candidate,
    options: {
      createCandidate?: boolean
      sendSurvey?: boolean
    } = {
      createCandidate: false,
      sendSurvey: false,
    },
  ) =>
  async dispatch => {
    dispatch(setLoading(true))

    const inviteCandidate = (() => {
      if (options.createCandidate) {
        return api.openjobroles.selfInvite
      }

      return (id: string, candidate: api.openjobroles.Candidate) =>
        api.openjobroles.invite(id, candidate, options.sendSurvey)
    })()

    const [response, errors] = await inviteCandidate(id, candidate)
    dispatch(setLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return null
    }

    dispatch(addSentInvites([candidate.email]))

    if (response) {
      dispatch(addEntities(response))
      dispatch(
        notify({
          success: 'Candidate has been added to HiPeople successfully! 🚀',
        }),
      )

      const token = response.included?.find(r => r.fields.token)?.fields.token
      return {
        candidateId: response.result.id || '',
        token: token ? encodeURI(token) : '',
      }
    }
  }

export const loadUsersByOrgId =
  (orgid: string): AppThunk =>
  async dispatch => {
    dispatch(setLoading(true))

    const [response, errors] = await api.users.loadActiveByOrgId(orgid)

    dispatch(setLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(addEntities(response))
      dispatch(setUsers(response.result.map(row => row.fields.id)))
    }
  }

export function createCandidateForm(steps: Step[]): Form {
  return {
    steps: [
      { dialog_id: content.CANDIDATE_INTRO_DIALOG_ID } as Step,
      content.CANDIDATE_VERIFICATION_QUESTION,
      content.CANDIDATE_PROFESSIONAL_NETWORK_QUESTION,
      content.CANDIDATE_ASK_REFERENCE_LOOP,
      ...steps,
    ],
    dialog_submit_id: content.CANDIDATE_SUBMIT_DIALOG_ID,
    dialog_submitted_id: content.CANDIDATE_SUBMITTED_DIALOG_ID,
    dialog_inactive_id: content.CANDIDATE_INACTIVE_DIALOG_ID,
  }
}

export const loadPresets =
  (organizationId: string, product: api.presets.Product, filterString: string, locale: string): AppThunk =>
  async dispatch => {
    dispatch(setLoading(true))
    const [response, errors] = await api.presets.list(organizationId, product, filterString, locale)

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      dispatch(setLoading(false))
      return false
    }

    if (response) {
      dispatch(addEntities(response))
      dispatch(setPresets(response.result))
    }
    dispatch(setLoading(false))
  }

export const getPresetById =
  (organizationId: string, templateId: string): AppThunk =>
  async dispatch => {
    const [response, errors] = await api.presets.getById(organizationId, templateId)
    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    if (response) {
      dispatch(addEntities(response))
    }
  }

export const addReferenceCheckQuestionnaireToRole =
  (roleId: string, presetId: string): AppThunk =>
  async dispatch => {
    const [response, errors] = await api.openjobroles.addTemplateToRole(roleId, presetId, 'reference_check')
    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(
        notify({
          success: "You've successfully added a template for reference check! 🎉",
        }),
      )
      dispatch(addEntities(response))
    }

    return true
  }

export const addSelfAssessmentPresetToRole =
  (roleId: string, presetId: string): AppThunk =>
  async dispatch => {
    const [response, errors] = await api.openjobroles.addTemplateToRole(roleId, presetId, 'self_assessment')
    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(
        notify({
          success: "You've successfully added a template for this assessment! 🎉",
        }),
      )
      dispatch(addEntities(response))
    }

    return true
  }

export const update =
  (id: string, data: RoleRequiredData): AppThunk =>
  async dispatch => {
    const [response, errors] = await api.openjobroles.update(id, {
      ...data,
      users: data.users.map(user => ({
        id: user.id,
        can_control: user.can_control,
      })),
      requiredReferences: data.requiredReferences.map(rr => ({
        id: rr.id,
        is_peer: rr.relationship === Relationship.Peer,
        is_manager: rr.relationship === Relationship.Manager,
        is_report: rr.relationship === Relationship.Report,
        is_student_peer: rr.relationship === Relationship.StudentPeer,
        is_client: rr.relationship === Relationship.Client,
        is_any: rr.relationship === Relationship.Any,
        relationship_description: rr.description,
      })),
    })

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(addEntities(response))

      dispatch(
        notify({
          success: 'Updated the job successfully!',
        }),
      )
    }

    return id
  }
