import React, { useMemo } from 'react'
import { FaUser } from 'react-icons/fa6'
import { LazyImage } from '../../LazyImage/LazyImage'
import classes from './UsersIcons.module.scss'

type ImgUrl = string | undefined

interface UserIconsProps {
  imgUrls: ImgUrl[]
}
/**
 * Displays up to 3 user icons
 * @param props.imgUrls - array of image urls
 */
export const UsersIcons = (props: UserIconsProps) => {
  // add undefined to the end of the array if there are less than 3 elements
  const imgUrls = useMemo(() => {
    const result = [...props.imgUrls, undefined, undefined, undefined].slice(0, 3)
    return result
  }, [props.imgUrls])

  return (
    <div className={classes.UserIcons}>
      {imgUrls.map((icon, index) =>
        icon ? (
          <LazyImage width={31} height={31} key={icon} src={icon} className={classes.UserIconImage} />
        ) : (
          <div key={'user-icon-' + index} className={classes.UserIconNoImage}>
            <FaUser />
          </div>
        ),
      )}
    </div>
  )
}
