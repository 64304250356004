import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import token from '../../core/token'
import * as tracking from '../../core/track'
import { RootState } from '../../store'

const Component = () => {
  const { isLoggedIn } = useSelector((state: RootState) => state.sessions)
  const trackingProps = useSelector((state: RootState) => tracking.users.extractPropsFromState(state))

  const [readyToRedirect, setReadyToRedirect] = useState(false)

  useEffect(() => {
    // The `token` parameter is not persistent, unless this method is called.
    if (token.isTemporary()) {
      // ensure to only attach once
      token.attach()
    }

    if (isLoggedIn) {
      tracking.users.login({ isSAMLSSO: true })

      if (trackingProps) {
        tracking.identify(trackingProps)
      }
    }

    setReadyToRedirect(true)
  }, [])

  if (!readyToRedirect) {
    return null
  }

  return <Redirect to={'/'} />
}

export default Component
Component.displayName = 'SAMLSSORedirect'
