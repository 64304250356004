import { Dot } from '@common/components/Dot/Dot'
import '@common/components/Table/PrimeReactTable.scss'
import { Candidate } from 'App/OpenJobRole/candidates'
import { px2rem, style } from 'core'
import { text } from 'core/design-system/text'
import React from 'react'
import { colorForStatus } from 'selectors/candidates'
import { Status, statusToText } from 'utils/status'

export const StatusContainer = style().flex({ alignItems: 'center' }).sans({ nodecoration: true }).element()

export const StatusText = text
  .bodyText()
  .spacing({
    outerLeft: px2rem(8),
  })
  .element()

const statusTemplate = (status: Status | undefined, statusType: string, candidateId: string) => {
  if (!status) {
    return '-'
  }

  return (
    <StatusContainer key={`${statusType}-${candidateId}`}>
      {status && <Dot color={colorForStatus(status)} />}
      {status && <StatusText>{statusToText(status)}</StatusText>}
    </StatusContainer>
  )
}

export const assessmentStatusTemplate = (candidate: Candidate) => {
  return statusTemplate(candidate.assessment_completion_status, 'assessment', candidate.id)
}

export const refStatusTemplate = (candidate: Candidate) => {
  return statusTemplate(candidate.reference_check_status, 'refcheck', candidate.id)
}
