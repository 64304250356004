import { AiSuggestionsIcon, Banner, Button, LoadingText } from '@common/components'
import { get } from 'api/methods'
import { ButtonContent } from 'components/Button/ButtonV2'
import Modal from 'components/Modal'
import TextField from 'components/TextField'
import { fr, px2rem, size, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import { debounce } from 'lodash-es'
import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import * as selectors from 'selectors'
import { RootState } from 'store'
import classes from './CustomQuestionModal.module.scss'

import * as tracking from '../../../core/track'

const Title = text.cardHeader().element('h3')

const ModalHeader = style()
  .grid({ columns: [fr(1), size.auto, size.auto], align: 'center' })
  .spacing({ gap: px2rem(8), inner: [px2rem(18), px2rem(32)] })
  .border({
    bottom: `solid ${px2rem(1)}`,
    color: designSystemColors.borderDefault,
  })
  .element()

const Content = style()
  .spacing({ inner: [px2rem(24), px2rem(32)], innerBottom: px2rem(50) })
  .scroll({ y: 'auto' })
  .size({
    maxHeight: px2rem(500),
    maxWidth: px2rem(768),
  })
  .element()

const Buttons = style()
  .flex({ justifyContent: 'flex-end' })
  .bg({
    color: '#fff',
  })
  .spacing({
    inner: [px2rem(18), px2rem(32)],
    gap: px2rem(16),
    outerBottom: px2rem(0),
  })
  .border({
    top: `solid 1px`,
    color: designSystemColors.borderDefault,
  })
  .select('> *', style().size({ minWidth: px2rem(140) }))
  .sticky({ bottom: px2rem(30) })
  .size({ height: px2rem(80) })
  .element()

const CustomQuestionModalStyle = style()
  .size({ minWidth: px2rem(600) })
  .flex({
    direction: 'column',

    justifyContent: 'space-between',
  })
  .relative()
  .element()

type CustomQuestionValues = {
  text: string
}

type OnCustomQuestionCreate = (values: CustomQuestionValues[]) => any

interface CustomQuestionModalProps {
  close: () => any
  onSubmit: OnCustomQuestionCreate
  roleId: string | null
  initialValues?: CustomQuestionValues[]
}

export const CustomQuestionModal: React.FC<CustomQuestionModalProps> = ({ close, onSubmit, roleId, initialValues }) => {
  const [text, setText] = React.useState<string>('')
  const [questions, setQuestions] = React.useState<CustomQuestionValues[]>(initialValues ?? [])

  // Check if component is mounted
  const mountedRef = useRef<boolean>(false)

  const openJobRole = useSelector((state: RootState) => (roleId ? selectors.roles.findById(state, roleId) : null))

  const orgId = useSelector((state: RootState) => selectors.orgs.currentId(state))

  const orgSettings = useSelector((state: RootState) => selectors.orgSettings.getByOrgId(state, orgId))

  const [loading, setLoading] = React.useState(false)

  const addToQuestions = React.useCallback(() => {
    setQuestions(prev => [...prev, { text }])
    setText('')
  }, [text])

  const [generatedQuestions, setGeneratedQuestions] = React.useState<{ question: string; category: string }[]>([])

  const sendPrompt = React.useCallback(() => {
    if (!roleId) return
    setLoading(true)
    setGeneratedQuestions([])

    get(`genie/reference_check/open_job_role/${roleId}/suggested_questions`)
      .then(res => {
        const apiQuestions = (res[0]?.meta as { question: string; category: string }[]) ?? []
        if (apiQuestions.length > 0 && mountedRef.current) setGeneratedQuestions(apiQuestions)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [roleId, mountedRef.current])

  const addAiQuestion = React.useCallback((item: (typeof generatedQuestions)[0]) => {
    tracking.references.aiCustomQuestionAdded()
    setQuestions(prev => [...prev, { text: item.question }])
  }, [])

  React.useEffect(() => {
    mountedRef.current = true

    if (!orgSettings?.fields.hire_genie_enabled_reference_check || !openJobRole?.fields?.description) return
    const handleSendPrompt = debounce(sendPrompt, 300)
    handleSendPrompt()
    return () => {
      handleSendPrompt.cancel()
      mountedRef.current = false
    }
  }, [])

  React.useEffect(() => {
    // remove question from generated questions if it is already added
    setGeneratedQuestions(prev => prev.filter(item => !questions.find(q => q.text === item.question)))
  }, [questions])

  return (
    <Modal
      open={true}
      setOpen={close}
      helpLink={'https://intercom.help/hipeople/en/articles/7123673-create-your-first-reference-feedback-request'}
      renderHeader={({ closeButton }) => (
        <ModalHeader>
          <Title>{`${initialValues ? 'Update' : 'Add'} custom free-text questions`}</Title>
          <a href="https://intercom.help/hipeople/en/articles/7123673-create-your-first-reference-feedback-request">
            <Button variant="tertiary">
              <ButtonContent icon={{ name: 'life-ring', ariaLabel: 'Help' }}>{'Help'}</ButtonContent>
            </Button>
          </a>
          {closeButton}
        </ModalHeader>
      )}
    >
      <CustomQuestionModalStyle>
        <Content>
          {questions.length > 0 && (
            <div className={classes.questionList}>
              {questions.map(item => (
                <Banner
                  key={'question-' + item.text}
                  variant="success"
                  onClick={() => setQuestions(prev => prev.filter(q => q.text !== item.text))}
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  <div
                    style={{
                      marginRight: '1rem',
                    }}
                  >
                    <i className="fas fa-check" />
                  </div>
                  {item.text}
                </Banner>
              ))}
            </div>
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              alignItems: 'stretch',
              width: '100%',
              margin: '1rem 0 1rem 0',
            }}
          >
            <TextField
              label="Custom Question"
              placeholder="e.g: Can you elaborate on the reasons for the termination?"
              value={text}
              onChange={ev => setText(ev.target.value)}
            />
            <Button variant="purple" onClick={addToQuestions}>
              {'Add custom question'}
            </Button>
          </div>

          {roleId ? (
            <div>
              {!orgSettings?.fields.hire_genie_enabled_reference_check ? (
                <Banner variant="ai" style={{ width: '100%' }} startIcon={<AiSuggestionsIcon />}>
                  <span>
                    AI Suggestions is not enabled for this organization. If you want AI suggestions for reference check
                    questions, they can be enabled in the{' '}
                    <a href="/settings/organization">
                      <u>organization settings</u>
                    </a>
                    .
                  </span>
                </Banner>
              ) : !openJobRole?.fields?.description ? (
                <Banner variant="ai" style={{ width: '100%' }}>
                  Add a{' '}
                  <a href={`/roles/${roleId}/edit?step=details`}>
                    <u>job description</u>
                  </a>{' '}
                  to generate questions based on the job
                </Banner>
              ) : (
                <>
                  <div style={{ marginBottom: '2rem' }}>
                    <Title>Generate questions based on Job Description (Powered by AI)</Title>
                  </div>

                  {loading ? (
                    <Banner variant="ai" startIcon={<AiSuggestionsIcon />}>
                      <LoadingText text="AI is generating questions" />
                    </Banner>
                  ) : (
                    <>
                      <div className={classes.questionList}>
                        {generatedQuestions?.length > 0 ? (
                          generatedQuestions?.map(item => (
                            <Banner
                              key={item.question}
                              variant="ai"
                              onClick={() => addAiQuestion(item)}
                              style={{
                                cursor: 'pointer',
                              }}
                            >
                              <div
                                style={{
                                  marginRight: '1rem',
                                }}
                              >
                                <i className="fas fa-plus" />
                              </div>
                              {item.question}
                              <span>{item.category}</span>
                            </Banner>
                          ))
                        ) : (
                          <span>No questions found</span>
                        )}
                      </div>
                      <Button onClick={sendPrompt}>Regenerate questions</Button>
                    </>
                  )}
                </>
              )}
            </div>
          ) : null}
        </Content>
        <Buttons>
          <Button variant="tertiary" onClick={close}>
            {'Cancel'}
          </Button>
          <Button
            variant="purple-dark"
            onClick={() => {
              onSubmit(questions)
            }}
          >
            {'Add questions'}
          </Button>
        </Buttons>
      </CustomQuestionModalStyle>
    </Modal>
  )
}
