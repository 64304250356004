import React from 'react'
import classes from './Time.module.scss'
import { FaRegClock } from 'react-icons/fa'
import { millisecondsToHuman } from '../../utils'

interface Props {
  timeTakenMillis: number
  timeAllowedMillis?: number
  showIcon?: boolean
  dark?: boolean
}

export const Time: React.FC<Props> = props => {
  const timeTakenCapped = props.timeAllowedMillis
    ? Math.min(props.timeTakenMillis, props.timeAllowedMillis)
    : props.timeTakenMillis
  return (
    <div className={`${classes.time} ${props.dark ? classes.dark : ''}`} data-testid="time">
      {props.showIcon && <FaRegClock />}

      {millisecondsToHuman(timeTakenCapped)}
      {props.timeAllowedMillis ? <>/{millisecondsToHuman(props.timeAllowedMillis)}</> : ''}
    </div>
  )
}
