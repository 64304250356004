import { LockedByPlan, LockIcon } from '@common/components'
import { lockedContentTracking } from 'core/track/locked-content'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Block, { Header } from '../../components/Block'
import Button from '../../components/Button/ButtonV1'
import { useConfirm } from '../../components/Confirm'
import DropdownField, { Option } from '../../components/DropdownField'
import Form from '../../components/Form'
import { Subheading } from '../../components/Typography'
import { space, style } from '../../core'
import * as selectors from '../../selectors'
import { RootState } from '../../store'
import { save, setDataRetentionDays, setDeletedDataRetentionDays } from '../../store/settings/gdpr'

const RetentionSettings = style().spacing({ outerTop: space.m }).element()

const Component = () => {
  const dispatch = useDispatch()
  const confirm = useConfirm()

  const org = useSelector((state: RootState) => selectors.orgs.current(state))
  const isOrgPaidPlan = useSelector(selectors.orgs.isOrgPaidPlan)
  const orgSettings = useSelector((state: RootState) =>
    selectors.orgSettings.getByOrgId(state, selectors.orgs.currentId(state)),
  )
  const changes = useSelector((state: RootState) => state.gdprSettings.changes)
  const loading = useSelector((state: RootState) => state.gdprSettings.loading)

  const notchanged = Object.values(changes).length === 0

  const dataRetentionDays =
    changes.dataRetentionDays !== undefined ? changes.dataRetentionDays : orgSettings?.fields.data_retention_days || 0

  const deletedDataRetentionDays =
    changes.deletedDataRetentionDays !== undefined
      ? changes.deletedDataRetentionDays
      : orgSettings?.fields.deleted_data_retention_days || 0

  const dataRetentionDaysOptions = genTimeWindowOptions(
    orgSettings?.fields.data_retention_days || 0,
    (opt: WindowOption) => opt.days >= deletedDataRetentionDays,
  )

  const deletedDataRetentionDaysOptions = genTimeWindowOptions(
    orgSettings?.fields.deleted_data_retention_days || 0,
    (opt: WindowOption) => opt.days <= dataRetentionDays,
  )

  return (
    <LockedByPlan
      tracking={lockedContentTracking}
      locked={!isOrgPaidPlan}
      lockIconDisabled={true}
      upgradeTo={'Scale'}
      modalData={{
        learnMoreLink:
          'https://intercom.help/hipeople/en/articles/7733644-organization-settings-gdpr-data-retention-settings',
        learnMoreAbout: 'Advanced GDPR Settings',
      }}
    >
      <Block
        testId="GDPR-settings"
        title={
          <>
            GDPR Settings
            <LockIcon visible={!isOrgPaidPlan} />
          </>
        }
      >
        <Header>
          <RetentionExplaination />
        </Header>
        <RetentionSettings>
          <Form>
            <DropdownField<number>
              label={
                dataRetentionDays ? 'Automatically delete personal data after' : 'Automatically delete personal data'
              }
              value={dataRetentionDays}
              onChange={e => dispatch(setDataRetentionDays(e))}
            >
              {dataRetentionDaysOptions.map(option => (
                <Option key={option.caption} value={option.days}>
                  {option.caption}
                </Option>
              ))}
            </DropdownField>
            <DropdownField<number>
              label={
                deletedDataRetentionDays
                  ? 'Automatically delete soft-deleted personal data after'
                  : 'Automatically delete soft-deleted personal data'
              }
              value={deletedDataRetentionDays}
              onChange={e => dispatch(setDeletedDataRetentionDays(e))}
              disabled={dataRetentionDays === 0}
            >
              {deletedDataRetentionDaysOptions.map(option => (
                <Option key={option.caption} value={option.days}>
                  {option.caption}
                </Option>
              ))}
            </DropdownField>
            <div></div>
            <Button primary onClick={() => (org ? onClickSave() : null)} disabled={loading || notchanged}>
              Save
            </Button>
          </Form>
        </RetentionSettings>
      </Block>
    </LockedByPlan>
  )

  async function onClickSave() {
    if (
      await confirm({
        title: `About to update your data retention settings, continue?`,
        message: genConfirmMessage(dataRetentionDays || 0, deletedDataRetentionDays || 0),
        confirmLabel: 'Yes, update settings',
      })
    ) {
      dispatch(save(org.id, changes))
    }
  }
}

export default Component
Component.displayName = 'GDPR'

function genConfirmMessage(dataRetentionDays: number, deletedRetentionDays: number): string {
  if (dataRetentionDays && deletedRetentionDays) {
    return `
After saving, your data retention settings will be:

- All personal data deleted after ${dataRetentionDays} days
- All soft-deleted personal data deleted after ${deletedRetentionDays} days`
  }

  if (dataRetentionDays) {
    return `
After saving, your data retention settings will be:

- All personal data deleted after ${dataRetentionDays} days`
  }

  return `After saving, your data retention settings will be:

- No personal data will be deleted at any point`
}

function genTimeWindowOptions(savedDays: number, filter: (option: WindowOption) => boolean): WindowOption[] {
  const options = timeWindowOptions.filter(filter)
  const hasOption = timeWindowOptions.find(opt => opt.days === savedDays)

  if (!hasOption && savedDays) {
    options.push({
      days: savedDays,
      caption: `${savedDays} days`,
    })
  }

  return options
}

function RetentionExplaination() {
  return (
    <Subheading>
      Enforce your data retention policy for how long personal data collected from candidates and references should be
      stored. Optionally, a specific time window can be selected for purging the personal data of candidates and
      references soft-deleted via the dashboard menus earlier.
    </Subheading>
  )
}

type WindowOption = { days: number; caption: string }

const timeWindowOptions: WindowOption[] = [
  { days: 0, caption: 'Not enabled' },
  { days: 14, caption: '2 weeks' },
  { days: 28, caption: '4 weeks' },
  { days: 91, caption: '3 months' },
  { days: 121, caption: '4 months' },
  { days: 152, caption: '5 months' },
  { days: 182, caption: '6 months' },
  { days: 365, caption: '12 months' },
  { days: 730, caption: '24 months' },
]
