import { oneTimePassword } from '../api'
import { Entity } from '../api/request'
import { RootState } from '../store'
import * as candidates from './candidates'
import lookup from './lookup'

export type OneTimePasswordEntity = Entity<oneTimePassword.Fields>

export function getById(state: RootState, id: string): OneTimePasswordEntity {
  return lookup(state, oneTimePassword.RESOURCE, id) || oneTimePassword.empty(id)
}

export function findByCandidateId(state: RootState, candidateId: string): OneTimePasswordEntity[] {
  const candidate = candidates.findById(state, candidateId)
  if (!candidate || !candidate.relationships) return []

  return candidate.relationships
    .filter(r => r.resource === oneTimePassword.RESOURCE)
    .map(r => getById(state, r.id))
    .filter(r => !!r)
}
