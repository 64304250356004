import { Button } from '@common/components'
import * as api from 'api'
import { breakpoints, fr, px2rem, size, space, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React from 'react'
import { useSelector } from 'react-redux'
import * as selectors from 'selectors'
import { Relationship } from 'selectors/references'
import { RootState } from 'store'
import ColumnReference from './ColumnReference'
import ColumnStatus from './ColumnStatus'
import ColumnType from './ColumnType'
import { useTranslation } from 'react-i18next'

const BaseContainer = style()
  .grid({
    columns: [size.auto, px2rem(80), fr(1), px2rem(125), px2rem(135)],
    align: 'center',
  })
  .screen(
    breakpoints.mobile,
    style().grid({
      columns: [size.auto, fr(1), px2rem(125)],
    }),
  )
  .spacing({ columns: space.s })
  .round(px2rem(8))

const HideOnMobile = style().text({ align: 'right' }).screen(breakpoints.mobile, style().hidden()).element()

const Container = BaseContainer.clone()
  .spacing({ inner: space.m, columns: space.s })
  .border({
    around: 'solid 1px',
    color: designSystemColors.borderDefault,
  })
  .size({ height: px2rem(82) })
  .pointer()
  .element()

const HeaderContainer = BaseContainer.clone()
  .spacing({ inner: [space.none, space.m] })
  .element()
const HeaderText = text.bodyInteractive().element('span')
const HeaderTextType = text.bodyInteractive().screen(breakpoints.mobile, style().hidden()).element('span')

const Index = text.bodyInteractive().element()

interface Props {
  index: number
  candidateId: string
  reference?: api.request.Entity<api.references.Fields>
  requirement?: api.request.Entity<api.requiredReferences.Fields>
}

const Reference = (props: Props) => {
  const { t } = useTranslation()
  const referenceByRequirement = useSelector((state: RootState) =>
    selectors.references.findByRequirementId(state, props.candidateId, props.requirement?.id || ''),
  )

  const reference = props.reference || referenceByRequirement
  const relationship = findRelationship()
  const relationshipCopy = selectors.references.relationshipCopyWithSelf[relationship]
  if (!relationshipCopy) return <></>

  return (
    <Container>
      <Index>{props.index + 1}.</Index>
      <ColumnType {...relationshipCopy} reference={reference} relationship={relationship} />
      <ColumnReference {...relationshipCopy} reference={reference} relationship={relationship} />
      <ColumnStatus reference={reference} />

      <HideOnMobile>
        <Button variant="purple-dark">
          {reference ? t('reference.modify-details', 'Modify details') : t('reference.add-details', 'Add details')}
        </Button>
      </HideOnMobile>
    </Container>
  )

  function findRelationship(): Relationship {
    if (props.requirement) return selectors.references.relationshipOf(props.requirement)
    if (props.reference) return selectors.references.relationshipOf(props.reference)
    return Relationship.Manager
  }
}

export default Reference

export const Header = () => {
  const { t } = useTranslation()
  return (
    <HeaderContainer>
      <HeaderText>#</HeaderText>
      <HeaderTextType>{t('candidate-dashboard-intro.reference.type', 'Type')}</HeaderTextType>
      <HeaderText>{t('candidate-dashboard-intro.reference.reference', 'Reference')}</HeaderText>
      <HeaderText>{t('candidate-dashboard-intro.reference.status', 'Status')}</HeaderText>
    </HeaderContainer>
  )
}
