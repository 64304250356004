import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { addEntities } from 'store/resources'
import * as api from '../../api'
import { AppThunk } from '../../core/store'
import { add as notify } from '../notifications'

interface State {
  loading: boolean
  url: string
  created: boolean
}

const name = 'backgroundcheckintegrations'
const initialState: State = {
  loading: false,
  url: '',
  created: false,
}

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setURL(state, action: PayloadAction<string>) {
      state.url = action.payload
    },
  },
})

export const { setLoading, setURL } = slice.actions

export const installOAuthProvider =
  (orgId: string, providerId: string): AppThunk =>
  async dispatch => {
    dispatch(setLoading(true))

    const [response, errors] = await api.backgroundcheckintegrations.installOAuthProvider(orgId, providerId)
    dispatch(setLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(setURL(response.result.fields.url))
    }
  }

export const deleteOrgIntegration =
  (orgId: string, integrationId: string): AppThunk =>
  async dispatch => {
    dispatch(setLoading(true))
    const [response, errors] = await api.backgroundcheckintegrations.deleteOrgIntegration(orgId, integrationId)
    dispatch(setLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
    }

    if (response) {
      dispatch(notify({ success: 'Successfully deleted the integration!' }))
      dispatch(addEntities(response))
    }
  }

export default slice.reducer
