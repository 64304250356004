export type InstallMethod = 'oauth'

export interface BackgroundCheckProvider {
  name: string
  slug: string
  logo: string
  colour: string
  docs_link?: string
  installMethod: InstallMethod
}

const providers: { [key: string]: BackgroundCheckProvider } = {
  // merge
  checkr: {
    name: 'Checkr',
    slug: 'checkr',
    logo: 'https://checkr.com/wp-content/uploads/2021/02/checkr-logo.png',
    colour: '#169AE3',
    installMethod: 'oauth',
  },
}

export default providers
