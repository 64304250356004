import { Text } from '@common/components'
import React from 'react'
import { Link } from 'react-router-dom'
import { style } from '../../core'
import classes from '../SignUp/Signup.module.scss'

const ResetPassLink = style().text({ nodecoration: true }).element('a')

const Component = () => {
  return (
    <Text variant="body-text" style={{ textAlign: 'center' }}>
      <Link
        to="/reset-password/request"
        component={ResetPassLink}
        className={classes.link}
        data-testid="forgot-password-link"
      >
        Forgot your password?
      </Link>
    </Text>
  )
}

export default Component
Component.displayName = 'ForgotPassword'
