import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useParams } from 'react-router-dom'
import Block from '../../../components/Block'
import Button from '../../../components/Button/ButtonV1'
import DashboardLayout, { Content, Header } from '../../../components/DashboardLayout'
import Form from '../../../components/Form'
import Grid from '../../../components/Grid'
import { Button as PopupButton } from '../../../components/Popup'
import { space, style } from '../../../core'
import * as selectors from '../../../selectors'
import { RootState } from '../../../store'
import { load, remove, update } from '../../../store/webhooks'
import WebhookForm from '../Webhooks/WebhookForm'

const section = style().spacing({
  inner: [space.xxxs, space.none, space.none, space.none],
})

const FormHolder = section.clone().spacing({ columns: space.l }).element()

const Component = () => {
  const dispatch = useDispatch()
  const { id } = useParams<{ id: string }>()

  const [orgId, webhook, changes]: [
    ReturnType<typeof selectors.orgs.currentId>,
    ReturnType<typeof selectors.webhooks.getById>,
    RootState['webhooks']['changes'],
  ] = useSelector((state: RootState) => [
    selectors.orgs.currentId(state),
    selectors.webhooks.getById(state, id || ''),
    state.webhooks.changes,
  ])

  const [deleted, setDeleted] = useState(false)

  useEffect(() => {
    dispatch(load(orgId))
  }, [])

  if (!webhook?.fields) {
    return <></>
  }

  const name: string = webhook.fields.title

  if (deleted) {
    return <Redirect to={'/settings/webhooks'}></Redirect>
  }

  return (
    <DashboardLayout>
      <Header
        breadcrumbs={[
          { label: 'Settings' },
          { url: `/settings/webhooks`, label: 'Webhooks' },
          { url: `/settings/webhooks/${id}`, label: name },
        ]}
      >
        <PopupButton
          icon="trash"
          onClick={() => {
            dispatch(remove(id || ''))
            setDeleted(true)
          }}
        >
          Delete
        </PopupButton>
      </Header>

      <Content>
        <Block title={`Manage ${name}`}>
          <Grid>
            <FormHolder>
              <Form>
                <WebhookForm updateMode webhook={webhook.fields} />
                <Button
                  primary
                  onClick={() => dispatch(update(id || '', changes))}
                  disabled={changes === undefined || Object.keys(changes).length === 0}
                >
                  Update
                </Button>
              </Form>
            </FormHolder>
          </Grid>
        </Block>
      </Content>
    </DashboardLayout>
  )
}

export default Component
Component.displayName = 'UpdateWebhook'
