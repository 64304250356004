import { MarkdownText } from '@common/components'
import { px2rem, space, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import React from 'react'
import { Chip, Chips } from './Chips'

const ChipContainer = style()
  .bg({ color: designSystemColors.backgroundNeutralSecondary })
  .spacing({
    outer: space.none,
    inner: [px2rem(24), px2rem(16)],
  })
  .element()

interface ChipContainerProps {
  chips: {
    id: string
    value: string
    caption: string
    overlapped?: boolean
    score?: number
  }[]
  outstandingOptionValue?: string
  text_input?: string
}

const MultiChipContainer = (props: ChipContainerProps) => {
  return (
    <ChipContainer>
      <Chips>
        {props.chips.map(option => {
          return (
            <Chip
              style={{ verticalAlign: 'top' }}
              key={option.id}
              highlight={option.value === props.outstandingOptionValue || option.overlapped}
              correct={(option.score || 0) > 0}
            >
              <MarkdownText text={option.caption} />
            </Chip>
          )
        })}
      </Chips>

      {props.text_input && <p>{props.text_input}</p>}
    </ChipContainer>
  )
}

export default MultiChipContainer
