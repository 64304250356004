import { Button } from '@common/components'
import { ButtonContent } from 'components/Button/ButtonV2'
import { Icon } from 'components/Icons'
import Modal from 'components/Modal'
import TextField from 'components/TextField'
import { fr, px2rem, rem, size, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React, { useMemo } from 'react'

const Title = text.cardHeader().element('h3')

const ModalHeader = style()
  .grid({ columns: [fr(1), size.auto, size.auto, size.auto], align: 'center' })
  .spacing({ gap: px2rem(8), inner: [px2rem(18), px2rem(24)] })
  .border({
    bottom: `solid ${px2rem(1)}`,
    color: designSystemColors.borderDefault,
  })
  .element()

const ContentHeader = text
  .smallBody()
  .spacing({ inner: [px2rem(8), px2rem(24)] })
  .fg(designSystemColors.typographySecondary)
  .bg({ color: designSystemColors.backgroundNeutralTertiary })
  .element('h3')
const SkillList = style()
  .list({ style: 'none' })
  .nospacing()
  .size({ height: 'calc(75vh - 150px)' })
  .scroll({ y: 'auto' })
  .element('ul')
const SkillListSelectedIcon = text.bodyEmphasis().fg('inherit').inlineBlock().element('span')
const SkillName = text.bodyEmphasis().inlineBlock().fg('inherit').element('span')

const NoSkills = text
  .timelineHeader()
  .spacing({ outerBottom: px2rem(24) })
  .serif()
  .element('h4')

const SkillListItem = style()
  .spacing({ inner: [px2rem(12), px2rem(24)] })
  .select(
    '& + &',
    style().border({
      top: `solid ${px2rem(1)}`,
      color: designSystemColors.borderDefault,
    }),
  )
  .size({ minHeight: px2rem(44) })
  .pointer()
  .cond(
    ({ isSelected }: { isSelected: boolean }) => isSelected,
    style().bg({ color: designSystemColors.uiStatusSuccess }).fg(designSystemColors.white),
  )
  .flex({ justifyContent: 'space-between' })
  .element('li')
const Content = style().scroll({ y: 'auto' }).element()

const EmptyState = style()
  .size({ width: px2rem(240) })
  .spacing({ outer: [rem(1.5), 'auto'] })
  .element()

const Buttons = style()
  .flex({ justifyContent: 'flex-end' })
  .spacing({ inner: [px2rem(18), px2rem(24)], gap: px2rem(16) })
  .border({
    top: `solid 1px`,
    color: designSystemColors.borderDefault,
  })
  .select('> *', style().size({ minWidth: px2rem(140) }))
  .element()

const SkillSelectionModalStyle = style()
  .size({ width: px2rem(800) })
  .element()

type SkillSelectionValues = {
  selectedSkillsId: string[]
}

type OnCustomQuestionCreate = (values: SkillSelectionValues) => any

function filterBySearch(name: string, searchTerm: string) {
  return name.toLowerCase().includes(searchTerm.toLowerCase())
}

interface SkillSelectionModalProps {
  close: () => any
  newSkillCallback: () => any
  onSubmit: OnCustomQuestionCreate
  skills: { id: string; name: string }[]
}

export const SkillSelectionModal: React.FC<SkillSelectionModalProps> = ({
  skills,
  close,
  onSubmit,
  newSkillCallback,
}) => {
  const [searchTerm, setSearchTerm] = React.useState('')

  const [selectedSkills, setSelectedSkills] = React.useState<Record<string, boolean>>({})
  const toggleSelectedSkillState = (id: string) =>
    setSelectedSkills(skills => {
      const newSkills = { ...skills }
      const isUnselected = skills[id]
      if (isUnselected) {
        delete newSkills[id]
      } else {
        newSkills[id] = true
      }
      return newSkills
    })

  const displayedSkills = useMemo(() => {
    if (!searchTerm) {
      return skills
    }
    return [
      // Show selected skills on top when the results are filtered by a search term
      ...skills.filter(({ id }) => selectedSkills[id]),
      // Append filtered skills later
      ...skills.filter(({ name, id }) => filterBySearch(name, searchTerm) && !selectedSkills[id]),
    ]
  }, [
    searchTerm,
    skills,
    /* selectedSkills are not part of the dependency list, to prevent changes in the order of items displayed */
  ])

  const selectedSkillCount = Object.keys(selectedSkills).length
  const isConfirmButtonDisabled = selectedSkillCount === 0

  return (
    <Modal
      open={true}
      setOpen={close}
      helpLink={'https://intercom.help/hipeople/en/articles/7123673-create-your-first-reference-feedback-request'}
      renderHeader={({ closeButton }) => (
        <ModalHeader>
          <Title>{'Select skill questions'}</Title>
          <TextField
            placeholder="Search"
            icon="search"
            value={searchTerm}
            onChange={ev => setSearchTerm(ev.target.value)}
          />
          <Button
            variant="tertiary"
            onClick={() => {
              window.open(
                'https://intercom.help/hipeople/en/articles/7123673-create-your-first-reference-feedback-request',
                '_blank',
              )
            }}
          >
            <ButtonContent icon={{ name: 'life-ring', ariaLabel: 'Help' }}>{'Help'}</ButtonContent>
          </Button>
          {closeButton}
        </ModalHeader>
      )}
    >
      <SkillSelectionModalStyle>
        <Content>
          <ContentHeader>{'Skill name'}</ContentHeader>
          <SkillList>
            {displayedSkills.length === 0 && (
              <EmptyState>
                <NoSkills>No skills found.</NoSkills>
                <Button data-for="invite-yourself-button" variant="secondary" onClick={newSkillCallback}>
                  <ButtonContent icon={{ name: 'plus', ariaLabel: 'add skill' }}>
                    {'Create a new skill question'}
                  </ButtonContent>
                </Button>
              </EmptyState>
            )}
            {displayedSkills.map(({ name, id }) => (
              <SkillListItem key={id} onClick={() => toggleSelectedSkillState(id)} isSelected={selectedSkills[id]}>
                <SkillName>{name}</SkillName>
                {selectedSkills[id] && (
                  <SkillListSelectedIcon>
                    <Icon name="check" ariaLabel={`${name} is selected`} />
                  </SkillListSelectedIcon>
                )}
              </SkillListItem>
            ))}
          </SkillList>
        </Content>
        <Buttons>
          <Button variant="tertiary" onClick={close}>
            {'Cancel'}
          </Button>
          <Button
            variant="purple-dark"
            onClick={() => {
              onSubmit({ selectedSkillsId: Object.keys(selectedSkills) })
              close()
            }}
            disabled={isConfirmButtonDisabled}
          >
            {selectedSkillCount === 0 ? 'Add skills' : `Add ${selectedSkillCount} skill(s)`}
          </Button>
        </Buttons>
      </SkillSelectionModalStyle>
    </Modal>
  )
}
