import { Icon } from 'components/Icons'
import { pct, px2rem, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import React from 'react'

import { Tooltip } from '@common/components'
import { OnboardingStatus } from 'api/candidates'

interface StatusRingProps {
  status: OnboardingStatus | null
  copy: string
}

const Circle = style()
  .size({ width: px2rem(16), height: px2rem(16) })
  .border({ radius: pct(100) })
  .with(({ outerColor }: { outerColor: string }) => style().color({ bg: outerColor }))
  .center()
  .element()

type IconColor = {
  [key in OnboardingStatus]: {
    icon: string | null
    outerColor: string
    innerColor: string
  }
}

const iconColorLookup: IconColor = {
  completed: {
    icon: 'check',
    outerColor: designSystemColors.informationGreenPrimary,
    innerColor: designSystemColors.white,
  },
  'in progress': {
    icon: null,
    outerColor: designSystemColors.typographySecondary,
    innerColor: designSystemColors.white,
  },
  overdue: {
    icon: null,
    outerColor: designSystemColors.uiStatusWarningSecondary,
    innerColor: designSystemColors.white,
  },
  'not started': {
    icon: null,
    outerColor: designSystemColors.backgroundNeutralTertiary,
    innerColor: designSystemColors.backgroundNeutralTertiary,
  },
}

const IconContainer = style()
  .with(({ innerColor }: { innerColor: string }) => style().fg(innerColor))
  .set('fontSize', px2rem(10))
  .element()

const InnerCircle = style()
  .size({ width: px2rem(4), height: px2rem(4) })
  .sans({ size: px2rem(10) })
  .border({ radius: pct(100) })
  .with(({ innerColor }: { innerColor: string }) => style().bg({ color: innerColor }))
  .element()

const Hint = style().inlineBlock().relative().element()

const StatusRing: React.FC<StatusRingProps> = ({ status, copy }) => {
  const { icon, outerColor, innerColor } = iconColorLookup[status ?? 'not started']

  return (
    <Tooltip text={copy} direction="top">
      <Hint>
        <Circle outerColor={outerColor}>
          {icon ? (
            <IconContainer innerColor={innerColor}>
              <Icon name={icon} />
            </IconContainer>
          ) : (
            <InnerCircle innerColor={innerColor} />
          )}
        </Circle>
      </Hint>
    </Tooltip>
  )
}

export default StatusRing
