import React, { useEffect, useState } from 'react'
import { Skeleton } from '../Skeleton/Skeleton'
import classes from './LazyImage.module.scss'

interface LazyImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  /** Will only be used to set the width of the skeleton */
  width?: string | number
  /** Will only be used to set the height of the skeleton */
  height?: string | number
}

/**
 * LazyImage is a component that will load an image and display a skeleton
 * while the image is loading.
 * @param props
 * @param props.src The source of the image
 * @param props.alt The alt text of the image
 * @param props.width The width of the loading skeleton
 * @param props.height The height of the loading skeleton
 */
export const LazyImage = ({ src, alt, width, height, className, ...props }: LazyImageProps) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (!src) return
    const img = new Image()
    img.src = src as string

    const handleLoad = () => {
      setLoaded(true)
    }

    img.addEventListener('load', handleLoad)

    return () => {
      img.removeEventListener('load', handleLoad)
    }
  }, [src])

  return (
    <>
      <Skeleton
        width={width || 50}
        height={height || 50}
        className={`${classes.base} ${className}`}
        style={{ display: loaded ? 'none' : '' }} // Allows for seamless transition between skeleton and image, otherwise on slow connections the skeleton will flash for a second before the image loads
      />
      <img
        {...props}
        src={src}
        alt={alt}
        className={`${classes.base} ${className}`}
        style={{
          display: loaded ? '' : 'none',
          ...props.style,
        }}
      />
    </>
  )
}
