import React from 'react'
import { idToColors, initialsFromName } from '../../../utils'

interface LetterIconGradientProps {
  name: string
  id: string
  fontColor?: string
}

export const LetterIconGradient = (props: LetterIconGradientProps) => {
  const initials = initialsFromName(props.name)

  const colors = idToColors(props.id)
  return (
    <svg viewBox="0 0 100 100">
      <defs>
        <linearGradient id={'gradient_' + props.id} x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" style={{ stopColor: colors.firstColor, stopOpacity: 1 }} />
          <stop
            offset="100%"
            style={{
              stopColor: colors.secondColor,
              stopOpacity: 1,
            }}
          />
        </linearGradient>
      </defs>
      <circle cx="50%" cy="50%" r="50%" fill={`url(#${'gradient_' + props.id})`} fillOpacity="70%" />
      <text
        x="50%"
        y="50%"
        dominantBaseline="central"
        textAnchor="middle"
        fontWeight="bold"
        fontSize="2rem"
        fill={props.fontColor ?? 'white'}
      >
        {initials}
      </text>
    </svg>
  )
}
