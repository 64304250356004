import TopicSummary, { Score } from 'components/TopicSummary'
import { fr, space, style } from 'core'
import { usePostHireOnboardingSummaries } from 'providers/onboarding-summaries'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import * as selectors from 'selectors'
import { milliseconds } from 'selectors'
import { RootState } from 'store'
import { employees } from '../../../api'
import { Button, ButtonContent } from '../../../components/Button/ButtonV2'
import DashboardLayout, { Content, Header } from '../../../components/DashboardLayout'
import { useUser } from '../../../providers/users'
import { loadEmployeeById } from '../../../store/employees'
import { EmployeeHeaderWithProps } from '../../Candidate/NameHeader'
import { OnboardingAttributesDetailModalWrapper } from '../../Candidate/Onboarding/OnboardingAttributesDetailModal'
import { OverallEvaluationSummary } from '../../Candidate/Onboarding/OverallEvaluationSummary'
import Empty from '../../Candidate/WaitingOnCandidate'

const ContentContainer = style().grid().element()

const PostHireContentContainer = style().grid().spacing({ rows: space.l }).element()

const OverallScoresContainer = style()
  .grid({ columns: [fr(1), fr(1)] })
  .spacing({ columns: space.l })
  .element()

interface OnboardingPageContentProps {
  employee?: Pick<employees.Fields, 'name' | 'work_email' | 'start_at' | 'scores'>
  managerEmail: string
  praiseTopics: Score[] | null
  analyzeTopics: Score[] | null
  setSelectedQuestionId: (id: string) => void
}

const EmployeePageUI: React.FC<OnboardingPageContentProps> = ({ employee, ...props }) => {
  const history = useHistory()
  const firstName = selectors.firstName(employee?.name || '')

  return (
    <>
      <EmployeeHeaderWithProps
        name={employee?.name || ''}
        email={employee?.work_email}
        manager={props.managerEmail}
        startAt={employee?.start_at ? new Date(milliseconds(employee?.start_at)) : undefined}
      />
      {!employee ? (
        <Empty
          title={'No data to display '}
          status={
            'There are no responses for this candidate yet. This will change once the new hire has received and answered their first survey.'
          }
          cta={
            <Button buttonType="secondary" onClick={history.goBack}>
              <ButtonContent icon={{ name: 'arrow-left', ariaLabel: 'go back' }}>{'Go back'}</ButtonContent>
            </Button>
          }
        />
      ) : (
        <ContentContainer>
          <PostHireContentContainer>
            <OverallScoresContainer>
              <OverallEvaluationSummary
                title={`${firstName}'s Experience`}
                emoji={`👋`}
                emojiColor={'purple'}
                overallScore={employee.scores.new_hire_experience_score}
                roleScore={employee.scores.employee_role}
                teamScore={employee.scores.employee_team}
                organizationScore={employee.scores.employee_organization}
              />
              <OverallEvaluationSummary
                title={'Manager Evaluation'}
                emoji={`📊`}
                emojiColor={'purple'}
                overallScore={employee.scores.managers_evaluation_score}
                roleScore={employee.scores.manager_role}
                teamScore={employee.scores.manager_team}
                organizationScore={employee.scores.manager_organization}
              />
            </OverallScoresContainer>
            {props.praiseTopics && props.analyzeTopics
              ? (props.praiseTopics.length || props.analyzeTopics.length) && (
                  <TopicSummary
                    title={`Feedback Topics`}
                    emoji={'📌'}
                    praise={props.praiseTopics}
                    adjust={props.analyzeTopics}
                    onItemClick={(id: string) => props.setSelectedQuestionId(id)}
                  />
                )
              : ''}
          </PostHireContentContainer>
        </ContentContainer>
      )}
    </>
  )
}

EmployeePageUI.displayName = 'OnboardingPageContent'

function EmployeeQualityOfHirePage() {
  const { employeeid: employeeId } = useParams<{ employeeid: string }>()
  const dispatch = useDispatch()

  const user = useUser()

  const org = useSelector((state: RootState) => selectors.orgs.findByUserId(state, user.id))

  const employee = useSelector((state: RootState) => selectors.employees.findById(state, employeeId))

  const breadcrumbs = employee
    ? [
        {
          url: '/quality-of-hire',
          label: 'Quality of Hire',
        },
        {
          url: `/quality-of-hire/${employee.fields.id}`,
          label: employee.fields.name,
        },
      ]
    : []

  const managerProfile = useSelector((state: RootState) =>
    selectors.managers.findById(state, employee?.fields.manager_id || ''),
  )

  const [selectedQuestionId, setSelectedQuestionId] = useState<null | string>(null)

  useEffect(() => {
    dispatch(loadEmployeeById(org?.id || '', employeeId))
    if (!employee?.fields.manager_id) {
      return
    }
  }, [employeeId])

  const { analyzeTopics, praiseTopics } = usePostHireOnboardingSummaries({
    employeeId: employee?.fields.id,
  })

  return (
    <DashboardLayout>
      <Header breadcrumbs={breadcrumbs}></Header>
      <Content>
        <EmployeePageUI
          employee={employee?.fields}
          analyzeTopics={analyzeTopics}
          praiseTopics={praiseTopics}
          managerEmail={managerProfile?.fields.work_email || ''}
          setSelectedQuestionId={setSelectedQuestionId}
        />
        {selectedQuestionId && (
          <OnboardingAttributesDetailModalWrapper
            employeeId={employeeId}
            close={() => setSelectedQuestionId(null)}
            questionId={selectedQuestionId}
          />
        )}
      </Content>
    </DashboardLayout>
  )
}

export default EmployeeQualityOfHirePage
