import React from 'react'
import { breakpoints, colors, px, rem, rgba, space, style, weight } from '../../core'
import { Icon } from '../Icons'
import { ModalContainer } from './Container'

export { default as Content } from './Content'

const ModalStyle = style()
  .bg({ color: colors.white })
  .shadow()
  .round(px(5))
  .spacing({ outer: space.m })
  .nooverflow()
  .screen(
    breakpoints.mobile,
    style()
      .fixed({ bottom: px(10) })
      .size({ maxWidth: `calc(100vw - 20px)` }),
  )
  .cond(({ isFullWidth }) => isFullWidth, style().size({ width: `calc(100vw - 28px)` }))
  .size({ maxHeight: '90vh' })
  .element()

const Header = style()
  .flex()
  .spacing({ inner: [space.m, space.s] })
  .border({ bottom: '1px solid', color: colors.lightMoonGray })
  .sans()
  .element()

const Title = style()
  .center({ vertical: true })
  .flex({ shrink: '0', grow: '1' })
  .sans({ weight: weight.normal })
  .element()

const ModalButton = style()
  .flex({ shrink: '0', grow: '0', basis: rem(3) })
  .size({ width: rem(1.5), height: rem(1.5) })
  .center()
  .round(px(100))
  .spacing({ inner: space.s })
  .text({ color: colors.gray, nodecoration: true })
  .select(':hover', style().color({ bg: colors.purple, fg: colors.white }))
  .pointer()

const CloseButton = ModalButton.clone().color({ bg: colors.transparent }).noborders().element('button')

const HelpButton = ModalButton.clone()
  .select(':hover', style().color({ bg: rgba(228, 81, 73, 0.05), fg: colors.red }))
  .element('a')

interface Params {
  open: boolean
}

interface Props extends Params {
  children: React.ReactNode
  setOpen: (open: boolean) => void
  title?: string
  helpLink?: string
  renderHeader?: (props: { closeButton: React.ReactNode; helpButton: React.ReactNode }) => React.ReactNode
  wrapperStyle?: React.CSSProperties
  style?: React.CSSProperties
  isFullWidth?: boolean
  testId?: string
}

const onModalClick = (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
  ev.stopPropagation()
}

export const ModalBox = (props: Props) => {
  const helpButton = props.helpLink ? (
    <HelpButton href={props.helpLink} target="_blank" title="Open Help">
      <Icon name="life-ring" />
    </HelpButton>
  ) : null

  const closeButton = (
    <CloseButton onClick={() => props.setOpen(false)} title="Close">
      <Icon name="times" />
    </CloseButton>
  )

  return (
    <ModalStyle
      onClick={onModalClick}
      isFullWidth={props.isFullWidth}
      className="data-hj-suppress"
      style={props.style}
      data-testid={props.testId}
    >
      {props.renderHeader ? (
        props.renderHeader({ helpButton, closeButton })
      ) : props.title ? (
        <Header>
          <Title>{props.title}</Title>
          {helpButton}
          {closeButton}
        </Header>
      ) : null}
      <div style={props.wrapperStyle}>{props.children}</div>
    </ModalStyle>
  )
}

const Modal = (props: Props) => {
  if (!props.open) return null

  return (
    <ModalContainer open={props.open} setOpen={props.setOpen}>
      <ModalBox {...props}>{props.children}</ModalBox>
    </ModalContainer>
  )
}

export default Modal
