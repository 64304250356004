import LargeScoreCard from 'App/ExperienceFeedback/LargeScoreCard'
import { fr, px2rem, size, space, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React from 'react'

const Container = style()
  .color({ bg: designSystemColors.backgroundNeutralPrimary })
  .border({
    around: `1px solid`,
    color: designSystemColors.borderDefault,
    radius: px2rem(8),
  })
  .nooverflow()
  .spacing({
    inner: space.s,
    rows: px2rem(24),
  })
  .size({ minHeight: px2rem(186) })
  .grid()
  .element()

const StatsGridStyle = style()
  .grid({ columns: [fr(1), fr(1), fr(1)] })
  .spacing({ gap: space.l })
  .element()

const CardStyle = style()
  .cond(
    ({ border }: { border: boolean }) => !!border,
    style().border({
      around: `1px solid`,
      color: designSystemColors.borderDefault,
      radius: px2rem(8),
    }),
  )
  .spacing({
    inner: space.s,
    rows: space.s,
  })
  .size({ height: size.fill })
  .grid()
  .element()

const Title = text.cardHeader().element()

interface StatsProps {
  overallScore: number
  managerScore: number
  newHireScore: number
}

const StatCards: React.FC<StatsProps> = ({ newHireScore, managerScore, overallScore }) => {
  return (
    <Container>
      <StatsGridStyle>
        <CardStyle>
          <Title>Organization score</Title>
          <LargeScoreCard score={overallScore} smallGreyText={'%'} maxScore={100} />
        </CardStyle>

        <CardStyle border>
          <Title>New hire experience</Title>
          <LargeScoreCard score={newHireScore} smallGreyText={'%'} scorebar={false} />
        </CardStyle>

        <CardStyle border>
          <Title>Managers evaluation</Title>
          <LargeScoreCard score={managerScore} smallGreyText={'%'} scorebar={false} />
        </CardStyle>
      </StatsGridStyle>
    </Container>
  )
}

export default StatCards
