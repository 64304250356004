import React, { FC, ReactNode, useEffect, useRef, useState } from 'react'
import { FaChevronDown } from 'react-icons/fa6'
import { Card } from '../Card/Card'
import classes from './Dropdown.module.scss'

export type DropdownItemWithLabel = {
  value: string
  label?: ReactNode
}

export type DropdownItem = DropdownItemWithLabel | string

export interface DropdownProps {
  items: DropdownItem[]
  selectedDefault?: DropdownItem
  onSelect: (item: DropdownItem) => void
  disabled?: boolean
  testId?: string
}

export const Dropdown: FC<DropdownProps> = props => {
  const [selected, setSelected] = useState(props.selectedDefault || props.items[0])

  // We need to call onSelect to apply any filters at the component init time.
  useEffect(() => {
    props.onSelect(props.selectedDefault || props.items[0])
  }, [props.selectedDefault])

  const [open, setOpen] = useState(false)
  const listRef = useRef<HTMLDivElement>(null)

  const handleChange = (item: DropdownItem) => {
    setOpen(false)
    setSelected(item)
    props.onSelect(item)
  }

  useEffect(() => {
    const handleClickAway = (e: MouseEvent) => {
      if (listRef.current && !listRef.current.contains(e.target as Node)) {
        setOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickAway)

    return () => {
      document.removeEventListener('mousedown', handleClickAway)
    }
  }, [])

  const disabled = (props.disabled !== undefined && props.disabled) || props.items.length === 0

  return (
    <div className={`${classes.dropdown} ${disabled ? classes.disabled : classes.enabled}`} data-testid={props.testId}>
      <div className={`${classes.dropdownSelect} ${disabled ? classes.disabled : ''}`} onClick={() => setOpen(!open)}>
        {returnLabel(selected)}
        <div className={disabled ? classes.arrowWrapperDisabled : classes.arrowWrapper}>
          <FaChevronDown className={classes.icon} />
        </div>
      </div>
      <div ref={listRef}>
        <Card className={`${classes.list} ${open ? classes.open : classes.closed}`}>
          <ul>
            {props.items.map(item => (
              <li
                key={returnKey(item)}
                onClick={() => handleChange(item)}
                className={selected === item ? classes.selected : ''}
              >
                {returnLabel(item)}
              </li>
            ))}
          </ul>
        </Card>
      </div>
    </div>
  )
}

export const returnKey = (item: DropdownItem) => {
  if (typeof item === 'object') {
    return item.value
  }
  return item
}

export const returnLabel = (item: DropdownItem) => {
  if (typeof item === 'object') {
    if (item.label) {
      return item.label
    }
    return item.value
  }
  return item
}
