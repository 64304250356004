import React from 'react'
import classes from './ScoreInfo.module.scss'
import { FaAngleDown, FaAngleUp, FaMinus } from 'react-icons/fa'

interface ScoreInfoProps {
  header: React.ReactNode
  children: React.ReactNode
  benchmarkScore: number
}

export const ScoreInfo = (props: ScoreInfoProps) => {
  const borderColor = `--border-color-${props.benchmarkScore}`
  const backgroundColor = `--background-color-${props.benchmarkScore}`
  const iconColor = `--icon-color-${props.benchmarkScore}`
  const iconBackgroundColor = `--icon-background-color-${props.benchmarkScore}`

  const handle = props.benchmarkScore > 2 ? <FaAngleUp /> : props.benchmarkScore < 2 ? <FaAngleDown /> : <FaMinus />

  return (
    <div
      className={classes.main}
      style={{ border: `1px solid var(${borderColor})`, backgroundColor: `var(${backgroundColor})` }}
    >
      <div className={classes.header}>
        <div
          className={classes.handle}
          style={{ color: `var(${iconColor})`, backgroundColor: `var(${iconBackgroundColor})` }}
        >
          {handle}
        </div>
        <div className={classes.headerContent}>{props.header}</div>
      </div>
      <div className={classes.content}>{props.children}</div>
    </div>
  )
}
