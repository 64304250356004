import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootState } from 'store'
import { findStatusForAssessment, findStatusForReferenceCheck } from 'selectors/candidates'
import * as selectors from 'selectors'
import RefCheckSummary from 'App/Candidate/Summary'
import SelfAssessment from 'App/Candidate/SelfAssessment/AssessmentPage/AssessmentPage'

export const RolePageRedirect = () => {
  const { roleid } = useParams<{ roleid: string }>()

  const referenceStatus = useSelector((state: RootState) => findStatusForReferenceCheck(state, roleid))
  const assessmentStatus = useSelector((state: RootState) => findStatusForAssessment(state, roleid))
  const role = useSelector((state: RootState) => selectors.roles.findById(state, roleid))
  const roleHasReferenceCheck =
    role?.fields.ask_reference_check && (role.fields.reference_check_preset_id || role.fields.reference_form_id)

  const roleHasAssessment = role?.fields.ask_self_assessment && role.fields.self_assessment_preset_id

  if (assessmentStatus === 'completed') {
    return <SelfAssessment />
  }
  if (referenceStatus === 'completed') {
    return <RefCheckSummary />
  }

  if (roleHasReferenceCheck && !roleHasAssessment) {
    return <RefCheckSummary />
  }

  return <SelfAssessment />
}
