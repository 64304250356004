import { px2rem, space, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React from 'react'

export type SelectedRoleCategory = 'mine' | 'org' | 'deleted' | 'starred'

interface RoleCategorySelectorProps {
  selected: SelectedRoleCategory
  setSelected: (selected: SelectedRoleCategory) => void
  orgAdmin: boolean
}

const RoleCategorySelectorStyle = style()
  .flex()
  .spacing({ gap: px2rem(12) })
  .element()

const Category = style()
  .flex()
  .pointer()
  .round(px2rem(4))
  .size({ height: px2rem(32) })
  .spacing({ columns: px2rem(8), inner: [space.none, space.xxs] })
  .bg({ color: designSystemColors.transparent })
  .cond(
    ({ isActive }: { isActive: boolean }) => isActive,
    style().bg({ color: designSystemColors.backgroundNeutralTertiary }),
  )
  .border({ around: 'none' })
  .element('button')

const CategoryEmoji = text.bodyInteractive().spacing({ outer: space.auto }).center({ vertical: true }).element()

const CategoryTitle = text
  .bodyInteractive()
  .spacing({ outer: [space.auto, space.none] })
  .center({ vertical: true })
  .sans({ ellipsis: true })
  .element()

const RoleCategorySelector: React.FC<RoleCategorySelectorProps> = ({ selected, setSelected, orgAdmin }) => {
  return (
    <RoleCategorySelectorStyle>
      <Category isActive={selected === 'mine'} onClick={() => setSelected('mine')}>
        <CategoryEmoji>📂</CategoryEmoji>
        <CategoryTitle>My Jobs</CategoryTitle>
      </Category>
      {orgAdmin ? (
        <Category isActive={selected === 'org'} onClick={() => setSelected('org')}>
          <CategoryEmoji>🏢</CategoryEmoji>
          <CategoryTitle>All org Jobs</CategoryTitle>
        </Category>
      ) : (
        <></>
      )}
      <Category isActive={selected === 'deleted'} onClick={() => setSelected('deleted')}>
        <CategoryEmoji>🗑</CategoryEmoji>
        <CategoryTitle>Deleted</CategoryTitle>
      </Category>
      <Category isActive={selected === 'starred'} onClick={() => setSelected('starred')}>
        <CategoryEmoji>⭐</CategoryEmoji>
        <CategoryTitle>Starred</CategoryTitle>
      </Category>
    </RoleCategorySelectorStyle>
  )
}

export default RoleCategorySelector
