export default {
  user: 'users',
  profile: 'profiles',
  team: 'teams',
  org: 'organizations',
  openjobrole: 'open_job_roles',
  assessment: 'assessments',
  candidate: 'candidates',
  candidateProfile: 'candidate_profiles',
  scale: 'scales',
  response: 'candidate_responses',
  genericAssessment: 'generic_assessments',
  assessmentVersion: 'assessment_versions',
  subscore: 'subscores',
  scaleScore: 'scale_scores',
  webhook: 'webhooks',
}
