import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Form from '../../components/Form'
import * as tracking from '../../core/track'
import { RootState } from '../../store'
import { fetchSSO } from '../../store/login'
import { GoogleSSO, signInWithGoogle } from '../SignUp/SocialSSO/GoogleSSO'
import LoginForm from './LoginForm'

import { Card, Logo, Text } from '@common/components'
import classes from '../SignUp/Signup.module.scss'

interface LoginPageProps {
  interceptGoogle: boolean
}

const Component: React.FC<LoginPageProps> = values => {
  const dispatch = useDispatch()
  // const location = useLocation<{ from: Location }>()

  const { isLoggedIn } = useSelector((state: RootState) => state.sessions)

  const trackingProps = useSelector((state: RootState) => tracking.users.extractPropsFromState(state))
  const email = useSelector((state: RootState) => state.login.email)

  useEffect(() => {
    if (email) {
      const domain = email.split('@')[1]
      dispatch(fetchSSO(domain))
    }
  }, [dispatch, email])

  if (isLoggedIn) {
    tracking.users.login({})

    if (trackingProps) {
      tracking.identify(trackingProps)
    }

    const to = '/' // default redirection path
    /*
    Causes white pages, disable for now.

    if (location && location.state) {
      to =  location.state.from.pathname || to
    }*/
    return <Redirect to={to} />
  }

  return (
    <div className={classes.main}>
      <Card big className={classes.loginCard}>
        <div className={`${classes.center} ${classes.marginBottom}`}>
          <Logo />
          <Text variant="highlight" style={{ textAlign: 'center' }}>
            Log in to HiPeople
          </Text>
        </div>
        {createLoginForm(values.interceptGoogle)}

        <Text variant="body-text" className={classes.termsLogin}>
          By continuing, you agree to the{' '}
          <a href={'https://www.hipeople.io/terms-and-conditions'} target={'_blank'} rel="noreferrer">
            Terms
          </a>{' '}
          and{' '}
          <a href={'https://www.hipeople.io/privacy'} target={'_blank'} rel="noreferrer">
            Privacy Policy
          </a>
          , and to be updated on HiPeople news.
        </Text>
      </Card>
    </div>
  )
}

function createLoginForm(interceptGoogle?: boolean) {
  if (interceptGoogle) {
    return <GoogleSSO>{createFormElement()}</GoogleSSO>
  }

  return createFormElement()
}

function createFormElement() {
  return (
    <Form>
      <LoginForm onGoogleSignIn={signInWithGoogle} />
    </Form>
  )
}

export default Component
Component.displayName = 'Login'
