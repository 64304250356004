import React from 'react'
import classes from './Dot.module.scss'

interface DotProps {
  color: string
}

export const Dot = (props: DotProps) => {
  return <div className={classes.dot} style={{ backgroundColor: props.color }}></div>
}
