import Button from 'components/Button/ButtonV2'
import DropdownField, { Option } from 'components/DropdownField'
import { Icon } from 'components/Icons'
import { px2rem, size, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React from 'react'
import * as tracking from '../../core/track'
import { Buttons, Container, Description, Title } from './Shared'
import { Select } from '@common/components'

const Tip = text
  .bodyText()
  .sans({ align: 'center' })
  .color({ bg: designSystemColors.backgroundNeutralTertiary })
  .border({ radius: px2rem(8) })
  .spacing({ outerBottom: px2rem(64), inner: px2rem(14) })
  .element('p')

const AccessEntryTable = style().element()
const AccessEntryTableHeader = style()
  .grid({ columns: [px2rem(28), size.auto, px2rem(128), px2rem(16)] })
  .spacing({ gap: px2rem(12), outerBottom: px2rem(10) })
  .element()
const AccessEntryTableHeaderLabel = text.bodyInteractive().block().element('span')
const AccessEntryRow = style()
  .grid({
    columns: [px2rem(28), size.auto, px2rem(128), px2rem(16)],
    align: 'center',
  })
  .spacing({ gap: px2rem(12), outerBottom: px2rem(18) })
  .element()
const AccessEntryOrder = text.bodyText().block().element('span')
const AccessEntryName = style().element()
const AccessEntryPermission = style().element()
const AccessEntryActions = style().element()
const AccessEntryDeleteButton = style()
  .noborders()
  .block()
  .bg({ color: designSystemColors.transparent })
  .pointer()
  .element('button')

export type AccessEntryPermissionLevel = 'admin' | 'viewer'
const permissionLevels: { label: string; value: AccessEntryPermissionLevel }[] = [
  { label: 'Admin', value: 'admin' },
  { label: 'Viewer', value: 'viewer' },
]

type AccessEntry = {
  userId: string
  name: string
  permissionLevel?: AccessEntryPermissionLevel
}

export type RoleCreationAccessValues = AccessEntry[]

interface RoleCreationAccessProps {
  allUsers: { userId: string; name: string }[]
  values: RoleCreationAccessValues
  currentUserId: string
  onChange: React.Dispatch<React.SetStateAction<RoleCreationAccessValues>>
  next: () => any
  back: () => any
}

export const RoleCreationAccess: React.FC<RoleCreationAccessProps> = ({
  allUsers,
  values,
  currentUserId,
  onChange,
  next,
  back,
}) => {
  const pickedUserIds = new Set(values.map(({ userId }) => userId).filter(userId => userId))
  const notPickedUsers = allUsers.filter(({ userId }) => !pickedUserIds.has(userId))
  const isAddUserButtonDisabled =
    notPickedUsers.length === 0 || values.length === allUsers.length || values.some(({ userId }) => !userId)
  const isNextButtonDisabled = values.some(({ userId, permissionLevel }) => !userId || !permissionLevel)

  const dropdownOptions = notPickedUsers.map(({ userId, name }) => ({
    value: userId,
    label: name,
  }))

  return (
    <Container>
      <Title data-testid="role-creation-access-title">{'Add team members'}</Title>
      <Description>{'Who should have access to this job?'}</Description>
      <Tip>
        {'💡️ '}
        <strong>{'Tip: '}</strong>
        {'Make sure to invite the Hiring Manager(s) for the job.'}
      </Tip>
      <AccessEntryTable>
        <AccessEntryTableHeader>
          <AccessEntryOrder></AccessEntryOrder>
          <AccessEntryName>
            <AccessEntryTableHeaderLabel>{'👤️ Name'}</AccessEntryTableHeaderLabel>
          </AccessEntryName>
          <AccessEntryPermission>
            <AccessEntryTableHeaderLabel>{'🔧 Permissions'}</AccessEntryTableHeaderLabel>
          </AccessEntryPermission>
          <AccessEntryActions></AccessEntryActions>
        </AccessEntryTableHeader>
        {values.map(({ userId, name, permissionLevel }, i) => (
          <AccessEntryRow key={userId}>
            <AccessEntryOrder>{`${i + 1}.`}</AccessEntryOrder>
            <AccessEntryName>
              <Select
                options={
                  userId
                    ? [{ value: userId, label: currentUserId === userId ? `${name} (you)` : name }, ...dropdownOptions]
                    : dropdownOptions
                }
                value={userId}
                placeholder="Select"
                disabled={currentUserId === userId}
                onChange={userId => {
                  onChange(values => {
                    const newValues = [...values]
                    const name = allUsers.find(user => user.userId === userId)?.name || ''
                    newValues[i] = { ...values[i], userId, name }
                    return newValues
                  })
                }}
              />
            </AccessEntryName>
            <AccessEntryPermission>
              <DropdownField<AccessEntryPermissionLevel | undefined>
                label=""
                placeholder="Select"
                value={permissionLevel}
                onChange={permissionLevel => {
                  onChange(values => {
                    const newValues = [...values]
                    newValues[i] = { ...values[i], permissionLevel }
                    return newValues
                  })
                }}
              >
                {permissionLevels.map(({ label, value }) => (
                  <Option key={value} value={value}>
                    {label}
                  </Option>
                ))}
              </DropdownField>
            </AccessEntryPermission>
            <AccessEntryActions>
              {currentUserId !== userId && (
                <AccessEntryDeleteButton
                  onClick={() =>
                    onChange(values => {
                      tracking.roles.roleCreationTeamMemberAccessRemoved()
                      return values.filter((_v, itemIndex) => itemIndex !== i)
                    })
                  }
                >
                  <Icon name="times" ariaLabel="Delete User" />
                </AccessEntryDeleteButton>
              )}
            </AccessEntryActions>
          </AccessEntryRow>
        ))}
      </AccessEntryTable>
      <Button
        buttonType="tertiary"
        onClick={() => {
          tracking.roles.roleCreationClickToAddUser()
          onChange(values => [...values, { userId: '', name: '' }])
        }}
        isFullWidth={true}
        disabled={isAddUserButtonDisabled}
      >
        {'Click to add user'}
      </Button>
      <Buttons>
        <Button buttonType="secondary" onClick={back}>
          {'Back'}
        </Button>
        <Button
          buttonType="primary"
          onClick={() => {
            tracking.roles.roleCreationTeam()
            next()
          }}
          disabled={isNextButtonDisabled}
        >
          {'Next step'}
        </Button>
      </Buttons>
    </Container>
  )
}
