import { BadgeTheme } from 'components/Badge'
import { get } from './methods'
import { APIResponse, Entity } from './request'

export const RESOURCE = 'groups'

export interface Fields {
  id: string
  product: 'reference-check' | 'self-assessment' | 'onboarding' | 'candidate-experience'
  slug: string
  copy: {
    icon: string
    emoji: string
    title: string
    theme: BadgeTheme
    is_scorable: boolean
    short_title?: string
  }
  properties: {
    exclude_from_overall_score?: boolean
  }
  created_at: number
  updated_at: number
  is_active: boolean
  library_item_count: number
}

export function list(): Promise<APIResponse<Entity<Fields>[]>> {
  return get(`groups?include=libraryitems`)
}
