import InfoCard from 'App/CandidateOnly/InfoCard'

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import * as selectors from 'selectors'
import { RootState } from 'store'

const CandidateStatus = () => {
  const { t } = useTranslation()
  const { candidateid } = useParams<{ candidateid: string }>()

  const candidate = useSelector((state: RootState) => selectors.candidates.findById(state, candidateid || ''))
  const deadline = useSelector((state: RootState) => selectors.candidates.getDeadline(state, candidateid || ''))
  const role = useSelector((state: RootState) => selectors.roles.findByCandidateId(state, candidateid || ''))

  if (!candidate || !role || !deadline) {
    return <></>
  }

  if (candidate?.fields.expired_at && !candidate?.fields.submitted_at) {
    return (
      <InfoCard heading={t('candidate-dashboard-intro.overdue.title', '⏳ Overdue')}>
        {t(
          'candidate-dashboard-intro.overdue.text',
          'You are overdue, please invite your references as soon as possible!',
        )}
      </InfoCard>
    )
  }

  if (candidate?.fields.completed_at)
    return (
      <InfoCard heading={t('candidate-dashboard-intro.done.title', '✅ Done')}>
        {t('candidate-dashboard-intro.done.text', 'Reference check has been completed. Thanks! 👍')}
      </InfoCard>
    )

  if (candidate?.fields.submitted_at)
    return (
      <InfoCard heading={t('candidate-dashboard-intro.waiting.title', '📝 Waiting for references')}>
        {t(
          'candidate-dashboard-intro.waiting.text',
          "Thanks for adding the references. We'll notify you via email as the new replies come in. Just sit back and relax, we got this! 👍",
        )}
      </InfoCard>
    )

  return (
    <InfoCard heading={t('candidate-dashboard-intro.invite.title', '📤 Invite references')}>
      {t(
        'candidate-dashboard-intro.invite.text',
        'To enable HiPeople to get reference checks for you, specify below the people to contact. Please provide the contact details before {DATE}',
        { DATE: selectors.dateString(deadline) },
      )}
    </InfoCard>
  )
}

export default CandidateStatus
