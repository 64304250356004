import { del, post } from './methods'
import { APIResponse, Entity, getApiUrl } from './request'

export const RESOURCE = 'background_check_integrations'

export interface Fields {
  id: string
  organization_id: string
  provider_slug: string
  access_token: string
  is_ready: boolean
  created_at: number
  updated_at: number
}

interface OAuthFields {
  url: string
}
export function installOAuthProvider(orgId: string, providerId: string): Promise<APIResponse<Entity<OAuthFields>>> {
  return post<Entity<OAuthFields>>(`organizations/${orgId}/backgroundcheckintegrations/install/oauth`, {
    provider_id: providerId,
  })
}

export function deleteOrgIntegration(orgId: string, integrationId: string): Promise<APIResponse<Entity<Fields>>> {
  return del<Entity<any>>(
    `organizations/${orgId}/backgroundcheckintegrations/${integrationId}?include=organizations,integrations`,
    {},
  )
}

export function getSessionTokenPath(orgId: string, providerSlug: string): string {
  return getApiUrl(`/organizations/${orgId}/backgroundcheckintegrations/token/${providerSlug}`)
}
