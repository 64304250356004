import Pairwise from 'components/Pairwise'
import { px2rem, style } from 'core'
import React from 'react'

interface RDSForcedTruthProps {
  answers: {
    startCaption: string
    endCaption: string
    value: number
    skipped?: boolean
  }[]
}

const Container = style()
  .grid()
  .spacing({ rows: px2rem(12) })
  .element()

const RDSForcedTruth = ({ answers }: RDSForcedTruthProps) => {
  return (
    <Container>
      {answers.map(answer => {
        // answer.value should go from 0 to 4 instead of 0 to 10 and should round to the nearest integer
        const answerWithCorrectValue = Math.round(answer.value / 2.5)

        return (
          <Pairwise
            key={answer.startCaption}
            startCaption={answer.startCaption}
            endCaption={answer.endCaption}
            score={answer.skipped ? null : answerWithCorrectValue}
          />
        )
      })}
    </Container>
  )
}

export default RDSForcedTruth
