import React from 'react'
import { colors, scale, size, space, style } from '../../core'
import Breadcrumbs, { Breadcrumb } from './Breadcrumbs'

export const BaseHeader = style()
  .sans({ size: scale.s })
  .spacing({ inner: [space.none, space.s] })
  .border({ bottom: '1px solid', color: colors.lightMoonGray })
  .stretch()

const HeaderStyle = BaseHeader.clone().flex({ alignItems: 'center' }).spacing({ gap: '1em' }).element()

const Separator = style().element()

const QuickActions = style().element()

export const Content = style().spacing({ outerLeft: size.auto }).element()

interface Props {
  breadcrumbs: Breadcrumb[]
  children?: React.ReactNode
  quickActions?: React.ReactNode
}

const Header = (props: Props) => {
  const children = Array.isArray(props.children) ? [...props.children] : [props.children]

  const contentChildren = children.filter(c => c && (c as JSX.Element).props.content)

  return (
    <HeaderStyle quickActions={props.quickActions !== undefined}>
      <Breadcrumbs content={props.breadcrumbs} />
      <Separator />
      <Content>{contentChildren}</Content>
      {props.quickActions ? <QuickActions>{props.quickActions}</QuickActions> : ''}
      <Separator />
    </HeaderStyle>
  )
}

export default Header
