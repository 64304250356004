import { get, put } from './methods'
import { APIResponse, Entity } from './request'

export const RESOURCE = 'saml_sso'

export interface Fields {
  id: string
}

interface IDFields {
  id: string
}

interface LoginURL {
  login_url: string
}

export function loadByEmailDomain(email: string): Promise<APIResponse<Entity<IDFields>>> {
  return get(`samlsso/check?email_domain=${email}`)
}

export function loginURL(id: string): Promise<APIResponse<Entity<LoginURL>>> {
  return put<Entity<LoginURL>>(`samlsso/${id}/login_url`, {})
}

export function logout(id: string): Promise<APIResponse<Entity<{}>>> {
  return put<Entity<{}>>(`samlsso/${id}/logout`, {})
}
