import { DECIMAL_PRECISION_BENCHMARKS } from 'components/Benchmark'
import { RootState } from 'store'
import { OLD_OVERALL_PERFORMANCE_QUESTION, OVERALL_PERFORMANCE_QUESTION } from '../store/new-role/content'

export function findAverageOrgOverallPerformanceScore(state: RootState, candidateId: string): number | undefined {
  return (
    findAverageOrgSkillScore(state, candidateId, OVERALL_PERFORMANCE_QUESTION.test_item_id) ||
    findAverageOrgSkillScore(state, candidateId, OLD_OVERALL_PERFORMANCE_QUESTION.test_item_id)
  )
}

export function findAverageOrgSkillScore(state: RootState, candidateId: string, skill: string): number | undefined {
  const candidateScores = state.candidates.itemScores[candidateId]
  if (!candidateScores) return

  const fields = candidateScores.find(q => q.question_content_id === skill || q.question_id === skill)
  if (!fields) return

  return roundOrgAverageToDecimalPrecision(fields.average_candidate_rating_in_org, DECIMAL_PRECISION_BENCHMARKS)
}

function roundOrgAverageToDecimalPrecision(num: number, precision: number): number {
  return parseFloat(num.toFixed(precision))
}
