import { Calendar } from 'primereact/calendar'
import { ColumnFilterElementTemplateOptions } from 'primereact/column'
import { FormEvent } from 'primereact/ts-helpers'
import React from 'react'

export const DateFilter = (options: ColumnFilterElementTemplateOptions) => {
  return (
    <Calendar
      value={options.value}
      onChange={(e: FormEvent) => {
        options.filterCallback(e.value, options.index)
      }}
      selectionMode={'single'}
      dateFormat="mm/dd/yy"
      placeholder="mm/dd/yyyy"
      mask="99/99/9999"
      hideOnDateTimeSelect
    />
  )
}
