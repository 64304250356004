import { Fields, RESOURCE, ResponseOptionEntity } from 'api/response-options'
import { RootState } from 'store'
import lookup from './lookup'
import { findById as findQuestionById } from './questions'

export function findById(state: RootState, id: string) {
  return lookup<Fields>(state, RESOURCE, id)
}

export function findByQuestionId(state: RootState, questionId: string) {
  const question = findQuestionById(state, questionId)

  if (!question) {
    return undefined
  }

  const responseOptionIds = question.relationships?.filter(r => r.resource === RESOURCE).map(r => r.id) || []

  return (
    responseOptionIds.map(id => findById(state, id)).filter(entity => entity !== undefined) as ResponseOptionEntity[]
  ).sort((a, b) => a.fields.created_at - b.fields.created_at)
}
