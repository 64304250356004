import React from 'react'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import * as selectors from '../../selectors'
import colors from './colors'

const WIDTH = '90%'
const HEIGHT = 200
const MARGIN = {
  top: 15,
  right: 40,
  left: 0,
  bottom: 5,
}

interface Props {
  data: selectors.charts.Data
  options: selectors.charts.chart.LineChartOptions
}

const Component = (props: Props) => {
  return (
    <ResponsiveContainer width={WIDTH} height={HEIGHT}>
      <LineChart data={props.data} syncId={props.options.syncId} margin={MARGIN}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis domain={props.options.x.domain} dataKey={props.options.x.key} />
        <YAxis dataKey={props.options.y.key} domain={props.options.y.domain} />
        <Tooltip labelFormatter={props.options.tooltipTitleFormatter} formatter={props.options.tooltipFormatter} />
        {props.options.lines.map((line, ind) => (
          <Line
            key={line}
            type="monotone"
            dataKey={line}
            stroke={colors[ind]}
            fill={colors[ind]}
            activeDot={{ r: 8 }}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  )
}

export default Component
Component.displayName = 'LineChart'
