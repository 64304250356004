import { px2rem } from '..'
import style from '../style'
import { designSystemColors } from './colors'

export const text = {
  highlight: () =>
    style()
      .sans({ weight: '600', size: px2rem(30), line: px2rem(40) })
      .color({ fg: designSystemColors.typographyPrimary })
      .nospacing(),
  cardHeader: () =>
    style()
      .sans({ weight: '600', size: px2rem(16), line: px2rem(19) })
      .color({ fg: designSystemColors.typographyPrimary })
      .nospacing(),
  bodyText: () =>
    style()
      .sans({
        weight: '400',
        size: px2rem(14),
        line: px2rem(18),
        numeric: 'tabular-nums',
      })
      .color({ fg: designSystemColors.typographyPrimary })
      .nospacing(),
  secondaryBodyText: () =>
    style()
      .sans({
        weight: '400',
        size: px2rem(14),
        line: px2rem(18),
        numeric: 'tabular-nums',
      })
      .color({ fg: designSystemColors.typographySecondary })
      .nospacing(),
  smallBodyText: () =>
    style()
      .sans({
        weight: '400',
        size: px2rem(12),
        line: px2rem(16),
        numeric: 'tabular-nums',
      })
      .color({ fg: designSystemColors.typographyPrimary })
      .nospacing(),
  bodyInteractive: () =>
    style()
      .sans({ weight: '500', size: px2rem(14), line: px2rem(18) })
      .color({ fg: designSystemColors.typographyPrimary })
      .nospacing(),
  secondaryBodyInteractive: () =>
    style()
      .sans({
        weight: '500',
        size: px2rem(14),
        line: px2rem(18),
      })
      .color({ fg: designSystemColors.typographySecondary })
      .nospacing(),
  bodyEmphasis: () =>
    style()
      .sans({
        weight: '600',
        size: px2rem(14),
        line: px2rem(18),
        numeric: 'tabular-nums',
      })
      .color({ fg: designSystemColors.typographyPrimary })
      .nospacing(),
  smallBody: () =>
    style()
      .sans({
        weight: '500',
        size: px2rem(12),
        line: px2rem(14),
        numeric: 'tabular-nums',
      })
      .color({ fg: designSystemColors.typographyPrimary })
      .nospacing(),
  smallBodySecondary: () =>
    style()
      .sans({
        weight: '500',
        size: px2rem(12),
        line: px2rem(14),
        numeric: 'tabular-nums',
      })
      .color({ fg: designSystemColors.typographySecondary })
      .nospacing(),
  label: () =>
    style()
      .sans({ weight: '600', size: px2rem(12), line: px2rem(14) })
      .color({ fg: designSystemColors.typographyPrimary })
      .nospacing(),
  scoreHighlightPrimary: () =>
    style()
      .sans({ weight: '500', size: px2rem(32), line: px2rem(40) })
      .color({ fg: designSystemColors.typographyPrimary })
      .nospacing(),
  scoreHighlightSecondary: () =>
    style()
      .sans({ weight: '300', size: px2rem(32), line: px2rem(40) })
      .color({ fg: designSystemColors.typographySecondary })
      .nospacing(),
  heroText: () =>
    style()
      .sans({ weight: '400', size: px2rem(48), line: px2rem(65) })
      .color({ fg: designSystemColors.typographyPrimary })
      .nospacing(),
  timelineHeader: () =>
    style()
      .sans({ weight: '500', size: px2rem(18), line: px2rem(24) })
      .color({ fg: designSystemColors.typographyPrimary })
      .nospacing(),
  timelineStep: () =>
    style()
      .sans({ weight: '500', size: px2rem(25), line: px2rem(34) })
      .color({ fg: designSystemColors.typographyPrimary })
      .nospacing(),
}
