import { get } from './methods'
import { APIResponse } from './request'

export function getByCandidateId(id: string): Promise<APIResponse<undefined, Fields[]>> {
  return get<undefined, Fields[]>(`/candidates/${id}/item_scores`)
}

export interface Fields {
  question_id?: string
  question_content_id: string
  is_skill_item: boolean
  heading: string
  heading_slug: string
  count_candidates_with_item_in_org: number
  count_references_with_item_in_org: number
  average_candidate_rating_in_org: number
  average_reference_rating_in_org: number
}
