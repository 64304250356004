import React from 'react'
import { Badge, BadgeTheme, BADGE_THEMES } from '../../../../components/Badge'
import { designSystemColors } from '../../../../core/design-system/colors'
import { text } from '../../../../core/design-system/text'
import classes from './FilterBadgeButton.module.scss'

const FilterBadgeButtonStyle = ({
  children,
  onClick,
  selected,
  theme,
}: {
  children: React.ReactNode
  onClick: () => void
  selected?: boolean
  theme: BadgeTheme
}) => (
  <button
    className={classes.button}
    onClick={onClick}
    style={
      selected
        ? {
            backgroundColor: BADGE_THEMES[theme].bg,
          }
        : {}
    }
  >
    {children}
  </button>
)

const Label = text
  .smallBody()
  .sans({ align: 'left' })
  .color({ fg: designSystemColors.typographySecondary })
  .element('span')

interface FilterBadgeButtonProps {
  badge: { theme: BadgeTheme; icon: string; copy: string }
  label: string
  selected?: boolean
  onClick: () => void
}

// TODO: add 'active' filter state style

const FilterBadgeButton: React.FC<FilterBadgeButtonProps> = ({ badge, label, onClick, selected }) => {
  return (
    <FilterBadgeButtonStyle onClick={onClick} selected={selected} theme={badge.theme}>
      <Badge copy={badge.copy} icon={badge.icon} theme={badge.theme} />
      {label && <Label>{label}</Label>}
    </FilterBadgeButtonStyle>
  )
}

export default FilterBadgeButton
