import { Entity } from 'api/request'
import { Fields } from 'api/required-references'
import InviteLinkModal from 'App/Reference/InviteLinkModal'
import Profile from 'App/Reference/Profile'
import UpdateEmailModal from 'App/Reference/UpdateEmailModal'
import { useConfirm } from 'components/Confirm'
import FeedbackStyleBadge from 'components/FeedbackStyleBadge'
import { Icon } from 'components/Icons'
import Modal from 'components/Modal'
import Popup, { Button, Separator } from 'components/Popup'
import RelationshipLabel from 'components/RelationshipLabel'
import VerificationBadge, { JobTitleBadge } from 'components/VerificationBadge'
import { px2rem, size, space, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import {
  deleted as dispatchReferenceDeletedEvent,
  showDetails as dispatchReferenceDetailsShownEvent,
} from 'core/track/references'
import { useSelectedCandidate } from 'providers/candidates'
import {
  useReferenceFeedbackStyles,
  useReferences,
  useReferenceStatuses,
  useSameOriginReferences,
} from 'providers/references'
import { useIsAllowedToManagedRole } from 'providers/users'
import React from 'react'
import { useSelector } from 'react-redux'
import relativeDate from 'relative-date'
import { FeedbackStyleType } from 'selectors/halo-score'
import { ReferenceStatus, ReferenceStatusStep, Relationship, relationshipOf } from 'selectors/references'
import * as selectors from '../../selectors'
import * as requiredreferences from '../../selectors/required-references'
import { RootState } from '../../store'
import classes from './ReferenceCard.module.scss'
import Warnings from './Warnings'

const IconContainer = style()
  .size({ width: px2rem(16) })
  .color({ fg: designSystemColors.typographySecondary })
  .cond(({ isReplied }: { isReplied: boolean }) => isReplied, style().color({ fg: designSystemColors.uiStatusSuccess }))
  .spacing({ outerRight: px2rem(12) })
  .element()

const Name = text.smallBody().fg(designSystemColors.typographySecondary).element('p')

const FeedbackStyleBadgeContainer = style().element()

const Labels = style()
  .flex({ alignItems: 'center' })
  .spacing({ outerTop: px2rem(4) })
  .select(
    '* + *',
    style()
      .spacing({ outerLeft: px2rem(10) })
      .set('flexShrink', '0'),
  )
  .element()

const Main = style().set('flexGrow', '1').element()

const MenuButton = style()
  .pointer()
  .flex({ alignItems: 'center', justifyContent: 'center' })
  .noborders()
  .spacing({ inner: px2rem(0) })
  .size({ width: size.fill, height: px2rem(24) })
  .color({ bg: designSystemColors.transparent })
  .select(':hover', style().color({ bg: designSystemColors.backgroundNeutralPrimaryHover }))
  .element('button')

const PopupContainer = style()
  .absolute({ left: size.none, right: size.none, top: size.fill })
  .spacing({ outerTop: px2rem(4) })
  .element()

const Menu = style()
  .size({ width: px2rem(24) })
  .spacing({ outerLeft: px2rem(12) })
  .element()

const ReferenceCardStyle = style()
  .relative()
  .flex({ alignItems: 'center', justifyContent: 'stretch' })
  .color({ bg: designSystemColors.backgroundNeutralPrimary })
  .border({ radius: px2rem(6) })
  .spacing({ inner: px2rem(12) })
  .select(`${Menu}`, style().invisible())
  .select(`:hover ${Menu}`, style().visible())
  .sans({ align: 'left' })
  .pointer()
  .noborders()
  .size({ width: size.fill })
  .element('div')

type Reference = {
  id: string
  name: string
  isReviewed: boolean
  isVerified: boolean
  isJobTitleVerified?: boolean | null
  relationshipType: Relationship
  referenceStatus: ReferenceStatus
  feedbackStyle: FeedbackStyleType | null
}

interface ReferenceCardProps extends Reference {
  isUserAllowedToUpdateReference: boolean
  showReferenceDetails: () => any
  getInviteLink: () => any
  updateReference: () => any
  deleteReference: () => any
  emptyState?: boolean
}

const TRACKING_STATUS_ICONS: Record<
  ReferenceStatusStep,
  { icon: string; captionFn: (data: ReferenceStatus['data']) => string }
> = {
  'sending-invitation': {
    icon: 'paper-plane',
    captionFn: () => 'Invitation sending',
  },
  'invitation-sent': {
    icon: 'envelope',
    captionFn: ({ emailDeliveredAt }) => `Invite delivered ${relativeDate(emailDeliveredAt)}`,
  },
  'invitation-seen': {
    icon: 'envelope-open',
    captionFn: ({ emailReadAt }) => `Opened invite ${relativeDate(emailReadAt)}`,
  },
  'failed-invitation': {
    icon: 'exclamation-circle',
    captionFn: ({ emailFailedAt }) => `Failed to deliver invite ${relativeDate(emailFailedAt)}`,
  },
  replied: {
    icon: 'check-circle',
    captionFn: ({ repliedAt }) => `Responded ${relativeDate(repliedAt)}`,
  },
} as const

const ReferenceCard: React.FC<ReferenceCardProps> = ({
  name,
  isReviewed,
  isVerified,
  referenceStatus: trackingStatus,
  relationshipType,
  feedbackStyle,
  isUserAllowedToUpdateReference,
  showReferenceDetails,
  getInviteLink,
  updateReference,
  deleteReference,
  isJobTitleVerified,
  emptyState,
}) => {
  const { icon, captionFn } = TRACKING_STATUS_ICONS[trackingStatus.step]
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)

  return (
    <div className={emptyState ? classes.emptyState : ''}>
      <ReferenceCardStyle onClick={showReferenceDetails}>
        <IconContainer title={captionFn(trackingStatus.data)} isReplied={trackingStatus.step === 'replied'}>
          <Icon name={icon} />
        </IconContainer>
        <Main>
          <Name className="data-hj-suppress">{name}</Name>
          <Labels>
            <RelationshipLabel type={relationshipType} />
            {relationshipType !== Relationship.Self && (
              <>
                {isVerified !== undefined && <VerificationBadge verified={isVerified} reviewed={isReviewed} />}
                {isJobTitleVerified !== null && (
                  <JobTitleBadge verified={Boolean(isJobTitleVerified)} reviewed={isReviewed} />
                )}
                {feedbackStyle && (
                  <FeedbackStyleBadgeContainer>
                    <FeedbackStyleBadge style={feedbackStyle} />
                  </FeedbackStyleBadgeContainer>
                )}
              </>
            )}
          </Labels>
        </Main>
        <Menu>
          {isUserAllowedToUpdateReference && (
            <MenuButton
              onClick={ev => {
                ev.stopPropagation()
                setIsMenuOpen(isOpen => !isOpen)
              }}
            >
              <Icon name="ellipsis-v" />
            </MenuButton>
          )}
        </Menu>
        <PopupContainer>
          <Popup open={isMenuOpen} setOpen={setIsMenuOpen}>
            <Button
              variant="secondary"
              icon="link"
              onClick={ev => {
                ev.stopPropagation()
                setIsMenuOpen(false)
                getInviteLink()
              }}
            >
              {'Get invite link'}
            </Button>
            <Separator />
            <Button
              variant="secondary"
              icon="envelope"
              onClick={ev => {
                ev.stopPropagation()
                setIsMenuOpen(false)
                updateReference()
              }}
            >
              {'Update details'}
            </Button>
            <Button
              variant="secondary"
              icon="user-minus"
              onClick={ev => {
                ev.stopPropagation()
                setIsMenuOpen(false)
                deleteReference()
              }}
            >
              {'Delete reference'}
            </Button>
          </Popup>
        </PopupContainer>
      </ReferenceCardStyle>
    </div>
  )
}

const ReferenceListContainer = style()
  .grid({ columns: `repeat(auto-fill, minmax(${px2rem(200)}, 1fr))` })
  .spacing({ gap: px2rem(8), outerTop: px2rem(12) })
  .element()

const ReferenceListTitle = text
  .label()
  .fg(designSystemColors.typographySecondary)
  .spacing({ outerLeft: px2rem(4), outerRight: space.auto })
  .element('h3')
const ReferenceListHeader = style()
  .pointer()
  .fg(designSystemColors.typographySecondary)
  .flex({ alignItems: 'center', grow: '1' })
  .spacing({ outerRight: px2rem(4) })
  .noborders()
  .spacing({ inner: px2rem(0) })
  .color({ bg: designSystemColors.transparent })
  .element('button')
const ReferenceListHeaderSection = style().flex({ alignItems: 'center', justifyContent: 'space-between' }).element()

const ReferenceListUIStyle = style()
  .spacing({ inner: px2rem(16) })
  .border({ radius: px2rem(6) })
  .color({ bg: designSystemColors.backgroundNeutralTertiary })
  .element()

interface ReferenceListUIProps {
  references: Reference[]
  sameOriginReferenceNames: string[]
  candidateName: string
  isUserAllowedToUpdateReference: boolean
  showReferenceDetails: (referenceId: string) => any
  getInviteLink: (referenceId: string) => any
  updateReference: (referenceId: string) => any
  deleteReference: (referenceId: string) => any
  requiredReferences: Entity<Fields>[]
}

const ReferenceListUI: React.FC<ReferenceListUIProps> = ({
  references,
  sameOriginReferenceNames,
  candidateName,
  isUserAllowedToUpdateReference,
  showReferenceDetails,
  getInviteLink,
  updateReference,
  deleteReference,
  requiredReferences,
}) => {
  const [isExpanded, setIsExpanded] = React.useState(true)
  return (
    <ReferenceListUIStyle>
      <ReferenceListHeaderSection>
        <ReferenceListHeader onClick={() => setIsExpanded(isExpanded => !isExpanded)}>
          <Icon name={isExpanded ? 'caret-down' : 'caret-right'} />
          <ReferenceListTitle>{'References'}</ReferenceListTitle>
        </ReferenceListHeader>
        <Warnings candidateName={candidateName} sameOriginReferenceNames={sameOriginReferenceNames} />
      </ReferenceListHeaderSection>
      {isExpanded && (
        <ReferenceListContainer>
          {references.length > 0
            ? references.map((ref, i) => (
                <ReferenceCard
                  key={`reference-card-${i}`}
                  {...ref}
                  isUserAllowedToUpdateReference={isUserAllowedToUpdateReference}
                  showReferenceDetails={() => showReferenceDetails(ref.id)}
                  getInviteLink={() => getInviteLink(ref.id)}
                  updateReference={() => updateReference(ref.id)}
                  deleteReference={() => deleteReference(ref.id)}
                />
              ))
            : requiredReferences.map((ref, i) => {
                // Ref Check Empty State
                const relationshipType = relationshipOf(ref)
                return (
                  <ReferenceCard
                    key={`reference-card-${i}`}
                    isUserAllowedToUpdateReference={false}
                    showReferenceDetails={() => {}}
                    getInviteLink={() => {}}
                    updateReference={() => {}}
                    deleteReference={() => {}}
                    id={ref.id}
                    name="Required Reference"
                    isReviewed={false}
                    isVerified={false}
                    referenceStatus={
                      {
                        step: 'replied',
                        data: {
                          repliedAt: 0,
                        },
                      } as ReferenceStatus
                    }
                    relationshipType={relationshipType}
                    feedbackStyle={null}
                    emptyState
                  />
                )
              })}
        </ReferenceListContainer>
      )}
    </ReferenceListUIStyle>
  )
}

const ReferenceListStyle = style().spacing({ outerBottom: space.s }).element()
const ProfileContainer = style()
  .size({ maxWidth: px2rem(1024) })
  .spacing({ inner: space.s })
  .element()

interface ReferenceListProps {
  isInSharedViewMode?: boolean
}

export const ReferenceList: React.FC<ReferenceListProps> = values => {
  const { candidate, candidateProfile } = useSelectedCandidate()
  const { references, deleteReference } = useReferences({
    candidateId: candidate?.id || '',
  })
  const { referenceStatuses } = useReferenceStatuses({
    referenceIds: references.map(ref => ref.id),
  })
  const { feedbackStyles } = useReferenceFeedbackStyles({
    referenceIds: references.map(ref => ref.id),
  })
  const { sameOriginReferences } = useSameOriginReferences({
    candidateId: candidate?.id || '',
  })
  const sameOriginReferenceNames = sameOriginReferences.map(ref => ref.fields.full_name)

  const role = useSelector((state: RootState) => selectors.roles.findByCandidateId(state, candidate?.id || ''))

  const requiredReferences = useSelector((state: RootState) => requiredreferences.findByRoleId(state, role?.id || ''))

  const isUserAllowedToUpdateReference = useIsAllowedToManagedRole(role?.id || '') && !values.isInSharedViewMode

  const referencesData = references.map<Reference>(ref => {
    const relationshipType = relationshipOf(ref)
    const feedbackStyle = feedbackStyles[ref.id]
    return {
      id: ref.id,
      name: ref.fields.full_name,
      isVerified: ref.fields.is_verified,
      isReviewed: ref.fields.is_reviewed,
      relationshipType,
      feedbackStyle,
      referenceStatus: referenceStatuses[ref.id],
      isJobTitleVerified: role?.fields.candidate_title_verification_enabled ? ref.fields.is_job_title_confirmed : null,
    }
  })
  // @ts-ignore: ValuesOf is not a valid type
  type RawReferenceData = ValuesOf<typeof references>
  const [selectedReference, setSelectedReference] = React.useState<RawReferenceData | undefined>()

  const [isReferenceDetailsShown, setIsReferenceDetailsShown] = React.useState(false)
  const [isInviteLinkModalOpen, setIsInviteLinkModalOpen] = React.useState(false)
  const [isUpdateEmailModalOpen, setIsUpdateEmailModalOpen] = React.useState(false)

  const confirm = useConfirm()
  const onClickDelete = async (reference: RawReferenceData) => {
    const confirmActionResult = await confirm({
      title: `About to delete a reference, continue?`,
      message: `**${reference.fields.full_name || ''}** will be deleted and won't be able to continue the process.`,
      confirmLabel: 'Yes, delete reference',
      requireInput: reference.fields.full_name,
      requireInputLabel: 'Enter the full name of the reference to confirm:',
      requireInputPlaceholder: 'Verify the full name of the reference to delete',
      danger: true,
    })
    if (confirmActionResult) {
      deleteReference(reference.id)
      dispatchReferenceDeletedEvent()
    }
    setSelectedReference(undefined)
  }

  return (
    <ReferenceListStyle>
      <ReferenceListUI
        requiredReferences={requiredReferences}
        candidateName={candidateProfile?.fields.full_name || ''}
        sameOriginReferenceNames={sameOriginReferenceNames}
        references={referencesData}
        isUserAllowedToUpdateReference={isUserAllowedToUpdateReference}
        showReferenceDetails={referenceId => {
          setIsReferenceDetailsShown(true)
          setSelectedReference(references.find(ref => ref.id === referenceId))
          dispatchReferenceDetailsShownEvent()
        }}
        getInviteLink={referenceId => {
          setIsInviteLinkModalOpen(true)
          setSelectedReference(references.find(ref => ref.id === referenceId))
        }}
        updateReference={referenceId => {
          setIsUpdateEmailModalOpen(true)
          setSelectedReference(references.find(ref => ref.id === referenceId))
        }}
        deleteReference={referenceId => {
          const selectedReference = references.find(ref => ref.id === referenceId)
          if (!selectedReference) {
            return
          }
          onClickDelete(selectedReference)
        }}
      />
      {isReferenceDetailsShown && selectedReference && (
        <Modal
          open={true}
          setOpen={() => {
            setIsReferenceDetailsShown(false)
            setSelectedReference(undefined)
          }}
          title={selectedReference.fields.full_name}
        >
          <ProfileContainer>
            <Profile reference={selectedReference} />
          </ProfileContainer>
        </Modal>
      )}
      {isInviteLinkModalOpen && selectedReference && (
        <InviteLinkModal
          open={true}
          setOpen={() => {
            setIsInviteLinkModalOpen(false)
            setSelectedReference(undefined)
          }}
          referenceId={selectedReference.id}
        />
      )}
      {isUpdateEmailModalOpen && selectedReference && (
        <UpdateEmailModal
          open={true}
          setOpen={() => {
            setIsUpdateEmailModalOpen(false)
            setSelectedReference(undefined)
          }}
          referenceId={selectedReference.id}
        />
      )}
    </ReferenceListStyle>
  )
}
