import { Button, LockedByPlan, Modal, PageHeader, Tooltip } from '@common/components'
import { EvaluationStatus } from 'api/candidates'
import { apiPut } from 'api/methods'
import DropdownField, { Option } from 'components/DropdownField'
import { BrandIcon, Icon } from 'components/Icons'
import { colors, px2rem, scale, size, space, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import * as tracking from 'core/track'
import { lockedContentTracking } from 'core/track/locked-content'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as selectors from 'selectors'
import { milliseconds } from 'selectors'
import { Step, SubmissionType } from 'selectors/candidates'
import { RootState } from 'store'
import { allEvaluationStatuses, evaluationStatusToText } from 'utils/evaluation_status'
import { formatTimeNano } from 'utils/format'
import { add as notify } from '../../store/notifications'

const IconColumn = style().color({ fg: designSystemColors.typographySecondary }).element()

const Value = text.smallBody().element()

const DetailStyle = style()
  .color({ fg: colors.midGray })
  .grid({ columns: [px2rem(16), 'auto'], align: 'center' })
  .spacing({ columns: space.xxs, inner: [space.xxs, space.none] })
  .sans()
  .select('a', style().text({ color: colors.midGray, nodecoration: true }))
  .element()

interface DetailProps {
  label: string
  icon?: string
  brandIcon?: string
  children: React.ReactNode
}

const Detail: React.FC<DetailProps> = ({ label, children, icon, brandIcon }) => {
  return (
    <DetailStyle title={label} className="data-hj-suppress">
      <IconColumn>
        {icon ? <Icon name={icon} /> : null}
        {brandIcon ? <BrandIcon name={brandIcon} /> : null}
      </IconColumn>
      <Value>{children}</Value>
    </DetailStyle>
  )
}

interface Props {
  candidateId: string
  children?: JSX.Element
  currentProgress?: Step
  button?: {
    send: () => void
    copy: string
  }
  title?: SubmissionType
  score?: number
  isSharedResults?: boolean
  evaluationStatus?: EvaluationStatus
  onEvaluationStatusChanged?: (newStatus: EvaluationStatus) => void
}

const Header = style()
  .flex({
    direction: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  })
  .element()

const Name = text.highlight().nooverflow().sans({ ellipsis: true }).element('h1')

const DetailsTime = style()
  .flex({
    direction: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  })
  .element()

const Image = style().element('img')

const TimeLabel = text.bodyText().spacing({ outerRight: space.s }).element('span')

const Details = style()
  .flex({
    direction: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  })
  .spacing({ gap: space.m })
  .element()

const LabelStyle = style()
  .color({
    bg: designSystemColors.backgroundNeutralTertiary,
    fg: designSystemColors.typographySecondary,
  })
  .cond(
    ({ step }: { step: Step }) => step === Step.InProgress,
    style().color({
      bg: designSystemColors.typographySecondary,
      fg: designSystemColors.white,
    }),
  )
  .cond(
    ({ step }: { step: Step }) => step === Step.Completed,
    style().color({
      bg: designSystemColors.uiStatusSuccess,
      fg: designSystemColors.white,
    }),
  )
  .cond(
    ({ step }: { step: Step }) => step === Step.MarkedComplete,
    style().color({
      bg: designSystemColors.uiStatusSuccess,
      fg: designSystemColors.white,
    }),
  )
  .cond(
    ({ step }: { step: Step }) => step === Step.Expired,
    style().color({
      bg: designSystemColors.uiStatusWarningSecondary,
      fg: designSystemColors.white,
    }),
  )
  .sans({ size: scale.s, align: 'center', ellipsis: true })
  .round(size.m)
  .spacing({ inner: [space.xxs, space.xs] })
  .element()

const Actions = style().flex({ alignItems: 'center' }).spacing({ gap: '1rem' }).element()

const EvaluationStatusDropdownContainer = style().size({ minWidth: '10rem' }).element()

const Label: React.FC<{ step: Step }> = ({ step }) => {
  return <LabelStyle step={step}>{step}</LabelStyle>
}

const DetailSpacer = text
  .smallBody()
  .spacing({ outer: [px2rem(0), px2rem(8)] })
  .element('span')

const ProfileDetails = style().flex({ alignItems: 'center' }).element()

const NamedHeaderUIStyle = style()
  .grid({ rows: [size.auto] })
  .spacing({ innerBottom: size.m, gap: px2rem(12) })
  .element()

interface NamedHeaderUIProps {
  name: string
  updatedAt?: string
  submittedAt?: string
  completedAt?: string
  createdAt?: string
  step?: Step
  email?: string
  phoneNumber?: string
  professionalNetwork?: {
    name: string
    url: string
    icon?: string
    brandIcon?: string
  }
  greenhouseProfileUrl?: string
  resumeUrl?: string
  date?: string
  image?: { src: string; alt: string }
  button?: {
    send: () => void
    copy: string
  }
  score?: number
  candidateId?: string
  roleId?: string
  title?: SubmissionType
  isSharedResults?: boolean
  evaluationStatus?: EvaluationStatus
  onEvaluationStatusChanged?: (newStatus: EvaluationStatus) => void
}

const ButtonContainer = style().center().element()

const NamedHeaderUI: React.FC<NamedHeaderUIProps> = props => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [sendResultsModalOpen, setSendResultsModalOpen] = React.useState(false)

  const isOrgPaidPlan = useSelector(selectors.orgs.isOrgPaidPlan)
  const UserStatus = React.useMemo(() => {
    if (props.isSharedResults) return null
    if (!props.submittedAt)
      return (
        <>
          <TimeLabel>{`Invited: ${props.createdAt}`}</TimeLabel>
          <TimeLabel>{`Last updated: ${props.updatedAt}`}</TimeLabel>
        </>
      )
    if (props.submittedAt)
      return (
        <>
          <TimeLabel>{`Invited: ${props.createdAt}`}</TimeLabel>
          <TimeLabel>{`Completed : ${props.submittedAt}`}</TimeLabel>
        </>
      )
  }, [props.submittedAt, props.updatedAt, props.createdAt, props.isSharedResults])

  const onExportPDFAssessment = () => {
    tracking.candidates.assessmentPdfExportClicked()
    if (!isOrgPaidPlan) {
      dispatch(
        notify({
          error: 'This feature is available only for the paid plan.',
        }),
      )
      return
    }
    try {
      history?.push(`/candidates/${props.candidateId}/assessment/export-pdf`)
    } catch (e) {
      dispatch(
        notify({
          error: 'Failed to export PDF',
        }),
      )
    }
  }

  const onExportPDFRefCheck = () => {
    tracking.candidates.refCheckPdfExportClicked()
    if (!isOrgPaidPlan) {
      dispatch(
        notify({
          error: 'This feature is available only for the paid plan.',
        }),
      )
      return
    }
    window.open(`/roles/${props.roleId}/candidates/${props.candidateId}/export`, '_blank')
  }

  const onShareResults = () => {
    if (!isOrgPaidPlan) {
      dispatch(
        notify({
          error: 'This feature is available only for the paid plan.',
        }),
      )
      return
    }
    setSendResultsModalOpen(true)
    tracking.candidates.sendCandidateFeedbackModalOpened()
  }

  return (
    <NamedHeaderUIStyle>
      <PageHeader.Root data-testid="candidate-profile-header">
        <PageHeader.Title>{props.name}</PageHeader.Title>
        <PageHeader.EndItems>
          {!props.isSharedResults && (
            <Details>
              <DetailsTime>{UserStatus}</DetailsTime>
              {props.button ? (
                <ButtonContainer>
                  <Button onClick={props.button?.send} variant="accent">
                    {props.button?.copy}
                  </Button>
                </ButtonContainer>
              ) : (
                props.step && <Label step={props.step} />
              )}
            </Details>
          )}
          {props.image && <Image src={props.image.src} alt={props.image.alt} />}
        </PageHeader.EndItems>
      </PageHeader.Root>
      {!props.isSharedResults ? (
        <ProfileDetails>
          {props.date && (
            <Detail label="Date" icon="calendar">
              {props.date}
            </Detail>
          )}
          {props.email && (
            <Detail label="Email" icon="envelope">
              {props.email}
            </Detail>
          )}

          {props.professionalNetwork && (
            <>
              <DetailSpacer>{'•'}</DetailSpacer>
              <Detail
                label={`${props.professionalNetwork.name} link`}
                icon={props.professionalNetwork.icon}
                brandIcon={props.professionalNetwork.brandIcon}
              >
                <a href={props.professionalNetwork.url} target="_blank" rel="noopener noreferrer">
                  {props.professionalNetwork.name}
                </a>
              </Detail>
            </>
          )}
          {props.phoneNumber && (
            <>
              <DetailSpacer>{'•'}</DetailSpacer>
              <Detail label="Phone Number" icon="phone">
                {props.phoneNumber}
              </Detail>
            </>
          )}
          {props.resumeUrl && (
            <>
              <DetailSpacer>{'•'}</DetailSpacer>
              <Detail label="Resume" icon="file-user">
                <a href={props.resumeUrl} target="_blank" rel="noopener noreferrer">
                  Resume
                </a>
              </Detail>
            </>
          )}
          {props.greenhouseProfileUrl && (
            <>
              <DetailSpacer>{'•'}</DetailSpacer>
              <Detail label="Greenhouse" brandIcon="goodreads">
                <a href={props.greenhouseProfileUrl} target="_blank" rel="noopener noreferrer">
                  Greenhouse
                </a>
              </Detail>
            </>
          )}
        </ProfileDetails>
      ) : null}
      {props.title === SubmissionType.Assessment && !props.isSharedResults ? (
        <Actions>
          {props.submittedAt && (
            <Tooltip text="Export PDF" direction="top" disabled={!isOrgPaidPlan}>
              <LockedByPlan
                tracking={lockedContentTracking}
                locked={!isOrgPaidPlan}
                upgradeTo={'Grow'}
                modalData={{
                  learnMoreLink: 'https://intercom.help/hipeople/en/articles/7434061-export-results-as-a-pdf',
                  learnMoreAbout: 'Downloading PDF Exports',
                }}
              >
                <Button
                  variant="tertiary"
                  style={{
                    height: '2rem',
                    margin: '0',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onClick={onExportPDFAssessment}
                  data-testid="export-pdf-button"
                >
                  <Icon name={'download'} />
                </Button>
              </LockedByPlan>
            </Tooltip>
          )}
          {props?.evaluationStatus && props.onEvaluationStatusChanged && (
            <EvaluationStatusDropdownContainer>
              <DropdownField small value={props.evaluationStatus} onChange={props.onEvaluationStatusChanged}>
                {allEvaluationStatuses.map(s => (
                  <Option key={`evaluation_status-option-${s}`} value={s} selected={s === props.evaluationStatus}>
                    {evaluationStatusToText(s)}
                  </Option>
                ))}
              </DropdownField>
            </EvaluationStatusDropdownContainer>
          )}

          {props.submittedAt && (
            <LockedByPlan
              tracking={lockedContentTracking}
              locked={!isOrgPaidPlan}
              upgradeTo={'Grow'}
              modalData={{
                learnMoreLink:
                  'https://intercom.help/hipeople/en/articles/8915359-sending-assessment-results-to-candidates',
                learnMoreAbout: 'Sending Assessment Results to Candidates',
              }}
            >
              <Button
                variant="tertiary"
                style={{
                  height: '2rem',
                  margin: '0 0 0 0',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '0.5rem',
                }}
                onClick={onShareResults}
              >
                <Icon name="envelope" />
                Send results to candidate
              </Button>
            </LockedByPlan>
          )}
        </Actions>
      ) : props.title === SubmissionType.ReferenceCheck && !props.isSharedResults ? (
        <Actions>
          {props.completedAt && (
            <Tooltip text="Export PDF" direction="top" disabled={!isOrgPaidPlan}>
              <LockedByPlan
                tracking={lockedContentTracking}
                locked={!isOrgPaidPlan}
                upgradeTo={'Grow'}
                modalData={{
                  learnMoreLink: 'https://intercom.help/hipeople/en/articles/7434061-export-results-as-a-pdf',
                  learnMoreAbout: 'Downloading PDF Exports',
                }}
              >
                <Button
                  variant="tertiary"
                  style={{
                    height: '2rem',
                    margin: '0 0 0 0',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onClick={onExportPDFRefCheck}
                >
                  <Icon name={'download'} />
                </Button>
              </LockedByPlan>
            </Tooltip>
          )}
          {props?.evaluationStatus && props.onEvaluationStatusChanged && (
            <EvaluationStatusDropdownContainer>
              <DropdownField small value={props.evaluationStatus} onChange={props.onEvaluationStatusChanged}>
                {allEvaluationStatuses.map(s => (
                  <Option key={`evaluation_status-option-${s}`} value={s} selected={s === props.evaluationStatus}>
                    {evaluationStatusToText(s)}
                  </Option>
                ))}
              </DropdownField>
            </EvaluationStatusDropdownContainer>
          )}
        </Actions>
      ) : null}
      <Modal
        title="Send results to candidate"
        open={sendResultsModalOpen}
        onClose={() => setSendResultsModalOpen(false)}
        footer={
          <>
            <Button
              variant="tertiary"
              onClick={() => {
                window.open(`/shared/candidates/${props.candidateId}/results`, '_blank')
                tracking.candidates.previewCandidateFeedback()
              }}
            >
              <Icon name="eye" /> Preview
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                setSendResultsModalOpen(false)
                tracking.candidates.cancelSendCandidateFeedback()
              }}
            >
              Cancel
            </Button>
            <Button
              variant="accent"
              onClick={() => {
                setSendResultsModalOpen(false)
                tracking.candidates.sendCandidateFeedback()
                apiPut(`/candidates/${props.candidateId}/share`, {
                  type: 'self-shared-candidate',
                })
                  .then(() => {
                    dispatch(
                      notify({
                        success: 'Successfully sent email to candidate',
                      }),
                    )
                  })
                  .catch(() => {
                    dispatch(
                      notify({
                        error: 'Failed to send email to candidate',
                      }),
                    )
                  })
              }}
            >
              Send
            </Button>
          </>
        }
      >
        This will send {props.name}{' '}
        <span
          style={{
            fontStyle: 'italic',
          }}
        >
          ({props.email})
        </span>{' '}
        an email with a link to view their results.
      </Modal>
    </NamedHeaderUIStyle>
  )
}

const NameHeader = (props: Props) => {
  const candidate = useSelector((state: RootState) => selectors.candidates.findById(state, props.candidateId))
  const profile = useSelector((state: RootState) =>
    selectors.candidateProfiles.findByCandidateId(state, props.candidateId),
  )
  const step = useSelector((state: RootState) => selectors.candidates.getStep(state, props.candidateId, props.title))
  const professionalNetwork = useSelector((state: RootState) =>
    selectors.candidates.getProfessionalNetworkById(state, candidate?.id || ''),
  )

  const roleId = useSelector((state: RootState) => selectors.roles.findByCandidateId(state, props.candidateId))?.id

  const assessment = useSelector((state: RootState) =>
    selectors.questionnaireSubmissions.findSelfAssessmentByCandidateId(state, props.candidateId),
  )

  const updatedAt =
    props.title === SubmissionType.Assessment
      ? formatTimeNano(assessment?.fields['updated_at'])
      : formatTimeNano(candidate?.fields['updated_at'])
  const submittedAt =
    props.title === SubmissionType.Assessment
      ? formatTimeNano(assessment?.fields['submitted_at'])
      : formatTimeNano(candidate?.fields['submitted_at'])
  const completedAt = formatTimeNano(candidate?.fields['completed_at'])
  const createdAt =
    props.title === SubmissionType.Assessment
      ? formatTimeNano(assessment?.fields['created_at'])
      : formatTimeNano(candidate?.fields['created_at'])
  return (
    <NamedHeaderUI
      name={profile?.fields.full_name || ''}
      email={profile?.fields.email || ''}
      professionalNetwork={professionalNetwork}
      step={props.currentProgress ?? step}
      updatedAt={updatedAt}
      button={props.button}
      score={props.score}
      submittedAt={submittedAt}
      createdAt={createdAt}
      candidateId={props.candidateId}
      roleId={roleId}
      title={props.title}
      isSharedResults={props.isSharedResults}
      evaluationStatus={props.evaluationStatus}
      onEvaluationStatusChanged={props.onEvaluationStatusChanged}
      completedAt={completedAt}
    />
  )
}

export default NameHeader

const DetailColumn = style().element()
const DetailColumnHeading = text
  .smallBodySecondary()
  .sans({
    size: px2rem(14),
    line: size.l,
  })
  .element()
const DetailColumnBody = text
  .bodyEmphasis()
  .sans({
    size: px2rem(15),
  })
  .element()

const Email = text
  .smallBody()
  .sans({
    ellipsis: true,
    color: designSystemColors.typographySecondary,
    size: px2rem(15),
    line: space.l,
  })
  .element('p')

interface OnboardingHeaderProps {
  name: string
  email?: string
  manager?: string
  startAt?: Date
  hideCandidateData?: boolean
}

export const EmployeeHeaderWithProps: React.FC<OnboardingHeaderProps> = props => {
  return (
    <NamedHeaderUIStyle>
      <Header>
        {!props.hideCandidateData && (
          <Name className="data-hj-suppress">
            {props.name}
            {props.email ? (
              <Email>
                <Icon name={'envelope'} /> {props.email}
              </Email>
            ) : (
              ''
            )}
          </Name>
        )}
        <Details>
          <DetailColumn>
            <DetailColumnHeading>Starting date</DetailColumnHeading>
            <DetailColumnBody>{props.startAt?.toLocaleDateString() || '-'}</DetailColumnBody>
          </DetailColumn>
          <DetailColumn>
            <DetailColumnHeading>Manager</DetailColumnHeading>
            <DetailColumnBody className="data-hj-suppress">{props.manager || '-'}</DetailColumnBody>
          </DetailColumn>
        </Details>
      </Header>
    </NamedHeaderUIStyle>
  )
}

EmployeeHeaderWithProps.displayName = 'OnboardingHeaderWithProps'

export const OnboardingHeader: React.FC<{
  candidateId: string
}> = ({ candidateId }) => {
  const profile = useSelector((state: RootState) => selectors.candidateProfiles.findByCandidateId(state, candidateId))

  const employee = useSelector((state: RootState) => selectors.employees.findByCandidateId(state, candidateId))

  const managerProfile = useSelector((state: RootState) =>
    selectors.profiles.getByUserId(state, employee?.fields.manager_id || ''),
  )

  return (
    <EmployeeHeaderWithProps
      name={profile?.fields.full_name || ''}
      email={profile?.fields.email}
      manager={managerProfile?.fields.full_name}
      startAt={employee?.fields.start_at ? new Date(milliseconds(employee?.fields.start_at)) : undefined}
      hideCandidateData
    />
  )
}

interface ResponseHeaderProps {
  candidateId: string
  title?: SubmissionType
  date: string
  score?: number
}

export const ResponseHeader = ({ title, candidateId, date, score }: ResponseHeaderProps) => {
  const candidate = useSelector((state: RootState) => selectors.candidates.findById(state, candidateId))

  const step = useSelector((state: RootState) => selectors.candidates.getStep(state, candidateId, title))

  const timestamp = formatTimeNano(candidate?.fields['updated_at'])

  return (
    <NamedHeaderUI
      name={title || SubmissionType.ReferenceCheck}
      step={step}
      updatedAt={timestamp}
      date={date}
      score={score}
      title={title || SubmissionType.ReferenceCheck}
    />
  )
}

interface MinimalNameHeaderProps {
  candidateId: string
}

export const MinimalNameHeader = (props: MinimalNameHeaderProps) => {
  const profile = useSelector((state: RootState) =>
    selectors.candidateProfiles.findByCandidateId(state, props.candidateId),
  )

  return <NamedHeaderUI name={profile?.fields.full_name || ''} email={profile?.fields.email || ''} />
}

interface NameAndImageHeaderProps {
  candidateId: string
  imageSrc: string
  imageAltText: string
}

export const NameAndImageHeader = (props: NameAndImageHeaderProps) => {
  const profile = useSelector((state: RootState) =>
    selectors.candidateProfiles.findByCandidateId(state, props.candidateId),
  )

  return (
    <NamedHeaderUI name={profile?.fields.full_name || ''} image={{ src: props.imageSrc, alt: props.imageAltText }} />
  )
}
