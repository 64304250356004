import { ScoreResponse } from '@common/api/score'
import React from 'react'
import Left from './ForcedTruthLeft.svg?react'
import LeftMost from './ForcedTruthLeftMost.svg?react'
import Middle from './ForcedTruthMiddle.svg?react'
import NoAnswer from './ForcedTruthNone.svg?react'
import Right from './ForcedTruthRight.svg?react'
import RightMost from './ForcedTruthRightMost.svg?react'

interface ScoreProps {
  benchmark: ScoreResponse['meta'][number]['benchmark'] | null
  color?: string
  textColor?: string
  hideTooltip?: boolean
}

const PairwiseScore: React.FC<ScoreProps> = ({ benchmark, color, textColor, hideTooltip }) => {
  const title = hideTooltip ? undefined : `Score: ${benchmark}`

  const cssVariables = {
    '--text-color': textColor || '#FFF',
  } as any

  if (benchmark === null || benchmark === undefined) {
    return <NoAnswer title={title} />
  }
  if (benchmark >= 4) {
    return <RightMost style={{ color: color || '#055FE2', ...cssVariables }} title={title} />
  }
  if (benchmark >= 3) {
    return <Right style={{ color: color || '#2F4DDF', ...cssVariables }} title={title} />
  }
  if (benchmark >= 2) {
    return <Middle style={{ color: color || '#4E40DD', ...cssVariables }} title={title} />
  }
  if (benchmark >= 1) {
    return <Left style={{ color: color || '#6E32DA', ...cssVariables }} title={title} />
  }
  return (
    <LeftMost
      style={{
        color: color || '#9520D6',
        ...cssVariables,
      }}
      title={title}
    />
  )
}

export default PairwiseScore
