import { LockedByPlan, LockIcon } from '@common/components'
import Block, { Header } from 'components/Block'
import Button from 'components/Button/ButtonV1'
import { useConfirm } from 'components/Confirm'
import Form from 'components/Form'
import ToggleField from 'components/ToggleField'
import { lockedContentTracking } from 'core/track/locked-content'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as selectors from 'selectors'
import { RootState } from 'store'
import { save } from 'store/settings/org'

const Component = () => {
  const dispatch = useDispatch()
  const confirm = useConfirm()

  const orgId = useSelector((state: RootState) => selectors.orgs.currentId(state))

  const isOrgPaidPlan = useSelector(selectors.orgs.isOrgPaidPlan)

  const orgSettings = useSelector((state: RootState) => selectors.orgSettings.getByOrgId(state, orgId))

  const loading = useSelector((state: RootState) => state.orgSettings.loading)

  const [enabled, setEnabled] = useState<boolean>(orgSettings?.fields.all_recruiters_candidate_pdf_enabled || false)

  const notchanged = enabled === orgSettings?.fields.all_recruiters_candidate_pdf_enabled

  return (
    <LockedByPlan
      tracking={lockedContentTracking}
      locked={!isOrgPaidPlan}
      lockIconDisabled={true}
      upgradeTo={'Grow'}
      modalData={{
        learnMoreLink: 'https://intercom.help/hipeople/en/articles/7434061-export-results-as-a-pdf',
        learnMoreAbout: 'Downloading PDF Exports',
      }}
    >
      <Block
        testId="reference-check-pdf-export"
        title={
          <>
            Reference check PDF Export
            <LockIcon visible={!isOrgPaidPlan} />
          </>
        }
      >
        <Header>Enable users to export and download a PDF view of a candidate’s reference check.</Header>
        <Form>
          <ToggleField
            disabled={!isOrgPaidPlan}
            on={enabled}
            onChange={value => setEnabled(value)}
            label="Enable PDF export"
          />
          <div></div>
          <Button primary onClick={() => onClickSave()} disabled={loading || notchanged}>
            Save
          </Button>
        </Form>
      </Block>
    </LockedByPlan>
  )

  async function onClickSave() {
    if (
      await confirm({
        title: `About to update your candidate's reference PDF export settings, continue?`,
        message: `This will ${
          enabled ? 'enable' : 'disable'
        } the ability for all of your recruiters to export candidate's reference PDFs.`,
        confirmLabel: 'Yes, update settings',
      })
    ) {
      dispatch(save(orgId || '', {}, { pdfExportEnabled: enabled }))
    }
  }
}

export default Component
Component.displayName = 'PrivacyPolicyLink'
