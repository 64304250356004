import { Icon, IconProps } from 'components/Icons'
import Spinner from 'components/Spinner'
import { colors, px, px2rem, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React, { MutableRefObject } from 'react'
import { Link, LinkProps } from 'react-router-dom'

export type ButtonType =
  | 'primary'
  | 'confirm'
  | 'destroy'
  | 'accent'
  | 'secondary'
  | 'secondary-accent'
  | 'secondary-accent-fgbg'
  | 'tertiary'
  | 'tertiary-contrast'
  | 'minimal'

type ButtonElementType = 'button' | 'a' | 'Link'
type ButtonFontWeightOptions = '400'

interface ButtonProps extends Partial<LinkProps> {
  buttonType: ButtonType
  as?: ButtonElementType
  href?: string
  onClick?: () => void
  isFullWidth?: boolean
  disabled?: boolean
  isLoading?: boolean
  size?: 'default' | 'small'
  testId?: string
  weight?: ButtonFontWeightOptions
  refAsActivator?: MutableRefObject<null | HTMLElement>
  hasBeacon?: boolean
}

const BaseButton = text
  .bodyInteractive()
  .size({
    height: px2rem(48),
  })
  .spacing({ inner: [px2rem(12), px2rem(16)] })
  .round(px(8))
  .pointer()
  .noborders()
  .flex({ justifyContent: 'center', alignItems: 'center' })
  .relative()
  .box({ display: 'inline-flex' })
  .sans({ nodecoration: true })
  .cond(({ isFullWidth }: ButtonProps) => !!isFullWidth, style().size({ width: '100%' }).box({ display: 'flex' }))
  .cond(
    ({ size }: ButtonProps) => size === 'small',
    style()
      .spacing({ inner: [px2rem(10), px2rem(12)] })
      .size({
        height: px2rem(38),
      }),
  )
  .cond(
    ({ disabled, isLoading }: ButtonProps) => !!disabled && !isLoading,
    style().set('opacity', '0.35').cursor('not-allowed'),
  )
  .cond(({ isLoading }: ButtonProps) => !!isLoading, style().cursor('wait'))
  .cond(
    ({ weight }: ButtonProps) => !!weight,
    style().with((props: { weight: ButtonFontWeightOptions }) => style().sans({ weight: props.weight })),
  )
  .select('&:focus-within,&:focus,&:active', style().set('boxShadow', `0px 0px 0px 3px ${colors.purple}`).nooutline())

const PrimaryButton = BaseButton.clone()
  .color({
    bg: designSystemColors.typographyPrimaryAccent,
    fg: designSystemColors.typographyInvertedPrimary,
  })
  .element('button')
const PrimaryButtonA = style(PrimaryButton).element('a')
const PrimaryButtonLink = style(PrimaryButton).elementFromComponent(Link)

const ConfirmButton = BaseButton.clone()
  .color({
    bg: designSystemColors.typographyPrimaryAccent,
    fg: designSystemColors.typographyInvertedPrimary,
  })
  .element('button')
const ConfirmButtonA = style(ConfirmButton).element('a')
const ConfirmButtonLink = style(ConfirmButton).elementFromComponent(Link)

const DestroyButton = BaseButton.clone()
  .color({
    bg: designSystemColors.uiStatusError,
    fg: designSystemColors.white,
  })
  .element('button')
const DestroyButtonA = style(DestroyButton).element('a')
const DestroyButtonLink = style(DestroyButton).elementFromComponent(Link)

const AccentButton = BaseButton.clone()
  .color({
    bg: designSystemColors.typographyPrimaryAccent,
    fg: designSystemColors.typographyInvertedPrimary,
  })
  .element('button')
const AccentButtonA = style(AccentButton).element('a')
const AccentButtonLink = style(AccentButton).elementFromComponent(Link)

const SecondaryButton = BaseButton.clone()
  .color({
    bg: designSystemColors.backgroundNeutralTertiary,
    fg: designSystemColors.typographySecondary,
  })
  .element('button')
const SecondaryButtonA = style(SecondaryButton).element('a')
const SecondaryButtonLink = style(SecondaryButton).elementFromComponent(Link)

const SecondaryAccentButton = BaseButton.clone()
  .color({
    bg: designSystemColors.backgroundSecondaryAccent,
    fg: designSystemColors.typographyPrimary,
  })
  .element('button')
const SecondaryAccentButtonA = style(SecondaryAccentButton).element('a')
const SecondaryAccentButtonLink = style(SecondaryAccentButton).elementFromComponent(Link)

const SecondaryAccentFGBGButton = BaseButton.clone()
  .color({
    bg: designSystemColors.backgroundPrimaryAccent,
    fg: designSystemColors.typographyPrimaryAccent,
  })
  .element('button')
const SecondaryAccentFGBGButtonA = style(SecondaryAccentFGBGButton).element('a')
const SecondaryAccentFGBGButtonLink = style(SecondaryAccentFGBGButton).elementFromComponent(Link)

export const TertiaryButton = BaseButton.clone()
  .color({
    bg: designSystemColors.backgroundNeutralSecondary,
    fg: designSystemColors.typographySecondary,
  })
  .border({ around: '1px solid', color: designSystemColors.borderDefault })
  .element('button')
const TertiaryButtonA = style(TertiaryButton).element('a')
const TertiaryButtonLink = style(TertiaryButton).elementFromComponent(Link)

const TertiaryContrastButton = BaseButton.clone()
  .color({
    bg: designSystemColors.backgroundNeutralPrimary,
    fg: designSystemColors.typographySecondary,
  })
  .border({ around: '1px solid', color: designSystemColors.borderDefault })
  .element('button')
const TertiaryContrastButtonA = style(TertiaryContrastButton).element('a')
const TertiaryContrastButtonLink = style(TertiaryContrastButton).elementFromComponent(Link)

const MinimalButton = BaseButton.clone()
  .color({
    fg: designSystemColors.typographySecondary,
  })
  .sans({ underline: true })
  .transparent()
  .nospacing()
  .element('button')
const MinimalButtonA = style(MinimalButton).element('a')
const MinimalButtonLink = style(MinimalButton).elementFromComponent(Link)

function getButtonElement(buttonType: ButtonType, buttonElementType?: ButtonElementType): React.FC<any> {
  switch (buttonType) {
    case 'primary': {
      switch (buttonElementType) {
        case 'a': {
          return PrimaryButtonA
        }
        case 'Link': {
          return PrimaryButtonLink
        }
        default: {
          return PrimaryButton
        }
      }
    }
    case 'confirm': {
      switch (buttonElementType) {
        case 'a': {
          return ConfirmButtonA
        }
        case 'Link': {
          return ConfirmButtonLink
        }
        default: {
          return ConfirmButton
        }
      }
    }
    case 'destroy': {
      switch (buttonElementType) {
        case 'a': {
          return DestroyButtonA
        }
        case 'Link': {
          return DestroyButtonLink
        }
        default: {
          return DestroyButton
        }
      }
    }
    case 'accent': {
      switch (buttonElementType) {
        case 'a': {
          return AccentButtonA
        }
        case 'Link': {
          return AccentButtonLink
        }
        default: {
          return AccentButton
        }
      }
    }
    case 'secondary': {
      switch (buttonElementType) {
        case 'a': {
          return SecondaryButtonA
        }
        case 'Link': {
          return SecondaryButtonLink
        }
        default: {
          return SecondaryButton
        }
      }
    }
    case 'secondary-accent': {
      switch (buttonElementType) {
        case 'a': {
          return SecondaryAccentButtonA
        }
        case 'Link': {
          return SecondaryAccentButtonLink
        }
        default: {
          return SecondaryAccentButton
        }
      }
    }
    case 'secondary-accent-fgbg': {
      switch (buttonElementType) {
        case 'a': {
          return SecondaryAccentFGBGButtonA
        }
        case 'Link': {
          return SecondaryAccentFGBGButtonLink
        }
        default: {
          return SecondaryAccentFGBGButton
        }
      }
    }
    case 'tertiary': {
      switch (buttonElementType) {
        case 'a': {
          return TertiaryButtonA
        }
        case 'Link': {
          return TertiaryButtonLink
        }
        default: {
          return TertiaryButton
        }
      }
    }
    case 'tertiary-contrast': {
      switch (buttonElementType) {
        case 'a': {
          return TertiaryContrastButtonA
        }
        case 'Link': {
          return TertiaryContrastButtonLink
        }
        default: {
          return TertiaryContrastButton
        }
      }
    }
    case 'minimal': {
      switch (buttonElementType) {
        case 'a': {
          return MinimalButtonA
        }
        case 'Link': {
          return MinimalButtonLink
        }
        default: {
          return MinimalButton
        }
      }
    }
  }
}

export const Button: React.FC<ButtonProps> = ({
  buttonType,
  as,
  disabled,
  href,
  children,
  onClick,
  isLoading,
  testId,
  hasBeacon,
  to,
  ...restOfProps
}) => {
  const ButtonElement = getButtonElement(buttonType, as)

  return (
    <ButtonElement
      disabled={isLoading || disabled}
      href={href}
      onClick={onClick}
      tabIndex={as === 'a' || as === 'Link' ? '0' : undefined}
      data-testid={testId}
      to={to}
      {...restOfProps}
    >
      {isLoading ? <Spinner size={1.2} /> : children}
    </ButtonElement>
  )
}

export default Button

const ButtonContentStyle = style()
  .size({ width: '100%' })
  .flex({ justifyContent: 'center', alignItems: 'center' })
  .cond(
    ({ justify }: ButtonContentProps) => !!justify,
    style().with((props: { justify: ButtonJustifyOptions }) => style().flex({ justifyContent: props.justify })),
  )
  .cond(({ noWrap }: ButtonContentProps) => !!noWrap, style().set('whiteSpace', 'nowrap'))
  .spacing({ gap: px2rem(8) })
  .element()

type ButtonJustifyOptions = 'space-between' | 'flex-start' | 'flex-end'

interface ButtonContentProps {
  icon?: IconProps
  order?: 'icon-right'
  justify?: ButtonJustifyOptions
  noWrap?: boolean
  children?: React.ReactNode
}

export const ButtonContent: React.FC<ButtonContentProps> = ({ children, icon, order, justify, noWrap }) => {
  return (
    <ButtonContentStyle justify={justify} noWrap={noWrap}>
      {order === 'icon-right' ? (
        <>
          {children}
          {icon && <Icon {...icon} />}
        </>
      ) : (
        <>
          {icon && <Icon {...icon} />}
          {children}
        </>
      )}
    </ButtonContentStyle>
  )
}
