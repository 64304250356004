import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../../components/Modal'
import CopyLinkButton from '../../components/Modal/CopyLinkButton'
import TextField from '../../components/TextField'
import { Buttons, Content, ContentAndButtons, Form } from '../../components/Wizard'
import { Subheading } from '../../components/Wizard/Typography'
import { rem, style } from '../../core'
import * as selectors from '../../selectors'
import { RootState } from '../../store'
import * as slice from '../../store/references'

interface Props {
  referenceId: string
  open: boolean
  setOpen: (b: boolean) => void
}

const Container = style()
  .size({
    maxWidth: rem(40),
    width: 'calc(100vw - 200px)',
    height: rem(20),
  })
  .element()

const Component = (props: Props) => {
  const dispatch = useDispatch()

  const reference = useSelector((state: RootState) => selectors.references.findById(state, props.referenceId))
  const candidate = useSelector((state: RootState) =>
    selectors.candidates.findById(state, reference?.fields.candidate_id || ''),
  )
  const inviteLink = useSelector((state: RootState) => state.references.inviteLink)

  useEffect(() => {
    dispatch(slice.setInviteLink(''))
    if (props.open) {
      candidate?.fields.questionnaire_id
        ? dispatch(slice.inviteLinkv2(props.referenceId))
        : dispatch(slice.inviteLink(props.referenceId))
    }
  }, [dispatch, props.open, props.referenceId])

  return (
    <Modal
      title={`Create ${selectors.possessiveFirstName(reference?.fields.full_name || '')} invite link`}
      open={props.open}
      setOpen={props.setOpen}
    >
      <Container>
        <ContentAndButtons>
          <Content standalone>
            <Subheading>
              Below is an invitation link for {reference?.fields.full_name}. Using this link they can provide a
              reference for their candidate.
            </Subheading>
            <Form>
              <TextField label="Reference's invite link" value={inviteLink || 'Loading...'} disabled />
            </Form>
            <CopyLinkButton copyText={inviteLink} />
          </Content>
          <Buttons forwardOnly next={() => props.setOpen(false)} back={() => {}} label={'Done'} />
        </ContentAndButtons>
      </Container>
    </Modal>
  )
}

export default Component
Component.displayName = 'InviteLinkModal'
