import { Fields, RESOURCE } from 'api/group-scores'
import { QuestionnaireSubmissionEntity } from 'api/questionnaire-submissions'
import { Entity } from 'api/request'
import { isPresent } from 'core/utils'
import { RootState } from 'store'
import lookup from './lookup'

function findById(state: RootState, id: string) {
  return lookup<Fields>(state, RESOURCE, id)
}

export function findByQuestionnaireSubmission(
  state: RootState,
  submission: QuestionnaireSubmissionEntity,
): Entity<Fields>[] {
  const groupScores = submission.relationships?.filter(r => r.resource === RESOURCE).map(r => r.id) || []

  return groupScores.map(id => findById(state, id)).filter(isPresent)
}
