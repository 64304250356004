import { PageHeader, TopBar } from '@common/components'
import { ReferenceData } from 'App/ProductPages/SalesPool/index'
import { SearchRow, Total } from 'App/Settings/Pages/ManageCandidates'
import NameAndNetwork from 'App/Settings/Pages/NameAndNetwork'
import { Icon } from 'components/Icons'

import TextField from 'components/TextField'
import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { Link } from 'react-router-dom'
import ContentGrid, { Caption, Column, Row } from '../../../components/ContentGrid'
import DashboardLayout, { Content } from '../../../components/DashboardLayout'
import { colors, rem, size, style } from '../../../core'
import * as tracking from '../../../core/track'

const UserRow = Row([rem(0.5), rem(2), size.auto, rem(10), rem(8), size.auto, rem(8), rem(4), rem(2)])

const IconColumn = style()
  .flex({ alignItems: 'center', justifyContent: 'center' })
  .round('100%')
  .color({ fg: colors.moonGray })
  .element()

const Email = style().element()

const SCROLLABLE_TARGET = 'sales-pool-scroll-target'

interface SalesPoolPageUIProps {
  loading: boolean
  references: ReferenceData
  totalReferences: number
  logout: () => void
  query: string
  setQuery: (query: string) => void
  getCopy: (referenceName: string, text: string) => void
  onScrollNext: () => void
  hasMore: boolean
}

export const SalesPoolPageUI: React.FC<SalesPoolPageUIProps> = props => {
  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: '200px',
  })

  useEffect(() => {
    if (inView && props.hasMore) {
      props.onScrollNext()
    }
  }, [inView, props.hasMore])

  return (
    <DashboardLayout>
      <TopBar tracking={tracking} />
      <Content id={SCROLLABLE_TARGET}>
        <PageHeader.Root>
          <PageHeader.Title>Sales Pool</PageHeader.Title>
        </PageHeader.Root>
        <SearchRow>
          <TextField
            value={props.query}
            placeholder="Search by ID, email, or name"
            onChange={e => props.setQuery(e.target.value)}
          />
          {!props.loading ? <Total> {props.totalReferences} references</Total> : null}
        </SearchRow>
        <ContentGrid>
          <UserRow>
            <Column head></Column>
            <Column head></Column>
            <Column head>Name</Column>
            <Column head>Company</Column>
            <Column head>Worked From</Column>
            <Column head>Reference Of</Column>
            <Column head>Relationship</Column>
            <Column head>Contact</Column>
            <Column head></Column>
            {props.references.map((row, i) => (
              <React.Fragment key={`row-${i}`}>
                <Column />
                <Column centerVert>
                  <IconColumn verified={row.reference?.fields.is_verified}>
                    <Icon name={row.verificationStatus?.icon || ''} />
                  </IconColumn>
                </Column>
                <Column centerVert highlight>
                  <NameAndNetwork
                    name={row.reference?.fields.full_name || ''}
                    link={`/roles/${row.role?.fields.id}/candidates/${row.candidate?.fields.id}/references/${row.reference?.fields.id}`}
                    network={row.referenceProfessionalNetwork}
                  />
                </Column>
                <Column centerVert className="data-hj-suppress">
                  {row.reference?.fields.company_name}
                </Column>
                <Column centerVert>
                  {row.reference?.fields.from} – {row.reference?.fields.to}
                </Column>
                <Column centerVert highlight>
                  <Link
                    to={`/roles/${row.candidate?.fields.open_job_role_id}/candidates/${row.candidate?.fields.id}`}
                    className="data-hj-suppress"
                  >
                    {row.candidateProfile?.fields.full_name}
                    <Caption>{row.role?.fields.name}</Caption>
                  </Link>
                </Column>
                <Column centerVert>{row.relationship}</Column>
                <Column center highlight>
                  <Email
                    onClick={() => {
                      props.getCopy(
                        row?.reference?.fields.full_name || '',
                        row.reference?.fields.sales_pool_email || '',
                      )
                      tracking.salesPool.referenceEmailClicked()
                    }}
                  >
                    <IconColumn>
                      <Icon name={'envelope'} />
                    </IconColumn>
                  </Email>
                </Column>
                <Column />
              </React.Fragment>
            ))}
            {props.references.length === 0 && !props.loading ? (
              <>
                <Column />
                <Column>
                  <IconColumn>
                    <Icon name={'times-circle'} />
                  </IconColumn>
                </Column>
                <Column>Nothing found 🤕</Column>
                <Column />
                <Column />
                <Column />
                <Column />
                <Column />
                <Column />
                <Column />
              </>
            ) : null}
            {props.hasMore && <div ref={ref} />}
          </UserRow>
        </ContentGrid>
      </Content>
    </DashboardLayout>
  )
}
