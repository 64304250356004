import validate from 'all-good-emails'
import React from 'react'
import { employees } from '../../../api'
import Button from '../../../components/Button/ButtonV2'
import DateInput from '../../../components/DateInput'
import { Icon } from '../../../components/Icons'
import Modal from '../../../components/Modal'
import TextField from '../../../components/TextField'
import { px2rem, size, space, style } from '../../../core'
import { text } from '../../../core/design-system/text'

const Container = style()
  .size({
    width: px2rem(612),
  })
  .element()

const Row = style()
  .grid({ columns: [size.auto, size.auto] })
  .spacing({ gap: space.xs })
  .element()

const Content = style()
  .flex({ direction: 'column' })
  .spacing({ inner: px2rem(32), gap: px2rem(24) })
  .element()

const DateInputLabel = text
  .bodyInteractive()
  .spacing({ bottom: [space.xs] })
  .element('p')

const ButtonContainer = style()
  .grid({
    columns: [px2rem(140), px2rem(160)],
    rows: size.auto,
    align: 'center',
    justifyContent: 'end',
  })
  .spacing({
    gap: px2rem(16),
  })
  .element()

const IconContainer = style().flex().spacing({ gap: space.xxs }).element()

interface NewHireModalProps {
  open: boolean
  setOpen: (b: boolean) => void
  submit: (fields: employees.CreateFields) => Promise<boolean>
}

interface Data {
  hireName: string
  hireEmail: string
  managerName: string
  managerEmail: string
  department: string
  day?: number
  month?: number
  year?: number
}

export const NewHireModal: React.FC<NewHireModalProps> = props => {
  const initialData = {
    hireName: '',
    hireEmail: '',
    managerName: '',
    managerEmail: '',
    department: '',
  }

  const [data, setData] = React.useState<Data>(initialData)

  const isFormComplete =
    !!data.day &&
    !!data.year &&
    !!data.month &&
    data.hireName.trim().length > 3 &&
    data.managerName.trim().length > 3 &&
    validate(data.hireEmail) &&
    validate(data.managerEmail) &&
    data.department.trim().length > 3

  return (
    <Modal title={'Add new hire'} open={props.open} setOpen={props.setOpen}>
      <Container>
        <Content>
          <Row>
            <TextField
              label="New hire name"
              placeholder={'Judy Hopps'}
              value={data.hireName}
              onChange={e => {
                setData(data => ({ ...data, hireName: e.target.value }))
              }}
              error={data.hireName.trim() !== '' && !(data.hireName.trim().length > 3)}
              errorLabel={
                data.hireName.trim() !== '' && !(data.hireName.trim().length > 3)
                  ? 'Please enter a name longer than 3 characters'
                  : ''
              }
            />
            <TextField
              label="New hire work email"
              placeholder={'new-hire@company.com'}
              value={data.hireEmail}
              onChange={e => {
                setData(data => ({ ...data, hireEmail: e.target.value }))
              }}
              error={data.hireEmail !== '' && !validate(data.hireEmail)}
              errorLabel={data.hireEmail !== '' && !validate(data.hireEmail) ? 'Please enter a valid email.' : ''}
            />
          </Row>
          <Row>
            <TextField
              label="New hire's manager name"
              placeholder={'Ella Smith'}
              value={data.managerName}
              onChange={e => {
                setData(data => ({ ...data, managerName: e.target.value }))
              }}
              error={data.managerName.trim() !== '' && !(data.managerName.trim().length > 3)}
              errorLabel={
                data.managerName.trim() !== '' && !(data.managerName.trim().length > 3)
                  ? 'Please enter a name longer than 3 characters'
                  : ''
              }
            />
            <TextField
              label="New hire's manager email"
              placeholder={'manager@company.com'}
              value={data.managerEmail}
              onChange={e => {
                setData(data => ({ ...data, managerEmail: e.target.value }))
              }}
              error={data.managerEmail !== '' && (!validate(data.managerEmail) || data.managerEmail === data.hireEmail)}
              errorLabel={
                data.managerEmail !== '' && !validate(data.managerEmail)
                  ? 'Please enter a valid email.'
                  : data.managerEmail !== '' && data.managerEmail === data.hireEmail
                  ? 'Manager email is the same as employee email'
                  : ''
              }
            />
          </Row>
          <div>
            <TextField
              label="Department"
              placeholder={'Sales'}
              value={data.department}
              onChange={e => {
                setData(data => ({ ...data, department: e.target.value }))
              }}
              error={data.department.trim() !== '' && !(data.department.trim().length > 3)}
              errorLabel={
                data.department.trim() !== '' && !(data.department.trim().length > 3)
                  ? 'Please enter a department longer than 3 characters'
                  : ''
              }
            />
          </div>
          <div>
            <DateInputLabel>Start date</DateInputLabel>
            <DateInput
              day={data.day}
              setDay={day => {
                setData(data => ({ ...data, day: day }))
              }}
              month={data.month}
              setMonth={month => {
                setData(data => ({ ...data, month: month }))
              }}
              year={data.year}
              setYear={year => {
                setData(data => ({ ...data, year: year }))
              }}
            />
          </div>
          <ButtonContainer>
            <Button
              onClick={() => {
                props.setOpen(false)
              }}
              buttonType={'tertiary'}
            >
              Close
            </Button>
            <Button
              onClick={async () => {
                const now = new Date()
                const fields: employees.CreateFields = {
                  name: data.hireName,
                  department: data.department,
                  manager_name: data.managerName,
                  work_email: data.hireEmail,
                  manager_email: data.managerEmail,
                  start_at:
                    ((data.year && data.month && data.day && new Date(data.year, data.month - 1, data.day).getTime()) ||
                      now.getTime()) * 1e6,
                }
                const result = await props.submit(fields)
                if (result) {
                  props.setOpen(false)
                  setData(initialData)
                }
              }}
              buttonType={'confirm'}
              disabled={!isFormComplete}
            >
              <IconContainer>
                <Icon name={'check'} />
                Add new hire
              </IconContainer>
            </Button>
          </ButtonContainer>
        </Content>
      </Container>
    </Modal>
  )
}
