import { SummaryContainer } from 'App/Candidate/Summary/SummaryContainer'
import { Icon } from 'components/Icons'
import { px2rem, size, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import { useAreasOfImprovement } from 'providers/candidate-summaries'
import { useSelectedCandidate } from 'providers/candidates'
import { useModule } from 'providers/modules'
import React from 'react'
import { useParams } from 'react-router-dom'
import { AREAS_OF_IMPROVEMENT } from 'store/new-role/content'
import {
  AttributeBreakdownBlock,
  AttributeBreakdownBlockProps,
  sortBreakdowns,
  sortReferences,
} from './AttributeBreakdownBlock'

const EmptyPlaceholderText = text
  .bodyText()
  .color({ fg: designSystemColors.typographySecondary })
  .sans({ align: 'center' })
  .element('p')
const IconContainer = style()
  .sans({ size: px2rem(54), line: px2rem(54) })
  .color({ fg: designSystemColors.backgroundNeutralTertiary })
  .element('span')
const EmptyPlaceholder = style()
  .flex({ alignItems: 'center', justifyContent: 'center', direction: 'column' })
  .size({ height: size.fill })
  .select(`${IconContainer}`, style().spacing({ outerBottom: px2rem(20) }))
  .element('div')

interface AreasOfImprovementBlockUIProps {
  attributes: AttributeBreakdownBlockProps['attributes']
  numberOfReferences: AttributeBreakdownBlockProps['numberOfReferences']
  detailsUrl?: string
}

const AreasOfImprovementBlockUI: React.FC<AreasOfImprovementBlockUIProps> = ({
  attributes,
  numberOfReferences,
  detailsUrl,
}) => {
  const areThereAnyAttributes = attributes.length > 0
  return (
    <SummaryContainer emoji={'🌱'} title={'Areas of Improvement'} hintColor={'green'} to={detailsUrl}>
      {areThereAnyAttributes && (
        <AttributeBreakdownBlock
          title={'Most mentioned areas'}
          attributes={attributes}
          numberOfReferences={numberOfReferences}
        />
      )}
      {!areThereAnyAttributes && (
        <EmptyPlaceholder>
          <IconContainer>
            <Icon name="file" variant="regular" />
          </IconContainer>
          <EmptyPlaceholderText>{'No improvement areas provided by the references'}</EmptyPlaceholderText>
        </EmptyPlaceholder>
      )}
    </SummaryContainer>
  )
}

AreasOfImprovementBlockUI.displayName = 'AreasOfImprovementBlock'

interface AreasOfImprovementBlockProps {
  container?: React.FC
}

const MAX_NUMBER_OF_ATTRIBUTES = 5

const AreasOfImprovementBlock: React.FC<AreasOfImprovementBlockProps> = ({ container }) => {
  const Container = container || React.Fragment

  const { candidate } = useSelectedCandidate()
  const { isLoading, data } = useAreasOfImprovement({
    candidateId: candidate?.id,
  })

  const module = useModule({ slug: 'areas-of-improvement' })
  const targetId = candidate?.fields.questionnaire_id ? module?.id : AREAS_OF_IMPROVEMENT.test_item_id
  const { roleid, candidateid } = useParams<{
    roleid: string
    candidateid: string
  }>()
  const detailsUrl = `/roles/${roleid}/candidates/${candidateid}/responses#${targetId}`

  if (isLoading || !data) {
    return null
  }

  const { attributes, numberOfReferences } = data

  const sortedAttributes = attributes
    // reverse sorting by the most picked attribute
    .sort((attribute1, attribute2) => sortBreakdowns(attribute1.pickedBy, attribute2.pickedBy))
    // limiting the number of attributes shown
    .slice(0, MAX_NUMBER_OF_ATTRIBUTES)
    // grouping relationship types together
    .map(attribute => ({
      ...attribute,
      pickedBy: attribute.pickedBy.sort(sortReferences),
    }))

  return (
    <Container>
      <AreasOfImprovementBlockUI
        attributes={sortedAttributes}
        numberOfReferences={numberOfReferences}
        detailsUrl={detailsUrl}
      />
    </Container>
  )
}

export default AreasOfImprovementBlock
