import { Button, Logo, Text } from '@common/components'
import { GoogleIcon } from 'components/Icons'
import { px2rem, rem } from 'core'
import { text } from 'core/design-system/text'
import React from 'react'
import { Link } from 'react-router-dom'
import { SignupForm } from '../SelfService/SignupForm'
import classes from './Signup.module.scss'

const SignInContainer = text
  .bodyText()
  .text({ align: 'center' })
  .spacing({ outerLeft: px2rem(14), outerBottom: px2rem(6), outerTop: px2rem(24) })
  .sans({ line: rem(1.125) })
  .element()

export type StartFreeTrialValues = {
  emailAddress: string
  password: string
  agreedToTermsAndConditions: boolean
  agreedToNewsletter: boolean
}

interface StartFreeTrialProps {
  values: StartFreeTrialValues
  onChange: React.Dispatch<React.SetStateAction<StartFreeTrialValues>>
  next: () => any
  onGoogleSignUp: (utmParams?: Record<string, string>) => void
}

export const StartFreeTrial: React.FC<StartFreeTrialProps> = ({ values, onChange, next, onGoogleSignUp }) => {
  return (
    <>
      <div className={classes.container}>
        <div />
        <div className={classes.center}>
          <Logo />
          <Text style={{ textAlign: 'center' }} variant="highlight">
            Start hiring with HiPeople
          </Text>
          <SignupForm values={values} onChange={onChange} next={next} />
          <Text variant="body-text" style={{ textAlign: 'center', marginBottom: '1.35rem', fontWeight: 500 }}>
            OR
          </Text>
          <Button
            variant="secondary"
            rounded
            onClick={() => onGoogleSignUp()}
            style={{ width: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex', gap: '8px' }}
          >
            <GoogleIcon />
            <span>Continue with Google</span>
          </Button>

          <SignInContainer>
            <Text variant="body-text">
              <span>Already have a HiPeople account? </span>
              <Link to={'/login'} className={classes.link}>
                Log in
              </Link>
            </Text>
          </SignInContainer>
        </div>
        <div className={classes.spaceBetween}>
          <Text variant="body-text" className={classes.terms}>
            By continuing, you agree to the{' '}
            <a href={'https://www.hipeople.io/terms-and-conditions'} target={'_blank'} rel="noreferrer">
              Terms
            </a>{' '}
            and{' '}
            <a href={'https://www.hipeople.io/privacy-policy'} target={'_blank'} rel="noreferrer">
              Privacy Policy
            </a>
            , and to be updated on HiPeople news.
          </Text>
        </div>
      </div>
    </>
  )
}
