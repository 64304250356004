import i18n from 'i18next'
import Backend from 'i18next-chained-backend'
import GridlyBackend, { GridlyBackendOptions } from 'i18next-gridly-backend'

import { initReactI18next } from 'react-i18next'

const isProduction = import.meta.env.PROD
const gridlyOptionsCandidateApp: GridlyBackendOptions = {
  apiKey: import.meta.env.VITE_GRIDLY_API_KEY,
  viewId: import.meta.env.VITE_GRIDLY_VIEW_ID,
}

const backendsOptions = {
  backends: [GridlyBackend],
  backendOptions: [gridlyOptionsCandidateApp],
}

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: !isProduction,
    fallbackLng: 'en_US',
    saveMissing: false,
    interpolation: { prefix: '{', suffix: '}' },
    backend: backendsOptions,
    react: {
      useSuspense: false,
    },
  })

export default i18n
