import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import * as api from '../../api'
import Chart from '../../components/Chart'
import { px2rem, space, style } from '../../core'
import * as selectors from '../../selectors'
import { RootState } from '../../store'
import EmptyAnswer from './EmptyAnswer'
import ReferenceAnswer from './ReferenceAnswer'
import ScoreBar from './ScoreBar'

interface Props {
  candidateId: string
  referenceId?: string
  item: api.assessmentversions.Item
}

const Container = style().element()
const Answers = style()
  .select(
    '> *',
    style().border({
      top: 'solid 1px',
      color: designSystemColors.borderDefault,
    }),
  )
  .element()
const InnerSection = style()
  .bg({ color: designSystemColors.backgroundNeutralSecondary })
  .spacing({
    outer: space.none,
    inner: [px2rem(24), px2rem(16)],
  })
  .element()
const EmptyAnswerSection = style()
  .spacing({
    outer: space.none,
    inner: [px2rem(24), px2rem(16)],
  })
  .element()
const ReferenceDescription = text.bodyText().element('p')

const Component = (props: Props) => {
  const [selectedIndex, setSelectedIndex] = useState(-1)

  const references = useSelector((state: RootState) =>
    props.referenceId
      ? [selectors.references.getById(state, props.referenceId)]
      : selectors.references.findByCandidateId(state, props.candidateId),
  )

  const answers = useSelector((state: RootState) =>
    references.map(r => selectors.formResponses.findAnswerByReferenceId(state, r.fields.id, props.item.content_id)),
  )

  const chart = useSelector((state: RootState) =>
    selectors.charts.chartForQuestion(state, props.candidateId, props.item.content_id),
  )

  const filter = useSelector((state: RootState) => state.references.filter[props.candidateId])

  if (references.length === 0) {
    return (
      <EmptyAnswerSection>
        <EmptyAnswer waitingReferences noSpacing />
      </EmptyAnswerSection>
    )
  }

  const ranking = renderRanking(
    references,
    answers,
    // selected index will be always 0 when answers are filtered by a reference id
    props.referenceId ? 0 : selectedIndex,
  )

  return (
    <Container>
      {!filter && ranking && (
        <InnerSection onClick={() => setSelectedIndex(-1)}>
          {chart && props.referenceId === undefined ? (
            <Chart data={chart.data} options={chart.options} />
          ) : (
            <ScoreBar label={ranking.label} score={ranking.score} suffix={ranking.suffix} max={ranking.max} />
          )}
        </InnerSection>
      )}
      <Answers>
        {references.map((reference, index) => (
          <Answer
            key={reference.id}
            index={index}
            item={props.item}
            reference={reference}
            toggleIndex={(index: number) => setSelectedIndex(selectedIndex === index ? -1 : index)}
            score={answers[index]?.value}
            suffix={ranking?.suffix}
            hideProfile={props.referenceId !== undefined}
          />
        ))}
      </Answers>
    </Container>
  )
}

export default Component
Component.displayName = 'SliderWithDesc'

interface AnswerProps {
  item: api.assessmentversions.Item
  reference: api.request.Entity<api.references.Fields>
  toggleIndex: (index: number) => void
  index: number
  score?: number
  suffix?: string
  hideProfile: boolean
}

function Answer(props: AnswerProps) {
  const content = useSelector((state: RootState) =>
    selectors.formResponses.findAnswerByReferenceId(state, props.reference.id, props.item.content_id),
  )

  const label = props.suffix && props.score ? `${props.score}${props.suffix}` : ''

  return (
    <ReferenceAnswer
      contentid={props.item.content_id}
      reference={props.reference}
      onClick={() => props.toggleIndex(props.index)}
      score={label}
      hideProfile={props.hideProfile}
    >
      {content?.input ? (
        <ReferenceDescription>{content.input}</ReferenceDescription>
      ) : (
        <EmptyAnswer
          notApplicableForCandidate={props.reference.fields.is_self && props.reference.fields.form_response_id !== ''}
          skipped={props.reference.fields.form_response_id !== '' || content?.skipped}
          waitingResponse={props.reference.fields.form_response_id === '' && !content?.skipped}
          noQualitative={!!content?.value}
          noSpacing
        />
      )}
    </ReferenceAnswer>
  )
}

interface RankingView {
  label: string
  suffix: string
  score: number
  max: number
}

function renderRanking(
  references: api.request.Entity<api.references.Fields>[],
  answers: (api.formresponses.Row | undefined)[],
  selectedIndex: number,
): RankingView | undefined {
  const max = 5 // fixme: read this from the scheme instead of hard coding it
  const suffix = ` / ${max}`

  // user selected a specific answer.
  // display the ranking given by the individual reference.
  const selectedAnswer = answers[selectedIndex]
  if (selectedAnswer) {
    return {
      max,
      suffix,
      score: selectedAnswer.value || 0,
      label: `${selectors.possessiveFirstName(selectors.references.firstNameOf(references[selectedIndex]))} Ranking`,
    }
  }

  // display the overall ranking
  const totalValidAnswers = answers.filter(a => a?.value !== undefined).length
  if (totalValidAnswers === 0) {
    return
  }

  const total = answers
    .map(a => a?.value || 0)
    .reduce((a: number, b: number) => {
      return a + b
    }, 0)

  return {
    label: 'Overall Ranking',
    score: Math.floor(total / totalValidAnswers),
    suffix,
    max,
  }
}
