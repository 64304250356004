export { colors } from './skin'
export { lookup } from './state'
export { default as style } from './style'

export const size = {
  none: '0',
  fullScreen: vh(100),
  auto: 'auto',
  fill: '100%',
  stretch: '100%',
  xxxxs: rem(0.125),
  xxxs: rem(0.25),
  xxs: rem(0.36),
  xs: rem(0.75),
  s: rem(1.1),
  m: rem(1.35),
  l: rem(2.25),
  xl: rem(3),
  xxl: rem(6),
  xxxl: rem(13.2),
  xxxxl: rem(18.75),
}

export const space = {
  none: rem(0),
  xxxs: rem(0.25),
  xxs: rem(0.36),
  xs: rem(0.75),
  s: rem(1.1),
  m: rem(1.35),
  l: rem(2.25),
  xl: rem(3),
  xxl: rem(6),
  xxxl: rem(12),
  auto: 'auto',
}

export const scale = {
  xxxl: rem(6),
  xxl: rem(5),
  xl: rem(3),
  l: rem(2.25),
  m: rem(1.5),
  s: rem(0.9),
  xs: rem(0.875),
  xxs: rem(0.5),
  xxxs: rem(0.25),
  xxxxs: rem(0.125),
  none: rem(0),
}

export const weight = {
  boldest: '900',
  bolder: '700',
  bold: '600',
  normal: '500',
  light: '400',
  lighter: '300',
}

export const height = {
  xxl: '2.3',
  xl: '2',
  l: '1.8',
  m: '1.5',
  s: '1.2',
  xs: '0.8',
}

export const breakpoints = {
  xl: { gte: px(1400) },
  l: { gte: px(1000) },
  m: { gte: px(768) },
  s: { gte: px(640) },
  xs: { gte: px(450) },
  mobile: { lte: px(450) },
}

export function rgba(r: number, g: number, b: number, alpha: number = 1): string {
  return alpha ? `rgb(${r}, ${g}, ${b}, ${alpha})` : `rgb(${r}, ${g}, ${b})`
}

export function rem(n: number): string {
  return `${n}rem`
}

export function fr(n: number): string {
  return `${n}fr`
}

export function ch(n: number): string {
  return `${n}ch`
}

export function px(n: number): string {
  return `${n}px`
}

export function pct(n: number): string {
  return `${n}%`
}

export function vh(n: number): string {
  return `${n}vh`
}

export function vw(n: number): string {
  return `${n}vw`
}

const DEFAULT_REM_SIZE_IN_PX = 16
export function px2rem(px: number): string {
  return rem(px / DEFAULT_REM_SIZE_IN_PX)
}
