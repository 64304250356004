import { size, style } from 'core'
import { useCultureAdd } from 'providers/candidate-summaries'
import { useSelectedCandidate } from 'providers/candidates'
import { useModule } from 'providers/modules'
import React from 'react'
import { useParams } from 'react-router-dom'
import { CultureAddAttributes } from 'selectors/candidate-summaries'
import { CULTURE_ADD_QUESTION } from 'store/new-role/content'
import { CartesianChartBlock } from './CartesianChartBlock'
import { SummaryContainer } from './SummaryContainer'

const CultureAddBlockUIStyle = style()
  .size({ height: size.fill })
  .select('> *', style().size({ height: size.fill }))
  .element()

interface CultureAddBlockUIProps {
  selectedAttribute: CultureAddAttributes
  selectedCandidateAttribute: CultureAddAttributes | null
  detailsUrl?: string
}

const CULTURE_ADD_CHART_ATTRIBUTE_INDEX: Record<CultureAddAttributes, number> = {
  innovative: 0,
  'results-driven': 1,
  'detail-oriented': 2,
  collaborative: 3,
}

const CultureAddBlockUI: React.FC<CultureAddBlockUIProps> = ({
  selectedAttribute,
  selectedCandidateAttribute,
  detailsUrl,
}) => {
  return (
    <SummaryContainer emoji={'🌟'} title={'Culture Add'} hintColor={'purple'} to={detailsUrl}>
      <CultureAddBlockUIStyle>
        <CartesianChartBlock
          numberOfAreas={4}
          hintColor="orange"
          selectedAreaIndex={CULTURE_ADD_CHART_ATTRIBUTE_INDEX[selectedAttribute]}
          firstQuadrantLabel2={{
            text: 'Innovative',
            hasCandidateLabel: selectedCandidateAttribute === 'innovative',
          }}
          firstQuadrantTooltip2={{
            title: 'Innovative',
            text: 'Promotes to take risks and values flexibility over routine.',
          }}
          secondQuadrantLabel1={{
            text: 'Results-driven',
            hasCandidateLabel: selectedCandidateAttribute === 'results-driven',
          }}
          secondQuadrantTooltip1={{
            title: 'Results-driven',
            text: 'Promotes competitiveness and values quick decisions over participation.',
          }}
          thirdQuadrantLabel2={{
            text: 'Detail-oriented',
            hasCandidateLabel: selectedCandidateAttribute === 'detail-oriented',
          }}
          thirdQuadrantTooltip2={{
            title: 'Detail-oriented',
            text: 'Promotes precision and values routines over flexibility.',
          }}
          fourthQuadrantLabel1={{
            text: 'Collaborative',
            hasCandidateLabel: selectedCandidateAttribute === 'collaborative',
          }}
          fourthQuadrantTooltip1={{
            title: 'Collaborative',
            text: 'Promotes harmony and values participation over quick decisions.',
          }}
        />
      </CultureAddBlockUIStyle>
    </SummaryContainer>
  )
}

interface CultureAddBlockProps {
  container?: React.FC
}

const CultureAddBlock: React.FC<CultureAddBlockProps> = ({ container }) => {
  const { candidate } = useSelectedCandidate()
  const { isLoading, summary, selfSummary } = useCultureAdd({
    candidateId: candidate?.id,
  })

  const module = useModule({ slug: 'culture-add' })
  const targetId = candidate?.fields.questionnaire_id ? module?.id : CULTURE_ADD_QUESTION.test_item_id
  const { roleid, candidateid } = useParams<{
    roleid: string
    candidateid: string
  }>()
  const detailsUrl = `/roles/${roleid}/candidates/${candidateid}/responses#${targetId}`

  if (isLoading || !summary) {
    return null
  }

  const Container = container || React.Fragment

  return (
    <Container>
      <CultureAddBlockUI
        selectedAttribute={summary}
        selectedCandidateAttribute={selfSummary || null}
        detailsUrl={detailsUrl}
      />
    </Container>
  )
}

export default CultureAddBlock
