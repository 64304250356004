import { del, put } from './methods'
import { APIResponse, Entity } from './request'

export const RESOURCE = 'open_job_role_users'

export interface Fields {
  id: string
  open_job_role_id: string
  user_id: string
  can_control: boolean
  is_active: boolean
  is_starred: boolean
  created_at: number
}

export function empty(id: string): Entity<Fields> {
  return {
    id,
    resource: RESOURCE,
    fields: {
      id,
      open_job_role_id: '',
      user_id: '',
      can_control: false,
      created_at: 0,
      is_starred: false,
      is_active: false,
    },
  }
}

export async function addUserToRole(
  roleId: string,
  userId: string,
  canControl: boolean,
): Promise<APIResponse<Entity<Fields>[]>> {
  return put<Entity<Fields>[]>(`open_job_roles/${roleId}/users/${userId}`, {
    can_control: canControl,
  })
}

export async function deleteUserFromRole(roleId: string, userId: string): Promise<APIResponse<Entity<Fields>[]>> {
  return del<Entity<Fields>[]>(`open_job_roles/${roleId}/users/${userId}`, {})
}
