import { Icon } from 'components/Icons'
import { fr, px2rem, size, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import { candidates as dispatchCandidateTrackingEvents } from 'core/track'
import React from 'react'
import { Link } from 'react-router-dom'

const Emoji = style()
  .flex({ alignItems: 'center', justifyContent: 'center' })
  .with(({ bgColor }: { bgColor: string }) => style().color({ bg: bgColor }))
  .size({ width: px2rem(42), height: px2rem(42) })
  .border({
    radius: px2rem(6),
  })
  .element()
const Title = text.bodyEmphasis().color({ fg: designSystemColors.typographyPrimary }).element('h3')
const HelpLink = text
  .bodyEmphasis()
  .color({
    fg: designSystemColors.typographyPrimary,
    bg: designSystemColors.backgroundNeutralPrimary,
  })
  .flex({ alignItems: 'center', justifyContent: 'center' })
  .size({ width: px2rem(32), height: px2rem(32) })
  .sans({ nodecoration: true })
  .border({ radius: '50%' })
  .set('boxShadow', '0px 1px 4px rgba(0, 0, 0, 0.1)')
  .element('a')
const Header = style()
  .grid({ columns: [size.auto, fr(1), size.auto], align: 'center' })
  .spacing({ gap: px2rem(12) })
  .element()
const Content = style()
  .size({ height: px2rem(232), minHeight: px2rem(232) })
  .element()

const SummaryContainerStyle = style()
  .color({ bg: designSystemColors.backgroundNeutralPrimary })
  .border({
    around: `solid 1px`,
    color: designSystemColors.borderDefault,
    radius: px2rem(6),
  })
  .spacing({ inner: px2rem(16) })
  .select(`${Header}`, style().spacing({ outerBottom: px2rem(16) }))
  .select(`${HelpLink}`, style().invisible())
  .select(`:hover ${HelpLink}`, style().visible())
  .block()
  .sans({ nodecoration: true })
  .elementFromComponent(Link)

function getBgColor(hintColor: HintColor): string {
  switch (hintColor) {
    case 'purple': {
      return designSystemColors.informationPurpleBackground
    }
    case 'orange': {
      return designSystemColors.informationOrangeBackground
    }
    case 'green': {
      return designSystemColors.informationGreenBackground
    }
    case 'blue': {
      return designSystemColors.informationBlueBackground
    }
  }
}

type HintColor = 'purple' | 'orange' | 'green' | 'blue'
interface SummaryContainerProps {
  emoji: React.ReactNode
  title: string
  helpLink?: string
  hintColor: HintColor
  to?: string
  children?: React.ReactNode
}

export const SummaryContainer: React.FC<SummaryContainerProps> = ({
  emoji,
  title,
  helpLink,
  hintColor,
  to,
  children,
}) => {
  return (
    <SummaryContainerStyle
      to={to || '#'}
      onClick={() => {
        if (!to) {
          return
        }
        dispatchCandidateTrackingEvents.candidateSummaryBlockDetailsViewed({
          blockTitle: title,
        })
      }}
    >
      <Header>
        <Emoji bgColor={getBgColor(hintColor)}>{emoji}</Emoji>
        <Title>{title}</Title>
        {helpLink && (
          <HelpLink href={helpLink} target="_blank" rel="noopener noreferrer">
            <Icon name="info-circle" />
          </HelpLink>
        )}
      </Header>
      <Content>{children}</Content>
    </SummaryContainerStyle>
  )
}
