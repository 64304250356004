import * as api from 'api'
import { breakpoints, size, style, weight } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Relationship as RelationshipType } from 'selectors/references'

const Container = style().size({ minWidth: size.none }).element()

const Name = text.bodyText().text({ weight: weight.bold, ellipsis: true }).element()
const Email = text.bodyText().text({ ellipsis: true }).screen(breakpoints.mobile, style().hidden()).element()
const Missing = text
  .bodyText()
  .text({ ellipsis: true })
  .fg(designSystemColors.typographySecondary)
  .text({ italic: true })
  .element()

const Relationship = text
  .bodyInteractive()
  .fg(designSystemColors.typographySecondary)
  .screen(breakpoints.xs, style().hidden())
  .element('span')
const RelationshipName = text.bodyInteractive().fg(designSystemColors.typographySecondary).element('span')

interface Props {
  reference?: api.request.Entity<api.references.Fields>
  emoji: string
  relationship: RelationshipType
}

const Component = (props: Props) => {
  const { t } = useTranslation()
  return (
    <Container>
      <Relationship>
        {props.emoji} <RelationshipName>{props.relationship}</RelationshipName>
      </Relationship>
      {props.reference?.fields.is_self ? (
        <Missing>{t('candidate-dashboard-intro.references.self-assessment-title', 'Self-assessment')}</Missing>
      ) : props.reference ? (
        <>
          <Name>{props.reference.fields.full_name}</Name>
          <Email>{props.reference.fields.email}</Email>
        </>
      ) : (
        <Missing>{t('candidate-dashboard-intro.references.reference-missing', 'No details provided')}</Missing>
      )}
    </Container>
  )
}

export default Component
Component.displayName = 'ColumnReference'
