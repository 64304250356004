import Feedback from 'App/Candidate/ByQuestion/Feedback'
import QuestionnaireAnswerBlockList from 'App/Candidate/ByQuestion/QuestionnaireAnswerBlockList'
import SectionBlockNavigator from 'App/Candidate/SectionBlockNavigator'
import { rem, size, space, style } from 'core'
import { useFeatureFlag } from 'providers/features'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import * as selectors from 'selectors'
import { RootState } from 'store'

interface Visible {
  [contentId: string]: boolean
}

const Answers = style().size({ width: size.fill }).spacing({ innerLeft: '0.5rem' }).element()

const Sidebar = style()
  .sticky({ top: '5rem' })
  .size({ height: 'calc(100dvh - 60px)', width: rem(18) })
  .flex({ direction: 'column' })
  .spacing({ gap: space.m })
  .nooverflow()
  .element()

const Container = style().flex().size({ width: size.fill }).element()

function createPath(candidateId: string, roleId?: string, path?: string, isInSharedMode?: boolean) {
  const result = isInSharedMode ? `/shared/candidates/${candidateId}` : `/roles/${roleId}/candidates/${candidateId}`
  return path ? result + path : result
}

interface ResponsesProps {
  candidateId: string
  roleId: string
  isInSharedViewMode?: boolean
  showOnboarding?: boolean
}

const Responses: React.FC<ResponsesProps> = values => {
  const candidate = useSelector((state: RootState) => selectors.candidates.findById(state, values.candidateId))

  const [visible, setVisible] = useState<Visible>({})

  const highlightedReferenceId = useSelector((state: RootState) => state.references.filter[values.candidateId || ''])

  const referenceQuestions = useSelector((state: RootState) =>
    selectors.assessmentVersions.getGroupedItemsByCandidateId(state, values.candidateId || ''),
  )

  const { isEnabled: isOnboardingEnabled } = useFeatureFlag('onboarding')

  if (values.showOnboarding && !isOnboardingEnabled) {
    // Redirect not authorized users to summary page
    return <Redirect to={createPath(values.candidateId, values.roleId, ``, values.isInSharedViewMode)} />
  }

  const hasContentfulContent = (candidate?.fields.questionnaire_id ?? '') === ''

  return (
    <Container>
      <Sidebar>
        <SectionBlockNavigator candidateId={values.candidateId || ''} visible={visible} />
      </Sidebar>
      <Answers>
        {hasContentfulContent ? (
          <Feedback
            candidateId={values.candidateId || ''}
            items={referenceQuestions}
            updateVisible={setVisible}
            visible={visible}
          />
        ) : (
          <QuestionnaireAnswerBlockList
            updateVisible={setVisible}
            candidateId={values.candidateId || ''}
            highlightedReferenceId={highlightedReferenceId || ''}
          />
        )}
      </Answers>
    </Container>
  )
}

export default Responses
