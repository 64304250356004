import DashboardLayout from 'components/DashboardLayout'
import React from 'react'
import ContentContainer from '../../../components/Container/ContentContainer'
import ProductPageShell from '../../../components/ProductPageLayout'
import HeroGrid from '../../../components/ProductPageLayout/HeroGrid'
import HeroImgSalesPool from './HeroImgSalesPool.png'

interface SalesPoolPageUIProps {
  button: JSX.Element
}

export const EmptySalesPoolPage: React.FC<SalesPoolPageUIProps> = props => {
  return (
    <DashboardLayout>
      <ProductPageShell title={'Sales Pool'}>
        <ContentContainer>
          <HeroGrid
            heading={'Turn references into sale leads'}
            subheading={
              'Tap into the network of your candidate’s references, and collect them as potential sale leads. All you need to do is start collecting references with HiPeople - at the end of the process, we will ask reference givers to stay in touch for your products and services.'
            }
            image={{
              src: HeroImgSalesPool,
              altText: 'User interface of HiPeople dashboard showing a sales pool',
            }}
          >
            {props.button}
          </HeroGrid>
        </ContentContainer>
      </ProductPageShell>
    </DashboardLayout>
  )
}
