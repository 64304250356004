import { Score } from '../../../api/score'
import { ModuleScoreDrawer } from './ModuleScoreDrawer'
import { DomainScoreCard } from './DomainScoreCard'
import React from 'react'
import classes from './ScoreGroup.module.scss'
import { Group, ScoreGroupProps } from './ScoreGroup'

interface ModuleListProps extends ScoreGroupProps {
  score: Score
  onClickResponses?: (moduleSlug: string, group: Group) => void
  candidateFirstName: string
  group: Group
  defaultCollapsed?: boolean
}

export const ModuleList = (props: ModuleListProps) => {
  if (props.score.type === 'inventories') {
    // BFAS, Big5, DISC and other types of scores with inventories
    return (
      <div className={`${classes.fullWidthSubgridItem}`}>
        <ModuleScoreDrawer
          key={props.score.slug}
          child={props.score}
          answerResultScoredPresent={false}
          getModuleData={props.getModuleData}
          onClickResponses={props.onClickResponses}
          candidateFirstName={props.candidateFirstName}
          group={props.group}
          score={props.score}
          defaultCollapsed={props.defaultCollapsed}
        />
      </div>
    )
  }

  return (
    <div className={`${classes.fullWidthSubgridItem}`} style={{ rowGap: '0.5rem' }}>
      {(props.score.children && props.score.children.length > 0 ? props.score.children : [props.score]).map(child => {
        const answerResultScoredPresent =
          child.num_correct !== undefined && child.num_incorrect !== undefined && child.num_skipped !== undefined

        const isDomain = child.type === 'domains'

        if (isDomain) {
          return (
            <>
              <DomainScoreCard name={child.name || 'Domain'} score={child.score} />
              {child.children?.map(child => (
                <ModuleScoreDrawer
                  key={child.slug}
                  child={child}
                  answerResultScoredPresent={answerResultScoredPresent}
                  getModuleData={props.getModuleData}
                  onClickResponses={props.onClickResponses}
                  candidateFirstName={props.candidateFirstName}
                  group={props.group}
                  score={child}
                  defaultCollapsed={props.defaultCollapsed}
                />
              ))}
            </>
          )
        }

        return (
          <ModuleScoreDrawer
            key={child.slug}
            child={child}
            answerResultScoredPresent={answerResultScoredPresent}
            getModuleData={props.getModuleData}
            onClickResponses={props.onClickResponses}
            candidateFirstName={props.candidateFirstName}
            group={props.group}
            score={child}
            defaultCollapsed={props.defaultCollapsed}
          />
        )
      })}
    </div>
  )
}
