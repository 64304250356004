import { Action, configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { ThunkAction } from 'redux-thunk'
import hipeople, { RootState } from '../store'

const store = configureStore({
  reducer: hipeople,
})

export default store

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>

export type ActionCreator<ReturnType> = ThunkAction<Promise<ReturnType>, RootState, unknown, Action<string>>

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
type DispatchFunc = () => AppDispatch
export const useAppDispatch: DispatchFunc = useDispatch
