import React from 'react'
import Left from './assets/ForcedTruthLeft.svg?react'
import LeftMost from './assets/ForcedTruthLeftMost.svg?react'
import Middle from './assets/ForcedTruthMiddle.svg?react'
import NoAnswer from './assets/ForcedTruthNone.svg?react'
import Right from './assets/ForcedTruthRight.svg?react'
import RightMost from './assets/ForcedTruthRightMost.svg?react'

import classes from './PairwiseScore.module.scss'

interface PairwiseScoreProps {
  score: number | null
  startCaption: React.ReactNode
  endCaption: React.ReactNode
  axisLabels?: boolean
  iconColor?: string
  symbolColor?: string
  hideScoreTooltip?: boolean
  className?: string
  style?: React.CSSProperties
}

const PairwiseScore: React.FC<PairwiseScoreProps> = props => {
  const cssVariables = {
    '--icon-color': props.iconColor || '#4c49ee',
    '--text-color': props.symbolColor || '#FFF',
  } as React.CSSProperties // TS doesn't like css variable declarations

  return (
    <div className={`${classes.container} ${props.className}`} style={props.style}>
      <div style={{ textAlign: 'right' }}>
        {props.axisLabels && <span className={classes.axisLabel}>Low</span>}
        <div className={classes.label}>{props.startCaption}</div>
      </div>
      <div className={`${classes.scoreContainer} ${classes.PairwiseScoreIcon}`} style={cssVariables}>
        <Score score={props.score} hideTooltip={props.hideScoreTooltip} />
      </div>
      <div>
        {props.axisLabels && <span className={classes.axisLabel}>High</span>}
        <div className={classes.label}>{props.endCaption}</div>
      </div>
    </div>
  )
}

export { PairwiseScore }

interface ScoreProps {
  score: number | null
  hideTooltip?: boolean
}

const Score: React.FC<ScoreProps> = ({ score, hideTooltip }) => {
  const title = hideTooltip ? undefined : `Score: ${score}`
  if (score === null) {
    return <NoAnswer title={title} />
  } else if (score >= 4) {
    return <RightMost className={classes.highest} title={title} />
  } else if (score === 3) {
    return <Right className={classes.high} title={title} />
  } else if (score === 2) {
    return <Middle className={classes.medium} title={title} />
  } else if (score === 1) {
    return <Left className={classes.low} title={title} />
  } else {
    return <LeftMost className={classes.lowest} title={title} />
  }
}
