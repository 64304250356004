import { designSystemColors } from 'core/design-system/colors'
import { px2rem, space, style } from '../../core'

export default style()
  .spacing({
    outer: [space.l, space.none, space.none, space.none],
  })
  .cond((props: { overflow?: boolean }) => !props.overflow, style().nooverflow())
  .border({
    around: '1px solid',
    color: designSystemColors.borderDefault,
    radius: px2rem(8),
  })
  .element()
