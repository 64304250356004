import React, { MutableRefObject, useRef } from 'react'
import PopupV2, { Button, Separator } from 'components/PopupV2'
import { px2rem, style } from 'core'

const PopupContainer = style()
  .absolute({ top: px2rem(54), right: 0 })
  .size({ width: px2rem(172) })
  .front()
  .element()

const MenuItem = style().size({ width: '100%' }).element('li')

interface InviteCandidateMenuProps {
  isMenuOpen: boolean
  setMenuOpen: (open: boolean) => void
  isInviteByLinkEnabled: boolean | undefined
  activatorRef?: MutableRefObject<null | HTMLElement>
  onClickInviteByEmail: () => void
  onClickInviteByLink: () => void
}

const InviteCandidateMenu: React.FC<InviteCandidateMenuProps> = props => {
  const inviteMenuItems = [
    {
      element: 'button',
      label: 'Invite via email',
      icon: 'envelope',
      ariaLabel: 'Invite via email',
      onClick: props.onClickInviteByEmail,
      isEnabled: true,
    },
    {
      element: 'button',
      label: 'Invite via link',
      icon: 'link',
      ariaLabel: 'Invite via link',
      onClick: props.onClickInviteByLink,
      isEnabled: props.isInviteByLinkEnabled || false,
    },
  ]

  const enabledItems = inviteMenuItems.filter(item => {
    return item.isEnabled ? item.isEnabled : false
  })

  const inviteMenuRef = useRef<HTMLElement>(null)

  return (
    <PopupContainer>
      <PopupV2
        open={props.isMenuOpen}
        setOpen={props.setMenuOpen}
        listRef={inviteMenuRef}
        activatorRef={props.activatorRef}
      >
        {enabledItems.map((item, i) =>
          item.element === 'separator' ? (
            <Separator key={`separator-${i}`} />
          ) : (
            <MenuItem key={item.label}>
              <Button icon={item.icon || 'cog'} aria-label={item.ariaLabel} onClick={item.onClick}>
                {item.label}
              </Button>
            </MenuItem>
          ),
        )}
      </PopupV2>
    </PopupContainer>
  )
}

export default InviteCandidateMenu
