import { put } from './methods'
import { APIResponse, Entity } from './request'

export const RESOURCE = 'organization_settings'

export type CandidateExperienceMode = 'none' | 'post-reference-check' | 'ats-hired-or-rejected' | 'link'

export interface Fields {
  id: string
  organization_id: string
  min_reference_count: number
  data_retention_days: number
  deleted_data_retention_days: number
  all_recruiters_candidate_pdf_enabled: boolean
  reference_phone_number_mandatory: boolean
  reference_phone_number_force_formatting: boolean
  candidate_experience_mode: CandidateExperienceMode
  disabled_verification: boolean
  disabled_reference_context: boolean
  quality_of_hire_enabled: boolean
  quality_of_hire_survey_interval: number
  talent_pool_enabled: boolean
  sales_pool_enabled: boolean
  hire_genie_enabled_reference_check: boolean
  hire_genie_enabled_assessment: boolean
  reference_allow_only_email_or_phone_number: boolean
  branding_color_primary: string
  interviewer_mode: boolean // disable cheating prevention and fullscreen mode, used by for example Douglas
}

export interface UpdateSettingsPayload {
  updates: {
    min_reference_count?: number
    data_retention_days?: number
    deleted_data_retention_days?: number
    all_recruiters_candidate_pdf_enabled?: boolean
    candidate_experience_mode?: CandidateExperienceMode
    disabled_verification?: boolean
    quality_of_hire_enabled?: boolean
    quality_of_hire_survey_interval?: number
    talent_pool_enabled?: boolean
    sales_pool_enabled?: boolean
    hire_genie_enabled_reference_check?: boolean
    hire_genie_enabled_assessment?: boolean
    branding_color_primary?: string
    interviewer_mode?: boolean
  }
  updated: string[]
}

export function updateSettings(orgId: string, payload: UpdateSettingsPayload): Promise<APIResponse<Entity<Fields>>> {
  return put<Entity<Fields>>(`organizations/${orgId}/settings`, payload)
}

export function empty(id: string): Entity<Fields> {
  return {
    id,
    resource: RESOURCE,
    fields: {
      id,
      organization_id: '',
      min_reference_count: 0,
      data_retention_days: 0,
      deleted_data_retention_days: 0,
      all_recruiters_candidate_pdf_enabled: false,
      reference_phone_number_force_formatting: false,
      reference_phone_number_mandatory: false,
      reference_allow_only_email_or_phone_number: false,
      candidate_experience_mode: 'none',
      disabled_verification: false,
      disabled_reference_context: false,
      quality_of_hire_enabled: false,
      quality_of_hire_survey_interval: 90,
      talent_pool_enabled: false,
      sales_pool_enabled: false,
      hire_genie_enabled_reference_check: false,
      hire_genie_enabled_assessment: false,
      branding_color_primary: '#000000',
      interviewer_mode: false,
    },
  }
}
