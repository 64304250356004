export const RESOURCE = 'module_scores'

export type Fields = {
  id: string
  questionnaire_submission_id: string
  module_id: string
  score: number
  qualitative_score: string
  num_correct: number
  num_incorrect: number
  num_skipped: number
  benchmark?: number | null
  time_taken_millis: number
  time_allowed_millis: number
}
