import * as api from 'api'
import { getSelfAssessmentToken } from 'api/candidates'
import { Entity, getEnvironment } from 'api/request'
import * as tracking from 'core/track'
import { candidateBaseURL } from 'core/utils'
import { useCandidate } from 'providers/candidates'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch, useLocation, useParams, useRouteMatch } from 'react-router-dom'
import { useMount, usePrevious } from 'react-use'
import * as selectors from 'selectors'
import { RootState } from 'store'
import './../../i18n'
import { CandidateShell } from './CandidateShell'
import ManageReferences from './ManageReferences'
import AddReference from './ManageReferences/AddReference'
import IntroScreen from './ManageReferences/IntroScreen'
import LawyerConfirmation from './ManageReferences/LawyerConfirmation'
import ProfessionalNetwork from './ManageReferences/ProfessionalNetwork'
import Portal from './Portal'
import * as styles from '@common/styles'
import { Environments } from 'App/Settings/Pages/ManageEnvironments'

export const useSelfAssessmentURL = (
  candidateId: string,
  roleId: string,
  org?: Entity<api.orgs.Fields>,
  token?: string,
) => {
  const selfAssessment = useSelector((state: RootState) => {
    return selectors.questionnaireSubmissions.findSelfAssessmentSubmissionsByCandidateId(state, candidateId)?.[0]
  })

  const [selfAssessmentToken, setSelfAssessmentToken] = useState('')
  useEffect(() => {
    if (!selfAssessment) {
      return
    }
    getSelfAssessmentToken(candidateId).then(([response, errors]) => {
      if (errors) {
        return
      }
      setSelfAssessmentToken(response?.result.fields.token || '')
    })
  }, [candidateId, roleId, token])

  const base = candidateBaseURL(org?.fields.slug || '')

  const environment = getEnvironment()
  const isStagingEnv = environment === Environments.Feat

  const url = new URL(`${base}/self-assessment/${selfAssessment?.id}`)

  url.searchParams.append('token', selfAssessmentToken)

  if (isStagingEnv) {
    url.searchParams.append('env', 'feat')
  }

  if (selfAssessmentToken) {
    return url.toString()
  }
}

const CandidateOnly = () => {
  const { candidateid } = useParams<{ candidateid: string }>()
  const { path } = useRouteMatch()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const fromPublic = searchParams.get('from-public')

  const { candidate } = useCandidate({
    candidateId: candidateid || '',
  })

  const role = useSelector((state: RootState) => selectors.roles.findByCandidateId(state, candidate?.fields.id || ''))
  const org = useSelector((state: RootState) => selectors.orgs.getById(state, role?.fields.organization_id || ''))
  const orgSettings = useSelector((state: RootState) => selectors.orgSettings.getByOrgId(state, org?.id || ''))

  if (orgSettings?.fields.branding_color_primary) {
    styles.updateBrandColours(orgSettings?.fields.branding_color_primary as styles.Hex)
  }

  // When the candidate's submitted at explicitly changes from 0 to a real number,
  // meaning they have submitted their final reference, send a submitted event.
  const prevSubmittedAt = usePrevious(candidate?.fields.submitted_at)
  const didCandidateSubmitForTheFirstTime = prevSubmittedAt === 0 && (candidate?.fields.submitted_at || 0) > 0

  useEffect(() => {
    if (didCandidateSubmitForTheFirstTime) tracking.candidatePortal.submit()
  }, [didCandidateSubmitForTheFirstTime])

  useMount(() => {
    tracking.candidatePortal.open()
  })

  const selfAssessmentURL = useSelfAssessmentURL(candidateid, role?.id || '', org)

  const roleOptions = {
    self_reference: role?.fields.ask_self_reference || false,
    self_assessment: role?.fields.ask_self_assessment || false,
    reference_check: role?.fields.ask_reference_check || false,
  }
  const haveOnly = (t: keyof typeof roleOptions) =>
    roleOptions[t] && Object.values(roleOptions).reduce((sum, o) => sum + Number(o), 0) === 1

  if (!candidate) return <></>

  return (
    <CandidateShell
      orgLogoUrl={org?.fields.logo_image_url || 'https://avatars2.githubusercontent.com/u/60610251?s=200&v=4'}
      brandingUrl={
        // slight hack, we need the branding image to only appear on the first page, but it needs to be
        // add the CandidateShell level in the DOM. so can't define it below in the /intro route
        location.pathname.endsWith('/intro') ? role?.fields.branding_image_url : undefined
      }
      roleId={role?.id}
    >
      <Switch>
        <Route exact path={path}>
          {/* If the candidate already has a professional network it is indicative that they have already */}
          {/* gone through the intro flow, thus redirect them to the portal. Should they belong to an org */}
          {/* without a verifcation requirement, unfortunately, they'll need to go through the intro flow */}
          {/* over and over again. */}
          {/* if the candidate comes from a public flow (invite via link) the we need to skip the intro page */}
          {/* because that is the same page the candidate already saw before submitting the contact details */}
          {/* at the start of the public-intro flow */}
          {/* see more: https://github.com/hipeople/frontend/pull/700#discussion_r1532873502 */}
          {candidate.fields.professional_network || (fromPublic && haveOnly('self_assessment')) ? (
            <Redirect to={{ pathname: `/candidates/${candidate.id}/portal`, search: location.search }} />
          ) : fromPublic ? (
            <Redirect to={{ pathname: `/candidates/${candidate.id}/legal`, search: location.search }} />
          ) : (
            <Redirect to={{ pathname: `/candidates/${candidate.id}/intro`, search: location.search }} />
          )}
        </Route>
        <Route exact path={`${path}/intro`}>
          <IntroScreen />
        </Route>
        <Route exact path={`${path}/legal`}>
          {role?.fields.ask_reference_check ? (
            <LawyerConfirmation />
          ) : (
            <Redirect to={{ pathname: `/candidates/${candidate.id}/network`, search: location.search }} />
          )}
        </Route>
        <Route exact path={`${path}/network`}>
          {orgSettings?.fields.disabled_verification || !role?.fields.ask_reference_check ? (
            <Redirect to={{ pathname: `/candidates/${candidate.id}/portal`, search: location.search }} />
          ) : (
            <ProfessionalNetwork />
          )}
        </Route>
        <Route exact path={`${path}/portal`}>
          {(() => {
            // if we only have 1 ref check tile, skip the portal
            if (haveOnly('reference_check'))
              return (
                <Redirect to={{ pathname: `/candidates/${candidate.id}/manage-references`, search: location.search }} />
              )

            // if we only have 1 assessment tile, skip the portal
            if (haveOnly('self_assessment') && selfAssessmentURL) {
              return (
                <Redirect
                  to={{ pathname: `/candidates/${candidate.id}/self-assessment-ext`, search: location.search }}
                />
              )
            }

            // we have multiple tiles, show the portal
            return <Portal />
          })()}
        </Route>
        <Route exact path={`${path}/manage-references`}>
          <ManageReferences />
        </Route>
        <Route exact path={`${path}/manage-references/add/required/:requiredreferenceid`}>
          <AddReference />
        </Route>
        <Route exact path={`${path}/manage-references/add/optional/:referenceid?`}>
          <AddReference />
        </Route>

        <Route
          exact
          path={`${path}/self-assessment-ext`}
          component={() => {
            window.location.href = selfAssessmentURL || ''
            return null
          }}
        ></Route>
        <Redirect to={path} />
      </Switch>
    </CandidateShell>
  )
}

export default CandidateOnly
