import { RootState } from 'store'
import * as api from '../api'
import * as candidates from './candidates'
import lookup from './lookup'

function getById(state: RootState, id: string): api.request.Entity<api.referenceCheckSummaries.Fields> | undefined {
  return lookup(state, api.referenceCheckSummaries.RESOURCE, id)
}

export function getByCandidateId(
  state: RootState,
  candidateId: string,
): api.request.Entity<api.referenceCheckSummaries.Fields> | undefined {
  const candidate = candidates.findById(state, candidateId)
  if (!candidate || !candidate.relationships) return

  const referenceCheckSummary = candidate.relationships.find(r => r.resource === api.referenceCheckSummaries.RESOURCE)
  if (!referenceCheckSummary) return

  return getById(state, referenceCheckSummary.id)
}
