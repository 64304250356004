import { Icon } from 'components/Icons'
import Modal from 'components/Modal'
import ModalButtonsV2 from 'components/Modal/ModalButtonsV2'
import { Content } from 'components/Wizard'
import { Subheading } from 'components/Wizard/Typography'
import { px2rem, rem, style } from 'core'
import React from 'react'

const ButtonContent = style()
  .set('whiteSpace', 'nowrap')
  .inlineFlex({})
  .spacing({ gap: px2rem(8) })
  .element('span')

const Container = style()
  .size({
    maxWidth: rem(40),
    width: 'calc(100vw - 200px)',
    height: rem(11),
  })
  .element()

interface Props {
  setOpen: (b: boolean) => void
  onUpgrade: () => void
}

const Component: React.FC<Props> = props => {
  return (
    <Modal title={`Analyze in depth your Candidate Experience`} open setOpen={props.setOpen}>
      <Container>
        <Content standalone>
          <Subheading>
            Do you think your candidates' experience is the same with each of your recruitment teams? Upgrade to
            discover your strenghts and weaknesses to offer the best experience to your Candidates.
          </Subheading>
          <ModalButtonsV2
            submitLabel={
              <ButtonContent>
                <Icon name="sparkles" />
                Upgrade now
              </ButtonContent>
            }
            onCancel={() => props.setOpen(false)}
            onSubmit={() => {
              props.onUpgrade()
              props.setOpen(false)
            }}
            confirmButtonType={'accent'}
          />
        </Content>
      </Container>
    </Modal>
  )
}

export default Component
Component.displayName = 'UpgradeModal'
