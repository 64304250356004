import ScoreBar from 'components/Scores/ScoreBar'
import { fr, px2rem, size, space, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React from 'react'

const SummaryContainer = style()
  .color({ bg: designSystemColors.backgroundNeutralPrimary })
  .border({
    around: `solid 1px`,
    color: designSystemColors.borderDefault,
    radius: px2rem(6),
  })
  .block()
  .spacing({ inner: px2rem(16), gap: px2rem(16) })
  .flex({ direction: 'column' })
  .sans({ nodecoration: true })
  .element('article')

const Emoji = style()
  .flex({ alignItems: 'center', justifyContent: 'center' })
  .with(({ bgColor }: { bgColor: string }) => style().color({ bg: bgColor }))
  .size({ width: px2rem(42), height: px2rem(42) })
  .border({
    radius: px2rem(6),
  })
  .element()

const Header = style()
  .grid({ columns: [size.auto, fr(1), size.auto], align: 'center' })
  .spacing({ gap: px2rem(12) })
  .element()

const Title = text.bodyEmphasis().color({ fg: designSystemColors.typographyPrimary }).element('h3')

const ScoreContainer = style()
  .spacing({ gap: px2rem(16) })
  .element('section')

const DimensionBarsContainer = style()
  .flex({ direction: 'column' })
  .spacing({ gap: px2rem(8) })
  .element('section')

const ContentContainer = style()
  .grid({ columns: [px2rem(160), fr(1)] })
  .spacing({ gap: px2rem(16) })
  .element()

const EmptyState = style()
  .bg({ color: designSystemColors.backgroundNeutralSecondary })
  .sans({ italic: true, align: 'center' })
  .flex({ grow: '1', alignItems: 'center', justifyContent: 'center' })
  .element()

function getBgColor(emojiColor: EmojiColor): string {
  switch (emojiColor) {
    case 'purple': {
      return designSystemColors.informationPurpleBackground
    }
    case 'orange': {
      return designSystemColors.informationOrangeBackground
    }
    case 'green': {
      return designSystemColors.informationGreenBackground
    }
    case 'blue': {
      return designSystemColors.informationBlueBackground
    }
  }
}

type EmojiColor = 'purple' | 'orange' | 'green' | 'blue'

interface OverallEvaluationProps {
  title: string
  emoji: string
  emojiColor: EmojiColor
  overallScore?: number | null
  roleScore?: number | null
  teamScore?: number | null
  organizationScore?: number | null
}

export const OverallEvaluationSummary: React.FC<OverallEvaluationProps> = ({
  title,
  emoji,
  emojiColor,
  overallScore,
  roleScore,
  teamScore,
  organizationScore,
}) => {
  return (
    <SummaryContainer>
      <Header>
        <Emoji bgColor={getBgColor(emojiColor)}>{emoji}</Emoji>
        <Title>{title}</Title>
      </Header>
      {overallScore ? (
        <ContentContainer>
          <ScoreContainer>
            <OverallScore score={overallScore} max={100} />
          </ScoreContainer>
          <DimensionBarsContainer>
            <LabelScore label={'Job'} score={roleScore} />
            <LabelScore label={'Team'} score={teamScore} />
            <LabelScore label={'Organization'} score={organizationScore} />
          </DimensionBarsContainer>
        </ContentContainer>
      ) : overallScore === null ? (
        <EmptyState>
          Not enough data! The score could not be calculated because more than one-third of the questions were skipped.
        </EmptyState>
      ) : (
        <EmptyState>The survey has not been completed yet</EmptyState>
      )}
    </SummaryContainer>
  )
}

const OverallScoreContainer = style()
  .flex({ direction: 'column' })
  .spacing({ inner: px2rem(8) })
  .size({ height: '100%' })
  .color({ bg: designSystemColors.backgroundNeutralSecondary })
  .border({ radius: px2rem(8) })
  .element()

const OverallScoreStyle = text
  .scoreHighlightSecondary()
  .spacing({ outer: ['0', space.auto, space.auto, space.auto] })
  .element()

const Score = text.scoreHighlightPrimary().inline().element()

const OverallScoreTitle = text
  .label()
  .fg(designSystemColors.typographySecondary)
  .spacing({ outer: [space.auto, space.auto, '0', space.auto] })
  .element()

interface OverallScoreProps {
  score?: number
  max: number
}

const OverallScore: React.FC<OverallScoreProps> = ({ score, max }) => {
  return (
    <OverallScoreContainer>
      <OverallScoreTitle>OVERALL</OverallScoreTitle>
      <OverallScoreStyle>
        <Score>{score !== undefined ? score.toFixed() : 'Na'}</Score> / {max}
      </OverallScoreStyle>
    </OverallScoreContainer>
  )
}

const LabelScoreContainer = style()
  .grid({ rows: [size.auto, px2rem(20)] })
  .spacing({ rows: px2rem(4), outer: [space.auto, space.none] })
  .element()

const Label = text.bodyEmphasis().element()

interface LabelScoreProps {
  score?: number | null
  label: string
}

const LabelScore: React.FC<LabelScoreProps> = ({ score, label }) => {
  return (
    <LabelScoreContainer>
      <Label>{label}</Label>
      {/* score is divided by 20, the selectors averages are out of 100
        and the bar is out of 5*/}
      {score == null ? <>N/A</> : <ScoreBar score={score / 20} />}
    </LabelScoreContainer>
  )
}
