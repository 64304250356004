export const DISC_EXPLANATIONS = {
  DI: "The DI style combines elements of Dominance and Influence. Individuals with a DI style are results-oriented, vocal, and enthusiastic. They influence others through charm and bold action. DI styles are dynamic, inquisitive, persuasive, and entrepreneurial. They set goals for quick action and seek new opportunities. Their fears include the loss of power or status and invisibility.\n\nHow to work with a DI-type person?\nDi styles influence others with their charm and boldness, but they may overuse impatience, egotism, and manipulation. In conflicts, they address issues directly, sometimes regretting their words later. DI styles can enhance their effectiveness by practicing patience, humility, and considering others' ideas. As leaders, they are pioneers, pushing boundaries, seeking opportunities, and achieving results.\n\nGeneral note:\nThe DISC Assessment is a useful tool for assessing personality and has valuable applications in the workplace. However, it should not be used as the sole pre-employment tool and should be combined with other validated assessments to make well-informed hiring decisions.",
  DC: 'The DC style combines elements of Dominance and Conscientiousness. Individuals with a DC style are diligent, tough-minded, and creative. They influence others through their high standards and determination. DC styles are known for their drive for perfection, initiative-taking, and focus. They set goals for independence and personal accomplishment.\n\nHow to work with a DC-type person?\nDC styles fear failing to meet their high standards. They influence others by maintaining high standards and displaying determination. They may overuse bluntness or exhibit a sarcastic or condescending attitude. In conflicts, they defend their rights and firmly hold their ground. To increase effectiveness, DC styles can benefit from showing warmth and employing more tactful communication. As leaders, they are resolute, setting high expectations and addressing problems. They focus on improving methods and procedures.\n\nGeneral note:\nThe DISC Assessment is a useful tool for assessing personality and has valuable applications in the workplace. However, it should not be used as the sole pre-employment tool and should be combined with other validated assessments to make well-informed hiring decisions.',
  DS: 'The DS style represents Dominance and Steadiness in the DISC model. Individuals with a DS style are determined, reliable, and patient. They influence others through their stability and attention to detail. DS styles are known for their persistence, methodical approach, diplomacy, and supportiveness. They set goals for stability and personal accomplishment. Their fears include change and disruption.\n\nHow to work with a DS-type person?\nDS styles influence others through their dependability and willingness to help. However, they may overuse caution or exhibit indecisiveness. In conflicts, they seek compromise and may avoid confrontation. To enhance their effectiveness, DS styles can benefit from assertiveness and expressing their needs and opinions. As leaders, they focus on maintaining stability, building relationships, and supporting their team members.\n\nGeneral note:\nThe DISC Assessment is a useful tool for assessing personality and has valuable applications in the workplace. However, it should not be used as the sole pre-employment tool and should be combined with other validated assessments to make well-informed hiring decisions.',
  ID: "The ID style represents Influence and Dominance in the DISC model. Individuals with an ID style are outgoing, assertive, and driven. They influence others through their charisma and assertiveness. ID styles are known for being sociable, determined, persuasive, and competitive. They set goals for social interaction and accomplishment. Their fears include rejection and losing control.\n\nHow to work with an ID-type person?\nEstablish open communication, provide opportunities for them to showcase their skills and take charge, and offer constructive feedback. ID styles influence others through their confidence and direct approach. However, they may overuse dominating conversations or be too forceful. In conflicts, they express their opinions strongly to even the score. To enhance their effectiveness, ID styles can benefit from active listening and considering others' perspectives. As leaders, they are assertive, goal-oriented, and inspire others through their charisma and drive.\n\nGeneral note:\nThe DISC Assessment is a useful tool for assessing personality and has valuable applications in the workplace. However, it should not be used as the sole pre-employment tool and should be combined with other validated assessments to make well-informed hiring decisions.",
  IS: 'The IS style represents Influence and Steadiness in the DISC model. Individuals with an IS style are outgoing, supportive, and diplomatic. They influence others through their charm and cooperative nature. IS styles are characterized as sociable, patient, empathetic, and reliable. They set goals for social interaction and stability. Their fears include conflict and loss of harmony.\n\nHow to work with an IS-type person?\nCreate a supportive and collaborative work environment, encourage their input and involvement, and provide clear expectations and guidelines. IS styles influence others through their ability to build relationships and willingness to help. However, they may overuse avoiding conflict or being indecisive. In conflicts, they seek compromise and harmony. To enhance their effectiveness, IS styles can benefit from assertiveness and expressing their needs. As leaders, they are approachable, supportive, and focus on creating a harmonious work environment.\n\nGeneral note:\nThe DISC Assessment is a useful tool for assessing personality and has valuable applications in the workplace. However, it should not be used as the sole pre-employment tool and should be combined with other validated assessments to make well-informed hiring decisions.',
  IC: 'The IC style represents Influence and Conscientiousness in the DISC model. Individuals with an IC style are outgoing, analytical, and detail-oriented. They influence others through their expertise and logical approach. IC styles are known for being sociable, precise, objective, and accurate. They set goals for social interaction and quality.\n\nHow to work with an IC-type person?\nProvide them with a structured and organized work environment, recognize their expertise and attention to detail, and encourage their critical thinking and problem-solving abilities. IC styles influence others through their knowledge and attention to detail. However, they may overuse overthinking or being overly critical. In conflicts, they seek logical solutions and may avoid expressing emotions. To enhance their effectiveness, IC styles can benefit from flexibility and openness to new ideas. As leaders, they are methodical, focused on quality, and excel in problem-solving and analysis.\n\nGeneral note:\nThe DISC Assessment is a useful tool for assessing personality and has valuable applications in the workplace. However, it should not be used as the sole pre-employment tool and should be combined with other validated assessments to make well-informed hiring decisions.',
  SI: 'The SI style represents Steadiness and Influence in the DISC model. Individuals with an SI style are supportive, sociable, and enthusiastic. They influence others through their cooperation and enthusiasm. SI styles are characterized as patient, persuasive, diplomatic, and optimistic. They set goals for stability and recognition. \n\nHow to work with an SI-type person? \nAcknowledge and appreciate their contributions, provide opportunities for social interaction and recognition, and encourage open communication and collaboration. SI styles influence others through their supportive and engaging nature. However, they may overuse seeking social approval or avoiding conflict. In conflicts, they strive for compromise and may say things they regret later. To enhance their effectiveness, SI styles can benefit from assertiveness and expressing their opinions. As leaders, they are supportive, relationship-oriented, and focus on motivating others and maintaining a harmonious work environment.\n\nGeneral note:\nThe DISC Assessment is a useful tool for assessing personality and has valuable applications in the workplace. However, it should not be used as the sole pre-employment tool and should be combined with other validated assessments to make well-informed hiring decisions.',
  SC: 'The SC style represents Steadiness and Conscientiousness in the DISC model. Individuals with an SC style are supportive, analytical, and precise. They influence others through their reliability and attention to detail. SC styles are characterized as patient, diligent, logical, and accurate. They set goals for stability and quality. Their fears include criticism and making mistakes.\n\nHow to work with an SC-type person?\nProvide clear expectations and guidelines, allow them time for thorough analysis and planning, and recognize their attention to detail and reliability. SC styles influence others through their careful and methodical approach. However, they may overuse overthinking or be overly critical. In conflict, they seek logical solutions and may avoid expressing emotions. To enhance their effectiveness, SC styles can benefit from flexibility and openness to new ideas. As leaders, they are focused on maintaining stability, ensuring accuracy, and excelling in problem-solving and analysis.\n\nGeneral note:\nThe DISC Assessment is a useful tool for assessing personality and has valuable applications in the workplace. However, it should not be used as the sole pre-employment tool and should be combined with other validated assessments to make well-informed hiring decisions.',
  SD: 'The SD style represents Steadiness and Dominance in the DISC model. Individuals with an SD style are supportive, determined, and assertive. They influence others through their stability and decisive action. SD styles are known for being patient, persistent, diplomatic, and driven. They set goals for stability and accomplishment.\n\nHow to work with an SD-type person?\nProvide clear goals and expectations, allow them autonomy in decision-making, and be prepared for direct and assertive communication. SD styles influence others through their reliability and determination. However, they may overuse passive-aggressive behavior or be too stubborn. In conflict, they address issues directly and may hold grudges. To enhance their effectiveness, SD styles can benefit from flexibility and open communication. As leaders, they are focused on maintaining stability, achieving goals, and providing guidance and support.\n\nGeneral note:\nThe DISC Assessment is a useful tool for assessing personality and has valuable applications in the workplace. However, it should not be used as the sole pre-employment tool and should be combined with other validated assessments to make well-informed hiring decisions.',
  CI: "The CI style represents Conscientiousness and Influence in the DISC model. Individuals with a CI style are analytical, enthusiastic, and sociable. They influence others through their expertise and optimistic nature. CI styles are known for being accurate, persuasive, objective, and sociable. They set goals for quality and social interaction. Their fears include failing to meet their standards and experiencing social rejection.\n\nHow to work with a CI-type person?\nAcknowledge their expertise, provide opportunities for social interaction and recognition, encourage their enthusiasm, and value their attention to detail. CI styles influence others through their knowledge and ability to connect with people. However, they may overuse seeking social approval or being overly critical. In conflict, they strive for logical solutions and may avoid expressing emotions. To enhance their effectiveness, CI styles can benefit from assertiveness and considering others' perspectives. As leaders, they are focused on quality, building relationships, and encouraging creativity and collaboration.\n\nGeneral note:\nThe DISC Assessment is a useful tool for assessing personality and has valuable applications in the workplace. However, it should not be used as the sole pre-employment tool and should be combined with other validated assessments to make well-informed hiring decisions.",
  CD: "The CD style represents Conscientiousness and Dominance in the DISC model. Individuals with a CD style are analytical, determined, and assertive. They influence others through their expertise and decisive action. CD styles are characterized as accurate, persistent, focused, and results-oriented. They set goals for quality and accomplishment.\n\nHow to work with a CD-type person?\nRecognize their expertise, provide them with autonomy and clear objectives, and appreciate their determination and attention to detail. CD styles influence others through their precise and assertive approach. However, they may overuse being overly critical or inflexible. In conflict, they address issues directly and may come across as demanding. To enhance their effectiveness, CD styles can benefit from being more flexible and open to others' ideas. As leaders, they are focused on achieving high-quality results, setting standards, and driving progress.\n\nGeneral note:\nThe DISC Assessment is a useful tool for assessing personality and has valuable applications in the workplace. However, it should not be used as the sole pre-employment tool and should be combined with other validated assessments to make well-informed hiring decisions.",
  CS: 'The CS style represents Conscientiousness and Steadiness in the DISC model. Individuals with a CS style are analytical, reliable, and patient. They influence others through their attention to detail and supportive nature. CS styles are known for being accurate, diplomatic, focused, and consistent. They set goals for quality and stability.\n\nHow to work with a CS-type person?\nProvide them with a structured and supportive work environment, value their attention to detail, and encourage open communication. CS styles influence others through their reliability and willingness to help. However, they may overuse caution or be overly reserved. In conflict, they seek compromise and harmony. To enhance their effectiveness, CS styles can benefit from being more assertive and expressing their opinions. As leaders, they are focused on maintaining stability, ensuring accuracy, and creating a supportive work environment.\n\nGeneral note:\nThe DISC Assessment is a useful tool for assessing personality and has valuable applications in the workplace. However, it should not be used as the sole pre-employment tool and should be combined with other validated assessments to make well-informed hiring decisions.',
}
