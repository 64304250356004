import React from 'react'
import classes from './LanguagePicker.module.scss'
import { LanguageIcon, LanguageMap } from '../../Dropdown/LanguageDropdown/LanguageDropdown'
import { Locale } from '../../../models/locales'

interface LanguagePickerProps {
  languagesAvailable: Locale[]
  defaultLocale?: Locale
  onSelectLanguage: (language: Locale) => void
}

/**
 * A picker that pops up in candidate app from the top of the footer to select languages.
 * Element has absolute positioning to be shown on top of parent element.
 */
export const LanguagePicker = (props: LanguagePickerProps) => {
  const [selectedLanguage, setSelectedLanguage] = React.useState<Locale | undefined>(props.defaultLocale)
  const [isOpen, setIsOpen] = React.useState(false)
  const pickerRef = React.useRef<HTMLDivElement>(null)

  const selectedItem = selectedLanguage && selectedLanguage in LanguageMap && LanguageMap[selectedLanguage]

  const onSelectLanguage = (language: Locale) => {
    setIsOpen(false)
    if (selectedLanguage === language) return
    setSelectedLanguage(language)
    props.onSelectLanguage(language)
  }

  React.useEffect(() => {
    if (props.defaultLocale) {
      onSelectLanguage(props.defaultLocale)
    } else if (props.languagesAvailable.length > 0) {
      onSelectLanguage(props.languagesAvailable[0])
    }
  }, [props.defaultLocale])

  React.useEffect(() => {
    const handleClickAway = (e: MouseEvent) => {
      if (pickerRef.current && !pickerRef.current.contains(e.target as Node)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickAway)

    return () => {
      document.removeEventListener('mousedown', handleClickAway)
    }
  }, [])

  if (!props.languagesAvailable || props.languagesAvailable.length <= 1) return null

  return (
    <div className={classes.languagePicker} ref={pickerRef}>
      {isOpen && (
        <div className={classes.languagePickerContent}>
          {props.languagesAvailable.map(language => {
            const item = LanguageMap[language]
            if (!item || !item.name) return null

            return (
              <div
                key={item.name}
                className={
                  selectedLanguage === language ? classes.languagePickerItemActive : classes.languagePickerItem
                }
                onClick={() => onSelectLanguage(language)}
              >
                <LanguageIcon locale={language} />
                <span>{item.name}</span>
              </div>
            )
          })}
        </div>
      )}
      <div className={classes.languagePickerHeader} onClick={() => setIsOpen(!isOpen)}>
        <div className={classes.languagePickerItem}>
          {selectedItem ? (
            <>
              <LanguageIcon locale={selectedLanguage} />
              <span>{selectedItem.name}</span>
            </>
          ) : null}
        </div>
      </div>
    </div>
  )
}
