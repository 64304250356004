import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import AccessDenied from '../../../components/AccessDenied'
import Form from '../../../components/Form'
import GuestLayout from '../../../components/GuestLayout'
import { RootState } from '../../../store'
import { load } from '../../../store/one-time-password'

interface Props {
  children: JSX.Element | JSX.Element[]
}

const Component = (props: Props) => {
  const dispatch = useDispatch()
  const { otpid } = useParams<{ otpid: string }>()

  const error = useSelector((state: RootState) => state.oneTimePassword.error)

  useEffect(() => {
    if (otpid) {
      dispatch(load(otpid))
    }
  }, [dispatch, otpid])

  if (error) {
    return <AccessDenied />
  }

  return (
    <GuestLayout>
      <Form>{props.children}</Form>
    </GuestLayout>
  )
}

export default Component
Component.displayName = 'OTPLayout'
