import React from 'react'
import { useSelector } from 'react-redux'
import * as api from '../../api'
import { px, scale, size, space, style } from '../../core'
import { designSystemColors } from '../../core/design-system/colors'
import * as selectors from '../../selectors'
import { Step } from '../../selectors/candidates'
import { RootState } from '../../store'

interface Props {
  candidate?: api.request.Entity<api.candidates.Fields>
}
const StepColor = style()
  .color({
    bg: designSystemColors.backgroundNeutralTertiary,
    fg: designSystemColors.typographySecondary,
  })
  .cond(
    ({ step }: { step: Step }) => step === Step.InProgress,
    style().color({
      bg: designSystemColors.typographySecondary,
      fg: designSystemColors.white,
    }),
  )
  .cond(
    ({ step }: { step: Step }) => step === Step.Completed,
    style().color({
      bg: designSystemColors.uiStatusSuccess,
      fg: designSystemColors.white,
    }),
  )
  .cond(
    ({ step }: { step: Step }) => step === Step.MarkedComplete,
    style().color({
      bg: designSystemColors.uiStatusSuccess,
      fg: designSystemColors.white,
    }),
  )
  .cond(
    ({ step }: { step: Step }) => step === Step.Expired,
    style().color({
      bg: designSystemColors.uiStatusWarningSecondary,
      fg: designSystemColors.typographyPrimary,
    }),
  )

export const LabelContainer = StepColor.clone()
  .sans({ size: scale.s, align: 'center', ellipsis: true })
  .round(size.m)
  .spacing({ inner: [space.xxs, space.xs] })
  .element()

export function Label({ candidate }: Props): JSX.Element {
  const step = useSelector((state: RootState) => selectors.candidates.getStep(state, candidate?.id || ''))
  return <LabelContainer step={step}>{step}</LabelContainer>
}

const CircleContainer = StepColor.clone()
  .size({ width: px(10), height: px(10) })
  .round('100%')
  .element()

export function Circle({ candidate }: Props): JSX.Element {
  const step = useSelector((state: RootState) => selectors.candidates.getStep(state, candidate?.id || ''))
  return <CircleContainer step={step} />
}
