import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as selectors from 'selectors'
import { RootState } from 'store'

import { AssessmentLocale } from '@common/models/locales'
import { useQueryParams } from 'hooks/useQueryParams'

import { setPrivate, TemplateContentItem } from 'api/templates'
import { addSelfAssessmentPresetToRole } from 'store/new-role'
import * as templatesSlice from 'store/templates'
import { createTemplate } from 'store/templates'
import { z } from 'zod'
import { NewAssessment } from './NewAssessment'

const NewAssessmentPage: React.FC<{}> = () => {
  const dispatch = useDispatch()
  const { getParam } = useQueryParams(queryParamSchema)
  const templateId = getParam('templateid')
  const roleId = getParam('roleid')
  const mode = getParam('mode', 'new')
  const org = useSelector((state: RootState) => selectors.orgs.getById(state, selectors.orgs.currentId(state)))
  const selectedRole = useSelector((state: RootState) => (roleId ? selectors.roles.findById(state, roleId) : null))

  // transform the role to a more usable format
  const role = useMemo(() => {
    if (!selectedRole) return null
    return {
      id: selectedRole.id,
      locale: selectedRole.fields.assessment_locale as AssessmentLocale,
      description: selectedRole.fields.description,
    }
  }, [selectedRole])

  async function handleSaveTemplate(params: { payload: TemplateContentItem[]; templateTitle: string }) {
    const newTemplateId = await dispatch(
      createTemplate(org.id, {
        is_private: false, // since we want to save it as a template, we need to make it public
        is_highlighted: true,
        copy: { title: params.templateTitle },
        content: params.payload,
        product: 'self-assessment',
        keywords: params.templateTitle, // the keywords are what the template library uses to search for templates
      }),
    )

    // if a roleid is present, we always want to add the template to the role
    if (roleId) {
      // we have some issues with the react-thunk typing
      // @ts-ignore
      await dispatch(addSelfAssessmentPresetToRole(roleId, newTemplateId))
    }
  }

  async function handleEditTemplate(params: { payload: TemplateContentItem[]; templateTitle: string }) {
    if (!templateId) {
      throw new Error('Role ID or Template ID is not defined')
    }

    // we first create a new template with the same title
    const newTemplateId = await dispatch(
      createTemplate(org.id, {
        is_private: false,
        is_highlighted: true,
        copy: { title: params.templateTitle },
        content: params.payload,
        product: 'self-assessment',
        keywords: params.templateTitle,
      }),
    )

    // we then set the initial template to private
    await dispatch(
      templatesSlice.setPrivate(
        { organizationId: org.id, templateId: templateId, isPrivate: true },
        { sendToast: false },
      ),
    )

    // if a roleid is present, we always want to add the template to the role
    if (roleId) {
      // we have some issues with the react-thunk typing
      // @ts-ignore
      await dispatch(addSelfAssessmentPresetToRole(roleId, newTemplateId))
    }
  }

  async function handleCreateNewAssessment(params: { payload: TemplateContentItem[] }) {
    if (!roleId) {
      throw new Error('Role ID or Template ID is not defined')
    }

    const newTemplateId = await dispatch(
      createTemplate(org.id, {
        is_private: true,
        is_highlighted: true,
        copy: { title: '' },
        content: params.payload,
        product: 'self-assessment',
      }),
    )

    // we have some issues with the react-thunk typing
    // @ts-ignore
    await dispatch(addSelfAssessmentPresetToRole(roleId, newTemplateId))
  }

  async function handleEditExistingAssessment(params: { payload: TemplateContentItem[]; templateTitle: string }) {
    if (!roleId || !templateId) {
      throw new Error('Role ID is not defined')
    }

    // we first create a new template with the same title
    const newTemplateId = await dispatch(
      createTemplate(org.id, {
        is_private: true,
        is_highlighted: true,
        copy: { title: params.templateTitle },
        content: params.payload,
        product: 'self-assessment',
      }),
    )

    // we then set the initial template to private
    await dispatch(setPrivate({ organizationId: org.id, templateId: templateId, isPrivate: true }))

    // we have some issues with the react-thunk typing
    // @ts-ignore
    await dispatch(addSelfAssessmentPresetToRole(roleId, newTemplateId))
  }

  async function handleConfirm(params: HandleCreateTemplate) {
    switch (params.mode) {
      case 'save-template':
        await handleSaveTemplate({ ...params })
        break
      case 'edit-template':
        await handleEditTemplate({ ...params })
        break
      case 'create-new-assessment':
        await handleCreateNewAssessment({ ...params })
        break
      case 'edit-existing-assessment':
        await handleEditExistingAssessment({ ...params })
        break
      default:
        throw new Error('Invalid mode')
        break
    }
  }

  return (
    <NewAssessment
      org={org}
      onConfirm={handleConfirm}
      mode={mode}
      templateId={templateId}
      role={role}
      isVideoQuestionEnabled
    />
  )
}

export default NewAssessmentPage

const queryParamSchema = z.object({
  templateid: z.string(),
  roleid: z.string(),
  mode: z.enum(['new', 'edit', 'clone']),
})

export type HandleCreateTemplate =
  | {
      mode: 'save-template'
      payload: TemplateContentItem[]
      templateTitle: string
    }
  | {
      mode: 'edit-template'
      payload: TemplateContentItem[]
      templateTitle: string
    }
  | {
      mode: 'create-new-assessment'
      payload: TemplateContentItem[]
    }
  | {
      mode: 'edit-existing-assessment'
      payload: TemplateContentItem[]
      templateTitle: string
    }
