import { px2rem, size, space, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import * as tracking from 'core/track'
import { useSelectedCandidate } from 'providers/candidates'
import React from 'react'
import { HashLink as Link } from 'react-router-hash-link'

interface Props {
  label: string
  contentId: string
}

const SkillLabelOverlay = text
  .secondaryBodyInteractive()
  .color({
    fg: designSystemColors.typographyPrimary,
    bg: designSystemColors.backgroundNeutralPrimary,
  })
  .set('boxShadow', '0px 1px 5px rgba(0, 0, 0, 0.19)')
  .border({ radius: px2rem(4) })
  .spacing({ inner: [px2rem(4), px2rem(6)] })
  .element('p')

const Container = style()
  .flex()
  .size({ width: size.fill, height: size.fill })
  .sans({ nodecoration: true })
  .spacing({ inner: [space.none, space.xxs] })
  .border({ radius: px2rem(6) })
  .select(
    `${SkillLabelOverlay}`,
    style()
      .absolute({ top: px2rem(6), left: 0 })
      .front(),
  )
  .relative()
  .elementFromComponent(Link)

const Text = text
  .secondaryBodyInteractive()
  .color({ fg: designSystemColors.typographyPrimary })
  .pointer()
  .spacing({ outer: [space.auto, space.none] })
  .sans({ ellipsis: true })
  .select(`${SkillLabelOverlay}`, style().invisible())
  .select(`:hover ${SkillLabelOverlay}`, style().visible())
  .element()

const SkillLabel: React.FC<Props> = ({ label, contentId }) => {
  const { candidate } = useSelectedCandidate()

  return <Label label={label} contentId={contentId} candidateId={candidate?.id || ''} />
}

interface LabelProps extends Props {
  candidateId: string
}

const Label: React.FC<LabelProps> = ({ label, contentId, candidateId }) => {
  return (
    <Container
      to={`${candidateId}/responses#${contentId}`}
      onClick={() => tracking.candidates.clicksHeatmapSkillLabel()}
    >
      <Text>
        <SkillLabelOverlay>{label}</SkillLabelOverlay>
        {label}
      </Text>
    </Container>
  )
}

export default SkillLabel
