import { Button, Modal } from '@common/components'
import validate from 'all-good-emails'
import DropdownField, { Option } from 'components/DropdownField'

import TextField from 'components/TextField'
import { fr, px2rem, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React, { useEffect, useState } from 'react'
import { AccessLevel } from '../../selectors/user-permissions'

const Content = style()
  .spacing({ inner: [px2rem(24), px2rem(32)] })
  .scroll({ y: 'auto' })
  .element()

const Buttons = style()
  .flex({ justifyContent: 'flex-end' })
  .spacing({ inner: [px2rem(12), px2rem(16)], gap: px2rem(16) })
  .border({
    top: `solid 1px`,
    color: designSystemColors.borderDefault,
  })
  .select('> *', style().size({ width: px2rem(140) }))
  .element()

const AddUserModalStyle = style().element()

const Row = style()
  .spacing({ inner: [px2rem(0), px2rem(0), px2rem(19), px2rem(0)] })
  .size({ maxWidth: '100%' })
  .element()

const SplitRow = style()
  .grid({ columns: [fr(1), fr(1)] })
  .spacing({
    inner: [px2rem(0), px2rem(0), px2rem(9), px2rem(0)],
    gap: px2rem(16),
  })
  .size({ width: px2rem(630), maxWidth: '100%' })
  .element()

const DropdownSubText = text
  .smallBody()
  .spacing({ top: px2rem(9) })
  .text({ nodecoration: true })
  .element()

const CommentColumnLink = text.smallBody().text({ underline: true, nodecoration: true }).element('a')

const DropdownContainer = style().element()
export type AddUserValues = {
  fullName: string
  emailAddress: string
  accessLevel: AccessLevel
}

type OnReferenceFeedbackDetailsSubmit = (values: AddUserValues) => any

interface AddUserModalProps {
  open: boolean
  setOpen: (open: boolean) => void
  initialValues?: Partial<AddUserValues>
  onSubmit: OnReferenceFeedbackDetailsSubmit
}

export const AddUserModal: React.FC<AddUserModalProps> = ({ open, setOpen, initialValues, onSubmit }) => {
  const [fullName, setFullName] = useState(initialValues?.fullName || '')
  const [emailAddress, setEmailAddress] = useState(initialValues?.emailAddress || '')
  const [accessLevel, setAccessLevel] = useState(initialValues?.accessLevel || AccessLevel.Recruiter)

  const [emailAddressDirty, setEmailAddressDirty] = useState(false)
  const [nameDirty, setNameDirty] = useState(false)
  const [isEmailAddressValid, setEmailAddressValid] = useState(false)
  const [isNameValid, setNameValid] = useState(false)

  useEffect(() => {
    setEmailAddressValid(validate(emailAddress))
    setNameValid(!!fullName && fullName.trim().length > 0)
  }, [fullName, emailAddress, accessLevel])

  return (
    <Modal title={`Add User to HiPeople`} open={open} onClose={() => setOpen(false)}>
      <AddUserModalStyle>
        <Content>
          <Row>
            <TextField
              aria-label={'Full name'}
              type={'text'}
              label="Full names"
              value={fullName}
              placeholder="e.g Sue Brown"
              error={nameDirty && !isNameValid}
              errorLabel={nameDirty && !isNameValid ? 'The name must not be empty.' : ''}
              onChange={ev => {
                setNameDirty(true)
                setFullName(ev.target.value)
              }}
            />
          </Row>
          <SplitRow>
            <TextField
              aria-label={'Email address'}
              label="Email"
              type={'email'}
              placeholder={'sue@brown.org'}
              value={emailAddress}
              error={emailAddressDirty && !isEmailAddressValid}
              errorLabel={emailAddressDirty && !isEmailAddressValid ? 'Please enter a valid email.' : ''}
              onChange={ev => {
                setEmailAddressDirty(true)
                setEmailAddress(ev.target.value)
              }}
            />
            <DropdownContainer>
              <DropdownField<AccessLevel>
                label="Permission"
                value={accessLevel}
                onChange={accessLevel => setAccessLevel(accessLevel)}
              >
                <Option value={AccessLevel.Administrator} icon="hand-holding-medical">
                  Administrator
                </Option>
                <Option value={AccessLevel.FullAccessRecruiter} icon="user-cog">
                  Recruiter (Full Access)
                </Option>
                <Option value={AccessLevel.Recruiter} icon="hands-helping">
                  Recruiter
                </Option>
                <Option value={AccessLevel.User} icon="user">
                  Hiring Manager
                </Option>
              </DropdownField>
              <DropdownSubText>
                <span>Read more about the </span>
                <CommentColumnLink
                  href={'https://intercom.help/hipeople/en/articles/7733534-organization-settings-users-permissions'}
                  target="_blank"
                >
                  permission types here.
                </CommentColumnLink>
              </DropdownSubText>
            </DropdownContainer>
          </SplitRow>
        </Content>
        <Buttons>
          <Button variant="tertiary" onClick={() => setOpen(false)}>
            {'Cancel'}
          </Button>
          <Button
            variant="purple-dark"
            onClick={() => {
              onSubmit({
                accessLevel: accessLevel,
                fullName: fullName.trim(),
                emailAddress: emailAddress.trim(),
              })
            }}
            disabled={!isEmailAddressValid || !isNameValid}
          >
            {'Send Invite'}
          </Button>
        </Buttons>
      </AddUserModalStyle>
    </Modal>
  )
}
