import Token from './Token'

const TOKEN_STORAGE_KEY = 'JWT'

export default class PermanentToken implements Token {
  constructor() {
    sanityCheck()
  }

  read(): string {
    const stored = localStorage.getItem(TOKEN_STORAGE_KEY)
    return stored || ''
  }

  write(jwt: string) {
    localStorage.setItem(TOKEN_STORAGE_KEY, jwt)
  }

  clear() {
    localStorage.removeItem(TOKEN_STORAGE_KEY)
  }

  status(): boolean {
    return this.read().length > 0
  }
}

function sanityCheck() {
  const key = '_sanity_check_key'
  localStorage.setItem(key, '1')
  if (localStorage.getItem(key) !== '1') {
    console.error('localStorage not working')
  }
}
