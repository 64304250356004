import React from 'react'
import * as selectors from '../../selectors'
import BigNumber from './Visuals/BigNumber'
import PieChart from './Visuals/PieChart'

interface Props {
  content: selectors.reports.Visual
}

const Component = (props: Props) => {
  if (props.content.bigNumber) {
    return <BigNumber content={props.content.bigNumber} />
  }
  if (props.content.pieChart) {
    return <PieChart content={props.content.pieChart} />
  }
  return <></>
}

export default Component
Component.displayName = 'Content'
