import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as openjobroles from 'api/openjobroles'
import { RoleQuery } from 'api/openjobroles'
import { ActionCreator, AppThunk } from 'core/store'
import { add as notify } from 'store/notifications'
import { addEntities } from 'store/resources'

const name = 'roles'

type RolesDataEntry = {
  updatedAt: number
  query: RoleQuery
  total: number
  cursor: number
  hasMore: boolean
  roleIds: string[]
}

interface State {
  roles?: RolesDataEntry
  hasCreatedRole?: boolean
}

const initialState: State = {}

const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateRolesCache(
      state,
      action: PayloadAction<{
        query: RoleQuery
        roleIds: string[]
        updatedAt: number
        total: number
        cursor: number
        hasMore: boolean
      }>,
    ) {
      const { query, roleIds, updatedAt, total, cursor, hasMore } = action.payload

      if (!state.roles || query.skip === 0) {
        state.roles = {
          updatedAt,
          query,
          // For user based queries, total value represents number of
          // open_job_role_users entities. For that reason, it is better to fallback to
          // the roleIds as total number
          total: query.type === 'user' ? roleIds.length : total,
          hasMore,
          cursor,
          roleIds,
        }

        return
      }
      const cacheEntry = {
        ...state.roles,
        updatedAt,
        // For user based queries, total value represents number of
        // open_job_role_users entities. For that reason, it is better to fallback to
        // the roleIds as total number
        total: query.type === 'user' ? state.roles.roleIds.length + roleIds.length : total,
        hasMore,
        cursor,
      }

      cacheEntry.roleIds = Array.from(new Set([...cacheEntry.roleIds, ...roleIds]))

      state.roles = cacheEntry
    },
    updateHasCreatedRole(
      state,
      action: PayloadAction<{
        hasCreatedRole: boolean
      }>,
    ) {
      state.hasCreatedRole = action.payload.hasCreatedRole
    },
    invalidateCache(state) {
      state = {}
    },
  },
})

const { updateRolesCache, updateHasCreatedRole } = slice.actions

export default slice.reducer

export const starRole = (query: { roleId: string; userId: string; isStarred: boolean }): AppThunk => {
  return async dispatch => {
    const [response, errors] = await openjobroles.starRole(query)

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    if (!response) {
      return
    }

    dispatch(addEntities(response))
    dispatch(
      notify({
        success: query.isStarred ? 'The job has been added to favorites!' : 'The job has been removed from favorites!',
      }),
    )
  }
}

export const loadRoles = (query: RoleQuery): ActionCreator<void> => {
  return async dispatch => {
    const [response, errors] = await openjobroles.fetchRoles(query)

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    if (!response) {
      return
    }

    dispatch(addEntities(response))

    const hasMore = response.list?.has_more || false
    const updatedAt = Date.now()

    const roleIds = (response.result as any).map(row => (query.type === 'user' ? row.fields.open_job_role_id : row.id))

    const total = response.list?.total || 0

    dispatch(
      updateRolesCache({
        query,
        roleIds,
        total,
        hasMore,
        cursor: query.skip + response.result.length,
        updatedAt,
      }),
    )
  }
}

export const loadSidebarRole = (userId: string): ActionCreator<void> => {
  return async dispatch => {
    const [response, errors] = await openjobroles.fetchRoles({
      type: 'user',
      userId: userId,
      skip: 0,
      limit: 1,
      onlyDeleted: false,
      title: '',
    })

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    dispatch(
      updateHasCreatedRole({
        hasCreatedRole: response?.list ? response.list.total > 0 : false,
      }),
    )
  }
}

export const createTestRole = (): AppThunk => {
  return async dispatch => {
    const [response, errors] = await openjobroles.createTestRole()

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    if (response) {
      dispatch(addEntities(response))
      dispatch(
        notify({
          success: 'Created test job successfully! 🎉',
        }),
      )
    }
  }
}
