import { Icon } from 'components/Icons'
import { px, px2rem, size, space, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React, { useMemo } from 'react'

const Tooltip = style()
  .size({ width: px2rem(200) })
  .spacing({ inner: space.xs })
  .border({ around: '1px solid', color: designSystemColors.borderDefault })
  .shadow(0.1)
  .color({ bg: designSystemColors.backgroundNeutralPrimary })
  .round('6px')
  .sans({ align: 'left' })
  .set('boxShadow', '0 1px 0 0 rgba(0,0,0,.05),0 0 20px 0 rgba(0,0,0,.15)')
  .element('section')

const TooltipContainer = style()
  .relative({ top: -px2rem(10) })
  .spacing({ innerTop: px2rem(4) })
  .absolute({ top: `calc(${size.fill} - 4px)`, right: size.none })
  .front()
  .element()

const Container = style()
  .relative()
  .flex({ alignItems: 'center', justifyContent: 'space-between' })
  .spacing({ inner: [px2rem(4), px2rem(8)] })
  .color({
    fg: designSystemColors.typographySecondary,
    bg: designSystemColors.informationGreenBackground,
  })
  .cond(
    ({ warning }) => warning,
    style().color({
      fg: designSystemColors.typographySecondary,
      bg: designSystemColors.uiStatusWarningBackground,
    }),
  )
  .border({
    around: 'solid 1px',
    color: designSystemColors.borderDefault,
  })
  .round(px(6))
  .select(
    `${TooltipContainer}`,
    style()
      .spacing({ outerTop: px2rem(4) })
      .invisible(),
  )
  .pointer()
  .select(`:hover ${TooltipContainer}`, style().visible())
  .element()

const Text = text
  .bodyText()
  .spacing({ outerBottom: px2rem(12) })
  .element()
const TextHint = text.smallBody().element()
const TextBold = text.bodyEmphasis().element()
const Link = style()
  .text({ nodecoration: true, underline: true, line: '1.125rem' })
  .color({ fg: 'inherit' })
  .element('a')

const Info = style()
  .spacing({ outerLeft: px2rem(8) })
  .element()

interface Props {
  sameOriginReferenceNames: string[]
  candidateName: string
}

const Component: React.FC<Props> = ({ sameOriginReferenceNames, candidateName }: Props) => {
  const isFraud = useMemo(() => sameOriginReferenceNames.length > 0, [sameOriginReferenceNames])

  return (
    <Container warning={isFraud}>
      <TextHint>{isFraud ? '⚠️ ' : '✅ no'} fraud warning</TextHint>
      <Info>
        <Icon name="info-circle" />

        <TooltipContainer>
          <Tooltip>
            {isFraud ? (
              <>
                <Text>
                  We detected fraud warnings concerning the following people:
                  <TextBold>{sameOriginReferenceNames.join(', ')}.</TextBold>
                </Text>
                <Text>{candidateName} and them could share the same location and/or device.</Text>
              </>
            ) : (
              <Text>We detected no fraud warnings concerning the references of {candidateName}.</Text>
            )}

            <Link
              href="https://intercom.help/hipeople/en/articles/7731159-reference-feedback-fraud-detection"
              target="_blank"
              rel="noopener noreferrer"
            >
              Click here to learn more about our fraud detection system.
            </Link>
          </Tooltip>
        </TooltipContainer>
      </Info>
    </Container>
  )
}

export default Component
Component.displayName = 'Warnings'
