import Permanent from './Permanent'
import Temporary from './Temporary'
import Token from './Token'

class CurrentToken implements Token {
  private temporary: Temporary = new Temporary()
  private permanent: Permanent = new Permanent()

  // Check if temporary token is activated, if yes, return temporary token
  // so that API client uses the temporary token, not the stored permanent one.
  read(): string {
    if (this.temporary.status()) {
      return this.temporary.read()
    }

    return this.permanent.read()
  }

  write(jwt: string) {
    this.temporary.clear()
    this.permanent.write(jwt)
  }

  writeTemporary(jwt: string) {
    this.temporary.write(jwt)
  }

  clear() {
    this.temporary.clear()
    this.permanent.clear()
  }

  // Sometimes we have race conditions and the tokens are not correctly read,
  // resulting in access denied erorrs.
  // This function is used to reload the Tokens, and be sure to have fresh and correct informations
  reload(): CurrentToken {
    this.temporary = new Temporary()
    this.permanent = new Permanent()
    return this
  }

  status(): boolean {
    return this.temporary.status() || this.permanent.status()
  }

  attach() {
    this.permanent.write(this.temporary.read())
    this.temporary.clear()
  }

  isTemporary(): boolean {
    return this.temporary.status()
  }

  impersonating(): boolean {
    // If a permanent token is in the place, as well as a temporary one,
    // user is impersonating another user.
    return this.permanent.status() && this.temporary.status() && this.permanent.read() !== this.temporary.read()
  }
}

export default new CurrentToken()
