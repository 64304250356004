import { Entity } from './request'

export const RESOURCE = 'questionnaire_answers'

export interface Fields {
  id: string
  question_id: string
  questionnaire_submission_id: string
  selected_response_option: string
  text_input: string
  media_url: string
  numerical_input: number
  is_skipped: boolean
  created_at: number
  updated_at: number
  time_taken_millis: number
}

export type QuestionnaireAnswerEntity = Entity<Fields>
