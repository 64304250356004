import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import * as api from '../../api'
import { SelectedUser } from '../../api/openjobroles'
import CreateButton from '../../components/CreateButton'
import DropdownField, { Option } from '../../components/DropdownField'
import { Icon } from '../../components/Icons'
import { colors, height, rem, scale, size, space, style, weight } from '../../core'
import * as selectors from '../../selectors'
import { RootState } from '../../store'

const Chips = style().sans({ height: height.xl }).element()

const Chip = style()
  .inlineBlock()
  .nooverflow()
  .spacing({ inner: [space.xxs, space.xs], outerRight: space.xs })
  .bg({ color: colors.nearWhite })
  .sans({
    size: scale.s,
    align: 'center',
    ellipsis: true,
    color: colors.midGray,
  })
  .round(size.xxxxs)
  .element('span')

const ChipContent = style().spacing({ outerLeft: space.s }).element('span')

const NoSelectedUsers = style()
  .sans({
    size: scale.s,
    weight: weight.light,
    color: colors.gray,
    height: height.xl,
  })
  .element()

const Label = style().fg(colors.darkGray).sans({ size: scale.s, weight: weight.normal }).element('label')

const InputContainer = style()
  .grid({ columns: [size.auto, size.xxxl, rem(5)] })
  .spacing({ columns: size.s })
  .element()

const AddUserButton = style()
  .select('div', style().sans({ height: scale.m }))
  .select('> div', style().spacing({ outerTop: rem(1.65) }))
  .element()

interface Props {
  selectableUserIds: string[]
  selectedUsersWithPerms: api.openjobroles.SelectedUser[]
  onDeleteUser: (id: string) => void
  onSelectUser: (user: SelectedUser) => void
}

const Component = (props: Props) => {
  const { selectableUserIds, selectedUsersWithPerms, onDeleteUser, onSelectUser } = props

  const [filter, setFilter] = useState('')
  const [canControl, setCanControl] = useState(false)
  const [selectedUserId, setSelectedUserId] = useState('')

  const selectableUserProfiles = useSelector((state: RootState) =>
    selectableUserIds.map(id => ({
      user: selectors.users.getById(state, id),
      profile: selectors.profiles.getByUserId(state, id),
    })),
  )
  const selectedUserProfilesWithPerms = useSelector((state: RootState) =>
    selectedUsersWithPerms.map(selected => ({
      selected,
      profile: selectors.profiles.getByUserId(state, selected.id),
    })),
  )

  return (
    <>
      <Label>Selected Users</Label>
      {selectedUserProfilesWithPerms.length ? (
        <Chips>
          {selectedUserProfilesWithPerms.map(s => (
            <Chip key={s.profile?.id || s.selected.id}>
              <Icon name={s.selected.can_control ? 'robot' : 'eye'} />
              <ChipContent>{s.profile?.fields.full_name}</ChipContent>
              <ChipContent onClick={() => onDeleteUser(s.selected.id || '')}>
                <Icon name={'times'} />
              </ChipContent>
            </Chip>
          ))}
        </Chips>
      ) : (
        <NoSelectedUsers>Please add a user to this job.</NoSelectedUsers>
      )}
      <InputContainer>
        <DropdownField<string>
          label="Add User"
          value={selectedUserId}
          onChange={userId => {
            setSelectedUserId(userId)
            setFilter('')
          }}
          allowInput={true}
          onInput={f => setFilter(f)}
          placeholder={'Add users to job'}
          limit={5}
          missingSuggestion={"Can't find the person? Ask your admin!"}
        >
          {selectableUserProfiles
            .filter(p => p.profile && selectors.match(p.profile?.fields.full_name || '', filter))
            .filter(p => !selectedUsersWithPerms.find(s => s.id === p.user.id))
            .map(p => (
              <Option imageUrl={p.profile?.fields.profile_picture_url} key={p?.user.id} value={p.user.id}>
                {p.profile?.fields.full_name}
              </Option>
            ))}
        </DropdownField>
        <DropdownField<boolean>
          label="Permission"
          value={canControl}
          onChange={canControl => setCanControl(canControl)}
        >
          <Option value={false} icon="eye">
            View
          </Option>
          <Option value={true} icon="robot">
            Manage
          </Option>
        </DropdownField>
        <AddUserButton>
          <CreateButton
            onClick={() => {
              if (!selectedUserId) return

              onSelectUser({
                id: selectedUserId,
                can_control: canControl,
              })
              setSelectedUserId('')
            }}
          >
            Add
          </CreateButton>
        </AddUserButton>
      </InputContainer>
    </>
  )
}

export default Component
Component.displayName = 'UserPermissions'
