import React, { FC, useMemo } from 'react'
import classes from './LanguageDropdown.module.scss'

import { CA, DE, ES, FlagComponent, FR, IT, US, SE, NO, DK, FI, NL } from 'country-flag-icons/react/1x1'
import { Dropdown, DropdownItem, DropdownProps } from '../Dropdown'
import { Locale } from '../../../models/locales'
import { Text } from '../../Text/Text'

export type LocaleInfo = {
  name: string
  icon: FlagComponent
}

export const LanguageMap: {
  [key in Locale]: LocaleInfo
} = {
  en_US: {
    name: 'English',
    icon: US,
  },
  es_ES: {
    name: 'Español',
    icon: ES,
  },
  de_DE: {
    name: 'Deutsch',
    icon: DE,
  },
  fr_FR: {
    name: 'Français',
    icon: FR,
  },
  fr_CA: {
    name: 'Français (Canada)',
    icon: CA,
  },
  it_IT: {
    name: 'Italiano',
    icon: IT,
  },
  sv_SE: {
    name: 'Svenska',
    icon: SE,
  },
  nb_NO: {
    name: 'Norsk Bokmål',
    icon: NO,
  },
  da_DK: {
    name: 'Dansk',
    icon: DK,
  },
  fi_FI: {
    name: 'Suomi',
    icon: FI,
  },
  nl_NL: {
    name: 'Nederlands',
    icon: NL,
  },
}

export const LanguageIcon = ({ locale, big }: { locale: Locale; big?: boolean }) => {
  const { name, icon: Icon } = LanguageMap[locale]

  return (
    <div className={big ? classes.languageIconWrapperBig : classes.languageIconWrapper}>
      <Icon title={name} />
    </div>
  )
}

interface LanguageDropdownProps {
  locales: ReadonlyArray<Locale>
  defaultLocale: Locale
  onSelectLocale: (item: Locale) => void
  disabled?: boolean
}

/**
 *  A dropdown component that allows the user to select a language and only returns the selected language.
 * @param locales - An array of language codes
 * @param defaultLocale - The default language code
 * @param onSelectLocale - A function that returns the selected language code
 * @param disabled - True when the dropdown should be disabled
 * @example
 * <LanguageDropdown
 *  locales={['en_US', 'es_ES', 'de_DE', 'fr_FR', 'fr_CA', 'it_IT']}
 *  defaultLocale='en_US'
 *  onSelectLocale={item => console.log(item)}
 * />
 */
export const LanguageDropdown: FC<LanguageDropdownProps> = ({ locales, defaultLocale, onSelectLocale, disabled }) => {
  const items: DropdownProps['items'] = useMemo(() => locales.map(item => languageToItem(item)), [locales])

  const onSelect = (item: DropdownItem) => {
    if (typeof item === 'string') {
      return
    }
    onSelectLocale(item.value as Locale)
  }

  const selectedDefault = useMemo(() => languageToItem(defaultLocale), [defaultLocale])

  return <Dropdown items={items} selectedDefault={selectedDefault} onSelect={onSelect} disabled={disabled} />
}

export const languageToItem = (language: Locale): DropdownItem => {
  const info = LanguageMap[language] || { value: '', label: <>None</> }

  return {
    value: language,
    label: (
      <div className={classes.languageItem}>
        <LanguageIcon locale={language} />
        <Text variant="body-text">{info.name}</Text>
      </div>
    ),
  }
}
