import { put } from './methods'
import { Fields as RoleFields } from './openjobroles'
import { APIResponse, Entity } from './request'

export const RESOURCE = 'required_references'

export interface Fields {
  id: string
  open_job_role_id: string
  candidate_id: string
  is_peer: boolean
  is_manager: boolean
  is_report: boolean
  is_student_peer: boolean
  is_any: boolean
  is_client: boolean
  is_self: boolean
  relationship_description: string
  created_at: number
  updated_at: number
}

interface UpdatePayload {
  id?: string
  is_peer?: boolean
  is_manager?: boolean
  is_report?: boolean
  is_any?: boolean
  is_student_peer?: boolean
  is_client?: boolean
  relationship_description?: string
}

export function updateForRole(
  roleId: string,
  requiredReferences: UpdatePayload[],
): Promise<APIResponse<Entity<RoleFields>>> {
  return put(`open_job_roles/${roleId}/required_references?include=required_references`, {
    required_references: requiredReferences,
  })
}

export function updateForCandidate(
  candidateId: string,
  requiredReferences: UpdatePayload[],
): Promise<APIResponse<Entity<RoleFields>>> {
  return put(`candidates/${candidateId}/required_references?include=required_references`, {
    required_references: requiredReferences,
  })
}
