import { CandidateExperienceMode } from 'api/org-settings'
import Empty from 'App/Candidate/WaitingOnCandidate'
import CandidateNPS, { CandidateNPSScore } from 'App/ExperienceFeedback/CandidateNPS'
import NPSOverTime from 'App/ExperienceFeedback/NPSOverTime'
import NumericQuestionSummary, { ExperienceSummaryScore } from 'App/ExperienceFeedback/NumericQuestionSummary'
import { Badge } from 'components/Badge'
import Button, { ButtonContent } from 'components/Button/ButtonV2'
import IconTooltip from 'components/Tooltip/IconTooltip'
import { px2rem, size, space, style } from 'core'
import { startOfMonth, startOfQuarter, startOfWeek, subDays } from 'date-fns'
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { PageHeader, Tooltip } from '@common/components'
import { FilterOptions, Filters, PaidProducts } from 'api/orgs'
import TabSelector from 'App/ExperienceFeedback/TabSelector'
import { UpgradeButton } from 'App/ProductPages/Shared'
import { text } from 'core/design-system/text'
import * as tracking from 'core/track'
import { useFeatureFlag } from 'providers/features'
import { useMount } from 'react-use'
import * as selectors from 'selectors'
import { getLastQuarterDateRange } from 'selectors/candidate-experience'
import { RootState } from 'store'
import {
  loadFilters,
  loadSummaries,
  loadTextResponsesBest,
  loadTextResponsesImprovements,
  loadTextResponsesNotCovered,
  NPSByMonth,
  QuestionAggregate,
  TextQuestions,
} from 'store/candidate-experience'
import { requestProductUpgrade } from 'store/settings/org'
import * as modulesSlice from '../../store/modules'
import EmptyCandidateExperiencePage from '../ProductPages/EmptyCandidateExperiencePage'
import { FiltersSection } from './Filters/FiltersSection'
import { InviteCandidateMenu } from './InviteCX'
import ResponseHighlights from './ResponseHighlights'
import ResponseRate from './ResponseRate'
import Shell from './Shell'
import UpgradeModal from './UpgradeModal'

export type DateRangeOption =
  | 'All time'
  | 'This week'
  | 'Last 7 days'
  | 'This month'
  | 'Last 30 days'
  | 'This quarter'
  | 'Last quarter'

export type DateRangePreset = {
  name: DateRangeOption
  startDate?: Date
  endDate?: Date
}

const ButtonContainer = style().relative().element()

const ButtonTooltip = style().element()

const todaysDate = new Date(Date.now())

export const ranges: DateRangePreset[] = [
  {
    // default range, no custom date range filter
    // will display all responses
    name: 'All time',
  },
  {
    // from start of week (Monday) to current date
    name: 'This week',
    startDate: startOfWeek(todaysDate, { weekStartsOn: 1 }),
    endDate: todaysDate,
  },
  {
    // past 7 days, including current date
    name: 'Last 7 days',
    startDate: subDays(todaysDate, 6),
    endDate: todaysDate,
  },
  {
    // from start of month (1st) to current date
    name: 'This month',
    startDate: startOfMonth(todaysDate),
    endDate: todaysDate,
  },
  {
    // past 30 days, including current date
    name: 'Last 30 days',
    startDate: subDays(todaysDate, 30),
    endDate: todaysDate,
  },
  {
    // quarter ranges are:
    // Q1: Jan 01 - Mar 31
    // Q2: Apr 01 - Jun 30
    // Q3: Jul 01 - Sep 30
    // Q4: Oct 01 - Dec 31
    name: 'This quarter',
    startDate: startOfQuarter(todaysDate),
    endDate: todaysDate,
  },
  {
    // subtracts a quarter from current date
    // then finds the start date and end dates for that quarter
    name: 'Last quarter',
    startDate: getLastQuarterDateRange(todaysDate).startDate,
    endDate: getLastQuarterDateRange(todaysDate).endDate,
  },
]

interface CandidateExperienceProps {}

const CandidateExperience: React.FC<CandidateExperienceProps> = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const org = useSelector((state: RootState) => selectors.orgs.current(state))

  const orgSettings = useSelector((state: RootState) => selectors.orgSettings.getByOrgId(state, org.id))

  const [range, setRange] = useState<DateRangePreset>({ name: 'All time' })

  let summary = useSelector((state: RootState) => {
    return state.candidateExperience
  })

  let questions = useSelector((state: RootState) => selectors.candidateExperience.findNumericQuestionScores(state))

  const textQuestions = useSelector((state: RootState) => selectors.candidateExperience.findTextQuestionScores(state))

  useMount(() => {
    dispatch(loadSummaries(org.id, filters, range?.startDate, range?.endDate))
    dispatch(loadFilters(org.id))
    dispatch(modulesSlice.loadBySlug('candidate-experience'))
  })

  const filterOptions = useSelector((state: RootState) => {
    return state.candidateExperience.filters
  })

  const defaultFilters: Filters = {
    hiring_status: 'all',
    department: 'all',
    hiring_manager: 'all',
    last_stage: 'all',
    location: 'all',
    recruiter_email: 'all',
  }

  const [filters, setFilters] = useState<Filters>(defaultFilters)

  const filtered =
    range.name !== 'All time' ||
    filters.department !== 'all' ||
    filters.hiring_status !== 'all' ||
    filters.last_stage !== 'all' ||
    filters.location !== 'all' ||
    filters.recruiter_email !== 'all'

  function resetFilterStates() {
    // when adding new filters, don't forget to include the states here
    setFilters(defaultFilters)
    setRange({ name: 'All time' })
  }

  useEffect(() => {
    if (!org) return
    dispatch(loadSummaries(org.id, filters, range?.startDate, range?.endDate))
    if (!textQuestions.length) return
    dispatch(loadTextResponsesBest(org.id, questionIdBest, filters, range?.startDate, range?.endDate))
    dispatch(loadTextResponsesImprovements(org.id, questionIdImprovements, filters, range?.startDate, range?.endDate))
    dispatch(loadTextResponsesNotCovered(org.id, questionIdNotCovered, filters, range?.startDate, range?.endDate))
  }, [org, range, filters, textQuestions?.length])

  const { isEnabled: isResponseRateCardEnabled } = useFeatureFlag('response-rate-card')

  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false)

  // the question id's for the three questions for the highlight cards
  const textQuestionIds = [...textQuestions.map(question => question.id)]
  const [questionIdBest, questionIdImprovements, questionIdNotCovered] = textQuestionIds

  const textResponsesBest = useSelector((state: RootState) => {
    return state.candidateExperience.textResponsesBest[questionIdBest] || []
  })

  const textResponsesImprovements = useSelector((state: RootState) => {
    return state.candidateExperience.textResponsesImprovements[questionIdImprovements] || []
  })
  const textResponsesNotCovered = useSelector((state: RootState) => {
    return state.candidateExperience.textResponsesNotCovered[questionIdNotCovered] || []
  })

  let highlightCardsData = {
    best: [
      ...textResponsesBest.map(responses => ({
        quote: responses.response,
      })),
    ],
    improvements: [
      ...textResponsesImprovements.map(responses => ({
        quote: responses.response,
      })),
    ],
    notCovered: [
      ...textResponsesNotCovered.map(responses => ({
        quote: responses.response,
      })),
    ],
    noResponsesYet: [{ quote: 'No responses yet.' }],
  }

  let months = useSelector((state: RootState) => selectors.candidateExperience.findScoresByMonth(state))

  const days = useSelector((state: RootState) =>
    selectors.candidateExperience.findScoresByDay(
      state,
      range.startDate || new Date(),
      range.endDate || new Date(),
      range.name,
    ),
  )

  const quarters = useSelector((state: RootState) =>
    selectors.candidateExperience.findScoresByQuarter(
      state,
      range.startDate || new Date(),
      range.endDate || new Date(),
      range.name,
    ),
  )

  if (org.fields.plan !== 'normal') {
    return <EmptyCandidateExperiencePage button={<UpgradeButton orgId={org.id} product={'candidate-experience'} />} />
  }

  if (orgSettings?.fields.candidate_experience_mode === 'none') {
    return (
      <EmptyCandidateExperiencePage
        button={
          <Button
            as="Link"
            to={'/settings/organization'}
            buttonType="accent"
            onClick={() => tracking.candidateExperience.sidebarCandidateExperienceEnableNowClicked()}
          >
            Enable Candidate Experience
          </Button>
        }
      />
    )
  }

  if (['acme', 'stripe'].includes(org.fields.slug)) {
    summary = {
      npsOverall: DEMO_NPS,
      npsScoreOverall: DEMO_NPS_SCORE,
      totalResponses: DEMO_TOTAL_RESPONSES,
      surveysSent: DEMO_SURVEYS_SENT,

      numericQuestions: [],
      npsByMonth: [],
      textQuestions: {},
      textResponsesBest: {},
      textResponsesImprovements: {},
      textResponsesNotCovered: {},
      neverOpened: 0,
      filters: filterOptions,
    }

    months = getOverTimeDemoData(range)
    questions = DEMO_QUESTIONS
    highlightCardsData = DEMO_HIGHLIGHT_CARDS_DATA
  }

  return (
    <>
      <Shell>
        <CandidateExperienceUI
          filterOptions={filterOptions}
          filtered={filtered}
          filters={filters}
          setFilters={setFilters}
          ranges={ranges}
          range={range}
          setRange={setRange}
          overTimeScores={
            range.name === 'All time'
              ? months
              : range.name === 'This quarter' || range.name === 'Last quarter'
              ? quarters
              : days
          }
          questions={questions}
          summary={summary}
          highlightCardsData={highlightCardsData}
          isResponseRateCardEnabled={isResponseRateCardEnabled}
          cxMode={orgSettings?.fields.candidate_experience_mode || 'none'}
          paidProducts={org.fields.paid_products}
          setUpgradeModalOpen={setUpgradeModalOpen}
          onClickATSReady={() => {
            history.push(`/settings/organization`)
          }}
          resetFilterStates={resetFilterStates}
          orgID={org.id}
        />
      </Shell>
      {upgradeModalOpen && (
        <UpgradeModal
          onUpgrade={() => {
            dispatch(requestProductUpgrade(org.id, 'candidate-experience'))
          }}
          setOpen={setUpgradeModalOpen}
        />
      )}
    </>
  )
}

export default CandidateExperience

type CXSummary = {
  npsOverall: number
  npsScoreOverall: number
  npsByMonth: NPSByMonth[]
  totalResponses: number
  surveysSent: number
  neverOpened: number
  numericQuestions: QuestionAggregate[]
  textQuestions: TextQuestions
  textResponsesBest: TextQuestions
  textResponsesImprovements: TextQuestions
  textResponsesNotCovered: TextQuestions
}

export type OverTimeScores = {
  label: string
  score: number | null
  hiddenUntilHovered?: boolean
}[]

type HighlightsData = {
  best: {
    quote: string
  }[]
  improvements: {
    quote: string
  }[]
  notCovered: {
    quote: string
  }[]
  noResponsesYet: {
    quote: string
  }[]
}

const Summary = style()
  .size({ width: size.fill })
  .grid({ columns: [px2rem(258), space.auto] })
  .spacing({ gap: px2rem(16) })
  .select('> *', style().size({ height: size.fill }))
  .element()

const Relative = style().relative().element()
const AbsoluteTooltip = style().absolute({ top: space.m, right: space.m }).elementFromComponent(IconTooltip)

type WithTooltipProps = {
  text: string
  children: React.ReactNode
}

const WithTooltip: React.FC<WithTooltipProps> = props => {
  return (
    <Relative>
      {props.children}
      <AbsoluteTooltip icon="question-circle" tooltipCopy={props.text} />
    </Relative>
  )
}

const PageContainerStyle = style()
  .flex({ direction: 'column', alignItems: 'center' })
  .spacing({ gap: px2rem(16), outer: size.auto })
  .size({ width: size.fill })
  .element()

export const TitleContainer = style()
  .flex({ justifyContent: 'space-between', alignItems: 'center' })
  .spacing({ gap: '1em' })
  .size({ width: size.fill })
  .element()

export const PageTitle = text.highlight().spacing({ outerRight: size.auto }).element('h1')

interface CandidateExperienceUIProps {
  filterOptions: FilterOptions
  filtered: boolean
  filters: Filters
  setFilters: Dispatch<SetStateAction<Filters>>
  ranges: DateRangePreset[]
  range: DateRangePreset
  setRange: Dispatch<SetStateAction<DateRangePreset>>
  overTimeScores: OverTimeScores
  questions: ExperienceSummaryScore[]
  isResponseRateCardEnabled?: boolean
  highlightCardsData: HighlightsData
  summary: CXSummary
  cxMode: CandidateExperienceMode
  paidProducts: PaidProducts
  setUpgradeModalOpen: (open: boolean) => void
  onClickATSReady?: () => void
  resetFilterStates: () => void
  orgID: string
}

const CandidateExperienceUI: React.FC<CandidateExperienceUIProps> = ({
  filterOptions,
  filtered,
  filters,
  setFilters,
  ranges,
  range,
  setRange,
  overTimeScores,
  questions,
  summary,
  highlightCardsData,
  isResponseRateCardEnabled,
  cxMode,
  paidProducts,
  setUpgradeModalOpen,
  onClickATSReady,
  resetFilterStates,
  orgID,
}) => {
  const anyScorePresence = summary.totalResponses > 0 && summary.npsByMonth
  const inviteMenuButtonRef = useRef<HTMLElement>(null)
  const [isInviteMenuOpen, setInviteMenuOpen] = useState(false)

  const user = useSelector((state: RootState) => {
    return selectors.users.current(state)
  })
  const userHasVerifiedEmail = user.fields.verified_at > 0

  return (
    <PageContainerStyle>
      <TabSelector content />
      <PageHeader.Root>
        <PageHeader.Title>Candidate Experience Analytics</PageHeader.Title>
        <PageHeader.EndItems>
          {(() => {
            if (cxMode === 'ats-hired-or-rejected') {
              return <Badge copy={'ATS connected'} theme={'green'} icon="wifi" />
            }
            if (
              (paidProducts['candidate-experience']?.paid_at || 0) > 0 &&
              (cxMode === 'none' || cxMode === 'post-reference-check')
            ) {
              return (
                <Badge
                  copy={'ATS ready for connection'}
                  theme={'orange'}
                  icon="wifi"
                  onClick={() => onClickATSReady?.()}
                />
              )
            }

            if (cxMode === 'link') {
              return (
                <ButtonContainer>
                  <Tooltip
                    text="Please verify your email address by clicking the link we sent you!"
                    disabled={userHasVerifiedEmail}
                  >
                    <ButtonTooltip>
                      <Button
                        data-for="invite-cx-button"
                        buttonType="accent"
                        disabled={!userHasVerifiedEmail}
                        refAsActivator={inviteMenuButtonRef}
                        onClick={() => {
                          setInviteMenuOpen(true)
                        }}
                      >
                        <ButtonContent icon={{ name: 'plus', ariaLabel: 'invite via link' }}>
                          {'Invite via link'}
                        </ButtonContent>
                      </Button>
                      <InviteCandidateMenu
                        isMenuOpen={isInviteMenuOpen}
                        setMenuOpen={setInviteMenuOpen}
                        activatorRef={inviteMenuButtonRef}
                        setUpgradeModalOpen={setUpgradeModalOpen}
                        orgID={orgID}
                      />
                    </ButtonTooltip>
                  </Tooltip>
                </ButtonContainer>
              )
            }

            if (paidProducts['candidate-experience']?.pending) {
              return <Badge copy={'Upgrade request in review'} theme={'orange'} icon="clock" />
            }
            return (
              <Badge
                copy={'Upgrade to connect ATS'}
                theme={'blue'}
                icon="sparkles"
                onClick={() => setUpgradeModalOpen(true)}
              />
            )
          })()}
        </PageHeader.EndItems>
      </PageHeader.Root>
      <FiltersSection
        dateRanges={ranges}
        filterOptions={filterOptions}
        filters={filters}
        filtered={filtered}
        reset={resetFilterStates}
        selectedDateRange={range}
        setDateRange={setRange}
        setFilters={setFilters}
        cxMode={cxMode}
        setUpgradeModalOpen={setUpgradeModalOpen}
      />
      {anyScorePresence ? (
        <>
          <Summary>
            <WithTooltip text="Average score from 0 to 10 provided by the candidates. Candidates scoring 6 or lower are unlikely to recommend others to apply, when those giving 9 or 10 being your company’s advocates.">
              <CandidateNPS score={summary.npsOverall} />
            </WithTooltip>
            <NPSOverTime
              scores={overTimeScores.map(item => ({
                label: item.label,
                score: item.score,
                hiddenUntilHovered: item.hiddenUntilHovered,
              }))}
            />
            {isResponseRateCardEnabled && (
              <>
                <WithTooltip text="% of candidates who scored 9 or 10 minus % of candidates who scored 0 to 6. The score is negative if below zero, and positive if above, with more specific benchmarks varying by industry.">
                  <CandidateNPSScore score={summary.npsScoreOverall} />
                </WithTooltip>
                <ResponseRate
                  overallScore={(summary.totalResponses * 100) / summary.surveysSent}
                  surveysSent={summary.surveysSent}
                  answers={summary.totalResponses}
                />
              </>
            )}
          </Summary>
          <NumericQuestionSummary questions={questions} />
          <ResponseHighlights highlightCardsData={highlightCardsData} />
        </>
      ) : (
        <Empty
          title={filtered ? `No summaries to display for the selected filters` : `No data to display`}
          status={
            filtered
              ? `You don't have any available responses for the selected filters.`
              : `For anonymity reasons, we only display answers once there are at least three submissions.`
          }
        />
      )}
    </PageContainerStyle>
  )
}

const DEMO_QUESTIONS = [
  {
    heading: 'Interacting with the hiring team was a pleasure.',
    emoji: '🤝️',
    title: 'Hiring Team Interaction',
    emojiBgColor: '#F5EBFC',
    overallScore: 3,
    trendScore: 6.5,
  },
  {
    heading: 'There was sufficient communication during the hiring process.',
    emoji: '🖇️',
    title: 'Communication',
    emojiBgColor: '#E9F9EF',
    overallScore: 1,
    trendScore: 6.5,
  },
  {
    heading: 'I knew what to expect from the hiring process at all times.',
    emoji: '🌦',
    title: 'Process clarity',
    emojiBgColor: '#EAF2FD',
    overallScore: 2,
    trendScore: 6.5,
  },
  {
    heading: 'I believe that the applicants for this position were treated equally during the hiring process.',
    emoji: '🌱',
    title: 'Process Fairness',
    emojiBgColor: '#FFE5EC',
    overallScore: 3,
    trendScore: 6.5,
  },
  {
    heading: 'Through the hiring process, I was able to gain sufficient insight into the position and the team.',
    emoji: '💼',
    title: 'Job Preview',
    emojiBgColor: '#FFF1DD',
    overallScore: 5,
    trendScore: 4.2,
  },
  {
    heading: 'The effort I had to put into the hiring process was reasonable.',
    emoji: '🏃‍♀️',
    title: 'Effort',
    emojiBgColor: '#F5EBFC',
    overallScore: 6,
    trendScore: 2.6,
  },
  {
    heading: 'I understand what information has been collected about me and how it contributes to the hiring decision.',
    emoji: '🗞',
    title: 'Information Use',
    emojiBgColor: '#E9F9EF',
    overallScore: 6.5,
    trendScore: 3.3,
  },
  {
    heading: 'The technologies used to support the hiring process are appropriate.',
    emoji: '💾',
    title: 'Technology use',
    emojiBgColor: '#EAF2FD',
    overallScore: 6.2,
    trendScore: 4.5,
  },
]

// the demo data is based around a 'current date' of 17/11/2022

const DEMO_SCORES_ALL_TME = [
  { label: 'Dec', score: 2 },
  { label: 'Jan', score: 4 },
  { label: 'Feb', score: 4.7 },
  { label: 'Mar', score: null },
  { label: 'Apr', score: 3.8 },
  { label: 'May', score: null },
  { label: 'Jun', score: 6.4 },
  { label: 'Jul', score: 7.5 },
  { label: 'Aug', score: 6.2 },
  { label: 'Sep', score: 7 },
  { label: 'Oct', score: 8.2 },
  { label: 'Nov', score: 9 },
]

const DEMO_SCORES_THIS_WEEK = [
  { label: '14.11', score: null },
  { label: '15.11', score: 7.8 },
  { label: '16.11', score: 6 },
  { label: '17.11', score: 8.2 },
]

const DEMO_SCORES_LAST_7_DAYS = [
  { label: '11.11', score: null },
  { label: '12.11', score: 7 },
  { label: '13.11', score: 6 },
  { label: '14.11', score: null },
  { label: '15.11', score: 7.8 },
  { label: '16.11', score: 6 },
  { label: '17.11', score: 8.2 },
]

const DEMO_SCORES_THIS_MONTH = [
  { label: '1.11', score: null },
  { label: '2.11', score: 7 },
  { label: '3.11', score: 6 },
  { label: '4.11', score: 4.1 },
  { label: '5.11', score: 8.3 },
  { label: '6.11', score: 9.2 },
  { label: '7.11', score: 8 },
  { label: '8.11', score: 7 },
  { label: '9.11', score: null },
  { label: '10.11', score: 7 },
  { label: '11.11', score: 6.9 },
  { label: '12.11', score: 3 },
  { label: '13.11', score: null },
  { label: '14.11', score: 5 },
  { label: '15.11', score: null },
  { label: '16.11', score: 7 },
  { label: '17.11', score: 6.1 },
]

const DEMO_SCORES_LAST_30_DAYS = [
  { label: '19', score: 4.8, hiddenUntilHovered: true },
  { label: '20', score: 6, hiddenUntilHovered: true },
  { label: '21', score: 9, hiddenUntilHovered: true },
  { label: '22', score: 8, hiddenUntilHovered: true },
  { label: '23', score: 7, hiddenUntilHovered: true },
  { label: '24.11', score: null },
  { label: '25', score: 7.4, hiddenUntilHovered: true },
  { label: '26', score: 6, hiddenUntilHovered: true },
  { label: '27', score: 3.8, hiddenUntilHovered: true },
  { label: '28', score: null, hiddenUntilHovered: true },
  { label: '29', score: 5, hiddenUntilHovered: true },
  { label: '30', score: 3, hiddenUntilHovered: true },
  { label: '31.10', score: 4 },
  { label: '1', score: null, hiddenUntilHovered: true },
  { label: '2', score: 7, hiddenUntilHovered: true },
  { label: '3', score: 6, hiddenUntilHovered: true },
  { label: '4', score: 4.1, hiddenUntilHovered: true },
  { label: '5', score: 8.3, hiddenUntilHovered: true },
  { label: '6', score: 9.2, hiddenUntilHovered: true },
  { label: '7.11', score: 8 },
  { label: '8', score: 7, hiddenUntilHovered: true },
  { label: '9', score: null, hiddenUntilHovered: true },
  { label: '10', score: 7, hiddenUntilHovered: true },
  { label: '11', score: 6.9, hiddenUntilHovered: true },
  { label: '12', score: 3, hiddenUntilHovered: true },
  { label: '13', score: null, hiddenUntilHovered: true },
  { label: '14.11', score: 5 },
  { label: '15', score: null, hiddenUntilHovered: true },
  { label: '16', score: 7, hiddenUntilHovered: true },
  { label: '17', score: 6.1, hiddenUntilHovered: true },
]

const DEMO_SCORES_THIS_QUARTER = [
  { label: 'Oct', score: 8.2 },
  { label: 'Nov', score: 9 },
]

const DEMO_SCORES_LAST_QUARTER = [
  { label: 'Jul', score: 7.5 },
  { label: 'Aug', score: 6.2 },
  { label: 'Sep', score: 7 },
]

function getOverTimeDemoData(range: DateRangePreset): OverTimeScores {
  switch (range.name) {
    case 'All time':
      return DEMO_SCORES_ALL_TME
    case 'This week':
      return DEMO_SCORES_THIS_WEEK
    case 'Last 7 days':
      return DEMO_SCORES_LAST_7_DAYS
    case 'This month':
      return DEMO_SCORES_THIS_MONTH
    case 'Last 30 days':
      return DEMO_SCORES_LAST_30_DAYS
    case 'This quarter':
      return DEMO_SCORES_THIS_QUARTER
    case 'Last quarter':
      return DEMO_SCORES_LAST_QUARTER
    default:
      return DEMO_SCORES_ALL_TME
  }
}

const DEMO_NPS = 8.7
const DEMO_NPS_SCORE = 76

const DEMO_TOTAL_RESPONSES = 456
const DEMO_SURVEYS_SENT = 593
const DEMO_HIGHLIGHT_CARDS_DATA = {
  best: [
    {
      quote:
        'Meeting coworkers, quick feedbacks, respectful environment. I really enjoyed the first meeting with Nina and the first email approach!',
    },
  ],
  improvements: [
    {
      quote:
        "There was really not much that I wasn't completely satisfied with during the process. Only two points about the coding test that I think could possibly be better: Giving a reward for completing the task and sharing the results.",
    },
  ],
  notCovered: [
    {
      quote:
        'I think the company could share after the assessment the Employee Value Proposition to be more transparent earlier in the hiring process.',
    },
  ],
  noResponsesYet: [{ quote: 'No responses yet.' }],
}
