import React from 'react'
import variables from '../../../styles/colorVariables.module.scss'
import { useSpring, animated } from '@react-spring/web'

interface CircleStepsProps {
  currentStep: number
  /** Total number of steps */
  numOfSteps?: number
  /** If the component should display the bigger version as for reports */
  big?: boolean
  /** Show label in percentage. i.e.: 78/100 -> 78% */
  showInPercentage?: boolean
}

/**
 * Circle steps component that shows a circle progress bar and inside the current step / total steps
 */
export const CircleSteps = (props: CircleStepsProps) => {
  const { radius, circumference, strokeDashoffset, width, viewBoxWidth, circlePosition, percentage } =
    calculateCircleData(props.currentStep, props.numOfSteps, props.big)

  const spring = useSpring({
    from: { strokeDashoffset: circumference },
    to: { strokeDashoffset },
  })

  return (
    <svg height={width} width={width} viewBox={`0 0 ${viewBoxWidth} ${viewBoxWidth}`}>
      <circle
        cx={circlePosition}
        cy={circlePosition}
        r={radius}
        fill="none"
        stroke="#eee"
        strokeWidth={props.big ? 10 : 5}
      />
      <animated.circle
        cx={circlePosition}
        cy={circlePosition}
        r={radius}
        fill="none"
        stroke={variables.typographyPrimaryAccent}
        strokeWidth={props.big ? 10 : 5}
        strokeDasharray={circumference}
        {...spring}
        strokeLinecap="round"
        transform={`rotate(-90 ${circlePosition} ${circlePosition})`}
      />
      <text
        x={circlePosition}
        y={circlePosition}
        fill={variables.typographyPrimary}
        fontSize={props.big ? 32 : 14}
        fontWeight={500}
        dy=".3em"
        textAnchor="middle"
      >
        {props.showInPercentage
          ? `${percentage}%`
          : `${props.currentStep}${props.numOfSteps ? `/${props.numOfSteps}` : ''}`}
      </text>
    </svg>
  )
}

function calculateCircleData(currentStep: number, numOfSteps?: number, big?: boolean) {
  const percentage = Math.round((currentStep / (numOfSteps || 100)) * 100)
  const radius = big ? 80 : 20
  const circumference = 2 * Math.PI * radius
  const strokeDashoffset = ((100 - percentage) / 100) * circumference

  const width = radius * 2
  const viewBoxWidth = width + 10
  const circlePosition = viewBoxWidth / 2

  return {
    radius,
    circumference,
    strokeDashoffset,
    width,
    viewBoxWidth,
    circlePosition,
    percentage,
  }
}
