import { AssessmentStatus, OnboardingStatus, ReferenceCheckStatus } from 'api/candidates'
import { ReferenceCheckSummaryAlertStatus } from 'api/reference-check-summaries'
import { Candidate } from 'App/CandidatesPage/CandidatesTable'
import { designSystemColors } from 'core/design-system/colors'
import { RootState } from 'store'
import { Status } from 'utils/status'
import {
  milliseconds,
  parseProfessionalNetwork,
  possessiveFirstName,
  ProfessionalNetwork,
  questionnaireSubmissions,
} from '.'
import * as api from '../api'
import * as referenceCheckSummaries from '../selectors/reference-check-summaries'
import { content } from '../store/new-role'
import * as candidateProfiles from './candidate-profiles'
import * as employees from './employees'
import * as formResponses from './form-responses'
import lookup from './lookup'
import * as references from './references'
import * as requiredreferences from './required-references'
import * as roles from './roles'

export function findById(state: RootState, id: string): api.request.Entity<api.candidates.Fields> | undefined {
  return lookup(state, api.candidates.RESOURCE, id)
}

export function getProfessionalNetworkById(
  state: RootState,
  id: string,
): (ProfessionalNetwork & { url: string }) | undefined {
  const candidate = findById(state, id)
  if (candidate?.fields.professional_network) return parseProfessionalNetwork(candidate.fields.professional_network)

  // TODO: deprecate candidate form responses
  const answer = formResponses.findAnswerByCandidateId(
    state,
    id,
    content.CANDIDATE_PROFESSIONAL_NETWORK_QUESTION.test_item_id,
  )
  if (!answer || !answer.input) return

  return parseProfessionalNetwork(answer.input)
}

export enum Step {
  Unknown = 'Unknown',
  Pending = 'Pending',
  InProgress = 'In progress',
  Completed = 'Completed',
  Expired = 'Overdue',
  MarkedComplete = 'Marked complete',
  NotStarted = 'Not started',
}

export enum SubmissionType {
  ReferenceCheck = 'Reference check',
  Assessment = 'Assessment',
  OnboardingSurvey = 'Onboarding survey',
}

export function getStep(state: RootState, id: string, type?: SubmissionType): Step {
  if (type === SubmissionType.OnboardingSurvey) {
    return getOnboardingStep(state, id)
  }
  if (type === SubmissionType.Assessment) {
    return getAssessmentStep(state, id)
  }

  return getReferenceCheckStep(state, id)
}

function getOnboardingStep(state: RootState, id: string): Step {
  const candidate = findById(state, id)
  if (!candidate) return Step.Unknown

  const role = roles.findByCandidateId(state, id)
  if (!role) return Step.Unknown
  return Step.InProgress
}

function getAssessmentStep(state: RootState, id: string): Step {
  const candidate = findById(state, id)
  if (!candidate) return Step.Unknown

  const role = roles.findByCandidateId(state, id)
  if (!role) return Step.Unknown

  if (candidate.fields.marked_as_completed_at) return Step.MarkedComplete

  const submission = questionnaireSubmissions.findSelfAssessmentByCandidateId(state, id)
  if (!submission) return Step.Pending

  if (candidate.fields.expired_at) return Step.Expired
  if (submission.fields.submitted_at) return Step.Completed
  return Step.InProgress
}

function getReferenceCheckStep(state: RootState, id: string): Step {
  const candidate = findById(state, id)
  if (!candidate) return Step.Unknown

  const role = roles.findByCandidateId(state, id)
  if (!role) return Step.Unknown

  const submittedTime = candidate.fields.submitted_at
  const completedTime = candidate.fields.completed_at
  const expiredTime = candidate.fields.expired_at
  const markedCompletedTime = candidate.fields.marked_as_completed_at

  if (markedCompletedTime) return Step.MarkedComplete
  if (expiredTime) return Step.Expired
  if (completedTime) return Step.Completed
  if (submittedTime) return Step.InProgress
  if (!candidate.fields.invited_at) return Step.NotStarted
  return Step.Pending
}

export function getDeadline(state: RootState, id: string): Date | undefined {
  const candidate = findById(state, id)
  if (!candidate) return

  const role = roles.findByCandidateId(state, id)
  if (!candidate) return

  return addWeekdays(new Date(milliseconds(candidate?.fields.invited_at || 0)), role?.fields.candidate_expiry_days || 0)
}

function addWeekdays(base: Date, weekdaysToAdd: number): Date {
  const dayCounter = base
  while (weekdaysToAdd > 0) {
    dayCounter.setDate(dayCounter.getDate() + 1)
    if (!(dayCounter.getDay() === 0 || dayCounter.getDay() === 6)) {
      weekdaysToAdd -= 1
    }
  }
  return dayCounter
}

export function isMarkedComplete(state: RootState, id: string): boolean {
  const candidate = findById(state, id)
  if (!candidate) return false

  if (candidate.fields.marked_as_completed_at) return true

  return candidate.fields.completed_at > 0 && !candidate.fields.expired_at
}

export function url(state: RootState, candidateId: string): string {
  const candidate = findById(state, candidateId)
  return window.location.pathname.startsWith('/shared')
    ? `/shared/candidates/${candidateId}`
    : `/roles/${candidate?.fields.open_job_role_id}/candidates/${candidateId}`
}

export function qaUrl(state: RootState, candidateId: string): string {
  const candidate = findById(state, candidateId)
  return window.location.pathname.startsWith('/shared')
    ? `/shared/candidates/${candidateId}/responses`
    : `/roles/${candidate?.fields.open_job_role_id}/candidates/${candidateId}/responses`
}

export function findRequiredReferencesByCandidateId(state: RootState, candidateId: string) {
  const candidate = findById(state, candidateId)
  if (!candidate) return []

  const candidateRequiredReferences = requiredreferences.findByCandidateId(state, candidate.fields.id)
  if (candidateRequiredReferences.length) {
    return references.requiredReferencesFromEntities(candidateRequiredReferences)
  }

  const roleRequiredReferences = requiredreferences.findByRoleId(state, candidate.fields.open_job_role_id)
  if (roleRequiredReferences.length) {
    return references.requiredReferencesFromEntities(roleRequiredReferences)
  }

  return []
}
export function emptyStatus(state: RootState, candidateId: string) {
  const submitted = references.findRespondedByCandidateIdExceptSelf(state, candidateId)
  if (submitted?.length > 0) return

  const profile = candidateProfiles.findByCandidateId(state, candidateId)
  if (!profile) return

  const name = profile?.fields.full_name || 'candidate'

  const candidate = findById(state, candidateId)
  if (!candidate) return

  if (!candidate.fields.invited_at) {
    return {
      title: `Request not yet send`,
      status: `Send the Reference Check request to ${name}`,
      type: 'not-started',
    }
  }

  // this one is a toss up, which should we show if say they've completed
  // their self-assessment but not invited candidates?
  const requested = references.totalReferencesExceptSelf(state, candidateId)

  if (!requested) {
    return {
      title: `${name} was successfully invited!`,
      status: `${name} is about to invite references, so you'll soon find out if they're the right fit for you!`,
      type: 'no-invitation',
    }
  }

  return {
    title: `Waiting for references...`,
    status: `None of ${possessiveFirstName(name)} references have responded yet.`,
    type: 'no-response',
  }
}

export function findStatusForReferenceCheck(state: RootState, id: string): ReferenceCheckStatus | null {
  const candidate = findById(state, id)

  return candidate?.fields.reference_check_status ?? null
}

export function findStatusForAssessment(state: RootState, id: string): AssessmentStatus | null {
  const candidate = findById(state, id)
  if (!candidate) {
    return null
  }

  const role = roles.findByCandidateId(state, id)
  if (!role || !role.fields.ask_self_assessment) {
    return null
  }

  const questionnaire = questionnaireSubmissions.findSelfAssessmentByCandidateId(state, id)

  return questionnaire?.fields.status ?? null
}

const StatusColor: Record<Status, string> = {
  completed: designSystemColors.uiStatusSuccess,
  hired: designSystemColors.uiStatusSuccess,
  'in progress': designSystemColors.uiStatusWarningSecondary,
  'in-progress': designSystemColors.uiStatusWarningSecondary,
  in_progress: designSystemColors.uiStatusWarningSecondary,
  'not started': designSystemColors.typographySecondary,
  not_started: designSystemColors.typographySecondary,
  pending: designSystemColors.typographySecondary,
  overdue: designSystemColors.uiStatusError,
  expired: designSystemColors.uiStatusError,
  rejected: designSystemColors.uiStatusError,
}

export function colorForStatus(status: Status): string {
  return StatusColor[status] || designSystemColors.typographySecondary
}

// onboarding not enabled -- null done
// no candidate - null done
// not hired - not started done
// all questionnaire submissions completed - done done
// any questionnaire submissions overdue and uncompleted - overdue done

// any questionnaire submission completed - in progress
// hired & past hire data - in progress
// not started
function findStatusForOnboarding(state: RootState, id: string, onboardingEnabled: boolean): OnboardingStatus | null {
  if (!onboardingEnabled) return null

  const candidate = findById(state, id)
  if (!candidate) return null
  if (!candidate.fields.is_hired || !candidate.fields.employee_id) {
    return 'not started'
  }

  const employee = employees.findByCandidateId(state, id)
  if (!employee) return 'not started'

  const startDate = new Date(milliseconds(employee.fields.start_at))
  if (new Date() < startDate) return 'not started'

  // no questionnaires not started
  const questionnaires = questionnaireSubmissions.findByEmployeeId(state, candidate.fields.employee_id)
  if (!questionnaires || !questionnaires.length) return 'not started'

  const employeeQuestionnaires = questionnaires.filter(i => i.fields.employee_id === candidate.fields.employee_id)
  // if there are no questionnaires without a submitted at: completed
  if (employeeQuestionnaires.filter(i => !i.fields.submitted_at).length === 0) {
    return 'completed'
  }

  // if there are any questionnaires that aren't submitted where we're past due: overdue
  if (
    employeeQuestionnaires.find(i => !i.fields.submitted_at && new Date((i.fields.due_at || 0) / 10e5) < new Date())
  ) {
    return 'overdue'
  }

  return 'in progress'
}

export function findCandidatesForCandidateList(
  state: RootState,
  onboardingEnabled: boolean,
  filterByProduct?: 'assessment' | 'reference-feedback',
): Candidate[] {
  const candidates: Candidate[] = []
  for (const id of state.candidateManagement.candidates) {
    const candidate = findById(state, id)
    if (!candidate) continue

    const profile = candidateProfiles.findByCandidateId(state, id)
    if (!profile) continue

    const referenceCheckProgress = findStatusForReferenceCheck(state, id)
    const assessmentProgress = findStatusForAssessment(state, id)
    const onboardingProgress = findStatusForOnboarding(state, id, onboardingEnabled)

    const employee = employees.findById(state, candidate.fields.employee_id)
    const missing = employees.findMissingById(state, candidate.fields.employee_id)

    const role = roles.findByCandidateId(state, id)

    if (filterByProduct === 'assessment') {
      if (!role?.fields.ask_self_assessment) {
        continue
      }
    }

    if (filterByProduct === 'reference-feedback') {
      if (!role?.fields.ask_reference_check) {
        continue
      }
    }

    candidates.push({
      id,
      fullName: profile.fields.full_name,
      updatedAt: new Date(candidate.fields.updated_at / 1e6),
      referenceCheckStatus: referenceCheckProgress,
      assessmentStatus: assessmentProgress,
      onboardingStatus: onboardingProgress,
      hiringStatus: candidate.fields.hiring_status,
      missingInformation:
        onboardingEnabled && candidate.fields.hiring_status === 'hired' && (!employee || missing.length > 0),
      roleId: candidate.fields.open_job_role_id,
    })
  }

  return candidates
}

export interface ReferenceCheckOverviewBlocks {
  respondedRefs: number
  totalRefs: number
  performance: {
    score: number
    of: number
    status: ReferenceCheckSummaryAlertStatus
  }
  acceptedRequests: {
    positive: number
    negative: number
    status: ReferenceCheckSummaryAlertStatus
  }
  hiringRecommendation: {
    positive: number
    negative: number
    status: ReferenceCheckSummaryAlertStatus
  }
  termination: {
    terminated: boolean
    status: ReferenceCheckSummaryAlertStatus
  }
  legitimacy: {
    fraudWarnings: number
    status: ReferenceCheckSummaryAlertStatus
  }
  reasonsForLeaving: {
    reasons: string[]
    status: ReferenceCheckSummaryAlertStatus
  }
  titleVerification: {
    matches: number
    status: ReferenceCheckSummaryAlertStatus
  }
}

// this function is neeeded because the statuses coming from the backend are misleading.
// the only status from the backend we care about are: negative, unselected, positive
// how and when to show the statuses, should be a responsibility of the UI
// the backend should be changed to return just negative, unselected, positive
// if the candidate status is not a final status we should just show the negative status, or jsut the incomplete
function computeOverviewCheckStatus(
  candidateRefCheckStatus: ReferenceCheckStatus,
  referenceCheckAlertStatus: ReferenceCheckSummaryAlertStatus,
): ReferenceCheckSummaryAlertStatus {
  /*  - Just for reference
  export type ReferenceCheckStatus =
    | 'expired'
    | 'completed'
    | 'in_progress'
    | 'not_started'
    | 'pending'

  export type ReferenceCheckSummaryAlertStatus =
    | 'positive'
    | 'negative'
    | 'unselected'
    | 'incomplete'
  */
  if (referenceCheckAlertStatus === 'negative' || referenceCheckAlertStatus === 'unselected') {
    return referenceCheckAlertStatus
  }

  if (candidateRefCheckStatus === 'completed' || candidateRefCheckStatus === 'expired') {
    return 'positive'
  }

  return 'incomplete'
}

export function overviewBlocks(
  state: RootState,
  candidateId: string,
  candidateRefCheckStatus: ReferenceCheckStatus,
): ReferenceCheckOverviewBlocks {
  const summary = referenceCheckSummaries.getByCandidateId(state, candidateId)

  const respondedRefs = references.findRespondedByCandidateIdExceptSelf(state, candidateId) || []
  const totalRefs = references.findNotSelfByCandidateId(state, candidateId) || []

  return {
    respondedRefs: respondedRefs.length,
    totalRefs: totalRefs.length,
    performance: {
      score: summary?.fields.performance_score || 0,
      of: summary?.fields.performance_references_total || 0,
      status: computeOverviewCheckStatus(candidateRefCheckStatus, summary?.fields.performance_status || 'unselected'),
    },
    acceptedRequests: {
      positive: summary?.fields.accepted_requests_positive || 0,
      negative: summary?.fields.accepted_requests_positive || 0,
      status: computeOverviewCheckStatus(
        candidateRefCheckStatus,
        summary?.fields.accepted_request_status || 'unselected',
      ),
    },
    hiringRecommendation: {
      positive: summary?.fields.hiring_recommendation_positive || 0,
      negative: summary?.fields.hiring_recommendation_negative || 0,
      status: computeOverviewCheckStatus(
        candidateRefCheckStatus,
        summary?.fields.hiring_recommendation_status || 'unselected',
      ),
    },
    termination: {
      terminated: summary?.fields.termination_terminated || false,
      status: computeOverviewCheckStatus(candidateRefCheckStatus, summary?.fields.termination_status || 'unselected'),
    },
    legitimacy: {
      fraudWarnings: summary?.fields.legitimacy_fraud_warnings || 0,
      status: computeOverviewCheckStatus(candidateRefCheckStatus, summary?.fields.legitimacy_status || 'unselected'),
    },
    reasonsForLeaving: {
      reasons: summary?.fields.reasons_for_leaving || [],
      status: computeOverviewCheckStatus(
        candidateRefCheckStatus,
        summary?.fields.reasons_for_leaving_status || 'unselected',
      ),
    },
    titleVerification: {
      matches: summary?.fields.title_verification_matches || 0,
      status: computeOverviewCheckStatus(
        candidateRefCheckStatus,
        summary?.fields.title_verification_status || 'unselected',
      ),
    },
  }
}
