import React from 'react'
import { Card } from '../Card/Card'
import { LazyImage } from '../LazyImage/LazyImage'
import { Text } from '../Text/Text'
import classes from './KnowledgeCard.module.scss'

export interface KnowledgeCardProps {
  learning: {
    image: string
    site: string
    title: string
    url: string
  }
  onClick?: () => void
}

export const KnowledgeCard = (props: KnowledgeCardProps) => {
  return (
    <a
      href={props.learning.url}
      target="_blank"
      rel="noopener noreferrer"
      className={classes.link}
      onClick={() => props.onClick?.()}
    >
      <Card className={classes.card}>
        <LazyImage src={props.learning.image} alt={props.learning.title} className={classes.image} />
        <div className={classes.text}>
          <Text variant="small-body-secondary" className={classes.title}>
            {props.learning.title}
          </Text>
          <Text variant="label" className={classes.site}>
            🔗 {props.learning.site}
          </Text>
        </div>
      </Card>
    </a>
  )
}
