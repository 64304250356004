import { map } from './css'
import Rows from './rows'

export interface IOptions {
  direction?: string
  wrap?: string
  basis?: string
  grow?: string
  shrink?: string
  alignItems?: string
  alignSelf?: string
  justifyContent?: string
}

export default function flex(rows: Rows, options: IOptions) {
  rows.set('display', 'flex')

  map(rows, options, {
    direction: 'flexDirection',
    wrap: 'flexWrap',
    basis: 'flexBasis',
    grow: 'flexGrow',
    shrink: 'flexShrink',
    alignItems: 'alignItems',
    alignSelf: 'alignSelf',
    justifyContent: 'justifyContent',
  })
}
