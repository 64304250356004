import { size, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import React, { ReactElement } from 'react'
import TopBar from '../TopBar/TopBar'
import { PageHeader } from '@common/components'

const ProductPageShellStyle = style()
  .color({ bg: designSystemColors.backgroundNeutralSecondary })
  .size({ height: '100dvh' })
  .nooverflow()
  .cond(({ shouldOverflow }: { shouldOverflow?: boolean }) => !!shouldOverflow, style().scroll({ y: 'auto' }))
  .element()

const Main = style()
  .spacing({
    inner: ['3rem 5rem'],
    outer: size.auto,
  })
  .size({ width: size.fill, height: '100%' })
  .with(({ innerHeight }: { innerHeight: string | undefined }) =>
    style().size(innerHeight ? { height: innerHeight } : {}),
  )
  .element()

const ChildrenContainerStyle = style().size({ height: size.fill }).element()

interface ProductPageShellProps {
  title: string
  button?: ReactElement
  overflow?: boolean
  children: React.ReactNode
}

const ProductPageShell: React.FC<ProductPageShellProps> = ({ title, button, overflow, children }) => {
  return (
    <ProductPageShellStyle shouldOverflow={overflow}>
      <TopBar title={title} />
      <Main>
        <PageHeader.Root>
          <PageHeader.Title>{title}</PageHeader.Title>
          <PageHeader.EndItems>{button}</PageHeader.EndItems>
        </PageHeader.Root>

        <ChildrenContainerStyle>{children}</ChildrenContainerStyle>
      </Main>
    </ProductPageShellStyle>
  )
}

export default ProductPageShell
