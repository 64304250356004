import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { colors, px, scale, space, style, weight } from '../../core'
import * as selectors from '../../selectors'
import { RootState } from '../../store'
import { forget } from '../../store/login'

const Container = style()
  .color({ bg: colors.lightestGray, fg: colors.darkGray })
  .sans({ size: scale.s, height: scale.m })
  .spacing({ inner: space.xs })
  .round(px(5))
  .element()

const Greeting = style().sans({ weight: weight.bold }).element('span')

const NotYou = style()
  .pointer()
  .color({
    fg: colors.purpleDark,
  })
  .element('span')

const Component = () => {
  const dispatch = useDispatch()

  const [email, firstName] = useSelector((state: RootState) => [
    state.login.email,
    selectors.firstName(state.login.fullname || ''),
  ])

  if (firstName) {
    return (
      <Container>
        Welcome back, <Greeting> {firstName}!</Greeting> <NotYou onClick={onClickNotYou}>(Not you?)</NotYou>
      </Container>
    )
  }

  if (email) {
    return (
      <Container>
        {email}
        <NotYou onClick={onClickNotYou}> Change email</NotYou>
      </Container>
    )
  }

  return <></>

  function onClickNotYou() {
    dispatch(forget())
  }
}

export default Component
Component.displayName = 'UserHint'
