import * as api from '../api'
import { RootState } from '../store'
import * as candidates from './candidates'
import lookup from './lookup'

function getById(state: RootState, id: string): api.request.Entity<api.referenceCheckStages.Fields> | undefined {
  return lookup(state, api.referenceCheckStages.RESOURCE, id)
}

export function getByCandidateId(
  state: RootState,
  candidateId: string,
): api.request.Entity<api.referenceCheckStages.Fields> | undefined {
  const candidate = candidates.findById(state, candidateId)
  if (!candidate || !candidate.relationships) return

  const referenceCheckStage = candidate.relationships.find(r => r.resource === api.referenceCheckStages.RESOURCE)
  if (!referenceCheckStage) return

  return getById(state, referenceCheckStage.id)
}
export function getReferenceFormInProgress(state: RootState, id: string): boolean {
  const referenceCheckStage = getByCandidateId(state, id)

  return !!referenceCheckStage?.fields.reference_form_started_at
}
