import { Card } from '@common/components'
import validate from 'all-good-emails'
import { Text } from 'App/CandidateOnly/Styles'
import Button from 'components/Button/ButtonV2'
import { fr, px2rem, space, style } from 'core'
import { useAppDispatch } from 'core/store'
import token from 'core/token'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useMount } from 'react-use'
import TextField from '../../components/TextField'
import * as selectors from '../../selectors'
import { RootState } from '../../store'
import * as slice from '../../store/new-role'
import * as openjobroles from '../../store/openjobroles'
import { CandidateShell } from './CandidateShell'
import * as styles from '@common/styles'

const Container = style()
  .size({
    maxWidth: px2rem(590),
    width: '100%',
  })
  .element()

const ButtonRow = style()
  .grid({ columns: [fr(1), fr(1)] })
  .spacing({ columns: space.s, outerTop: px2rem(40) })
  .size({ width: px2rem(420) })
  .element()

const ButtonsContainer = style()
  .size({ width: '100%' })
  .flex({
    alignItems: 'center',
    justifyContent: 'center',
  })
  .element()

const Row = style()
  .spacing({ inner: [px2rem(0), px2rem(0), px2rem(19), px2rem(0)] })
  .size({ maxWidth: '100%' })
  .element()

const InputContainer = style()
  .spacing({
    outerTop: px2rem(32),
  })
  .element()

const SplitRow = style()
  .grid({ columns: [fr(1), fr(1)] })
  .spacing({
    inner: [px2rem(0), px2rem(0), px2rem(9), px2rem(0)],
    gap: px2rem(16),
    outerBottom: px2rem(16),
  })
  .size({ maxWidth: '100%' })
  .element()

interface Props {
  firstName?: string
  lastName?: string
  emailAddress?: string
}

const Component: React.FC<Props> = values => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()
  const { roleid } = useParams<{ roleid: string }>()
  const history = useHistory()

  useMount(() => {
    dispatch(openjobroles.fetch(roleid))
  })

  const org = useSelector((state: RootState) => selectors.orgs.findByRoleId(state, roleid))

  const orgSettings = useSelector((state: RootState) => selectors.orgSettings.getByOrgId(state, org?.id || ''))

  if (orgSettings?.fields.branding_color_primary) {
    styles.updateBrandColours(orgSettings?.fields.branding_color_primary as styles.Hex)
  }

  const [firstName, setFirstName] = useState(values?.firstName || '')
  const [lastName, setLastName] = useState(values?.lastName || '')
  const [emailAddress, setEmailAddress] = useState(values?.emailAddress || '')

  const [emailAddressModified, setEmailAddressModified] = useState(false)
  const [firstNameModified, setFirstNameModified] = useState(false)
  const [lastNameModified, setLastNameModified] = useState(false)

  const [isEmailAddressValid, setEmailAddressValid] = useState(false)
  const [isFirstNameValid, setFirstNameValid] = useState(false)
  const [isLastNameValid, setLastNameValid] = useState(false)

  useEffect(() => {
    setEmailAddressValid(validate(emailAddress))
    setFirstNameValid(!!firstName && firstName.trim().length > 0)
    setLastNameValid(!!lastName && lastName.trim().length > 0)
  }, [firstName, lastName, emailAddress])

  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const submitData = async () => {
    setIsLoading(true)
    const resp = await dispatch(
      slice.invitev2(
        roleid,
        {
          full_name: `${firstName} ${lastName}`,
          email: emailAddress,
        },
        { createCandidate: true },
      ),
    )

    // if we got a response, and there is a token needed for candidate app and there is a candidate Id of the new created candidate
    // we can store the token and move forward to the next steps
    if (resp && resp.token && resp.candidateId) {
      setIsSubmitted(true)
      token.write(resp.token)

      window.location.replace(`/candidates/${resp.candidateId}?from-public=true&token=${resp.token}`)
    }
    setIsLoading(false)
  }

  return (
    <CandidateShell
      orgLogoUrl={org?.fields.logo_image_url || 'https://avatars2.githubusercontent.com/u/60610251?s=200&v=4'}
      roleId={roleid}
    >
      <Container>
        <Card big>
          <Text>{t('candidate-dashboard.add-details.intro', 'Please provide us with your contact information.')}</Text>
          <InputContainer>
            <SplitRow>
              <TextField
                aria-label={'First name'}
                label={t('candidate-dashboard.add-details.first-name.label', 'First name')}
                type={'text'}
                placeholder={'Sue'}
                value={firstName}
                error={firstNameModified && !isFirstNameValid}
                errorLabel={
                  firstNameModified && !isFirstNameValid
                    ? t('candidate-dashboard.add-details.first-name.error', 'Please enter a valid first name.')
                    : ''
                }
                onChange={ev => {
                  setFirstNameModified(true)
                  setFirstName(ev.target.value)
                }}
                testId="candidate-details-first-name-input"
              />
              <TextField
                aria-label={'Last name'}
                label={t('candidate-dashboard.add-details.last-name.label', 'Last name')}
                type={'text'}
                placeholder={'Brown'}
                value={lastName}
                error={lastNameModified && !isLastNameValid}
                errorLabel={
                  lastNameModified && !isLastNameValid
                    ? t('candidate-dashboard.add-details.last-name.error', 'Please enter a valid last name.')
                    : ''
                }
                onChange={ev => {
                  setLastNameModified(true)
                  setLastName(ev.target.value)
                }}
                testId="candidate-details-last-name-input"
              />
            </SplitRow>
            <Row>
              <TextField
                aria-label={'Email address'}
                label={t('candidate-dashboard.add-details.email.label', 'Email')}
                type={'email'}
                placeholder={'sue@brown.org'}
                value={emailAddress}
                error={emailAddressModified && !isEmailAddressValid}
                errorLabel={
                  emailAddressModified && !isEmailAddressValid
                    ? t('candidate-dashboard.add-details.email.error', 'Please enter a valid email address.')
                    : ''
                }
                onChange={ev => {
                  setEmailAddressModified(true)
                  setEmailAddress(ev.target.value)
                }}
                testId="candidate-details-email-input"
              />
            </Row>
          </InputContainer>

          <ButtonsContainer>
            <ButtonRow>
              <Button buttonType={'secondary'} isFullWidth onClick={() => history.goBack()}>
                {t('candidate-dashboard.add-details.button.go-back', 'Go back')}
              </Button>
              <Button
                buttonType={'primary'}
                isFullWidth
                disabled={!isEmailAddressValid || !isLastNameValid || !isFirstNameValid || isSubmitted || isLoading}
                onClick={() => submitData()}
              >
                {t('candidate-dashboard.add-details.button.continue', 'Continue')}
              </Button>
            </ButtonRow>
          </ButtonsContainer>
        </Card>
      </Container>
    </CandidateShell>
  )
}

export default Component
Component.displayName = 'AddCandidateDetails'
