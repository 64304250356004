import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../../components/Modal'
import TextField from '../../components/TextField'
import { Content, ContentAndButtons, Form } from '../../components/Wizard'
import Buttons from '../../components/Wizard/Buttons'
import { Subheading } from '../../components/Wizard/Typography'
import { rem, style } from '../../core'
import * as selectors from '../../selectors'
import { RootState } from '../../store'
import * as slice from '../../store/candidates'

interface Props {
  candidateId: string
  open: boolean
  setOpen: (b: boolean) => void
}

const Container = style()
  .size({
    maxWidth: rem(40),
    maxHeight: rem(25),
    width: 'calc(100vw - 200px)',
    height: rem(16),
  })
  .element()

const Component = (props: Props) => {
  const dispatch = useDispatch()

  const [candidateProfile] = useSelector((state: RootState) => [
    selectors.candidateProfiles.findByCandidateId(state, props.candidateId),
  ])

  const [candidateEmail, setCandidateEmail] = useState('')

  const updatedEmail = candidateProfile?.fields.email !== candidateEmail
  const invalidEmail = !candidateEmail || !selectors.isEmailValid(candidateEmail)

  useEffect(() => {
    if (candidateProfile?.fields.email) {
      setCandidateEmail(candidateProfile.fields.email)
    }
  }, [candidateProfile])

  return (
    <Modal
      title={`Update ${selectors.possessiveFirstName(candidateProfile?.fields.full_name || '')} email address`}
      open={props.open}
      setOpen={props.setOpen}
    >
      <Container>
        <ContentAndButtons>
          <Content standalone>
            <Subheading>
              Update the email assigned to {candidateProfile?.fields.full_name}, this will also resend the invitation to
              the new email.
            </Subheading>
            <Form>
              <TextField
                error={invalidEmail}
                label="New email address"
                value={candidateEmail}
                placeholder="Enter a valid email address"
                onChange={e => setCandidateEmail(e.target.value)}
              />
            </Form>
          </Content>
          <Buttons
            next={() =>
              updatedEmail ? dispatch(slice.updateEmail(props.candidateId, candidateEmail)) : props.setOpen(false)
            }
            back={() => {}}
            disabled={invalidEmail}
            label={updatedEmail ? 'Update' : 'Done'}
            forwardOnly
          ></Buttons>
        </ContentAndButtons>
      </Container>
    </Modal>
  )
}

export default Component
Component.displayName = 'UpdateEmailModal'
