import { size, style } from 'core'
import React from 'react'

interface WarningHeaderProps {
  children?: React.ReactNode
}

const WarningHeaderStyle = style().grid().spacing({ rows: size.m, innerBottom: size.m }).element()

const WarningHeader: React.FC<WarningHeaderProps> = ({ children }) => {
  if (React.Children.toArray(children).length <= 0) {
    return null
  }

  return <WarningHeaderStyle>{children}</WarningHeaderStyle>
}

export default WarningHeader
