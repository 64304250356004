import action from './action'
import events from './events'
import track from './track'

interface ChipProps {
  chipName: string
  article?: string
}

export const chipViewed = action((props: ChipProps) => {
  track(events.CHIP_VIEWED, {
    'Chip name': props.chipName,
  })
})

export const knowledgeBiteContentViewed = action((props: ChipProps) => {
  track(events.CONTENT_VIEWED, {
    'Chip name': props.chipName,
    'Further Reading Selected': props.article || '',
  })
})

interface ViewProps {
  status: string
  roleName: string
}

export const view = action((props: ViewProps) => {
  track(events.REFERENCE_VIEWED, {
    Status: props.status,
    'Role Name': props.roleName,
  })
})

export const updateEmail = action(() => {
  track(events.REFERENCE_UPDATE_EMAIL, {})
})

export const showMoreAnswers = action(() => {
  track(events.REFERENCE_SHOW_MORE_ANSWERS, {})
})

export const showDetails = action(() => {
  track(events.REFERENCE_SHOW_DETAILS, {})
})

export const deleted = action(() => {
  track(events.REFERENCE_DELETE, {})
})

// SIDEBAR
export const sidebarReferenceFeedbackPageViewed = action(() => {
  track(events.SIDEBAR_REFERENCE_CHECK_VIEWED, {})
})

export const sidebarCreateReferenceCheckClicked = action(() => {
  track(events.SIDEBAR_CREATE_REFERENCE_CHECK_CLICKED, {})
})

export const aiCustomQuestionAdded = action(() => {
  track(events.AI_TOOLS_CUSTOM_QUESTION_ADDED, {})
})
