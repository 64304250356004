import { get } from 'api/methods'
import { useEffect, useState } from 'react'

export type InstallMethod = 'oauth' | 'link-token' | 'greenhouse-api-key' | 'docs' | 'api-key-basic'

export interface ATS {
  name: string
  slug: string
  square_image: string
  colour: string
  docs_link?: string
  installMethod?: InstallMethod
}
export function useATS(orgId: string) {
  const [combinedATSMap, setCombinedATSMap] = useState<{
    [key: string]: ATS
  }>(ats)

  useEffect(() => {
    if (!orgId) return
    ;(async () => {
      const [response] = await get<{}, ATS[]>(`/organizations/${orgId}/merge/integrations`)
      if (!response?.meta) return
      const extra: { [key: string]: ATS } = {}
      for (const ats of response.meta) extra[ats.slug] = ats

      setCombinedATSMap(m => ({ ...m, ...extra }))
    })()
  }, [orgId])

  return combinedATSMap
}

const ats: { [key: string]: ATS } = {
  // lever
  lever: {
    name: 'Lever',
    slug: 'lever',
    square_image: 'https://merge-api-production.s3.amazonaws.com/media/Lever_Square_Logo_oYg8yBW.jpg',
    colour: '#262A34',
    installMethod: 'oauth',
  },

  // greenhouse
  greenhouse: {
    name: 'Greenhouse',
    slug: 'greenhouse',
    square_image: 'https://merge-api-production.s3.amazonaws.com/media/Greenhouse_Square_Logo.jpg',
    colour: '#037A56',
    installMethod: 'docs',
    docs_link: 'https://intercom.help/hipeople/en/articles/7956484-integrations-developer-resources',
  },

  // Cornerstone TalentLink
  'cornerstone-talentlink': {
    name: 'Cornerstone TalentLink',
    slug: 'cornerstone-talentlink',
    square_image: 'https://merge-api-production.s3.amazonaws.com/media/Cornerstone_Square_Logo.jpg',
    colour: '#0008FF',
    installMethod: 'api-key-basic',
  },
}

export default ats
