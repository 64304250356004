import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { add as notify } from 'store/notifications'
import { samlsso, sessions } from '../../api'
import { AppThunk } from '../../core/store'
import { addEntities } from '../resources'
import { newSession } from '../sessions'

const EMAIL_STORAGE_KEY = 'session:email'
const FULLNAME_STORAGE_KEY = 'login:fullname'

interface State {
  email: string
  fullname: string
  passwd: string
  loading: boolean
  success: boolean
  ssoLoginURL: string
}

const name = 'login'
const initialState: State = {
  email: localStorage.getItem(EMAIL_STORAGE_KEY) || '',
  fullname: localStorage.getItem(FULLNAME_STORAGE_KEY) || '',
  passwd: '',
  loading: false,
  success: false,
  ssoLoginURL: '',
}

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setEmail(state, action: PayloadAction<string>) {
      state.email = action.payload
    },
    setFullname(state, action: PayloadAction<string>) {
      state.fullname = action.payload
    },
    setPasswd(state, action: PayloadAction<string>) {
      state.passwd = action.payload
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setSuccess(state, action: PayloadAction<boolean>) {
      state.success = action.payload
    },
    setSSOLoginURL(state, action: PayloadAction<string>) {
      state.ssoLoginURL = action.payload
    },
  },
})

export const { setEmail, setFullname, setPasswd, setLoading, setSuccess, setSSOLoginURL } = slice.actions

export const login =
  (email?: string, password?: string, type?: string, code?: string): AppThunk =>
  async dispatch => {
    dispatch(setLoading(true))

    const [response, errors] = await sessions.login(email, password, type, code)
    dispatch(setLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    if (response) {
      dispatch(newSession(response.result))
      dispatch(addEntities(response))
    }

    dispatch(setSuccess(true))
  }

export const fetchSSO =
  (email: string): AppThunk =>
  async dispatch => {
    dispatch(setLoading(true))
    const [response, errors] = await samlsso.loadByEmailDomain(email)
    dispatch(setLoading(false))

    if (errors) {
      return
    }

    if (response) {
      dispatch(addEntities(response))
      if (response.result.fields.id) {
        dispatch(fetchSSOLoginURL(response.result.fields.id))
      }
    }
  }

const fetchSSOLoginURL =
  (samlssoID: string): AppThunk =>
  async dispatch => {
    dispatch(setLoading(true))
    const [response, errors] = await samlsso.loginURL(samlssoID)
    dispatch(setLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    if (response) {
      dispatch(addEntities(response))
      dispatch(setSSOLoginURL(response.result.fields.login_url))
    }
  }

export const logoutSSO =
  (samlssoID: string): AppThunk =>
  async () => {
    await samlsso.logout(samlssoID)
  }

export const remember =
  (email: string, fullName: string): AppThunk =>
  async () => {
    localStorage.setItem(EMAIL_STORAGE_KEY, email)
    localStorage.setItem(FULLNAME_STORAGE_KEY, fullName)
  }

export const forget = (): AppThunk => async dispatch => {
  dispatch(setEmail(''))
  dispatch(setFullname(''))
  dispatch(setSSOLoginURL(''))
  localStorage.setItem(EMAIL_STORAGE_KEY, '')
  localStorage.setItem(FULLNAME_STORAGE_KEY, '')
}

export default slice.reducer
