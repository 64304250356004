import React, { createContext, useContext, forwardRef } from 'react'
import clsx from 'clsx'
import styles from './SplitButton.module.scss'

interface SplitButtonContextType {
  size?: 'sm' | 'md' | 'lg'
  disabled?: boolean
}

const SplitButtonContext = createContext<SplitButtonContextType>({})

interface SplitButtonRootProps extends React.HTMLAttributes<HTMLDivElement> {
  size?: 'sm' | 'md' | 'lg'
  disabled?: boolean
  children: React.ReactNode
}

const SplitButtonRoot = forwardRef<HTMLDivElement, SplitButtonRootProps>(
  ({ size = 'md', disabled = false, className, children, ...props }, ref) => {
    return (
      <SplitButtonContext.Provider value={{ size, disabled }}>
        <div ref={ref} className={clsx(styles.root, className)} {...props}>
          {children}
        </div>
      </SplitButtonContext.Provider>
    )
  },
)

SplitButtonRoot.displayName = 'SplitButtonRoot'

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode
}

const PrimaryButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, children, disabled: buttonDisabled, ...props }, ref) => {
    const { size, disabled: contextDisabled } = useContext(SplitButtonContext)
    const isDisabled = buttonDisabled ?? contextDisabled

    return (
      <button
        ref={ref}
        className={clsx(styles.button, styles.primary, size && styles[size], className)}
        disabled={isDisabled}
        {...props}
      >
        {children}
      </button>
    )
  },
)

PrimaryButton.displayName = 'SplitButtonPrimary'

const SecondaryButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, children, disabled: buttonDisabled, ...props }, ref) => {
    const { size, disabled: contextDisabled } = useContext(SplitButtonContext)
    const isDisabled = buttonDisabled ?? contextDisabled

    return (
      <button
        ref={ref}
        className={clsx(styles.button, styles.secondary, size && styles[size], className)}
        disabled={isDisabled}
        {...props}
      >
        {children}
      </button>
    )
  },
)

SecondaryButton.displayName = 'SplitButtonSecondary'

/**
 * A compound component that creates a split button with primary and secondary actions.
 * The split button consists of a primary button and a secondary button side by side.
 *
 * @example
 * ```tsx
 * <SplitButton.Root size="md">
 *   <SplitButton.Primary onClick={() => console.log('Primary clicked')}>
 *     Save
 *   </SplitButton.Primary>
 *   <SplitButton.Secondary onClick={() => console.log('Secondary clicked')}>
 *     ⌄
 *   </SplitButton.Secondary>
 * </SplitButton.Root>
 * ```
 *
 */
const SplitButton = {
  Root: SplitButtonRoot,
  Primary: PrimaryButton,
  Secondary: SecondaryButton,
}

export { SplitButton }
