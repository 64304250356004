import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { FaGripVertical } from 'react-icons/fa6'
import classes from './DragAndDrop.module.scss'

interface DragAndDropProps<T> {
  /** The data to render. Fully controlled by the parent component */
  data: T[]
  setData: React.Dispatch<React.SetStateAction<T[]>>
  /** If true, the component will use the drag handle inside the render function, otherwise it will be rendered outside */
  dragHandleInside?: boolean
  /** The render function for each item */
  render: (item: T, index: number, DragHandle?: React.ReactNode) => ReactNode
}

export const DragAndDrop = <T extends unknown>(props: DragAndDropProps<T>) => {
  const [list, setList] = useState<T[]>(props.data)

  const dragItemRef = useRef<number | null>(null)
  const [currentIndex, setCurrentIndex] = useState<number | null>(null)

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>, index: number | null) => {
    dragItemRef.current = index
    setCurrentIndex(Number(index))
    setTimeout(() => {
      setCurrentIndex(null)
    }, 0) // this clears the styling of the dragged item
    e.dataTransfer.effectAllowed = 'move'
  }

  const handleDragEnter = React.useCallback(
    (e: React.DragEvent<HTMLDivElement>, newIndex: number) => {
      e.preventDefault()
      if (dragItemRef.current === null || dragItemRef.current === newIndex) {
        return
      }
      // if the item is not dragged onto itself
      setList(reorderArray(dragItemRef.current as number, newIndex))

      dragItemRef.current = newIndex
    },
    [props.data],
  )

  const handleDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    props.setData(list)
  }

  const render = (item: T, index: number) => {
    if (props.dragHandleInside) {
      return props.render(item, index, <FaGripVertical className={classes.dragIcon} />)
    }
    return props.render(item, index)
  }

  useEffect(() => {
    if (props.data !== list) {
      setList(props.data)
    }
  }, [props.data])

  return (
    <div className={classes.dragAndDrop} onDragOver={e => e.preventDefault()} onDrop={handleDragEnd}>
      {list.map((item, index) => (
        <div
          key={index}
          onDragStart={e => handleDragStart(e, index)}
          onDragEnter={e => handleDragEnter(e, index)}
          draggable
          className={[classes.dragItem, currentIndex === index ? classes.dragging : ''].join(' ')}
        >
          {!props.dragHandleInside && <FaGripVertical className={classes.dragIcon} />}
          <div className={classes.dragContent}>{render(item, index)}</div>
        </div>
      ))}
    </div>
  )
}

const reorderArray = (from: number, to: number) => {
  return (oldArray: any[]) => {
    const newList = [...oldArray]
    newList.splice(to, 0, newList.splice(from, 1)[0])
    return newList
  }
}
