import * as api from 'api'
import { AssessmentStatus, EvaluationStatus, HiringStatus, ReferenceCheckStatus } from 'api/candidates'
import { getGroupScoreField, getModuleScoreField } from 'App/OpenJobRole/ColumnSelector'
import { RootState } from 'store'
import { formatDateFromNano } from 'utils/format'
import * as selectors from '../../selectors'

function formatScore(score: number | string, selfAssessmentStatus: string | undefined) {
  return selfAssessmentStatus === 'completed' ? (typeof score === 'number' ? `${score.toFixed(0)}%` : score) : '-'
}

export type ModuleScore = {
  score: string
  entity?: api.modules.Fields
  field: string
}

export type GroupScore = {
  score: string
  entity?: api.groups.Fields
  field: string
}

export interface Candidate {
  id: string
  'candidate_profiles.full_name': string
  score: string
  assessmentCompletedAt: string
  refCheckCompletedAt: string
  reference_check_status?: ReferenceCheckStatus
  assessment_completion_status?: AssessmentStatus
  hiring_status?: HiringStatus
  referenceCheckFraudWarnings?: number
  evaluation_status: EvaluationStatus
  moduleScores: ModuleScore[]
  groupScores: GroupScore[]
}

export function getCandidateTableData(state: RootState, candidateID: string): Candidate | undefined {
  const candidate = selectors.candidates.findById(state, candidateID)
  if (!candidate) return

  const profile = selectors.candidateProfiles.findById(state, candidate.fields.candidate_profile_id)

  const referenceCheckFraudWarnings = getNumberOfRefCheckWarningsNew(state, candidate.id)

  const questionnaireSubmission = selectors.questionnaireSubmissions.findSelfAssessmentByCandidateId(
    state,
    candidate.id,
  )

  if (!questionnaireSubmission) {
    return {
      id: candidate.id,
      'candidate_profiles.full_name': profile.fields.full_name,
      assessmentCompletedAt: '-',
      referenceCheckFraudWarnings: referenceCheckFraudWarnings,
      refCheckCompletedAt: candidate.fields.completed_at > 0 ? formatDateFromNano(candidate.fields.completed_at) : '-',
      score: '-',
      reference_check_status: candidate.fields.reference_check_status,
      assessment_completion_status: undefined,
      hiring_status: candidate.fields.hiring_status,
      evaluation_status: candidate.fields.evaluation_status,
      groupScores: [],
      moduleScores: [],
    }
  }

  const selfAssessmentStatus = questionnaireSubmission.fields.status

  const sortBySlug = (a, b) => {
    return (a.entity?.slug || '').localeCompare(b.entity?.slug || '')
  }

  const groupScores: GroupScore[] = selectors.groupScores
    .findByQuestionnaireSubmission(state, questionnaireSubmission)
    .map(c => ({
      score: formatScore(c.fields.score, selfAssessmentStatus),
      entity: selectors.groups.findById(state, c.fields.group_id)?.fields,
      field: getGroupScoreField(c.fields.group_id),
    }))
    .filter(gs => gs.entity && !gs.entity.properties.exclude_from_overall_score)
    .sort(sortBySlug)

  const moduleScores = selectors.moduleScores
    .findByQuestionnaireSubmission(state, questionnaireSubmission)
    .map(c => {
      const moduleEntity = selectors.modules.findById(state, c.fields.module_id)?.fields

      return {
        score: formatScore(c.fields.score, selfAssessmentStatus),
        entity: moduleEntity,
        excluded: selectors.groups.findById(state, moduleEntity?.group_id || '')?.fields.properties
          ?.exclude_from_overall_score,
        field: getModuleScoreField(c.fields.module_id),
      }
    })
    .filter(ms => ms.entity && !ms.excluded)
    .sort(sortBySlug)

  const submissionScore = selectors.questionnaireSubmissionScores.findByQuestionnaireSubmission(
    state,
    questionnaireSubmission,
  )

  return {
    id: candidate.id,
    'candidate_profiles.full_name': profile.fields.full_name,
    assessmentCompletedAt: questionnaireSubmission.fields.submitted_at
      ? formatDateFromNano(questionnaireSubmission.fields.submitted_at)
      : '-',
    referenceCheckFraudWarnings: referenceCheckFraudWarnings,
    refCheckCompletedAt: candidate.fields.completed_at > 0 ? formatDateFromNano(candidate.fields.completed_at) : '-',
    score: formatScore(submissionScore?.fields.score || '-', selfAssessmentStatus),
    reference_check_status: candidate.fields.reference_check_status,
    assessment_completion_status: questionnaireSubmission?.fields.status,
    hiring_status: candidate.fields.hiring_status,
    evaluation_status: candidate.fields.evaluation_status,
    groupScores: groupScores,
    moduleScores: moduleScores,
  }
}

function getNumberOfRefCheckWarningsNew(state: RootState, candidateId: string) {
  const summary = selectors.referenceCheckSummaries.getByCandidateId(state, candidateId)
  return summary?.fields.fraud_warnings
}
