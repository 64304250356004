import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import * as React from 'react'
import { useState } from 'react'
import { colors, px2rem, rem, scale, size, space, style, weight } from '../../core'
import PhoneTextField from '../PhoneInput'

const DEFAULT_MULTILINE_HEIGHT = '5rem'

interface IProps {
  value: string
  change?: string
  label?: string
  subLabel?: string | React.ReactNode
  error?: boolean
  errorLabel?: string | React.ReactNode
  disabled?: boolean
  placeholder?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void
  onEnter?: () => void
  type?: 'text' | 'email' | 'number' | 'password' | 'tel' | 'url'
  autoFocus?: boolean
  autocomplete?: boolean
  multiline?: boolean
  height?: string
  noresize?: boolean
  phone?: boolean
  children?: React.ReactNode
  icon?: string
  testId?: string
  isFullWidth?: boolean
}

const Container = style()
  .cond((props: IProps) => !!props.isFullWidth, style().size({ width: size.stretch }))
  .select(
    'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button',
    style().spacing({ outer: '0' }).set('WebKitAppearance', 'none'),
  ) // removes chrome spinners for input type 'number'
  .select('input[type=number]', style().set('MozAppearance', 'textfield')) // removes arrows for input type 'number' in firefox
  .element()

const PasswordToggle = style()
  .absolute({ right: px2rem(12) })
  .element()

const ErrorStyle = style()
  .border({ color: designSystemColors.uiStatusError })
  .select(':hover', style().border({ color: designSystemColors.uiStatusError }))
  .select(
    ':focus-within',
    style()
      .border({ color: designSystemColors.uiStatusError })
      .set(
        'boxShadow',
        'inset 0 0 2px rgb(45, 89, 66, 0.14), inset 0 0 0 1px rgb(235, 110, 110), 0 0 0 3px rgb(235, 110, 110, 0.14)',
      ),
  )
export const TextFieldBase = style()
  .flex({ alignItems: 'center' })
  .relative()
  .cond((props: IProps) => !props.multiline, style().size({ height: rem(3.1) }))
  .with<IProps>((props: IProps) => style().size({ height: props.height }))
  .border({ around: '1px solid', color: colors.lightMoonGray })
  .round('8px')
  .select(
    ':focus-within',
    style()
      .border({ color: colors.purple })
      .set(
        'boxShadow',
        'inset 0 0 2px var(--brand-primary-background-accent),inset 0 0 0 1px var(--brand-primary),0 0 0 3px var(--brand-primary-background-accent)',
      ),
  )
  .bg({ color: colors.white })
  .select(':focus-within input', style().border({ color: colors.darkGray }))
  .select(':focus-within label', style().fg(colors.black))
  .cond((props: IProps) => !props.disabled, style().select(':hover', style().border({ color: colors.purple })))
  .cond((props: IProps) => !!props.error, ErrorStyle)
  .cond((props: IProps) => !!props.icon, style().spacing({ innerLeft: size.m }))
  .cond(
    (props: IProps) => props.type === 'number',
    style()
      .select('::-webkit-outer-spin-button', style().spacing({ outer: '0' }).set('WebkitAppearance', 'none'))
      .select('::-webkit-inner-spin-button', style().spacing({ outer: '0' }).set('WebkitAppearance', 'none')),
  )

const TextFieldElement = TextFieldBase.element()

const InputStyle = style()
  .noborders()
  .nooutline()
  .stretch()
  .round('8px')
  .sans({ size: scale.s, color: colors.midGray, height: scale.m })
  .spacing({ inner: space.xs })
  .bg({ color: colors.transparent })
  .select(':focus::-webkit-input-placeholder', style().fg(colors.moonGray))
  .cond(
    (props: IProps) => props.disabled || false,
    style().bg({ color: colors.nearWhite }).border({ color: colors.lightGray }),
  )
  .cond((props: IProps) => !!props.error, ErrorStyle)

const Input = InputStyle.clone().element('input')
const Textarea = InputStyle.clone()
  .with<IProps>((props: IProps) => style().size({ height: props.height || DEFAULT_MULTILINE_HEIGHT }))
  .set('resize', 'vertical')
  .cond((props: IProps) => props.noresize || false, style().set('resize', 'none'))
  .element('textarea')

export const BaseLabel = style().fg(colors.darkGray).sans({ size: scale.s, weight: weight.normal }).easein(0.1)

export const Label = BaseLabel.clone()
  .block()
  .spacing({ outerBottom: px2rem(12) })
  .element('label')

const SubLabel = text
  .smallBody()
  .spacing({ outerTop: px2rem(8) })
  .fg(designSystemColors.typographySecondary)
  .element()

const ErrorLabel = text
  .smallBody()
  .whitespace('pre-wrap')
  .spacing({ outerTop: px2rem(8) })
  .fg(designSystemColors.uiStatusError)
  .element()

const Icon = style()
  .absolute({ top: 'calc(50% - 0.5rem)', left: space.xs })
  .text({ size: scale.s, color: colors.moonGray })
  .element('i')

const TextField: React.FC<IProps> = props => {
  const value = props.change === undefined ? props.value : props.change
  const isPassword = props.type === 'password'
  const [showPassword, setShowPassword] = useState(false)
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  // Warning: Unknown event handler property `onEnter`. It will be ignored. at label
  const labelProps = { ...props }
  delete labelProps.onEnter

  return (
    <Container isFullWidth={props.isFullWidth}>
      {props.label && <Label {...labelProps}>{props.label}</Label>}
      <TextFieldElement
        className="data-hj-suppress"
        label={props.label}
        icon={props.icon}
        disabled={props.disabled}
        multiline={props.multiline}
        error={props.error}
        height={props.height}
      >
        {props.multiline ? (
          <Textarea
            type={props.type || 'text'}
            autoFocus={props.autoFocus}
            placeholder={props.placeholder}
            value={value}
            disabled={props.disabled}
            height={props.height}
            noresize={props.noresize}
            onChange={props.onChange}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            onKeyUp={onKeyUp}
            autocomplete={props.autocomplete === false && props.type === 'password' ? 'new-password' : 'on'}
            data-testid={props.testId}
          />
        ) : props.phone ? (
          <PhoneTextField defaultCountry={'us'} enableSearch={true} value={props.value} onChange={props.onChange} />
        ) : (
          <Input
            type={!showPassword || (props.type && props.type !== 'password') ? props.type : 'text'}
            autoFocus={props.autoFocus}
            placeholder={props.placeholder}
            value={value}
            disabled={props.disabled}
            onChange={props.onChange}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            onKeyUp={onKeyUp}
            autocomplete={props.autocomplete === false && props.type === 'password' ? 'new-password' : 'on'}
            data-testid={props.testId}
          />
        )}
        {isPassword && (
          <PasswordToggle onClick={togglePasswordVisibility}>
            {showPassword ? <i className="far fa-eye-slash"></i> : <i className="far fa-eye"></i>}
          </PasswordToggle>
        )}
        {props.icon ? (
          <Icon>
            <i className={'fas fa-' + props.icon} />
          </Icon>
        ) : null}
        {props.children}
      </TextFieldElement>
      {props.subLabel ? <SubLabel>{props.subLabel}</SubLabel> : null}
      {props.errorLabel && <ErrorLabel>{props.errorLabel}</ErrorLabel>}
    </Container>
  )

  function onKeyUp(event: React.KeyboardEvent<HTMLInputElement>) {
    if (props.onKeyUp) {
      props.onKeyUp(event)
    }

    if (props.onEnter && event.key === 'Enter') {
      props.onEnter()
    }
  }
}

export default TextField
