import action from './action'
import events from './events'
import track from './track'

export const documentationButtonClicked = action(() => {
  track(events.CONTENT_SELECTION_DOCUMENTATION_BUTTON_CLICKED, {})
})

export const addTestsButtonClicked = action(() => {
  track(events.CONTENT_SELECTION_ADD_TESTS_BUTTON_CLICKED, {})
})

export const addTextQuestionButtonClicked = action(() => {
  track(events.CONTENT_SELECTION_ADD_TEXT_QUESTION_BUTTON_CLICKED, {})
})

export const addSelectionQuestionButtonClicked = action(() => {
  track(events.CONTENT_SELECTION_ADD_SELECTION_QUESTION_BUTTON_CLICKED, {})
})

export const addVideoQuestionButtonClicked = action(() => {
  track(events.CONTENT_SELECTION_ADD_VIDEO_QUESTION_BUTTON_CLICKED, {})
})

export const editCustomQuestionButtonClicked = action(() => {
  track(events.CONTENT_SELECTION_EDIT_CUSTOM_QUESTION_BUTTON_CLICKED, {})
})

export const removeButtonClicked = action(() => {
  track(events.CONTENT_SELECTION_REMOVE_BUTTON_CLICKED, {})
})

export const modalCancelClicked = action(() => {
  track(events.CONTENT_SELECTION_MODAL_CANCEL_CLICKED, {})
})

export const modalValidateSelectionClicked = action(() => {
  track(events.CONTENT_SELECTION_MODAL_VALIDATE_SELECTION_CLICKED, {})
})

export const modalGroupFilterClicked = action((groupName: string) => {
  track(events.CONTENT_SELECTION_MODAL_GROUP_FILTER_CLICKED, {
    GroupName: groupName,
  })
})

export const modalCloseClicked = action(() => {
  track(events.CONTENT_SELECTION_MODAL_CLOSE_CLICKED, {})
})

export const openPreviewButtonClicked = action(() => {
  track(events.CONTENT_SELECTION_OPEN_PREVIEW_BUTTON_CLICKED, {})
})

export const libraryItemToggleClicked = action((libraryItemName: string) => {
  track(events.CONTENT_SELECTION_LIBRARY_ITEM_TOGGLE_CLICKED, {
    LibraryItemName: libraryItemName,
  })
})
export const aiLibraryItemToggleClicked = action((libraryItemName: string) => {
  track(events.CONTENT_SELECTION_AI_LIBRARY_ITEM_TOGGLE_CLICKED, {
    LibraryItemName: libraryItemName,
  })
})

export const aiContentSelectionReloadClicked = action(() => {
  track(events.CONTENT_SELECTION_AI_LIBRARY_ITEM_TOGGLE_CLICKED, {})
})

export const libraryItemsSearched = action((searchQuery: string) => {
  track(events.AI_TOOLS_CONTENT_SELECTION_RELOAD_BUTTON_CLICKED, {
    SearchQuery: searchQuery,
  })
})

export const confirmClicked = action(() => {
  track(events.CONTENT_SELECTION_CONFIRM_CLICKED, {})
})
