import { scale, space, style } from '../../core'

export default function (columnSizing: string[]) {
  return style()
    .relative()
    .grid({ columns: columnSizing })
    .spacing({ columns: space.none })
    .sans({ size: scale.s })
    .element()
}
