import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useParams } from 'react-router-dom'
import Button from '../../../components/Button/ButtonV1'
import TextField from '../../../components/TextField'
import { FocusHeading as Heading, Subheading } from '../../../components/Typography'
import * as selectors from '../../../selectors'
import { RootState } from '../../../store'
import { send } from '../../../store/one-time-password'
import OTPLayout from './OTPLayout'

const StepRequest = () => {
  const dispatch = useDispatch()
  const { otpid } = useParams<{ otpid: string }>()

  const otp = useSelector((state: RootState) => selectors.oneTimePassword.getById(state, otpid || ''))
  const loading = useSelector((state: RootState) => state.oneTimePassword.loading)
  const sent = useSelector((state: RootState) => state.oneTimePassword.sent)

  if (sent) {
    return <Redirect to={`/shared/otp/${otpid}/verify`} />
  }

  return (
    <OTPLayout>
      <Heading center>Request PIN</Heading>
      <Subheading center>We'll send an access PIN to the following email</Subheading>
      <TextField disabled value={otp?.fields?.email || 'Loading...'} />
      <Button
        onClick={() => {
          dispatch(send(otpid || ''))
        }}
        primary
        disabled={loading}
      >
        Send me a PIN
      </Button>
    </OTPLayout>
  )
}

export default StepRequest
