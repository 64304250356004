import classes from './Role.module.scss'
import { CandidateListingQuery } from 'api/candidates'
import { EditTemplateModal } from 'App/RoleCreation/EditTemplateModal'
import { Preview } from 'App/RoleCreation/PreviewTable'
import { PreviewTemplateModal } from 'App/RoleCreation/PreviewTemplateModal'
import { ButtonContent, Button as ButtonV2 } from 'components/Button/ButtonV2'
import { Icon } from 'components/Icons'
import * as tracking from 'core/track'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import {
  AnalyticsTabs,
  Badge,
  Button,
  LanguageMap,
  LockedByPlan,
  Modal,
  RoleAnalytics,
  Tooltip,
  UsersIcons,
  Link,
  Text,
  PopoverMenu,
  PageHeader,
} from '@common/components'
import * as api from 'api'
import { SortOrder } from 'api/listing'
import { Product } from 'api/templates'
import { CanManageRole } from 'App/AccessLevel'
import CandidatesTable, { LazyTableState } from 'App/OpenJobRole/CandidatesTable'
import { defaultFilters } from 'App/OpenJobRole/filters'
import { useConfirm } from 'components/Confirm'

import { Button as ButtonOld, Separator } from 'components/Popup'
import { AppDispatch, useAppDispatch } from 'core/store'
import { lockedContentTracking } from 'core/track/locked-content'
import { isPresent } from 'core/utils'
import { useFeatureFlag } from 'providers/features'
import * as selectors from 'selectors'
import { RootState } from 'store'
import * as openjobroles from 'store/openjobroles'
import { addEntities } from 'store/resources'
import { tableFiltersToApiFilters } from 'utils/table_filters'
import * as slice from '../../store/new-role'
import InviteCandidateMenu from './InviteCandidate/InviteCandidateMenu'
import { ModuleList } from './ModuleList'
import { Button as CommonButton } from '@common/components'

export type TemplateProps = {
  id: string
  templateName: string
  canBePreviewed: boolean
  canBeEdited: boolean
}

interface RolePageProps {
  roleName: string
  isReferenceCheckActivated: boolean
  isSelfAssessmentActivated: boolean
  isOnboardingActivated: boolean
  referenceCheckTemplate?: TemplateProps
  selfAssessmentTemplate?: TemplateProps
  onboardingTemplate?: TemplateProps
  candidateExperienceTemplate?: TemplateProps
  onClickInviteCandidateByEmail: () => void
  onClickInviteCandidateByLink: () => void
  isInviteByLinkEnabled: boolean | undefined
  getPreview: (templateId: string) => Preview[]
  hasLegacyReferenceForm: boolean
  isStarred?: boolean
  toggleStarred?: () => void
  setIsInviteViaEmailModalOpen: (v: boolean) => void
  setIsManageUserPermissionsModalOpen: (v: boolean) => void
  setIsUpdateExpiryModalOpen: (v: boolean) => void
  setIsUpdateReferencesModalOpen: (v: boolean) => void
  setIsUpdateTitlesModalOpen: (v: boolean) => void
  setIsATSAutomationModalOpen: (v: boolean) => void
}

export const RolePage: React.FC<RolePageProps> = props => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const confirm = useConfirm()
  const history = useHistory()

  const { roleid: roleId = '' } = useParams<{ roleid: string }>()
  const { user, profile, userHasVerifiedEmail } = useUserProfile()

  const role = useRole({ roleId, dispatch })
  const org = useSelector((state: RootState) => selectors.orgs.getById(state, selectors.orgs.currentId(state)))

  const {
    isReferenceCheckActivated,
    referenceCheckTemplate,
    isSelfAssessmentActivated,
    selfAssessmentTemplate,
    hasLegacyReferenceForm,
  } = props

  const missingReferenceCheckTemplate = isReferenceCheckActivated && !referenceCheckTemplate
  const missingAssessmentTemplate = isSelfAssessmentActivated && !selfAssessmentTemplate

  const allowedToAddCandidates =
    missingReferenceCheckTemplate && !hasLegacyReferenceForm ? false : missingAssessmentTemplate ? false : true

  const inviteMenuButtonRef = useRef<HTMLElement>(null)

  const [isInviteMenuOpen, setInviteMenuOpen] = useState(false)
  const [isUpgradeModalOpen, setUpgradeModalOpen] = useState(false)

  const [candidateIDs, setCandidateIDs] = useState<string[]>([])
  const hasCandidates = candidateIDs.length > 0

  const [totalCandidates, setTotalCandidates] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [lazyState, setLazyState] = useState<LazyTableState>({
    first: 0,
    rows: 10,
    page: 1,
    sortField: 'updated_at',
    sortOrder: 0,
    filters: defaultFilters,
    visibleColumns: undefined,
  })

  const onSetLazyState = (state: LazyTableState) => {
    // add to route state so we can restore it when navigating back
    history.replace({ ...location, state: { lazyState: state } })
    setLazyState(state)
  }

  // restore lazy state from route state on mount
  useEffect(() => {
    const state = location.state as { lazyState?: LazyTableState }

    if (state?.lazyState) {
      setLazyState(state.lazyState)
    }
  }, [])

  useEffect(() => {
    loadCandidates(lazyState, setCandidateIDs, setTotalCandidates, setLoading, dispatch, roleId)
  }, [lazyState])

  async function inviteYourself() {
    tracking.roles.inviteYourself({ name: role.fields.name })

    await dispatch(
      slice.inviteYourself(
        roleId,
        {
          full_name: profile ? profile.fields.full_name : '',
          email: user.fields.email,
        },
        role.fields,
      ),
    )

    loadCandidates(lazyState, setCandidateIDs, setTotalCandidates, setLoading, dispatch, roleId)
  }

  const trackingProps = useSelector((state: RootState) => tracking.roles.extractPropsFromState(state, roleId))
  const isFilterActive = Object.keys(lazyState.filters).some(key => lazyState.filters[key] !== defaultFilters[key])
  const isV2Role = !!(role?.fields.ask_reference_check || role?.fields.ask_self_assessment)

  const { isEnabled: isMergeDevIntegrationEnabled } = useFeatureFlag('merge-dev-integration')

  const roleHasForms =
    !!role?.fields.reference_form_id ||
    !!role?.fields.questionnaire_id ||
    !!role?.fields.reference_check_preset_id ||
    !!role?.fields.self_assessment_preset_id

  const missingFormsRequiredForATS =
    (role?.fields.ask_reference_check &&
      !(
        !!role?.fields.questionnaire_id ||
        !!role?.fields.reference_check_preset_id ||
        !!role?.fields.reference_form_id
      )) ||
    (role?.fields.ask_self_assessment && !role.fields.self_assessment_preset_id)

  const assigned = useSelector((state: RootState) =>
    selectors.openJobRoleUsers
      .findByRoleId(state, role?.id || '')
      .map(r => ({
        user: selectors.users.getById(state, r.fields.user_id),
        profile: selectors.profiles.getByUserId(state, r.fields.user_id),
      }))
      .filter(r => r.profile),
  )

  const [selectedTemplateIdForEdit, setSelectedTemplateIdForEdit] = useState<{
    templateId: string
    type: Product
  } | null>(null)

  const [selectedTemplateIdForPreview, setSelectedTemplateIdForPreview] = useState<string | null>(null)

  const onClickSelectTemplate = (product: Product) => {
    const queryParams = new URLSearchParams()
    queryParams.set('product', product)
    queryParams.set('roleid', roleId)
    queryParams.set('mode', 'single')

    history.push(`/templates?${queryParams.toString()}`)
  }

  const onClickSelectExistingTemplate = (templateId: string) => {
    const queryParams = new URLSearchParams()
    queryParams.set('roleid', roleId)
    queryParams.set('mode', 'edit')
    queryParams.set('templateid', templateId)

    history.push(`/templates/self-assessment?${queryParams.toString()}`)
  }

  const onClickExistingReferenceCheckTemplate = (templateId: string) => {
    const queryParams = new URLSearchParams()
    queryParams.set('roleid', roleId)
    queryParams.set('mode', 'edit')
    queryParams.set('templateid', templateId)

    history.push(`/templates/reference-check?${queryParams.toString()}`)
  }

  const onClickPreviewTemplate = (templateId: string) => setSelectedTemplateIdForPreview(templateId)
  const onClickEditTemplate = (templateId: string, type: Product) => setSelectedTemplateIdForEdit({ templateId, type })

  async function onDelete() {
    if (!role?.id) return

    if (
      await confirm({
        title: 'About to delete a job, continue?',
        message: 'Deleted jobs will not be accessible by candidates anymore.',
        confirmLabel: 'Yes, delete it',
        danger: true,
      })
    ) {
      dispatch(openjobroles.del(role.id))

      if (trackingProps) {
        tracking.roles.archive(trackingProps)
      }
    }
  }

  const [analyticsData, setAnalyticsData] = useState<RoleAnalytics | null>(null)

  useEffect(() => {
    if (!role) return
    api.openjobroles.getRoleAnalytics(role.id).then(response => {
      const roleAnalytics = response[0] as RoleAnalytics | undefined
      setAnalyticsData(roleAnalytics ?? null)
    })
  }, [role.id])

  return (
    <>
      <div className={classes.page}>
        <PageHeader.Root>
          <PageHeader.StartItems>
            <PageHeader.Title data-testid="role-page-title">{props.roleName}</PageHeader.Title>
            <Tooltip text="Job Settings">
              <Button
                variant="icon"
                rounded
                size="sm"
                onClick={() => {
                  trackingProps && tracking.roles.updateRole(trackingProps)
                  history.push(`/roles/${roleId}/edit`)
                }}
              >
                <Icon name="cog" ariaLabel="Preview Template" />
              </Button>
            </Tooltip>
          </PageHeader.StartItems>
          <PageHeader.EndItems>
            {props.isSelfAssessmentActivated && (
              <Tooltip text={'Assessment communications will be sent in this language'} direction="right">
                <Badge variant="white">
                  <i className="fas fa-globe-americas"></i>
                  {role.fields.assessment_locale in LanguageMap && LanguageMap[role.fields.assessment_locale].name}
                </Badge>
              </Tooltip>
            )}

            <Tooltip
              text={props.isStarred ? 'Remove from favorites' : 'Add to favorites'}
              direction="right"
              disabled={props.isStarred === undefined}
            >
              <Button
                variant="icon"
                rounded
                size="sm"
                className={classes.favouriteButton}
                data-starred={props.isStarred}
                onClick={props.toggleStarred}
              >
                <Icon
                  name="star"
                  ariaLabel={props.isStarred ? 'Job is in favorites' : 'Job is not in favorites'}
                  variant={props.isStarred ? 'solid' : 'regular'}
                />
              </Button>
            </Tooltip>
            <UsersIcons
              imgUrls={assigned
                .map(a => a.profile?.fields.profile_picture_url ?? null)
                .filter(isPresent)
                .slice(0, 3)}
            />
            {allowedToAddCandidates && (
              <div className={classes.buttonContainer}>
                <Tooltip
                  text={'Please verify your email address by clicking the link we sent you!'}
                  disabled={userHasVerifiedEmail}
                >
                  <Button variant="secondary" disabled={!userHasVerifiedEmail} onClick={() => inviteYourself()}>
                    <ButtonContent noWrap>{'Invite yourself'}</ButtonContent>
                  </Button>
                </Tooltip>
              </div>
            )}
            {allowedToAddCandidates && (
              <div className={classes.buttonContainer}>
                <Tooltip
                  text={'Please verify your email address by clicking the link we sent you!'}
                  disabled={userHasVerifiedEmail}
                >
                  <Button
                    variant="accent"
                    disabled={!userHasVerifiedEmail}
                    onClick={() => {
                      if (
                        org?.fields.plan === 'self-trial' &&
                        role.fields.ask_reference_check === true &&
                        org?.fields.candidate_quota_left !== undefined &&
                        org?.fields.candidate_quota_left <= 0
                      ) {
                        setUpgradeModalOpen(true)
                      } else {
                        setInviteMenuOpen(true)
                        tracking.roles.inviteCandidates({
                          name: role.fields.name,
                        })
                      }
                    }}
                    data-testid="invite-candidates-button"
                  >
                    <ButtonContent icon={{ name: 'plus', ariaLabel: 'invite candidates' }} noWrap>
                      Invite candidates
                    </ButtonContent>
                  </Button>
                </Tooltip>

                <InviteCandidateMenu
                  isMenuOpen={isInviteMenuOpen}
                  setMenuOpen={setInviteMenuOpen}
                  activatorRef={inviteMenuButtonRef}
                  isInviteByLinkEnabled={props.isInviteByLinkEnabled}
                  onClickInviteByEmail={props.onClickInviteCandidateByEmail}
                  onClickInviteByLink={props.onClickInviteCandidateByLink}
                />
                <Modal
                  open={isUpgradeModalOpen}
                  onClose={() => setUpgradeModalOpen(false)}
                  title={'You have no more free candidates remaining.'}
                >
                  <Text variant="body-text">To invite more candidates to HiPeople:</Text>
                  <div className={classes.buttonRow}>
                    <ButtonV2
                      as="a"
                      href={'https://www.hipeople.io/book-a-demo'}
                      buttonType="accent"
                      target="_blank"
                      onClick={() => tracking.users.talkToProductAdvisorClicked(user.id, user.fields.email)}
                    >
                      Talk to a product advisor
                    </ButtonV2>
                    <Button
                      variant="purple-dark"
                      onClick={() => {
                        window.open('/upgrade')
                        tracking.users.choosePlanTrialExpiredClicked(user.id, user.fields.email)
                      }}
                    >
                      Upgrade to a paid plan
                    </Button>
                  </div>
                </Modal>
              </div>
            )}
            <PopoverMenu
              trigger={
                <CommonButton variant="ghost" data-testid="menu-dashboard-header-button">
                  <Icon name="ellipsis-h" />
                </CommonButton>
              }
              menuContent={
                <div>
                  {role && role.fields.is_active && role.fields.is_paused ? (
                    <CanManageRole roleId={roleId}>
                      <ButtonOld
                        icon="play"
                        onClick={() => {
                          dispatch(openjobroles.start(role?.id))
                          if (trackingProps) {
                            tracking.roles.start(trackingProps)
                          }
                        }}
                      >
                        Start
                      </ButtonOld>
                    </CanManageRole>
                  ) : null}
                  {role && role.fields.is_active && !role.fields.is_paused ? (
                    <CanManageRole roleId={roleId}>
                      <ButtonOld
                        icon="pause"
                        onClick={() => {
                          dispatch(openjobroles.pause(role?.id))
                          if (trackingProps) {
                            tracking.roles.pause(trackingProps)
                          }
                        }}
                      >
                        Pause
                      </ButtonOld>
                    </CanManageRole>
                  ) : null}
                  {role && role.fields.is_active ? (
                    <CanManageRole roleId={roleId}>
                      <ButtonOld icon="archive" onClick={onDelete}>
                        Delete
                      </ButtonOld>
                    </CanManageRole>
                  ) : null}
                  {role && isV2Role ? (
                    <CanManageRole roleId={roleId}>
                      <ButtonOld
                        icon="file-signature"
                        onClick={() => {
                          if (trackingProps) {
                            tracking.roles.updateRole(trackingProps)
                          }
                          history.push(`/roles/${roleId}/edit`)
                        }}
                      >
                        Update job
                      </ButtonOld>
                    </CanManageRole>
                  ) : null}
                  {role && !isV2Role ? (
                    <CanManageRole roleId={roleId}>
                      <ButtonOld
                        icon="file-signature"
                        onClick={() => {
                          props.setIsUpdateTitlesModalOpen(true)
                          if (trackingProps) {
                            tracking.roles.updateTitles(trackingProps)
                          }
                        }}
                      >
                        Update titles
                      </ButtonOld>
                    </CanManageRole>
                  ) : null}
                  {role && !isV2Role && roleHasForms ? (
                    <CanManageRole roleId={roleId}>
                      <ButtonOld
                        icon="vote-yea"
                        onClick={() => {
                          props.setIsUpdateReferencesModalOpen(true)
                          if (trackingProps) {
                            tracking.roles.updateReferences(trackingProps)
                          }
                        }}
                      >
                        Update references
                      </ButtonOld>
                    </CanManageRole>
                  ) : null}
                  {role && !isV2Role ? (
                    <CanManageRole roleId={roleId}>
                      <ButtonOld
                        icon="clock"
                        onClick={() => {
                          props.setIsUpdateExpiryModalOpen(true)
                          if (trackingProps) {
                            tracking.roles.updateExpiryTime(trackingProps)
                          }
                        }}
                      >
                        Update expiry time
                      </ButtonOld>
                    </CanManageRole>
                  ) : null}
                  {role && !isV2Role ? (
                    <CanManageRole roleId={roleId}>
                      <ButtonOld
                        icon="tasks"
                        onClick={() => {
                          props.setIsManageUserPermissionsModalOpen(true)
                          if (trackingProps) {
                            tracking.roles.manageUsers(trackingProps)
                          }
                        }}
                      >
                        Manage users
                      </ButtonOld>
                    </CanManageRole>
                  ) : null}
                  {role && !missingFormsRequiredForATS && (
                    <>
                      <Separator />
                      <LockedByPlan
                        tracking={lockedContentTracking}
                        locked={!isMergeDevIntegrationEnabled}
                        upgradeTo={'Scale'}
                        modalData={{
                          learnMoreAbout: 'Integrating Your ATS',
                          learnMoreLink: 'https://intercom.help/hipeople/en/articles/8915399-integrating-your-ats',
                        }}
                      >
                        <ButtonOld icon="magic" onClick={() => props.setIsATSAutomationModalOpen(true)}>
                          Manage ATS automation
                        </ButtonOld>
                      </LockedByPlan>
                    </>
                  )}
                  <Separator />
                  <ButtonOld
                    icon="life-ring"
                    link="https://intercom.help/hipeople/en/"
                    onClick={() => tracking.users.help()}
                  >
                    Help
                  </ButtonOld>
                </div>
              }
            />
          </PageHeader.EndItems>
        </PageHeader.Root>

        <div className={classes.pageContent}>
          <ModuleList
            isReferenceCheckActivated={props.isReferenceCheckActivated}
            isSelfAssessmentActivated={props.isSelfAssessmentActivated}
            isOnboardingActivated={props.isOnboardingActivated}
            referenceCheckTemplate={props.referenceCheckTemplate}
            selfAssessmentTemplate={props.selfAssessmentTemplate}
            onboardingTemplate={props.onboardingTemplate}
            candidateExperienceTemplate={props.candidateExperienceTemplate}
            onClickPreviewTemplate={onClickPreviewTemplate}
            onClickEditTemplate={onClickEditTemplate}
            onClickSelectTemplate={onClickSelectTemplate}
            onClickSelectExistingTemplate={onClickSelectExistingTemplate}
            onClickExistingReferenceCheckTemplate={onClickExistingReferenceCheckTemplate}
            hasCandidates={hasCandidates}
          />
          {props.isSelfAssessmentActivated && analyticsData && (
            <AnalyticsTabs
              data={analyticsData}
              tracking={{
                engagementClick: tracking.roles.analyticsEngagementClicked,
                performanceClick: tracking.roles.analyticsPerformanceClicked,
              }}
            />
          )}

          {props.referenceCheckTemplate && !props.referenceCheckTemplate?.canBePreviewed && (
            <div className={classes.updateJobNotice}>
              <Icon name="exclamation-circle" />
              <Text variant="body-text">
                To allow full functionality and to ensure your content is of the highest quality please update your job
                now.
                <Link to={`/templates?roleid=${roleId}&product=reference-check&mode=single`}>Update job</Link>
              </Text>
            </div>
          )}
          <section className={classes.candidateSection}>
            {/*
              if there's no candidates, and not allowed to add
              any more – display incomplete role.

              otherwise display candidate list, knowing that the
              add candidate button won't be present if they are not
              allowed to add candidates.
             */}
            {!hasCandidates && !allowedToAddCandidates ? (
              <div className={classes.incompleteRoleWarning}>
                <div className={classes.incompleteRoleWarningIcon}>
                  <Icon name="exclamation-triangle" ariaLabel="Warning" variant="regular" />
                </div>
                <h3 className={classes.incompleteRoleWarningTitle}>Almost ready!</h3>
                <p className={classes.incompleteRoleWarningDescription}>
                  Please specify the surveys template on the side before adding candidates
                </p>
              </div>
            ) : (
              <>
                {!hasCandidates && !isFilterActive ? (
                  <div className={classes.candidatePlaceholder}>
                    <img className={classes.candidateImage} src="/assets/woman_on_laptop.png" alt="woman on laptop" />
                    <div>
                      <h3 className={classes.candidateSubject}>
                        Invite your first candidates and quickly discover the ideal fit for your needs!
                      </h3>
                      <p className={classes.candidateBody}>
                        Click the buttons on the top to try the flow yourself or invite your first candidate.
                      </p>
                    </div>
                  </div>
                ) : (
                  <CandidatesTable
                    role={role.fields}
                    isReferenceCheckActivated={props.isReferenceCheckActivated}
                    isSelfAssessmentActivated={props.isSelfAssessmentActivated}
                    loadCandidates={() =>
                      loadCandidates(lazyState, setCandidateIDs, setTotalCandidates, setLoading, dispatch, roleId)
                    }
                    candidates={candidateIDs}
                    total={totalCandidates}
                    loading={loading}
                    setLazyState={onSetLazyState}
                    lazyState={lazyState}
                    selfAssessmentPresetId={props.selfAssessmentTemplate?.id || ''}
                  />
                )}
              </>
            )}
          </section>
        </div>
      </div>

      {selectedTemplateIdForEdit && (
        <EditTemplateModal
          close={() => setSelectedTemplateIdForEdit(null)}
          roleId={roleId}
          templateId={selectedTemplateIdForEdit.templateId}
          type={selectedTemplateIdForEdit.type}
        />
      )}

      {selectedTemplateIdForPreview && (
        <PreviewTemplateModal
          close={() => setSelectedTemplateIdForPreview(null)}
          preview={props.getPreview(selectedTemplateIdForPreview)}
        />
      )}
    </>
  )
}

function loadCandidates(
  lazyState: LazyTableState,
  setApiCandidates: React.Dispatch<React.SetStateAction<string[]>>,
  setTotal: React.Dispatch<React.SetStateAction<number>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  dispatch: AppDispatch,
  roleId: string,
) {
  setLoading(true)

  const query = {
    limit: lazyState.rows,
    skip: lazyState.first,
    ...{
      sort: lazyState.sortField
        ? [
            {
              field: lazyState.sortField,
              order: lazyState.sortOrder === 1 ? SortOrder.Asc : SortOrder.Desc,
            },
          ]
        : undefined,
    },
    roleId: roleId,
    filters: tableFiltersToApiFilters(lazyState.filters),
    include: [
      api.candidateProfiles.RESOURCE,
      api.questionnaireSubmissions.RESOURCE,
      api.referenceCheckSummaries.RESOURCE,
      api.groupScores.RESOURCE,
      api.moduleScores.RESOURCE,
      api.modules.RESOURCE,
      api.questionnaireSubmissionsScores.RESOURCE,
    ],
  } satisfies CandidateListingQuery

  api.candidates.fetchRoleCandidates(query).then(response => {
    setLoading(false)
    const [resp, errors] = response
    if (errors) {
      throw errors
    }
    if (!resp?.result) return
    dispatch(addEntities(resp))

    setApiCandidates(resp.result.map(r => r.id))
    setTotal(resp.list?.total || 0)
  })
}

function useRole({ roleId, dispatch }: { roleId: string; dispatch: AppDispatch }) {
  const role = useSelector((state: RootState) => selectors.roles.findById(state, roleId))

  useEffect(() => {
    if (!role) {
      dispatch(openjobroles.fetch(roleId))
    }
  }, [roleId, role, dispatch])

  return role
}

function useUserProfile() {
  const user = useSelector((state: RootState) => selectors.users.current(state))
  const profile = useSelector((state: RootState) => selectors.profiles.getByUserId(state, user.id))
  const userHasVerifiedEmail = user.fields.verified_at > 0

  return { user, profile, userHasVerifiedEmail }
}
