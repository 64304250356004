import { colors, rem, scale, space, style, weight } from '../../core'

export default style()
  .color({ bg: colors.transparent })
  .sans({ size: scale.m, weight: weight.normal, height: rem(2) })
  .element()

export const TitleRow = style()
  .grid({ columns: [space.auto, space.auto], align: 'center' })
  .spacing({
    outer: [rem(0.8), space.none, rem(0.7), space.none],
    inner: space.none,
  })
  .element()
