import { Tooltip } from '@common/components'
import { TooltipContent } from 'components/Tooltip'
import { px2rem, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React from 'react'

import { Reference, Relationship } from 'selectors/references'

const Title = text
  .smallBody()
  .sans({ uppercase: true })
  .color({ fg: designSystemColors.typographySecondary })
  .element('h3')

const AttributeNameOverlay = text
  .bodyEmphasis()
  .color({
    fg: designSystemColors.typographyPrimary,
    bg: designSystemColors.backgroundNeutralPrimary,
  })
  .set('boxShadow', '0px 1px 5px rgba(0, 0, 0, 0.19)')
  .border({ radius: px2rem(4) })
  .spacing({ inner: [px2rem(4), px2rem(6)] })
  .element('p')
const AttributeName = text
  .bodyEmphasis()
  .sans({ ellipsis: true })
  .color({ fg: designSystemColors.typographyPrimary })
  .spacing({ inner: [px2rem(4), px2rem(0)] })
  .select(`${AttributeNameOverlay}`, style().invisible())
  .select(`:hover ${AttributeNameOverlay}`, style().visible())
  .element('p')
const SelfAssessmentTooltipContent = text
  .smallBody()
  .color({ fg: designSystemColors.backgroundNeutralPrimary })
  .sans({ noWrap: true })
  .block()
  .element('p')
const SelfAssessmentLabel = text
  .label()
  .flex({ alignItems: 'center', justifyContent: 'center' })
  .size({ width: px2rem(20), height: px2rem(20) })
  .border({ radius: '50%' })
  .color({
    fg: designSystemColors.informationRedPrimary,
    bg: designSystemColors.informationRedBackground,
  })
  .relative()
  .cursor('default')
  .select(
    `${TooltipContent.Style}`,
    style().absolute({ top: 0, left: '50%' }).invisible().set('transform', 'translateX(-50%) translateY(-100%)'),
  )
  .select(`:hover ${TooltipContent.Style}`, style().visible())
  .element('span')
const AttributeNameAndSelfAssessmentLabelContainer = style()
  .flex({
    alignItems: 'center',
    justifyContent: 'space-between',
  })
  .select(`${SelfAssessmentLabel}`, style().set('flexShrink', '0').set('flexGrow', '0'))
  .relative()
  .cursor('default')
  .select(
    `${AttributeNameOverlay}`,
    style()
      .absolute({ top: 0, left: px2rem(-6) })
      .front(),
  )
  .element()

const RelationshipLabel = text
  .label()
  .color({
    bg: designSystemColors.backgroundNeutralSecondary,
  })
  .size({ height: px2rem(20) })
  .flex({ alignItems: 'center', justifyContent: 'center' })
  .element('span')
const ManagerRelationshipLabel = style(RelationshipLabel)
  .color({
    bg: designSystemColors.informationOrangeBackground,
    fg: designSystemColors.informationOrangePrimary,
  })
  .element('span')
const PeerRelationshipLabel = style(RelationshipLabel)
  .color({
    bg: designSystemColors.informationPurpleBackground,
    fg: designSystemColors.informationPurplePrimary,
  })
  .element('span')
const ReportRelationshipLabel = style(RelationshipLabel)
  .color({
    bg: designSystemColors.informationBlueBackground,
    fg: designSystemColors.informationBluePrimary,
  })
  .element('span')
const StudentPeerRelationshipLabel = style(RelationshipLabel)
  .color({
    bg: designSystemColors.informationRedBackground,
    fg: designSystemColors.informationRedPrimary,
  })
  .element('span')
const AnyRelationshipLabel = style(RelationshipLabel)
  .color({
    bg: designSystemColors.informationBlueBackground,
    fg: designSystemColors.informationBluePrimary,
  })
  .element('span')
const ClientRelationshipLabel = style(RelationshipLabel)
  .color({
    bg: designSystemColors.informationGreenBackground,
    fg: designSystemColors.informationGreenPrimary,
  })
  .element('span')
const LabelContainer = style()
  .grid({ columns: `repeat(auto-fit, minmax(0, 1fr))` })
  .spacing({ gap: px2rem(2) })
  .border({ radius: px2rem(4) })
  .element('div')

const PickedByNumberOfReferences = style()
  .sans({
    weight: '600',
  })
  .element('span')
const NumberOfReferences = style().element('span')
const Tally = text
  .bodyText()
  .sans({
    align: 'right',
  })
  .color({
    fg: designSystemColors.typographySecondary,
  })
  .element('p')

const AttributeRow = style()
  .grid({
    align: 'center',
    columns: `minmax(auto, ${px2rem(128)}) minmax(0, 1fr) minmax(${px2rem(52)}, auto)`,
  })
  .spacing({ gap: px2rem(4) })
  .element('li')
const Attributes = style()
  .grid()
  .spacing({ gap: px2rem(18) })
  .list({ style: 'none' })
  .nospacing()
  .element('ul')

const AttributeBreakdownBlockStyle = style()
  .select(`${Title}`, style().spacing({ outerBottom: px2rem(16) }))
  .element()

interface RelationshipLabelExceptSelfProps {
  reference: Reference
}

const RelationshipLabelExceptSelf: React.FC<RelationshipLabelExceptSelfProps> = ({ reference }) => {
  switch (reference.relationship) {
    case Relationship.Client: {
      return <ClientRelationshipLabel>{'C'}</ClientRelationshipLabel>
    }
    case Relationship.Manager: {
      return <ManagerRelationshipLabel>{'M'}</ManagerRelationshipLabel>
    }
    case Relationship.Peer: {
      return <PeerRelationshipLabel>{'P'}</PeerRelationshipLabel>
    }
    case Relationship.Report: {
      return <ReportRelationshipLabel>{'DR'}</ReportRelationshipLabel>
    }
    case Relationship.StudentPeer: {
      return <StudentPeerRelationshipLabel>{'S'}</StudentPeerRelationshipLabel>
    }
    case Relationship.Any: {
      return <AnyRelationshipLabel>{'A'}</AnyRelationshipLabel>
    }
    case Relationship.Self: {
      return null
    }
    default:
      throw new Error(`Undefined relationship type: ${reference.relationship}`)
  }
}

type AttributeBreakdown = {
  name: string
  pickedBy: Reference[]
}
export interface AttributeBreakdownBlockProps {
  title: string
  attributes: AttributeBreakdown[]
  numberOfReferences: number
}

export const AttributeBreakdownBlock: React.FC<AttributeBreakdownBlockProps> = ({
  title,
  attributes,
  numberOfReferences,
}) => {
  return (
    <AttributeBreakdownBlockStyle>
      <Title>{title}</Title>
      <Attributes>
        {attributes.map(({ name, pickedBy }, i) => {
          const isPickedBySelf = pickedBy.some(reference => reference.relationship === Relationship.Self)
          const pickedByNumberOfReferencesExceptSelf = pickedBy.filter(
            reference => reference.relationship !== Relationship.Self,
          ).length
          const numberOfReferencesWhoDoNotPickTheAttribute = numberOfReferences - pickedByNumberOfReferencesExceptSelf

          return (
            <AttributeRow key={`attribute-${i}`}>
              <AttributeNameAndSelfAssessmentLabelContainer>
                <AttributeName>
                  <AttributeNameOverlay>{name}</AttributeNameOverlay>
                  {name}
                </AttributeName>
                {isPickedBySelf && (
                  <SelfAssessmentLabel>
                    <TooltipContent arrowPosition="bottom">
                      <SelfAssessmentTooltipContent>{'Candidate self-assessment'}</SelfAssessmentTooltipContent>
                    </TooltipContent>
                    {'C'}
                  </SelfAssessmentLabel>
                )}
              </AttributeNameAndSelfAssessmentLabelContainer>
              <LabelContainer>
                {pickedBy.map((reference, i) => (
                  <Tooltip
                    key={`reference-${i}`}
                    text={reference.fullName}
                    disabled={reference.relationship === Relationship.Self}
                  >
                    <RelationshipLabelExceptSelf reference={reference} />
                  </Tooltip>
                ))}
                {Array.from({
                  length: numberOfReferencesWhoDoNotPickTheAttribute,
                }).map((_, i) => (
                  <RelationshipLabel key={`dummy-relationship-label-${i}`} />
                ))}
              </LabelContainer>
              <Tally>
                <PickedByNumberOfReferences>{pickedByNumberOfReferencesExceptSelf}</PickedByNumberOfReferences>
                <NumberOfReferences>{` / ${numberOfReferences}`}</NumberOfReferences>
              </Tally>
            </AttributeRow>
          )
        })}
      </Attributes>
    </AttributeBreakdownBlockStyle>
  )
}

// Sorts the attribute breakdowns initially by their length.
// if they have matching lengths order by most managers
// if they have matching managers by the one with the fewest self-assessments selection
export function sortBreakdowns(pickedBy1: Reference[], pickedBy2: Reference[]): number {
  if (pickedBy2.length !== pickedBy1.length) {
    return pickedBy2.length - pickedBy1.length
  }

  const managerCount1 = pickedBy1.filter(a => a.relationship === Relationship.Manager).length
  const managerCount2 = pickedBy2.filter(a => a.relationship === Relationship.Manager).length
  if (managerCount1 !== managerCount2) {
    return managerCount2 - managerCount1
  }

  return (
    pickedBy1.filter(a => a.relationship === Relationship.Self).length -
    pickedBy2.filter(a => a.relationship === Relationship.Self).length
  )
}

export function sortReferences(ref1: Reference, ref2: Reference): number {
  if (ref1.relationship > ref2.relationship) return 1
  if (ref1.relationship < ref2.relationship) return -1
  return 0
}
