import { ScoreResponse } from '@common/api/score'
import { Card, Group, MarkdownText, ScoreExplanation, ScoreGroup, Text } from '@common/components'
import { cleanMd } from '@common/utils/markdown'
import * as api from 'api'
import { Fields as ModuleFields } from 'api/modules'
import { QuestionEntity } from 'api/questions'
import { Entity } from 'api/request'
import { DISC_EXPLANATIONS } from 'App/Candidate/SelfAssessment/hardcoded_disc_explanations'
import { Icon } from 'components/Icons'
import { designSystemColors } from 'core/design-system/colors'
import { hasPresentKey, isPresent } from 'core/utils'
import Markdown from 'markdown-to-jsx'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import * as selectors from 'selectors'
import { AssessmentModuleAnswers } from 'selectors/self-assessment'
import { RootState } from 'store'
import { groupBy } from 'utils/collections'
import classes from './PersonalityDashboard.module.scss'
import {
  PersonalityModuleScoringProps,
  ScoreExplanationContainer,
  ScoreExplanationTitle,
} from './PersonalityScoreSectionCard'

interface PersonalityScoreCardUIProps {
  inventoryToModuleScorePropsMap: Map<string | undefined, PersonalityModuleScoringProps[]>
  isSharedResults: boolean
  personalityScores: ScoreResponse['meta'][number] | undefined
  candidateFirstName: string
  roleId: string
  candidateId: string
  defaultCollapsed?: boolean
  onResponsesClick: (moduleSlug: string, group: Group) => void
}

const PersonalityScoreCardUI: React.FC<PersonalityScoreCardUIProps> = props => {
  const inventories = useSelector((state: RootState) =>
    Array.from(props.inventoryToModuleScorePropsMap.keys())
      .map(id => selectors.inventories.findById(state, id || ''))
      .filter(isPresent),
  )

  const discInventory = inventories.find(inventory => inventory.fields.slug === 'disc')

  const disc = useMemo(() => {
    if (discInventory) {
      return props.inventoryToModuleScorePropsMap.get(discInventory.id)
    }
  }, [props.inventoryToModuleScorePropsMap, discInventory])

  const getModuleData = (score: ScoreResponse['meta'][number]) => {
    const isDisc = score.slug === 'disc'
    if (isDisc) {
      const moduleInInventory = disc?.find(m => m.inventoryId === score.slug)
      return {
        description: moduleInInventory?.description,
        locale: moduleInInventory?.locale || 'en_US',
        customDescription: (
          <MarkdownText text={score.qualitative_score && DISC_EXPLANATIONS[score.qualitative_score]} />
        ),
      }
    } else {
      const moduleInInventory = Array.from(props.inventoryToModuleScorePropsMap.values())
        .flat()
        .find(m => m.title === score.name)
      return {
        description: moduleInInventory?.description,
        locale: moduleInInventory?.locale || 'en_US',
        customDescription: !props.isSharedResults &&
          moduleInInventory?.highBehaviourExample &&
          moduleInInventory?.lowBehaviourExample && (
            <ScoreExplanationContainer>
              {moduleInInventory?.lowBehaviourExample && (
                <ScoreExplanation>
                  <ScoreExplanationTitle>Low score behavior:</ScoreExplanationTitle>
                  <Markdown>{cleanMd(moduleInInventory.lowBehaviourExample || '')}</Markdown>
                </ScoreExplanation>
              )}
              {moduleInInventory?.highBehaviourExample && (
                <ScoreExplanation>
                  <ScoreExplanationTitle>High score behavior:</ScoreExplanationTitle>
                  <Markdown>{cleanMd(moduleInInventory.highBehaviourExample || '')}</Markdown>
                </ScoreExplanation>
              )}
            </ScoreExplanationContainer>
          ),
        pairwise: {
          startCaption: moduleInInventory?.startCaption || '',
          endCaption: moduleInInventory?.endCaption || '',
        },
        isSharedResults: props.isSharedResults,
      }
    }
  }

  const group = useSelector((state: RootState) => {
    const group_redux = selectors.groups.findBySlug(state, props.personalityScores?.slug || '')
    return {
      id: group_redux?.id || '',
      name: props.personalityScores?.name || group_redux?.fields?.copy?.title || '',
      slug: group_redux?.fields?.slug || '',
      copy: {
        title: group_redux?.fields?.copy?.title || '',
        icon: group_redux?.fields?.copy?.icon ? <Icon name={group_redux?.fields?.copy?.icon} /> : '🛠',
        emoji: group_redux?.fields?.copy?.emoji,
        theme: group_redux?.fields?.copy?.theme || '',
        description: group_redux?.fields?.copy?.short_title || '',
      },
    }
  })

  return (
    <>
      {props.isSharedResults && (
        <div className={classes.fullWidthSubgridItem}>
          <Card
            style={{
              borderLeft: '5px solid blue',
              gridColumn: '1 / -1',
            }}
          >
            <Text variant="body-emphasis" marginBottom>
              No personality is better than another
            </Text>
            <Text variant="body-text" marginBottom style={{ color: designSystemColors.typographySecondary }}>
              A personality test is a valuable tool used to assess an individual's behavioral traits and tendencies
              within a professional context. It's important to understand that the purpose of a personality test is not
              to label one personality type as superior to another. Each personality type brings its unique strengths
              and attributes, which can be exceptionally advantageous in various work settings.
            </Text>
          </Card>
        </div>
      )}

      <ScoreGroup
        key={props.personalityScores?.slug}
        score={props.personalityScores}
        group={group}
        onClickResponses={props.onResponsesClick}
        candidateFirstName={props.candidateFirstName}
        getModuleData={getModuleData}
        defaultCollapsed={props.defaultCollapsed}
      />
    </>
  )
}

interface PersonalityScoreCardProps {
  questionnaire?: Entity<api.questionnaires.Fields>
  personalityAnswers: AssessmentModuleAnswers[]
  personalityScores: ScoreResponse['meta'][number] | undefined
  moduleQuestions: Map<string, QuestionEntity[]>
  personalityGroupId: string
  isSharedResults: boolean
  candidateFirstName: string
  roleId: string
  candidateId: string
  defaultCollapsed?: boolean
  onResponsesClick: (moduleSlug: string, group: Group) => void
}

const PersonalityScoreCard: React.FC<PersonalityScoreCardProps> = props => {
  const discScoreObj = props.personalityScores?.children?.find(s => s.slug === 'disc')

  // FIXME: Arrays in the body of the component. Make it states.
  const nonDiscProps = props.personalityAnswers
    .filter(m => !m.module?.slug.startsWith('disc'))
    .filter(hasPresentKey('module'))
    .map((m): PersonalityModuleScoringProps | undefined => {
      const currentModule =
        props.personalityScores?.children?.find(
          //direct child module
          s => s.slug === m.module?.slug,
        ) ??
        props.personalityScores?.children?.reduce((acc, curr) => {
          //nested module in scoring endpoint
          let moduleFound: ScoreResponse['meta'][number] | undefined = undefined
          curr.children?.forEach(c => {
            if (c.slug === m.module?.slug) {
              moduleFound = c
            }
          })
          return moduleFound ? moduleFound : acc
        }, null)

      const scoreFromEndpoint = currentModule?.benchmark !== undefined ? currentModule.benchmark : null

      return scoreScorePropsFromModule(m.module, scoreFromEndpoint, '🛠')
    })
    .filter(e => !!e)

  const discModuleProps = props.personalityAnswers
    .filter(m => m.module?.slug.startsWith('disc'))
    .filter(hasPresentKey('module'))
    .map(m => {
      const scoreObj = scoreScorePropsFromModule(m.module, discScoreObj?.score ?? null, '')
      return {
        ...scoreObj,
        score: discScoreObj?.qualitative_score ?? '',
      }
    })

  const personalityModuleScoringProps = [...nonDiscProps, ...discModuleProps] as PersonalityModuleScoringProps[]

  return (
    <PersonalityScoreCardUI
      onResponsesClick={props.onResponsesClick}
      inventoryToModuleScorePropsMap={groupBy(personalityModuleScoringProps, cp => cp.inventoryId)}
      personalityScores={props.personalityScores}
      isSharedResults={props.isSharedResults}
      candidateFirstName={props.candidateFirstName}
      roleId={props.roleId}
      candidateId={props.candidateId}
      defaultCollapsed={props.defaultCollapsed}
    />
  )
}

export default PersonalityScoreCard

const scoreScorePropsFromModule = (
  m: ModuleFields,
  score: number | null,
  defaultEmoji: string,
  scoreLabel?: string,
): PersonalityModuleScoringProps => {
  return {
    inventoryId: m.inventory_id,
    title: m.copy.title || '',
    emoji: m.copy?.emoji || defaultEmoji,
    emojiColor: 'orange' as const,
    score: score,
    scoreLabel: scoreLabel,
    description: m.copy.description || '',
    startCaption: m.copy.score_card?.low_caption || '',
    endCaption: m.copy.score_card?.high_caption || '',
    lowBehaviourExample: m.copy.score_card?.low_behavior_example || '',
    highBehaviourExample: m.copy.score_card?.high_behavior_example || '',
    locale: m.locale,
  }
}
