import AssessmentExportPDFPage from 'App/Candidate/SelfAssessment/AssessmentExportPDF/AssessmentExportPDFPage'
import { CandidatePageRedirect } from 'App/Candidate/Summary/CandidatePageRedirect'
import CandidateExperience from 'App/ExperienceFeedback'
import { default as CXInvite } from 'App/ExperienceFeedback/Invite'
import Questions from 'App/ExperienceFeedback/Questions'
import WrittenFeedback from 'App/ExperienceFeedback/WrittenFeedback'
import HomePage from 'App/Home/HomePage'
import { SalesPoolPage } from 'App/ProductPages/SalesPool'
import Upgrade from 'App/Upgrade'
import { Route, Router } from 'core/router'
import { RedirectWithParams } from 'core/router/RedirectWithParams'
import { extractPropsFromState } from 'core/track/users'
import { useFeatureFlag } from 'providers/features'
import React, { useEffect } from 'react'
import { I18nextProvider } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { RootState } from 'store'
import { getUTMSearchParams, removeUTMSearchParams } from 'utils/format'
import * as tracking from '../core/track'
import i18n from './../i18n'
import BackgroundCheck from './Candidate/BackgroundCheck/BackgroundCheck'
import SelfAssessment from './Candidate/SelfAssessment/AssessmentPage/AssessmentPage'
import CandidateSummary, { RefCheckSummary } from './Candidate/Summary'
import CandidateOnly from './CandidateOnly'
import CandidateDetails from './CandidateOnly/AddCandidateDetails'
import PublicIntroScreenPage from './CandidateOnly/ManageReferences/PublicIntroScreen'
import WorkVerification from './CandidateOnly/WorkVerification'
import HRWorkVerificationForm from './CandidateOnly/WorkVerification/HRWorkVerificationForm'
import CandidatesPage from './CandidatesPage'
import Login from './Login'
import Maintenance from './Maintenance'
import OpenJobRole from './OpenJobRole/OpenJobRolePage'
import AssessmentsPage from './ProductPages/AssessmentsPage'
import ReferenceChecksPage from './ProductPages/ReferenceChecksPage'
import QualityOfHirePage from './QualityOfHire'
import EmployeeQualityOfHirePage from './QualityOfHire/Employee'
import Reports from './Reports'
import CreatePassword from './ResetPassword/CreatePassword'
import RequestPasswordReset from './ResetPassword/RequestPasswordReset'
import ResetPassword from './ResetPassword/ResetPassword'
import RoleCreationPage from './RoleCreation'
import RolesPage from './RolesPage'
import SAMLSSORedirect from './SAMLSSORedirect'
import OrganizationSettings from './Settings/Organization'
import CreateWebhooks from './Settings/Pages/CreateWebhooks'
import EditAccount from './Settings/Pages/EditAccount'
import ManageCandidates from './Settings/Pages/ManageCandidates'
import ManageEnvironments from './Settings/Pages/ManageEnvironments'
import ManageReferences from './Settings/Pages/ManageReferences'
import ManageTalentPool from './Settings/Pages/ManageTalentPool'
import ManageUsers from './Settings/Pages/ManageUsers'
import ManageWebhooks from './Settings/Pages/ManageWebhooks'
import UpdateWebhook from './Settings/Pages/UpdateWebhook'
import StepRequest from './Shared/OneTimePassword/StepRequest'
import StepVerify from './Shared/OneTimePassword/StepVerify'
import SignUpPage from './SignUp'
import SlackPortal from './SlackPortal'
import NewAssessmentPage from './Templates/assessment/NewAssessmentPage'
import { TemplatesPage } from './Templates/TemplatesPage'
import ReferenceCheckPage from './Templates/reference-check/NewReferenceCheckPage'
import { RolePageRedirect } from 'App/Role/RolePageRedirect'

const CandidateExport = React.lazy(() => import(/* webpackChunkName: 'ExportDocument' */ './Candidate/ExportDocument'))

const RouterComponent = () => {
  const history = useHistory()
  const location = useLocation()

  const trackingProps = useSelector((state: RootState) => extractPropsFromState(state))
  if (trackingProps) {
    tracking.identify(trackingProps)
  }

  useEffect(() => {
    tracking.pageView() // Track first pageview on initial site load
    const unlisten = history.listen(tracking.pageView) // Track all subsequent pageviews

    const utmParams = getUTMSearchParams(location.search)
    if (Object.keys(utmParams).length > 0) {
      localStorage.setItem('utm_params', JSON.stringify(utmParams))

      // Remove UTM parameters from the URL
      history.replace({
        ...location,
        search: removeUTMSearchParams(location.search),
      })
    }

    return () => {
      unlisten()
    }
  }, [history])

  const { isEnabled: maintenanceMode } = useFeatureFlag('maintenance-mode')
  if (maintenanceMode) {
    return <Maintenance />
  }

  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <Route path="/login/google">
          <Login interceptGoogle={true} />
        </Route>
        <Route path="/login">
          <Login interceptGoogle={false} />
        </Route>
        <Route path={'/upgrade'} auth>
          <Upgrade />
        </Route>
        <Route path="/settings/accounts/:userid" auth>
          <EditAccount />
        </Route>
        <Route path="/settings/webhooks/create" auth>
          <CreateWebhooks />
        </Route>
        <Route path="/settings/webhooks/:id" auth>
          <UpdateWebhook />
        </Route>
        <Route path="/settings/candidates" auth>
          <ManageCandidates />
        </Route>
        <Route path="/settings/references" auth>
          <ManageReferences />
        </Route>
        <Route path="/settings/users" auth>
          <ManageUsers />
        </Route>
        <Route path="/settings/webhooks" auth>
          <ManageWebhooks />
        </Route>
        <Route path="/settings/organization" auth>
          <OrganizationSettings />
        </Route>
        <Route path="/environments">
          <ManageEnvironments />
        </Route>
        <Route path="/roles/:roleid/candidates/:candidateid/references/:referenceid" auth>
          <RedirectWithParams setUrl={params => `/roles/${params.roleid}/candidates/${params.candidateid}/responses`} />
        </Route>
        <Route path="/roles/:roleid/candidates/:candidateid/export" auth>
          <React.Suspense fallback={null}>
            <CandidateExport />
          </React.Suspense>
        </Route>
        <Route path="/roles/:roleid/candidates/:candidateid/list-by-references" auth>
          <RedirectWithParams setUrl={params => `/roles/${params.roleid}/candidates/${params.candidateid}/responses`} />
        </Route>
        {/* route for responses */}
        <Route path="/roles/:roleid/candidates/:candidateid/assessment/responses" auth>
          <SelfAssessment responses />
        </Route>
        <Route path="/roles/:roleid/candidates/:candidateid/assessment" auth>
          <SelfAssessment />
        </Route>
        <Route path="/roles/:roleid/candidates/:candidateid/reference-check" auth>
          <RefCheckSummary />
        </Route>
        <Route path="/roles/:roleid/candidates/:candidateid/background-check" auth>
          <BackgroundCheck />
        </Route>
        <Route path="/roles/:roleid/candidates/:candidateid/quality-of-hire" auth>
          <EmployeeQualityOfHirePage />
        </Route>
        <Route path="/roles/:roleid/candidates/:candidateid/" auth>
          <RolePageRedirect />
        </Route>
        <Route path="/shared/candidates/:candidateid/assessment" limited>
          <SelfAssessment sharedViewMode />
        </Route>
        <Route path="/shared/candidates/:candidateid/results/" limited>
          <SelfAssessment sharedViewMode="candidate" />
        </Route>
        <Route path="/shared/candidates/:candidateid/reference-check" limited>
          <CandidateSummary isInSharedViewMode />
        </Route>
        <Route path="/shared/candidates/:candidateid/references/:referenceid" limited>
          <RedirectWithParams setUrl={params => `/shared/candidates/${params.candidateid}/responses`} />
        </Route>
        <Route path="/shared/candidates/:candidateid/list-by-references" limited>
          <RedirectWithParams setUrl={params => `/shared/candidates/${params.candidateid}/responses`} />
        </Route>
        <Route path="/shared/candidates/:candidateid" limited>
          <CandidatePageRedirect />
        </Route>
        <Route path="/shared/otp/:otpid/verify" limited>
          <StepVerify />
        </Route>
        <Route path="/shared/otp/:otpid" limited>
          <StepRequest />
        </Route>
        <Route path="/candidates/:candidateid/assessment/export-pdf" limited>
          <AssessmentExportPDFPage />
        </Route>
        <Route path="/roles/new" auth>
          <RoleCreationPage />
        </Route>
        <Route path="/roles/:roleid/edit" auth>
          <RoleCreationPage />
        </Route>
        <Route path="/roles/:roleid/add-candidate-details">
          <CandidateDetails />
        </Route>
        <Route path="/roles/:roleid/public-intro">
          <PublicIntroScreenPage />
        </Route>
        <Route path="/roles/:roleid" auth>
          <OpenJobRole />
        </Route>
        <Route path="/roles" auth>
          <RolesPage />
        </Route>

        <Route path="/templates" auth exact>
          <TemplatesPage />
        </Route>

        {/* roleId now comes from a query param */}
        <Route path="/templates/self-assessment" auth>
          <NewAssessmentPage />
        </Route>

        <Route path="/templates/reference-check" auth>
          <ReferenceCheckPage />
        </Route>

        <Route path="/candidates/:candidateid/work-verification" auth limited>
          <WorkVerification />
        </Route>
        <Route path="/candidates/:candidateid/work-verification/:workverificationid" auth limited>
          <HRWorkVerificationForm />
        </Route>
        <Route path="/candidates/:candidateid" auth limited>
          <CandidateOnly />
        </Route>
        <Route path="/candidates" auth>
          <CandidatesPage />
        </Route>
        <Route path="/samlsso" auth>
          <SAMLSSORedirect />
        </Route>
        <Route path="/slack" auth>
          <SlackPortal />
        </Route>
        <Route path="/talentpool" auth>
          <ManageTalentPool />
        </Route>
        <Route path="/sales-pool" auth>
          <SalesPoolPage />
        </Route>
        <Route path="/candidateexperience/scores" auth>
          <CandidateExperience />
        </Route>
        <Route path="/candidateexperience/feedback/:id" auth>
          <WrittenFeedback />
        </Route>
        <Route path="/candidateexperience/feedback" auth>
          <Questions />
        </Route>
        <Route path="/candidateexperience/public/:orgid/invite">
          <CXInvite />
        </Route>
        <Route path="/reports" auth>
          <Reports />
        </Route>
        <Route path="/assessments" auth>
          <AssessmentsPage />
        </Route>
        <Route path="/reference-feedback" auth>
          <ReferenceChecksPage />
        </Route>
        <Route path="/quality-of-hire/:employeeid" auth>
          <EmployeeQualityOfHirePage />
        </Route>
        <Route path="/quality-of-hire" auth>
          <QualityOfHirePage />
        </Route>
        <Route path="/reset-password/request">
          <RequestPasswordReset />
        </Route>
        <Route path="/reset-password">
          <ResetPassword />
        </Route>
        <Route path="/create-password">
          <CreatePassword />
        </Route>
        <Route path="/sign-up/google">
          <SignUpPage interceptGoogle />
        </Route>
        <Route path="/sign-up">
          <SignUpPage />
        </Route>
        <Route path="/" auth>
          <HomePage />
        </Route>
      </Router>
    </I18nextProvider>
  )
}

export default RouterComponent
