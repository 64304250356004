import { Fields, QuestionEntity, QuestionResponseType, RESOURCE } from 'api/questions'
import { Entity } from 'api/request'
import { RootState } from 'store'
import { QUESTION_SLUGS } from 'store/new-role/content'
import lookup from './lookup'
import { findById as findModuleById, findBySlug as findModulesBySlug } from './modules'
import { findQuestionnaireByCandidateId } from './questionnaires'

export function findById(state: RootState, id: string) {
  return lookup<Fields>(state, RESOURCE, id)
}

export function findByModuleId(state: RootState, moduleId: string) {
  const module = findModuleById(state, moduleId)

  if (!module) {
    return undefined
  }

  const moduleIds = module.relationships?.filter(r => r.resource === RESOURCE).map(r => r.id) || []

  return (moduleIds.map(id => findById(state, id)).filter(entity => entity !== undefined) as QuestionEntity[]).sort(
    (a, b) => a.fields.created_at - b.fields.created_at,
  )
}

export function findByModuleIds(state: RootState, moduleIds: string[]) {
  const result = new Map<string, Entity<Fields>[]>()

  for (const moduleId of moduleIds) {
    let questions = findByModuleId(state, moduleId)
    if (!questions?.length) continue
    questions = questions.filter(q => !irrelevantResponseTypes.has(q.fields.response_type))
    if (!questions?.length) continue
    result.set(moduleId, questions)
  }

  return result
}

const irrelevantResponseTypes: Set<QuestionResponseType> = new Set(['timed-intro', 'timed-outro', 'intro', 'outro'])

export function findByModuleSlug(state: RootState, candidateId: string, slug: string) {
  const questionnaire = findQuestionnaireByCandidateId(state, candidateId)
  if (!questionnaire) return

  const questionIds: string[] = []
  for (const question of questionnaire.questions) {
    const module = findModuleById(state, question.module_id)
    if (!module || module?.fields.slug !== slug) continue

    questionIds.push(question.id)
  }

  return questionIds.map(id => findById(state, id)).filter(question => question !== undefined) as QuestionEntity[]
}

export function findAllQuestionsBySlug(state: RootState, slug: string) {
  const modules = findModulesBySlug(state, slug)
  if (!modules?.relationships) return

  return modules.relationships.filter(rel => rel.resource === 'questions').map(rel => findById(state, rel.id))
}

export function findBySlugCandidateId(state: RootState, candidateId: string, slug: QUESTION_SLUGS) {
  const questionnaire = findQuestionnaireByCandidateId(state, candidateId)
  if (!questionnaire) return

  const questionIds: string[] = []
  for (const question of questionnaire.questions) {
    if (question.slug === slug) {
      questionIds.push(question.id)
    }
  }

  return questionIds.map(id => findById(state, id)).filter(question => question !== undefined) as QuestionEntity[]
}

export function findSkillsetQuestionsByCandidateId(state: RootState, candidateId: string) {
  const questionnaire = findQuestionnaireByCandidateId(state, candidateId)
  if (!questionnaire) return

  return questionnaire.questions.filter(question => question.skill_id)
}
export function findBySlug(state: RootState, slug: string) {
  return Object.values(state.resources)
    .filter(r => r.resource === RESOURCE)
    .find(r => r.fields.slug === slug) as QuestionEntity | undefined
}
