import { put } from './methods'
import { APIResponse, Entity } from './request'

export const RESOURCE = 'candidate_profiles'

export interface Fields {
  id: string
  full_name: string
  email: string
  phone_number: string
  created_at: number
  updated_at: number
}

export function empty(id: string): Entity<Fields> {
  return {
    id,
    resource: RESOURCE,
    fields: {
      id,
      full_name: '',
      email: '',
      phone_number: '',
      created_at: 0,
      updated_at: 0,
    },
  }
}

export interface UpdateProfileDetails {
  full_name?: string
  email?: string
}

interface UpdatePayload {
  updates: UpdateProfileDetails
  updated: string[]
}

export function updateCandidateProfile(
  candidateId: string,
  updates: UpdatePayload,
): Promise<APIResponse<Entity<Fields>>> {
  return put(`candidate_profiles/${candidateId}`, updates)
}
