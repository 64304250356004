import React, { forwardRef } from 'react'
import classes from './Card.module.scss'

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  big?: boolean
  container?: boolean
}

export const Card = forwardRef<HTMLDivElement, CardProps>(({ big, className, container = false, ...props }, ref) => {
  return (
    <div
      ref={ref}
      data-is-container={container}
      className={[classes.card, big ? classes.bigCard : '', className].join(' ')}
      {...props}
    >
      {props.children}
    </div>
  )
})

Card.displayName = 'Card'
