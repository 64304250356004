import { colors, height, scale, space, style, weight } from '../../core'

interface Props {
  nospacing?: boolean
  center?: boolean
  right?: boolean
}

const common = style()
  .cond((props: Props) => !!props.nospacing, style().spacing({ outer: space.none }))
  .cond((props: Props) => !!props.center, style().text({ align: 'center' }))
  .cond((props: Props) => !!props.right, style().text({ align: 'right' }))

export const Heading = common
  .clone()
  .fg(colors.midGray)
  .sans({ size: scale.l, weight: weight.bold })
  .spacing({ outer: space.xxs })
  .element('h1')

export const Subheading = common
  .clone()
  .fg(colors.gray)
  .sans({ size: scale.s, height: height.s, weight: weight.light })
  .spacing({ outer: [space.xxs, space.none] })
  .element('h2')

export const FocusHeading = common
  .clone()
  .fg(colors.black)
  .serif({ size: scale.xl, weight: weight.lighter, uppercase: true })
  .spacing({ outer: space.xxs })
  .element('h1')
