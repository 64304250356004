import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const name = 'create-team'

interface State {
  loading: boolean
}

const initialState: State = {
  loading: false,
}

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
  },
})

export default slice.reducer
