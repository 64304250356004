import { Prices } from '@common/components'

const HubspotMeetingLink = 'https://meetings.hubspot.com/jgillmann'

/**
 * This will display "Custom" and disable the company size dropdown
 */
export const EnterprisePrices: Prices = {
  'For all sizes': { yearly_link: HubspotMeetingLink },
}

export const AssessmentGrowPrices: Record<string, Prices> = {
  //   TODO fill prices in EUR
  EUR: {},
  USD: {
    '1-15': {
      monthly: 72,
      yearly: 720,
      save: 144,
      monthly_link: 'https://buy.stripe.com/4gw5n15Swb1n7L2bK5',
      yearly_link: 'https://buy.stripe.com/bIY8zddkY1qNfdu8xU',
    },
    '16-30': {
      monthly: 115,
      yearly: 1152,
      save: 230,
      monthly_link: 'https://buy.stripe.com/fZeeXBft65H36GYeWj',
      yearly_link: 'https://buy.stripe.com/6oEdTxep28Tf7L2dSg',
    },
    '31-50': {
      monthly: 200,
      yearly: 1997,
      save: 399,
      monthly_link: 'https://buy.stripe.com/00g2aP3Kod9vfdu6pP',
      yearly_link: 'https://buy.stripe.com/6oEg1F94Id9vfdu6pQ',
    },
    '51-100': {
      monthly: 384,
      yearly: 3840,
      save: 768,
      monthly_link: 'https://buy.stripe.com/aEUcPt5SwfhDaXecOf',
      yearly_link: 'https://buy.stripe.com/aEUeXB4Osb1n9Ta7tW',
    },
    '101-200': {
      monthly: 672,
      yearly: 6720,
      save: 1344,
      monthly_link: 'https://buy.stripe.com/aEU8zd6WA4CZ9TabKd',
      yearly_link: 'https://buy.stripe.com/eVaaHl94Ib1n6GYbKe',
    },
    '201-300': {
      monthly: 1080,
      yearly: 10800,
      save: 2160,
      monthly_link: 'https://buy.stripe.com/8wM5n1dkY6L7d5maGb',
      yearly_link: 'https://buy.stripe.com/eVaeXB94I5H33uM4hO',
    },
    '301-400': {
      monthly: 1680,
      yearly: 16800,
      save: 3360,
      monthly_link: 'https://buy.stripe.com/4gw3eTbcQ6L7fdu8y5',
      yearly_link: 'https://buy.stripe.com/00g02H2Gkd9v0iA9Ca',
    },
    '401-500': {
      monthly: 2400,
      yearly: 24000,
      save: 4800,
      monthly_link: 'https://buy.stripe.com/14k2aPcgUglHghy5lV',
      yearly_link: 'https://buy.stripe.com/dR68zdep22uRfdu5lW',
    },
    '501-750': { yearly_link: HubspotMeetingLink, custom: true },
    '751-1,000': { yearly_link: HubspotMeetingLink, custom: true },
    '1,001+': { yearly_link: HubspotMeetingLink, custom: true },
  },
}

export const AssessmentScalePrices: Record<string, Prices> = {
  //   TODO fill prices in EUR
  EUR: {},
  USD: {
    '1-15': {
      monthly: 101,
      yearly: 1008,
      save: 204,
      monthly_link: 'https://buy.stripe.com/fZe2aPgxa1qN9Ta4hT',
      yearly_link: 'https://buy.stripe.com/aEUbLp5Sw2uRe9q8ya',
    },
    '16-30': {
      monthly: 161,
      yearly: 1608,
      save: 324,
      monthly_link: 'https://buy.stripe.com/14keXB1Cgc5rfduaGj',
      yearly_link: 'https://buy.stripe.com/aEU5n1gxa3yV2qI9Cg',
    },
    '31-50': {
      monthly: 280,
      yearly: 2796,
      save: 564,
      monthly_link: 'https://buy.stripe.com/4gw6r5bcQb1nghy3dT',
      yearly_link: 'https://buy.stripe.com/5kAaHl6WA2uR9Ta6q6',
    },
    '51-100': {
      monthly: 538,
      yearly: 5376,
      save: 1080,
      monthly_link: 'https://buy.stripe.com/7sI02H1Cg8Tf8P64hZ',
      yearly_link: 'https://buy.stripe.com/7sIg1F0yc4CZd5m15O',
    },
    '101-200': {
      monthly: 941,
      yearly: 9408,
      save: 1884,
      monthly_link: 'https://buy.stripe.com/aEUbLp2Gk1qNfdu7ud',
      yearly_link: 'https://buy.stripe.com/6oEeXB94I5H39Ta8yi',
    },
    '201-300': {
      monthly: 1512,
      yearly: 15120,
      save: 3024,
      monthly_link: 'https://buy.stripe.com/eVag1F4Os2uR5CUaGr',
      yearly_link: 'https://buy.stripe.com/dR616L94I8Tf0iA7ug',
    },
    '301-400': {
      monthly: 2352,
      yearly: 23520,
      save: 4704,
      monthly_link: 'https://buy.stripe.com/4gw9Dha8MglHd5meWJ',
      yearly_link: 'https://buy.stripe.com/dR6g1FcgU9XjfduaGu',
    },
    '401-500': {
      monthly: 3360,
      yearly: 33600,
      save: 6720,
      monthly_link: 'https://buy.stripe.com/fZe2aP5SwfhDc1idSH',
      yearly_link: 'https://buy.stripe.com/14k8zd5Sw1qN9Ta8yo',
    },
    '501-750': { yearly_link: HubspotMeetingLink, custom: true },
    '751-1,000': { yearly_link: HubspotMeetingLink, custom: true },
    '1,001+': { yearly_link: HubspotMeetingLink, custom: true },
  },
}

export const RefCheckGrowPrices: Record<string, Prices> = {
  //   TODO fill prices in EUR
  EUR: {},
  USD: {
    '1-15': { yearly: 1092, yearly_link: 'https://buy.stripe.com/bIY2aP6WA3yV5CUeXn' },
    '16-30': { yearly: 1420, yearly_link: 'https://buy.stripe.com/9AQ3eT5Sw9Xj7L216z' },
    '31-50': { yearly: 1845, yearly_link: 'https://buy.stripe.com/eVa4iX6WAb1n1mE4iN' },
    '51-100': { yearly: 3062, yearly_link: 'https://buy.stripe.com/aEU16L0yc0mJ7L2g1x' },
    '101-200': { yearly: 3981, yearly_link: 'https://buy.stripe.com/28o02Hft6d9v6GYg1z' },
    '201-300': { yearly: 5176, yearly_link: 'https://buy.stripe.com/4gw2aP0yc2uR8P6dTt' },
    '301-400': { yearly: 6728, yearly_link: 'https://buy.stripe.com/dR616LcgUb1nd5m9Df' },
    '401-500': { yearly: 8747, yearly_link: 'https://buy.stripe.com/6oE9Dh6WA0mJ4yQ9Dh' },
    '501-750': { yearly_link: HubspotMeetingLink, custom: true },
    '751-1,000': { yearly_link: HubspotMeetingLink, custom: true },
    '1,001+': { yearly_link: HubspotMeetingLink, custom: true },
  },
}

export const RefCheckScalePrices: Record<string, Prices> = {
  //   TODO fill prices in EUR
  EUR: {},
  USD: {
    '1-15': { yearly: 1529, yearly_link: 'https://buy.stripe.com/28ocPt6WAb1n9Ta7uW' },
    '16-30': { yearly: 1987, yearly_link: 'https://buy.stripe.com/00g02Hgxa2uRe9q2aE' },
    '31-50': { yearly: 2584, yearly_link: 'https://buy.stripe.com/6oE2aPbcQd9v8P6eXs' },
    '51-100': { yearly: 4287, yearly_link: 'https://buy.stripe.com/bIYaHl5Sw1qNghydTq' },
    '101-200': { yearly: 5574, yearly_link: 'https://buy.stripe.com/5kAbLp1CgglH3uM5mW' },
    '201-300': { yearly: 7246, yearly_link: 'https://buy.stripe.com/eVaeXBgxa1qN9Ta6r2' },
    '301-400': { yearly: 9419, yearly_link: 'https://buy.stripe.com/fZe8zdep29Xj8P602G' },
    '401-500': { yearly: 12245, yearly_link: 'https://buy.stripe.com/5kA5n1bcQglHc1idTy' },
    '501-750': { yearly_link: HubspotMeetingLink, custom: true },
    '751-1,000': { yearly_link: HubspotMeetingLink, custom: true },
    '1,001+': { yearly_link: HubspotMeetingLink, custom: true },
  },
}

export const AllInOneGrowPrices: Record<string, Prices> = {
  USD: {
    '1-15': {
      yearly: 1540,
      save: 231,
      yearly_link: 'https://buy.stripe.com/9AQeXB1Cg9Xj2qIbLr',
    },
    '16-30': {
      yearly: 2186,
      save: 328,
      yearly_link: 'https://buy.stripe.com/bIYaHl0ycd9v6GY5n5',
    },
    '31-50': {
      yearly: 3266,
      save: 490,
      yearly_link: 'https://buy.stripe.com/8wM3eT80E0mJ3uM3eZ',
    },
    '51-100': {
      yearly: 5867,
      save: 880,
      yearly_link: 'https://buy.stripe.com/28o9Dhgxad9v4yQ8zl',
    },
    '101-200': {
      yearly: 9096,
      save: 1364,
      yearly_link: 'https://buy.stripe.com/28o3eTep29Xj6GYcPD',
    },
    '201-300': {
      yearly: 13579,
      save: 2037,
      yearly_link: 'https://buy.stripe.com/4gw2aP94IfhDghycPF',
    },
    '301-400': {
      yearly: 19999,
      save: 3000,
      yearly_link: 'https://buy.stripe.com/14k6r51CgfhDd5mdTL',
    },
    '401-500': {
      yearly: 27835,
      save: 4175,
      yearly_link: 'https://buy.stripe.com/3csaHldkY2uR5CU3f9',
    },
    '501-750': {
      custom: true,
    },
    '751-1,000': {
      custom: true,
    },
    '1,001+': {
      custom: true,
    },
  },
  EUR: {},
}

export const AllInOneScalePrices: Record<string, Prices> = {
  USD: {
    '1-15': {
      yearly: 2156,
      save: 323,
      yearly_link: 'https://buy.stripe.com/bIYcPt2Gkc5r7L25n4',
    },
    '16-30': {
      yearly: 3056,
      save: 458,
      yearly_link: 'https://buy.stripe.com/8wM3eTcgUedzc1idTC',
    },
    '31-50': {
      yearly: 4573,
      save: 686,
      yearly_link: 'https://buy.stripe.com/cN24iXcgUd9v9Ta5n8',
    },
    '51-100': {
      yearly: 8214,
      save: 1232,
      yearly_link: 'https://buy.stripe.com/5kA02H94I8TfaXe02Q',
    },
    '101-200': {
      yearly: 12734,
      save: 1910,
      yearly_link: 'https://buy.stripe.com/14k7v9dkY1qNghy7vk',
    },
    '201-300': {
      yearly: 19011,
      save: 2852,
      yearly_link: 'https://buy.stripe.com/14kbLp6WA7Pbe9q8zq',
    },
    '301-400': {
      yearly: 27999,
      save: 4200,
      yearly_link: 'https://buy.stripe.com/00g8zdbcQglH9Ta4jc',
    },
    '401-500': {
      yearly: 38968,
      save: 5845,
      yearly_link: 'https://buy.stripe.com/14kaHlcgUfhD9Ta9Dy',
    },
    '501-750': {
      custom: true,
    },
    '751-1,000': {
      custom: true,
    },
    '1,001+': {
      custom: true,
    },
  },
  EUR: {},
}
