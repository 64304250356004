export interface ChipKnowledgeBite {
  key: string
  emoji: string
  teaser: string
  suggestions: Suggestion[]
  furtherReading: ArticleDescription[]
}

interface Suggestion {
  topic: Topic
  icon: string
  content: string[]
}

type Topic = 'Onboarding' | 'Development' | 'Engagement'

export interface ArticleDescription {
  title: string
  summary: string
  url: string
  image: string
}

const POSITIVE: ChipKnowledgeBite = {
  key: 'Positive',
  emoji: '🤩',
  teaser:
    'Positive colleagues are usually enthusiastic about work. This makes them motivated self-starters who don’t need much to get started on a new task. They also transfer their cheerful attitude to others, encourage them, and quickly build new relationships.',
  suggestions: [
    {
      topic: 'Onboarding',
      icon: '🏂',
      content: [
        'Positive colleagues enjoy spending time with others. Most probably, they will thank you for an onboarding buddy or mentor who is talkative and outgoing.',
        'Informal events such as team lunches or coffee dates are great for building new relationships. Let the candidate shine and set up some informal event(s) for them!',
        'Let positive colleagues spread their enthusiasm by assigning them first tasks that enable them to interact with as many different people and functions as possible.',
      ],
    },
  ],
  furtherReading: [
    {
      title: 'Introverts, Extroverts, and the Complexities of Team Dynamics',
      summary:
        'Let’s start with a short personality test. For each of the following dimensions, indicate the extent to which each of the following words describes you, with a 5 indicating “very much so” and a 1 indicating “not at all”.',
      url: 'https://hbr.org/2015/03/introverts-extroverts-and-the-complexities-of-team-dynamics#:~:text=Extroverts%20gravitate%20toward%20groups%20and,other%20people%20gives%20them%20energy',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
    {
      title: 'Work Styles',
      summary: 'Learn more about the HiPeople Work Styles.',
      url: 'https://hipeople.notion.site/Work-Styles-2d30c24089d0403fa0824e448405b99a',
      image: 'https://uploads-ssl.webflow.com/5e74de595836b80113d48585/5e9ee5f40065a044f614dafd_postit-p-500.jpeg',
    },
  ],
}
const RESPECTFUL: ChipKnowledgeBite = {
  key: 'Respectful',
  emoji: '🙏',
  teaser:
    "Respectful colleagues are usually polite and courteous to others. They appreciate others' perspectives and listen to their conscience when making a decision. This makes them sincere and consistent counterparts for everyone.",
  suggestions: [
    {
      topic: 'Onboarding',
      icon: '🏂',
      content: [
        'To be polite, one needs to know the rules! Let respectful candidates shine by giving them more hints than usual about what you expect from their role.',
        'Do you need someone to work on confidential matters? Leverage the skills of respectful candidates by bringing them on board for this kind of task.',
        'Mentor your respectful colleagues so they can hone their social sensing skills by assigning them tasks that allow them to mediate between opposing viewpoints.',
      ],
    },
  ],
  furtherReading: [
    {
      title: 'The Leadership Behavior That’s Most Important to Employees',
      summary:
        'For the last 20 years, I’ve studied the costs of incivility, as well as the benefits of civility. Across the board, I’ve found that civility pays.',
      url: 'https://hbr.org/2015/05/the-leadership-behavior-thats-most-important-to-employees',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
    {
      title: 'Work Styles',
      summary: 'Learn more about the HiPeople Work Styles.',
      url: 'https://hipeople.notion.site/Work-Styles-2d30c24089d0403fa0824e448405b99a',
      image: 'https://uploads-ssl.webflow.com/5e74de595836b80113d48585/5e9ee5f40065a044f614dafd_postit-p-500.jpeg',
    },
  ],
}
const LEADING: ChipKnowledgeBite = {
  key: 'Leading',
  emoji: '💫',
  teaser:
    'Leading colleagues are usually able to captivate others. Because of this, they are very effective in influencing others. No matter what the situation, they tend to take charge and act confidently.',
  suggestions: [
    {
      topic: 'Onboarding',
      icon: '🏂',
      content: [
        'Leading team members are confident and persuasive communicators. Let them deliver a presentation to the team on a topic of their choosing.',
        'In case you have a (minor) task in mind that requires leading others, your new colleague might be interested in taking on that task right away!',
        "In many cases, it's not in the nature of leading colleagues to mince words. Let them shine by giving them enough space to share their perspectives during onboarding sessions.",
      ],
    },
  ],
  furtherReading: [
    {
      title: 'The Delicate Art of Being Perfectly Assertive',
      summary:
        'One of the most important, and most difficult, parts of my job is to strike the delicate balance between being too assertive and not assertive enough.',
      url: 'https://hbr.org/2010/06/the-delicate-art-of-being-perf',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
    {
      title: 'Work Styles',
      summary: 'Learn more about the HiPeople Work Styles.',
      url: 'https://hipeople.notion.site/Work-Styles-2d30c24089d0403fa0824e448405b99a',
      image: 'https://uploads-ssl.webflow.com/5e74de595836b80113d48585/5e9ee5f40065a044f614dafd_postit-p-500.jpeg',
    },
  ],
}
const EMPATHETIC: ChipKnowledgeBite = {
  key: 'Empathetic',
  emoji: '🤗',
  teaser:
    'Empathetic colleagues always assume the best about people. This makes them a fantastic partner both to customers and coworkers. Being kind and caring individuals, they are natural team players.',
  suggestions: [
    {
      topic: 'Onboarding',
      icon: '🏂',
      content: [
        'Empathetic team members take out time for others. Make them feel at ease in their first weeks by creating space for longer personal encounters.',
        "Empathetic employees anticipate their peers' needs and make them feel worthwhile. Let them spread this spirit of teamwork by connecting them with other colleagues who need help working with others.",
        'Empathetic colleagues rarely complain. To prevent the new colleague from dropping key concerns under the rug, make it as easy as possible for them to mention anything that would make them even more productive.',
      ],
    },
  ],
  furtherReading: [
    {
      title: 'Making Empathy Central to Your Company Culture',
      summary:
        'Research shows that empathic workplaces tend to enjoy stronger collaboration, less stress, and greater morale, and their employees bounce back more quickly from difficult moments such as layoffs.',
      url: 'https://hbr.org/2019/05/making-empathy-central-to-your-company-culture',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
    {
      title: 'Work Styles',
      summary: 'Learn more about the HiPeople Work Styles.',
      url: 'https://hipeople.notion.site/Work-Styles-2d30c24089d0403fa0824e448405b99a',
      image: 'https://uploads-ssl.webflow.com/5e74de595836b80113d48585/5e9ee5f40065a044f614dafd_postit-p-500.jpeg',
    },
  ],
}
const CREATIVE: ChipKnowledgeBite = {
  key: 'Creative',
  emoji: '💡',
  teaser:
    'Creative colleagues love to think up new ways of doing things. They usually benefit from their strong imagination and enjoy learning about all sorts of topics. On both a small and large scale, creative colleagues are likely to come up with something new and develop a bold plan.',
  suggestions: [
    {
      topic: 'Onboarding',
      icon: '🏂',
      content: [
        'In order to ignite the passion of creative colleagues, make them talk to people who can explain the ideas behind your products.',
        'Leverage the creativity and fresh view of creative colleagues and let them take on an existing process that needs improvement in a brainstorming session.',
        'Let creative colleagues shine by prioritizing first tasks that emphasize creating a new approach instead of jobs with a straightforward process to follow through.',
      ],
    },
  ],
  furtherReading: [
    {
      title: 'Motivating Your Most Creative Employees',
      summary:
        'Decades of psychological research suggests that creative people are quite different from others when it comes to personality, values, and abilities.',
      url: 'https://hbr.org/2018/11/motivating-your-most-creative-employees',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
    {
      title: 'Work Styles',
      summary: 'Learn more about the HiPeople Work Styles.',
      url: 'https://hipeople.notion.site/Work-Styles-2d30c24089d0403fa0824e448405b99a',
      image: 'https://uploads-ssl.webflow.com/5e74de595836b80113d48585/5e9ee5f40065a044f614dafd_postit-p-500.jpeg',
    },
  ],
}
const CLEVER: ChipKnowledgeBite = {
  key: 'Clever',
  emoji: '🤓',
  teaser:
    'Clever colleagues understand things readily. They process a great deal of information with ease and have analytical abilities. Complex problems and conversations are welcome challenges for clever colleagues.',
  suggestions: [
    {
      topic: 'Onboarding',
      icon: '🏂',
      content: [
        'Clever colleagues require information to process. You can give them a boost by having knowledge resources such as data or learning materials (e.g. a wiki) prepared to explore.',
        'Clever colleagues often have a wealth of knowledge and desire to expand it. Enable their success by setting up a Knowledge Bite Round, where each team member can share a brief insight into their expertise domain.',
        'Let your clever colleague shine right from the beginning by giving them a task that allows them to dive deep into a problem.',
      ],
    },
  ],
  furtherReading: [
    {
      title: 'How to Manage People Who Are Smarter than You',
      summary:
        'The best managers hire smart people to work for them. But what if your direct reports are smarter than you?',
      url: 'https://hbr.org/2015/08/how-to-manage-people-who-are-smarter-than-you',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
    {
      title: 'Work Styles',
      summary: 'Learn more about the HiPeople Work Styles.',
      url: 'https://hipeople.notion.site/Work-Styles-2d30c24089d0403fa0824e448405b99a',
      image: 'https://uploads-ssl.webflow.com/5e74de595836b80113d48585/5e9ee5f40065a044f614dafd_postit-p-500.jpeg',
    },
  ],
}
const DETAILED: ChipKnowledgeBite = {
  key: 'Detailed',
  emoji: '🔎',
  teaser:
    'Detailed colleagues are always prepared. They want every detail to be taken care of and enjoy when tasks are done according to a plan. Out of their demand for quality, the work of detailed people usually requires little or no checking.',
  suggestions: [
    {
      topic: 'Onboarding',
      icon: '🏂',
      content: [
        'Let detailed colleagues shine by asking them to check over documents or plans.',
        'Enable detailed colleagues to play out their strengths by letting them know what will happen in their first weeks before they have their first day.',
        'Detailed colleagues deliver and demand quality. Make the first days as comfortable as possible by telling the new team that punctuality and order might be essential for the new colleague.',
      ],
    },
  ],
  furtherReading: [
    {
      title: 'How to Manage a Perfectionist',
      summary:
        'Do you have a perfectionist on your team? The good news is that your direct report has high standards and a fine attention for detail.',
      url: 'https://hbr.org/2011/10/how-to-manage-a-perfectionist.html',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
    {
      title: 'Work Styles',
      summary: 'Learn more about the HiPeople Work Styles.',
      url: 'https://hipeople.notion.site/Work-Styles-2d30c24089d0403fa0824e448405b99a',
      image: 'https://uploads-ssl.webflow.com/5e74de595836b80113d48585/5e9ee5f40065a044f614dafd_postit-p-500.jpeg',
    },
  ],
}
const DEPENDABLE: ChipKnowledgeBite = {
  key: 'Dependable',
  emoji: '🤝',
  teaser:
    'Dependable colleagues can always be counted on. They work hard and finish what they start. It is their persistence that often leads them to be very successful coworkers!',
  suggestions: [
    {
      topic: 'Onboarding',
      icon: '🏂',
      content: [
        'Let dependable colleagues shine by having them take on several smaller tasks right away.',
        'Dependable colleagues do everything and hardly ever turn down a task. To protect them, be careful not to overload them with tasks.',
        'Dependable colleagues work long hours. To stay in good mental shape for the long term, make sure they take time off to rest.',
      ],
    },
  ],
  furtherReading: [
    {
      title: 'The Hidden Skills in Your Most Reliable People',
      summary: 'When you need something done — and done right — you probably know who you can count on.',
      url: 'https://hbr.org/2012/03/the-hidden-skills-in-your-most',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
    {
      title: 'Work Styles',
      summary: 'Learn more about the HiPeople Work Styles.',
      url: 'https://hipeople.notion.site/Work-Styles-2d30c24089d0403fa0824e448405b99a',
      image: 'https://uploads-ssl.webflow.com/5e74de595836b80113d48585/5e9ee5f40065a044f614dafd_postit-p-500.jpeg',
    },
  ],
}
const BALANCED: ChipKnowledgeBite = {
  key: 'Balanced',
  emoji: '✌️',
  teaser:
    'Balanced colleagues are not easily upset. They barely show mood swings, and whenever others feel angry, balanced people keep their emotions under control. These are reasons for balanced people to be around the most competent to handle setbacks and uncertainty.',
  suggestions: [
    {
      topic: 'Onboarding',
      icon: '🏂',
      content: [
        'If you are dealing with projects or tasks that are likely to change frequently, let balanced colleagues showcase their strengths by giving them a stake in the project.',
        'Balanced colleagues can manage criticism without taking it personally. If you have tasks involving difficult stakeholders, they are a fantastic choice!',
        'A balanced colleague does not complain about setbacks. If you have team members who are having difficulty handling setbacks, you might consider pairing them up with the new colleague occasionally.',
      ],
    },
  ],
  furtherReading: [
    {
      title: '3 Ways to Stay Calm When Conversations Get Intense',
      summary:
        'It’s not easy to stay cool and engaged when things get heated in meetings, negotiations, or difficult conversations. We’ve all been there.',
      url: 'https://hbr.org/2016/06/3-ways-to-stay-calm-when-conversations-get-intense',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
    {
      title: 'Work Styles',
      summary: 'Learn more about the HiPeople Work Styles.',
      url: 'https://hipeople.notion.site/Work-Styles-2d30c24089d0403fa0824e448405b99a',
      image: 'https://uploads-ssl.webflow.com/5e74de595836b80113d48585/5e9ee5f40065a044f614dafd_postit-p-500.jpeg',
    },
  ],
}
const CALM: ChipKnowledgeBite = {
  key: 'Calm',
  emoji: '😌',
  teaser:
    'Calm colleagues are relaxed and can handle stress well. They tend not to worry too much, and their mentality allows them to cope effectively with highly stressful work environments.',
  suggestions: [
    {
      topic: 'Onboarding',
      icon: '🏂',
      content: [
        'Let calm colleagues shine by asking for their opinions when conditions are demanding.',
        'If you have any projects that are likely to end up in tense situations, a calm employee will make an excellent team member.',
        'Let calm colleagues spread their composure among teams where others are frequently stressed out.',
      ],
    },
  ],
  furtherReading: [
    {
      title: 'How to Keep Your Cool in High-Stress Situations',
      summary:
        'When faced with a high-stress situation, one that even feels threatening, it can feel like we don’t have control over our response.',
      url: 'https://hbr.org/2021/01/how-to-keep-your-cool-in-high-stress-situations',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
    {
      title: 'Work Styles',
      summary: 'Learn more about the HiPeople Work Styles.',
      url: 'https://hipeople.notion.site/Work-Styles-2d30c24089d0403fa0824e448405b99a',
      image: 'https://uploads-ssl.webflow.com/5e74de595836b80113d48585/5e9ee5f40065a044f614dafd_postit-p-500.jpeg',
    },
  ],
}
const LISTENS_AND_AGREES: ChipKnowledgeBite = {
  key: 'Listens and agrees',
  emoji: '🙏',
  teaser:
    "Colleagues with a teamwork style characterized by listening and agreeing are often highly cooperative individuals. They are good listeners and always ready to support a good suggestion in the team's common interest.",
  suggestions: [
    {
      topic: 'Onboarding',
      icon: '🏂',
      content: [
        'Colleagues comfortable with this role often avoid disagreement. Remembering this, you could try to protect them from difficult team discussions early on.',
        'Good listeners need someone to listen to, especially when they are new to a team. While finding their role in the new team, you could help by assigning them straightforward to-dos.',
        'Sometimes you want new people with this teamwork style to contribute more of their own opinion in team meetings. Help your new colleague develop this skill by asking for their perspective in 1:1 situations.',
      ],
    },
  ],
  furtherReading: [
    {
      title: 'What Great Listeners Actually Do',
      summary:
        'What makes a good listener? Most people think is comes down to three components: not interrupting the speaker, following along with facial expressions, and being able to repeat back almost verbatim what the speaker has just said.',
      url: 'https://hbr.org/2016/07/what-great-listeners-actually-do',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
    {
      title: 'Teamwork Styles',
      summary: 'Learn more about the HiPeople Teamwork Styles.',
      url: 'https://hipeople.notion.site/Working-with-Others-42d355a0ed9f494793dfa64b96d4accb',
      image: 'https://uploads-ssl.webflow.com/5e74de595836b80113d48585/5eb6b9f2f3dd3668c6b887e6_videochat_re-p-500.png',
    },
  ],
}
const DIRECTS_AND_ORGANIZES: ChipKnowledgeBite = {
  key: 'Directs and organizes',
  emoji: '💫',
  teaser:
    'Colleagues with a teamwork style characterized by leading and organizing are those who are comfortable leading others. They typically guide, make decisions and ensure that teammates know their responsibilities.',
  suggestions: [
    {
      topic: 'Onboarding',
      icon: '🏂',
      content: [
        'Good leaders need someone to lead. While finding their role in the new team, you could help by assigning them tasks that imply leading others right away.',
        'Sometimes you want new people with this teamwork style to be less dominant in team meetings. Help your new colleague develop this capability by discussing their future leadership opportunities in 1:1 situations.',
        "Individuals who are comfortable in this role may find themselves in conflict with others who are currently in the leader's role. Keeping this in mind, you can try to protect the team from difficult situations by clearly defining formal hierarchies for the new colleague.",
      ],
    },
  ],
  furtherReading: [
    {
      title: 'Leadership And Teamwork: The Secret Sauce To Business Success',
      summary:
        'Strong, dominant team effort is facilitated by a leader who has a clear direction and the capacity to influence his or her team to work toward the realization of a vision.',
      url: 'https://www.forbes.com/sites/forbescoachescouncil/2017/02/08/leadership-and-teamwork-the-secret-sauce-to-business-success/?sh=52c5441136f1',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/forbes-logo.jpg',
    },
    {
      title: 'Teamwork Styles',
      summary: 'Learn more about the HiPeople Teamwork Styles.',
      url: 'https://hipeople.notion.site/Working-with-Others-42d355a0ed9f494793dfa64b96d4accb',
      image: 'https://uploads-ssl.webflow.com/5e74de595836b80113d48585/5eb6b9f2f3dd3668c6b887e6_videochat_re-p-500.png',
    },
  ],
}
const MOTIVATES_TO_TAKE_ACTION: ChipKnowledgeBite = {
  key: 'Motivates to take action',
  emoji: '🙌',
  teaser:
    'Colleagues with a teamwork style that involves motivating others are the individuals who can energize a group. They typically keep the team on pace and cheer it up.',
  suggestions: [
    {
      topic: 'Onboarding',
      icon: '🏂',
      content: [
        'Motivators need colleagues they can motivate. During the transition into a new team, you could assist them by providing them as many opportunities to interact with other team members as possible.',
        'Individuals experienced in this role can help to boost team motivation. With this in mind, you can arrange personal encounters with individuals that are currently experiencing difficulties motivating themselves.',
        'Having someone on your team who motivates other members is always useful. Be sure to acknowledge the efforts a motivator makes to keep them in this role.',
      ],
    },
  ],
  furtherReading: [
    {
      title: 'Motivating People Starts with Having the Right Attitude',
      summary:
        'Most leaders know what strong motivation looks like. When I ask leadership development clients to describe the type of motivation they’d like to see in their teams, they mention different qualities.',
      url: 'https://hbr.org/2017/03/motivating-people-starts-with-having-the-right-attitude',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
    {
      title: 'Teamwork Styles',
      summary: 'Learn more about the HiPeople Teamwork Styles.',
      url: 'https://hipeople.notion.site/Working-with-Others-42d355a0ed9f494793dfa64b96d4accb',
      image: 'https://uploads-ssl.webflow.com/5e74de595836b80113d48585/5eb6b9f2f3dd3668c6b887e6_videochat_re-p-500.png',
    },
  ],
}
const TAKES_A_CRITICAL_VIEW: ChipKnowledgeBite = {
  key: 'Takes a critical view',
  emoji: '🤔',
  teaser:
    "Colleagues who frequently take a critical view can best be described as challengers. They are not afraid to question others' assumptions and point out possible risks of a situation or a plan.",
  suggestions: [
    {
      topic: 'Onboarding',
      icon: '🏂',
      content: [
        'Challengers are great at showing up different perspectives of a situation. During the transition to a new team, it is helpful if you provide them the opportunity to discuss openly with other team members.',
        'Some individuals with this type of teamwork style may be mistaken for merely being negative during team meetings. Make sure to appreciate their perspectives in team environments and create opportunities where challengers can ask "why" questions.',
        'Sometimes, you want new employees with this teamwork style to be less critical in team meetings. Help your new colleague develop this capability by asking for their honest opinion in 1:1 situations.',
      ],
    },
  ],
  furtherReading: [
    {
      title: 'Every Leader Needs a Challenger in Chief',
      summary:
        'We are drawn to those who echo what it is we already believe. We get a dopamine rush when we are presented with confirming data similar to what we get when we eat chocolate or fall in love.',
      url: 'https://hbr.org/2013/09/whos-your-challenger-in-chief',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
    {
      title: 'Teamwork Styles',
      summary: 'Learn more about the HiPeople Teamwork Styles.',
      url: 'https://hipeople.notion.site/Working-with-Others-42d355a0ed9f494793dfa64b96d4accb',
      image: 'https://uploads-ssl.webflow.com/5e74de595836b80113d48585/5eb6b9f2f3dd3668c6b887e6_videochat_re-p-500.png',
    },
  ],
}
const MAINTAINS_GROUP_HARMONY: ChipKnowledgeBite = {
  key: 'Maintains group harmony',
  emoji: '🤗',
  teaser:
    'Colleagues who maintain group harmony are team builders. They mediate whenever there is disagreement and encourage others whenever they are facing challenges.',
  suggestions: [
    {
      topic: 'Onboarding',
      icon: '🏂',
      content: [
        'Team builders need groups they can strengthen. During the transition into a new team, you could assist them by providing them many opportunities to get to know the other team members well.',
        'Colleagues with this teamwork style can reduce conflicts. Let them play out their strengths by putting them into situations where they can mediate between opposing opinions.',
        'The direct contribution of team builders is often overlooked. Make sure to acknowledge their efforts towards constructing and maintaining an environment that people enjoy working in.',
      ],
    },
  ],
  furtherReading: [
    {
      title: 'Making Joy a Priority at Work',
      summary:
        'Companies are making massive investments in technologies that can more closely link their people to each other, to customers, and to other stakeholders. Yet many struggle because their cultures get in the way.',
      url: 'https://hbr.org/2019/07/making-joy-a-priority-at-work',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
    {
      title: 'Teamwork Styles',
      summary: 'Learn more about the HiPeople Teamwork Styles.',
      url: 'https://hipeople.notion.site/Working-with-Others-42d355a0ed9f494793dfa64b96d4accb',
      image: 'https://uploads-ssl.webflow.com/5e74de595836b80113d48585/5eb6b9f2f3dd3668c6b887e6_videochat_re-p-500.png',
    },
  ],
}
const COORDINATES_ACTIVITIES: ChipKnowledgeBite = {
  key: 'Coordinates activities',
  emoji: '🔎',
  teaser:
    'Colleagues who coordinate activities are organizers. They keep track of things and like to sort out the details of a team project.',
  suggestions: [
    {
      topic: 'Onboarding',
      icon: '🏂',
      content: [
        'Colleagues with this teamwork style see the details in team environments. Let them use their strength by putting them in situations where they can organize something (e.g. a team meeting).',
        'Individuals experienced in this role can help to create a well-organized team environment. With this in mind, you can arrange situations where they can contribute to detailed planning.',
        'Some individuals with this type of teamwork style may be mistaken for not thinking about the bigger picture. Make sure to appreciate their attention to the small things in team environments.',
      ],
    },
  ],
  furtherReading: [
    {
      title: 'Differing Work Styles Can Help Team Performance',
      summary:
        'Most leaders now recognize that the best teams leverage diversity to achieve long-term success. But many think about it in pretty narrow terms.',
      url: 'https://hbr.org/2015/04/differing-work-styles-can-help-team-performance',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
    {
      title: 'Teamwork Styles',
      summary: 'Learn more about the HiPeople Teamwork Styles.',
      url: 'https://hipeople.notion.site/Working-with-Others-42d355a0ed9f494793dfa64b96d4accb',
      image: 'https://uploads-ssl.webflow.com/5e74de595836b80113d48585/5eb6b9f2f3dd3668c6b887e6_videochat_re-p-500.png',
    },
  ],
}
const JUMPS_IN_AS_SUPPORTER: ChipKnowledgeBite = {
  key: 'Jumps in as supporter',
  emoji: '✌️',
  teaser:
    "Colleagues who jump in as supporters do everything for the team. They sum up, clarify, and above all, help others not slow down the team's progress.",
  suggestions: [
    {
      topic: 'Onboarding',
      icon: '🏂',
      content: [
        'Supporters would do everything so that the team accomplishes a given job in time. Let them show their strength to the team by giving them a suitable task to support a coworker right from the beginning.',
        'Some individuals with this type of teamwork style may add too many jobs to their task list. Keep them productive in the long term by frequently checking in on their workload.',
        'Individuals experienced in this role can help to boost team productivity. With this in mind, you can arrange personal encounters with colleagues currently experiencing difficulties handling their workload.',
      ],
    },
  ],
  furtherReading: [
    {
      title: 'How to Overcome Your Obsession with Helping Others',
      summary:
        'If you have ever wondered whether your genuine enjoyment of helping others teeters on over-indulgence, there a few things you can to do overcome agency addiction, or avoid it altogether.',
      url: 'https://hbr.org/2020/02/how-to-overcome-your-obsession-with-helping-others',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
    {
      title: 'Teamwork Styles',
      summary: 'Learn more about the HiPeople Teamwork Styles.',
      url: 'https://hipeople.notion.site/Working-with-Others-42d355a0ed9f494793dfa64b96d4accb',
      image: 'https://uploads-ssl.webflow.com/5e74de595836b80113d48585/5eb6b9f2f3dd3668c6b887e6_videochat_re-p-500.png',
    },
  ],
}
const EVALUATES_OUTPUTS: ChipKnowledgeBite = {
  key: 'Evaluates outputs',
  emoji: '🤓',
  teaser:
    'Colleagues who evaluate team outputs focus on facts and figures. They analyze circumstances and draw strong conclusions.',
  suggestions: [
    {
      topic: 'Onboarding',
      icon: '🏂',
      content: [
        'Evaluators analyze what happens. Let them play out their strength by giving them dedicated time in team settings to present their conclusions.',
        'Individuals experienced in this role can help the team focus on facts. However, they may be perceived as less friendly. Integrate their perspective by communicating the value of evaluations to the new team.',
        'Sometimes, you want new employees with this teamwork style to be more receptive to differing opinions. Help your new colleague develop this skill by talking about the value of diverse views in 1:1 situations.',
      ],
    },
  ],
  furtherReading: [
    {
      title: 'How to Actually Encourage Employee Accountability',
      summary:
        'Companies have been struggling to define and improve accountability processes —from annual performance appraisals to routine check-ins with the boss — for decades, and most employees still dread the conversations.',
      url: 'https://hbr.org/2020/11/how-to-actually-encourage-employee-accountability',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
    {
      title: 'Teamwork Styles',
      summary: 'Learn more about the HiPeople Teamwork Styles.',
      url: 'https://hipeople.notion.site/Working-with-Others-42d355a0ed9f494793dfa64b96d4accb',
      image: 'https://uploads-ssl.webflow.com/5e74de595836b80113d48585/5eb6b9f2f3dd3668c6b887e6_videochat_re-p-500.png',
    },
  ],
}
const PARTICIPATES_IN_IDEA_GENERATION: ChipKnowledgeBite = {
  key: 'Participates in idea generation',
  emoji: '💡',
  teaser:
    'Colleagues who participate in idea generation are problem solvers and innovators. They offer their opinions freely and like to try out new ways of doing things in a team.',
  suggestions: [
    {
      topic: 'Onboarding',
      icon: '🏂',
      content: [
        'Innovators come up with new ideas. Let them play out their strength by giving them a dedicated place in team settings to present their ideas.',
        'Individuals experienced in this role can help to make teamwork more effective. Integrate their creative perspectives by asking them about current team practices they would like to redesign.',
        'Sometimes innovators may regularly present their ideas to the team prematurely. If this is the case, you can prepare them for a productive exchange of ideas and information by setting fixed meetings for idea discussion.',
      ],
    },
  ],
  furtherReading: [
    {
      title: 'How to Unlock Your Team’s Creativity',
      summary:
        'How can leaders unleash the full range of creative potential on their teams? If a team is creatively blocked, a first step for managers is to examine whether the processes that surround people are holding them hostage in their thinking.',
      url: 'https://hbr.org/2019/01/how-to-unlock-your-teams-creativity',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
    {
      title: 'Teamwork Styles',
      summary: 'Learn more about the HiPeople Teamwork Styles.',
      url: 'https://hipeople.notion.site/Working-with-Others-42d355a0ed9f494793dfa64b96d4accb',
      image: 'https://uploads-ssl.webflow.com/5e74de595836b80113d48585/5eb6b9f2f3dd3668c6b887e6_videochat_re-p-500.png',
    },
  ],
}
const PERFORMS_ROUTINE_ACTIVITIES: ChipKnowledgeBite = {
  key: 'Performs routine activities',
  emoji: '✍️',
  teaser:
    "Colleagues who perform routine tasks are the ones everyone looks to when something needs to be done. They are reliable, and they won't hesitate to take on unpopular jobs for the team.",
  suggestions: [
    {
      topic: 'Onboarding',
      icon: '🏂',
      content: [
        'Doers enjoy getting things done and are not picky about their tasks. To play out this strength, let them crack down some regular jobs for the team.',
        'Sometimes you want new people with this teamwork style to contribute more of their own opinion in team meetings. Help your new colleague develop this skill by asking for their perspective in 1:1 and not team meetings.',
        'Doers can be a reliable backbone for any team. To help them thrive in their role, make sure you value all their small but significant contributions.',
      ],
    },
  ],
  furtherReading: [
    {
      title: 'Differing Work Styles Can Help Team Performance',
      summary:
        'Most leaders now recognize that the best teams leverage diversity to achieve long-term success. But many think about it in pretty narrow terms.',
      url: 'https://hbr.org/2015/04/differing-work-styles-can-help-team-performance',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
    {
      title: 'Teamwork Styles',
      summary: 'Learn more about the HiPeople Teamwork Styles.',
      url: 'https://hipeople.notion.site/Working-with-Others-42d355a0ed9f494793dfa64b96d4accb',
      image: 'https://uploads-ssl.webflow.com/5e74de595836b80113d48585/5eb6b9f2f3dd3668c6b887e6_videochat_re-p-500.png',
    },
  ],
}
const COMPETENCE: ChipKnowledgeBite = {
  key: 'Competence',
  emoji: '💫',
  teaser:
    'Colleagues who value competence tend to have high expectations for performance. They excel under conditions that emphasize resoluteness and results orientation.',
  suggestions: [
    {
      topic: 'Engagement',
      icon: '💍',
      content: [
        'Colleagues who are driven by competence might work long hours to achieve high-quality results. Frequently adjusting their benefits as a sign of appreciation will help you keep them engaged.',
        'Competency-driven individuals often value clear responsibilities. You can keep them engaged in day-to-day work by regularly aligning on the role expectations with them.',
        'The celebration of wins and internal competitions can significantly help competency-driven colleagues to keep up their engagement.',
      ],
    },
  ],
  furtherReading: [
    {
      title: 'Reward Your Employees — Without Breaking the Bank',
      summary:
        'Due to the current economic slowdown, many companies are finding themselves unable to afford large annual bonuses. What can organizations do to reward top performers and keep morale high while limiting spending?',
      url: 'https://hbr.org/2020/08/reward-your-employees-without-breaking-the-bank',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
    {
      title: 'Work Values',
      summary: 'Learn more about the HiPeople Work Values.',
      url: 'https://hipeople.notion.site/Culture-Add-a449f7df8bc74f598a7f17bc7d01514d',
      image: 'https://uploads-ssl.webflow.com/5e74de595836b80113d48585/5ef37955476d1c2cf49fc0ed_heureka.png',
    },
  ],
}
const COLLABORATION: ChipKnowledgeBite = {
  key: 'Collaboration',
  emoji: '✌️',
  teaser:
    'Colleagues who value collaboration tend to be people-oriented. They excel under conditions that emphasize fairness and teamwork.',
  suggestions: [
    {
      topic: 'Engagement',
      icon: '💍',
      content: [
        'People-oriented colleagues see group efforts as an essential part of work-life. Setting team goals and rewarding the group as a whole will help you to keep them engaged.',
        'Collaboration-driven individuals strongly value good team vibes. Keep them engaged by frequently checking in on how the team could improve the atmosphere.',
        'Colleagues who are motivated by collaboration can significantly benefit from team events, as they allow them to strengthen relationships.',
      ],
    },
  ],
  furtherReading: [
    {
      title: 'How To Maintain A Culture Of Collaboration As Your Company Grows',
      summary: 'Nowhere do first impressions count more than with work culture.',
      url: 'https://www.forbes.com/sites/serenitygibbons/2019/12/10/how-to-maintain-a-culture-of-collaboration-as-your-company-grows/?sh=5292fdc4f00b',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/forbes-logo.jpg',
    },
    {
      title: 'Work Values',
      summary: 'Learn more about the HiPeople Work Values.',
      url: 'https://hipeople.notion.site/Culture-Add-a449f7df8bc74f598a7f17bc7d01514d',
      image: 'https://uploads-ssl.webflow.com/5e74de595836b80113d48585/5ef37955476d1c2cf49fc0ed_heureka.png',
    },
  ],
}
const PARTICIPATION: ChipKnowledgeBite = {
  key: 'Participation',
  emoji: '✌️',
  teaser:
    'Colleagues who value participation tend to be people-oriented. They thrive in environments that emphasize transparency and appreciation of different perspectives.',
  suggestions: [
    {
      topic: 'Engagement',
      icon: '💍',
      content: [
        'Shared decision-making processes engage participation-driven individuals. In day-to-day work, you might want to set aside time in team sessions to ask for different perspectives on a particular plan or topic.',
        'The engagement of colleagues motivated by participation can significantly benefit from openly accessible meeting/decision documentation. This way, they can keep themselves in the loop on different topics.',
        'People-oriented colleagues see group efforts as an essential part of work-life. Setting team goals and rewarding the group as a whole will help you to keep them engaged.',
      ],
    },
  ],
  furtherReading: [
    {
      title: 'Decisions Are More Effective When More People Are Involved from the Start',
      summary:
        'Why is it so hard to get people to change? That’s one of the most common complaints I hear from executives in other organizations.',
      url: 'https://hbr.org/2016/03/decisions-are-more-effective-when-more-people-are-involved-from-the-start',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
    {
      title: 'Work Values',
      summary: 'Learn more about the HiPeople Work Values.',
      url: 'https://hipeople.notion.site/Culture-Add-a449f7df8bc74f598a7f17bc7d01514d',
      image: 'https://uploads-ssl.webflow.com/5e74de595836b80113d48585/5ef37955476d1c2cf49fc0ed_heureka.png',
    },
  ],
}
const SUPPORTIVENESS: ChipKnowledgeBite = {
  key: 'Supportiveness',
  emoji: '✌️',
  teaser:
    'Colleagues who value supportiveness tend to be people-oriented. They excel under conditions that emphasize social responsibility and strong bonds within a team.',
  suggestions: [
    {
      topic: 'Engagement',
      icon: '💍',
      content: [
        'Activities that demonstrate corporate responsibility engage supportiveness-driven individuals. If you open up opportunities for the colleague in this respect, they will very likely thank you for this.',
        "The engagement of colleagues motivated by supportiveness can significantly benefit from informal 1:1 meetings with other colleagues. This way, they can learn best about others' needs and stay engaged.",
        'People-oriented colleagues see group efforts as an essential part of work-life. Setting team goals and rewarding the group as a whole will help you to keep them engaged.',
      ],
    },
  ],
  furtherReading: [
    {
      title: 'How to Foster a Cooperative Culture',
      summary:
        'In today’s densely interconnected workplaces, working with others — closely, creatively, globally, and productively — drives organizational and personal effectiveness.',
      url: 'https://hbr.org/2009/01/four-ways-to-encourage-more-pr',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
    {
      title: 'Work Values',
      summary: 'Learn more about the HiPeople Work Values.',
      url: 'https://hipeople.notion.site/Culture-Add-a449f7df8bc74f598a7f17bc7d01514d',
      image: 'https://uploads-ssl.webflow.com/5e74de595836b80113d48585/5ef37955476d1c2cf49fc0ed_heureka.png',
    },
  ],
}
const GROWTH: ChipKnowledgeBite = {
  key: 'Growth',
  emoji: '💡',
  teaser:
    'Colleagues who value growth tend to be motivated by openness. They thrive in work environments where they can work on cutting-edge topics and have the possibility to take risks.',
  suggestions: [
    {
      topic: 'Engagement',
      icon: '💍',
      content: [
        'Colleagues who are driven by growth see learning as an essential part of work-life. Keep them engaged by regularly speaking about learning opportunities they could participate in.',
        'To be able to thrive, growth-driven colleagues need to take risks from time to time. Giving them projects and tasks that allow them to do so will keep them engaged.',
        "The engagement of colleagues motivated by growth can significantly benefit from regular hints on the overall importance of their contribution to the company's broad vision.",
      ],
    },
  ],
  furtherReading: [
    {
      title: 'How to Push Your Team to Take Risks and Experiment',
      summary:
        'Most managers I know want their employees to be curious and experimental, to take the initiative and develop new products and solutions.',
      url: 'https://hbr.org/2017/03/how-to-push-your-team-to-take-risks-and-experiment',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
    {
      title: 'Work Values',
      summary: 'Learn more about the HiPeople Work Values.',
      url: 'https://hipeople.notion.site/Culture-Add-a449f7df8bc74f598a7f17bc7d01514d',
      image: 'https://uploads-ssl.webflow.com/5e74de595836b80113d48585/5ef37955476d1c2cf49fc0ed_heureka.png',
    },
  ],
}
const CHANGE: ChipKnowledgeBite = {
  key: 'Change',
  emoji: '💡',
  teaser:
    'Colleagues who value change tend to be motivated by openness. They thrive in work environments where experimentation and taking an innovative approach are appreciated.',
  suggestions: [
    {
      topic: 'Engagement',
      icon: '💍',
      content: [
        'Activities that allow them to jump onto a new topic engage change-driven colleagues. If you open up opportunities for the colleague in this respect, they will very likely thank you for this.',
        'Colleagues driven by change can get fatigued by routine. You can provide them with a variety of tasks or different work environments to help them stay engaged.',
        "The engagement of colleagues motivated by change can significantly benefit from regular hints on the overall importance of their contribution to the company's broad vision.",
      ],
    },
  ],
  furtherReading: [
    {
      title: 'A Step-by-Step Guide to Smart Business Experiments',
      summary:
        'The power of analytics in decision making is well understood, but few companies have what it takes to successfully implement a complex analytics program.',
      url: 'https://hbr.org/2011/03/a-step-by-step-guide-to-smart-business-experiments',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
    {
      title: 'Work Values',
      summary: 'Learn more about the HiPeople Work Values.',
      url: 'https://hipeople.notion.site/Culture-Add-a449f7df8bc74f598a7f17bc7d01514d',
      image: 'https://uploads-ssl.webflow.com/5e74de595836b80113d48585/5ef37955476d1c2cf49fc0ed_heureka.png',
    },
  ],
}
const AUTONOMY: ChipKnowledgeBite = {
  key: 'Autonomy',
  emoji: '💡',
  teaser:
    'Colleagues who value autonomy tend to be motivated by openness. They thrive in workplaces that allow freedom and free expression.',
  suggestions: [
    {
      topic: 'Engagement',
      icon: '💍',
      content: [
        'Colleagues who are driven by autonomy see freedom as an essential part of work-life. Keep them engaged by not exposing them to too many rules and allowing them to make decisions.',
        "The engagement of colleagues motivated by autonomy can significantly benefit from regular hints on the overall importance of their contribution to the company's broad vision.",
        'Autonomy-driven colleagues value different perspectives. Taking this into account, candid feedback sessions are an excellent way of keeping them engaged.',
      ],
    },
  ],
  furtherReading: [
    {
      title: 'How to Give Your Team the Right Amount of Autonomy',
      summary:
        'Leaders often say they want to empower autonomous teams and free the front line to innovate, but they also fear the chaos that might be unleashed if they do.',
      url: 'https://hbr.org/2019/07/how-to-give-your-team-the-right-amount-of-autonomy',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
    {
      title: 'Work Values',
      summary: 'Learn more about the HiPeople Work Values.',
      url: 'https://hipeople.notion.site/Culture-Add-a449f7df8bc74f598a7f17bc7d01514d',
      image: 'https://uploads-ssl.webflow.com/5e74de595836b80113d48585/5ef37955476d1c2cf49fc0ed_heureka.png',
    },
  ],
}
const COMPETITION: ChipKnowledgeBite = {
  key: 'Competition',
  emoji: '🎯',
  teaser:
    'Colleagues who value competition tend to have high expectations for performance. They thrive in work environments that encourage straightforwardness and help them to take advantage of opportunities quickly.',
  suggestions: [
    {
      topic: 'Engagement',
      icon: '💍',
      content: [
        'Colleagues who are driven by competition appreciate working at a fast pace. Keep them engaged by giving them quick answers and decisions to their requests.',
        'Competition-driven colleagues appreciate clear communication. Taking this into account, you can try to be very specific when requesting something from them.',
        'The celebration of wins and internal competitions can significantly help competition-driven colleagues to keep up their engagement.',
      ],
    },
  ],
  furtherReading: [
    {
      title: 'The Pros and Cons of Competition Among Employees',
      summary:
        'New research shows that the way in which leaders communicate about competition can make employees experience either anxiety or excitement, and those feelings influence whether they react positively or negatively.',
      url: 'https://hbr.org/2017/03/the-pros-and-cons-of-competition-among-employees',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
    {
      title: 'Work Values',
      summary: 'Learn more about the HiPeople Work Values.',
      url: 'https://hipeople.notion.site/Culture-Add-a449f7df8bc74f598a7f17bc7d01514d',
      image: 'https://uploads-ssl.webflow.com/5e74de595836b80113d48585/5ef37955476d1c2cf49fc0ed_heureka.png',
    },
  ],
}
const ACHIEVEMENT: ChipKnowledgeBite = {
  key: 'Achievement',
  emoji: '🎯',
  teaser:
    'Colleagues who value achievement have high expectations for performance. They thrive in work environments that reward initiative and give a sufficient chance for professional growth.',
  suggestions: [
    {
      topic: 'Engagement',
      icon: '💍',
      content: [
        'Colleagues who are driven by achievement take the initiative at work. Keep them engaged by acknowledging their ambition and their accomplishments regularly.',
        'The celebration of wins and internal competitions can significantly help achievement-driven colleagues to keep up their engagement.',
        'Providing development and growth perspectives enables achievement-driven colleagues to actively pursue their career ambitions.',
      ],
    },
  ],
  furtherReading: [
    {
      title: 'How Ambitious Should You Be?',
      summary:
        'Fostering a healthy level of ambition is not easy, and amidst so much uncertainty, it may seem like a low priority.',
      url: 'https://hbr.org/2020/04/how-ambitious-should-you-be',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
    {
      title: 'Work Values',
      summary: 'Learn more about the HiPeople Work Values.',
      url: 'https://hipeople.notion.site/Culture-Add-a449f7df8bc74f598a7f17bc7d01514d',
      image: 'https://uploads-ssl.webflow.com/5e74de595836b80113d48585/5ef37955476d1c2cf49fc0ed_heureka.png',
    },
  ],
}
const CONSISTENCY: ChipKnowledgeBite = {
  key: 'Consistency',
  emoji: '🎯',
  teaser:
    'Colleagues who value consistency tend to have high expectations of preciseness. They excel under conditions that emphasize replicable processes and routines.',
  suggestions: [
    {
      topic: 'Engagement',
      icon: '💍',
      content: [
        'Consistency-driven individuals often value the smooth functioning of a process. You can keep them engaged in day-to-day work by regularly letting them formalize procedures.',
        'Activities that allow them to establish routines engage consistency-driven colleagues. If you open up opportunities for the colleague in this respect, they will very likely thank you for this.',
        'The engagement of colleagues motivated by consistency can benefit significantly from clearly defined and quantifiable deliverables.',
      ],
    },
  ],
  furtherReading: [
    {
      title: 'To Achieve Big Goals, Start with Small Habits',
      summary: 'When presented with a problem that requires behavior change, we pounce on it with big goals.',
      url: 'https://hbr.org/2020/01/to-achieve-big-goals-start-with-small-habits',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
    {
      title: 'Work Values',
      summary: 'Learn more about the HiPeople Work Values.',
      url: 'https://hipeople.notion.site/Culture-Add-a449f7df8bc74f598a7f17bc7d01514d',
      image: 'https://uploads-ssl.webflow.com/5e74de595836b80113d48585/5ef37955476d1c2cf49fc0ed_heureka.png',
    },
  ],
}
const RULES: ChipKnowledgeBite = {
  key: 'Rules',
  emoji: '🔎',
  teaser:
    'Colleagues who value rules tend to have high expectations of preciseness. They excel under conditions that emphasize conformity and accountability.',
  suggestions: [
    {
      topic: 'Engagement',
      icon: '💍',
      content: [
        'Rule-driven individuals often value a precise distinction between responsibilities. You can keep them engaged in day-to-day work by regularly aligning on the role expectations with them.',
        'Colleagues who are driven by rules get highly demotivated if established rules are being violated. To keep them engaged, you can frequently check-in on their perception of rule violations.',
        'The engagement of colleagues motivated by rules can benefit significantly from clearly defined and quantifiable deliverables.',
      ],
    },
  ],
  furtherReading: [
    {
      title: 'Write Down Your Team’s Unwritten Rules',
      summary:
        'Are your employees aware of your company’s or team’s unwritten rules? Do they know if it’s okay to turn off their video during a Zoom meeting?',
      url: 'https://hbr.org/2020/10/write-down-your-teams-unwritten-rules',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
    {
      title: 'Work Values',
      summary: 'Learn more about the HiPeople Work Values.',
      url: 'https://hipeople.notion.site/Culture-Add-a449f7df8bc74f598a7f17bc7d01514d',
      image: 'https://uploads-ssl.webflow.com/5e74de595836b80113d48585/5ef37955476d1c2cf49fc0ed_heureka.png',
    },
  ],
}
const STRUCTURE: ChipKnowledgeBite = {
  key: 'Structure',
  emoji: '🔎',
  teaser:
    'Colleagues who value structure tend to have high expectations of preciseness. They excel under conditions that emphasize detailed plans and explicit tasks.',
  suggestions: [
    {
      topic: 'Engagement',
      icon: '💍',
      content: [
        'Structure-driven individuals often value a clear plan they can follow. Keep them engaged by not changing plans too frequently.',
        'Activities that allow them to measure and control processes engage structure-driven colleagues. Engage your new colleague and open up opportunities for them in this respect.',
        'The engagement of colleagues motivated by structure can benefit significantly from clearly defined and quantifiable deliverables.',
      ],
    },
  ],
  furtherReading: [
    {
      title: 'To Get Better at Your Job, Work Practice into Your Routine',
      summary:
        'Skill-building requires practice. In most endeavors, those who want to improve take this as self-evident.',
      url: 'https://hbr.org/2016/01/to-get-better-at-your-job-work-practice-into-your-routine',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
    {
      title: 'Work Values',
      summary: 'Learn more about the HiPeople Work Values.',
      url: 'https://hipeople.notion.site/Culture-Add-a449f7df8bc74f598a7f17bc7d01514d',
      image: 'https://uploads-ssl.webflow.com/5e74de595836b80113d48585/5ef37955476d1c2cf49fc0ed_heureka.png',
    },
  ],
}
const GETTING_HELP_WHEN_NEEDED: ChipKnowledgeBite = {
  key: 'Getting help when needed',
  emoji: '👫',
  teaser:
    'Asking for help can be challenging, mostly because coworkers are worried that it makes them look incompetent. However, asking for help when necessary can boost individual and team performance significantly.',
  suggestions: [
    {
      topic: 'Development',
      icon: '📈',
      content: [
        'Lead by example: Frequently ask your new colleague for help with smaller, maybe even everyday things.',
        'Facilitate vulnerability by regularly emphasizing that it is okay to ask for help.',
        'Encourage the new colleague by showing appreciation when they act vulnerable.',
      ],
    },
  ],
  furtherReading: [
    {
      title: 'Why We Don’t Let Coworkers Help Us, Even When We Need It',
      summary:
        'When colleagues display helpful and cooperative “citizenship behaviors,” they tend to develop high-quality social connections, which can improve individual and team performance.',
      url: 'https://hbr.org/2018/03/why-we-dont-let-coworkers-help-us-even-when-we-need-it',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
  ],
}
const QUALITY_OF_WORK: ChipKnowledgeBite = {
  key: 'Quality of Work',
  emoji: '🔎',
  teaser:
    'Low work quality often results from not paying attention to details. Being thorough is critical for a good performance, and luckily, it can be developed by anyone!',
  suggestions: [
    {
      topic: 'Development',
      icon: '📈',
      content: [
        'Ask if you can provide them a tool that makes small errors easier to avoid (e.g. language checks).',
        'Take some time during the onboarding process to show your new colleagues the errors they make and how they can prevent them.',
        'Encourage the new colleague by showing appreciation in situations where they pay attention to detail.',
      ],
    },
  ],
  furtherReading: [
    {
      title: 'The Pros and Cons of Perfectionism, According to Research',
      summary: 'Extensive research has found the psychology of perfectionism to be rather complex.',
      url: 'https://hbr.org/2018/12/the-pros-and-cons-of-perfectionism-according-to-research',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
  ],
}
const QUANTITY_OF_WORK: ChipKnowledgeBite = {
  key: 'Quantity of Work',
  emoji: '🚗',
  teaser:
    'A lack of work quantity is often a consequence of focusing on the wrong actions. Fortunately, new colleagues can learn to align their focus and hence perform even better.',
  suggestions: [
    {
      topic: 'Development',
      icon: '📈',
      content: [
        'Take some time to discuss time estimations for a new assignment. Afterward, set a clear timeline together.',
        'Align on expectations by clarifying and discussing expected results. Maybe even support your new colleague in defining the priorities of subtasks.',
        'Support the new colleague by holding them accountable for their results. Celebrate them if they make it!',
      ],
    },
  ],
  furtherReading: [
    {
      title: 'Want to Be More Productive? Try Doing Less.',
      summary:
        'If we want to ramp up our productivity and happiness at home and at work, we should actually be doing less. But that’s incredibly difficult as you’re balancing work, parenting, friendships, and more.',
      url: 'https://hbr.org/2020/05/want-to-be-more-productive-try-doing-less',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
  ],
}
const PLANNING: ChipKnowledgeBite = {
  key: 'Planning',
  emoji: '🎯',
  teaser:
    'Colleagues who find it hard to plan often have difficulty developing a detailed picture of the future. However, everyone can create a more consistent approach towards structuring jobs.',
  suggestions: [
    {
      topic: 'Development',
      icon: '📈',
      content: [
        'Offer your collaboration and feedback on the first planning tasks.',
        'Encourage the new colleague in their behavior by emphasizing that their plans do not need to be perfect.',
        'Show them different planning methods (e.g. mind-maps vs. spreadsheets), so they can reevaluate which works best for them.',
      ],
    },
  ],
  furtherReading: [
    {
      title: 'A Way to Plan If You’re Bad at Planning',
      summary:
        'Planning can be hard for everyone. But even if you feel like planning just doesn’t come to you naturally, there are steps you can take to get better at it.',
      url: 'https://hbr.org/2017/07/a-way-to-plan-if-youre-bad-at-planning',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
  ],
}
const RESULTS_ORIENTATION: ChipKnowledgeBite = {
  key: 'Results Orientation',
  emoji: '🛠',
  teaser:
    'Low results orientation is often a consequence of vague ideas of what the work outputs should look like. Yet, good expectations management contributes to the development of results orientation.',
  suggestions: [
    {
      topic: 'Development',
      icon: '📈',
      content: [
        'Take some time apart to discuss time estimations for a new assignment. Afterward, commit on a clear timeline together.',
        'Align on expectations by clarifying and discussing expected outcomes. Maybe even support your new colleague in defining the priorities of subtasks.',
        'Support the new colleague by holding them accountable for their results. Celebrate them if they make it!',
      ],
    },
  ],
  furtherReading: [
    {
      title: 'How Managers Drive Results and Employee Engagement at the Same Time',
      summary:
        'Is it possible to be a high-standards, results-driven leader while at the same time building an engaged, fun-to-work-with team? Data from 60,000 managers suggests that the answer is yes.',
      url: 'https://hbr.org/2017/06/how-managers-drive-results-and-employee-engagement-at-the-same-time',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
  ],
}
const PRIORITIZATION: ChipKnowledgeBite = {
  key: 'Prioritization',
  emoji: '🎯',
  teaser:
    'It is often a lack of awareness of the urgency of tasks that causes challenges in prioritization. Luckily, coworkers can assist colleagues in gaining this understanding!',
  suggestions: [
    {
      topic: 'Development',
      icon: '📈',
      content: [
        'Plan regular mini check-ins to ask the new colleague if there are any barriers you could help removing.',
        'Break down tasks into smaller subtasks and assign priorities for each together with them.',
        "Make sure to allocate some time in 1:1's to discuss overarching priorities for their role.",
      ],
    },
  ],
  furtherReading: [
    {
      title: 'How to Manage Someone Who Thinks Everything Is Urgent',
      summary:
        'We’ve all been in situations in which we couldn’t wait for a slow-moving or overly cautious employee to take action.',
      url: 'https://hbr.org/2017/08/how-to-manage-someone-who-thinks-everything-is-urgent',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
  ],
}
const EFFICIENCY: ChipKnowledgeBite = {
  key: 'Efficiency',
  emoji: '🚗',
  teaser:
    'Usually, it is a poor understanding of task scopes that causes challenges with efficiency. Hence, improving awareness about task scopes is an effective way to boost performance.',
  suggestions: [
    {
      topic: 'Development',
      icon: '📈',
      content: [
        'Take some time apart to discuss time estimations for a new assignment. Afterward, commit on a clear timeline together.',
        'Align on expectations by clarifying and discussing expected outcomes. Maybe even support your new colleague in defining the priorities of subtasks.',
        'Support the new colleague by holding them accountable for their results. Celebrate them if they make it!',
      ],
    },
  ],
  furtherReading: [
    {
      title: 'Time Management Is About More Than Life Hacks',
      summary:
        'There is certainly no shortage of advice — books and blogs, hacks and apps — all created to boost time management with a bevy of ready-to-apply tools.',
      url: 'https://hbr.org/2020/01/time-management-is-about-more-than-life-hacks',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
  ],
}
const WORKING_WITH_OTHERS: ChipKnowledgeBite = {
  key: 'Working with Others',
  emoji: '👫',
  teaser:
    'Collaborating with others can be challenging for a variety of reasons. Nevertheless, colleagues with challenges in this regard can develop through positive experiences with other coworkers.',
  suggestions: [
    {
      topic: 'Development',
      icon: '📈',
      content: [
        'Try to find a mentor or onboarding buddy who has overcome challenges in working with others.',
        'Facilitate connections with peers that have similar interests.',
        'Arrange times for small informal team events (e.g. lunch) when the new colleague begins their new role.',
      ],
    },
  ],
  furtherReading: [
    {
      title: 'Be an Emotionally Supportive Colleague — Without Prying',
      summary:
        'Research shows that employees who bring their authentic selves to work perform better and report greater job satisfaction. But what if a colleague doesn’t feel comfortable opening up?',
      url: 'https://hbr.org/tip/2020/05/be-an-emotionally-supportive-colleague-without-prying',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
  ],
}
const COMING_UP_WITH_IDEAS: ChipKnowledgeBite = {
  key: 'Coming up with Ideas',
  emoji: '💡',
  teaser:
    'Coming up with ideas can be challenging, especially because colleagues may think their ideas are inappropriate or premature. However, sharing ideas can help boost innovation and productivity within a team.',
  suggestions: [
    {
      topic: 'Development',
      icon: '📈',
      content: [
        'Instead of asking for their perspective in group settings, start by asking them in 1:1 situations. Then encourage them to share exciting points in the next team session!',
        'Use creativity methods such as asking for the worst possible idea to make coming up with ideas more playful.',
        'Spot times where the new colleague is not too distracted by other topics to strike the right timing when asking for perspectives.',
      ],
    },
  ],
  furtherReading: [
    {
      title: 'To Come Up with a Good Idea, Start by Imagining the Worst Idea Possible',
      summary:
        'How have chefs invent new dishes and scientists sequence DNA? The answer may surprise you: reverse thinking.',
      url: 'https://hbr.org/2017/08/to-come-up-with-a-good-idea-start-by-imagining-the-worst-idea-possible',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
  ],
}
const COMMUNICATING_WITH_OTHERS: ChipKnowledgeBite = {
  key: 'Communicating with Others',
  emoji: '👫',
  teaser:
    'Communicating with others can be challenging for a variety of reasons. Yet colleagues with challenges in this regard can enhance their communication skills through many means.',
  suggestions: [
    {
      topic: 'Development',
      icon: '📈',
      content: [
        'Try to find a mentor or onboarding buddy who has overcome challenges in communicating with others.',
        'Lead by example by taking the time to provide the new colleague with honest feedback about their communication.',
        'Provide resources about communication methods and review them with the new candidate or within the team.',
      ],
    },
  ],
  furtherReading: [
    {
      title: 'Five Ways to Sharpen Your Communication Skills',
      summary:
        'We need people who can communicate! Raise your hand if you have heard this line at least a thousand times.',
      url: 'https://hbr.org/2009/04/five-things-leaders-can-do-to',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
  ],
}
const SHOWING_INITIATIVE: ChipKnowledgeBite = {
  key: 'Showing Initiative',
  emoji: '💫',
  teaser:
    "A lack of initiative is often due to a poor understanding of one's own contribution to the company's success. Strengthening the perception of their contribution is therefore a key driver in developing a colleague's initiative.",
  suggestions: [
    {
      topic: 'Development',
      icon: '📈',
      content: [
        'Frequently outline the importance of their contribution to overall company success.',
        'Point out specific areas where you expect them to be proactive.',
        'Allow the new colleague to make independent decisions that do not require approval whenever possible.',
      ],
    },
  ],
  furtherReading: [
    {
      title: 'Research Shows a Simple Way to Increase Your Engagement at Work',
      summary:
        'A recent research study investigated two types of daily planning and how they influence employee engagement in dynamic work environments.',
      url: 'https://hbr.org/2018/04/research-shows-a-simple-way-to-increase-your-engagement-at-work',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
  ],
}
const TAKING_OWNERSHIP: ChipKnowledgeBite = {
  key: 'Taking Ownership',
  emoji: '💫',
  teaser:
    "Often, colleagues do not take ownership because they believe it is someone else's responsibility to take action. Hence, establishing clear expectations and responsibilities is key to facilitating ownership.",
  suggestions: [
    {
      topic: 'Development',
      icon: '📈',
      content: [
        'Embrace ownership by clearly outlining the responsibilities of their role. It is also helpful to consider what is not part of their position.',
        'Allow the new colleague to make independent decisions that do not require approval whenever possible.',
        'Empower the candidate by regularly showing up ways to keep others in the loop about their work.',
      ],
    },
  ],
  furtherReading: [
    {
      title: 'How to Make Employees Feel Like They Own Their Work',
      summary:
        'Most of us spend a lot of time at work. Yet a lot of people feel their jobs are not as fulfilling or engaging as they could be.',
      url: 'https://hbr.org/2015/12/how-to-make-employees-feel-like-they-own-their-work',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
  ],
}
const OPENNESS_FOR_FEEDBACK: ChipKnowledgeBite = {
  key: 'Openness for Feedback',
  emoji: '🤝',
  teaser:
    'Being open to feedback is challenging for many coworkers because it demands one to risk being vulnerable. Showing colleagues that it is worth taking this risk will help them develop their ability to take feedback and improve!',
  suggestions: [
    {
      topic: 'Development',
      icon: '📈',
      content: [
        'Try to find the right place (e.g. a tool or a specific meeting) to collect and provide feedback.',
        'Lead by example by regularly asking for feedback yourself.',
        'Spot times where the new colleague is not too distracted by other topics to strike the right timing when giving feedback.',
      ],
    },
  ],
  furtherReading: [
    {
      title: "The Gourmet Chef's Guide to Giving Feedback",
      summary:
        'The ability to give constructive feedback is a key communications skill. This article uses the metaphor of preparing and serving a gourmet meal to illustrate how this can be done most effectively.',
      url: 'https://hr.mit.edu/learning-topics/comm/articles/gourmet',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/mit-logo.png',
    },
  ],
}
const CUSTOMER_ORIENTATION: ChipKnowledgeBite = {
  key: 'Customer Orientation',
  emoji: '👔',
  teaser:
    'Challenges in customer orientation often result from missing interactions with clients. By increasing the understanding of the customer needs, every colleague can become more customer-oriented!',
  suggestions: [
    {
      topic: 'Development',
      icon: '📈',
      content: [
        'Set up sessions where the new colleague reviews existing customer interaction (e.g. support messages), if possible, even let them speak to a customer.',
        'To raise awareness, make sure to push relevant customer-related stories in team settings regularly.',
        'Discuss customer-related metrics that you can include in performance reviews.',
      ],
    },
  ],
  furtherReading: [
    {
      title: '6 Ways to Build a Customer-Centric Culture',
      summary:
        'Companies have been trying to adopt customer centricity for nearly 20 years now. Yet only 14% of marketers say their company really focuses on customer centricity.',
      url: 'https://hbr.org/2018/10/6-ways-to-build-a-customer-centric-culture',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
  ],
}
const DOMAIN_EXPERTISE: ChipKnowledgeBite = {
  key: 'Domain Expertise',
  emoji: '👔',
  teaser:
    'Low domain expertise is often the consequence of little learning opportunities in a specific field. However, these opportunities can be provided with not too much effort!',
  suggestions: [
    {
      topic: 'Development',
      icon: '📈',
      content: [
        'Facilitate learning from others by introducing the new colleague to internal and external subject matter experts.',
        'Offer learning opportunities and subscriptions for key publications right from when the new colleague starts with their new role.',
        'Ask a group of experts to create a list of news sources for the domain (e.g. newsletters), hand over the list to the new colleague.',
      ],
    },
  ],
  furtherReading: [
    {
      title: 'How to Build Expertise in a New Field',
      summary:
        'Better pay, more joy in the job, or prerequisite to promotion? Whatever your reasons for deciding to build expertise in a new field, the question is how to get there.',
      url: 'https://hbr.org/2015/04/how-to-build-expertise-in-a-new-field',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
  ],
}
const FLEXIBILITY: ChipKnowledgeBite = {
  key: 'Flexibility',
  emoji: '😌',
  teaser:
    'Being flexible can be challenging since one might not know if one is capable of handling a new situation. Assuring your colleagues of their capability to cope with different conditions will boost their flexibility and performance.',
  suggestions: [
    {
      topic: 'Development',
      icon: '📈',
      content: [
        'Enable the candidate to learn about different perspectives by suggesting learning activities that are not in their immediate area of expertise.',
        'Set up fixed meeting routines that allow the candidate to establish order in their daily work. This will enable them to be flexible outside of this routine.',
        'Offer the new colleague additional resources and feedback whenever you expect uncertain conditions.',
      ],
    },
  ],
  furtherReading: [
    {
      title: 'To Build an Agile Team, Commit to Organizational Stability',
      summary:
        'Companies that are best able to bounce back from jolts and adjust have a stable foundation. It provides the confidence, security, and optimism people need to keep calm, act rationally, and swiftly adapt to disruptive change.',
      url: 'https://hbr.org/2020/04/to-build-an-agile-team-commit-to-organizational-stability',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
  ],
}
const HANDLING_CONFLICT: ChipKnowledgeBite = {
  key: 'Handling Conflict',
  emoji: '🤝',
  teaser:
    'There are conflicts in every team, and yet colleagues often find it hard to manage them. Such challenges often arise because co-workers are not convinced of the benefits and necessity of conflict.',
  suggestions: [
    {
      topic: 'Development',
      icon: '📈',
      content: [
        'Outline how different functions should be in tension with each other and how this drives the overall business.',
        'Create space to discuss different perspectives of a controversy.',
        'Lead by example by starting to be outspoken in a low-stakes conversation.',
      ],
    },
  ],
  furtherReading: [
    {
      title: 'An Exercise to Help Your Team Feel More Comfortable with Conflict',
      summary:
        'The ability to get issues on the table and work through them constructively is critical to having a healthy culture.',
      url: 'https://hbr.org/2019/03/an-exercise-to-help-your-team-feel-more-comfortable-with-conflict',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
  ],
}
const HANDLING_UNCERTAINTY: ChipKnowledgeBite = {
  key: 'Handling Uncertainty',
  emoji: '😌',
  teaser:
    'Uncertainties can cause anxiety, which is why many people have trouble coping with them. By empowering your new colleagues to deal with this anxiety, you can help them thrive in uncertain situations.',
  suggestions: [
    {
      topic: 'Development',
      icon: '📈',
      content: [
        'Reframe uncertain situations together with the new colleague by outlining the opportunities they bring for them.',
        'Offer the new colleague additional resources and feedback whenever you expect uncertain conditions.',
        'Set up fixed meeting routines that allow the candidate to establish order in their daily work.',
      ],
    },
  ],
  furtherReading: [
    {
      title: 'You’re Not Powerless in the Face of Uncertainty',
      summary:
        'While part of our capacity to deal with the unknown is innate, a larger portion is learned. Those who develop this “uncertainty capability” are more creative, more successful, and better able to turn uncertainty into possibility.',
      url: 'https://hbr.org/2020/03/youre-not-powerless-in-the-face-of-uncertainty',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
  ],
}
const DEALING_WITH_MISTAKES: ChipKnowledgeBite = {
  key: 'Dealing with Mistakes',
  emoji: '🛠',
  teaser:
    'Handling mistakes can be challenging, mostly because coworkers are worried that errors make them look incompetent. However, a good reflection on mistakes is crucial for learning and improvement.',
  suggestions: [
    {
      topic: 'Development',
      icon: '📈',
      content: [
        'Once a mistake occurs, focus on the future. Discuss a process that could help to avoid the same mistake in the future.',
        'Try to talk about mistakes in 1:1 situations, not in groups. Once discussed privately, share the learnings with the team.',
        'Inspire the new colleague to learn from failures by emphasizing how other colleagues in your company have learned from them.',
      ],
    },
  ],
  furtherReading: [
    {
      title: 'What to Say When Your Employee Makes a Mistake',
      summary:
        'If you’re dissatisfied with someone’s performance, it’s easy to let your emotions get the better of you.',
      url: 'https://hbr.org/2019/09/what-to-say-when-your-employee-makes-a-mistake',
      image: 'https://hipeople-brand.s3.eu-central-1.amazonaws.com/hbr-logo.jpg',
    },
  ],
}

const KNOWLEDGE_BITES: { [key: string]: ChipKnowledgeBite } = {
  [POSITIVE.key]: POSITIVE,
  [RESPECTFUL.key]: RESPECTFUL,
  [LEADING.key]: LEADING,
  [EMPATHETIC.key]: EMPATHETIC,
  [CREATIVE.key]: CREATIVE,
  [CLEVER.key]: CLEVER,
  [DETAILED.key]: DETAILED,
  [DEPENDABLE.key]: DEPENDABLE,
  [BALANCED.key]: BALANCED,
  [CALM.key]: CALM,
  [LISTENS_AND_AGREES.key]: LISTENS_AND_AGREES,
  [DIRECTS_AND_ORGANIZES.key]: DIRECTS_AND_ORGANIZES,
  [MOTIVATES_TO_TAKE_ACTION.key]: MOTIVATES_TO_TAKE_ACTION,
  [TAKES_A_CRITICAL_VIEW.key]: TAKES_A_CRITICAL_VIEW,
  [MAINTAINS_GROUP_HARMONY.key]: MAINTAINS_GROUP_HARMONY,
  [COORDINATES_ACTIVITIES.key]: COORDINATES_ACTIVITIES,
  [JUMPS_IN_AS_SUPPORTER.key]: JUMPS_IN_AS_SUPPORTER,
  [EVALUATES_OUTPUTS.key]: EVALUATES_OUTPUTS,
  [PARTICIPATES_IN_IDEA_GENERATION.key]: PARTICIPATES_IN_IDEA_GENERATION,
  [PERFORMS_ROUTINE_ACTIVITIES.key]: PERFORMS_ROUTINE_ACTIVITIES,
  [COMPETENCE.key]: COMPETENCE,
  [COLLABORATION.key]: COLLABORATION,
  [PARTICIPATION.key]: PARTICIPATION,
  [SUPPORTIVENESS.key]: SUPPORTIVENESS,
  [GROWTH.key]: GROWTH,
  [CHANGE.key]: CHANGE,
  [AUTONOMY.key]: AUTONOMY,
  [COMPETITION.key]: COMPETITION,
  [ACHIEVEMENT.key]: ACHIEVEMENT,
  [CONSISTENCY.key]: CONSISTENCY,
  [RULES.key]: RULES,
  [STRUCTURE.key]: STRUCTURE,
  [GETTING_HELP_WHEN_NEEDED.key]: GETTING_HELP_WHEN_NEEDED,
  [QUALITY_OF_WORK.key]: QUALITY_OF_WORK,
  [QUANTITY_OF_WORK.key]: QUANTITY_OF_WORK,
  [PLANNING.key]: PLANNING,
  [RESULTS_ORIENTATION.key]: RESULTS_ORIENTATION,
  [PRIORITIZATION.key]: PRIORITIZATION,
  [EFFICIENCY.key]: EFFICIENCY,
  [WORKING_WITH_OTHERS.key]: WORKING_WITH_OTHERS,
  [COMING_UP_WITH_IDEAS.key]: COMING_UP_WITH_IDEAS,
  [COMMUNICATING_WITH_OTHERS.key]: COMMUNICATING_WITH_OTHERS,
  [SHOWING_INITIATIVE.key]: SHOWING_INITIATIVE,
  [TAKING_OWNERSHIP.key]: TAKING_OWNERSHIP,
  [OPENNESS_FOR_FEEDBACK.key]: OPENNESS_FOR_FEEDBACK,
  [CUSTOMER_ORIENTATION.key]: CUSTOMER_ORIENTATION,
  [DOMAIN_EXPERTISE.key]: DOMAIN_EXPERTISE,
  [FLEXIBILITY.key]: FLEXIBILITY,
  [HANDLING_CONFLICT.key]: HANDLING_CONFLICT,
  [HANDLING_UNCERTAINTY.key]: HANDLING_UNCERTAINTY,
  [DEALING_WITH_MISTAKES.key]: DEALING_WITH_MISTAKES,
}

export function getKnowledgeBiteByKey(key: string): ChipKnowledgeBite | undefined {
  return KNOWLEDGE_BITES[key]
}

export function getCopyByTopic(topic: Topic): string {
  if (topic === 'Onboarding') return 'Ideas that have worked to onboard candidates with this profile'
  if (topic === 'Engagement') return 'Ideas that have worked to engage candidates with this profile'
  return 'Ideas that have worked to develop candidates with this profile'
}
