import React from 'react'
import { rem, style } from '../../core'

const DEFAULT_SIZE = 1.5

interface Props {
  size?: number
}

const Container = style()
  .with((props: Props) =>
    style().size({
      width: rem(props.size || DEFAULT_SIZE),
      height: rem(props.size || DEFAULT_SIZE),
    }),
  )
  .element()

const Component = (props: Props) => {
  return (
    <Container {...props}>
      <div className="spinner"></div>
    </Container>
  )
}

export default Component
Component.displayName = 'Spinner'
