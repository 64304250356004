import DropdownField, { Option } from 'components/DropdownField'
import { ColumnFilterElementTemplateOptions } from 'primereact/column'
import React from 'react'
import { allAssessmentStatuses, allHiringStatuses, allRefCheckStatuses, Status, statusToText } from 'utils/status'

const StatusDropdown = (
  options: ColumnFilterElementTemplateOptions,
  statuses: Status[],
  statusType: string,
  placeholder: string = 'Select status',
) => {
  return (
    <DropdownField
      placeholder={placeholder}
      value={options.value}
      onChange={value => {
        options.filterCallback(value, options.index)
      }}
    >
      {statuses.map(s => (
        <Option key={`${options.index}-${statusType}-${s}`} value={s}>
          {statusToText(s)}
        </Option>
      ))}
    </DropdownField>
  )
}

export const AssessmentStatusDropdown = (options: ColumnFilterElementTemplateOptions) => {
  return StatusDropdown(options, allAssessmentStatuses, 'assessment')
}

export const RefCheckStatusDropdown = (options: ColumnFilterElementTemplateOptions) => {
  return StatusDropdown(options, allRefCheckStatuses, 'refcheck')
}

export const HiringStatusDropdown = (options: ColumnFilterElementTemplateOptions) => {
  return StatusDropdown(options, allHiringStatuses, 'hiring', 'Select stage')
}
