import { lookup } from '../core/state'
import { RootState } from '../store'
import * as api from './index'
import { get, post, put } from './methods'
import { APIResponse, Entity } from './request'
import { Fields as UserFields } from './users'

export const RESOURCE = 'teams'

export interface Fields {
  id: string
  name: string
  location: string
  organization_id: string
  created_at: number
  updated_at: number
  is_active: boolean
}

export function empty(id: string): Entity<Fields> {
  return {
    id,
    resource: RESOURCE,
    fields: {
      id,
      name: '',
      location: '',
      organization_id: '',
      created_at: 0,
      updated_at: 0,
      is_active: false,
    },
  }
}

export async function invite(
  teamid: string,
  email: string,
  name: string,
  isOrgAdmin: boolean,
  permissions?: api.userPermissions.UpdatePayloadPermissions,
): Promise<APIResponse<Entity<UserFields>>> {
  return post<Entity<UserFields>>(`/teams/${teamid}/invite`, {
    email,
    name,
    is_org_admin: isOrgAdmin,
    permissions,
  })
}

export interface TeamUserFields {
  id: string
  user_id: string
  team_id: string
}
export interface TeamUserFields {
  id: string
  team_id: string
  user_id: string
}

export async function loadByUserId(id: string): Promise<APIResponse<Entity<TeamUserFields>[]>> {
  return get<Entity<TeamUserFields>[]>(`users/${id}/teams?include=teams&limit=250`)
}

export async function updateTeamName(teamid: string, name: string): Promise<APIResponse<Entity<TeamUserFields>>> {
  return put<Entity<TeamUserFields>>(`teams/${teamid}/name`, {
    name: name,
  })
}

export async function updateTeamLocation(
  teamid: string,
  location: string,
): Promise<APIResponse<Entity<TeamUserFields>>> {
  return put<Entity<TeamUserFields>>(`teams/${teamid}/location`, {
    location: location,
  })
}
export function findById(state: RootState, teamId: string): Fields | undefined {
  const team = lookup(state, { team: teamId })

  if (!team) return

  return team.fields as Fields
}
