import { Card } from '@common/components'
import { Link, PreformattedText } from 'App/CandidateOnly/Styles'
import Button from 'components/Button/ButtonV2'
import { px2rem, space, style } from 'core'
import { TFunction } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useMount } from 'react-use'
import * as selectors from 'selectors'
import { RootState } from 'store'
import * as rolesSlice from 'store/openjobroles'
import { CandidateShell } from '../CandidateShell'
import * as styles from '@common/styles'
import { designSystemColors } from 'core/design-system/colors'

const Container = style()
  .size({ width: '100%', maxWidth: px2rem(590) })
  .select('> * + *', style().spacing({ outerTop: space.m }))
  .element()

const ButtonsContainer = style()
  .size({ width: '100%' })
  .spacing({ outerTop: px2rem(50) })
  .flex({
    alignItems: 'center',
    justifyContent: 'center',
  })
  .element()

export const defaultTextTemplate =
  'As the next step in your application for the {ROLE_TITLE} role at {COMPANY_NAME}, ' +
  'the recruiting team is looking forward to learning more about you. In the next page you’ll be able to have an ' +
  'overview of your next steps and what’s required from your side to proceed in your application process.\n\n' +
  'Thanks in advance for your time!'

interface PublicIntroProps {
  brandingText: string
  brandingImageUrl?: string
  roleId?: string
  roleName: string
  orgLogoUrl: string
  orgId: string
}

function interpolateString(str: string | undefined, variables: Record<string, string>) {
  if (typeof str !== 'string') {
    return ''
  }
  let interpolatedStr = str
  for (const [key, value] of Object.entries(variables)) {
    interpolatedStr = interpolatedStr?.replaceAll(`{${key}}`, value) as string
  }
  return interpolatedStr
}

function prepareIntroText(
  t: TFunction<'translation', undefined>,
  placeholders: Record<string, string>,
  roleBranding: string,
  defaultText: string,
): string {
  let toInterpolateText = roleBranding
  if (roleBranding === defaultText) {
    toInterpolateText = t('candidate-dashboard-intro.default-text', defaultText)
  }
  return interpolateString(toInterpolateText, placeholders)
}

export const PublicIntroScreen: React.FC<PublicIntroProps> = props => {
  const { t } = useTranslation()

  const orgSettings = useSelector((state: RootState) => selectors.orgSettings.getByOrgId(state, props.orgId))

  const primary = orgSettings?.fields.branding_color_primary || designSystemColors.typographyPrimaryAccent
  const primaryText = styles.textColorForBackground(primary)

  const fakeMode: boolean = !props.roleId
  return (
    <>
      <CandidateShell
        orgLogoUrl={props.orgLogoUrl || 'https://avatars2.githubusercontent.com/u/60610251?s=200&v=4'}
        brandingUrl={props.brandingImageUrl}
        fakeMode={fakeMode}
        roleId={props.roleId}
      >
        <Container>
          <Card big>
            <PreformattedText>{props.brandingText}</PreformattedText>
            <ButtonsContainer>
              {props.roleId && (
                <Link
                  replace
                  to={{
                    pathname: `/roles/${props.roleId}/add-candidate-details`,
                  }}
                >
                  <Button
                    buttonType={'primary'}
                    // This custom styling for the button is needed since this page is also used during role creation to preview the branded intro.
                    // It means we can avoid globally changing the primary color css variables.
                    style={{ color: primaryText, background: primary, minWidth: px2rem(236) }}
                  >
                    {t('candidate-dashboard-intro.button.continue', 'Continue')}
                  </Button>
                </Link>
              )}
              {!props.roleId && (
                <Button
                  buttonType={'primary'}
                  style={{ color: primaryText, background: primary, minWidth: px2rem(236) }}
                >
                  {t('candidate-dashboard-intro.button.continue', 'Continue')}
                </Button>
              )}
            </ButtonsContainer>
          </Card>
        </Container>
      </CandidateShell>
    </>
  )
}

const PublicIntroScreenPage = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const { roleid } = useParams<{ roleid: string }>()

  useMount(() => {
    dispatch(rolesSlice.fetch(roleid))
  })

  const role = useSelector((state: RootState) => selectors.roles.findById(state, roleid || ''))

  const org = useSelector((state: RootState) => selectors.orgs.findByRoleId(state, roleid))

  const placeholders: Record<string, string> = {
    ROLE_TITLE: role?.fields.public_name || '',
    COMPANY_NAME: org?.fields.name || '',
  }

  return (
    <>
      {org && (
        <PublicIntroScreen
          orgId={org?.id || ''}
          roleId={role.id}
          brandingText={prepareIntroText(t, placeholders, role?.fields.branding_text, defaultTextTemplate)}
          roleName={role.fields.public_name}
          brandingImageUrl={role.fields.branding_image_url}
          orgLogoUrl={org?.fields.logo_image_url || ''}
        ></PublicIntroScreen>
      )}
    </>
  )
}

export default PublicIntroScreenPage
