import React, { forwardRef } from 'react'
import classes from './ScoreCard.module.scss'

interface ScoreCardProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
  fullWidthSubgridItem?: boolean
  accent?: boolean
}

const Card = (props: ScoreCardProps, ref: React.Ref<HTMLDivElement>) => {
  const { fullWidthSubgridItem = true, className, accent, ...rest } = props
  return (
    <div
      className={`${classes.scoreCard} ${fullWidthSubgridItem ? classes.fullWidthSubgridItem : ''} ${
        accent ? classes.accent : ''
      } ${className || ''} `}
      {...rest}
      ref={ref}
    >
      {props.children}
    </div>
  )
}

export const ScoreCard = forwardRef(Card)
