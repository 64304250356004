import { size, style } from 'core'
import { useWorkingWithOthers } from 'providers/candidate-summaries'
import { useSelectedCandidate } from 'providers/candidates'
import { useModule } from 'providers/modules'
import React from 'react'
import { useParams } from 'react-router-dom'
import { WorkingWithOthersChartAttribute } from 'selectors/candidate-summaries'
import { WORKING_WITH_OTHERS_QUESTION } from 'store/new-role/content'
import { CartesianChartBlock } from './CartesianChartBlock'
import { SummaryContainer } from './SummaryContainer'

const WorkingWithOthersBlockUIStyle = style()
  .size({ height: size.fill })
  .select('> *', style().size({ height: size.fill }))
  .element()

interface WorkingWithOthersBlockUIProps {
  selectedAttribute: WorkingWithOthersChartAttribute
  selectedCandidateAttribute: WorkingWithOthersChartAttribute | null
  detailsUrl?: string
}

const WORKING_WITH_OTHERS_CHART_ATTRIBUTE_INDEX: Record<WorkingWithOthersChartAttribute, number> = {
  engage: 0,
  lead: 1,
  direct: 2,
  balance: 3,
  control: 4,
  cooperate: 5,
  consider: 6,
  connect: 7,
}

const WorkingWithOthersBlockUI: React.FC<WorkingWithOthersBlockUIProps> = ({
  selectedAttribute,
  selectedCandidateAttribute,
  detailsUrl,
}) => {
  return (
    <SummaryContainer emoji={'🤝'} title={'Working with Others'} hintColor={'purple'} to={detailsUrl}>
      <WorkingWithOthersBlockUIStyle>
        <CartesianChartBlock
          numberOfAreas={8}
          hintColor="purple"
          selectedAreaIndex={WORKING_WITH_OTHERS_CHART_ATTRIBUTE_INDEX[selectedAttribute]}
          firstQuadrantLabel1={{
            text: 'Engage',
            hasCandidateLabel: selectedCandidateAttribute === 'engage',
          }}
          firstQuadrantTooltip1={{
            title: 'Engage',
            text: 'An outgoing person who might overwhelm others with their openness.',
          }}
          firstQuadrantLabel2={{
            text: 'Lead',
            hasCandidateLabel: selectedCandidateAttribute === 'lead',
          }}
          firstQuadrantTooltip2={{
            title: 'Lead',
            text: 'A persuasive person who takes charge, but finds it hard to follow.',
          }}
          secondQuadrantLabel1={{
            text: 'Direct',
            hasCandidateLabel: selectedCandidateAttribute === 'direct',
          }}
          secondQuadrantTooltip1={{
            title: 'Direct',
            text: 'Outspoken and not afraid of conflict, this person makes tough decisions.',
          }}
          secondQuadrantLabel2={{
            text: 'Balance',
            hasCandidateLabel: selectedCandidateAttribute === 'balance',
          }}
          secondQuadrantTooltip2={{
            title: 'Balance',
            text: 'Can tolerate conflicts, but finds it hard to express emotions to colleagues.',
          }}
          thirdQuadrantLabel1={{
            text: 'Control',
            hasCandidateLabel: selectedCandidateAttribute === 'control',
          }}
          thirdQuadrantTooltip1={{
            title: 'Control',
            text: "An honest person who respects coworkers' privacy, but cannot easily join in groups.",
          }}
          thirdQuadrantLabel2={{
            text: 'Cooperate',
            hasCandidateLabel: selectedCandidateAttribute === 'cooperate',
          }}
          thirdQuadrantTooltip2={{
            title: 'Cooperate',
            text: 'A grateful person and a good listener who finds it hard to disagree with others.',
          }}
          fourthQuadrantLabel1={{
            text: 'Consider',
            hasCandidateLabel: selectedCandidateAttribute === 'consider',
          }}
          fourthQuadrantTooltip1={{
            title: 'Consider',
            text: 'A person who loves to help others but is also very sensitive to their opinions.',
          }}
          fourthQuadrantLabel2={{
            text: 'Connect',
            hasCandidateLabel: selectedCandidateAttribute === 'connect',
          }}
          fourthQuadrantTooltip2={{
            title: 'Connect',
            text: 'A warm and welcoming person who finds it hard to refuse others.',
          }}
        />
      </WorkingWithOthersBlockUIStyle>
    </SummaryContainer>
  )
}

interface WorkingWithOthersBlockProps {
  container?: React.FC
}

const WorkingWithOthersBlock: React.FC<WorkingWithOthersBlockProps> = ({ container }) => {
  const { candidate } = useSelectedCandidate()
  const { isLoading, summary, selfSummary } = useWorkingWithOthers({
    candidateId: candidate?.id,
  })

  const module = useModule({ slug: 'working-with-others' })
  const targetId = candidate?.fields.questionnaire_id ? module?.id : WORKING_WITH_OTHERS_QUESTION.test_item_id
  const { roleid, candidateid } = useParams<{
    roleid: string
    candidateid: string
  }>()
  const detailsUrl = `/roles/${roleid}/candidates/${candidateid}/responses#${targetId}`

  if (isLoading || !summary) {
    return null
  }

  const Container = container || React.Fragment

  return (
    <Container>
      <WorkingWithOthersBlockUI
        selectedAttribute={summary}
        selectedCandidateAttribute={selfSummary || null}
        detailsUrl={detailsUrl}
      />
    </Container>
  )
}

export default WorkingWithOthersBlock
