import React from 'react'
import * as Popover from '@radix-ui/react-popover'
import styles from './PopoverMenu.module.scss'

interface PopoverMenuProps {
  trigger: React.ReactNode
  menuContent: React.ReactNode
  side?: 'top' | 'right' | 'bottom' | 'left'
  sideOffset?: number
  arrow?: boolean
  defaultOpen?: boolean
  onOpenChange?: (open: boolean) => void
}

const PopoverMenu: React.FC<PopoverMenuProps> = props => {
  const arrow = props.arrow ?? true // default to true
  return (
    <Popover.Root defaultOpen={props.defaultOpen} onOpenChange={props.onOpenChange}>
      <Popover.Trigger asChild>{props.trigger}</Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          className={styles.content}
          sideOffset={props.sideOffset ?? 5}
          side={props.side}
          collisionPadding={10}
        >
          <Popover.Close asChild>{props.menuContent}</Popover.Close>
          {arrow && <Popover.Arrow className={styles.arrow} />}
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}

export { PopoverMenu }
