import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Block from '../../components/Block'
import Button from '../../components/Button/ButtonV1'
import Buttons from '../../components/Buttons'
import Form from '../../components/Form'
import TextField from '../../components/TextField'
import * as selectors from '../../selectors'
import { RootState } from '../../store'
import { save, setNewPassword, setNewPasswordConfirm, setOldPassword } from '../../store/settings/password'

const Component = () => {
  const dispatch = useDispatch()

  const [user, changes, loading]: [
    ReturnType<typeof selectors.users.current>,
    RootState['passwordSettings']['changes'],
    boolean,
  ] = useSelector((state: RootState) => {
    const user = selectors.users.current(state)
    return [user, state.passwordSettings.changes, state.passwordSettings.loading]
  })

  const notchanged = Object.values(changes).length === 0

  return (
    <Block title="Password">
      <Form>
        <TextField
          type="password"
          label="Current Password"
          value=""
          change={changes.oldPassword}
          onChange={e => dispatch(setOldPassword(e.target.value))}
          testId="account-current-password-input"
        />
        <TextField
          type="password"
          label="New Password"
          value=""
          change={changes.newPassword}
          onChange={e => dispatch(setNewPassword(e.target.value))}
          testId="account-new-password-input"
        />
        <TextField
          type="password"
          label="Confirm"
          value=""
          change={changes.newPasswordConfirm}
          onChange={e => dispatch(setNewPasswordConfirm(e.target.value))}
          testId="account-confirm-password-input"
        />
        <Buttons>
          <Button
            primary
            onClick={() => (user ? dispatch(save(user.id, changes)) : null)}
            disabled={loading || notchanged}
            testId="account-update-password-button"
          >
            Update Password
          </Button>
        </Buttons>
      </Form>
    </Block>
  )
}

export default Component
Component.displayName = 'Password'
