import { Button, Modal, Text } from '@common/components'
import { colors } from 'core'
import React from 'react'

interface Props {
  open: boolean
  setOpen: (b: boolean) => void

  verified: boolean
  error?: string
}

const Component = (props: Props) => {
  return (
    <Modal
      title="Email verified!"
      open={props.open}
      onClose={() => props.setOpen(false)}
      footer={
        <Button variant="primary" onClick={() => props.setOpen(false)}>
          Ok
        </Button>
      }
    >
      <Text variant="body-text" style={{ color: colors.darkGray }}>
        Thank you, your email has been confirmed. <br />
        You are ready to invite your first candidate.
      </Text>
    </Modal>
  )
}

export default Component
Component.displayName = 'EmailVerificationModal'
