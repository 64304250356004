import { Card } from '@common/components'
import { CandidateExperienceHiringStatus, createCandidateExperienceSubmissionLink } from 'api/orgs'
import { CandidateShell } from 'App/CandidateOnly/CandidateShell'
import { Text } from 'App/CandidateOnly/Styles'
import Button from 'components/Button/ButtonV2'
import TextField from 'components/TextField'
import { px2rem, style } from 'core'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { add as notify } from 'store/notifications'

const Container = style()
  .size({ maxWidth: px2rem(590), width: '100%' })
  .flex({ direction: 'column' })
  .spacing({ gap: px2rem(16) })
  .element()

const Component: React.FC<{}> = () => {
  const dispatch = useDispatch()
  const { orgid } = useParams<{ orgid: string }>()

  const params = new URLSearchParams(window.location.search)
  const stateString = params.get('state') || ''

  const [hiringParams, setHiringParams] = useState<{
    hiring_status: CandidateExperienceHiringStatus
  }>()
  useEffect(() => {
    try {
      setHiringParams(JSON.parse(atob(stateString)))
    } catch (e) {
      //
    }
  }, [stateString])

  const [email, setEmail] = useState('')

  const valid = !!email && hiringParams

  const fetchLink = async () => {
    if (!hiringParams) return

    const [response, errors] = await createCandidateExperienceSubmissionLink(orgid, hiringParams.hiring_status, email)

    if (errors?.length) {
      dispatch(notify({ error: errors[0].message }))
      return
    }

    const link = response?.result.fields.link
    if (!link) return

    window.location.href = link
  }

  return (
    <CandidateShell>
      <Container>
        <Card big>
          <Text>Welcome, please provide some information to continue the Candidate Experience survey</Text>
          <TextField
            aria-label={'Email address'}
            label="Email"
            type={'email'}
            placeholder={'sue@brown.org'}
            value={email}
            onChange={ev => {
              setEmail(ev.target.value)
            }}
          />
          <Button buttonType={'primary'} isFullWidth disabled={!valid} onClick={fetchLink}>
            Continue
          </Button>
        </Card>
      </Container>
    </CandidateShell>
  )
}

export default Component
Component.displayName = 'Invite'
