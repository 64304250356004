import TabSelector from 'App/ExperienceFeedback/TabSelector'
import { interpolateVariables } from 'core/text'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useMount } from 'react-use'
import * as selectors from 'selectors'
import { RootState } from 'store'
import { loadFilters, loadTextResponses } from 'store/candidate-experience'
import * as modulesSlice from 'store/modules'
import { requestProductUpgrade } from 'store/settings/org'
import { Filters } from '../../api/orgs'
import { FiltersSection } from './Filters/FiltersSection'
import { DateRangePreset, PageTitle, ranges, TitleContainer } from './index'
import Shell from './Shell'
import TextQuestionList from './TextQuestionList'
import UpgradeModal from './UpgradeModal'

interface WrittenFeedbackProps {}

const WrittenFeedback: React.FC<WrittenFeedbackProps> = () => {
  const { pathname } = useLocation()
  const dispatch = useDispatch()

  useMount(() => {
    dispatch(loadFilters(org.id))
  })

  const questionId = pathname.split('/')[pathname.split('/').length - 1]
  const org = useSelector((state: RootState) => selectors.orgs.getById(state, selectors.orgs.currentId(state)))
  const orgSettings = useSelector((state: RootState) => selectors.orgSettings.getByOrgId(state, org.id))

  const question = useSelector((state: RootState) => selectors.questions.findById(state, questionId))

  const [range, setRange] = useState<DateRangePreset>({ name: 'All time' })

  const filterOptions = useSelector((state: RootState) => {
    return state.candidateExperience.filters
  })

  const defaultFilters: Filters = {
    hiring_status: 'all',
    department: 'all',
    hiring_manager: 'all',
    last_stage: 'all',
    location: 'all',
    recruiter_email: 'all',
  }

  const [filters, setFilters] = useState<Filters>(defaultFilters)

  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false)

  function resetFilterStates() {
    // when adding new filters, don't forget to include the states here
    setFilters(defaultFilters)
    setRange({ name: 'All time' })
  }

  const filtered =
    range.name !== 'All time' ||
    filters.department !== 'all' ||
    filters.hiring_status !== 'all' ||
    filters.last_stage !== 'all' ||
    filters.location !== 'all' ||
    filters.recruiter_email !== 'all'

  useEffect(() => {
    if (!questionId || !org) return

    dispatch(modulesSlice.loadBySlug('candidate-experience'))
    dispatch(loadTextResponses(org.id, questionId, filters, range?.startDate, range?.endDate))
  }, [questionId, org, range, filters])

  const answers = useSelector((state: RootState) => {
    return state.candidateExperience.textQuestions[questionId] || []
  })

  return (
    <>
      <Shell>
        <TabSelector content />
        <TitleContainer>
          <PageTitle>
            {interpolateVariables(question?.fields.copy.heading, {
              'ORGANIZATION.NAME': org.fields.name,
            })}
          </PageTitle>
        </TitleContainer>
        <FiltersSection
          dateRanges={ranges}
          filterOptions={filterOptions}
          filters={filters}
          filtered={filtered}
          reset={resetFilterStates}
          selectedDateRange={range}
          setDateRange={setRange}
          setFilters={setFilters}
          setUpgradeModalOpen={setUpgradeModalOpen}
          cxMode={orgSettings?.fields.candidate_experience_mode || 'none'}
        />
        <TextQuestionList
          question={answers.map(ans => ({
            date: new Date(ans.date / 10e5),
            answer: ans.response,
          }))}
          filtered={filtered}
        />
      </Shell>
      {upgradeModalOpen && (
        <UpgradeModal
          setOpen={setUpgradeModalOpen}
          onUpgrade={() => {
            dispatch(requestProductUpgrade(org.id, 'candidate-experience'))
          }}
        />
      )}
    </>
  )
}

export default WrittenFeedback
