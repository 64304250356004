import Sidebar from 'components/Sidebar'
import { designSystemColors as designColors } from 'core/design-system/colors'
import { useEmailVerification } from 'providers/email-verification'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as selectors from 'selectors'
import { resendEmailVerification } from 'store/settings/account'
import { breakpoints, colors, px2rem, rem, size, style, vh } from '../../core'
import * as tracking from '../../core/track'
import Grid from '../Grid'
import Layout from '../Layout'
import classes from './DashboardLayout.module.scss'
import EmailVerificationModal from './EmailVerificationModal'

export { default as Header } from './Header'
export { default as Title, TitleRow } from './Title'

interface Props {
  children: React.ReactNode
  areBeaconsVisible?: boolean
}

export const Content: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => {
  return (
    <div {...props} className={`${classes.main} ${props.className ? props.className : ''}`}>
      {props.children}
    </div>
  )
}

const Left = style()
  .sticky({ top: 0 })
  .size({ height: vh(100) })
  .element('div')

export const Right = style()
  .bg({ color: colors.nearWhite })
  .grid({ rows: [rem(3.3), size.auto] })
  .screen(breakpoints.xl, style().select('> *', style().size({ minWidth: rem(24) })))
  .element()

const EmailVerificationWarningBanner = style()
  .size({ height: px2rem(40) })
  .flex({ alignItems: 'center', justifyContent: 'center' })
  .color({ bg: designColors.backgroundSecondaryAccent })
  .sans({ weight: '500', size: px2rem(14), line: px2rem(18) })
  .color({ bg: designColors.backgroundSecondaryAccent })
  .element()

const EmailVerificationWarningBannerBold = style().sans({ weight: '700' }).element('span')

const Link = style()
  .text({ nodecoration: true, underline: true })
  .color({ fg: 'inherit' })
  .cursor('pointer')
  .element('a')

const Component = ({ children }: Props) => {
  const dispatch = useDispatch()
  const { status: verificationStatus, dismiss: dismissVerificationStatus } = useEmailVerification()

  const user = useSelector(selectors.users.current)

  return (
    <>
      <Layout fill>
        {!user.fields.verified_at && (
          <EmailVerificationWarningBanner>
            <p>
              You need to verify your email address (
              <EmailVerificationWarningBannerBold>{user.fields.email}</EmailVerificationWarningBannerBold>) to invite
              candidates. Didn’t receive the email?{' '}
              <Link
                onClick={() => {
                  tracking.users.requestNewVerificationEmail(user.id, user.fields.email)
                  dispatch(resendEmailVerification(user.id))
                }}
              >
                Click here to send it again.
              </Link>
            </p>
          </EmailVerificationWarningBanner>
        )}
        <Grid auto fill>
          <Left width={size.auto}>
            <Sidebar />
          </Left>
          <Right>{children}</Right>
        </Grid>
      </Layout>
      {verificationStatus && (
        <EmailVerificationModal
          open
          setOpen={dismissVerificationStatus}
          verified={verificationStatus.verified}
          error={verificationStatus.error}
        />
      )}
    </>
  )
}

export default Component
Component.displayName = 'DashboardLayout'
