import React, { ReactNode } from 'react'
import { Text } from '../../Text/Text'
import classes from './ScoreExplanation.module.scss'
import { MarkdownText } from '../../MarkdownText/MarkdownText'

export interface ScoreTableInfoBlockProps {
  lowBehaviourText?: string
  highBehaviourText?: string
  description?: string
  children?: ReactNode
}

export const ScoreExplanation = (props: ScoreTableInfoBlockProps) => {
  const showBehaviourText =
    (props.highBehaviourText || '').trim().length > 0 && (props.lowBehaviourText || '').trim().length > 0
  return (
    <div>
      {props.description && <div className={classes.descriptionText}>{props.description}</div>}
      {showBehaviourText && (
        <div className={classes.container}>
          {props.highBehaviourText && (
            <div className={classes.column}>
              <div className={classes.title}>{<Text variant="body-interactive">High score behavior:</Text>}</div>
              <MarkdownText text={props.highBehaviourText || ''} />
            </div>
          )}
          {props.lowBehaviourText && (
            <div className={classes.column}>
              <div className={classes.title}>{<Text variant="body-interactive">Low score behavior:</Text>}</div>
              <MarkdownText text={props.lowBehaviourText || ''} />
            </div>
          )}
        </div>
      )}
      {props.children}
    </div>
  )
}
