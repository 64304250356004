export function updateBrandColours(primary: Hex) {
  const rgb = hexToRGB(primary)
  if (!rgb) return

  document.documentElement.style.setProperty(`--brand-primary`, primary)
  document.documentElement.style.setProperty(`--brand-primary-text`, isDark(rgb) ? '#fff' : '#000')
}

export type Hex = `#${string}`
export type RGB = [r: number, g: number, b: number]

// https://github.com/scottcorgan/hex-to-rgb/blob/master/index.js
function hexToRGB(hex: Hex): RGB | undefined {
  const parts = hex.replace('#', '')
  if (parts.length !== 6) return

  const v = parseInt(parts, 16)
  const r = (v >> 16) & 255
  const g = (v >> 8) & 255
  const b = v & 255
  return [r, g, b]
}

// https://github.com/scottcorgan/contrast/blob/master/index.js
function isDark([r, g, b]: RGB): boolean {
  return Math.round((r * 299 + g * 587 + b * 114) / 1000) <= 128
}

export function textColorForBackground(hex: string): Hex {
  const rgb = hexToRGB(hex as Hex)
  if (!rgb) return '#fff'
  return isDark(rgb) ? '#fff' : '#000'
}
