const PEER_REFERENCE_QUESTION = {
  test_item_id: '1Sh8OAgaVcbaVN1NaKHQCg',
} as const

const REPORT_REFERENCE_QUESTION = {
  test_item_id: 'Phe1dYbeNFwy9ZWCswXQx',
} as const

const MANAGER_REFERENCE_QUESTION = {
  test_item_id: '5gR6HwOabdFMAfYlS3pxYK',
} as const

const STUDENT_PEER_REFERENCE_QUESTION = {
  test_item_id: '6DgicGxBkw9uBqJUigb6KZ',
} as const

const CLIENT_REFERENCE_QUESTION = {
  test_item_id: '5darvFPGrZeVAG33k4x8tL',
} as const

export const WORK_STYLE_QUESTION = {
  test_item_id: '7HtDYbqxdvWQNgtE1v6vsb',
} as const

export const SELF_WORK_STYLE_QUESTION = {
  test_item_id: '5939q56oLzuHgvYa69V21v',
} as const

export const WORK_VALUES_QUESTION = {
  test_item_id: '4gmD1nhca5riUyPUOVXfiN',
} as const

export const SELF_CULTURE_ADD_QUESTION = {
  test_item_id: '1E3v68ATsXaQ1yWo9CuB7r',
} as const

const SELF_GREAT_RESULTS_GREAT_VIBES_QUESTION = {
  test_item_id: '2XzrbaKutJ0kXmcCduyJnM',
} as const

export const LEADERSHIP_STYLE_QUESTION = {
  test_item_id: '5NRViBzrgTsRp0FdIFwhMF',
} as const

export const CANDIDATE_VERIFICATION_QUESTION = {
  test_item_id: '2PZ2YabbAigEEj1Dnf9Ihv',
} as const

export const VERIFICATION_QUESTION = {
  test_item_id: 'ghpCbuO9tVwFGT0nt1upO',
} as const
export const VERIFICATION_QUESTION_ANONYMITY_OPTION = '2eB3qAtzd5DcLvdt8AP24'

export const CANDIDATE_PROFESSIONAL_NETWORK_QUESTION = {
  test_item_id: '19I1YJmRYjPyHGIbNCKrfv',
} as const

export const CANDIDATE_ASK_REFERENCE_LOOP = {
  test_item_id: '2LwDegkqUHKq5aSSmwOE3',
} as const

export const COLLABORATION_FREQUENCY_QUESTION = {
  test_item_id: '5sqEUVMWayrlXAwcKb2U5p',
} as const

export const COMPANY_STAGE_QUESTION = {
  test_item_id: '6UtKUjv1SrNX48sVY0tY0w',
} as const

export const COMPANY_SIZE_QUESTION = {
  test_item_id: '6npEuOedRb8JL6NOqXJu6D',
} as const

export const TEAM_SIZE_QUESTION = {
  test_item_id: 'rLYNnXJn1kSWDSBwuLd9c',
} as const

export const TEAMWORK_STYLE_QUESTION = {
  test_item_id: '28lDj4fBrNDoRZKgTKjGWK',
} as const

export const OVERALL_PERFORMANCE_QUESTION = {
  test_item_id: 'FSQAXISuuNZ8rX9ZLMKCk',
} as const

export const OLD_OVERALL_PERFORMANCE_QUESTION = {
  test_item_id: '5k8b9J0imBEu42c33mHINu',
} as const

export const AREAS_OF_IMPROVEMENT = {
  test_item_id: 'jvMQzXAWY0KP1OTGKFLIP',
} as const

export const SELF_AREAS_OF_IMPROVEMENT_QUESTION = {
  test_item_id: '51ZTla5BJ5HORHH8dGKO0L',
} as const

export const OLD_AREAS_OF_IMPROVEMENT = {
  test_item_id: '3bisgKTV18oLJaRmpZ1Ds5',
} as const

export const RELATIONSHIP_DESCRIPTION_QUESTION = {
  test_item_id: 'hk6bFAMe5MY8UyIWuV8Lt',
} as const

export const REASONS_FOR_LEAVING_QUESTION = {
  test_item_id: '290tPsxKKmcDdTJxhWH8QO',
} as const

export const KEY_ACCOMPLISHMENTS_QUESTION = {
  test_item_id: '16egqMRm4vqkofP4zIazVp',
} as const

export const STRENGTHS_QUESTION = {
  test_item_id: '2p6vrwO6WLutIOJaELdooB',
} as const

export const SELF_STRENGTHS_QUESTION = {
  test_item_id: '3a1iImXMb6dDyXYT3AkmJ0',
} as const

export const RECOMMENDATION_OF_HIRE_QUESTION = {
  test_item_id: '7xgNvwmAjskoxQLtSsxhaz',
} as const

export const GROWTH_HINTS_QUESTION = {
  test_item_id: '2Z7fcWIZSZnC9pwOWm36xW',
} as const

export const SELF_GROWTH_HINTS_QUESTION = {
  test_item_id: '2yQTVqUHKHAtlzwSFbUzIY',
} as const

const ANYTHING_ELSE_QUESTION = {
  test_item_id: '6ccwnH6i8GlZJZYGnkaca8',
} as const

const SELF_ANYTHING_ELSE_QUESTION = {
  test_item_id: '2b9fYF452sUK0wSp9XpJzJ',
} as const

export const PROFICIENCY_QUESTION = {
  question: {
    heading: 'Please share some insights into {CANDIDATE.FIRST_NAME}’s ability to [skill].',
    response_options: ['2VdeyBxlEX5CDrnOwKXTxR'],
  },
}

export const SKILL_LEVEL_QUESTION = {
  question: {
    heading:
      'When you last worked with {CANDIDATE.FIRST_NAME}, how skilled was {CANDIDATE.FIRST_NAME} in the following activity: [skill]',
    response_options: ['17nwkdEnw5Is9IMSPvdIIA'],
    validation_id: '3qmYjFQuEB3Z7rk7ixqKKE',
  },
}

export const CURRENT_EXPERIENCE_QUESTION = {
  question: {
    heading: 'How experienced was {CANDIDATE.FIRST_NAME} in the following activity: [skill]?',
    response_options: ['4tkNC7gEPwKuTt3DxIE03q'],
  },
}

export const GENERAL_IMPRESSION = {
  test_item_id: '1y5e2shsNpw18YLc95X82Q',
} as const

export const SELF_GENERAL_IMPRESSION_QUESTION = {
  test_item_id: '5DE4zROrE2PrcuepHQBwYS',
} as const

const KEY_CONTRIBUTIONS = {
  test_item_id: '4wsrLzRmOEJmXqkioa4Alh',
} as const

export const SELF_KEY_ACCOMPLISHMENTS_QUESTION = {
  test_item_id: '3tINUHEWZkz8IfewWgjWS4',
} as const

export const WORKING_WITH_OTHERS_QUESTION = {
  test_item_id: '3IX1Djjns4CxNfxP9E0Ga7',
} as const

export const SELF_WORKING_WITH_OTHERS_QUESTION = {
  test_item_id: '6571ihYXliRSjGj2t9Xh47',
} as const

const SELF_WORKING_WITH_OTHERS_ALOOF_ENTHUSIASTIC_QUESTION = {
  test_item_id: 'P0mWiQh1vAWvMXe5chUxN',
} as const

const SELF_WORKING_WITH_OTHERS_COMPASSION = {
  test_item_id: '5sxmfo9RVHIzGT8ExQGK8j',
} as const

const SELF_WORKING_WITH_OTHERS_INTERACTION_STYLE = {
  test_item_id: 'GhZVSKAT8CMPxmplrupmA',
} as const

export const NEED_PRIVACY_OTHER_PEOPLE_LAUGH_QUESTION = {
  test_item_id: '6jhuO1RmUzo0v6Nqydahc1',
} as const

export const TELLING_OTHERS_NO_REASSURING_OTHERS_QUESTION = {
  test_item_id: '282RnCULYBzSstrnB109xw',
} as const

export const LOOKS_AFTER_INTERESTS_THINKS_OTHERS_FIRST_QUESTION = {
  test_item_id: '5tXtehaq0WyIFJcddfCqy1',
} as const

export const CULTURE_ADD_QUESTION = {
  test_item_id: '3VyCr6gbtMVPw145rCJbv6',
} as const

export const GREAT_RESULTS_GREAT_VIBES_QUESTION = {
  test_item_id: '28U0Ukwogn7kbqYJ9WC0DW',
} as const

export const LEADERSHIP_SKILL_QUESTION = {
  test_item_id: '5pQeQwCEYvFI4vtZu9M6gM',
} as const

export const EVERYONE_KNOWS_WHAT_TO_DO_QUESTION = {
  test_item_id: '57h7rFpkVQVZ3LjG7GCoLh',
} as const

export const RECOGNISED_IMPORTANT_CONTRIBUTIONS_QUESTION = {
  test_item_id: '2LmMuLsahYYyVunrmyNHU3',
} as const

export const SHOWED_CONSIDERATION_FEELINGS_QUESTION = {
  test_item_id: '5DlWG3p5NLM4DFDX9VMR1D',
} as const

export const HELP_OTHERS_ACCOMPLISH_WORK_QUESTION = {
  test_item_id: '4roW2YkwkgrLPhAUrh8O1F',
} as const

export const SOUND_COMPELLING_VISION_QUESTION = {
  test_item_id: '7oHdWwDhvS9keKNtMGDyHH',
} as const

export const ENCOURAGED_REEVALUATION_WORKFLOW_QUESTION = {
  test_item_id: '5iBpl9Yugf1rnxtM5qAPdH',
} as const

export const CANDIDATE_INTRO_DIALOG_ID = 'ueG8SWuKNIlSdoqmRa3Eg'
export const CANDIDATE_SUBMIT_DIALOG_ID = 'Yjcjd3cfMWDJTE0NLH8JR'
export const CANDIDATE_SUBMITTED_DIALOG_ID = '4OG3fPZ557cQYl1KHiXgGY'
export const CANDIDATE_INACTIVE_DIALOG_ID = '4ZgqczTZS5uqOjFibtsLqj'

// I'm not sure we use in_active anymore, but having looked at the content
// it seems fine if we continue to use the reference dialog_id
export const CONTENT_EMOJI_LOOKUP: { [questionId: string]: string } = {
  [MANAGER_REFERENCE_QUESTION.test_item_id]: '💼',
  [REPORT_REFERENCE_QUESTION.test_item_id]: '🌱',
  [PEER_REFERENCE_QUESTION.test_item_id]: '🤲',
  [STUDENT_PEER_REFERENCE_QUESTION.test_item_id]: '🎓',
  [CLIENT_REFERENCE_QUESTION.test_item_id]: '👔',
  [WORK_STYLE_QUESTION.test_item_id]: '🦸',
  [COLLABORATION_FREQUENCY_QUESTION.test_item_id]: '👯‍♂️',
  [COMPANY_SIZE_QUESTION.test_item_id]: '💼',
  [COMPANY_STAGE_QUESTION.test_item_id]: '🏢',
  [TEAM_SIZE_QUESTION.test_item_id]: '🧮',
  [WORK_VALUES_QUESTION.test_item_id]: '🌟',
  [SKILL_LEVEL_QUESTION.question.response_options[0]]: '🛠',
  [TEAMWORK_STYLE_QUESTION.test_item_id]: '🤝',
  [OVERALL_PERFORMANCE_QUESTION.test_item_id]: '🏋️‍♀️',
  [AREAS_OF_IMPROVEMENT.test_item_id]: '🌱',
  [OLD_AREAS_OF_IMPROVEMENT.test_item_id]: '🌱',
  [LEADERSHIP_STYLE_QUESTION.test_item_id]: '👔',
  [GENERAL_IMPRESSION.test_item_id]: '🖼',
  [KEY_CONTRIBUTIONS.test_item_id]: '🗝',
  [RELATIONSHIP_DESCRIPTION_QUESTION.test_item_id]: '📝',
  [REASONS_FOR_LEAVING_QUESTION.test_item_id]: '👋',
  [KEY_ACCOMPLISHMENTS_QUESTION.test_item_id]: '🗝',
  [STRENGTHS_QUESTION.test_item_id]: '💪',
  [RECOMMENDATION_OF_HIRE_QUESTION.test_item_id]: '✅',
  [GROWTH_HINTS_QUESTION.test_item_id]: '🎁',
  [ANYTHING_ELSE_QUESTION.test_item_id]: '❓',
  [WORKING_WITH_OTHERS_QUESTION.test_item_id]: '🤝',
  [CULTURE_ADD_QUESTION.test_item_id]: '🌟',
  [LEADERSHIP_SKILL_QUESTION.test_item_id]: '⚖️',
}

export const SELF_REFERENCE_QUESTION_LOOKUP: Record<string, { test_item_id: string } | undefined> = {
  [GENERAL_IMPRESSION.test_item_id]: SELF_GENERAL_IMPRESSION_QUESTION,
  [KEY_ACCOMPLISHMENTS_QUESTION.test_item_id]: SELF_KEY_ACCOMPLISHMENTS_QUESTION,
  [WORK_STYLE_QUESTION.test_item_id]: SELF_WORK_STYLE_QUESTION,
  [STRENGTHS_QUESTION.test_item_id]: SELF_STRENGTHS_QUESTION,
  [AREAS_OF_IMPROVEMENT.test_item_id]: SELF_AREAS_OF_IMPROVEMENT_QUESTION,
  [GROWTH_HINTS_QUESTION.test_item_id]: SELF_GROWTH_HINTS_QUESTION,
  [ANYTHING_ELSE_QUESTION.test_item_id]: SELF_ANYTHING_ELSE_QUESTION,
  [CULTURE_ADD_QUESTION.test_item_id]: SELF_CULTURE_ADD_QUESTION,
  [GREAT_RESULTS_GREAT_VIBES_QUESTION.test_item_id]: SELF_GREAT_RESULTS_GREAT_VIBES_QUESTION,
  [WORKING_WITH_OTHERS_QUESTION.test_item_id]: SELF_WORKING_WITH_OTHERS_QUESTION,
  [LOOKS_AFTER_INTERESTS_THINKS_OTHERS_FIRST_QUESTION.test_item_id]: SELF_WORKING_WITH_OTHERS_INTERACTION_STYLE,
  [TELLING_OTHERS_NO_REASSURING_OTHERS_QUESTION.test_item_id]: SELF_WORKING_WITH_OTHERS_COMPASSION,
  [NEED_PRIVACY_OTHER_PEOPLE_LAUGH_QUESTION.test_item_id]: SELF_WORKING_WITH_OTHERS_ALOOF_ENTHUSIASTIC_QUESTION,
}
const CONTENT_TYPE_SLUGS = [
  'areas-of-improvement',
  'general-impression',
  'key-achievements',
  'leadership-styles',
  'leadership-skills',
  'culture-add',
  'growth-hints',
  'hiring-recommendations',
  'strengths',
  'work-styles',
  'working-with-others',
  'overall-performance',
  'reasons-for-leaving',
  'reference-outro',
] as const

export type CONTENT_SLUGS = (typeof CONTENT_TYPE_SLUGS)[number]

export const CONTENT_ID_SLUG_LOOKUP: Record<string, CONTENT_SLUGS> = {
  [AREAS_OF_IMPROVEMENT.test_item_id]: 'areas-of-improvement',
  [GENERAL_IMPRESSION.test_item_id]: 'general-impression',
  [KEY_ACCOMPLISHMENTS_QUESTION.test_item_id]: 'key-achievements',
  [LEADERSHIP_STYLE_QUESTION.test_item_id]: 'leadership-styles',
  [CULTURE_ADD_QUESTION.test_item_id]: 'culture-add',
  [GROWTH_HINTS_QUESTION.test_item_id]: 'growth-hints',
  [RECOMMENDATION_OF_HIRE_QUESTION.test_item_id]: 'hiring-recommendations',
  [STRENGTHS_QUESTION.test_item_id]: 'strengths',
  [WORK_STYLE_QUESTION.test_item_id]: 'work-styles',
  [WORKING_WITH_OTHERS_QUESTION.test_item_id]: 'working-with-others',
  [OVERALL_PERFORMANCE_QUESTION.test_item_id]: 'overall-performance',
  [REASONS_FOR_LEAVING_QUESTION.test_item_id]: 'reasons-for-leaving',
  [ANYTHING_ELSE_QUESTION.test_item_id]: 'reference-outro',
}

const QUESTION_TYPE_SLUGS = [
  'areas-of-improvement',
  'culture-add',
  'encouraged-reevaluation',
  'everyone-knows-what-todo',
  'general-impression',
  'results-vibes',
  'growth-hints',
  'helps-others-work',
  'key-accomplishments',
  'leadership-skills',
  'self-versus-others',
  'privacy-others-laughing',
  'overall-performance',
  'reasons-for-leaving',
  'recognises-contributions',
  'recommends-hiring',
  'shows-consideration',
  'compelling-vision',
  'strengths',
  'rejection-reassurance',
  'working-with-others',
  'work-style',
] as const

export type QUESTION_SLUGS = (typeof QUESTION_TYPE_SLUGS)[number]
