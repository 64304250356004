import * as tracking from 'core/track'
import React, { useEffect, useRef, useState } from 'react'
import ListSelector, { ListItem } from 'components/ListSelector'
import { FaChevronLeft } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import { Button } from '@common/components'

import { style } from 'core'

interface Props {
  activeId: string
  items: ListItem[]
  visible: { [id: string]: boolean }
}

const Container = style().scroll({ y: 'scroll' }).element()

const SectionBlockNavigator = (props: Props) => {
  const [previous, setPrevious] = useState(props.items[0]?.id || '')

  const history = useHistory()
  const isFirstRender = useRef<boolean>(true)
  const current = Object.keys(props.visible).filter(id => props.visible[id])[0]

  const currentUrl = history.location.pathname
  const jobUrl = currentUrl.substring(0, currentUrl.lastIndexOf('/'))

  useEffect(() => {
    const scrollBehavior = isFirstRender.current ? 'auto' : 'smooth'
    const activeElm = document.getElementById(props.activeId)

    activeElm?.scrollIntoView({ behavior: scrollBehavior, block: 'start' })
    isFirstRender.current = false
  }, [props.activeId])

  useEffect(() => {
    current && setPrevious(current)
  }, [current])

  if (!props.items.length) return <></>

  return (
    <Container>
      {/* Temporary button to go back to the job until we have the Responses modal */}
      <Button
        variant="purple"
        onClick={() => history.push(jobUrl)}
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '9rem',
          height: '3rem',
          gap: '1rem',
          marginTop: 'auto',
          padding: '0px',
          paddingLeft: '0.5rem',
          backgroundColor: 'transparent',
        }}
      >
        <FaChevronLeft style={{ marginRight: '-0.5rem' }} />
        Back to Job
      </Button>
      <ListSelector
        items={props.items}
        onClick={s => tracking.candidates.questionBySectionBlockNavigation(s, props.items)}
        active={current || previous}
      />
    </Container>
  )
}

export default SectionBlockNavigator
