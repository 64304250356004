import React, { useState } from 'react'
import Dialog, { Options } from './Dialog'

interface DialogContextValue {
  open?: (opt: Options, cb: (_: boolean) => void) => void
}

export const DialogContext = React.createContext<DialogContextValue>({})

interface Props {
  children: React.ReactNode
}

const Component = (props: Props) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [options, setOptions] = useState<Options>({
    title: '',
    message: '',
  })

  const [callback, setCallback] = useState<{ fn?: (_: boolean) => void }>({})

  return (
    <DialogContext.Provider value={{ open }}>
      {props.children}
      <Dialog {...options} isOpen={isDialogOpen} setOpen={setIsDialogOpen} onConfirm={close} />
    </DialogContext.Provider>
  )

  function open(options: Options, cb: (_: boolean) => void) {
    setIsDialogOpen(true)
    setOptions(options)
    setCallback({ fn: cb })
  }

  function close(result: boolean) {
    setIsDialogOpen(false)
    callback.fn && callback.fn(result)
  }
}

export default Component
Component.displayName = 'Provider'
