import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocalStorage } from 'react-use'
import * as selectors from 'selectors'
import { RootState } from 'store'
// Step is an enum representing the state of a demo for a user.
// It is ordered so that we can make general high-level decisions
// for example `if (step > NeedsRole) { doThing() }`
export enum DemoStep {
  Finished,
  NeedsRole,
  NeedsModules,
  NeedsCandidates,
  NeedsNotifications,
}

export function useDemo(): { step: DemoStep; isInDemo: boolean } {
  const roleIDs = useSelector((state: RootState) => [
    ...Object.keys(state.openjobroles.total),
    ...(state.roles.roles?.roleIds || []),
  ])
  const roles = useSelector((state: RootState) => roleIDs.map(id => selectors.roles.findById(state, id)))
  const role = roles?.[0]
  const candidateIDs = useSelector((state: RootState): string[] => [
    ...Object.keys(state.candidates.fetchedAt),
    ...Object.values(state.openjobroles.candidates).flat(),
  ])
  const candidate = useSelector((state: RootState) => selectors.candidates.findById(state, candidateIDs[0]))
  const selfAssessmentSubmission = useSelector((state: RootState) =>
    selectors.questionnaireSubmissions.findSelfAssessmentByCandidateId(state, candidate?.id || ''),
  )

  // TODO: this could be server state
  const [hasFinishedOnce, setHasFinishedOnce] = useLocalStorage('demo-has-finished-once', false)

  const step = ((): DemoStep => {
    if (hasFinishedOnce) {
      return DemoStep.Finished
    }
    if (!roles?.length) {
      return DemoStep.NeedsRole
    }
    if (
      (role.fields.ask_reference_check && !role.fields.reference_check_preset_id) ||
      (role.fields.ask_self_assessment && !role.fields.self_assessment_preset_id)
    ) {
      return DemoStep.NeedsModules
    }
    if (!candidateIDs.length) {
      return DemoStep.NeedsCandidates
    }
    if (
      (role.fields.ask_reference_check && !candidate?.fields.invited_at) ||
      (role.fields.ask_self_assessment && !selfAssessmentSubmission)
    ) {
      return DemoStep.NeedsNotifications
    }
    return DemoStep.Finished
  })()

  useEffect(() => {
    if (step === DemoStep.Finished) {
      setHasFinishedOnce(true)
    }
  }, [step])

  return {
    step: step,
    isInDemo: step > DemoStep.Finished,
  }
}
