import React, { Dispatch, SetStateAction } from 'react'
import { Visible } from '.'
import { Item as ItemType } from '../../../api/assessment-versions'
import { size, space, style } from '../../../core'
import Item from './../Item'

const Items = style().grid().spacing({ rows: space.l }).size({ width: size.fill }).element()

interface Props {
  candidateId: string
  referenceId?: string
  items: ItemType[]
  visible?: Visible
  updateVisible?: Dispatch<SetStateAction<Visible>>
  children?: React.ReactNode
}

const Component = (props: Props) => {
  return (
    <Items>
      {props.children}
      {props.items
        .filter(
          item =>
            item.dashboard_view && !item.dashboard_view.reference_profile && !item.dashboard_view.parent_question_id,
        )
        .map(item => (
          <Item
            key={item.content_id}
            candidateId={props.candidateId}
            referenceId={props.referenceId}
            item={item}
            updateVisible={props.updateVisible}
            visible={props.visible}
          />
        ))}
    </Items>
  )
}

export default Component
Component.displayName = 'Feedback'
