import { useGrowthHints } from 'providers/candidate-summaries'
import { useSelectedCandidate } from 'providers/candidates'
import { useModule } from 'providers/modules'
import React from 'react'
import { useParams } from 'react-router-dom'
import { GROWTH_HINTS_QUESTION } from 'store/new-role/content'
import { SummaryContainer } from './SummaryContainer'
import { TestimonialBlock, TestimonyType } from './TestimonialBlock'

interface GrowthHintsBlockUIProps {
  testimonies: TestimonyType[]
  detailsUrl?: string
}

const GrowthHintsBlockUI: React.FC<GrowthHintsBlockUIProps> = ({ testimonies, detailsUrl }) => {
  return (
    <SummaryContainer emoji={'🎁'} title={'Growth Hints'} hintColor={'orange'} to={detailsUrl}>
      <TestimonialBlock testimonies={testimonies} />
    </SummaryContainer>
  )
}

interface GrowthHintsBlockProps {
  container?: React.FC
}

const GrowthHintsBlock: React.FC<GrowthHintsBlockProps> = ({ container }) => {
  const { candidate } = useSelectedCandidate()
  const { isLoading, testimonies } = useGrowthHints({
    candidateId: candidate?.id,
  })

  const module = useModule({ slug: 'growth-hints' })
  const targetId = candidate?.fields.questionnaire_id ? module?.id : GROWTH_HINTS_QUESTION.test_item_id
  const { roleid, candidateid } = useParams<{
    roleid: string
    candidateid: string
  }>()

  const detailsUrl = `/roles/${roleid}/candidates/${candidateid}/responses#${targetId}`

  if (isLoading || !testimonies || !testimonies.length) {
    return null
  }

  const Container = container || React.Fragment

  return (
    <Container>
      <GrowthHintsBlockUI testimonies={testimonies} detailsUrl={detailsUrl} />
    </Container>
  )
}

export default GrowthHintsBlock
