import { Entity } from '../../api/request'
import { RootState } from '../../store'
import { buildKey } from '../../store/resources'
import resourceNames from './resource-names'

interface Query {
  user?: string
  profile?: string
  team?: string
  org?: string
  openjobrole?: string
  assessment?: string
  genericAssessment?: string
  candidate?: string
  employee?: string
  candidateProfile?: string
  candidateScore?: string
  assessmentVersion?: string
  webhook?: string
  scale?: string
  response?: string
  subscore?: string
  scaleScore?: string
}

export function lookup<T>(state: RootState, query: Query): Entity<T> {
  const key = Object.keys(query)[0]
  const id = query[key]

  return state.resources[buildKey(resourceNames[key], id)]
}
