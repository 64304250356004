import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { colors, rem, scale, space, style } from '../../core'

const BreadcrumbsStyle = style()
  .size({ height: rem(1.75) })
  .center({ vertical: true })
  .element()

const BreadcrumbStyle = style()
  .box({ inlineBlock: true })
  .sans({ noWrap: true })
  .with(({ ellipsis }: { ellipsis: boolean }) => style().sans({ ellipsis }))
  .select(
    'a, span',
    style().sans({
      size: scale.s,
      color: colors.gray,
      nodecoration: true,
    }),
  )
  .select(
    'a:hover',
    style().color({
      fg: colors.midGray,
    }),
  )
  .element()

const Separator = style()
  .inlineBlock()
  .sans({ size: scale.s, color: colors.lightMoonGray, align: 'center' })
  .spacing({ outer: [space.none, space.xs] })
  .select(':before', style().content('/'))
  .element()

export interface Breadcrumb {
  url?: string
  label: string
}

export const Breadcrumbs = (props: { content: Breadcrumb[] }) => {
  const location = useLocation<{ previousPath: string }>()
  const history = useHistory()

  function clickOnBreadcrumb(url?: string) {
    const currentRoleId = location.pathname.split('/')[2]
    const previousPath = location.state?.previousPath

    const previousPathRoleId = previousPath?.split('/')[2]

    if (previousPath?.search('roles') !== -1 && previousPathRoleId === currentRoleId) {
      history.goBack()
    } else {
      if (url) history.push(url)
    }
  }

  return (
    <BreadcrumbsStyle>
      {props.content.map((b, ind) => (
        <React.Fragment key={ind}>
          <BreadcrumbStyle ellipsis={ind === 0}>
            {b.url ? (
              <span
                className={b.url.includes('roles') && b.url.includes('candidates') ? 'data-hj-suppress' : ''}
                onClick={() => clickOnBreadcrumb(b.url)}
                style={{ cursor: 'pointer' }}
              >
                {b.label}
              </span>
            ) : (
              <span>{b.label}</span>
            )}
          </BreadcrumbStyle>
          {ind < props.content.length - 1 ? <Separator /> : null}
        </React.Fragment>
      ))}
    </BreadcrumbsStyle>
  )
}

export default Breadcrumbs
