import React from 'react'
import classes from './TaskCard.module.scss'

import { FaCheckCircle } from 'react-icons/fa'
import { ArrowIcon } from '../../Home/ArrowIcon/ArrowIcon'
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner'
import { Text } from '../../Text/Text'

interface TaskCardProps {
  title: string
  description: string
  completed: boolean
  onClick?: () => void
  loading?: boolean
}

export const TaskCard = (props: TaskCardProps) => {
  return (
    <div className={classes.TaskCard} onClick={props.onClick}>
      {props.completed ? <FaCheckCircle className={classes.circleActive} /> : <div className={classes.circle} />}
      <div className={`${classes.content} ${props.completed ? classes.contentActive : ''}`}>
        <Text variant="card-header" style={{ height: '24px', alignItems: 'center', display: 'flex' }}>
          {props.title}
        </Text>
        {!props.completed && !props.loading ? <Text variant="body-text">{props.description}</Text> : null}
        {props.loading && <LoadingSpinner className={classes.loadingSpinner} />}
      </div>
      <ArrowIcon className={props.completed ? classes.contentActive : ''} />
    </div>
  )
}
