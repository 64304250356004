import { isPresent } from 'core/utils'
import { Column, ColumnFilterElementTemplateOptions } from 'primereact/column'
import { DataTableFilterMeta } from 'primereact/datatable'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'

import { LoadingText } from '@common/components'
import { DateFilter } from '@common/components/Table/DateFilter'
import '@common/components/Table/PrimeReactTable.scss'
import PrimeReactTable from '@common/components/Table/PrimeReactTable'
import * as api from 'api'
import { CandidateName } from 'App/OpenJobRole/CandidateName'
import { Candidate, getCandidateTableData } from 'App/OpenJobRole/candidates'
import { CandidateTableColumnSelector, getMultiselectColumns } from 'App/OpenJobRole/ColumnSelector'
import { AssessmentStatusDropdown, HiringStatusDropdown, RefCheckStatusDropdown } from 'App/OpenJobRole/DropdownFilter'
import { defaultFilters, ScoreMatchModeOptions } from 'App/OpenJobRole/filters'
import { ScoreLink } from 'App/OpenJobRole/ScoreLink'
import { assessmentStatusTemplate, refStatusTemplate } from 'App/OpenJobRole/StatusTemplate'
import DropdownField, { Option } from 'components/DropdownField'
import { style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import * as tracking from 'core/track'
import { InputNumber } from 'primereact/inputnumber'
import { useEvaluationStatus, useHireStatus } from 'providers/candidates/hire-status'
import { remove } from 'store/candidates'
import { allEvaluationStatuses, evaluationStatusToText } from 'utils/evaluation_status'
import { CandidateBulkActions } from './BulkActions'

const RefCheckWarningsText = text
  .bodyText()
  .cond(({ warning }) => !!warning, style().color({ fg: designSystemColors.uiStatusError }))
  .element()

/** State that is stored in the route, so that we can retrieve all the filters when navigating back */
export interface LazyTableState {
  first: number
  rows: number
  page: number
  sortField?: string
  sortOrder?: 0 | 1 | -1 | null | undefined
  filters: DataTableFilterMeta
  /** Used in the table component to show columns */
  visibleColumns?: Record<string, boolean>
}

interface Props {
  role: api.openjobroles.Fields
  isReferenceCheckActivated: boolean
  isSelfAssessmentActivated: boolean
  loadCandidates: () => void
  lazyState: LazyTableState
  setLazyState: (state: LazyTableState) => void
  candidates: string[]
  total: number
  loading: boolean
  selfAssessmentPresetId: string
}

export default function CandidatesTable(props: Props) {
  const dispatch = useDispatch()

  useEffect(() => {
    props.loadCandidates()
  }, [props.lazyState])

  const { updateHireStatus } = useHireStatus()
  const { updateEvaluationStatus } = useEvaluationStatus()

  const StageTemplate = (candidate: Candidate) => {
    const [isUpdating, setIsUpdating] = useState(false)

    return (
      <DropdownField
        key={`hire-status-${candidate.id}`}
        value={candidate.hiring_status}
        onChange={async status => {
          try {
            setIsUpdating(true)
            await updateHireStatus(candidate.id, status === 'hired', status === 'rejected')
          } finally {
            setIsUpdating(false)
          }

          tracking.candidates.changeHireStatus()
        }}
      >
        <Option emphasisOnSelect value={'hired'}>
          {isUpdating ? <LoadingText text={'Loading'} /> : 'Hired'}
        </Option>
        <Option value={'in-progress'}> {isUpdating ? <LoadingText text={'Loading'} /> : 'In Progress'}</Option>
        <Option value={'rejected'}> {isUpdating ? <LoadingText text={'Loading'} /> : 'Rejected'}</Option>
      </DropdownField>
    )
  }

  const EvaluationStatusTemplate = (candidate: Candidate) => {
    const [isUpdating, setIsUpdating] = useState(false)

    return (
      <DropdownField
        key={`evaluation-status-${candidate.id}`}
        value={candidate.evaluation_status}
        onChange={async status => {
          try {
            setIsUpdating(true)
            await updateEvaluationStatus(candidate.id, status)
          } finally {
            setIsUpdating(false)
          }
          tracking.candidates.changeEvaluationStatus()
        }}
      >
        {allEvaluationStatuses.map(s => (
          <Option key={`evaluation_status-option-${s}`} value={s} selected={s === candidate.evaluation_status}>
            {isUpdating ? <LoadingText text={'Loading'} /> : evaluationStatusToText(s)}
          </Option>
        ))}
      </DropdownField>
    )
  }

  const EvaluationStatusFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <DropdownField
        placeholder={'Select evaluation status'}
        value={options.value}
        onChange={value => {
          options.filterCallback(value, options.index)
        }}
      >
        {allEvaluationStatuses.map(s => (
          <Option key={`${options.index}-evaluation-${s}`} value={s}>
            {evaluationStatusToText(s)}
          </Option>
        ))}
      </DropdownField>
    )
  }

  const refCheckAlertsTemplate = (candidate: Candidate) => {
    return (
      <RefCheckWarningsText
        key={`reference-check-alerts-${candidate.id}`}
        warning={(candidate.referenceCheckFraudWarnings || 0) > 0}
      >
        {candidate.referenceCheckFraudWarnings || '-'}
      </RefCheckWarningsText>
    )
  }

  const scoreFilterTemplate = options => {
    return (
      <InputNumber
        value={options.value}
        onChange={e => options.filterCallback(e.value, options.index)}
        mode="decimal"
        min={0}
        max={100}
        suffix={'%'}
      />
    )
  }

  const scoreLink = (candidate: Candidate, score: string, route: string) => {
    // Return null if score is missing or assessment isn't completed
    if (score === '-' || candidate.assessment_completion_status !== 'completed') {
      return null
    }
    return `/roles/${props.role.id}/candidates/${candidate.id}/assessment/${route}`
  }

  const candidates: Candidate[] = useSelector((state: RootState) =>
    props.candidates.map(id => getCandidateTableData(state, id)).filter(isPresent),
  )

  const completedCandidates = useMemo(
    () => candidates.filter(c => c.assessment_completion_status === 'completed'),
    [candidates],
  )

  const [groupScores, setGroupScores] = useState(completedCandidates[0]?.groupScores || [])
  const [moduleScores, setModuleScores] = useState(completedCandidates[0]?.moduleScores || [])

  // update group and module scores only if they are bigger than previous
  // this is to avoid groups disappearing when sorting
  useEffect(() => {
    setGroupScores(previousValue => {
      //only update if length is bigger than previous
      if (completedCandidates[0]?.groupScores.length > previousValue.length) {
        return completedCandidates[0]?.groupScores
      }
      return previousValue
    })
    setModuleScores(previousValue => {
      //only update if length is bigger than previous
      if (completedCandidates[0]?.moduleScores.length > previousValue.length) {
        return completedCandidates[0]?.moduleScores
      }
      return previousValue
    })
  }, [completedCandidates])

  for (const score of [...groupScores, ...moduleScores]) {
    defaultFilters[score.field] = {
      value: null,
      matchMode: 'gte',
    }
  }

  const [columns, setColumns] = useState<
    {
      field: string
      header: string
      selected: boolean
    }[]
  >([])

  // use props.lazyState.visibleColumns to set the initial state of the columns
  const { visibleColumns } = props.lazyState
  const setVisibleColumns = (newVisibleColumns: Record<string, boolean>) => {
    props.setLazyState({
      ...props.lazyState,
      visibleColumns: newVisibleColumns,
    })
  }

  const [selection, setSelection] = useState<Candidate[]>([])

  const columnData: typeof columns = useMemo(
    () =>
      getMultiselectColumns(
        props.isReferenceCheckActivated,
        props.isSelfAssessmentActivated,
        groupScores,
        moduleScores,
      ),
    [props.isReferenceCheckActivated, props.isSelfAssessmentActivated, groupScores, moduleScores],
  )

  // if info about visible columns is passed from the parent component, use it to set the initial state of the columns
  useEffect(() => {
    // if there's info on lazyState, then just use that
    if (visibleColumns !== undefined) {
      const newColumnData = columnData.map(column => ({
        ...column,
        selected: Boolean(visibleColumns[column.field]),
      }))

      setColumns(newColumnData)
    } else {
      // if not, set the initial state of the columns to be all selected
      const formattedVisibleColumns = formatVisibleColumns(columnData)
      setColumns(columnData)
      setVisibleColumns(formattedVisibleColumns)
    }
  }, [columnData.length])

  return (
    <PrimeReactTable<Candidate[]>
      testId="candidates-table"
      rows={props.lazyState.rows}
      total={props.total}
      loading={props.loading}
      setlazyState={props.setLazyState}
      lazyState={props.lazyState}
      data={candidates}
      header={() => {
        if (selection.length) {
          return (
            <CandidateBulkActions
              numSelected={selection.length}
              onDelete={async () => {
                await dispatch(remove(...selection.map(c => c.id)))
                props.loadCandidates()
              }}
            />
          )
        }

        return (
          <CandidateTableColumnSelector
            setVisibleColumns={setVisibleColumns}
            visibleColumns={visibleColumns}
            columns={columns}
          />
        )
      }}
      selection={selection}
      onSelectionChange={s => setSelection(s.value)}
    >
      <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
      <Column
        field={'candidate_profiles.full_name'}
        header={'Name'}
        body={(candidate: Candidate) => (
          <CandidateName
            roleId={props.role.id}
            isReferenceCheckActivated={props.isReferenceCheckActivated}
            candidate={candidate}
          />
        )}
        style={{ minWidth: '11rem' }}
        sortable
        filter
        showAddButton={false}
        showFilterOperator={false}
        filterPlaceholder="Name"
        filterMenuClassName={'hipeopleFilter'}
        onFilterApplyClick={tracking.candidates.candidateSearchTableFilteredByName}
      />
      <Column
        style={{ minWidth: '8rem' }}
        field={'score'}
        header={'Overall'}
        body={candidate => (
          <ScoreLink
            score={candidate.score || '-'}
            link={scoreLink(candidate, candidate.score || '-', '')}
            tracking={() => {
              tracking.candidates.tableScoreClicked('overall', '')
            }}
          />
        )}
        sortable
        sortField={'questionnaire_submission_scores.score'}
        hidden={!visibleColumns?.score}
        filterField="questionnaire_submission_scores.score"
        filter
        dataType="numeric"
        showFilterOperator={false}
        showAddButton={false}
        filterMatchModeOptions={ScoreMatchModeOptions}
        filterElement={scoreFilterTemplate}
      />

      {groupScores.map((groupScore, index) => {
        return (
          <Column
            key={`group-score-${index}`}
            field={groupScore.field}
            header={groupScore.entity?.copy?.short_title || groupScore.entity?.copy?.title}
            body={candidate => (
              <ScoreLink
                score={candidate.groupScores[index]?.score || '-'}
                link={scoreLink(candidate, candidate.groupScores[index]?.score || '-', groupScore.entity?.slug || '')}
                tracking={() => tracking.candidates.tableScoreClicked('group', groupScore.entity?.copy.title || '')}
              />
            )}
            style={{ minWidth: '8rem' }}
            hidden={!visibleColumns?.[groupScore.field]}
            sortable
            sortField={groupScore.field}
            filterField={groupScore.field}
            filter
            dataType="numeric"
            showFilterOperator={false}
            showAddButton={false}
            filterMatchModeOptions={ScoreMatchModeOptions}
            filterElement={scoreFilterTemplate}
          />
        )
      })}

      {moduleScores.map((moduleScore, index) => {
        return (
          <Column
            key={`module-score-${index}`}
            field={moduleScore.field}
            header={moduleScore.entity?.copy?.title}
            body={candidate => (
              <ScoreLink
                score={candidate.moduleScores[index]?.score || '-'}
                link={scoreLink(candidate, candidate.moduleScores[index]?.score || '-', moduleScore.entity?.slug || '')}
                tracking={() => {
                  tracking.candidates.tableScoreClicked('module', moduleScore.entity?.copy.title || '')
                }}
              />
            )}
            style={{ minWidth: '8rem' }}
            hidden={!visibleColumns?.[moduleScore.field]}
            sortable
            sortField={moduleScore.field}
            filterField={moduleScore.field}
            filter
            dataType="numeric"
            showFilterOperator={false}
            showAddButton={false}
            filterMatchModeOptions={ScoreMatchModeOptions}
            filterElement={scoreFilterTemplate}
          />
        )
      })}

      <Column
        field={'assessmentCompletedAt'}
        header={'Assessment Completed'}
        sortable
        sortField={'questionnaire_submissions.submitted_at'}
        style={{ minWidth: '8rem' }}
        hidden={!visibleColumns?.assessment_completed_at}
        showAddButton={false}
        showFilterOperator={false}
        filterField={'questionnaire_submissions.submitted_at'}
        filter
        filterElement={DateFilter}
        dataType={'date'}
      />
      <Column
        field={'assessment_completion_status'}
        header={'Assessment Status'}
        body={assessmentStatusTemplate}
        sortable
        sortField={'assessment_completion_status'}
        style={{ minWidth: '8rem' }}
        hidden={!visibleColumns?.assessment_completion_status}
        filter
        showFilterMatchModes={false}
        filterElement={AssessmentStatusDropdown}
        filterPlaceholder="Assessment Status"
        filterMenuClassName={'hipeopleFilter'}
        onFilterApplyClick={(e: any) => {
          tracking.candidates.candidateSearchTableFilteredByAssessmentStatus(e.constraints.value)
        }}
      />

      <Column
        field={'refCheckAlerts'}
        header={'Reference Check Alerts'}
        body={refCheckAlertsTemplate}
        style={{ minWidth: '10rem' }}
        hidden={!props.isReferenceCheckActivated}
        sortable
        sortField={'reference_check_summaries.fraud_warnings'}
      />
      <Column
        field={'refCheckCompletedAt'}
        header={'Reference Check done'}
        sortable
        sortField={'completed_at'}
        style={{ minWidth: '10rem' }}
        hidden={!visibleColumns?.ref_check_completed_at}
        showAddButton={false}
        showFilterOperator={false}
        filterField="completed_at"
        filter
        filterElement={DateFilter}
        dataType={'date'}
      />
      <Column
        header={'Reference Check Status'}
        body={refStatusTemplate}
        sortable
        field={'reference_check_status'}
        style={{ minWidth: '12rem' }}
        hidden={!visibleColumns?.reference_check_status}
        filter
        showFilterMatchModes={false}
        filterElement={RefCheckStatusDropdown}
        filterPlaceholder="Reference Check Status"
        filterMenuClassName={'hipeopleFilter'}
        onFilterApplyClick={(e: any) => {
          tracking.candidates.candidateSearchTableFilteredByRefCheckStatus(e.constraints.value)
        }}
      />
      <Column
        field={'evaluation_status'}
        header={'Evaluation'}
        body={EvaluationStatusTemplate}
        style={{ minWidth: '10rem' }}
        // sortable
        // sortField={'evaluation_status'}
        filter
        showFilterMatchModes={false}
        filterElement={EvaluationStatusFilterTemplate}
        filterPlaceholder="Evaluation Status"
        filterMenuClassName={'hipeopleFilter'}
        onFilterApplyClick={(e: any) => {
          tracking.candidates.candidateSearchTableFilteredByEvaluationStatus(e.constraints.value)
        }}
        hidden={!visibleColumns?.evaluation_status}
      />
      <Column
        field={'hiring_status'}
        header={'Stage'}
        body={StageTemplate}
        style={{ minWidth: '10rem' }}
        sortable
        sortField={'hiring_status'}
        filter
        showFilterMatchModes={false}
        filterElement={HiringStatusDropdown}
        filterPlaceholder="Reference Check Status"
        filterMenuClassName={'hipeopleFilter'}
        onFilterApplyClick={(e: any) => {
          tracking.candidates.candidateSearchTableFilteredByStage(e.constraints.value)
        }}
        hidden={!visibleColumns?.hiring_status}
      />
    </PrimeReactTable>
  )
}

function formatVisibleColumns(
  columns: { field: string; header: string; selected: boolean }[],
): Record<string, boolean> {
  return Object.fromEntries(columns.map(column => [column.field, column.selected]))
}
