import { px, space } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import { useEmployee } from 'providers/employees'
import React from 'react'

const Banner = text
  .bodyInteractive()
  .color({ bg: designSystemColors.backgroundNeutralTertiary })
  .flex({ alignItems: 'center', justifyContent: 'space-between' })
  .spacing({ inner: space.s })
  .round(px(8))
  .element()

const Button = text
  .bodyInteractive()
  .color({ bg: designSystemColors.backgroundNeutralAccent, fg: 'white' })
  .spacing({ inner: space.xs })
  .round(px(6))
  .cursor('pointer')
  .element()

interface Props {
  candidateId: string
  onClickUpdateMissing?: () => void
}

const Component: React.FC<Props> = props => {
  const { missingFields } = useEmployee({ candidateId: props.candidateId })

  if (missingFields.length === 0) return <></>

  return (
    <Banner>
      The following information is missing: {missingFields.join(', ')}
      {props.onClickUpdateMissing && <Button onClick={props.onClickUpdateMissing}>Add missing information</Button>}
    </Banner>
  )
}

export default Component

Component.displayName = 'MissingInfoBanner'
