import { GroupIcon, Text, VideoPlayer } from '@common/components'
import classes from '@common/components/Scoring/ScoreGroup/ScoreGroup.module.scss'
import { Icon as FAIcon } from 'components/Icons'
import { px, px2rem, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import React from 'react'
import { AssessmentAnswerQuestion } from 'selectors/self-assessment'
import { Chip } from '../Chips'
import { ScoreCard } from '@common/components'

import { Icon as FaIcon } from 'components/Icons'
import { Group } from './AssessmentPage/AssessmentPage'

type IconColour = 'blue' | 'purple' | 'orange' | 'green'

const IconWrapper = style()
  .inlineFlex({ alignItems: 'center', justifyContent: 'center' })
  .sans({ size: px2rem(12) })
  .round(px(4))
  .size({ width: px2rem(22), height: px2rem(22) })
  .with(({ colour }: { colour: IconColour }) => {
    switch (colour) {
      case 'blue':
        return style().color({
          fg: designSystemColors.informationBluePrimary,
          bg: designSystemColors.informationBlueBackground,
        })
      case 'purple':
        return style().color({
          fg: designSystemColors.informationPurplePrimary,
          bg: designSystemColors.informationPurpleBackground,
        })
      case 'orange':
        return style().color({
          fg: designSystemColors.informationOrangePrimary,
          bg: designSystemColors.informationOrangeBackground,
        })
      case 'green':
        return style().color({
          fg: designSystemColors.informationGreenPrimary,
          bg: designSystemColors.informationGreenBackground,
        })
      default:
        return style()
    }
  })
  .element('span')

const Icon: React.FC<{ colour: IconColour; name: string }> = props => (
  <IconWrapper colour={props.colour}>
    <FAIcon name={props.name} />
  </IconWrapper>
)

interface ScoreGroupProps {
  customQuestionAnswers: AssessmentAnswerQuestion[] | null
  onResponsesClick: (moduleSlug: string, group: Group) => void
}

// Hacked together quick for NIQ demo
const ScoreGroupTempCustomQuestions = (props: ScoreGroupProps) => {
  return (
    <ScoreCard>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem',
          paddingBlockEnd: '1rem',
          borderBottom: '1px solid #E4E4E480',
        }}
      >
        <GroupIcon theme="#055FE2" icon={<FaIcon name="list-alt" />} />
        <Text variant="card-header" style={{ marginLeft: '0.5rem' }}>
          Custom Questions
        </Text>
      </div>

      <div
        className={classes.fullWidthSubgridItem}
        style={{
          gap: '0.5rem',
        }}
      >
        {(props.customQuestionAnswers || []).map(q => {
          switch (q.question.response_type) {
            case 'text-input':
              return (
                <ScoreCard
                  key={q.question.id}
                  style={{
                    display: 'flex',
                    gap: '2.5rem',
                  }}
                >
                  <Icon colour="blue" name="font-case" />
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                    <QuestionHeading>{q.question.copy.heading}</QuestionHeading>
                    <TextAnswer>{q.text_input ? q.text_input : 'This question was skipped.'}</TextAnswer>
                  </div>
                </ScoreCard>
              )
            case 'optionset':
            case 'chips':
              return (
                <ScoreCard
                  key={q.question.id}
                  style={{
                    display: 'flex',
                    gap: '2.5rem',
                  }}
                >
                  <Icon colour="orange" name="tasks" />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '0.5rem',
                    }}
                  >
                    <QuestionHeading>{q.question.copy.heading}</QuestionHeading>
                    <TextAnswer>
                      {q.selected?.length
                        ? q.selected.map(opt => <Chip key={opt.id}>{opt.copy?.caption}</Chip>)
                        : 'This question was skipped.'}
                    </TextAnswer>
                  </div>
                </ScoreCard>
              )
            case 'video':
              return (
                <ScoreCard
                  key={q.question.id}
                  style={{
                    display: 'flex',
                    gap: '2.5rem',
                  }}
                >
                  <Icon colour="green" name="video" />
                  <div>
                    <QuestionHeading>{q.question.copy.heading}</QuestionHeading>
                    {q.media_url ? (
                      <VideoPlayer src={q.media_url} />
                    ) : (
                      <Text variant="body-text">This question was skipped.</Text>
                    )}
                  </div>
                </ScoreCard>
              )
          }
          return undefined
        })}
      </div>
    </ScoreCard>
  )
}

const TextAnswer: React.FC<{ children: React.ReactNode }> = props => {
  return (
    <div style={{ color: '#666666' }}>
      <Text variant="label">ANSWER</Text>
      <Text variant="body-text" style={{ marginTop: '6px' }}>
        {props.children}
      </Text>
    </div>
  )
}

const QuestionHeading: React.FC<{ children: React.ReactNode }> = props => {
  return (
    <Text
      variant="card-header"
      style={{
        gridColumn: '1 / -1', // span all columns
        paddingBlockEnd: '1rem',
      }}
    >
      {props.children}
    </Text>
  )
}

export default ScoreGroupTempCustomQuestions
