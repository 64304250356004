import { useKeyAcheivements } from 'providers/candidate-summaries'
import { useSelectedCandidate } from 'providers/candidates'
import { useModule } from 'providers/modules'
import React from 'react'
import { useParams } from 'react-router-dom'
import { KEY_ACCOMPLISHMENTS_QUESTION } from 'store/new-role/content'
import { SummaryContainer } from './SummaryContainer'
import { TestimonialBlock, TestimonyType } from './TestimonialBlock'

interface KeyAchievementsBlockUIProps {
  testimonies: TestimonyType[]
  detailsUrl?: string
}

const KeyAchievementsBlockUI: React.FC<KeyAchievementsBlockUIProps> = ({ testimonies, detailsUrl }) => {
  return (
    <SummaryContainer emoji={'🗝'} title={'Key Achievements'} hintColor={'purple'} to={detailsUrl}>
      <TestimonialBlock testimonies={testimonies} />
    </SummaryContainer>
  )
}

interface KeyAchievementsBlockProps {
  container?: React.FC
}

const KeyAchievementsBlock: React.FC<KeyAchievementsBlockProps> = ({ container }) => {
  const { candidate } = useSelectedCandidate()
  const { isLoading, testimonies } = useKeyAcheivements({
    candidateId: candidate?.id,
  })

  const module = useModule({ slug: 'key-achievements' })
  const targetId = candidate?.fields.questionnaire_id ? module?.id : KEY_ACCOMPLISHMENTS_QUESTION.test_item_id
  const { roleid, candidateid } = useParams<{
    roleid: string
    candidateid: string
  }>()
  const detailsUrl = `/roles/${roleid}/candidates/${candidateid}/responses#${targetId}`

  if (isLoading || !testimonies || !testimonies.length) {
    return null
  }

  const Container = container || React.Fragment

  return (
    <Container>
      <KeyAchievementsBlockUI testimonies={testimonies} detailsUrl={detailsUrl} />
    </Container>
  )
}

export default KeyAchievementsBlock
