import React, { createContext, PropsWithChildren, useContext } from 'react'
import { usePopover, PopoverSide } from '../../hooks'
import { Button } from '../Button/Button'
import { Portal } from '../Portal/Portal'
import './Popover.scss'

type PopoverProps = PropsWithChildren<{
  side: PopoverSide
}>

type PopoverContentProps = {
  className?: string
  children: React.ReactNode
}

const PopoverContext = createContext({} as ReturnType<typeof usePopover>)

export const Popover = ({ children, side = 'bottom' }: PopoverProps) => {
  const { ...contextValues } = usePopover(side)
  return <PopoverContext.Provider value={contextValues}>{children}</PopoverContext.Provider>
}

export const PopoverTrigger = ({ children, className, ...rest }: React.ComponentProps<typeof Button>) => {
  const { triggerRef, setActive } = useContext(PopoverContext)

  return (
    <Button
      variant="ghost"
      ref={triggerRef}
      className={className}
      style={{ padding: 0 }}
      onClick={() => setActive(active => !active)}
      {...rest}
    >
      {children}
    </Button>
  )
}

export const PopoverContent = ({ className, children }: PopoverContentProps) => {
  const { ref, styles, active } = useContext(PopoverContext)

  return (
    <Portal label="popover-portal">
      <div ref={ref} data-active={active} className={['popoverContent', className].join(' ')} style={styles}>
        {children}
      </div>
    </Portal>
  )
}
