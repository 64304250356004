import React, { useMemo } from 'react'
import { LoadingBar } from '../LoadingBar/LoadingBar'
import { Text } from '../Text/Text'
import classes from './DrawerTextInput.module.scss'

export type DrawerTextInputOption = {
  id: string
  title: string
  onClick: () => void
  children?: React.ReactNode
  category?: string
}

interface DrawerTextInputProps {
  /** Options to display in drawer */
  options: DrawerTextInputOption[]
  /** Main loading state */
  loading: boolean
  responseLoading: boolean
  selected: string
  className?: string
  style?: React.CSSProperties
}

/**
 *  DrawerTextInput
 *  Component for displaying a text input that has a drawer with default options
 */
export const DrawerTextInput = (props: DrawerTextInputProps) => {
  const selectedOption = useMemo(() => {
    return props.options.find(option => option.title === props.selected || option.id === props.selected)
  }, [props.options, props.selected])

  return (
    <div className={props.className} style={props.style}>
      {(() => {
        if (props.loading) {
          return (
            <div className={classes.loadingWrapper}>
              <LoadingBar
                approximateTimeEstimate={30}
                loadingMessages={[
                  'Getting AI insights ready for you',
                  'Collecting assessment data',
                  'Calibrating against job profile',
                  'This might take some time, stay tuned',
                  'Preparing data',
                  'Building insights',
                  "Are you there? That's good!",
                  'Starting engines',
                  'Charging systems',
                  'Hmm, taking longer than expected',
                  'Launching insights',
                  'Still loading',
                ]}
              />
            </div>
          )
        }

        if (props.responseLoading) {
          return (
            <div className={classes.loadingWrapper}>
              <LoadingBar
                approximateTimeEstimate={6}
                loadingMessages={['AI is gathering resources', 'AI is thinking', 'AI is writing']}
              />
            </div>
          )
        }

        if (selectedOption?.children) {
          return <div className={classes.content}>{selectedOption.children}</div>
        }

        return (
          <div className={classes.drawer}>
            <CategoriesList options={props.options} />
          </div>
        )
      })()}
    </div>
  )
}

const CategoriesList = (props: { options: DrawerTextInputOption[] }) => {
  const categories: {
    [key: string]: DrawerTextInputOption[]
  } = React.useMemo(() => {
    const optionsPerCategory: { [key: string]: DrawerTextInputOption[] } = {}
    props.options?.forEach(option => {
      if (!option.category) {
        if (!optionsPerCategory['Default']) {
          optionsPerCategory['Default'] = []
        }
        optionsPerCategory['Default'].push(option)
        return
      }
      if (!optionsPerCategory[option.category]) {
        optionsPerCategory[option.category] = []
      }
      optionsPerCategory[option.category].push(option)
    })
    return optionsPerCategory
  }, [props.options])

  return (
    <>
      {Object.keys(categories).map((category, index) => {
        return (
          <div key={index} style={{ width: '100%' }}>
            {category !== 'Default' && (
              <Text variant="small-body-text" className={classes.category}>
                {category}
              </Text>
            )}
            {categories[category].map((option, index) => {
              return (
                <div key={index} className={classes.option} onClick={option.onClick}>
                  {option.title}
                </div>
              )
            })}
          </div>
        )
      })}
    </>
  )
}
