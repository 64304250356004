import { Fields, ModuleEntity, ModuleType, RESOURCE } from 'api/modules'
import * as selectors from 'selectors'
import { RootState } from 'store'
import lookup from './lookup'

export function findById(state: RootState, id: string) {
  return lookup<Fields>(state, RESOURCE, id)
}

export function latest(state: RootState, moduleType: ModuleType) {
  const modules: Record<string, ModuleEntity> = {}
  for (const module of state.modules.modules[moduleType]) {
    if (!modules[module.fields.slug]) {
      modules[module.fields.slug] = module
      continue
    }
    if (modules[module.fields.slug].fields.version < module.fields.version) {
      modules[module.fields.slug] = module
    }
  }
  return Object.values(modules)
}

export function findByQuestionId(state: RootState, questionId: string) {
  const question = selectors.questions.findById(state, questionId)
  if (!question || !question.fields.module_id) return

  return findById(state, question.fields.module_id)
}

// FIXME: We should lookup the module using the module id in the questionnaire instead of the slug. This way, instead of getting a undefined module version, we get the version that was latest at the time of rendering the questionnaire from the preset
export function findBySlug(state: RootState, slug: string): ModuleEntity | undefined {
  return Object.values(state.resources)
    .filter(r => r.resource === RESOURCE)
    .find(r => r.fields.slug === slug)
}

export function allLatest(state: RootState) {
  const modules: Record<string, ModuleEntity> = {}
  for (const module of Object.values(state.modules.modules).flat()) {
    if (!modules[module.fields.slug]) {
      modules[module.fields.slug] = module
      continue
    }
    if (modules[module.fields.slug].fields.version < module.fields.version) {
      modules[module.fields.slug] = module
    }
  }
  return Object.values(modules)
}
type BessiDomain = {
  id: string
  name: string
  emoji: string
  description: string
  facets: string[]
}

export const BESSI_DOMAINS: BessiDomain[] = [
  {
    id: 'compound-skills',
    name: 'Compound Skills',
    emoji: '🧬',
    description:
      'Skills that combine aspects of multiple skills domains. This domains consists of three skill facets: adaptability, capacity for independence, self-reflection skill',
    facets: ['bessiadaptability', 'bessicapacityforindependence', 'bessiselfreflectionskill'],
  },
  {
    id: 'cooperation-skills',
    name: 'Cooperation Skills',
    emoji: '👥',
    description:
      'Capacities that people use to maintain positive, harmonious, and satisfying social relationships. This domains consists of five skill facets: teamwork skill, capacity for trust, perspective-taking skill, capacity for social warmth, ethical competence',
    facets: [
      'bessicapacityforsocialwarmth',
      'bessicapacityfortrust',
      'bessiperspectivetakingskill',
      'bessiteamworkskill',
    ],
  },
  {
    id: 'emotional-resilience-skills',
    name: 'Emotional Resilience Skills',
    emoji: '🧘',
    description:
      'Capacities that people use to regulate their emotions and moods. This domains consists of five skill facets: stress regulation, capacity for optimism, anger management, confidence regulation, impulse regulation',
    facets: ['bessiangermanagement', 'bessicapacityforoptimism', 'bessiconfidenceregulation', 'bessistressregulation'],
  },
  {
    id: 'innovation-skills',
    name: 'Innovation Skills',
    emoji: '💡',
    description:
      'Capacities that people use to create, engage with, and learn from new ideas and experiences. This domains consists of five skill facets: abstract thinking skill, creative skill, artistic skill, cultural competence, information processing skill',
    facets: ['bessiabstractthinkingskill', 'bessiartisticskill', 'bessicreativeskill', 'bessiculturalcompetence'],
  },
  {
    id: 'self-management-skills',
    name: 'Self-Management Skills',
    emoji: '👤',
    description:
      'Capacities that people use to effectively pursue goals and complete tasks. This domains consists of nine skill facets: task management, time management, detail management, organizational skill, responsibility management, capacity for consistency, goal regulation, rule-following skill, decision-making skill',
    facets: [
      'bessicapacityforconsistency',
      'bessidecisionmakingskill',
      'bessidetailmanagement',
      'bessienergyregulation',
      'bessiethicalcompetence',
      'bessigoalregulation',
      'bessiimpulseregulation',
      'bessiinformationprocessingskill',
      'bessiorganizationalskill',
      'bessiresponsibilitymanagement',
      'bessirulefollowingskill',
      'bessitaskmanagement',
      'bessitimemanagement',
    ],
  },
  {
    id: 'social-engagement-skills',
    name: 'Social Engagement Skills',
    emoji: '👋',
    description:
      'Capacities that people use to actively engage with others in one-on-one and group interactions. This domains consists of four skill facets: leadership skill, persuasive skill, conversational skill, expressive skill, energy regulation',
    facets: ['bessiconversationalskill', 'bessiexpressiveskill', 'bessileadershipskill', 'bessipersuasiveskill'],
  },
]
