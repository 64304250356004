import React from 'react'
import { useDispatch } from 'react-redux'
import { rem, size, style } from '../../core'
import { copyToClipboard } from '../../core/utils'
import { add as notify } from '../../store/notifications'
import CreateButton from '../CreateButton'

interface Props {
  copyText: string
}

const Container = style()
  .grid({ columns: [size.auto, rem(12), size.auto] })
  .element()

const Empty = style().element()

const Component = (props: Props) => {
  const dispatch = useDispatch()

  return (
    <Container>
      <Empty />
      <CreateButton onClick={copy} icon={'copy'}>
        Copy to clipboard
      </CreateButton>
      <Empty />
    </Container>
  )

  function copy() {
    copyToClipboard(props.copyText)
    dispatch(
      notify({
        success: `Link copied to clipboard!`,
      }),
    )
  }
}

export default Component
Component.displayName = 'CopyLinkButton'
