import React from 'react'
import { breakpoints, colors, fr, rem, size, space, style } from '../../core'
import Button from '../Button/ButtonV1'
import Spinner from '../Spinner'

const Container = style()
  .sans({ noWrap: true })
  .border({ top: '1px solid', color: colors.lightMoonGray })
  .grid({ columns: [size.auto, rem(6), rem(9)] })
  .spacing({ inner: [space.xs, space.m], columns: space.xs })
  .cond(
    (props: Props) => !!props.forwardOnly,
    style().grid({
      columns: [size.auto, rem(9)],
    }),
  )
  .screen(
    breakpoints.mobile,
    style()
      .grid({ columns: fr(1) })
      .spacing({ inner: [space.xs, space.l, space.xs, space.s] }),
  )
  .element()

interface Props {
  next: () => void
  back: () => void
  label?: string
  secondaryLabel?: string
  disabled?: boolean
  forwardOnly?: boolean
  loading?: boolean
}

const Component = (props: Props) => {
  return (
    <Container {...props}>
      <div />
      {!props.forwardOnly ? (
        <Button minimal onClick={props.back}>
          {props.secondaryLabel || 'Back'}
        </Button>
      ) : null}
      <Button primary onClick={props.next} disabled={props.disabled} loading={props.loading}>
        {props.loading ? <Spinner size={1.2} /> : props.label || 'Next'}
      </Button>
    </Container>
  )
}

export default Component
Component.displayName = 'Buttons'
