import { ScoreResponse } from '@common/api/score'
import { getScore } from 'api/score'
import { hasPresentKey } from 'core/utils'
import groupBy from 'lodash-es/groupBy'
import { useCandidate } from 'providers/candidates'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as selectors from 'selectors'
import { BESSI_DOMAINS } from 'selectors/modules'
import { findByQuestionnaireId } from 'selectors/questionnaire-entries'
import { findByModuleIds } from 'selectors/questions'
import {
  AssessmentAnswersByDomain,
  AssessmentModuleAnswers,
  findCustomSelfAssessmentAnswers,
  findSelfAssessmentAnswers,
} from 'selectors/self-assessment'
import { RootState } from 'store'

export const useAssessmentData = (candidateId: string) => {
  const { candidate, candidateProfile } = useCandidate({ candidateId })
  const { selfAssessment, questionnaire } = useQuestionnaire(candidateId)
  const candidateRole = useSelector((state: RootState) => selectors.roles.findByCandidateId(state, candidateId))

  const questionnaireSubmission = useSelector((state: RootState) =>
    selectors.questionnaireSubmissions.findSelfAssessmentByCandidateId(state, candidateId),
  )

  const answers = useSelector((state: RootState) => findSelfAssessmentAnswers(state, candidateId)) || []
  const score = useScore(selfAssessment?.id)
  const moduleQuestions = useModuleQuestions(questionnaire)

  const candidateFirstName = selectors.firstName(candidateProfile?.fields.full_name || 'The candidate')
  const hasAnswers = answers.map(answer => answer.questionAnswers).flat().length > 0

  const personalityGroup = useSelector((state: RootState) => selectors.groups.findBySlug(state, 'personality'))
  const personality = answers.filter(field => field.module?.group_id === personalityGroup?.id)

  const cognitiveAbilities = answers?.filter(field => field.module?.type === 'cognitive-abilities') || []
  const candidateAnswers = answers.filter(field => field.module?.type === 'candidate-centered') || []
  const customQuestion = useSelector((state: RootState) => findCustomSelfAssessmentAnswers(state, candidateId))

  const assessmentAnswersByDomain = getAssessmentAnswersByDomain(answers)

  const cultureAddAnswersByDomain: AssessmentAnswersByDomain = useSelector((state: RootState) => {
    if (!answers) return []

    const cultureAddAnswers = answers.filter(field => {
      const group_id = field?.module?.group_id
      if (!group_id) return false
      const group = selectors.groups.findById(state, group_id)
      return group && group.fields.slug === 'cultureadd'
    })

    if (!cultureAddAnswers?.length) return []

    const groups = groupBy(
      cultureAddAnswers,
      answer =>
        (answer?.module?.inventory_id &&
          selectors.inventories.findById(state, answer.module.inventory_id)?.fields?.copy?.title) ||
        answer?.module?.copy?.title ||
        'Other',
    )

    return Object.entries(groups).map(([domainName, modules]) => ({
      domain: { name: domainName },
      answers: modules,
    }))
  })

  const cultureFit = useSelector((state: RootState) =>
    selectors.questionnaireSubmissions.findCultureFitCheckResponses(state, selfAssessment?.id || ''),
  )

  return {
    assessment: {
      selfAssessment,
      questionnaire,
      questionnaireSubmission,
      score,
      moduleQuestions,
    },

    context: {
      candidate,
      candidateProfile,
      candidateRole,
      candidateFirstName,
    },

    answers: {
      hasAnswers,
      personality,
      cognitiveAbilities,
      customQuestion,
      cultureFit,
      personalityGroup,
      candidateAnswers,
      assessmentAnswersByDomain,
      cultureAddAnswersByDomain,
    },
  }
}

const getAssessmentAnswersByDomain = (answers: AssessmentModuleAnswers[]): AssessmentAnswersByDomain => {
  const softSkillsAnswers = answers
    .filter(hasPresentKey('module'))
    .filter(field => field.module?.type === 'soft-skills' || field.module?.type === 'self-assessment')

  if (!softSkillsAnswers?.length) return []

  const groups = groupBy(
    softSkillsAnswers,
    ans => BESSI_DOMAINS.find(domain => domain.facets.includes(ans.module.slug))?.name || 'Other',
  )

  return Object.entries(groups).map(([name, modules]) => ({
    domain: { name },
    answers: modules,
  }))
}

const useQuestionnaire = (candidateId: string) => {
  const selfAssessment = useSelector((state: RootState) =>
    selectors.questionnaireSubmissions.findSelfAssessmentByCandidateId(state, candidateId),
  )

  const questionnaire = useSelector(
    (state: RootState) =>
      selectors.questionnaires.findById(state, selfAssessment?.fields.questionnaire_id || '') ?? undefined,
  )

  return { selfAssessment, questionnaire }
}

const useModuleQuestions = (questionnaire: any) => {
  const questionnaireModuleIds = useSelector((state: RootState) => {
    if (!questionnaire) return []
    const result = findByQuestionnaireId(state, questionnaire?.id)
    return (result || []).map(e => e.fields.module_id)
  })

  return useSelector((state: RootState) => findByModuleIds(state, questionnaireModuleIds))
}

const useScore = (submissionId?: string) => {
  const [score, setScore] = useState<ScoreResponse | null>(null)

  useEffect(() => {
    if (!submissionId) return
    getScore(submissionId).then(res => {
      const scoreResponse = res[0] as unknown as ScoreResponse
      if (!scoreResponse) return
      setScore(scoreResponse)
    })
  }, [submissionId])

  return score
}
