import React from 'react'
import { FaArrowRightLong } from 'react-icons/fa6'
import classes from './ArrowIcon.module.scss'

interface ArrowIconProps {
  className?: string
}

export const ArrowIcon = (props: ArrowIconProps) => {
  return (
    <div className={`${classes.icon} ${props.className ?? ''}`}>
      <FaArrowRightLong />
    </div>
  )
}
