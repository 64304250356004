import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import ContentGrid, { Column, Row } from '../../../components/ContentGrid'
import DashboardLayout, { Content, Header, Title, TitleRow } from '../../../components/DashboardLayout'
import { Button } from '../../../components/Popup'
import { colors, rem, scale, size, style } from '../../../core'
import * as selectors from '../../../selectors'
import { RootState } from '../../../store'
import { logout } from '../../../store/sessions'
import { load } from '../../../store/webhooks'
import { useInView } from 'react-intersection-observer'

const WebhookSummary = style().element()

const UserRow = Row([rem(2), size.auto, rem(20), rem(10), rem(10)])

const Total = style()
  .sans({
    size: scale.s,
    color: colors.gray,
    align: 'right',
  })
  .element()

const SCROLLABLE_TARGET = 'manage-webhooks-scroll-target'

const Component = () => {
  const dispatch = useDispatch()
  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: '200px',
  })

  const [orgId, totalWebhooks, hasMore, webhooks]: [
    ReturnType<typeof selectors.orgs.currentId>,
    RootState['webhooks']['total'],
    RootState['webhooks']['hasMore'],
    ReturnType<typeof selectors.webhooks.getSanitizedById>[],
  ] = useSelector((state: RootState) => {
    return [
      selectors.orgs.currentId(state),
      state.webhooks.total,
      state.webhooks.hasMore,
      state.webhooks.content.map(webhookId => selectors.webhooks.getSanitizedById(state, webhookId)),
    ]
  })

  useEffect(() => {
    if (orgId) {
      dispatch(load(orgId))
    }
  }, [])

  useEffect(() => {
    if (inView && hasMore) {
      onPageLoad()
    }
  }, [inView, hasMore])

  return (
    <DashboardLayout>
      <Header breadcrumbs={[{ label: 'Settings' }, { url: `/settings/webhooks`, label: 'Webhooks' }]}>
        <Button link="/settings/webhooks/create" icon="plus">
          New webhook
        </Button>
        <Button icon="sign-out" onClick={() => dispatch(logout())}>
          Sign out
        </Button>
      </Header>
      <Content id={SCROLLABLE_TARGET}>
        <TitleRow>
          <Title>Manage webhooks</Title>
          <Total>{totalWebhooks} webhooks</Total>
        </TitleRow>
        <ContentGrid>
          <UserRow>
            <Column head></Column>
            <Column head>Webhooks</Column>
            <Column head center>
              Action
            </Column>
            <Column head center>
              Resource
            </Column>
            <Column head></Column>
            {webhooks.map(row => (
              <>
                <Column></Column>
                <Column>
                  <Link to={`/settings/webhooks/${row?.fields.id}`}>
                    <WebhookSummary>{row?.fields.title}</WebhookSummary>
                  </Link>
                </Column>
                <Column center>{row?.fields.action}</Column>
                <Column center>{row?.fields.resource}</Column>
                <Column></Column>
              </>
            ))}
            {hasMore && <div ref={ref} />}
          </UserRow>
        </ContentGrid>
      </Content>
    </DashboardLayout>
  )

  function onPageLoad() {
    dispatch(load(orgId, webhooks.length))
  }
}

export default Component
Component.displayName = 'ManageWebhooks'
