import React from 'react'
import { Text } from '../../Text/Text'
import { ScoreCard } from './ScoreCard'

export const DomainScoreCard: React.FC<{
  name: string
  score: number
}> = props => {
  return (
    <ScoreCard
      accent
      style={{
        border: 'none',
        paddingBlock: '1.5rem',
      }}
    >
      <Text variant="body-interactive" style={{ whiteSpace: 'nowrap' }}>
        {props.name}
      </Text>
      <Text variant="body-interactive">{props.score.toFixed(0)}%</Text>
    </ScoreCard>
  )
}
