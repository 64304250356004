import { toHumanString } from 'human-readable-numbers'
import { RootState } from '../store'

export interface BigNumber {
  value: string
  label?: string
}

export interface PieChart {
  slices: { name: string; value: number }[]
}

export interface Visual {
  title: string
  bigNumber?: BigNumber
  pieChart?: PieChart
}

const TIME_PER_REFERENCE_HOURS = 1.42

export function all(state: RootState, isSelfAssessmentEnabled: boolean): Visual[] {
  return [
    references(state),
    candidates(state),
    referencesPerCandidate(state),
    referenceCompletionRate(state),
    candidateCompletionRate(state),
    verifiedReferences(state),
    timeSaved(state),
    totalRoles(state),
    candidatesPerRole(state),
    averageTimeToComplete(state),
    referenceRelationships(state, isSelfAssessmentEnabled),
    finalCandidateStatus(state),
  ]
}

// stats for reports

function references(state: RootState): Visual {
  const report = state.reports.report
  const value = toHumanString(report.total_references)
  return {
    title: 'References',
    bigNumber: { value },
  }
}

function candidates(state: RootState): Visual {
  const report = state.reports.report
  const value = toHumanString(report.total_candidates)
  return {
    title: 'Candidates',
    bigNumber: { value },
  }
}

function referencesPerCandidate(state: RootState): Visual {
  const report = state.reports.report
  const value = ratio(report.total_references, report.total_candidates)
  return {
    title: 'References per Candidate',
    bigNumber: { value: `${value}` },
  }
}

function referenceCompletionRate(state: RootState): Visual {
  const report = state.reports.report
  const value = pct(report.total_completed_references, report.total_references)
  return {
    title: 'Reference Completion Rate',
    bigNumber: { value: `${value}%` },
  }
}

function candidateCompletionRate(state: RootState): Visual {
  const report = state.reports.report
  const value = pct(report.total_completed_candidates, report.total_candidates)
  return {
    title: 'Candidate Completion Rate',
    bigNumber: { value: `${value}%` },
  }
}

function verifiedReferences(state: RootState): Visual {
  const report = state.reports.report
  const value = pct(report.total_verified_references, report.total_references)
  return {
    title: 'Verified References',
    bigNumber: { value: `${value}%` },
  }
}

function timeSaved(state: RootState): Visual {
  const report = state.reports.report
  const value = toHumanString(report.total_completed_references * TIME_PER_REFERENCE_HOURS)
  return {
    title: 'Time Saved by HiPeople',
    bigNumber: { value, label: value === 1 ? 'hour' : 'hours' },
  }
}

function totalRoles(state: RootState): Visual {
  const report = state.reports.report
  const value = toHumanString(report.total_roles)
  return {
    title: 'Total Jobs',
    bigNumber: { value },
  }
}

function candidatesPerRole(state: RootState): Visual {
  const report = state.reports.report
  const value = ratio(report.total_candidates, report.total_roles)
  return {
    title: 'Candidates per Job',
    bigNumber: { value: `${value}` },
  }
}

function averageTimeToComplete(state: RootState): Visual {
  const value = toHumanString(state.reports.report.average_time_candidate_complete_hours / 24)
  return {
    title: 'Average Time to Complete',
    bigNumber: { value: `${value}`, label: value === 1 ? 'day' : 'days' },
  }
}

function referenceRelationships(state: RootState, isSelfAssessmentEnabled: boolean): Visual {
  const report = state.reports.report
  const slices = [
    { value: report.total_references_peers, name: 'Peers' },
    { value: report.total_references_managers, name: 'Managers' },
    { value: report.total_references_reports, name: 'Direct Reports' },
    { value: report.total_references_student_peers, name: 'Student Peers' },
    { value: report.total_references_clients, name: 'Clients' },
    { value: report.total_references_anys, name: 'Any Relationships' },
  ]

  if (isSelfAssessmentEnabled) {
    slices.push({
      value: report.total_references_selfs,
      name: 'Self-Assessments',
    })
  }

  return {
    title: 'Reference Relationships',
    pieChart: { slices },
  }
}

function finalCandidateStatus(state: RootState): Visual {
  const report = state.reports.report
  const slices = [
    {
      value:
        report.total_completed_candidates -
        report.total_marked_as_completed_candidates -
        report.total_expired_candidates,
      name: 'Completed',
    },
    {
      value: report.total_marked_as_completed_candidates,
      name: 'Manually Completed',
    },
    { value: report.total_expired_candidates, name: 'Overdue' },
  ]
  return {
    title: 'Final Candidate Status',
    pieChart: { slices },
  }
}

function pct(a: number, b: number): number {
  if (a === 0 || b === 0) return 0

  return Math.min(Math.round((a / b) * 100), 100)
}

function ratio(a: number, b: number): number {
  if (a === 0 || b === 0) return 0

  return Math.round((a / b) * 100) / 100
}
