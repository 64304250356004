import React from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import { colors, fr, height, rem, size, space, style, weight } from '../../core'

export default style()
  .grid({ columns: [fr(1), fr(1)] })
  .spacing({ columns: space.m })
  .element()

export const RowIcon = style()
  .round('100%')
  .color({ fg: colors.moonGray })
  .cond(({ verified }: { verified: boolean }) => verified, style().color({ fg: colors.verified }))
  .cond(({ emoji }: { emoji: boolean }) => emoji, style().sans({ size: rem(1.2) }))
  .element()

export const Label = style()
  .cond(({ nooverflow }: { nooverflow: boolean }) => nooverflow, style().nooverflow().sans({ ellipsis: true }))
  .sans({ color: colors.black, height: height.s })
  .select(
    'strong',
    style().block().sans({ weight: weight.light, color: colors.silver }).spacing({ outerBottom: space.xxxs }),
  )
  .select('a', style().block().sans({ color: colors.black, nodecoration: true }))
  .element()

const RowLink = style()
  .select('a', style().text({ nodecoration: true }))
  .element()

const RowContainer = style()
  .grid({ columns: [rem(1.2), size.auto], align: 'center' })
  .spacing({ columns: space.xxs, inner: [space.xs, space.s] })
  .cond(({ emoji }: { emoji: boolean }) => emoji, style().grid({ columns: [rem(1.6), size.auto] }))
  .cond(
    ({ comment }: { comment: boolean }) => comment,
    style()
      .grid({ columns: [rem(2.5), size.auto], align: 'start' })
      .spacing({ columns: space.xs }),
  )
  .element()

interface RowProps {
  children: React.ReactNode
  link?: string
  emoji?: boolean
  comment?: boolean
}

export const Row = (props: RowProps) => {
  if (!props.link) {
    return (
      <RowContainer emoji={props.emoji} comment={props.comment}>
        {props.children}
      </RowContainer>
    )
  }
  return (
    <RowLink>
      <Link to={props.link}>
        <RowContainer emoji={props.emoji} comment={props.comment}>
          {props.children}
        </RowContainer>
      </Link>
    </RowLink>
  )
}
