import { cleanMd } from '@common/utils/markdown'
import { templateCustomQuestionResponseTypes } from 'api/templates'
import { QuestionResponseType } from 'api/questions'
import { QuestionTable } from 'App/ExperienceFeedback/QuestionList'
import { BadgeTheme, BADGE_THEMES } from 'components/Badge'
import { Column } from 'components/ContentGrid'
import { Icon } from 'components/Icons'
import { TooltipContent } from 'components/Tooltip'
import { px2rem, size, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import Markdown from 'markdown-to-jsx'
import React from 'react'
import { CONTENT_SLUGS } from 'store/new-role/content'

const Title = text.secondaryBodyInteractive().element('th')

const Row = style()
  .grid({
    columns: [px2rem(14), px2rem(28), size.auto, px2rem(48), px2rem(200), px2rem(14)],
  })
  .element('tr')

const QuestionNumber = text.bodyText().element('td')
const Copy = text.bodyEmphasis().element('td')

const TypeContainer = style()
  .relative()
  .select(
    `${TooltipContent.Style}`,
    style().absolute({ top: 0, left: '50%' }).invisible().set('transform', 'translateX(-50%) translateY(-100%)'),
  )
  .select(`:hover ${TooltipContent.Style}`, style().visible())
  .element('td')

const Type = style()
  .size({ height: px2rem(22), width: px2rem(22) })
  .sans({ size: px2rem(12) })
  .center()
  .border({ radius: px2rem(4) })
  .with((props: { bgColor: string }) => style().color({ bg: props.bgColor }))
  .with((props: { fgColor: string }) => style().color({ fg: props.fgColor }))
  .element()

const TooltipContentStyled = text
  .smallBody()
  .color({ fg: designSystemColors.backgroundNeutralPrimary })
  .block()
  .sans({ align: 'center', noWrap: true })
  .spacing({ inner: [px2rem(2), px2rem(4)] })
  .size({ width: size.auto })
  .element('p')

const ModuleContainer = style()
  .flex()
  .center()
  .spacing({ inner: px2rem(4), gap: px2rem(8) })
  .border({ radius: px2rem(6) })
  .color({
    bg: designSystemColors.backgroundNeutralTertiary,
    fg: designSystemColors.typographyPrimary,
  })
  .element('td')

const ModuleName = text.label().element('p')
const ModuleEmoji = text.label().element('p')

export function getCustomQuestionThemeCopy(questionType: QuestionResponseType): {
  theme: BadgeTheme
  icon: string
  ariaLabel: string
} {
  switch (questionType) {
    case 'text-input': {
      return {
        theme: 'blue',
        icon: 'font-case',
        ariaLabel: 'text question',
      }
    }
    case 'chips': {
      return {
        theme: 'orange',
        icon: 'tasks',
        ariaLabel: 'chip question',
      }
    }
    case 'optionset': {
      return {
        theme: 'green',
        icon: 'chevron-circle-down',
        ariaLabel: 'select question',
      }
    }
    case 'slider': {
      return {
        theme: 'purple',
        icon: 'signal-alt',
        ariaLabel: 'scale question (e.g. 1 - 7)',
      }
    }
    case 'video': {
      return {
        theme: 'green',
        icon: 'video',
        ariaLabel: 'video question',
      }
    }
    default:
      return {
        theme: 'red',
        icon: 'balance-scale',
        ariaLabel: 'rating question',
      }
  }
}

const ImageElem = style()
  .size({
    maxWidth: '100%',
  })
  .box({ display: 'block' })
  .spacing({ outerLeft: 'auto', outerRight: 'auto', outerTop: '1rem' })
  .element('img')

/*

  display: inline-block;
  margin: auto;
  margin-top: 1rem;
  white-space: break-spaces;
  font-weight: 400;
  font-size: 1rem;
  background: #efefef;
  padding: 1rem;
  width: 100%;
*
* */
const CodeElem = style()
  .size({
    maxWidth: '100%',
  })
  .color({
    bg: designSystemColors.backgroundNeutralSecondaryHover,
  })
  .box({ display: 'inline-block' })
  .text({
    weight: '400',
    size: '1rem',
    breakSpaces: true,
  })
  .spacing({ outer: 'auto', outerTop: '1rem', inner: '1rem' })
  .element('code')

const Image: React.FC<React.ImgHTMLAttributes<any>> = ({ children, ...rest }) => {
  return <ImageElem {...rest}>{children}</ImageElem>
}

const Code: React.FC<React.HTMLAttributes<any>> = ({ children, ...rest }) => {
  return <CodeElem {...rest}>{children}</CodeElem>
}

const MarkdownLink: React.FC<React.AnchorHTMLAttributes<any>> = ({ children, ...rest }) => {
  return (
    <a {...rest} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  )
}

export type Preview = {
  copy: string
  type: QuestionResponseType
  module?: {
    id: string
    slug: CONTENT_SLUGS | 'skills'
    name: string
    emoji: string
  }
}

interface PreviewTableProps {
  questions: Preview[]
}

const PreviewTable: React.FC<PreviewTableProps> = ({ questions }) => {
  return (
    <QuestionTable>
      <Row>
        <Column head></Column>
        <Column head>
          <Title>#</Title>
        </Column>
        <Column head>
          <Title>Question</Title>
        </Column>
        <Column head>
          <Title>Type</Title>
        </Column>
        <Column head>
          <Title>Module</Title>
        </Column>
        <Column head></Column>
      </Row>
      {questions.map((question, i) => {
        const { theme, icon, ariaLabel } = getCustomQuestionThemeCopy(question.type)
        const { bg, fg } = BADGE_THEMES[theme] || { bg: '', fg: '' }
        return (
          <Row key={`${question.copy}-${i}`}>
            <Column />
            <Column centerVert>
              <QuestionNumber>{`${i + 1}.`}</QuestionNumber>
            </Column>
            <Column centerVert>
              <Copy>
                <Markdown
                  options={{
                    wrapper: 'p',
                    forceWrapper: true,
                    overrides: {
                      a: MarkdownLink,
                      img: Image,
                      code: Code,
                    },
                  }}
                >
                  {cleanMd(question.copy || '')}
                </Markdown>
              </Copy>
            </Column>
            <Column centerVert>
              <TypeContainer>
                <Type bgColor={bg} fgColor={fg}>
                  <Icon name={icon} ariaLabel={ariaLabel}></Icon>
                </Type>
                <TooltipContent arrowPosition="bottom">
                  <TooltipContentStyled>{ariaLabel}</TooltipContentStyled>
                </TooltipContent>
              </TypeContainer>
            </Column>
            <Column centerVert>
              {(() => {
                if (question.module)
                  return (
                    <ModuleContainer>
                      <ModuleEmoji>{question.module.emoji || '🛠'}</ModuleEmoji>
                      <ModuleName>{question.module.name || ''}</ModuleName>
                    </ModuleContainer>
                  )
                if (templateCustomQuestionResponseTypes[question.type])
                  return (
                    <ModuleContainer>
                      <ModuleName>Custom question</ModuleName>
                    </ModuleContainer>
                  )
              })()}
            </Column>
            <Column />
          </Row>
        )
      })}
    </QuestionTable>
  )
}

export default PreviewTable
