import { SubText } from 'App/ProductPages/Shared'
import ContentContainer from 'components/Container/ContentContainer'
import HeroGrid from 'components/ProductPageLayout/HeroGrid'
import React from 'react'
import QualityOfHire from './QualityOfHire.png'

interface Props {
  button: React.ReactNode
}

export const EmptyQualityOfHirePageUI: React.FC<Props> = props => {
  return (
    <ContentContainer>
      <HeroGrid
        heading={'Measure, understand, and improve your Quality of Hire'}
        subheading={
          <SubText>
            HiPeople automatically measures and analyzes your Quality of Hire. Discover hidden insights into your
            recruiting performance effortlessly! <br />
            <br />
            ✔ 10x your recruitment output <br />
            ✔ Lower your Cost of Hire <br />✔ Hire the best candidates, every time
          </SubText>
        }
        image={{
          src: QualityOfHire,
          altText: 'User interface of HiPeople dashboard showing Quality of Hire preview',
        }}
      >
        {props.button}
      </HeroGrid>
    </ContentContainer>
  )
}
