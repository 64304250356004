import React from 'react'
import { BsLightningChargeFill } from 'react-icons/bs'
import { Button } from '../Button/Button'
import { Modal } from '../Modal/Modal'
import { Text } from '../Text/Text'
import { Tooltip } from '../Tooltip/Tooltip'
import classes from './Locked.module.scss'

export const LockIcon = (props: { visible?: boolean; style?: React.CSSProperties }) => {
  if (props.visible !== undefined && !props.visible) {
    return null
  }

  return (
    <div className={classes.lock} style={props.style}>
      <BsLightningChargeFill className={classes.lockIcon} />
    </div>
  )
}

export type LockedByPlanProps = {
  locked: boolean
  modalData?: {
    title?: string
    learnMoreAbout?: string
    learnMoreLink?: string
    content?: React.ReactNode
    children?: React.ReactNode
  }
  upgradeTo?: string
  children: React.ReactNode
  lockIconStyle?: React.CSSProperties
  lockIconDisabled?: boolean
  tracking: {
    modalOpened: (feature: string) => void
    modalClosed: () => void
    learnMoreClicked: (feature: string) => void
    upgradeClicked: (feature: string) => void
  }
}

export const LockedByPlan = (props: LockedByPlanProps) => {
  const [open, setOpen] = React.useState(false)

  function openModal(e: React.MouseEvent) {
    props.tracking.modalOpened(props.modalData?.learnMoreAbout || '')

    e.stopPropagation()
    setOpen(true)
  }

  if (!props.locked) {
    return <>{props.children}</>
  }

  return (
    <>
      <div className={`${classes.main}`} onClick={openModal}>
        <Tooltip text={`Upgrade to the '${props.upgradeTo}' plan to use this feature.`} style={{ display: 'block' }}>
          {/* inject the LockIcon into the first child element */}
          {!props.lockIconDisabled
            ? React.Children.map(props.children, child => {
                if (React.isValidElement(child)) {
                  return React.cloneElement(
                    child,
                    {},
                    child.props.children,
                    props.locked && <LockIcon style={props.lockIconStyle} />,
                  )
                }
                return child
              })
            : props.children}
          <div onClick={openModal} className={classes.lockedClick} />
        </Tooltip>
      </div>
      <LockedModal {...props} open={open} setOpen={setOpen} />
    </>
  )
}

export const LockedModal = (
  props: Partial<LockedByPlanProps> & {
    open: boolean
    setOpen: (open: boolean) => void
    closeButtonText?: string
  },
) => {
  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.setOpen(false)
        props.tracking?.modalClosed()
      }}
      title={props.modalData?.title || 'You have found a premium feature!'}
      data-testid="premium-feature-modal"
    >
      {props.modalData?.children || (
        <>
          <div className={classes.modalContent}>
            {props.modalData?.content || (
              <Text variant="body-text">
                Upgrade to the '{props.upgradeTo || 'Grow'}' plan to use this feature. You can learn more about{' '}
                <strong>{props.modalData?.learnMoreAbout}</strong>
                <a
                  className={classes.learnMoreLink}
                  href={props.modalData?.learnMoreLink}
                  target={'_blank'}
                  rel={'noopener noreferrer'}
                  onClick={() => props.tracking?.learnMoreClicked(props.modalData?.learnMoreAbout || '')}
                >
                  {' '}
                  here.
                </a>
              </Text>
            )}
          </div>
          <div className={classes.modalButtons}>
            <Button
              variant={'secondary'}
              onClick={() => {
                props.tracking?.modalClosed()
                props.setOpen(false)
              }}
            >
              {props.closeButtonText || 'Close'}
            </Button>
            <a
              rel="noopener noreferrer"
              href={'/upgrade'}
              onClick={() => props.tracking?.upgradeClicked(props.modalData?.learnMoreAbout || '')}
            >
              <Button variant={'purple-dark'}>Upgrade</Button>
            </a>
          </div>
        </>
      )}
    </Modal>
  )
}
