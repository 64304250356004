import React from 'react'
import { LetterIconGradient } from '../LetterIconGradient/LetterIconGradient'
import styles from './JobRow.module.scss'

import { ArrowIcon } from '../../Home/ArrowIcon/ArrowIcon'
import { Skeleton } from '../../Skeleton/Skeleton'
import InviteIcon from './InviteIcon.svg?react'

type Props = {
  id: string
  name: string
  refCheck: boolean
  assessment: boolean
  toReview: number
  active: boolean
  onClick?: () => void
  loading?: boolean
}

export const JobRow: React.FC<Props> = props => {
  if (props.loading) {
    return <JobRowLoading />
  }
  return (
    <div onClick={props.onClick} className={styles.jobRow}>
      <LetterIconGradient id={props.id} name={props.name} />

      <div className={styles.jobName}>{props.name}</div>
      <div className={styles.tags}>
        {props.assessment && <div className={styles.tag}>Assessment</div>}
        {props.refCheck && <div className={styles.tag}>Reference Check</div>}
      </div>
      <div className={styles.tags}>
        {props.toReview ? (
          <span className={styles.toReviewTag}>{props.toReview} to review</span>
        ) : (
          <span className={styles.inviteCandidates}>
            <InviteIcon /> Invite candidates
          </span>
        )}
      </div>

      <div>
        <span className={`${styles.jobActive} ${props.active ? styles.active : ''}`}>
          {props.active ? 'Active' : 'Paused'}
        </span>
      </div>
      <ArrowIcon />
    </div>
  )
}

export const JobRowLoading = () => {
  return (
    <div className={styles.loading}>
      <Skeleton width={50} height={50} className={styles.profileIcon} />

      <div className={styles.jobName}>
        <Skeleton width={100} />
      </div>
      <div className={styles.tags}>
        <Skeleton width={100} />
        <Skeleton width={120} />
      </div>
      <div className={styles.tags}>
        <Skeleton width={120} />
      </div>

      <div>
        <Skeleton width={60} />
      </div>
      <ArrowIcon className={styles.icon} />
    </div>
  )
}
