import {
  IncompleteStatus,
  NegativeStatus,
  PositiveStatus,
  UnselectedStatus,
} from 'App/Candidate/Summary/Overview/Status'
import React from 'react'
import classes from './Block.module.scss'

type BlockStatus = 'positive' | 'negative' | 'unselected' | 'incomplete'

export interface BlockProps {
  title: string
  emoji: string
  status: BlockStatus
  description: string
  modalTitle: string
  modalInfo: string
  result: JSX.Element
}

export const Block: React.FC<BlockProps> = props => {
  return (
    <div className={classes.container}>
      <div>
        <div className={classes.emoji}>{props.emoji}</div>
      </div>
      <div className={classes.info}>
        <div className={classes.titleContainer}>
          <p className={classes.bodyEmphasis}>{props.title}</p>
          <span>
            {
              {
                positive: <PositiveStatus />,
                negative: <NegativeStatus />,
                unselected: <UnselectedStatus />,
                incomplete: <IncompleteStatus />,
              }[props.status]
            }
          </span>
        </div>
        <div className={classes.description}>{props.description}</div>
      </div>
      <div>{props.result}</div>
    </div>
  )
}
