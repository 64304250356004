import TabWizard, { TabItem } from 'App/NewRole/TabWizard'
import Modal from 'components/Modal'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as selectors from 'selectors'
import { RootState } from 'store'
import * as slice from 'store/integrations'
import { useATS } from 'store/integrations/ats'
import AlreadyConnected from './AlreadyConnected'
import NoIntegration from './NoIntegration'
import PipelineTab from './PipelineTab'
import ReviewTab from './ReviewTab'
import RoleTab from './RoleTab'

const ROLE_TAB = 'job'
const PIPELINE_TAB = 'pipeline'
const REVIEW_TAB = 'review'

export const INTEGRATION_HELP_LINK =
  'https://intercom.help/hipeople/en/articles/7826759-integrating-a-role-with-your-chosen-ats'

const tabItems: TabItem[] = [
  { tab: ROLE_TAB, desc: 'ATS job', component: RoleTab },
  { tab: PIPELINE_TAB, desc: 'Pipeline stage', component: PipelineTab },
  { tab: REVIEW_TAB, desc: 'Review', component: ReviewTab },
]

interface Props {
  open: boolean
  roleId: string
  setOpen: (b: boolean) => void
}

const Component = (props: Props) => {
  return (
    <Modal title="Manage ATS automation" open={props.open} setOpen={props.setOpen} helpLink={INTEGRATION_HELP_LINK}>
      <MultiModal {...props} />
    </Modal>
  )
}

export default Component
Component.displayName = 'ATSAutomationModal'

const MultiModal = (props: Props) => {
  const dispatch = useDispatch()

  const org = useSelector((state: RootState) => selectors.orgs.getById(state, selectors.orgs.currentId(state)))
  const integration = useSelector((state: RootState) => selectors.integrations.getByOrgId(state, org.id))
  const connection = useSelector((state: RootState) =>
    selectors.integrationConnections.getByRoleId(state, props.roleId),
  )

  useEffect(() => {
    if (props.open) dispatch(slice.reset())
  }, [dispatch, props.open])

  const combinedATSMap = useATS(org?.id)

  const closeModal = useCallback(() => props.setOpen(false), [props.setOpen])

  if (!integration) {
    return <NoIntegration setOpen={props.setOpen} />
  }

  if (connection?.length) {
    return <AlreadyConnected roleId={props.roleId} setOpen={props.setOpen} atsMap={combinedATSMap} />
  }

  return (
    <TabWizard
      tabItems={tabItems}
      atsMap={combinedATSMap}
      closeModal={closeModal}
      helpLinks={{}}
      setHelpLink={() => {}}
    />
  )
}
