import * as api from 'api'
import { EmptyResponses } from 'App/Candidate/SelfAssessment/EmptyResponses'
import { getResponses } from 'App/Candidate/SelfAssessment/get_responses'
import { debounce } from 'lodash-es'
import { useCandidateProfile } from 'providers/candidates'
import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import * as orgsSelectors from 'selectors/orgs'
import { findSelfAssessmentByCandidateId } from 'selectors/questionnaire-submissions'
import { AssessmentAnswerQuestion, AssessmentModuleAnswers, findSelfAssessmentAnswers } from 'selectors/self-assessment'
import { RootState } from 'store'
import * as selectors from '../../../../selectors'
import SectionBlockNavigator from '../AssessmentSectionBlockNavigator'
import { SelfAssessmentQuestionBlock } from '../SelfAssessmentQuestionBlock'
import classes from './Index.module.scss'

export interface Response {
  score: number
  moduleSlug: string
  moduleName: string
  title: string
  maxScore: number
  moduleEmoji: string
  hasBeenSubmitted: boolean
  answer: AssessmentAnswerQuestion
  questionnaireSubmissionId?: string
}

const ModuleSection: React.FC<{
  id: string
  setVisible: (inView: boolean) => void
  children?: React.ReactNode
}> = props => {
  const { ref, inView } = useInView({ threshold: 0.15 })

  useEffect(() => {
    props.setVisible(inView)
  }, [inView])

  return (
    <div style={{ marginBottom: '1rem' }} id={props.id} ref={ref}>
      {props.children}
    </div>
  )
}

interface ResponsesProps {
  candidateId: string
  role?: api.request.Entity<api.openjobroles.Fields>
}

export const Responses: React.FC<ResponsesProps> = props => {
  const location = useLocation()
  const activeId = location.hash.substring(1) // strip the # in id

  const [visible, setVisible] = useState<{ [id: string]: boolean }>({})

  const candidateProfile = useCandidateProfile({
    candidateId: props.candidateId,
  })

  const answersByModuleId: AssessmentModuleAnswers[] =
    useSelector((state: RootState) => findSelfAssessmentAnswers(state, props.candidateId)) || []

  const orgId = useSelector((state: RootState) => orgsSelectors.currentId(state))

  const org = useSelector((state: RootState) => selectors.orgs.getById(state, orgId))

  const variables: Record<string, string> = {
    'ORGANIZATION.NAME': org.fields.name,
    'ROLE.NAME': props.role?.fields.public_name || props.role?.fields.name || '',
  }

  const submission = useSelector((state: RootState) => findSelfAssessmentByCandidateId(state, props.candidateId))

  const moduleItems: { emoji: string; id: string; title: string }[] = answersByModuleId.map(({ module }) => {
    return {
      title: module?.copy.title || module?.name || 'Custom Questions',
      id: module?.slug || 'custom-questions',
      emoji: module?.copy.emoji || '🛠',
    }
  })

  const candidateName = candidateProfile?.fields.full_name || ''

  const responsesByModuleSlug = getResponses(answersByModuleId, variables, submission)

  return org.fields.plan === 'normal' ? (
    <div className={classes.container}>
      <aside>
        <div className={classes.sidebar}>
          <SectionBlockNavigator visible={visible} items={moduleItems} activeId={activeId} />
        </div>
      </aside>
      <main className={classes.answers}>
        <div className={classes.questionBlockList}>
          {Object.keys(responsesByModuleSlug).map(moduleSlug => {
            return (
              <ModuleSection
                key={moduleSlug}
                id={moduleSlug}
                setVisible={(inView: boolean) =>
                  debounce(() => {
                    setVisible(visibleSlugs => ({
                      ...visibleSlugs,
                      [moduleSlug]: inView,
                    }))
                  }, 100)()
                }
              >
                {responsesByModuleSlug[moduleSlug].map(response => {
                  return (
                    <SelfAssessmentQuestionBlock
                      key={response.answer.question.id}
                      response={response}
                      questionnaireSubmissionId={response.questionnaireSubmissionId || ''}
                      candidateName={candidateName}
                    />
                  )
                })}
              </ModuleSection>
            )
          })}
        </div>
      </main>
    </div>
  ) : (
    <EmptyResponses />
  )
}
