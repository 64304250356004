import { breakpoints, fr, space, style } from '../../core'

interface Props {
  children: JSX.Element[]
  reverseOrderOnMobile?: boolean
}

export default style()
  .spacing({ inner: [space.xs, space.m], columns: space.xs })
  .with((props: Props) => {
    const result = style().grid({
      columns: props.children.map(() => fr(1)),
    })

    if (!props.reverseOrderOnMobile) {
      return result
    }

    const mobileStyle = style()

    let i = props.children.length
    while (i--) {
      mobileStyle.selectChild(i + 1, style().order(props.children.length - i))
    }

    return result.screen(breakpoints.mobile, mobileStyle)
  })
  .screen(
    breakpoints.mobile,
    style()
      .grid({
        columns: fr(1),
      })
      .spacing({
        inner: [space.xs, space.l, space.xs, space.s],
        rows: space.xxs,
      })
      .with((props: Props) => {
        const result = style()

        if (!props.reverseOrderOnMobile) {
          return result
        }

        let i = props.children.length
        while (i--) {
          result.selectChild(i + 1, style().order(props.children.length - i))
        }

        return result
      }),
  )
  .element()
