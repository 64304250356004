import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EvaluationStatus } from 'api/candidates'
import { candidates, itemScores } from '../../api'
import { AppThunk } from '../../core/store'
import { add as notify } from '../notifications'
import { addEntities } from '../resources'

interface State {
  loading: boolean
  fetching: { [key: string]: boolean }
  fetchedAt: { [key: string]: number }
  inviteLink: string
  managementLink: string
  itemScores: { [candidateId: string]: itemScores.Fields[] }
}

const name = candidates.RESOURCE
const initialState: State = {
  loading: false,
  fetching: {},
  fetchedAt: {},
  inviteLink: '',
  managementLink: '',
  itemScores: {},
}

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setIsFetching(state, action: PayloadAction<{ id: string; fetching: boolean }>) {
      const { id, fetching } = action.payload
      state.fetching[id] = fetching

      if (fetching === false) {
        state.fetchedAt[id] = Date.now()
      }
    },
    setInviteLink(state, action: PayloadAction<string>) {
      state.inviteLink = action.payload
    },
    setManagementLink(state, action: PayloadAction<string>) {
      state.managementLink = action.payload
    },
    setItemScores(state, action: PayloadAction<{ id: string; scores: itemScores.Fields[] }>) {
      const { id, scores } = action.payload
      state.itemScores[id] = scores
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
  },
})

export const { setIsFetching, setInviteLink, setManagementLink, setItemScores, setLoading } = slice.actions

export default slice.reducer

export const fetch =
  (id: string): AppThunk =>
  async (dispatch, getStore) => {
    // Prevent over fetching
    const store: { [candidates.RESOURCE]: State } = getStore()
    const isAlreadyFetching = store.candidates.fetching[id]
    if (isAlreadyFetching) {
      return
    }

    dispatch(setIsFetching({ id, fetching: true }))

    const [response, errors] = await candidates.fetch(id)
    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    dispatch(setIsFetching({ id, fetching: false }))

    if (response) {
      dispatch(addEntities(response))
    }
  }

export const fetchItemScores =
  (id: string): AppThunk =>
  async dispatch => {
    dispatch(setIsFetching({ id, fetching: true }))
    const [response, errors] = await itemScores.getByCandidateId(id)
    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    dispatch(setIsFetching({ id, fetching: false }))

    if (response?.meta) {
      dispatch(setItemScores({ id, scores: response.meta }))
    }
  }
export const remind =
  (id: string): AppThunk =>
  async dispatch => {
    const [response, errors] = await candidates.remind(id)

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    if (response) {
      dispatch(addEntities(response))
      dispatch(
        notify({
          success: 'The selected candidate has been sent a reminder.',
        }),
      )
    }
  }

export const remove =
  (...ids: string[]): AppThunk =>
  async dispatch => {
    const [response, errors] = await candidates.remove(...ids)

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    if (response) {
      dispatch(addEntities(response))
      dispatch(
        notify({
          success: 'The selected candidate(s) have been archived.',
        }),
      )
    }
  }

export const unremove =
  (id: string): AppThunk =>
  async dispatch => {
    const [response, errors] = await candidates.unremove(id)

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    if (response) {
      dispatch(addEntities(response))
      dispatch(
        notify({
          success: 'The selected candidate has been unarchived.',
        }),
      )
    }
  }

export const complete =
  (id: string): AppThunk =>
  async dispatch => {
    const [response, errors] = await candidates.complete(id)

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    if (response) {
      dispatch(addEntities(response))
      dispatch(
        notify({
          success: 'The selected candidate has been marked as completed.',
        }),
      )
    }
  }

export const updateEmail =
  (id: string, email: string): AppThunk =>
  async dispatch => {
    const [response, errors] = await candidates.updateEmail(id, email)

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    if (response) {
      dispatch(addEntities(response))
      dispatch(
        notify({
          success:
            "You've updated the email for this candidate successfully and their invitation will be resent to the updated address! 🎉",
        }),
      )
    }
  }

export const updateCheckr =
  (id: string, checkrCandidateId, checkrInvitationId: string): AppThunk =>
  async dispatch => {
    const [response, errors] = await candidates.updateCheckr(id, checkrCandidateId, checkrInvitationId)

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return
    }

    if (response) {
      dispatch(addEntities(response))
    }
  }

export const updateProfessionalNetwork =
  (id: string, professionalNetwork: string): AppThunk =>
  async dispatch => {
    dispatch(setLoading(true))
    const [response, errors] = await candidates.updateProfessionalNetwork(id, professionalNetwork)
    dispatch(setLoading(false))

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(addEntities(response))
    }
  }

export const inviteLink =
  (id: string): AppThunk =>
  async dispatch => {
    const [response, errors] = await candidates.inviteLink(id)

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(setInviteLink(response.result.fields.link))
    }
  }

export const managementLink =
  (id: string): AppThunk =>
  async dispatch => {
    const [response, errors] = await candidates.managementLink(id)

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(setManagementLink(response.result.fields.link))
    }
  }

export const share =
  (id: string, email: string): AppThunk =>
  async dispatch => {
    const [response, errors] = await candidates.share(id, email)

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(addEntities(response))
    }
  }

export const unshare =
  (id: string, email: string): AppThunk =>
  async dispatch => {
    const [response, errors] = await candidates.unshare(id, email)

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(addEntities(response))
    }
  }

export const updateHireStatus =
  (id: string, isHired: boolean, isRejected: boolean): AppThunk =>
  async dispatch => {
    const [response, errors] = await candidates.updateHiring(id, isHired, isRejected)

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(addEntities(response))
      dispatch(notify({ success: 'The candidates hiring status has been updated!' }))
    }
  }

export const updateEvaluationStatus =
  (id: string, status: EvaluationStatus): AppThunk =>
  async dispatch => {
    const [response, errors] = await candidates.updateEvaluationStatus(id, status)

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(addEntities(response))
      dispatch(
        notify({
          success: 'The candidates evaluation status has been updated!',
        }),
      )
    }
  }

export const sendSelfAssessment =
  (id: string): AppThunk =>
  async dispatch => {
    const [response, errors] = await candidates.inviteSelfAssessment(id)

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(addEntities(response))
      dispatch(
        notify({
          success: 'This candidate has been invited to complete an assessment!',
        }),
      )
    }
  }

export const sendReferenceCheck =
  (id: string): AppThunk =>
  async dispatch => {
    const [response, errors] = await candidates.inviteReferenceCheck(id)

    if (errors) {
      errors.forEach(e => {
        dispatch(notify({ error: e.message }))
      })
      return false
    }

    if (response) {
      dispatch(addEntities(response))
      dispatch(
        notify({
          success: 'This candidate has been invited to complete a reference check!',
        }),
      )
    }
  }
