import { px2rem, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import React from 'react'

const Container = style()
  .color({
    bg: designSystemColors.typographyPrimary,
    fg: designSystemColors.backgroundNeutralPrimary,
  })
  .spacing({ inner: [px2rem(6), px2rem(4)] })
  .border({ radius: px2rem(4) })
  .element()

const LeftArrow = style()
  .size({ width: px2rem(0), height: px2rem(0) })
  .inlineBlock()
  .set('borderStyle', 'solid')
  .set('borderWidth', [7, 6, 7, 0].map(px2rem).join(' '))
  .set(
    'borderColor',
    [
      designSystemColors.transparent,
      designSystemColors.typographyPrimary,
      designSystemColors.transparent,
      designSystemColors.transparent,
    ].join(' '),
  )
  .element()
const RightArrow = style()
  .size({ width: px2rem(0), height: px2rem(0) })
  .inlineBlock()
  .set('borderStyle', 'solid')
  .set('borderWidth', [7, 0, 7, 6].map(px2rem).join(' '))
  .set(
    'borderColor',
    [
      designSystemColors.transparent,
      designSystemColors.transparent,
      designSystemColors.transparent,
      designSystemColors.typographyPrimary,
    ].join(' '),
  )
  .element()
const TopArrow = style()
  .size({ width: px2rem(0), height: px2rem(0) })
  .inlineBlock()
  .set('borderStyle', 'solid')
  .set('borderWidth', [0, 7, 6, 7].map(px2rem).join(' '))
  .set(
    'borderColor',
    [
      designSystemColors.transparent,
      designSystemColors.transparent,
      designSystemColors.typographyPrimary,
      designSystemColors.transparent,
    ].join(' '),
  )
  .element()
const BottomArrow = style()
  .size({ width: px2rem(0), height: px2rem(0) })
  .inlineBlock()
  .set('borderStyle', 'solid')
  .set('borderWidth', [6, 7, 0, 7].map(px2rem).join(' '))
  .set(
    'borderColor',
    [
      designSystemColors.typographyPrimary,
      designSystemColors.transparent,
      designSystemColors.transparent,
      designSystemColors.transparent,
    ].join(' '),
  )
  .element()

const HorizontalStack = style().flex({ alignItems: 'center', justifyContent: 'center' }).element()

export const TooltipStyle = style()
  .flex({ direction: 'column', alignItems: 'center', justifyContent: 'center' })
  .element()

export type ArrowPosition = 'left' | 'right' | 'bottom' | 'top'

interface TooltipProps {
  arrowPosition: ArrowPosition
  children: React.ReactNode
}

export const TooltipContent: React.FC<TooltipProps> & {
  Style: typeof TooltipStyle
} = ({ arrowPosition, children }) => {
  return (
    <TooltipStyle>
      {arrowPosition === 'top' && <TopArrow />}
      <HorizontalStack>
        {arrowPosition === 'left' && <LeftArrow />}
        <Container>{children}</Container>
        {arrowPosition === 'right' && <RightArrow />}
      </HorizontalStack>
      {arrowPosition === 'bottom' && <BottomArrow />}
    </TooltipStyle>
  )
}

TooltipContent.Style = TooltipStyle
