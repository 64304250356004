import { Button, LoadingText } from '@common/components'
import { Button as ButtonV2, ButtonContent } from 'components/Button/ButtonV2'
import Modal from 'components/Modal'
import TextField from 'components/TextField'
import { fr, px2rem, size, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React from 'react'

const Title = text.cardHeader().element('h3')

const ModalHeader = style()
  .grid({ columns: [fr(1), size.auto, size.auto], align: 'center' })
  .spacing({ gap: px2rem(8), inner: [px2rem(18), px2rem(32)] })
  .border({
    bottom: `solid ${px2rem(1)}`,
    color: designSystemColors.borderDefault,
  })
  .element()

const Warning = text
  .bodyText()
  .color({ bg: designSystemColors.uiStatusWarningBackground })
  .spacing({ inner: [px2rem(12), px2rem(16)], outerBottom: px2rem(16) })
  .border({ radius: px2rem(8) })
  .element('p')

const PreviewTitle = text
  .bodyEmphasis()
  .spacing({ outerTop: px2rem(24), outerBottom: px2rem(4) })
  .element('h4')
const PreviewDescription = text
  .bodyText()
  .spacing({ outerBottom: px2rem(12) })
  .element('p')

const PreviewContent = style()
  .color({ bg: designSystemColors.backgroundNeutralSecondary })
  .border({
    around: `solid ${px2rem(1)} ${designSystemColors.borderDefault}`,
    radius: px2rem(8),
  })
  .spacing({ inner: px2rem(24) })
  .element()
const PreviewQuestionTitle = style()
  .sans({ size: px2rem(16), line: px2rem(21), weight: '600' })
  .nospacing()
  .element('h5')
const PreviewQuestionDescription = style()
  .sans({ size: px2rem(14.4), line: px2rem(18), weight: '400', italic: true })
  .nospacing()
  .spacing({ outerTop: px2rem(16) })
  .element('p')
const SkillSpan = style(PreviewQuestionTitle)
  .spacing({ inner: [px2rem(2), px2rem(4)] })
  .border({ radius: px2rem(6) })
  .color({ bg: designSystemColors.backgroundNeutralTertiary })
  .element('span')
const SkillSpanEmpty = style(SkillSpan)
  .color({ fg: designSystemColors.typographySecondary })
  .sans({ italic: true, weight: '500' })
  .element('span')

const Chips = style()
  .flex({ wrap: 'wrap' })
  .spacing({ gap: px2rem(8), outerBottom: px2rem(24), outerTop: px2rem(16) })
  .element()
const Chip = text
  .bodyText()
  .border({
    around: `solid ${px2rem(1)} ${designSystemColors.borderDefault}`,
    radius: px2rem(30),
  })
  .spacing({ inner: [px2rem(8), px2rem(14)] })
  .inlineBlock()
  .element('span')

const Content = style()
  .spacing({ inner: [px2rem(24), px2rem(64)] })
  .scroll({ y: 'auto' })
  .size({
    maxHeight: px2rem(576),
    maxWidth: px2rem(768),
  })
  .element()

const Buttons = style()
  .flex({ justifyContent: 'flex-end' })
  .spacing({ inner: [px2rem(18), px2rem(32)], gap: px2rem(16) })
  .border({
    top: `solid 1px`,
    color: designSystemColors.borderDefault,
  })
  .select('> *', style().size({ minWidth: px2rem(140) }))
  .element()

const CustomSkillModalStyle = style()
  .size({ minWidth: px2rem(600) })
  .element()

type CustomQuestionValues = {
  text: string
}

type OnCustomQuestionCreate = (values: CustomQuestionValues) => Promise<any>

interface CustomSkillModalProps {
  close: () => any
  onSubmit: OnCustomQuestionCreate
}

export const CustomSkillModal: React.FC<CustomSkillModalProps> = ({ close, onSubmit }) => {
  const [text, setText] = React.useState('')
  const [isFocused, setIsFocused] = React.useState(false)
  const [isCreating, setIsCreating] = React.useState(false)

  const onSubmitCallback: OnCustomQuestionCreate = async values => {
    setIsCreating(true)
    try {
      await onSubmit(values)
      close()
    } catch {
      // DO NOTHING
    }
    setIsCreating(false)
  }

  const isConfirmButtonDisabled = text.trim().length < 3

  return (
    <Modal
      open={true}
      setOpen={close}
      helpLink={'https://intercom.help/hipeople/en/articles/7123673-create-your-first-reference-feedback-request'}
      renderHeader={({ closeButton }) => (
        <ModalHeader>
          <Title>{'Create custom skill question'}</Title>
          <ButtonV2
            buttonType="tertiary"
            size="small"
            onClick={() => {}}
            as="a"
            target="_blank"
            href="https://intercom.help/hipeople/en/articles/7123673-create-your-first-reference-feedback-request"
          >
            <ButtonContent icon={{ name: 'life-ring', ariaLabel: 'Help' }}>{'Help'}</ButtonContent>
          </ButtonV2>
          {closeButton}
        </ModalHeader>
      )}
    >
      <CustomSkillModalStyle>
        <Content>
          <Warning>
            {
              '⚠️️ Please note: new skills added to the library won’t have any data available with which to benchmark candidates.'
            }
          </Warning>
          <TextField
            disabled={isCreating}
            label="Question"
            placeholder={'e.g: Run user tests'}
            value={text}
            onChange={ev => {
              setText(ev.target.value)
            }}
            onFocus={() => {
              setIsFocused(true)
            }}
            onBlur={() => {
              setIsFocused(false)
            }}
          />
          <PreviewTitle>{'Preview'}</PreviewTitle>
          <PreviewDescription>{'This is how your question will look like to the references:'}</PreviewDescription>
          <PreviewContent>
            <PreviewQuestionTitle>
              {'“When you last worked with Alice, how skilled was Alice in the following activity: '}
              {isFocused || text ? (
                <SkillSpan>{text}</SkillSpan>
              ) : (
                <SkillSpanEmpty>{'skill name goes here'}</SkillSpanEmpty>
              )}
              {'”'}
            </PreviewQuestionTitle>
            <Chips>
              <Chip>{'Not applicable'}</Chip>
              <Chip>{'Room for improvement'}</Chip>
              <Chip>{'Good'}</Chip>
              <Chip>{'Excellent'}</Chip>
              <Chip>{'Outstanding'}</Chip>
            </Chips>
            <PreviewQuestionTitle>
              {'“Can you give an example of a situation where Alice showed this skill?”'}
            </PreviewQuestionTitle>
            <PreviewQuestionDescription>
              {'Reference will be asked to provide a text answer.'}
            </PreviewQuestionDescription>
          </PreviewContent>
        </Content>
        <Buttons>
          <Button variant="tertiary" onClick={close} disabled={isCreating}>
            {'Cancel'}
          </Button>
          <Button
            variant="purple-dark"
            onClick={() => {
              onSubmitCallback({ text })
            }}
            disabled={isConfirmButtonDisabled}
          >
            {!isCreating ? 'Create new skill' : <LoadingText text="Loading" />}
          </Button>
        </Buttons>
      </CustomSkillModalStyle>
    </Modal>
  )
}
