import { UpgradeButton } from 'App/ProductPages/Shared'
import Button from 'components/Button/ButtonV2'
import * as tracking from 'core/track'
import { copyToClipboard } from 'core/utils'
import { useUser } from 'providers/users'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDebounce } from 'react-use'
import * as selectors from 'selectors'
import { RootState } from 'store'
import { add as notify } from 'store/notifications'
import { load } from 'store/sales-pool'
import { logout } from 'store/sessions'
import { EmptySalesPoolPage } from './EmptyPageUI'
import { SalesPoolPageUI } from './PageUI'

export type ReferenceData = ReturnType<typeof getReferences>

function getReferences(state: RootState) {
  return state.salesPool.references.map(id => {
    const reference = selectors.references.findById(state, id)
    if (!reference) return {}
    return {
      reference,
      referenceProfessionalNetwork: selectors.references.getProfessionalNetworkForDisplay(state, reference),
      candidate: selectors.candidates.findById(state, reference.fields.candidate_id),
      candidateProfile: selectors.candidateProfiles.findByCandidateId(state, reference.fields.candidate_id),
      role: selectors.roles.findByCandidateId(state, reference.fields.candidate_id),
      verificationStatus: selectors.references.getVerificationStatus(state, id),
      relationship: selectors.references.relationshipOf(reference),
      organization: selectors.orgs.findByCandidateId(state, reference.fields.candidate_id),
    }
  })
}

export const SalesPoolPage = () => {
  const dispatch = useDispatch()

  const totalReferences = useSelector((state: RootState) => state.salesPool.totalReferences)
  const hasMore = useSelector((state: RootState) => state.salesPool.hasMore)

  const loading = useSelector((state: RootState) => state.salesPool.loading)
  const org = useSelector((state: RootState) => selectors.orgs.current(state))

  const references = useSelector(getReferences)

  const [query, setQuery] = useState('')

  useDebounce(() => dispatch(load(query, org.id)), 400, [query])

  const [queryWasChanged, setQueryWasChanged] = useState(false)
  useEffect(() => {
    if (query.length > 0) {
      setQueryWasChanged(true)
    }
  }, [query])

  function onScrollNext() {
    dispatch(load(query, org.id, references.length))
  }

  function getCopy(referenceName: string, text: string) {
    copyToClipboard(text)
    dispatch(
      notify({
        success: `${selectors.possessiveFirstName(referenceName)} contact email has been copied to your clipboard!`,
      }),
    )
  }

  const user = useUser()

  const canCreateRole = useSelector((state: RootState) => selectors.users.canCreateRole(state, user.id))

  if (org.fields.plan !== 'normal') {
    return <EmptySalesPoolPage button={<UpgradeButton orgId={org.id} product={'sales-pool'} />} />
  }

  return (
    <>
      {!queryWasChanged && references.length === 0 && (
        <EmptySalesPoolPage
          button={
            <Button
              as="Link"
              to={
                canCreateRole ? { pathname: '/roles/new', state: { from: 'sales-pool' } } : { pathname: '/sales-pool' }
              }
              buttonType="accent"
              onClick={() => tracking.salesPool.sidebarCreateReferenceCheckClicked()}
            >
              Start with Reference Check
            </Button>
          }
        />
      )}
      {(queryWasChanged || references.length > 0) && (
        <SalesPoolPageUI
          logout={() => dispatch(logout())}
          loading={loading}
          references={references}
          getCopy={getCopy}
          hasMore={hasMore}
          query={query}
          setQuery={setQuery}
          totalReferences={totalReferences}
          onScrollNext={onScrollNext}
        />
      )}
    </>
  )
}
