import { px2rem, size, style } from 'core'
import React from 'react'
import { designSystemColors } from '../../core/design-system/colors'
import { text } from '../../core/design-system/text'

const StepItem = style()
  .grid({ columns: [px2rem(48), size.auto] })
  .spacing({ gap: px2rem(16) })
  .element()

const CenterWrapper = style().center().element()

const StepCircle = style()
  .size({ width: px2rem(48), height: px2rem(48) })
  .color({
    bg: designSystemColors.backgroundSecondaryAccent,
    fg: designSystemColors.typographyPrimary,
  })
  .border({ radius: '50%' })
  .center()
  .element()
const StepLabel = text.timelineStep().serif().element('h3')

const StepInfo = style().flex({ direction: 'column' }).element()
const StepHeaderText = text.timelineHeader().serif().element('h4')
const StepDescriptionText = text.bodyText().element('p')

interface TimelineUIProps {
  label: string
  header: string
  description: string
}

const Step: React.FC<TimelineUIProps> = props => {
  return (
    <StepItem key={props.label}>
      <CenterWrapper>
        <StepCircle>
          <StepLabel>{props.label}</StepLabel>
        </StepCircle>
      </CenterWrapper>
      <StepInfo>
        <StepHeaderText>{props.header}</StepHeaderText>
        <StepDescriptionText>{props.description}</StepDescriptionText>
      </StepInfo>
    </StepItem>
  )
}

export default Step
