import { fr, px2rem, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import React from 'react'
import StatsCard from '../../components/StatsCard/StatsCard'
import { text } from '../../core/design-system/text'
import LargeScoreCard from './LargeScoreCard'

const ResponseRateStyle = style()
  .color({ bg: designSystemColors.backgroundNeutralPrimary })
  .border({
    around: `1px solid`,
    color: designSystemColors.borderDefault,
    radius: px2rem(8),
  })
  .nooverflow()
  .spacing({
    inner: px2rem(16),
    rows: px2rem(24),
  })
  .size({ minHeight: px2rem(186) })
  .grid()
  .element()

const StatsGridStyle = style()
  .grid({ columns: [fr(1), fr(1), fr(1)] })
  .spacing({ gap: px2rem(16) })
  .element()

const Title = text.cardHeader().element()

interface ResponseRateProps {
  overallScore: number
  surveysSent: number
  answers: number
}

const ResponseRate: React.FC<ResponseRateProps> = ({ overallScore, surveysSent, answers }) => {
  return (
    <ResponseRateStyle>
      <StatsGridStyle>
        <LargeScoreCard score={overallScore} smallGreyText={'%'} maxScore={100} scoreBarSegments={10}>
          <Title>Response Rate</Title>
        </LargeScoreCard>
        <StatsCard key={'Surveys sent'} label={'Surveys sent'} stat={surveysSent} />
        <StatsCard key={'Answers'} label={'Answers'} stat={answers} />
      </StatsGridStyle>
    </ResponseRateStyle>
  )
}

export default ResponseRate
