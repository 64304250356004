import { GroupScore, ModuleScore } from 'App/OpenJobRole/candidates'
import * as tracking from 'core/track'
import { MultiSelect } from 'primereact/multiselect'
import React, { useEffect, useState } from 'react'

export function getGroupScoreField(groupId: string) {
  return 'group_score_' + groupId
}

export function getModuleScoreField(moduleId: string) {
  return 'module_score_' + moduleId
}

export function getMultiselectColumns(
  refCheckEnabled: boolean,
  assessmentEnabled: boolean,
  groupScores?: GroupScore[],
  moduleScores?: ModuleScore[],
) {
  const assessmentColumns = [
    {
      field: 'score',
      header: 'Overall Score',
      selected: true,
    },
    ...(groupScores || []).map(gs => ({
      field: gs.field,
      header: gs.entity?.copy.title || '',
      selected: true,
    })),
    ...(moduleScores || []).map(ms => ({
      field: ms.field,
      header: ms.entity?.copy.title || '',
      selected: false,
    })),
    {
      field: 'assessment_completed_at',
      header: 'Assessment Completed At',
      selected: true,
    },
    {
      field: 'assessment_completion_status',
      header: 'Assessment Status',
      selected: true,
    },
  ]

  const refCheckColumns = [
    {
      field: 'ref_check_alerts',
      header: 'Reference Check Alerts',
      selected: true,
    },
    {
      field: 'ref_check_completed_at',
      header: 'Reference Check done',
      selected: true,
    },
    {
      field: 'reference_check_status',
      header: 'Reference Check Status',
      selected: true,
    },
  ]

  return [
    ...(assessmentEnabled ? assessmentColumns : []),
    ...(refCheckEnabled ? refCheckColumns : []),
    {
      field: 'evaluation_status',
      header: 'Evaluation',
      selected: true,
    },
    { field: 'hiring_status', header: 'Stage', selected: true },
  ]
}

export const CandidateTableColumnSelector: React.FC<{
  columns: {
    field: string
    header: string
    selected: boolean
  }[]
  setVisibleColumns: (columns: Record<string, boolean>) => void
  visibleColumns: Record<string, boolean> | undefined
}> = props => {
  const [multiselectColumns, setMultiselectColumns] = useState(props.columns.filter(c => c.selected))

  useEffect(() => {
    setMultiselectColumns(props.columns.filter(c => c.selected))
  }, [props.columns.length])

  useEffect(() => {
    if (props.visibleColumns !== undefined) {
      setMultiselectColumns(
        props.columns.filter(
          c => props.visibleColumns && c.field in props.visibleColumns && props.visibleColumns[c.field],
        ),
      )
    }
  }, [props.visibleColumns?.length])

  const onColumnToggle = event => {
    const selectedColumns = event.value || []

    const newVisibleColumns = selectedColumns.reduce((obj, column) => {
      obj[column.field] = true
      return obj
    }, {})

    const orderedSelectedColumns = props.columns.filter(col => selectedColumns.some(sCol => sCol.field === col.field))

    setMultiselectColumns(orderedSelectedColumns)
    props.setVisibleColumns(newVisibleColumns)
  }

  const clearButton = options => (
    <div
      {...options.iconProps}
      style={{
        paddingRight: '2em',
        textDecoration: 'underline',
        cursor: 'pointer',
      }}
    >
      Clear all
    </div>
  )

  return (
    <MultiSelect
      onFocus={tracking.candidates.candidateTableColumnSelectorClicked}
      value={multiselectColumns}
      options={props.columns}
      optionLabel="header"
      onChange={onColumnToggle}
      display="chip"
      style={{ width: '100%', position: 'relative' }}
      selectAll={false}
      showSelectAll={true}
      placeholder={'Select columns to display'}
      clearIcon={clearButton}
      showClear={true}
    />
  )
}
