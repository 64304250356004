import {
  ActionCard,
  CircleSteps,
  DrawerCard,
  PageHeader,
  ResourceCard,
  TaskCard,
  Text,
  TopBar,
  UsersIcons,
} from '@common/components'
import { EmptyTable } from '@common/components/Home/JobRow/Empty'
import { JobRow, JobRowLoading } from '@common/components/Home/JobRow/JobRow'
import { listing } from 'api'
import { RoleQuery } from 'api/openjobroles'
import DashboardLayout from 'components/DashboardLayout'
import { isPresent } from 'core/utils'
import React, { useEffect } from 'react'
import { BsFillRocketFill, BsStars } from 'react-icons/bs'
import { FaBook } from 'react-icons/fa'
import { RiCustomerServiceLine } from 'react-icons/ri'
import { TfiFiles } from 'react-icons/tfi'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import * as selectors from 'selectors'
import { RootState } from 'store'
import { createTestRole, loadRoles } from 'store/roles'
import { loadUsersByOrgId } from 'store/settings/user-management'
import * as tracking from '../../core/track'
import classes from './HomePage.module.scss'

function useHomePageData() {
  return useSelector((state: RootState) => {
    const userId = state.sessions.userId
    const org = selectors.orgs.current(state)
    const profile = selectors.profiles.getByUserId(state, userId)
    const people = state.userPermissions.users[org?.id ?? ''] ?? []

    const user = selectors.users.getById(state, userId)

    const isOrgAdmin = user.fields.is_org_admin

    const teamProfilePictures = [
      profile?.fields.profile_picture_url,
      ...people
        .map(personId => {
          const personProfile = selectors.profiles.getByUserId(state, personId)
          if (personId === userId || !personProfile || personProfile.fields.profile_picture_url === '') return undefined
          return personProfile?.fields.profile_picture_url
        })
        .filter(isPresent),
    ]

    const roles = state.roles.roles?.roleIds
      .map(id => selectors.roles.findById(state, id))
      .filter(role => role?.fields.created_by === userId)
      .filter(isPresent)

    const checks = {
      hasOrgLogo: !!org?.fields.logo_image_url,
      hasUsersInOrg: people.length > 1,
      hasProfilePicture: !!profile?.fields.profile_picture_url,
      hasTestRole: (roles || []).some(role => role.fields.is_test_role),
    }

    const isIntroComplete = isOrgAdmin
      ? checks.hasOrgLogo && checks.hasUsersInOrg && checks.hasProfilePicture
      : checks.hasProfilePicture

    return {
      login: state.login,
      orgId: org?.id,
      roles,
      firstName: selectors.firstName(profile?.fields.full_name ?? ''),
      userId,
      checks,
      teamProfilePictures,
      shouldShowIntro: !isIntroComplete,
      isFirstUser: isOrgAdmin,
      shouldShowCandidateRows: isIntroComplete || (roles && roles.length > 0),
    }
  })
}

const HomePage = () => {
  const location = useLocation()
  const history = useHistory()
  const state = useHomePageData()
  const [loadingCreateTest, setLoadingCreateTest] = React.useState(false)

  useEffect(() => {
    const { googlesso, ...utmParams } = (location?.state as Record<string, any>) ?? { googlesso: undefined }
    if (googlesso === true) {
      tracking.identify({
        userId: state.userId,
        email: state.login.email,
        is_org_admin: true,
        is_super_user: false,
        organization_plan: 'self-trial',
        ...utmParams,
      })
    }
  }, [state.login, location])

  const dispatch = useDispatch()

  const fetchRolesQuery: RoleQuery = {
    type: 'user',
    userId: state.userId,
    skip: 0,
    limit: 7,
    onlyDeleted: false,
    title: '',
  }

  useEffect(() => {
    if (state.userId) dispatch(loadRoles(fetchRolesQuery))
    if (state.orgId) {
      dispatch(
        loadUsersByOrgId(state.orgId, 0, [
          {
            field: 'created_at',
            order: listing.SortOrder.Asc,
          },
        ]),
      )
    }
  }, [state.orgId, state.userId])

  return (
    <DashboardLayout>
      <TopBar tracking={tracking} />
      <div className={classes.wrapper}>
        <div className={classes.container}>
          <PageHeader.Root>
            <PageHeader.Title>{`${state.firstName} - Hire better, faster.`}</PageHeader.Title>
          </PageHeader.Root>
          <div className={classes.flexRow}>
            <div className={classes.MainColumn}>
              <div data-testid="home-cards" className={classes.HomeCards}>
                <ActionCard
                  onClick={() => {
                    history.push({
                      pathname: '/roles/new',
                      state: { isQuickMode: true, from: 'assessment-page' },
                    })
                    tracking.home.homeAssessmentsCardClicked()
                  }}
                  title={'Assess Candidates'}
                  description={'Cut through the noise and discover perfect fits'}
                />
                <ActionCard
                  onClick={() => {
                    history.push({
                      pathname: '/roles/new',
                      state: { isQuickMode: true, from: 'references-page' },
                    })
                    tracking.home.homeReferenceChecksCardClicked()
                  }}
                  title={'Check References'}
                  description={'Clarify credentials and identify top talents'}
                />
              </div>
              {state.shouldShowIntro && (
                <DrawerCard
                  header={
                    <div className={classes.MainCardWizard}>
                      <Text variant="home-card-header">Account Setup</Text>

                      <CircleSteps
                        numOfSteps={state.isFirstUser ? 4 : 1}
                        currentStep={
                          state.isFirstUser
                            ? Object.values(state.checks).filter(Boolean).length
                            : state.checks.hasProfilePicture
                            ? 1
                            : 0
                        }
                      />
                    </div>
                  }
                  children={
                    <div className={classes.MainCardContent}>
                      <div className={classes.MainCardTasks}>
                        {state.isFirstUser && (
                          <>
                            <TaskCard
                              onClick={() => {
                                history.push('/settings/organization')
                                tracking.home.taskClicked('Customize your brand')
                              }}
                              title="Customize your brand"
                              description="Upload your organization's logo"
                              completed={state.checks.hasOrgLogo}
                            />
                            <TaskCard
                              onClick={() => {
                                history.push('/settings/users')
                                tracking.home.taskClicked('Invite your team')
                              }}
                              title="Invite your team"
                              description="Invite your team mates and hire
                  better together"
                              completed={state.checks.hasUsersInOrg}
                            />
                            <TaskCard
                              onClick={async () => {
                                if (loadingCreateTest) return
                                setLoadingCreateTest(true)
                                await dispatch(createTestRole())
                                await dispatch(loadRoles(fetchRolesQuery))
                                setLoadingCreateTest(false)
                                tracking.roles.testRoleCreation('Home Page')
                              }}
                              title="Create test job"
                              description="Automatically create a test job with example candidates for you to review"
                              completed={state.checks.hasTestRole}
                              loading={loadingCreateTest}
                            />
                          </>
                        )}
                        <TaskCard
                          onClick={() => {
                            history.push(`/settings/accounts/${state.userId}`)
                            tracking.home.taskClicked('Make it yours')
                          }}
                          title="Make it yours"
                          description="Personalize your account with a unique
                  profile picture"
                          completed={state.checks.hasProfilePicture}
                        />
                      </div>
                    </div>
                  }
                  showHandle={state.shouldShowCandidateRows}
                  defaultCollapsed={state.shouldShowCandidateRows}
                  className={classes.introDrawer}
                />
              )}
              {state.shouldShowCandidateRows && (
                <div className={`${classes.Card} ${classes.FullHeight}`}>
                  <div className={classes.MainCardHeader}>
                    <Text variant="home-card-header">Candidate Reviews</Text>
                  </div>
                  <div className={classes.CandidateReviewsTable} data-testid="candidate-reviews-table">
                    {state.roles?.length ? (
                      state.roles?.map(role => (
                        <JobRow
                          key={role.id}
                          id={role.id}
                          name={role.fields.name}
                          refCheck={role.fields.ask_reference_check}
                          assessment={role.fields.ask_self_assessment}
                          toReview={role.fields.candidates_to_review_count}
                          active={role.fields.is_active}
                          onClick={() => {
                            tracking.home.homeJobRowClicked(role.id, role.fields.name)
                            history.push(`/roles/${role.id}`)
                          }}
                        />
                      ))
                    ) : loadingCreateTest ? (
                      <JobRowLoading />
                    ) : (
                      <EmptyTable />
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className={classes.SideColumn}>
              {state.isFirstUser ? (
                <div
                  className={classes.Card}
                  style={{
                    display: 'flex',
                    gap: '15px',
                    flexDirection: 'column',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '15px',
                    }}
                  >
                    <Text variant="home-card-header">Invite your team</Text>
                    <UsersIcons imgUrls={state.teamProfilePictures} />
                  </div>
                  <ActionCard
                    variant="secondary"
                    title="Invite your team"
                    onClick={() => {
                      history.push('/settings/users')
                      tracking.home.inviteTeamClicked()
                    }}
                  />
                </div>
              ) : (
                ''
              )}
              <div className={classes.Card}>
                <Text variant="home-card-header">Resources</Text>
                <div className={classes.ResourcesCards}>
                  {resources.map(resource => (
                    <ResourceCard
                      key={resource.title}
                      title={resource.title}
                      description={resource.description}
                      icon={resource.icon}
                      onClick={() => {
                        resource.link && window.open(resource.link, '_blank')
                        tracking.home.resourceClicked(resource.title)
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  )
}

export default HomePage

const resources: {
  title: string
  description: string
  icon?: React.ReactNode
  link?: string
}[] = [
  {
    title: 'Advice and Answers',
    description: 'All answers to the HiPeople platform',
    icon: <FaBook />,
    link: 'https://intercom.help/hipeople/en/',
  },
  {
    title: 'Contact sales',
    description: 'Get in touch with sales',
    icon: <RiCustomerServiceLine />,
    link: 'https://meetings.hubspot.com/jgillmann',
  },
  {
    title: 'Product Updates',
    description: 'Be the first to know about new features',
    icon: <BsFillRocketFill />,
    link: 'https://intercom.help/hipeople/en/collections/3880257-updates',
  },
  {
    title: 'HiPeople Blog',
    description: 'Read our latest news and content',
    icon: <BsStars />,
    link: 'https://www.hipeople.io/blog',
  },
  {
    title: 'User Reviews',
    description: 'Read what users think of us',
    icon: <TfiFiles />,
    link: 'https://www.g2.com/products/hipeople/reviews',
  },
]
