import { map } from './css'
import Rows from './rows'

export interface IOptions {
  align?: string
  family?: string[]
  size?: string
  weight?: string
  height?: string
  line?: string
  letterSpacing?: string
  color?: string
  uppercase?: boolean
  lowercase?: boolean
  capitalize?: boolean
  ellipsis?: boolean
  breakSpaces?: boolean
  noWrap?: boolean
  preformatted?: boolean
  nodecoration?: boolean
  numeric?: string
  italic?: boolean
  underline?: boolean
}

export default function type(rows: Rows, options: IOptions) {
  if (options.uppercase) {
    rows.set('textTransform', 'uppercase')
  } else if (options.lowercase) {
    rows.set('textTransform', 'lowercase')
  } else if (options.capitalize) {
    rows.set('textTransform', 'capitalize')
  }

  if (options.breakSpaces) {
    rows.set('whiteSpace', 'break-spaces')
  }

  if (options.ellipsis) {
    rows.set('whiteSpace', 'nowrap')
    rows.set('overflow', 'hidden')
    rows.set('textOverflow', 'ellipsis')
  }

  if (options.noWrap) {
    rows.set('whiteSpace', 'nowrap')
  }

  if (options.preformatted) {
    rows.set('whiteSpace', 'pre-line')
  }

  if (options.family) {
    rows.set('fontFamily', "'" + options.family.join("','") + "'")
  }

  if (options.nodecoration) {
    rows.set('textDecoration', 'none')
  }

  if (options.italic) {
    rows.set('fontStyle', 'italic')
  }

  if (options.underline) {
    rows.set('textDecoration', 'underline')
  }

  if (options.numeric) {
    rows.set('fontVariantNumeric', options.numeric)
  }

  map(rows, options, {
    align: 'textAlign',
    color: 'color',
    height: 'lineHeight',
    numeric: 'fontVariantNumeric',
    line: 'lineHeight',
    letterSpacing: 'letterSpacing',
    size: 'fontSize',
    weight: 'fontWeight',
  })
}
