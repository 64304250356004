import { InterviewStage } from 'api/openjobroles'
import Checkbox from 'components/Checkbox'
import TextField from 'components/TextField'
import { Buttons, Content, ContentAndButtons } from 'components/Wizard'
import { Heading, Subheading } from 'components/Wizard/Typography'
import { fr, px2rem, rem, space, style } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import { text } from 'core/design-system/text'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import * as selectors from 'selectors'
import { RootState } from 'store'
import * as slice from 'store/integrations'
import { ATS as ATSType } from 'store/integrations/ats'

interface Props {
  next: () => void
  back: () => void
  setDone: (d: boolean) => void
  closeModal: () => void
  atsMap: { [key: string]: ATSType }
}

const SelectedContainer = style()
  .grid({ columns: [fr(1), fr(1)] })
  .spacing({ columns: space.s, rows: space.s })
  .element()

const ConfirmContainer = style().flex().element()
const ConfirmMessage = text.bodyText().spacing({ outerLeft: space.xs }).element()

const SubheadingBold = text.bodyEmphasis().fg(designSystemColors.typographySecondary).inline().element('p')

const Form = style()
  .grid()
  .spacing({ rows: rem(1.35) })
  .element()

const Labels = style()
  .grid({ columns: [fr(1), fr(1)] })
  .spacing({ columns: space.s, outerBottom: px2rem(10) })
  .element()

const Label = text.bodyInteractive().element()

const Component = (props: Props) => {
  const dispatch = useDispatch()
  const { roleid } = useParams<{ roleid: string }>()

  const org = useSelector((state: RootState) => selectors.orgs.getById(state, selectors.orgs.currentId(state)))
  const integration = useSelector((state: RootState) => selectors.integrations.getByOrgId(state, org.id))
  const role = useSelector((state: RootState) => selectors.roles.findById(state, roleid || ''))
  const loading = useSelector((state: RootState) => state.integrations.loading)
  const created = useSelector((state: RootState) => state.integrations.created)
  const selectedATSJob = useSelector((state: RootState) => state.integrations.selectedATSJob)
  const selectedATSReferenceCheckJobInterviewStage = useSelector(
    (state: RootState) => state.integrations.selectedATSReferenceCheckJobInterviewStage,
  )

  const selectedATSSelfAssessmentJobInterviewStage = useSelector(
    (state: RootState) => state.integrations.selectedATSSelfAssessmentJobInterviewStage,
  )

  const [agree, setAgree] = useState(false)

  useEffect(() => {
    if (created) props.closeModal()
  }, [props.closeModal, created])

  if (integration?.fields.ats_slug && props.atsMap[integration?.fields.ats_slug]) {
    const ats = props.atsMap[integration?.fields.ats_slug || '']

    return (
      <ContentAndButtons>
        <Content>
          <Heading>Review</Heading>
          <Subheading>
            HiPeople <SubheadingBold>“{role.fields.name}”</SubheadingBold> will be connected to the ATS'{' '}
            <SubheadingBold>“{selectedATSJob?.name}”</SubheadingBold> role.
          </Subheading>
          <Labels>
            <Label>HiPeople module</Label>
            <Label>ATS Pipeline stage</Label>
          </Labels>
          <Form>
            <SelectedContainer>
              {role.fields.ask_self_assessment ? (
                <>
                  <TextField label="" value={`👤 Assessment`} disabled />
                  <TextField label="" value={selectedATSSelfAssessmentJobInterviewStage?.name || ''} disabled />
                </>
              ) : (
                <></>
              )}
              {role.fields.ask_reference_check ? (
                <>
                  <TextField label="" value={`👥 Reference Check`} disabled />
                  <TextField label="" value={selectedATSReferenceCheckJobInterviewStage?.name || ''} disabled />
                </>
              ) : (
                <></>
              )}
            </SelectedContainer>
            <ConfirmContainer>
              <Checkbox
                on={agree}
                onChange={v => {
                  props.setDone(true)
                  setAgree(v)
                }}
              />
              <ConfirmMessage>
                I understand that by connecting this role, HiPeople will automatically send an email to the candidate to
                complete the defined HiPeople questionnaire when they are moved to the above Pipeline stages in{' '}
                {ats.name}.
              </ConfirmMessage>
            </ConfirmContainer>
          </Form>
        </Content>
        <Buttons
          next={() => {
            const interviewStages: InterviewStage[] = []
            if (selectedATSReferenceCheckJobInterviewStage?.id) {
              interviewStages.push({
                ats_job_interview_stage_id: selectedATSReferenceCheckJobInterviewStage.id,
                integrated_product: 'reference-check',
              })
            }
            if (selectedATSSelfAssessmentJobInterviewStage?.id) {
              interviewStages.push({
                ats_job_interview_stage_id: selectedATSSelfAssessmentJobInterviewStage.id,
                integrated_product: 'self-assessment',
              })
            }

            // TalentLink is an edge case, and we need to let the user manage ATS automation
            // without providing an interview stage. Backend has a strict validation, that's why we need to have
            // something in the request, even though it won't be used in the end.
            if (ats.slug === 'cornerstone-talentlink' && interviewStages.length === 0) {
              interviewStages.push({
                ats_job_interview_stage_id: '0',
                integrated_product: 'self-assessment',
              })
            }

            dispatch(
              slice.createOpenJobRoleIntegration(
                integration?.id || '',
                role?.id || '',
                selectedATSJob?.id || '',
                interviewStages,
              ),
            )
          }}
          back={props.back}
          disabled={!agree}
          label={'Confirm'}
          loading={loading}
        />
      </ContentAndButtons>
    )
  }
  return <></>
}

export default Component
