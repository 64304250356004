import '@common/components/Table/PrimeReactTable.scss'
import { Candidate } from 'App/OpenJobRole/candidates'
import * as tracking from 'core/track'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from '@common/components'

import { RootState } from 'store'

interface NameProps {
  roleId: string
  isReferenceCheckActivated: boolean
  candidate: Candidate
}

export const CandidateName: React.FC<NameProps> = props => {
  const trackingProps = useSelector((state: RootState) =>
    tracking.candidates.extractPropsFromState(state, props.candidate.id),
  )

  // if the pathname is empty,
  // it means the candidate has not completed the assessment or reference check yet
  const pathname = route(
    props.isReferenceCheckActivated && props.candidate.reference_check_status === 'completed',
    props.candidate.assessment_completion_status === 'completed',
    props.candidate.id,
    props.roleId,
  )

  return (
    <Link
      to={{
        pathname,
      }}
      style={{ color: 'inherit', cursor: pathname ? 'pointer' : 'default' }}
      className="data-hj-suppress"
      onClick={() => trackingProps && tracking.candidates.viewed(trackingProps)}
      data-testid="candidates-table-candidate-name"
    >
      {props.candidate['candidate_profiles.full_name']}
    </Link>
  )
}

const route = (
  isRefCheckCompleted: boolean,
  isSelfAssessmentCompleted: boolean,
  candidateId: string,
  roleId: string,
) => {
  return isSelfAssessmentCompleted
    ? `/roles/${roleId}/candidates/${candidateId}/assessment`
    : `/roles/${roleId}/candidates/${candidateId}/`
}
