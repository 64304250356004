import React from 'react'
import { useSelector } from 'react-redux'
import * as selectors from '../../selectors'
import { RootState } from '../../store'

interface Props {
  children: React.ReactNode
  roleId: string
}

const Component = (props: Props) => {
  const canManageRole = useSelector((state: RootState) =>
    selectors.openJobRoleUsers.canManageRole(state, props.roleId, state.sessions.userId),
  )

  return canManageRole ? <>{props.children}</> : null
}

export default Component
Component.displayName = 'CanManageRole'
