export const RESOURCE = 'managers'

export interface Fields {
  id: string
  org_id: string
  work_email: string
  name: string
  created_at: number
  updated_at: number
  is_active: boolean
}
