import Markdown from 'markdown-to-jsx'
import React from 'react'
import { cleanMd } from '../../utils/markdown'
import classes from './MarkdownText.module.scss'

interface MarkdownTextProps {
  text?: string
  wrapper?: React.ElementType
  className?: string
}

const MarkdownImage = ({ src, className, alt, ...rest }: React.ImgHTMLAttributes<any>) => {
  return <img className={`${classes.imgElement} ${className || ''}`} src={src} alt={alt} {...rest} />
}

const MarkdownCode: React.FC<React.HTMLAttributes<any>> = ({ children, ...rest }) => {
  return (
    <code className={classes.codeElement} {...rest}>
      {children}
    </code>
  )
}

const MarkdownLink: React.FC<React.AnchorHTMLAttributes<any>> = ({ children, ...rest }) => {
  return (
    <a {...rest} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  )
}

const MarkdownHr: React.FC<React.HTMLAttributes<any>> = ({ children, ...rest }) => {
  return <hr className={classes.hrElement} {...rest} />
}

export const MarkdownText = (props: MarkdownTextProps) => {
  return (
    <Markdown
      className={props.className ? [classes.markdownContainer, props.className].join(' ') : classes.markdownContainer}
      options={{
        wrapper: props.wrapper,
        forceWrapper: true,
        overrides: {
          a: MarkdownLink,
          img: MarkdownImage,
          code: MarkdownCode,
          hr: MarkdownHr,
        },
      }}
    >
      {cleanMd(props.text || '')}
    </Markdown>
  )
}
