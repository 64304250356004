import { RootState } from '../../store'
import { findDashboardView } from '../assessment-versions'
import * as chart from './chart'
import * as lineChart from './line-chart'
import * as radarChart from './radar-chart'

export { chart }

type Chart = chart.Chart
export type Options = chart.Options
export type Data = chart.Data

export function chartForQuestion(state: RootState, candidateId: string, contentId: string): Chart | undefined {
  const dashboardView = findDashboardView(state, candidateId, contentId)
  if (!dashboardView || !dashboardView.chart) return

  if (dashboardView.chart.line_chart && dashboardView.chart.line_chart.render_slider_values_by_year) {
    return lineChart.sliderLineChart(state, candidateId, contentId)
  }

  if (dashboardView.chart.radar_chart && dashboardView.chart.radar_chart.render_chip_labels) {
    return radarChart.renderChipLabels(state, candidateId, contentId)
  }
}
