import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMount } from 'react-use'
import { RootState } from 'store'
import { loadOrgs } from 'store/settings/org-management'
import { setOrganization } from 'store/settings/user-management'
import DropdownField, { Option } from '../../components/DropdownField'
import { rem, size, style } from '../../core'
import * as selectors from '../../selectors'
import { compareAlphaNum } from './util'

const Container = style()
  .grid({ columns: [size.auto, rem(12)] })
  .element()

const Empty = style().element()

const OrgViewSelector = props => {
  const dispatch = useDispatch()
  const hasMounted = useRef(false)

  useMount(() => {
    // TODO: Redesign loadOrgs to be idempotent and remove this hack.
    if (hasMounted.current) return

    dispatch(loadOrgs())
    hasMounted.current = true
  })

  const current = useSelector(
    (state: RootState) => state.userPermissions.organization || selectors.orgs.currentId(state),
  )

  const organizations = useSelector((state: RootState) =>
    state.orgManagement.orgs.map(id => selectors.orgs.getById(state, id)),
  )

  const [filter, setFilter] = useState('')

  return (
    <Container>
      <Empty />
      <DropdownField
        value={current}
        onChange={value => {
          dispatch(setOrganization(value))
          setFilter('')
        }}
        allowInput
        onInput={input => setFilter(input)}
        placeholder={'Organization'}
        minimal
      >
        {organizations
          .filter(org => selectors.match(org.fields.name, filter))
          .sort((a, b) => compareAlphaNum(a?.fields.name, b?.fields.name))
          .map(org => (
            <Option key={org.id} imageUrl={org.fields.logo_image_url} value={org.id}>
              {org.fields.name}
            </Option>
          ))}
      </DropdownField>
    </Container>
  )
}

export default OrgViewSelector
