import DropdownField, { Option } from 'components/DropdownField'
import { space, style } from 'core'
import React from 'react'

const Container = style()
  .flex({
    direction: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  })
  .select('> *', style().size({ width: '100%' }))
  .spacing({ columns: [space.xs], bottom: [space.m] })
  .size({ width: '100%' })
  .element()

// DATA FIELDS
const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

// METHODS
const isLeapYear = (year: number) => (year % 100 === 0 ? year % 400 === 0 : year % 4 === 0)

function populateDays(month?: number, year?: number) {
  let daysInMonth = 31
  const monthsWith30Days = [4, 6, 9, 11]

  // handle months with different days
  if (month) {
    if (month === 2) {
      daysInMonth = 28
    } else if (monthsWith30Days.includes(month)) {
      daysInMonth = 30
    }
  }
  // handle leap years
  if (year) {
    daysInMonth = isLeapYear(year) && month === 2 ? 29 : daysInMonth
  }
  return [...Array(daysInMonth)].map((_, i) => (
    <Option key={i + 1} value={i + 1}>
      {i + 1}
    </Option>
  ))
}

function populateMonths() {
  return months.map((_, i) => (
    <Option key={i + 1} value={i + 1}>
      {months[i]}
    </Option>
  ))
}

/**
 * populateYears returns an array of 3 years before the current year, the current year and the next year
 */
function populateYears() {
  const startYear = new Date().getFullYear() - 3
  return [...Array(5)].map((_, i) => (
    <Option key={startYear + i} value={startYear + i}>
      {startYear + i}
    </Option>
  ))
}

interface Props {
  day?: number
  setDay: (value?: number) => void
  month?: number
  setMonth: (value?: number) => void
  year?: number
  setYear: (value?: number) => void
  startYear?: number
  numberOfYears?: number
}

const DateInput = (props: Props) => {
  return (
    <Container>
      <DropdownField
        aria-label="Day"
        placeholder="Day"
        value={props.day}
        onChange={e => props.setDay(e)}
        disableRelativePositionTracking={true}
      >
        {populateDays(props.month, props.year)}
      </DropdownField>
      <DropdownField
        aria-label="Month"
        placeholder="Month"
        value={props.month}
        onChange={e => {
          props.setMonth(e)
          populateDays(e, props.year)
        }}
        disableRelativePositionTracking={true}
      >
        {populateMonths()}
      </DropdownField>
      <DropdownField
        aria-label="Year"
        placeholder="Year"
        value={props.year}
        onChange={e => {
          props.setYear(e)
          populateDays(props.month, e)
        }}
        disableRelativePositionTracking={true}
      >
        {populateYears()}
      </DropdownField>
    </Container>
  )
}

export default DateInput
