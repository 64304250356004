import { BoundingRect } from 'react-measure'
import { Params } from '.'
import { px, style } from '../../core'

const WINDOW_OVERFLOW_BUFFER_PX = 20

interface PopupPositionProps extends Params {
  windowSize: { width: number; height: number }
  bounds: BoundingRect
}

export default function (props: PopupPositionProps) {
  const rect = props.parent?.current?.getBoundingClientRect()

  let top = rect && rect.top ? rect.top : 0
  if (props.bounds && props.windowSize && props.windowSize.height - WINDOW_OVERFLOW_BUFFER_PX < props.bounds.bottom) {
    top -= props.bounds.bottom - (props.windowSize.height - WINDOW_OVERFLOW_BUFFER_PX)
  } else if (props.bounds && top > props.bounds?.top) {
    top = props.bounds.top
  }

  let left = rect ? (rect.width || 0) + (rect.left || 0) : 0
  if (
    rect &&
    props.bounds &&
    props.windowSize &&
    props.windowSize.width - WINDOW_OVERFLOW_BUFFER_PX < rect.x + props.bounds.width
  ) {
    left -= props.bounds.width + WINDOW_OVERFLOW_BUFFER_PX
  } else {
    left -= 10
  }

  return style()
    .size({
      width: !props.inheritSize && rect && rect.width ? px(rect.width) : '',
    })
    .fixed({
      top: px(top),
      left: `${left}px`,
    })
}
