import React, { FC, useMemo } from 'react'
import { languageToItem } from '../LanguageDropdown'
import { Locale } from '../../../../models/locales'
import { MultiSelectDropdown, MultiSelectDropdownProps } from '../../MultiSelectDropdown/MultiSelectDropdown'
import { DropdownItemWithLabel } from '../../Dropdown'

interface LanguageDropdownProps {
  locales: ReadonlyArray<Locale>
  defaultLocales: ReadonlyArray<Locale>
  onSelectLocales: (items: Locale[]) => void
  disabled?: boolean
  placeholder?: string
  testId?: string
}

/**
 *  A dropdown component that allows the user to select multiple languages.
 * @param locales - An array of language codes
 * @param defaultLocales - array of the default languages codes
 * @param onSelectLocales - A function that returns the codes of the selected languages. Returns an array of language codes
 * @param disabled - True when the dropdown should be disabled
 * @param placeholder - Optional text which is used when there are no selected languages.
 * @example
 * <MultiSelectLanguageDropdown
 *  locales={['en_US', 'es_ES', 'de_DE', 'fr_FR', 'fr_CA', 'it_IT']}
 *  defaultLocales={['en_US', 'de_DE']}
 *  onSelectLocale={items => console.log(items)}
 *  placeholder='Select languages'
 * />
 */
export const MultiSelectLanguageDropdown: FC<LanguageDropdownProps> = ({
  locales,
  defaultLocales,
  onSelectLocales,
  disabled,
  placeholder,
  testId,
}) => {
  const items: MultiSelectDropdownProps['items'] = useMemo(() => locales.map(item => languageToItem(item)), [locales])

  const selectedDefault: MultiSelectDropdownProps['items'] = useMemo(
    () => defaultLocales.map(item => languageToItem(item)),
    [defaultLocales],
  )

  const onSelect = (items: MultiSelectDropdownProps['selectedDefault']) => {
    if (items === undefined) {
      return
    }
    onSelectLocales(items.map(item => (item as DropdownItemWithLabel).value as Locale))
  }

  return (
    <MultiSelectDropdown
      items={items}
      selectedDefault={selectedDefault}
      onSelect={onSelect}
      disabled={disabled}
      placeholder={placeholder}
      testId={testId}
    />
  )
}
