import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { request } from '../../api'

const name = 'resources'
const initialState: { [key: string]: request.Entity<any> } = {}

const slice = createSlice({
  name,
  initialState,
  reducers: {
    addEntities(state, action: PayloadAction<request.Success<request.Entity<any> | request.Entity<any>[]>>) {
      const url = new URL(`http://host${action.payload?.path || ''}`)
      const includeParam = url.searchParams.get('include') || ''
      const includedKeys = new Set(includeParam.split(','))

      const add = (r: request.Entity<any>) => {
        const key = buildKey(r.resource, r.id)
        const existing = state[key]?.relationships || []
        const relationships: Record<string, request.RelationshipKey> = {}
        // The relationships should be those we have already but didn't expect to see
        for (const rel of existing) {
          if (includedKeys.has(rel.resource)) continue
          relationships[buildKey(rel.resource, rel.id)] = rel
        }
        // Plus the ones we did expect to see
        for (const rel of r.relationships || []) {
          relationships[buildKey(rel.resource, rel.id)] = rel
        }
        state[key] = { ...r, relationships: Object.values(relationships) }
      }

      if (action.payload) {
        const { result, included } = action.payload

        if (!Array.isArray(result) && result.id) {
          add(result)
        } else if (Array.isArray(result)) {
          result.forEach(r => add(r))
        }

        if (included) {
          included.forEach(e => add(e))
        }
      }
    },
  },
})

export const { addEntities } = slice.actions

export default slice.reducer

export function buildKey(resource: string, id: string): string {
  return `${resource}:${id}`
}
