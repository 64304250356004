import React from 'react'
import classes from './EmptyState.module.scss'

interface Props {
  photo?: string
  icon?: React.ReactNode
  centered?: boolean
  background?: 'neutral' | 'lightGray'
  text?: React.ReactNode
  bordered?: boolean
  rounded?: boolean
}

export const EmptyState = ({ centered, photo, background, text, icon }: Props) => {
  return (
    <div data-centered={centered} data-background={background} className={classes.emptystate}>
      {photo ? (
        <img className={classes.emptystatePhoto} src={photo} alt="no items to show" />
      ) : icon ? (
        <div className={classes.emptystateIcon}>{icon}</div>
      ) : null}

      {typeof text === 'string' ? <p className={classes.emptystateText}>{text}</p> : text}
    </div>
  )
}
