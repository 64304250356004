import { SELF_REFERENCE_QUESTION_LOOKUP } from 'store/new-role/content'
import * as api from '../api'
import { RootState } from '../store'
import {
  CURRENT_EXPERIENCE_QUESTION_NOT_APPLICABLE_ANSWER,
  SKILL_LEVEL_QUESTION_NOT_APPLICABLE_ANSWER,
} from '../store/new-role/response-options'
import {
  findOverallPerformanceQuestionsByCandidateId,
  findReferenceOptionById,
  findSkillsetQuestionsByCandidateId,
} from './assessment-versions'
import * as candidates from './candidates'
import lookup from './lookup'
import * as references from './references'

export function findById(state: RootState, id: string): api.request.Entity<api.formresponses.Fields> | undefined {
  return lookup<api.formresponses.Fields>(state, api.formresponses.RESOURCE, id)
}

export function findByReferenceId(
  state: RootState,
  referenceId: string,
): api.request.Entity<api.formresponses.Fields> | undefined {
  const ref = references.findById(state, referenceId)
  if (!ref) return

  const response = findById(state, ref.fields.form_response_id)
  if (!response) return

  return response
}

function findByCandidateId(
  state: RootState,
  candidateId: string,
): api.request.Entity<api.formresponses.Fields> | undefined {
  const candidate = candidates.findById(state, candidateId)
  if (!candidate) return

  const response = findById(state, candidate.fields.candidate_response_id)
  if (!response) return

  return response
}

export function findAnswerByReferenceId(
  state: RootState,
  referenceId: string,
  contentId: string,
): api.formresponses.Row | undefined {
  const response = findByReferenceId(state, referenceId)
  if (!response || !response.fields.content) return

  const associated = SELF_REFERENCE_QUESTION_LOOKUP[contentId]?.test_item_id

  return response.fields.content.find(row => row.item_id === associated || row.item_id === contentId)
}

export function findAnswerByCandidateId(
  state: RootState,
  candidateId: string,
  itemId: string,
): api.formresponses.Row | undefined {
  const response = findByCandidateId(state, candidateId)
  if (!response || !response.fields.content) return
  return response.fields.content.find(row => row.item_id === itemId)
}

export function findAllAnswersByReferenceId(
  state: RootState,
  referenceId: string,
): api.formresponses.Row[] | undefined {
  const response = findByReferenceId(state, referenceId)
  if (!response || !response.fields.content) return
  return response.fields.content
}

export function groupAnswersByRelationshipYear(
  state: RootState,
  candidateId: string,
  contentId: string,
): { [year: string]: api.formresponses.Row[] } {
  const result: { [year: string]: api.formresponses.Row[] } = {}
  const candidateRefs = references.findByCandidateId(state, candidateId)

  for (const ref of candidateRefs) {
    const answer = findAnswerByReferenceId(state, ref.id, contentId)
    if (!answer) continue

    if (!result[ref.fields.to]) {
      result[ref.fields.to] = []
    }

    result[ref.fields.to].push(answer)
  }

  return result
}

export function findAnswerByResponseId(
  state: RootState,
  formResponseId: string,
  itemId: string,
): api.formresponses.Row | undefined {
  const response = findById(state, formResponseId)
  if (!response || !response.fields.content) return
  return response.fields.content.find(row => row.item_id === itemId)
}

export function findSelectedOptionsByReferenceId(
  state: RootState,
  referenceId: string,
  contentId: string,
): (api.assessmentversions.Option | undefined)[] {
  const answer = findAnswerByReferenceId(state, referenceId, contentId)
  if (!answer || !answer.selected) return []

  return answer.selected.map((optionId: string) => findReferenceOptionById(state, referenceId, optionId))
}

export function findPopularOptionsByCandidateIdExceptSelf(
  state: RootState,
  candidateId: string,
  contentId: string,
  threshold: number,
): { total: number; popular: { caption: string; count: number }[] } {
  const counts: { [key: string]: number } = {}
  let total = 0

  const submitted = references.findRespondedByCandidateIdExceptSelf(state, candidateId)

  for (const reference of submitted) {
    const options = findSelectedOptionsByReferenceId(state, reference.id, contentId)

    for (const option of options) {
      if (!option) continue

      total++

      if (counts[option.caption]) {
        counts[option.caption] = counts[option.caption] + 1
        continue
      }

      counts[option.caption] = 1
    }
  }

  const sorted = Object.keys(counts)
    .map(key => ({ caption: key, count: counts[key] }))
    .filter(row => row.count > threshold)
    .sort((a, b) => {
      if (a.count > b.count) return -1
      if (a.count < b.count) return 1
      return 0
    })

  return { total, popular: sorted }
}

export function outstandingOptionValue(
  state: RootState,
  candidateId: string,
  contentId: string,
  options: api.assessmentversions.Option[],
): number {
  const submitted = references.findRespondedByCandidateIdExceptSelf(state, candidateId)
  const mostRecentYear = Math.max(...submitted.map(reference => reference.fields.to))
  const yearsConsideredValuable = 3

  const ignoreList = [
    SKILL_LEVEL_QUESTION_NOT_APPLICABLE_ANSWER.content_id,
    CURRENT_EXPERIENCE_QUESTION_NOT_APPLICABLE_ANSWER.content_id,
  ]
  const recents = submitted.filter(reference => {
    const selected = findSelectedOptionsByReferenceId(state, reference.id, contentId)?.[0]

    return (
      reference.fields.to >= mostRecentYear - yearsConsideredValuable &&
      selected?.value !== undefined &&
      !ignoreList.includes(selected?.content_id)
    )
  })

  let selectedTotal = 0
  for (const reference of recents) {
    const selectedOption = findSelectedOptionsByReferenceId(state, reference.id, contentId)
    selectedTotal += selectedOption[0]?.value || 0
  }

  const outstandingOptionValue = Math.ceil(selectedTotal / recents.length)

  let bestMatch = 0
  for (const option of options) {
    if (Math.abs(option.value - outstandingOptionValue) <= Math.abs(bestMatch - outstandingOptionValue)) {
      bestMatch = option.value
    }
  }

  return bestMatch
}

export function findAllSkillAnswersByReferenceId(
  state: RootState,
  referenceId: string,
  candidateId: string,
): api.formresponses.Row[] | undefined {
  const skills = findSkillsetQuestionsByCandidateId(state, candidateId)

  const response = findByReferenceId(state, referenceId)
  if (!response || !response.fields.content) return

  return response.fields.content.filter(item => skills.includes(item.item_id))
}

export function findAllOverallPerformanceAnswersByReferenceId(
  state: RootState,
  referenceId: string,
  candidateId: string,
): api.formresponses.Row[] | undefined {
  const overallPerformance = findOverallPerformanceQuestionsByCandidateId(state, candidateId)

  const response = findByReferenceId(state, referenceId)
  if (!response || !response.fields.content) return

  return response.fields.content.filter(item => overallPerformance.includes(item.item_id))
}
