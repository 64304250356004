import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SelectedUser } from '../../api/openjobroles'
import Modal from '../../components/Modal'
import { Form } from '../../components/Wizard'
import { Subheading } from '../../components/Wizard/Typography'
import { rem, space, style } from '../../core'
import * as selectors from '../../selectors'
import { RootState } from '../../store'
import { add as notify } from '../../store/notifications'
import * as slice from '../../store/role-user-management'
import UserPermissions from './UserPermissions'

interface Props {
  open: boolean
  roleId: string
  setOpen: (b: boolean) => void
}

const Container = style()
  .size({
    maxWidth: rem(50),
    maxHeight: rem(25),
    width: 'calc(100vw - 200px)',
    height: 'calc(100dvh - 200px)',
  })
  .spacing({ inner: [space.xxs, space.m] })
  .element()

const Component = (props: Props) => {
  const dispatch = useDispatch()

  const [orgId, selectableUserIds, selectedUsersWithPerms]: [
    string | undefined,
    RootState['roleUserManagement']['selectableUserIds'],
    SelectedUser[],
  ] = useSelector((state: RootState) => {
    return [
      selectors.orgs.findByRoleId(state, props.roleId)?.id,
      state.roleUserManagement.selectableUserIds,
      selectors.openJobRoleUsers.findByRoleId(state, props.roleId).map(
        user =>
          ({
            id: user.fields.user_id,
            can_control: user.fields.can_control,
          } as SelectedUser),
      ),
    ]
  })

  useEffect(() => {
    if (orgId) {
      dispatch(slice.loadUsersByOrgId(orgId))
    }
  }, [dispatch, orgId])

  return (
    <Modal title="Update Job User Permissions" open={props.open} setOpen={props.setOpen}>
      <Container>
        <Subheading>
          Every job is accessible by a set of users. Select the users that should have access to this job.
        </Subheading>
        <Form>
          <UserPermissions
            selectableUserIds={selectableUserIds}
            onSelectUser={(user: SelectedUser) => {
              dispatch(slice.addUser(props.roleId, user))
            }}
            onDeleteUser={(userId: string) => {
              if (selectedUsersWithPerms.length === 1) {
                dispatch(
                  notify({
                    error: 'You may not have no users assigned to a job.',
                  }),
                )
                return
              }
              dispatch(slice.deleteUser(props.roleId, userId))
            }}
            selectedUsersWithPerms={selectedUsersWithPerms}
          />
        </Form>
      </Container>
    </Modal>
  )
}

export default Component
Component.displayName = 'UserPermissionsModal'
