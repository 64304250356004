import React from 'react'
import Breadcrumbs, { Breadcrumb } from '../../components/DashboardLayout/Breadcrumbs'
import { BaseHeader, Content } from '../../components/DashboardLayout/Header'
import OrgLogo from '../../components/OrgLogo'
import { size, space } from '../../core'

const Header = BaseHeader.clone()
  .grid({
    columns: [size.auto, 'minmax(0, 1fr)', size.auto],
    align: 'center',
  })
  .spacing({ column: space.l })
  .element()

interface Props {
  orgId: string
  breadcrumbs: Breadcrumb[]
  children?: React.ReactNode
  next?: string
  prev?: string
}

const Component = (props: Props) => {
  const children = Array.isArray(props.children) ? [...props.children] : [props.children]

  const contentChildren = children.filter(c => c && (c as JSX.Element).props.content)

  return (
    <Header>
      <OrgLogo orgId={props.orgId} />
      <Breadcrumbs content={props.breadcrumbs} />
      <Content>{contentChildren}</Content>
    </Header>
  )
}

export default Component
Component.displayName = 'Header'
