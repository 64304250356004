import { profiles } from '../api'
import { Entity } from '../api/request'
import { RootState } from '../store'
import lookup from './lookup'
import { getById as getUserById } from './users'

function getById(state: RootState, id: string): Entity<profiles.Fields> {
  return lookup(state, profiles.RESOURCE, id) || profiles.empty(id)
}

export function getByUserId(state: RootState, userId: string): Entity<profiles.Fields> | undefined {
  const user = getUserById(state, userId)
  if (!user || !user.relationships) return

  const rel = user.relationships.find(r => r.resource === profiles.RESOURCE)
  if (!rel) {
    return
  }

  return getById(state, rel.id)
}

export function firstNameOf(profile: Entity<profiles.Fields>) {
  return profile.fields.full_name.trim().split(' ')[0]
}
