import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as api from '../../api'
import * as selectors from '../../selectors'
import { RootState } from '../../store'
import { remove } from '../../store/comments'
import { Label, Row } from '../Block'
import { Icon } from '../Icons'
import ProfileImage from '../ProfileImage'
import classes from './Index.module.scss'

interface Props {
  comment: api.request.Entity<api.comments.Fields>
}

const Component = (props: Props) => {
  const dispatch = useDispatch()

  const [user, currentUser]: [
    ReturnType<typeof selectors.profiles.getByUserId>,
    ReturnType<typeof selectors.users.current>,
  ] = useSelector((state: RootState) => [
    selectors.profiles.getByUserId(state, props.comment.fields.user_id),
    selectors.users.current(state),
  ])

  const parts = parseText(props.comment.fields.text)

  return (
    <Row comment>
      <ProfileImage nocircle src={user?.fields.profile_picture_url} name={user?.fields.full_name || ''} />
      <Label>
        <div className={classes.content}>
          <div>
            <strong className="data-hj-suppress">{user?.fields.full_name}</strong>
            {parts.map(p => {
              const mention = parseMention(p)
              return mention ? <span className={classes.name}>@{mention.name}</span> : <span>{p}</span>
            })}
          </div>
          {currentUser?.id === user?.fields.user_id ? (
            <div
              className={classes.iconColumn}
              onClick={() => {
                dispatch(remove(props.comment.fields.candidate_id, props.comment.id))
              }}
            >
              <Icon name={'times'} />
            </div>
          ) : null}
        </div>
      </Label>
    </Row>
  )
}

export default Component
Component.displayName = 'Comment'

function parseText(text: string) {
  return text.split(/(\@\[[^\]]+\]\([^\)]+\))/)
}

function parseMention(mention: string) {
  const match = mention.match(/\@\[(?<name>[^\]]+)\]\((?<id>[^\)]+)\)/)
  if (!match) return

  const name = match.groups?.name
  const id = match.groups?.id
  if (!name || !id) return

  return { name, id }
}
