import Button from 'components/Button/ButtonV2'
import { px2rem, size, space, style } from 'core'
import { text } from 'core/design-system/text'
import * as tracking from 'core/track'
import React from 'react'
import PreviewTable, { Preview } from '../PreviewTable'

const TemplateCreationPreviewStyle = style()
  .size({ maxWidth: px2rem(900), width: size.fill })
  .grid()
  .spacing({ outer: [size.none, size.auto], gap: px2rem(16) })
  .element()

const Buttons = style()
  .spacing({ outer: [space.none, space.auto], gap: px2rem(16) })
  .grid({ columns: [px2rem(256), px2rem(256)] })
  .element()

const Title = text.highlight().sans({ align: 'center' }).element('h1')

interface TemplateCreationPreviewProps {
  back: () => any
  submit: () => any
  isSubmitOnProgress?: boolean
  questions: Preview[]
}

const TemplateCreationPreview: React.FC<TemplateCreationPreviewProps> = ({
  back,
  submit,
  questions,
  isSubmitOnProgress,
}) => {
  return (
    <TemplateCreationPreviewStyle>
      <Title>Preview</Title>
      <PreviewTable questions={questions} />
      <Buttons>
        <Button onClick={back} buttonType={'tertiary'} disabled={isSubmitOnProgress}>
          Back
        </Button>
        <Button
          onClick={() => {
            submit()
            tracking.roles.templateConfirm()
          }}
          buttonType={'confirm'}
          isLoading={isSubmitOnProgress}
        >
          Confirm
        </Button>
      </Buttons>
    </TemplateCreationPreviewStyle>
  )
}

export default TemplateCreationPreview
