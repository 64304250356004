import React from 'react'
import classes from './Skeleton.module.scss'

interface SkeletonProps {
  width?: React.CSSProperties['width']
  height?: React.CSSProperties['height']
  style?: React.CSSProperties
  className?: React.HTMLAttributes<HTMLDivElement>['className']
}

export const Skeleton = (props: SkeletonProps) => {
  return (
    <div
      className={`${classes.skeleton} ${props.className}`}
      style={{
        width: props.width || 30,
        height: props.height || 30,
        ...props.style,
      }}
    />
  )
}
