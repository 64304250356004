import { ResultType, InterpretationData, InterpretationStates5 } from '@common/components'
import * as api from 'api'
import { Fields as ModuleFields, ModuleType } from 'api/modules'
import { QuestionEntity, Fields as QuestionFields } from 'api/questions'
import { Entity } from 'api/request'
import { Fields as ResponseOptionFields } from 'api/response-options'
import { getAssessmentTime } from 'App/Candidate/SelfAssessment/utils'
import { hasPresentKey } from 'core/utils'
import { intersection } from 'lodash-es'
import { RootState } from 'store'
import * as selectors from '../selectors'

export type AssessmentAnswersByDomain = {
  domain: {
    name: string
  }
  answers: AssessmentModuleAnswers[]
}[]

export type AssessmentAnswerQuestion = {
  question: QuestionFields
  selected: Pick<ResponseOptionFields, 'id' | 'copy' | 'value' | 'slug'>[]
  options?: ResponseOptionFields[]
  is_skipped?: boolean
  numerical_input: number[]
  text_input?: string
  media_url?: string
  time_taken_millis: number
}

export type AssessmentModuleAnswers = {
  module: ModuleFields | null
  questionAnswers: AssessmentAnswerQuestion[]
  timeExceeded?: boolean
}

export function findSelfAssessmentAnswers(
  state: RootState,
  candidateId: string,
): AssessmentModuleAnswers[] | undefined {
  const questionnaireSubmission = selectors.questionnaireSubmissions.findSelfAssessmentByCandidateId(state, candidateId)
  if (!questionnaireSubmission) return undefined

  const answers = selectors.questionnaireAnswers.findByQuestionnaireSubmissionId(state, questionnaireSubmission.id)
  if (!answers) return undefined
  const answersByModules: { [key: string]: any } = {}

  const allNumInputs = answers.map(answer => {
    return {
      questionId: answer.fields.question_id,
      numericalInput: answer.fields.numerical_input,
    }
  })

  const getNumInputsByQuestionId = (questionId: string) => allNumInputs.filter(input => input.questionId === questionId)

  answers.forEach(answer => {
    const question = selectors.questions.findById(state, answer.fields.question_id)
    if (!question) {
      return
    }

    answersByModules[question.fields.module_id] = answersByModules[question.fields.module_id] || {}

    answersByModules[question.fields.module_id][question.id] =
      answersByModules[question.fields.module_id][question.id] || {}

    answersByModules[question.fields.module_id][question.id].question = question.fields

    answersByModules[question.fields.module_id][question.id].selected =
      answersByModules[question.fields.module_id][question.id].selected || []

    answersByModules[question.fields.module_id][question.id].is_skipped = answer.fields.is_skipped

    if (answer.fields.media_url) {
      answersByModules[question.fields.module_id][question.id].text_input = answer.fields.media_url
    } else if (answer.fields.text_input) {
      answersByModules[question.fields.module_id][question.id].text_input = answer.fields.text_input
    }

    answersByModules[question.fields.module_id][question.id].properties = question.fields.properties

    answersByModules[question.fields.module_id][question.id].time_taken_millis = answer.fields.time_taken_millis

    answersByModules[question.fields.module_id][question.id].numerical_input = [
      ...getNumInputsByQuestionId(question.id).map(input => input.numericalInput),
    ]

    const responseOption = selectors.responseOptions.findById(state, answer.fields.selected_response_option)

    if (responseOption) {
      answersByModules[question.fields.module_id][question.id].selected.push(responseOption.fields)
    }
  })

  const moduleAssessmentAnswers: AssessmentModuleAnswers[] = []
  for (const [moduleId, answersGroupedByQuestionId] of Object.entries(answersByModules)) {
    let moduleFields: ModuleFields | null = null

    const module = selectors.modules.findById(state, moduleId)
    if (module) {
      moduleFields = module.fields
    }

    moduleAssessmentAnswers.push({
      module: moduleFields,
      timeExceeded: (questionnaireSubmission.fields.time_exceeded_modules || []).includes(moduleId),
      questionAnswers: Object.values(answersGroupedByQuestionId as any),
    })
  }

  for (const moduleId of questionnaireSubmission.fields.time_exceeded_modules || []) {
    if (!answersByModules[moduleId]) {
      moduleAssessmentAnswers.push({
        module: selectors.modules.findById(state, moduleId)?.fields || null,
        timeExceeded: true,
        questionAnswers: [],
      })
    }
  }

  return moduleAssessmentAnswers.filter(({ module }) => {
    const group = module?.group_id ? selectors.groups.findById(state, module.group_id) : null
    const inventory = module?.inventory_id ? selectors.inventories.findById(state, module.inventory_id) : null

    return (
      module?.type === 'cognitive-abilities' ||
      (!module?.exclude_from_summary &&
        (module?.type === 'self-assessment' ||
          group?.fields?.slug === 'cultureadd' ||
          inventory?.fields?.slug === 'disc' ||
          inventory?.fields?.slug === 'gesisambitiontolearn' ||
          module?.type === 'hard-skills' ||
          module?.type === 'bfas' ||
          module?.type === 'mini-ipip' ||
          module?.type === 'hexaco' ||
          module?.type === 'mindset' ||
          module?.type === 'soft-skills' ||
          module?.type === 'culture-add' ||
          module?.type === 'candidate-centered' ||
          module?.type === 'knowledge-test')) ||
      !module
    )
  })
}

export function findCustomSelfAssessmentAnswers(
  state: RootState,
  candidateId: string,
): AssessmentAnswerQuestion[] | null {
  const selfAssessment = selectors.questionnaireSubmissions.findSelfAssessmentByCandidateId(state, candidateId)
  if (!selfAssessment) return null

  const answers = selectors.questionnaireAnswers.findByQuestionnaireSubmissionId(state, selfAssessment.id)
  if (!answers) return null

  const answersByID: any = {}

  for (const answer of answers) {
    const question = selectors.questions.findById(state, answer.fields.question_id)
    if (!question || question.fields.module_id || !question.fields.is_custom) {
      continue
    }

    answersByID[question.id] = answersByID[question.id] || {}
    answersByID[question.id].question = question.fields
    answersByID[question.id].is_skipped = answer.fields.is_skipped
    answersByID[question.id].text_input = answer.fields.text_input
    answersByID[question.id].media_url = answer.fields.media_url
    answersByID[question.id].time_taken_millis = answer.fields.time_taken_millis

    answersByID[question.id].selected = answersByID[question.id].selected || []

    const responseOption = selectors.responseOptions.findById(state, answer.fields.selected_response_option)
    if (responseOption) {
      answersByID[question.id].selected.push(responseOption.fields)
    }

    answersByID[question.id].options = selectors.responseOptions
      .findByQuestionId(state, question.id)
      ?.map(option => option.fields)
  }

  return Object.values(answersByID)
}

function getStenScore(zScore: number): number {
  const MIN_STEN_SCORE = 0
  const MAX_STEN_SCORE = 10

  // For more information on the following equation please refer to: https://en.wikipedia.org/wiki/Sten_scores
  return Math.min(Math.max(MIN_STEN_SCORE, zScore * 2 + 5.5), MAX_STEN_SCORE)
}

const MAX_RAW_SCORE_VALUE = 10

function getAssessmentModuleScore(
  questionnaire: Entity<api.questionnaires.Fields> | undefined,
  module: ModuleFields,
  answers: AssessmentModuleAnswers[],
  moduleQuestions: Map<string, QuestionEntity[]>,
): number | null {
  const cultureFitCheck = 'culturefitcheck'
  if (module.slug === cultureFitCheck) {
    const cultureFitCheckModule = Object.entries(questionnaire?.fields.library_item_configs || {}).find(([key]) =>
      key.startsWith('modules:culturefitcheck'),
    )?.[1]

    // TODO: this could really do with some better typing and support for more than one configurable question per module
    const conf = cultureFitCheckModule || { questions: {} }
    const slugA = Object.values(conf.questions)?.[0] || []
    const answersModule = (answers || []).find(a => a?.module?.slug === cultureFitCheck)
    const slugB = answersModule?.questionAnswers?.[0].selected.map(s => s.slug) || []
    return (intersection(slugA, slugB).length * 100) / 6
  }

  const moduleAnswers = answers.find(answer => answer?.module?.slug === module.slug)

  if (!moduleAnswers) {
    return null
  }

  const questionScores = (moduleQuestions?.get(module.id) || []).map(moduleQuestion => {
    const answer = moduleAnswers.questionAnswers.find(q => q.question.id === moduleQuestion.id)
    return {
      id: moduleQuestion.id,
      label: moduleQuestion.fields.copy.heading || '',
      rawScore: answer?.selected[0]?.value ?? null,
      scoring: answer?.question.properties?.scoring ?? null,
      // if there is no answer for a specific question, the question was skipped (e.g., time ran out).
      is_skipped: answer ? answer.is_skipped : true,
      type: moduleQuestion.fields.response_type,
    }
  })

  const areAllScoringPropertiesValid = questionScores.some(({ scoring }) => {
    return (scoring?.polarity || scoring?.module?.polarity) && scoring?.module?.pop_mean && scoring?.module?.pop_sd
  })

  if (!areAllScoringPropertiesValid) {
    // If scoring properties are missing,
    // abort module level scoring calculation
    // and fallback to average
    const notSkippedQuestions = questionScores.filter(q => !q.is_skipped)

    const validScores: number[] = []
    for (const { rawScore } of notSkippedQuestions) {
      if (typeof rawScore === 'number') {
        validScores.push(rawScore)
      }
    }

    const averageScore =
      (validScores.reduce((sum, score) => sum + Number((score || 0) > 0), 0) * 100) / questionScores.length

    return averageScore
  }

  const answeredQuestions = questionScores.filter(({ rawScore, is_skipped }) => rawScore !== null && !is_skipped)

  // If less than half of the questions in the module have been answered,
  // do not calculate the module score
  if (answeredQuestions.length < questionScores.length / 2) {
    return null
  }

  const polarizedValues: number[] = []
  for (const { scoring, rawScore } of answeredQuestions) {
    if ((rawScore !== 0 && !rawScore) || !scoring) {
      continue
    }

    const polarity = scoring.polarity ?? scoring.module.polarity
    const polarizedRawScore = polarity > 0 ? rawScore : MAX_RAW_SCORE_VALUE - rawScore

    polarizedValues.push(polarizedRawScore)
  }

  const moduleMean = polarizedValues.reduce((sum, score) => sum + score, 0) / polarizedValues.length

  const popMean = questionScores[0].scoring?.module.pop_mean ?? 0
  const popSd = questionScores[0].scoring?.module.pop_sd ?? 1

  const moduleZScore = (moduleMean - popMean) / popSd

  return getStenScore(moduleZScore) * 10 // adjust to 100 based scoring
}

// COGNITIVE ABILITIES

export type NumericalReasoningAnswer = {
  expected: number[]
  actual: number[]
  toProvide: number
}

function computeNumericalReasoningQuestionScore(answers: NumericalReasoningAnswer): number {
  let score = 0
  // compare actual answers vs. expected
  answers.actual.forEach((numericalInput, i) => {
    if (numericalInput === answers.expected[i]) {
      score += 1 / answers.toProvide
    }
  })
  return score
}

export function getCognitiveAbilitiesQuestionScore(
  module: ModuleFields,
  selected: { value: number }[],
  is_skipped?: boolean,
  numericalScoring?: NumericalReasoningAnswer,
) {
  // PROBLEM SOLVING
  if (module.slug === 'problem-solving') {
    // give skipped questions score of -1, otherwise take the value of the selected field
    // where 1 = correct, 0 = incorrect
    return is_skipped ? -1 : selected.length ? selected[0].value : -1
  }

  // LOGICAL THINKING
  // no skip option in logical thinking
  // take value of the selected field
  // 1 = correct, 0 = incorrect
  if (module.slug === 'logical-thinking') {
    return selected.length ? selected[0].value : 0
  }

  // NUMERICAL REASONING
  if (module.slug === 'numerical-reasoning' || module.slug === 'cogabilitynumericalreasoningbasic') {
    // give skipped questions score of -1, otherwise compute the score
    return is_skipped ? -1 : numericalScoring ? computeNumericalReasoningQuestionScore(numericalScoring) : 0
  }

  return selected[0]?.value ? 1 : 0
}

export function getKnowledgeTestQuestionScore(
  answerScale: number,
  selected: { value: number }[],
  is_skipped?: boolean,
) {
  return is_skipped ? -1 : selected.length ? selected[0].value / answerScale : -1
}

// TODO: scoring should be part of module properties or similar
function getCognitiveAbilitiesAnswerScores(
  numQuestions: number,
  answers: number[],
): {
  resultType: ResultType
  scorePercent: number
  numberOfQuestions: number
}[] {
  // ignore explicitly skipped, they're the same as implicitly skipped
  answers = answers.filter(a => a >= 0)

  // numAnswers is < numQuestions when the candidate runs out of time,
  // so consider "skipped" to be the difference in those

  // also note that for some questions you can receive half marks. so the sum of the positive
  // answers does not always equal the num of positive answers

  const numAnswered = answers.length
  const numCorrect = answers.reduce((s, a) => s + a, 0)
  const numIncorrect = numAnswered - numCorrect
  const numSkipped = numQuestions - numCorrect - numIncorrect

  const percent = (n: number): number => (n / numQuestions) * 100

  return [
    {
      resultType: 'Correct',
      scorePercent: percent(numCorrect),
      numberOfQuestions: numCorrect,
    },
    {
      resultType: 'Incorrect',
      scorePercent: percent(numIncorrect),
      numberOfQuestions: numIncorrect,
    },
    {
      resultType: 'Skipped',
      scorePercent: percent(numSkipped),
      numberOfQuestions: numSkipped,
    },
  ]
}

function getCognitiveAbilitiesModuleScore(numQuestions: number, questionScores: number[]) {
  return getCognitiveAbilitiesAnswerScores(numQuestions, questionScores)[0]?.scorePercent
}

function getBenchmarkRelativeScore(score: number, boundaryValues: number[]): number {
  if (score === 0) return 0
  if (score < boundaryValues[0]) return 10
  if (score < boundaryValues[1]) return 30
  if (score < boundaryValues[2]) return 50
  if (score < boundaryValues[3]) return 70
  return 90
}

export function getInterpretationStatus(
  results: {
    resultType: ResultType
    scorePercent: number
  }[],
  boundaryValues: number[],
): InterpretationStates5 {
  if (!results.length || !results[0].scorePercent || results[0].scorePercent === 0) {
    return 'Not-Interpretable'
  }

  const benchmarkedScore = getBenchmarkRelativeScore(results[0].scorePercent, boundaryValues)

  if (benchmarkedScore > 80) {
    return 'High'
  }
  if (benchmarkedScore > 60) {
    return 'Above Average'
  }
  if (benchmarkedScore > 40) {
    return 'Average'
  }
  if (benchmarkedScore > 20) {
    return 'Below Average'
  }
  return 'Low'
}

export function getInterpretationData(
  resultState: InterpretationStates5,
  module: ModuleFields,
  candidateFirstName: string,
): InterpretationData {
  switch (resultState) {
    case 'High': {
      return {
        icon: 'tick',
        copy: {
          title: `${candidateFirstName} achieved high scores`,
          description: module.copy.interpretation?.high,
        },
        status: resultState,
      }
    }
    case 'Above Average': {
      return {
        icon: 'tick',
        copy: {
          title: `${candidateFirstName} achieved above average scores`,
          description: module.copy.interpretation?.above_average,
        },
        status: resultState,
      }
    }
    case 'Average': {
      return {
        icon: 'check-circle',
        copy: {
          title: `${candidateFirstName} achieved average scores`,
          description: module.copy.interpretation?.average,
        },
        status: resultState,
      }
    }
    case 'Below Average': {
      return {
        icon: 'cross',
        copy: {
          title: `${candidateFirstName} achieved below average scores`,
          description: module.copy.interpretation?.below_average,
        },
        status: resultState,
      }
    }
    case 'Low': {
      return {
        icon: 'cross',
        copy: {
          title: `${candidateFirstName} achieved low scores`,
          description: module.copy.interpretation?.low,
        },
        status: resultState,
      }
    }
    case 'Not-Interpretable': {
      return {
        icon: 'question',
        copy: {
          title: `We cannot interpret the results`,
          description: 'N/A because no questions were answered correctly.',
        },
        status: resultState,
      }
    }
  }
}

export function computeBoundaries(numBoundaries: number, questionMeans: number[]): number[] {
  // each question has a benchmark mean value
  // these values are stored in an array

  // first sum all of the mean values in the array
  let meanSum: number = 0.0
  for (const mean of questionMeans) {
    meanSum += mean
  }
  // calculate the mean of these means
  const overallMean = meanSum / questionMeans.length

  // calculate the overall size of the side slices
  const left = overallMean
  const right = 100 - overallMean

  const numBoundariesPerSide = numBoundaries / 2.0

  // calculate the size of each boundary
  const leftBoundarySize: number = left / numBoundariesPerSide
  const rightBoundarySize: number = right / numBoundariesPerSide

  const result: number[] = []

  let currentPositionInGraph = 0.0

  // generate the boundary limit values for the left side and add to the array
  for (let i = 0; i < Math.floor(numBoundariesPerSide); i++) {
    currentPositionInGraph += leftBoundarySize
    result.push(currentPositionInGraph)
  }

  // Check if the side number is a whole number (integer)
  // If not, we consider the middle boundary
  if (numBoundariesPerSide % 1 !== 0) {
    const middleBoundarySize = leftBoundarySize / 2.0 + rightBoundarySize / 2.0
    currentPositionInGraph = currentPositionInGraph + middleBoundarySize
    result.push(currentPositionInGraph)
  }

  // generate the boundary limit values for the left side and add to the array
  for (let i = 0; i < Math.floor(numBoundariesPerSide) - 1; i++) {
    currentPositionInGraph += rightBoundarySize
    result.push(currentPositionInGraph)
  }

  // return an array of boundary values
  return result
}

// OVERALL ASSESSMENT SCORE CALCULATIONS
export type ModuleScoreData = {
  groupId: string
  moduleType: ModuleType
  moduleSlug: string
  moduleScore: number | null
  timeTakenMillis: number
  timeAllowedMillis?: number
}

export function getAllAssessmentModuleScores(
  questionnaire: Entity<api.questionnaires.Fields>,
  answers: AssessmentModuleAnswers[],
  moduleQuestions: Map<string, QuestionEntity[]>,
): ModuleScoreData[] {
  return (answers || [])
    .filter(({ module }) => module)
    .filter(hasPresentKey('module'))
    .map(assessmentModuleAnswers => {
      let score
      if (assessmentModuleAnswers.module.type === 'cognitive-abilities') {
        const cognitiveAbilitiesModuleQuestions = [
          ...assessmentModuleAnswers.questionAnswers.map(({ question, selected, is_skipped, numerical_input }) => {
            const numericalAnswers: NumericalReasoningAnswer = {
              expected: question.properties.numerical_reasoning?.expected_answers || [],
              actual: numerical_input,
              toProvide: question.properties.numerical_reasoning?.to_provide || 0,
            }

            return {
              id: question.id,
              label: question.copy.heading,
              numericalScoring: numericalAnswers,
              score: getCognitiveAbilitiesQuestionScore(
                assessmentModuleAnswers.module,
                selected,
                is_skipped,
                numericalAnswers,
              ),
            }
          }),
        ]

        score = getCognitiveAbilitiesModuleScore(moduleQuestions.get(assessmentModuleAnswers.module.id)?.length || 0, [
          ...cognitiveAbilitiesModuleQuestions.map(question => question.score),
        ])
      } else {
        score = getAssessmentModuleScore(questionnaire, assessmentModuleAnswers.module, answers || [], moduleQuestions)
      }

      const { timeTakenMillis, timeAllowedMillis } = getAssessmentTime(
        assessmentModuleAnswers.module,
        assessmentModuleAnswers.questionAnswers,
        assessmentModuleAnswers.timeExceeded,
      )

      return {
        moduleType: assessmentModuleAnswers.module.type,
        moduleSlug: assessmentModuleAnswers.module.slug,
        moduleScore: score,
        groupId: assessmentModuleAnswers.module.group_id || '',
        timeTakenMillis: timeTakenMillis,
        timeAllowedMillis: timeAllowedMillis,
      }
    })
}

export type ModuleGroupScoreData = {
  groupId: string
  moduleCount: number
  score: number
  timeTakenMillis: number
  timeAllowedMillis?: number
  isScorable?: boolean
}

export function getModuleGroupScores(
  scoresByModule: ModuleScoreData[],
  allGroups: Entity<api.groups.Fields>[],
): ModuleGroupScoreData[] {
  // only push valid scores for calculation
  const validScoreGroups = new Map<string, ModuleScoreData[]>()

  for (const module of scoresByModule) {
    if (module.moduleScore !== null && !isNaN(module.moduleScore)) {
      const group = validScoreGroups.get(module.groupId)
      if (!group) {
        validScoreGroups.set(module.groupId, [module])
      } else {
        group.push(module)
      }
    }
  }

  return Array.from(validScoreGroups.entries()).map(([groupId, moduleTypeScores]): ModuleGroupScoreData => {
    const score = moduleTypeScores.reduce((sum, module) => sum + (module.moduleScore ?? 0), 0) / moduleTypeScores.length

    const timeTakenMillis = moduleTypeScores.reduce((sum, module) => sum + module.timeTakenMillis, 0)

    const timeAllowedMillis =
      moduleTypeScores.reduce((sum, module) => sum + (module.timeAllowedMillis ?? 0), 0) || undefined

    return {
      groupId: groupId,
      moduleCount: moduleTypeScores.length,
      score: score,
      timeTakenMillis: timeTakenMillis,
      timeAllowedMillis: timeAllowedMillis,
      isScorable: allGroups.find(g => g.id === groupId)?.fields.copy?.is_scorable,
    }
  })
}

export function getOverallAssessmentScore(moduleTypeScores: ModuleGroupScoreData[]) {
  const validScores = moduleTypeScores.filter(score => {
    return score.isScorable
  })
  if (validScores && validScores.length > 0) {
    return validScores.reduce((sum, m) => sum + m.score, 0) / validScores.length
  }
}

// navigation
export function getNavigationPath(role: Entity<any> | undefined, targetCandidateId: string) {
  const assessmentOnly = !role?.fields.ask_reference_check
  return assessmentOnly
    ? `/roles/${role?.id}/candidates/${targetCandidateId}/assessment`
    : `/roles/${role?.id}/candidates/${targetCandidateId}`
}

export function findSelfAssessmentAnswersByModuleSlug(
  state: RootState,
  candidateId: string,
  moduleSlug: string,
): AssessmentModuleAnswers | undefined {
  const allAnswers = findSelfAssessmentAnswers(state, candidateId)
  return allAnswers?.find(({ module }) => module?.slug === moduleSlug)
}
