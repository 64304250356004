import DropdownField, { Option } from 'components/DropdownField'
import TextField from 'components/TextField'
import React, { useEffect, useState } from 'react'
import { Rules, Validator } from 'core/validation/validator'
import { ReferredBy, referrers, TellUsMoreValues } from 'App/SignUp/TellUsMore'

const fullNameValidator = new Validator([Rules.minStringLength(3), Rules.maxStringLength(128)])
const jobTitleValidator = new Validator([Rules.minStringLength(3), Rules.maxStringLength(128)])
const phoneValidator = new Validator([Rules.minStringLength(8), Rules.maxStringLength(16)])
const referrerValidator = new Validator([Rules.isPresent()])

interface Props {
  values: TellUsMoreValues
  onChange: React.Dispatch<React.SetStateAction<TellUsMoreValues>>
  setValid: (valid: boolean) => void
}

export const PersonalInfo: React.FC<Props> = props => {
  const [dirty, setDirty] = useState({
    fullName: false,
    jobTitle: false,
    phone: false,
    referredBy: false,
  })

  const valid = {
    fullName: fullNameValidator.validate(props.values.fullName),
    jobTitle: jobTitleValidator.validate(props.values.jobTitle),
    phone: phoneValidator.validate(props.values.phoneNumber),
    referredBy: referrerValidator.validate(props.values.referredBy || ''),
  }

  useEffect(() => {
    props.setValid(Object.values(valid).every(v => v.isValid))
  }, [valid])

  return (
    <>
      <TextField
        aria-label="Full name"
        label="Full name"
        placeholder="Sue Brown"
        value={props.values.fullName}
        onChange={e => {
          setDirty({ ...dirty, fullName: true })
          props.onChange(values => ({
            ...values,
            fullName: e.target.value,
          }))
        }}
        error={dirty.fullName && !valid.fullName.isValid}
        errorLabel={dirty.fullName && !valid.fullName.isValid ? valid.fullName.getError : ''}
      />
      <TextField
        aria-label={'Your phone number'}
        label={'Your phone number'}
        value={''}
        phone={true}
        error={dirty.phone && !valid.phone.isValid}
        errorLabel={dirty.phone && !valid.phone.isValid ? valid.phone.getError : ''}
        onChange={e => {
          setDirty({ ...dirty, phone: true })
          props.onChange(values => ({
            ...values,
            phoneNumber: e.target.value,
          }))
        }}
      />
      <TextField
        aria-label="What is your job title?"
        label="What is your job title?"
        placeholder="Recruiting Manager"
        value={props.values.jobTitle}
        onChange={e => {
          setDirty({ ...dirty, jobTitle: true })
          props.onChange(values => ({
            ...values,
            jobTitle: e.target.value,
          }))
        }}
        error={dirty.jobTitle && !valid.jobTitle.isValid}
        errorLabel={dirty.jobTitle && !valid.jobTitle.isValid ? valid.jobTitle.getError : ''}
      />
      <DropdownField<ReferredBy | undefined>
        aria-label="How did you hear about us?"
        label="How did you hear about us?"
        placeholder="Please select"
        value={props.values.referredBy}
        onChange={e => {
          props.onChange(values => ({
            ...values,
            referredBy: e,
          }))
        }}
      >
        {referrers.map(s => (
          <Option key={s} value={s}>
            {s}
          </Option>
        ))}
      </DropdownField>
    </>
  )
}
