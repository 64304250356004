import { copyToClipboard } from 'core/utils'
import React, { MutableRefObject, useRef } from 'react'
import { useDispatch } from 'react-redux'
import PopupV2, { Button, Separator } from '../../components/PopupV2'
import { px2rem, style } from '../../core'
import { add as notify } from '../../store/notifications'

const PopupContainer = style()
  .absolute({ top: px2rem(54), right: 0 })
  .size({ width: px2rem(200) })
  .front()
  .element()

const MenuItem = style().size({ width: '100%' }).element('li')

interface InviteCXProps {
  isMenuOpen: boolean
  orgID: string
  setMenuOpen: (open: boolean) => void
  activatorRef?: MutableRefObject<null | HTMLElement>
  setUpgradeModalOpen: (open: boolean) => void
}

export const InviteCandidateMenu: React.FC<InviteCXProps> = ({
  isMenuOpen,
  orgID,
  setMenuOpen,
  activatorRef,
  setUpgradeModalOpen,
}) => {
  const dispatch = useDispatch()
  const inviteMenuRef = useRef<HTMLElement>(null)

  return (
    <PopupContainer>
      <PopupV2 open={isMenuOpen} setOpen={setMenuOpen} listRef={inviteMenuRef} activatorRef={activatorRef}>
        <MenuItem key={'Hired candidate'}>
          <Button
            icon={'user-check'}
            aria-label={'Hired candidate'}
            onClick={() => {
              const hired = btoa(JSON.stringify({ hiring_status: 'hired' }))
              copyToClipboard(
                import.meta.env.VITE_BASE + '/candidateexperience/public/' + orgID + '/invite?state=' + hired,
              )
              dispatch(
                notify({
                  success: 'The link is copied to your clipboard',
                }),
              )
            }}
          >
            Hired candidate
          </Button>
        </MenuItem>
        <MenuItem key={'Rejected candidate'}>
          <Button
            icon={'user-times'}
            aria-label={'Rejected candidate'}
            onClick={() => {
              const rejected = btoa(JSON.stringify({ hiring_status: 'rejected' }))
              copyToClipboard(
                import.meta.env.VITE_BASE + '/candidateexperience/public/' + orgID + '/invite?state=' + rejected,
              )
              dispatch(
                notify({
                  success: 'The link is copied to your clipboard',
                }),
              )
            }}
          >
            Rejected candidate
          </Button>
        </MenuItem>
        <Separator key={`separator`} />
        <MenuItem key={'ATS Automation'}>
          <Button
            icon={'sparkles'}
            aria-label={'ATS automation'}
            onClick={() => {
              setUpgradeModalOpen(true)
            }}
          >
            ATS Automation
          </Button>
        </MenuItem>
      </PopupV2>
    </PopupContainer>
  )
}
