import { Job } from 'api/integrations'
import DropdownField, { Option } from 'components/DropdownField'
import ToggleField from 'components/ToggleField'
import { Buttons, Content, ContentAndButtons, Form } from 'components/Wizard'
import { Heading, Subheading } from 'components/Wizard/Typography'
import { style, weight } from 'core'
import { designSystemColors } from 'core/design-system/colors'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import * as selectors from 'selectors'
import { RootState } from 'store'
import * as slice from 'store/integrations'
import { ATS as ATSType } from 'store/integrations/ats'
import { INTEGRATION_HELP_LINK } from '.'

interface Props {
  next: () => void
  back: () => void
  setDone: (d: boolean) => void
  roleId: string
  atsMap: { [key: string]: ATSType }
}

const Link = style().text({ weight: weight.normal }).color({ fg: designSystemColors.typographySecondary }).element('a')

// Reflection of https://github.com/hipeople/api/blob/master/hipeople/integrations/ats/ats.go#L12-L20
// TODO: fetch from API.
const atsJobStatuses = [
  { id: 'open', name: 'Open' },
  { id: 'closed', name: 'Closed' },
  { id: 'draft', name: 'Draft' },
  { id: 'internal', name: 'Internal' },
]

const Component = (props: Props) => {
  const dispatch = useDispatch()
  const { roleid } = useParams<{ roleid: string }>()

  const role = useSelector((state: RootState) => selectors.roles.findById(state, roleid || ''))
  const org = useSelector((state: RootState) => selectors.orgs.getById(state, selectors.orgs.currentId(state)))
  const integration = useSelector((state: RootState) => selectors.integrations.getByOrgId(state, org.id))
  const loading = useSelector((state: RootState) => state.integrations.loading)
  const atsJobs = useSelector((state: RootState) => state.integrations.atsJobs || [])
  const selectedATSJob = useSelector((state: RootState) => state.integrations.selectedATSJob)

  const [filter, setFilter] = useState('')
  const [jobStatus, setJobStatus] = useState(atsJobStatuses[0].id)
  const [showIDs, setShowIDs] = useState(false)

  useEffect(() => {
    if (!integration || !jobStatus) {
      return
    }

    dispatch(slice.getATSJobs(integration.id, jobStatus))
  }, [integration, jobStatus])

  if (integration?.fields.ats_slug && props.atsMap[integration?.fields.ats_slug]) {
    const ats = props.atsMap[integration?.fields.ats_slug || '']

    return (
      <ContentAndButtons>
        <Content>
          <Heading>ATS Job</Heading>
          <Subheading>
            By connecting a job from {ats.name} to HiPeople, you can automate the various questionnaire for requests by
            simply moving candidates through the {ats.name} pipeline stages.
            <br />
            <Link href={INTEGRATION_HELP_LINK}>Click here to learn more about ATSs integrations with HiPeople.</Link>
          </Subheading>
          <Form>
            <DropdownField<string | undefined>
              label={`Select status to filter jobs by`}
              value={jobStatus}
              onChange={status => {
                if (status) setJobStatus(status)
              }}
            >
              {atsJobStatuses.map(s => (
                <Option value={s.id} key={s.id}>
                  {s.name}
                </Option>
              ))}
            </DropdownField>
            <DropdownField<Job | undefined>
              disabled={!jobStatus}
              label={`Select ${ats.name} job to connect to “${role.fields.name}”`}
              value={selectedATSJob}
              onChange={job => {
                if (!job) return
                props.setDone(true)
                setFilter('')
                dispatch(slice.setSelectedATSJob(job))
              }}
              allowInput={true}
              onInput={input => {
                setFilter(input)
              }}
              placeholder={`Type keyword to filter ${ats.name} jobs`}
              missingSuggestion={loading ? 'Loading...' : 'Not here? Please allow up to an hour while we are syncing'}
            >
              {atsJobs
                .filter(job => selectors.match(`${job.remote_id || job.id} ${job.name}`, filter))
                .map(j => (
                  <Option value={j} key={j.id}>
                    {showIDs && `${j.code || j.remote_id || j.id} `}
                    {j.name}
                    {j.location && `, ${j.location}`}
                  </Option>
                ))}
            </DropdownField>
            <ToggleField on={showIDs} onChange={setShowIDs} label="Show job IDs" />
          </Form>
        </Content>
        <Buttons next={props.next} back={props.back} forwardOnly disabled={!selectedATSJob} />
      </ContentAndButtons>
    )
  }
  return <></>
}

export default Component
