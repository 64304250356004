import * as api from 'api'
import { listing } from 'api'
import { SortOrder } from 'api/listing'
import { Entity } from 'api/request'
import { getRolesQuery } from 'App/RolesPage'
import ContentContainer from 'components/Container/ContentContainer'
import { isPresent } from 'core/utils'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { loadByProduct as loadReportsByProduct } from 'store/reports'
import { addEntities } from 'store/resources'
import DashboardLayout from '../../components/DashboardLayout'
import ProductPageShell from '../../components/ProductPageLayout'
import * as selectors from '../../selectors'
import { ProductFilteredView } from './ProductFilteredView'

interface Props {
  product: 'reference_check' | 'assessment'
  title: string
  ctaButton: React.ReactElement
  hero: React.ReactElement
}

const ProductPage: React.FC<Props> = props => {
  const dispatch = useDispatch()

  const userId = useSelector(selectors.users.current)?.id || ''
  const canCreateRole = useSelector((state: RootState) => selectors.users.canCreateRole(state, userId))

  const updatedAt = useSelector((state: RootState) => state.reports.updatedAt)

  useEffect(() => {
    if (!canCreateRole) return
    if (updatedAt > 0) return
    dispatch(loadReportsByProduct(props.product))
  }, [dispatch, updatedAt, props.product])

  const orgId = useSelector(selectors.orgs.currentId)

  const [sort, setSort] = React.useState<listing.Options['sort']>([
    {
      field: 'updated_at',
      order: listing.SortOrder.Desc,
    },
  ])

  const [roleIDs, setRoleIDs] = useState<string[]>([])

  const roles = useSelector((state: RootState) =>
    roleIDs.map(id => selectors.roles.getRole(state, id)).filter(isPresent),
  )

  // TODO: we could do with a custom hook to paginate though API resources for us.
  // it could expose the returned resources, page, hasMore, etc
  const [hasMore, setHasMore] = useState<boolean>(true)

  const loadMoreRoles = async (skip: number) => {
    const query = getRolesQuery({ category: 'mine', sort: sort, offset: skip }, userId, orgId)
    if (!query) return

    query.product = props.product

    const [response, errors] = await api.openjobroles.fetchRoles(query)
    if (errors) return
    if (!response) return

    dispatch(addEntities(response))

    // we may have an openjobroles[] or an openjobroleusers[], depending on org or user query
    const nextRoleIDs = response.result.map(
      (row: Entity<{ id: string; open_job_role_id?: string }>) => row.fields.open_job_role_id || row.fields.id,
    )

    setHasMore(response.list?.has_more ?? false)
    setRoleIDs(!skip ? nextRoleIDs : [...roleIDs, ...nextRoleIDs])
  }

  useEffect(() => {
    loadMoreRoles(0)
  }, [sort, userId, orgId, props.product])

  const onSort = useCallback(
    (key: string, direction: string) => {
      setSort([
        {
          field: key,
          order: direction as SortOrder,
        },
      ])
    },
    [dispatch],
  )

  return (
    <DashboardLayout>
      <ProductPageShell title={props.title} button={props.ctaButton}>
        {!roles?.length ? (
          <ContentContainer>{props.hero}</ContentContainer>
        ) : (
          <ProductFilteredView
            onSort={onSort}
            loadMoreRoles={() => loadMoreRoles(roleIDs.length ?? 0)}
            roles={roles || []}
            hasMoreRole={hasMore}
          />
        )}
      </ProductPageShell>
    </DashboardLayout>
  )
}

export default ProductPage
